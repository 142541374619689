import React from 'react';
import { connect } from 'react-redux';
import { TRENDEX_ROLES } from '../../../config/_const';
import AssetInterface from '../../../model/AssetModel/AssetInterface';
import { RouteComponentProps } from 'react-router-dom';
import AssetTransactionInterface from '../../../model/AssetModel/AssetTransactionInterface';
import { actionFetchAssetTransactionsLogVip } from '../../../service/asset-transactions-log/action';
import {
    actionFetchOwnAssets,
    actionFetchVipIncome,
    actionGetPrivateSellStatus,
} from '../../../service/assets/actions';
import OverviewSalesProps from './OverviewSalesProps';
import LogTable from '../../../components/medium/shared/LogTable/LogTable';
import './OverviewSales.scss';
import PrimaryButton from '../../../components/small/buttons/primary-button/PrimaryButton';
import i18next from 'i18next';
import OverviewSalesState from './OverviewSalesState'
import moment from 'moment';
import Timer from '../../../components/medium/timer/Timer';
const periodToDisplay = 24; // hours

type ComposedProps = OverviewSalesProps & RouteComponentProps;

class OverviewSales extends React.Component<ComposedProps, OverviewSalesState> {
    TimerStyle = {
        "light": {
            chartStyle: { color: "white", width: "65px", height: "65px", background: "#9371ef" },
            titleStyle: { color: "#9371ef" },
            rootStyle: { marginTop: "100px" }
        },
    }
    constructor(props: ComposedProps) {
        super(props);

        this.state = {
            fetchDataInterval: 0
        }
    }

    componentWillMount = () => {
        this.props.actionFetchOwnAssets();
        if (!this.props.auth.data.actualAsset) return;
        this.fetchTransactionsLogsByPagination(
            {
                asset: this.props.auth.data.actualAsset._id,
                fromInitialSale: true,
            },
            0,
            false,
        );
        this.props.actionFetchVipIncome(this.props.auth.data.actualAsset._id);
        const fetchDataInterval = setInterval(() => {
            const endDate = new Date();
            const startDate = new Date(endDate.getTime() - (periodToDisplay * 60 * 60 * 1000));
            this.props.actionGetPrivateSellStatus(this.props.auth.data.actualAsset._id, startDate.toISOString(), endDate.toISOString());
            this.props.actionGetPrivateSellStatus(this.props.auth.data.actualAsset._id);
            this.props.actionFetchVipIncome(this.props.auth.data.actualAsset._id);
        }, 3000);

        this.setState({
            fetchDataInterval
        })
    }

    componentWillUnmount = () => {
        clearInterval(this.state.fetchDataInterval);
    }

    componentDidUpdate = (prevProps: OverviewSalesProps) => {
        if (this.props.auth.data.actualAsset) {

            const assetIsChange: boolean = !!(prevProps.auth.data.actualAsset._id !== this.props.auth.data.actualAsset._id);

            if (assetIsChange) {

                this.fetchTransactionsLogsByPagination(
                    {
                        asset: this.props.auth.data.actualAsset._id,
                        fromInitialSale: true,
                    },
                    0,
                    false,
                );
                const endDate = new Date();
                const startDate = new Date(endDate.getTime() - (periodToDisplay * 60 * 60 * 1000));
                this.props.actionGetPrivateSellStatus(this.props.auth.data.actualAsset._id, startDate.toISOString(), endDate.toISOString());
                this.props.actionGetPrivateSellStatus(this.props.auth.data.actualAsset._id);
                this.props.actionFetchVipIncome(this.props.auth.data.actualAsset._id);
            }
        }
    }

    fetchTransactionsLogsByPagination = (filterCustom: Object, page: number, skipOptions: boolean) => {

        if (this.props.auth.data.account.type === TRENDEX_ROLES.VIP) {

            this.props.actionFetchAssetTransactionsLogVip(
                page + 1,
                skipOptions,
                filterCustom,
                undefined,
                undefined,
                [
                    {
                        path: 'otherUser',
                        select: 'firstName lastName',
                    },
                    {
                        path: 'userId',
                        select: 'firstName lastName',

                    }, {
                        path: 'asset',
                        select: 'name',
                    },
                ],
            );
        }
    }

    render() {
        const {
            auth,
            assets,
            assetTransactionsLog,
        } = this.props;
        const actualAsset: AssetInterface = auth.data.actualAsset;
        const hasActualAsset = !!actualAsset;
        let totalAmount,
            totalPaid,
            totalAmountForPeriod,
            totalPaidForPeriod,
            totalRoyalties,
            totalIncome;
        let logsActualAsset: AssetTransactionInterface[] = [];
        if (hasActualAsset) {
            const assetData: AssetInterface | undefined = assets.data.find((asset: AssetInterface) => asset._id === actualAsset._id);

            if (assetData) {
                totalAmount = assetData.totalAmount ?? 0;
                totalPaid = assetData.totalPaid ?? 0;
                totalAmountForPeriod = assetData.totalAmountForPeriod ?? 0;
                totalPaidForPeriod = assetData.totalPaidForPeriod ?? 0;
                totalRoyalties = assetData.totalRoyalties ?? 0;
                totalIncome = assetData.totalIncome ?? 0;
            }
            if (assetTransactionsLog.data && assetTransactionsLog.data.length > 0) {
                logsActualAsset = assetTransactionsLog.data.filter((log: AssetTransactionInterface) => log.asset && log.asset._id === actualAsset._id);
            }
        }

        return (
            hasActualAsset && moment(actualAsset.initialSaleDate) < moment() ? (
                <div className="overview-sales">
                    {!hasActualAsset && (
                        <div className="private-sell-container">
                            <div className="no-sales">
                                <PrimaryButton
                                    disabled={false}
                                    receiveClickEvent={false}
                                    handleClick={() => this.props.history.push('/my-sales')}
                                    textButton={i18next.t('overviewSales.mySales')}
                                    type="button"
                                />
                            </div>
                        </div>
                    )}
                    {hasActualAsset && (
                        <>
                            <div className="private-sell-container">
                                <h2>{i18next.t('overviewSales.yourPrivateSale')}</h2>
                                <p>{actualAsset && actualAsset.initialSaleDone ? i18next.t('overviewSales.ended') : i18next.t('overviewSales.inProgress')}</p>

                                <div className="recap-sale">
                                    <div className="item">
                                        <h4>{i18next.t('overviewSales.totalSold')}</h4>
                                        <p>{totalAmount} {i18next.t('overviewAdvantage.shares')} / {totalPaid?.toCurrency()}</p>
                                    </div>

                                    <div className="item">
                                        <h4>{i18next.t('overviewSales.last24Hours')}</h4>
                                        <p>{totalAmountForPeriod} {i18next.t('overviewAdvantage.shares')} / {totalPaidForPeriod?.toCurrency()}</p>
                                    </div>
                                </div>

                                {logsActualAsset.length > 0 && (
                                    <div className="transactions-table">
                                        <LogTable
                                            logs={logsActualAsset}
                                            loadMoreData={(page: number) => this.fetchTransactionsLogsByPagination(
                                                {
                                                    asset: actualAsset._id,
                                                    fromInitialSale: true,
                                                },
                                                page,
                                                false,
                                            )}
                                            height={'25vh'} />
                                    </div>)}
                            </div>

                            <div className="private-sell-container">
                                <h2>{i18next.t('overviewSales.yourIncome')}</h2>
                                <div className="recap-sale">
                                    <div className="item">
                                        <h4>{i18next.t('overviewSales.totalCashedSales')}</h4>
                                        <p>{totalIncome?.toCurrency()}</p>
                                    </div>

                                    <div className="item">
                                        <h4>{i18next.t('overviewSales.totalCashedRoyalties')}</h4>
                                        <p>{totalRoyalties?.toCurrency()}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div className="overview-sales-timer-container">
                    <Timer title={`${i18next.t('overviewSales.privateSellWillBeStartedSoon')} :`} date={actualAsset.initialSaleDate} {...this.TimerStyle.light} />
                </div>
            )
        );
    }
}

const mapStateToProps = (state: OverviewSalesProps) => ({
    auth: state.auth,
    assetTransactionsLog: state.assetTransactionsLog,
    assets: state.assets,
});

export default connect(
    mapStateToProps, {
    actionFetchAssetTransactionsLogVip,
    actionGetPrivateSellStatus,
    actionFetchOwnAssets,
    actionFetchVipIncome,
},
)(OverviewSales);
