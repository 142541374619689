import React from "react";
import { Redirect, Route } from "react-router-dom";

const AdminProtectedRoute: React.FC<any> = ({ component: Component, isUserAdmin, ...restOfProps }: any) => {
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isUserAdmin ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default AdminProtectedRoute;