import React from 'react';
import { Icon, Tab, Tabs } from '@material-ui/core';
import i18next from 'i18next';
import './FilterTabs.scss';
import IFilterTabsProps from './IFilterTabsProps';

const FilterTabs: React.FC<IFilterTabsProps> = (props): JSX.Element => {
    return (<Tabs
        className="top-filter"
        onChange={props.handleTabsChange}
        scrollButtons="off"
        value={props.currentTab}
        variant="scrollable"
        ref={props.tabRef}
        TabIndicatorProps={{ style: { display: 'none' } }}>
        {props.hideDefaultTab ? null :
            <Tab
                classes={{ root: 'asset-tab', selected: 'asset-tab-selected' }}
                key="all"
                value="all"
                style={{ order: 1 }}
                label={
                    <React.Fragment>
                        <Icon>
                            {props.transformStringToIconName('star')}
                        </Icon>
                        {i18next.t(props.starIconText)}
                    </React.Fragment>} />
        }
        {props.categories.map(category => props.renderTab(category))}
    </Tabs>);
};

export default FilterTabs;
