import { isEmpty } from 'lodash';
import ITournamentEvolution, { IEvolution } from 'model/Tournament/ITournamentEvolution';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import AssetTournamentViewModel from 'view-model/Assets/AssetTournamentViewModel';
import { Collection } from 'utils/types';
import { sortedEvolutionsByMostRecentDate } from './sorters';

export const calculAssetScoreEvolution = (asset: AssetViewModel, evolutions: IEvolution[], considerIRLScore: boolean = true): number => {
    if (evolutions.length === 0)
        return 0;
    const snapshotsSortedByMostRecentDate: IEvolution[] = sortedEvolutionsByMostRecentDate(evolutions);
    const lastSnapshot: IEvolution = snapshotsSortedByMostRecentDate[0];
    const lastAssetScoreSnapshot: number = considerIRLScore && lastSnapshot.score !== asset.IRLScore ? asset.IRLScore : lastSnapshot.score;
    return parseInt((lastAssetScoreSnapshot - snapshotsSortedByMostRecentDate[snapshotsSortedByMostRecentDate.length - 1].score).toString(), 10);
};

export const extractAssetsEvolution = (evolutionCollection: Collection<ITournamentEvolution>, allAssets: AssetViewModel[], assetIds: string[]): AssetTournamentViewModel[] => {
    if (isEmpty(allAssets) || isEmpty(assetIds))
        return [];
    const assetsFiltered: AssetViewModel[] = allAssets.filter((asset: AssetViewModel) => assetIds.includes(asset.AssetId));
    if (assetsFiltered.length === 0)
        return [];
    return assetsFiltered.map((asset: AssetViewModel) => new AssetTournamentViewModel(asset.Asset, evolutionCollection[asset.AssetId]));
};
