import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AchievementCard from 'components/small/achievement-card/AchievementCard';
import GreyButton from 'components/small/buttons/grey-button/GreyButton';
import withFirstDepositAchievement from 'hoc/with-first-deposit-achievement/withFirstDepositAchievement';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IFirstDepositAchievementProps from './IFirstDepositAchievementProps';

const FirstDepositAchievement: React.FC<IFirstDepositAchievementProps> = ({ firstDepositAchievementVM, translate }) => {
    return (

        <AchievementCard
            title={translate(firstDepositAchievementVM.Achievement.Title.key, firstDepositAchievementVM.Achievement.Title.values)}
            subtitle={<Trans
                i18nKey={firstDepositAchievementVM.Achievement.Subtitle.key}
                components={{ bold: <strong /> }}
                values={firstDepositAchievementVM.Achievement.Subtitle.values} />}
            bgColor={firstDepositAchievementVM.FirstDepositCompleted ? 'green' : undefined}
            bottomComponent={
                firstDepositAchievementVM.FirstDepositCompleted ?
                    <div className='achievement-completed'><FontAwesomeIcon className='icon' icon={faCheckCircle} />{translate('text.completed')}</div> :
                    <Link to={firstDepositAchievementVM.Achievement.RedirectLink}>
                        <GreyButton
                            disabled={false}
                            receiveClickEvent={false}
                            textButton={translate('Achievements.makePurchase')}
                            type="button" />
                    </Link>
            } />
    )
}

export default withTranslation()(withFirstDepositAchievement(FirstDepositAchievement));