import i18n from '18n';
import Application from 'Application';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import type IAdminAssetFormInitialValues from 'components/medium/form/admin/admin-asset-form/IAdminAssetFormInitialValues';
import { HTTP_STATUS_OK } from 'config/_const';
import AxiosConfig, { getSource } from 'config/axiosConfig';
import { ASSET_ENDPOINTS } from 'config/endpoints';
import AssetInterface from 'model/AssetModel/AssetInterface';
import IAssetMarketConfiguration from 'model/AssetModel/AssetMarketConfiguration';
import IAssetScore from 'model/AssetModel/IAssetScore';
import { IHttpResponse, IHttpStrongTypedResponse } from 'model/IHttpResponse';
import ILaunchPrivateSellParams from 'model/ILaunchPrivateSellParams';
import Level from 'model/Level';
import { ReduxRootState, RootState } from 'model/Redux';
import { batch } from 'react-redux';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import ICurrentValue from '../../model/AssetModel/IAssetSecondaryMarketInfo';
import { UPDATE_ASSET_CURRENT_INFORMATION } from '../asset-current-information/actionType';
import { showNotification } from '../notification/action';
import { UPDATE_OWNED_ASSET_INITIAL_SALE_DONE } from './../wallet/actionsType';
import { IPredictPriceResponse } from './IAssetServerResponse';
import * as AssetActionType from './actionsType';
import { getCurrentStep, getInitialSellPriceWithFees } from './getters';

let source: CancelTokenSource = getSource();

export const cancelGetPredictPriceRequest = (reason: string = 'Operation canceled by the user.'): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    source.cancel(reason);
    return dispatch({ type: AssetActionType.GET_PREDICTED_ASSET_PRICE_FAILED });
};

export const getAllAssets = (selectedPage: any, populate?: string, filter?: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {

        const filterQuery = filter ? encodeURIComponent(filter) : '';
        const response: IHttpResponse = await AxiosConfig.get(`/assets/all/${selectedPage}?${populate ? `with=${populate}` : ''}&${filterQuery ? `filter=${filterQuery}` : ''}`);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.ASSETS_SUCCESS,
            payload: {
                data: response.data.data,
                message: response.data.message,
            },
        });

    } catch (error) {
        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const updateInitialSaleDone = (assetId: string, initialSaleSoldOutDate?: string): ThunkAction<void, null, unknown, Action<string>> => (dispatch) => {
    batch(() => {
        dispatch({ type: AssetActionType.INITIAL_SALE_DONE, payload: { assetId, initialSaleSoldOutDate } });
        dispatch({ type: UPDATE_OWNED_ASSET_INITIAL_SALE_DONE, payload: { assetId } });
    });
};
/**
 * WARNING ! Each time you call this action middleware watchFetchTopAssets will be called
 * 
 */
export const fetchTopAssets = (): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: AssetActionType.ASSETS_LOADING })
        const fetchAllAssets: boolean = Application.getInstance().hideCategoriesOnTop50;
        const response: IHttpStrongTypedResponse<AssetInterface[]> = await AxiosConfig.get(`/assets/fetchTopAssets/${fetchAllAssets}`);
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
                message: response.data.message,
            });

        return dispatch({
            type: AssetActionType.ASSETS_SUCCESS,
            payload: {
                data: response.data.data,
            },
        });
    } catch (error) {
        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const fetchTopAssetsCurrentValues = (): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<ICurrentValue[]> = await AxiosConfig.get(`${ASSET_ENDPOINTS.GET_TOP_ASSETS_CURRENT_VALUES}`);
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: AssetActionType.GET_TOP_ASSETS_CURRENT_VALUES_ERROR,
                message: response.data.message,
            });
        return dispatch({
            type: AssetActionType.GET_TOP_ASSETS_CURRENT_VALUES_SUCCESS,
            payload: {
                data: response.data.data,
            },
        });
    } catch (error) {
        return dispatch({
            type: AssetActionType.GET_TOP_ASSETS_CURRENT_VALUES_ERROR,
        });
    }
};

export const getFutureAssets = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {

        dispatch({
            type: AssetActionType.FUTURE_ASSETS_LOADING,
        });

        const response: IHttpResponse = await AxiosConfig.get('/assets/fetchFutureListings/');
        if (response && response.data && response.data.status !== 200) {
            return dispatch({
                type: AssetActionType.FUTURE_ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.FUTURE_ASSETS_SUCCESS,
            payload: {
                data: response.data.data,
                message: response.data.message,
            },
        });

    } catch (error) {

        return dispatch({
            type: AssetActionType.FUTURE_ASSETS_ERROR,
        });
    }
};

export const getPredictedPrice = (assetId: string, tokens: number, isFromUserAction: boolean, updateCurrentPrice = false, callbackOnSuccess?: () => void, callbackOnCancel?: () => void): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const states: RootState = getState();
    const assets: ReduxRootState<AssetInterface[]> = states.assets;
    const asset: AssetInterface | undefined = assets.data.find((asset: AssetInterface) => asset && asset._id === assetId);
    const config: Level[] = asset && asset.steps ? asset.steps : [];

    if (asset === undefined)
        return dispatch({ type: AssetActionType.GET_PREDICTED_ASSET_PRICE_FAILED });

    try {
        dispatch({ type: AssetActionType.GET_PREDICTED_ASSET_PRICE_START, payload: { isFromUserAction: false } });
        const cancelToken: CancelToken = source.token;
        const response: IHttpStrongTypedResponse<IPredictPriceResponse> = await AxiosConfig.get(`${ASSET_ENDPOINTS.PRIMARY_MARKET_PREDICT_PRICE}/${assetId}/${tokens}`, {
            cancelToken,
        });

        if (response && response.data && response.data.status !== HTTP_STATUS_OK)
            return dispatch({ type: AssetActionType.ASSETS_ERROR });

        const tokensSold: number = asset.initialSupply - response.data.data.asset.availableSupply;
        const currentInitialSellStep: Level | undefined = getCurrentStep(config, tokensSold);
        const currentValueAsset: number = asset.currentValue;
        const coef: number = asset.coef;
        const priceWithFees: number = getInitialSellPriceWithFees({
            currentInitialSellStep,
            currentValueAsset,
            coef,
            tokens,
            price: response.data.data.price,
            feesRate: response.data.data.feesRate,
            feesFixedAmount: response.data.data.feesFixedAmount,
        });

        if (callbackOnSuccess)
            callbackOnSuccess();
        return batch(() => {
            dispatch({
                type: AssetActionType.GET_PREDICTED_ASSET_PRICE_SUCCESS,
                payload: {
                    assetId,
                    availableSupply: response.data.data.asset.availableSupply,
                    feesRate: response.data.data.feesRate,
                    feesFixedAmount: response.data.data.feesFixedAmount,
                    hoursBeforeSecondaryMarketStarts: response.data.data.asset.hoursBeforeSecondaryMarketStarts,
                    isFromUserAction,
                    initialSellTotalPrice: priceWithFees,
                    initialSaleRealEndDate: response.data.data.asset.initialSaleRealEndDate,
                    price: response.data.data.price,
                    privateSaleEnded: response.data.data.privateSaleEnded,
                },
            });

            dispatch({
                type: UPDATE_ASSET_CURRENT_INFORMATION,
                payload: {
                    availableSupply: response.data.data.asset.availableSupply, currentValue: response.data.data.asset.assetCurrentValue, assetId
                }
            })

            if (updateCurrentPrice) {
                dispatch({
                    type: AssetActionType.UPDATE_ASSET_CURRENT_VALUE,
                    payload: {
                        assetId,
                        assetCurrentValue: response.data.data.asset.assetCurrentValue,
                    },
                });
            }
        });
    } catch (error) {
        if (axios.isCancel(error)) {
            source = getSource();
            if (callbackOnCancel)
                callbackOnCancel();
        }
        console.error('Error thown on getPredictedPrice: ', error);
        return dispatch({ type: AssetActionType.ASSETS_ERROR });
    }
};

export const getPriceAsset = (assetId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {

        const response: IHttpResponse = await AxiosConfig.get(`/assets/price/${assetId}`);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.GET_PRICE,
            payload: {
                data: response.data.data,
            },
        });

    } catch (error) {

        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const createAsset = (asset: IAdminAssetFormInitialValues): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {

        const response: IHttpResponse = await AxiosConfig.post('/assets/manage', asset);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.ASSETS_SUCCESS,
            payload: {
                data: [response.data.data],
                message: response.data.message,
            },
        });

    } catch (error) {
        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const editAssets = (asset: Partial<AssetInterface>): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<AssetInterface> = await AxiosConfig.post(ASSET_ENDPOINTS.MANAGE_ASSET, asset);
        showNotification(dispatch, response.data.message, response.data.status);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK)
            return dispatch({ type: AssetActionType.ASSETS_ERROR });

        return dispatch({
            type: AssetActionType.ASSET_UPDATE,
            payload: {
                asset: response.data.data,
                message: response.data.message,
            },
        });

    } catch (error) {
        return dispatch({ type: AssetActionType.ASSETS_ERROR });
    }
};

export const getAssetBySlug = (slug: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({
        type: AssetActionType.ASSETS_LOADING,
    });
    try {
        const response: IHttpResponse = await AxiosConfig.get(`/assets/name/${slug}`);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {

            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.ASSETS_SUCCESS,
            payload: {
                data: [{ ...response.data.data, fetchedAt: new Date() }],
                message: response.data.message,
            },
        });
    } catch (error) {

        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const getAllAssetsForSortByName = (selectedPage: any, sort: any): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<AssetInterface[]> = await AxiosConfig.get(`/assets/all/${selectedPage}?sort=${sort}`);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.ASSETS_SUCCESS,
            payload: {
                data: response.data.data,
                message: response.data.message,
            },
        });

    } catch (error) {

        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const getAssetsById = (assetId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<{ data: AssetInterface[], message: string }> = await AxiosConfig.get(`/assets/${assetId}`);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.ASSETS_SUCCESS,
            payload: {
                data: response.data.data.data,
                message: response.data.message,
            },
        });

    } catch (error) {

        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const getAllAssetsBySearchAndCategoryId = (selectedPage: number, search: string, categoryId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {

        dispatch({
            type: AssetActionType.ASSETS_LOADING,
        });

        const response: IHttpStrongTypedResponse<AssetInterface[]> = await AxiosConfig.get(`/assets/all/${selectedPage}?search=${search}&categoryId=${categoryId}&with=mainCategory&sort=-currentValue`);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.ASSETS_UPDATE,
            payload: {
                data: response.data.data,
                message: response.data.message,
            },
        });

    } catch (error) {

        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const uploadAssetImg = (file: any, slug: string, field: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    try {
        const form = new FormData();
        form.append('file', file);
        const response: IHttpResponse = await AxiosConfig.post(`/assets/uploadAssetImg/${slug}?for=${field}`, form);
        showNotification(dispatch, response.data.message, response.data.status);
        if (response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }
        return dispatch({
            type: AssetActionType.ASSETS_SUCCESS,
            payload: {
                data: [response.data.data],
                message: response.data.message,
            },
        });
    } catch (error) {
        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const actionDeleteAsset = (assetId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    try {

        const response: IHttpResponse = await AxiosConfig.delete(`/assets/${assetId}`);

        showNotification(dispatch, response.data.message, response.data.status);

        if (response.data.status !== HTTP_STATUS_OK) {

            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.DELETE_ASSET,
            payload: {
                assetId,
            },
        });
    } catch (error) {

        console.log('error: ', error);
        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const actionLaunchPrivateSell = (launchPrivateSellParams: ILaunchPrivateSellParams): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    try {
        const response: IHttpResponse = await AxiosConfig.post('/assets/launchPrivateSell/', {
            ...launchPrivateSellParams,
            initialEndDate: launchPrivateSellParams.initialEndDate && new Date(launchPrivateSellParams.initialEndDate).toISOString(),
            initialSaleDate: new Date(launchPrivateSellParams.initialSaleDate).toISOString()
        });

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {

            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }
        showNotification(dispatch, `${i18n.t('overviewSales.yourPrivateSale')} OK`, HTTP_STATUS_OK);
        return dispatch({
            type: AssetActionType.ASSETS_SUCCESS,
            payload: {
                data: [response.data.data],
                message: response.data.message,
            },
        });
    } catch (error) {
        console.log('error: ', (error as Error));
        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const actionFetchOwnAssets = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpResponse = await AxiosConfig.get('/assets/all/ownAssets');
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {

            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.ASSETS_SUCCESS,
            payload: {
                data: response.data.data,
                message: response.data.message,
            },
        });
    } catch (error) {

        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const actionGetPrivateSellStatus = (assetId: string, startDate?: string, endDate?: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    let queryRequest: string = '';

    if (startDate) {

        queryRequest += `startDate=${encodeURIComponent(startDate)}`;
    }

    if (endDate) {

        queryRequest += `${queryRequest ? '&' : ''}endDate=${encodeURIComponent(endDate)}`;
    }

    try {

        const response: IHttpResponse = await AxiosConfig.get(`/assets/privateSellStatus/${assetId}${queryRequest ? `?${queryRequest}` : ''}`);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {

            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.UPDATE_ASSET_KEYS,
            payload: {
                assetId,
                data: response.data.data,
                message: response.data.message,
            },
        });
    } catch (error) {

        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const actionFetchVipIncome = (assetId: string): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {

    try {

        const response: IHttpResponse = await AxiosConfig.get(`/assets/income/vip/${assetId}`);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {

            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: AssetActionType.ASSETS_ERROR,
            });
        }

        return dispatch({
            type: AssetActionType.UPDATE_ASSET_KEYS,
            payload: {
                assetId,
                data: response.data.data,
                message: response.data.message,
            },
        });

    } catch (error) {
        showNotification(dispatch, (error as Error).stack ?? '', 500);
        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const uploadS3ImgAsset = (file: any, forParam?: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    dispatch({
        type: AssetActionType.ASSETS_LOADING,
    });

    try {

        const reader = new window.FileReader();
        reader.readAsArrayBuffer(file);

        reader.onloadend = async () => {

            if (reader.result) {

                const formData = new FormData();
                formData.append('file', file);

                const response: IHttpResponse = await AxiosConfig.post(`/assets/upload/uploadS3ImgAsset/${forParam}`, formData);

                if (response.data.status !== HTTP_STATUS_OK) {
                    return dispatch({
                        type: AssetActionType.ASSETS_ERROR,
                    });
                }

                dispatch({
                    type: AssetActionType.ASSETS_SUCCESS,
                    payload: {
                        data: [response.data.data],
                        message: response.data.message,
                    },
                });

                return response.data.status;

            }
        };

    } catch (error) {

        return dispatch({
            type: AssetActionType.ASSETS_ERROR,
        });
    }
};

export const getMarketConfigurationByAsset = (assetId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: AssetActionType.ASSETS_MARKET_CONFIGURATION_LOADING });
        const response: IHttpStrongTypedResponse<IAssetMarketConfiguration> = await AxiosConfig.post(ASSET_ENDPOINTS.FETCH_ASSET_MARKET_CONFIGURATION_BASE_URL, { assetId });
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: AssetActionType.ASSETS_MARKET_CONFIGURATION_ERROR,
            });
        return dispatch({
            type: AssetActionType.ASSETS_MARKET_CONFIGURATION_SUCCESS,
            payload: {
                marketConfiguration: response.data.data,
                assetId,
            },
        });
    } catch (error) {
        return dispatch({
            type: AssetActionType.ASSETS_MARKET_CONFIGURATION_ERROR,
        });
    }
};

export const getTranslatedDescription = (assetId: string, lng: string): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: AssetActionType.GET_TRANSLATED_DESCRIPTION_LOADING });
        const response: IHttpStrongTypedResponse<{ description: string }> = await AxiosConfig.get(`${ASSET_ENDPOINTS.GET_TRANSLATED_DESCRIPTION}/${assetId}/${lng}`);
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: AssetActionType.GET_TRANSLATED_DESCRIPTION_ERROR,
            });
        return dispatch({
            type: AssetActionType.GET_TRANSLATED_DESCRIPTION_SUCCESS,
            payload: {
                description: {
                    lng,
                    value: response.data.data.description
                },
                assetId,
            },
        });
    } catch (error) {
        return dispatch({
            type: AssetActionType.GET_TRANSLATED_DESCRIPTION_ERROR,
        });
    }
}

export const fetchTopAssetsScores = (): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<IAssetScore[]> = await AxiosConfig.get(`${ASSET_ENDPOINTS.GET_TOP_ASSETS_SCORES}`);
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: AssetActionType.GET_TOP_ASSETS_SCORES_ERROR,
                message: response.data.message,
            });
        return dispatch({
            type: AssetActionType.GET_TOP_ASSETS_SCORES_SUCCESS,
            payload: {
                data: response.data.data,
            },
        });
    } catch (error) {
        return dispatch({
            type: AssetActionType.GET_TOP_ASSETS_SCORES_ERROR,
        });
    }
};

export const updateAsset = (assetId: string, payload: Partial<Omit<AssetInterface, '_id'>>): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({
        type: AssetActionType.UPDATE_ASSET_KEYS, payload: {
            assetId: assetId,
            data: {
                ...payload,
            }
        }
    })
};
