import { ISvgIconProps } from 'interfaces/SvgIcon.interface';
import * as React from 'react';

const Champions: React.FC<ISvgIconProps> = ({ ...props }) => {

    const defaultColor = "url(#linear-gradient-champions)";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="388.254" height="459.754" {...props} viewBox="0 0 388.254 459.754">
            <defs>
                <linearGradient id="linear-gradient-champions" x1="-0.258" y1="1.322" x2="0.248" y2="-0.414" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#798496" />
                    <stop offset="1" stop-color="#e7e9fd" />
                </linearGradient>
            </defs>
            <g id="Champions" transform="translate(-7682.617 -2601.297)">
                <g id="Believers" transform="translate(408.808)">
                    <g id="Feathers">
                        <g id="Groupe_5145" data-name="Groupe 5145" transform="translate(-4 -1)">
                            <path id="Tracé_3797" data-name="Tracé 3797" d="M7543.354,2933.005s9.563-32.274,44.272-41.574,48.005-26.911,48.005-26.911.9,35.26-35.7,64.333S7543.354,2933.005,7543.354,2933.005Z" transform="matrix(0.951, 0.309, -0.309, 0.951, 1251.136, -2096.398)" fill={props.color || defaultColor} />
                            <path id="Tracé_3798" data-name="Tracé 3798" d="M7543.354,2933.005s9.563-32.274,44.272-41.574,48.005-26.911,48.005-26.911.9,35.26-35.7,64.333S7543.354,2933.005,7543.354,2933.005Z" transform="translate(-398.102 1544.259) rotate(-11)" fill={props.color || defaultColor} />
                            <path id="Tracé_3799" data-name="Tracé 3799" d="M7543.354,2933.005s9.563-32.274,44.272-41.574,48.005-26.911,48.005-26.911.9,35.26-35.7,64.333S7543.354,2933.005,7543.354,2933.005Z" transform="translate(-340.562 4571.758) rotate(-33)" fill={props.color || defaultColor} />
                        </g>
                        <g id="Groupe_5146" data-name="Groupe 5146" transform="translate(7273.809 2814.483)">
                            <path id="Tracé_3797-2" data-name="Tracé 3797" d="M0,9.942S9.563,42.215,44.272,51.515,92.278,78.426,92.278,78.426s.9-35.26-35.7-64.333S0,9.942,0,9.942Z" transform="matrix(-0.951, 0.309, -0.309, -0.951, 150.192, 218.052)" fill={props.color || defaultColor} />
                            <path id="Tracé_3798-2" data-name="Tracé 3798" d="M0,9.942S9.563,42.216,44.272,51.516,92.277,78.426,92.277,78.426s.9-35.26-35.7-64.333S0,9.942,0,9.942Z" transform="translate(97.863 178.313) rotate(-169)" fill={props.color || defaultColor} />
                            <path id="Tracé_3799-2" data-name="Tracé 3799" d="M0,9.942S9.563,42.216,44.272,51.516,92.277,78.426,92.277,78.426s.9-35.26-35.7-64.333S0,9.942,0,9.942Z" transform="matrix(-0.839, -0.545, 0.545, -0.839, 77.392, 116.033)" fill={props.color || defaultColor} />
                        </g>
                    </g>
                    <g id="Contenders" transform="translate(339.51 9)">
                        <g id="Managers" transform="translate(363.5 55.095)">
                            <path id="Soustraction_37" data-name="Soustraction 37" d="M-210.009,268.172V199.617a83.129,83.129,0,0,0,15.543-2.274v45.37a173.409,173.409,0,0,0,32.876-24.838,185.784,185.784,0,0,0,37.058-49.89,296.88,296.88,0,0,0,17.356-43.216c4.512-13.96,8.766-29.573,12.642-46.406C-87.685,48.619-83.66,22.383-81.87,9.392l-112.6-40.923V5.1A83.133,83.133,0,0,0-210.009,2.83V-55.094L-64.745-2.3l-.8,6.933c-.508,4.373-12.811,107.824-45.412,171.642-33.28,65.141-86.374,86.854-88.618,87.746l-10.427,4.149Zm-8.229,0h0l-10.426-4.148c-2.244-.893-55.345-22.618-88.617-87.746-32.6-63.819-44.9-167.268-45.412-171.641L-363.5-2.3l145.263-52.8V2.819A83.123,83.123,0,0,0-233.781,5.05V-31.531L-346.375,9.392c1.789,12.986,5.812,39.215,12.664,68.971,3.876,16.833,8.13,32.447,12.642,46.406a296.856,296.856,0,0,0,17.356,43.215,186.15,186.15,0,0,0,37.1,50,171.644,171.644,0,0,0,32.827,24.762V197.4a83.116,83.116,0,0,0,15.543,2.231Z" transform="translate(6977.745 2691.682)" fill={props.color || defaultColor} />
                            <g id="Rookies" transform="translate(213.063 66.511)">
                                <path id="Tracé_3792" data-name="Tracé 3792" d="M5166.364,4890.094l-34.67,36.193h15.887l-14.522,75.234-31.864-43.56h-25.019l-14.964,86.935,20.087,20.26,14.413-76.684,32.012,44.236h24.656l19.6-106.422h13.439l-19.057-36.193m2-14.21,30.956,58.791h-20.352l-19.6,106.422h-35.923l-23.088-31.9-13.76,73.214-34.379-34.674,16.9-98.159h36.342l23.02,31.467,8.95-46.366h-25.377Z" transform="translate(1428.178 -2255.593)" fill={props.color || defaultColor} />
                                <path id="Tracé_3792_-_Contour" data-name="Tracé 3792 - Contour" d="M5169.567,4869.107l36.906,70.09h-23.807l-19.6,106.421H5121.5l-18.625-25.738-13.435,71.48-41.525-41.882,17.862-103.771h42l18.6,25.421,6.165-31.932h-30.121Zm-2,14.211h0l-15.353,16.027,15.354-16.026Zm11.11,39.1-13.107-24.894-23.847,24.894h11.145l-17.31,89.668-36.288-49.606h-19.357l-14,81.323,12.942,13.052,14.738-78.418,36.473,50.4h19.022l19.6-106.421Zm-35.972,8.387h0Zm49.868,0h-16.9l-19.6,106.421h-30.29l-27.549-38.07h0l27.549,38.07h30.29l19.6-106.421ZM5072.85,4954.1h0l-15.932,92.548,27.234,27.466,8.484-45.14-8.484,45.139-27.233-27.467Zm30.68,0h0l27.441,37.514h0Z" transform="translate(1427.97 -2255.921)" fill={props.color || defaultColor} />
                            </g>
                        </g>
                    </g>
                </g>
                <path id="Tracé_3800" data-name="Tracé 3800" d="M113.117,83.146V0l32.912,54.937,33.5-27.14,9.177,49.342,37.525-7.327-29.778,43.555ZM0,69.812l37.525,7.327L46.7,27.8l33.89,27.14L113.117,0V83.146L29.778,113.367Z" transform="translate(7763.627 2601.298)" fill={props.color || defaultColor} />
                <path id="Tracé_3801" data-name="Tracé 3801" d="M7876.744,2686.22l-64.573,23.426-1.727-29.615Z" transform="translate(-87.967 29)" fill={props.color || defaultColor} />
                <path id="Tracé_3802" data-name="Tracé 3802" d="M7810.444,2686.22l64.573,23.426,1.727-29.615Z" transform="translate(152.556 30.169)" fill={props.color || defaultColor} />
            </g>
        </svg>

    );
}

export default Champions;