import React from 'react';
import Euro2024AssetDisclaimerProps from './Euro2024AssetDisclaimerProps';
import './style.scss';
import StadiumEuro from 'assets/images/euro-2024/stadium_euro.webp';
import { withTranslation } from 'react-i18next';
import EuroCup from 'components/icons/EuroCup';
import Euro2024WinRules from '../euro-2024-win-rules/Euro2024WinRules';
import Application from 'Application';

const Euro2024AssetDisclaimer: React.FC<Euro2024AssetDisclaimerProps> = ({ t: translation }) => {
    return (
        <div className='euro-2024-asset-disclaimer'>
            <img src={StadiumEuro} alt="Stadium Euro 2024" />
            <div className='explanation'>
                <p className='no-margin title'><b>{translation('euro2024.asset.disclaimer.explanation.title')}</b></p>
                <p className='no-margin description italic'>{translation('euro2024.asset.disclaimer.explanation.description.1')}</p>
                {/* <p className='no-margin description'>{translation('euro2024.asset.disclaimer.explanation.description.2')}</p> */}
                <p className='no-margin description'>{translation('euro2024.asset.disclaimer.explanation.description.3', { amount: (3).toCurrency() })}</p>
                <p className='no-margin description'>{translation('euro2024.asset.disclaimer.explanation.description.4')}</p>
            </div>
            <div className='potential-gains'>
                <span className='cup-icon'><EuroCup /></span>
                <p className='no-margin title'><b>{translation('euro2024.asset.disclaimer.potential-gains.title')}</b></p>
                <p className='no-margin description'>{translation('euro2024.asset.disclaimer.potential-gains.description')}</p>
                <div className='rewards'>
                    <p className='no-margin reward'>
                        <label className='no-margin'><b>{translation('euro2024.asset.disclaimer.potential-gains.reward.1')}</b></label>
                        <label className='no-margin'>{translation('euro2024.asset.disclaimer.potential-gains.reward-by-card', { amount: (Application.getInstance().FootballEuroGains().quarterFinal).toCurrency() })}</label>
                    </p>
                    <p className='no-margin reward'>
                        <label className='no-margin'><b>{translation('euro2024.asset.disclaimer.potential-gains.reward.2')}</b></label>
                        <label className='no-margin'>{translation('euro2024.asset.disclaimer.potential-gains.reward-by-card', { amount: (Application.getInstance().FootballEuroGains().semiFinal).toCurrency() })}</label>
                    </p>
                    <p className='no-margin reward'>
                        <label className='no-margin'><b>{translation('euro2024.asset.disclaimer.potential-gains.reward.3')}</b></label>
                        <label className='no-margin'>{translation('euro2024.asset.disclaimer.potential-gains.reward-by-card', { amount: (Application.getInstance().FootballEuroGains().final).toCurrency() })}</label>
                    </p>
                </div>
                <Euro2024WinRules />
            </div>
        </div>
    );
};

export default withTranslation()(Euro2024AssetDisclaimer);