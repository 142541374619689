import { ZERO } from 'config/_const';
import IFootballStatisticsCard from 'interfaces/sports/football/statistics/IFootballStatisticsCard';

export default class FootballStatisticsCardViewModel {
    private yellow: number;
    private yellowred: number;
    private red: number;

    constructor(statistics: Partial<IFootballStatisticsCard>) {
        this.yellow = statistics.yellow ?? ZERO;
        this.yellowred = statistics.yellowred ?? ZERO;
        this.red = statistics.red ?? ZERO;
    }

    get Yellow(): number {
        return this.yellow;
    }

    get YellowRed(): number {
        return this.yellowred;
    }

    get Red(): number {
        return this.red;
    }
}
