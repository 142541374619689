import { Skeleton } from '@mui/material';
import React from 'react';
import { batch, useSelector } from 'react-redux';
import ITournamentLevelButtonProps from 'components/medium/tournament-level-button/ITournamentLevelButtonProps';
import { DEFAULT_ID_AS_STRING } from 'config/_const';
import usePersistentTeamManagement from 'hooks/team/use-persistent-team-management/usePersistentTeamManagement';
import useUserLeague from 'hooks/use-user-league/useUserLeague';
import { selectorConnectedUserId } from 'service/auth/selectors';
import useTournament from 'hooks/tournament/use-tournament/useTournament';

const withTournamentLevelButton = (Component: React.FC<ITournamentLevelButtonProps>) => {
    const MemoComponent: React.FC<ITournamentLevelButtonProps> = React.memo(Component);

    const Wrapper: React.FC = () => {
        const connectedUserId: string = useSelector(selectorConnectedUserId);
        const { getUserTeams, teamLoading, userTeams } = usePersistentTeamManagement({ userId: connectedUserId });
        const { userLeagueViewModel } = useUserLeague();
        const { fetchPreviousTournament} = useTournament();

        React.useEffect(() => {
            if (userTeams.length > 0 || connectedUserId === DEFAULT_ID_AS_STRING)
                return;
            batch(() => { 
                getUserTeams();
                fetchPreviousTournament();
            });
        }, []);

        if (teamLoading)
            return (
                <div>
                    <Skeleton animation="wave" variant="text" width={100} height="100%" />
                </div>
            );

        if (!userLeagueViewModel.LeagueAttribution.hasEnteredInLeague)
            return <></>;
        return <MemoComponent userLeagueViewModel={userLeagueViewModel} />;
    }

    return React.memo(Wrapper);
}

export default withTournamentLevelButton;