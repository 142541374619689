import Application from 'Application';
import { Dictionary, groupBy } from 'lodash';
import ITournamentLeagueAndResult from 'model/Tournament/ITournamentLeagueAndResult';
import { useSelector } from 'react-redux';
import { userIdSelector } from 'service/auth/selectors';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';
import TournamentResultViewModel from 'view-model/Tournament/TournamentResultViewModel/TournamentResultViewModel';
import IUseTournamentOff from './IUseTournamentOff';
import IUseTournamentOffProps from './IUseTournamentOffProps';

const useTournamentOff = ({ tournamentResults, tournamentPrizePool, activeLeague }: IUseTournamentOffProps): IUseTournamentOff => {
    const app: Application = Application.getInstance();
    const tournamentLeagues: LeagueViewModel[] = app.getLeagues(tournamentPrizePool);
    const sortedTournamentLeagues: LeagueViewModel[] = tournamentLeagues.sort((leagueA: LeagueViewModel, leagueB: LeagueViewModel) => leagueA.MinWalletShares > leagueB.MinWalletShares ? -1 : 1);
    const userId: string = useSelector(userIdSelector);
    const tournamentResultOfUser: TournamentResultViewModel | undefined = tournamentResults.find(result => result.User.Id.toString() === userId);
    const leagues: { [key: string]: ITournamentLeagueAndResult } = {};
    const tournamentResultsGroupByLeague: Dictionary<TournamentResultViewModel[]> = groupBy(tournamentResults, (t: TournamentResultViewModel) => t.League);
    sortedTournamentLeagues.forEach((league: LeagueViewModel) => {
        leagues[league.Name] = { league, results: tournamentResultsGroupByLeague[league.Name] ?? [] };
    });
    const sortedLeaguesAndResults: ITournamentLeagueAndResult[] = sortedTournamentLeagues.map((league: LeagueViewModel) => leagues[league.Name]);
    const defaultPodiumSize: number = 60;
    const tournamentResultsWithNoLeague: boolean = tournamentResults.filter(result => result.League !== undefined).length === 0;

    const tournamentResultOfActiveLeague: TournamentResultViewModel | undefined = tournamentResults.find(result => result.League === activeLeague.Name);
    return {
        tournamentResultOfUser,
        sortedLeaguesAndResults,
        defaultPodiumSize,
        userId,
        tournamentResultsWithNoLeague,
        tournamentResultOfActiveLeague
    };
};

export default useTournamentOff;
