import { EmptyString } from "config/_const";

export default class BrowserClientHelper {
    private static instance: BrowserClientHelper;

    constructor() {
        BrowserClientHelper.instance = this;
    }

    public static getInstance(): BrowserClientHelper {
        return BrowserClientHelper.instance ? BrowserClientHelper.instance : new BrowserClientHelper();
    }

    public getClientTimezone(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    public getClientLanguageFromNavigator(): string {
        return navigator.language;
    }

    public getClientLanguageCountry(): string {
        let locale: string = this.getClientLocaleFromIntl();
        if (locale.split('-')[1])
            return locale.split('-')[1];
        locale = this.getClientLanguageFromNavigator();
        return locale.split('-')[1] ?? EmptyString;
    }

    public getClientLocaleFromIntl(): string {
        return Intl.DateTimeFormat().resolvedOptions().locale;
    }
}