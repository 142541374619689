import { useEffect } from "react";
import IUserWithdrawalRequestsParams from "./IUserWithdrawalRequestsParams";
import IUseUserUserWithdrawalRequests from "./IUseUserUserWithdrawalRequests";
import { useDispatch, useSelector } from "react-redux";
import { getUserWithdrawalRequests, cancelUserWithdrawalRequests } from "service/user-withdrawal-requests/actions";
import { userWithdrawalRequestsLoadingSelector, selectUserWithdrawalRequests } from "service/user-withdrawal-requests/selector";
import WalletWithdrawalViewModel from "view-model/WalletWithdrawalViewModel/WalletWithdrawalViewModel";

const useUserWithdrawalRequests = (params: IUserWithdrawalRequestsParams): IUseUserUserWithdrawalRequests => {
    const dispatch = useDispatch();
    const userWithdrawalRequests: WalletWithdrawalViewModel[] = useSelector(selectUserWithdrawalRequests);
    const userWithdrawalRequestsLoading: boolean = useSelector(userWithdrawalRequestsLoadingSelector);

    useEffect(() => {
        if (!params.getAllAtComponentLoading)
            return;
        dispatch(getUserWithdrawalRequests());
    }, []);


    const cancelWithdrawalRequest = (id: string, callBackToStopLoading?: () => void) => {
        return dispatch(cancelUserWithdrawalRequests(id, callBackToStopLoading));
    }

    const getAllUserOpenWithdrawalRequests = () => {
        return dispatch(getUserWithdrawalRequests());
    }
    
    return {
        withdrawalRequests: userWithdrawalRequests,
        cancelWithdrawalRequest,
        loading: userWithdrawalRequestsLoading,
        getAllUserOpenWithdrawalRequests
    };
};

export default useUserWithdrawalRequests;