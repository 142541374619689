import IUseAccount from "hooks/use-account/IUseAccount";
import useAccount from "hooks/use-account/useAccount";
import React from "react";
import IInjectedAccount from "./IInjectedAccount";

const withAccount = <BaseProps,>(ChildComponent: React.ComponentType<BaseProps & IInjectedAccount>): React.MemoExoticComponent<React.FC<BaseProps>> => {
    const WrappedComponent: React.FC<BaseProps> = (baseProps: BaseProps) => {
        const { ...rest }: IUseAccount = useAccount();
        return <ChildComponent {...baseProps} {...rest} />
    };

    return React.memo<React.FC<BaseProps>>(WrappedComponent);
};

export default withAccount;