import React from "react";
import IScoreLabelProps from "./IScoreLabelProps"
import TrendScoreIcon from "components/icons/TrendScoreIcon";
import { EmptyString, MAX_TEAM_SCORE, ZERO } from "config/_const";
import './ScoreLabel.scss';
import Color from "view-model/Shared/Color";
import CategoriesIconsMap from "components/icons/categories/CategoriesIconsMap";
import TripleArrowsUp from "components/icons/arrows/TripleArrowsUp";
import withTooltip from "hoc/with-tooltip/withTooltip";

const ScoreLabel: React.FC<IScoreLabelProps> = ({ score = 0, backgroundColor, category, style = {}, canShowBoost, showMaxScore = false }) => {
    const bgColor = backgroundColor ?? Color.getColorByInputRatio(score);

    return (

        <div className="asset-score " style={{ background: `${bgColor}`, ...style }}>
            <div className="asset-score_icon">{category !== undefined ? CategoriesIconsMap[category.capitalizeFirstLetter()] : < TrendScoreIcon />}</div>
            <span className="asset-score_item">
                {score < ZERO ? "__" : score}
                {showMaxScore ? `/ ${MAX_TEAM_SCORE}` : EmptyString}
            </span>
            {canShowBoost &&
                <span className="asset-score_boosted">
                    <TripleArrowsUp />
                </span>
            }
        </div>
    )
}

export default withTooltip(ScoreLabel);