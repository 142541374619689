import { SELECTABLE_CURRENCIES } from "data/currencies";
import i18next from "i18next";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, InjectedFormProps, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { actionUpdateUserInformation } from "service/auth/actions";
import IUseAccount from "../../../hooks/use-account/IUseAccount";
import useAccount from "../../../hooks/use-account/useAccount";
import "./PostSignUpView.scss";
import { EmptyString, LOCAL_STORAGE_KNOWN_KEYS, PHONEPLACEHOLDER, TRENDEX_LANGUAGES } from "config/_const";
import IPostSignUpViewProps from "./IPostSignUpViewProps";
import { useHistory } from "react-router-dom";
import { COUNTRIES_LIST } from "data/countries";
import CircularLoader from "components/small/CircularLoader";
import ReduxStoreInterface from "model/ReduxStoreInterface";
import { getUserWallet } from 'service/wallet/actions';
import { welcomeImgLogo } from "data/SignUpInImages/authentificationImages";
import Welcome from "../welcome/Welcome";
import { generatePhoneInput, phoneFieldValidation } from "components/small/input/ReduxFormInput";
import GreenButton from "components/small/buttons/green-button/GreenButton";
import Application from "Application";
import { managementModal } from "service/modal/action";

const INITIAL_STATE_FORM = {
    firstName: "",
    lastName: "",
    email: "",
    pseudo: "",
    country: "",
    phone: "",
    language:
        localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.LANG) ??
        TRENDEX_LANGUAGES.ENGLISH,
};

const PostSignUpView: React.FC<
    InjectedFormProps<{}, IPostSignUpViewProps> & IPostSignUpViewProps
> = (props) => {

    const registerGiftViewModel = props.registerGiftViewModel;
    const { accountViewModel }: IUseAccount = useAccount();
    const isAuthStateLoadingWhileFormSubmit = useSelector(
        (state: ReduxStoreInterface) => state.auth.loading
    );
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const history = useHistory();
    const search = new URLSearchParams(window.location.search);
    const hasLandingParam: string | null = search.get('l');
    const onSuccessCallback = () => {
        props.onValidation();
        !hasLandingParam && history.push("/top-50");
    };

    const onClaimReward = () => {
        if (Application.getInstance().OnBoardingV2) {
            if (accountViewModel.IntendToBeVip)
                return dispatch(managementModal(false));
            props.onValidation();
            return;
        }

        setStep(1);
        return;
    }

    const updateOwnInformations = (
        formData: Record<string, string>
    ) => {
        dispatch(
            actionUpdateUserInformation(formData, undefined, onSuccessCallback)
        );
    };

    const handleUserInfoSubmit = (
        formData: Record<string, string>
    ): void => {
        !hasLandingParam && history.push("/my-portfolio");
        dispatch(getUserWallet(accountViewModel.Id, true));
        updateOwnInformations({ ...formData });
    };

    const generateSelect = (fieldProps: any) => {
        let data: {
            label: string | number;
            value: string | number;
        }[] = [];

        if (fieldProps.input.name === "country") {
            data = COUNTRIES_LIST.map((country: { code: string; name: string; }) => {
                return {
                    label: country.name,
                    value: country.code,
                };
            });
        }

        return (
            <div>
                <Form.Control
                    disabled={fieldProps.disabled}
                    {...fieldProps.input}
                    as="select"
                    className="cust_dropdown"
                    value={fieldProps.input.value || props.initialValues.country}
                >
                    <option value="" disabled selected hidden>
                        {i18next.t("Aboutus.tab.about.selectcountry")}
                    </option>
                    {data.map(
                        (
                            country: {
                                label: string | number;
                                value: string | number;
                            },
                            i: number
                        ) => {
                            return (
                                <option key={`opt_${i}`} value={country.value}>
                                    {country.label}
                                </option>
                            );
                        }
                    )}
                </Form.Control>

                {fieldProps.meta.touched &&
                    ((fieldProps.meta.error && (
                        <span style={{ color: "red" }}>
                            {i18next.t("Aboutus.error.country.isNotAValidCountry")}
                        </span>
                    )) ||
                        (fieldProps.meta.warning && (
                            <span style={{ color: "orange" }}>{fieldProps.meta.warning}</span>
                        )))}
            </div>
        );
    };

    const generateCurrencySelect = (fieldProps: any) => {
        let options: {
            label: string;
            value: string;
        }[] = SELECTABLE_CURRENCIES;

        return (
            <div>
                <Form.Control
                    disabled={fieldProps.disabled}
                    {...fieldProps.input}
                    as="select"
                    className="cust_dropdown"
                    value={fieldProps.input.value || props.initialValues.currency}
                >
                    {options.map(
                        (
                            option: {
                                label: string | number;
                                value: string | number;
                            },
                            i: number
                        ) => {
                            return (
                                <option key={`opt_${i}`} value={option.value}>
                                    {option.label}
                                </option>
                            );
                        }
                    )}
                </Form.Control>

                {fieldProps.meta.touched &&
                    ((fieldProps.meta.error && (
                        <span style={{ color: "red" }}>
                            {i18next.t("Aboutus.error.currency.isNotAValidCurrency")}
                        </span>
                    )) ||
                        (fieldProps.meta.warning && (
                            <span style={{ color: "orange" }}>{fieldProps.meta.warning}</span>
                        )))}
            </div>
        );
    };

    return (
        <div className="signUp-gift-view">
            <div className="welcome-img-container">
                <img src={welcomeImgLogo} alt="Welcome" />
            </div>
            {step === 0 &&
                <Welcome onClaimReward={onClaimReward} accountViewModel={accountViewModel} registerGiftViewModel={registerGiftViewModel} />
            }
            {!Application.getInstance().OnBoardingV2 && step == 1 && (
                <div className="profile-form">
                    <form
                        onSubmit={props.handleSubmit(handleUserInfoSubmit)}
                        id="user-profile-form"
                    >
                        <div className="field-container custinput_group col-md-12">
                            <label className="form_label">
                                {i18next.t("Aboutus.tab.about.preferred_country")}
                            </label>
                            <Field
                                name={"country"}
                                type="select"
                                component={generateSelect}
                            />
                        </div>
                        <div className="field-container custinput_group col-md-12">
                            <label className="form_label">
                                {i18next.t("Aboutus.tab.about.preferred_currency")}
                            </label>
                            <Field
                                name={"currency"}
                                type="select"
                                component={generateCurrencySelect}
                            />
                        </div>
                        <div className="field-container custinput_group col-md-12">
                            <label className="form_label">
                                {i18next.t("Aboutus.tab.about.phonenumber")}
                            </label>
                            <br />
                            <label className="sublabel">
                                {i18next.t("Aboutus.tab.about.phonenumber.explanation")}
                            </label>
                            <Field
                                name={"phone"}
                                country={props.formValues?.country.toLowerCase() ?? EmptyString}
                                component={generatePhoneInput}
                                mandatory={false}
                                validate={phoneFieldValidation}
                                placeholder={PHONEPLACEHOLDER}
                            />
                        </div>
                        <div className="field-container custinput_group button-submit col-md-12">
                            <GreenButton
                                receiveClickEvent={false}
                                type="submit"
                                textButton={i18next.t("Myportfolio.confirm")}
                                disabled={props.invalid}
                            />
                        </div>
                    </form>
                </div>
            )}
            {isAuthStateLoadingWhileFormSubmit && (
                <CircularLoader
                    loaderLocal={false}
                    loaderMessage={i18next.t("genericloading")}
                />
            )}
        </div>
    );
};

const mapStateToProps = (
    state: IPostSignUpViewProps,
    ownProps: IPostSignUpViewProps
) => {
    const formValues = getFormValues('PostSignUpForm')(state);
    return {
        initialValues: ownProps.initialValues
            ? ownProps.initialValues
            : INITIAL_STATE_FORM,
        formValues,
    };
};

const PostSignUpForm = reduxForm<{}, IPostSignUpViewProps>({
    form: "PostSignUpForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    enableReinitialize: true,
})(PostSignUpView);

export default connect(mapStateToProps)(PostSignUpForm);
