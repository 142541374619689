import React from 'react';
import { useDispatch } from 'react-redux';
import Tutorial from 'components/medium/tutorial-tournament/Tutorial';
import { LOCAL_STORAGE_KNOWN_KEYS, TOP_50_CAROUSEL_IMAGES, TOURNAMENT_TUTORIAL_MODAL } from 'config/_const';
import { managementModal } from 'service/modal/action';
import Utils from 'utils/Utils';
import IUseTournamentTutorial from './IUseTournamentTutorial';
import { getCarouselIdVideoLeagues } from 'data/YoutubeVideo/youtubeVideo.data';

const useTournamentTutorial = (): IUseTournamentTutorial => {
    const dispatch = useDispatch();
    const carouselImages =
        TOP_50_CAROUSEL_IMAGES[Utils.getCurrentLanguage().toUpperCase()] ??
        TOP_50_CAROUSEL_IMAGES.EN;

    const modalOptions = TOURNAMENT_TUTORIAL_MODAL === 'STORIES' ? {
        display: true,
        disableBackDrop: true,
        showBackArrow: false,
        showCancelButton: false,
        fullScreen: Utils.isMobile(),
        type: 'TOURNAMENT_TUTO',
        propsCustom: {
            message: <Tutorial />,
        },
    } : {
        display: true,
        disableBackDrop: false,
        showBackArrow: false,
        showCancelButton: false,
        fullScreen: false,
        type: 'VIDEO_MODAL',
        propsCustom: {
            bannerImage: carouselImages.LEAGUES,
            videoId: getCarouselIdVideoLeagues(),
        },
    }

    const handleTutorialClick = () => {
        localStorage.removeItem(LOCAL_STORAGE_KNOWN_KEYS.TUTORIAL);
        dispatch(managementModal(true, {
            ...modalOptions,
        }));
    };

    return {
        handleTutorialClick,
    };
};

export default useTournamentTutorial;
