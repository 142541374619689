import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import React from 'react';
import { batch, useDispatch } from 'react-redux';
import i18n from '../../../18n';
import IUseAccount from '../../../hooks/use-account/IUseAccount';
import useAccount from '../../../hooks/use-account/useAccount';
import IUseDialog from '../../../hooks/use-dialog/IUseDialog';
import useDialog from '../../../hooks/use-dialog/useDialog';
import { AssetRewardRegister } from '../../../model/RegisterGiftModel/RegisterGiftModel';
import { getUserWallet } from '../../../service/wallet/actions';
import Shares from '../../icons/Shares';
import AssetAvatar from '../../small/asset-avatar/AssetAvatar';
import IRegisterGiftViewProps from './IRegisterGiftViewProps';
import './style.scss';

const RegisterGiftView: React.FC<IRegisterGiftViewProps> = ({ registerGiftViewModel }: IRegisterGiftViewProps) => {
    const { accountViewModel }: IUseAccount = useAccount();
    const { closeDialog }: IUseDialog = useDialog();
    const dispatch = useDispatch();

    return (
        <div className='register-gift-view'>
            <div className="welcome-img-container">
                <img src="/img/welcome-register.png" alt='Welcome' />
            </div>

            <div className="title-container">
                <h3>{i18n.t('register.welcome')}</h3>
                {accountViewModel.Referral.usedReferralName && <p className="referral-name"> {i18n.t('referral.modal.title')} {accountViewModel.Referral.usedReferralName}</p>}
            </div>

            <div className="assets-container">
                {registerGiftViewModel.Assets.map((asset: AssetRewardRegister) => {
                    return (
                        <div key={asset._id} className="gift-container">
                            <div className="flex">
                                <div className="wallet-transaction-avatar" key={asset._id}>
                                    <AssetAvatar asset={{
                                        avatarPath: asset.icon,
                                        mainCategoryId: asset.mainCategory,
                                        cardLevel: asset.cardLevel
                                    }} />
                                    <div className="transaction-type-icon"><Shares color="black" /></div>
                                </div>

                                <div>
                                    <p className="no-margin asset-name">{asset.name}</p>
                                    <p className="no-margin amount-tokens">+{i18n.t('Assetprofilepage.topInvestorAmount', { amount: registerGiftViewModel.NbTokensPerAsset })}</p>
                                </div>
                            </div>

                            <div>
                                <p className="no-margin current-value"><span>{i18n.t('Asset.shareLabel')}/</span>{asset.currentValue.toCurrency()}</p>
                            </div>
                        </div>
                    )
                })}
            </div>

            <PrimaryButton
                receiveClickEvent={false}
                disabled={false}
                style={{
                    width: '65%',
                    marginTop: '40px',
                }}
                type="button"
                handleClick={() => {
                    batch(() => {
                        closeDialog();
                        dispatch(getUserWallet(accountViewModel.Id, true));
                    })
                }}
                textButton={i18n.t('confirm.register.gift')} />
        </div>
    );
};
export default RegisterGiftView;
