import { AssetCardLevel, AssetCategory } from 'config/_enums';
import _ from 'lodash';
import moment from 'moment';
import { ALL_CATEGORIES, ASSETS_CATEGORIES, ASSETS_SLUG, ASSETS_TO_HIDE_IN_TOP50, DAY_IN_MS, DEFAULT_FILTER_TOP_50, NUMBER_OF_HOURS_BEFORE_SORT_ASSET_BY_CURRENT_VALUE } from '../../config/_const';
import AssetInterface from '../../model/AssetModel/AssetInterface';
import Utils from '../../utils/Utils';
import AssetViewModel from '../../view-model/Assets/AssetViewModel';

export type SortFunction = (assets: AssetViewModel[]) => AssetViewModel[];

const getUpcomingAndRecentlyReleasedAssets = (assets: AssetViewModel[]) => {
    const now = Date.now();
    const threeDaysInMs = 3 * DAY_IN_MS;
    const upcoming: AssetViewModel[] = [];
    const recentlyReleased: AssetViewModel[] = [];
    for (const asset of assets) {
        if (!asset.InitialSaleDate || new Date(asset.InitialSaleDate).getTime() < now - threeDaysInMs)
            continue;
        if (new Date(asset.InitialSaleDate).getTime() > now)
            upcoming.push(asset);
        else
            recentlyReleased.push(asset);
    }
    const upComingAndRecentlyReleasedAssets: AssetViewModel[] = upcoming.concat(recentlyReleased);
    return { upComingAndRecentlyReleasedAssets, otherAssets: _.difference(assets, upComingAndRecentlyReleasedAssets) };
}

export const applySort = (sortFunction: SortFunction, sortFnName:string, assets: AssetViewModel[], sortDirection: number = 1, currentTab?: string): AssetViewModel[] => {
    const viewableAssets:AssetViewModel[] = (currentTab === ALL_CATEGORIES)
        ? assets.filter(asset => !ASSETS_TO_HIDE_IN_TOP50.includes(asset.AssetId))
        : assets;
    if(sortFnName !== DEFAULT_FILTER_TOP_50)
        return sortDirection === 1 
            ? sortFunction(viewableAssets) 
            : sortFunction(viewableAssets).reverse();

    let { upComingAndRecentlyReleasedAssets, otherAssets } = getUpcomingAndRecentlyReleasedAssets(viewableAssets);
    const assetsOrder: Record<string, number> = {
        [ASSETS_SLUG.ESPAGNE_EURO_2024]: 1,
        [ASSETS_SLUG.ANGLETERRE_EURO_2024]: 2,
        [ASSETS_SLUG.MARCUS_SMART]: 3,
        [ASSETS_SLUG.KARIM_BENZEMA]: 4,
        [ASSETS_SLUG.RUDY_GOBERT]: 5,
        [ASSETS_SLUG.RONALD_ARAUJO]: 6,
        [ASSETS_SLUG.JESSE_LINGUARD]: 7,
        [ASSETS_SLUG.RENATO_SANCHES]: 8,
    };
    
    const sortedAssets = sortFunction(otherAssets).sort((assetA: AssetViewModel, assetB: AssetViewModel) => {
        const positionA = assetsOrder[assetA.AssetSlug];
        const positionB = assetsOrder[assetB.AssetSlug];
        if (positionA && positionB)
            return positionA - positionB;
        else if (positionA)
            return -1;
        else if (positionB)
            return 1;
        return 0;
    });
    const finalArray: AssetViewModel[] = upComingAndRecentlyReleasedAssets.concat(sortedAssets)
    return sortDirection === 1 ? finalArray : finalArray.reverse();
};

export const sortByPlannedSale = (assets: AssetInterface[]) => {
    const now = new Date().getTime();
    const planned = assets.sort((asset) => {
        if (!asset.initialSaleDone) return 1;
        const hasInitialDate = Utils.isDefined(asset.initialSaleDate) && !Utils.isEmpty(asset.initialSaleDate);
        if (!hasInitialDate) return 1;
        const isInFuture = new Date(asset.initialSaleDate).getTime() > now;

        return isInFuture ? -1 : 1;
    });

    return planned;
};

export const sortByActiveSale = (assets: AssetInterface[]) => {
    const now = new Date().getTime();
    const active = assets.sort((asset) => {
        if (!asset.initialSaleDone) return 1;
        const hasInitialDate = Utils.isDefined(asset.initialSaleDate) && !Utils.isEmpty(asset.initialSaleDate);
        if (!hasInitialDate) return 1;
        const isNow = new Date(asset.initialSaleDate).getTime() <= now && new Date(asset.initialSaleRealEndDate).getTime() >= now;
        return isNow ? -1 : 1;
    });

    return active;
};

export const sortByTotalPriceChange = (assets: AssetInterface[]) => {
    return assets.sort((a: AssetInterface, b: AssetInterface) =>
        a.totalPriceChange > b.totalPriceChange ? -1 : 0);
};

export const sortByDate = (assets: AssetInterface[]) => {
    return assets.sort((first: AssetInterface, second: AssetInterface) => {
        if (!first || !second) {
            return 0;
        }
        if (!first.initialSaleDate) {
            return 1;
        }
        if (!second.initialSaleDate) {
            return -1;
        }
        if (moment().diff(moment(first.initialSaleDate), 'hours') <= NUMBER_OF_HOURS_BEFORE_SORT_ASSET_BY_CURRENT_VALUE) {
            return moment(second.initialSaleDate).diff(first.initialSaleDate);
        }
        const firstAssetCurrentValue = first.currentValue ?? 0;
        const secondAssetCurrentValue = second.currentValue ?? 0;
        return firstAssetCurrentValue > secondAssetCurrentValue ? -1 : 1;
    });
};

export const sortByListOrder = (assets: AssetInterface[]) => {
    const listOrderAssets = assets.filter(asset => typeof asset.listOrder === 'number').sort((a, b) => (a.listOrder || 0) - (b.listOrder || 0));
    const noListOrderAssets = assets.filter(asset => typeof asset.listOrder !== 'number');

    listOrderAssets.forEach((el) => {
        if (typeof el.listOrder === 'number') {
            noListOrderAssets.splice(el.listOrder, 0, el);
        }
    });

    return noListOrderAssets;
};

export const defaultAssetsSorter = (assets: AssetViewModel[], sortDirection: number = 1): AssetViewModel[] => {
    const assetsSortedByDefaultFilter = assets.sort((assetA: AssetViewModel, assetB: AssetViewModel) => {
        const assetAIndex = assets.indexOf(assetA);
        const assetBIndex = assets.indexOf(assetB);
        if (assetA.CardLevel === AssetCardLevel.Legend && assetB.CardLevel !== AssetCardLevel.Legend)
            return -1;
        else if (assetB.CardLevel === AssetCardLevel.Legend && assetA.CardLevel !== AssetCardLevel.Legend)
            return 1;
        else if (assetA.InitialSaleDate && !assetB.InitialSaleDate)
            return -1;
        else if (!assetA.InitialSaleDate && assetB.InitialSaleDate)
            return 1;
        else if (assetA.InitialSaleDate && assetB.InitialSaleDate) {
            const aLaunchDate = new Date(assetA.InitialSaleDate).getTime();
            const bLaunchDate = new Date(assetB.InitialSaleDate).getTime();
            if (!aLaunchDate && !bLaunchDate)
                return assetAIndex - assetBIndex;
            else if (!aLaunchDate)
                return 1;
            else if (!bLaunchDate)
                return -1;
            else
                return assetAIndex - assetBIndex;
        } else
            return assetAIndex - assetBIndex;
    });
    return sortDirection === 1 ? assetsSortedByDefaultFilter : assetsSortedByDefaultFilter.reverse();
};

export const sortAssetsByName = (assets: AssetViewModel[], sortDirection: number = 1): AssetViewModel[] => {
    const assetsSortedByName = assets.sort((assetA: AssetViewModel, assetB: AssetViewModel) => assetA.AssetName.localeCompare(assetB.AssetName));
    return sortDirection === 1 ? assetsSortedByName : assetsSortedByName.reverse();
};

export const sortAssetsByCurrentValue = (assets: AssetViewModel[], sortDirection: number = 1): AssetViewModel[] => {
    const assetsSortedByCurrentValue = assets.filter((asset: AssetViewModel) => asset.InitialSaleDate).sort((assetA: AssetViewModel, assetB: AssetViewModel) => assetA.CurrentValueAsNumber - assetB.CurrentValueAsNumber);
    return sortDirection === 1 ? assetsSortedByCurrentValue : assetsSortedByCurrentValue.reverse();
};


export const sortAssetsByYield = (assets: AssetViewModel[], sortDirection: number = 1): AssetViewModel[] => {
    const assetsSortedByMaxAPY = assets.filter((asset: AssetViewModel) => asset.YieldEnabled && asset.InitialSaleDate).sort((assetA: AssetViewModel, assetB: AssetViewModel) => assetB.getMaxAPY() - assetA.getMaxAPY());
    return sortDirection === 1 ? assetsSortedByMaxAPY : assetsSortedByMaxAPY.reverse();
};

export const sortAssetsByTotalPriceChange = (assets: AssetViewModel[], sortDirection: number = 1): AssetViewModel[] => {
    const assetsSortedByTotalPriceChange = assets.filter((asset: AssetViewModel) => asset.TotalPriceChange).sort((assetA: AssetViewModel, assetB: AssetViewModel) => assetB.PriceEvolutions.evolutionValue - assetA.PriceEvolutions.evolutionValue);
    return sortDirection === 1 ? assetsSortedByTotalPriceChange : assetsSortedByTotalPriceChange.reverse();
};

export const sortAssetsByIRLScore = (assets: AssetViewModel[], sortDirection: number = 1): AssetViewModel[] => {
    const assetsSortedByIRLScore = assets.filter((asset: AssetViewModel) => asset.InitialSaleDate).sort((assetA: AssetViewModel, assetB: AssetViewModel) => assetB.IRLScore - assetA.IRLScore);
    return sortDirection === 1 ? assetsSortedByIRLScore : assetsSortedByIRLScore.reverse();
};

export const sortAssetsByInitialSaleDate = (assets: AssetViewModel[], sortDirection: number = 1): AssetViewModel[] => {
    const assetsSortedByInitialSaleDate = assets.filter((asset: AssetViewModel) => asset.InitialSaleDate).sort((assetA: AssetViewModel, assetB: AssetViewModel) => assetB.InitialSaleDate.localeCompare(assetA.InitialSaleDate));
    return sortDirection === 1 ? assetsSortedByInitialSaleDate : assetsSortedByInitialSaleDate.reverse();
};

export const sortAssetsByCardLevels = (assets: AssetViewModel[], sortDirection: number = 1): AssetViewModel[] => {
    const assetsSortedByCardLevel = assets.sort((assetA: AssetViewModel, assetB: AssetViewModel) => assetB.CardLevel - assetA.CardLevel);
    return sortDirection === 1 ? assetsSortedByCardLevel : assetsSortedByCardLevel.reverse();
};

export const sortAssetsByCategoriesAndCardLevel = (assets: AssetViewModel[], sortDirection: number = 1): AssetViewModel[] => {
    const categoriesOrder: Record<string, number> = {
        [ASSETS_CATEGORIES[AssetCategory.Football]]: 1,
        [ASSETS_CATEGORIES[AssetCategory.Basketball]]: 2,
        [ASSETS_CATEGORIES[AssetCategory.Fight]]: 3,
        [ASSETS_CATEGORIES[AssetCategory.Rugby]]: 4,
        [ASSETS_CATEGORIES[AssetCategory.Music]]: 5,
        [ASSETS_CATEGORIES[AssetCategory.Tiktok]]: 6,
        [ASSETS_CATEGORIES[AssetCategory.Cinema]]: 7,
        [ASSETS_CATEGORIES[AssetCategory.Instagram]]: 8,
        [ASSETS_CATEGORIES[AssetCategory.Youtube]]: 9,
        [ASSETS_CATEGORIES[AssetCategory.Streamers]]: 10,
    };

    const assetsSortedByCategoriesAndCardLevel = assets.sort((assetA: AssetViewModel, assetB: AssetViewModel) => {
        const categoryComparison = categoriesOrder[assetA.MainCategoryId] - categoriesOrder[assetB.MainCategoryId];
        return categoryComparison === 0 ? assetB.CardLevel - assetA.CardLevel : categoryComparison;
    });

    return sortDirection === 1 ? assetsSortedByCategoriesAndCardLevel : assetsSortedByCategoriesAndCardLevel.reverse();
};

export const sortAssetsBySportScore = (assets: AssetViewModel[], sortDirection: number = 1): AssetViewModel[] => {
    const assetsSortedBySportsScore = assets.filter((asset: AssetViewModel) => asset.InitialSaleDate && asset.SportScore > 0).sort((assetA: AssetViewModel, assetB: AssetViewModel) => assetB.SportScore - assetA.SportScore);
    return sortDirection === 1 ? assetsSortedBySportsScore : assetsSortedBySportsScore.reverse();
};

