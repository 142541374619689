
import IUseTournament from "hooks/tournament/use-tournament/IUseTournament";
import useTournament from "hooks/tournament/use-tournament/useTournament";
import React from "react";
import { batch } from "react-redux";
import TournamentViewModel from "view-model/Tournament/TournamentViewModel/TournamentViewModel";

const withCurrentTournament = <BaseProps,>(Component: React.ComponentType<any>) => {

    const Wrapper: React.FC<BaseProps> = (args: BaseProps) => {
        const { tournamentInProgress, nextTournament, fetchTournamentInProgress, fetchNextTournament }: IUseTournament = useTournament();

        React.useEffect(() => {
            if (tournamentInProgress && nextTournament)
                return;
            batch(() => {
                fetchTournamentInProgress();
                if (!tournamentInProgress)
                    fetchNextTournament();
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [tournamentInProgress, nextTournament]);

        return <Component {...args as BaseProps} currentTournament={tournamentInProgress || nextTournament || new TournamentViewModel({})} />
    }
    return React.memo(Wrapper);
}

export default withCurrentTournament;