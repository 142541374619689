import Application from "Application";
import { LOCAL_STORAGE_KNOWN_KEYS } from "config/_const";
import IPersonalTournament from "model/Tournament/PersonalTournament/IPersonalTournament";
import PersonaTournamentStateEnum from "types/PersonalTournamentStateEnum";

export default class UserPersonalTournamentLocalStorageHelper {
    private tournament: IPersonalTournament;
    constructor() {
        const serializedTournament: string | null = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.USER_PERSONAL_TOURNAMENT);
        this.tournament = serializedTournament ? JSON.parse(serializedTournament) : { subscriptions: [], tournamentState: PersonaTournamentStateEnum.NONE, config: Application.getInstance().UserPersonalTournamentConfig };
    }

    public get Tournament(): IPersonalTournament {
        return this.tournament;
    }

    public set Tournament(tournament: IPersonalTournament) {
        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.USER_PERSONAL_TOURNAMENT, JSON.stringify(tournament));
    }
}