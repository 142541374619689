import i18n from '18n';
import CupDuotune from 'components/icons/tournament/league/Cup-duotune';
import RoiDuotone from 'components/icons/tournament/league/Roi-duotune';
import UserGroup from 'components/icons/tournament/league/UserGroup';
import ListCard from 'components/small/list-card/ListCard';
import React, { useMemo } from 'react'
import { ILeagueDetails } from './ILeagueDetailsProps';

export const LeagueDetails: React.FC<ILeagueDetails> = ({ userLeagueViewModel }) => {
    const leagueInformations = useMemo(() => [
        { id: "R1", show: true, icon: <CupDuotune />, label: i18n.t('leagueDetails-prize-perTournament'), value: userLeagueViewModel.PricePoolofLeagueFormatted },
        { id: "R2", show: true, icon: <CupDuotune />, label: i18n.t('leagueDetails-prize-annualized'), value: userLeagueViewModel.AnnualPricePoolFormatted },
        { id: "R3", show: true, icon: <UserGroup />, label: i18n.t('leagueDetails-max-leaguePlaces'), value: userLeagueViewModel.MaxSubscriptions },
        { id: "R4", show: false, icon: <RoiDuotone />, label: i18n.t('leagueDetails-annualized-current'), value: "--" },
        { id: "R5", show: false, icon: <RoiDuotone />, label: i18n.t('leagueDetails-annualized-minimum'), value: "--" },
    ], [userLeagueViewModel.PricePoolofLeagueFormatted, userLeagueViewModel.AnnualPricePoolFormatted, userLeagueViewModel.MaxSubscriptions]);

    return (
        <div className='league-details'>
            <ListCard informationsList={leagueInformations} />
        </div>
    )
}

export default React.memo(LeagueDetails)
