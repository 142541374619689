import ReduxRootState from "model/Redux/ReduxRootSate";
import ReduxActionInterface from "model/ReduxActionInterface";
import IUserTournament from "model/UserTournament/IUserTournament";
import UserTournamentActionType from "./actionType";

const ROOT_VALUE_STATE: ReduxRootState<IUserTournament[]> = {
    data: [],
    loading: false,
    error: ''
};

const handleUserTournamentDataUpdate = (prevUserTournaments: IUserTournament[], userTournament: IUserTournament) => {
    if (!prevUserTournaments.find((prevUserTournament => prevUserTournament.nameOfLeague === userTournament.nameOfLeague))) {
        return [...prevUserTournaments, userTournament]
    }
    return prevUserTournaments.map((prevUserTournament) => prevUserTournament.nameOfLeague === userTournament.nameOfLeague ? userTournament : prevUserTournament)
}

export const userTournamentReducer = (state: ReduxRootState<IUserTournament[]> = ROOT_VALUE_STATE, action: ReduxActionInterface<IUserTournament>) => {
    switch (action.type) {
        case (UserTournamentActionType.LOADING):
            return { ...state, loading: true };
        case (UserTournamentActionType.SUCCESS):
            return { ...state, loading: false, data: handleUserTournamentDataUpdate(state.data, action.payload) };
        case (UserTournamentActionType.ERROR):
            return { ...state, loading: false };
        default:
            return state;

    }
}

