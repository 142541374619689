import ReduxRootState from 'model/Redux/ReduxRootSate';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import i18n from '../../18n';
import AxiosConfig from '../../config/axiosConfig';
import { USER_ENDPOINTS } from '../../config/endpoints';
import { HTTP_STATUS_INTERNAL_ERROR, HTTP_STATUS_OK } from '../../config/_const';
import AssetWalletInterface from '../../model/AssetModel/AssetWalletInterface';
import { IHttpResponse, IHttpStrongTypedResponse } from '../../model/IHttpResponse';
import { RootState } from '../../model/Redux';
import { managementModal } from '../modal/action';
import { showNotification } from '../notification/action';
import * as UserActionType from '../users/actionsType';
import * as WalletActionType from './actionsType';
import WalletStoreData from './WalletStoreData';
import INumberOfSharesInTeam from 'view-model/Team/TeamViewModel/ISharesLocalTeam';

export const getUserWallet = (userId: string, transparentLoading?: boolean): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        if (!transparentLoading)
            dispatch({ type: WalletActionType.WALLET_LOADING });
        const getWalletResponse: IHttpStrongTypedResponse<WalletStoreData> = await AxiosConfig.get(`${USER_ENDPOINTS.GET_USER_WALLET}/${userId}`);
        const responseHttpCode: number = getWalletResponse.data.status;
        if (responseHttpCode !== HTTP_STATUS_OK && responseHttpCode !== HTTP_STATUS_INTERNAL_ERROR)
            return dispatch({ type: WalletActionType.WALLET_ERROR });

        if (responseHttpCode === HTTP_STATUS_INTERNAL_ERROR)
            throw new Error(i18n.t('acceptReferralTerms.error.internal'));
        return dispatch({
            type: WalletActionType.WALLET_SUCCESS,
            payload: {
                data: getWalletResponse.data.data,
            },
        });
    } catch (error) {
        return dispatch({ type: WalletActionType.WALLET_ERROR });
    }
};

export const actionStackAssetShares = (amount: number, assetId: string, userId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({ type: WalletActionType.WALLET_LOADING });

    try {
        const response: IHttpResponse = await AxiosConfig.post('/users/stackAssetShares', { amount, assetId });

        console.log('actionStackAssetShares response', response);
        if (response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: WalletActionType.WALLET_ERROR,
            });
        }

        dispatch(managementModal(false));

        showNotification(dispatch, response.data.message, response.data.status);

        dispatch(getUserWallet(userId));

        return dispatch({
            type: WalletActionType.WALLET_SUCCESS,
        });

    } catch (error) {
        console.log('error', error);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const updateUserAssetsAction = (assets: INumberOfSharesInTeam[]): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const states: RootState = getState();
    const storeWallet: ReduxRootState<WalletStoreData> = states.wallet;

    const tempUserAssets: AssetWalletInterface[] = [...storeWallet.data.ownedAssets];
    for (const asset of assets) {
        const walletEntryIndex: number = tempUserAssets.findIndex((entry: AssetWalletInterface) => entry.asset._id === asset.assetId);
        if (walletEntryIndex === -1)
            continue;
        const walletEntry: AssetWalletInterface = tempUserAssets[walletEntryIndex];
        walletEntry.amount += asset.amountOfShares;
    }

    dispatch({
        type: WalletActionType.UPDATE_OWNED_ASSETS,
        payload: {
            userAssets: tempUserAssets,
        }
    })
}
