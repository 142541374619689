import React from 'react';
import i18next from 'i18next';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import './Footer.scss';
import Application from 'Application';

class Footer extends React.Component {

    render = () => {

        const hiddenCordova = window.IS_CORDOVA ? 'hidden' : '';

        return (
            <footer className="footer_section">
                <div className="container">
                    <div className={`row ${hiddenCordova}`}>
                        <div className="col-md-12">
                            {/* <div className="footer_nav">
                                <div className="android_app">
                                    <a className="app_links" href="#">
                                        <img className="img-fluid" alt="app-store" src={appimg} />
                                    </a>
                                    <a className="app_links" href="#">
                                        <img className="img-fluid" alt="app-store" src={appaimg} />
                                    </a>
                                </div>
                            </div> */}

                            {/* <!-- copyrights Section Start --> */}
                            <div className="copyrights">
                                <div className="copyright-texts">
                                    <h4>{i18next.t('Footer.copyright')} © 2021</h4>
                                    <h4 className="copyright-bold-text">
                                        {i18next.t('Footer.madewithpassion', { brand: Application.getInstance().brand })}
                                    </h4>
                                    <h4>{i18next.t('Footer.allrightreserved')}</h4>
                                </div>
                                <div className="copyrights_social">
                                    {Application.getInstance().showSocialNetworks && <div className="icon-socials">
                                        <a href="https://www.facebook.com/Trendex_official-102851405294015" target="_blank"><FacebookIcon id="icon-fb" /></a>
                                        <a href="https://twitter.com/trendex_off" target="_blank"><TwitterIcon id="icon-twitter" /></a>
                                        <a href="https://www.instagram.com/trendex_official/" target="_blank"><InstagramIcon id="icon-insta" /></a>
                                    </div>}
                                </div>
                            </div>
                            {/* <!-- copyrights Section End --> */}

                        </div>
                    </div>
                </div>
            </footer>

        );
    }
}
export default Footer;
