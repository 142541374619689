import i18n from "18n";
import RerollArrow from "components/icons/RerollArrow";
import GreenButton from "components/small/buttons/green-button/GreenButton";
import React from "react";
import IScoreBoosterValueRerollProps from "./IScoreBoosterValueRerollProps";
import './ScoreBoosterValueReroll.scss';

const ScoreBoosterValueReroll: React.FC<IScoreBoosterValueRerollProps> = () => {
    return (
        <div className="boost-detail-container-reroll">
            <GreenButton type={"button"} icon={<RerollArrow width={20} height={20} />} textButton={i18n.t('score.booster.reroll')} receiveClickEvent={false} disabled={false} />
        </div>);
};

export default ScoreBoosterValueReroll;