import i18n from "18n";
import Application from "Application";
import { DEFAULT_ID_AS_STRING } from "config/_const";
import { STATE_SCOPE, TournamentLeagues } from "config/_enums";
import ICreditsPaymentState from "hooks/payment/ICreditsPaymentState";
import useBuyByCredits from "hooks/payment/useBuyByCredits";
import useTournament from "hooks/tournament/use-tournament/useTournament";
import useUserTournamentSubscription from "hooks/tournament/use-user-tournament-subscription/useUserTournamentSubscription";
import useModalDisplay from "hooks/use-modal-display/useModalDisplay";
import useSlidingPages from "hooks/use-sliding-pages/useSlidingPages";
import useUserLeague from "hooks/use-user-league/useUserLeague";
import IBuyScoreBoostPayload from "model/Payment/IBuyScoreBoostPayload";
import React from 'react';
import { useDispatch } from "react-redux";
import { getUserCredits } from "service/auth/actions";
import ScoreBoosterViewModel from "view-model/ScoreBoosterViewModel/ScoreBoosterViewModel";

const useTeamScoreBoostConfirmation = () => {
    const dispatch = useDispatch();
    const { hideSlidingPage } = useSlidingPages();
    const { showBuyScoreBoostSuccessMessage } = useModalDisplay();
    const { buyForScoreBoost } = useBuyByCredits({ showErrorsOnSecondaryMarketPaymentError: false });
    const { userLeagueViewModel } = useUserLeague();
    const { tournamentInProgress } = useTournament();
    const { currentUserSubscription, fetchUserTournamentSubscription } = useUserTournamentSubscription({ tournamentId: tournamentInProgress?.Id || DEFAULT_ID_AS_STRING, });
    const userSubscriptionId: string = currentUserSubscription?.TournamentSubscriptionId || DEFAULT_ID_AS_STRING;
    const tournamentSubscribedLeague: TournamentLeagues = currentUserSubscription?.League ?? userLeagueViewModel.LeagueAttribution.Name;
    const scoreBoosterViewModel: ScoreBoosterViewModel = React.useMemo(() => {
        return new ScoreBoosterViewModel({
            winningPoints: currentUserSubscription?.ScoreBooster.points || 0,
            avgTeamScore: 0,
            priceForBoost: Application.getInstance().getLeagueFromName(tournamentSubscribedLeague).PriceForTeamScoreBoost,
            isBoostAcquired: currentUserSubscription?.IsScoreBoosted || false,
        });
    }, [currentUserSubscription, tournamentSubscribedLeague]);

    const onBuyBoostSuccess = React.useCallback(() => {
        showBuyScoreBoostSuccessMessage(i18n.t('boost.score.buy.success.message'));
        dispatch(getUserCredits());
        dispatch(fetchUserTournamentSubscription());
        hideSlidingPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const onBuyBoostError = React.useCallback(() => {
        dispatch(getUserCredits());
    }, [dispatch]);

    const handleCreditBuyBoost = React.useCallback(() => {
        const boostData: IBuyScoreBoostPayload = {
            amount: scoreBoosterViewModel.Price,
            tournamentSubscriptionId: userSubscriptionId || DEFAULT_ID_AS_STRING
        }

        const boostCreditsPaymentState: ICreditsPaymentState = {
            assetId: DEFAULT_ID_AS_STRING,
            isDirectBuy: false,
            stateScope: STATE_SCOPE.PAYMENT,
            transactionSuccessCallback: onBuyBoostSuccess,
            transactionErrorCallback: onBuyBoostError,
        };
        buyForScoreBoost(boostCreditsPaymentState, boostData);
    }, [buyForScoreBoost, onBuyBoostError, onBuyBoostSuccess, scoreBoosterViewModel.Price, userSubscriptionId]);

    return {
        scoreBoosterViewModel,
        onBuyBoostSuccess,
        onBuyBoostError,
        handleCreditBuyBoost,
    }
}

export default useTeamScoreBoostConfirmation;
