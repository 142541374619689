import { ZERO } from "config/_const";
import ILiquidityPool from "interfaces/liquidity-pool/ILiquidityPool";
import ILiquidityPoolParams from "interfaces/liquidity-pool/ILiquidityPoolParams";
import LiquidityPool from './liquidityPool';


class WaveLiquidityPool extends LiquidityPool implements ILiquidityPool {
    private initialPrice: number;
    private growthRate: number;
    private amplitudeFactor: number;
    private offset: number;

    constructor(liquidityPool: ILiquidityPoolParams) {
        super(liquidityPool);
        this.initialPrice = 1;
        this.growthRate = Math.log(this.AssetCurveConfiguration.factors.endPrice / this.initialPrice) / this.InitialTokens;
        this.amplitudeFactor = (this.AssetCurveConfiguration.factors.endPrice - this.initialPrice) / (Math.exp(this.growthRate * this.InitialTokens) - 1);
        this.offset = this.initialPrice - this.amplitudeFactor;
    }

    private curve(x: number) {
        if (x < ZERO || !x)
            x = ZERO;
        const { power, modulationFactorParam, sinFactor, cosFactor } = this.AssetCurveConfiguration.factors;
        const initialTokens = this.InitialTokens;

        const adjustedX = Math.pow(x / initialTokens, power) * initialTokens;
        const modulationFactor = modulationFactorParam * (1 - x / initialTokens);
        const modulation = modulationFactor * (Math.sin(adjustedX / sinFactor) + Math.cos(adjustedX / cosFactor));
        const modulatedX = adjustedX * (1 + modulation);
        const finalX = Math.min(Math.max(modulatedX, ZERO), initialTokens - 1);
        return this.amplitudeFactor * Math.exp(this.growthRate * finalX) + this.offset;
    }

    private integrate(start: number, xout: number) {
        let sum = ZERO;
        for (let i = start; i < xout; i++) {
            sum += this.curve(i);
        }
        return sum;
    }

    getBuyPrice(xout: number) {
        if (xout === ZERO)
            return this.curve(xout);
        return this.integrate(this.CurveBalance, this.CurveBalance + xout);
    }

    getSellPrice(xin: number) {
        if (xin === ZERO)
            return this.curve(xin);
        return this.integrate(this.CurveBalance - xin, this.CurveBalance);
    }

    getMaxTokenWithAvailableCredit(credits: number) {
        const { power, sinFactor, cosFactor, modulationFactorParam } = this.AssetCurveConfiguration.factors;
        const initialTokens = this.InitialTokens;

        let totalCost = ZERO;
        let tokensBought = ZERO;

        for (let x = ZERO; x < initialTokens; x++) {
            const adjustedX = Math.pow(x / initialTokens, power) * initialTokens;
            const modulationFactor = modulationFactorParam * (1 - x / initialTokens);
            const modulation = modulationFactor * (Math.sin(adjustedX / sinFactor) + Math.cos(adjustedX / cosFactor));
            const modulatedX = adjustedX * (1 + modulation);
            const finalX = Math.min(Math.max(modulatedX, ZERO), initialTokens - 1);
            const price = this.amplitudeFactor * Math.exp(this.growthRate * finalX) + this.offset;

            if (totalCost + price > credits) {
                break;
            }

            totalCost += price;
            tokensBought++;
        }

        return tokensBought;
    }
}

export default WaveLiquidityPool;