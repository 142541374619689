import * as React from 'react';

const CalenderStar = ({ height = "20px", width = "20px", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg id="calender-star-svg" height={height} width={width}{...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g id="Rectangle_2014" data-name="Rectangle 2014" fill="#8187d0" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="20" height="20" stroke="none" />
                <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
            </g>
            <g id="calendar-star-duotone" transform="translate(2.946 1.938)">
                <path id="Tracé_3201" data-name="Tracé 3201" d="M5.038,1.008V2.015H9.069V1.008a1.008,1.008,0,0,1,2.015,0V2.015H12.6a1.512,1.512,0,0,1,1.512,1.512V6.046H0V3.527A1.512,1.512,0,0,1,1.512,2.015H3.023V1.008a1.008,1.008,0,0,1,2.015,0Zm3.051,8.4,1.663.242a.326.326,0,0,1,.179.557l-1.2,1.171.283,1.653a.329.329,0,0,1-.476.346L7.054,12.6l-1.486.781a.329.329,0,0,1-.476-.346l.283-1.653-1.2-1.171a.326.326,0,0,1,.179-.557l1.663-.242L6.761,7.9a.327.327,0,0,1,.586,0Z" fill="#8187d0" />
                <path id="Tracé_3202" data-name="Tracé 3202" d="M14.108,200.565a1.512,1.512,0,0,1-1.512,1.512H1.512A1.512,1.512,0,0,1,0,200.565V192H14.108Zm-6.761-6.711a.327.327,0,0,0-.586,0l-.743,1.505-1.663.242a.326.326,0,0,0-.179.557l1.2,1.171-.283,1.653a.329.329,0,0,0,.476.346l1.458-.781,1.515.781a.329.329,0,0,0,.476-.346l-.283-1.653,1.2-1.171a.326.326,0,0,0-.179-.557L8.09,195.36Z" transform="translate(0 -185.954)" fill="#8187d0" opacity="0.4" />
            </g>
        </svg>

    );
}

export default CalenderStar;
