import * as Sentry from "@sentry/react";
import firebase from 'firebase/app';
import 'firebase/messaging';
import { LOCAL_STORAGE_KNOWN_KEYS, VAPID_KEY } from './config/_const';

const firebaseConfig = {
    apiKey: 'AIzaSyBTWYkGskhwzJPKMFS2jwH8UyttRI8Pjls',
    authDomain: 'trendex-317313.firebaseapp.com',
    projectId: 'trendex-317313',
    storageBucket: 'trendex-317313.appspot.com',
    messagingSenderId: '274765803953',
    appId: '1:274765803953:web:fc60979b8de8e94400cd90',
    measurementId: 'G-CT5GBP2GKR',
};

let messaging: firebase.messaging.Messaging | undefined;;

try {
    firebase.initializeApp(firebaseConfig);
    messaging = firebase.messaging();
} catch (exception) {
    console.error('Error during initialize firebase messaging');
}

export const getToken = (sendToken: (token: string) => void) => {
    if (!messaging)
        return;

    return messaging.getToken({ vapidKey: VAPID_KEY }).then((currentToken: string) => {
        if (!currentToken)
            return
        const token: string | null = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_DEVICE_TOKEN);
        if (!token || token !== currentToken) {
            sendToken(currentToken);
            localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_DEVICE_TOKEN, currentToken);
        }
    }).catch((exception: any) => {
        console.log('An error occurred while retrieving token. ', exception);
        Sentry.captureException(exception);
    });
};
