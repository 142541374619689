import { RootState } from 'model/Redux';
import { useSelector } from 'react-redux';
import { getRewardByTournamentId } from 'service/tournamentReward/actions';
import { tournamentRewardsSelector } from 'service/tournamentReward/selector';
import TournamentRewardViewModel from 'view-model/Tournament/TournamentRewardViewModel/TournamentRewardViewModel';
import IUseTournamentRewards from './IUseTournamentRewards';
import IUseTournamentRewardParams from './IUseTournamentRewardsParams';

const useTournamentRewards = ({ tournamentId }: IUseTournamentRewardParams): IUseTournamentRewards => {
    const tournamentRewards: TournamentRewardViewModel[] = useSelector((state: RootState) => tournamentRewardsSelector(state, tournamentId));
    const tournamentRewardsLoading: boolean = useSelector((state: RootState) => state.tournamentReward.loading);
    return {
        tournamentRewards,
        tournamentRewardsLoading,
        fetchCurrentTournamentRewards: () => getRewardByTournamentId(tournamentId),
    };
};

export default useTournamentRewards;
