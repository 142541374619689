import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrimaryButton from "components/small/buttons/primary-button/PrimaryButton";
import i18next from "i18next";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Field,
  InjectedFormProps,
  SubmissionError,
  reduxForm,
} from "redux-form";
import { containsLetterAndNumber, hasMinimumLength, isValidPassword } from "utils/validator";
import Visibility from "../../assets/images/reset-password/eye-duotone.svg";
import VisibilityOff from "../../assets/images/reset-password/eye-slashed.svg";
import imageResetPassword from '../../assets/images/reset-password/reset_password_logo.png';
import * as Input from '../../components/small/input/ReduxFormInput';
import { resetPassword } from "../../service/auth/actions";
import { managementNotification as managementNotificationAction } from "../../service/notification/action";
import "./ResetPassword.scss";

interface ResetPasswordProps extends RouteComponentProps {
  managementNotification: (stateNotification: boolean, message?: string, status?: number) => void;
  resetPassword: (token: string, password: string, email: string, history: any, redirectAfterLogin: string, callback?: Function) => void;
}

interface ResetPasswordState {
  newPassword: string;
  confirmPassword: string;
  showPassword: boolean;
  password: string;
  passwordNotMatching: boolean;
  passwordHas8Characters: false;
  passwordContainsNumbersAndLetters: false;
}
class ResetPassword extends React.Component<InjectedFormProps<ResetPasswordState, ResetPasswordProps> & ResetPasswordProps,
  { showPassword: boolean, showConfirmPassword: boolean, password: string, confirmPassword: string, passwordNotMatching: boolean, checkIfAtLeast8Characters: boolean, checkIfHaveNumbersAndLetters: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      password: '',
      confirmPassword: '',
      passwordNotMatching: false,
      checkIfAtLeast8Characters: false,
      checkIfHaveNumbersAndLetters: false,
    };
  }
  validators = {
    required: (message: string) => (value: any) => value ? undefined : message,
    minLengthAndContainsUpperCaseOrDigit: (_message: string) => (value: string) => isValidPassword(value)
  };

  savePasswordAndConfirmPassword = (pass: string, isConfirmPassword: boolean) => {
    if (isConfirmPassword) {
      this.setState({ confirmPassword: pass });
    } else {
      // if(this.state.confirmPassword !== '') {
      //   this.setState({confirmPassword : ''});
      // }
      this.setState({ password: pass });
    }
    this.checkRulesPassword(pass);
  }

  checkRulesPassword = (pass: string) => {
    this.setState({ checkIfAtLeast8Characters: hasMinimumLength(pass) });
    this.setState({ checkIfHaveNumbersAndLetters: containsLetterAndNumber(pass) });
    if (this.state.password !== pass && this.state.confirmPassword !== pass) {
      this.setState({ passwordNotMatching: true });
    } else {
      this.setState({ passwordNotMatching: false });
    }
  };

  handleFailure = (message: string) => {
    this.props.managementNotification(true, message, 400);
  };

  handleSuccess = (message: string) => {
    this.props.managementNotification(true, message);
    this.props.reset();
  };

  resetPassword = async (values: any) => {
    if (values.newPassword !== values.confirmPassword) {
      this.handleFailure(i18next.t("resetPassword.notMatched"));
      throw new SubmissionError({
        _error: "passwords does not matched",
      });
    }
    const token = new URLSearchParams(this.props.location.search).get("token");
    const email = new URLSearchParams(this.props.location.search).get("email");
    const password = values.newPassword;
    if (!token || !email || !password)
      return this.handleFailure(i18next.t("resetPassword.urlParamsNotFound"));
    try {
      const redirect = '/top-50';
      this.props.resetPassword(token, password, email, this.props.history, redirect, () => this.handleSuccess(i18next.t("resetPassword.success")))
    } catch (error) {
      this.handleFailure(error.toString());
      throw new SubmissionError({
        _error: "An error occurred while submitting contact form",
      });
    }
  };

  render = () => {
    const { handleSubmit } = this.props;
    return (
      <div className="reset-password-page">
        <div className="reset_password_structure">
          <div className="reset-password-top-section">
            <img className='img-reset-password' src={imageResetPassword} alt="resetPassword" />
          </div>
          <h2 className="title-reset-password">{i18next.t("resetPassword.title")}</h2>
          <form
            className="reset_password_form"
            onSubmit={handleSubmit(this.resetPassword)}
          >
            <div className="fields-container">
              <div className="field-container custinput_group">
                <img src={this.state.showPassword ? VisibilityOff : Visibility} alt={this.state.showPassword ? 'VisibilityOff' : 'Visibility'} className="password-icon-toggle" onClick={() => { this.setState({ showPassword: this.state.showPassword ? false : true }) }} />
                <Field
                  id={"newPassword"}
                  name={"newPassword"}
                  type={this.state.showPassword ? "text" : "password"}
                  variant="standard"
                  className="register-input-field"
                  placeholder={i18next.t("resetPassword.placeholder")}
                  component={Input.generateInputText}
                  onChange={(e: any) => {
                    this.savePasswordAndConfirmPassword(e.target.value.trim(), false);
                  }}
                />
              </div>
              <div className="field-container custinput_group m-0">
                <img src={this.state.showConfirmPassword ? VisibilityOff : Visibility} alt={this.state.showConfirmPassword ? 'VisibilityOff' : 'Visibility'} className="password-icon-toggle" onClick={() => { this.setState({ showConfirmPassword: this.state.showConfirmPassword ? false : true }) }} />
                <Field
                  id={"confirmPassword"}
                  name={"confirmPassword"}
                  type={this.state.showConfirmPassword ? "text" : "password"}
                  variant="standard"
                  className="register-input-field"
                  placeholder={i18next.t("resetPassword.re-placeholder")}
                  onChange={(e: any) => {
                    this.savePasswordAndConfirmPassword(e.target.value.trim(), true);
                  }}
                  component={Input.generateInputText}
                />
              </div>
              {this.state.passwordNotMatching ? (<p className="error"><FontAwesomeIcon className='icon' icon={faTimesCircle} />{i18next.t("resetPassword.notCorresponding")}</p>) : null}
              <div className="contain-text">
                <p className={'text'}><FontAwesomeIcon className={this.state.checkIfAtLeast8Characters ? 'icon green' : 'icon red'} icon={this.state.checkIfAtLeast8Characters ? faCheckCircle : faTimesCircle} />{i18next.t("resetPassword.conditionOne")}</p>
                <p className={'text'}><FontAwesomeIcon className={this.state.checkIfHaveNumbersAndLetters ? 'icon green' : 'icon red'} icon={this.state.checkIfHaveNumbersAndLetters ? faCheckCircle : faTimesCircle} />{i18next.t("resetPassword.conditionTwo")}</p>
              </div>
              <div className="container-btn">
                <PrimaryButton
                  style={{ fontSize: "15px", fontWeight: "bold", height: "50px", borderRadius: "11px" }}
                  receiveClickEvent={false}
                  disabled={!this.state.checkIfAtLeast8Characters || !this.state.checkIfHaveNumbersAndLetters || this.state.passwordNotMatching ? true : false}
                  textButton={i18next.t("resetPassword.submit")}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const ResetPasswordFormRedux = reduxForm<
  ResetPasswordState,
  ResetPasswordProps
>({
  form: "resetPassword",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
})(ResetPassword);

export default connect(null, {
  managementNotification: managementNotificationAction,
  resetPassword
})(ResetPasswordFormRedux);
