import { ILeagueColorIconProps } from "./ILeagueColorIconProps"
import React from "react"
import './LeagueColorIcon.scss'

const LeagueColorIcon: React.FC<ILeagueColorIconProps> = (props) => {
    return (
        <div className='league-color-icon-component' 
            style={{
                background: props.assetLeague.getGradientBackground(130)
                }}>
        </div>
    )
}

export default LeagueColorIcon