import * as React from "react";
import NFunAssetLogo from 'assets/images/fun-assets/n-fun-cards.webp';
const FunCardsIcon: React.FC = () => {
    return (
        <div className="container-icon-fun-card">
            <img style={{ width: "20px", height: '25px', marginBottom: '7px' }} src={NFunAssetLogo} alt="fun cards icon" />
        </div>
    );
}

export default FunCardsIcon;
