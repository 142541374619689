import { AddCircle } from '@material-ui/icons';
import clsx from 'clsx';
import LeagueAccess from 'components/small/league-header/league-access/LeagueAccess';
import FilledSlot from 'components/small/team/filled-slot/FilledSlot';
import UnfilledSlot from 'components/small/team/unfilled-slot/UnfilledSlot';
import { DEFAULT_ID_AS_STRING, TOURNAMENT_CATEGORY } from 'config/_const';
import withCurrentTournament from 'hoc/with-tournament/withCurrentTournament';
import useAssetsCategories from 'hooks/asset-categories/useAssetCategories';
import IUseAllAssets from 'hooks/asset/use-all-assets/IUseAllAssets';
import useAllAssets from 'hooks/asset/use-all-assets/useAllAssets';
import IUseTournament from 'hooks/tournament/use-tournament/IUseTournament';
import useTournament from 'hooks/tournament/use-tournament/useTournament';
import useUserTournamentSubscription from 'hooks/tournament/use-user-tournament-subscription/useUserTournamentSubscription';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import useUserLeague from 'hooks/use-user-league/useUserLeague';
import IUseWallet from 'hooks/wallet/use-wallet/IUseWallet';
import useWallet from 'hooks/wallet/use-wallet/useWallet';
import { ICategoryTypeName } from 'interfaces/IAssetBgImages';
import AssetInterface from 'model/AssetModel/AssetInterface';
import ITeamAsset from 'model/Team/ITeamAsset';
import React from 'react';
import { compose } from 'redux';
import { showAssetScore } from 'service/teams/helper';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import AvatarViewModel from 'view-model/AvatarViewModel/AvatarViewModel';
import ITeamAssetsProps, { IBaseTeamAssetsScoreProps } from './ITeamAssetsProps';
import TeamAssetsScore from './TeamAssetsScore/TeamAssetsScore';
import TeamAssetScoreDetails from './local components/TeamAssetScoreDetails/TeamAssetScoreDetails';
import './style.scss';

const TeamAssets: React.FC<ITeamAssetsProps> = ({ teamScoreOnTop, embededInModal, showRemoveIcon, showGetButton, teamBuildingViewModel, deleteAssetFromLocalTeam, getAssetViewModelsFromTeamAssets, isCollapsed, showTokenPerSlot, currentTournament }) => {
    const { accountViewModel }: IUseAccount = useAccount();
    const { assetsCategories } = useAssetsCategories();
    const { walletViewModel }: IUseWallet = useWallet({ considerTeamShares: true });
    const { nextTournament, tournamentInProgress }: IUseTournament = useTournament();
    const { currentUserSubscription } = useUserTournamentSubscription({ tournamentId: tournamentInProgress?.Id || DEFAULT_ID_AS_STRING });
    const tournamentType: TOURNAMENT_CATEGORY = (currentTournament.Category) as TOURNAMENT_CATEGORY;
    const teamIRLScore: number = teamBuildingViewModel.computeTeamsScores(getAssetViewModelsFromTeamAssets(teamBuildingViewModel.teamAssets), assetsCategories, TOURNAMENT_CATEGORY.DEFAULT, currentUserSubscription);
    const { userLeagueViewModel } = useUserLeague();
    const { getAssetViewModelById }: IUseAllAssets = useAllAssets();
    const isTeamCompleted: boolean = teamBuildingViewModel.isComplete(getAssetViewModelById);
    const isTeamIRLScoreBoosted = teamBuildingViewModel.isBoostApplicablePerTouranamentCategory(TOURNAMENT_CATEGORY.DEFAULT, currentUserSubscription);
    const isTeamScoreBoosted = teamBuildingViewModel.isBoostApplicablePerTouranamentCategory(tournamentType as TOURNAMENT_CATEGORY, currentUserSubscription);
    const { showTrendScore, showSportsScore } = showAssetScore(nextTournament, tournamentInProgress);
    const numberOfSlotsRemaining: number = teamBuildingViewModel.configuration.maxNumberOfAssets - teamBuildingViewModel.teamAssets.length;
    const teamScoreAverage: number = teamBuildingViewModel.computeTeamsScores(walletViewModel.getAssetsFromWallet(), assetsCategories, currentTournament?.Category as TOURNAMENT_CATEGORY, currentUserSubscription)

    return (
        <div className={'team-assets-component'} key={teamBuildingViewModel.id}>
            {teamScoreOnTop && <TeamAssetsScore teamScore={teamScoreAverage} />}

            <div className={clsx('team-assets-list', {
                'embeded-modal': embededInModal,
            })}>
                {teamBuildingViewModel.teamAssets.map((teamAsset: ITeamAsset) => {
                    const asset: AssetInterface | undefined = walletViewModel.getWalletAssetById(teamAsset.asset)?.asset;
                    if (!asset)
                        return <div key={teamAsset.asset} className="team-members">
                            <UnfilledSlot showAddButton={false} />
                        </div>;
                    const assetViewModel: AssetViewModel = new AssetViewModel(asset);
                    const assetAvailableForTournament: boolean = walletViewModel.isOwnedAssetAvailableForTeam(accountViewModel, assetViewModel.AssetId, currentTournament.Category);
                    const numberOfShares: number = teamAsset.numberOfShares;
                    const category: ICategoryTypeName = assetViewModel.getCategoryName(assetsCategories) as ICategoryTypeName;
                    const avatarBackground: AvatarViewModel = new AvatarViewModel({ asset: { avatarPath: assetViewModel.AvatarPath, mainCategoryId: assetViewModel.MainCategoryId, cardLevel: assetViewModel.CardLevel }, assetCategories: assetsCategories })

                    return (
                        <div key={teamAsset.asset} className='team-members'>
                            <FilledSlot
                                assetViewModel={assetViewModel}
                                numberOfShares={numberOfShares}
                                hasFocus={false}
                                applyStackedEffect={false}
                                backgroundImgPath={avatarBackground.BackgroundImage}
                                borderColor={avatarBackground.Color}
                                onRemove={() => deleteAssetFromLocalTeam && deleteAssetFromLocalTeam(assetViewModel.AssetId)}
                                showGetButton={showGetButton}
                                showRemoveIcon={showRemoveIcon}
                                textButton="TeamManagement.button.edit"
                                iconButton={<AddCircle />}
                                blurEffect={!assetAvailableForTournament}
                                category={category}
                                canShowSportScore={assetViewModel.canShowSportScore(assetsCategories) && showSportsScore && assetAvailableForTournament}
                                showTokenPerSlot={showTokenPerSlot}
                                canShowTrendScore={showTrendScore && assetViewModel.ShowTrendScore}
                            />
                        </div>
                    );
                }).concat(Array.from({ length: numberOfSlotsRemaining }).map((_, index: number) => {
                    return <div key={`unfilled-slot-${index}`} className="team-members">
                        <UnfilledSlot showAddButton={false} />
                    </div>;
                }))}
            </div>

            {!teamScoreOnTop && <>
                <TeamAssetScoreDetails
                    teamSportScore={teamScoreAverage}
                    teamIRLScore={teamIRLScore}
                    tournamentVM={currentTournament}
                    isCollapsed={isCollapsed}
                    isTeamCompleted={isTeamCompleted}
                    isTeamIRLScoreBoosted={isTeamIRLScoreBoosted}
                    isTeamScoreBoosted={isTeamScoreBoosted}
                />
                {(!isCollapsed && userLeagueViewModel.LeagueAttribution.hasEnteredInLeague) &&
                    <div className="team-asset__checklist">
                        <LeagueAccess canShowTeamData={true} showMaxSubscriptions={false} tournamentLeague={userLeagueViewModel.LeagueAttribution.League} isTeamCompleted={isTeamCompleted} />
                    </div>
                }
            </>}
        </div>
    );
};

export default compose<React.FC<IBaseTeamAssetsScoreProps>>(
    withCurrentTournament
)(TeamAssets);
