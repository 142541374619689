import { useEffect, useRef, useState } from "react";
import IUseVideoSlideProps from "./IUseVideoSlideProps";
import { YouTubeProps } from "react-youtube";
import { IUseVideoSlide } from "./IUseVideoSlide";

const YT_PLAYER_STATE = {
    UNSTARTED: -1,
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING: 3,
}

const useVideoSlide = (props: IUseVideoSlideProps): IUseVideoSlide => {
    const { setCarouselAutoPlay } = props;
    const timeInMs = 2000;
    const videoTargetRef = useRef<any>(null);
    const showBannerTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const isOnCarouselView = !props.isNotOnCarouselView;

    useEffect(() => {
        props.onVideoStart && props.onVideoStart(visible);
        if (visible && videoTargetRef.current) {
            videoTargetRef.current.playVideo();
        }
    }, [visible]);

    useEffect(() => {
        if (videoTargetRef.current && props.isNotOnCarouselView) {
            videoTargetRef.current.pauseVideo();
            setVisible(false);
        }
    }, [props.isNotOnCarouselView]);

    const onPlayerReady: YouTubeProps['onReady'] = (event): void => {
        videoTargetRef.current = event.target;
    }

    const onVideoStateChange = (event: { target: any; data: number }): void => {
        if (event.data === YT_PLAYER_STATE.PLAYING) {
            if (showBannerTimeoutRef.current) clearTimeout(showBannerTimeoutRef.current);
            setCarouselAutoPlay(false);
            setVisible(true);
        }
        else if (event.data === YT_PLAYER_STATE.ENDED) {
            event.target.stopVideo();
            setCarouselAutoPlay(true);
            setVisible(false);
        }
        else if (event.data === YT_PLAYER_STATE.PAUSED) {
            if (showBannerTimeoutRef.current) clearTimeout(showBannerTimeoutRef.current);
            showBannerTimeoutRef.current = setTimeout(() => {
                setCarouselAutoPlay(true);
                setVisible(false);
            }, timeInMs);
        }
    }

    const onPlayBtnClicked = (): void => {
        if (isOnCarouselView)
            setVisible(true);
    };

    return {
        onPlayBtnClicked,
        onVideoStateChange,
        onPlayerReady,
        visible
    }
}

export default useVideoSlide;
