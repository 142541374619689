import Application from 'Application';
import Big, { BigSource } from 'big.js';
import { CURRENCIES_ENUM as CURRENCIES_TYPE, LOCAL_STORAGE_KNOWN_KEYS } from 'config/_const';
import { ZERO } from '../../config/_const';
import Utils from '../Utils';
import { CURRENCIES } from 'data/currencies';

const AMOUNT_PRECISION_AFTER_DECIMAL = 2;

type SignDisplay = 'auto' | 'never' | 'always' | 'exceptZero';

declare global {
    interface Number {
        divideByTen(): number;
        safeDivideBy(divider: number): number;
        isGreaterThenOrEqualToZero(): boolean;
        addArithmeticOperator(addLessOperator?: boolean): string;
        toCurrency(maximumFractionDigits?: number, forcedCurrency?: string, minIntegerDigits?: number, minimumFractionDigits?: number, signDisplay?: SignDisplay): string;
        asCurrency(maximumFractionDigits?: number, forcedCurrency?: string, minIntegerDigits?: number, minimumFractionDigits?: number, signDisplay?: SignDisplay): string;
        toCurrencyValue(forcedCurrency?: string): number
        toDefaultCurrencyValue(forcedCurrency?: string): number;
        toPercentage(): string;
        countDecimals(): number;
        roundNumber(): number;
        multiply(multiplier: number): number;
        capMinToZero(): number;
        isSmallerThan(num: number): boolean;
        divide(num: number): number;
        addition(num: number): number;
        subtract(num: number): number;
    }
}

Number.prototype.divideByTen = function (): number {
    return Number(this) / 10;
};

Number.prototype.safeDivideBy = function (divider: number): number {
    const safeDivider: number = divider ? divider : 1;
    return Number(this) / safeDivider;
};

Number.prototype.isGreaterThenOrEqualToZero = function (): boolean {
    return this as number > -1;
};

Number.prototype.addArithmeticOperator = function (addLessOperator: boolean = false): string {
    if (this === 0)
        return `${this}`;
    const lessOperator: string = addLessOperator ? '-' : '';
    return this.isGreaterThenOrEqualToZero() ? `+${this}` : `${lessOperator}${this}`;
};

Number.prototype.multiply = function (multiplier: number): number {
    const multipliedResultsAsBig = Big(this as BigSource).mul(multiplier);
    const multipliedAsNumber = multipliedResultsAsBig.toNumber();
    if (Number.isInteger(multipliedAsNumber))
        return multipliedAsNumber;
    return toPrecisedNumber(multipliedAsNumber, multipliedResultsAsBig);
}

function toPrecisedNumber(number: number, bigNumber: Big) {
    return bigNumber.prec(getPrecision(number), Big.roundUp).toNumber();
}

function getPrecision(number: number) {
    const numberOfDigitBeforeDecimal = number.toString().split('.')[0].length;
    return numberOfDigitBeforeDecimal + AMOUNT_PRECISION_AFTER_DECIMAL;
}

Number.prototype.toCurrency = function (maximumFractionDigits?: number, forcedCurrency?: string, minIntegerDigits?: number, minimumFractionDigits?: number, signDisplay?: SignDisplay): string {
    const value = this.toCurrencyValue(forcedCurrency);
    return value.asCurrency(maximumFractionDigits, forcedCurrency, minIntegerDigits, minimumFractionDigits, signDisplay);
};

Number.prototype.toCurrencyValue = function (forcedCurrency?: string): number {
    const numValue = this;
    const ratesString = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.FOREX_RATES) as string;
    if (!ratesString) {
        return NaN;
    }
    const rates = JSON.parse(ratesString)?.rates;

    if (!rates) {
        return NaN;
    }

    const supportedCurrencies = CURRENCIES;
    const applicationCurrency = Application.getInstance().currency;
    const fallbackCurrency = supportedCurrencies[applicationCurrency as keyof object] ? applicationCurrency : CURRENCIES_TYPE.USD;
    const currencyMultiplier = rates[forcedCurrency || fallbackCurrency];
    const value = numValue.multiply(currencyMultiplier);
    return value;
}

Number.prototype.toDefaultCurrencyValue = function (forcedCurrency?: string): number {
    const numValue = this;
    const ratesString = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.FOREX_RATES) as string;
    if (!ratesString) {
        return NaN;
    }
    const rates = JSON.parse(ratesString)?.rates;

    if (!rates) {
        return NaN;
    }

    const supportedCurrencies = CURRENCIES;
    const applicationCurrency = Application.getInstance().currency;
    const fallbackCurrency = supportedCurrencies[applicationCurrency as keyof object] ? applicationCurrency : CURRENCIES_TYPE.USD;
    const currencyMultiplier = rates[forcedCurrency || fallbackCurrency];
    const value = numValue.divide(currencyMultiplier);
    return value;
}

Number.prototype.asCurrency = function (maximumFractionDigits?: number, forcedCurrency?: string, minIntegerDigits?: number, minimumFractionDigits?: number, signDisplay?: SignDisplay): string {
    const supportedCurrencies = CURRENCIES;
    const applicationCurrency = Application.getInstance().currency;
    const fallbackCurrency = supportedCurrencies[applicationCurrency as keyof object] ? applicationCurrency : CURRENCIES_TYPE.USD;
    maximumFractionDigits = maximumFractionDigits ?? 2;
    if ((!minimumFractionDigits) ||
        (minimumFractionDigits && maximumFractionDigits < minimumFractionDigits)) {
        minimumFractionDigits = maximumFractionDigits;
    }

    return new Intl.NumberFormat(
        Utils.getCurrentLanguage(), {
        signDisplay: signDisplay ?? 'never',
        style: 'currency',
        currency: forcedCurrency || fallbackCurrency,
        maximumFractionDigits,
        minimumFractionDigits,
        minimumIntegerDigits: minIntegerDigits ?? 1,
    }).format(this as number);
};

Number.prototype.toPercentage = function (): string {
    return new Intl.NumberFormat(
        Utils.getCurrentLanguage(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'percent',
    }).format(this as number);
};

Number.prototype.countDecimals = function () {
    if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
    return this.toString().split('.')[1].length || 0;
};

Number.prototype.roundNumber = function () {
    return Math.round(this.valueOf() * 100) / 100;
};

Number.prototype.capMinToZero = function () {
    return (this as number) < ZERO ? ZERO : this as number;
};

Number.prototype.isSmallerThan = function (num: number) {
    return (this as number) < num;
};

Number.prototype.divide = function (num: number): number {
    if (num === 0)
        return this as number;
    const dividedResultsAsBig = Big(this as number).div(num);
    const dividedAsNumber = dividedResultsAsBig.toNumber();
    if (Number.isInteger(dividedAsNumber))
        return dividedAsNumber;
    return toPrecisedNumber(dividedAsNumber, dividedResultsAsBig);
};
Number.prototype.addition = function (num: number): number {
    const addedResultsAsBig = Big(this as number).add(num);
    const addedAsNumber = addedResultsAsBig.toNumber();
    if (Number.isInteger(addedAsNumber))
        return addedAsNumber;
    return toPrecisedNumber(addedAsNumber, addedResultsAsBig);
};
Number.prototype.subtract = function (num: number): number {
    const subtractedResultsAsBig = Big(this as number).minus(num);
    const subtractedAsNumber = subtractedResultsAsBig.toNumber();
    if (Number.isInteger(subtractedAsNumber))
        return subtractedAsNumber;
    return toPrecisedNumber(subtractedAsNumber, subtractedResultsAsBig);
};

export { };
