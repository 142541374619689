import AdminCreditUser from 'components/medium/admin-credit-user/AdminCreditUser';
import AdminGiveTokenToUser from 'components/medium/admin-give-token-user/AdminGiveTokenToUser';
import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import AdminUserInformationForm from '../../../../components/medium/form/admin/admin-user-form/AdminUserInformationForm';
import AdminIdentityFooter from '../../../../components/medium/profil-banner/admin-identity-footer/AdminIdentityFooter';
import CircularLoader from '../../../../components/small/CircularLoader';
import UserInterface from '../../../../model/User/UserInterface';
import { actionUpdateUserAsAdmin, adminValidationUserAccount, getSignedImage, getUserDetailsById } from '../../../../service/users/actions';
import Utils from '../../../../utils/Utils';
import OverviewUsersProfilPageProps from './OverviewUsersPageProps';
import './OverviewUsersProfilPage.scss';

class OverviewUsersProfilPage extends React.Component<OverviewUsersProfilPageProps> {
    componentWillMount = () => {
        const paramUserId: string = this.props.match.params.userId;
        this.props.getUserDetailsById(paramUserId);
    }

    componentDidUpdate = (prevProps: OverviewUsersProfilPageProps) => {
        const prevAccount: UserInterface | undefined = prevProps.users.data.all?.find((user: UserInterface) => user && user._id === this.props.match.params.userId);
        const account: UserInterface | undefined = this.props.users.data.all?.find((user: UserInterface) => user && user._id === this.props.match.params.userId);
        let hasRectoImage = false;
        let hasVersoImage = false;
        if (account && account.identityRectoImage)
            hasRectoImage = true;
        if (account && account.identityVersoImage)
            hasVersoImage = true;
        const hasRectoAndVersoImages = [hasRectoImage, hasVersoImage].every(bool => bool);
        if ((!prevAccount?.identityRectoImage && hasRectoImage) || (!prevAccount?.identityVersoImage && hasVersoImage)) {
            if (hasRectoAndVersoImages)
                return this.props.getSignedImage(account?.identityRectoImage, account?.identityVersoImage);
            if (hasRectoImage)
                return this.props.getSignedImage(account?.identityRectoImage);
            if (hasVersoImage)
                return this.props.getSignedImage(undefined, account?.identityVersoImage);
        }
    }

    colorByUserStatus = (status: string) => {
        switch (status) {
            case 'REFUSED':
                return '#ff6659';

            case 'CONFIRMED':
                return '#6abf69';

            default:
                return '#fbc02d';
        }
    }

    render = () => {

        const {
            users,
        } = this.props;
        const account: UserInterface | undefined = users.data.all?.find((user: UserInterface) => user && user._id === this.props.match.params.userId);
        let updateData: any = undefined;

        if (account) {
            const {
                lastName,
                firstName,
                pseudo,
                country,
                stripeCustomerId,
                type,
                address,
                referral,
                identificationDocumentNumber,
                phone,
                dateOfBirth,
                validatedEmail } = account;

            updateData = {
                lastName,
                firstName,
                pseudo,
                country,
                stripeCustomerId,
                type,
                address,
                phone,
                referral,
                identificationDocumentNumber,
                email: Utils.findEmail(account),
                dateOfBirth,
                validatedEmail
            };
        }

        return (
            <div className="overview-user-profil-page">
                {!account && users.loading && (
                    <CircularLoader
                        loaderMessage={'Loading user detail'}
                        loaderLocal={false} />
                )}

                {account && (
                    <div className="content">
                        <div className="crud-user-edit">
                            <AdminIdentityFooter
                                confirmOrRefusedIdentity={(isConfirmed: string, identityRefusalReasons?: string) => this.props.adminValidationUserAccount(isConfirmed, account._id, account.email, identityRefusalReasons)}
                                actualIdentityStatus={account.isConfirmed}
                                identityRefusalReasons={account.identityRefusalReasons} />
                            <div className="identity-section">

                                <h2>{i18next.t('admin.overviewUsersProfilPage.identity')}</h2>

                                {account.identityRectoImage || account.identityVersoImage ?
                                    (
                                        this.props.users.actualUserIdentity ? (
                                            <div className="identity">
                                                <a href={this.props.users.actualUserIdentity.rectoImg} target="_blank">
                                                    <img className='identity-image' src={this.props.users.actualUserIdentity.rectoImg} alt="rectoImg" onError={() => account.identityRectoImage && this.props.getSignedImage(account.identityRectoImage, undefined, true)} />
                                                </a>
                                                {account.identityVersoImage &&
                                                    <a href={this.props.users.actualUserIdentity.versoImg} target="_blank">
                                                        <img className='identity-image' src={this.props.users.actualUserIdentity.versoImg} alt="versoImg" onError={() => account.identityVersoImage && this.props.getSignedImage(undefined, account.identityVersoImage, true)} />
                                                    </a>
                                                }
                                            </div>
                                        ) : (
                                            <p>Loading</p>
                                        )
                                    )
                                    :
                                    (
                                        <p>{i18next.t('admin.overviewUsersProfilPage.no-identity')}</p>
                                    )}

                            </div>
                            <AdminCreditUser user={account} />
                            <AdminGiveTokenToUser user={account} />
                            <AdminUserInformationForm
                                buttonText={i18next.t('admin.userDetail.button.submit')}
                                initialValues={updateData}
                                onInformationsSubmit={(form: any) => this.props.actionUpdateUserAsAdmin(form, account._id)}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: OverviewUsersProfilPageProps) => ({
    users: state.users,
});

export default compose(
    connect(mapStateToProps, {
        getUserDetailsById,
        actionUpdateUserAsAdmin,
        adminValidationUserAccount,
        getSignedImage
    }),
)(OverviewUsersProfilPage);
