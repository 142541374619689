import { createSelector, Selector } from 'reselect';
import { RootState } from '../../model/Redux';
import ITeamConfig from '../../model/Team/ITeamConfig';
import { getDefaultTeamConfiguration } from './getters';
import { TournamentLeagues } from 'config/_enums';

const teamConfigurationSelector: Selector<RootState, ITeamConfig[]> = state => state.teamConfiguration.data;
export const teamConfigurationLoadingSelector: Selector<RootState, boolean> = state => state.teamConfiguration.loading;

export const selectDefaultConfiguration = (leagueType: TournamentLeagues) => createSelector<RootState, ITeamConfig[], ITeamConfig | undefined>(
    teamConfigurationSelector,
    (teamConfigurations: ITeamConfig[]) => getDefaultTeamConfiguration(teamConfigurations, leagueType),
);
