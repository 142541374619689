import type ReduxRootState from 'model/Redux/ReduxRootSate';
import type ReduxActionInterface from 'model/ReduxActionInterface';
import type { ITournamentSubscription, ITournamentSubscriptionsReducer } from 'model/Tournament/ITournamentSubscription';
import { STATUS } from 'service/storeType';
import * as ActionTypesTournamentSubscription from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<ITournamentSubscriptionsReducer> = {
    loading: false,
    data: {},
    error: '',
    status: {
        getSubscriptions: STATUS.IDLE,
        subscribe: STATUS.IDLE,
    },
};

export const tournamentSubscriptionReducer = (state: ReduxRootState<ITournamentSubscriptionsReducer> = ROOT_VALUE_STATE, action: ReduxActionInterface): ReduxRootState<ITournamentSubscriptionsReducer> => {
    switch (action.type) {
        case ActionTypesTournamentSubscription.GET_SUBSCRIPTIONS.LOADING:
            return {
                ...state,
                loading: true,
                status: {
                    ...state.status,
                    getSubscriptions: STATUS.PENDING,
                },
            };

        case ActionTypesTournamentSubscription.GET_SUBSCRIPTIONS.ERROR:
            return {
                ...state,
                loading: false,
                status: {
                    ...state.status,
                    getSubscriptions: STATUS.REJECTED,
                },
            };
        case ActionTypesTournamentSubscription.GET_SUBSCRIPTIONS.SUCCESS:
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    [action.payload.tournamentId]: action.payload.subscriptions,
                },
                status: {
                    ...state.status,
                    getSubscriptions: STATUS.RESOLVED,
                },
            };
        case ActionTypesTournamentSubscription.GET_USER_SUBSCRIPTION.SUCCESS:
            return handleUserTournamentSubscription(state, action.payload);
        case ActionTypesTournamentSubscription.SUBSCRIBE.LOADING:
            return {
                ...state,
                loading: true,
                status: {
                    ...state.status,
                    subscribe: STATUS.PENDING,
                },
            };

        case ActionTypesTournamentSubscription.SUBSCRIBE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
                status: {
                    ...state.status,
                    subscribe: STATUS.REJECTED,
                },
            };
        case ActionTypesTournamentSubscription.SUBSCRIBE.SUCCESS:
            return manageSubscribeSuccessData(state, action.payload);
        default: return state;
    }
};

const manageSubscribeSuccessData = (state: ReduxRootState<ITournamentSubscriptionsReducer>, payload: any): ReduxRootState<ITournamentSubscriptionsReducer> => {
    if (!payload.subscription)
        return {
            ...state
        };
    const subscription: ITournamentSubscription = payload.subscription;
    const defaultState = {
        ...state,
        loading: false,
        status: {
            ...state.status,
            subscribe: STATUS.RESOLVED,
        },
    };
    const tournamentId: string = subscription.tournament._id;

    if (!state.data[tournamentId])
        return {
            ...defaultState,
            data: {
                ...defaultState.data,
                [tournamentId]: [subscription],
            },
        };

    const subscriptionAlreadyExists: ITournamentSubscription | undefined = defaultState.data[tournamentId].find((element: ITournamentSubscription) => element._id === subscription._id);
    if (subscriptionAlreadyExists)
        return state;
    return {
        ...defaultState,
        data: {
            ...defaultState.data,
            [tournamentId]: defaultState.data[tournamentId].concat([subscription]),
        },
    };
};

const handleUserTournamentSubscription = (state: ReduxRootState<ITournamentSubscriptionsReducer>, payload: { tournamentId: string, subscription: ITournamentSubscription; }) => {
    const defaultState = {
        ...state,
        loading: false,
        status: {
            ...state.status,
            subscribe: STATUS.RESOLVED,
        },
    };
    if (!Boolean(payload.subscription))
        return defaultState;

    if (!state.data[payload.tournamentId]) {
        return {
            ...defaultState,
            data: {
                ...defaultState.data,
                [payload.tournamentId]: [payload.subscription],
            },
        };
    }

    const stateDataOfTournament = [...state.data[payload.tournamentId]];
    const payloadSubscriptionIndex: number = state.data[payload.tournamentId].findIndex((element: ITournamentSubscription) => element._id === payload.subscription._id);
    const isSubscriptionAlreadyExist = (payloadSubscriptionIndex !== -1);
    if (isSubscriptionAlreadyExist)
        stateDataOfTournament[payloadSubscriptionIndex] = payload.subscription;

    return {
        ...defaultState,
        data: {
            ...defaultState.data,
            [payload.tournamentId]: isSubscriptionAlreadyExist
                ? stateDataOfTournament
                : stateDataOfTournament.concat([payload.subscription])
        },
    };
};
