import { ISvgIconProps } from 'interfaces/SvgIcon.interface';
import * as React from 'react';

const CirclePlayBtn: React.FC<ISvgIconProps> = ({ ...props }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="114" height="114" {...props} viewBox="0 0 114 114">
            <g id="Groupe_6185" data-name="Groupe 6185" transform="translate(-170.607 -365.607)">
                <circle id="Ellipse_357" data-name="Ellipse 357" cx="57" cy="57" r="57" transform="translate(170.607 365.607)" fill="#fff" opacity="0.3" />
                <path id="Polygone_130" data-name="Polygone 130" d="M21.637,7.31a5,5,0,0,1,8.615,0l17.2,29.18a5,5,0,0,1-4.308,7.538H8.75A5,5,0,0,1,4.442,36.49Z" transform="translate(256.697 396.662) rotate(90)" fill="#fff" />
            </g>
        </svg>
    )
}

export default CirclePlayBtn;
