export enum Status {
    idle = 'idle',
    pending = 'pending',
    resolved = 'resolved',
    rejected = 'rejected',
}

export enum FormMode {
    REGISTER = 'REGISTER',
    LOGIN = 'LOGIN',
}

export enum NotificationUserResponse {
    REFUSED = 'REFUSED',
    ACCEPTED = 'ACCEPTED',
}

export enum NotificationPermission {
    DENIED = 'denied',
    GRANTED = 'granted',
    DEFAULT = 'default',
}

export enum STATE_SCOPE {
    NONE = 0,
    PAYMENT = 1,
    ORDER = 2,
    DIRECT_BUY_BEST_SELLING_ORDERS = 3
}

export enum TdxNotification {
    GUIDANCE_USER_RESPONSE = 'tdx-notification-guidance-user-response',
    USER_RESPONSE = 'tdx-notification-user-response',
    REFUSED_COUNT = 'refused-count',
    REFUSED_DATE = 'tdx-notification-refused-date',
}


export enum MarketEnum {
    NONE = 0,
    PRIMARY = 1,
    SECONDARY = 2
}

export enum PaymentMethodsEnum {
    CREDITS = 0,
    PAYPAL = 1,
    STRIPE = 2,
    BITPAY = 3,
    STRIPE_NEW_CARD = 4,
    OTHER = 5
}

export enum OrderSide {
    BUY = 'BUY',
    SELL = 'SELL',
}

export enum OrderStatus {
    OPEN = 'OPEN',
    PICKED = 'PICKED',
    CANCELED = 'CANCELED',
    QUEUED = 'QUEUED',
}


export enum ModalType {
    REFERRAL_FORM = "REFERRAL_FORM"
}

export enum OrderBy {
    ASC = 0,
    DESC = 1,
}

// TODO - Change comment when tournament date system is set
/**
 * TO SUBSCRIBE FOR A TOURNAMENT USE NEXT
 */
export enum TournamentProgress {
    PREVIOUS = 0,
    IN_PROGRESS = 1,
    NEXT = 2,
    PERSONAL_TOURNAMENT = 9,
}

export enum TransactionType {
    BUY = "BUY",
    SELL = "SELL",
    YIELD = "YIELD",
    TOURNAMENT = "REWARD",
    REGISTER = "REGISTER",
    REFERRAL = "REFERRAL",
    STRIPE = "STRIPE",
    BITPAY = "BITPAY",
    MARKETPLACE = "MARKETPLACE",
    FEE = "FEE",
    SECONDARY_MARKET = "SECONDARY_MARKET",
    STACKING = "STACKING",
    REWARD = "REWARD",
    FIRST_STRIPE_PAYMENT = "FIRST_STRIPE_PAYMENT",
    DISCORD_GIVEAWAY = "DISCORD_GIVEAWAY",
    WITHDRAW = "WITHDRAW",
    BOOST = 'BOOST',
    TOURNAMENT_TICKET = 'TOURNAMENT_TICKET'
}
export enum TransactionSource {
    REFERRAL = 'REFERRAL',
    PAYPAL = 'PAYPAL',
    STRIPE = 'STRIPE',
    BITPAY = 'BITPAY',
    MARKETPLACE = 'MARKETPLACE',
    INITIAL_SUPPLY = 'INITIAL_SUPPLY',
    WITHDRAW = 'WITHDRAW',
    FEE = 'FEE',
    SECONDARY_MARKET = 'SECONDARY_MARKET',
    STACKING = 'STACKING',
    END_OF_INITIAL_SALE = 'END_OF_INITIAL_SALE',
    REWARD = 'REWARD',
    REGISTER_WITH_REFERRAL = 'REGISTER_WITH_REFERRAL',
    REGISTER_WITHOUT_REFERRAL = 'REGISTER_WITHOUT_REFERRAL',
    GIFT_FIRST_STRIPE_PAYMENT = 'FIRST_STRIPE_PAYMENT',
    DISCORD_GIVEWAY = 'DISCORD_GIVEWAY',
    MANUAL_TOURNAMENT_ADMIN_REFUND = 'MANUAL_TOURNAMENT_ADMIN_REFUND',
    TWITTER_GIVEWAY = 'TWITTER_GIVEWAY',
    DELTA_BETWEEN_ORDER_PRICES = 'SECONDARY_MARKET_SLEEPAGE',
    NEGATIVE_DELTA_BETWEEN_ORDER_PRICES = 'NEGATIVE_SECONDARY_MARKET_SLEEPAGE',
    INITIAL_SUPPLY_TRENDEX_PART = 'INITIAL_SUPPLY_TRENDEX_PART',
    YIELD = 'YIELD',
    PARTNERSHIP = 'PARTNERSHIP',
    REGULATION_SECONDARY_MARKET = 'REGULATION_SECONDARY_MARKET',
    SECONDARY_MARKET_NEGATIVE_SPREAD_REGULATION = 'SECONDARY_MARKET_NEGATIVE_SPREAD_REGULATION',
    BOOST = 'BOOST',
    TOURNAMENT_TICKET = 'TOURNAMENT_TICKET'
}

export enum TransactionAction {
    DEBIT = "DEBIT",
    CREDIT = "CREDIT"
}

export enum PortfolioTabs {
    TRANSACTION = "Transaction",
    SHARES = "Shares"
}

export enum DefaultNumberOfTokens {
    BUY = 100,
    SELL = 1
}
export enum BitPayInitiationPages {
    REFUND_WALLET = "REFUND_WALLET",
    BUY_ASSET = "BUY_ASSET_CONFIRMATION",
}

export enum AssetTransactionType {
    BUY = "BUY",
    SELL = "SELL",
    REFUND = "REFUND"
}

export enum HistoryActions {
    PUSH = "PUSH",
    REPLACE = "REPLACE"
}

export enum RedirectInitiationPage {
    BUY_ASSET = "BUY_ASSET"
}

export enum URL_INTENT {
    REDIRECT_TO = "redirectTo"
}


export enum AssetCategory {
    'Euro 2024' = "Euro 2024",
    Instagram = "Instagram",
    Sport = "Sport",
    Youtube = "Youtube",
    Cinema = "Cinéma",
    Streamers = "Streamers",
    Music = "Music",
    Tiktok = "Tiktok",
    Basketball = "Basketball",
    Football = "Football",
    Fight = "Fight",
    Rugby = "Rugby",
    Handball = "Handball",
    'Fun cards' = "Fun cards"
}

export enum AssetCardLevel {
    Rookie = 1,
    Contender = 2,
    Champion = 3,
    Legend = 4
}

export enum AssetCardLevelPlurial {
    Rookies = 1,
    Contenders = 2,
    Champions = 3,
    Legends = 4
}

export enum TournamentLeagues {
    Rookies = 'Rookies',
    Managers = 'Managers',
    Believers = 'Believers',
    Contenders = 'Contenders',
    Champions = 'Champions',
    Legends = 'Legends'
}

export enum AssetCategoryEnum {
    Instagram = "6085883ce4c992ae6f74fe00",
    Youtube = "60858bfa3ac68b6c185d78ad",
    Cinema = "617013b410c9781e9f1d2007",
    Music = "60858bfa3ac68b6c185d78a9",
    Tiktok = "633ea4cc9041bace1a678fe9",
    Basketball = "64219383c07ae31d115b0774",
    Football = "64219383c07ae31d115b0775",
    Fight = "64219383c07ae31d115b0777",
    Rugby = "64219383c07ae31d115b0776",
    Handball = '65dc944a0469ab78d62173eb'
}

export enum ConnectionMode {
    login = 'LOGIN',
    register = 'REGISTER',
};

export enum SELL_TYPE {
    MARKET = 'MARKET',
    LIQUIDITY_POOL = 'LIQUIDITY_POOL'
}

export enum SignupVersion {
    Standard = 'standard',
    Foot = 'f',
    Basket = 'b',
}

export enum ApplicationSportVersionEnum {
    STANDARD = 'standard',
    FOOTBALL = 'f',
    BASKETBALL = 'b',
}

export enum TournamentRewardKind {
    CREDITS_REWARD = 'CreditReward',
    SHARES_REWARD = 'ShareReward'
}

export enum OnboardingStep {
    NO_ONBOARDING = -1,
    VIEW_LIGHT_WALLET = 1,
    CREATE_TEAM = 2,
    SELL_HIS_FIRST_ASSET = 3,
    FIRST_WITHDRAW = 4,
    FINISH = 999
}

export enum AssetCurveType {
    DEFAULT = 'default',
    EXPONENTIAL = 'exponential',
    STEPWISE = 'stepwise',
    WAVE = 'wave',
    LINEAR = 'linear'
}

export enum TournamentEntryType {
    FREE = "FREE",
    PAID = "PAID"
}
