import { withStyles, Button, createStyles, Theme } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from 'react';

export const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const TdxButton = withStyles(_theme => ({
  root: {
    color: 'white',
    width: '100%',
    backgroundColor: '#3936C0',
    transition: '.5s',
    padding: '10px',
    fontSize: '12px',
    border: 'solid #3936C0 0.9px',
    '&:hover': {
      backgroundColor: '#3936C0',
      color: 'white',
    },
  },
}))(Button);

export const TdxDisabledButton = withStyles(_theme => ({
  root: {
    color: 'white',
    width: '100%',
    backgroundColor: 'white',
    transition: '.5s',
    padding: '10px',
    fontSize: '12px',
    border: 'solid #3936C0 0.9px',
    borderRadius: '40px',
  },
}))(Button);
