import React from 'react';
import IButtonProps from '../IButtonProps';
import './GreenButton.scss';

const GreenButtonWithGradient = React.forwardRef<HTMLButtonElement,IButtonProps>((props,ref) => {
    return (
        <button
            className={`green-button-gradient ${props.className || ''}`}
            type={props.type}
            disabled={props.disabled}
            onClick={props.handleClick}
            ref={ref}
            id={props.id}>
            {props.startIcon && props.startIcon}
            {props.textButton}
        </button>
    );
})

export default React.memo(GreenButtonWithGradient);
