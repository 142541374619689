import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import Divider from 'components/small/divider/Divider';
import './Top50SkeletonLoading.scss';
import clsx from 'clsx';
import ITop50SkeletonLoadingProps from './ITop50SkeletonLoadingProps';

const Top50SkeletonLoading: React.FC<ITop50SkeletonLoadingProps> = ({showBorder = true}) => {
    const NB_ROW: number = 6;

    return (
        <div className={clsx('top50-skeleton-loading',
            { 'no-border': !showBorder })}
        >
            <div className="loading__carousel-content">
                <Skeleton animation="wave" className="container-rect" variant="rect" height={200} />
            </div>
            <Divider background='#D2D4E0' size='97%' />
            <div className="loading__title">
                <Skeleton animation="wave" variant="text" width={100} height={50} />
            </div>
            <div className="loading__category-list">
                <Skeleton animation="wave" variant="text" width={"100%"} height={40} />
            </div>
            <div className="loading__dropdown">
                <Skeleton animation="wave" variant="text" width={80} height={30} />
            </div>
            {Array.from({ length: NB_ROW }, (_, index) =>
                <div key={index} className="loading__asset-row">
                    <Skeleton animation="wave" variant="text" width={"100%"} height={80} />
                </div>
            )}
        </div>
    );
};

export default Top50SkeletonLoading;
