import i18n from '18n';
import ApplePay from 'components/icons/ApplePay';
import Bitcoin from 'components/icons/Bitcoin';
import CreditCard from 'components/icons/CreditCard';
import GooglePay from 'components/icons/GooglePay';
import LinkPay from 'components/icons/Link';
import CheckoutModeEnum from 'components/medium/stripe-elements/CheckoutModeEnum';
import PaymentWalletCheckoutForm from 'components/medium/stripe-elements/paymentWalletCheckoutForm';
import BuyAssetCreditBreakdown from 'components/small/buy-asset-credit-breakdown/BuyAssetCreditBreakdown';
import CreditCardWrapper from 'components/small/credit-card-wrapper/CreditCardWrapper';
import PaymentOptionCard from 'components/small/payment-option-card/PaymentOptionCard';
import CryptoPaymentCard from 'components/small/payment-option-card/crypto-payment-card/CryptoPaymentCard';
import { MIN_PAYMENT_AMOUNT, ZERO } from 'config/_const';
import { AssetTransactionType, MarketEnum, PaymentMethodsEnum } from 'config/_enums';
import useAccount from 'hooks/use-account/useAccount';
import React, { useEffect } from 'react';
import IConfirmBuyAssetProps from './IConfirmBuyAssetProps';
import { IPaymentOptions } from './IPaymentOptions';
import "./confirm-buy-asset.scss";
import BuyRewardDebtInfo from 'components/small/buy-reward-debt/BuyRewardDebtInfo';

const ConfirmBuyAsset: React.FC<IConfirmBuyAssetProps> = ({ buyAssetViewModel, selectedPaymentType, creditCardConfirmationMethodRef, setSelectedPaymentType, setTransactionType, onError, onSuccess, setCreditCardPaymentReady, setSelectedPaymentTypeHasChanged, isTransactionDisabled, handleClick }) => {
    const { accountViewModel } = useAccount();
    const [expressPaymentName, setExpressPaymentName] = React.useState<string>('');

    const renderStripeSubComponent = () => {
        const isNewCardForm: boolean = selectedPaymentType === PaymentMethodsEnum.STRIPE_NEW_CARD;
        return <CreditCardWrapper
            fiatAmount={buyAssetViewModel.Amount}
            mode={CheckoutModeEnum.BUY_ASSET}
            hasStripeCustomerId={accountViewModel.StripeCustomerId?.safeIsNullOrEmpty() ?? false}
            trackEventOnPixel={true}
            showLoader={true}
            intent={{
                unitaryPrice: buyAssetViewModel.UnitaryPrice,
                assetId: buyAssetViewModel.AssetId,
                amountOfShares: buyAssetViewModel.NumberOfToken,
                isDirectBuy: buyAssetViewModel.Market === MarketEnum.PRIMARY,
                fiatAmount: buyAssetViewModel.Amount,
                concernedMarket: buyAssetViewModel.Market,
                directBuyFromTheBestSellingOrders: buyAssetViewModel.Market === MarketEnum.SECONDARY,
                type: buyAssetViewModel.OrderType
            }}
            buyAssetInErrorCallback={(_transactionData, _isDone) => { return new Promise(() => { (onError(_transactionData)) }) }}
            buyAssetSuccessCallback={(_transactionData, _isDone) => { return new Promise(() => { onSuccess() }) }}
            confirmationMethodRef={creditCardConfirmationMethodRef}
            setCreditCardReady={setCreditCardPaymentReady}
            newCardForm={isNewCardForm}
        />
    }

    const renderBitpaySubComponent = () => (
        <CryptoPaymentCard handleClick={handleClick} />
    )

    const renderOtherSubComponent = () => (
        <div className="wallet-checkout-form">
            {isTransactionDisabled ? <></> : <PaymentWalletCheckoutForm
                getExpressPaymentName={saveExpressPaymentName}
                key={buyAssetViewModel.Amount}
                label={buyAssetViewModel.Asset.AssetName}
                fiatAmount={buyAssetViewModel.Amount}
                mode={CheckoutModeEnum.BUY_ASSET}
                hasStripeCustomerId={accountViewModel.StripeCustomerId?.safeIsNullOrEmpty() ?? false}
                trackEventOnPixel={true}
                showLoader={true}
                intent={{
                    unitaryPrice: buyAssetViewModel.UnitaryPrice,
                    assetId: buyAssetViewModel.AssetId,
                    amountOfShares: buyAssetViewModel.NumberOfToken,
                    isDirectBuy: buyAssetViewModel.Market === MarketEnum.PRIMARY,
                    fiatAmount: buyAssetViewModel.Amount,
                    concernedMarket: buyAssetViewModel.Market,
                    directBuyFromTheBestSellingOrders: buyAssetViewModel.Market === MarketEnum.SECONDARY,
                    type: buyAssetViewModel.OrderType
                }}
                buyAssetInErrorCallback={(_transactionData, _isDone) => { return new Promise(() => { (onError(_transactionData)) }) }}
                buyAssetSuccessCallback={(_transactionData, _isDone) => { return new Promise(() => { onSuccess() }) }}
                newCardForm={false} />}
        </div>
    )

    const EXPRESS_PAYMENT_ICON: Record<string, JSX.Element> = {
        'Apple Pay': <ApplePay />,
        'Google Pay': <GooglePay />,
        'Link': <LinkPay />,
    }

    let paymentOptions: IPaymentOptions[] = [
        { id: 1, value: PaymentMethodsEnum.CREDITS, label: i18n.t("credits"), info: <BuyAssetCreditBreakdown buyAssetViewModel={buyAssetViewModel} selectedPaymentType={selectedPaymentType} />, isDisabled: buyAssetViewModel.IsLowAvailableCredits || !buyAssetViewModel.IsReady, renderSubComponent: () => buyAssetViewModel.ShowRewardDebt(selectedPaymentType) ? <BuyRewardDebtInfo /> : <></> },
        { id: 2, value: PaymentMethodsEnum.STRIPE, label: i18n.t("Payment.card"), info: <CreditCard />, isDisabled: !buyAssetViewModel.IsReady || !(accountViewModel.StripeCustomerId?.safeIsNullOrEmpty() ?? false) || buyAssetViewModel.TotalPrice < MIN_PAYMENT_AMOUNT, renderSubComponent: renderStripeSubComponent },
        { id: 3, value: PaymentMethodsEnum.STRIPE_NEW_CARD, label: i18n.t(accountViewModel.isAuthenticated ? "Payment.newCard" : 'anonymous.payment-option.new-card'), info: <CreditCard />, isDisabled: !buyAssetViewModel.IsReady || buyAssetViewModel.TotalPrice < MIN_PAYMENT_AMOUNT, renderSubComponent: renderStripeSubComponent },
        { id: 4, value: PaymentMethodsEnum.BITPAY, label: i18n.t("payment.crypto"), info: <Bitcoin />, isDisabled: !buyAssetViewModel.IsReady, renderSubComponent: renderBitpaySubComponent },
        { id: 5, value: PaymentMethodsEnum.OTHER, label: i18n.t("payment.paywith", { name: expressPaymentName }), info: EXPRESS_PAYMENT_ICON[expressPaymentName], isDisabled: !buyAssetViewModel.IsReady, renderSubComponent: renderOtherSubComponent }
    ]

    if (!accountViewModel.isAuthenticated) {
        setSelectedPaymentType(PaymentMethodsEnum.STRIPE_NEW_CARD);
        paymentOptions = paymentOptions.filter((option) => option.value === PaymentMethodsEnum.STRIPE_NEW_CARD);
    }

    useEffect(() => {
        if (buyAssetViewModel.TotalPrice <= ZERO)
            return;
        if (buyAssetViewModel.TotalPrice < MIN_PAYMENT_AMOUNT)
            setSelectedPaymentType(buyAssetViewModel.IsLowAvailableCredits ? PaymentMethodsEnum.BITPAY : PaymentMethodsEnum.CREDITS);
        else if (selectedPaymentType === PaymentMethodsEnum.CREDITS && buyAssetViewModel.IsLowAvailableCredits)
            setSelectedPaymentType((accountViewModel.StripeCustomerId?.safeIsNullOrEmpty() ?? false) ? PaymentMethodsEnum.STRIPE : PaymentMethodsEnum.STRIPE_NEW_CARD);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountViewModel.StripeCustomerId, buyAssetViewModel.IsLowAvailableCredits, buyAssetViewModel.TotalPrice])

    useEffect(() => {
        setSelectedPaymentTypeHasChanged(true);
        if (selectedPaymentType === PaymentMethodsEnum.BITPAY)
            return setTransactionType(AssetTransactionType.REFUND)
        return setTransactionType(AssetTransactionType.BUY)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPaymentType])

    const saveExpressPaymentName = React.useCallback((expressPaymentName: string) => {
        setExpressPaymentName(expressPaymentName);
    }, [])

    return (
        <div className="buy-confirmation-view">
            <div className="confirm-payment-type">
                {paymentOptions.map((option) =>
                    <>
                        <PaymentOptionCard
                            isSelected={selectedPaymentType === option.value}
                            handleClick={(value) => setSelectedPaymentType(value)}
                            label={option.label}
                            value={option.value}
                            info={option.info}
                            isDisabled={option.isDisabled}
                            key={option.id}
                            renderSubComponent={option.renderSubComponent}
                        />
                        {(buyAssetViewModel.IsReady && (buyAssetViewModel.TotalPrice < MIN_PAYMENT_AMOUNT && option.value === PaymentMethodsEnum.STRIPE_NEW_CARD)) &&
                            <div className='warning-msg'>
                                {i18n.t('payment.error.credit-card.tooSmallAmount', { amount: MIN_PAYMENT_AMOUNT.toCurrency() })}
                            </div>
                        }
                    </>
                )}

            </div>

            {isTransactionDisabled ? <></> :
                <div className='hidden'>
                    <PaymentWalletCheckoutForm
                        getExpressPaymentName={saveExpressPaymentName}
                        key={buyAssetViewModel.Amount}
                        label={buyAssetViewModel.Asset.AssetName}
                        fiatAmount={buyAssetViewModel.Amount}
                        mode={CheckoutModeEnum.BUY_ASSET}
                        hasStripeCustomerId={accountViewModel.StripeCustomerId?.safeIsNullOrEmpty() ?? false}
                        trackEventOnPixel={true}
                        showLoader={true}
                        intent={{
                            unitaryPrice: buyAssetViewModel.UnitaryPrice,
                            assetId: buyAssetViewModel.AssetId,
                            amountOfShares: buyAssetViewModel.NumberOfToken,
                            isDirectBuy: buyAssetViewModel.Market === MarketEnum.PRIMARY,
                            fiatAmount: buyAssetViewModel.Amount,
                            concernedMarket: buyAssetViewModel.Market,
                            directBuyFromTheBestSellingOrders: buyAssetViewModel.Market === MarketEnum.SECONDARY,
                            type: buyAssetViewModel.OrderType
                        }}
                        buyAssetInErrorCallback={(_transactionData, _isDone) => { return new Promise(() => { (onError(_transactionData)) }) }}
                        buyAssetSuccessCallback={(_transactionData, _isDone) => { return new Promise(() => { onSuccess() }) }}
                        newCardForm={false} />
                </div>}

        </div>
    )
}

export default React.memo(ConfirmBuyAsset);
