import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import * as NotifcationActionsType from './actionsType';

export const managementNotification = (stateNotification: boolean, message: string = '', status?: number, verticalTop: boolean = false, link: string = ''): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    const type: string = stateNotification ? NotifcationActionsType.NOTIFICATION_DISPLAY : NotifcationActionsType.DISMISS;
    return dispatch({
        type,
        payload: { message, status, verticalTop, link },
    });
};

export const showNotification = (dispatch: Function, message: string, status: number, verticalTop: boolean = false) => (
    dispatch({
        type: NotifcationActionsType.NOTIFICATION_DISPLAY,
        payload: { message, status, verticalTop },
    })
);
