import * as React from 'react';
import { Trans } from 'react-i18next';
import './DescriptionFunCardsAssetProfilPage.scss';
import IDescriptionFunCardsAssetProfilPageProps from './IDescriptionFunCardsAssetProfilPageProps';
import FunAssetLogo from 'assets/images/fun-assets/fun-cards-logo.webp';

const DescriptionFunCardsAssetProfilPage: React.FC<IDescriptionFunCardsAssetProfilPageProps> = () => {

    return <div className='container-description-fun-cards-asset-profil-page'>
        <img src={FunAssetLogo} alt="fun cards logo" className='logo-fun-cards' />
        <p className="title-description-fun-cards">
            <Trans
                i18nKey={"fun.title.description.asset.profile.page"}
                components={{ span: <span className='fun-cards-gold-text' /> }}
            />
        </p>
        <p className="description-fun-cards">
            <Trans
                i18nKey={"fun.description.asset.profile.page"}
            />
        </p>
        <p className="second-description-fun-cards">
            <Trans
                i18nKey={"fun.description.second.asset.profile.page"}
            />
        </p>
        <p className="bold-description">
            <Trans
                i18nKey={"fun.bold.description.asset.profile.page"}
            />
        </p>
    </div>
};

export default DescriptionFunCardsAssetProfilPage;
