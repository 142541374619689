import { Capacitor } from '@capacitor/core';
import Application from 'Application';
import { CONNECTION_MODE, DEVICE_PLATFORM, LOCAL_STORAGE_KNOWN_KEYS, OauthProviderEnum, REACT_AB_TEST_KNOWN_KEYS, TRENDEX_ROLES, URLS } from 'config/_const';
import { ConnectionMode } from 'config/_enums';
import { loadProxyUrl } from 'config/axiosConfig';
import { AUTH_ENDPOINTS } from 'config/endpoints';
import useReferral from 'hooks/use-referral/useReferral';
import i18next from 'i18next';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { actionTokenSocialConnect } from 'service/auth/actions';
import ConnectViewModel from 'view-model/ConnectViewModel/ConnectViewModel';
import SignupSourcesViewModel from 'view-model/SignupSources/SignupSourcesViewModel';
import appleImg from '../../../assets/images/apple-flat.png';
import fimg from '../../../assets/images/facebook.png';
import gimg from '../../../assets/images/google.png';
import './SignUp.scss';
import SocialSignupProps from './SignupProps';

const SocialSignup: React.FC<SocialSignupProps> = (props) => {
    const redirectPostSocial = (redirectTo: string | undefined) => {
        if (redirectTo)
            return redirectTo;
        if (window.location.href.includes(URLS.profile.mainPage))
            return window.location.origin;
        if (!window.location.href.includes('/signup'))
            return window.location.href;
        return window.location.origin;
    };

    const dispatch = useDispatch();
    const history = useHistory();
    const authTypeConnect: string = window.location.href.includes('/vip') || localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.VIP_MODE) ? 'VIP' : 'NONE';
    const signupAsVip: boolean = authTypeConnect === TRENDEX_ROLES.VIP;
    const platform: string = Capacitor.getPlatform();
    const isWeb: boolean = platform === DEVICE_PLATFORM.WEB;
    const redirect: string = loadProxyUrl();
    const actualRedirectUrl: string = redirectPostSocial(props.redirectTo);
    const signupSourceViewModel = new SignupSourcesViewModel();
    const { referralViewModel } = useReferral();
    const landingVersion = new ConnectViewModel({ mode: ConnectionMode.register, showReCaptcha: false, startVideo: false }).ApplicationSportVersionEnum;
    let query = `redirectTo=${actualRedirectUrl}&isUserVip=${signupAsVip}&signupSourceChannel=${signupSourceViewModel.SignupSourceChannel}&signupSourceId=${signupSourceViewModel.SignupSourceId}`;
    if (props.intentPurchase)
        query += `&intentPurchase=${props.intentPurchase}`;
    if (localStorage.getItem(REACT_AB_TEST_KNOWN_KEYS.LOCAL_STORED_HOME_EXPERIMENT))
        query += '&fromAbTesting=true';
    if (referralViewModel.ReferralId)
        query += `&referralId=${referralViewModel.ReferralId}`;
    if (landingVersion)
        query += `&v=${landingVersion}`;
    const connectToSocialNetwork = (socialNetwork: OauthProviderEnum, loginThroughDispatch: boolean, event: React.MouseEvent<HTMLParagraphElement, MouseEvent>, url: string) => {
        if (props.onBeforeSocialLogin)
            props.onBeforeSocialLogin(socialNetwork);
        if (loginThroughDispatch) {
            event.preventDefault();
            return dispatch(actionTokenSocialConnect(socialNetwork, history, props.onSocialConnectSuccessCallback));
        }
        return window.location.assign(url);
    };

    return (
        <div className="sign-up">
            <div className="content-sign-up">
                <div className="loginsocial_buttons">
                    {Application.getInstance().showGoogleLoginButton && (
                        <p
                            className="google-button hover"
                            onClick={(e) => {
                                connectToSocialNetwork(OauthProviderEnum.GOOGLE, !isWeb, e, `${redirect}${AUTH_ENDPOINTS.SOCIAL_NETWORKS.GOOGLE}?${query}`);
                            }}>
                            <img className="img-fluid" src={gimg} alt="" />
                            <span>
                                {props.mode === CONNECTION_MODE.REGISTER ? i18next.t('register.withGoogle') : i18next.t('login.withGoogle')}
                            </span>
                        </p>)

                    }

                    {Application.getInstance().showAppleLoginButton && <p
                        className="apple-button hover"
                        onClick={(e) => {
                            connectToSocialNetwork(OauthProviderEnum.APPLE, !isWeb, e, `${redirect}${AUTH_ENDPOINTS.SOCIAL_NETWORKS.APPLE}?${query}`);
                        }}>
                        <img className="img-fluid" src={appleImg} alt="" />
                        <span>
                            {props.mode === CONNECTION_MODE.REGISTER ? i18next.t('register.withApple') : i18next.t('login.withApple')}
                        </span>
                    </p>}
                    {isWeb && Application.getInstance().showFacebookLoginButton && <p
                        className="facebook-button hover"
                        onClick={(e) => {
                            connectToSocialNetwork(OauthProviderEnum.FACEBOOK, !isWeb, e, `${redirect}${AUTH_ENDPOINTS.SOCIAL_NETWORKS.FACEBOOK}?${query}`);
                        }}>
                        <img className="img-fluid" src={fimg} alt="" />
                        <span>
                            {props.mode === CONNECTION_MODE.REGISTER ? i18next.t('register.withFb') : i18next.t('login.withFb')}
                        </span>
                    </p>}
                </div>

            </div>
        </div>
    );
};

export default SocialSignup;
