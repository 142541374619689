import PriceHistoryInterface from 'model/AssetModel/PriceHistoryInterface';
import { RootState } from 'model/Redux';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPriceHistoryByAssetSlug } from 'service/asset-current-information/actions';
import { selectorAssetInformationPriceHistory } from 'service/asset-current-information/selectors';
import { getAssetIdBySlugSelector } from './../../service/assets/selectors';
import IUsePriceHistory from './IUsePriceHistory';

const usePriceHistory = (slug: string): IUsePriceHistory => {
    const memoSlug: string = React.useMemo(() => slug, [slug]);
    const assetId: string = useSelector((state: RootState) => getAssetIdBySlugSelector(state, slug));
    const priceHistory: PriceHistoryInterface[] = useSelector((state: RootState) => selectorAssetInformationPriceHistory(state, assetId));
    const dispatch = useDispatch();
    const fetchPriceHistory = useCallback(() =>
        dispatch(fetchPriceHistoryByAssetSlug(memoSlug, assetId)), [dispatch, memoSlug, assetId]);

    React.useEffect(() => {
        if (priceHistory.length > 0 || !assetId)
            return;
        fetchPriceHistory();
    }, [memoSlug]);

    return {
        priceHistory,
        fetchPriceHistory,
    };
};

export default usePriceHistory;