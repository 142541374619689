import React from 'react'

const TeamIcon = () => {
    return (<svg id="Team" xmlns="http://www.w3.org/2000/svg" width="27.576" height="24" viewBox="0 0 27.576 24">
        <path id="Tracé_229" data-name="Tracé 229" d="M0,0H24V24H0Z" transform="translate(2.289)" fill="#15182b" opacity="0" />
        <g id="Groupe_5986" data-name="Groupe 5986" transform="translate(-20411.002 -13886.168)">
            <path id="Union_42" data-name="Union 42" d="M15.3,22.613a1,1,0,0,1-1-1v-6.17a1,1,0,0,1,1-1h2.96a3.052,3.052,0,0,0-2.553-1.373H11.867a3.052,3.052,0,0,0-2.553,1.373h2.959a1,1,0,0,1,1,1v6.17a1,1,0,0,1-1,1H8.144a1,1,0,0,1-1-1v-6.17a1,1,0,0,1,.338-.752,4.621,4.621,0,0,1,4.386-3.183h3.839a4.622,4.622,0,0,1,4.388,3.187,1,1,0,0,1,.334.748v6.17a1,1,0,0,1-1,1Zm7.148-3.067a1,1,0,0,1-1-1v-6.17a1,1,0,0,1,1-1h4.129a1,1,0,0,1,1,1v6.17a1,1,0,0,1-1,1ZM1,19.545a1,1,0,0,1-1-1v-6.17a1,1,0,0,1,1-1H5.125a1,1,0,0,1,1,1v6.17a1,1,0,0,1-1,1Zm21.946-9.352a2.363,2.363,0,0,0-2.36-2.36H17.716V7.578a3.956,3.956,0,0,0-.122-.982l-.081-.319h3.074A3.921,3.921,0,0,1,24.5,10.193a.779.779,0,0,1-1.557,0ZM10.393,7.578a3.394,3.394,0,1,1,3.394,3.394A3.4,3.4,0,0,1,10.393,7.578Zm1.557,0a1.837,1.837,0,1,0,1.837-1.836A1.839,1.839,0,0,0,11.95,7.578ZM3.07,10.193A3.922,3.922,0,0,1,6.988,6.277h3.074L9.98,6.6a4,4,0,0,0-.122.982v.256H6.988a2.363,2.363,0,0,0-2.36,2.36.779.779,0,0,1-1.558,0ZM17.193,2.871a2.871,2.871,0,1,1,2.871,2.871A2.874,2.874,0,0,1,17.193,2.871Zm1.557,0a1.314,1.314,0,1,0,1.314-1.314A1.315,1.315,0,0,0,18.75,2.871Zm-13.587,0A2.871,2.871,0,1,1,8.033,5.742,2.874,2.874,0,0,1,5.162,2.871Zm1.557,0A1.314,1.314,0,1,0,8.033,1.557,1.315,1.315,0,0,0,6.719,2.871Z" transform="translate(20411.002 13886.862)" fill="#15182b" />
        </g>
    </svg>
    )
}

export default TeamIcon;