import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import AxiosConfig from '../../config/axiosConfig';
import { showNotification } from '../notification/action';
import * as AssetTransactionLogActionType from './actionType';
import i18next from "i18next";
import { IHttpResponse } from '../../model/IHttpResponse';

export const actionFetchAssetTransactionsLog = (pageIndex: number, filter?: Object, sort?: string, search?: string, populate?: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    dispatch({
        type: AssetTransactionLogActionType.ASSET_TRANSACTION_LOG_LOADING,
    });

    let queryRequest: string = '';

    if (filter) {
        queryRequest += `filter=${JSON.stringify(filter)}`;
    }

    if (sort) {
        queryRequest += `sort=${sort}`;
    }

    if (search) {
        queryRequest += `&search=${search}`;
    }

    if (populate) {
        queryRequest += `&with=${populate}`;
    }

    try {

        const response: IHttpResponse = await AxiosConfig.get(`/assetTransactionsLog/all/${pageIndex}?${queryRequest}`);

        if (response.data.status !== 200) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: AssetTransactionLogActionType.ASSET_TRANSACTION_LOG_ERROR,
            });
        }

        return dispatch({
            type: AssetTransactionLogActionType.ASSET_TRANSACTION_LOG_SUCCESS,
            payload: {
                assetTransactionsLog: response.data.data,
            },
        });

    } catch (error) {
        console.log('error: ', error);

        showNotification(dispatch, error.stack, 500);

        return dispatch({
            type: AssetTransactionLogActionType.ASSET_TRANSACTION_LOG_ERROR,
        });
    }
};

export const actionFetchAssetTransactionsLogVip = (pageIndex: number, skipOptions?: boolean, filter?: Object, sort?: string, search?: string, populate?: object[]): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    dispatch({
        type: AssetTransactionLogActionType.ASSET_TRANSACTION_LOG_LOADING,
    });

    let queryRequest: string = '';

    if (filter) {
        queryRequest += `filter=${JSON.stringify(filter)}`;
    }

    if (sort) {
        queryRequest += `sort=${sort}`;
    }

    if (search) {
        queryRequest += `&search=${search}`;
    }

    if (populate) {
        queryRequest += `&with=${JSON.stringify(populate)}`;
    }
    if (skipOptions) {
        queryRequest += `&skipOptions=${skipOptions}`;
    }

    try {

        const response: IHttpResponse = await AxiosConfig.get(`/assetTransactionsLog/vipTransactionsLog/${pageIndex}?${queryRequest}`);
        if (response.data.status !== 200) {
            showNotification(dispatch, i18next.t(response.data.message), response.data.status);
            return dispatch({
                type: AssetTransactionLogActionType.ASSET_TRANSACTION_LOG_ERROR,
            });
        }

        return dispatch({
            type: AssetTransactionLogActionType.ASSET_TRANSACTION_LOG_SUCCESS,
            payload: {
                assetTransactionsLog: response.data.data,
            },
        });

    } catch (error) {
        showNotification(dispatch, error.stack, 500);

        return dispatch({
            type: AssetTransactionLogActionType.ASSET_TRANSACTION_LOG_ERROR,
        });
    }
};

