import Application from 'Application';
import { DEFAULT_ID_AS_STRING, MAX_TEAM_SCORE, TOURNAMENT_CATEGORY, TOURNAMENT_SUBJECT_TO_SPORT } from 'config/_const';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import ITeamAsset from 'model/Team/ITeamAsset';
import { IScoreBoost } from 'model/Tournament/ITournamentSubscription';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import TournamentViewModel from 'view-model/Tournament/TournamentViewModel/TournamentViewModel';
export const calculateTeamScoreAverage = (teamAssets: ITeamAsset[], assets: AssetViewModel[], assetCategories: AssetCategoryInterface[], tournamentType: TOURNAMENT_CATEGORY = TOURNAMENT_CATEGORY.DEFAULT, scoreBoost: IScoreBoost): number => {
    if (teamAssets.length === 0 || assets.length === 0)
        return 0;

    let totalScore: number = 0;

    if (tournamentType === TOURNAMENT_CATEGORY.DEFAULT || !TOURNAMENT_SUBJECT_TO_SPORT.includes(tournamentType)) {
        for (const teamAsset of teamAssets) {
            const asset: AssetViewModel | undefined = assets.find((assetViewModel: AssetViewModel) => assetViewModel.AssetId === teamAsset.asset);
            totalScore += asset && asset.IRLScore ? asset.IRLScore : 0;
        }
        return computeAverageWithBooster(teamAssets.length, totalScore, scoreBoost)
    }

    const sportCategory = assetCategories.find(category => category.name.toLowerCase() === tournamentType.toLowerCase());
    for (const teamAsset of teamAssets) {
        const asset: AssetViewModel | undefined = assets.find((assetViewModel: AssetViewModel) => assetViewModel.AssetId === teamAsset.asset && assetViewModel.MainCategoryId === sportCategory?._id);
        totalScore += asset && asset.SportScore ? asset.SportScore : 0;
    }

    return computeAverageWithBooster(teamAssets.length, totalScore, scoreBoost)
};

const computeAverage = (noOfAsset: number, totalScore: number) => Math.round(totalScore.safeDivideBy(noOfAsset) * 100) / 100;

const computeAverageWithBooster = (numberOfAsset: number, totalScore: number, scoreBoost: IScoreBoost) => {
    const isConfiguredBoostEnabled = Application.getInstance().IsTeamScoreBoostConfigured;
    const computedAverage = computeAverage(numberOfAsset, totalScore);
    if (!isConfiguredBoostEnabled || !scoreBoost.isAcquired)
        return computedAverage;

    const computedAverageWithBoostPoints = computedAverage + scoreBoost.points;
    return Math.min(computedAverageWithBoostPoints, MAX_TEAM_SCORE)
}

export const showAssetScore = (nextTournament: TournamentViewModel | undefined, tournamentInProgress: TournamentViewModel | undefined): { showTrendScore: boolean, showSportsScore: boolean } => {

    if (!nextTournament && !tournamentInProgress) {
        return {
            showSportsScore: true,
            showTrendScore: true
        };
    }

    const isDefaultTournament: boolean = Boolean(tournamentInProgress?.Id !== DEFAULT_ID_AS_STRING && tournamentInProgress?.IsTournamentDefault) || Boolean(nextTournament?.IsTournamentDefault);

    return {
        showTrendScore: isDefaultTournament ?? true,
        showSportsScore: !isDefaultTournament ?? true,
    }

}

