import React from 'react';
import './KycResultDialog.scss';
import IKycResultDialogProps from './IKycResultDialogProps';
import useAccount from 'hooks/use-account/useAccount';
import { Trans } from 'react-i18next';
import PrimaryButton from '../buttons/primary-button/PrimaryButton';
import SecondaryButton from '../buttons/secondary-button/SecondaryButton';
import { Link } from 'react-router-dom';

const KycResultDialog = ({ linkTo, customClassName, showSecondaryButton, onSecondaryButtonClick, onPrimaryButtonClick }: IKycResultDialogProps) => {
    const { accountViewModel } = useAccount();

    return (
        <div className={`kyc-result-dialog ${customClassName}`}>
            <Trans
                i18nKey={`onboarding.withdraw.kyc.popup.${accountViewModel.identityConfirmStatus.toLowerCase()}.message`}
                components={{ p: <p /> }}
            />
            <div className='buttons-container'>
                {showSecondaryButton &&
                    <Link to={linkTo}>
                        <SecondaryButton
                            className='cancel-button'
                            handleClick={onSecondaryButtonClick}
                            textButton={<Trans i18nKey={`onboarding.withdraw.kyc.popup.${accountViewModel.identityConfirmStatus.toLowerCase()}.secondary.button`} />}
                            receiveClickEvent={false}
                            type={'button'}
                            disabled={false} />
                    </Link>
                }

                <Link to={linkTo}>
                    <PrimaryButton
                        className='confirm-button'
                        handleClick={onPrimaryButtonClick}
                        textButton={<Trans i18nKey={`onboarding.withdraw.kyc.popup.${accountViewModel.identityConfirmStatus.toLowerCase()}.primary.button`} />}
                        receiveClickEvent={false}
                        type={'button'}
                        disabled={false} />
                </Link>
            </div>
        </div>
    )
}

export default KycResultDialog;