const integrationAsDevelopmentServer: boolean = JSON.parse(process.env.INTEGRATION_AS_DEFAULT_SERVER ?? 'false');
export const getMarketServiceBaseUrl = () => {
    if (window.location.host.includes('localhost'))
        return !integrationAsDevelopmentServer ? 'http://localhost:5002' : 'https://market-integration-api.trendex.vip';
    if (window.location.host.includes('integration-app.trendex.vip'))
        return 'https://market-integration-api.trendex.vip'
    if (window.location.host.includes('demo-app.trendex.vip'))
        return 'https://market-demo-api.trendex.vip';
    if (window.location.host.includes('app.trendex.vip'))
        return 'https://market-production-api.trendex.vip';
    return 'http://localhost:5002'
}

export const marketServiceUrl = `${getMarketServiceBaseUrl()}/market`