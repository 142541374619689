import React from 'react';
import GreenButton from '../../../small/buttons/green-button/GreenButton';
import RedButton from '../../../small/buttons/red-button/RedButton';
import AdminIdentityFooterProps from './AdminIdentityFooterProps';
import { VERIFICATION_STATUS } from '../../../../config/_const';

import './AdminIdentityFooter.scss';

const AdminIdentityFooter: React.FC<AdminIdentityFooterProps> = (props) => {

    const handleRefuseButton = () => {
        const identityRefusalReasons = window.prompt()
        identityRefusalReasons && props.confirmOrRefusedIdentity(VERIFICATION_STATUS.REFUSED, identityRefusalReasons)
    }

    return (
        <div className="admin-identity-footer">
            <div className="content-banner">

                {props.actualIdentityStatus && props.actualIdentityStatus !== VERIFICATION_STATUS.PENDING && (
                    <>
                        <p>Identity of the user is : {props.actualIdentityStatus}</p>

                        {props.actualIdentityStatus === VERIFICATION_STATUS.REFUSED &&
                            (<>
                                <p>Reasons : {props.identityRefusalReasons}</p>
                                <div>
                                    <GreenButton
                                        disabled={false}
                                        receiveClickEvent={false}
                                        textButton="Valid Identity"
                                        type="button"
                                        handleClick={() => props.confirmOrRefusedIdentity(VERIFICATION_STATUS.CONFIRMED)} />
                                </div>
                            </>)}

                        {props.actualIdentityStatus === VERIFICATION_STATUS.CONFIRMED &&
                            (<>
                                <div>
                                    <RedButton
                                        disabled={false}
                                        receiveClickEvent={false}
                                        textButton="Refused Identity"
                                        type="button"
                                        handleClick={handleRefuseButton} />
                                </div>
                            </>)}
                    </>
                )}

                {(!props.actualIdentityStatus || props.actualIdentityStatus === VERIFICATION_STATUS.PENDING) && (
                    <>
                        <div>
                            <GreenButton
                                disabled={false}
                                receiveClickEvent={false}
                                textButton="Valid Identity"
                                type="button"
                                handleClick={() => props.confirmOrRefusedIdentity(VERIFICATION_STATUS.CONFIRMED)} />
                        </div>

                        <div>
                            <RedButton
                                disabled={false}
                                receiveClickEvent={false}
                                textButton="Refused Identity"
                                type="button"
                                handleClick={handleRefuseButton} />
                        </div>
                    </>)}
            </div>
        </div>
    );
};

export default AdminIdentityFooter;
