import basketballRookie from "assets/images/Assets/asset-card-level-background/rookie/basketball.jpg";
import movieRookie from "assets/images/Assets/asset-card-level-background/rookie/cinema.jpg";
import fightRookie from 'assets/images/Assets/asset-card-level-background/rookie/fight.jpg';
import footballRookie from "assets/images/Assets/asset-card-level-background/rookie/football.jpg";
import instagramRookie from 'assets/images/Assets/asset-card-level-background/rookie/instagram.jpg';
import musicRookie from "assets/images/Assets/asset-card-level-background/rookie/music.jpg";
import rugbyRookie from 'assets/images/Assets/asset-card-level-background/rookie/rugby.jpg';
import tiktokRookie from 'assets/images/Assets/asset-card-level-background/rookie/tiktok.jpg';
import youtubeRookie from "assets/images/Assets/asset-card-level-background/rookie/youtube.jpg";
import handballRookie from "assets/images/Assets/asset-card-level-background/rookie/handball.jpg";

import basketballContender from "assets/images/Assets/asset-card-level-background/contender/basketball.jpg";
import movieContender from "assets/images/Assets/asset-card-level-background/contender/cinema.jpg";
import fightContender from 'assets/images/Assets/asset-card-level-background/contender/fight.jpg';
import footballContender from "assets/images/Assets/asset-card-level-background/contender/football.jpg";
import instagramContender from 'assets/images/Assets/asset-card-level-background/contender/instagram.jpg';
import musicContender from "assets/images/Assets/asset-card-level-background/contender/music.jpg";
import rugbyContender from 'assets/images/Assets/asset-card-level-background/contender/rugby.jpg';
import tiktokContender from 'assets/images/Assets/asset-card-level-background/contender/tiktok.jpg';
import youtubeContender from "assets/images/Assets/asset-card-level-background/contender/youtube.jpg";
import handballContender from "assets/images/Assets/asset-card-level-background/contender/handball.jpg";

import basketballChampion from "assets/images/Assets/asset-card-level-background/champion/basketball.jpg";
import movieChampion from "assets/images/Assets/asset-card-level-background/champion/cinema.jpg";
import footballChampion from "assets/images/Assets/asset-card-level-background/champion/football.jpg";
import instagramChampion from 'assets/images/Assets/asset-card-level-background/champion/instagram.jpg';
import musicChampion from "assets/images/Assets/asset-card-level-background/champion/music.jpg";
import rugbyChampion from 'assets/images/Assets/asset-card-level-background/champion/rugby.jpg';
import tiktokChampion from 'assets/images/Assets/asset-card-level-background/champion/tiktok.jpg';
import youtubeChampion from "assets/images/Assets/asset-card-level-background/champion/youtube.jpg";
import fightChampion from 'assets/images/Assets/asset-card-level-background/champion/fight.jpg';
import handballChampion from 'assets/images/Assets/asset-card-level-background/champion/handball.jpg';


import basketballLegend from "assets/images/Assets/asset-card-level-background/legend/basketball.jpg";
import movieLegend from "assets/images/Assets/asset-card-level-background/legend/cinema.jpg";
import fightLegend from 'assets/images/Assets/asset-card-level-background/legend/fight.jpg';
import footballLegend from "assets/images/Assets/asset-card-level-background/legend/football.jpg";
import instagramLegend from 'assets/images/Assets/asset-card-level-background/legend/instagram.jpg';
import musicLegend from "assets/images/Assets/asset-card-level-background/legend/music.jpg";
import tiktokLegend from 'assets/images/Assets/asset-card-level-background/legend/tiktok.jpg';
import youtubeLegend from "assets/images/Assets/asset-card-level-background/legend/youtube.jpg";
import rugbyLegend from 'assets/images/Assets/asset-card-level-background/legend/rugby.jpg';

import { IAssetsBgImages } from "interfaces/IAssetBgImages";
import { AssetCardLevel } from 'config/_enums';

const assetsBgImagesPath: IAssetsBgImages = {
    youtube: {
        [AssetCardLevel.Rookie]: youtubeRookie,
        [AssetCardLevel.Contender]: youtubeContender,
        [AssetCardLevel.Champion]: youtubeChampion,
        [AssetCardLevel.Legend]: youtubeLegend,
    },
    cinema: {
        [AssetCardLevel.Rookie]: movieRookie,
        [AssetCardLevel.Contender]: movieContender,
        [AssetCardLevel.Champion]: movieChampion,
        [AssetCardLevel.Legend]: movieLegend,
    },
    basketball: {
        [AssetCardLevel.Rookie]: basketballRookie,
        [AssetCardLevel.Contender]: basketballContender,
        [AssetCardLevel.Champion]: basketballChampion,
        [AssetCardLevel.Legend]: basketballLegend,
    },
    music: {
        [AssetCardLevel.Rookie]: musicRookie,
        [AssetCardLevel.Contender]: musicContender,
        [AssetCardLevel.Champion]: musicChampion,
        [AssetCardLevel.Legend]: musicLegend,
    },
    fight: {
        [AssetCardLevel.Rookie]: fightRookie,
        [AssetCardLevel.Contender]: fightContender,
        [AssetCardLevel.Champion]: fightChampion,
        [AssetCardLevel.Legend]: fightLegend,
    },
    football: {
        [AssetCardLevel.Rookie]: footballRookie,
        [AssetCardLevel.Contender]: footballContender,
        [AssetCardLevel.Champion]: footballChampion,
        [AssetCardLevel.Legend]: footballLegend,
    },
    rugby: {
        [AssetCardLevel.Rookie]: rugbyRookie,
        [AssetCardLevel.Contender]: rugbyContender,
        [AssetCardLevel.Champion]: rugbyChampion,
        [AssetCardLevel.Legend]: rugbyLegend,
    },
    instagram: {
        [AssetCardLevel.Rookie]: instagramRookie,
        [AssetCardLevel.Contender]: instagramContender,
        [AssetCardLevel.Champion]: instagramChampion,
        [AssetCardLevel.Legend]: instagramLegend,
    },
    tiktok: {
        [AssetCardLevel.Rookie]: tiktokRookie,
        [AssetCardLevel.Contender]: tiktokContender,
        [AssetCardLevel.Champion]: tiktokChampion,
        [AssetCardLevel.Legend]: tiktokLegend,
    },
    handball: {
        [AssetCardLevel.Rookie]: handballRookie,
        [AssetCardLevel.Contender]: handballContender,
        [AssetCardLevel.Champion]: handballChampion,
        [AssetCardLevel.Legend]: handballRookie,
    },
}

export default assetsBgImagesPath;