import React from 'react';
import { withTranslation } from 'react-i18next';
import withWhiteListDisclaimer from 'hoc/with-white-list-disclaimer/withWhiteListDisclaimer';
import WhiteListDisclaimerProps from './WhiteListDisclaimerProps';
import './style.scss';

const WhiteListDisclaimer: React.FC<WhiteListDisclaimerProps> = ({ whiteListDisclaimerViewModel, icon, translate, onClick }) => {
    return (
        <div className="whitelist-disclaimer" onClick={onClick}>
            <p className="title">{whiteListDisclaimerViewModel.isUserWhiteList && icon}<b>{translate(whiteListDisclaimerViewModel.Title.key)}</b></p>
            <p className="explaination-challenge">{translate(whiteListDisclaimerViewModel.Description.key, whiteListDisclaimerViewModel.Description.values)}</p>
        </div>
    )
};

export default withTranslation()(withWhiteListDisclaimer(WhiteListDisclaimer));
