import i18n from '18n';
import { IUserOrder } from 'model/IOrder';
import { OrderSide } from 'config/_enums';

export default class WalletOrderViewModel {
    private _order: IUserOrder;
    private _createdAt: Date;
    constructor(order: IUserOrder) {
        this._order = order;
        this._createdAt = new Date(order.createdAt);
    }

    public get relatedAsset() {
        return this._order.asset;
    }
    public get id() {
        return this._order._id;
    }
    public get side() {
        return this._order.side;
    }
    public get isSellOrder() {
        return this._order.side === OrderSide.SELL;
    }

    public get filledAmountString() {
        return `${this._order.filled}/${this._order.amount}`
    }

    public get restAmount() {
        return this._order.amount - this._order.filled;
    }

    public get totalOrderPrice() {
        return this.restAmount * this._order.price;
    }

    public get formattedCreatedAtDate() {
        return this._createdAt.formatDDMMYY();
    }

    public get formattedCreatedAtTime() {
        return this._createdAt.toTimeString().split(' ')[0];
    }
    public get orderStatus() {
        return this._order.status;
    }

    public orderDescription = (key: string) => {
        switch (key) {
            case 'waiting':
                return i18n.t('wallet.order.description.waiting');
            case 'queued':
                return i18n.t('wallet.order.description.queued');
            case 'description.text':
                return `${i18n.t(this.isSellOrder ?
                    'wallet.order.description.sell' :
                    'wallet.order.description.buy', {
                    shareCount: this._order.amount, assetName: this._order.asset.name
                })}.`;
            case 'summary.tokens':
                return `${i18n.t(this.isSellOrder ?
                    'wallet.order.description.sold' :
                    'wallet.order.description.shares')}: 
                    ${this.filledAmountString}`;
            case 'summary.price':
                return `${i18n.t(this.isSellOrder ?
                    'wallet.order.description.minPrice' :
                    'wallet.order.description.sharePrice')}: 
                    ${(this._order.minimumSellPrice || this._order.price).toCurrency(2, this._order.currency)}`
            case 'summary.total':
                return `${i18n.t('wallet.order.description.total')}: ${this.totalOrderPrice.toCurrency(2, this._order.currency)}`;
            case 'cancelOrder':
                return i18n.t('wallet.order.description.cancelOrder');
            default:
                return '';
        }
    }




}

