import * as React from 'react';

const CrossMark: React.FC = (props: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={props.className ?? "share-icon"} width={props.width ?? "7.575"} height={props.height ?? "8.05"} viewBox="0 0 7.575 8.05">
            <g id="Groupe_6287" data-name="Groupe 6287" transform="translate(-317.523 -607.393)">
                <line id="Ligne_279" data-name="Ligne 279" x2="4.75" y2="5.225" transform="translate(318.936 608.806)" fill="none" stroke="#e66f81" strokeLinecap="round" strokeWidth="2" />
                <line id="Ligne_280" data-name="Ligne 280" x1="4.75" y2="5.225" transform="translate(318.936 608.806)" fill="none" stroke="#e66f81" strokeLinecap="round" strokeWidth="2" />
            </g>
        </svg>
    );
}

export default CrossMark;
