import React from 'react';
import './CheckIcon.scss';

const CheckIcon: React.FC = () => {
    return (
        <div className="check-icon">
            <div className="check-container-success">
                <div className="check-background-success">
                    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="check-shadow-success"></div><br></br>
            </div>
        </div>
    );
};

export default CheckIcon;
