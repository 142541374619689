import WalletSheet from 'components/medium/wallet/WalletSheet'
import IUseAccount from 'hooks/use-account/IUseAccount'
import useAccount from 'hooks/use-account/useAccount'
import IUseWallet from 'hooks/wallet/use-wallet/IUseWallet'
import useWallet from 'hooks/wallet/use-wallet/useWallet'
import React from 'react'
import './style.scss'

const WalletOnboarding: React.FC = () => {
    const { accountViewModel }: IUseAccount = useAccount();
    const { walletViewModel }: IUseWallet = useWallet({ considerTeamShares: false });
    return (
        <div className='wallet-onboarding'>
            <div className='onboarding-lightwallet-bg' />
            <div className='onboarding-wallet-sell-container'>
                <WalletSheet isSidebar={false} onClose={() => console.log} accountViewModel={accountViewModel} walletViewModel={walletViewModel} />
            </div>
        </div>
    )
}

export default WalletOnboarding