import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import WalletListViewCellOnboarding from 'components/medium/wallet/wallet-list-view/wallet-list-view-cell-onboarding/WalletListViewCellOnboarding';
import { ZERO } from 'config/_const';
import useAssetsCategories from 'hooks/asset-categories/useAssetCategories';
import useIsMobile from "hooks/useIsMobile";
import useWallet from 'hooks/wallet/use-wallet/useWallet';
import React from "react";
import { Trans } from "react-i18next";
import WalletAssetListViewModel from 'view-model/WalletAssetViewModel/WalletAssetListViewModel';
import AssetWalletViewModel from 'view-model/WalletViewModel/AssetWalletViewModel';
import './OnboardingLightWallet.scss';

const OnboardingLightWallet: React.FC = () => {
    const isMobile = useIsMobile();
    const { walletViewModel } = useWallet({ considerTeamShares: false });
    const { assetsCategories } = useAssetsCategories();
    const totalPrice = walletViewModel.UserAssets.reduce((acc, asset) => acc + (asset.amount.multiply(asset.asset.marketPrice)), ZERO);

    const renderOnboardingLightWallet = () => (
        <div data-rsbs-scroll='true'>
            <div data-rsbs-content='true'>
                <div className="onboarding-lightwallet-container" >
                    <span className="close-wallet rotate">{!isMobile ? <KeyboardArrowRight /> : <KeyboardArrowDown />}</span>
                    <h3 className='wallet-title'><Trans i18nKey="wallet.yourWallet" /></h3>
                    <div className="header">
                        <h3 className="title"><Trans i18nKey='onboarding.light-wallet.header.title' /></h3>
                        <p className="subtitle no-margin"><Trans i18nKey='onboarding.light-wallet.header.subtitle' components={[<br key='br' />]} values={{ amount: totalPrice.toCurrency(ZERO) }} /></p>
                    </div>
                    <div className="cards-container">
                        <h4 className="title"><Trans i18nKey="common.tokens" /></h4>
                        <div className='content'>
                            {walletViewModel.UserAssets.map((assetWallet, index) => {
                                const assetWalletAsViewModel: AssetWalletViewModel = new AssetWalletViewModel(assetWallet);

                                return (
                                    <WalletListViewCellOnboarding
                                        key={index}
                                        asset={new WalletAssetListViewModel({
                                            ...assetWalletAsViewModel.Asset.Asset,
                                            amount: assetWalletAsViewModel.Amount,
                                            profit: assetWalletAsViewModel.Earning,
                                            tokensInTeam: assetWalletAsViewModel.TokensInTeam,
                                            yieldAmountPerYear: ZERO,
                                            yieldTotalAmountEarned: ZERO,
                                            isYieldEnabled: false,
                                        })}
                                        assetCategories={assetsCategories}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    if (isMobile)
        return (
            <div data-rsbs-overlay="true" className='onboarding-lightwallet'>
                <div data-rsbs-header="true"></div>
                {renderOnboardingLightWallet()}
            </div>
        );
    return (
        <>
            <div className='onboarding-lightwallet-bg' />
            <div data-rsbs-overlay="true" className='onboarding-lightwallet desktop'>
                {renderOnboardingLightWallet()}
            </div>
        </>
    );
};

export default React.memo(OnboardingLightWallet);