import { ISvgIconProps } from 'interfaces/SvgIcon.interface';
import * as React from 'react';

const RoiDuotone: React.FC<ISvgIconProps> = ({ ...props }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" {...props} height="20" viewBox="0 0 20 20">
            <g id="icon" transform="translate(0 -728.5)">
                <g id="Rectangle_2014" data-name="Rectangle 2014" transform="translate(0 728.5)" fill="#8187d0" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="20" height="20" stroke="none" />
                    <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
                </g>
                <g id="seedling-duotone" transform="translate(1.012 698.635)">
                    <path id="Tracé_3808" data-name="Tracé 3808" d="M2.247,95.1H0a7.863,7.863,0,0,0,7.865,7.865v4.494a1.128,1.128,0,0,0,1.092,1.155,1.176,1.176,0,0,0,1.155-1.124V103A7.892,7.892,0,0,0,2.247,95.1Z" transform="translate(0 -60.885)" fill="#8187d0" />
                    <path id="Tracé_3809" data-name="Tracé 3809" d="M247.524,32a7.863,7.863,0,0,1-7.865,7.865v2.247a7.827,7.827,0,0,0-1.759-4.954A7.86,7.86,0,0,1,245.277,32Z" transform="translate(-229.547)" fill="#8187d0" opacity="0.4" />
                </g>
            </g>
        </svg>
    )
};

export default RoiDuotone;


