import * as React from 'react';
import ITokenYieldInformationBannerProps from './ITokenYieldInformationBanner';
import TokenYieldInformation from '../token-yield-information/TokenYieldInformation';
import './TokenYieldInformationBanner.scss';
import i18n from '18n';
import useModalDisplay from 'hooks/use-modal-display/useModalDisplay';

const TokenYieldInformationBanner: React.FC<ITokenYieldInformationBannerProps> = (props) => {
    const { showYieldModal } = useModalDisplay();

    return (
        <section className='token-yield-information-banner'>
            <TokenYieldInformation
                maxAPY={props.maxAPY}
                seedlingIconSize={props.seedlingIconSize}
                minYield={props.minYield}
                maxYield={props.maxYield}
                canShowYieldChartText={props.canShowYieldChartText}
            />
            <div className='token-yield-information-banner__details' >
                <h6 className='details__title'>
                    {i18n.t('token-information.yield')}
                </h6>
                <p className='details__info'>
                    {i18n.t('token-information.yield.banner.info', { yieldPercentage: parseFloat(props.maxAPY.toPercentage()) })}
                    <span
                        className='details__learn-more cursor-pointer'
                        onClick={() => showYieldModal(props.maxAPY)}
                    >
                        {i18n.t('general.learn.more')}
                    </span>
                </p>
            </div>
        </section>
    );
};

export default TokenYieldInformationBanner;
