import ReduxActionInterface from '../../model/ReduxActionInterface';
import ReduxRootState from '../../model/Redux/ReduxRootSate';

import * as withdrawFeeActionsTypes from './actionsTypes';

const ROOT_VALUE_STATE: ReduxRootState = {
    loading: false,
    data: {
        feesRate: 0,
        feesFixedAmount: 0,
    },
};

export const withdrawFeeReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    switch (action.type) {
        case withdrawFeeActionsTypes.WITHDRAWS_FEE_LOADING:
            return {
                ...state,
                loading: true,
            };
        case withdrawFeeActionsTypes.WITHDRAWS_FEE_ERROR:

            return {
                ...state,
                loading: false,
            };
        case withdrawFeeActionsTypes.WITHDRAWS_FEE_SUCCESS:
            return {
                ...state,
                data: {
                    feesRate: action.payload.feesRate,
                    feesFixedAmount: action.payload.feesFixedAmount,
                },
            };
        default: return state;
    }
};
