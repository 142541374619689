import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { IHttpStrongTypedResponse } from './../../model/IHttpResponse';
import AxiosConfig from '../../config/axiosConfig';
import * as TeamsConfigurationActionTypes from './actionTypes';
import { TEAM_CONFIGURATION_ENDPOINTS } from '../../config/endpoints';
import { HTTP_STATUS_OK } from '../../config/_const';
import { showNotification } from '../notification/action';
import ITeamConfig from '../../model/Team/ITeamConfig';
import { TournamentLeagues } from 'config/_enums';

export const createTeamConfiguration = (teamConfig: Required<Pick<ITeamConfig, 'maxNumberOfAssets' | 'minimumSharesByAsset' | 'defaultFiatAmount'>> & Partial<ITeamConfig>): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_LOADING });
        const response: IHttpStrongTypedResponse<ITeamConfig> = await AxiosConfig.post(TEAM_CONFIGURATION_ENDPOINTS.CREATE, teamConfig);
        if (response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_ERROR });
        }
        return dispatch({
            type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_SUCCESS,
            payload: {
                teamConfigs: [response.data.data],
            },
        });
    } catch (exception) {
        return dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_ERROR });
    }
};

export const updateTeamConfiguration = (teamConfig: Required<Pick<ITeamConfig, '_id'>> & Partial<ITeamConfig>): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_LOADING });
        const response: IHttpStrongTypedResponse<ITeamConfig> = await AxiosConfig.put(TEAM_CONFIGURATION_ENDPOINTS.UPADATE, teamConfig);
        if (response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_ERROR });
        }
        return dispatch({
            type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_SUCCESS,
            payload: {
                teamConfigs: [response.data.data],
            },
        });
    } catch (exception) {
        return dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_ERROR });
    }
};

export const deleteTeamConfiguration = (id: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_LOADING });
        const response: IHttpStrongTypedResponse<ITeamConfig> = await AxiosConfig.delete(`${TEAM_CONFIGURATION_ENDPOINTS.DELETE}/${id}`);
        if (response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_ERROR });
        }
        return dispatch({
            type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_DELETED,
            payload: {
                teamConfigId: id,
            },
        });
    } catch (exception) {
        return dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_ERROR });
    }
};

export const getTeamConfiguration = (leagueType: TournamentLeagues): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_LOADING });
        const response: IHttpStrongTypedResponse<ITeamConfig> = await AxiosConfig.get(`${TEAM_CONFIGURATION_ENDPOINTS.GET}/${leagueType}`);
        if (response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_ERROR });
        }
        return dispatch({
            type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_SUCCESS,
            payload: {
                teamConfigs: [response.data.data],
            },
        });
    } catch (exception) {
        return dispatch({ type: TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_ERROR });
    }
};
