import IUseTournament from 'hooks/tournament/use-tournament/IUseTournament';
import useTournament from 'hooks/tournament/use-tournament/useTournament';
import React from 'react';
import IInjectedWithTournamentInProgress from './IInjectedWithTournamentInProgress';
import TournamentViewModel from 'view-model/Tournament/TournamentViewModel/TournamentViewModel';

const withTournamentInProgress = <ExtraProps,>(ChildComponent: React.ComponentType<ExtraProps & IInjectedWithTournamentInProgress>): React.MemoExoticComponent<React.FC<ExtraProps>> => {
    const ParentComponent: React.FC<ExtraProps> = (args: ExtraProps) => {
        const { tournamentInProgress, fetchTournamentInProgress }: IUseTournament = useTournament();
        const tournamentVM: TournamentViewModel = React.useMemo(() => {
            return tournamentInProgress ?? new TournamentViewModel({});
        }, [tournamentInProgress]);

        React.useEffect(() => {
            if (!tournamentInProgress)
                fetchTournamentInProgress();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [tournamentInProgress]);

        return <ChildComponent  {...args as ExtraProps} tournamentInProgress={tournamentVM} />
    }

    return React.memo<React.FC<ExtraProps>>(ParentComponent);
};

export default withTournamentInProgress;