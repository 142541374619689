import FightWhiteIcon from 'components/icons/FightWhiteIcon';
import AssetAvatar from 'components/small/asset-avatar/AssetAvatar';
import ScoreLabel from 'components/small/score-label/ScoreLabel';
import { ZERO } from 'config/_const';
import useAssetInitialSalePeriod from "hooks/use-assetCurrentInfos/useAssetInitialSalePeriod";
import { ICategoryTypeName } from 'interfaces/IAssetBgImages';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Color from 'view-model/Shared/Color';
import IWalletInformationMap from 'view-model/WalletAssetViewModel/IWalletInformationMap';
import IWalletListViewCellProps from './IWalletListViewCellProps';
import './style.scss';

const WalletListViewCell: React.FC<IWalletListViewCellProps> = ({ t: translation, asset, assetCategories, renderPortfolioActions, showOnboardingTooltip }: IWalletListViewCellProps) => {
    const { withinFakePrimaryMarket } = useAssetInitialSalePeriod({
        assetId: asset.Id,
        initialSaleEndDate: asset.InitialSaleRealEndDate
    });

    return (
        <div className='wallet-portfolio-list-item' key={asset.Id}>
            <div className="cell-container">
                <div className="icon-container">
                    <AssetAvatar
                        asset={{
                            avatarPath: asset.icon,
                            mainCategoryId: asset.mainCategory,
                            cardLevel: asset.CardLevel
                        }}
                        className="portfolio-item-avatar-img"
                    />
                </div>

                <div className="content-container">
                    <div className="header-container">
                        <div className="portfolio-item-introduction">
                            {asset.isAssetInTeam && (
                                <div className="fight-icon-container">
                                    <div className="trendscore-container-img">  <FightWhiteIcon color="white" /></div>
                                    <p className="asset-category no-margin">{translation('wallet.team')}</p>
                                </div>)}
                            <p className="asset-name no-margin">{asset.Name}</p>
                            <div className='asset-pseudo-detail'>
                                {asset.AssetAsVM.ShowTrendScore && <ScoreLabel
                                    score={asset.AssetAsVM.getTrendScoreToDisplay(2)}
                                    canShowBoost={asset.ScoreBoosted}
                                    backgroundColor={Color.getColorByInputRatio(asset.IRLScore)}
                                    tooltipText={asset.getScoreTooltipText(translation('tooltip.trend.score'))}
                                />}
                                {asset.canShowSportScore(assetCategories) &&
                                    <ScoreLabel
                                        score={asset.AssetAsVM.getSportScoreToDisplay(2)}
                                        category={asset.getAssetCategoryName(assetCategories) as ICategoryTypeName}
                                        canShowBoost={asset.ScoreBoosted}
                                        backgroundColor={Color.getColorByInputRatio(asset.sportsScore)}
                                        tooltipText={asset.getScoreTooltipText(translation('tooltip.sport.score'))}
                                    />
                                }
                                <p className="asset-category no-margin">{asset.getAssetCategoryName(assetCategories)}</p>
                            </div>

                        </div>

                        <div className="amount-container">
                            <p className="current-value-wallet-item no-margin">{asset.getTotalHoldingValue().toCurrency()}</p>
                            <p className="number-of-trends no-margin">{translation('wallet.shares')}: {asset.Amount}</p>
                        </div>
                    </div>


                    <div className="portfolio-item-contents">
                        {asset.getAssetInformationMap().map((info: IWalletInformationMap) => {
                            if (info.label === 'wallet.year.yield' && !asset.IsYieldEnabled)
                                return null;
                            return (
                                <React.Fragment key={info.order}>
                                    <div key={info.order} className="portfolio-contents-item">
                                        <p className="item-title no-margin">{translation(info.label)}</p>
                                        <p className={`item-value no-margin ${info.color}`}>{info.value} <sup className="info-sup">{info.sup}</sup></p>
                                    </div>

                                    <span className="divider-content"></span>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {renderPortfolioActions(asset.Slug, asset.Amount ?? ZERO, !withinFakePrimaryMarket, asset.AmountWithoutTokensInTeam ?? ZERO, showOnboardingTooltip)}
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(React.memo(WalletListViewCell));
