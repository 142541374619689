import Success from 'assets/images/modal-tournament-confirmation/success.gif';
import GreenButtonWithGradient from 'components/small/buttons/green-button/GreenButtonWithGradient';
import React from 'react';
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import IBuyTournamentTicketConfirmationProps from './IBuyTournamentTicketConfirmationProps';
import './style.scss';

const BuyTournamentSubscriptionConfirmation: React.FC<IBuyTournamentTicketConfirmationProps> = ({ t: translate, onButtonClick, league }: IBuyTournamentTicketConfirmationProps) => {
    return (
        <div className='container-modal-buy-ticket-confirmation'>
            <div className='container-top-on-the-left-img'><img src={Success} alt='Congratulations' /></div>
            <h3>{translate('tournament.ticket.confirmation.title', { leagueName: league.Name })}</h3>
            <p className='first-sentence'>{translate('tournament.ticket.confirmation.message')}</p>
            <Link to='/tournament'>
                <GreenButtonWithGradient
                    textButton={translate('next')}
                    type={'button'}
                    receiveClickEvent={false}
                    handleClick={onButtonClick}
                    disabled={false}
                    className={'tournament-confirmation-modal-on-boarding-btn'} />
            </Link>
        </div>
    )
};

export default withTranslation()(BuyTournamentSubscriptionConfirmation);