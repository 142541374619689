import InstantLiquidityChip from 'components/small/instantLiquidity-chip/InstantLiquidityChip';
import LoveBanner from 'components/small/love-banner/LoveBanner';
import { LAUNCH_MODE, SHOW_LOVE_BOX } from 'config/_const';
import { AssetCardLevel } from 'config/_enums';
import * as React from 'react';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import AssetProfilActionButtons from 'components/small/asset-profil-action-button/AssetProfilActionButtons';
import IProfileMidSectionProps from './ProfileMidSectionProps';
import './ProfileMidSection.scss';

const ProfileMidSection: React.FC<IProfileMidSectionProps> = (props) => {
    const assetVM: AssetViewModel = React.useMemo(() => new AssetViewModel(props.asset), [props.asset]);

    return <section className='profile-mid-section'>
        {assetVM.LiquidityPoolEnabled && <InstantLiquidityChip isListView={true} isOnlyLiquidityPool={assetVM.LaunchMode === LAUNCH_MODE.LIQUIDITY_POOL}/>}
        {SHOW_LOVE_BOX && assetVM.CardLevel === AssetCardLevel.Rookie && assetVM.ShowLoveBanner && <LoveBanner />}
        <AssetProfilActionButtons assetVM={assetVM} />
    </section>
};

export default React.memo(ProfileMidSection);
