import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';

import type { DeviceInfo } from '@capacitor/device';
import * as ActionsTypes from './actionsType';

export interface AppConfigState extends Omit<ReduxRootState, 'data'> {
    data: {
        device: DeviceInfo | null;
    };
}

const INITIAL_STATE: AppConfigState = {
    loading: false,
    data: {
        device: null,
    },
};

export const appConfigReducer = (state = INITIAL_STATE, action: ReduxActionInterface) => {
    switch (action.type) {
        case ActionsTypes.SET_THEME: {
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                },
            };
        }

        case ActionsTypes.SET_DEVICE: {
            const device = action.payload as DeviceInfo;

            return {
                ...state,
                data: {
                    ...state.data,
                    device,
                },
            };
        }

        default: return state;
    }
};
