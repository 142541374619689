// 20230216124851
// https://gist.githubusercontent.com/ksafranski/2973986/raw/5fda5e87189b066e11c1bf80bbfbecb556cf2cc1/Common-Currency.json

const CURRENCIES = {
    "USD": {
      "symbol": "$",
      "name": "US Dollar",
      "symbol_native": "$",
      "decimal_digits": 2,
      "rounding": 0,
      "code": "USD",
      "name_plural": "US dollars"
    },
    "EUR": {
      "symbol": "€",
      "name": "Euro",
      "symbol_native": "€",
      "decimal_digits": 2,
      "rounding": 0,
      "code": "EUR",
      "name_plural": "euros"
    },
    "GBP": {
      "symbol": "£",
      "name": "British Pound Sterling",
      "symbol_native": "£",
      "decimal_digits": 2,
      "rounding": 0,
      "code": "GBP",
      "name_plural": "British pounds sterling"
    },
    "SAR": {
      "symbol": "SR",
      "name": "Saudi Riyal",
      "symbol_native": "ر.س",
      "decimal_digits": 2,
      "rounding": 0,
      "code": "SAR",
      "name_plural": "Saudi riyals"
    },
};

let SELECTABLE_CURRENCIES: {value: string; label: string}[] = [];

for(const [key, currencyValue] of Object.entries(CURRENCIES)) {
    SELECTABLE_CURRENCIES.push({value: key, label: `${currencyValue.symbol_native} ${currencyValue.name}`});
}


export { CURRENCIES, SELECTABLE_CURRENCIES };
