import { COUNTRY, ID_FACES, ID_TYPES, VERIFICATION_STATUS } from 'config/_const';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import i18next from 'i18next';
import UserInterface from 'model/User/UserInterface';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { sendIdentity } from 'service/auth/actions';
import './ValidIdentity.scss';
import IdentityForm from './state-id-identity/IdentityForm';

const ValidIdentity: React.FC = () => {
    const dispatch = useDispatch();
    const errorTrad = i18next.t('resale.errorHasOccurred');
    const [rectoImage, setRectoImage] = useState<string>('');
    const [versoImage, setVersoImage] = useState<string>('');
    const [rectoImgUrl, setRectoImgUrl] = useState<string>('');
    const [versoImgUrl, setVersoImgUrl] = useState<string>('');
    const [showChooseIdDocumentType, setShowChooseIdDocumentType] = useState<boolean>(true);
    const [showIdNumberUserInput, setShowIdNumberUserInput] = useState<boolean>(false);
    const [idTypeChosen, setIdTypeChosen] = useState<string>('');
    const { accountViewModel }: IUseAccount = useAccount();
    const account: UserInterface = accountViewModel.account;
    const accountConfirmationRefusedOrNotConfirmed = (!account.isConfirmed || account.isConfirmed === VERIFICATION_STATUS.REFUSED);
    const accountConfirmationPendingOrVerified = account.isConfirmed && (account.isConfirmed === VERIFICATION_STATUS.PENDING || account.isConfirmed === VERIFICATION_STATUS.CONFIRMED);
    const arrayOfSupportedCountry = ['US', 'GB', 'FR'];
    const countryOfUser: string = account.country !== '' && arrayOfSupportedCountry.indexOf(account.country) > -1 ? account.country : COUNTRY.DEFAULT;

    useEffect(() => {
        if (![ID_TYPES.ID_CARD, ID_TYPES.PASSPORT].includes(idTypeChosen))
            return;
        setShowIdNumberUserInput(true);
        setShowChooseIdDocumentType(false);
    }, [idTypeChosen]);

    const onIdentityChangeCallback = (img: string, imgUrl: string, type: string): void => {
        if (type === ID_FACES.RECTO) {
            setRectoImage(img);
            setRectoImgUrl(imgUrl)
        }
        else {
            setVersoImage(img);
            setVersoImgUrl(imgUrl)
        }
    };

    const handleSubmit = (e: any, identificationDocumentNumber: string) => {
        e.preventDefault();
        dispatch(sendIdentity(rectoImage, versoImage, identificationDocumentNumber));
    }

    try {
        const onIdentityChange = (e: any, type: string): void => {
            const file: any = e.target.files[0];
            if (!file)
                return;
            const fileUrl: string = URL.createObjectURL(file);
            if (type === ID_FACES.RECTO)
                batch(() => {
                    setRectoImgUrl(fileUrl);
                    setRectoImage(file);
                });
            if (type === ID_FACES.VERSO)
                batch(() => {
                    setVersoImgUrl(fileUrl);
                    setVersoImage(file);
                });
        };

        const handleClickOnIdType = (idType: string): void => {
            setIdTypeChosen(idType);
        };

        const returnFromSetIdNumberUserInput = (): void => {
            batch(() => {
                setIdTypeChosen('');
                clearImages();
                setShowIdNumberUserInput(false);
                setShowChooseIdDocumentType(true);
            });
        };

        const clearImages = () => {
            batch(() => {
                onIdentityChangeCallback("", "", ID_FACES.RECTO);
                onIdentityChangeCallback("", "", ID_FACES.VERSO);
                setVersoImgUrl("");
                setRectoImgUrl("");
            });
        }

        return (
            <div className='valid-identity'>
                <div className='identity_content'>
                    <div className='valid-identity-body'>
                        <div className="model_container">
                            {accountConfirmationPendingOrVerified &&
                                <>
                                    <div className="model_title">
                                        <h4>{i18next.t('Aboutus.tab.identity.pendingvalidation')}</h4>
                                    </div>
                                    <p>
                                        {i18next.t('Aboutus.tab.identity.thankyou')}
                                    </p>
                                </>
                            }
                            {accountConfirmationRefusedOrNotConfirmed && !showChooseIdDocumentType && !showIdNumberUserInput && <p>
                                {i18next.t('Aboutus.tab.identity.refused')}
                            </p>
                            }
                            <div className="model_btns">
                                {accountConfirmationPendingOrVerified &&
                                    <div className="success-checkmark">
                                        <div className="check-icon">
                                            <span className="icon-line line-tip"></span>
                                            <span className="icon-line line-long"></span>
                                            <div className="icon-circle"></div>
                                            <div className="icon-fix"></div>
                                        </div>
                                    </div>
                                }
                                {accountConfirmationRefusedOrNotConfirmed &&
                                    <div style={{ width: '100%' }}>
                                        {showChooseIdDocumentType &&
                                            <div className="ChooseIdTypeContainer">
                                                <p className="choose-identity-title">{i18next.t('Aboutus.tab.identity.pleaseupload')}</p>
                                                <p className='choose-identity'>{i18next.t('Identity.chooseType')}</p>
                                                <div className="option_btn" onClick={() => handleClickOnIdType(ID_TYPES.ID_CARD)}>
                                                    <p>{i18next.t('Identity.type.cardId')}</p>
                                                </div>
                                                <div className="option_btn" onClick={() => handleClickOnIdType(ID_TYPES.PASSPORT)}>
                                                    <p>{i18next.t('Identity.type.passport')}</p>
                                                </div>
                                            </div>
                                        }
                                        {!showChooseIdDocumentType &&
                                            <React.Fragment>
                                                <IdentityForm
                                                    countryOfUser={countryOfUser}
                                                    requiresBothSides={true}
                                                    idType={idTypeChosen}
                                                    handleSubmit={handleSubmit}
                                                    onIdentityChange={onIdentityChange}
                                                    versoImgUrl={versoImgUrl}
                                                    rectoImgUrl={rectoImgUrl}
                                                    goBack={returnFromSetIdNumberUserInput}
                                                />
                                            </React.Fragment>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } catch (error) {
        console.log(error);
        return (<div >{errorTrad} - {i18next.t('resale.errorDetail')} {error ?? 'Unknown error'}</div>);
    }
};

export default ValidIdentity;
