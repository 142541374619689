import Heart from "components/icons/love/Heart";
import i18next from "i18next";
import React from "react";
import "./LoveBanner.scss";

export const LoveBanner = () => {
    return (
        <div className="lover-banner">
            <div className="banner-icon">
                <Heart />
            </div>
            <div className="banner-info">
                <div className="banner-title">
                    <div className="title">{i18next.t('Assetprofilepage.loveBanner.title')}</div>
                </div>
                <div className="banner-detail">
                    {i18next.t('Assetprofilepage.loveBanner.detail')}
                </div>
            </div>
        </div>
    );
};

export default LoveBanner;
