import React from 'react'
import './StatPanel.scss'
import { IListCardInformation } from '../list-card/ListCardProps';
import StatPanelProps from './StatPanelProps';
import Divider from 'components/small/divider/Divider';
import i18next from 'i18next';

const StatPanel: React.FC<StatPanelProps> = (props) => {

    return <div className='state-panel-container'>
        {props.statList.map((stat: IListCardInformation) =>
            stat.show && (<div key={stat.id} className="stat-panel">
                <div className="stat-panel__value">
                    {stat.value}
                </div>
                <div className="stat-panel__title">
                    {i18next.t(`${stat.label}`)}
                </div>
                <Divider size='27px' background="#15182B26" />
            </div>)
        )}
    </div>;
};

export default React.memo(StatPanel);