import * as React from 'react';
import { ISvgIconProps } from 'interfaces/SvgIcon.interface';

const Rookies: React.FC<ISvgIconProps> = ({ ...props }) => {
    const defaultColor: string = "url(#linear-gradient-rookies)"
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="245" height="245" {...props} viewBox="0 0 245 245">
            <defs>
                <linearGradient id="linear-gradient-rookies" x1="0.811" x2="0.167" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#7180e3" />
                    <stop offset="1" stop-color="#b1b8e8" />
                </linearGradient>
            </defs>
            <g id="Rookies" transform="translate(-6425.5 -2597.5)">
                <path id="Ellipse_281" data-name="Ellipse 281" d="M118,9A109,109,0,1,0,227,118,109.123,109.123,0,0,0,118,9m0-9A118,118,0,1,1,0,118,118,118,0,0,1,118,0Z" transform="translate(6430 2602)" fill={props.color || defaultColor} />
                <path id="Ellipse_281_-_Contour" data-name="Ellipse 281 - Contour" d="M118-4.5A122.5,122.5,0,1,1-4.5,118,122.639,122.639,0,0,1,118-4.5Zm0,227A104.5,104.5,0,1,0,13.5,118,104.618,104.618,0,0,0,118,222.5Zm0,9A113.629,113.629,0,0,1,4.5,118a113.5,113.5,0,0,0,227,0A113.629,113.629,0,0,1,118,231.5Z" transform="translate(6430 2602)" fill={props.color || defaultColor} />
                <path id="Tracé_3792" data-name="Tracé 3792" d="M5161.086,4889.438l-33.068,34.52h15.153l-13.851,71.756-30.392-41.546h-23.862l-14.272,82.916,19.159,19.323,13.746-73.138,30.532,42.191h23.516l18.7-101.5h12.818l-18.176-34.52m1.9-13.553,29.525,56.073h-19.411l-18.7,101.5h-34.263l-22.02-30.429L5085,5072.859l-32.79-33.071,16.115-93.621h34.663l21.955,30.012,8.536-44.222h-24.2Z" transform="translate(1427.971 -2255.922)" fill={props.color || defaultColor} />
                <path id="Tracé_3792_-_Contour" data-name="Tracé 3792 - Contour" d="M5163.944,4869.107l35.2,66.85h-22.707l-18.7,101.5H5118.1l-17.764-24.548-12.813,68.175-39.605-39.945,17.036-98.974h40.063l17.736,24.246,5.879-30.456h-28.729Zm-1.905,13.554h0l-14.643,15.286,14.643-15.286Zm10.6,37.3-12.5-23.743-22.744,23.743h10.628l-16.509,85.522-34.61-47.312h-18.462l-13.351,77.563,12.343,12.449,14.057-74.792,34.788,48.072h18.143l18.7-101.5Zm-34.31,8h0Zm47.564,0h-16.115l-18.7,101.5h-28.89l-26.276-36.31h0l26.276,36.31h28.89l18.7-101.5Zm-114.192,22.21h0l-15.193,88.269,25.975,26.2,8.091-43.053-8.091,43.052-25.974-26.2Zm29.262,0h0l26.173,35.779h0Z" transform="translate(1427.971 -2255.922)" fill={props.color || defaultColor} />
            </g>
        </svg>
    );
}

export default Rookies;
