declare global {
    interface Storage {
        trendexSetItem(itemName: string, value?: any): void;
    }
}

Storage.prototype.trendexSetItem = function(itemName: string, value?: any): void {
    if (!value)
        return this.setItem(itemName, itemName);
    if (typeof value === 'string')
        return this.setItem(itemName, value);
    return this.setItem(itemName, JSON.stringify(value));
}


export {};