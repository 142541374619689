import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import ICommonBottomSheetProps from './ICommonBottomSheetProps';
import "./common-bottomsheet.scss"
import 'react-spring-bottom-sheet/dist/style.css';

const CommonBottomSheet: React.FC<ICommonBottomSheetProps> = (props) => {

    return (
        <BottomSheet open={props.open} onDismiss={props.onDismiss} className="common-bottomsheet" blocking={false}>
            {props.children}
        </BottomSheet>)

}

export default CommonBottomSheet;
