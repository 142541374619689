import i18next from 'i18next';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { managementModal } from 'service/modal/action';
import { actionShare } from 'service/share/actions';
import Utils from 'utils/Utils';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import MonitoringPrivateSell from './monitoring-private-sell/MonitoringPrivateSell';
import './ProgressPrivateSell.scss';
import ProgressPrivateSellProps from './ProgressPrivateSellProps';

const ProgressPrivateSell: React.FC<ProgressPrivateSellProps> = (props) => {
    const dispatch = useDispatch();

    const onClickShareButton = async () => {

        dispatch(actionShare(
            i18next.t('ProgressPrivateSell.ShareLinkTitle'),
            i18next.t('ProgressPrivateSell.contentMessage'),
            Utils.referralLinkToAsset(props.assets.data[0].slug, props.auth.data.account.referral.ownReferralId),
            i18next.t('ProgressPrivateSell.ShareLinkTitle')),
        );
    };

    return (
        <div className="progress-private-sell">
            <div className="header-private-sell">
                <h2 className="title">{props.privateSellFinish ? '' : i18next.t('ProgressPrivateSell.title')}</h2>
                <p className="remaining-tokens">{i18next.t('ProgressPrivateSellProps.rest', { totalSupply: props.totalSupply })}</p>
                <p>{i18next.t('ProgressPrivateSellProps.saleEnd')}</p>
                {/*<p className="remaining-tokens">{i18next.t('ProgressPrivateSell.content')} {props.totalSupply} {i18next.t('ProgressPrivateSell.contentTwo')}</p>*/}
                {/*<p>{i18next.t('ProgressPrivateSell.contentThree')}</p>*/}
            </div>

            <div className="monitoring-container">
                <MonitoringPrivateSell
                    {...props}
                />
            </div>
            <div onClick={() => onClickShareButton()}>
                <PrimaryButton
                    receiveClickEvent={false}
                    disabled={false}
                    textButton={i18next.t('ProgressPrivateSell.ButtonLabel')}
                    type="button" />
            </div>
        </div>
    );

};

const mapStateToProps = (state: ProgressPrivateSellProps) => ({
    auth: state.auth,
    assets: state.assets,
});

export default compose(connect(mapStateToProps, { managementModal }))(ProgressPrivateSell);
