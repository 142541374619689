import React from 'react';

const Shares = ({ color, width, height, className }: any | undefined) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" className={className ?? "share-icon"} width={width ?? "25"} height={height ?? "26"} viewBox="0 0 25 26">
        <g id="shares" transform="translate(-39.724 -246)">
            <ellipse id="Ellipse_11" data-name="Ellipse 11" cx="12.5" cy="13" rx="12.5" ry="13" transform="translate(39.724 246)" fill={color ?? '#fff'} opacity="0" />
            <rect id="Rectangle_1926" data-name="Rectangle 1926" width="15" height="18" rx="3" transform="translate(42.724 249)" fill={color ?? '#fff'} />
            <path id="Soustraction_18" data-name="Soustraction 18" d="M11.815,19.58a3.016,3.016,0,0,1-.677-.077l-3.4-.785H8.754a4.694,4.694,0,0,0,4.689-4.689V1.837c0-.03,0-.059,0-.089l1.7.393a3,3,0,0,1,2.249,3.6L14.736,17.254A2.987,2.987,0,0,1,11.815,19.58Zm-3.06-2.738H1.1a2.992,2.992,0,0,1-.983-2.964L2.773,2.363A2.986,2.986,0,0,1,5.694.038a3.018,3.018,0,0,1,.678.078L11.517,1.3a2.832,2.832,0,0,1,.051.533V14.029A2.817,2.817,0,0,1,8.754,16.843Z" transform="translate(45.974 249.79)" fill={color ?? '#fff'} />
        </g>
    </svg>)
}

export default Shares;
