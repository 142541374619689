import React from "react";
import "aiprise-web-sdk";
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import IUseAIPriseKYC from "hooks/ai-prise-kyc/IUseAIPriseKYC";
import useAiPriseKYC from "hooks/ai-prise-kyc/useAiPriseKYC";
import './AiPriseKYC.scss';
import IAiPriseKYCProps from "./IAiPriseKYCProps";

const AiPriseKYC: React.FC<IAiPriseKYCProps> = ({ callbackFunction }) => {
    const { accountViewModel }: IUseAccount = useAccount();
    const { verifyFrameRef, mode }: IUseAIPriseKYC = useAiPriseKYC({ callbackFunction });

    return (
        <div className='container-iframe-ia-prise'>
            <aiprise-frame
                ref={verifyFrameRef}
                mode={mode}
                template-id="a197b4b2-cd09-40db-9b98-1d4ccc517534"
                client-reference-id={accountViewModel.Id}
                user-data={JSON.stringify({
                    email_address: accountViewModel.Email
                })}
            />
        </div>
    );
};

export default AiPriseKYC;
