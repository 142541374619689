import Application from 'Application';
import { TransactionSource } from 'config/_enums';
import IGiftAtFirstPayment from 'model/Gift/IGiftAtFirstPayment';
import Achievement from '../Achievement/Achievement';
import Translation from '../Achievement/Translation';
import TransactionViewModel from '../TransactionViewModel/TransactionViewModel';

export default class FirstDepositAchievementViewModel {
    private static readonly giftAtFirstDeposit: IGiftAtFirstPayment = Application.getInstance().GiftRewardsAtFirstDeposit;
    private static readonly tokensAchievement: Achievement = new Achievement({ title: new Translation('Achievements.paymentByCreditCard', {}), subtitle: new Translation('Achievements.winTenCredits', {}), redirectLink: '/top-50' });
    private static readonly creditsAchievement: Achievement = new Achievement({ title: new Translation('Achievements.paymentByCreditCard', {}), subtitle: new Translation('gift-amount-on-first-payment', { amount: `${FirstDepositAchievementViewModel.giftAtFirstDeposit.giveCredits.amount} ${Application.getInstance().currencySymbol}` }), redirectLink: '/buy-credits' });
    private currentAchievement: Achievement;
    private achievementAccomplished: Achievement | null;
    private firstDepositCompleted: boolean;

    constructor(financialTransactions: TransactionViewModel[]) {
        this.currentAchievement = this.getCurrentAchievement();
        this.firstDepositCompleted = financialTransactions.length > 0 ? financialTransactions.some((transac: TransactionViewModel) => transac.TransactionSource === TransactionSource.STRIPE) : false;
        this.achievementAccomplished = this.firstDepositCompleted ? this.getAchievementAccomplished(financialTransactions) : null;
    }

    private get CurrentAchievement() {
        return this.currentAchievement;
    }

    public get Achievement(): Achievement {
        if (this.FirstDepositCompleted && this.AchievementAccomplished)
            return this.AchievementAccomplished;
        return this.CurrentAchievement;
    }

    public get FirstDepositCompleted(): boolean {
        return this.firstDepositCompleted;
    }

    private get AchievementAccomplished(): Achievement | null {
        return this.achievementAccomplished;
    }

    private getAchievementAccomplished(userAllTransactionsList: TransactionViewModel[]): Achievement {
        const hasReceiveCreditsGift: boolean = userAllTransactionsList.some((elem) => elem.TransactionSource === TransactionSource.GIFT_FIRST_STRIPE_PAYMENT);
        if (hasReceiveCreditsGift)
            return FirstDepositAchievementViewModel.creditsAchievement;
        return FirstDepositAchievementViewModel.tokensAchievement;
    }

    private getCurrentAchievement(): Achievement {
        if (FirstDepositAchievementViewModel.giftAtFirstDeposit.giveTokens.enabled)
            return FirstDepositAchievementViewModel.tokensAchievement;
        return FirstDepositAchievementViewModel.creditsAchievement;
    }

    public get GiftAtFirstDepositIsEnabled(): boolean {
        return FirstDepositAchievementViewModel.giftAtFirstDeposit.enabled;
    }
}