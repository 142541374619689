import React from "react";
import AssetScoreEvolutionViewModel from "view-model/Assets/AssetScoreEvolutionViewModel";
import IWithAssetScoreEvolution from "./IWithAssetScoreEvolution";
import IAssetTrendScoreEvolutionProps from "page/AssetProfilPage/local-components/AssetEvolutions/AssetTrendScoreEvolution/IAssetTrendScoreEvolutionProps";

const withAssetScoreEvolution = (WrappedComponent: React.ComponentType<IAssetTrendScoreEvolutionProps>) => {
    const MemoComponent = React.memo(WrappedComponent);
    const Wrapper: React.FC<IWithAssetScoreEvolution> = ({ asset }) => {
        const assetScoreEvolutionVM = React.useMemo(() => new AssetScoreEvolutionViewModel(asset.getTrendScoreToDisplay(2)), [asset.getTrendScoreToDisplay(2)]);
        return (
            <MemoComponent assetScoreEvolutionVM={assetScoreEvolutionVM} asset={asset} />
        );
    };
    return React.memo(Wrapper);
};

export default withAssetScoreEvolution;