import AssetTokenCard from 'components/small/asset-token/asset-token-card/AssetTokenCard'
import React from 'react'
import AvatarViewModel from 'view-model/AvatarViewModel/AvatarViewModel'
import SimpleAssetCardProps from './SimpleAssetCardProps';
import './style.scss';

const SimpleAssetCard: React.FC<SimpleAssetCardProps> = ({ asset, categories }) => {
    const assetBackgroundViewModel: AvatarViewModel = React.useMemo(() => new AvatarViewModel({ assetCategories: categories, asset: { avatarPath: asset.AvatarPath, mainCategoryId: asset.MainCategoryId, cardLevel: asset.CardLevel } }), [asset, categories]);

    return (
        <React.Fragment >
            <div key={asset.AssetId} className="simple-asset-card cursor-pointer">
                <div className='grid-wrapper' style={{ background: assetBackgroundViewModel.Color }}>
                    <img src={assetBackgroundViewModel.BackgroundImage} className="grid-asset-bg-img" alt={asset.AvatarPath + "-bg"} loading="lazy" />
                    <img src={asset.AvatarPath} className="grid-asset-avatar-img" alt={asset.AvatarPath} loading="lazy" />
                    <div className='overlay-filter'>
                        <div className='token-content'>
                            <AssetTokenCard availableToken={asset.AvailableSupply} />
                        </div>
                        <div className='grid-name'>
                            <span>{asset.AssetName}</span>
                            <p className='greenTxt'>{asset.CurrentValueAsNumber.toCurrency()}</p>
                        </div>

                    </div>

                </div>
            </div>
        </React.Fragment >
    )
};

export default React.memo(SimpleAssetCard);
