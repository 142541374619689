import i18n from '18n';
import React from 'react';
import './style.scss';

const NoTournamentResults: React.FC = () => {
    return (
        <div className="no-results">
            <div className="img-container">
                <img src="/img/rewards/closedreward-min.png" alt="Rewards" />
            </div>
            <h4 className="black-color">{i18n.t('first-tournament')}</h4>
            <h4 className="black-color">{i18n.t('join-first-tournament')}</h4>
        </div>
    );
};

export default NoTournamentResults;
