import i18n from "18n";
import { IListCardInformation } from "components/small/list-card/ListCardProps";
import ScoreLabel from "components/small/score-label/ScoreLabel";
import React from "react";
import Color from "view-model/Shared/Color";
import IInjectedWithTeamAssetScoreDetails from "./IInjectedWithTeamAssetScoreDetails";
import IWithTeamAssetScoreDetailsProps from "./IWithTeamAssetScoreDetailsProps";

const withTeamAssetScoreDetails = <BaseProps,>(ChildComponent: React.ComponentType<BaseProps & IInjectedWithTeamAssetScoreDetails>): React.FC<IWithTeamAssetScoreDetailsProps<BaseProps>> => {
    const WrappedComponent: React.FC<IWithTeamAssetScoreDetailsProps<BaseProps>> = (props: IWithTeamAssetScoreDetailsProps<BaseProps>) => {
        const { teamIRLScore, teamSportScore, tournamentVM, userLeagueAttribution, isTeamIRLScoreBoosted, isTeamScoreBoosted }: IWithTeamAssetScoreDetailsProps<BaseProps> = props;
        const teamStatistics: IListCardInformation[] = React.useMemo(() => {
            return [
                {
                    id: "R1",
                    show: true,
                    hidden: tournamentVM.IsTournamentDefault,
                    label: i18n.t("team.performance.score.sport", { sport: tournamentVM.CategoryInLocaleLowerCase }),
                    value: (
                        <ScoreLabel
                            score={teamSportScore}
                            backgroundColor={Color.getColorByInputRatio(teamSportScore)}
                            category={tournamentVM.CategoryInLocaleLowerCase}
                            canShowBoost={isTeamScoreBoosted}
                        />
                    ),
                },
                {
                    id: "R2",
                    show: true,
                    hidden: !tournamentVM.IsTournamentDefault,
                    label: i18n.t("team.performance.score", { sport: "Trend's" }),
                    value: (
                        <ScoreLabel
                            score={teamIRLScore}
                            backgroundColor={Color.getColorByInputRatio(teamIRLScore)}
                            canShowBoost={isTeamIRLScoreBoosted}
                        />
                    ),
                },
                {
                    id: "R3",
                    show: true,
                    label: i18n.t("team.total-tokens"),
                    value: <span className="user-tokens">{userLeagueAttribution.UserTotalTokens}</span>,
                },
            ]
        }, [tournamentVM.IsTournamentDefault, tournamentVM.CategoryInLocaleLowerCase, teamSportScore, isTeamScoreBoosted, teamIRLScore, isTeamIRLScoreBoosted, userLeagueAttribution.UserTotalTokens]);
        const filteredStatistic: IListCardInformation[] = React.useMemo(() => {
            return [!tournamentVM.IsTournamentDefault ? teamStatistics[0] : teamStatistics[1]]
        }, [teamStatistics, tournamentVM.IsTournamentDefault]);

        return <ChildComponent {...props as BaseProps} teamStatistics={teamStatistics} filteredStatistic={filteredStatistic} />;
    }

    return React.memo(WrappedComponent);
}

export default withTeamAssetScoreDetails