import axios, { CancelToken, CancelTokenSource } from 'axios';
import { HTTP_STATUS_OK } from 'config/_const';
import AxiosConfig, { getSource } from 'config/axiosConfig';
import { ASSET_ENDPOINTS, ORDER_ENDPOINTS } from "config/endpoints";
import { marketServiceUrl } from "config/marketServiceConfig";
import IAssetPredictPrice from "model/AssetModel/IAssetPredictPrice";
import PriceHistoryFrequency from 'model/AssetModel/PriceHistoryFrequency';
import { IHttpStrongTypedResponse } from 'model/IHttpResponse';
import { RootState } from "model/Redux";
import { batch } from "react-redux";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { UPDATE_OWNED_ASSET } from "service/wallet/actionsType";
import PriceHistoryInterface from '../../model/AssetModel/PriceHistoryInterface';
import { UPDATE_ASSET_CURRENT_INFORMATION, UPDATE_ASSET_CURRENT_INFORMATION_LOADING, UPDATE_ASSET_CURRENT_INFORMATION_PRICE_HISTORY, UPDATE_ASSET_CURRENT_INFORMATION_PRICE_HISTORY_ERROR } from "./actionType";
import { IGetAssetInfoFromMarketServiceResponse } from './types';

let source: CancelTokenSource = getSource();

export const cancelSecondaryMarketPredictPrice = (reason?: string): ThunkAction<void, null, unknown, Action<string>> => async () => {
    source.cancel(reason);
};

export const updateAssetCurrentInformation = (asset: IAssetPredictPrice): ThunkAction<void, null, unknown, Action<string>> => (dispatch) => {
    batch(() => {
        dispatch({ type: UPDATE_ASSET_CURRENT_INFORMATION, payload: asset });
        dispatch({ type: UPDATE_OWNED_ASSET, payload: asset })
    });
};

export const getAssetInfoFromMarketService = (assetId: string, callbackOnSuccess?: () => void, callbackOnCancel?: () => void): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        const cancelToken: CancelToken = source.token;
        dispatch({
            type: UPDATE_ASSET_CURRENT_INFORMATION_LOADING
        });
        const assetInfoResponse: IHttpStrongTypedResponse<IGetAssetInfoFromMarketServiceResponse> = await AxiosConfig.get(`${marketServiceUrl}/${ORDER_ENDPOINTS.MARKET_SERVICE_GET_ASSET_INFO}/${assetId}`, { cancelToken });
        if (assetInfoResponse?.data?.status === HTTP_STATUS_OK) {
            const { availableSupply, currentValue, liquidityPoolApplicable, initialSalePeriodIsDone, liquidityPoolSupply, liquidityPoolValue, effectivePrice }: IGetAssetInfoFromMarketServiceResponse = assetInfoResponse.data.data;
            if (!currentValue)
                return;
            if (callbackOnSuccess)
                callbackOnSuccess();
            return dispatch({
                type: UPDATE_ASSET_CURRENT_INFORMATION,
                payload: { secondaryMarketSupply: availableSupply, currentValue: currentValue, assetId, liquidityPoolApplicable, initialSalePeriodIsDone, liquidityPoolSupply, liquidityPoolValue, effectivePrice }
            });
        }
    }
    catch (error) {
        if (axios.isCancel(error)) {
            source = getSource();
            if (callbackOnCancel)
                callbackOnCancel();
        }

        console.log('getAssetInfoFromMarketService: ', error);
        return dispatch({ type: UPDATE_ASSET_CURRENT_INFORMATION_PRICE_HISTORY_ERROR });
    }

}


export const fetchPriceHistoryByAssetSlug = (slug: string, assetId: string, frequency?: PriceHistoryFrequency): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_ASSET_CURRENT_INFORMATION_LOADING
        });
        const response: IHttpStrongTypedResponse<PriceHistoryInterface[]> = await AxiosConfig.get(`${ASSET_ENDPOINTS.GET_PRICE_HISTORY_BY_ASSET_SLUG}/${slug}/${frequency ?? ''}`);
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({ type: UPDATE_ASSET_CURRENT_INFORMATION_PRICE_HISTORY_ERROR });
        return dispatch({
            type: UPDATE_ASSET_CURRENT_INFORMATION_PRICE_HISTORY,
            payload: {
                assetId,
                priceHistory: response.data.data,
            },
        });
    } catch (error) {
        return dispatch({ type: UPDATE_ASSET_CURRENT_INFORMATION_PRICE_HISTORY_ERROR });
    }
};
