import Application from 'Application';
import ScoreBoosterDetailBlock from 'components/small/score-booster/score-booster-detail-block/ScoreBoosterDetailBlock';
import { DEFAULT_ID_AS_STRING } from 'config/_const';
import IUseTournament from 'hooks/tournament/use-tournament/IUseTournament';
import useTournament from 'hooks/tournament/use-tournament/useTournament';
import useUserTournamentSubscription from 'hooks/tournament/use-user-tournament-subscription/useUserTournamentSubscription';
import IUseTeamsOverview from 'hooks/use-teams-overview/IUseTeamsOverview';
import useTeamsOverview from 'hooks/use-teams-overview/useTeamsOverview';
import React from 'react';
import { useDispatch } from 'react-redux';
import TeamList from '../team-list/TeamList';
import ITeamsOverviewProps, { ITeamOverviewPropsWrapper } from './ITeamsOverviewProps';
import './style.scss';
import TeamsOverviewLoading from './teams-overview-loading/TeamsOverviewLoading';

const TeamsOverview: React.FC<ITeamsOverviewProps> = (props: ITeamsOverviewProps) => {
    return (
        <div className="teams-overview">
            <div className="container-team-list">
                <TeamList
                    onboardingMode={props.onboardingMode}
                    getAssetFromTeamAsset={(assetId: string) => props.walletViewModel.getWalletAssetById(assetId)?.asset}
                    teamBuildingViewModel={props.teamBuildingViewModel}
                    deleteAssetFromLocalTeam={props.deleteAssetFromEditingTeam}
                    resetEditingTeam={props.resetEditingTeam}
                    teamScoreOnTop={false}
                    showGetButton={false}
                    showRemoveIcon={true}
                    isCollapsed={props.isCollapsed} />
            </div>
            {!props.onboardingMode && props.renderButtonsAndWidget()}
            {props.showTournamentBoost && <ScoreBoosterDetailBlock canShowTitle={true} />}
        </div>
    );
};

const withTeamAndTournamentInProgress = (Component: React.FC<ITeamsOverviewProps>) => {
    const MemoComponent = React.memo(Component);

    const Wrapper: React.FC<ITeamOverviewPropsWrapper> = (props: ITeamOverviewPropsWrapper) => {
        const {
            renderButtonsAndWidget,
        }: IUseTeamsOverview = useTeamsOverview({
            resetTeam: props.resetTeam,
            team: props.teamBuildingViewModel,
            launchGeneration: props.launchGeneration,
            displayConfirmTeamDialog: props.displayConfirmTeamDialog,
            walletViewModel: props.walletViewModel,
            isTeamIncompatibleWithTournament: props.isTeamIncompatibleWithTournament
        });

        const dispatch = useDispatch();
        const { tournamentInProgress, nextTournament }: IUseTournament = useTournament();
        const { userAlreadyRegisteredForTournament, fetchUserTournamentSubscription, currentUserSubscription } = useUserTournamentSubscription({ tournamentId: tournamentInProgress?.Id || DEFAULT_ID_AS_STRING });

        React.useEffect(() => {
            if (!tournamentInProgress || !userAlreadyRegisteredForTournament || currentUserSubscription)
                return;
            dispatch(fetchUserTournamentSubscription());
        }, [currentUserSubscription, dispatch, fetchUserTournamentSubscription, userAlreadyRegisteredForTournament, tournamentInProgress]);

        const shouldShowTournamentBoost: boolean = React.useMemo(() => {
            return Boolean(Application.getInstance().IsTeamScoreBoostConfigured && userAlreadyRegisteredForTournament && !Boolean(tournamentInProgress?.IsPersonalTournament || nextTournament?.IsPersonalTournament));
        }, [userAlreadyRegisteredForTournament, nextTournament, tournamentInProgress]);

        if (props.teamBuildingViewModel.Loading)
            return <TeamsOverviewLoading />;

        return <MemoComponent
            {...props}
            showTournamentBoost={shouldShowTournamentBoost}
            renderButtonsAndWidget={renderButtonsAndWidget} />;
    };

    return React.memo(Wrapper);
};

export default withTeamAndTournamentInProgress(TeamsOverview);
