import { DEFAULT_ID_AS_STRING, DEFAULT_SCORE_BOOST, TEAM_INTERNAL_STATUS, TEAM_STATUS, TOURNAMENT_CATEGORY } from 'config/_const';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import ITeam, { IInternalTeam } from 'model/Team/ITeam';
import ITeamAsset from 'model/Team/ITeamAsset';
import ITeamConfig from 'model/Team/ITeamConfig';
import { TeamUserInterface } from 'model/User/UserInterface';
import { calculateTeamScoreAverage } from 'service/teams/helper';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import TournamentSubscriptionViewModel from 'view-model/Tournament/TournamentSubscriptionsViewModel/TournamentSubscriptionViewModel';
import WalletViewModel from 'view-model/WalletViewModel/WalletViewModel';
import TeamAsset from '../../../model/Team/TeamAsset';
import AccountViewModel from '../../AccountViewModel/AccountViewModel';
import TeamConfigurationViewModel from '../TeamConfigViewModel/TeamConfigViewModel';
import ITeamViewModel from './ITeamViewModel';
import INumberOfSharesInTeam from './ISharesLocalTeam';

export default class TeamViewModel implements ITeamViewModel {
    private _loading: boolean;
    private _id: string;
    private _label: string;
    private _teamAssets: ITeamAsset[];
    private _teamConfigurationViewModel: TeamConfigurationViewModel;
    private _status: string;
    private _enabled: boolean;
    private _autoGenerated: boolean;
    private _lastTournamentPosition: number;
    private _owner: TeamUserInterface;
    private _createdAt: string;
    private _updatedAt: string;
    private internalStatus: string;
    private currentTeamConfigVM: TeamConfigurationViewModel;

    constructor(currentTeamConfigVM: TeamConfigurationViewModel, team?: IInternalTeam, loading?: boolean,);
    constructor(currentTeamConfigVM: TeamConfigurationViewModel, team: IInternalTeam, loading?: boolean) {
        this._loading = loading ?? false;
        this.currentTeamConfigVM = currentTeamConfigVM;
        this._id = team?._id ?? DEFAULT_ID_AS_STRING;
        this._label = team?.label ?? '';
        this._teamAssets = team?.teamAssets ?? [];
        this._teamConfigurationViewModel = new TeamConfigurationViewModel(false, team?.configuration);
        this._status = team?.status ?? TEAM_STATUS.DISENGAGED;
        this._enabled = team?.enabled ?? false;
        this._autoGenerated = team?.autoGenerated ?? false;
        this._lastTournamentPosition = team?.lastTournamentPosition ?? -1;
        this._owner = team?.owner ?? {
            _id: '-1',
        };
        this._createdAt = team?.createdAt ?? new Date().toISOString();
        this._updatedAt = team?.updatedAt ?? new Date().toISOString();
        this.internalStatus = team?.internalStatus ?? TEAM_INTERNAL_STATUS.EDIT_DONE;
    }

    public getTeamAssetByAssetId(assetId: string): ITeamAsset | undefined {
        return this.teamAssets.find((teamAsset: ITeamAsset) => teamAsset.asset === assetId);
    }

    public isEngagedInTournament(): boolean {
        return this.status === TEAM_STATUS.ENGAGED;
    }

    public getNumberOfSharesToPutInTeamAsset(assetsWallet: AssetWalletInterface[], assetId: string): number {
        const ownedSharesAmount: number = assetsWallet.find((assetWallet: AssetWalletInterface) => assetWallet.asset._id === assetId)?.amount ?? 0;
        return ownedSharesAmount;
    }

    public getAssetFromTeamAsset(walletViewModel: WalletViewModel, allAssetsExceptOwned: AssetViewModel[], assetId: string): AssetViewModel {
        const allAssets: AssetViewModel[] = walletViewModel.getAssetsFromWallet().concat(allAssetsExceptOwned);
        const assetIndex: number = allAssets.findIndex((asset: AssetViewModel) => asset.AssetId === assetId);
        return allAssets[assetIndex];
    }

    public asITeam(): ITeam {
        return {
            _id: this._id,
            label: this._label,
            teamAssets: this._teamAssets,
            autoGenerated: this._autoGenerated,
            lastTournamentPosition: this._lastTournamentPosition,
            owner: this.Owner,
            status: this._status,
            configuration: this._teamConfigurationViewModel.Configuration,
            enabled: this._enabled,
            createdAt: this._createdAt,
            updatedAt: this._updatedAt,
        };
    }

    public asInternalTeam(): IInternalTeam {
        return {
            _id: this._id,
            label: this._label,
            teamAssets: this._teamAssets,
            autoGenerated: this._autoGenerated,
            lastTournamentPosition: this._lastTournamentPosition,
            owner: this.Owner,
            status: this._status,
            configuration: this._teamConfigurationViewModel.Configuration,
            enabled: this._enabled,
            createdAt: this._createdAt,
            updatedAt: this._updatedAt,
            internalStatus: this.InternalStatus,
        };
    }

    public getUnavailableTeamAssetIds(walletViewModel: WalletViewModel, accountViewModel: AccountViewModel): string[] {
        return this.teamAssets.filter((teamAsset: ITeamAsset) => !walletViewModel.isOwnedAssetAvailableForTeam(accountViewModel, teamAsset.asset)).map((teamAsset: ITeamAsset) => teamAsset.asset);
    }

    public isComplete(getAssetViewModelById: (assetId: string) => AssetViewModel | undefined): boolean {
        return this.teamAssets.length === this.configuration.maxNumberOfAssets &&
            this.teamAssets.every((teamAsset: ITeamAsset) => {
                const assetViewModel = getAssetViewModelById(teamAsset.asset);
                if (!assetViewModel) return false;

                const cardEquivalent = assetViewModel.getTeamCardEquivalence(teamAsset.numberOfShares, this.CurrentTeamConfigVM.MinSharesByAssset);
                return cardEquivalent.isCountAcceptable;
            });
    };

    public isReadyToConfirm(getAssetViewModelById: (assetId: string) => AssetViewModel | undefined): boolean {
        return this.InternalStatus === TEAM_INTERNAL_STATUS.EDIT_IN_PROGRESS && this.isComplete(getAssetViewModelById);
    }

    public computeTeamsScores(assets: AssetViewModel[], assetCategories: AssetCategoryInterface[], tournamentType: TOURNAMENT_CATEGORY, userTournamentSubscriptionVM: TournamentSubscriptionViewModel | undefined): number {
        return calculateTeamScoreAverage(this.teamAssets, assets, assetCategories, tournamentType, userTournamentSubscriptionVM?.ScoreBooster || DEFAULT_SCORE_BOOST);
    }

    public wasPaid(teamServer: ITeam): boolean {
        if (this.id === DEFAULT_ID_AS_STRING || this.teamAssets.length !== teamServer.teamAssets.length)
            return false;

        for (const teamAsset of teamServer.teamAssets) {
            const localTeamAsset: ITeamAsset | undefined = this.teamAssets.find((value: ITeamAsset) => value._id === teamAsset._id);
            if (!localTeamAsset || localTeamAsset.numberOfShares !== teamAsset.numberOfShares)
                return false;
        }
        return true;
    }

    public flushTeamAssets = (): TeamViewModel => {
        this.teamAssets = [];
        return this;
    }

    public addAsset = (assetId: string, numberOfTokens: number): ITeamAsset[] => {
        if (this.InternalStatus !== TEAM_INTERNAL_STATUS.EDIT_IN_PROGRESS)
            this.InternalStatus = TEAM_INTERNAL_STATUS.EDIT_IN_PROGRESS;
        const tempTeamAssets: ITeamAsset[] = [...this.teamAssets];
        const teamAssetFromTeamIndex: number = tempTeamAssets.findIndex((entry: ITeamAsset) => entry.asset === assetId);
        if (this.teamAssets.length < this.configuration.maxNumberOfAssets && teamAssetFromTeamIndex === -1) {
            this.teamAssets = ([...this.teamAssets, new TeamAsset(numberOfTokens, assetId)]);
        } else {
            if (teamAssetFromTeamIndex === -1)
                return this.teamAssets;
            tempTeamAssets[teamAssetFromTeamIndex] = {
                ...tempTeamAssets[teamAssetFromTeamIndex],
                numberOfShares: numberOfTokens,
            };
            this.teamAssets = tempTeamAssets;
        }

        return this.teamAssets;
    }

    public isBoostApplicablePerTouranamentCategory(tournamentType: TOURNAMENT_CATEGORY, userTournamentSubscribtionVM: TournamentSubscriptionViewModel | undefined): boolean {
        return (this.isEngagedInTournament() &&
            Boolean(userTournamentSubscribtionVM?.IsScoreBoosted) &&
            Boolean((userTournamentSubscribtionVM?.TournamentCategory) === tournamentType.toLowerCase())
        );
    }

    public get Loading(): boolean {
        return this._loading;
    }

    public get isEdditing(): boolean {
        return this.id !== DEFAULT_ID_AS_STRING && this.InternalStatus === TEAM_INTERNAL_STATUS.EDIT_IN_PROGRESS;
    }

    public get configuration(): ITeamConfig {
        return this._teamConfigurationViewModel.Configuration;
    }

    public get teamConfigurationViewModel(): TeamConfigurationViewModel {
        return this._teamConfigurationViewModel;
    }
    public set teamConfigurationViewModel(config: TeamConfigurationViewModel) {
        this._teamConfigurationViewModel = config;
    }

    public get teamAssets(): ITeamAsset[] {
        return this._teamAssets;
    }

    public set teamAssets(value: ITeamAsset[]) {
        this._teamAssets = value;
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: string) {
        this._label = value;
    }

    public get status(): string {
        return this._status;
    }

    public set status(value: string) {
        this._status = value;
    }

    public get id(): string {
        return this._id;
    }

    public set id(value: string) {
        this._id = value;
    }

    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(value: boolean) {
        this._enabled = value;
    }

    public get autoGenerated(): boolean {
        return this._autoGenerated;
    }

    public set autoGenerated(value: boolean) {
        this._autoGenerated = value;
    }
    public get InternalStatus(): string {
        return this.internalStatus;
    }
    public set InternalStatus(internalStatus: string) {
        this.internalStatus = internalStatus;
    }

    public get Owner(): TeamUserInterface {
        return this._owner;
    }

    public get OwnerId(): string {
        return this.Owner._id
    }
    public get MaxNumberOfAssetsInTeam(): number {
        return this.teamConfigurationViewModel.MaxNumOfAssetTeam;
    }

    public get CurrentTeamConfigVM(): TeamConfigurationViewModel {
        return this.currentTeamConfigVM;
    }

    public get IsReady(): boolean {
        return this.teamAssets.length === this.configuration.maxNumberOfAssets;
    }

    public getNumberOfRemainingSharesByAsset(assetId: string, initialShares: number): number {
        const teamAsset: ITeamAsset | undefined = this.teamAssets.find((value: ITeamAsset) => value.asset === assetId);
        return teamAsset ? initialShares - teamAsset.numberOfShares : initialShares;
    }

    public get SharesInLocalTeams(): INumberOfSharesInTeam[] {
        return this.teamAssets.map((teamAsset: ITeamAsset) => ({ assetId: teamAsset.asset, amountOfShares: teamAsset.numberOfShares }));
    }
}
