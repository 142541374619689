import Shares from 'components/icons/Shares'
import React from 'react'
import './AssetTokenCard.scss';
import Utils from 'utils/Utils';
import { ZERO } from 'config/_const';

interface IAssetTokenCardProps{
    availableToken:number
}

export const AssetTokenCard: React.FC<IAssetTokenCardProps> = (props) => {

    return (
        <div className="asset-token-card">
            <Shares />
            <span className='available-token'>
                {Utils.toKorM(props.availableToken, ZERO)}
            </span>
      </div>
  )
}

export default AssetTokenCard;