import { ENABLE_RECAPTCHA, G_TAGS_KNOWN_EVENTS, LOCAL_STORAGE_KNOWN_KEYS } from "config/_const";
import { ConnectionMode } from "config/_enums";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router';
import { isSignupModalSelector } from "service/modal/selectors";
import ConnectViewModel from "view-model/ConnectViewModel/ConnectViewModel";
import { IUseConnectReturn } from "./IUseConnect";
import { TagManagerWrapper } from "service/tag-manager/TagManagerWrapper";
import IUseConnectParams from "./IUseConnectParams";

const useConnect = (params: IUseConnectParams): IUseConnectReturn => {
    const [mode, setMode] = React.useState<ConnectionMode>(params.forceSignup ? ConnectionMode.register : localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.KNOWN_USER) ? ConnectionMode.login : ConnectionMode.register);
    const history = useHistory();
    const isModal: boolean = useSelector(isSignupModalSelector);
    const isNotModal: boolean = !isModal;
    const [isVideoPlay, setIsVideoPlay] = useState<boolean>(false);
    const connectViewModel: ConnectViewModel = new ConnectViewModel({ mode: mode, showReCaptcha: ENABLE_RECAPTCHA, startVideo: isVideoPlay });

    React.useEffect(() => {
        if (mode === ConnectionMode.register)
            TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.REGISTER_PAGE);
    }, [mode]);

    React.useEffect(() => {
        const searchParams: URLSearchParams = new URLSearchParams(history.location.search);
        const queryMode: string | null = searchParams.get("mode");
        if (queryMode === ConnectionMode.login || queryMode === ConnectionMode.register) {
            setMode(queryMode);
        }
    }, [history.location.search])


    return { connectViewModel, mode, setMode, isModal, isNotModal, setIsVideoPlay }
}

export default useConnect;