import DateFnsUtils from '@date-io/date-fns';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControlLabel } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PrimaryButton from 'components//small/buttons/primary-button/PrimaryButton';
import CircularLoader from 'components/small/CircularLoader';
import { TrendexCheckbox } from 'components/small/CustomCheckbox';
import SecondaryBtn from 'components/small/buttons/secondary-btn/SecondaryBtn';
import * as Input from 'components/small/input/ReduxFormInput';
import WrapperSelect from 'components/small/wrapper-select/WrapperSelect';
import {
    COUNTRY,
    DATEPICKER,
    EDIT_PROFILE_MANDATORY,
    EmptyString,
    LOCAL_STORAGE_KNOWN_KEYS,
    TRENDEX_LANGUAGES,
    VERIFICATION_STATUS,
    WRAPPER_SELECT_TYPE,
    WorldRegions
} from 'config/_const';
import { COUNTRIES_LIST } from 'data/countries';
import { SELECTABLE_CURRENCIES } from 'data/currencies';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import i18next from 'i18next';
import {
    checkValue,
    discordValidator,
    emailValidator,
} from 'middleware/reduxFormValidator';
import ReduxStoreInterface from 'model/ReduxStoreInterface';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { connect, useSelector } from 'react-redux';
import { Field, InjectedFormProps, isPristine, reduxForm } from 'redux-form';
import Utils from 'utils/Utils';
import { isFrenchPhoneNumber } from 'utils/validator';
import './UserInformationForm.scss';
import UserInformationFormProps, {
    ISelectOption,
} from './UserInformationFormProps';

const INITIAL_STATE_FORM = {
    firstName: '',
    lastName: '',
    email: '',
    pseudo: '',
    country: '',
    phone: '',
    dateOfBirth: new Date().toISOString(),
    language:
        localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.LANG) ??
        TRENDEX_LANGUAGES.ENGLISH,
};

const UserInformationForm: React.FC<InjectedFormProps<{}, UserInformationFormProps> & UserInformationFormProps> = (props) => {
    const [phone, setPhone] = useState(props.initialValues.phone);
    const [phoneIsValid, setPhoneIsValid] = useState(false);
    const user = useSelector((state: ReduxStoreInterface) => state.auth.data.account);
    const isAuthStateLoadingWhileFormSubmit = useSelector((state: ReduxStoreInterface) => state.auth.loading);
    const isConfirmed = user.isConfirmed === VERIFICATION_STATUS.CONFIRMED;
    const LOCALE: Locale = user.language === TRENDEX_LANGUAGES.FRENCH ? frLocale : enLocale;
    const datePicker = user.language === TRENDEX_LANGUAGES.FRENCH ? DATEPICKER.FR : DATEPICKER.EN;
    const [smsNotificationEnabled, setSmsNotificationEnabled] = useState<boolean>(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.SMS_NOTIFICATION_ENABLED) ?? 'false'));

    useEffect(() => {
        props.change('phone', phone);
    }, [phone, props]);

    const checkIsEmptyValue = (value: string) => {
        return value == EmptyString ? i18next.t('login.validatemsg') : undefined;
    }

    const checkValueWithoutValidatedConfirmedEmail = (value: string) => {
        if (props.isEmailValidated || isConfirmed) {
            return undefined;
        }
        return checkValue(value);
    };

    const checkPristine = (): boolean => {
        return props.pristine;
    };

    const validatePhone = (value: string, country: any) => {
        if (!value) return true;
        if (value === '33') {
            return true;
        }
        if (country.iso2 === TRENDEX_LANGUAGES.FRENCH) {
            return isFrenchPhoneNumber(value);
        }
        return true;
    };

    const validateFormPhone = (value: string, country: any) => {
        const valResult = validatePhone(value, country);
        setPhoneIsValid(valResult);
        return valResult;
    };

    const acceptSMSNotification = (value: any) => {
        props.acceptSMSNotification(value);
        setSmsNotificationEnabled(value);
    }

    const generateSelect = (fieldProps: any) => {
        let data: {
            label: string | number;
            value: string | number;
        }[] = [];

        if (fieldProps.input.name === 'country') {
            data = COUNTRIES_LIST.map((country: { code: string; name: string }) => {
                return {
                    label: country.name,
                    value: country.code,
                };
            });
        }

        return (
            <div>
                <Form.Control
                    disabled={fieldProps.disabled}
                    {...fieldProps.input}
                    as='select'
                    className='cust_dropdown'
                    value={fieldProps.input.value || props.initialValues.country}
                >
                    <option value='' disabled selected hidden>
                        {i18next.t('Aboutus.tab.about.selectcountry')}
                    </option>
                    {data.map((country: { label: React.ReactText; value: React.ReactText }, i: number) => {
                        return (
                            <option key={`opt_${i}`} value={country.value}>
                                {country.label}
                            </option>
                        );
                    })}
                </Form.Control>

                {fieldProps.meta.touched &&
                    ((fieldProps.meta.error && (
                        <span style={{ color: 'red' }}>
                            {i18next.t('Aboutus.error.country.isNotAValidCountry')}
                        </span>
                    )) ||
                        (fieldProps.meta.warning && (
                            <span style={{ color: 'orange' }}>{fieldProps.meta.warning}</span>
                        )))}
            </div>
        );
    };

    const generateCurrencySelect = (fieldProps: any) => {
        let options: {
            label: string;
            value: string;
        }[] = SELECTABLE_CURRENCIES;

        return (
            <div>
                <Form.Control
                    disabled={fieldProps.disabled}
                    {...fieldProps.input}
                    as='select'
                    className='cust_dropdown'
                    value={fieldProps.input.value || props.initialValues.currency} >
                    <option value='' disabled selected hidden>
                        {i18next.t('Aboutus.tab.about.selectcurrency')}
                    </option>
                    {options.map((option: { label: React.ReactText; value: React.ReactText }, i: number) => {
                        return (
                            <option key={`opt_${i}`} value={option.value}>
                                {option.label}
                            </option>
                        );
                    })}
                </Form.Control>
                {fieldProps.meta.touched &&
                    ((fieldProps.meta.error && (
                        <span style={{ color: 'red' }}>
                            {i18next.t('Aboutus.error.currency.isNotAValidCurrency')}
                        </span>
                    )) ||
                        (fieldProps.meta.warning && (
                            <span style={{ color: 'orange' }}>{fieldProps.meta.warning}</span>
                        )))}
            </div>
        );
    };

    const languageOption: ISelectOption[] = [
        { value: TRENDEX_LANGUAGES.ENGLISH, label: `${i18next.t('English')}` },
        { value: TRENDEX_LANGUAGES.FRENCH, label: `${i18next.t('French')}` },
        { value: TRENDEX_LANGUAGES.SPANISH, label: `${i18next.t('Spanish')}` },
    ];

    const generateLanguageSelect = (fieldProps: any, optionList: ISelectOption[]) => {
        return (
            <WrapperSelect
                className='select-language'
                showDefaultStyle={false}
                valueChange={fieldProps.input.onChange}
                propsReduxForm={fieldProps}
                customValue=''
                data={optionList}
                type={WRAPPER_SELECT_TYPE.NATIVE} />
        );
    };

    const generateDatePicker = (fieldProps: any) => {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={LOCALE}>
                <DatePicker
                    className='date-dob'
                    InputProps={{
                        disableUnderline: true
                    }}
                    format={datePicker.DISPLAY_FORMAT}
                    name={'dateOfBirth'}
                    value={fieldProps.input.value}
                    disableFuture={true}
                    invalidDateMessage={i18next.t('Aboutus.tab.about.datepicker.invalidformat')}
                    openTo="year"
                    required
                    views={['year', 'month', 'date']}
                    onChange={fieldProps.input.onChange}
                    cancelLabel={i18next.t('genericcancel')}
                />
            </MuiPickersUtilsProvider>
        );
    }

    const fieldValid = (fieldName: string) => {
        return props.initialValues[fieldName as keyof typeof props.initialValues] !== EmptyString;
    }

    const handleUserInfoSubmit = (formData: Record<string, string | number | undefined | Date>): void => {
        if (!formData.dateOfBirth) {
            props.onInformationsSubmit(formData);
            return;
        }
        props.onInformationsSubmit({ ...formData, dateOfBirth: new Date(formData.dateOfBirth).toISOString().substring(0, 10) });
    };

    const fieldMandatory = (args: any, field: string) => EDIT_PROFILE_MANDATORY[field] ? checkIsEmptyValue(args) : undefined;

    return (
        <div className='user-information-form'>
            {Utils.isMobile() && (
                <span className='infobox'>
                    <Trans i18nKey={'userInformationForm.infoBox'} />
                </span>
            )}
            <form
                id='user-information-form'
                onSubmit={props.handleSubmit(handleUserInfoSubmit)}
                className='row'>
                <div className='field-container custinput_group col-md-6'>
                    <label className='form_label'>
                        {i18next.t('Aboutus.tab.about.email')}
                        {EDIT_PROFILE_MANDATORY.email && <span className='red'>*</span>}
                    </label>
                    <Field
                        disabled={props.isEmailValidated || isConfirmed}
                        name={'email'}
                        type='text'
                        placeholder='s.doe@gmail.com'
                        normalize={Utils.getTrimValue}
                        validate={[
                            ((args: any) => fieldMandatory(args, "email")),
                            checkValueWithoutValidatedConfirmedEmail,
                            emailValidator,
                        ]}
                        component={Input.generateInputText}
                    />
                    {!props.isEmailValidated ? (
                        <span className='email-not-verified'>
                            <FontAwesomeIcon className='icon' icon={faTimesCircle} />
                            {i18next.t('Email.NotVerified')}
                        </span>
                    ) : null}
                </div>
                <div className='field-container custinput_group col-md-6'>
                    <label className='form_label'>
                        {i18next.t('Aboutus.tab.about.firstname')}
                        {EDIT_PROFILE_MANDATORY.firstName && <span className='red'>*</span>}
                    </label>
                    <Field
                        disabled={fieldValid('firstName') && isConfirmed}
                        name={'firstName'}
                        type='text'
                        normalize={Utils.getTrimStartValue}
                        validate={[(args: any) => fieldMandatory(args, "firstName")]}
                        component={Input.generateInputText as any}
                    />
                </div>
                <div className='field-container custinput_group col-md-6'>
                    <label className='form_label'>
                        {i18next.t('Aboutus.tab.about.lastname')}
                        {EDIT_PROFILE_MANDATORY.lastName && <span className='red'>*</span>}
                    </label>
                    <Field
                        disabled={fieldValid('lastName') && isConfirmed}
                        name={'lastName'}
                        type='text'
                        normalize={Utils.getTrimStartValue}
                        validate={[(args: any) => fieldMandatory(args, "lastName")]}
                        component={Input.generateInputText}
                    />
                </div>
                <div className='field-container custinput_group col-md-6'>
                    <label className='form_label'>
                        {i18next.t('Aboutus.tab.about.pseudo')}
                        {EDIT_PROFILE_MANDATORY.pseudo && <span className='red'>*</span>}
                    </label>
                    <Field
                        name={'pseudo'}
                        type='text'
                        normalize={Utils.getTrimStartValue}
                        validate={(args: any) => fieldMandatory(args, "pseudo") && checkValue}
                        component={Input.generateInputText}
                    />
                </div>
                <div className='field-container custinput_group col-md-6'>
                    <label className='form_label'>
                        {i18next.t('Aboutus.tab.about.country')}
                        {EDIT_PROFILE_MANDATORY.country && <span className='red'>*</span>}
                    </label>
                    <Field
                        disabled={fieldValid("country") && isConfirmed}
                        name={'country'}
                        type='select'
                        component={generateSelect}
                        validate={[(args: any) => fieldMandatory(args, "country")]}
                    />
                </div>

                <div className='field-container custinput_group col-md-6'>
                    <label className='form_label'>
                        {i18next.t('Aboutus.tab.about.currency')}
                        {EDIT_PROFILE_MANDATORY.currency && <span className='red'>*</span>}
                    </label>
                    <Field
                        name={'currency'}
                        type='select'
                        component={generateCurrencySelect}
                        validate={[(args: any) => fieldMandatory(args, "currency")]}
                    />
                </div>

                <div className='field-container custinput_group col-md-6'>
                    <label className='form_label'>
                        {i18next.t('Aboutus.tab.about.phone')}
                        {EDIT_PROFILE_MANDATORY.phone && <span className='red'>*</span>}
                    </label>
                    <PhoneInput
                        country={COUNTRY.FRENCH.toLowerCase()}
                        regions={WorldRegions}
                        value={phone}
                        onChange={setPhone}
                        isValid={validateFormPhone} />
                </div>

                {phone && <div className="checkbox-container">
                    <FormControlLabel
                        onChange={(_, checked: boolean) => acceptSMSNotification(checked)}
                        key={''}
                        classes={{ label: 'checkbox-send-sms' }}
                        value={smsNotificationEnabled}
                        control={<TrendexCheckbox checkboxColor='#6677F5' />}
                        label={`${i18next.t('sms.notification.disclaimer')}`}
                        checked={smsNotificationEnabled} />
                </div>}

                <div className='field-container custinput_group col-md-6'>
                    <label className='form_label'>
                        {i18next.t('Aboutus.tab.about.address')}
                        {EDIT_PROFILE_MANDATORY.address && <span className='red'>*</span>}
                    </label>
                    <Field
                        name={'address'}
                        type='text'
                        normalize={Utils.getTrimStartValue}
                        component={Input.generateInputText}
                        validate={[(args: any) => fieldMandatory(args, "address")]}
                    />
                </div>

                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">
                        {i18next.t('Aboutus.tab.about.datepicker')}
                        {EDIT_PROFILE_MANDATORY.datepicker && <span className='red'>*</span>}
                    </label>
                    <Field
                        name={'dateOfBirth'}
                        type='date'
                        component={generateDatePicker}
                        validate={[(args: any) => fieldMandatory(args, "dateOfBirth")]}
                    />
                </div>
                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">
                        {i18next.t('Aboutus.tab.about.discord')}
                        {EDIT_PROFILE_MANDATORY.discord && <span className='red'>*</span>}
                    </label>
                    <Field
                        name={'discord'}
                        type="text"
                        normalize={Utils.getTrimStartValue}
                        validate={[(args: any) => fieldMandatory(args, "discord"), discordValidator]}
                        component={Input.generateInputText}
                    />
                </div>
                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">
                        {i18next.t('Aboutus.tab.about.language')}
                        {EDIT_PROFILE_MANDATORY.language && <span className='red'>*</span>}
                    </label>
                    <Field
                        name={'language'}
                        type="select"
                        component={(fieldProps: any) => generateLanguageSelect(fieldProps, languageOption)} />
                </div>
                <div className='confirm-block'>
                    <div className='button-cancel'>
                        <SecondaryBtn
                            disabled={false}
                            receiveClickEvent={false}
                            type="button"
                            className='cancel-btn'
                            textButton={i18next.t('genericcancel')}
                            handleClick={() => props.handleIsThisMainProfilePage(true)}
                        />
                    </div>
                    <div className="button-submit">
                        {props.showSubmitButton !== false &&
                            <PrimaryButton
                                receiveClickEvent={false}
                                disabled={!phoneIsValid || checkPristine()}
                                type="submit"
                                textButton={i18next.t('genericOk')}
                            />
                        }
                    </div>
                </div>
            </form>
            {isAuthStateLoadingWhileFormSubmit &&
                <CircularLoader
                    loaderLocal={false}
                    loaderMessage={i18next.t("genericloading")}
                />
            }
        </div>
    );
};

const mapStateToProps = (_: UserInformationFormProps, ownProps: UserInformationFormProps) => ({
    initialValues: ownProps.initialValues ? ownProps.initialValues : INITIAL_STATE_FORM,
    pristine: isPristine("UserInformationForm")(ownProps),
});

const UserInformationFormRedux = reduxForm<{}, UserInformationFormProps>({
    form: 'UserInformationForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    enableReinitialize: true,
})(UserInformationForm);

export default connect(mapStateToProps)(UserInformationFormRedux);
