import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import * as AuthLoadMeActionType from './actionTypes';

const ROOT_VALUE_STATE: ReduxRootState = {
    loading: false,
    data: {}
};

export const authLoadMeReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {
        case AuthLoadMeActionType.LOADME_LOADING:
            return {
                ...state,
                loading: true,
            };

        case AuthLoadMeActionType.LOADME_NOT_LOADING:
            return {
                ...state,
                loading: false,
            };

        default: return state;
    }
};
