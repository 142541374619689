import User from 'components/icons/User';
import i18next from 'i18next';
import React from 'react';
import ITokenPriceInformationProps from './ITokenPriceInformationProps';

const TokenPriceInformation: React.FC<ITokenPriceInformationProps> = ({ userIconSize, currentValue, specialPrice, asset }) => {
    return <div className="info token-price bonus">
        <div className="icon">
            <User width={userIconSize} height={userIconSize} />
        </div>
        <div className="label">
            {i18next.t(asset.enableCustomAssetProfil() && asset.IsAssetBenzema && asset.isEarlyBirdEnable() ? 'benzema.custom-profil.token-information.price' : 'token-information.price')}
        </div>
        {specialPrice &&
            <div className="specialPrice">
                {specialPrice.toCurrency()}
            </div>
        }
        <div className="value">
            {currentValue.toCurrency()}
        </div>
    </div>;
};

export default React.memo(TokenPriceInformation);
