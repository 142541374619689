import * as React from 'react';

const MoneyEarned = ({ height = "20px", width = "20px", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg height={height} width={width} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g id="Money_earned" data-name="Money earned" transform="translate(0 -728.5)">
                <g id="Rectangle_2014" data-name="Rectangle 2014" transform="translate(0 728.5)" fill="#8187d0" stroke="#707070" strokeWidth="1" opacity="0">
                    <rect width="20" height="20" stroke="none" />
                    <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
                </g>
                <g id="hand-holding-dollar-duotone_1_" data-name="hand-holding-dollar-duotone (1)" transform="translate(1.076 730.566)">
                    <path id="Tracé_3224" data-name="Tracé 3224" d="M217.362,5.7a4.944,4.944,0,0,1-.86-.257l-.16-.055a.766.766,0,0,0-1,.446.8.8,0,0,0,.475,1l.152.054a8.056,8.056,0,0,0,.893.271v.4a.782.782,0,1,0,1.563,0V7.221a1.784,1.784,0,0,0,1.5-1.49c.283-1.631-1.267-2.074-2.1-2.312l-.173-.06c-.857-.243-.808-.348-.785-.482.038-.22.5-.328,1.05-.241a3.928,3.928,0,0,1,.64.177.782.782,0,0,0,.511-1.478,6.2,6.2,0,0,0-.646-.186V.782a.782.782,0,1,0-1.563,0v.343A1.919,1.919,0,0,0,215.3,2.611c-.251,1.612,1.323,2.075,1.916,2.251l.212.061c1.032.3,1.015.387.989.542C218.382,5.686,217.922,5.794,217.362,5.7Z" transform="translate(-208.718)" fill="#8187d0" />
                    <path id="Tracé_3225" data-name="Tracé 3225" d="M17.609,320.516a1.2,1.2,0,0,0-1.733-.276l-3.71,2.873H8.429a.49.49,0,0,1-.468-.521.509.509,0,0,1,.468-.521h2.425a1.067,1.067,0,0,0,1.034-.867,1.03,1.03,0,0,0-.98-1.218H5.922a3.594,3.594,0,0,0-2.3.855l-1.441,1.23-1.716-.029a.54.54,0,0,0-.468.551v3.128a.508.508,0,0,0,.468.522H11.194a3.143,3.143,0,0,0,1.912-.661l4.187-3.243A1.307,1.307,0,0,0,17.609,320.516Z" transform="translate(0 -310.374)" fill="#8187d0" opacity="0.4" />
                </g>
            </g>
        </svg>


    );
};

export default MoneyEarned;
