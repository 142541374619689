import validator from 'validator';
import Utils from './Utils';
import { EmptyString, MINIMUM_REFUND_AMOUNT, REGEX_PATTERNS, ZERO } from 'config/_const';
import i18n from '18n';
const IBAN = require('iban');

export const isValidEmail = (email: string): boolean => {
    if (!email) return false;
    if (Utils.isProdEnvironment())
        email = email.replace(/\+[^@]*/g, '');
    return REGEX_PATTERNS.EMAIL.test(email);
};

export const isValidPassword = (password: string) => {
    return REGEX_PATTERNS.PASSWORD.test(password);
};

export const containsLetterAndNumber = (input: string) => {
    return REGEX_PATTERNS.NUMBER_AND_LETTER.test(input);
};

export const hasMinimumLength = (input: string) => {
    return REGEX_PATTERNS.MINIMUM_LENGTH_REGEX.test(input);
};

export const isValidPhoneNumber = (phone: string) => {
    return validator.isMobilePhone(phone);
};


export const isFrenchPhoneNumber = (phone: string) => {
    return REGEX_PATTERNS.FRENCH_PHONE.test(phone);
}

export const checkDiscord = (usernameAndTag: string) => {
    return REGEX_PATTERNS.DISCORD.test(usernameAndTag);
};

export const checkValidIban = (value: string) => {
    return !IBAN.isValid(value) ? i18n.t("error.invalidIban") : EmptyString;
}

export const checkValidWithdrawAmount = (value: string, isOnboarding: boolean) => {
    const numericValue = Number(value)
    const minimumRefundAmount = isOnboarding ? ZERO : MINIMUM_REFUND_AMOUNT.toCurrencyValue();
    return numericValue < minimumRefundAmount;
}

export const checkEmpty = (value: string) => {
    return value === EmptyString ? i18n.t("withdraw.not-sepa.error.not-complete") : EmptyString;
}