import React, { FC } from "react";

const LoginIcon: FC = () => {
  return (
    <span>
      <svg
        style={{
          verticalAlign: "middle",
          fontSize: "20px",
          marginBottom: "4px",
        }}
        width="16"
        height="16"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 0C12.1454 0 3.81014 6.47713 1.00789 15.4008L4.82415 16.5992C7.11789 9.29487 13.9426 4 22 4C31.9414 4 40 12.0586 40 22C40 31.9414 31.9414 40 22 40C13.9445 40 7.11785 34.705 4.82415 27.4008L1.00789 28.5992C3.81018 37.523 12.1476 44 22 44C34.1506 44 44 34.1506 44 22C44 9.84943 34.1506 0 22 0ZM14.5858 11.4142L23.1716 20H0V24H23.1716L14.5858 32.5858L17.4142 35.4142L29.4142 23.4142C30.1953 22.6332 30.1953 21.3668 29.4142 20.5858L17.4142 8.58579L14.5858 11.4142Z"
          fill="#fff"
        />
      </svg>
    </span>
  );
};

export default LoginIcon;
