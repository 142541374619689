import { KeyboardArrowDown, KeyboardArrowLeft } from '@material-ui/icons';
import Application from 'Application';
import clsx from 'clsx';
import PlusIcon from 'components/icons/Plus';
import SubtractIcon from 'components/icons/Subtract';
import WalletRefund from 'components/medium/wallet-refund/WalletRefund';
import { ALLOW_ACCESS_WITH_INSUFFICIENT_BALANCE, MINIMUM_REFUND_AMOUNT, VERIFICATION_STATUS } from 'config/_const';
import useFirstDepositAchievement from 'hooks/first-deposit-achievement/useFirstDepositAchievement';
import useModalDisplay from 'hooks/use-modal-display/useModalDisplay';
import useSlidingPages from 'hooks/use-sliding-pages/useSlidingPages';
import IUseTransaction from 'hooks/use-transactions/IUseTransaction';
import useTransaction from 'hooks/use-transactions/useTransaction';
import IUseUserIdentityVerification from 'hooks/use-user-identity-verification/IUseUserIdentityVerification';
import useUserIdentityVerification from 'hooks/use-user-identity-verification/useUserIdentityVerification';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import ReduxStoreInterface from 'model/ReduxStoreInterface';
import React, { useEffect } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCredits } from 'service/auth/actions';
import { getActiveUserTeams } from 'service/teams/action';
import WithdrawModal from '../withdraw-modal/WithdrawModal';
import { IWalletProps } from './IWalletProps';
import Portfolio from './portfolio/Portfolio';
import WalletOverview from './wallet-overview/WalletOverview';
import "./wallet.scss";

const WalletSheet: React.FC<IWalletProps> = ({ accountViewModel, t: translate, isSidebar, onClose, walletViewModel }) => {
    const dispatch = useDispatch();
    const assetCategories: AssetCategoryInterface[] = useSelector((state: ReduxStoreInterface) => state.assetsCategories.data);
    const { transactions, financialTransactionsLoading }: IUseTransaction = useTransaction();
    const { displaySlidingPage } = useSlidingPages();
    const { showShortMessageInModal, showToVerifyProfileInModal, showEmailVerificationText } = useModalDisplay();
    const timeForRotating: number = 400;
    const [isWalletOpended, setIsWalletOpened] = React.useState<boolean>(false);
    const { firstDepositAchievementVM } = useFirstDepositAchievement(transactions);
    const { launchIdentityVerification }: IUseUserIdentityVerification = useUserIdentityVerification({ shouldLaunchPolling: Application.getInstance().OnBoardingV2 && accountViewModel.IsOnboardingRunning });

    useEffect(() => {
        const rotatingTimeout = setTimeout(() => {
            if (isSidebar) setIsWalletOpened(true)
        }, timeForRotating)

        return () => clearTimeout(rotatingTimeout);
    }, [isSidebar])

    useEffect(() => {
        dispatch(getUserCredits());
        dispatch(getActiveUserTeams(accountViewModel.Id, true));
    }, [dispatch, accountViewModel.Id]);

    const openWithdrawPage = (): void => {
        if (accountViewModel.identityConfirmStatus === VERIFICATION_STATUS.PENDING) showShortMessageInModal(translate('Myportfolio.message.pending'));
        else if (!accountViewModel.isEmailValidated) showToVerifyProfileInModal(translate('Myportfolio.message.to.confirm.user'), translate('emailverify.sendbtn'), () => showEmailVerificationText(translate("email.verification.text")));
        else if (accountViewModel.identityConfirmStatus !== VERIFICATION_STATUS.CONFIRMED) launchIdentityVerification();
        else if (accountViewModel.creditsAsNumber <= MINIMUM_REFUND_AMOUNT && !ALLOW_ACCESS_WITH_INSUFFICIENT_BALANCE) showShortMessageInModal(translate('not.enough.money-withdraw'));
        else {
            onClose();
            displaySlidingPage(<WithdrawModal />)
        };
    }

    const onAddMoneyClick = () => {
        displaySlidingPage(<WalletRefund />);
        onClose();
    }

    return (
        <>
            <div className="wallet-container">
                {!accountViewModel.IsOnboardingRunning &&
                    <>
                        <span className={clsx("close-wallet", { "rotate": isWalletOpended })} onClick={onClose}>{isSidebar ? <KeyboardArrowLeft /> : <KeyboardArrowDown />}</span>
                        <h3 className='wallet-title'>{translate("wallet.yourWallet")}</h3>
                    </>
                }
                {Application.getInstance().showGamblingFeature && (!Application.getInstance().OnBoardingV2 || !accountViewModel.IsOnboardingRunning) &&
                    <>
                        <div className="wallet-transaction-buttons">
                            <button className="add-money" onClick={onAddMoneyClick}>
                                <PlusIcon />
                                <span>{translate("wallet.addMoney")}</span>
                            </button>
                            <button onClick={openWithdrawPage}>
                                <SubtractIcon />
                                <span>{translate("wallet.withdraw")}</span>
                            </button>
                        </div>
                        {!financialTransactionsLoading && !firstDepositAchievementVM.FirstDepositCompleted &&
                            <p onClick={onAddMoneyClick} className="gift-first-depot">
                                <Trans
                                    i18nKey={firstDepositAchievementVM.Achievement.Subtitle.key}
                                    components={{ bold: <strong /> }}
                                    values={firstDepositAchievementVM.Achievement.Subtitle.values}
                                />
                            </p>
                        }
                    </>
                }
                <WalletOverview walletViewModel={walletViewModel} accountViewModel={accountViewModel} />
                <Portfolio handleKYCModeAndDisplayModal={launchIdentityVerification} walletViewModel={walletViewModel} accountViewModel={accountViewModel} assetCategories={assetCategories} transactions={transactions} onWalletClose={onClose} />
            </div>
        </>
    )
}

export default withTranslation()(WalletSheet);
