import { TournamentProgress } from "config/_enums";
import LeagueAttribution from "model/League/LeagueAttribution";
import { RootState } from "model/Redux";
import React from "react";
import { useSelector } from "react-redux";
import { leagueAttributionSelector } from "service/league/selectors";
import { tournamentByProgressSelector } from "service/tournament/selectors";
import TournamentViewModel from "view-model/Tournament/TournamentViewModel/TournamentViewModel";
import UserLeagueViewModel from "view-model/UserLeagueViewModel/UserLeagueViewModel";

const useUserLeague = () => {
    const leagueAttribution: LeagueAttribution = useSelector(leagueAttributionSelector);
    const previousTournament: TournamentViewModel | undefined = useSelector((state: RootState) => tournamentByProgressSelector(state, TournamentProgress.PREVIOUS));
    const userLeagueViewModel = React.useMemo(() => {
        return new UserLeagueViewModel({ leagueAttribution, previousTournament });
    }, [leagueAttribution, previousTournament]);

    return { userLeagueViewModel };
};

export default useUserLeague;
