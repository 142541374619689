import { OrderBy } from '../../config/_enums';
import PredicateSortBuilder from '../../model/Helper/PredicateSortBuilder';
import ITournamentResult from '../../model/Tournament/ITournamentResult';

export const sortTournamentResultsByPlace = (tournamentResultA: ITournamentResult, tournamentResultB: ITournamentResult) => {
    return new PredicateSortBuilder<ITournamentResult>({ sortSide: OrderBy.ASC, sortKey: 'place' }).build(tournamentResultA, tournamentResultB);
};

export const sortResultsByPlace = (results: ITournamentResult[]) => {
    return results.sort(sortTournamentResultsByPlace);
};
