import { DEFAULT_ID_AS_STRING, DEFAULT_ID_AS_NUMBER, TEAM_STATUS, TEAM_INTERNAL_STATUS } from 'config/_const';
import { TeamUserInterface } from 'model/User/UserInterface';
import ITeam, { IInternalTeam } from './ITeam';
import ITeamAsset from './ITeamAsset';
import ITeamConfig from './ITeamConfig';

interface IInternalTeamParams {
    userId: string;
    configuration: ITeamConfig;
    team?: Partial<ITeam>;
}
export default class InternalTeam implements IInternalTeam {
    public _id: string;
    public label: string;
    public teamAssets: ITeamAsset[];
    public autoGenerated: boolean;
    public lastTournamentPosition: number;
    public owner: TeamUserInterface;
    public status: string;
    public configuration: ITeamConfig;
    public enabled: boolean;
    public createdAt: string;
    public updatedAt: string;
    public internalStatus: string;

    constructor({ userId, configuration, team }: IInternalTeamParams) {
        this._id = team?._id ?? DEFAULT_ID_AS_STRING
        this.label = `team_${userId}`;
        this.teamAssets = team?.teamAssets ?? [];
        this.lastTournamentPosition = team?.lastTournamentPosition ?? DEFAULT_ID_AS_NUMBER;
        this.owner = {
            _id: userId,
        };
        this.status = team?.status ?? TEAM_STATUS.DISENGAGED;
        this.configuration = team?.configuration ?? configuration;
        this.autoGenerated = team?.autoGenerated ?? false;

        this.enabled = team?.enabled ?? true;
        this.createdAt = team?.createdAt ?? new Date().toISOString();
        this.updatedAt = team?.updatedAt ?? new Date().toISOString();
        this.internalStatus = TEAM_INTERNAL_STATUS.EDIT_DONE;
    }
}
