import axios, { CancelToken } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { HTTP_STATUS_OK } from '../../config/_const';
import AxiosConfig, { getSource } from '../../config/axiosConfig';
import { ASSET_ENDPOINTS } from '../../config/endpoints';
import { IHttpStrongTypedResponse } from '../../model/IHttpResponse';
import { RootState } from '../../model/Redux';
import SecondaryMarketPredictPriceWrapperState from '../../model/Resale/SecondaryMarketPredictPriceWrapperState';
import * as SecondaryMarketActionsType from './actionsType';
let source = getSource();

export const cancelSecondaryMarketGetPredictPriceRequest = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    source.cancel('Operation canceled by the user.');
    return dispatch({ type: SecondaryMarketActionsType.SECONDARY_MARKET_GET_PREDICTED_ASSET_PRICE_FAILED });
};

export const secondaryMarketGetPredictedPrice = (assetId: string, tokens: number, side: string, currentUserId?: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch, _) => {
    try {
        dispatch({ type: SecondaryMarketActionsType.SECONDARY_MARKET_GET_PREDICTED_ASSET_PRICE_START });
        let route: string = `${ASSET_ENDPOINTS.SECONDARY_MARKET_PREDICT_PRICE_BASE_URL}${assetId}/${tokens}/${side}`;
        if (typeof currentUserId !== 'undefined')
            route = `${route}/${currentUserId}`;
        const cancelToken: CancelToken = source.token;
        const response: IHttpStrongTypedResponse<SecondaryMarketPredictPriceWrapperState> = await AxiosConfig.get(route, {
            cancelToken,
        });
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: SecondaryMarketActionsType.SECONDARY_MARKET_GET_PREDICTED_ASSET_PRICE_FAILED,
            });
        }
        return dispatch({
            type: SecondaryMarketActionsType.SECONDARY_MARKET_GET_PREDICTED_ASSET_PRICE_SUCCESS,
            payload: { secondaryMarketPredictPriceData: response.data.data, assetId, side }
        });
    } catch (error) {
        console.log('error: ', error);
        if (axios.isCancel(error))
            source = getSource();
        return dispatch({
            type: SecondaryMarketActionsType.SECONDARY_MARKET_GET_PREDICTED_ASSET_PRICE_FAILED,
        });
    }
};

export const dropSecondaryMarketPredictPriceData = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    return dispatch({
        type: SecondaryMarketActionsType.DROP_PREDICT_PRICE_DATA,
    });
};
