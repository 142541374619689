import { Tooltip } from '@material-ui/core';
import Application from 'Application';
import clsx from 'clsx';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import { G_TAGS_KNOWN_EVENTS, ONBOARDING_PREFIX_URL, TRANSACTION_SOURCE, ZERO } from 'config/_const';
import { AssetTransactionType, PortfolioTabs } from 'config/_enums';
import withCurrentTournament from 'hoc/with-tournament/withCurrentTournament';
import useAssetSlideView from 'hooks/asset/use-asset-slide-view/useAssetSlideView';
import IUseTeamEditor from 'hooks/team/use-team-editor/IUseTeamEditor';
import useTeamEditor from 'hooks/team/use-team-editor/useTeamEditor';
import useAssetBuySellClick from 'hooks/use-asset-buy-sell-click/useAssetBuySellClick';
import useAssetTransactionSlides from 'hooks/use-asset-transaction-slideup/useAssetTransactionSlides';
import useModalDisplay from 'hooks/use-modal-display/useModalDisplay';
import IUseYield from 'hooks/use-yield/IUseYield';
import useYield from 'hooks/use-yield/useYield';
import useIsDesktopMode from 'hooks/useIsDesktopMode';
import useIsMobile from 'hooks/useIsMobile';
import AssetInterface, { IAssetWithAmount } from 'model/AssetModel/AssetInterface';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import React, { useMemo, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { SegmentTracking } from 'service/segment/SegmentTracking';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import TransactionViewModel from 'view-model/TransactionViewModel/TransactionViewModel';
import AssetWalletViewModel from 'view-model/WalletViewModel/AssetWalletViewModel';
import YieldCalculService from 'view-model/Yield/YieldCalculService/YieldCalculService';
import WalletListView from '../wallet-list-view/WalletListView';
import WalletTeamView from '../wallet-team-view/WalletTeamView';
import WalletTransactions from '../wallet-transactions/WalletTransactions';
import IPortfolioProps, { IBasePortfolioProps } from './IPortfolioProps';
import "./wallet-portfolio.scss";
import SecondaryButton from 'components/small/buttons/secondary-button/SecondaryButton';
import { updateOnboardingStep } from 'service/auth/actions';
import { useDispatch } from 'react-redux';
import { managementModal } from 'service/modal/action';

const Portfolio: React.FC<IPortfolioProps> = ({ walletViewModel, accountViewModel, assetCategories, transactions, onWalletClose, t: translation, handleKYCModeAndDisplayModal, currentTournament }) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState<PortfolioTabs>(PortfolioTabs.SHARES);
    const { teamBuildingViewModel }: IUseTeamEditor = useTeamEditor({ walletViewModel, accountViewModel, nextTournament: currentTournament });
    const isMobile = useIsMobile();
    const portfolioTabs = [{ label: translation("wallet.shares"), value: PortfolioTabs.SHARES }, { label: translation("wallet.transactions"), value: PortfolioTabs.TRANSACTION }];
    const { onAssetProfileClick } = useAssetSlideView();
    const { openAssetTransaction, closeAssetTransaction } = useAssetTransactionSlides();
    const canShowHalfScreenAssetTransactionInDesktop = true;
    const yieldTransactions: TransactionViewModel[] = useMemo(() => transactions.filter((entry) => entry.TransactionSource === TRANSACTION_SOURCE.YIELD), [transactions]);
    const { onSellAssetClick } = useAssetBuySellClick();
    const { shouldShowYield }: IUseYield = useYield();
    const { showAssetSellWarning } = useModalDisplay();
    const isDesktopView: boolean = useIsDesktopMode();
    const [showOnboardingTooltip, setShowOnboardingTooltip] = useState<boolean>(true);

    const onClick = (assetViewModel: AssetViewModel) => {
        onAssetProfileClick(assetViewModel);
        onWalletClose();
        closeAssetTransaction();
    };

    const ownedAssets: IAssetWithAmount[] = useMemo(() => {
        return walletViewModel.UserAssets.filter(assetWallet => assetWallet.amount > ZERO).map((assetWallet: AssetWalletInterface) => {
            const assetWalletAsViewModel: AssetWalletViewModel = new AssetWalletViewModel(assetWallet);
            const yieldCalcul: YieldCalculService = new YieldCalculService({ assetId: assetWalletAsViewModel.Asset.AssetId, tokensAmount: assetWalletAsViewModel.TokensAmountWithTeams, assetIRLScore: assetWalletAsViewModel.Asset.IRLScore, transactions: yieldTransactions });

            return {
                ...assetWalletAsViewModel.Asset.Asset,
                amount: assetWalletAsViewModel.Amount,
                profit: assetWalletAsViewModel.Earning,
                tokensInTeam: assetWalletAsViewModel.TokensInTeam,
                yieldAmountPerYear: yieldCalcul.YieldAmountPerYear,
                yieldTotalAmountEarned: yieldCalcul.TotalAmountEarned,
                isYieldEnabled: shouldShowYield(assetWalletAsViewModel.Asset),
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletViewModel.UserAssets, yieldTransactions]);

    const onBuyAssetClick = (assetSlug: string) => {
        const asset = walletViewModel.getAssetBySlug(assetSlug);
        if (!asset)
            return;
        const assetViewModel = new AssetViewModel(asset);
        SegmentTracking.sendProductAddedEvent({ asset, accountViewModel });
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.GET_SHARES, {
            name: asset.name,
            conversionValue: asset.currentValue,
            currency: accountViewModel.Currency,
        });
        const openTransactionSlideCallback = () => openAssetTransaction(asset._id, AssetTransactionType.BUY, canShowHalfScreenAssetTransactionInDesktop);
        onAssetProfileClick(assetViewModel, openTransactionSlideCallback);
        onWalletClose();
    };

    const handleSellClick = (assetSlug: string, assetAmount: number) => {
        const canOpenAssetProfile = !accountViewModel.IsOnboardingRunning;
        if (assetAmount <= ZERO) {
            showAssetSellWarning();
            return;
        }

        onSellAssetClick(assetSlug, canOpenAssetProfile, handleKYCModeAndDisplayModal, onWalletClose);
    };

    const renderPortfolioActions = (assetSlug: string, assetAmount: number, userCanSell: boolean, assetAmountWithoutTeam?: number, showOnboardingTooltip?: boolean) => {
        const assetFromStore = walletViewModel.getAssetBySlug(assetSlug) as AssetInterface;
        const assetViewModel = new AssetViewModel(assetFromStore);
        const canShowSellButton = (((assetViewModel.InitialSaleDone && assetAmount) && (assetViewModel.Owner !== accountViewModel.Id || accountViewModel.CanVipSellOwnTokens)) || accountViewModel.IsUserAdmin);
        if (accountViewModel.IsUserAdmin)
            userCanSell = true;
        return (
            <div className={clsx("portfolio-asset-actions")} >
                <button className='portfolio-asset-actions_view' onClick={() => onClick(assetViewModel)} disabled={accountViewModel.IsOnboardingRunning}>{translation("wallet.view")}</button>
                {Application.getInstance().showGamblingFeature &&
                    <>
                        <button className='portfolio-asset-actions_buy' onClick={() => onBuyAssetClick(assetSlug)} disabled={accountViewModel.IsOnboardingRunning}>{translation("wallet.buy")}</button>
                        {canShowSellButton && userCanSell && (
                            <>
                                {accountViewModel.IsOnboardingRunning && showOnboardingTooltip ?
                                    <Tooltip
                                        arrow
                                        interactive
                                        title={
                                            <div className='onboarding-wallet-sell'>
                                                <p className='no-margin'><Trans i18nKey="onboarding.wallet.sell.tooltip.text" /></p>
                                                <Link to={`/${ONBOARDING_PREFIX_URL}/sell/asset/${assetSlug}`}>
                                                    <PrimaryButton disabled={false} textButton={translation('onboarding.wallet.sell.tooltip.button')} receiveClickEvent={false} type={'button'} handleClick={() => handleSellClick(assetSlug, assetAmountWithoutTeam ?? ZERO)} />
                                                </Link>
                                            </div>
                                        }
                                        placement="bottom"
                                        open={true}>
                                        <Link to={`/${ONBOARDING_PREFIX_URL}/sell/asset/${assetSlug}`}>
                                            <button
                                                onClick={() => handleSellClick(assetSlug, assetAmountWithoutTeam ?? ZERO)}
                                                className='portfolio-asset-actions_sell'
                                                disabled={accountViewModel.IsOnboardingRunning && !showOnboardingTooltip}>
                                                {translation("wallet.sell")}
                                            </button>
                                        </Link>
                                    </Tooltip>
                                    :
                                    <button
                                        className='portfolio-asset-actions_sell'
                                        disabled={accountViewModel.IsOnboardingRunning && !showOnboardingTooltip}
                                        onClick={() => handleSellClick(assetSlug, assetAmountWithoutTeam ?? ZERO)}
                                    >
                                        {translation("wallet.sell")}
                                    </button>
                                }
                            </>
                        )}
                    </>}
            </div >
        );
    };

    const displaySkipStepConfirmationPopup = () => {
        setShowOnboardingTooltip(false);
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: true,
            showBackArrow: false,
            showCancelButton: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                className: "sell-onboarding-skip-step_customModal",
                showCancelButton: true,
                onRefused: () => {
                    dispatch(managementModal(false));
                    setShowOnboardingTooltip(true);
                },
                onValidation: () => {
                    dispatch(updateOnboardingStep());
                    dispatch(managementModal(false));
                },
                textValidButton: <Trans i18nKey="genericaccept" />,
                message: <Trans i18nKey={'onboarding.skip.step.confirmation'} />
            },
        }))
    }

    const showWalletTeam: boolean = React.useMemo(() => {
        if (accountViewModel.IsOnboardingRunning && !isDesktopView)
            return false;
        return true;
    }, [accountViewModel.IsOnboardingRunning, isDesktopView])

    return (
        <div className={clsx("wallet-portfolio", { sidepane: !isMobile })}>
            <div className="wallet-portfolio-header">
                <div className="wallet-portfolio-tabs">
                    {portfolioTabs
                        .map((tab, index) =>
                            <div key={index}
                                className={clsx("wallet-portfolio-tab", { inactive: tab.value !== selectedTab })}
                                onClick={() => setSelectedTab(tab.value)}
                            >
                                {tab.label}
                            </div>)}
                </div>
            </div>
            {selectedTab === PortfolioTabs.SHARES &&
                <>
                    {showWalletTeam &&
                        <WalletTeamView walletViewModel={walletViewModel} teamBuildingViewModel={teamBuildingViewModel} onWalletClose={onWalletClose} currentTournament={currentTournament} />
                    }
                    {ownedAssets.length > ZERO &&
                        <>
                            <label className='assets-title'>{translation("wallet.assets.available")}</label>
                            <WalletListView assets={ownedAssets} assetCategories={assetCategories} renderPortfolioActions={renderPortfolioActions} onboardingMode={accountViewModel.IsOnboardingRunning} showOnboardingTooltip={showOnboardingTooltip} />
                        </>
                    }
                    {accountViewModel.IsOnboardingRunning &&
                        <div className='skip-onboarding-step'>
                            <SecondaryButton textButton={translation("onboarding.skip.step")} receiveClickEvent={false} type={'button'} disabled={false} handleClick={displaySkipStepConfirmationPopup} />
                        </div>
                    }
                </>
            }
            {selectedTab === PortfolioTabs.TRANSACTION && <WalletTransactions transactions={transactions} />}
        </div>
    );
};

export default compose<React.FC<IBasePortfolioProps>>(
    withTranslation(),
    withCurrentTournament,
)(Portfolio);
