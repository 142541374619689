import { ThunkAction } from "redux-thunk";
import BuyAssetViewModel from "view-model/BuyAssetViewModel/BuyAssetViewModel";
import { Action } from 'redux';
import { WALLET_SIMULATE_BUY, WALLET_SIMULATE_SELL } from './actionTypes';
import SellAssetViewModel from "view-model/SellAssetViewModel/SellAssetViewModel";
import IAssetTransactionViewModel from "view-model/AssetTransactionViewModel/IAssetTransactionViewModel";

export const simulateBuy = (buyAssetViewModel: BuyAssetViewModel): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    return dispatch({
        type: WALLET_SIMULATE_BUY.SUCCESS,
        payload: {
            data: buildTransactionPayload(buyAssetViewModel)
        }
    })
};

export const simulateSell = (sellAssetViewModel: SellAssetViewModel): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    return dispatch({
        type: WALLET_SIMULATE_SELL.SUCCESS,
        payload: {
            data: buildTransactionPayload(sellAssetViewModel)
        }
    })
};

const buildTransactionPayload = (transactionViewModel: IAssetTransactionViewModel) => {
    const instantSell = transactionViewModel instanceof SellAssetViewModel ? transactionViewModel.IsLiquidityPoolApplicable : false;
    return {
        asset: transactionViewModel.Asset.Asset,
        numberOfShares: transactionViewModel.NumberOfToken,
        price: transactionViewModel.TotalPrice,
        instantSell,
        amountPaidInVirtualCredit: transactionViewModel.AmountPaidInVirtualCredit
    };
}