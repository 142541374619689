import { AnalyticsBrowser } from '@segment/analytics-next';
import { SEGMENT_EVENTS } from "config/_const";
import Utils from "utils/Utils";
import { ISegmentTracking, ISendClaimTournamentRewardEventProps, ISendCopyingReferralLinkEventProps, ISendDepositEventProps, ISendIdentityVerificationRequestEventProps, ISendLoginEventProps, ISendOrderCompletedEventProps, ISendProductAddedEventProps, ISendProductClickedEventProps, ISendSignupEventProps, ISendTeamEventProps, ISendTournamentSubscriptionEventProps, ISendWithdrawRequestEventProps } from "./ISegmentTracking";

export class SegmentTracking implements ISegmentTracking {
    private static instance: SegmentTracking;
    private analytics: AnalyticsBrowser;
    private constructor() {
        this.analytics = AnalyticsBrowser.load({ writeKey: '1AKzIuDOGhxRXOm9P1HYR55eNiJ1BFCG' });
    }

    public static getInstance(): SegmentTracking {
        if (!SegmentTracking.instance) {
            SegmentTracking.instance = new SegmentTracking();
        }
        return SegmentTracking.instance;
    }

    sendTracking(eventName: string, eventData: any): void {
        if (eventName === SEGMENT_EVENTS.SIGNUP && eventData) {
            this.analytics.identify(eventData.userId, {
                email: eventData.userEmail,
            });
        }
        this.analytics.track(eventName, eventData);
    }

    public static sendSignupEvent(props: ISendSignupEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.SIGNUP, {
            userEmail: props.actionUser.email,
            userId: props.actionUser._id,
            type: Utils.getConnectionMode(props.actionUser) ?? '',
            referral_id: props.referralViewModel.ReferralId ?? '',
            utm_source_channel: props.signupSourceViewModel.SignupSourceChannel,
            utm_source_id: props.signupSourceViewModel.SignupSourceId
        });
    }

    public static sendLoginEvent(props: ISendLoginEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.LOGIN, {
            userEmail: props.actionUser.email,
            userId: props.actionUser._id,
            type: Utils.getConnectionMode(props.actionUser) ?? '',
        });
    }

    public static sendIdentityVerificationRequestEvent(props: ISendIdentityVerificationRequestEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.IDENTITY_VERIFICATION_REQUEST, {
            userId: props.user._id,
            userEmail: props.user.email
        });
    }

    public static sendCopyingReferralLinkEvent(props: ISendCopyingReferralLinkEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.COPYING_REFERRAL_LINK, {
            referralLink: props.referralLink,
            userEmail: props.userEmail,
            userId: props.userId
        });
    }

    public static sendProductClickedEvent(props: ISendProductClickedEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.PRODUCT_CLICKED, {
            product_id: props.productId,
            category: props.category,
            name: props.name,
            price: props.price,
            url: props.url,
            userEmail: props.accountViewModel.Email ?? '',
            userId: props.accountViewModel.Id ?? ''
        });
    }

    public static sendProductAddedEvent(props: ISendProductAddedEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.PRODUCT_ADDED, {
            product_id: props.asset.id,
            category: props.asset.mainCategory,
            name: props.asset.name,
            price: props.asset.currentValue.toCurrencyValue(),
            url: props.asset.slug,
            currency: props.accountViewModel.Currency,
            user_email: props.accountViewModel.Email
        });
    }

    public static sendOrderCompletedEvent(props: ISendOrderCompletedEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.ORDER_COMPLETED, {
            checkout_id: props.checkoutId,
            userEmail: props.accountViewModel.Email,
            userId: props.accountViewModel.Id,
            order_id: props.orderId,
            total: props.assetTransactionViewModel.Amount,
            revenue: props.assetTransactionViewModel.TotalPrice,
            tax: props.assetTransactionViewModel.TotalFees,
            items: [{
                item_id: props.assetTransactionViewModel.Asset.AssetId,
                item_name: props.assetTransactionViewModel.Asset.AssetName,
                currency: props.assetTransactionViewModel.Currency,
                item_category: props.assetTransactionViewModel.Asset.MainCategoryId,
                price: props.assetTransactionViewModel.CurrentValue,
                quantity: props.assetTransactionViewModel.NumberOfToken
            }]
        });
    }

    public static sendDepositEvent(props: ISendDepositEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.DEPOSIT, {
            conversion_value: props.transactionData.amount.toCurrencyValue(),
            currency: props.transactionData.currency,
            userEmail: props.accountViewModel.Email ?? '',
            userId: props.accountViewModel.Id ?? ''
        });
    }

    public static sendWithdrawRequestEvent(props: ISendWithdrawRequestEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.WITHDRAWAL_REQUEST, {
            conversionValue: parseFloat(props.withdrawData.amount).toCurrencyValue(),
            currency: props.withdrawData.currency,
        });
    }

    public static sendCreateTeamEvent(props: ISendTeamEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.CREATE_TEAM, {
            teamId: props.teamId
        });
    }

    public static sendUpdateTeamEvent(props: ISendTeamEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.UPDATE_TEAM, {
            teamId: props.teamId
        });
    }

    public static sendTournamentSubscriptionEvent(props: ISendTournamentSubscriptionEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.TOURNAMENT_SUBSCRIBE, {
            tournamentId: props.subscription.tournament,
            teamId: props.subscription.team
        });
    }

    public static sendClaimTournamentRewardEvent(props: ISendClaimTournamentRewardEventProps): void {
        SegmentTracking.getInstance().sendTracking(SEGMENT_EVENTS.CLAIM_TOURNAMENT_REWARD, {
            rewardId: props.data.rewardId,
            conversionValue: props.data.virtualCreditsAmount,
            currency: props.currency
        });
    }
}