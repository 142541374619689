import { ExpandMore } from '@material-ui/icons';
import Application from 'Application';
import CheckoutBanner from 'assets/images/tournament/checkout/background/tournament-checkout-banner-min.jpg';
import clsx from 'clsx';
import TrendexBlueSelect from 'components/small/input/trendex-blue-select/TrendexBlueSelect';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';
import ITournamentTicketCheckoutHeaderProps from './ITournamentTicketCheckoutHeaderProps';
import './style.scss';
import WalletCard from 'components/icons/wallet/card/WalletCard';
import i18n from '18n';

const TournamentTicketCheckoutHeader: React.FC<ITournamentTicketCheckoutHeaderProps> = ({ t: translate, league, totalTokens, prizePool, onLeagueChange, leagueEntryPrice, isDiscountElligible, leagueEntryPriceWithoutDiscount }) => {
    return (
        <div className='tournament-ticket-header'>
            <h2 className='ticket-title'>{translate('tournament.ticket.buy.join', { leagueName: league.Name })}</h2>

            <div className='league-cashprize-banner'>
                <img src={CheckoutBanner} alt="CheckoutBanner" />
                <div className='filter' />
                <p className='no-margin cashprize-title'>{translate('tournament.cashprize')} <span>{league.PrizePoolToCurrency}</span></p>
                <p className='no-margin cashprize-win'>{translate('tournament.ticket.cahprize.awared', { alreadyClaimed: Number(631000).toCurrency() })}</p>
            </div>

            <div className='section-payment-container'>
                <div className="section-payment">
                    <div className='section-left'>
                        <div>
                            <ReactSVG className="leagueIcon" src={league.IconLink} />
                        </div>
                        <div className='select-container'>
                            <div className='select-wrapper'>
                                <TrendexBlueSelect onChange={onLeagueChange} value={league.Name} className='custom-select'>
                                    {Application.getInstance().getLeagues(prizePool).map((leagueEntry: LeagueViewModel) => {
                                        return <option value={leagueEntry.Name}>{leagueEntry.Name}</option>
                                    })}
                                </TrendexBlueSelect>
                            </div>
                            <div>
                                <ExpandMore />
                            </div>
                        </div>

                    </div>
                    <div className='section-right'>
                        <span className={clsx('', {
                            "promo-display": isDiscountElligible
                        })}>{leagueEntryPriceWithoutDiscount.toCurrency()}</span>
                        {isDiscountElligible && <span>{leagueEntryPrice.toCurrency()}</span>}
                    </div>
                </div>

                {isDiscountElligible && <div className="promo-display-disclaimer">
                    <span className='icon'> <WalletCard /></span>
                    <span>{i18n.t('promo.ticket.disclaimer', { totalTokens, discount: league.DiscountAsDisplay })}</span>
                </div>}
            </div>


        </div>
    )
}

export default withTranslation()(TournamentTicketCheckoutHeader)