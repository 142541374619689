import { CURRENT_FOOTBALL_SEASON_YEAR, HTTP_STATUS_INTERNAL_ERROR, HTTP_STATUS_OK } from "config/_const";
import AxiosConfig from "config/axiosConfig";
import { PLAYERS_PARAMS, SPORTS_ENDPOINT,  } from "config/endpoints";
import { showNotification } from "service/notification/action";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { sportsDataApiBaseUrl } from "config/sportsDataConfig";
import { SPORTS_PLAYERS_ACTION } from "./actionTypes";
import i18next from "i18next";

export const getAllSportsPlayersWithTeamId = (teamId: number): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: SPORTS_PLAYERS_ACTION.LOADING });
        const route = `${sportsDataApiBaseUrl}${SPORTS_ENDPOINT.GET_PLAYERS}?${PLAYERS_PARAMS.TEAM_ID}=${teamId}&${PLAYERS_PARAMS.LEAGUE_SEASON}=${CURRENT_FOOTBALL_SEASON_YEAR}`;
        const response = await AxiosConfig.get(route);
        if (!response) {
            showNotification(dispatch, 'Sports : Server is offline, please retry later', HTTP_STATUS_INTERNAL_ERROR);
            return dispatch({
                type: SPORTS_PLAYERS_ACTION.ERROR,
            });
        }
        if (response.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data, response.status);
            return dispatch({
                type: SPORTS_PLAYERS_ACTION.ERROR,
            });
        }
        dispatch({
            type: SPORTS_PLAYERS_ACTION.SUCCESS,
            payload: response.data
        });
    } catch (error) {
        console.log('DataApi error: ', error);
        showNotification(dispatch, 'Sports :'+i18next.t('ERRORS.GENERIC'), HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: SPORTS_PLAYERS_ACTION.ERROR,
        });
    }
};

