import { isEmpty } from 'lodash';
import type { RootState } from 'model/Redux';
import { createSelector, Selector } from 'reselect';
import type { Status } from 'service/storeType';
import TournamentViewModel from 'view-model/Tournament/TournamentViewModel/TournamentViewModel';
import { TournamentProgress } from '../../config/_enums';
import { IInternalTournament, TournamentReducer } from './types';

export const extractTournamentId = (_: RootState, tournamentId: string): string => tournamentId;
export const tournamentStateSelector: Selector<RootState, TournamentReducer> = state => state.tournament;
const tournamentsDataSelector: Selector<RootState, IInternalTournament[]> = (state: RootState) => state.tournament.data;
export const tournamentLoadingSelector: Selector<RootState, boolean> = state => state.tournament.loading;
export const postTournamentStatus = createSelector<RootState, TournamentReducer, Status>(
    tournamentStateSelector,
    state => state.status!.postTournament,
);
export const selectTournament = createSelector(
    tournamentStateSelector,
    extractTournamentId,
    (tournaments: TournamentReducer, tournamentId: string) => {
        if (isEmpty(tournaments.data))
            return null;
        const tournament: IInternalTournament | undefined = tournaments.data.find((tournament: IInternalTournament) => tournament._id === tournamentId);
        if (!tournament)
            return null;

        return new TournamentViewModel(tournament);
    },
);

export const selectCurrentTournamentStatus = createSelector<RootState, TournamentReducer, Status>(
    tournamentStateSelector,
    state => state.status!.getCurrentTournament,
);

export const loadingTournamentSelector = createSelector<RootState, boolean, boolean>(
    tournamentLoadingSelector,
    (loading: boolean) => loading,
);

export const prizePoolSelector = createSelector(
    tournamentsDataSelector,
    (_: RootState, tournamentId: string) => tournamentId,
    (tournaments: IInternalTournament[], tournamentId: string) => {
        const pendingTournament: IInternalTournament | undefined = tournaments.find((tournament: IInternalTournament) => tournament._id === tournamentId);
        return pendingTournament ? pendingTournament.prizePool : 0;
    },
);

export const tournamentByIdSelector = createSelector(
    tournamentsDataSelector,
    extractTournamentId,
    (tournaments: IInternalTournament[], tournamentId: string) => {
        const tournament: IInternalTournament | undefined = tournaments.find((tournament: IInternalTournament) => tournament._id === tournamentId);
        return tournament ? new TournamentViewModel(tournament) : undefined;
    },
);

export const tournamentInProgressSelector = createSelector<RootState, IInternalTournament[], TournamentViewModel | undefined>(
    tournamentsDataSelector,
    (tournaments: IInternalTournament[]) => {
        const tournamentInProgress: IInternalTournament | undefined = tournaments.find((tournament: IInternalTournament) => tournament.tournamentProgress === TournamentProgress.IN_PROGRESS);
        return tournamentInProgress ? new TournamentViewModel(tournamentInProgress) : undefined;
    },
);

export const tournamentByProgressSelector = createSelector(
    tournamentsDataSelector,
    (_: RootState, tournamentProgress: TournamentProgress) => tournamentProgress,
    (tournaments: IInternalTournament[], tournamentProgress: TournamentProgress) => {
        const tournament: IInternalTournament | undefined = tournaments.find((tournament: IInternalTournament) => {
            return tournament && tournament.tournamentProgress === tournamentProgress
        })

        return tournament ? new TournamentViewModel(tournament) : undefined;
    },
);

export const nextTournamentSelector = createSelector(
    tournamentsDataSelector,
    (tournaments: IInternalTournament[]) => {
        const tournament: IInternalTournament | undefined = tournaments.find((tournament: IInternalTournament) => tournament && tournament.tournamentProgress === TournamentProgress.NEXT);
        return tournament ? new TournamentViewModel(tournament) : undefined;
    },
);
