import useSportScoreChart from "hooks/sport-score-chart/useSportScoreChart";
import React from "react";
import ScoreChart from "../ScoreChart";
import i18n from "18n";
import useAssetsCategories from "hooks/asset-categories/useAssetCategories";
import ISportScoreChartProps from "./ISportScoreChartProps";

const SportScoreChart: React.FC<ISportScoreChartProps> = ({ assetVM, statistics }) => {
    const { assetsCategories } = useAssetsCategories();
    const { scores, onChartbarClick } = useSportScoreChart(statistics, assetVM.MainCategoryId);
    return (
        <div className="mixed-chart-score">
            <ScoreChart title={i18n.t("sport.score.chart.title", { sport: assetVM.getCategoryName(assetsCategories) })} onChartbarClick={onChartbarClick} scoreChartVM={scores} />
        </div>
    )
}

export default SportScoreChart;