import * as React from 'react';

const InstagramDuoTone = ({ height = "20px", width = "20px", ...props }: React.SVGProps<SVGSVGElement>) => {

  return (
    <svg height={height} width={width} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g id="Insta" transform="translate(0 -728.5)">
        <g id="Rectangle_2014" data-name="Rectangle 2014" transform="translate(0 728.5)" fill="#8187d0" stroke="#707070" strokeWidth="1" opacity="0">
          <rect width="20" height="20" stroke="none" />
          <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
        </g>
        <path id="instagram_1_" data-name="instagram (1)" d="M8.707,36.1a4.5,4.5,0,1,0,4.5,4.5A4.494,4.494,0,0,0,8.707,36.1Zm0,7.427A2.926,2.926,0,1,1,11.633,40.6a2.932,2.932,0,0,1-2.926,2.926Zm5.735-7.611a1.05,1.05,0,1,1-1.05-1.05A1.047,1.047,0,0,1,14.442,35.918Z" transform="translate(1.295 697.897)" fill="#8187d0" />
        <path id="instagram_1_2" data-name="instagram (1)" d="M17.423,36.983A5.2,5.2,0,0,0,16,33.3a5.23,5.23,0,0,0-3.678-1.418c-1.449-.082-5.794-.082-7.243,0A5.222,5.222,0,0,0,1.4,33.3,5.212,5.212,0,0,0-.013,36.979c-.082,1.449-.082,5.794,0,7.243A5.2,5.2,0,0,0,1.4,47.9a5.236,5.236,0,0,0,3.678,1.418c1.449.082,5.794.082,7.243,0A5.2,5.2,0,0,0,16,47.9a5.23,5.23,0,0,0,1.418-3.678C17.5,42.773,17.5,38.432,17.423,36.983ZM15.55,45.777a2.963,2.963,0,0,1-1.669,1.669c-1.156.458-3.9.353-5.175.353s-4.023.1-5.175-.353a2.963,2.963,0,0,1-1.669-1.669c-.458-1.156-.353-3.9-.353-5.175s-.1-4.023.353-5.175a2.963,2.963,0,0,1,1.669-1.669c1.156-.458,3.9-.353,5.175-.353s4.023-.1,5.175.353a2.963,2.963,0,0,1,1.669,1.669c.458,1.156.353,3.9.353,5.175S16.008,44.626,15.55,45.777Z" transform="translate(1.295 697.897)" fill="#8187d0" opacity="0.4" />
      </g>
    </svg>

  );
}

export default InstagramDuoTone;
