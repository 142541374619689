import IWithdrawalRequest from "./IWithdrawalRequest";
import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import { GET_OPEN, CANCEL } from './actionsType';
import Utils from "utils/Utils";

const ROOT_VALUE_STATE: ReduxRootState<IWithdrawalRequest[]> = {
    loading: false,
    data: []
};

export const userWithdrawalRequestsReducer = (state: ReduxRootState<IWithdrawalRequest[]> = ROOT_VALUE_STATE, action: ReduxActionInterface): ReduxRootState<IWithdrawalRequest[]> => {
    switch (action.type) {
        case GET_OPEN.LOADING:
        case CANCEL.LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_OPEN.SUCCESS:
            const storeData = Utils.removedDuplicateMongoFile(state.data, action.payload.data);

            return {
                ...state,
                loading: false,
                data: storeData.concat(action.payload.data)
            };
        case CANCEL.SUCCESS:
            return {
                ...state,
                loading: false,
                data: state.data.filter(entry => entry._id !== action.payload.data._id)
            };
        case CANCEL.ERROR:
        case GET_OPEN.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.message
            };
        default: return state;
    }
};