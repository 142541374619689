import { PlayCircleOutline } from '@material-ui/icons';
import i18next from 'i18next';
import React from 'react'
import './WatchNowPlayBtn.scss';
import { watchNowPlayBtnProps } from './WatchNowPlayBtnProps';
import clsx from 'clsx';

const WatchNowPlayBtn: React.FC<watchNowPlayBtnProps> = (props) => {
    return (
        <div className={clsx("watchNow-play-btn",
            { "left-padding": !props.showPlayIcon }
        )}>
            {props.showPlayIcon &&
                <PlayCircleOutline className="play-button" />
            }
            <span className="playBtn-text">
                {i18next.t('banner.watchNow')}
            </span>
        </div>
    )
};


export default WatchNowPlayBtn;