import React from "react";
import { AddRounded } from "@material-ui/icons";
import "./tournament-modal-card.scss";
import Trophy from "components/icons/Trophy";

interface Props {
    iconSrc: string;
    iconName?: string;
    rewardAmount: string;
    rewardTextDescription: string;
}

const TournamentModalCard: React.FC<Props> = (props) => {
    const { iconSrc, iconName, rewardAmount, rewardTextDescription } = props;

    return (
        <div className='description-card'>
            <div className="reward-trophy-container">
                <div className="reward-icon">
                    <Trophy/>
                    <img src={iconSrc} alt={iconName || ''} />
                </div>
            </div>
            <div className="plus-sign"><AddRounded /></div>
            <div className="reward-description">
                <span className='reward-amount'>{rewardAmount}</span>
                <span className="reward-text">{rewardTextDescription}</span>
            </div>
        </div>
    )
}

export default TournamentModalCard;