import ModalProps from 'components/medium/modal/ModalProps';
import IUseModal from 'hooks/use-modal/IUseModal';
import useModal from 'hooks/use-modal/useModal';
import React from 'react';

const withModal = (Component: React.FC<ModalProps>) => {
    const MemoComponent: React.FC<ModalProps> = React.memo(Component);

    const WrapperComponent: React.FC = () => {
        const { modal, close, child }: IUseModal = useModal();
        if (!modal.display)
            return <></>;
        return <MemoComponent child={child} open={modal.display} {...modal} close={close} />;
    };

    return React.memo(WrapperComponent);
};

export default withModal;