import ReduxActionInterface from '../../model/ReduxActionInterface';

import * as PaymentActionType from './actionsType';
import ReduxRootState from '../../model/Redux/ReduxRootSate';

const ROOT_VALUE_STATE: ReduxRootState = {
    data: {},
    loading: false
};

export const paymentReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {

        case PaymentActionType.PAYMENT_LOADING:
            return {
                ...state,
                loading: true
            };

        case PaymentActionType.PAYMENT_ERROR:
            if (action.payload) {
                return {
                    ...state,
                    loading: false,
                    error: action.payload.message,
                    status: action.payload.status
                };
            }

            return state;

        case PaymentActionType.PAYMENT_SUCCESS:
            if (action.payload) {

                return {
                    ...state,
                    data: action.payload,
                    loading: false,
                    error: null
                };
            }

            return state;

        case PaymentActionType.PAYMENT_ASSET_STATUS_LOADING:
            return {
                ...state,
                loading: true
            };

        case PaymentActionType.PAYMENT_ASSET_STATUS_ERROR:
            if (action.payload) {
                return {
                    ...state,
                    loading: false,
                    error: action.payload.message,
                    status: action.payload.status
                };
            }

            return state;

        case PaymentActionType.PAYMENT_ASSET_STATUS_SUCCESS:
            if (action.payload) {
                return {
                    ...state,
                    data: action.payload,
                    loading: false,
                    error: null
                };
            }

            return state;


        case PaymentActionType.PAYMENT_TRANSACTION_STATUS_SUCCESS:
            if (action.payload) {
                return {
                    ...state,
                    data: action.payload,
                    loading: false,
                    error: null
                };
            }

            return state;

        case PaymentActionType.PAYMENT_TRANSACTION_STATUS_ERROR:
            if (action.payload) {
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                };
            }

            return state;

        case PaymentActionType.PAYMENT_TRANSACTION_LOADING:
            if (action.payload) {
                return {
                    data: {},
                    // error: action.payload,
                    loading: true,
                };
            }

            return state;


        case PaymentActionType.PAYMENT_INTENT_SUCCESS:
            if (action.payload) {
                return {
                    data: {
                        ...state.data,
                        paymentIntent: action.payload,
                    },
                    loading: false,
                    error: null
                };
            }

            return state;

        case PaymentActionType.PAYMENT_INTENT_ERROR:

            if (action.payload) {
                return {
                    ...state,
                    error: action.payload.message,
                    loading: false,
                };
            }

            return {...state, loading: false};

        case PaymentActionType.PAYMENT_INTENT_LOADING:

            return {
                data: {
                    ...state.data,
                    paymentIntent: {},
                },
                loading: true,
            };

        case PaymentActionType.DROP_PAYMENT_DATA:
            return {
                ...state,
                error: null,
                data: [],
                loading: false
            }
        default: return state;
    }
};
