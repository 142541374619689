import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import Application from 'Application';
import Plus from 'components/icons/Plus';
import Wallet from 'components/icons/wallet/Wallet';
import { TrendexRadio } from 'components/small/CustomRadioButton';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import { COUNTRY, MAX_WITHDRAW_ONBOARDING_WITHOUT_KYC, MINIMUM_REFUND_AMOUNT, VERIFICATION_STATUS, WITHDRAWS_TYPES, WorldRegions } from 'config/_const';
import useAccount from 'hooks/use-account/useAccount';
import IUseUserIdentityVerification from 'hooks/use-user-identity-verification/IUseUserIdentityVerification';
import useUserIdentityVerification from 'hooks/use-user-identity-verification/useUserIdentityVerification';
import IUseWithdraw from 'hooks/use-withdraw-form/IUseWithdrawForm';
import useWithdrawForm from 'hooks/use-withdraw-form/useWithdrawForm';
import i18next from 'i18next';
import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { batch, useDispatch } from 'react-redux';
import { finishOnboarding } from 'service/auth/actions';
import { managementModal } from 'service/modal/action';
import './WithdrawForm.scss';
import IWithdrawFormProps, { IAttributeField } from './WithdrawFormProps';
import PhoneInput from 'react-phone-input-2';
import SecondaryButton from 'components/small/buttons/secondary-button/SecondaryButton';

const WITHDRAW_DATA_NAME = {
    AMOUNT: "amount",
    IBAN: "iban",
    ROUTING_NUMBER: "routingNumber",
    ACCOUNT_NUMBER: "accountNumber",
    BIC_OR_SWIFT: "bicOrSwift",
    BANK_NAME: "bankName",
    BANK_ADDRESS: "bankAddress",
    PHONE: "phone",
    LAST_NAME: "lastName",
    FIRST_NAME: "firstName"
};

const bankFielsdData: IAttributeField[] = [
    { id: "R1_routing", label: i18next.t('withdraw.not-sepa.routing-number'), name: WITHDRAW_DATA_NAME.ROUTING_NUMBER },
    { id: "R2_accountNumber", label: i18next.t('withdraw.not-sepa.accountNumber'), name: WITHDRAW_DATA_NAME.ACCOUNT_NUMBER },
    { id: "R3_bicOrSwift", label: i18next.t('withdraw.not-sepa.bicOrSwift'), name: WITHDRAW_DATA_NAME.BIC_OR_SWIFT },
    { id: "R4_bankName", label: i18next.t('withdraw.not-sepa.bankName'), name: WITHDRAW_DATA_NAME.BANK_NAME },
    { id: "R5_bankAddress", label: i18next.t('withdraw.not-sepa.bankAddress'), name: WITHDRAW_DATA_NAME.BANK_ADDRESS },
];

const WithdrawForm: React.FC<IWithdrawFormProps> = (props) => {
    const dispatch = useDispatch();
    const { accountViewModel } = useAccount();
    const { withdrawViewModel, withdrawForm, onWithdrawFormChange, handleSubmitWithdrawForm, checkOnboardingBeforeWithdraw }: IUseWithdraw = useWithdrawForm({ ...props.initialValues, lastName: accountViewModel.LastName, firstName: accountViewModel.firstName }, accountViewModel);
    const isOnboardingMode = Application.getInstance().OnBoardingV2 && accountViewModel.IsOnboardingRunning;
    const { launchIdentityVerification, pollingRetryCount, maxPollingRetry, stopKYCPolling, isPollingStarted }: IUseUserIdentityVerification = useUserIdentityVerification({ shouldLaunchPolling: Application.getInstance().OnBoardingV2 && accountViewModel.IsOnboardingRunning });

    const showConfirmationPopup = () => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: true,
            showBackArrow: false,
            showCancelButton: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                className: "withdraw-onboarding-cancel-tuto_customModal",
                showCancelButton: true,
                showLinkButton: true,
                urlValidation: '/',
                onRefused: () => {
                    dispatch(managementModal(false));
                },
                onValidation: () => {
                    dispatch(finishOnboarding());
                    dispatch(managementModal(false));
                },
                textValidButton: <Trans i18nKey="genericaccept" />,
                message: <Trans i18nKey={'onboarding.withdraw.cancel.tutorial'} />
            },
        }));
    };

    const onWithdrawFormSubmit = React.useCallback((event: any) => {
        event.preventDefault();
        if (isOnboardingMode)
            return checkOnboardingBeforeWithdraw(launchIdentityVerification);
        return handleSubmitWithdrawForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountViewModel, isOnboardingMode]);

    React.useEffect(() => {
        const defaultKycStatus: VERIFICATION_STATUS = accountViewModel.identityConfirmStatus ?? VERIFICATION_STATUS.PENDING;
        if (!isOnboardingMode || !isPollingStarted || (pollingRetryCount < maxPollingRetry && defaultKycStatus === VERIFICATION_STATUS.PENDING))
            return;

        batch(() => {
            stopKYCPolling();
            dispatch(managementModal(false));
            dispatch(managementModal(true, {
                display: true,
                disableBackDrop: true,
                showBackArrow: false,
                showCancelButton: false,
                fullScreen: false,
                type: 'KYC_RESULT_DIALOG',
                propsCustom: {
                    linkTo: '/',
                    customClassName: accountViewModel.identityConfirmStatus.toLowerCase(),
                    showSecondaryButton: !accountViewModel.isIdentityConfirmed,
                    onPrimaryButtonClick: () => {
                        dispatch(managementModal(false));
                        dispatch(finishOnboarding());
                        if (!accountViewModel.isIdentityConfirmed)
                            window.open(Application.getInstance().whatsAppLink, '_blank');
                    },
                    onSecondaryButtonClick: () => {
                        dispatch(managementModal(false));
                        dispatch(finishOnboarding());
                    },
                }
            }));
        });
    }, [accountViewModel.identityConfirmStatus, accountViewModel.isIdentityConfirmed, dispatch, isOnboardingMode, isPollingStarted, maxPollingRetry, pollingRetryCount, stopKYCPolling]);

    return (
        <div className='withdraw-form-container'>
            <form onSubmit={onWithdrawFormSubmit} id="withdraw-form">
                <div className="field-container withdraw-field">
                    <label htmlFor='withdraw_amount_field' className="input-field-box">
                        <div className="left-part-box">
                            <div className="icon-container">
                                <Plus /><Wallet /><span className='currency-text'>{withdrawForm.currency}</span>
                            </div>
                        </div>
                        <div className="right-part-box">
                            <input
                                value={withdrawForm.amount}
                                type='string'
                                inputMode='decimal'
                                required={true}
                                max={accountViewModel.credits}
                                min={accountViewModel.IsOnboardingRunning ? MAX_WITHDRAW_ONBOARDING_WITHOUT_KYC : 50}
                                onChange={(event: any) => {
                                    const amount = parseFloat(event.target.value);
                                    if (isNaN(event.target.value))
                                        return;
                                    if (amount > accountViewModel.creditsAsNumber)
                                        event.target.value = `${accountViewModel.creditsAsNumber}`;
                                    return onWithdrawFormChange(event);
                                }}
                                name={WITHDRAW_DATA_NAME.AMOUNT}
                                className='amount-field' />

                            <span className='amount-field'>{Application.getInstance().currencySymbol}</span>
                        </div>
                    </label>

                    {withdrawViewModel.ShowAmountErrorAndFees.amountError && !isOnboardingMode &&
                        <p className="display-message incorrect"><FontAwesomeIcon className='icon' icon={faTimesCircle} />{i18next.t('withdraw.minimumToWithdraw', { amount: MINIMUM_REFUND_AMOUNT.toCurrency() })}<br /></p>}
                </div>

                <div className="info">
                    {withdrawViewModel.ShowAmountErrorAndFees.fees && !isOnboardingMode &&
                        <>
                            <span>{i18next.t('withdraw.fees-amount')} {withdrawViewModel.FeesAmount.toCurrency()}</span>
                            {withdrawViewModel.IsSepaForm ?
                                (<span>{i18next.t('withdraw.receivable-amount')} : {withdrawViewModel.Receivable.toCurrency()}</span>) :
                                (<span>{i18next.t('withdraw.total-amount')} {withdrawViewModel.PriceWithFees.toCurrency()}</span>)
                            }
                        </>
                    }
                    <span className='available-amount-info'>{i18next.t('buyAsset.available')} : {accountViewModel.creditsAsNumber.toCurrency()}</span>
                </div>

                <div className="radio-container">

                    <FormControl>
                        <RadioGroup
                            onChange={onWithdrawFormChange}
                            name='zoneType'
                            value={withdrawForm.zoneType}>
                            <div>
                                {[{ label: i18next.t('withdraw.select.sepa'), value: WITHDRAWS_TYPES.SEPA }, { label: i18next.t('withdraw.select.not-sepa'), value: WITHDRAWS_TYPES.NON_SEPA }].map((data: any) =>
                                    <FormControlLabel
                                        key={data.label}
                                        value={`${data.value}`}
                                        control={<TrendexRadio />}
                                        label={data.label} />)}
                            </div>
                        </RadioGroup>
                    </FormControl>
                </div>

                {withdrawViewModel.IsSepaForm
                    ? <>
                        <div className="field-container">
                            <label htmlFor='iban_field' className="input-field-box">
                                <div className="left-part-box">
                                    {i18next.t('withdraw.sepa.iban')}
                                </div>
                                <div className="right-part-box">
                                    <input
                                        id='iban_field'
                                        placeholder='ex:FR7630001007941234567890185'
                                        required={true}
                                        value={withdrawForm.iban}
                                        onChange={onWithdrawFormChange}
                                        disabled={false}
                                        name={WITHDRAW_DATA_NAME.IBAN}
                                        type="text"
                                        className='input-field' />

                                </div>
                            </label>
                        </div>
                    </>
                    :
                    <>
                        {bankFielsdData.map((field: IAttributeField) => <Fragment key={field.id}>
                            <div className="field-container">
                                <label htmlFor={field.id} className="input-field-box">
                                    <div className="left-part-box">
                                        {field.label}
                                    </div>
                                    <div className="right-part-box">
                                        <input
                                            value={(withdrawForm as Record<string, any>)[field.name]}
                                            id={field.id}
                                            onChange={onWithdrawFormChange}
                                            name={field.name}
                                            type="text"
                                            required={true}
                                            className='input-field'
                                        />
                                    </div>
                                </label>
                            </div>
                        </Fragment>
                        )}
                    </>
                }
                <span className='warning'><Trans i18nKey='withdraw.warning' /></span>
                <>;
                    {
                        !accountViewModel.LastName &&
                        <div className='field-container'>
                            <label htmlFor='lastName_field' className="input-field-box">
                                <div className="left-part-box">
                                    {i18next.t('Aboutus.tab.about.lastname')}
                                </div>
                                <div className="right-part-box">
                                    <input
                                        id='lastName_field'
                                        placeholder='Snow'
                                        required={true}
                                        value={withdrawForm.lastName}
                                        onChange={onWithdrawFormChange}
                                        disabled={false}
                                        name={WITHDRAW_DATA_NAME.LAST_NAME}
                                        type="text"
                                        className='input-field' />
                                </div>
                            </label>
                        </div>
                    }
                    {
                        !accountViewModel.firstName &&
                        <div className='field-container'>
                            <label htmlFor='firstName_field' className="input-field-box">
                                <div className="left-part-box">
                                    {i18next.t('Aboutus.tab.about.firstname')}
                                </div>
                                <div className="right-part-box">
                                    <input
                                        id='firstName_field'
                                        placeholder='John'
                                        required={true}
                                        value={withdrawForm.firstName}
                                        onChange={onWithdrawFormChange}
                                        disabled={false}
                                        name={WITHDRAW_DATA_NAME.FIRST_NAME}
                                        type="text"
                                        className='input-field' />
                                </div>
                            </label>
                        </div>
                    }
                    <div className='field-container'>

                        <label htmlFor='phone_field' className="input-field-box">
                            <div className="left-part-box">
                                {i18next.t('Aboutus.tab.about.phone')}
                            </div>
                            <div className="right-part-box">
                                <PhoneInput
                                    country={COUNTRY.FRENCH.toLowerCase()}
                                    regions={WorldRegions}
                                    value={withdrawForm.phone}
                                    onChange={(value: string) => onWithdrawFormChange({ target: { name: WITHDRAW_DATA_NAME.PHONE, value } })} />
                            </div>
                        </label>
                    </div>
                    <p>{i18next.t("withdraw.phone-information")}</p>
                </>

				<div className="button-submit">
					<PrimaryButton
                        disabled={withdrawViewModel.Disabled || parseFloat(withdrawViewModel.WithdrawData.amount) === 0 || parseFloat(withdrawViewModel.WithdrawData.amount) > accountViewModel.creditsAsNumber || (parseFloat(withdrawViewModel.WithdrawData.amount) < MINIMUM_REFUND_AMOUNT && !accountViewModel.IsOnboardingRunning)}
						receiveClickEvent={false}
						type="submit"
						textButton={i18next.t('Myportfolio.transactions.WITHDRAW')}
					/>
					{accountViewModel.IsOnboardingRunning &&
						<SecondaryButton
							disabled={false}
							receiveClickEvent={false}
							textButton={i18next.t('onboarding.skip.step')}
							type={'button'}
							handleClick={showConfirmationPopup} />
					}
				</div>

            </form >
        </div >
    );
};

const WithdrawFormRedux = WithdrawForm;

export default React.memo(WithdrawFormRedux);
