import * as React from 'react';
import { ITournamentProgressBarProps } from './TournamentProgressBar.Props';
import { ReactSVG } from 'react-svg';
import { TournamentLeagueSvg } from 'data/TournamentImages/tournamentImages.data';
import { TournamentLeagues } from 'config/_enums';
import useUserLeague from 'hooks/use-user-league/useUserLeague';
import './TournamentProgressBar.scss'

const TournamentProgressBar: React.FC<ITournamentProgressBarProps> = ({ showLeagueIcon }) => {
    const { userLeagueViewModel } = useUserLeague();
    const svgIcon: string = TournamentLeagueSvg[userLeagueViewModel.LeagueAttribution.Name as TournamentLeagues]

    return (
        <div className="tournament-progress-bar">
            <div
                className='bar__primaryColor'
                style={{ background: userLeagueViewModel.BtnColor }}
            >
                <div className="bar__overlay-filter">
                    <div className="tournament-level-wrapper">
                        {(showLeagueIcon && userLeagueViewModel.LeagueIcon) &&
                            <ReactSVG className="tournament-league-icon" src={svgIcon} />
                        }
                        <span className="tournament-level-score">
                            {userLeagueViewModel.ProgressPercentageFromCurrentToHigherLeague}
                        </span>
                    </div>
                </div>
                <div
                    className="bar__SecondColor-progressBar"
                    style={{
                        width: userLeagueViewModel.ProgressPercentageFromCurrentToHigherLeague,
                        background: userLeagueViewModel.BtnColorSecondary,
                    }}
                >
                </div>
            </div>
        </div>
    );
};

export default React.memo(TournamentProgressBar);
