import { DEFAULT_ID_AS_STRING, YIELD_CONFIG_PER_ASSET } from 'config/_const';
import IYieldConfigPerAsset from 'model/AbTesting/Yield/IYieldConfigPerAsset';
import TransactionViewModel from 'view-model/TransactionViewModel/TransactionViewModel';
import YieldCalculServiceParams from './YieldCalculServiceParams';

export default class YieldCalculService {
    private assetId: string;
    private tokensAmount: number;
    private amountPerYear: number;
    private static readonly percentageOfMaxYield: number = 0.8;
    private config: IYieldConfigPerAsset;
    private totalAmountEarned: number;
    private static readonly DEFAULT_YIELD: IYieldConfigPerAsset = {
        _id: DEFAULT_ID_AS_STRING,
        minYield: 0.022,
        maxYield: 0.22,
    };

    constructor(params: YieldCalculServiceParams) {
        this.assetId = params.assetId;
        this.tokensAmount = params.tokensAmount;
        this.config = this.getYieldConfigPerAsset();
        this.amountPerYear = this.calculYearlyYield(params.assetIRLScore);
        this.totalAmountEarned = this.getTotalAmountEarned(params.transactions);
    }

    public get YieldAmountPerYear(): number {
        return this.amountPerYear;
    }

    public get TotalAmountEarned(): number {
        return this.totalAmountEarned;
    }

    private calculYearlyYield(assetIRLScore: number): number {
        const IRLScoreToRatio = assetIRLScore.divide(100);
        const yieldRatio = this.config.maxYield.multiply(YieldCalculService.percentageOfMaxYield).multiply(IRLScoreToRatio);
        return yieldRatio.multiply(this.tokensAmount);
    }

    private getYieldConfigPerAsset(): IYieldConfigPerAsset {
        const getYieldConfigPerAssetResponse: IYieldConfigPerAsset | undefined = YIELD_CONFIG_PER_ASSET.find((entry: IYieldConfigPerAsset) => entry._id === this.assetId?.toString());
        if (getYieldConfigPerAssetResponse !== undefined)
            return {
                _id: this.assetId,
                minYield: getYieldConfigPerAssetResponse.minYield,
                maxYield: getYieldConfigPerAssetResponse.maxYield
            };
        return {
            _id: this.assetId,
            minYield: YieldCalculService.DEFAULT_YIELD.minYield,
            maxYield: YieldCalculService.DEFAULT_YIELD.maxYield,
        };
    }

    private getTotalAmountEarned(transactions: TransactionViewModel[]): number {
        return transactions.length === 0 ? 0 : transactions.filter((entry: TransactionViewModel) => entry.RelatedAsset?.id === this.assetId).reduce((acc: number, transaction: TransactionViewModel) => acc + transaction.TransactionCapital, 0);
    }
}