import { EmptyObject, EmptyString, SPORTS_STATISTICS_RATIO, ZERO } from "config/_const";
import IDetailedStatistics from "interfaces/score-chart/IDetailedStatistics";
import IBasketballScoreViewModel from "./IBasketballScoreViewModel";

export class BasketballScoreViewModel {
    private assists: number;
    private minutesPlayed: number;
    private rebounds: number;
    private twoPointsScored: number;
    private twoPointsTotal: number;
    private twoPointsAccuracy: string;
    private threePointsScored: number;
    private threePointsTotal: number;
    private threePointsAccuracy: string;
    private offensiveRebounds: number;
    private defensiveRebounds: number;
    private steals: number;
    private turnovers: number;
    private blocks: number;
    private personalFouls: number;
    private freeThrowsAccuracy: number;
    private fieldGoalsAccuracy: string;
    private points: number;
    private timePlayedMultiplier: number;
    private static basketRatio = SPORTS_STATISTICS_RATIO.Basketball;

    public constructor(basketballScore: IBasketballScoreViewModel) {
        const statistics = basketballScore?.statistics ?? EmptyObject;
        this.assists = statistics.assists ?? ZERO;
        this.minutesPlayed = statistics.minutes_played ?? ZERO;
        this.rebounds = statistics.rebounds ?? ZERO;
        this.twoPointsScored = statistics.two_points_scored ?? ZERO;
        this.twoPointsTotal = statistics.two_points_total ?? ZERO;
        this.twoPointsAccuracy = statistics.two_points_accuracy ?? EmptyString;
        this.threePointsScored = statistics.three_points_scored ?? ZERO;
        this.threePointsTotal = statistics.three_points_total ?? ZERO;
        this.threePointsAccuracy = statistics.three_points_accuracy ?? EmptyString;
        this.offensiveRebounds = statistics.offensive_rebounds ?? ZERO;
        this.defensiveRebounds = statistics.defensive_rebounds ?? ZERO;
        this.steals = statistics.steals ?? ZERO;
        this.turnovers = statistics.turnovers ?? ZERO;
        this.blocks = statistics.blocks ?? ZERO;
        this.personalFouls = statistics.personal_fouls ?? ZERO;
        this.freeThrowsAccuracy = statistics.free_throws_accuracy ?? ZERO;
        this.fieldGoalsAccuracy = statistics.field_goals_accuracy ?? EmptyString;
        this.points = statistics.points ?? ZERO;
        this.timePlayedMultiplier = statistics.time_played_multiplier ?? ZERO;
    }

    private calculateScoreFromStatsWithoutMultiplier(): number {
        return Object.entries(BasketballScoreViewModel.basketRatio).map((a: any) => {
            const key: keyof BasketballScoreViewModel = a[0] as keyof BasketballScoreViewModel;
            const statisticsRatio: number = a[1];
            return parseFloat(`${this[key]}`).multiply(statisticsRatio)
        }).reduce((a: number, b: number) => a + b, ZERO);
    }

    public buildDetailedStatistics(): IDetailedStatistics[] {
        return Object.entries(BasketballScoreViewModel.basketRatio).map((a: any) => {
            const key: keyof BasketballScoreViewModel = a[0] as keyof BasketballScoreViewModel;
            const statisticsRatio: number = a[1];
            return {
                label: `sport.score.statistic.basketball.${key.toLowerCase()}`,
                amount: `${this[key]}`,
                points: `${statisticsRatio !== ZERO ? parseFloat(`${this[key]}`).multiply(statisticsRatio) : ZERO}`,
                detailedCalculation: '',
            }
        }).concat([{
            label: 'asset-profile.sport-score.details.basketball.time-played-multiplier.label',
            amount: `${this.MinutesPlayed}`,
            points: `${this.calculateScoreFromStatsWithoutMultiplier()} pts*${this.timePlayedMultiplier.toFixed(2)}`,
            detailedCalculation: this.getCalculTimePlayedMultiplier()
        }])
    };

    public getCalculTimePlayedMultiplier(): string {
        return 'asset-profile.sport-score.details.basketball.time-played-multiplier.calcul';
    }

    public get Assists(): number {
        return this.assists;
    }

    public get MinutesPlayed(): number {
        return this.minutesPlayed;
    }

    public get Rebounds(): number {
        return this.rebounds;
    }

    public get TwoPointsScored(): number {
        return this.twoPointsScored;
    }

    public get TwoPointsTotal(): number {
        return this.twoPointsTotal;
    }

    public get TwoPointsAccuracy(): string {
        return this.twoPointsAccuracy;
    }

    public get ThreePointsScored(): number {
        return this.threePointsScored;
    }

    public get ThreePointsTotal(): number {
        return this.threePointsTotal;
    }

    public get ThreePointsAccuracy(): string {
        return this.threePointsAccuracy;
    }

    public get OffensiveRebounds(): number {
        return this.offensiveRebounds;
    }

    public get DefensiveRebounds(): number {
        return this.defensiveRebounds;
    }

    public get Steals(): number {
        return this.steals;
    }

    public get Turnovers(): number {
        return this.turnovers;
    }

    public get Blocks(): number {
        return this.blocks;
    }

    public get PersonalFouls(): number {
        return this.personalFouls;
    }

    public get FreeThrowsAccuracy(): number {
        return this.freeThrowsAccuracy;
    }

    public get FieldGoalsAccuracy(): string {
        return this.fieldGoalsAccuracy;
    }

    public get Points(): number {
        return this.points;
    }

    public get TimePlayedMultiplier(): number {
        return this.timePlayedMultiplier;
    }
}