import { RESERVE_VALUE } from "config/_const";
import ILiquidityPool from "interfaces/liquidity-pool/ILiquidityPool";
import LiquidityPool from './liquidityPool';
import ILiquidityPoolParams from "interfaces/liquidity-pool/ILiquidityPoolParams";


class DefaultLiquidityPool extends LiquidityPool implements ILiquidityPool {

    constructor(liquidityPool: ILiquidityPoolParams) {
        super(liquidityPool);
    }

    getBuyPrice(xout: number) {
        // Inverse des params
        const x = this.TokenBalance;
        const y = this.TotalBalance;
        let yin = ((y.multiply(xout))).divide(x.subtract(xout)); // yin = (y * xout) / (x + xout); ---eqn (1)
        const x_reserves = x - xout;
        if (x_reserves <= 0) {
            yin = RESERVE_VALUE;
        }
        return yin;
    }

    getSellPrice(xin: number) {
        // Inverse des params
        const x = this.TokenBalance;
        const y = this.TotalBalance;
        let yout = ((y.multiply(xin))).divide(x.addition(xin)); // yout = (y * xin) / (x + xin); ---eqn (2)
        const y_reserves = y - yout;
        if (y_reserves <= 0) {
            yout = RESERVE_VALUE;
        }
        return yout;
    }

    getMaxTokenWithAvailableCredit(yin: number) {
        const x = this.TokenBalance;
        const y = this.TotalBalance;
        let xout = (yin.multiply(x)).divide(y.addition(yin));  //xout = (yin * x) / (y + yin); ---from eqn (1) 
        return Math.floor(xout);
    }
}

export default DefaultLiquidityPool;