import ReduxActionInterface from '../../model/ReduxActionInterface';
import { GET_ENVIRONMENT_SUCCESS, GET_ENVIRONMENT_ERROR, GET_ENVIRONMENT_LOADING } from './actionTypes'
import ReduxRootState from '../../model/Redux/ReduxRootSate';

const ROOT_VALUE_STATE: ReduxRootState = {
    data: {},
    loading: false
};

export const systemReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    switch (action.type) {
        case GET_ENVIRONMENT_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                data: action.payload.data,

            }
        case GET_ENVIRONMENT_ERROR:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }

}