import Application from 'Application';
import IWithdrawFormInitialValues from 'components/medium/form/withdraw-form/IWithdrawFormInitialValues';
import { G_TAGS_KNOWN_EVENTS, MINIMUM_REFUND_AMOUNT, WITHDRAWS_TYPES, ZERO } from 'config/_const';
import useSlidingPages from 'hooks/use-sliding-pages/useSlidingPages';
import { omit } from 'lodash';
import IWithdrawData from 'model/Withdraw/IWithdrawData';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { actionUpdateUserInformation, finishOnboarding } from 'service/auth/actions';
import { managementModal } from 'service/modal/action';
import { SegmentTracking } from 'service/segment/SegmentTracking';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import IWithdrawFeeStoreData from 'service/withdraw-fee/IWithdrawFeeStoreData';
import { loadWithdrawFeesRate } from 'service/withdraw-fee/actions';
import { withdrawFeeDataSelector } from 'service/withdraw-fee/selector';
import { createWithdrawRequest } from 'service/withdraws/actions';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import WithdrawViewModel from 'view-model/WithdrawViewModel/WithdrawViewModel';
import IUseWithdraw from './IUseWithdrawForm';
import IOwnInformations from './IOwnInformations';

const getWithdrawFormInitialValues = (): IWithdrawFormInitialValues => ({
    amount: MINIMUM_REFUND_AMOUNT.toCurrencyValue().toString(),
    iban: "",
    routingNumber: "",
    accountNumber: "",
    bicOrSwift: "",
    bankName: "",
    bankAddress: "",
    currency: "",
    phone: "",
    firstName: "",
    lastName: "",
    country: "",
    zoneType: 'SEPA'
});

const useWithdrawForm = (withdrawData: Partial<IWithdrawFormInitialValues>, accountVM: AccountViewModel): IUseWithdraw => {
    const dispatch = useDispatch();
    const withdrawFeeData: IWithdrawFeeStoreData = useSelector(withdrawFeeDataSelector);
    const { hideSlidingPage } = useSlidingPages();
    const [showAmountErrorAndFees, setShowAmountErrorAndFees] = useState({ amountError: false, fees: false });
    const [withdrawForm, setWithdrawForm] = React.useState<IWithdrawFormInitialValues>({ ...getWithdrawFormInitialValues(), ...withdrawData });
    const withdrawViewModel = useMemo(() => new WithdrawViewModel({
        withdrawData: {
            amount: withdrawForm.amount ?? MINIMUM_REFUND_AMOUNT.toCurrencyValue(),
            iban: withdrawForm.iban ?? "",
            routingNumber: withdrawForm.routingNumber ?? "",
            accountNumber: withdrawForm.accountNumber ?? "",
            bicOrSwift: withdrawForm.bicOrSwift ?? "",
            bankName: withdrawForm.bankName ?? "",
            bankAddress: withdrawForm.bankAddress ?? "",
            currency: accountVM.Currency ?? "",
            country: withdrawForm.country ?? "",
            phone: withdrawForm.phone.replaceAll(" ", ""),
            firstName: withdrawForm.firstName || accountVM.firstName || "",
            lastName: withdrawForm.lastName || accountVM.LastName || "",
            zoneType: withdrawForm.zoneType || WITHDRAWS_TYPES.SEPA
        },
        showAmountErrorAndFees,
        withdrawFeeData,
    }), [withdrawForm.amount, withdrawForm.iban, withdrawForm.routingNumber, withdrawForm.accountNumber, withdrawForm.bicOrSwift, withdrawForm.bankName, withdrawForm.bankAddress, withdrawForm.country, withdrawForm.phone, withdrawForm.firstName, withdrawForm.lastName, withdrawForm.zoneType, accountVM.Currency, accountVM.firstName, accountVM.LastName, showAmountErrorAndFees, withdrawFeeData]);

    React.useEffect(() => {
        setWithdrawForm((prev) => ({
            ...prev,
            amount: withdrawData.amount?.toString() || "0"
        }));
    }, [withdrawData.amount]);

    const onWithdrawFormChange = (event: any) => {
        event.persist && event.persist();
        setWithdrawForm((prev) => ({
            ...prev,
            [event.target.name]: `${event.target.value}`.replaceAll(" ", "")
        }));
    };

    const onConfirmOnboardingWithdraw = () => {
        dispatch(finishOnboarding());
        dispatch(managementModal(false));
    };

    const checkOnboardingBeforeWithdraw = (launchIdentityVerification: () => void) => {
        if (withdrawViewModel.requiresKYCForWithdrawal() || accountVM.RequireKycForFirstWithdrawal) {
            launchIdentityVerification();
            return;
        }

        handleSubmitWithdrawForm();
        return dispatch(managementModal(true, {
            display: true,
            disableBackDrop: true,
            showBackArrow: false,
            showCancelButton: false,
            fullScreen: false,
            type: 'CONFIRM',
            closeByEscape: false,
            propsCustom: {
                className: "withdraw-onboarding-confirm_customModal",
                showCancelButton: false,
                showLinkButton: false,
                onValidation: () => onConfirmOnboardingWithdraw(),
                textValidButton: <Trans i18nKey="onboarding.withdraw.popup.confirm" />,
                message: (
                    <strong>
                        <Trans i18nKey={'onboarding.withdraw.popup'} />
                    </strong>
                ),
            },
        }));
    };

    const onWithdrawSuccessCallback = React.useCallback(() => {
        if (withdrawForm.phone || withdrawForm.firstName || withdrawForm.lastName)
            updateUserInformations({ phone: withdrawForm.phone.replaceAll(" ", ""), firstName: withdrawForm.firstName, lastName: withdrawForm.lastName });
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.WITHDRAWAL_REQUEST, { conversionValue: withdrawData.amount, currency: withdrawData.currency });
        SegmentTracking.sendWithdrawRequestEvent({ withdrawData: withdrawViewModel.WithdrawData });
        hideSlidingPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withdrawData.amount, withdrawData.currency, withdrawViewModel.WithdrawData]);

    const confirmForm = React.useCallback((formData: IWithdrawData) => {
        dispatch(createWithdrawRequest({ ...formData, isSepaWithdrawRequest: withdrawViewModel.IsSepaForm, extraFees: !withdrawViewModel.IsSepaForm && accountVM.IsEuMember }, onWithdrawSuccessCallback));
        return dispatch(managementModal(false));
    }, [accountVM.IsEuMember, dispatch, onWithdrawSuccessCallback, withdrawViewModel.IsSepaForm]);

    const updateUserInformations = (data: IOwnInformations) => {
        dispatch(actionUpdateUserInformation(data, true));
    };

    const handleSubmitWithdrawForm = () => {
        const newForm: IWithdrawData = omit(withdrawForm, 'zoneType', 'phone');
        confirmForm(newForm);
    };

    useEffect(() => {
        const isSepaForm: boolean = withdrawViewModel.IsSepaForm;

        batch(() => {
            setWithdrawForm((prev) => ({
                ...prev,
                iban: isSepaForm ? prev.iban : '',
                ...(isSepaForm && {
                    routingNumber: "",
                    accountNumber: "",
                    bicOrSwift: "",
                    bankName: "",
                    bankAddress: "",
                })
            }));
            dispatch(loadWithdrawFeesRate({
                currency: withdrawViewModel.WithdrawData.currency,
                isSepaWithdrawRequest: isSepaForm,
                iban: withdrawViewModel.WithdrawData.iban,
                bicOrSwift: withdrawViewModel.WithdrawData.bicOrSwift,
            }));
        });
    }, [dispatch, withdrawViewModel.IsSepaForm, withdrawViewModel.WithdrawData.bicOrSwift, withdrawViewModel.WithdrawData.currency, withdrawViewModel.WithdrawData.iban]);

    useEffect(() => {
        const amount: number = Number(withdrawViewModel.WithdrawData.amount);
        setShowAmountErrorAndFees(prev => ({
            ...prev,
            amountError: (Application.getInstance().OnBoardingV2 && accountVM.IsOnboardingRunning) ? false : amount > ZERO && amount < MINIMUM_REFUND_AMOUNT.toCurrencyValue(),
            fees: !(MINIMUM_REFUND_AMOUNT.toCurrencyValue() > amount),

        }));
    }, [accountVM.IsOnboardingRunning, withdrawViewModel.WithdrawData.amount]);


    return {
        handleSubmitWithdrawForm,
        checkOnboardingBeforeWithdraw,
        withdrawViewModel,
        withdrawForm,
        onWithdrawFormChange,
    };
};

export default useWithdrawForm;
