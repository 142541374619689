import i18n from '18n';
import Clock from 'components/icons/Clock';
import ITournamentWidgetProps from 'components/medium/tournament/off/tournament-widget/ITournamentWidgetProps';
import React from 'react';
import Countdown, { CountdownTimeDelta } from 'react-countdown';
import TournamentViewModel from 'view-model/Tournament/TournamentViewModel/TournamentViewModel';

const useTournamentWidgetContent = (props: ITournamentWidgetProps) => {
    const memoTournament: TournamentViewModel = React.useMemo(() => props.tournament, [props.tournament]);

    const customTournamentCountdown = (args: CountdownTimeDelta, showOnlyCountdown: boolean): JSX.Element => {
        if (args.completed) {
            window.location.href = '/tournament';
        }

        const displayRemainingTime = (times: CountdownTimeDelta): string => {
            const { hours, minutes, seconds, days } = times;
            if (days === 0 && hours === 0 && minutes < 1)
                return `${seconds.toString().padStart(2, '0')}s`;
            if (days === 0)
                return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`;
            return `${days.toString().padStart(2, '0')}${i18n.t('day.short')} ${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`;
        };

        return (showOnlyCountdown ?
            <>
                {displayRemainingTime(args)}
            </>
            :
            <div className="countdown-start-tournament">
                <p className="text no-margin">{i18n.t('tournament-starts-in')}</p>
                <p className='countdown no-margin'>{!showOnlyCountdown && <Clock />}{displayRemainingTime(args)} <span>{i18n.t('time.left')}</span></p>
            </div >);
    };

    const renderCountdown = (showOnlyCountdown: boolean): JSX.Element => {
        const date: string = memoTournament.isOpen() ? memoTournament.EndDate : memoTournament.SubscriptionEndDate;
        return <Countdown
            date={new Date(date)}
            precision={0}
            renderer={(CountdownTimeDelta) => customTournamentCountdown(CountdownTimeDelta, showOnlyCountdown)} />;
    };

    return {
        renderCountdown,
    };
};

export default useTournamentWidgetContent;
