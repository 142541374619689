import { ZERO } from 'config/_const';
import IFootballStatisticsFoul from 'interfaces/sports/football/statistics/IFootballStatisticsFoul';

export default class FootballStatisticsFoulViewModel {
    private drawn: number;
    private committed: number;

    constructor(statistics: Partial<IFootballStatisticsFoul>) {
        this.drawn = statistics.drawn ?? ZERO;
        this.committed = statistics.committed ?? ZERO;
    }

    get Drawn(): number {
        return this.drawn;
    }

    get Committed(): number {
        return this.committed;
    }
}
