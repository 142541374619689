import { isEmpty } from 'lodash';
import AssetInterface from 'model/AssetModel/AssetInterface';
import ITournamentEvolution, { IEvolution } from 'model/Tournament/ITournamentEvolution';
import { filterEvolutionsFromDate } from 'service/score-history/filter';
import { calculAssetScoreEvolution } from 'service/score-history/helper';
import { sortedEvolutionsByMostRecentDate } from 'service/score-history/sorters';
import AssetViewModel from './AssetViewModel';

export default class AssetTournamentViewModel extends AssetViewModel {
    private snapshotsIRLScoreEvolution: IEvolution[];
    private irlScoreFromLastSnapshot: number;

    constructor(asset: AssetInterface, snapshots: ITournamentEvolution | undefined) {
        super(asset);
        this.snapshotsIRLScoreEvolution = snapshots ? snapshots.evolution : [];
        this.irlScoreFromLastSnapshot = this.getIRLScoreFromLastSnapshot();
    }

    public get SnapshotsIRLScoreEvolution(): IEvolution[] {
        return this.snapshotsIRLScoreEvolution;
    }

    public get IrlScoreFromLastSnapshot(): number {
        return this.irlScoreFromLastSnapshot;
    }

    public scoreEvolutionFromDate(startDate: string, endDate?: string, considerIRLScore: boolean = true): number {
        if (this.SnapshotsIRLScoreEvolution.length === 0)
            return 0;
        const assetScoreSnapshots: IEvolution[] = filterEvolutionsFromDate(this.SnapshotsIRLScoreEvolution, startDate, endDate ?? new Date().toISOString());
        return calculAssetScoreEvolution(this, assetScoreSnapshots, considerIRLScore);
    }

    public getIRLScoreFromLastSnapshot(): number {
        if (isEmpty(this.SnapshotsIRLScoreEvolution))
            return 0;
        const snapshotsSortedByMostRecentDate: IEvolution[] = sortedEvolutionsByMostRecentDate([...this.SnapshotsIRLScoreEvolution]);
        return snapshotsSortedByMostRecentDate[0]?.score ?? 0;
    }

    public getIRLScoreByTournamentState(tournamentIsClosed: boolean): number {
        return !tournamentIsClosed ? this.IRLScore : this.IrlScoreFromLastSnapshot;
    }
}
