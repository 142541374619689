import NoNextTournament from 'components/medium/tournament/off/no-next-tournament/NoNextTournament';
import ITournamentWidgetProps from 'components/medium/tournament/off/tournament-widget/ITournamentWidgetProps';
import TournamentWidgetContentLoading from 'components/medium/tournament/off/tournament-widget/tournament-widget-content/tournament-widget-content-loading/TournamentWidgetContentLoading';
import IUseTournament from 'hooks/tournament/use-tournament/IUseTournament';
import useTournament from 'hooks/tournament/use-tournament/useTournament';
import React from 'react';

const withTournamentWidget = (Component: React.FC<ITournamentWidgetProps>) => {
    const MemoComponent = React.memo(Component);

    const Wrapper = () => {
        const { tournamentLoading, nextTournament, tournamentInProgress }: IUseTournament = useTournament();

        if (tournamentLoading)
            return <TournamentWidgetContentLoading />;
        if (tournamentInProgress)
            return <MemoComponent tournament={tournamentInProgress} />;
        if (nextTournament)
            return <MemoComponent tournament={nextTournament} />;
        return <NoNextTournament />;
    };

    return React.memo(Wrapper);
};

export default withTournamentWidget;
