import { Capacitor } from '@capacitor/core';
import { DialogProps } from '@material-ui/core';
import clsx from 'clsx';
import CancelButton from 'components/small/CancelButton/CancelButton';
import DynamicArrowBack from 'components/small/arrowBack/DynamicArrowBack';
import withModal from 'hoc/with-modal/withModal';
import React from 'react';
import './Modal.scss';
import type ModalProps from './ModalProps';
import StyledNativeDialog from './StyledNativeDialog';
import { MODAL_COLOR } from 'config/_const';

const Modal: React.FC<ModalProps> = (props) => {
    const dialogProps: DialogProps = {
        fullScreen: props.fullScreen ?? false,
        fullWidth: true,
        disableBackdropClick: props.disableBackDrop,
        onBackdropClick: (event: React.SyntheticEvent<{}, Event>) => props.onBackdropClick ? props.onBackdropClick(event) : !props.disableBackDrop && props.close(),
        open: true,
        classes: {
            paperFullWidth: props.fullWidth ? 'dialog-full-width' : '',
            paper: 'center',
        },
        className: clsx('', {
            'full-screen-modal': props.fullScreen && props.showBackArrow,
            'modal-at-bottom': props.showModalAtBottom,
            'modal-bg-blur': props.showModalBgBlur,
        }),
        PaperProps: {
            style: {
                borderRadius: !props.fullScreen ? '16px' : 'initial',
                backgroundColor: props.bgColor ? props.bgColor : MODAL_COLOR.LIGHT,
                color: props.textColor ? props.textColor : MODAL_COLOR.DARK,
            },
        },
        disableEscapeKeyDown: !props.closeByEscape,
    };
    const isNative: boolean = Capacitor.isNativePlatform();

    return (
        <div className={`custom-dialog ${props.className}`}>
            <StyledNativeDialog {...props} {...dialogProps} isNative={isNative} onClick={() => props.closeByBodyClick && props.close()}>
                <div className="body-modal">
                    {!(props.showCancelButton === false) && (
                        <CancelButton close={() => props.close()} isPositionRight={true} />
                    )}
                    {props.showBackArrow && <DynamicArrowBack close={() => props.close()} />}
                    <props.child {...props.propsCustom} />
                </div>
            </StyledNativeDialog>
        </div>
    );
};

export default withModal(Modal);
