import React from 'react';
import i18next from 'i18next';
import ScheduledOrderProps from './ScheduledOrderProps';
import FormScheduledOrder from '../../form/vip/form-scheduled-order/FormScheduledOrder';
import ScrReady from '../../../../assets/images/lancement.png';
import clsx from 'clsx';
import './ScheduledOrder.scss';

const ScheduledOrder: React.FC<ScheduledOrderProps> = (props) => {

    const remainTokens = props.totalSupply - props.initialSupply;
    return (
        <div className={clsx('start-private-sell-base start-private-sell-light', {})}>
            <div className="summary-container">
                <h2 className="title-first-accordion">
                    {i18next.t('MySales.FormScheduledOrder.title')}
                </h2>
            </div>
            <img src={ScrReady} alt="Ready to start ico" />
            <h2 className="title-start-ico">{i18next.t('MySales.FormScheduledOrder.subtitle')}</h2>
            <p>{i18next.t('MySales.FormScheduledOrder.paragraph1.part1')} <b style={{ color: '#fa5073' }}>{remainTokens} </b> {i18next.t('MySales.FormScheduledOrder.paragraph1.part2')}</p>
            <p className="second-text-start-ico">{i18next.t('MySales.FormScheduledOrder.paragraph2')}</p>
            <div className="container-form-private-sell">
                <FormScheduledOrder
                    totalSupply={props.totalSupply}
                    minAssets={props.minAssets}
                    initialValues={{
                        initialSupply: props.minAssets,
                    }}
                    initialSupply={props.initialSupply}
                    buttonText={i18next.t('MySales.FormScheduledOrder.form.define')}
                    createScheduledOrder={props.createScheduledOrder} />
            </div>
        </div>
    );

};

export default ScheduledOrder;
