import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import i18n from '18n';
import './style.scss';

const TeamsOverviewLoading: React.FC = () => {
    return (
        <div className="teams-overview-loading">
            <h3 className="bold black-color label-team">{i18n.t('my-team')}</h3>
            <div className="teams-overview-loading-items">
                <Skeleton animation="wave" className="team-members" variant="rect" />
                <Skeleton animation="wave" className="team-members" variant="rect" />
                <Skeleton animation="wave" className="team-members" variant="rect" />
                <Skeleton animation="wave" className="team-members" variant="rect" />
            </div>
        </div>
    );
};

export default TeamsOverviewLoading;
