import { Button } from '@material-ui/core';
import clsx from 'clsx';
import CommonBottomSheet from 'components/medium/bottom-sheet/CommonBottomSheet';
import WalletSheet from 'components/medium/wallet/WalletSheet';
import CommonSlidingPane from 'components/small/common-sliding-pane/CommonSlidingPane';
import { COLOR } from 'config/_const';
import withTopMenuWidget from 'hoc/with-top-menu-widget/withTopMenuWidget';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import Wallet from '../../../../icons/wallet/Wallet';
import ITopMenuWidgetProps from './ITopMenuWidgetProps';
import "./top-menu-widget.scss";
import TournamentLevelButton from 'components/medium/tournament-level-button/TournamentLevelButton';

const TopMenuWidget: React.FC<ITopMenuWidgetProps> = ({ walletViewModel, portfolioTotalValue, accountViewModel }) => {
    const [isWalletOpen, setWalletOpen] = React.useState<boolean>(false);
    const handleClick = () => setWalletOpen(true)
    const isMobile = useIsMobile();

    return (
        <div className="customize-menu">
            <TournamentLevelButton />
            <Button
                className={clsx('button-menu', {
                    'focus-button': isWalletOpen,
                })}
                focusVisibleClassName="focus-button"
                aria-haspopup="true"
                variant="text"
                onClick={handleClick}
            >
                <span className="total-credits plus-jakarta-bold-font">{portfolioTotalValue.toCurrency()}</span> &nbsp;
                <Wallet color={COLOR.PRIMARY_VIOLET} />
            </Button>
            {isMobile ?
                <CommonBottomSheet open={isWalletOpen} onDismiss={() => setWalletOpen(false)}>
                    <WalletSheet
                        onClose={() => setWalletOpen(false)}
                        walletViewModel={walletViewModel}
                        accountViewModel={accountViewModel}
                        isSidebar={false} />
                </CommonBottomSheet> :
                <CommonSlidingPane isOpen={isWalletOpen} onRequestClose={() => setWalletOpen(false)} className="wallet-side-pane" hideHeader={true}>
                    <WalletSheet
                        onClose={() => setWalletOpen(false)}
                        walletViewModel={walletViewModel}
                        accountViewModel={accountViewModel}
                        isSidebar />
                </CommonSlidingPane>
            }
        </div>
    );
};

export default withTopMenuWidget(TopMenuWidget);
