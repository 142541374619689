import React from "react";
import './ScoreBoosterValues.scss';
import IScoreBoosterValuesProps from "./IScoreBoosterValuesProps";

const ScoreBoosterValue: React.FC<IScoreBoosterValuesProps> = (props) => {
    return (
        <div className="boost-detail-container flex-center">
            <span className="score">
                {props.scoreBoosterVM.CurrentScore}
            </span>
            <span className="right-chevron-arrow">
                <i className="fa fa-chevron-right transparent"></i>
                <i className="fa fa-chevron-right transparent"></i>
                <i className="fa fa-chevron-right"></i>
            </span>
            <span className="score">
                {props.scoreBoosterVM.SimulateBoostedScore}
            </span>
        </div>)
}

export default ScoreBoosterValue;