import { RootState } from 'model/Redux';
import { useSelector } from 'react-redux';
import { getTournamentResult } from 'service/tournamentResult/actions';
import { tournamentResultsLoadingSelector, tournamentResultsSelector } from 'service/tournamentResult/selector';
import TournamentResultViewModel from 'view-model/Tournament/TournamentResultViewModel/TournamentResultViewModel';
import IUseTournamentResults from './IUseTournamentResults';
import IUseTournamentResultParams from './IUseTournamentResultsParams';

const useTournamentResults = ({ tournamentId }: IUseTournamentResultParams): IUseTournamentResults => {
    const tournamentResults: TournamentResultViewModel[] = useSelector((state: RootState) => tournamentResultsSelector(state, tournamentId));
    const tournamentResultsLoading: boolean = useSelector(tournamentResultsLoadingSelector);

    return {
        tournamentResults,
        tournamentResultsLoading,
        fetchTournamentResults: getTournamentResult
    };
};

export default useTournamentResults;
