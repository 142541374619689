import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import StripeConfig from '../../../config/stripe';
import { Elements } from '@stripe/react-stripe-js';
import PaymentRequestButton from './PaymentRequestButton';
import { IPaymentWalletCheckoutFormProps } from './IPaymentWalletCheckoutFormProps';

const stripePromise = loadStripe(StripeConfig.publicKey);

const PaymentWalletCheckoutForm: React.FC<IPaymentWalletCheckoutFormProps> = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentRequestButton {...props}></PaymentRequestButton>
        </Elements>
    );
};

export default PaymentWalletCheckoutForm;