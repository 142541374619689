import { DEFAULT_SIGNUP_SOURCE_AND_ID } from "config/_const";

const UTM_PARAMS_NAME = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_id'];
const LEGACY_UTM_PARAMS = {
    signupSource: 's',
    signupId: 'i'
};

export default class SignupSourcesViewModel {
    private signupSourceChannel: string | null;
    private signupSourceId: string | null;

    constructor() {
        this.signupSourceChannel = null;
        this.signupSourceId = null;
        const legacySignupSource: string | null = localStorage.getItem(LEGACY_UTM_PARAMS.signupSource);
        const legacySignupSourceId: string | null = localStorage.getItem(LEGACY_UTM_PARAMS.signupId);
        if (legacySignupSource && legacySignupSourceId) {
            this.signupSourceChannel = legacySignupSource;
            this.signupSourceId = legacySignupSourceId;
        } else {
            const paramsFromLocalStorage: any = {};
            for (const paramName of UTM_PARAMS_NAME)
                paramsFromLocalStorage[paramName] = localStorage.getItem(paramName);
            if (paramsFromLocalStorage['utm_source'] && paramsFromLocalStorage['utm_medium'])
                this.signupSourceChannel = `${paramsFromLocalStorage['utm_source']}_${paramsFromLocalStorage['utm_medium']}`;
            if (paramsFromLocalStorage['utm_campaign'] && paramsFromLocalStorage['utm_id'])
                this.signupSourceId = `${paramsFromLocalStorage['utm_campaign']}_${paramsFromLocalStorage['utm_id']}`;
        }
    }

    public get SignupSourceChannel(): string {
        return this.signupSourceChannel ?? this.defaultSignupSources.signupSourceChannel;
    }

    public get SignupSourceId(): string {
        return this.signupSourceId ?? this.defaultSignupSources.signupSourceId;
    }

    public get defaultSignupSources(): { signupSourceChannel: string, signupSourceId: string } {
        const defaultResponse = {
            signupSourceChannel: DEFAULT_SIGNUP_SOURCE_AND_ID.SIGNUP_SOURCE_CHANNEL,
            signupSourceId: DEFAULT_SIGNUP_SOURCE_AND_ID.SIGNUP_SOURCE_ID
        };

        if (!document.referrer)
            return defaultResponse;

        const referrerUrl = new URL(document.referrer);
        const referrerDomain = referrerUrl.hostname;

        if (document.referrer === referrerDomain)
            return defaultResponse;

        return {
            signupSourceChannel: referrerDomain,
            signupSourceId: referrerDomain
        };
    }
}