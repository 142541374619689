import React from 'react';

const EuroCup = ({ height = 20, width = 25 }: React.SVGProps<SVGSVGElement>) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_2646" data-name="Rectangle 2646" width={width} height={height} fill="none" />
                </clipPath>
                <clipPath id="clip-path-2">
                    <path id="Tracé_4240" data-name="Tracé 4240" d="M125.857,163.933c-1.152.576-.874,2.344-.685,3.017a1.906,1.906,0,0,1,.629-.461,2.15,2.15,0,0,1,.258-1.914.794.794,0,0,1,1.1.112,5.23,5.23,0,0,0-.055-.713,1.629,1.629,0,0,0-.7-.154,1.214,1.214,0,0,0-.541.114" transform="translate(-124.999 -163.819)" fill="#ebf0f3" />
                </clipPath>
                <clipPath id="clip-path-3">
                    <path id="Tracé_4241" data-name="Tracé 4241" d="M527.614,163.973a5.216,5.216,0,0,0-.055.713.794.794,0,0,1,1.1-.112,2.151,2.151,0,0,1,.258,1.914,1.9,1.9,0,0,1,.629.461c.189-.672.467-2.441-.685-3.017a1.214,1.214,0,0,0-.541-.114,1.628,1.628,0,0,0-.7.154" transform="translate(-527.559 -163.819)" fill="#ebf0f3" />
                </clipPath>
            </defs>
            <g id="cup" transform="translate(9873.146 -16269.872)">
                <rect id="Rectangle_2649" data-name="Rectangle 2649" width={width} height={height} transform="translate(-9873.146 16269.872)" fill="#fff" opacity="0" />
                <g id="Groupe_6696" data-name="Groupe 6696" transform="translate(-9868.143 16271.009)">
                    <g id="Groupe_6691" data-name="Groupe 6691" transform="translate(0 0.003)">
                        <g id="Groupe_6690" data-name="Groupe 6690" clip-path="url(#clip-path)">
                            <path id="Tracé_4171" data-name="Tracé 4171" d="M103.636,5.622c-.369-1.056-1.489-1.152-1.962-1.358s-.377-2.184-.232-2.4c.166-.246.814-.178,1.092-.255.383-.107.551-1.349.423-1.468A14.566,14.566,0,0,0,99.97,0a14.532,14.532,0,0,0-2.986.155c-.127.12.049,1.361.432,1.466.278.076.926,0,1.094.249.146.215.253,2.193-.218,2.4s-1.591.311-1.954,1.37a8.811,8.811,0,0,0,.669,5.622c.775,1.653,1.854,2.392,1.748,3.228-.171,1.364-1.6,2.106-1.334,2.576.283.5,1.931.653,2.6.652s2.319-.167,2.6-.668c.264-.472-1.17-1.2-1.35-2.568-.111-.836.964-1.581,1.73-3.239a8.811,8.811,0,0,0,.633-5.626" transform="translate(-94.976 -0.003)" fill="#ebf0f3" fill-rule="evenodd" />
                            <path id="Tracé_4172" data-name="Tracé 4172" d="M103.636,5.622c-.369-1.056-1.489-1.152-1.962-1.358s-.377-2.184-.232-2.4c.166-.246.814-.178,1.092-.255.383-.107.551-1.349.423-1.468A14.566,14.566,0,0,0,99.97,0a14.532,14.532,0,0,0-2.986.155c-.127.12.049,1.361.432,1.466.278.076.926,0,1.094.249.146.215.253,2.193-.218,2.4s-1.591.311-1.954,1.37a8.811,8.811,0,0,0,.669,5.622c.775,1.653,1.854,2.392,1.748,3.228-.171,1.364-1.6,2.106-1.334,2.576.283.5,1.931.653,2.6.652s2.319-.167,2.6-.668c.264-.472-1.17-1.2-1.35-2.568-.111-.836.964-1.581,1.73-3.239a8.811,8.811,0,0,0,.633-5.626" transform="translate(-94.976 -0.003)" fill="#ebf0f3" fill-rule="evenodd" />
                            <path id="Tracé_4173" data-name="Tracé 4173" d="M101.607,19.9c0,.417-1.066.514-1.584.515s-1.585-.072-1.587-.506c0-.217.4-.988,1.582-.992s1.588.766,1.589.982m.662-6.972c-.21,1.147-1.228,2.634-2.261,2.638s-2.06-1.479-2.275-2.624A9.7,9.7,0,0,1,97.8,8.812c.214-.644,1.2-1.165,1.3-1.282a4.82,4.82,0,0,0,.265-1.939c0-.27.479-.409.609-.41s.611.136.612.406a4.838,4.838,0,0,0,.277,1.937c.1.117,1.088.632,1.307,1.275a9.725,9.725,0,0,1,.1,4.125m1.367-4.256c-.369-1.056-1.489-1.152-1.962-1.358s-.377-2.184-.232-2.4c.166-.246.814-.178,1.092-.255.383-.107.551-1.349.423-1.468a3.328,3.328,0,0,0-.866-.1c-.206-.008-.067,1.319-.342,1.4a11.9,11.9,0,0,1-1.775.109,11.877,11.877,0,0,1-1.775-.1c-.274-.074-.145-1.4-.35-1.393a3.336,3.336,0,0,0-.866.106c-.127.12.049,1.361.432,1.466.278.076.926,0,1.094.249.146.215.253,2.193-.218,2.4s-1.591.311-1.954,1.37a8.812,8.812,0,0,0,.669,5.622c.775,1.653,1.854,2.392,1.748,3.228-.171,1.364-1.6,2.106-1.334,2.576.283.5,1.931.653,2.6.652s2.319-.167,2.6-.668c.264-.472-1.17-1.2-1.35-2.568-.11-.836.964-1.581,1.73-3.239a8.811,8.811,0,0,0,.633-5.626" transform="translate(-94.976 -3.05)" fill="#ebf0f3" fill-rule="evenodd" />
                            <path id="Tracé_4174" data-name="Tracé 4174" d="M102.588,19.976c0,.417-1.073.517-1.592.519s-1.595-.075-1.6-.509c0-.217.407-1.01,1.59-1.013s1.6.787,1.6,1m-1.607-4.3c-1.031,0-2.07-1.511-2.285-2.657a9.771,9.771,0,0,1,.069-4.132c.214-.644,1.208-1.166,1.306-1.283a4.842,4.842,0,0,0,.262-1.946c0-.27.486-.407.616-.408s.618.134.619.4a4.868,4.868,0,0,0,.274,1.945c.1.117,1.1.633,1.315,1.276a9.793,9.793,0,0,1,.094,4.131c-.208,1.148-1.238,2.667-2.27,2.671M104.6,8.75c-.368-1.054-1.488-1.151-1.96-1.357s-.374-2.186-.228-2.4c.166-.247.815-.178,1.095-.256.38-.107.549-1.349.421-1.467a3.159,3.159,0,0,0-.855-.1c-.2-.009-.066,1.318-.342,1.4a11.972,11.972,0,0,1-1.781.109,11.913,11.913,0,0,1-1.782-.1c-.276-.075-.147-1.4-.35-1.393a3.159,3.159,0,0,0-.855.1c-.127.119.05,1.36.43,1.465.28.076.929,0,1.1.249.146.215.254,2.2-.214,2.4s-1.59.312-1.953,1.369a8.828,8.828,0,0,0,.659,5.6c.767,1.662,1.856,2.409,1.751,3.25-.17,1.363-1.6,2.107-1.333,2.576.278.5,1.918.652,2.6.65s2.32-.169,2.595-.666c.26-.47-1.169-1.2-1.348-2.567-.11-.84.974-1.594,1.732-3.26a8.83,8.83,0,0,0,.623-5.6" transform="translate(-95.949 -3.13)" fill="#eaeff2" fill-rule="evenodd" />
                            <path id="Tracé_4175" data-name="Tracé 4175" d="M103.687,20.024c0,.418-1.079.521-1.6.523s-1.6-.079-1.6-.513a1.761,1.761,0,0,1,3.2-.01m-1.615-4.261c-1.031,0-2.081-1.543-2.294-2.689a9.841,9.841,0,0,1,.066-4.138c.214-.644,1.216-1.167,1.315-1.284a4.881,4.881,0,0,0,.259-1.954c0-.27.493-.405.623-.406s.625.132.626.4a4.89,4.89,0,0,0,.271,1.953c.1.117,1.1.633,1.323,1.276a9.86,9.86,0,0,1,.091,4.137c-.206,1.148-1.247,2.7-2.279,2.7M105.678,8.8c-.367-1.052-1.487-1.15-1.958-1.357s-.37-2.188-.225-2.4c.166-.246.816-.178,1.1-.257.377-.106.546-1.348.419-1.466a3.106,3.106,0,0,0-.844-.1c-.2-.009-.064,1.318-.342,1.4a12.038,12.038,0,0,1-1.788.109,12.026,12.026,0,0,1-1.788-.1c-.278-.075-.149-1.4-.35-1.393a3.085,3.085,0,0,0-.844.1c-.127.119.051,1.36.428,1.464.282.077.932,0,1.1.25.146.215.255,2.2-.21,2.405s-1.589.314-1.951,1.369a8.881,8.881,0,0,0,.649,5.577c.764,1.669,1.858,2.426,1.753,3.271-.169,1.363-1.589,2.109-1.332,2.575.272.492,1.9.651,2.594.649s2.321-.171,2.59-.665c.255-.468-1.169-1.205-1.347-2.567-.11-.845.981-1.608,1.734-3.282a8.882,8.882,0,0,0,.614-5.581" transform="translate(-97.04 -3.179)" fill="#e8ecef" fill-rule="evenodd" />
                            <path id="Tracé_4176" data-name="Tracé 4176" d="M104.723,20.1c0,.418-1.085.525-1.609.527s-1.613-.082-1.612-.517a1.759,1.759,0,0,1,3.221-.01M103.1,15.876c-1.031,0-2.092-1.576-2.3-2.722a9.92,9.92,0,0,1,.062-4.145c.215-.644,1.225-1.167,1.323-1.285a4.9,4.9,0,0,0,.256-1.962c0-.27.5-.4.63-.4s.632.13.633.4a4.917,4.917,0,0,0,.268,1.96c.1.117,1.113.634,1.332,1.277a9.942,9.942,0,0,1,.088,4.144c-.205,1.148-1.257,2.732-2.289,2.736m3.6-7c-.367-1.051-1.485-1.148-1.957-1.356s-.366-2.19-.221-2.406c.166-.246.817-.178,1.1-.257.374-.1.544-1.347.418-1.465a3.051,3.051,0,0,0-.833-.1c-.2-.009-.062,1.317-.342,1.4a12.113,12.113,0,0,1-1.794.109,12.06,12.06,0,0,1-1.795-.1c-.279-.076-.152-1.4-.35-1.393a3.06,3.06,0,0,0-.833.1c-.126.119.052,1.36.427,1.463.284.077.935.005,1.1.251.146.215.257,2.2-.206,2.407s-1.587.315-1.949,1.368a8.92,8.92,0,0,0,.639,5.555c.758,1.677,1.86,2.443,1.755,3.293-.168,1.362-1.583,2.11-1.331,2.575.267.488,1.892.649,2.589.647s2.322-.173,2.586-.663c.25-.466-1.169-1.205-1.346-2.566-.11-.85.988-1.623,1.736-3.3a8.924,8.924,0,0,0,.6-5.559" transform="translate(-98.067 -3.255)" fill="#e6ebee" fill-rule="evenodd" />
                            <path id="Tracé_4177" data-name="Tracé 4177" d="M105.758,20.18c0,.419-1.091.529-1.617.53s-1.623-.086-1.62-.521a1.758,1.758,0,0,1,3.238-.01m-1.632-4.187c-1.031,0-2.1-1.608-2.313-2.754a9.99,9.99,0,0,1,.06-4.151c.214-.644,1.233-1.168,1.331-1.286a4.934,4.934,0,0,0,.253-1.97c0-.27.507-.4.637-.4s.639.127.64.4A4.946,4.946,0,0,0,105,7.8c.1.117,1.122.635,1.341,1.278a10.021,10.021,0,0,1,.085,4.15c-.2,1.148-1.266,2.765-2.3,2.768m3.584-7.036c-.366-1.049-1.484-1.147-1.955-1.356s-.362-2.192-.217-2.408c.166-.246.818-.178,1.1-.258.371-.1.542-1.346.416-1.464a3,3,0,0,0-.822-.1c-.2-.009-.061,1.317-.342,1.395a12.149,12.149,0,0,1-1.8.109,12.171,12.171,0,0,1-1.8-.1c-.281-.076-.154-1.4-.35-1.393a3.006,3.006,0,0,0-.822.1c-.125.118.054,1.36.425,1.462.286.078.938.006,1.105.251.146.215.258,2.2-.2,2.409s-1.586.317-1.947,1.368a8.961,8.961,0,0,0,.629,5.533c.752,1.684,1.862,2.459,1.758,3.315-.167,1.361-1.578,2.112-1.329,2.574.261.485,1.878.648,2.585.646s2.323-.175,2.581-.662c.246-.463-1.169-1.205-1.345-2.566-.11-.855,1-1.636,1.738-3.325a8.963,8.963,0,0,0,.594-5.537" transform="translate(-99.094 -3.336)" fill="#e5eaed" fill-rule="evenodd" />
                            <path id="Tracé_4178" data-name="Tracé 4178" d="M106.8,20.228c.006.419-1.1.532-1.626.534s-1.632-.089-1.628-.524a1.757,1.757,0,0,1,3.254-.01m-1.64-4.15c-1.031,0-2.115-1.641-2.323-2.787a10.068,10.068,0,0,1,.057-4.157c.214-.644,1.242-1.169,1.34-1.287a4.969,4.969,0,0,0,.25-1.978c0-.27.514-.4.644-.4s.646.125.646.4a4.98,4.98,0,0,0,.262,1.976c.1.117,1.13.636,1.349,1.279a10.082,10.082,0,0,1,.082,4.156c-.2,1.148-1.275,2.8-2.307,2.8m3.573-7.072c-.366-1.047-1.483-1.146-1.953-1.356s-.359-2.193-.214-2.409c.166-.246.819-.178,1.107-.259.368-.1.54-1.346.415-1.463a2.949,2.949,0,0,0-.812-.094c-.194-.009-.059,1.316-.342,1.4a12.259,12.259,0,0,1-1.807.108,12.206,12.206,0,0,1-1.808-.1c-.283-.077-.156-1.4-.35-1.393a2.953,2.953,0,0,0-.812.1c-.125.118.055,1.359.423,1.461.288.078.941.006,1.108.252.146.215.259,2.2-.2,2.411s-1.585.319-1.946,1.368a9,9,0,0,0,.619,5.511c.746,1.692,1.864,2.476,1.76,3.336-.166,1.361-1.572,2.114-1.328,2.574.255.482,1.865.647,2.58.645s2.324-.177,2.577-.661c.241-.461-1.169-1.206-1.344-2.565-.109-.86,1-1.65,1.741-3.347a9,9,0,0,0,.584-5.515" transform="translate(-100.128 -3.384)" fill="#e3e8ea" fill-rule="evenodd" />
                            <path id="Tracé_4179" data-name="Tracé 4179" d="M107.828,20.306c.007.42-1.1.536-1.634.538s-1.641-.092-1.637-.528a1.756,1.756,0,0,1,3.271-.01m-1.648-4.112c-1.031,0-2.125-1.673-2.332-2.82a10.143,10.143,0,0,1,.053-4.163c.214-.644,1.25-1.17,1.348-1.287a4.99,4.99,0,0,0,.247-1.985c0-.27.521-.4.651-.4s.653.123.653.393a5.012,5.012,0,0,0,.259,1.984c.1.117,1.138.636,1.357,1.279a10.161,10.161,0,0,1,.079,4.162c-.2,1.149-1.284,2.831-2.317,2.834m3.563-7.107c-.365-1.045-1.482-1.145-1.952-1.355s-.355-2.2-.21-2.412c.166-.246.82-.178,1.11-.26.365-.1.538-1.345.413-1.461a2.881,2.881,0,0,0-.8-.093c-.192-.009-.057,1.315-.342,1.395a12.339,12.339,0,0,1-1.814.108,12.318,12.318,0,0,1-1.814-.1c-.285-.077-.158-1.4-.35-1.393a2.9,2.9,0,0,0-.8.1c-.124.117.057,1.359.422,1.459.29.079.943.007,1.111.253.146.215.26,2.207-.2,2.413s-1.584.32-1.944,1.367a9.041,9.041,0,0,0,.609,5.489c.741,1.7,1.866,2.493,1.762,3.358-.165,1.36-1.566,2.115-1.327,2.574.25.479,1.852.645,2.576.643s2.325-.179,2.572-.659c.237-.46-1.169-1.206-1.342-2.565-.109-.864,1.012-1.664,1.743-3.368a9.04,9.04,0,0,0,.575-5.493" transform="translate(-101.148 -3.463)" fill="#e1e6e9" fill-rule="evenodd" />
                            <path id="Tracé_4180" data-name="Tracé 4180" d="M108.859,20.357c.008.421-1.11.54-1.643.542s-1.65-.1-1.645-.532a1.757,1.757,0,0,1,3.288-.01M107.2,16.282c-1.031,0-2.136-1.705-2.342-2.852a10.217,10.217,0,0,1,.05-4.169c.214-.644,1.259-1.171,1.357-1.288a5.021,5.021,0,0,0,.244-1.994c0-.27.528-.394.658-.394s.659.121.66.39a5.034,5.034,0,0,0,.256,1.992c.1.117,1.147.637,1.366,1.28a10.248,10.248,0,0,1,.076,4.168c-.2,1.149-1.294,2.863-2.326,2.867m3.551-7.143C110.389,8.1,109.273,8,108.8,7.785c-.454-.2-.351-2.2-.206-2.414.166-.246.821-.178,1.113-.26.362-.1.535-1.344.411-1.46a2.847,2.847,0,0,0-.79-.092c-.19-.008-.055,1.315-.342,1.4a12.42,12.42,0,0,1-1.821.108,12.362,12.362,0,0,1-1.821-.1c-.287-.078-.161-1.4-.35-1.393a2.869,2.869,0,0,0-.79.1c-.124.117.058,1.359.42,1.458.293.08.946.008,1.114.253.146.215.261,2.209-.192,2.415-.469.214-1.583.321-1.942,1.367a9.083,9.083,0,0,0,.6,5.467c.735,1.708,1.868,2.51,1.764,3.38-.164,1.359-1.56,2.117-1.326,2.573.244.475,1.839.644,2.571.642s2.326-.181,2.568-.658c.232-.458-1.169-1.206-1.341-2.565-.109-.869,1.02-1.678,1.745-3.39a9.083,9.083,0,0,0,.565-5.47" transform="translate(-102.169 -3.516)" fill="#dfe4e7" fill-rule="evenodd" />
                            <path id="Tracé_4181" data-name="Tracé 4181" d="M109.886,20.432c.009.422-1.116.544-1.651.545s-1.66-.1-1.654-.535a1.757,1.757,0,0,1,3.3-.01m-1.665-4.038c-1.031,0-2.148-1.738-2.351-2.885a10.291,10.291,0,0,1,.047-4.175c.215-.644,1.268-1.171,1.366-1.289a5.062,5.062,0,0,0,.241-2c0-.27.535-.392.664-.392s.666.119.667.388a5.069,5.069,0,0,0,.253,2c.1.117,1.155.638,1.374,1.281a10.318,10.318,0,0,1,.072,4.174c-.2,1.149-1.3,2.9-2.335,2.9m3.54-7.178c-.364-1.041-1.479-1.142-1.948-1.354-.451-.2-.348-2.2-.2-2.415.166-.246.821-.179,1.115-.261.359-.1.533-1.343.409-1.459a2.788,2.788,0,0,0-.779-.091c-.187-.008-.053,1.314-.342,1.395a12.5,12.5,0,0,1-1.827.108,12.477,12.477,0,0,1-1.827-.1c-.288-.078-.163-1.4-.35-1.393a2.808,2.808,0,0,0-.779.1c-.123.117.059,1.358.418,1.457.295.08.949.008,1.117.254.146.215.262,2.212-.188,2.417-.468.215-1.582.323-1.941,1.366a9.127,9.127,0,0,0,.589,5.445c.729,1.716,1.87,2.527,1.767,3.4-.163,1.359-1.555,2.118-1.325,2.573.239.471,1.825.643,2.566.64s2.327-.183,2.563-.656c.227-.456-1.169-1.207-1.34-2.564-.109-.874,1.029-1.691,1.747-3.412a9.126,9.126,0,0,0,.555-5.448" transform="translate(-103.189 -3.591)" fill="#dee3e6" fill-rule="evenodd" />
                            <path id="Tracé_4182" data-name="Tracé 4182" d="M110.908,20.481c.009.422-1.122.547-1.659.549s-1.669-.1-1.662-.539a1.758,1.758,0,0,1,3.321-.01m-1.673-4c-1.031,0-2.159-1.77-2.361-2.918a10.381,10.381,0,0,1,.044-4.181c.215-.644,1.276-1.172,1.374-1.29a5.077,5.077,0,0,0,.238-2.009c0-.27.542-.39.671-.39s.674.117.675.386a5.086,5.086,0,0,0,.25,2.008c.1.117,1.164.639,1.382,1.282a10.394,10.394,0,0,1,.07,4.181c-.2,1.149-1.312,2.928-2.344,2.932m3.529-7.214c-.364-1.039-1.478-1.141-1.947-1.354-.448-.2-.344-2.2-.2-2.417.166-.246.822-.179,1.118-.262.356-.1.531-1.343.408-1.458a2.745,2.745,0,0,0-.769-.09c-.185-.008-.052,1.314-.342,1.395a12.577,12.577,0,0,1-1.834.108,12.549,12.549,0,0,1-1.834-.1c-.291-.079-.165-1.4-.35-1.393a2.763,2.763,0,0,0-.769.094c-.123.116.06,1.358.417,1.456.3.081.952.009,1.12.255.146.215.264,2.214-.184,2.418-.468.216-1.581.324-1.939,1.366a9.171,9.171,0,0,0,.579,5.423c.723,1.724,1.872,2.543,1.769,3.423-.162,1.358-1.549,2.12-1.323,2.572.233.468,1.812.641,2.562.639s2.328-.185,2.559-.655c.223-.453-1.169-1.207-1.339-2.564-.108-.879,1.037-1.7,1.749-3.433a9.17,9.17,0,0,0,.545-5.426" transform="translate(-104.202 -3.642)" fill="#dde1e4" fill-rule="evenodd" />
                            <path id="Tracé_4183" data-name="Tracé 4183" d="M111.924,20.559c.01.423-1.128.551-1.667.553s-1.678-.106-1.67-.543a1.759,1.759,0,0,1,3.337-.01m-1.681-3.964c-1.031,0-2.17-1.8-2.37-2.95a10.453,10.453,0,0,1,.041-4.187c.214-.644,1.284-1.173,1.382-1.291.293-.349.236-1.856.235-2.017,0-.27.549-.388.678-.388s.68.114.681.384c0,.161-.047,1.668.247,2.015.1.117,1.172.64,1.391,1.283a10.474,10.474,0,0,1,.067,4.186c-.194,1.149-1.322,2.961-2.354,2.965m3.518-7.25c-.363-1.037-1.476-1.14-1.945-1.353-.446-.2-.34-2.2-.195-2.419.166-.246.823-.179,1.121-.262.353-.1.529-1.342.406-1.457a2.688,2.688,0,0,0-.758-.088c-.183-.008-.05,1.313-.342,1.395a12.647,12.647,0,0,1-1.841.108,12.625,12.625,0,0,1-1.84-.1c-.292-.079-.168-1.4-.35-1.393a2.7,2.7,0,0,0-.758.093c-.122.116.062,1.357.415,1.455.3.081.955.01,1.123.255.146.215.265,2.216-.18,2.42-.468.216-1.58.326-1.938,1.365a9.211,9.211,0,0,0,.57,5.4c.717,1.732,1.874,2.56,1.771,3.444-.16,1.357-1.543,2.122-1.322,2.572s1.8.64,2.557.638,2.329-.187,2.554-.653-1.169-1.207-1.337-2.564c-.108-.884,1.045-1.718,1.751-3.455a9.212,9.212,0,0,0,.536-5.4" transform="translate(-105.21 -3.72)" fill="#dbe0e3" fill-rule="evenodd" />
                            <path id="Tracé_4184" data-name="Tracé 4184" d="M112.946,20.607c.011.424-1.135.555-1.676.557s-1.687-.11-1.678-.547a1.761,1.761,0,0,1,3.354-.01m-1.689-3.927c-1.031,0-2.181-1.835-2.38-2.983a10.525,10.525,0,0,1,.038-4.193c.214-.644,1.293-1.174,1.391-1.291.293-.349.233-1.864.233-2.025,0-.27.555-.385.685-.386s.688.112.688.382c0,.161-.05,1.676.244,2.024.1.117,1.181.64,1.4,1.283a10.548,10.548,0,0,1,.063,4.193c-.193,1.15-1.331,2.994-2.363,3M114.764,9.4c-.363-1.035-1.475-1.139-1.944-1.353-.443-.2-.336-2.2-.191-2.421.166-.246.824-.179,1.124-.263.35-.1.527-1.341.4-1.456a2.645,2.645,0,0,0-.747-.087c-.181-.008-.048,1.313-.342,1.395a12.727,12.727,0,0,1-1.847.108,12.7,12.7,0,0,1-1.847-.1c-.294-.08-.17-1.4-.35-1.393a2.645,2.645,0,0,0-.747.092c-.121.115.063,1.357.413,1.453.3.082.958.011,1.126.256.146.215.265,2.218-.176,2.422-.468.216-1.579.327-1.936,1.365a9.257,9.257,0,0,0,.56,5.378c.711,1.74,1.877,2.577,1.774,3.466-.159,1.357-1.537,2.123-1.321,2.571s1.785.638,2.553.636,2.33-.189,2.55-.652-1.169-1.207-1.336-2.563c-.108-.889,1.054-1.732,1.754-3.477a9.255,9.255,0,0,0,.526-5.382" transform="translate(-106.223 -3.769)" fill="#dadee1" fill-rule="evenodd" />
                            <path id="Tracé_4185" data-name="Tracé 4185" d="M113.961,20.687c.012.424-1.141.559-1.684.56s-1.7-.113-1.687-.55a1.763,1.763,0,0,1,3.371-.01m-1.7-3.89c-1.031,0-2.192-1.868-2.389-3.016a10.61,10.61,0,0,1,.035-4.2c.214-.644,1.3-1.175,1.4-1.292.293-.349.23-1.872.229-2.033,0-.27.563-.383.693-.384s.694.11.695.379c0,.161-.053,1.684.242,2.032.1.117,1.189.641,1.408,1.284a10.637,10.637,0,0,1,.06,4.2c-.191,1.15-1.34,3.027-2.372,3.03m3.5-7.321c-.362-1.033-1.473-1.139-1.942-1.353-.44-.2-.332-2.206-.187-2.423.166-.247.825-.179,1.127-.264.347-.1.524-1.34.4-1.454a2.589,2.589,0,0,0-.737-.086c-.178-.008-.046,1.312-.342,1.394a12.808,12.808,0,0,1-1.854.108,12.776,12.776,0,0,1-1.853-.1c-.3-.08-.172-1.4-.35-1.393A2.6,2.6,0,0,0,109.29,4c-.121.115.064,1.357.412,1.452.3.082.961.011,1.129.257.146.215.266,2.22-.173,2.424-.467.217-1.577.329-1.934,1.364a9.3,9.3,0,0,0,.55,5.357c.7,1.748,1.878,2.594,1.776,3.487-.158,1.356-1.531,2.125-1.32,2.571s1.772.637,2.548.635,2.331-.191,2.545-.65-1.168-1.208-1.335-2.563c-.108-.893,1.062-1.745,1.756-3.5a9.3,9.3,0,0,0,.516-5.36" transform="translate(-107.231 -3.849)" fill="#d8dde0" fill-rule="evenodd" />
                            <path id="Tracé_4186" data-name="Tracé 4186" d="M114.969,20.736c.013.425-1.147.563-1.692.564s-1.706-.116-1.695-.554a1.766,1.766,0,0,1,3.387-.01m-1.706-3.853c-1.031,0-2.2-1.9-2.4-3.048A10.694,10.694,0,0,1,110.9,9.63c.215-.644,1.31-1.175,1.408-1.293.293-.349.227-1.88.226-2.041,0-.27.569-.381.7-.382s.7.108.7.377c0,.161-.056,1.692.239,2.039.1.117,1.2.642,1.416,1.285a10.716,10.716,0,0,1,.058,4.2c-.19,1.15-1.35,3.059-2.382,3.063m3.486-7.356c-.362-1.031-1.472-1.137-1.94-1.352-.438-.2-.329-2.208-.184-2.425.166-.246.826-.179,1.13-.264.344-.1.522-1.34.4-1.453a2.545,2.545,0,0,0-.726-.085c-.176-.007-.044,1.312-.342,1.395a12.877,12.877,0,0,1-1.86.107,12.855,12.855,0,0,1-1.86-.1c-.3-.081-.174-1.4-.35-1.393a2.564,2.564,0,0,0-.726.089c-.12.115.066,1.357.41,1.451.3.083.964.012,1.132.258.146.215.267,2.222-.169,2.426-.467.218-1.576.33-1.933,1.364a9.345,9.345,0,0,0,.54,5.334c.7,1.757,1.881,2.611,1.778,3.509-.157,1.355-1.525,2.126-1.318,2.571s1.759.636,2.544.633,2.332-.193,2.541-.649-1.168-1.208-1.334-2.562c-.107-.9,1.071-1.759,1.758-3.52a9.344,9.344,0,0,0,.507-5.338" transform="translate(-108.23 -3.9)" fill="#d6dbde" fill-rule="evenodd" />
                            <path id="Tracé_4187" data-name="Tracé 4187" d="M116,20.811c.014.426-1.153.566-1.7.568s-1.715-.12-1.7-.558a1.768,1.768,0,0,1,3.4-.01m-1.714-3.816c-1.031,0-2.214-1.932-2.408-3.081A10.773,10.773,0,0,1,111.9,9.7c.215-.644,1.318-1.176,1.416-1.294.293-.349.224-1.888.224-2.048,0-.27.576-.379.706-.38s.708.106.709.375c0,.161-.059,1.7.236,2.047.1.117,1.206.643,1.425,1.285a10.8,10.8,0,0,1,.055,4.211c-.188,1.15-1.359,3.092-2.391,3.1M117.756,9.6c-.361-1.029-1.471-1.136-1.939-1.352-.435-.2-.325-2.21-.18-2.427.166-.246.827-.18,1.133-.265.341-.1.52-1.339.4-1.452a2.5,2.5,0,0,0-.715-.084c-.174-.007-.043,1.311-.342,1.394a12.959,12.959,0,0,1-1.867.107,12.934,12.934,0,0,1-1.867-.1c-.3-.081-.176-1.4-.35-1.392a2.51,2.51,0,0,0-.715.088c-.12.114.067,1.356.408,1.45.307.084.967.012,1.135.258.146.215.268,2.225-.165,2.428-.467.218-1.575.332-1.931,1.364a9.4,9.4,0,0,0,.53,5.312c.692,1.765,1.883,2.627,1.781,3.531-.156,1.355-1.519,2.128-1.317,2.57s1.745.634,2.539.632,2.333-.2,2.536-.648-1.168-1.208-1.333-2.562c-.107-.9,1.079-1.772,1.76-3.541a9.394,9.394,0,0,0,.5-5.316" transform="translate(-109.248 -3.975)" fill="#d5dadd" fill-rule="evenodd" />
                            <path id="Tracé_4188" data-name="Tracé 4188" d="M117,20.893c.015.426-1.159.57-1.709.572s-1.724-.123-1.712-.562a1.772,1.772,0,0,1,3.421-.01m-1.722-3.779c-1.031,0-2.225-1.965-2.418-3.114a10.862,10.862,0,0,1,.026-4.218c.215-.644,1.327-1.177,1.425-1.294.293-.349.221-1.9.22-2.057,0-.27.583-.377.713-.377s.715.1.716.373c0,.161-.062,1.708.233,2.055.1.117,1.214.643,1.433,1.286a10.876,10.876,0,0,1,.051,4.217c-.186,1.15-1.368,3.125-2.4,3.128m3.464-7.428c-.36-1.027-1.469-1.135-1.937-1.351-.432-.2-.321-2.212-.176-2.429.167-.246.828-.179,1.136-.266.338-.095.518-1.338.4-1.451a2.445,2.445,0,0,0-.7-.082c-.171-.007-.041,1.31-.342,1.394a13.086,13.086,0,0,1-1.873.108,13.019,13.019,0,0,1-1.873-.1c-.3-.082-.179-1.4-.35-1.393a2.46,2.46,0,0,0-.7.087c-.119.114.068,1.356.406,1.449.309.084.97.013,1.138.259.146.215.27,2.227-.162,2.429-.466.219-1.574.334-1.929,1.363a9.444,9.444,0,0,0,.52,5.29c.686,1.773,1.885,2.644,1.783,3.552-.155,1.354-1.513,2.129-1.316,2.57s1.732.633,2.535.631,2.334-.2,2.532-.646S116.66,19.9,116.5,18.543c-.107-.908,1.088-1.785,1.762-3.563a9.441,9.441,0,0,0,.487-5.293" transform="translate(-110.249 -4.058)" fill="#d4d8db" fill-rule="evenodd" />
                            <path id="Tracé_4189" data-name="Tracé 4189" d="M118.013,20.94c.016.427-1.166.574-1.717.576s-1.733-.127-1.72-.565a1.775,1.775,0,0,1,3.437-.01m-1.73-3.741c-1.031,0-2.236-2-2.428-3.146a10.948,10.948,0,0,1,.023-4.224c.215-.644,1.335-1.178,1.433-1.3.293-.349.218-1.9.218-2.064,0-.27.59-.375.72-.375s.722.1.723.371c0,.161-.065,1.716.23,2.063.1.117,1.223.644,1.442,1.287a10.972,10.972,0,0,1,.048,4.223c-.185,1.151-1.377,3.157-2.41,3.161m3.453-7.464c-.36-1.025-1.468-1.134-1.935-1.351-.43-.2-.318-2.214-.172-2.43.166-.246.828-.18,1.139-.267.335-.094.515-1.337.4-1.45a2.4,2.4,0,0,0-.694-.081c-.169-.007-.039,1.31-.342,1.394a13.118,13.118,0,0,1-1.88.108,13.1,13.1,0,0,1-1.88-.1c-.3-.082-.181-1.4-.35-1.392a2.4,2.4,0,0,0-.693.085c-.119.114.07,1.356.4,1.448.311.085.973.014,1.14.26.146.215.271,2.229-.158,2.431-.466.22-1.573.335-1.927,1.363a9.487,9.487,0,0,0,.51,5.268c.679,1.782,1.887,2.661,1.786,3.574-.154,1.353-1.507,2.131-1.315,2.569s1.719.632,2.53.629,2.335-.2,2.527-.645-1.168-1.208-1.33-2.561c-.107-.913,1.1-1.8,1.765-3.585a9.49,9.49,0,0,0,.477-5.271" transform="translate(-111.249 -4.107)" fill="#d2d7da" fill-rule="evenodd" />
                            <path id="Tracé_4190" data-name="Tracé 4190" d="M119.022,21.017c.017.427-1.172.578-1.726.579s-1.743-.13-1.728-.569a1.779,1.779,0,0,1,3.454-.01m-1.738-3.7c-1.031,0-2.247-2.03-2.437-3.179a11.023,11.023,0,0,1,.02-4.23c.214-.644,1.344-1.179,1.442-1.3.293-.349.215-1.911.215-2.072,0-.27.6-.373.727-.373s.729.1.73.368c0,.161-.068,1.724.227,2.071.1.117,1.231.645,1.451,1.288a11.054,11.054,0,0,1,.045,4.229c-.184,1.151-1.387,3.19-2.419,3.194m3.442-7.5c-.359-1.023-1.467-1.133-1.934-1.35-.427-.2-.314-2.216-.169-2.432.166-.246.829-.18,1.142-.267.332-.093.513-1.337.394-1.449a2.348,2.348,0,0,0-.683-.08c-.167-.007-.037,1.309-.342,1.394a13.2,13.2,0,0,1-1.886.108,13.175,13.175,0,0,1-1.886-.1c-.3-.083-.183-1.4-.35-1.392a2.342,2.342,0,0,0-.683.084c-.118.113.071,1.355.4,1.447.313.085.976.015,1.143.26.146.215.272,2.232-.154,2.433-.466.22-1.572.336-1.926,1.362a9.537,9.537,0,0,0,.5,5.246c.672,1.79,1.889,2.678,1.788,3.6-.153,1.352-1.5,2.132-1.313,2.569s1.706.63,2.526.628,2.336-.2,2.523-.643-1.168-1.209-1.329-2.56c-.107-.917,1.105-1.812,1.767-3.606a9.537,9.537,0,0,0,.467-5.249" transform="translate(-112.249 -4.184)" fill="#d1d6da" fill-rule="evenodd" />
                            <path id="Tracé_4191" data-name="Tracé 4191" d="M120.023,21.068c.018.428-1.178.582-1.734.583s-1.752-.133-1.737-.573a1.783,1.783,0,0,1,3.471-.011M118.276,17.4c-1.031,0-2.258-2.062-2.446-3.212a11.113,11.113,0,0,1,.017-4.236c.214-.644,1.352-1.179,1.451-1.3.293-.349.212-1.919.211-2.08,0-.27.6-.371.734-.371s.736.1.737.366c0,.161-.07,1.731.224,2.079.1.117,1.24.646,1.459,1.288a11.129,11.129,0,0,1,.042,4.235c-.182,1.151-1.4,3.223-2.428,3.227m3.431-7.535c-.359-1.022-1.465-1.132-1.932-1.35-.425-.2-.31-2.218-.165-2.434.166-.246.83-.18,1.144-.268s.511-1.336.393-1.448a2.305,2.305,0,0,0-.672-.079c-.164-.007-.035,1.309-.342,1.394a13.268,13.268,0,0,1-1.893.107,13.256,13.256,0,0,1-1.893-.1c-.307-.083-.186-1.4-.35-1.392a2.313,2.313,0,0,0-.672.083c-.118.113.072,1.355.4,1.446s.978.015,1.146.261c.146.215.273,2.234-.15,2.435-.465.221-1.571.338-1.924,1.362a9.587,9.587,0,0,0,.49,5.224c.666,1.8,1.891,2.695,1.79,3.617-.151,1.352-1.5,2.134-1.312,2.568s1.692.629,2.521.626,2.337-.2,2.518-.642-1.168-1.209-1.328-2.56c-.107-.922,1.114-1.825,1.769-3.628a9.585,9.585,0,0,0,.458-5.227" transform="translate(-113.243 -4.236)" fill="#cfd5d8" fill-rule="evenodd" />
                            <path id="Tracé_4192" data-name="Tracé 4192" d="M121.018,21.143c.019.429-1.184.585-1.742.587s-1.761-.137-1.745-.576a1.787,1.787,0,0,1,3.487-.011m-1.755-3.63c-1.031,0-2.269-2.094-2.456-3.244a11.2,11.2,0,0,1,.014-4.242c.215-.644,1.361-1.18,1.459-1.3.293-.349.209-1.927.209-2.088,0-.27.611-.368.741-.368s.743.094.744.364c0,.161-.073,1.739.221,2.086.1.117,1.248.646,1.467,1.289a11.218,11.218,0,0,1,.039,4.241c-.18,1.151-1.405,3.256-2.438,3.259m3.42-7.57c-.358-1.02-1.464-1.131-1.93-1.35-.422-.2-.306-2.22-.161-2.436.166-.246.831-.18,1.147-.269s.509-1.335.391-1.446a2.25,2.25,0,0,0-.661-.078c-.162-.007-.034,1.308-.342,1.394a13.348,13.348,0,0,1-1.9.107,13.379,13.379,0,0,1-1.9-.1c-.308-.084-.188-1.4-.35-1.392a2.259,2.259,0,0,0-.661.082c-.117.112.074,1.355.4,1.444s.981.016,1.149.262c.146.215.274,2.236-.147,2.437-.465.222-1.569.339-1.922,1.361a9.637,9.637,0,0,0,.48,5.2c.659,1.807,1.893,2.711,1.793,3.639-.15,1.351-1.49,2.135-1.311,2.568s1.679.628,2.517.625,2.337-.206,2.514-.64-1.168-1.209-1.327-2.56c-.106-.927,1.123-1.839,1.771-3.65a9.636,9.636,0,0,0,.448-5.2" transform="translate(-114.229 -4.312)" fill="#ced4d7" fill-rule="evenodd" />
                            <path id="Tracé_4193" data-name="Tracé 4193" d="M122.019,21.193c.02.429-1.19.589-1.75.591s-1.77-.14-1.753-.58a1.791,1.791,0,0,1,3.5-.01M120.256,17.6c-1.031,0-2.28-2.127-2.465-3.277a11.281,11.281,0,0,1,.01-4.248c.215-.644,1.369-1.181,1.468-1.3.293-.349.206-1.935.206-2.1,0-.27.618-.366.748-.366s.75.092.75.362c0,.161-.076,1.747.218,2.094.1.117,1.257.647,1.476,1.29a11.318,11.318,0,0,1,.036,4.248c-.179,1.151-1.415,3.288-2.447,3.292m3.409-7.606c-.358-1.018-1.463-1.13-1.929-1.349-.419-.2-.3-2.222-.158-2.438.166-.247.832-.18,1.15-.269s.507-1.334.39-1.445a2.217,2.217,0,0,0-.65-.077c-.16-.006-.032,1.308-.342,1.394a13.479,13.479,0,0,1-1.906.107,13.407,13.407,0,0,1-1.906-.1c-.31-.084-.19-1.4-.35-1.392a2.21,2.21,0,0,0-.65.08c-.116.112.075,1.354.4,1.443s.984.017,1.152.262c.146.215.275,2.238-.143,2.439-.465.223-1.568.341-1.921,1.361a9.69,9.69,0,0,0,.47,5.18c.652,1.815,1.895,2.728,1.795,3.66-.149,1.35-1.484,2.136-1.31,2.567s1.666.626,2.512.624,2.338-.208,2.509-.639-1.168-1.21-1.325-2.559c-.106-.932,1.132-1.852,1.773-3.671a9.691,9.691,0,0,0,.438-5.182" transform="translate(-115.222 -4.362)" fill="#ccd2d5" fill-rule="evenodd" />
                            <path id="Tracé_4194" data-name="Tracé 4194" d="M123.014,21.271c.02.43-1.2.593-1.759.595s-1.779-.144-1.761-.584a1.8,1.8,0,0,1,3.52-.011m-1.771-3.556c-1.031,0-2.291-2.159-2.475-3.309a11.381,11.381,0,0,1,.007-4.254c.215-.644,1.378-1.182,1.476-1.3.293-.349.2-1.942.2-2.1,0-.27.625-.364.755-.364s.757.09.757.36c0,.161-.079,1.755.216,2.1.1.117,1.265.648,1.484,1.291a11.4,11.4,0,0,1,.033,4.254c-.177,1.152-1.424,3.321-2.456,3.324m3.4-7.642c-.357-1.016-1.461-1.128-1.927-1.349-.417-.2-.3-2.224-.154-2.44.167-.246.833-.18,1.153-.27s.5-1.334.388-1.444a2.164,2.164,0,0,0-.64-.075c-.158-.006-.03,1.307-.342,1.394A13.509,13.509,0,0,1,121.208,6a13.533,13.533,0,0,1-1.912-.1c-.312-.085-.193-1.4-.35-1.392a2.158,2.158,0,0,0-.639.079c-.116.111.076,1.354.4,1.442s.987.017,1.155.263c.146.215.276,2.241-.139,2.441-.464.223-1.567.342-1.919,1.36a9.743,9.743,0,0,0,.46,5.158c.645,1.824,1.9,2.745,1.8,3.682-.148,1.35-1.478,2.138-1.309,2.567s1.653.625,2.508.622,2.339-.21,2.5-.638-1.168-1.21-1.324-2.559c-.106-.936,1.141-1.865,1.775-3.693a9.746,9.746,0,0,0,.428-5.16" transform="translate(-116.209 -4.442)" fill="#cad1d4" fill-rule="evenodd" />
                            <path id="Tracé_4195" data-name="Tracé 4195" d="M124,21.319c.021.431-1.2.6-1.767.6s-1.789-.147-1.77-.588A1.8,1.8,0,0,1,124,21.319M122.224,17.8c-1.031,0-2.3-2.191-2.484-3.342a11.467,11.467,0,0,1,0-4.26c.215-.644,1.386-1.183,1.485-1.3.293-.349.2-1.95.2-2.111,0-.27.632-.362.762-.362s.763.088.764.357c0,.161-.082,1.763.212,2.11.1.117,1.274.648,1.493,1.291a11.483,11.483,0,0,1,.03,4.26c-.176,1.152-1.433,3.353-2.465,3.357m3.387-7.677c-.357-1.014-1.46-1.127-1.925-1.348-.414-.2-.3-2.226-.15-2.442.167-.246.834-.181,1.156-.271s.5-1.333.386-1.443a2.115,2.115,0,0,0-.629-.074c-.156-.006-.028,1.307-.342,1.394a13.6,13.6,0,0,1-1.919.107,13.574,13.574,0,0,1-1.919-.1c-.314-.085-.195-1.4-.35-1.392a2.11,2.11,0,0,0-.629.078c-.116.111.078,1.354.395,1.441s.99.018,1.158.264c.146.215.278,2.243-.135,2.442-.464.224-1.566.344-1.917,1.36a9.793,9.793,0,0,0,.451,5.136c.638,1.833,1.9,2.762,1.8,3.7-.147,1.349-1.472,2.14-1.307,2.566s1.639.624,2.5.621,2.34-.213,2.5-.636-1.167-1.21-1.323-2.558c-.105-.941,1.15-1.878,1.778-3.714a9.8,9.8,0,0,0,.419-5.138" transform="translate(-117.189 -4.491)" fill="#cacfd3" fill-rule="evenodd" />
                            <path id="Tracé_4196" data-name="Tracé 4196" d="M125.01,21.4c.022.431-1.209.6-1.776.6s-1.8-.15-1.778-.591a1.806,1.806,0,0,1,3.554-.011m-1.788-3.482c-1.031,0-2.313-2.224-2.494-3.375a11.552,11.552,0,0,1,0-4.266c.214-.644,1.394-1.184,1.493-1.3.293-.349.2-1.958.2-2.119,0-.269.638-.359.768-.36s.77.085.771.355c0,.161-.085,1.771.209,2.118.1.117,1.282.649,1.5,1.292a11.577,11.577,0,0,1,.027,4.266c-.174,1.152-1.442,3.387-2.475,3.39M126.6,10.2c-.356-1.012-1.459-1.126-1.924-1.348-.412-.2-.292-2.227-.146-2.443.166-.247.834-.181,1.159-.272s.5-1.332.384-1.441a2.064,2.064,0,0,0-.618-.073c-.153-.006-.027,1.306-.342,1.394a13.725,13.725,0,0,1-1.925.107,13.7,13.7,0,0,1-1.925-.1c-.316-.086-.2-1.4-.35-1.392a2.058,2.058,0,0,0-.618.077c-.115.111.079,1.353.393,1.44s.993.019,1.161.264c.146.215.279,2.245-.132,2.444-.464.225-1.565.345-1.916,1.36a9.854,9.854,0,0,0,.441,5.113c.631,1.842,1.9,2.779,1.8,3.725-.146,1.348-1.466,2.141-1.306,2.566s1.626.622,2.5.62,2.341-.214,2.5-.635-1.167-1.21-1.322-2.558c-.105-.946,1.159-1.891,1.78-3.736A9.852,9.852,0,0,0,126.6,10.2" transform="translate(-118.187 -4.568)" fill="#c8ced1" fill-rule="evenodd" />
                            <path id="Tracé_4197" data-name="Tracé 4197" d="M126,21.473c.023.432-1.215.6-1.784.606s-1.807-.154-1.786-.6a1.812,1.812,0,0,1,3.57-.011m-1.8-3.445c-1.031,0-2.324-2.256-2.5-3.407a11.641,11.641,0,0,1,0-4.273c.215-.644,1.4-1.184,1.5-1.3.293-.349.194-1.966.194-2.127,0-.27.646-.357.776-.358s.777.083.778.353c0,.161-.088,1.779.207,2.126.1.117,1.291.65,1.51,1.293a11.673,11.673,0,0,1,.024,4.272c-.173,1.152-1.452,3.419-2.484,3.423m3.365-7.748c-.356-1.01-1.457-1.125-1.922-1.348-.409-.2-.288-2.229-.143-2.445.166-.246.835-.181,1.162-.272.311-.087.5-1.331.383-1.441a2.012,2.012,0,0,0-.607-.072c-.151-.006-.025,1.305-.342,1.394a13.756,13.756,0,0,1-1.932.107,13.728,13.728,0,0,1-1.932-.1c-.317-.086-.2-1.4-.35-1.392a2.032,2.032,0,0,0-.607.075c-.114.11.08,1.353.391,1.439.327.089,1,.019,1.164.265.146.215.28,2.248-.128,2.446-.463.226-1.564.347-1.914,1.359a9.912,9.912,0,0,0,.431,5.091c.624,1.85,1.9,2.8,1.8,3.747-.145,1.348-1.46,2.142-1.3,2.566s1.613.621,2.494.618,2.342-.217,2.491-.633-1.167-1.21-1.32-2.557c-.105-.951,1.169-1.9,1.782-3.758a9.911,9.911,0,0,0,.4-5.094" transform="translate(-119.167 -4.646)" fill="#c7cdd0" fill-rule="evenodd" />
                            <path id="Tracé_4198" data-name="Tracé 4198" d="M126.98,21.524c.024.433-1.221.608-1.792.61s-1.816-.157-1.795-.6a1.817,1.817,0,0,1,3.587-.011m-1.8-3.408c-1.031,0-2.335-2.289-2.513-3.44a11.73,11.73,0,0,1-.005-4.279c.215-.644,1.411-1.185,1.51-1.3.293-.349.191-1.974.191-2.135,0-.27.652-.355.782-.356s.784.081.785.351c0,.161-.091,1.787.2,2.134.1.117,1.3.651,1.518,1.294a11.764,11.764,0,0,1,.021,4.278c-.171,1.152-1.461,3.452-2.493,3.455m3.354-7.784c-.355-1.008-1.456-1.124-1.92-1.347-.406-.2-.284-2.231-.139-2.447.166-.246.836-.181,1.165-.273.308-.086.5-1.33.381-1.439a1.979,1.979,0,0,0-.6-.071c-.149-.006-.023,1.3-.342,1.394a13.88,13.88,0,0,1-1.938.107A13.855,13.855,0,0,1,123.2,6.16c-.319-.087-.2-1.4-.35-1.392a1.98,1.98,0,0,0-.6.074c-.114.11.082,1.353.39,1.437.329.09,1,.02,1.167.266.146.215.281,2.25-.124,2.448-.463.226-1.563.349-1.912,1.359a9.965,9.965,0,0,0,.421,5.069c.617,1.859,1.905,2.812,1.806,3.768-.144,1.347-1.454,2.144-1.3,2.565s1.6.619,2.49.617,2.342-.219,2.486-.632-1.167-1.211-1.319-2.557c-.1-.956,1.178-1.917,1.784-3.779a9.964,9.964,0,0,0,.39-5.072" transform="translate(-120.141 -4.697)" fill="#c5cccf" fill-rule="evenodd" />
                            <path id="Tracé_4199" data-name="Tracé 4199" d="M127.967,21.6c.025.433-1.227.612-1.8.614s-1.826-.161-1.8-.6a1.823,1.823,0,0,1,3.6-.011m-1.812-3.371c-1.031,0-2.346-2.321-2.522-3.473a11.824,11.824,0,0,1-.008-4.284c.215-.644,1.42-1.186,1.518-1.3.293-.349.188-1.982.188-2.143,0-.27.659-.353.789-.354s.791.079.792.349c0,.161-.094,1.794.2,2.142.1.117,1.308.651,1.526,1.294a11.851,11.851,0,0,1,.018,4.284c-.17,1.153-1.471,3.484-2.5,3.488m3.343-7.82c-.355-1.006-1.455-1.123-1.919-1.347-.4-.195-.28-2.233-.135-2.449.167-.247.837-.181,1.168-.274.3-.085.493-1.33.38-1.439a1.931,1.931,0,0,0-.586-.069c-.146-.006-.021,1.3-.342,1.394a13.966,13.966,0,0,1-1.945.107,13.889,13.889,0,0,1-1.945-.095c-.321-.087-.2-1.4-.35-1.392a1.927,1.927,0,0,0-.586.073c-.113.11.083,1.353.388,1.436.331.091,1,.021,1.17.267.146.215.282,2.252-.121,2.45-.463.227-1.562.35-1.911,1.358a10.027,10.027,0,0,0,.411,5.047c.61,1.868,1.908,2.829,1.809,3.79-.143,1.347-1.448,2.145-1.3,2.565s1.586.618,2.485.616,2.343-.221,2.482-.631-1.167-1.211-1.318-2.557c-.1-.96,1.188-1.929,1.786-3.8a10.022,10.022,0,0,0,.38-5.049" transform="translate(-121.12 -4.774)" fill="#c3cace" fill-rule="evenodd" />
                            <path id="Tracé_4200" data-name="Tracé 4200" d="M128.949,21.648c.026.434-1.233.616-1.809.617s-1.835-.164-1.811-.606a1.829,1.829,0,0,1,3.62-.011m-1.821-3.333c-1.031,0-2.357-2.353-2.532-3.505a11.932,11.932,0,0,1-.011-4.291c.215-.644,1.428-1.187,1.527-1.3.293-.349.185-1.989.185-2.151,0-.269.666-.351.8-.351s.8.077.8.346c0,.161-.1,1.8.2,2.149.1.117,1.316.652,1.535,1.295a11.942,11.942,0,0,1,.015,4.29c-.168,1.153-1.48,3.517-2.512,3.521m3.332-7.855c-.354-1-1.453-1.121-1.917-1.346-.4-.195-.276-2.235-.132-2.451.167-.246.838-.181,1.171-.274.3-.084.491-1.329.378-1.437a1.873,1.873,0,0,0-.575-.068c-.144-.005-.019,1.3-.342,1.394a14,14,0,0,1-1.951.107,14.017,14.017,0,0,1-1.951-.094c-.323-.088-.206-1.4-.35-1.392a1.884,1.884,0,0,0-.575.072c-.113.109.084,1.352.386,1.435.333.091,1,.021,1.173.267.146.215.283,2.255-.117,2.452-.462.228-1.56.351-1.909,1.358a10.085,10.085,0,0,0,.4,5.025c.6,1.876,1.91,2.846,1.811,3.812-.141,1.346-1.441,2.146-1.3,2.564s1.573.617,2.481.614,2.344-.223,2.477-.629-1.167-1.211-1.316-2.556c-.1-.965,1.2-1.942,1.788-3.823a10.085,10.085,0,0,0,.37-5.027" transform="translate(-122.094 -4.824)" fill="#c2c9cd" fill-rule="evenodd" />
                            <path id="Tracé_4201" data-name="Tracé 4201" d="M129.925,21.728c.027.434-1.24.619-1.817.621s-1.844-.167-1.82-.61c.011-.216.629-1.591,1.813-1.594s1.811,1.368,1.824,1.584m-1.829-3.3c-1.031,0-2.369-2.386-2.541-3.538a12.015,12.015,0,0,1-.014-4.3c.215-.644,1.437-1.188,1.536-1.3.292-.349.182-2,.182-2.158,0-.269.673-.349.8-.349s.805.074.806.344c0,.161-.1,1.81.195,2.157.1.117,1.324.653,1.543,1.3a12.034,12.034,0,0,1,.012,4.3c-.167,1.153-1.489,3.55-2.521,3.553m3.321-7.891c-.353-1-1.452-1.12-1.915-1.346-.4-.194-.273-2.237-.128-2.453.167-.247.839-.181,1.174-.275.3-.084.489-1.328.376-1.436a1.837,1.837,0,0,0-.565-.067c-.142-.005-.018,1.3-.342,1.394a14.122,14.122,0,0,1-1.958.106,14.049,14.049,0,0,1-1.958-.094c-.325-.088-.209-1.4-.35-1.392a1.83,1.83,0,0,0-.564.07c-.112.109.085,1.352.384,1.434.336.092,1.008.022,1.175.268.146.215.285,2.257-.113,2.454-.462.228-1.559.353-1.907,1.357a10.143,10.143,0,0,0,.391,5c.595,1.886,1.912,2.863,1.813,3.833-.14,1.345-1.435,2.148-1.3,2.564s1.56.616,2.476.613,2.345-.225,2.473-.628-1.167-1.211-1.315-2.556c-.1-.97,1.207-1.955,1.791-3.844a10.148,10.148,0,0,0,.36-5.005" transform="translate(-123.061 -4.904)" fill="#c1c7cb" fill-rule="evenodd" />
                            <path id="Tracé_4202" data-name="Tracé 4202" d="M130.906,21.775c.028.435-1.246.623-1.826.625s-1.854-.171-1.828-.614c.012-.216.637-1.612,1.821-1.616s1.819,1.389,1.832,1.6m-1.837-3.259c-1.031,0-2.379-2.419-2.551-3.571a12.106,12.106,0,0,1-.017-4.3c.214-.644,1.445-1.189,1.544-1.306.293-.349.179-2.005.179-2.166,0-.27.68-.347.81-.347s.812.072.813.342c0,.161-.1,1.818.192,2.165.1.117,1.333.654,1.552,1.3a12.144,12.144,0,0,1,.009,4.3c-.165,1.153-1.5,3.583-2.531,3.586m3.31-7.927c-.353-1-1.451-1.119-1.914-1.345-.4-.194-.269-2.239-.124-2.455.167-.246.84-.181,1.177-.276.3-.083.486-1.327.374-1.435a1.787,1.787,0,0,0-.554-.066c-.139,0-.016,1.3-.342,1.394a14.159,14.159,0,0,1-1.964.107,14.2,14.2,0,0,1-1.965-.094c-.326-.089-.21-1.4-.35-1.392a1.781,1.781,0,0,0-.553.069c-.112.108.087,1.352.383,1.433.337.092,1.01.023,1.178.269.146.215.286,2.259-.109,2.455-.461.229-1.558.354-1.906,1.357a10.21,10.21,0,0,0,.381,4.981c.587,1.894,1.914,2.879,1.816,3.855-.139,1.345-1.429,2.149-1.3,2.563.126.4,1.546.614,2.471.611s2.346-.228,2.469-.626c.128-.415-1.166-1.212-1.314-2.556-.1-.975,1.217-1.968,1.793-3.866a10.211,10.211,0,0,0,.351-4.983" transform="translate(-124.034 -4.953)" fill="#c0c6ca" fill-rule="evenodd" />
                            <path id="Tracé_4203" data-name="Tracé 4203" d="M131.883,21.852c.029.436-1.253.627-1.834.629s-1.863-.174-1.836-.618c.012-.216.646-1.634,1.829-1.637s1.827,1.41,1.841,1.626m-1.846-3.222c-1.031,0-2.39-2.451-2.56-3.6a12.209,12.209,0,0,1-.02-4.309c.215-.644,1.454-1.189,1.552-1.306.292-.349.176-2.013.176-2.174,0-.27.687-.344.817-.345s.819.07.82.34c0,.161-.106,1.826.189,2.173.1.117,1.342.654,1.56,1.3a12.228,12.228,0,0,1,.006,4.309c-.164,1.153-1.508,3.615-2.54,3.619m3.3-7.962c-.352-1-1.45-1.118-1.912-1.345-.394-.193-.265-2.24-.121-2.457.167-.246.841-.182,1.18-.276.293-.082.484-1.327.373-1.434a1.747,1.747,0,0,0-.543-.064c-.137-.005-.014,1.3-.342,1.394A14.289,14.289,0,0,1,130,6.591,14.225,14.225,0,0,1,128.03,6.5c-.328-.089-.213-1.4-.35-1.392a1.747,1.747,0,0,0-.543.068c-.111.108.088,1.351.381,1.431.339.093,1.013.024,1.181.269.146.215.287,2.262-.106,2.457-.461.23-1.557.356-1.9,1.356a10.272,10.272,0,0,0,.372,4.959c.579,1.9,1.916,2.9,1.818,3.876-.138,1.344-1.423,2.151-1.3,2.563.12.394,1.533.613,2.467.61s2.346-.23,2.464-.625c.123-.413-1.166-1.212-1.313-2.555-.1-.98,1.227-1.981,1.8-3.887a10.274,10.274,0,0,0,.341-4.961" transform="translate(-125.002 -5.03)" fill="#bec5c9" fill-rule="evenodd" />
                            <path id="Tracé_4204" data-name="Tracé 4204" d="M132.878,21.905c.03.436-1.259.63-1.842.632s-1.872-.178-1.845-.621c.012-.216.654-1.656,1.838-1.659s1.835,1.432,1.849,1.648m-1.854-3.185c-1.031,0-2.4-2.483-2.57-3.636a12.3,12.3,0,0,1-.023-4.315c.215-.644,1.462-1.19,1.561-1.307.292-.349.173-2.021.173-2.182,0-.269.694-.342.824-.342s.826.068.827.337c0,.161-.109,1.834.186,2.181.1.117,1.35.655,1.569,1.3a12.325,12.325,0,0,1,0,4.315c-.162,1.154-1.517,3.648-2.549,3.651m3.288-8c-.352-1-1.448-1.117-1.91-1.345-.391-.193-.261-2.242-.117-2.458.166-.247.841-.182,1.182-.277.29-.081.482-1.326.371-1.432a1.7,1.7,0,0,0-.532-.063c-.135,0-.012,1.3-.342,1.394a14.372,14.372,0,0,1-1.977.106,14.354,14.354,0,0,1-1.978-.094c-.33-.09-.215-1.4-.35-1.392a1.687,1.687,0,0,0-.532.067c-.11.108.09,1.351.38,1.43.341.093,1.016.024,1.184.27.146.215.288,2.264-.1,2.459-.461.231-1.556.357-1.9,1.356a10.338,10.338,0,0,0,.361,4.937c.572,1.912,1.918,2.913,1.821,3.9-.137,1.343-1.417,2.152-1.3,2.562.115.391,1.52.611,2.462.609s2.347-.232,2.46-.623c.119-.411-1.166-1.212-1.312-2.555-.1-.984,1.237-1.993,1.8-3.909a10.338,10.338,0,0,0,.331-4.939" transform="translate(-125.989 -5.084)" fill="#bdc4c8" fill-rule="evenodd" />
                            <path id="Tracé_4205" data-name="Tracé 4205" d="M133.847,21.979c.031.437-1.265.634-1.85.636s-1.88-.181-1.853-.625c.013-.216.662-1.677,1.846-1.681s1.843,1.454,1.857,1.67m-1.862-3.148c-1.031,0-2.413-2.516-2.579-3.669a12.4,12.4,0,0,1-.026-4.321c.215-.644,1.471-1.191,1.569-1.308.293-.349.17-2.029.17-2.19,0-.269.7-.34.831-.34s.833.066.833.335c0,.161-.112,1.842.183,2.189.1.117,1.358.656,1.577,1.3a12.428,12.428,0,0,1,0,4.321c-.16,1.154-1.526,3.681-2.558,3.684m3.277-8.034c-.351-.994-1.447-1.115-1.909-1.344-.388-.192-.258-2.245-.113-2.461.166-.247.842-.182,1.185-.278.287-.08.48-1.325.37-1.431a1.654,1.654,0,0,0-.522-.062c-.133,0-.01,1.3-.342,1.393a14.407,14.407,0,0,1-1.984.106,14.386,14.386,0,0,1-1.984-.094c-.332-.09-.217-1.4-.35-1.391a1.639,1.639,0,0,0-.521.065c-.11.107.091,1.351.378,1.429.343.094,1.019.025,1.187.271.146.215.289,2.266-.1,2.461-.46.232-1.555.359-1.9,1.356a10.4,10.4,0,0,0,.352,4.914c.563,1.921,1.92,2.93,1.823,3.919-.136,1.343-1.411,2.154-1.3,2.562.11.387,1.507.61,2.458.607s2.347-.234,2.455-.622c.114-.409-1.166-1.212-1.31-2.554-.1-.989,1.247-2.006,1.8-3.931a10.4,10.4,0,0,0,.322-4.917" transform="translate(-126.95 -5.159)" fill="#bcc3c7" fill-rule="evenodd" />
                            <path id="Tracé_4206" data-name="Tracé 4206" d="M134.815,22.029c.031.438-1.271.638-1.859.64s-1.89-.184-1.862-.629c.014-.216.671-1.7,1.854-1.7s1.851,1.475,1.866,1.691m-1.87-3.111c-1.031,0-2.424-2.548-2.589-3.7a12.512,12.512,0,0,1-.029-4.327c.215-.644,1.479-1.192,1.578-1.309.293-.349.167-2.036.167-2.2,0-.269.708-.338.838-.338s.84.064.84.333c0,.162-.114,1.85.181,2.2.1.117,1.367.657,1.586,1.3a12.533,12.533,0,0,1,0,4.327c-.159,1.154-1.536,3.713-2.568,3.717m3.266-8.069c-.351-.993-1.446-1.114-1.907-1.344-.386-.192-.254-2.246-.109-2.462.166-.247.843-.182,1.188-.279.284-.079.478-1.324.368-1.43a1.61,1.61,0,0,0-.511-.061c-.13,0-.009,1.3-.341,1.394a14.538,14.538,0,0,1-1.991.106,14.52,14.52,0,0,1-1.991-.094c-.333-.091-.22-1.4-.35-1.392a1.6,1.6,0,0,0-.511.064c-.109.107.092,1.35.376,1.428.346.094,1.022.026,1.19.271.146.215.29,2.269-.094,2.463-.46.232-1.554.361-1.9,1.355a10.469,10.469,0,0,0,.342,4.892c.555,1.93,1.922,2.947,1.825,3.941-.135,1.342-1.4,2.155-1.294,2.561.1.384,1.493.609,2.453.606s2.348-.236,2.451-.62c.109-.407-1.166-1.213-1.309-2.554-.1-.994,1.258-2.019,1.8-3.952a10.472,10.472,0,0,0,.312-4.894" transform="translate(-127.91 -5.21)" fill="#bac1c6" fill-rule="evenodd" />
                            <path id="Tracé_4207" data-name="Tracé 4207" d="M135.783,22.107c.033.439-1.277.642-1.867.644s-1.9-.188-1.87-.633c.014-.216.678-1.72,1.863-1.724s1.859,1.5,1.874,1.712m-1.878-3.074c-1.031,0-2.435-2.58-2.6-3.734a12.61,12.61,0,0,1-.033-4.333c.215-.644,1.488-1.192,1.586-1.31.293-.349.165-2.045.164-2.205,0-.27.715-.336.845-.336s.846.061.847.331c0,.161-.117,1.858.178,2.2.1.117,1.375.658,1.594,1.3a12.624,12.624,0,0,1-.006,4.334c-.157,1.154-1.545,3.746-2.577,3.749m3.255-8.1c-.35-.991-1.444-1.113-1.905-1.343-.383-.191-.251-2.248-.106-2.464.166-.247.844-.182,1.191-.279.28-.078.475-1.324.366-1.429a1.571,1.571,0,0,0-.5-.06c-.128,0-.007,1.3-.341,1.393a14.626,14.626,0,0,1-2,.106,14.548,14.548,0,0,1-2-.094c-.335-.091-.222-1.4-.35-1.391a1.552,1.552,0,0,0-.5.063c-.109.106.093,1.35.374,1.427.348.1,1.025.026,1.193.272.146.215.292,2.271-.09,2.465-.46.233-1.552.362-1.9,1.355a10.542,10.542,0,0,0,.332,4.87c.547,1.939,1.924,2.963,1.828,3.963-.134,1.341-1.4,2.156-1.293,2.561.1.38,1.48.607,2.449.6s2.349-.239,2.446-.619c.1-.405-1.166-1.213-1.308-2.553-.1-1,1.268-2.031,1.8-3.974a10.548,10.548,0,0,0,.3-4.872" transform="translate(-128.87 -5.289)" fill="#b8c0c4" fill-rule="evenodd" />
                            <path id="Tracé_4208" data-name="Tracé 4208" d="M136.754,22.184c.033.439-1.283.646-1.875.648s-1.908-.191-1.878-.636c.015-.216.687-1.741,1.871-1.745s1.867,1.518,1.883,1.734m-1.887-3.037c-1.031,0-2.446-2.613-2.608-3.767a12.716,12.716,0,0,1-.036-4.34c.215-.644,1.5-1.193,1.6-1.311.293-.349.161-2.052.161-2.214,0-.269.722-.333.851-.334s.853.059.854.329c0,.161-.121,1.865.174,2.212.1.117,1.384.658,1.6,1.3a12.731,12.731,0,0,1-.009,4.339c-.156,1.155-1.554,3.779-2.586,3.782m3.245-8.14c-.35-.989-1.443-1.112-1.9-1.343-.381-.191-.247-2.25-.1-2.466.166-.247.845-.182,1.194-.28.277-.078.473-1.323.365-1.428a1.519,1.519,0,0,0-.49-.058c-.126,0-.005,1.3-.341,1.393a14.7,14.7,0,0,1-2,.106,14.678,14.678,0,0,1-2-.094c-.337-.092-.224-1.4-.35-1.391a1.515,1.515,0,0,0-.489.061c-.108.106.095,1.35.373,1.426.35.1,1.028.027,1.2.273.146.215.293,2.273-.087,2.466-.459.234-1.551.364-1.9,1.354a10.615,10.615,0,0,0,.322,4.848c.539,1.948,1.926,2.98,1.83,3.984-.133,1.341-1.392,2.158-1.291,2.561.094.376,1.467.606,2.444.6s2.349-.241,2.442-.618c.1-.4-1.166-1.213-1.307-2.553-.1-1,1.279-2.044,1.806-4a10.616,10.616,0,0,0,.292-4.85" transform="translate(-129.831 -5.366)" fill="#b7bfc3" fill-rule="evenodd" />
                            <path id="Tracé_4209" data-name="Tracé 4209" d="M137.715,22.233c.034.44-1.29.649-1.884.651s-1.917-.194-1.886-.64c.015-.216.7-1.763,1.879-1.767s1.875,1.539,1.891,1.755m-1.895-3c-1.031,0-2.457-2.645-2.618-3.8a12.816,12.816,0,0,1-.038-4.346c.215-.644,1.5-1.194,1.6-1.311.292-.349.158-2.06.158-2.221,0-.269.729-.331.859-.332s.86.057.861.326c0,.162-.123,1.873.172,2.221.1.117,1.392.659,1.611,1.3a12.83,12.83,0,0,1-.012,4.346c-.154,1.155-1.563,3.812-2.6,3.815m3.234-8.176c-.349-.987-1.441-1.111-1.9-1.342-.378-.19-.243-2.252-.1-2.468.166-.247.846-.182,1.2-.281.274-.077.471-1.322.363-1.427a1.471,1.471,0,0,0-.479-.057c-.123,0,0,1.3-.341,1.393a14.787,14.787,0,0,1-2.01.106,14.708,14.708,0,0,1-2.01-.094c-.339-.092-.227-1.4-.35-1.391a1.475,1.475,0,0,0-.478.06c-.108.106.1,1.35.371,1.425.352.1,1.031.027,1.2.273.146.215.294,2.276-.083,2.468-.459.235-1.55.365-1.894,1.354a10.688,10.688,0,0,0,.312,4.826c.53,1.957,1.928,3,1.832,4.006-.131,1.34-1.386,2.159-1.29,2.56.089.373,1.453.6,2.44.6s2.35-.243,2.437-.616c.094-.4-1.166-1.213-1.306-2.552-.1-1.008,1.29-2.057,1.808-4.017a10.69,10.69,0,0,0,.282-4.828" transform="translate(-130.785 -5.417)" fill="#b6bdc2" fill-rule="evenodd" />
                            <path id="Tracé_4210" data-name="Tracé 4210" d="M138.679,22.308c.035.441-1.3.653-1.892.655s-1.927-.2-1.895-.644c.015-.216.7-1.784,1.887-1.788s1.883,1.561,1.9,1.777m-1.9-2.962c-1.031,0-2.468-2.678-2.627-3.832a12.908,12.908,0,0,1-.042-4.352c.215-.644,1.513-1.2,1.612-1.312.292-.349.155-2.068.155-2.229,0-.269.736-.329.865-.33s.867.055.868.324c0,.161-.126,1.881.169,2.228.1.117,1.4.66,1.62,1.3a12.946,12.946,0,0,1-.015,4.352c-.153,1.155-1.573,3.844-2.605,3.848M140,11.134c-.349-.985-1.44-1.109-1.9-1.342-.375-.19-.239-2.254-.094-2.47.166-.247.847-.183,1.2-.281.271-.076.468-1.322.361-1.426a1.431,1.431,0,0,0-.467-.056c-.121,0,0,1.3-.342,1.393a14.883,14.883,0,0,1-2.017.106,14.852,14.852,0,0,1-2.017-.094c-.341-.093-.229-1.4-.35-1.391a1.426,1.426,0,0,0-.467.059c-.107.105.1,1.349.369,1.424.354.1,1.034.028,1.2.274.146.215.295,2.278-.079,2.47-.459.235-1.549.366-1.892,1.353a10.761,10.761,0,0,0,.3,4.8c.521,1.966,1.93,3.014,1.835,4.028-.13,1.339-1.38,2.16-1.289,2.56.084.369,1.44.6,2.435.6s2.351-.246,2.433-.615c.089-.4-1.166-1.213-1.3-2.552-.1-1.013,1.3-2.069,1.81-4.039A10.765,10.765,0,0,0,140,11.134" transform="translate(-131.74 -5.493)" fill="#b5bcc1" fill-rule="evenodd" />
                            <path id="Tracé_4211" data-name="Tracé 4211" d="M139.634,22.359c.036.441-1.3.657-1.9.659s-1.936-.2-1.9-.647c.016-.216.712-1.806,1.9-1.81s1.891,1.583,1.908,1.8m-1.911-2.925c-1.031,0-2.479-2.71-2.637-3.864a13.018,13.018,0,0,1-.045-4.358c.215-.644,1.522-1.2,1.62-1.313.292-.349.152-2.076.152-2.237,0-.269.742-.327.872-.327s.875.052.875.322c0,.161-.129,1.889.166,2.236.1.117,1.409.661,1.628,1.3a13.052,13.052,0,0,1-.018,4.358c-.151,1.155-1.582,3.877-2.614,3.88m3.212-8.247c-.348-.983-1.439-1.108-1.9-1.342-.373-.189-.236-2.256-.09-2.472.166-.247.848-.183,1.2-.282.268-.075.466-1.321.359-1.424a1.388,1.388,0,0,0-.457-.055c-.119,0,0,1.3-.342,1.393a14.959,14.959,0,0,1-2.023.106,14.884,14.884,0,0,1-2.023-.094c-.342-.093-.231-1.4-.35-1.391a1.387,1.387,0,0,0-.457.058c-.107.1.1,1.349.368,1.422.356.1,1.037.029,1.2.275.146.215.3,2.281-.075,2.472-.458.236-1.548.368-1.891,1.353a10.836,10.836,0,0,0,.292,4.782c.513,1.976,1.932,3.031,1.837,4.049-.129,1.338-1.374,2.162-1.288,2.559.079.365,1.427.6,2.431.6s2.351-.248,2.428-.613c.083-.4-1.166-1.214-1.3-2.551-.1-1.018,1.311-2.082,1.812-4.06a10.843,10.843,0,0,0,.263-4.784" transform="translate(-132.687 -5.544)" fill="#b3bbc0" fill-rule="evenodd" />
                            <path id="Tracé_4212" data-name="Tracé 4212" d="M140.6,22.436c.037.442-1.308.661-1.908.663s-1.945-.2-1.911-.651c.016-.216.72-1.828,1.9-1.832s1.9,1.6,1.916,1.82m-1.919-2.888c-1.031,0-2.49-2.742-2.646-3.9a13.132,13.132,0,0,1-.048-4.364c.215-.644,1.53-1.2,1.629-1.314.293-.349.15-2.084.149-2.245,0-.269.75-.325.88-.325s.881.05.882.32c0,.162-.132,1.9.163,2.244.1.117,1.418.661,1.636,1.3a13.144,13.144,0,0,1-.021,4.364c-.15,1.155-1.591,3.909-2.624,3.913m3.2-8.283c-.348-.981-1.438-1.107-1.9-1.341-.37-.189-.232-2.257-.086-2.473.166-.247.848-.183,1.206-.283.265-.074.464-1.32.358-1.423a1.34,1.34,0,0,0-.446-.054c-.117,0,0,1.3-.342,1.393a15.045,15.045,0,0,1-2.03.106,15.02,15.02,0,0,1-2.03-.093c-.344-.094-.233-1.4-.35-1.391a1.334,1.334,0,0,0-.446.057c-.106.1.1,1.349.366,1.421.358.1,1.04.029,1.208.275.146.215.3,2.283-.072,2.474-.458.237-1.547.369-1.889,1.352a10.922,10.922,0,0,0,.282,4.76c.5,1.985,1.935,3.048,1.84,4.071-.128,1.338-1.368,2.163-1.287,2.559.074.362,1.413.6,2.426.6s2.352-.25,2.424-.612c.078-.4-1.166-1.214-1.3-2.551-.1-1.023,1.323-2.094,1.814-4.082a10.919,10.919,0,0,0,.253-4.761" transform="translate(-133.641 -5.622)" fill="#b2babe" fill-rule="evenodd" />
                            <path id="Tracé_4213" data-name="Tracé 4213" d="M141.565,22.485c.038.442-1.314.665-1.917.666s-1.954-.208-1.92-.655c.017-.216.728-1.849,1.912-1.853s1.907,1.625,1.925,1.841m-1.927-2.851c-1.031,0-2.5-2.775-2.656-3.929a13.244,13.244,0,0,1-.051-4.37c.215-.644,1.539-1.2,1.637-1.315.293-.349.146-2.091.146-2.253,0-.269.756-.323.886-.323s.888.048.889.318c0,.161-.135,1.9.16,2.252.1.116,1.426.662,1.645,1.3a13.257,13.257,0,0,1-.024,4.37c-.148,1.156-1.6,3.943-2.633,3.946m3.19-8.318c-.347-.979-1.436-1.106-1.9-1.341-.368-.188-.228-2.26-.083-2.475.166-.247.849-.183,1.208-.284.263-.073.462-1.319.356-1.422a1.305,1.305,0,0,0-.435-.053c-.115,0,0,1.3-.342,1.393a15.123,15.123,0,0,1-2.037.105,15.092,15.092,0,0,1-2.036-.093c-.346-.095-.236-1.4-.35-1.391a1.288,1.288,0,0,0-.435.055c-.105.1.1,1.348.365,1.42.36.1,1.043.03,1.21.276.146.215.3,2.285-.068,2.476-.458.238-1.546.371-1.887,1.352a11.007,11.007,0,0,0,.272,4.738c.495,1.994,1.937,3.064,1.842,4.092-.127,1.337-1.361,2.164-1.285,2.558.069.358,1.4.6,2.422.6s2.352-.252,2.419-.61c.073-.395-1.165-1.214-1.3-2.551-.1-1.028,1.334-2.107,1.817-4.1a11.008,11.008,0,0,0,.243-4.739" transform="translate(-134.601 -5.672)" fill="#b0b9be" fill-rule="evenodd" />
                            <path id="Tracé_4214" data-name="Tracé 4214" d="M142.514,22.563c.039.443-1.32.668-1.925.67s-1.963-.211-1.928-.659c.017-.216.736-1.871,1.92-1.874s1.914,1.647,1.933,1.863m-1.936-2.814c-1.031,0-2.512-2.808-2.665-3.962a13.348,13.348,0,0,1-.054-4.376c.215-.644,1.547-1.2,1.646-1.316.292-.349.143-2.1.143-2.26,0-.269.763-.321.893-.321s.895.046.9.315c0,.162-.138,1.913.157,2.26.1.117,1.434.663,1.654,1.305a13.365,13.365,0,0,1-.027,4.376c-.147,1.156-1.61,3.975-2.642,3.979m3.179-8.354c-.346-.977-1.435-1.1-1.894-1.34-.365-.188-.224-2.261-.079-2.477.166-.247.85-.183,1.211-.284.259-.073.46-1.318.355-1.421a1.256,1.256,0,0,0-.425-.051c-.112,0,.006,1.3-.342,1.393a15.208,15.208,0,0,1-2.043.105,15.182,15.182,0,0,1-2.043-.093c-.348-.1-.238-1.395-.35-1.391a1.235,1.235,0,0,0-.424.054c-.105.1.1,1.348.363,1.419.362.1,1.046.031,1.213.277.146.215.3,2.288-.064,2.478-.457.239-1.545.372-1.886,1.352a11.087,11.087,0,0,0,.262,4.715c.485,2,1.939,3.081,1.844,4.114-.126,1.336-1.355,2.166-1.284,2.558.064.354,1.387.6,2.417.595s2.353-.255,2.415-.609c.068-.393-1.165-1.215-1.3-2.55-.1-1.032,1.345-2.119,1.819-4.125a11.092,11.092,0,0,0,.234-4.717" transform="translate(-135.542 -5.751)" fill="#afb8bd" fill-rule="evenodd" />
                            <path id="Tracé_4215" data-name="Tracé 4215" d="M143.47,22.616c.04.444-1.327.673-1.934.674s-1.972-.215-1.936-.662c.017-.216.745-1.892,1.928-1.9s1.922,1.669,1.941,1.884m-1.944-2.777c-1.031,0-2.523-2.84-2.674-3.995a13.447,13.447,0,0,1-.057-4.383c.215-.644,1.555-1.2,1.654-1.316.292-.349.141-2.107.14-2.268,0-.269.77-.318.9-.319s.9.044.9.313c0,.162-.141,1.921.154,2.268.1.117,1.443.663,1.662,1.306a13.481,13.481,0,0,1-.03,4.382c-.145,1.156-1.619,4.008-2.652,4.011m3.168-8.39c-.346-.975-1.434-1.1-1.892-1.34-.362-.187-.221-2.263-.075-2.479.166-.247.851-.183,1.214-.285.256-.072.457-1.318.353-1.42a1.212,1.212,0,0,0-.414-.05c-.11,0,.007,1.3-.342,1.393a15.3,15.3,0,0,1-2.05.105,15.212,15.212,0,0,1-2.049-.093c-.35-.1-.24-1.394-.35-1.391a1.2,1.2,0,0,0-.413.053c-.1.1.1,1.348.361,1.418.364.1,1.049.031,1.216.278.146.215.3,2.29-.06,2.479-.457.24-1.544.374-1.884,1.351a11.174,11.174,0,0,0,.253,4.693c.475,2.013,1.941,3.1,1.846,4.135-.125,1.336-1.349,2.167-1.283,2.558.059.351,1.374.6,2.413.593s2.353-.257,2.41-.608c.063-.391-1.165-1.215-1.3-2.55-.1-1.037,1.357-2.131,1.821-4.147a11.178,11.178,0,0,0,.224-4.695" transform="translate(-136.49 -5.804)" fill="#afb7bc" fill-rule="evenodd" />
                            <path id="Tracé_4216" data-name="Tracé 4216" d="M144.419,22.691c.041.444-1.333.676-1.942.678s-1.982-.218-1.945-.666c.018-.216.753-1.914,1.937-1.917s1.931,1.69,1.95,1.906m-1.952-2.74c-1.031,0-2.534-2.872-2.684-4.027a13.563,13.563,0,0,1-.06-4.389c.215-.644,1.564-1.2,1.663-1.317.292-.349.137-2.115.137-2.276,0-.269.777-.316.907-.316s.909.042.91.311c0,.161-.144,1.928.151,2.275.1.117,1.451.664,1.67,1.307a13.587,13.587,0,0,1-.033,4.389c-.144,1.156-1.629,4.04-2.661,4.044m3.157-8.425c-.345-.973-1.433-1.1-1.891-1.34-.36-.187-.217-2.265-.072-2.481.166-.247.852-.183,1.217-.286.253-.071.455-1.317.351-1.419a1.171,1.171,0,0,0-.4-.049c-.108,0,.009,1.295-.342,1.393a15.366,15.366,0,0,1-2.056.1,15.35,15.35,0,0,1-2.056-.092c-.351-.1-.243-1.394-.35-1.391a1.156,1.156,0,0,0-.4.052c-.1.1.106,1.347.359,1.416.366.1,1.052.032,1.219.278.146.215.3,2.292-.057,2.481-.456.24-1.542.376-1.882,1.351a11.265,11.265,0,0,0,.243,4.671c.466,2.022,1.943,3.115,1.849,4.158-.124,1.335-1.342,2.168-1.282,2.557.054.347,1.36.6,2.408.592s2.353-.259,2.406-.606c.058-.389-1.165-1.215-1.3-2.549-.1-1.042,1.369-2.144,1.823-4.169a11.265,11.265,0,0,0,.214-4.673" transform="translate(-137.431 -5.88)" fill="#adb6ba" fill-rule="evenodd" />
                            <path id="Tracé_4217" data-name="Tracé 4217" d="M145.368,22.74c.041.445-1.339.68-1.95.682s-1.991-.221-1.953-.67c.018-.216.761-1.935,1.945-1.939s1.938,1.712,1.958,1.927m-1.96-2.7c-1.031,0-2.545-2.9-2.694-4.06a13.679,13.679,0,0,1-.063-4.394c.215-.644,1.573-1.2,1.671-1.318.293-.349.135-2.123.134-2.284,0-.269.784-.314.914-.314s.916.039.917.309c0,.162-.147,1.936.148,2.283.1.117,1.46.665,1.679,1.308a13.7,13.7,0,0,1-.036,4.395c-.142,1.156-1.638,4.073-2.67,4.076m3.146-8.461c-.345-.971-1.431-1.1-1.889-1.339-.357-.186-.213-2.267-.068-2.483.166-.247.853-.183,1.22-.286.25-.07.453-1.316.349-1.417A1.129,1.129,0,0,0,145.774,6c-.105,0,.011,1.294-.342,1.393a15.456,15.456,0,0,1-2.063.1,15.389,15.389,0,0,1-2.063-.092c-.353-.1-.245-1.394-.35-1.391a1.11,1.11,0,0,0-.392.05c-.1.1.107,1.347.358,1.415.368.1,1.055.033,1.222.279.146.215.3,2.295-.053,2.483-.456.241-1.541.377-1.881,1.35a11.359,11.359,0,0,0,.233,4.649c.456,2.031,1.945,3.131,1.851,4.179-.123,1.334-1.336,2.17-1.281,2.557.049.343,1.347.594,2.4.59s2.354-.261,2.4-.6c.053-.387-1.165-1.215-1.3-2.549-.1-1.047,1.381-2.156,1.825-4.19a11.354,11.354,0,0,0,.2-4.65" transform="translate(-138.371 -5.931)" fill="#abb4b9" fill-rule="evenodd" />
                            <path id="Tracé_4218" data-name="Tracé 4218" d="M146.319,22.82c.042.446-1.345.684-1.958.686s-2-.225-1.961-.674c.019-.216.77-1.957,1.953-1.96s1.946,1.733,1.966,1.949m-1.968-2.666c-1.031,0-2.556-2.937-2.7-4.093a13.791,13.791,0,0,1-.066-4.4c.215-.644,1.581-1.2,1.68-1.319.292-.349.132-2.13.131-2.292,0-.269.791-.312.921-.312s.923.037.924.307c0,.162-.15,1.944.146,2.291.1.116,1.468.666,1.687,1.308a13.807,13.807,0,0,1-.039,4.4c-.141,1.157-1.647,4.106-2.679,4.109m3.135-8.5c-.344-.969-1.43-1.1-1.887-1.339-.355-.186-.21-2.269-.064-2.485.166-.247.854-.184,1.223-.287.247-.069.451-1.315.348-1.416a1.072,1.072,0,0,0-.382-.047c-.1,0,.013,1.294-.342,1.393a15.554,15.554,0,0,1-2.069.105,15.536,15.536,0,0,1-2.069-.092c-.355-.1-.247-1.394-.35-1.391a1.085,1.085,0,0,0-.381.049c-.1.1.108,1.347.356,1.414.37.1,1.058.034,1.225.28.146.215.3,2.3-.049,2.485-.456.242-1.54.379-1.879,1.35a11.441,11.441,0,0,0,.223,4.627c.446,2.041,1.947,3.148,1.853,4.2-.121,1.334-1.329,2.171-1.279,2.556.044.34,1.334.592,2.4.589s2.354-.264,2.4-.6c.047-.386-1.165-1.216-1.295-2.549-.1-1.052,1.393-2.168,1.827-4.212a11.449,11.449,0,0,0,.2-4.628" transform="translate(-139.314 -6.011)" fill="#aab3b8" fill-rule="evenodd" />
                            <path id="Tracé_4219" data-name="Tracé 4219" d="M147.267,22.9c.044.446-1.351.688-1.967.689s-2.009-.228-1.969-.677c.019-.216.778-1.978,1.961-1.982s1.954,1.755,1.975,1.97m-1.976-2.629c-1.031,0-2.567-2.969-2.713-4.125a13.912,13.912,0,0,1-.069-4.407c.215-.644,1.59-1.2,1.689-1.319.292-.349.128-2.138.128-2.3,0-.269.8-.31.928-.31s.93.035.93.3c0,.161-.152,1.952.143,2.3.1.116,1.477.666,1.7,1.309a13.937,13.937,0,0,1-.042,4.407c-.139,1.157-1.657,4.139-2.689,4.142m3.124-8.532c-.344-.967-1.429-1.1-1.886-1.338-.352-.185-.206-2.271-.06-2.487.166-.247.855-.184,1.226-.288.244-.068.448-1.314.346-1.415a1.033,1.033,0,0,0-.371-.045c-.1,0,.015,1.293-.341,1.393a15.644,15.644,0,0,1-2.076.1,15.609,15.609,0,0,1-2.076-.092c-.357-.1-.249-1.394-.35-1.391a1.03,1.03,0,0,0-.371.048c-.1.1.11,1.346.354,1.413.372.1,1.061.034,1.228.28.146.215.306,2.3-.045,2.487-.455.243-1.539.38-1.877,1.35a11.542,11.542,0,0,0,.213,4.6c.436,2.05,1.949,3.165,1.856,4.222-.12,1.333-1.323,2.172-1.278,2.556.039.336,1.321.591,2.394.588s2.354-.266,2.392-.6c.042-.384-1.165-1.216-1.293-2.548-.1-1.056,1.406-2.181,1.83-4.233a11.548,11.548,0,0,0,.185-4.606" transform="translate(-140.253 -6.087)" fill="#a8b2b7" fill-rule="evenodd" />
                            <path id="Tracé_4220" data-name="Tracé 4220" d="M148.211,22.945c.044.447-1.358.692-1.975.693s-2.019-.232-1.978-.681c.02-.216.786-2,1.969-2s1.962,1.776,1.983,1.991m-1.985-2.591c-1.031,0-2.578-3-2.722-4.158a14.033,14.033,0,0,1-.072-4.413c.215-.644,1.6-1.2,1.7-1.32.292-.349.126-2.146.125-2.308,0-.269.8-.307.935-.308s.937.033.937.3c0,.162-.155,1.96.14,2.307.1.116,1.485.668,1.7,1.31a14.041,14.041,0,0,1-.045,4.413c-.137,1.157-1.666,4.171-2.7,4.175m3.113-8.568c-.343-.965-1.427-1.1-1.884-1.338-.35-.185-.2-2.273-.057-2.489.166-.247.855-.184,1.229-.288.242-.067.446-1.314.345-1.414a.991.991,0,0,0-.36-.044c-.1,0,.016,1.293-.341,1.393a15.732,15.732,0,0,1-2.082.1,15.7,15.7,0,0,1-2.082-.092c-.358-.1-.251-1.393-.35-1.391a1,1,0,0,0-.36.047c-.1.1.111,1.346.352,1.412.374.1,1.064.035,1.231.281.146.215.307,2.3-.042,2.489-.455.244-1.538.381-1.875,1.349a11.64,11.64,0,0,0,.2,4.583c.426,2.059,1.951,3.182,1.858,4.244-.119,1.332-1.316,2.174-1.277,2.555.034.333,1.307.59,2.39.586s2.355-.268,2.387-.6c.037-.382-1.165-1.216-1.292-2.548-.1-1.061,1.418-2.193,1.832-4.255a11.651,11.651,0,0,0,.175-4.584" transform="translate(-141.189 -6.137)" fill="#a7b0b5" fill-rule="evenodd" />
                            <path id="Tracé_4221" data-name="Tracé 4221" d="M149.158,23.024c.045.448-1.364.7-1.983.7s-2.028-.235-1.986-.685c.02-.216.794-2.021,1.978-2.025s1.97,1.8,1.991,2.013m-1.993-2.554c-1.031,0-2.589-3.035-2.731-4.191a14.147,14.147,0,0,1-.076-4.419c.215-.644,1.607-1.2,1.706-1.321.292-.349.123-2.154.122-2.315,0-.269.812-.305.942-.306s.943.031.944.3c0,.162-.158,1.968.137,2.315.1.117,1.494.668,1.713,1.311a14.165,14.165,0,0,1-.048,4.419c-.136,1.157-1.675,4.2-2.707,4.207m3.1-8.6c-.343-.964-1.427-1.095-1.882-1.337-.347-.184-.2-2.274-.053-2.49.166-.247.856-.184,1.232-.29.238-.066.444-1.313.343-1.413a.95.95,0,0,0-.35-.043c-.1,0,.018,1.292-.341,1.393a15.868,15.868,0,0,1-2.089.1,15.781,15.781,0,0,1-2.089-.092c-.36-.1-.254-1.393-.35-1.391a.948.948,0,0,0-.349.045c-.1.1.112,1.346.351,1.411.376.1,1.066.035,1.234.282.146.215.308,2.3-.038,2.491-.454.244-1.537.383-1.874,1.348a11.746,11.746,0,0,0,.193,4.561c.415,2.069,1.953,3.2,1.86,4.265-.118,1.332-1.31,2.175-1.276,2.555.029.329,1.294.588,2.385.585s2.355-.27,2.382-.6c.032-.38-1.164-1.216-1.291-2.547-.1-1.067,1.431-2.205,1.834-4.277a11.754,11.754,0,0,0,.166-4.562" transform="translate(-142.128 -6.217)" fill="#a6afb5" fill-rule="evenodd" />
                            <path id="Tracé_4222" data-name="Tracé 4222" d="M150.1,23.072c.046.448-1.37.7-1.992.7s-2.037-.238-1.994-.689c.02-.216.8-2.043,1.986-2.047s1.978,1.819,2,2.035m-2-2.518c-1.031,0-2.6-3.067-2.741-4.223a14.262,14.262,0,0,1-.078-4.425c.215-.644,1.615-1.2,1.714-1.322.292-.348.12-2.162.119-2.323,0-.269.819-.3.949-.3s.95.029.951.3c0,.162-.161,1.976.134,2.323.1.117,1.5.669,1.721,1.312a14.279,14.279,0,0,1-.051,4.425c-.134,1.158-1.685,4.237-2.717,4.24m3.091-8.639c-.342-.962-1.425-1.094-1.881-1.337-.345-.183-.195-2.276-.049-2.492.166-.247.857-.184,1.235-.29.235-.066.442-1.312.341-1.412a.908.908,0,0,0-.339-.042c-.094,0,.02,1.292-.341,1.393a15.9,15.9,0,0,1-2.1.1,15.872,15.872,0,0,1-2.1-.092c-.362-.1-.256-1.392-.35-1.391a.91.91,0,0,0-.338.044c-.1.1.114,1.345.349,1.41.378.1,1.07.036,1.237.282.146.215.31,2.307-.034,2.493-.453.246-1.536.384-1.872,1.348a11.86,11.86,0,0,0,.183,4.539c.4,2.078,1.955,3.216,1.863,4.287-.117,1.331-1.3,2.176-1.275,2.554.025.325,1.281.587,2.381.583s2.356-.272,2.378-.6c.026-.378-1.164-1.216-1.289-2.547-.1-1.071,1.444-2.218,1.836-4.3a11.861,11.861,0,0,0,.156-4.54" transform="translate(-143.061 -6.266)" fill="#a5aeb3" fill-rule="evenodd" />
                            <path id="Tracé_4223" data-name="Tracé 4223" d="M151.035,23.148c.047.449-1.376.7-2,.7s-2.047-.242-2-.693c.021-.216.81-2.064,1.994-2.068s1.986,1.841,2.008,2.056m-2.009-2.48c-1.031,0-2.612-3.1-2.751-4.256a14.377,14.377,0,0,1-.081-4.431c.215-.644,1.624-1.205,1.722-1.323.293-.348.117-2.17.116-2.331,0-.269.825-.3.955-.3s.957.026.958.3c0,.162-.164,1.983.131,2.331.1.116,1.511.669,1.73,1.312a14.419,14.419,0,0,1-.054,4.431c-.133,1.158-1.694,4.269-2.726,4.273m3.08-8.675c-.342-.96-1.424-1.092-1.879-1.337-.342-.182-.191-2.278-.046-2.494.166-.247.858-.184,1.238-.291.232-.065.44-1.311.339-1.41a.866.866,0,0,0-.328-.041c-.091,0,.022,1.292-.341,1.392a15.814,15.814,0,0,1-2.1.1,15.946,15.946,0,0,1-2.1-.091c-.364-.1-.258-1.392-.35-1.391a.87.87,0,0,0-.328.043c-.1.1.115,1.345.348,1.409.38.1,1.072.037,1.239.283.146.215.311,2.31-.031,2.494-.453.247-1.534.386-1.87,1.348a11.967,11.967,0,0,0,.173,4.517c.393,2.087,1.957,3.232,1.865,4.308-.116,1.33-1.3,2.178-1.273,2.554.02.322,1.268.586,2.376.582s2.356-.275,2.374-.6c.021-.376-1.164-1.217-1.288-2.546-.1-1.076,1.458-2.23,1.839-4.32a11.968,11.968,0,0,0,.146-4.518" transform="translate(-143.989 -6.343)" fill="#a4aeb3" fill-rule="evenodd" />
                            <path id="Tracé_4224" data-name="Tracé 4224" d="M151.97,23.2c.048.45-1.383.706-2.008.708s-2.056-.245-2.011-.7c.021-.216.819-2.086,2-2.09s1.994,1.862,2.017,2.078m-2.018-2.443c-1.031,0-2.623-3.132-2.76-4.289a14.508,14.508,0,0,1-.085-4.437c.215-.643,1.632-1.206,1.731-1.323.293-.348.114-2.177.113-2.339,0-.269.833-.3.963-.3s.964.024.965.293c0,.162-.167,1.991.128,2.338.1.116,1.519.67,1.738,1.313a14.525,14.525,0,0,1-.057,4.437c-.131,1.158-1.7,4.3-2.735,4.305m3.069-8.71c-.341-.958-1.423-1.091-1.877-1.336-.34-.182-.187-2.28-.042-2.5.166-.247.859-.185,1.241-.292.229-.064.437-1.311.338-1.409a.827.827,0,0,0-.317-.04c-.089,0,.024,1.291-.341,1.393a15.95,15.95,0,0,1-2.108.1,16.04,16.04,0,0,1-2.108-.091c-.366-.1-.261-1.392-.35-1.391a.83.83,0,0,0-.317.042c-.1.1.116,1.345.346,1.407.382.1,1.075.038,1.242.284.146.215.312,2.312-.027,2.5-.453.248-1.533.387-1.869,1.347a12.082,12.082,0,0,0,.163,4.494c.382,2.1,1.96,3.249,1.867,4.33-.115,1.33-1.29,2.179-1.272,2.554.015.318,1.254.584,2.372.581s2.356-.277,2.369-.6c.016-.374-1.164-1.217-1.287-2.546-.1-1.081,1.471-2.242,1.841-4.342a12.082,12.082,0,0,0,.137-4.5" transform="translate(-144.915 -6.392)" fill="#a3acb1" fill-rule="evenodd" />
                            <path id="Tracé_4225" data-name="Tracé 4225" d="M152.9,23.273c.049.45-1.389.71-2.017.712s-2.065-.249-2.019-.7c.022-.216.827-2.107,2.011-2.111s2,1.884,2.025,2.1m-2.026-2.406c-1.031,0-2.634-3.164-2.77-4.321a14.638,14.638,0,0,1-.088-4.443c.215-.644,1.641-1.207,1.739-1.324.293-.348.111-2.185.11-2.347,0-.269.839-.3.969-.3s.971.022.972.291c0,.162-.17,2,.125,2.346.1.116,1.528.671,1.746,1.314a14.646,14.646,0,0,1-.06,4.444c-.13,1.158-1.712,4.335-2.744,4.338m3.058-8.746c-.341-.956-1.421-1.09-1.876-1.336-.337-.182-.184-2.282-.038-2.5.166-.247.86-.185,1.244-.292.226-.063.435-1.31.336-1.408a.786.786,0,0,0-.307-.038c-.087,0,.026,1.291-.341,1.392a16.053,16.053,0,0,1-2.115.1,16.132,16.132,0,0,1-2.115-.091c-.367-.1-.263-1.392-.35-1.391a.783.783,0,0,0-.306.04c-.1.1.118,1.344.344,1.406.384.1,1.078.038,1.246.284.146.215.313,2.314-.023,2.5-.452.249-1.532.389-1.867,1.347a12.194,12.194,0,0,0,.154,4.473c.371,2.106,1.961,3.266,1.87,4.352-.114,1.329-1.283,2.18-1.271,2.553.01.314,1.241.583,2.367.579s2.356-.279,2.365-.593c.01-.373-1.164-1.217-1.286-2.546-.1-1.086,1.484-2.254,1.843-4.363a12.2,12.2,0,0,0,.127-4.473" transform="translate(-145.835 -6.47)" fill="#a2abb1" fill-rule="evenodd" />
                            <path id="Tracé_4226" data-name="Tracé 4226" d="M153.779,23.323c.05.451-1.395.714-2.025.716s-2.074-.252-2.028-.7c.022-.216.835-2.129,2.019-2.133s2.01,1.905,2.034,2.121m-2.034-2.369c-1.031,0-2.645-3.2-2.779-4.354a14.759,14.759,0,0,1-.091-4.449c.215-.644,1.649-1.208,1.748-1.325.293-.348.108-2.193.107-2.355,0-.269.846-.295.976-.295s.978.02.979.289c0,.162-.173,2.007.122,2.354.1.116,1.536.672,1.755,1.315a14.774,14.774,0,0,1-.063,4.449c-.128,1.159-1.722,4.368-2.754,4.371m3.047-8.782c-.34-.954-1.42-1.089-1.874-1.335-.335-.181-.18-2.284-.035-2.5.166-.247.861-.185,1.247-.293.223-.062.433-1.309.334-1.407a.752.752,0,0,0-.3-.037c-.084,0,.027,1.29-.341,1.393a16.125,16.125,0,0,1-2.121.1,16.227,16.227,0,0,1-2.121-.091c-.369-.1-.265-1.391-.35-1.391a.75.75,0,0,0-.3.039c-.1.1.119,1.344.343,1.405.386.106,1.081.039,1.248.285.146.215.314,2.317-.019,2.5-.452.249-1.531.391-1.865,1.346a12.262,12.262,0,0,0,.144,4.45c.356,2.116,1.964,3.283,1.872,4.374-.112,1.328-1.276,2.182-1.27,2.553.006.311,1.228.581,2.363.578s2.356-.281,2.36-.592c0-.371-1.164-1.218-1.285-2.545-.1-1.09,1.5-2.266,1.845-4.385a12.269,12.269,0,0,0,.117-4.451" transform="translate(-146.707 -6.521)" fill="#a0aaaf" fill-rule="evenodd" />
                            <path id="Tracé_4227" data-name="Tracé 4227" d="M154.76,23.375c.051.451-1.4.718-2.034.72s-2.085-.255-2.036-.708c.022-.216.843-2.15,2.028-2.154s2.018,1.926,2.042,2.142m-2.043-2.332c-1.031,0-2.656-3.229-2.788-4.387a14.88,14.88,0,0,1-.094-4.455c.215-.644,1.658-1.209,1.756-1.326.292-.349.1-2.2.1-2.362,0-.269.853-.292.983-.292s.985.017.986.287c0,.162-.176,2.015.119,2.362.1.117,1.545.673,1.764,1.315a14.911,14.911,0,0,1-.067,4.456c-.127,1.159-1.731,4.4-2.763,4.4m3.036-8.817c-.339-.952-1.419-1.088-1.872-1.335-.332-.181-.176-2.285-.031-2.5.167-.246.862-.185,1.249-.293.22-.062.431-1.309.333-1.406a.71.71,0,0,0-.285-.036c-.082,0,.029,1.289-.341,1.392a16.338,16.338,0,0,1-2.128.1,16.363,16.363,0,0,1-2.128-.091c-.371-.1-.267-1.391-.35-1.391a.7.7,0,0,0-.284.038c-.1.1.12,1.344.341,1.4.388.106,1.083.04,1.251.286.146.216.315,2.319-.016,2.5-.451.25-1.53.392-1.864,1.346a12.451,12.451,0,0,0,.134,4.428c.348,2.125,1.966,3.3,1.875,4.395-.112,1.328-1.27,2.183-1.269,2.552,0,.307,1.214.58,2.358.576s2.357-.283,2.356-.59c0-.369-1.164-1.218-1.283-2.545-.1-1.1,1.512-2.279,1.847-4.406a12.453,12.453,0,0,0,.107-4.429" transform="translate(-147.68 -6.573)" fill="#9ea8ae" fill-rule="evenodd" />
                            <path id="Tracé_4228" data-name="Tracé 4228" d="M298.662,428.539a2.02,2.02,0,0,0-2.513.007c-.368.354-.174,4.371.109,5.1a1.167,1.167,0,1,0,2.326-.007c.278-.732.448-4.75.078-5.1" transform="translate(-292.393 -422.965)" fill="#ebf0f3" fill-rule="evenodd" />
                            <path id="Tracé_4229" data-name="Tracé 4229" d="M305.256,434.16a1.908,1.908,0,0,0-2.376.007c-.346.332-.165,4.052.1,4.729a1.1,1.1,0,1,0,2.2-.007c.263-.678.422-4.4.074-4.73" transform="translate(-299.056 -428.542)" fill="#edf1f4" fill-rule="evenodd" />
                            <path id="Tracé_4230" data-name="Tracé 4230" d="M311.863,439.779a1.8,1.8,0,0,0-2.238.007c-.323.31-.156,3.734.1,4.357a1.036,1.036,0,1,0,2.072-.006c.247-.625.4-4.051.07-4.358" transform="translate(-305.732 -434.117)" fill="#eef2f5" fill-rule="evenodd" />
                            <path id="Tracé_4231" data-name="Tracé 4231" d="M318.459,445.4a1.706,1.706,0,0,0-1.052-.365,1.689,1.689,0,0,0-1.049.371c-.3.289-.146,3.415.09,3.986a.972.972,0,1,0,1.944-.006c.232-.572.369-3.7.066-3.986" transform="translate(-312.396 -439.696)" fill="#f0f4f5" fill-rule="evenodd" />
                            <path id="Tracé_4232" data-name="Tracé 4232" d="M325.057,451.025a1.621,1.621,0,0,0-.982-.341,1.6,1.6,0,0,0-.981.347c-.276.266-.137,3.1.085,3.614a.909.909,0,0,0,1.817-.005c.217-.518.343-3.353.062-3.614" transform="translate(-319.063 -445.273)" fill="#f1f4f6" fill-rule="evenodd" />
                            <path id="Tracé_4233" data-name="Tracé 4233" d="M331.651,456.648a1.536,1.536,0,0,0-.914-.318,1.506,1.506,0,0,0-.912.323,7.792,7.792,0,0,0,.078,3.242.846.846,0,0,0,1.69-.005,7.741,7.741,0,0,0,.058-3.243" transform="translate(-325.726 -450.853)" fill="#f4f6f7" fill-rule="evenodd" />
                            <path id="Tracé_4234" data-name="Tracé 4234" d="M338.259,462.268a1.455,1.455,0,0,0-.845-.294,1.416,1.416,0,0,0-.843.3,6.669,6.669,0,0,0,.072,2.87.785.785,0,0,0,1.562,0,6.6,6.6,0,0,0,.054-2.871" transform="translate(-332.403 -456.428)" fill="#f5f7f8" fill-rule="evenodd" />
                            <path id="Tracé_4235" data-name="Tracé 4235" d="M344.852,467.891a1.372,1.372,0,0,0-.776-.271,1.326,1.326,0,0,0-.775.276,5.566,5.566,0,0,0,.066,2.5.726.726,0,0,0,1.435,0,5.5,5.5,0,0,0,.05-2.5" transform="translate(-339.065 -462.006)" fill="#f8f9fa" fill-rule="evenodd" />
                            <path id="Tracé_4236" data-name="Tracé 4236" d="M351.45,473.514a1.293,1.293,0,0,0-.707-.248,1.235,1.235,0,0,0-.706.252,4.489,4.489,0,0,0,.059,2.127.67.67,0,0,0,1.308,0,4.421,4.421,0,0,0,.046-2.127" transform="translate(-345.732 -467.585)" fill="#f9fafb" fill-rule="evenodd" />
                            <path id="Tracé_4237" data-name="Tracé 4237" d="M358.038,479.132a1.217,1.217,0,0,0-.638-.224,1.152,1.152,0,0,0-.637.228,3.452,3.452,0,0,0,.053,1.755.619.619,0,0,0,1.18,0,3.38,3.38,0,0,0,.042-1.755" transform="translate(-352.388 -473.159)" fill="#fafcfc" fill-rule="evenodd" />
                            <path id="Tracé_4238" data-name="Tracé 4238" d="M364.644,484.754a1.147,1.147,0,0,0-.569-.2,1.067,1.067,0,0,0-.569.2,2.462,2.462,0,0,0,.047,1.384.577.577,0,0,0,1.053,0,2.394,2.394,0,0,0,.038-1.384" transform="translate(-359.063 -478.736)" fill="#fcfcfc" fill-rule="evenodd" />
                            <path id="Tracé_4239" data-name="Tracé 4239" d="M371.236,490.376a1.084,1.084,0,0,0-.5-.178.982.982,0,0,0-.5.181,1.547,1.547,0,0,0,.041,1.012.555.555,0,0,0,.926,0,1.5,1.5,0,0,0,.034-1.012" transform="translate(-365.724 -484.314)" fill="#fff" fill-rule="evenodd" />
                        </g>
                    </g>
                    <g id="Groupe_6693" data-name="Groupe 6693" transform="translate(1.501 1.969)">
                        <g id="Groupe_6692" data-name="Groupe 6692" clip-path="url(#clip-path-2)">
                            <rect id="Rectangle_2647" data-name="Rectangle 2647" width={width} height={height} transform="matrix(0.999, -0.055, 0.055, 0.999, -0.465, 0.009)" fill="#ebf0f3" />
                        </g>
                    </g>
                    <g id="Groupe_6695" data-name="Groupe 6695" transform="translate(6.333 1.969)">
                        <g id="Groupe_6694" data-name="Groupe 6694" clip-path="url(#clip-path-3)">
                            <rect id="Rectangle_2648" data-name="Rectangle 2648" width={width} height={height} transform="translate(-0.171 3.121) rotate(-86.872)" fill="#ebf0f3" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
};

export default EuroCup;