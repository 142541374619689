import WrapperSelect from 'components/small/wrapper-select/WrapperSelect';
import { NONE, WRAPPER_SELECT_TYPE } from 'config/_const';
import * as React from 'react';
import useSportsTeams from 'hooks/sports/use-sports-teams/useSportsTeams';
import { ISelectFieldProps } from 'interfaces/ISelectFieldProps';

const SelectSportsCountriesField: React.FC<ISelectFieldProps> = (fieldProps) => {
  const { fetchSportsTeamsByCountry } = useSportsTeams();

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const currentSelectedValue: string = event.target.value;
      if (currentSelectedValue && currentSelectedValue !== NONE) {
        fetchSportsTeamsByCountry(currentSelectedValue);
      }

    fieldProps.input.onChange(event);
  }


  return (
    <WrapperSelect
      valueChange={handleSelectChange}
      propsReduxForm={fieldProps}
      customValue=""
      data={fieldProps.options}
      type={WRAPPER_SELECT_TYPE.NATIVE} />
  )
};

export default React.memo(SelectSportsCountriesField);
