import { Skeleton } from '@mui/material';
import { transactionsAsViewModelSelector } from 'components/medium/transactions/AllTransactionsSelector';
import IFirstDepositAchievementProps from 'components/small/first-deposit-achievement/IFirstDepositAchievementProps';
import usePrevious from 'hooks/usePrevious';
import ReduxStoreInterface from 'model/ReduxStoreInterface';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllTransactions } from 'service/transactions/actions';
import TransactionViewModel from 'view-model/TransactionViewModel/TransactionViewModel';
import WithFirstDepositAchievementProps from './WithFirstDepositAchievementProps';
import useFirstDepositAchievement from 'hooks/first-deposit-achievement/useFirstDepositAchievement';

const withFirstDepositAchievement = (Component: React.FC<IFirstDepositAchievementProps>): React.FC<WithFirstDepositAchievementProps> => {
    const MemoComponent: React.FC<IFirstDepositAchievementProps> = React.memo(Component);

    const WrapperComponent: React.FC<WithFirstDepositAchievementProps> = ({ t: translate }) => {
        const dispatch = useDispatch();
        const isTransactionsStateLoading = useSelector((state: ReduxStoreInterface) => state.transactions.loading);
        const userAllTransactionsList: TransactionViewModel[] = useSelector(transactionsAsViewModelSelector);
        const previousIsTransactionsStateLoading = usePrevious(isTransactionsStateLoading);
        const [isTransactionsLoaded, setIsTransactionsLoaded] = React.useState<boolean>(false);
        const { firstDepositAchievementVM: firstDepositAchievementVM } = useFirstDepositAchievement(userAllTransactionsList);

        useEffect(() => {
            dispatch(fetchAllTransactions(1));
        }, [dispatch]);

        useEffect(() => {
            if (!isTransactionsStateLoading && previousIsTransactionsStateLoading)
                setIsTransactionsLoaded(true);
        }, [isTransactionsStateLoading, previousIsTransactionsStateLoading]);

        if (!firstDepositAchievementVM.GiftAtFirstDepositIsEnabled)
            return <></>;

        if (!isTransactionsLoaded)
            return (
                <div className='loading-skeleton'>
                    <Skeleton variant="text" width={"100%"} height={"200px"} />
                </div>
            );

        return <MemoComponent translate={translate} firstDepositAchievementVM={firstDepositAchievementVM} />;
    }

    return React.memo(WrapperComponent);
}

export default withFirstDepositAchievement;