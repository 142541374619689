import Application from 'Application';
import ReactPixel from 'react-facebook-pixel';
import IMarketPixelHandler from 'service/pixel/IMarketPixelHandler';
import IPixelHandler from "../pixel/IPixelHandler";
import IFacebookPixelPurchaseData from "./IFacebookPixelPurchaseData";

export default class PixelHandlerBuyOnSecondaryMarket implements IPixelHandler, IMarketPixelHandler {
    private trackOnStandardEvent: boolean;
    private trackOnTheFirstPayment: boolean;
    private _purchaseData: IFacebookPixelPurchaseData;
    private static customFacebookEventName: string = "Purchase_SecondaryMarket";
    private static standardFacebookEventName: string = "Purchase";
    private static isFirstPayment: string = 'First Purchase';

    constructor(trackOnStandardEvent: boolean, trackOnTheFirstPayment: boolean = false) {
        this._purchaseData = {
            currency: Application.getInstance().currency,
            value: 0,
            quantity: 0,
            assetName: 'FAKE',
            userEmail: 'FAKE',
            userId: 'FAKE'
        }
        this.trackOnTheFirstPayment = trackOnTheFirstPayment;
        this.trackOnStandardEvent = trackOnStandardEvent;
    }

    setPurchaseData(data: IFacebookPixelPurchaseData): void {
        this._purchaseData = data;
    }

    public get purchageData() {
        return this._purchaseData;
    }

    public set purchaseData(data: IFacebookPixelPurchaseData) {
        this._purchaseData = data;
    }

    handle(): void {
        ReactPixel.trackCustom(PixelHandlerBuyOnSecondaryMarket.customFacebookEventName, this._purchaseData);
        if (this.trackOnStandardEvent)
            ReactPixel.track(PixelHandlerBuyOnSecondaryMarket.standardFacebookEventName, this._purchaseData);
        if (this.trackOnTheFirstPayment)
            ReactPixel.track(PixelHandlerBuyOnSecondaryMarket.isFirstPayment, this._purchaseData);
    }
}