import i18n from '18n';
import Application from 'Application';
import { DEFAULT_ID_AS_STRING, EmptyString, TOURNAMENT_CATEGORY, TOURNAMENT_STATE } from 'config/_const';
import { TournamentEntryType, TournamentLeagues, TournamentProgress } from 'config/_enums';
import moment from 'moment';
import { IInternalTournament } from 'service/tournament/types';

export default class TournamentViewModel {
    private _id: string;
    private label: string;
    private startDate: string;
    private endDate: string;
    private subscriptionEndDate: string;
    private enabled: boolean;
    private prizePool: number;
    private state: string;
    private tournamentProgress: TournamentProgress;
    private category: string;
    private entryType: TournamentEntryType;
    private leaguesPriceEntry: Record<TournamentLeagues, number>;

    constructor(tournament: Partial<IInternalTournament>) {
        this._id = tournament._id ?? DEFAULT_ID_AS_STRING;
        this.label = tournament.label ?? EmptyString;
        this.startDate = tournament.startDate ?? new Date().toISOString();
        this.endDate = tournament.endDate ?? new Date().toISOString();
        this.subscriptionEndDate = tournament.subscriptionEndDate ?? new Date().toISOString();
        this.enabled = tournament.enabled ?? true;
        this.prizePool = tournament.prizePool ?? Application.getInstance().defaultPrizePool;
        this.state = tournament.state ?? TOURNAMENT_STATE.NONE;
        this.tournamentProgress = tournament.tournamentProgress ?? TournamentProgress.PREVIOUS;
        this.category = tournament.category ?? TOURNAMENT_CATEGORY.DEFAULT;
        this.entryType = tournament.entryType ?? TournamentEntryType.FREE;
        this.leaguesPriceEntry = tournament.leaguesPriceEntry || { Believers: 0, Champions: 0, Contenders: 0, Legends: 0, Managers: 0, Rookies: 0 };
    }

    public get Category(): string {
        return this.category;
    }

    public get Id(): string {
        return this._id;
    }

    public get Label(): string {
        return this.label;
    }

    public get StartDate(): string {
        return this.startDate;
    }

    public get EndDate(): string {
        return this.endDate;
    }

    public get SubscriptionEndDate(): string {
        return this.subscriptionEndDate;
    }

    public get Enabled(): boolean {
        return this.enabled;
    }

    public get PrizePool(): number {
        return this.prizePool;
    }

    public get State(): string {
        return this.state;
    }

    public get TournamentProgress(): TournamentProgress {
        return this.tournamentProgress;
    }

    public get EntryType(): TournamentEntryType {
        return this.entryType;
    }

    public isSubscriptionOpen(): boolean {
        return this.Enabled && this.State === TOURNAMENT_STATE.PENDING && moment().isBefore(moment(this.SubscriptionEndDate));
    }

    public isOpen(): boolean {
        const isTournamentInProgress: boolean = moment().isBetween(moment(this.SubscriptionEndDate), moment(this.EndDate));
        return this.State === TOURNAMENT_STATE.OPENED && isTournamentInProgress;
    }

    public isClose(): boolean {
        return this.State === TOURNAMENT_STATE.CLOSED && this.IsEndDatePassed;
    }

    public get IsEndDatePassed(): boolean {
        return moment(new Date()).isAfter(this.EndDate);
    }

    public get IsTournamentDefault(): boolean {
        return this.Category === TOURNAMENT_CATEGORY.DEFAULT;
    }

    public get CategoryInLocaleLowerCase(): string {
        return this.Category.toLowerCase();
    }

    public get IsPersonalTournament(): boolean {
        return this.TournamentProgress === TournamentProgress.PERSONAL_TOURNAMENT;
    }

    public getTournamentCategory(): string {
        return i18n.t(`tournament.category.${this.category.toLowerCase()}`);
    }

    public get HasEntryFee(): boolean {
        return this.entryType === TournamentEntryType.PAID;
    }

    public getLeaguePriceEntry(league: TournamentLeagues): number {
        return this.leaguesPriceEntry[league] || 0;
    }
}
