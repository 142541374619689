import GiftSolid from 'components/icons/referral/GiftSolid';
import { GODFATHER_REFERRAL } from 'config/_const';

import React from 'react';
import "./ReferYourFriendsTitle.scss";
import IReferFriendsTitle from './ReferYourFriendsTitleProps';
import { Trans } from 'react-i18next';

const ReferYourFriendsTitle: React.FC<IReferFriendsTitle> = (props) => {
    return (
        <div className='referYourFriends-title'>
            <div className='refer-title-icon'>
                <GiftSolid />
            </div>
            <div className="refer-title-info">
                {props.extraTitle
                    ? <h3>{props.extraTitle}</h3>
                    : null
                }
                <p><Trans i18nKey={'Aboutus.tab.about.referyourfriend'}  values={{ percent: GODFATHER_REFERRAL.percentageDueByGodson }} components={{ b: <strong /> }}/></p>
            </div>
        </div>
    )
}

export default ReferYourFriendsTitle;