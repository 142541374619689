import { TextareaAutosize } from '@material-ui/core';
import clsx from 'clsx';
import GreenButton from 'components/small/buttons/green-button/GreenButton';
import SecondaryButton from 'components/small/buttons/secondary-button/SecondaryButton';
import CheckIcon from 'components/small/check-icon/CheckIcon';
import { DEFAULT_AVATAR, WITHDRAWS_TYPES, WITHDRAW_STATUS } from 'config/_const';
import IUseWithdrawRequestProfil from 'hooks/use-withdraw-request-profil/IUseWithdrawRequestProfil';
import useWithdrawRequestProfil from 'hooks/use-withdraw-request-profil/useWithdrawRequestProfil';
import i18next from 'i18next';
import RouteParamProps from 'model/RouteParamsProps';
import React from 'react';
import './WithdrawRequestProfil.scss';

const WithdrawRequestProfil = (props: RouteParamProps) => {
    const { withdrawData, withdrawLoading, onRejectWithdraw, onAcceptWithdraw, onFieldClick, rejectionMessage, setRejectionMessage, refusedMode, setRefusedMode }: IUseWithdrawRequestProfil = useWithdrawRequestProfil({ withdrawRequestId: props.match.params.withdrawRequetsId });

    return (
        <div className="withdraw-request-profil">
            {!withdrawData && !withdrawLoading && (
                <h1>{i18next.t('withdraw.request.no-withdraw-find')}</h1>
            )}
            {withdrawData && (
                <div className="withdraw-card">
                    <div className="container-status-withdraw">
                        <h3>{withdrawData.status}</h3>
                        {withdrawData.status === WITHDRAW_STATUS.ACCEPTED && (
                            <CheckIcon />
                        )}
                    </div>
                    <hr className="divider" />
                    <div className="container-information">
                        <div className="container-icon">
                            <img
                                src={withdrawData.userId.avatar || DEFAULT_AVATAR}
                                alt="Avatar" />
                        </div>
                        <div>
                            <h3>{withdrawData.userId.firstName} {withdrawData.userId.lastName}</h3>
                            <p>{i18next.t('withdraw.request.user-identity-status', { status: withdrawData.userId.isConfirmed })}</p>
                            <p>{i18next.t('withdraw.request.total-credits-debited', { amount: withdrawData.creditsToWithdraw.toCurrency(2, withdrawData.currency) })}</p>
                            <p>{i18next.t('withdraw.request.credits-to-superadmin', { amount: withdrawData.fees.toCurrency(2, withdrawData.currency) })}</p>

                        </div>
                    </div>
                    <hr className="divider" />
                    <div className="container-withdraw-information">
                        <p>{i18next.t('withdraw.request.amount-to-send')}</p>
                        <h1 className={clsx('', {
                            success: withdrawData.status === WITHDRAW_STATUS.ACCEPTED,
                            error: withdrawData.status === WITHDRAW_STATUS.REJECTED,
                            warning: withdrawData.status === WITHDRAW_STATUS.OPEN,
                        })}>{(withdrawData.creditsToWithdraw - withdrawData.fees).toCurrency(2, withdrawData.currency)}</h1>
                        <br />
                        <div className="withdraw-information">
                            <h3>{i18next.t('withdraw.request.information')}</h3>
                            {withdrawData.type === WITHDRAWS_TYPES.SEPA ? (
                                <p onClick={() => onFieldClick(withdrawData.IBAN)}>{i18next.t('withdraw.request.sepa.user-iban', { iban: withdrawData.IBAN })}</p>
                            ) : (
                                <>
                                    <p className="field" onClick={() => onFieldClick(withdrawData.nonSepaBankInformation.routingNumber)}>{i18next.t('withdraw.request.no-sepa.routingNumber', { routingNumber: withdrawData.nonSepaBankInformation.routingNumber })}</p>
                                    <p className="field" onClick={() => onFieldClick(withdrawData.nonSepaBankInformation.accountNumber)}>{i18next.t('withdraw.request.no-sepa.accountNumber', { accountNumber: withdrawData.nonSepaBankInformation.accountNumber })}</p>
                                    <p className="field" onClick={() => onFieldClick(withdrawData.nonSepaBankInformation.bicOrSwift)}>{i18next.t('withdraw.request.no-sepa.bicOrSwift', { bicOrSwift: withdrawData.nonSepaBankInformation.bicOrSwift })}</p>
                                    <p className="field" onClick={() => onFieldClick(withdrawData.nonSepaBankInformation.bankName)}>{i18next.t('withdraw.request.no-sepa.bankName', { bankName: withdrawData.nonSepaBankInformation.bankName })}</p>
                                    <p className="field" onClick={() => onFieldClick(withdrawData.nonSepaBankInformation.bankAddress)}>{i18next.t('withdraw.request.no-sepa.bankAddress', { bankAddress: withdrawData.nonSepaBankInformation.bankAddress })}</p>
                                    <p className="warning">{i18next.t('withdraw.request.no-sepa.warning')}</p>
                                </>
                            )}
                            {withdrawData?.userId?.phone &&
                                <p onClick={() => onFieldClick(withdrawData.userId.phone ?? "")}>{i18next.t('withdraw.request.phone', { phone: withdrawData.userId.phone })}</p>
                            }
                        </div>
                    </div>
                    <p className="warning">{i18next.t('withdraw.request.warning.message')}</p>
                    {withdrawData.status !== WITHDRAW_STATUS.ACCEPTED && withdrawData.status !== WITHDRAW_STATUS.REJECTED && !refusedMode && (
                        <div className="action-buttons-withdraw">
                            <div className="button">
                                <GreenButton
                                    disabled={false}
                                    receiveClickEvent={false}
                                    textButton={i18next.t('withdraw.request.accept-request')}
                                    handleClick={() => onAcceptWithdraw()}
                                    type="button" />
                            </div>
                            <div className="button">
                                <SecondaryButton
                                    disabled={false}
                                    receiveClickEvent={false}
                                    textButton={i18next.t('withdraw.request.refuse-request')}
                                    handleClick={() => setRefusedMode(true)}
                                    type="button" />
                            </div>
                        </div>)}
                    {refusedMode && withdrawData.status !== WITHDRAW_STATUS.REJECTED && (
                        <div className="refusal-form">
                            <label>{i18next.t('withdraw.request.refuse.reason')}</label>
                            <div>
                                <TextareaAutosize
                                    style={{
                                        width: '100%',
                                        marginBottom: '30px',
                                        height: '100px',
                                    }}
                                    rowsMax={5}
                                    value={rejectionMessage}
                                    onChange={(e: any) => setRejectionMessage(e.target.value)} />
                            </div>
                            <div className="action-buttons-withdraw">
                                <div className="button">
                                    <GreenButton
                                        disabled={false}
                                        receiveClickEvent={false}
                                        textButton={i18next.t('withdraw.request.validate-refuse')}
                                        handleClick={() => onRejectWithdraw()}
                                        type="button" />
                                </div>
                                <div className="button">
                                    <SecondaryButton
                                        disabled={false}
                                        receiveClickEvent={false}
                                        textButton={i18next.t('withdraw.request.close')}
                                        handleClick={() => setRefusedMode(false)}
                                        type="button" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default React.memo(WithdrawRequestProfil);
