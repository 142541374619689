import i18n from "18n";
import Application from 'Application';
import {
    ASSET_BUY_STAGE,
    ASSET_CARD_EQUIVALENT,
    ASSETS_CATEGORIES,
    ASSETS_SLUG,
    BUY_STAGE_TRANSITION_TOLERANCE_TIME,
    DEFAULT_ORDERBOOK_FEES,
    EmptyString,
    LAUNCH_MODE,
    LIGHT_ASSET_PROFIL_ELLIGIBLE,
    LOCAL_STORAGE_KNOWN_KEYS,
    LP_BUY_FEE,
    LP_SELL_FEE,
    LP_SELL_FIXED_FEE,
    MAX_ASSET_TRANSACTION_TOKENS,
    ONE,
    PRE_PRIVATE_SALES_START_THRESHOLD,
    SECONDS_AFTER_INITIAL_SOLDOUT,
    SPORT_ASSET_CATEGORIES,
    SUPER_TALENT,
    TREND_SCORE_STATUS_PART,
    ZERO
} from 'config/_const';
import { AssetCardLevel, AssetCategory } from 'config/_enums';
import ASSET_CARD_LEVEL from 'data/AssetCardlevel/assetCardLevel.data';
import { ICategoryTypeName } from 'interfaces/IAssetBgImages';
import { ISportsFootballPlayersExtraInfo } from 'interfaces/sports/ISportsPlayer';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import AssetInterface from 'model/AssetModel/AssetInterface';
import IAssetMarketConfiguration from 'model/AssetModel/AssetMarketConfiguration';
import IAssetCurveConfiguration from "model/AssetModel/IAssetCurveConfiguration";
import IAssetSecondaryMarketRestriction from 'model/AssetModel/IAssetSecondaryMarketRestriction';
import PriceHistoryInterface from 'model/AssetModel/PriceHistoryInterface';
import IRLScoreDetail from "model/IRLScoreDetail";
import moment from 'moment';
import Utils from 'utils/Utils';
import { dateDifferenceInSeconds, isBeforeNow, subtractDay, toFrenchTimeMidnight } from 'utils/date';
import { IEquivalentCard } from 'view-model/Team/TeamViewModel/ITeamViewModel';
import IPriceEvolutions from './IPriceEvolutions';

export default class AssetViewModel {
    private asset: AssetInterface;
    private assetId: string;
    private avatarPath: string;
    private assetName: string;
    private currentValue: number;
    private initialSaleDone: boolean;
    private mainCategoryId: string;
    private availableSupply: number;
    private irLScore: number;
    private availableForTournament: boolean;
    private initialSaleDate: string;
    private assetSlug: string;
    private assetBannerImage: string;
    private hidden: boolean;
    private initialSaleSoldOutDate: string;
    private description: string;
    private priceHistory: PriceHistoryInterface[];
    private owner: string;
    private feesRate?: number;
    private feesFixedAmount?: number;
    private initialSupply: number;
    private yieldEnabled: boolean;
    private subtitle: string;
    private contractTerm: number;
    private totalSupply: number;
    private initialValue: number;
    private secondaryMarketSupply?: number;
    private totalPriceChange: number;
    private cardLevel?: AssetCardLevel;
    private marketConfiguration: IAssetMarketConfiguration;
    private initialSaleRealEndDate: Date;
    private liquidityPoolEnabled: boolean;
    private sportScore: number;
    private liquidityPoolSupply: number;
    private liquidityPoolValue: number;
    private liquidityPoolInitialSupply: number;
    private createdAt: string;
    private sportPlayer: ISportsFootballPlayersExtraInfo;
    private dailyMinPriceHistory?: PriceHistoryInterface;
    private scoreBoosted: boolean;
    private videoIconProfile: string;
    private irlScoreDetails: IRLScoreDetail[] | undefined;
    private assetCurveConfiguration: IAssetCurveConfiguration;

    constructor(asset: AssetInterface) {
        this.asset = asset;
        this.assetId = asset._id;
        this.avatarPath = asset.icon;
        this.assetName = asset.name;
        this.currentValue = asset.currentValue;
        this.initialSaleDone = asset.initialSaleDone;
        this.mainCategoryId = asset.mainCategory;
        this.availableSupply = asset.availableSupply;
        this.irLScore = asset.IRLScore;
        this.sportScore = asset.sportsScore;
        this.availableForTournament = asset.availableForTournament;
        this.initialSaleDate = asset.initialSaleDate;
        this.assetSlug = asset.slug;
        this.assetBannerImage = asset.assetBannerImage;
        this.hidden = asset.hidden;
        this.initialSaleSoldOutDate = asset.initialSaleSoldOutDate;
        this.description = asset.description;
        this.priceHistory = asset.priceHistory;
        this.owner = asset.owner;
        this.feesRate = asset.feesRate;
        this.feesFixedAmount = asset.feesFixedAmount;
        this.initialSupply = asset.initialSupply;
        this.yieldEnabled = asset.yieldEnabled;
        this.subtitle = asset.subtitle;
        this.contractTerm = asset.contractTerm;
        this.totalSupply = asset.totalSupply;
        this.initialValue = asset.initialValue;
        this.secondaryMarketSupply = asset.secondaryMarketSupply;
        this.totalPriceChange = asset.totalPriceChange;
        this.cardLevel = asset.cardLevel;
        this.marketConfiguration = asset.marketConfiguration;
        this.initialSaleRealEndDate = new Date(asset.initialSaleRealEndDate);
        this.liquidityPoolEnabled = asset.liquidityPoolEnabled;
        this.liquidityPoolSupply = asset.liquidityPoolSupply;
        this.liquidityPoolValue = asset.liquidityPoolValue;
        this.liquidityPoolInitialSupply = asset.liquidityPoolInitialSupply;
        this.createdAt = asset.createdAt;
        this.sportPlayer = asset.sportPlayer;
        this.dailyMinPriceHistory = asset.dailyMinPriceHistory;
        this.scoreBoosted = asset.scoreBoosted;
        this.videoIconProfile = asset.videoIconProfile;
        this.irlScoreDetails = asset.IRLScoreDetails;
        this.assetCurveConfiguration = asset.assetCurveConfiguration;
    }

    public get LiquidityPoolBuyFeesRatio(): number {
        return this.marketConfiguration.secondaryMarket.liquidityPoolFees?.buyFees ?? LP_BUY_FEE;
    }

    public get LiquidityPoolSellFeesRatio(): number {
        return this.marketConfiguration.secondaryMarket.liquidityPoolFees?.sellFees ?? LP_SELL_FEE;
    }

    public get OrderBookFeesRate(): number {
        return this.marketConfiguration.secondaryMarket.orderBookFees?.sellFees ?? DEFAULT_ORDERBOOK_FEES.sellFees;
    }

    public get OrderBookFixedFees(): number {
        return this.marketConfiguration.secondaryMarket.orderBookFees?.sellFixedFees ?? DEFAULT_ORDERBOOK_FEES.sellFixedFees;
    }

    public get LiquidityPoolSellFixedFees(): number {
        return this.marketConfiguration.secondaryMarket.liquidityPoolFees?.sellFixedFees ?? LP_SELL_FIXED_FEE;
    }

    public get SecondaryMarketSupply(): number {
        return this.secondaryMarketSupply ?? 0;
    }

    public get InitialValue(): number {
        return this.initialValue;
    }

    public get TotalSupply(): number {
        return this.totalSupply;
    }

    public get ContractTerm(): number {
        return this.contractTerm;
    }

    public get Subtitle(): string {
        return this.subtitle;
    }

    public get YieldEnabled() {
        return this.yieldEnabled;
    }

    public get InitialSupply() {
        return this.initialSupply;
    }

    public get FeesRate() {
        return this.feesRate;
    }

    public get FeesFixedAmount() {
        return this.feesFixedAmount;
    }

    public get Owner(): string {
        return this.owner;
    }

    public get PriceHistory(): PriceHistoryInterface[] {
        return this.priceHistory;
    }

    public get Description(): string {
        return this.description;
    }

    public get InitialSaleDate(): string {
        return this.initialSaleDate;
    }

    public get AssetId(): string {
        return this.assetId;
    }

    public get IsIcoOpen(): boolean {
        return moment(new Date(this.InitialSaleDate)).isBefore(moment()) && !this.InitialSaleDone;
    }

    public get AvatarPath(): string {
        return this.avatarPath;
    }

    public get AssetName(): string {
        return this.assetName;
    }

    public get Asset(): AssetInterface {
        return this.asset;
    }

    public get CurrentValue(): string {
        return this.currentValue.toFixed(2);
    }

    public get CurrentValueAsNumber(): number {
        return parseFloat(this.currentValue.toFixed(2));
    }

    public get InitialSaleDone(): boolean {
        if (!this.initialSaleSoldOutDate)
            return this.initialSaleDone;
        const momentSoldOutDate = moment(this.initialSaleSoldOutDate);
        const now = moment();
        if (momentSoldOutDate.isBefore(now))
            return momentSoldOutDate.add(SECONDS_AFTER_INITIAL_SOLDOUT, 'seconds').isBefore(now);
        return this.initialSaleDone;
    }

    public get MainCategoryId(): string {
        return this.mainCategoryId;
    }

    public get AvailableSupply() {
        return this.availableSupply;
    }
    public get IRLScore(): number {
        return this.irLScore ?? 0;
    }

    public get SportScore(): number {
        return this.sportScore ?? 0;
    }

    public getSportScoreToDisplay(numberOfDecimals: number = 2): number {
        return parseFloat(this.SportScore.toFixed(numberOfDecimals));
    }

    public getTrendScoreToDisplay(numberOfDecimals: number = 2): number {
        return parseFloat(this.IRLScore.toFixed(numberOfDecimals));
    }

    public get AssetSlug(): string {
        return this.assetSlug;
    }

    public get AssetBannerImage(): string {
        return this.assetBannerImage;
    }

    public get Hidden(): boolean {
        return this.hidden;
    }

    public get AvailableForTournament(): boolean {
        if (!this.InitialSaleDate || this.Hidden)
            return false;
        return this.availableForTournament && (moment(this.InitialSaleDate).isSameOrBefore(moment()));
    }

    public get MarketConfiguration(): IAssetMarketConfiguration {
        return this.marketConfiguration ?? {};
    }

    public get InitialSaleRealEndDate(): Date {
        return this.initialSaleRealEndDate;
    }

    private get SecondaryMarketRestriction(): IAssetSecondaryMarketRestriction {
        return this.MarketConfiguration.secondaryMarket?.restriction || { enabled: false, maxTokensPerUser: MAX_ASSET_TRANSACTION_TOKENS, timeRestrictionInMinutes: 0 };
    }

    public get SecondaryMarketRestrictionInMinutes(): number {
        return this.SecondaryMarketRestriction.timeRestrictionInMinutes;
    }
    public get isSecondaryMarketRestrictionEnabled(): boolean {
        if (this.PackEnabled || this.IsWhiteListedPurchaseOpen)
            return false;
        if (Application.getInstance().SkipPrimaryMarket && this.SecondaryMarketRestriction.enabled)
            return moment().isBefore(moment(this.SecondaryMarketPurchaseRestrictionEndDate));

        if (!this.SecondaryMarketRestriction.enabled || !this.InitialSaleDone)
            return false;
        return moment().isBetween(this.InitialSaleDate, this.SecondaryMarketPurchaseRestrictionEndDate);
    }

    public get TokensLimitOnSecondaryMarketStart(): number {
        if (!this.isSecondaryMarketRestrictionEnabled)
            return MAX_ASSET_TRANSACTION_TOKENS;
        return this.SecondaryMarketRestriction.maxTokensPerUser;
    }

    public get FetchedAt(): Date | undefined {
        return this.asset.fetchedAt;
    }

    public get CardLevel(): number {
        return this.cardLevel ?? AssetCardLevel.Rookie;
    }

    public get InitialSaleSoldOutDate(): string {
        return this.initialSaleSoldOutDate;
    }

    public get SecondaryMarketPurchaseRestrictionEndDate(): Date {
        return moment(this.initialSaleDate).add(this.SecondaryMarketRestrictionInMinutes, 'minutes').toDate();
    }

    public belongsTo = (userId: string): boolean => {
        return this.Owner === userId;
    };

    public get TotalPriceChange(): number {
        return this.totalPriceChange;
    }

    public get DailyLowestPriceHistory(): PriceHistoryInterface | undefined {
        return this.dailyMinPriceHistory;
    }

    public get PriceEvolutionWithin24hr(): IPriceEvolutions {
        const priceEvolutionDuringLast24Hours: number = this.DailyLowestPriceHistory ? Utils.getTotalPriceChangePercent(this.currentValue, this.DailyLowestPriceHistory.price) : 0;
        const globalPriceEvolution: number = this.getEvolutionTotalPriceChangeInPercentage();
        const is24HoursMoreHigh: boolean = priceEvolutionDuringLast24Hours > globalPriceEvolution;
        const evolutionValue: number = Math.max(priceEvolutionDuringLast24Hours, globalPriceEvolution);
        const evolutionPercentage: string = `${evolutionValue.toFixed(2)}% ${is24HoursMoreHigh ? i18n.t('hour24') : ''}`;
        const isEvolutionPositive: boolean = evolutionValue >= ZERO;
        const evolutionPercentageWithIndicator: string = (isEvolutionPositive ? '▲' : '▼') + evolutionPercentage;

        return {
            evolutionValue,
            evolutionPercentage,
            evolutionPercentageWithIndicator,
            isEvolutionPositive,
        };
    }

    public getEvolutionTotalPriceChangeInPercentage(): number {
        return Utils.getTotalPriceChangePercent(this.currentValue, this.initialValue);
    }

    public get PriceEvolutions(): IPriceEvolutions {
        const isPriceEvolution24hrConfigured = Application.getInstance().IsPriceEvolution24hrConfigured;
        if (isPriceEvolution24hrConfigured) {
            return this.PriceEvolutionWithin24hr;
        }

        const evolutionValue: number = this.getEvolutionTotalPriceChangeInPercentage();
        const evolutionPercentage: string = evolutionValue.toFixed(2) + '%';
        const isEvolutionPositive: boolean = evolutionValue >= ZERO;
        const evolutionPercentageWithIndicator: string = (isEvolutionPositive ? '▲' : '▼') + evolutionPercentage;
        return {
            evolutionValue,
            evolutionPercentage,
            evolutionPercentageWithIndicator,
            isEvolutionPositive,
        };
    }

    public getMaxAPY(): number {
        return Utils.getYieldConfigPerAsset(this.AssetId).maxYield.divide(this.CurrentValueAsNumber);
    }

    public isLegend(): boolean {
        return this.CardLevel === AssetCardLevel.Legend;
    }

    public get CardLevelName(): string {
        return ASSET_CARD_LEVEL[this.cardLevel || ONE].name;
    }

    public get CardLevelTextColor(): string {
        return ASSET_CARD_LEVEL[this.cardLevel || ONE].textColor;
    }

    public getAvailableToken(): number {
        if (!this.InitialSaleDone)
            return Math.max(this.AvailableSupply ?? ZERO, ZERO);
        if (this.LaunchMode === LAUNCH_MODE.LIQUIDITY_POOL)
            return Math.max((this.LiquidityPoolSupply - 1 ?? ZERO), ZERO);
        return Math.max((this.SecondaryMarketSupply ?? ZERO), ZERO);
    }

    public isInPrePrivateSaleState(): boolean {
        if (!this.InitialSaleDate || this.IsIcoOpen)
            return false;
        const prePrivateSaletStartDate: moment.Moment = moment(this.InitialSaleDate).subtract(PRE_PRIVATE_SALES_START_THRESHOLD, 'minutes');
        return moment().isBetween(prePrivateSaletStartDate, moment(this.InitialSaleDate), undefined, '[)');
    }

    public get LiquidityPoolEnabled(): boolean {
        return this.liquidityPoolEnabled;
    }

    public get IsAssetBenzema(): boolean {
        return this.AssetId === Application.getInstance().PacksConfiguration.asset;
    }

    public get PackEnabled(): boolean {
        const packConfiguration = Application.getInstance().PacksConfiguration;
        if (!packConfiguration.enabled || packConfiguration.asset !== this.AssetId || !this.InitialSaleDate)
            return false;
        return this.BuyStage === ASSET_BUY_STAGE.PRE_BUY_PACK;
    }

    public get CreatedAt(): string {
        return this.createdAt;
    }

    public get SportPlayer(): ISportsFootballPlayersExtraInfo {
        return this.sportPlayer;
    }

    public get IsWhiteListSubscriptionEnabled(): boolean {
        if (!this.isWhiteListed())
            return false;
        const whiteListConfiguration = Application.getInstance().WhiteListConfiguration;
        const endDateBeforeLaunch: string = moment(this.InitialSaleDate).subtract(whiteListConfiguration.daysBeforeLaunch, "days").startOf('date').toISOString();
        return moment().isBefore(endDateBeforeLaunch);
    }

    public isWhiteListed(): boolean {
        const whiteListConfiguration = Application.getInstance().WhiteListConfiguration;
        if (!whiteListConfiguration.enabled || whiteListConfiguration.asset !== this.AssetId || !this.InitialSaleDate)
            return false;
        return true;
    }

    public get IsWhiteListedPurchaseOpen(): boolean {
        if (!this.isWhiteListed())
            return false;
        return this.BuyStage === ASSET_BUY_STAGE.WHITELIST_USER_BUY;
    }

    public get InitialSaleDateHasPassed(): boolean {
        return Boolean(this.InitialSaleDate) && moment(this.InitialSaleDate).isSameOrBefore(moment());
    }

    public get BuyStage(): ASSET_BUY_STAGE {
        return this.getAssetBuyStage();
    }

    private getAssetBuyStage() {
        if (isBeforeNow(this.InitialSaleDate)) return ASSET_BUY_STAGE.MARKET;
        const isPreBuyPackAsset = this.AssetId === Application.getInstance().PacksConfiguration.asset;
        const isWhitelistBuyAsset = this.AssetId === Application.getInstance().WhiteListConfiguration.asset;
        if (!isPreBuyPackAsset || !isWhitelistBuyAsset) return ASSET_BUY_STAGE.NONE;
        if (moment().isBetween(this.WhiteListDate, this.EarlyBirdEndDate))
            return ASSET_BUY_STAGE.WHITELIST_USER_BUY;
        if (this.WhiteListDate.isAfter(new Date()))
            return ASSET_BUY_STAGE.PRE_BUY_PACK;
        return ASSET_BUY_STAGE.NONE;
    }

    public get NextTransitionBuyStageInSeconds(): number {
        if (this.BuyStage === ASSET_BUY_STAGE.NONE || this.BuyStage === ASSET_BUY_STAGE.MARKET) return ZERO;
        let transitionDate: Date | null = null;
        if (this.BuyStage === ASSET_BUY_STAGE.PRE_BUY_PACK) {
            transitionDate = toFrenchTimeMidnight(subtractDay(this.initialSaleDate, Application.getInstance().WhiteListConfiguration.daysBeforeLaunch).toDate());
        }
        if (this.BuyStage === ASSET_BUY_STAGE.WHITELIST_USER_BUY) {
            transitionDate = toFrenchTimeMidnight(this.InitialSaleDate);
        }
        return transitionDate ? dateDifferenceInSeconds(transitionDate, new Date()).addition(BUY_STAGE_TRANSITION_TOLERANCE_TIME).capMinToZero() : ZERO;
    }

    static getSupplyByStage(asset: AssetViewModel, supply: number): number {
        switch (asset.BuyStage) {
            case ASSET_BUY_STAGE.PRE_BUY_PACK:
                return Application.getInstance().PacksTotalSupply.subtract(asset.InitialSupply.subtract(supply));
            case ASSET_BUY_STAGE.WHITELIST_USER_BUY:
                return (Application.getInstance().WhiteListConfiguration.maxTokens).subtract(asset.InitialSupply.subtract(supply));
            default:
                return supply;
        }
    }

    public get ForcedSecondaryMarket(): boolean {
        return this.BuyStage === ASSET_BUY_STAGE.PRE_BUY_PACK || this.BuyStage === ASSET_BUY_STAGE.WHITELIST_USER_BUY || this.AssetId === SUPER_TALENT;
    }

    public get WhiteListDate(): moment.Moment {
        const whiteListConfiguration = Application.getInstance().WhiteListConfiguration;
        return moment(this.InitialSaleDate).subtract(whiteListConfiguration.daysBeforeLaunch, "days");
    }

    public get EarlyBirdEndDate(): moment.Moment {
        return moment(this.InitialSaleDate).subtract(PRE_PRIVATE_SALES_START_THRESHOLD, "minutes");
    }

    public get NextStageDate(): Date {
        if (this.BuyStage === ASSET_BUY_STAGE.PRE_BUY_PACK)
            return this.WhiteListDate.toDate();
        if (this.BuyStage === ASSET_BUY_STAGE.WHITELIST_USER_BUY)
            return this.EarlyBirdEndDate.toDate();
        return new Date(this.InitialSaleDate);
    }

    public enableCustomAssetProfil(): boolean {
        const assetIsElligibleForCustomProfil: boolean = LIGHT_ASSET_PROFIL_ELLIGIBLE.includes(this.AssetSlug);
        const asCustomProfilFromLocalStorage: number = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.FROM_LANDING) ?? `${ZERO}`);
        return (asCustomProfilFromLocalStorage === ONE || Application.getInstance().IsRegisterDuringBenzemaCampaign) && assetIsElligibleForCustomProfil;
    }

    public get EarlyBirdDate(): moment.Moment {
        return moment(this.InitialSaleDate).subtract(Application.getInstance().WhiteListConfiguration.daysBeforeEarlyBird, "days");
    }

    public isEarlyBirdEnable(): boolean {
        const whiteListConfiguration = Application.getInstance().WhiteListConfiguration;
        if (whiteListConfiguration.asset !== this.AssetId)
            return false;
        const now: moment.Moment = moment(new Date());
        return now.isBetween(this.EarlyBirdDate, this.EarlyBirdEndDate);
    }

    public showPack(): boolean {
        return (this.PackEnabled || this.IsWhiteListedPurchaseOpen) && !this.isEarlyBirdEnable();
    }

    public getCategoryName(assetCategories: AssetCategoryInterface[]): string {
        const [assetCategory] = Utils.getAssetCategory(assetCategories, { mainCategory: this.MainCategoryId });
        const categoryName: ICategoryTypeName = assetCategory?.name.toLowerCase() as ICategoryTypeName;
        return categoryName;
    }

    public getCategoryNameCapitalizeFirstLetter(assetCategories: AssetCategoryInterface[]): string {
        const categoryName: string = this.getCategoryName(assetCategories);
        return categoryName ? categoryName.capitalizeFirstLetter() : EmptyString;
    }

    public isCustomBenzemaProfilPageBeforePublicSales(): boolean {
        return this.enableCustomAssetProfil();
    }

    public get PreSaleEndDate(): moment.Moment {
        return moment(this.WhiteListDate).add(ONE, "days");
    }

    public get PreSaleIsFinished(): boolean {
        return this.PreSaleEndDate.isSameOrBefore(moment());
    }

    public get IsSportPlayerRetired(): boolean {
        return this.asset.sportPlayer?.isRetired || false;
    }

    public canShowSportScore(assetCategories: AssetCategoryInterface[]): boolean {
        if (this.IsSportPlayerRetired || this.sportScore <= 0)
            return false;
        const categoryName = this.getCategoryName(assetCategories);
        return SPORT_ASSET_CATEGORIES.includes(categoryName);
    }

    public showChristmasBonus(): boolean {
        return Application.getInstance().ChristmasBonusConfiguration.assetIds.includes(this.AssetId);
    }

    public get cardEquivalent(): number {
        return this.cardLevel ? ASSET_CARD_EQUIVALENT[this.cardLevel] : ONE;
    }

    public getTotalEquivalentCardCount(numOfShares: number) {
        return numOfShares.multiply(this.cardEquivalent);
    }

    public getTeamCardEquivalence(numOfShares: number, sharePerSlot: number): IEquivalentCard {
        const totalEquivalentCardCount = this.getTotalEquivalentCardCount(numOfShares);

        return {
            totalCount: totalEquivalentCardCount,
            isCountAcceptable: totalEquivalentCardCount >= sharePerSlot
        };

    }

    public getTeamGetBtnTxt(text: string, numOfShares?: number): string {
        if (!numOfShares) {
            return i18n.t(text);
        }

        const totalCardEquivalentShares = this.getTotalEquivalentCardCount(numOfShares);
        return i18n.t(text) + `(+${totalCardEquivalentShares})`;
    }

    public get IsMarcusSmart(): boolean {
        return this.AssetSlug === ASSETS_SLUG.MARCUS_SMART;
    }

    public get LiquidityPoolValue() {
        return this.liquidityPoolValue;
    }

    public get LiquidityPoolSupply() {
        return this.liquidityPoolSupply;
    }

    public get LiquidityPoolInitialSupply() {
        return this.liquidityPoolInitialSupply;
    }

    private getStatusMessageByScore(score: number) {
        for (let i = 0; i < TREND_SCORE_STATUS_PART.length; i++) {
            if (score <= TREND_SCORE_STATUS_PART[i].value)
                return TREND_SCORE_STATUS_PART[i].label;
        }
        return TREND_SCORE_STATUS_PART[0].label;
    }

    public getTrendScoreStatus = () => {
        return this.getStatusMessageByScore(this.IRLScore);
    };

    public getSportScoreStatus = () => {
        return this.getStatusMessageByScore(this.SportScore);
    };

    public get ScoreBoosted(): boolean {
        if (!this.initialSaleDate) {
            return false;
        }
        return this.scoreBoosted && Utils.isDateBeforeDelay(this.initialSaleDate, Application.getInstance().AssetScoreBoostedDays);
    }

    public getScoreTooltipText(tooltipText: string): string {
        return this.scoreBoosted ? i18n.t('tooltip.score.boosted') : tooltipText;
    }

    public get IsFootballEuro(): boolean {
        return this.MainCategoryId === ASSETS_CATEGORIES[AssetCategory['Euro 2024']];
    }

    public get ShowTrendScore(): boolean {
        return !this.IsFootballEuro && !this.IsFunCategory;
    }

    public get IsFunCategory(): boolean {
        return this.MainCategoryId === ASSETS_CATEGORIES[AssetCategory['Fun cards']];
    }

    public get IsBuyableOnlyWithCredits(): boolean {
        return this.IsFunCategory || this.IsFootballEuro;
    }

    public get ShowLoveBanner(): boolean {
        return !this.IsFootballEuro && !this.IsFunCategory;
    }

    public get LaunchMode() {
        return this.marketConfiguration.secondaryMarket.launchMode;
    }

    public get VideoIconProfile(): string {
        return this.videoIconProfile;
    }

    public get AssetCurveConfiguration(): IAssetCurveConfiguration {
        return this.assetCurveConfiguration;
    }

    public get IRLScoreDetails(): IRLScoreDetail[] | undefined {
        return this.irlScoreDetails;
    }
}
