import TripleArrowUpGif from 'assets/gif/arrow/TripleArrowUp.gif';
import clsx from 'clsx';
import TrendScoreIcon from "components/icons/TrendScoreIcon";
import ScoreBoostCheckout from "components/medium/score-booster/score-boost-checkout/ScoreBoostCheckout";
import useSlidingPages from "hooks/use-sliding-pages/useSlidingPages";
import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Translation from "view-model/Achievement/Translation";
import ChipsScoreBoosterPrice from '../chips-score-booster-price/ChipsScoreBoosterPrice';
import ScoreBoosterValueAdded from "../score-booster-values-added/ScoreBoosterValuesAdded";
import ScoreBoosterValueReroll from "../score-booster-values-reroll/ScoreBoosterValueReroll";
import ScoreBoosterValue from "../score-booster-values/ScoreBoosterValues";
import IScoreBoosterDetailProps from "./IScoreBoosterDetailProps";
import './ScoreBoosterDetail.scss';

const ScoreBoosterDetail: React.FC<IScoreBoosterDetailProps> = (props) => {
    const iconSize = 35;
    const { displaySlidingPage } = useSlidingPages();
    const history = useHistory();
    const location = useLocation();
    const openBuyBoostModal = React.useCallback(() => {
        if (!props.scoreBoosterVM.IsRerollActivated && props.scoreBoosterVM.IsBoostAcquired)
            return;
        props.closeBoosterModal && props.closeBoosterModal();
        return displaySlidingPage(<ScoreBoostCheckout />);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get(("openBoosterCheckout"))) {
            openBuyBoostModal();
            history.replace({
                pathname: location.pathname,
                search: ''
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const translation = React.useMemo(() => {
        if (props.scoreBoosterVM.IsBoostAcquired)
            return { ...new Translation('points', { points: props.scoreBoosterVM.WinningPoints }), component: [<></>] };
        const key: string = !props.scoreBoosterVM.IsRandomizedActivated ? 'points' : "score.booster.buy.randomized";
        const values: Object = !props.scoreBoosterVM.IsRandomizedActivated ? { points: props.scoreBoosterVM.BoostPoints } : { min: props.scoreBoosterVM.MinPoints, max: props.scoreBoosterVM.MaxPoints };
        return { ...new Translation(key, values), component: [<span className="booster-points-value" />] };
    }, [props.scoreBoosterVM.BoostPoints, props.scoreBoosterVM.IsBoostAcquired, props.scoreBoosterVM.IsRandomizedActivated, props.scoreBoosterVM.MaxPoints, props.scoreBoosterVM.MinPoints, props.scoreBoosterVM.WinningPoints]);

    return (
        <div className={clsx("score-booster-container flex-center",
            {
                "green": !props.scoreBoosterVM.IsBoostAcquired,
                "grey": props.scoreBoosterVM.IsBoostAcquired
            }
        )} onClick={openBuyBoostModal}>
            <div className={clsx("left-part", { "grey-text": props.scoreBoosterVM.IsBoostAcquired })}>
                <p className="booster-points booster-points-text no-margin">
                    <Trans components={translation.component} i18nKey={translation.key} values={translation.values} />
                </p>
                {props.scoreBoosterVM.IsBoostAcquired &&
                    <span className='upgrade-arrow'>
                        <img src={TripleArrowUpGif} alt="up-arrow" className='arrowUp-gif' />
                    </span>
                }
            </div>
            <div className="right-part flex">
                {props.scoreBoosterVM.IsBoostAcquired && !props.scoreBoosterVM.IsRerollActivated && !props.scoreBoosterVM.IsRandomizedActivated && <ScoreBoosterValueAdded />}
                {props.scoreBoosterVM.IsBoostAcquired && props.scoreBoosterVM.IsRerollActivated && props.scoreBoosterVM.IsRandomizedActivated && <ScoreBoosterValueReroll />}
                {!props.scoreBoosterVM.IsBoostAcquired && props.scoreBoosterVM.IsRandomizedActivated && props.scoreBoosterVM.IsRerollActivated && <ChipsScoreBoosterPrice price={props.scoreBoosterVM.PriceAsCurrency} />}
                {!props.scoreBoosterVM.IsBoostAcquired && !props.scoreBoosterVM.IsRandomizedActivated && !props.scoreBoosterVM.IsRerollActivated && <ScoreBoosterValue scoreBoosterVM={props.scoreBoosterVM} />}
                {!props.scoreBoosterVM.IsRerollActivated && props.scoreBoosterVM.IsBoostAcquired &&
                    <TrendScoreIcon height={iconSize} width={iconSize} fill={props.scoreBoosterVM.getContentColor()} />
                }


            </div>
        </div>);
};

export default ScoreBoosterDetail;