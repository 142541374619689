import Application from 'Application';
import { DEFAULT_ID_AS_STRING, ZERO } from 'config/_const';
import ITournamentRewardViewModelParams from './ITournamentRewardViewModelParams';
import { TournamentRewardKind } from 'config/_enums';

export default class TournamentRewardViewModel {
    private _id: string;
    private user: string;
    private fiatAmount: number;
    private tournament: string;
    private kind: TournamentRewardKind;

    constructor(args: ITournamentRewardViewModelParams) {
        this._id = args._id ?? DEFAULT_ID_AS_STRING;
        this.user = args.user ?? DEFAULT_ID_AS_STRING;
        this.fiatAmount = args.fiatAmount ?? ZERO;
        this.tournament = args.tournament ?? DEFAULT_ID_AS_STRING;
        this.kind = args.__t ?? TournamentRewardKind.CREDITS_REWARD;
    }

    public get Id(): string {
        return this._id;
    }

    public get User(): string {
        return this.user;
    }

    public get FiatAmount(): number {
        return this.fiatAmount;
    }

    public get TournamentId(): string {
        return this.tournament;
    }

    public get FormattedFiatAmount(): string {
        return this.fiatAmount.toCurrency();
    }

    public get RateOfReturn(): number {
        return this.FiatAmount * Application.getInstance().tournamentsPerYear;
    }

    public get Kind(): TournamentRewardKind {
        if (!Application.getInstance().TournamentRewardConfiguration.giveAssets.enabled)
            return TournamentRewardKind.CREDITS_REWARD;
        return this.kind;
    }
}
