import ILiquidityPool from "interfaces/liquidity-pool/ILiquidityPool";
import LiquidityPool from './liquidityPool';
import ILiquidityPoolParams from "interfaces/liquidity-pool/ILiquidityPoolParams";
import { ZERO } from "config/_const";


class StepwiseLiquidityPool extends LiquidityPool implements ILiquidityPool {
    private lastStepLength: number;
    private initialPrice: number;
    private growthRate: number;
    private amplitudeFactor: number;
    private offset: number;


    constructor(liquidityPool: ILiquidityPoolParams) {
        super(liquidityPool);
        this.lastStepLength = 10;
        this.initialPrice = 1;
        this.growthRate = Math.log(this.AssetCurveConfiguration.factors.endPrice / this.initialPrice) / this.InitialTokens;
        this.amplitudeFactor = (this.AssetCurveConfiguration.factors.endPrice - this.initialPrice) / (Math.exp(this.growthRate * this.InitialTokens) - 1);
        this.offset = this.initialPrice - this.amplitudeFactor;
    }
    private curve(x: number) {
        if (x < ZERO || !x)
            x = ZERO;
        const { power, plateauLength, plateauHeightFactor, endPrice } = this.AssetCurveConfiguration.factors;
        const initialTokens = this.InitialTokens;

        const adjustedX = Math.pow(x / initialTokens, power) * initialTokens;

        const numPlateaux = Math.floor(adjustedX / plateauLength);

        const plateauHeight = plateauHeightFactor * numPlateaux;

        const plateauAdjustedX = adjustedX - (adjustedX % plateauLength) + plateauHeight;

        const finalX = Math.min(Math.max(plateauAdjustedX, ZERO), initialTokens - 1);

        let price = this.amplitudeFactor * Math.exp(this.growthRate * finalX) + this.offset;

        if (x >= initialTokens - this.lastStepLength)
            price = endPrice;

        return price;
    }

    private integrate(start: number, xout: number) {
        let sum = ZERO;
        for (let i = start; i < xout; i++) {
            sum += this.curve(i);
        }
        return sum;
    }

    getBuyPrice(xout: number) {
        if (xout === ZERO)
            return this.curve(xout);
        return this.integrate(this.CurveBalance, this.CurveBalance + xout);
    }

    getSellPrice(xin: number) {
        if (xin === ZERO)
            return this.curve(xin);
        return this.integrate(this.CurveBalance - xin, this.CurveBalance);
    }

    getMaxTokenWithAvailableCredit(credits: number) {
        const { power, plateauLength, plateauHeightFactor, endPrice } = this.AssetCurveConfiguration.factors;
        const initialTokens = this.InitialTokens;

        let totalCost = ZERO;
        let tokensBought = ZERO;
        for (let x = ZERO; x < initialTokens; x++) {
            const adjustedX = Math.pow(x / initialTokens, power) * initialTokens;

            const numPlateaux = Math.floor(adjustedX / plateauLength);
            const plateauHeight = plateauHeightFactor * numPlateaux;
            const plateauAdjustedX = adjustedX - (adjustedX % plateauLength) + plateauHeight;

            const finalX = Math.min(Math.max(plateauAdjustedX, ZERO), initialTokens - 1);

            let price = this.amplitudeFactor * Math.exp(this.growthRate * finalX) + this.offset;

            if (x >= initialTokens - this.lastStepLength)
                price = endPrice;

            totalCost += price;

            if (totalCost > credits) {
                break;
            }

            tokensBought++;
        }

        return tokensBought;
    }

}

export default StepwiseLiquidityPool;