import React from 'react';
import SignUpStepProps from './SignUpStepProps';
import Divider from 'components/small/divider/Divider';
import './SignUpStep.scss'
import { Trans } from 'react-i18next';

const SignUpStep: React.FC<SignUpStepProps> = (props) => {
    return (
        <div className='signupstep-container'>
            <div className='img-container'>
                <img src={props.stepImage} />
            </div>
            <div className="text-wrapper">
                <h3 className='step-h3'>
                    {props.stepId}
                </h3>
                <span className='step-span'>
                    <Trans i18nKey={props.stepTitle} components={{ b: <strong /> }} values={{currency: props.currency}} />
                    <Divider background='#15182B26' />
                </span>
            </div>
        </div>
    );
};

export default SignUpStep;
