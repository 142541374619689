import { EmptyString } from 'config/_const';
import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from '../buttons/primary-button/PrimaryButton';
import SecondaryButton from '../buttons/secondary-button/SecondaryButton';
import ConfirmProps from './ConfirmProps';
import './style.scss';

const Confirm: React.FunctionComponent<ConfirmProps> = (props: ConfirmProps) => {
    const renderPrimaryButton = () => {
        const primaryButton = <PrimaryButton
            receiveClickEvent={false}
            disabled={false}
            type="button"
            className="confirm-button"
            textButton={props.textValidButton || EmptyString}
            handleClick={() => props.onValidation()}
        />

        if (props.urlValidation && !props.showLinkButton)
            return (
                <a href={props.urlValidation}>
                    {primaryButton}
                </a>
            )

        if (props.urlValidation && props.showLinkButton)
            return (
                <Link to={props.urlValidation}>
                    {primaryButton}
                </Link>
            )

        return primaryButton;
    }

    return <div className={`confirm-dialog ${props.className}`}>
        <div style={{ lineHeight: '23px' }}>{props.message}</div>

        <p>{props.subMessage}</p>

        <div className="buttons-container">
            {props.showCancelButton &&
                <SecondaryButton
                    disabled={false}
                    receiveClickEvent={false}
                    type="button"
                    className={'confirm-button'}
                    handleClick={() => props.onRefused ? props.onRefused() : undefined}
                    textButton={props.textCancelButton || EmptyString} />
            }

            {renderPrimaryButton()}
        </div>
    </div >;
};

Confirm.defaultProps = {
    textCancelButton: i18next.t('genericcancel'),
    textValidButton: i18next.t('genericaccept'),
    showCancelButton: true,
    showLinkButton: false,
};

export default Confirm;
