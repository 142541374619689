import ReactPixel from 'react-facebook-pixel';
import IPixelHandler from "../pixel/IPixelHandler";
import IInitiateCheckoutData from './IInitiateCheckoutData';

export default class PixelHandlerInitiateCheckout implements IPixelHandler {
    private data: IInitiateCheckoutData;
    private static customFacebookEventName: string = 'InitiateCheckout';

    constructor(){
        this.data = {
            assetId: '',
            currency: '',
            value: 0,
            quantity: 0,
            status: false
        };
    }

    public get initiateCheckoutData() {
        return this.data;
    }

    public set initiateCheckoutData(data: IInitiateCheckoutData) {
        this.data = data;
    }

    handle(): void {
        ReactPixel.track(PixelHandlerInitiateCheckout.customFacebookEventName, this.data);
    }

}