import { ZERO } from "config/_const";
import React from "react";
import Countdown from "react-countdown";
import { Trans } from "react-i18next";

const CountdownWidget = ({ date, titleTranslationKey, daysInHours = false }: { date: string, titleTranslationKey?: string, daysInHours?: boolean; }) => {


    const TimeUnit = ({ value, label }: { value: number, label: string; }) => (
        <div className={label}>
            <div className="value">
                {value.toString().padStart(2, '0').split('').map((char, index) => (
                    <span key={index}>{char}</span>
                ))}
            </div>
            <div className="text">
                <Trans i18nKey={`general.time.${label}`} />
            </div>
        </div>
    );


    return (
        <Countdown
            daysInHours={daysInHours}
            date={date}
            precision={ZERO}
            renderer={({
                formatted: { hours, minutes, seconds, days },
                completed
            }) => {
                if (completed)
                    return null;
                return (
                    <div className='countdown-widget'>
                        <label className='countdown-widget title'><Trans i18nKey={titleTranslationKey} /></label>
                        <div className='countdown-widget content'>
                            {!daysInHours && <>
                                <TimeUnit value={parseInt(days)} label="days" />
                                <span className="separator">:</span>
                            </>}
                            <TimeUnit value={parseInt(hours)} label="hours" />
                            <span className="separator">:</span>
                            <TimeUnit value={parseInt(minutes)} label="minutes" />
                            <span className="separator">:</span>
                            <TimeUnit value={parseInt(seconds)} label="seconds" />
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default CountdownWidget;