import { Button } from '@material-ui/core';
import clsx from 'clsx';
import useModalDisplay from 'hooks/use-modal-display/useModalDisplay';
import React from 'react';
import withTournamentLevelButton from 'hoc/with-tournament-level-button/withTournamentLevelButton';
import ITournamentLevelButtonProps from './ITournamentLevelButtonProps';
import './TournamentLevelButton.scss';
import TournamentProgressBar from './localComponent/TournamentProgressBar/TournamentProgressBar';

const TournamentLevelButton: React.FC<ITournamentLevelButtonProps> = () => {
    const { showTournamentModal } = useModalDisplay();

    const handleClick = () => {
        showTournamentModal()
    };

    return (
        <Button
            className={clsx('tournament-level-button')}
            focusVisibleClassName="focus-button"
            aria-haspopup="true"
            variant="text"
            onClick={handleClick}
        >
            <TournamentProgressBar showLeagueIcon={true} />
        </Button>
    );
};

export default withTournamentLevelButton(TournamentLevelButton);