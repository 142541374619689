import { ITournamentRewardReducer } from 'model/Tournament/ITournamentReward';
import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import { RESET_STATE } from '../commonActionTypes';
import * as ActionTypesTournamentResult from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<ITournamentRewardReducer> = {
    loading: false,
    data: {},
    error: '',
};

export const tournamentRewardReducer = (state: ReduxRootState<ITournamentRewardReducer> = ROOT_VALUE_STATE, action: ReduxActionInterface): ReduxRootState<ITournamentRewardReducer> => {
    switch (action.type) {
        case ActionTypesTournamentResult.REWARD_LAST_TOURNAMENT_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ActionTypesTournamentResult.REWARD_LAST_TOURNAMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        case ActionTypesTournamentResult.REWARD_LAST_TOURNAMENT_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.tournamentId]: action.payload.rewards,
                },
                loading: false,
            };
        case RESET_STATE:
            return ROOT_VALUE_STATE;
        default: return state;
    }
};
