import SolidSecondaryButton from 'components/small/buttons/solid-secondary-button/SolidSecondayButton';
import WatchNowPlayBtn from 'components/small/watch-now-play-btn/WatchNowPlayBtn';
import { ONE } from 'config/_const';
import { academyThumbnail1, academyThumbnail2, academyThumbnail3, getAcademyVideoId1, getAcademyVideoId2, getAcademyVideoId3 } from 'data/YoutubeVideo/youtubeVideo.data';
import { academyVideoImg } from 'data/generalImages/generalImages.data';
import withAdminView from 'hoc/with-admin-view/withAdminView';
import useModalDisplay from 'hooks/use-modal-display/useModalDisplay';
import React from 'react';
import { withTranslation } from 'react-i18next';
import './TrendexAcademy.scss';
import TrendexAcademyProps from './TrendexAcademyProps';


const TrendexAcademy: React.FC<TrendexAcademyProps> = ({ t: translate }) => {
    const CAN_SHOW_ACADEMY_BTN: boolean = false;
    const academyData = [
        { id: "R1", img: academyVideoImg, title: translate('academy_1'), videoId: getAcademyVideoId1(), bannerImage: academyThumbnail1 },
        { id: "R2", img: academyVideoImg, title: translate('academy_2'), videoId: getAcademyVideoId2(), bannerImage: academyThumbnail2 },
        { id: "R3", img: academyVideoImg, title: translate('academy_3'), videoId: getAcademyVideoId3(), bannerImage: academyThumbnail3 },
    ];
    const { showYtVideoModal } = useModalDisplay();

    return (
        <div className='trendex-academy'>
            <div className="academy-title">
                {translate('Trendex.academy')}
            </div>

            <div className="academy-container">
                <div className="academy-wrapper">
                    {academyData.map((academy, index) =>
                        <div key={academy.id} className="video-container hover" onClick={() => showYtVideoModal(academy.videoId)}>
                            <div className="thumbnail-card">
                                <img className='thumbnail-img' src={academy.bannerImage} alt='thumbnail' />
                                <div className='thumbnail-play-btn'>
                                    <WatchNowPlayBtn showPlayIcon={true} />
                                </div>
                            </div>
                            <div className="thumbnail-title hover" onClick={() => academy.videoId && showYtVideoModal(academy.videoId)}>
                                <span>{translate('Trendex.academy')} #{index + ONE}: </span>
                                <span>{academy.title}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {CAN_SHOW_ACADEMY_BTN &&
                <div className="btn-academy">
                    <SolidSecondaryButton
                        disabled={false}
                        textButton={translate('acadmey-gotoBtn')}
                        receiveClickEvent
                        type='button'
                    />
                </div>
            }
        </div>
    )
};

export default withTranslation()(withAdminView(TrendexAcademy));