import React from 'react';
import { IVideoModalProps } from './IVideoModalProps';
import YouTube from 'react-youtube';
import "./styles.scss";

const VideoModal: React.FC<IVideoModalProps> = ({ videoId }) => {
    return (
        <div className="video-modal">
            <div className="video-container">
                <div className="banner-slide video-visible">
                    <YouTube containerClassName="youtube-video"
                        videoId={videoId}
                        opts={{
                            height: '100%', width: '100%',
                            playerVars: {
                                playsinline: 0
                            }
                        }}
                    />
                </div >
            </div>
        </div>
    )
};

export default VideoModal;