import React from 'react';
import { CSSTransition } from 'react-transition-group';

const TransitionWrapper: React.FC<any> = (props) => {

    if (props.slug) return (
        <CSSTransition
            nodeRef={props.nodeRef}
            in={props.in}
            timeout={props.timeout}
            classNames={props.classNames}
            onEntered={props.onEntered}
            onExited={props.onExited}
            unmountOnExit
        >
            <div className="asset-profile-slider" ref={props.nodeRef}>
                {props.children}
            </div>
        </CSSTransition>
    )

    return <>{props.children}</>
}

export default TransitionWrapper;