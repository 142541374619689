import TopBarNavButton from 'components/small/top-bar-nav-button/TopBarNavButton';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTopBarColor } from 'service/top-bar/selectors';
import TopMenuWidget from './core/TopMenuWidget/TopMenuWidget';
import './TopBar.scss';
import TopBarProps from './TopBarProps';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { HIDDEN_BOTTOM_MENU_PATHS } from 'config/_const';
import { selectAssetModalState } from 'service/sliding-pane/selectors';
import useAccount from 'hooks/use-account/useAccount';
import TopBarActions from './core/TopMenuActions/TopBarActions';
import ReduxStoreInterface from 'model/ReduxStoreInterface';
import TopMenuWidgetLoading from './core/TopMenuWidget/TopMenuWidgetLoading';

const TopBar: React.FC<TopBarProps> = ({ addSideMenuActiveClassMenu }) => {
    const scrollPoint: number = 0.8;
    const [isScrollY, setIsScrollY] = useState<boolean>(false);
    const topBarColor: string = useSelector(selectTopBarColor);
    const { pathname: currentPath } = useLocation();
    const { accountViewModel } = useAccount();
    const isTopBarHiddden = () => HIDDEN_BOTTOM_MENU_PATHS.includes(currentPath) && !accountViewModel.isAuthenticated;
    const { isOpen: isSlideViewOpen } = useSelector(selectAssetModalState);
    const isAuthLoadMeLoading = useSelector((state: ReduxStoreInterface) => state.authLoadMe.loading)

    useEffect(() => {
        window.addEventListener('scroll', detectScroll);
        return () => window.removeEventListener('scroll', detectScroll);
    }, [])

    const detectScroll = (): void => setIsScrollY(window.scrollY >= scrollPoint);

    return (
        <div className={clsx('flex', 'top-bar', {
            'active-top-bar': isScrollY === true || isSlideViewOpen,
            'hide': isTopBarHiddden()
        })}>
            <TopBarNavButton
                color={topBarColor}
                openMenu={addSideMenuActiveClassMenu}
            />

            {isAuthLoadMeLoading &&
                <TopMenuWidgetLoading />
            }

            {!isAuthLoadMeLoading && !accountViewModel.IsOnboardingRunning &&
                <>
                    {accountViewModel.isAuthenticated
                        ? <TopMenuWidget color={topBarColor} />
                        : <TopBarActions />
                    }
                </>
            }
        </div>
    );
};

export default React.memo(TopBar);
