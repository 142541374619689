import i18n from '18n';
import { TextField } from '@material-ui/core';
import RedButton from 'components/small/buttons/red-button/RedButton';
import WrapperSelect from 'components/small/wrapper-select/WrapperSelect';
import { TRANSACTION_SOURCE, WRAPPER_SELECT_TYPE, ZERO } from 'config/_const';
import useAllAssets from 'hooks/asset/use-all-assets/useAllAssets';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { managementModal } from 'service/modal/action';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import './AdminGiveTokenToUser.scss';
import IAdminCreditUserProps from './IAdminGiveTokenToUserProps';
import { sendCardToUserAsAdmin } from 'service/users/actions';

interface ITokenInput {
    amount: number;
    assetId: string;
    transactionSource: string;
}

const AdminGiveTokenToUser: React.FC<IAdminCreditUserProps> = (props: IAdminCreditUserProps) => {
    let { allAssets, fetchAllAssets } = useAllAssets();
    useEffect(() =>
        fetchAllAssets(), []
    )

    const sortedAssets = allAssets.sort((a: AssetViewModel, b: AssetViewModel) => a.AssetName.localeCompare(b.AssetName));

    const defaultValue: ITokenInput = { amount: 0, assetId: sortedAssets[0]?.AssetId ?? ZERO, transactionSource: TRANSACTION_SOURCE.ADMIN_REGULATION };
    const [tokenInput, setTokenInput] = useState<ITokenInput>({ ...defaultValue });
    const dispatch = useDispatch();
    const onConfirmation = async () => {
        dispatch(sendCardToUserAsAdmin({ userId: props.user._id, ...tokenInput }))
        setTokenInput({ ...defaultValue });
        dispatch(managementModal(false));
    }

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()
        const selectedAsset: AssetViewModel | undefined = allAssets.find((asset) => asset.AssetId === tokenInput.assetId);
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            showCancelButton: true,
            fullScreen: false,
            type: 'CONFIRMATION_MODAL',
            propsCustom: {
                hideCancelBtn: false,
                fullButtons: false,
                onRefused: () => dispatch(managementModal(false)),
                onValidation: onConfirmation,
                message:
                    i18n.t('admin.add.cards',
                        { amount: tokenInput.amount, assetName: selectedAsset?.AssetName, userName: props.user.email }
                    )
            },
        }));
    }
    return <section className='admin-give-token-to-user'>
        <h2>Add cards to user</h2>
        <form onSubmit={onSubmit}>
            <div className="flex">
                <div className='custinput_group col-4'>
                    <label className='form_label'>
                        Card
                    </label>
                    <WrapperSelect
                        type={WRAPPER_SELECT_TYPE.NATIVE}
                        data={sortedAssets.map((value: AssetViewModel) => ({ label: value.AssetName, value: value.AssetId }))}
                        valueChange={(event) => setTokenInput({ ...tokenInput, assetId: event.target.value })}
                        value={tokenInput.assetId} />
                </div>
                <div className='custinput_group col-4'>
                    <label className='form_label'>
                        Amount
                    </label>
                    <TextField
                        type='number'
                        id="admin-add-virtual-credits"
                        value={tokenInput.amount}
                        onChange={(event) => setTokenInput({ ...tokenInput, amount: Number(event.target.value) })}
                    />
                </div>
                <div className='custinput_group col-4'>
                    <label className='form_label'>
                        Source de la transaction
                    </label>

                    <WrapperSelect
                        type={WRAPPER_SELECT_TYPE.NATIVE}
                        data={Object.values(TRANSACTION_SOURCE).map((value: string) => ({ label: value, value }))}
                        valueChange={(event) => setTokenInput({ ...tokenInput, transactionSource: event.target.value as string })}
                        value={tokenInput.transactionSource} />
                </div>
            </div>

            <RedButton
                disabled={false}
                type="submit"
                textButton={"Add Cards"}
                receiveClickEvent={false}
                className='col-3'
            />

        </form>
    </section>
}

export default AdminGiveTokenToUser;