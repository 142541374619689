import React from 'react';

const TrendScoreIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    return (
        <svg width={props.width ?? "10.909"} height={props.height ?? "12"} viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M148.5,145.6c4.5-23.1,8.9-46.2,13.3-69.2c1.3-7,2.7-14,3.9-21c0.6-3.2,0.1-3.7-3.2-3.7
	c-5.4-0.1-10.8,0-16.2-0.1c-0.9,0-1.8-0.6-2.7-0.9c0.5-0.8,0.8-1.7,1.4-2.3c11.1-11.2,22.2-22.3,33.2-33.5
	c4.3-4.4,8.6-8.8,12.9-13.2c2.3-2.3,2.8-2.3,4.3,0.5c8.4,15.4,16.8,30.8,25.2,46.2c1.4,2.6,1.1,3.3-2,3.4c-4.1,0.1-8.2,0.1-12.2,0
	c-2.7-0.1-4.1,0.9-4.6,3.7c-3.6,20-7.4,40.1-11.2,60.1c-5,26.5-10,53.1-15,79.6c-0.7,3.8-1.5,7.5-2.1,11.3c-0.2,1.6-1.6,2.8-3.2,2.8
	c-10.3,0-20.6,0.2-30.8-0.1c-1.6,0-3.5-1.8-4.5-3.2c-9.1-12.8-18-25.8-27-38.7c-4.9-7-9.8-14-14.7-21c-0.5-0.7-1.2-1.5-2-2.5
	c-0.4,0.9-0.6,1.6-0.7,2.2c-4.5,24.3-8.9,48.7-13.4,73c-2.1,11.3-4.3,22.5-6.3,33.8c-0.4,2.3-1.5,3.4-4,3.4c-9.8-0.1-19.5,0-29.3,0
	c-3.2,0-3.7-0.7-3.1-3.8c5.6-30,11.2-60,16.9-90c3.9-20.5,7.8-41,11.6-61.6c0.4-2,2.1-3.4,4.1-3.4c9.7,0.1,19.4,0.1,29.2,0
	c2.6,0,4.1,1.4,5.5,3.3c13.5,19,27.1,37.9,40.7,56.9l2.8,3.9C146.4,156.1,147.5,150.9,148.5,145.6z" />
        </svg>
    );
};

export default TrendScoreIcon;
