import { EmptyString, ZERO } from "config/_const";
import IDetailedStatistics from "interfaces/score-chart/IDetailedStatistics";
import ISportChartDetail from "interfaces/score-chart/IScoreChartConfig";
import { IBuildDetailedStatistics } from "interfaces/sports/IBuildDetailedStatistics";
import { getDateToString } from "utils/date";
import AssetScoreEvolutionViewModel from "view-model/Assets/AssetScoreEvolutionViewModel";

type IScoreChartVM = {
    _id: number;
    score: number;
    date: string;
    service: IBuildDetailedStatistics;
}

export default class ScoreChartViewModel {
    private service: IBuildDetailedStatistics;
    private hasStatistics: boolean;
    private scoreChartStatistics: IDetailedStatistics[];
    private player_id: number;
    private score: number;
    private date: string;

    constructor(constructorScoreChartVM: IScoreChartVM) {
        this.service = constructorScoreChartVM.service;
        this.scoreChartStatistics = this.service.buildDetailedStatistics();
        this.hasStatistics = this.scoreChartStatistics.some((value: IDetailedStatistics) => parseInt(value.amount, 10) > ZERO);
        this.score = parseFloat((constructorScoreChartVM.score ?? ZERO).toFixed(0));
        this.date = constructorScoreChartVM.date ?? EmptyString;
        this.player_id = constructorScoreChartVM._id;
    }

    public get HasStatistics(): boolean {
        return this.hasStatistics;
    }

    public get ScoreChartStatistics(): IDetailedStatistics[] {
        return this.scoreChartStatistics;
    }

    public get Score(): number {
        return this.score;
    }

    public get DateToDisplayInPopUp(): string {
        return getDateToString(new Date(this.date), undefined, "long", "2-digit", "numeric");
    }

    public get DateToDisplayInGraphs(): string {
        return getDateToString(new Date(this.date), undefined, "2-digit", "numeric");
    }

    public get PlayerId(): number {
        return this.player_id;
    }

    public buildScoreChartDetail(): ISportChartDetail {
        return {
            date: this.DateToDisplayInPopUp,
            assetScoreEvolutionVM: new AssetScoreEvolutionViewModel(this.score),
            data: this.scoreChartStatistics,
        }
    }

}