
import { isEmpty, isEqual, xorWith } from 'lodash';
import PredicateSortBuilder from '../../model/Helper/PredicateSortBuilder';
import IOrderByConfiguration from '../../model/shared/IOrderByConfiguration';
import Utils from 'utils/Utils';

declare global {
    interface Array<T> {
        isEqualTo(toArray: T[]): boolean;
        sortBy(orderByConfiguration: IOrderByConfiguration<T>): T[];
        removedDuplicateMongoFile(newArray: T[]): T[];
        getUniqueValues(): T[];
        moveZerosAtEnd(fieldToSort?: string): T[];
        sortByAnotherArray(array: any[], fieldToSort?: string): T[];
        random(): T;
    }
}

Array.prototype.isEqualTo = function <T>(array: T[]): boolean {
    if (!array.length && !this.length) return true;
    if (array.length !== this.length) return false;
    return isEmpty(xorWith<T>(this, array, isEqual));
};

Array.prototype.sortBy = function <T>(orderByConfiguration: IOrderByConfiguration<T>): T[] {
    if (!this.length || this.length === 1) return this;
    return this.sort(new PredicateSortBuilder(orderByConfiguration).build);
};

Array.prototype.removedDuplicateMongoFile = function <T>(newArray: T[]): T[] {
    if (newArray.length === 0)
        return this;
    return Utils.removedDuplicateMongoFile(this, newArray);
};

Array.prototype.getUniqueValues = function <T>(): T[] {
    return [...new Set(this)];
};

Array.prototype.moveZerosAtEnd = function <T>(fieldToSort?: string): T[] {
    return this.filter(element => fieldToSort ? element[fieldToSort] > 0 : element > 0).concat(this.filter(element => fieldToSort ? element[fieldToSort] === 0 : element === 0));
};

Array.prototype.sortByAnotherArray = function <T>(referenceArray: any[], fieldToSort?: string): T[] {
    if (!fieldToSort)
        return this.sort((entryA, entryB) => referenceArray.indexOf(entryA) - referenceArray.indexOf(entryB));
    return this.sort((entryA, entryB) => referenceArray.indexOf(entryA[fieldToSort]) - referenceArray.indexOf(entryB[fieldToSort]));
};

Array.prototype.random = function () {
    return this[Math.floor((Math.random() * this.length))];
}

export { };
