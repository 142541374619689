import { LOCAL_STORAGE_KNOWN_KEYS } from "config/_const";
import { ApplicationSportVersionEnum } from "config/_enums";
import BrowserClientHelper from "model/BrowserClientHelper/BrowserClientHelper";

export default class ApplicationSportVersion {
    private static instance: ApplicationSportVersion;
    private sportVersion: ApplicationSportVersionEnum;
    private readonly forcedLanguageCodeForBasketball: string[] = ['US'];

    constructor() {
        const sportVersionFromLocalStorage: string | null = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.SIGNUP_VERSION);
        const browserClientLanguageCountryCode: string = BrowserClientHelper.getInstance().getClientLanguageCountry();
        const basketballIsForced: boolean = this.forcedLanguageCodeForBasketball.includes(browserClientLanguageCountryCode);
        let sportVersion: ApplicationSportVersionEnum = sportVersionFromLocalStorage as ApplicationSportVersionEnum ?? ApplicationSportVersionEnum.STANDARD;
        if (!sportVersionFromLocalStorage)
            if (basketballIsForced)
                sportVersion = ApplicationSportVersionEnum.BASKETBALL;
            else
                sportVersion = ApplicationSportVersionEnum.STANDARD;
        if (!this.isSportVersionRecognized(sportVersion))
            sportVersion = ApplicationSportVersionEnum.STANDARD;

        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.SIGNUP_VERSION, sportVersion);
        this.sportVersion = sportVersion;
    }

    public static getInstance(): ApplicationSportVersion {
        return ApplicationSportVersion.instance ? ApplicationSportVersion.instance : new ApplicationSportVersion();
    }

    public get Version(): ApplicationSportVersionEnum {
        return this.sportVersion;
    }

    private isSportVersionRecognized(sportVersion: ApplicationSportVersionEnum): boolean {
        return Object.values(ApplicationSportVersionEnum).includes(sportVersion);
    }
}