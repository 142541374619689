import React from 'react';
import PrimaryButton from '../../../small/buttons/primary-button/PrimaryButton';
import CheckIcon from '../../../small/check-icon/CheckIcon';
import ConfirmSaleProps from './ConfirmSaleProps';
import './ConfirmSale.scss';

const ConfirmSale: React.FunctionComponent<ConfirmSaleProps> = (props: ConfirmSaleProps) => (
    <div className="confirm-sale padding">
        <CheckIcon />
        <h3>Lancement confirmé !</h3>

        <div className="message">
            <div>
                <p>Préparez-vous à teaser tout le monde avant la vente en précisant la date et l'heure précise!</p>
                <p>Rendez-vous sur trendex.vip pour acheter mes parts en édition limitée</p>
            </div>
        </div>

        <div>
            <PrimaryButton
                receiveClickEvent={false}
                disabled={false}
                type="button"
                handleClick={() => props.onValidation()}
                textButton={"J'ai compris"} />
        </div>

    </div >
);

export default ConfirmSale;
