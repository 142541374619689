import { ASSET_CARD_LEVEL_NAME } from 'config/_const';
import { AssetCardLevel } from 'config/_enums';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import Utils from 'utils/Utils';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import { GroupAssetWalletByLevel } from './types';


export const groupAssetsByLevel = (assetsWallet: AssetWalletInterface[]): GroupAssetWalletByLevel => {
    if (assetsWallet.length === 0)
        return [];
    const enumValuesAsArray: number[] = Utils.valueOfNumberEnum(AssetCardLevel);
    const wallet: GroupAssetWalletByLevel = [];

    for (let i = enumValuesAsArray.length - 1; i >= 0; i = i - 1) {
        const cardLevel: AssetCardLevel = enumValuesAsArray[i];

        const assetWalletReduce = assetsWallet.reduce((acc: { amount: number, assets: AssetViewModel[], countAsset: number }, assetWallet: AssetWalletInterface) => {
            if (assetWallet.asset.cardLevel !== cardLevel)
                return acc;
            return {
                amount: acc.amount.addition(assetWallet.amount),
                assets: acc.assets.concat([new AssetViewModel(assetWallet.asset)]),
                countAsset: ++acc.countAsset
            }
        }, { amount: 0, assets: [], countAsset: 0 });
        if (assetWalletReduce.assets.length === 0)
            continue;
        wallet.push({
            levelName: ASSET_CARD_LEVEL_NAME[cardLevel],
            ...assetWalletReduce,
        });
    }
    return wallet;
}