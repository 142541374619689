import clsx from 'clsx';
import React from 'react';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import type MessageModalProps from './MessageModalProps';
import './MessageModal.scss';
import { Capacitor } from '@capacitor/core';

const MessageModal: React.FunctionComponent<MessageModalProps> = (props: MessageModalProps) => {
    const isNativeDevice: boolean = Capacitor.isNativePlatform();

    return (
        <div className="message-modal-container">
            {props.onClose &&
                <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }} onClick={() => props.onClose ? props.onClose() : undefined}>
                    <HighlightOffRoundedIcon className={clsx('icon', { native: isNativeDevice })} />
                </div>}
            <div className={props.customClass ? props.customClass : 'padding'}>
                <div className="sub-message-container" style={{ lineHeight: props.lineHeight ? props.lineHeight : '23px' }}>{props.message}</div>
                {props.subMessage &&
                    <p>{props.subMessage}</p>
                }
            </div >
        </div>
    );
};

export default MessageModal;
