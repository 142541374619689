import { useDispatch, useSelector } from "react-redux";
import { managementNotification } from "../service/notification/action";
import { globalNotificationSelector } from "service/notification/selectors";
import NotificationReduxStore from "service/notification/NotificationReduxStore";
import { EmptyString } from "config/_const";
const useNotification = () => {
    const dispatch = useDispatch();
    const notificationStore: NotificationReduxStore = useSelector(globalNotificationSelector);
    const notification = (stateNotification: boolean, message: string = EmptyString, status?: number, verticalTop: boolean = false) => {
        return dispatch(managementNotification(stateNotification, message, status, verticalTop));
    };
    return {
        managementNotification: notification,
        notification: notificationStore,
    };
};
export default useNotification;