import IDiffBetweenLeague from 'model/League/IDiffBetweenLeague';
import LeagueAttribution from 'model/League/LeagueAttribution';
import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { leagueAttributionSelector } from 'service/league/selectors';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';
import ILeagueDisclaimerProps from './ILeagueDisclaimerProps';
import './LeagueDisclaimer.scss';

const LeagueDisclaimer: React.FC<ILeagueDisclaimerProps> = ({ userWalletShares, tournament }: ILeagueDisclaimerProps) => {
    const leagueAttribution: LeagueAttribution = useSelector(leagueAttributionSelector);
    const tournamentPrizePool: number = tournament.PrizePool;
    const currentLeague: LeagueViewModel = leagueAttribution.League;
    const nextHigherLeague: LeagueViewModel = leagueAttribution.HigherLeague;
    const tokensDiffBetweenHigherLeague: IDiffBetweenLeague = leagueAttribution.DiffBetweenLeague;

    return (
        <ul className="league-disclaimer-list">
            {currentLeague &&
                <li><Trans
                    i18nKey="tournament.leagues.current-wallet-shares-and-league"
                    components={{ bold: <strong /> }}
                    values={{
                        userWalletShares,
                        currentLeague: currentLeague.Name,
                        currentROI: currentLeague.getROIWithFixedDecimals(tournamentPrizePool),
                        currentYearlyCashPrize: currentLeague.getYearlyCashPrizeToCurrency(tournamentPrizePool),
                        maxSubscriptions: currentLeague.MaxSubscriptions,
                    }} /></li>
            }
            {!tournament.IsPersonalTournament && currentLeague && nextHigherLeague && nextHigherLeague.Name !== currentLeague.Name &&
                <li>
                    <Trans
                        i18nKey="league.above.disclaimer"
                        components={{ span: <strong /> }}
                        values={{
                            tokens: tokensDiffBetweenHigherLeague.tokensLeague,
                            totalTokens: tokensDiffBetweenHigherLeague.totalTokens,
                            leagueTokens: tokensDiffBetweenHigherLeague.tokensLeague,
                            leagueName: tokensDiffBetweenHigherLeague.leagueName,
                        }} />
                </li>
            }
        </ul>
    );
};

export default LeagueDisclaimer;
