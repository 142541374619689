import * as Sentry from "@sentry/react";
import { DEFAULT_ID_AS_STRING } from 'config/_const';
import AssetWalletInterface from "model/AssetModel/AssetWalletInterface";
import ReduxRootState from 'model/Redux/ReduxRootSate';
import ReduxActionInterface from 'model/ReduxActionInterface';
import { WALLET_SIMULATE_BUY, WALLET_SIMULATE_SELL } from 'service/simulation/buy/wallet/actionTypes';
import { RESET_STATE } from '../commonActionTypes';
import { IInternalSimulateBuy, IInternalSimulateSell } from "../simulation/types";
import WalletStoreData from './WalletStoreData';
import * as ActionTypesWallet from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<WalletStoreData> = {
    loading: false,
    data: {
        credits: 0,
        ownedAssets: [],
        totalEarnings: 0,
        totalEarningsHistory: [],
        walletTotalValue: 0,
        walletTotalValueHistory: [],
        loaded: false,
    },
};

export const walletReducer = (state: ReduxRootState<WalletStoreData> = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    switch (action.type) {
        case ActionTypesWallet.WALLET_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ActionTypesWallet.WALLET_ERROR:
            return {
                ...state,
                data: {
                    ...state.data,
                    loaded: true,
                },
                loading: false,
            };
        case ActionTypesWallet.WALLET_SUCCESS:
            return {
                ...state,
                data: {
                    ...(action.payload ? action.payload.data : state.data),
                    loaded: true,
                },
                loading: false,
            };

        case ActionTypesWallet.UPDATE_OWNED_ASSETS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ownedAssets: action.payload.userAssets,
                },
                loading: false,
            };
        case ActionTypesWallet.UPDATE_OWNED_ASSET_INITIAL_SALE_DONE:
            const updatedOwnedAsset = state.data.ownedAssets.map(walletAsset => {
                if (walletAsset.asset._id === action.payload.assetId) {
                    const updatedAsset = { ...walletAsset.asset, initialSaleDone: true };
                    return { ...walletAsset, asset: updatedAsset };
                }
                return walletAsset;
            });
            return {
                ...state,
                data: { ...state.data, ownedAssets: updatedOwnedAsset }
            };
        case ActionTypesWallet.UPDATE_OWNED_ASSET:
            const updatedWallet = state.data.ownedAssets.map(walletAsset => {
                if (walletAsset.asset._id === action.payload.assetId) {
                    const updatedAsset = {
                        ...walletAsset.asset,
                        currentValue: action.payload.currentValue ?? walletAsset.asset.currentValue,
                        availableSupply: action.payload.availableSupply ?? walletAsset.asset.availableSupply,
                        secondaryMarketSupply: action.payload.secondaryMarketSupply ?? walletAsset.asset.secondaryMarketSupply,
                        initialSaleSoldOutDate: action.payload.initialSaleSoldOutDate ?? walletAsset.asset.initialSaleSoldOutDate,
                        initialSaleDone: action.payload.initialSaleDone ?? walletAsset.asset.initialSaleDone
                    };
                    return { ...walletAsset, asset: updatedAsset };
                }
                return walletAsset;
            });
            return {
                ...state,
                data: { ...state.data, ownedAssets: updatedWallet }
            };
        case RESET_STATE:
            return ROOT_VALUE_STATE;
        case WALLET_SIMULATE_BUY.SUCCESS:
            return internalSimulateBuy(action.payload.data, state);
        case WALLET_SIMULATE_SELL.SUCCESS:
            return internalSimulateSell(action.payload.data, state);
        default: return state;
    }
};

const internalSimulateSell = (data: IInternalSimulateSell, state: ReduxRootState<WalletStoreData>): ReduxRootState<WalletStoreData> => {
    const assetIndex: number = state.data.ownedAssets.findIndex((entry: AssetWalletInterface) => entry.asset._id === data.asset._id);
    if (assetIndex === -1) {
        Sentry.captureMessage(`WalletReducer:SimulateSell -> Weird thing, user has sold asset but asset was not found in wallet store... ${data.asset._id}`);
        return state;
    }
    const ownedAsset: AssetWalletInterface = state.data.ownedAssets[assetIndex];
    ownedAsset.amount -= data.numberOfShares;

    if (ownedAsset.amountPaidInVirtualCredits) {
        ownedAsset.amountPaidInVirtualCredits -= data.amountPaidInVirtualCredit
    }
    if (!data.instantSell)
        ownedAsset.frozenAmount += data.numberOfShares;
    return {
        ...state,
        data: {
            ...state.data,
            ownedAssets: state.data.ownedAssets
        }
    };
}

const internalSimulateBuy = (data: IInternalSimulateBuy, state: ReduxRootState<WalletStoreData>): ReduxRootState<WalletStoreData> => {
    const assetIndex = state.data.ownedAssets.findIndex(entry => entry.asset._id === data.asset._id);
    const unitaryPrice = data.price.divide(data.numberOfShares);
    if (assetIndex > -1) {
        const ownedAsset = state.data.ownedAssets[assetIndex];
        if (!ownedAsset.boughtAt)
            ownedAsset['boughtAt'] = unitaryPrice;
        if (ownedAsset.boughtAt)
            ownedAsset.boughtAt = (ownedAsset.boughtAt.multiply(ownedAsset.amount) + data.price).divide(ownedAsset.amount + data.numberOfShares);
        ownedAsset.amount += data.numberOfShares;
        ownedAsset.amountPaidInVirtualCredits = (ownedAsset.amountPaidInVirtualCredits || 0).addition(data.amountPaidInVirtualCredit);
        return {
            ...state,
            data: {
                ...state.data,
                ownedAssets: state.data.ownedAssets
            }
        };
    }
    const ownedAssets = state.data.ownedAssets;
    ownedAssets.push({
        _id: DEFAULT_ID_AS_STRING,
        amount: data.numberOfShares,
        asset: data.asset,
        boughtAt: unitaryPrice,
        stack: [],
        numberToSell: 0,
        frozenAmount: 0,
        amountPaidInVirtualCredits: data.amountPaidInVirtualCredit,
        createdAt: new Date().toISOString(),
    });
    return {
        ...state,
        data: {
            ...state.data,
            ownedAssets: ownedAssets
        }
    }
}
