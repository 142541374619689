import ReduxActionInterface from "model/ReduxActionInterface";
import { ReduxRootState } from "model/Redux";
import { ISportsTeam } from "interfaces/sports/ISportsTeam";
import { SPORTS_TEAMS_ACTION } from "./actionTypes";

const ROOT_VALUE_STATE: ReduxRootState<ISportsTeam[]> = {
    loading: false,
    data: [],
};

export const sportsTeamsReducer = (state = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    switch (action.type) {
        case SPORTS_TEAMS_ACTION.LOADING:
            return {
                ...state,
                loading: true,
            };
        case SPORTS_TEAMS_ACTION.SUCCESS:
            return handleSortSportsTeams(state, action);

        case SPORTS_TEAMS_ACTION.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    };

    function handleSortSportsTeams(state: ReduxRootState<ISportsTeam[]>, action: ReduxActionInterface): ReduxRootState<ISportsTeam[]> {
        const data = action.payload.data;
        if (data.length === 0) {
            return {
                ...state,
                loading: false,
                data,
            };
        }
        const teams: ISportsTeam[] = data;
        const sortTeams = teams.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            return (nameA < nameB) ? -1 : 1;
        });

        return {
            ...state,
            loading: false,
            data: sortTeams,
        };
    }
};