import { DEFAULT_CONTRACT_TERM, DEFAULT_ORDERBOOK_FEES, IRL_SCORE_DETAIL_NAME, LAUNCH_MODE, LP_BUY_FEE, LP_SELL_FEE, LP_SELL_FIXED_FEE, NONE, SECONDARY_MARKET_CURRENT_VALUE_COMPUTATION_METHODS, SECONDARY_MARKET_MATCHING_STRATEGIES, SECONDARY_MARKET_PREDICT_PRICE_METHODS } from "config/_const";
import { AssetCardLevel } from "config/_enums";
import { ISportsCountries } from "interfaces/sports/ISportsCountries";
import { ISportsFootballPlayersInfo } from "interfaces/sports/ISportsPlayer";
import { ISportsTeamNameInfo } from "interfaces/sports/ISportsTeam";
import IAdminAssetFormInitialValues from "./IAdminAssetFormInitialValues";

export const INITIAL_STATE_FORM: IAdminAssetFormInitialValues = {
    icon: '',
    name: '',
    description: '',
    facebook: '',
    facebookFollowers: 0,
    twitter: '',
    twitterFollowers: 0,
    tiktok: '',
    tiktokFollowers: 0,
    instagram: '',
    instagramFollowers: 0,
    snapchat: '',
    snapchatFollowers: 0,
    youtube: '',
    youtubeFollowers: 0,
    youtubeVideo: '',
    mainCategory: '-1',
    subtitle: '',
    listOrder: 0,
    availableForTournament: false,
    IRLScore: 0,
    marketConfiguration: {
        variation: {
            maxVariationRatio: 0,
            minVariationRatio: 0,
            probabilityOfGettingPlusOrMinus: 0,
            enabled: true,
            onlyOnSecondaryMarket: true,
        },
        privateSell: {
            progressBar: true,
            progressBarConfiguration: {
                showProgressFromPrivateSellStartInMinutes: 30,
                minRangeForProgression: 1,
                maxRangeForProgression: 8,
                minimumProgressWidthToAdd: 1.5,
                fakeWidthMagicNumberForProgression: 40,
                defaultWidthForProgressCalculation: 20,
            },
            vipPartAtEachBuyRatio: 0.15,
            chunkProgressiveMarket: false
        },
        secondaryMarket: {
            maximumHighDeviationFromCurrentPrice: 0.2,
            maximumLowDeviationFromCurrentPrice: 0.2,
            currentPriceCalculation: {
                method: SECONDARY_MARKET_CURRENT_VALUE_COMPUTATION_METHODS.ORDERBOOK_PONDERATED_AVG,
                size: 5,
            },
            secondaryMarketPredictPriceExtraRatio: 0.01,
            capForSharesTransferFromAdmin: 5000,
            orderMatchCalculationMethod: SECONDARY_MARKET_MATCHING_STRATEGIES.FLOOR_PRICE,
            enableSellOrderTolerance: false,
            predictPriceMethod: SECONDARY_MARKET_PREDICT_PRICE_METHODS.AVERAGE_PONDERATED_PRICE_OF_ORDERBOOK,
            restriction: {
                enabled: false,
                maxTokensPerUser: 50,
                timeRestrictionInMinutes: 3,
            },
            liquidityPoolFees: {
                buyFees: LP_BUY_FEE,
                sellFees: LP_SELL_FEE,
                sellFixedFees: LP_SELL_FIXED_FEE,
            },
            orderBookFees: {
                sellFees: DEFAULT_ORDERBOOK_FEES.sellFees,
                sellFixedFees: DEFAULT_ORDERBOOK_FEES.sellFixedFees
            },
            isParallelSecondaryMarket: false,
            isSellable: true,
            isBuyable: true,
            warningBeforeSell: false,
            isTokensInjectionEnabled: true,
            launchMode: LAUNCH_MODE.DEFAULT
        },
    },
    yieldEnabled: false,
    contractTerm: DEFAULT_CONTRACT_TERM,
    IRLScoreDetails: [
        {
            engagedFollowers: 0,
            engagedFollowersOverDays: 0,
            followers: 0,
            mediaCount: 0,
            name: IRL_SCORE_DETAIL_NAME.INSTAGRAM,
            progressiveScore: 0,
            relativeScore: 0,
        }
    ],
    cardLevel: AssetCardLevel.Rookie,
    isRetired: false,
    sportsScore: 0,
    scoreBoosted: false
};

export const defaultCountry: ISportsCountries = {
    name: NONE,
    code: '0',
    flag: ''
};

export const defaultSportTeam: ISportsTeamNameInfo = {
    name: NONE,
    _id: '0',
    id: 0
};

export const defaultSportPlayer: ISportsFootballPlayersInfo = {
    id: 0,
    name: NONE,
    firstname: null,
    lastname: null
};