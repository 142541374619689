import React from 'react';
import PaymentCheckbox from 'components/medium/asset-transaction/payment-checkbox/PaymentCheckbox';
import IPaymentOptionCardProps from './IPaymentOptionCardProps';
import clsx from 'clsx';
import "./payment-option-card.scss";

const PaymentOptionCard: React.FC<IPaymentOptionCardProps> = ({ label, info, handleClick, isSelected, value, isDisabled, renderSubComponent }) => {
    return (
        <div className={clsx("payment-card-wrapper", { expanded: isSelected && renderSubComponent })} onClick={() => !isDisabled && handleClick(value)}>
            <div className={clsx("payment-card", { "active": isSelected, disabled: isDisabled })}>
                <div className="payment-card-left">
                    <PaymentCheckbox isChecked={isSelected} onCheck={() => !isDisabled && handleClick(value)} />
                    {label}
                </div>
                <div className="payment-card-right">
                    {info}
                </div>
            </div>
            {renderSubComponent && isSelected &&
                <div className={clsx("payment-subcomponent no-margin-top", { hidden: isDisabled })}>
                    {renderSubComponent()}
                </div>
            }
        </div >
    )
}

export default PaymentOptionCard