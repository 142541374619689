import { Button, ButtonClassKey } from '@material-ui/core';
import React, { MouseEvent } from 'react';
import { IOverrideButtonProps } from '../IButtonProps';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import './style.scss';
import HandleClick from './ClickHandler';

const WhiteButton: React.FC<IOverrideButtonProps> = (props) => {
    const classes: Partial<ClassNameMap<ButtonClassKey>> = {
        root: 'white-button',
    };

    return <Button
        classes={classes}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            HandleClick(event, props.debounceEvent, props.preventDefaultEvent, props.handleClick);
        }}
        variant="contained"
        type={props.type}
        startIcon={props.startIcon}>{props.textButton}</Button>;
};

export default WhiteButton;
