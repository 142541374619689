import { IAssetInAssetTransaction } from '../AssetTransactions/IAssetTransaction';

export default interface TransactionInterface {
    _id: string;
    fiat: number;
    credits: number;
    createdAt: string;
    updatedAt: string;
    type: string;
    source: string;
    status: string;
    transactionId?: string;
    isDebitedBySource?: boolean;
    isCredited?: boolean;
    isDebited?: boolean;
    externalReference?: string;
}

interface IInternalTransaction extends TransactionInterface {
    asset?: IAssetInAssetTransaction;
}

export class InternalTransaction implements IInternalTransaction {
    public _id: string;
    public fiat: number;
    public credits: number;
    public createdAt: string;
    public updatedAt: string;
    public type: string;
    public source: string;
    public status: string;
    public transactionId?: string;
    public isDebitedBySource?: boolean;
    public isCredited?: boolean;
    public isDebited?: boolean;
    public externalReference?: string;
    public asset?: IAssetInAssetTransaction;

    constructor(transaction: IInternalTransaction) {
        this._id = transaction._id;
        this.fiat = transaction.fiat;
        this.credits = transaction.credits;
        this.createdAt = transaction.createdAt;
        this.isCredited = transaction.isCredited;
        this.updatedAt = transaction.updatedAt;
        this.type = transaction.type;
        this.source = transaction.source;
        this.status = transaction.status;
        this.externalReference = transaction.externalReference;
        this.asset = transaction.asset;
    }
}
