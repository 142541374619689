import i18n from '18n';
import clsx from 'clsx';
import LinkButton from 'components/small/buttons/link-button/LinkButton';
import { TEAM_STATUS } from 'config/_const';
import IUseTeamList from 'hooks/tournament/use-team-list/IUseTeamList';
import useTeamList from 'hooks/tournament/use-team-list/useTeamList';
import IUseTournamentTutorial from 'hooks/use-tournament-tutorial/IUseTournamentTutorial';
import useTournamentTutorial from 'hooks/use-tournament-tutorial/useTournamentTutorial';
import React from 'react';
import ITeamListProps from './ITeamListProps';
import IWrapperTeamListProps from './IWrapperTeamListProps';
import TeamAssets from './TeamAssets/TeamAssets';
import './style.scss';

const TeamList: React.FC<IWrapperTeamListProps> = (props: IWrapperTeamListProps) => {
    const { handleTutorialClick }: IUseTournamentTutorial = useTournamentTutorial();

    return (
        <div className="team-list">
            <div key={props.teamBuildingViewModel.id}>
                <div className="team-list-header">
                    <div className={clsx('team-list-header_top-section', { 'collapse-section': props.isCollapsed })}>
                        {!props.onboardingMode && <div className='btn-tutorial-tournament-container'>
                            <LinkButton
                                textButton={i18n.t('btn-portfolios-open-tutorial')}
                                receiveClickEvent={false}
                                className='btn-tutorial-tournament'
                                type={'button'}
                                disabled={false}
                                handleClick={handleTutorialClick}
                                debounceEvent={true}
                                preventDefaultEvent={false} />
                        </div>}
                        <div className='team-list-header_title'>
                            <div>
                                <h3 className="bold black-color label-team">{i18n.t('next.tournament.pick')}</h3>
                                <p>{i18n.t('next.tournament.pick.card')}</p>
                            </div>

                        </div>
                    </div>
                    {!props.onboardingMode && props.teamBuildingViewModel.isEdditing &&
                        <h5 className="team-in-progress">
                            {i18n.t('team.inProgress')}&nbsp;
                            <a className="hover" onClick={props.resetEditingTeam}>{i18n.t('team.reset')}</a>
                        </h5>}
                </div>

                <TeamAssets
                    teamScoreOnTop={props.teamScoreOnTop}
                    embededInModal={false}
                    showGetButton={props.showGetButton}
                    getAssetViewModelsFromTeamAssets={props.getAssetViewModelsFromTeamAssets}
                    showRemoveIcon={props.showRemoveIcon && props.teamBuildingViewModel.status !== TEAM_STATUS.ENGAGED}
                    getAssetFromTeamAsset={props.getAssetFromTeamAsset}
                    teamBuildingViewModel={props.teamBuildingViewModel}
                    deleteAssetFromLocalTeam={props.deleteAssetFromLocalTeam}
                    isCollapsed={props.isCollapsed}
                    showTokenPerSlot={true}
                />

            </div>
        </div>
    );
};

const withTeamList = (Component: React.FC<IWrapperTeamListProps>) => {
    const MemoComponent = React.memo(Component);

    const Wrapper = (props: ITeamListProps) => {
        const {
            getAssetViewModelsFromTeamAssets,
        }: IUseTeamList = useTeamList();

        return <MemoComponent
            {...props}
            getAssetViewModelsFromTeamAssets={getAssetViewModelsFromTeamAssets}
        />;
    };
    return React.memo(Wrapper);
};

export default withTeamList(TeamList);
