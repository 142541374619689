import ReduxActionInterface from '../../model/ReduxActionInterface';
import * as NotifcationActionsType from './actionsType';
import ReduxRootState from '../../model/Redux/ReduxRootSate';

const ROOT_VALUE_STATE: ReduxRootState = {
    data: {
        display: false,
        status: 200,
        message: '',
        verticalTop: false,
        link: '',
    },
    loading: true,
};

export const notificationReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {
        case NotifcationActionsType.NOTIFICATION_DISPLAY:
            return {
                ...state,
                data: {
                    display: true,
                    status: action.payload.status,
                    message: action.payload.message,
                    verticalTop: action.payload.verticalTop,
                    link: action.payload.link ?? '',
                },
            };

        case NotifcationActionsType.DISMISS:
            return {
                ...state,
                data: {
                    display: false,
                },
            };

        default: return state;
    }
};
