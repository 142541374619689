import { ThunkAction } from 'redux-thunk';
import { IHttpStrongTypedResponse } from 'model/IHttpResponse';
import AxiosConfig from 'config/axiosConfig';
import { SCORE_HISTORY_ENDPOINTS } from 'config/endpoints';
import { HTTP_STATUS_OK } from 'config/_const';
import * as ActionsType from './actionsType';
import type { ITournamentEvolutionBody, GetEvolutionsAction } from './types';
import type ITournamentEvolution from 'model/Tournament/ITournamentEvolution';
import type { RootState } from 'model/Redux';

export const getEvolutions = (args: ITournamentEvolutionBody): ThunkAction<void, RootState, unknown, GetEvolutionsAction> => async (dispatch) => {
    try {
        dispatch({ type: ActionsType.GET_EVOLUTIONS.LOADING });
        const response: IHttpStrongTypedResponse<ITournamentEvolution[]> = await AxiosConfig.post(SCORE_HISTORY_ENDPOINTS.GET_EVOLUTION, args);
        if (response.data.status !== HTTP_STATUS_OK) throw new Error(response.data.message);
        return dispatch({
            type: ActionsType.GET_EVOLUTIONS.SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        let message = 'Server.evolution.generic-error';
        if (error instanceof TypeError)
            message = error.message;
        console.error(error);
        return dispatch({
            type: ActionsType.GET_EVOLUTIONS.ERROR,
            payload: { message },
        });
    }
};
