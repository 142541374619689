import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import AxiosConfig from '../../config/axiosConfig';
import { HTTP_STATUS_OK } from 'config/_const';
import { WITHDRAW_REQUESTS_ENDPOINTS } from 'config/endpoints';
import * as WithdrawFeeActionsTypes from './actionsTypes';
import { IHttpStrongTypedResponse } from 'model/IHttpResponse';
import IWithdrawFeeStoreData from './IWithdrawFeeStoreData';
import { debounce } from 'lodash';


const debouncedLoadWithdrawFeesRate = debounce(async (params: { currency: string, isSepaWithdrawRequest: boolean, bicOrSwift: string, iban: string }, dispatch) => {
    dispatch({ type: WithdrawFeeActionsTypes.WITHDRAWS_FEE_LOADING });
    try {
        const response: IHttpStrongTypedResponse<IWithdrawFeeStoreData> = await AxiosConfig.post(`${WITHDRAW_REQUESTS_ENDPOINTS.FEES}`, params); 
        if (response && response.data && response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: WithdrawFeeActionsTypes.WITHDRAWS_FEE_ERROR,
            });
        return dispatch({
            type: WithdrawFeeActionsTypes.WITHDRAWS_FEE_SUCCESS,
            payload: {
                feesRate: response.data.data.feesRate,
                feesFixedAmount: response.data.data.feesFixedAmount,
            },
        });
    } catch (error) {
        return dispatch({
            type: WithdrawFeeActionsTypes.WITHDRAWS_FEE_ERROR,
        });
    }
}, 500);
export const loadWithdrawFeesRate = (params: { currency: string, isSepaWithdrawRequest: boolean, bicOrSwift: string, iban: string }): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => debouncedLoadWithdrawFeesRate(params, dispatch)