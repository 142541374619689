import { Action } from 'redux';
import { batchActions } from 'redux-batched-actions';
import { ThunkAction } from 'redux-thunk';
import AxiosConfig from '../../config/axiosConfig';
import { IHttpResponse } from '../../model/IHttpResponse';
import { showNotification } from '../notification/action';
import { USER_SUCCESS } from '../users/actionsType';
import * as AssetRequestActionType from './actionType';

export const actionFetchAssetRequest = (filter?: Object, sort?: string, search?: string, populate?: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    dispatch({
        type: AssetRequestActionType.ASSET_REQUEST_LOADING,
    });

    let queryRequest: string = '';

    if (filter) {
        queryRequest += `filter=${JSON.stringify(filter)}`;
    }

    if (sort) {
        queryRequest += `sort=${sort}`;
    }

    if (search) {
        queryRequest += `&search=${search}`;
    }

    if (populate) {
        queryRequest += `&with=${populate}`;
    }

    try {
        const response: IHttpResponse = await AxiosConfig.get(`/assetRequest/all?${queryRequest}`);

        if (response.data.status !== 200) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: AssetRequestActionType.ASSET_REQUEST_ERROR,
            });
        }

        return dispatch({
            type: AssetRequestActionType.ASSET_REQUEST_SUCCESS,
            payload: {
                assetRequest: response.data.data,
            },
        });

    } catch (error) {
        showNotification(dispatch, error.message, 500);
        return dispatch({
            type: AssetRequestActionType.ASSET_REQUEST_ERROR,
        });
    }
};

export const actionFetchAssetRequestById = (assetRequestId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    dispatch({
        type: AssetRequestActionType.ASSET_REQUEST_LOADING,
    });

    try {
        const response: IHttpResponse = await AxiosConfig.get(`/assetRequest/${assetRequestId}`);
        if (response.data.status !== 200) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: AssetRequestActionType.ASSET_REQUEST_ERROR,
            });
        }
        if (response.data.data.user) {
            dispatch({
                type: USER_SUCCESS,
                payload: {
                    data: [response.data.data.user],
                },
            });
        }

        return dispatch({
            type: AssetRequestActionType.ASSET_REQUEST_SUCCESS,
            payload: {
                assetRequest: [response.data.data.assetRequest],
            },
        });

    } catch (error) {
        showNotification(dispatch, error.message ?? error.stack, 500);
        return dispatch({
            type: AssetRequestActionType.ASSET_REQUEST_ERROR,
        });
    }
};

export const actionUpdateAssetRequestById = (assetRequestId: string, body: Object): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    dispatch({
        type: AssetRequestActionType.ASSET_REQUEST_LOADING,
    });

    try {
        const response: IHttpResponse = await AxiosConfig.put(`/assetRequest/${assetRequestId}`, body);
        showNotification(dispatch, response.data.message, response.data.status);
        if (response.data.status !== 200) {
            return dispatch({
                type: AssetRequestActionType.ASSET_REQUEST_ERROR,
            });
        }

        return dispatch({
            type: AssetRequestActionType.ASSET_REQUEST_SUCCESS,
            payload: {
                assetRequest: [response.data.data.assetRequest],
            },
        });

    } catch (error) {

        showNotification(dispatch, error.message ?? error.stack, 500);

        return dispatch({
            type: AssetRequestActionType.ASSET_REQUEST_ERROR,
        });
    }
};

export const actionUpdateStatusRequestAndCreateVipAsset = (assetData: Object, userId: string, assetRequestData: Object, assetRequestId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    dispatch({
        type: AssetRequestActionType.ASSET_REQUEST_LOADING,
    });

    try {

        const response: IHttpResponse = await AxiosConfig.post('/assetRequest/updateStatusRequestAndCreateVipAsset', { assetData, userId, assetRequestData, assetRequestId });

        showNotification(dispatch, response.data.message, response.data.status);

        if (response.data.status !== 200) {
            return dispatch({
                type: AssetRequestActionType.ASSET_REQUEST_ERROR,
            });
        }

        return dispatch(batchActions(
            [
                {

                    type: AssetRequestActionType.ASSET_REQUEST_SUCCESS,
                    payload: {
                        assetRequest: [response.data.data.assetRequest],
                    },
                },
                {
                    type: USER_SUCCESS,
                    payload: {
                        data: [response.data.data.user],
                    },
                },
            ]),
        );

    } catch (error) {

        showNotification(dispatch, error.stack, 500);

        return dispatch({
            type: AssetRequestActionType.ASSET_REQUEST_ERROR,
        });
    }
};

