import i18n from '18n';
import ConfirmProps from 'components/small/confirm/ConfirmProps';
import { MINIMUM_REFUND_AMOUNT } from 'config/_const';
import { useDispatch } from 'react-redux';
import { managementModal } from 'service/modal/action';
import Utils from 'utils/Utils';
import IDisplayConfirmTeamDialog from './IDisplayConfirmTeamDialog';
import IShowBuyTicketConfirmationProps from './IShowBuyTicketConfirmationParams';
import IUseDialog from './IUseDialog';

const useDialog = (): IUseDialog => {
    const dispatch = useDispatch();

    const displayCreditDialog = (): void => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'CREDITS_FORM',
            propsCustom: {
                amount: MINIMUM_REFUND_AMOUNT,
            },
        }));
    };

    const closeDialog = () => dispatch(managementModal(false));

    const displayConfirmTeamDialog = (props: IDisplayConfirmTeamDialog): void => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: Utils.isMobile(),
            fullScreen: Utils.isMobile(),
            type: 'CONFIRM_TEAM',
            propsCustom: {
                teamViewModel: props.teamViewModel,
                onSaveTeamCallback: props.onSaveTeamCallback,
            },
        }));
    };

    const displaySimpleConfirmDialog = (args: ConfirmProps) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showCancelButton: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                ...args,
            },
        }));
    };

    const displayFutureFeature = () => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                message: i18n.t('feature-to-come'),
                textValidButton: i18n.t('confirm'),
                showCancelButton: false,
                onValidation: closeDialog,
            },
        }));
    };

    const openIdentityVerificationDialog = () => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showCancelButton: false,
            fullScreen: false,
            type: 'IDENTITY_VERIFICATION',
            propsCustom: {},
        }));
    }

    const showBuyTicketConfirmation = ({ league, onButtonClick }: IShowBuyTicketConfirmationProps) => {
        return managementModal(true, {
            display: true,
            disableBackDrop: true,
            showBackArrow: false,
            showCancelButton: false,
            fullScreen: false,
            showModalAtBottom: true,
            type: 'BUY_TOURNAMENT_SUBSCRIPTION_CONFIRMATION',
            propsCustom: {
                league,
                onButtonClick: () => {
                    onButtonClick && onButtonClick();
                    closeDialog();
                },
            },
        });
    };

    const showBuyTicketError = ({ league, onButtonClick }: IShowBuyTicketConfirmationProps) => {
        return managementModal(true, {
            display: true,
            disableBackDrop: true,
            showBackArrow: false,
            showCancelButton: false,
            fullScreen: false,
            showModalAtBottom: true,
            type: 'BUY_TOURNAMENT_SUBSCRIPTION_ERROR',
            propsCustom: {
                league,
                onButtonClick: () => {
                    onButtonClick && onButtonClick();
                    closeDialog();
                },
            },
        });
    };

    return {
        closeDialog,
        displayCreditDialog,
        displaySimpleConfirmDialog,
        displayConfirmTeamDialog,
        displayFutureFeature,
        openIdentityVerificationDialog,
        showBuyTicketConfirmation,
        showBuyTicketError,
    };
};

export default useDialog;
