import React from 'react';
import SecondaryBtnProps from './SecondaryBtnProps';
import './SecondaryBtn.scss';

const SecondaryBtn: React.FC<SecondaryBtnProps> = (props) => {
    return (
        <button
            style={props.style}
            className={`secondary-btn ${props.className}`}
            type={props.type}
            disabled={props.loading || props.disabled}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.handleClick ? props.receiveClickEvent ? props.handleClick(e) : props.handleClick() : undefined} >
            {props.textButton}
            {props.loading && <div className="loader loader-box" />}
        </button>
    );
};

export default SecondaryBtn;
