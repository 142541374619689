import { useEffect, useState } from 'react';
import { MENU_MOBILE_BREAKPOINT } from '../config/_const';
import useWindowSize from './useWindowSize';

function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);
    const [width] = useWindowSize();

    useEffect(() => {
        if (width <= MENU_MOBILE_BREAKPOINT)
            return setIsMobile(true);
        return setIsMobile(false);
    }, [width]);

    return isMobile;
}

export default useIsMobile;
