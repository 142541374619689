import * as React from "react";

function Music() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.556'
      height='15.556'
      viewBox='0 0 15.556 15.556'
    >
      <path
        id='music-solid'
        d='M15.528,11.153a2.7,2.7,0,0,1-2.889,2.431,2.7,2.7,0,0,1-2.889-2.4,2.678,2.678,0,0,1,2.889-2.4,3.412,3.412,0,0,1,.973.149V4.5L5.833,6.806v6.323a2.734,2.734,0,0,1-2.944,2.428A2.663,2.663,0,0,1,0,13.125a2.678,2.678,0,0,1,2.889-2.4,3.408,3.408,0,0,1,.972.149V3.843a1.035,1.035,0,0,1,.681-.927L14.237.045A1.856,1.856,0,0,1,14.583,0a.973.973,0,0,1,.972.972Z'
        fill='#15182b'
      />
    </svg>
  );
}

export default Music;
