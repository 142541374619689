import ReduxActionInterface from '../../model/ReduxActionInterface';
import ReduxRootState from '../../model/Redux/ReduxRootSate';

import * as AssetsConfigActionType from './actionsType';
import Utils from '../../utils/Utils';

const ROOT_VALUE_STATE: ReduxRootState = {
    loading: false,
    data: [],
};

export const assetsConfigReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {
        case AssetsConfigActionType.ASSETS_CONFIG_LOADING:
            return {
                ...state,
                loading: true,
            };

        case AssetsConfigActionType.ASSETS_CONFIG_ERROR:
            return {
                ...state,
                loading: false,
            };

        case AssetsConfigActionType.ASSETS_CONFIG_SUCCESS:
            const stateData = state.data.length > 0 ? Utils.removedDuplicateMongoFile(state.data, action.payload.assetsConfig) : [];
            return {
                ...state,
                data: stateData.concat(action.payload.assetsConfig),
                loading: false,
            };
        default: return state;
    }
};

