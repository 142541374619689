import React from 'react';

const Subtract = () => {
    return (<svg id="circle-minus-duotone_1_" data-name="circle-minus-duotone (1)" xmlns="http://www.w3.org/2000/svg" width="19.685" height="19.685" viewBox="0 0 19.685 19.685">
        <path id="Tracé_3182" data-name="Tracé 3182" d="M144,232.923a.921.921,0,0,1,.923-.923h6.767a.923.923,0,1,1,0,1.845h-6.767A.921.921,0,0,1,144,232.923Z" transform="translate(-138.464 -223.08)" fill="#fff" />
        <path id="Tracé_3183" data-name="Tracé 3183" d="M0,9.842a9.842,9.842,0,1,1,9.842,9.842A9.842,9.842,0,0,1,0,9.842ZM6.459,8.92a.923.923,0,1,0,0,1.845h6.767a.923.923,0,1,0,0-1.845Z" fill="#fff" opacity="0.4" />
    </svg>)
}

export default Subtract;
