import i18n from '18n';
import { Add, Remove } from '@material-ui/icons';
import clsx from 'clsx';
import ArrowLeftRight from 'components/icons/ArrowLeftRight';
import Error from 'components/icons/Error';
import Shares from 'components/icons/Shares';
import Trophy from 'components/icons/Trophy';
import Yield from 'components/icons/Yield';
import DiscordIcon from 'components/small/DiscordIcon/DiscordIcon';
import AssetAvatar from 'components/small/asset-avatar/AssetAvatar';
import { TRANSACTION_VIEW_SIZE } from 'config/_const';
import { TransactionAction, TransactionType } from 'config/_enums';
import React, { useState } from 'react';
import TransactionViewModel from 'view-model/TransactionViewModel/TransactionViewModel';
import clockTimeGif from "../../../../assets/images/clock-time.gif";
import UserOpenOrders from '../wallet-pending-transactions/UserOpenOrders';
import IWalletTransactionProps from './IWalletTransactionsProps';
import "./wallet-transactions.scss";
import WalletPendingWithdraws from '../wallet-pending-withdraws/WalletPendingWithdraws';

const transactionIconMap: Record<string, JSX.Element> = {
    [TransactionType.BUY]: <Shares />,
    [TransactionType.SELL]: <Shares />,
    [TransactionType.REWARD]: <Trophy style={{ fill: '#fff', height: '18px' }} />,
    [TransactionType.YIELD]: <Yield />,
    [TransactionType.DISCORD_GIVEAWAY]: <DiscordIcon />,
    [TransactionType.MARKETPLACE]: <ArrowLeftRight />
}
const WalletTransactions: React.FC<IWalletTransactionProps> = ({ transactions }) => {
    const [viewSize, setViewSize] = useState(TRANSACTION_VIEW_SIZE);

    const getTransactionSign = (transaction: TransactionViewModel) => {
        return transaction.Action === TransactionAction.DEBIT ? <Remove /> : <Add />;
    }

    const handleSeeAll = () => {
        setViewSize(transactions.length);
    }

    const getTransactionNotSucceededIcon = (transaction: TransactionViewModel) => {
        if (transaction.TransactionPending) {
            return <img src={clockTimeGif} alt="clock-time" className='bottom-clock-gif' />
        }
        return <Error />
    }


    return (<div className='wallet-transactions-container'>
        <UserOpenOrders />
        <WalletPendingWithdraws />
        <div className='section-title'>{i18n.t("wallet.transaction.section.title")}</div>
        {transactions.slice(0, viewSize).map(transaction => {
            return <div className='wallet-transaction-card' key={transaction.Id}>
                <div className="wallet-transaction-avatar">
                    {transaction.RelatedAsset ? <AssetAvatar asset={{
                        mainCategoryId: transaction.RelatedAsset.mainCategory,
                        avatarPath: transaction.RelatedAsset.icon,
                        cardLevel: transaction.RelatedAsset.cardLevel
                    }} /> : <img src={transaction.TransactionImage} alt="asset-avatar" className='avatar-image' />}
                    <div className="transaction-type-icon">{transaction.HasFailed || transaction.TransactionPending ? getTransactionNotSucceededIcon(transaction) : transactionIconMap[transaction.TransactionType || ""]}</div>
                </div>
                <div className="wallet-transaction-sign">{getTransactionSign(transaction)}</div>
                <div className="wallet-transaction-detail">
                    <span className={clsx('wallet-transaction-amount', { 'transaction-failed-text': transaction.HasFailed })}>
                        {transaction.TransactionCapital.toCurrency()}
                    </span>
                    <span className="wallet-transaction-description">{transaction.TransactionDetail}</span>
                </div>
                <div className="wallet-transaction-date">{new Date(transaction.TransactionDate).formatDDMMYY()}</div>
            </div>
        })}
        {viewSize < transactions.length && <span className="see-more" onClick={handleSeeAll}>{i18n.t("wallet.transaction.seeMore")}</span>}
    </div>)
}

export default WalletTransactions;