import IYieldInformationProps from "components/medium/token-information/small/yield/yield-information/IYieldInformation";
import IUseYield from "hooks/use-yield/IUseYield";
import useYield from "hooks/use-yield/useYield";
import React from "react";
import IWithYieldInformation from "./IWithYieldInformation";

const withYieldInformation = (Component: React.FC<IYieldInformationProps>) => {
    const MemoComponent = React.memo(Component);

    const Wrapper: React.FC<IWithYieldInformation> = ({ assetVM, canShowAsBanner }) => {
        const { shouldShowYield, calculateMaxAPY }: IUseYield = useYield();
        const shouldShowYieldBox: boolean = (shouldShowYield?.(assetVM) ?? false) && !assetVM.enableCustomAssetProfil();

        return (!shouldShowYieldBox)
            ? <></>
            : < MemoComponent assetVM={assetVM} canShowAsBanner={canShowAsBanner} calculateMaxAPY={calculateMaxAPY} />
    }

    return React.memo(Wrapper);
}

export default withYieldInformation;