import i18n from '18n';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandMore } from '@material-ui/icons';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Application from 'Application';
import benzemaThumnail from 'assets/images/Benzema-club/KarimBenzema_thumbnail.png';
import AssetMarketPlace from 'components/medium/asset-market-place/AssetMarketPlace';
import DescriptionFunCardsAssetProfilPage from 'components/medium/description-fun-cards-asset-profil-page/DescriptionFunCardsAssetProfilPage';
import WrapperFirstPurchaseBannerFunCards from 'components/medium/first-purchase-banner-fun-cards/WrapperFirstPurchaseBannerFunCards';
import ProfilBanner from 'components/medium/profil-banner/ProfilBanner';
import ProfileMidSection from 'components/medium/profile-mid-section/ProfileMidSection';
import YieldInformation from 'components/medium/token-information/small/yield/yield-information/YieldInformation';
import { default as CircularIndeterminate, default as CircularLoader } from 'components/small/CircularLoader';
import AssetProfilActionButtons from 'components/small/asset-profil-action-button/AssetProfilActionButtons';
import TransitionWrapper from 'components/small/asset-slide-up-view/TransitionWrapper';
import BuyPacksButtonDisclaimer from 'components/small/buy-packs-button-disclaimer/BuyPacksButtonDisclaimer';
import Euro2024AssetDisclaimer from 'components/small/euro-2024-asset-disclaimer/Euro2024AssetDisclaimer';
import ReferenceYourFriends from 'components/small/referenceYourFriends/ReferenceYourFriends';
import SuccessBuyAssetSharePage from 'components/small/successBuyAssetSharePage/SuccessBuyAssetSharePage';
import { ASSET_SLIDE_DOWN_DURATION, ASSET_SLIDE_UP_DURATION, ASSET_TRANSACTIONS_STATUS, BENZEMA_BANNER_VIDEO, G_TAGS_KNOWN_EVENTS, HTTP_STATUS_OK, LANGUAGES, LOCAL_STORAGE_KNOWN_KEYS, ORDER_CREATED_SUCCESSFULLY_STATUS, ORDER_STATUS, TRENDEX_HTTP_STATUS_NOK } from 'config/_const';
import { MarketEnum, Status } from 'config/_enums';
import StripeConfig from 'config/stripe';
import { getPrivateSellExplainer } from 'data/YoutubeVideo/youtubeVideo.data';
import withYield from 'hoc/with-yield/withYield';
import useShouldHideInstagramAssets from 'hooks/useShouldHideInstagramAssets';
import i18next from 'i18next';
import AssetInterface, { AssetInterfaceWithTranslatedDescriptions } from 'model/AssetModel/AssetInterface';
import IOrder from 'model/IOrder';
import IShareAssetBuyModal from 'model/IShareAssetBuyModal';
import TriggerCardPaymentInputsInterface from 'model/Payment/TriggerCardPaymentInputsInterface';
import { RootState } from 'model/Redux';
import TransactionInterface from 'model/User/TransactionInterface';
import moment from 'moment';
import BannerVideoSlide from 'page/Top50/top50Carousel/slides/VideoSlide';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getAssetBySlug, getTranslatedDescription } from 'service/assets/actions';
import { getSocialNetworks } from 'service/assets/getters';
import { acceptCGUPayment, addAssetSharesToAssetWallet, getUserCredits } from 'service/auth/actions';
import { postSubscriptionStatusSelector, selectorAccountViewModel } from 'service/auth/selectors';
import PixelHandlerBuyOnPrimaryMarket from 'service/facebook-pixel/PixelHandlerBuyOnPrimaryMarket';
import PixelHandlerBuyOnSecondaryMarket from 'service/facebook-pixel/PixelHandlerBuyOnSecondaryMarket';
import ModalReduxStore from 'service/modal/ModalReduxStore';
import { managementModal } from 'service/modal/action';
import { managementNotification, showNotification } from 'service/notification/action';
import { CardPayment, getStatusAssetPayment, getTransactionPayment, paypalPaymentSuccess, secondaryMarketStripePayment } from 'service/payment/actions';
import { actionShare } from 'service/share/actions';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import Utils from 'utils/Utils';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import './AssetProfilPage.scss';
import AssetProfilPageProps, { WithAssetProfileProps } from './AssetProfilPageProps';
import AssetProfilPageState from './AssetProfilPageState';
import AssetDescription from './local-components/AssetDescription/AssetDescription';
import AssetDetail from './local-components/AssetDetail/AssetDetail';
import AssetEvolutions from './local-components/AssetEvolutions/AssetEvolutions';
import AssetPriceEvolution from './local-components/AssetEvolutions/AssetPriceEvolution/AssetPriceEvolution';
import BenzemaClubSection from './local-components/Benzema-club-section/BenzemaClubSection';
import SocialBadgesBanner from './local-components/SocialBadgesBanner/SocialBadgesBanner';


// Private interfaces
interface IPaymentUrlParams {
    assetId: string | null;
    tokens: string | null;
    paymentId: string | null;
    token: string | null;
    payerId: string | null;
    urlParamsTransactionId: string | null;
}
interface IPostPaymentParams {
    pendingTransaction: boolean;
    isCredited: boolean;
    transactionId: string;
}

interface ICheckLoaderParams {
    isDirectStatus: boolean;
    isPropsStatus: boolean;
    transactionStatus: string;
    pendingTransaction: boolean;
    isCredited: boolean;
    urlSearchParams: URLSearchParams;
    transactionId: string;
    checkLoaderCallback: Function;
    getTransactionStatusCallback: Function;
}

const stripePromise = loadStripe(StripeConfig.publicKey);

class AssetProfilPage extends React.Component<WithAssetProfileProps, AssetProfilPageState> {

    constructor(props: WithAssetProfileProps) {
        super(props);
        this.state = {
            showLoader: false,
            showNewPriceError: false,
            succesConfirm: false,
            classPricingPage: 'pricing-page',
            loaderMessage: i18next.t('trendexloading'),
            classColorRemainingAsset: 'title-asset remaining-asset',
            updateAlreadyCalled: false,
            transactionId: '',
            transactionStatus: '',
            assetInterval: 0,
            pendingTransaction: false,
            paymentTimeInterval: 0,
            userReturnedToSiteFromPaymentLoader: false,
            slugParam: this.props.slug || this.props.match?.params?.slug,
            numberOfTokensInStepper: 1,
        };
        this.buyOnPrimaryMarketPixelHandler = new PixelHandlerBuyOnPrimaryMarket(true);
        this.buyOnSecondaryMarketPixelHandler = new PixelHandlerBuyOnSecondaryMarket(true);
    }

    //#region Static members
    private static transactionMayTakeSeveralMinutes: string = i18next.t('initPayment.transactionMayTakeSeveralMinutes');
    private static maximumTimeBeforeShowingLoaderButtonInMiliseconds: number = 3000;
    private static pendingPaymentRequestStatus: string = 'PENDING';
    private static successPaymentRequestStatus: string = 'SUCCESS';
    private static errorPaymentRequestStatus: string = 'ERROR';
    private static priceChangedRequestStatus: string = 'PRICE_CHANGED';
    private static assetTransactionStatus: string[] = [ASSET_TRANSACTIONS_STATUS.SUCCESS, ASSET_TRANSACTIONS_STATUS.ERROR, ASSET_TRANSACTIONS_STATUS.PRICE_CHANGED];
    private static paymentRequestStatusWithourPending: string[] = [AssetProfilPage.successPaymentRequestStatus, AssetProfilPage.errorPaymentRequestStatus, AssetProfilPage.priceChangedRequestStatus];
    private static paymentIntentSuccessStatus: string = 'succeeded';
    //#endregion

    private buyOnPrimaryMarketPixelHandler: PixelHandlerBuyOnPrimaryMarket;
    private buyOnSecondaryMarketPixelHandler: PixelHandlerBuyOnSecondaryMarket;
    //#region Component lifecycle

    componentDidMount = () => {
        // Scroll to top so that someone coming from a scrolled assetlist sees the top of the page
        if (!this.props.slug) window.scrollTo(0, 0);
        const assets: AssetInterface[] = this.props.assets.data || [];
        const slugParam: string = this.props.slug || this.state.slugParam;
        let requireLaunchAssetsAction: boolean = false;
        const asset: AssetInterface | undefined = assets.find((asset: AssetInterface) => asset && asset.slug === slugParam);

        // redirect to home-page if user donot own any influence asset
        if (asset)
            this.checkInfluenceCategoryAndRedirect(asset.mainCategory)


        if (assets.length > 0) {
            requireLaunchAssetsAction = this.fetchActualAssets(assets, slugParam);
        } else {
            requireLaunchAssetsAction = true;
        }
        if (requireLaunchAssetsAction)
            return this.props.getAssetBySlug(slugParam);
        if (!Utils.isDefined(asset))
            return;
        const assetViewModel = new AssetViewModel(asset);
        this.setState({ slugParam });
        this.props.setPrivateSellIsOpen(assetViewModel.IsIcoOpen);

        const userLanguage = Utils.getCurrentLanguage();
        if (!asset.description || userLanguage !== LANGUAGES.FR)
            this.props.getTranslatedDescription(asset._id, userLanguage);
        assetViewModel.InitialSaleDone ? this.postPaymentOnSecondaryMarket() : this.postPaymentOnPrimaryMarket();

        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.VIRTUAL_PAGE_VIEW, {
            virtualPageURL: '/pre-buy',
            virtualPageTitle: 'Payment intent',
        }, 'PageDataLayer');
        if (!localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.FUN_ASSET_MODAL_SEEN) && assetViewModel.IsFunCategory)
            return this.props.managementModal(true, {
                display: true,
                disableBackDrop: true,
                showBackArrow: false,
                fullScreen: false,
                type: 'FUN_ASSET_MODAL',
                propsCustom: {
                    onValidation: () => this.props.managementModal(false)
                },
            });
    }

    checkInfluenceCategoryAndRedirect = (mainCategory: string) => {
        const isMainCategoryInfluence = (mainCategory === this.props.hiddenCategory?.Id);
        if (this.props.shouldHideInfluenceAssets && isMainCategoryInfluence) { this.props.history.push("/") };
    }

    componentWillUnmount = () => {
        clearInterval(this.state.assetInterval);
    }

    componentDidUpdate = (prevProps: AssetProfilPageProps) => {
        const paymentStopLoading: boolean = (prevProps.payment.loading && !this.props.payment.loading);

        if (prevProps.payment.data.assetStatus !== this.props.payment.data.assetStatus && this.props.payment.data.assetStatus === AssetProfilPage.pendingPaymentRequestStatus && !this.state.paymentTimeInterval) {
            this.setState({ paymentTimeInterval: new Date().getTime() });
        }

        if ((this.props.payment.data.assetStatus === AssetProfilPage.successPaymentRequestStatus || this.props.payment.data.assetStatus === AssetProfilPage.errorPaymentRequestStatus) && this.state.paymentTimeInterval !== 0) {
            this.setState({ paymentTimeInterval: 0 });
        }

        if (this.paymentTransactionTakeTooMuchTime() && this.state.loaderMessage !== AssetProfilPage.transactionMayTakeSeveralMinutes) {
            this.setState({ loaderMessage: AssetProfilPage.transactionMayTakeSeveralMinutes });
        }
        if ((prevProps.match?.params?.slug || prevProps.slug) !== this.state.slugParam) {
            this.props.getAssetBySlug(this.state.slugParam);
        }

        if (paymentStopLoading) {
            const query = new URLSearchParams(this.props.location.search);
            const isPaymentSucceeded = query.get('paymentSuccess');
            const transactionId = query.get('transactionId');

            if ((!isPaymentSucceeded || isPaymentSucceeded == 'false') && transactionId && transactionId !== 'false') {
                this.hideFromLoaderButton();
                this.props.managementNotification(true, i18next.t('purchase.canceled'), TRENDEX_HTTP_STATUS_NOK);
            }
            this.clearQueryOnComponentLoad();
        }

        const currentAsset: AssetInterface | undefined = this.props.assets.data?.find((asset: AssetInterface) => asset && asset.slug === this.state.slugParam);
        const previousAsset: AssetInterface | undefined = prevProps.assets.data?.find((asset: AssetInterface) => asset && asset.slug === this.state.slugParam);
        if (currentAsset && !previousAsset)
            this.props.setPrivateSellIsOpen(new AssetViewModel(currentAsset).IsIcoOpen);
        if (currentAsset && previousAsset)
            this.shouldClosePrivateSell(new AssetViewModel(currentAsset), new AssetViewModel(previousAsset));
    }

    shouldClosePrivateSell = (asset: AssetViewModel, previousAsset: AssetViewModel): void => {
        const isInitialSaleDone: boolean = asset.InitialSaleDone && !previousAsset.InitialSaleDone;
        const noRemainingTokens: boolean = asset.AvailableSupply <= 0 && previousAsset.AvailableSupply > 0;
        if (!isInitialSaleDone || !noRemainingTokens)
            return;
        return this.props.setPrivateSellIsOpen(false);
    }

    //#region Loader

    showLoader = () => {
        this.setState({
            showLoader: true,
        });
    }

    hideLoader = () => {
        this.setState({
            showLoader: false,
        });
    }

    hideFromLoaderButton = () => {
        this.hideLoader();
        this.setState({ userReturnedToSiteFromPaymentLoader: true });
    }

    //#endregion

    //#region Payment

    anonymousFunctionOfGetStatusAsset = () => {
        this.getStatusAsset(this.state.transactionId, this.props.payment.data.assetStatus);
    }

    paymentTransactionTakeTooMuchTime = () => this.state.paymentTimeInterval != 0 && new Date().getTime() - this.state.paymentTimeInterval >= AssetProfilPage.maximumTimeBeforeShowingLoaderButtonInMiliseconds;

    handlePaymentTransactionError = (isCredited: boolean): void => {
        if (isCredited) {
            return this.handlePaymentErrorButWalletCredited();
        }
        this.setState({
            showLoader: false,
            showNewPriceError: false,
            userReturnedToSiteFromPaymentLoader: false,
        });
        return this.props.managementNotification(true, i18next.t('Common.an-error-occurred'), TRENDEX_HTTP_STATUS_NOK);
    }

    handlePaymentTransactionSuccess = (successPaymentCallback: Function): void => {
        this.setState({
            showLoader: false,
            transactionId: '',
            userReturnedToSiteFromPaymentLoader: false,
        });
        successPaymentCallback();
        this.setState({
            loaderMessage: i18next.t('trendexloading'),
            succesConfirm: true,
            pendingTransaction: false,
            showNewPriceError: false,
            userReturnedToSiteFromPaymentLoader: false,
        });
    }

    checkLoader = (params: ICheckLoaderParams): void => {
        this.setState({
            transactionId: params.transactionId,
        });
        const assetInterval = setTimeout(() => {
            if (!this.state.succesConfirm && params.pendingTransaction && (params.isDirectStatus || params.isPropsStatus)) {
                params.urlSearchParams.delete('transactionId');
                clearTimeout(this.state.assetInterval);
                params.isDirectStatus ? params.checkLoaderCallback(params.transactionStatus, params.isCredited) : params.checkLoaderCallback(params.transactionStatus);
                return null;
            }
            params.getTransactionStatusCallback();
        }, 2500);
        this.setState({
            assetInterval,
        });
    }

    checkLoaderForSecondaryMarket = (transactionStatus: string, _isCredited?: boolean) => {
        const isCredited = _isCredited ?? this.props.payment.data.isCredited;
        if (this.state.succesConfirm || !transactionStatus)
            return;
        switch (transactionStatus) {
            case AssetProfilPage.successPaymentRequestStatus:
                if (this.props.payment.data.intentStatus.status === AssetProfilPage.errorPaymentRequestStatus) {
                    return this.handlePaymentErrorButWalletCredited(this.props.payment.data.intentStatus.error);
                }
                this.displayConfirmModalForSecondaryMarketByuingSuccess();
                break;
            case AssetProfilPage.errorPaymentRequestStatus:
            default:
                return this.handlePaymentTransactionError(isCredited);
        }
    }

    checkLoaderForPrimaryMarket = (transactionStatus: string, _isCredited?: boolean) => {
        const isCredited = _isCredited ?? this.props.payment.data.isCredited;
        if (this.state.succesConfirm || !transactionStatus)
            return;
        switch (transactionStatus) {
            case AssetProfilPage.pendingPaymentRequestStatus:
                this.setState({
                    showLoader: true,
                    succesConfirm: false,
                    pendingTransaction: true,
                    showNewPriceError: false,
                });
                break;
            case AssetProfilPage.errorPaymentRequestStatus:
                return this.handlePaymentTransactionError(isCredited);
            case AssetProfilPage.successPaymentRequestStatus:
                this.handlePaymentTransactionSuccess(() => {
                    const { orderInfo, assetInfo } = this.props.payment.data;
                    const { paid, amount } = orderInfo;
                    const { name, slug } = assetInfo;

                    TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.PURCHASE, {
                        name: slug,
                        conversionValue: paid,
                        currencyCode: Application.getInstance().currencySymbol,
                    });
                    this.trackBuyToMixpanel({
                        userId: this.props.auth.Id,
                        userEmail: this.props.auth.Email,
                        assetName: name,
                        price: paid,
                        amountOfShares: amount,
                        market: MarketEnum.PRIMARY,
                    });
                    const currentAsset: AssetInterface | undefined = this.props.assets.data?.find((asset: AssetInterface) => asset && asset.slug === slug);
                    if (currentAsset !== undefined) {
                        this.props.addAssetSharesToAssetWallet(currentAsset._id.toString(), amount);
                    }

                    this.displayShareModal({ amount, paid, name, slug });
                });
                break;
            case AssetProfilPage.priceChangedRequestStatus:
                this.setState({
                    showLoader: false,
                    loaderMessage: i18next.t('trendexloading'),
                    transactionId: '',
                    pendingTransaction: false,
                    succesConfirm: true,
                    showNewPriceError: true,
                    userReturnedToSiteFromPaymentLoader: false,
                });
                this.props.managementModal(false);
                this.props.getAssetBySlug(this.state.slugParam);
                this.props.managementModal(true, {
                    display: true,
                    disableBackDrop: false,
                    showBackArrow: false,
                    fullScreen: false,
                    type: 'CONFIRM',
                    propsCustom: {
                        showCancelButton: false,
                        showLinkButton: false,
                        onRefused: () => this.props.managementModal(false),
                        onValidation: () => {
                            this.props.managementModal(false);
                        },
                        textValidButton: i18next.t('Becomevippage.submitSuccess.confirm'),
                        message: i18next.t('Assetprofilepage.priceHasChanged', { amount: this.props.payment?.data?.price ?? this.props.payment?.data?.orderInfo?.paid, currency: Application.getInstance().currencySymbol }),
                    },
                });
                break;
            default: return;
        }
    }
    /**
     * Function call by payment.tsx
     * Check the transaction status for payment on primary market only
     * @param transactionId transaction id initiated by payment
     * @param transactionStatus nullable, transaction status (can be done at first call)
     * @param _isCredited indicates that the credit has been carried out
     */
    getStatusAsset = (transactionId: string, transactionStatus?: string, _isCredited?: boolean) => {
        this.props.getStatusAssetPayment(transactionId, this.props.auth.Id);
        const pendingTransaction = true;
        this.setState({
            transactionId,
            pendingTransaction,
            loaderMessage: `${i18next.t('initPayment.paymentSuccess')}`,
            showLoader: !this.state.userReturnedToSiteFromPaymentLoader,
            succesConfirm: false,
        });

        const currentTransactionStatus: string = transactionStatus ?? this.props.payment.data.assetStatus;
        if (currentTransactionStatus && AssetProfilPage.assetTransactionStatus.includes(currentTransactionStatus))
            return this.postPaymentOnPrimaryMarket(transactionId, pendingTransaction, currentTransactionStatus, _isCredited ?? Boolean(this.props.payment.data.isCredited));
        return this.postPaymentOnPrimaryMarket(transactionId, pendingTransaction);
    }

    /**
     * Function called by BuySharesForm > Basket
     * Check the transactions status for payment on secondary market ONLY
     * @param transactionId transaction id initiated by payment
     * @param transactionStatus nullable, transaction status (can be done at first call)
     * @param _isCredited indates that the credit has been carried out
     */
    getTransactionStatus = (transactionId: string, transactionStatus?: string, _isCredited?: boolean) => {
        const pendingTransaction: boolean = true;
        this.setState(
            {
                transactionId,
                pendingTransaction,
                loaderMessage: i18next.t('secondaryMarket.buyingShares'),
                showLoader: !this.state.userReturnedToSiteFromPaymentLoader,
                succesConfirm: false,
            }, () => {
                this.props.getTransactionPayment(transactionId, this.props.auth.Id);
            },
        );
        const currentTransactionStatus: string = transactionStatus ?? this.props.payment.data.transactionStatus;
        if (currentTransactionStatus && AssetProfilPage.assetTransactionStatus.includes(currentTransactionStatus))
            return this.postPaymentOnSecondaryMarket(transactionId, pendingTransaction, currentTransactionStatus, _isCredited ?? Boolean(this.props.payment.data.isCredited));
        return this.postPaymentOnSecondaryMarket(transactionId, pendingTransaction);
    }

    getTransactionStatusWrapper = () => {
        this.getTransactionStatus(this.state.transactionId, this.props.payment.data.transactionStatus);
    }

    postPaymentGetUrlParams = (url: URLSearchParams): IPaymentUrlParams => {
        return {
            assetId: url.get('assetId'),
            tokens: url.get('tokens'),
            paymentId: url.get('paymentId'),
            token: url.get('token'),
            payerId: url.get('PayerID'),
            urlParamsTransactionId: url.get('transactionId'),
        };
    }

    postPaymentCheckParams = (urlParamsTransactionId: string | null, _transactionId?: string, _pendingTransaction?: boolean, _transactionStatus?: string, _isCredited?: boolean): IPostPaymentParams => {
        return {
            pendingTransaction: _pendingTransaction ?? this.state.pendingTransaction ?? Boolean(urlParamsTransactionId),
            isCredited: _isCredited ?? this.props.payment.data.isCredited ?? false,
            transactionId: _transactionId ?? this.state.transactionId ?? urlParamsTransactionId,
        };
    }

    postPaymentOnSecondaryMarket = (_transactionId?: string, _pendingTransaction?: boolean, _transactionStatus?: string, _isCredited?: boolean): void => {
        const url = new URLSearchParams(window.location.search);
        const { urlParamsTransactionId } = this.postPaymentGetUrlParams(url);
        const postPaymentParams = this.postPaymentCheckParams(urlParamsTransactionId, _transactionId, _pendingTransaction, _transactionStatus, _isCredited);
        const { pendingTransaction, transactionId } = postPaymentParams;
        if (transactionId && pendingTransaction)
            this.postStripePaymentForSecondaryMarket(postPaymentParams, url, _transactionStatus);
    }

    postStripePaymentForPrimaryMarket = (params: IPostPaymentParams, url: URLSearchParams, transactionStatus?: string) => {
        const isDirectStatus: boolean = typeof transactionStatus !== 'undefined' && AssetProfilPage.paymentRequestStatusWithourPending.includes(transactionStatus);
        const isPropsStatus: boolean = AssetProfilPage.paymentRequestStatusWithourPending.includes(this.props.payment.data.assetStatus);
        const { pendingTransaction, transactionId, isCredited } = params;
        this.checkLoader({
            isDirectStatus,
            isPropsStatus,
            pendingTransaction,
            isCredited,
            transactionId,
            transactionStatus: transactionStatus ?? this.props.payment.data.assetStatus,
            urlSearchParams: url,
            checkLoaderCallback: this.checkLoaderForPrimaryMarket,
            getTransactionStatusCallback: this.anonymousFunctionOfGetStatusAsset,
        });
    }

    postStripePaymentForSecondaryMarket = (params: IPostPaymentParams, url: URLSearchParams, transactionStatus?: string) => {
        const isDirectStatus: boolean = typeof transactionStatus !== 'undefined' && AssetProfilPage.paymentRequestStatusWithourPending.includes(transactionStatus);
        const isPropsStatus: boolean = AssetProfilPage.paymentRequestStatusWithourPending.includes(this.props.payment.data.transactionStatus);
        const { pendingTransaction, transactionId, isCredited } = params;
        this.checkLoader({
            isDirectStatus,
            isPropsStatus,
            pendingTransaction,
            isCredited,
            transactionId,
            transactionStatus: transactionStatus ?? this.props.payment.data.assetStatus,
            urlSearchParams: url,
            checkLoaderCallback: this.checkLoaderForSecondaryMarket,
            getTransactionStatusCallback: this.getTransactionStatusWrapper,
        });
    }

    postPaymentOnPrimaryMarket = (_transactionId?: string, _pendingTransaction?: boolean, _transactionStatus?: string, _isCredited?: boolean): void => {
        const url = new URLSearchParams(window.location.search);
        const { assetId, tokens, paymentId, token, payerId, urlParamsTransactionId } = this.postPaymentGetUrlParams(url);
        const postPaymentParams = this.postPaymentCheckParams(urlParamsTransactionId, _transactionId, _pendingTransaction, _transactionStatus, _isCredited);
        let { pendingTransaction, transactionId } = postPaymentParams;
        transactionId && pendingTransaction ? this.postStripePaymentForPrimaryMarket(postPaymentParams, url, _transactionStatus) : transactionId = this.state.transactionId;
        const oldTokenPrice = url.get('oldTokenPrice');
        let parsedAmount = 0;
        if (tokens)
            parsedAmount = parseFloat(tokens);
        if (!(assetId && tokens && token && paymentId && payerId && parsedAmount > 0 && transactionId && oldTokenPrice))
            return;
        this.setState({
            showLoader: true,
        });
        this.props.paypalPaymentSuccess(parsedAmount, assetId, paymentId, payerId, token, transactionId, oldTokenPrice);
    }

    triggerCardPayment = async (inputs: TriggerCardPaymentInputsInterface): Promise<any> => {
        this.showLoader();
        const errorModal: ModalReduxStore = {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                showCancelButton: false,
                showLinkButton: false,
                onValidation: () => {
                    this.props.managementModal(false);
                },
                textValidButton: i18next.t('cardpayment.error-modal.validation'),
                message: i18next.t('cardpayment.error-modal.error'),
            },
        };
        try {
            let getTransactionStatusCallback: Function = this.getStatusAsset;
            const { asset, amountOfShares, market, totalPriceForSecondaryMarketPayment, priceOfOrderForSecondaryMarketPayment } = inputs;
            switch (market) {
                case MarketEnum.SECONDARY:
                    if (!totalPriceForSecondaryMarketPayment || !priceOfOrderForSecondaryMarketPayment)
                        throw Error('Unable to proceed payment on secondary market with params passed');
                    await this.props.secondaryMarketStripePayment({
                        amountOfShares,
                        totalAmount: totalPriceForSecondaryMarketPayment,
                        assetId: asset._id,
                        priceOfOrder: priceOfOrderForSecondaryMarketPayment,
                        isDirectBuy: false,
                        isBuyingFromOrderbook: false,
                        sellingOrderId: undefined,
                        directBuyFromTheBestSellingOrders: true,
                    });
                    getTransactionStatusCallback = this.getTransactionStatus;
                    break;
                case MarketEnum.PRIMARY:
                    await this.props.CardPayment(asset._id, amountOfShares, true);
                    break;
                default:
                    throw Error('No market passed to triggerCardPayment. Cannot proceed to a payment. This implies a bug');
            }

            const paymentIntent = this.props.payment.data.paymentIntent;

            if ((!paymentIntent.data) || (!this.props.payment.loading && paymentIntent.data && paymentIntent.status !== HTTP_STATUS_OK)) {
                this.hideLoader();
                return this.props.managementModal(true, {
                    display: true,
                    disableBackDrop: false,
                    showBackArrow: false,
                    fullScreen: false,
                    type: 'CONFIRM',
                    propsCustom: {
                        showCancelButton: false,
                        showLinkButton: false,
                        onValidation: () => {
                            this.props.managementModal(false);
                        },
                        textValidButton: i18next.t('cardpayment.error-modal.validation'),
                        message: i18next.t('cardpayment.error-modal.error'),
                    },
                });
            }
            const transactionId = paymentIntent.data.transactionId;
            const transactions = market === MarketEnum.PRIMARY ? paymentIntent.data.user.assetTransactions : paymentIntent.data.user.transactions;
            const transaction: TransactionInterface | undefined = transactions?.find((transac: TransactionInterface) => transac.transactionId && transac.transactionId === transactionId);

            const result = await this.props.stripe.confirmCardPayment(paymentIntent.data.secretIntent, {
                payment_method: paymentIntent.data.paymentMethod.data[0].id,
            });

            if (result.error) {
                this.hideLoader();
                return this.props.managementModal(true, errorModal);
            }

            if (result.paymentIntent.status === AssetProfilPage.paymentIntentSuccessStatus) {
                this.props.managementModal(false);
                if (!transaction)
                    return getTransactionStatusCallback(transactionId);
                if (!!transaction.isCredited)
                    return getTransactionStatusCallback(transactionId, transaction.status, transaction.isCredited);
                return getTransactionStatusCallback(transactionId, transaction.status);
            }
            throw Error('Unable to pay with stripe');
        } catch (error) {
            this.hideLoader();
            return this.props.managementModal(true, errorModal);
        }
    }
    //#endregion

    //#region calls

    afterBuyingSharesOnSecondaryMarket = (buyingSharesResponse: any, amountOfShares: number, assetName: string, price: number) => {
        if (buyingSharesResponse.type !== ORDER_CREATED_SUCCESSFULLY_STATUS)
            return;
        const { assets } = this.props;
        const asset: AssetInterface | undefined = assets.data.find((asst: AssetInterface) => asst && asst.slug === this.state.slugParam);
        let message = i18next.t('resale.buyingOrderCreatedSuccessfullyFromAssetProfile', { assetName, amount: amountOfShares });
        if (asset) {
            const delayBeforeShowingSecondaryMarketIsReach: boolean = moment.duration(moment().diff(moment(asset.initialSaleRealEndDate))).asHours() >= asset.hoursBeforeSecondaryMarketStarts;
            if (!delayBeforeShowingSecondaryMarketIsReach)
                message += i18next.t('resale.buyingOrderInBufferTime');
        }
        this.trackBuyToMixpanel({
            userId: this.props.auth.Id,
            userEmail: this.props.auth.Email,
            assetName,
            price,
            amountOfShares,
            market: MarketEnum.SECONDARY,
        });

        this.props.managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                showCancelButton: false,
                showLinkButton: false,
                onRefused: () => this.props.managementModal(false),
                textValidButton: i18next.t('Becomevippage.submitSuccess.confirm'),
                onValidation: () => this.props.managementModal(false),
                message,
            },
        });
        if (!this.props.auth.PaymentCGUAccepted)
            this.props.acceptCGUPayment();

        this.props.getUserCredits();
        localStorage.removeItem(LOCAL_STORAGE_KNOWN_KEYS.TOKENS);
    }

    clearQueryOnComponentLoad = () => {
        const cleanQuery = window.location.href.toString().split('?')[0];
        window.history.replaceState({ path: cleanQuery }, '', cleanQuery);
    }

    fetchActualAssets = (assets: AssetInterface[], slugParam: string) => {
        return this.findActualAsset(assets, 'slug', slugParam) === undefined;
    }

    findActualAsset = (assets: AssetInterface[], keySearch: string, valueSearch: string) => {
        return assets.find((asset: AssetInterface) => asset && keySearch && valueSearch && asset[keySearch] === valueSearch);
    }

    private trackBuyToMixpanel(userData: any) {
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.ORDER_CONFIRMATION, {
            name: userData.assetName,
            conversionValue: userData.price,
            currencyCode: Application.getInstance().currencySymbol
        });

        if (userData.market === MarketEnum.PRIMARY) {
            this.buyOnPrimaryMarketPixelHandler.purchaseData = {
                currency: Application.getInstance().currency,
                userId: userData.userId,
                userEmail: userData.userEmail,
                assetName: userData.assetName,
                value: userData.price,
                quantity: userData.amountOfShares,
            };
            return this.buyOnPrimaryMarketPixelHandler.handle();
        }
        this.buyOnSecondaryMarketPixelHandler.purchaseData = {
            currency: Application.getInstance().currency,
            userId: userData.userId,
            userEmail: userData.userEmail,
            assetName: userData.assetName,
            value: userData.price,
            quantity: userData.amountOfShares,
        };
        return this.buyOnSecondaryMarketPixelHandler.handle();

    }

    private handlePaymentErrorButWalletCredited(message: string | null = null) {
        this.setState({
            showLoader: false,
        });
        this.props.getUserCredits();
        return this.props.managementNotification(true, i18next.t(!message ? 'Assetprofilepage.purchase-not-completed' : message), TRENDEX_HTTP_STATUS_NOK);
    }

    //#endregion

    //#region onChangeOfStepperByUser
    onChangeOfStepperByUserCallback = (numberOfTokens: number) => {
        this.setState({
            numberOfTokensInStepper: numberOfTokens,
        });
    }
    //#endregion

    //#region utils
    inRange(subject: number, a: number, b: number) {
        return subject > a && subject < b;
    }
    //#endregion

    //#region rendering

    displayConfirmModalForSecondaryMarketByuingSuccess = () => {
        const { assets } = this.props;
        const asset: AssetInterface | undefined = assets.data.find((asset: AssetInterface) => asset && asset.slug === this.props.match.params.slug);
        const orderId = this.props.payment.data.intentStatus.orderId;
        const order = this.props.orders.data.find((ordr: IOrder) => ordr._id === orderId);
        this.trackBuyToMixpanel({
            userId: this.props.auth.Id,
            userEmail: this.props.auth.Email,
            assetName: asset?.name,
            price: Number(order?.price) * Number(order?.amount),
            amountOfShares: order?.amount,
            market: MarketEnum.SECONDARY,
        });
        localStorage.removeItem(LOCAL_STORAGE_KNOWN_KEYS.TOKENS);
        if (order?.status !== ORDER_STATUS.PICKED)
            this.handlePaymentTransactionSuccess(() => {
                return this.props.managementModal(true, {
                    display: true,
                    disableBackDrop: false,
                    showBackArrow: false,
                    fullScreen: false,
                    type: 'CONFIRM',
                    propsCustom: {
                        showCancelButton: false,
                        showLinkButton: false,
                        onRefused: () => this.props.managementModal(false),
                        textValidButton: i18next.t('Becomevippage.submitSuccess.confirm'),
                        onValidation: () => this.props.managementModal(false),
                        message: i18next.t('resale.buyingOrderCreatedSuccessfullyFromAssetProfile', { amount: this.props.orders.data.amount, assetName: asset?.name ?? '' }),
                    },
                });

            });
    }

    displayShareModal({ amount, paid, name, slug }: IShareAssetBuyModal) {
        this.props.managementModal(true, {
            display: true,
            disableBackDrop: true,
            showBackArrow: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                showCancelButton: true,
                showLinkButton: false,
                onRefused: () => this.props.managementModal(false),
                onValidation: () => {
                    const shareMessage: string = i18next.t('Share.assetBuy', { name, amount });
                    this.props.actionShare(
                        shareMessage,
                        shareMessage,
                        Utils.referralLinkToAsset(slug, this.props.auth.Referral.ownReferralId),
                        shareMessage,
                    );
                },
                textValidButton: i18next.t('successBuyAssetSharePage.share'),
                textCancelButton: i18next.t('button-cancel-delete-asset'),
                message: <>
                    <SuccessBuyAssetSharePage asset={new AssetViewModel(this.props.asset)} tokenQuantity={amount} totalPrice={paid} orderExecuted={true} userYield={this.props.auth.shouldShowYield()} isLiquidityEnabled={false} />
                    <ReferenceYourFriends greenButton={true} />
                </>,
            },
        });
    }
    //#endregion

    renderNotificationUIButton = (isSubscribed: boolean, handleClick: () => void): JSX.Element => {
        if (this.props.postSubscriptionStatus === Status.pending)
            return (
                <div onClick={handleClick} className="flex " style={{ minHeight: '49px' }}>
                    <CircularIndeterminate
                        loaderLocal
                        loaderMessage=""
                        localLoaderStyle={{ marginBottom: '8px', height: '22px', width: '22px' }}
                        localTextStyle={{ fontSize: '11px' }} />
                </div>
            );

        if (isSubscribed)
            return (
                <div onClick={handleClick} className="flex hover container-msg-already-subscribe">
                    <span className="subscribe-bell"><FontAwesomeIcon icon={faBell} /></span>
                    <p>{i18n.t('title.post.join.whitelist')}</p>
                </div>
            );

        return <div onClick={handleClick} className="flex hover container-msg-subscribe">
            <span className="unsubscribe-bell"><FontAwesomeIcon icon={faBell} /></span>
            <p>{i18n.t('join.asset.vip')}</p>
        </div>;
    }

    onPrivateSellTimerEnd = () => {
        if (this.props.privateSellIsOpen)
            return;
        const asset: AssetInterface | undefined = this.props.assets.data.find((asset: AssetInterface) => asset && asset.slug === this.state.slugParam);
        if (!asset)
            return;
        this.props.setPrivateSellIsOpen(true);
    }

    render = () => {
        const canDisplaySocialBadge: boolean = false;
        const { asset: actualAsset } = this.props;
        const asset: AssetInterfaceWithTranslatedDescriptions | AssetInterface = actualAsset;
        const assetViewModel = new AssetViewModel(asset);
        const isMarketOpen = assetViewModel.InitialSaleDone;
        const showLoaderButton = this.paymentTransactionTakeTooMuchTime();
        const showWhiteListAndPackButton: boolean = assetViewModel.PackEnabled || assetViewModel.isWhiteListed();
        const renderBanner = () => (
            <div className="private-sell__video-container">
                <BannerVideoSlide
                    key={assetViewModel.AssetId}
                    id={assetViewModel.AssetId}
                    isNotOnCarouselView={false}
                    setCarouselAutoPlay={() => { }}
                    videoId={assetViewModel.IsAssetBenzema ? (assetViewModel.enableCustomAssetProfil() ? BENZEMA_BANNER_VIDEO[Utils.getCurrentLanguage() as 'fr' | 'en' | 'es'] : "YDU3Tftxa00") : getPrivateSellExplainer()}
                    bannerImage={benzemaThumnail}
                    showText={false}
                    showCenterPlayBtn
                    showWatchNowPlayBtn={false}
                    showPlayIconInWatchNow={false}
                    isVideo={true} />
            </div>
        );

        return (
            <div className="full-container">
                {canDisplaySocialBadge &&
                    <div className="info-wrapper">
                        <SocialBadgesBanner socialNetworks={getSocialNetworks(asset)} isTopAsset={false} />
                    </div>
                }

                {asset && <AssetPriceEvolution asset={assetViewModel} />}
                {!this.props.isSlidingUp && <>
                    <AssetMarketPlace
                        asset={asset}
                        isBuyable={assetViewModel.IsIcoOpen || isMarketOpen}
                        preSaleIsOpen={this.props.preSaleIsOpen}
                        privateSellIsOpen={this.props.privateSellIsOpen}
                        getYtBanner={renderBanner}
                        managementModal={this.props.managementModal}
                        onPrivateSellTimerEnd={() => this.onPrivateSellTimerEnd()}
                        renderNotificationUIButton={this.renderNotificationUIButton}
                        setPreSaleIsOpen={this.props.setPreSaleIsOpen}
                    />

                    {assetViewModel.isEarlyBirdEnable() && <BuyPacksButtonDisclaimer assetViewModel={assetViewModel} />}

                    {assetViewModel.enableCustomAssetProfil() && <div>
                        {renderBanner()}
                    </div>}
                    <YieldInformation
                        assetVM={assetViewModel}
                        canShowAsBanner={true}
                    />
                    {!assetViewModel.enableCustomAssetProfil() &&
                        <>
                            <AssetDescription assetLoading={this.props.assets.loading} assetWithTranslatedDescriptions={asset as AssetInterfaceWithTranslatedDescriptions} />
                            <AssetEvolutions asset={assetViewModel} />
                            {((assetViewModel.InitialSupply && !isMarketOpen) || assetViewModel.InitialSaleDone) && <AssetDetail asset={assetViewModel} />
                            }
                        </>
                    }

                    {assetViewModel.IsAssetBenzema && <BenzemaClubSection withLightAssetProfil={assetViewModel.enableCustomAssetProfil()} assetViewModel={assetViewModel} />}
                    {showWhiteListAndPackButton && <div className="container-actions-buttons">
                        <AssetProfilActionButtons assetVM={assetViewModel} />
                    </div>}
                </>}
                {assetViewModel.IsFunCategory &&
                    <DescriptionFunCardsAssetProfilPage />
                }
                {this.state.showLoader && (
                    <CircularLoader
                        loaderMessage={this.state.loaderMessage}
                        loaderLocal={false}
                        showButton={showLoaderButton}
                        buttonText={i18next.t('Message.ReturnToTheSite')}
                        buttonAction={() => this.hideFromLoaderButton()}
                        doNotSplitMessage={true} />)
                }
            </div>
        );
    }
}


const SlideUpBannerComponent = (props: WithAssetProfileProps) => {
    const [isSliderOpen, setIsSliderOpen] = useState<boolean | undefined>(props.slug ? false : true);
    const { asset } = props;
    const assetVM: AssetViewModel = React.useMemo(() => new AssetViewModel(asset), [asset]);
    const [privateSellIsOpen, setPrivateSellIsOpen] = React.useState<boolean>(assetVM.IsIcoOpen);
    const [preSaleIsOpen, setPreSaleIsOpen] = React.useState<boolean>(assetVM.IsWhiteListedPurchaseOpen);
    const showPackButton: boolean = React.useMemo(() => {
        return assetVM.PackEnabled || (assetVM.IsWhiteListedPurchaseOpen && !assetVM.isEarlyBirdEnable());
    }, [assetVM]);


    React.useEffect(() => {
        return () => localStorage.removeItem(LOCAL_STORAGE_KNOWN_KEYS.FROM_LANDING)
    }, []);

    const renderBanner = (): JSX.Element | undefined => {
        if (!asset) return;
        const profilBannerProps = {
            youtubeVideo: asset.youtubeVideo,
            logoUrl: asset.icon,
            assetName: asset.name,
            assetVM: assetVM,
            instagram: asset.instagram,
            snapchat: asset.snapchat,
            facebook: asset.facebook,
            twitter: asset.twitter,
            tiktok: asset.tiktok,
            actionShare: props.actionShare,
        };

        return (
            <>
                {assetVM.IsFunCategory && <WrapperFirstPurchaseBannerFunCards />}
                {!assetVM.enableCustomAssetProfil() && <ProfilBanner {...profilBannerProps} />}
                {assetVM.enableCustomAssetProfil() ? showPackButton ? <BuyPacksButtonDisclaimer assetViewModel={assetVM} /> : <></> : <ProfileMidSection asset={asset} />}
                {Application.getInstance().IsFootballEuroMode() && assetVM.IsFootballEuro && <Euro2024AssetDisclaimer />}
            </>
        );
    };

    useEffect(() => {
        if (props.slug)
            setIsSliderOpen(props.isSlideViewOpen);
    }, [props.isSlideViewOpen, props.slug]);

    return (
        <TransitionWrapper
            nodeRef={props.innerRef}
            in={isSliderOpen}
            timeout={{
                enter: ASSET_SLIDE_UP_DURATION,
                exit: ASSET_SLIDE_DOWN_DURATION,
            }}
            classNames="slide"
            onEntered={props.onSlideViewOpen}
            onExited={props.onTransitionComplete}
            slug={props.slug}  >
            <div id="assetProfilePage" className='asset-profil-page' style={Application.getInstance().IsInjectedAsIframe ? { overflowY: 'hidden' } : {}}>
                <div className={"dropdown-button"} >
                    <ExpandMore />
                </div>
                {renderBanner()}
                {!props.isSlidingUp && <AssetProfilPage
                    {...{ ...props, privateSellIsOpen, setPrivateSellIsOpen, preSaleIsOpen, setPreSaleIsOpen }}
                />}
                {props.isSlidingUp && <>
                    <div className="full-container">
                        <AssetDetail asset={new AssetViewModel(asset)} />
                    </div>
                    <CircularIndeterminate
                        loaderMessage=""
                        loaderLocal={true} />
                </>}
            </div>
        </TransitionWrapper>
    );
};

const InjectedCheckoutForm = (props: WithAssetProfileProps) => {
    return (
        <ElementsConsumer>
            {({ stripe }) => (
                <SlideUpBannerComponent
                    {...props}
                    stripe={stripe}
                />
            )}
        </ElementsConsumer>
    );
};

const App = (props: AssetProfilPageProps) => {
    const slugParam: string = props.slug || props.match.params.slug;
    const asset: AssetInterface | undefined = props.assets.data.find((asset: AssetInterface) => asset && asset.slug === slugParam);
    const { shouldHide: shouldHideInfluenceAssets, category: hiddenCategory } = useShouldHideInstagramAssets();

    useEffect(() => {
        if (!asset)
            props.getAssetBySlug(slugParam);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset, slugParam]);

    return (
        <Elements stripe={stripePromise}>
            {asset &&
                <InjectedCheckoutForm
                    {...props}
                    asset={asset}
                    shouldHideInfluenceAssets={shouldHideInfluenceAssets}
                    hiddenCategory={hiddenCategory}
                />
            }
        </Elements>
    );
};

const mapStateToProps = (state: RootState) => ({
    auth: selectorAccountViewModel(state),
    assets: state.assets,
    payment: state.payment,
    orders: state.orders,
    postSubscriptionStatus: postSubscriptionStatusSelector(state),
});

export default compose(
    withYield,
    connect(mapStateToProps, {
        managementModal,
        managementNotification,
        getAssetBySlug,
        paypalPaymentSuccess,
        getStatusAssetPayment,
        actionShare,
        getTransactionPayment,
        showNotification,
        CardPayment,
        secondaryMarketStripePayment,
        getUserCredits,
        acceptCGUPayment,
        addAssetSharesToAssetWallet,
        getTranslatedDescription
    }),
)(App);
