import * as Sentry from "@sentry/react";
import Application from 'Application';
import UserInterface from 'model/User/UserInterface';
import moment from 'moment';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getGeoIP } from 'service/geoip/action';
import { leagueAttributionSelector } from 'service/league/selectors';
import { SegmentTracking } from 'service/segment/SegmentTracking';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import Utils from 'utils/Utils';
import ReferralViewModel from 'view-model/Referrals/ReferralViewModel';
import RegisterGiftViewModel from 'view-model/RegisterGiftViewModel/RegisterGiftViewModel';
import SignupSourcesViewModel from 'view-model/SignupSources/SignupSourcesViewModel';
import RewardModalViewModel from 'view-model/Tournament/RewardModalViewModel/RewardModalViewModel';
import { ASSET_PATH, CHECK_LIMIT_IN_SECONDS, DEFAULT_CURRENCY, DEFAULT_ID_AS_STRING, G_TAGS_KNOWN_EVENTS, LOCAL_STORAGE_KNOWN_KEYS, MODAL_COLOR } from '../../config/_const';
import PixelHandlerRegisterUser from '../facebook-pixel/PixelHandlerRegisterUser';
import { managementModal } from '../modal/action';
import PixelHandlerSignup from '../snapchat-pixel/PixelHandlerSignup';
import { actionRegisterSignupSource, claimRewardAtRegister, createUserPersonalTournament, getClaimableRewardAndResultForPersonalTournament, getClaimableRewardAndResultFromPreviousTournamentByUser, handleReward, launchOnboarding } from './actions';
import { AUTH_SUCCESS, HANDLE_CLAIMABLE_REWARD_SUCCESS, REWARD_REGISTER, REWARD_SUCCESS } from './actionsType';
import LeagueAttribution from 'model/League/LeagueAttribution';
import IClaimableUserReward from 'model/Tournament/Rewards/IClaimableUserReward';
import { TournamentRewardKind } from 'config/_enums';

export const watchUserSignupSource = (storeAPI: MiddlewareAPI<ThunkDispatch<void, null, any>>) => (next: Dispatch) => (action: AnyAction) => {

    if (action.type !== AUTH_SUCCESS)
        return next(action);

    const checkLimitInSeconds = CHECK_LIMIT_IN_SECONDS;
    const actionUser: UserInterface = action.payload.user;
    // Track new signup
    if (moment().diff(moment(actionUser.createdAt), 'seconds') < checkLimitInSeconds) {
        // Signupsource
        const signupSourceViewModel = new SignupSourcesViewModel();
        if (signupSourceViewModel.SignupSourceChannel && signupSourceViewModel.SignupSourceId)
            storeAPI.dispatch(actionRegisterSignupSource(signupSourceViewModel.SignupSourceChannel, signupSourceViewModel.SignupSourceId));
    }

    return next(action);
};

export const watcherUserRegister = (storeAPI: MiddlewareAPI<ThunkDispatch<void, null, any>>) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type !== AUTH_SUCCESS)
        return next(action);
    const checkLimitInSeconds: number = CHECK_LIMIT_IN_SECONDS * 5;
    const actionUser: UserInterface = action.payload.user;
    if (moment().diff(moment(actionUser.createdAt), 'seconds') > checkLimitInSeconds || localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.ALREADY_GET_LOGIN) === action.payload.user._id)
        return next(action);
    if (Application.getInstance().UserPersonalTournamentConfig.enabled)
        handleUserPersonalTournamentAtRegister(actionUser, storeAPI);
    const signupSourceViewModel = new SignupSourcesViewModel();
    const referralViewModel = new ReferralViewModel();
    localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.ALREADY_GET_LOGIN, action.payload.user._id);
    if (Utils.isProdEnvironment()) {
        SegmentTracking.sendSignupEvent({ actionUser, referralViewModel, signupSourceViewModel });
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.SIGNUP, {
            method: Utils.getConnectionMode(actionUser) ?? '',
            referral: referralViewModel.ReferralId ?? ''
        });
        const pixelHandlerRegisterUser: PixelHandlerRegisterUser = new PixelHandlerRegisterUser();
        pixelHandlerRegisterUser.registerData = {
            userEmail: actionUser.email,
            status: true,
        };
        pixelHandlerRegisterUser.handle();
        const snapchatSignupHandler: PixelHandlerSignup = new PixelHandlerSignup();
        snapchatSignupHandler.data = {
            user_email: actionUser.email,
        };
        snapchatSignupHandler.handle();
    }
    if (!action?.payload?.giftRegister?.enabled)
        return next(action);

    storeAPI.dispatch(claimRewardAtRegister(referralViewModel.ReferralId));
    localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.GIFT_REGISTER_CLAIMED, 'true');
    return next(action);
};

export const watchUserGiftRegisterClaims = (storeAPI: MiddlewareAPI<ThunkDispatch<void, null, any>>) => (next: Dispatch) => async (action: AnyAction) => {

    if (action.type !== REWARD_REGISTER)
        return next(action);

    const isAssetProfileOpenedWhileRegistered = window.location.pathname.toString().includes(ASSET_PATH);
    if (isAssetProfileOpenedWhileRegistered) return next(action);

    const modalGiftRegisterAlreadyShown: boolean = Boolean(localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_GODSON_POPEDUP));
    if (modalGiftRegisterAlreadyShown)
        return next(action);


    const defaultCountry = await getGeoIP();
    let initialCurrency = DEFAULT_CURRENCY;
    const continent = Utils.getContinentByTimezone(defaultCountry.timezone);
    const isLocaleEuropean = Utils.isLocaleEuropean(navigator.language);
    switch (continent) {
        case 'Europe':
            initialCurrency = isLocaleEuropean ? DEFAULT_CURRENCY : 'USD';
            break;
        default:
            initialCurrency = 'USD';
    }
    storeAPI.dispatch(managementModal(true, {
        display: true,
        disableBackDrop: true,
        showBackArrow: false,
        fullScreen: true,
        type: 'POST_SIGNUP',
        showCancelButton: false,
        showModalBgBlur: true,
        bgColor: MODAL_COLOR.TRANSPARENT,
        closeByEscape: false,
        propsCustom: {
            onValidation: () => {
                if (Application.getInstance().OnBoardingV2)
                    storeAPI.dispatch(launchOnboarding());
                storeAPI.dispatch(managementModal(false));
            },
            initialValues: {
                currency: initialCurrency,
                country: defaultCountry.country,
            },
            registerGiftViewModel: new RegisterGiftViewModel({ ...action.payload }),
        },
    }));

    return next(action);
};

export const checkForTournamentRewardModal = (storeAPI: MiddlewareAPI<ThunkDispatch<void, null, any>>) => (next: Dispatch) => (action: AnyAction) => {
    const authorizedActionType: string[] = [AUTH_SUCCESS, REWARD_SUCCESS, HANDLE_CLAIMABLE_REWARD_SUCCESS];
    if (!authorizedActionType.includes(action.type))
        return next(action);

    const currentUser: UserInterface = action.payload.user;
    const dispatch = storeAPI.dispatch;
    if (action.type === AUTH_SUCCESS && currentUser._id !== DEFAULT_ID_AS_STRING) {
        if (Application.getInstance().UserPersonalTournamentConfig.enabled)
            storeAPI.dispatch(getClaimableRewardAndResultForPersonalTournament());
        dispatch(getClaimableRewardAndResultFromPreviousTournamentByUser());
        return next(action);
    }

    if (action.type === REWARD_SUCCESS) {
        const rewardPayload: IClaimableUserReward = action.payload.data;
        if (Object.keys(rewardPayload).length === 0 || rewardPayload.handled === true)
            return next(action);
        const leagueAttribution: LeagueAttribution = leagueAttributionSelector(storeAPI.getState());
        const handleValidateReward = () => {
            TagManagerWrapper.handleTournamentReward({
                rewardId: rewardPayload.rewardId,
                conversionValue: rewardPayload.virtualCreditsAmount ?? 0,
                currency: Application.getInstance().currency
            });
            SegmentTracking.sendClaimTournamentRewardEvent({ data: rewardPayload, currency: Application.getInstance().currency });
            dispatch(handleReward(rewardPayload.rewardId));
            dispatch(managementModal(false));
        };

        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: true,
            showBackArrow: false,
            fullScreen: false,
            showCancelButton: false,
            type: rewardPayload.kind === TournamentRewardKind.CREDITS_REWARD ? 'REWARD_MODAL' : 'REWARD_MODAL_CARDS',
            bgColor: rewardPayload.kind === TournamentRewardKind.CREDITS_REWARD ? MODAL_COLOR.LIGHT : MODAL_COLOR.TRANSPARENT,
            showModalBgBlur: true,
            propsCustom: {
                onValidation: handleValidateReward,
                rewardModalViewModel: new RewardModalViewModel(action.payload.data, leagueAttribution),
            },
        }));
    }

    return next(action);
};

const handleUserPersonalTournamentAtRegister = (user: UserInterface, storeAPI: MiddlewareAPI<ThunkDispatch<void, null, any>>) => {
    try {
        if (user.personalTournament || localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.USER_PERSONAL_TOURNAMENT_CREATION_CALLED))
            return;
        localStorage.trendexSetItem(LOCAL_STORAGE_KNOWN_KEYS.USER_PERSONAL_TOURNAMENT_CREATION_CALLED);
        return storeAPI.dispatch(createUserPersonalTournament());
    } catch (exception) {
        console.error(exception);
        Sentry.captureException(exception);
    }
};
