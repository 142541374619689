import i18n from '18n';
import { TextField } from '@material-ui/core';
import RedButton from 'components/small/buttons/red-button/RedButton';
import WrapperSelect from 'components/small/wrapper-select/WrapperSelect';
import { TRANSACTION_SOURCE, WRAPPER_SELECT_TYPE } from 'config/_const';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { managementModal } from 'service/modal/action';
import { increaseUserCreditsAsAdmin } from 'service/users/actions';
import IAdminCreditUserProps from './IAdminCreditUserProps';
import './admin-credit-user.scss';

interface ICreditInput {
    credits: number;
    virtualCredits: number;
    transactionSource: string;
}

const AdminCreditUser: React.FC<IAdminCreditUserProps> = (props: IAdminCreditUserProps) => {
    const defaultValue: ICreditInput = { credits: 0, virtualCredits: 0, transactionSource: TRANSACTION_SOURCE.ADMIN_REGULATION };
    const [creditsInput, setCreditsInput] = useState<ICreditInput>({ ...defaultValue });
    const dispatch = useDispatch();

    const onConfirmation = async () => {
        dispatch(increaseUserCreditsAsAdmin({ userId: props.user._id, ...creditsInput }))
        setCreditsInput({ ...defaultValue });
        dispatch(managementModal(false));
    }

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            showCancelButton: true,
            fullScreen: false,
            type: 'CONFIRMATION_MODAL',
            propsCustom: {
                hideCancelBtn: false,
                fullButtons: false,
                onRefused: () => dispatch(managementModal(false)),
                onValidation: onConfirmation,
                message: i18n.t('admin.add.credits', { credits: creditsInput.credits.addition(creditsInput.virtualCredits), userName: props.user.email })
            },
        }));
    }
    return <section className='admin-credit-user'>
        <h2>Add credits</h2>
        <form onSubmit={onSubmit}>
            <div className="flex">
                <div className='custinput_group col-4'>
                    <label className='form_label'>
                        Credits
                    </label>
                    <TextField
                        type='number'
                        id="add-admin-credits"
                        value={creditsInput.credits}
                        onChange={(event) => setCreditsInput({ ...creditsInput, credits: Number(event.target.value) })}
                    />
                </div>

                <div className='custinput_group col-4'>

                    <label className='form_label'>
                        Virtual Credits
                    </label>
                    <TextField
                        type='number'
                        id="admin-add-virtual-credits"
                        value={creditsInput.virtualCredits}
                        onChange={(event) => setCreditsInput({ ...creditsInput, virtualCredits: Number(event.target.value) })}
                        className='' />
                </div>

                <div className='custinput_group col-4'>

                    <label className='form_label'>
                        Source de la transaction
                    </label>

                    <WrapperSelect
                        type={WRAPPER_SELECT_TYPE.NATIVE}
                        data={Object.values(TRANSACTION_SOURCE).map((value: string) => ({ label: value, value }))}
                        valueChange={(event) => setCreditsInput({ ...creditsInput, transactionSource: event.target.value as string })}
                        value={creditsInput.transactionSource} />
                </div>
            </div>

            <RedButton
                disabled={false}
                type="submit"
                textButton={"Add Credits"}
                receiveClickEvent={false}
                className='col-3'
            />

        </form>
    </section>
}

export default AdminCreditUser;