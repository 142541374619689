import { DEFAULT_ID_AS_STRING, ZERO } from 'config/_const';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxRootState } from 'model/Redux';
import { getUserCredits } from 'service/auth/actions';
import { authSelector, userIdSelector } from 'service/auth/selectors';
import UserStoreData from 'service/auth/UserStoreData';
import type IUseCredits from './IUseCredits';

const useCredits = (): IUseCredits => {
    const dispatch = useDispatch();
    const auth: ReduxRootState<UserStoreData> = useSelector(authSelector);
    const userId: string = useSelector(userIdSelector);
    const credits: number = auth.data.account.credits ?? ZERO;
    const virtualCredits: number = auth.data.account.virtualCredits ?? ZERO;

    useEffect(() => {
        if (userId === DEFAULT_ID_AS_STRING)
            return;
        refreshUserCredits();
    }, [userId]);

    const refreshUserCredits = () => {
        if (userId === DEFAULT_ID_AS_STRING)
            return;
        dispatch(getUserCredits());
    };

    return {
        refreshUserCredits,
        credits,
        virtualCredits,
        totalCredits: credits + virtualCredits,
        creditsLoading: auth.loading,
    };
};
export default useCredits;
