import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import { ReduxRootState } from 'model/Redux';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { assetsCategoriesSelector } from 'service/asset-category/selector';
import AvatarViewModel from 'view-model/AvatarViewModel/AvatarViewModel';
import { IAssetAvatar } from 'model/AssetModel/AssetInterface';

const useAvatar = (asset: IAssetAvatar): AvatarViewModel => {
    const { data: assetCategories }: ReduxRootState<AssetCategoryInterface[]> = useSelector(assetsCategoriesSelector);
    return useMemo(() => new AvatarViewModel({ assetCategories: assetCategories, asset }), [asset, assetCategories]);
}

export default useAvatar;
