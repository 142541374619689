import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
    typography: {
      fontFamily: [
        'Plus Jakarta Sans',
        'sans-serif'
      ].join(','),
    }
  });

const ThemeProviderWrapper: React.FC = ({ children }) => {
    return(
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};

export default ThemeProviderWrapper;