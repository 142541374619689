import i18n from "18n";
import Application from "Application";
import { ADMINISTRATOR_ID, DEFAULT_ORDERBOOK_FEES, ORDER_SIDE, ORDER_TYPE, ROUND_UP_ERROR_ACCEPTANCE, SELL_SLIDER_CONFIG, TOKEN_INFORMATION_ITEMS, ZERO } from "config/_const";
import { AssetCurveType, AssetTransactionType, MarketEnum } from "config/_enums";
import SecondaryMarketPredictPriceWrapperState from "model/Resale/SecondaryMarketPredictPriceWrapperState";
import moment from "moment";
import IAssetCurrentInformationData from "service/asset-current-information/IAssetCurrentInformationData";
import LiquidityPoolFactory from "utils/liquidity-pool/LiquidityPoolFactory";
import Utils from "utils/Utils";
import AssetViewModel from "view-model/Assets/AssetViewModel";
import IAssetTransactionViewModel from "view-model/AssetTransactionViewModel/IAssetTransactionViewModel";
import ISellAssetViewModelBuilder from "./ISellAssetViewModelBuilder";

export default class SellAssetViewModel implements IAssetTransactionViewModel {
    private assetViewModel: AssetViewModel;
    private _holding: number;
    private _numberOfToken: number;
    private _userPrice: number;
    private _boughtAt: number;
    private _amountPaidInVirtualCredits: number;
    private _predictPriceData?: SecondaryMarketPredictPriceWrapperState;
    private assetCurrentInformation: IAssetCurrentInformationData;
    private isSuperAdmin: boolean;
    private currency: string;
    private userId: string;
    private _orderType: ORDER_TYPE;
    private _userInputAmount: number;
    private static minimumAcceptableSellingPrice: number = 0.10;
    AdjustedAvailableSupply?: number | undefined;
    constructor({ asset, assetWallet, numberOfToken, userAmount, predictPriceData, assetCurrentInformation, userId, currency, orderType }: ISellAssetViewModelBuilder) {
        this.assetViewModel = new AssetViewModel(asset);
        this._holding = assetWallet?.amount ?? 0;
        this._numberOfToken = numberOfToken;
        this._boughtAt = assetWallet?.boughtAt ?? 0;
        this._amountPaidInVirtualCredits = assetWallet?.amountPaidInVirtualCredits ?? 0;
        this.assetCurrentInformation = assetCurrentInformation && Utils.removeUndefinedAndNullFromObject(assetCurrentInformation);
        this.isSuperAdmin = userId === ADMINISTRATOR_ID;
        if (this.checkPredictPriceData(predictPriceData)) {
            this._predictPriceData = predictPriceData;
        }
        this.currency = currency;
        this.userId = userId;
        this._orderType = orderType;
        this._userInputAmount = userAmount;
        this._userPrice = this.getUserPriceFromUserAmount(userAmount);
    }

    private getUserPriceFromUserAmount(userAmount: number) {
        const amountWithFees = this.getAmountWithFeesFromNetAmount(userAmount);
        return amountWithFees.divide(this._numberOfToken);
    }

    private getAmountWithFeesFromNetAmount(netAmount: number) {
        return (netAmount.addition(this.getFeesFixedAmount())).divide(1 - Number(this.getFeesRate()));
    }

    private checkPredictPriceData(predictPriceData: SecondaryMarketPredictPriceWrapperState) {
        return predictPriceData.assetId === this.AssetId;
    }

    public get InitialSalePeriodIsDone() {
        return true;
    }

    public get IsSuperAdmin(): boolean {
        return this.isSuperAdmin;
    }
    public get TransactionType(): AssetTransactionType {
        return AssetTransactionType.SELL;
    }

    public get Asset(): AssetViewModel {
        return this.assetViewModel;
    }

    public get IsIcoOpen() {
        return (this.assetViewModel && (moment(new Date(this.assetViewModel.InitialSaleDate)).isBefore(moment()) || this.Asset.isInPrePrivateSaleState()) && !this.assetViewModel.InitialSaleDone);
    }

    public get HoldingAssetCount(): number {
        return this._holding;
    }

    public get NumberOfToken(): number {
        return this._numberOfToken;
    }

    public get Currency() {
        return this.currency;
    }

    public get TotalPrice(): number {
        return this.IsPriceSelectorEnabled ? this._numberOfToken.multiply(this._userPrice) : this.getMarketValue();
    }

    private getMarketValue(): number {
        return this.IsLiquidityPoolApplicable ? this.LiquidityPoolData.totalAmount : this.NumberOfToken.multiply(this.CurrentValue);
    }

    public get MarketValue(): number {
        const currentMarketValue = this.getMarketValue();
        return currentMarketValue.subtract(this.getFees(currentMarketValue)).capMinToZero();
    }

    private getFees(value: number) {
        const fee: number = (Number(this.getFeesRate())) * value + this.getFeesFixedAmount();
        return Number(fee.toFixed(2));
    }

    public get TotalPriceFormatted(): string {
        return this.TotalPrice.toCurrency();
    }

    public get TotalTransactionFormatted(): string {
        return this.TotalPrice.toCurrency();
    }

    public get TotalFees() {
        if (!this.NumberOfToken) return ZERO;
        return this.OrderType === ORDER_TYPE.MARKET ? this.TotalPrice.subtract(this.UserInputAmount) : this.getFees(this.TotalPrice);;
    }

    public get TotalFeesFormatted() {
        return this.TotalFees.toCurrency();
    }

    public get Amount() {
        return this.TotalPrice.subtract(this.TotalFees).capMinToZero();
    }

    public get AmountFormatted() {
        return this.Amount.toCurrency();
    }

    public get AssetId(): string {
        return this.assetViewModel.AssetId;
    }

    public get HeaderLabel(): string {
        return i18n.t("sellAsset.tokens.sell", { name: this.assetViewModel.AssetName });
    }

    public get TotalLabel(): string {
        return i18n.t("sellAsset.totalSaleAmount");
    }

    public get AvailableFieldFormatted(): string {
        return `${this._holding} ${i18n.t("shares.text")}`;
    }

    public get AvailableSupply(): number {
        return this._holding;
    }

    public get FeesCautionMessage(): string {
        return i18n.t("sellAsset.feesCaution");
    }

    public get AssetTransactionSign(): string {
        return "-";
    }

    public get Market(): MarketEnum {
        return MarketEnum.SECONDARY;
    }

    public get BoughtAt(): number {
        return this._boughtAt;
    }

    public get Profit(): number {
        return this.TotalPrice - (this._boughtAt * this.NumberOfToken) - this.TotalFees;
    }

    public get ProfitFormatted(): string {
        return this.Profit.toCurrency();
    }

    public get ProfitLabel(): string {
        return this.isProfit ? i18n.t("profit") : i18n.t("loss");
    }

    public get CreateOrderPayload() {
        return {
            type: this.OrderType,
            assetId: this.assetViewModel.AssetId,
            side: ORDER_SIDE.SELL,
            amount: this.NumberOfToken,
            price: this.IsLiquidityPoolApplicable
                ? this.LiquidityPoolData.unitaryPrice
                : this._userPrice,
            showSuccessMessage: false,
            userId: this.userId
        };
    }

    public get CurrentValue(): number {
        return Number(this.SelectedAssetInfo.currentValue.toFixed(2));
    }

    public get IsReadyForConfirmScreen(): boolean {
        if (this.IsUserAmountLowWarning || this.IsUserAmountHighWarning || !this.IsReady) return false;
        if (Boolean(this._predictPriceData) && Boolean(this._numberOfToken)) {
            if (this.IsPoolOutOfStock) return false;
            return true;
        }
        return false;
    }

    public get IsReady(): boolean {
        return Boolean(this._numberOfToken);
    }

    public get IsSupplyExceeded(): boolean {
        return this.AvailableSupply < this._numberOfToken;
    }

    public get ChangeSign() {
        return this.isProfit ? "+" : "";
    }

    public get ChangeColor() {
        return this.isProfit ? "green-text" : "red-text";
    }

    private getFeesRate() {
        if (this.IsLiquidityPoolApplicable)
            return this.assetViewModel.LiquidityPoolSellFeesRatio;
        return this.assetViewModel.OrderBookFeesRate ?? DEFAULT_ORDERBOOK_FEES.sellFees;
    }

    private getFeesFixedAmount() {
        if (this.IsLiquidityPoolApplicable)
            return this.assetViewModel.LiquidityPoolSellFixedFees;
        return this.assetViewModel.OrderBookFixedFees ?? DEFAULT_ORDERBOOK_FEES.sellFixedFees;
    }

    public get isProfit(): boolean {
        return this.Profit >= 0;
    }

    public get UnitChange(): string {
        if (!this.NumberOfToken) return `${ZERO}`;
        return Math.abs(this._userPrice - this._boughtAt).toFixed(2);
    }

    public get ChangePercent(): string {
        if (!this.NumberOfToken) return ZERO + " %";
        return Math.abs(((this._userPrice - this._boughtAt) * 100).safeDivideBy(this._boughtAt)).toFixed();
    }

    public get TransactionErrorMessage(): string {
        return i18n.t("sellAsset.failedToSell");
    }

    public get AssetName(): string {
        return this.assetViewModel.AssetName;
    }

    public get MaxAllowedTransaction(): number {
        return this.AvailableSupply;
    }

    public get IsMaxAllowedTransactionWarning(): boolean {
        return false;
    }

    public get MaxAllowedCautionMessage(): string {
        return i18n.t("assetTransaction.maxSellPerUser");
    }

    public get MaxAllowedBuyToken(): number {
        return 0;
    }

    public get MaxBuyTokenWithAvailableCredit(): number {
        return 0;
    }

    public get IsMaxBuyTokenFromCreditAndInputTokenSame(): boolean {
        return false;
    }

    public get PredictedPrice(): number {
        return this._predictPriceData?.secondaryMarketPredictPriceData.price ?? this.assetViewModel.CurrentValueAsNumber;
    }

    private get SelectedAssetInfo(): Omit<IAssetCurrentInformationData, "fetchedAt"> {
        const defaultAssetInformation: Omit<IAssetCurrentInformationData, "fetchedAt"> = {
            availableSupply: this.assetViewModel.AvailableSupply,
            secondaryMarketSupply: this.assetViewModel.SecondaryMarketSupply,
            currentValue: this.assetViewModel.CurrentValueAsNumber,
            liquidityPoolSupply: this.assetViewModel.LiquidityPoolSupply,
            liquidityPoolValue: this.assetViewModel.LiquidityPoolValue,
            priceHistory: [],
            liquidityPoolApplicable: false,
            initialSalePeriodIsDone: false,
            mainCategory: this.assetViewModel.MainCategoryId,
        };
        return !this.isCurrentAssetInformationApplicable() ? defaultAssetInformation : { ...defaultAssetInformation, ...this.assetCurrentInformation };
    }

    private isCurrentAssetInformationApplicable(): boolean {
        return !(!this.assetCurrentInformation || (this.assetViewModel.FetchedAt && this.assetViewModel.FetchedAt.getDifference(this.assetCurrentInformation.fetchedAt) > 0));
    }

    public get IsSecondaryMarket(): boolean {
        return this.Market === MarketEnum.SECONDARY;
    }

    public get BtnContinueText(): string {
        return i18n.t('initPayment.sellshares');
    }

    public get FeeIncludedText(): string {
        return i18n.t('fees.deducted', { feesAmount: this.TotalFeesFormatted });
    }

    public get isAssetOwnerSelf(): boolean {
        return this.userId === this.AssetId;
    }

    public get IsPoolOutOfStock(): boolean {
        return this.IsLiquidityPoolApplicable && this.SelectedAssetInfo.liquidityPoolSupply <= ZERO;
    }

    public get IsLiquidityPoolApplicable(): boolean {
        return this.assetViewModel.LiquidityPoolEnabled && this.OrderType === ORDER_TYPE.LIQUIDITY_POOL;
    }

    public get IsPoolApplicableAndEqualSupply(): boolean {
        return false;
    }

    private get LiquidityPoolData() {
        const DynamicLiquidityPool: any = LiquidityPoolFactory.getLiquidityPoolByType<any>(this.assetViewModel.AssetCurveConfiguration.curveType ?? AssetCurveType.DEFAULT);
        const liquidityPoolInstance = new DynamicLiquidityPool({ liquidityPoolValue: this.SelectedAssetInfo.liquidityPoolValue, liquidityPoolSupply: this.SelectedAssetInfo.liquidityPoolSupply, initialTokens: this.assetViewModel.LiquidityPoolInitialSupply, assetCurveConfiguration: this.assetViewModel.AssetCurveConfiguration });
        const totalAmount = liquidityPoolInstance.getSellPrice(this._numberOfToken);
        const unitaryPrice = totalAmount.safeDivideBy(this._numberOfToken);

        return {
            totalAmount,
            unitaryPrice: Number(unitaryPrice.toFixed(4)),
        };
    }

    public get UnitPriceFromPoolFormatted(): string {
        return this.Amount.divide(this.NumberOfToken).toCurrency();
    }

    public get TokenInformationHeaderDisplayItems(): string[] {
        return [TOKEN_INFORMATION_ITEMS.PRICE];
    }

    public get OrderType(): ORDER_TYPE {
        return this._orderType;
    }

    public get IsPriceSelectorEnabled(): boolean {
        return !this.IsImmediateSettlement;
    }

    public get IsImmediateSettlement() {
        if (!this.IsLiquidityPoolApplicable) return false;
        return this._orderType !== ORDER_TYPE.MARKET;
    }

    public get UserInputAmount() {
        return this._userInputAmount;
    }

    public get IsUserAmountEditable(): boolean {
        return !this.IsImmediateSettlement;
    }

    public get DisplayAmount(): number {
        return this.IsUserAmountEditable ? this.UserInputAmount : this.Amount;
    }


    private getAcceptableRoundUpErrorCorrection(value: number, units: number): number {
        if (!Application.getInstance().ShouldAvoidPriceRangeRoundUpError) return value;
        const roundUpDeviationErrorMargin = ROUND_UP_ERROR_ACCEPTANCE.multiply(units);
        return value.subtract(roundUpDeviationErrorMargin);

    }

    private get ClassicSellEligiblePriceRange(): { max: number, min: number; } {
        if (this.userId === ADMINISTRATOR_ID) {
            return {
                min: Number.NEGATIVE_INFINITY,
                max: Number.POSITIVE_INFINITY
            };
        }

        const maxPriceDeviation = this._predictPriceData?.secondaryMarketPredictPriceData?.maxPriceDeviation ?? SELL_SLIDER_CONFIG.MAX_DEVIATION_RATIO;
        const maxValue = this.EffectiveValue.addition(this.EffectiveValue.multiply(maxPriceDeviation));
        const maxValueWithFees = this.getAcceptableRoundUpErrorCorrection(maxValue.subtract(this.getFees(maxValue)), this.NumberOfToken);
        const minPriceDeviation = this._predictPriceData?.secondaryMarketPredictPriceData?.minPriceDeviation ?? SELL_SLIDER_CONFIG.MIN_DEVIATION_RATIO;
        let minValue = this.EffectiveValue.subtract(this.EffectiveValue.multiply(minPriceDeviation));
        const minimumValueAcceptable = (SellAssetViewModel.minimumAcceptableSellingPrice + this.getFeesFixedAmount());
        if (minValue < minimumValueAcceptable)
            minValue = minimumValueAcceptable;
        const minValueWithFees = minValue.subtract(this.getFees(minValue));

        return {
            min: minValueWithFees,
            max: maxValueWithFees
        };
    }

    public get PriceDeviationInPercent(): string {
        return `${SELL_SLIDER_CONFIG.MAX_DEVIATION_RATIO.multiply(100) + '%'}`;
    }

    public get EligiblePriceRangeInCurrencyValue() {
        return {
            max: Number.isFinite(this.EligiblePriceRange.max) ? this.EligiblePriceRange.max.toCurrencyValue() : this.EligiblePriceRange.max,
            min: Number.isFinite(this.EligiblePriceRange.min) ? this.EligiblePriceRange.min.toCurrencyValue() : this.EligiblePriceRange.min
        };
    }
    public get EligiblePriceRange(): { max: number, min: number; } {
        return this.ClassicSellEligiblePriceRange;
    }

    public get IsUserAmountLowWarning(): boolean {
        if (!this.NumberOfToken) return false;
        return this.IsPriceSelectorEnabled && this._userInputAmount < this.EligiblePriceRange.min;
    }

    public get IsUserAmountHighWarning(): boolean {
        if (!this.NumberOfToken) return false;
        return this.IsPriceSelectorEnabled && this._userInputAmount > this.EligiblePriceRange.max;
    }

    public get MarketValueInUserCurrencyValue(): string {
        return this.MarketValue.toCurrencyValue().toString();
    }

    public get FromPack(): boolean {
        return false;
    }

    public get DefaultAllowedTransaction(): number {
        return this.AvailableSupply;
    }

    public get EligiblePriceRangeAsCurrency() {
        return {
            max: this.EligiblePriceRangeInCurrencyValue.max.asCurrency(),
            min: this.EligiblePriceRangeInCurrencyValue.min.asCurrency()
        };
    }

    public get NumberOfTokenToString(): string {
        return this._numberOfToken.toString();
    }

    public get IsAmountPaidInVirtualCredits(): boolean {
        return this._amountPaidInVirtualCredits > ZERO;
    }

    public get LiquidityMarketValue() {
        return this.LiquidityPoolData.unitaryPrice;
    }

    public get CurrentValueAsCurrency(): string {
        return this.CurrentValue.toCurrency();
    }

    private get EffectivePrice(): number {
        return (this.assetCurrentInformation.effectivePrice || this.CurrentValue);
    }

    public get EffectivePriceAsCurrency(): string {
        return this.EffectivePrice.toCurrency();
    }

    public get AmountToBeReceivedInSell(): { amountInCredit: string, amountInVirtualCredit: string; } {
        if (this.UserInputAmount < this._amountPaidInVirtualCredits) {
            return {
                amountInCredit: Number(0).toCurrency(2),
                amountInVirtualCredit: this.UserInputAmount.toCurrency(2)
            };
        }

        return {
            amountInCredit: this.UserInputAmount.subtract(this._amountPaidInVirtualCredits).toCurrency(2),
            amountInVirtualCredit: this._amountPaidInVirtualCredits.toCurrency(2)
        };
    }

    public get AmountPaidInVirtualCredit() {
        return this._amountPaidInVirtualCredits;
    }

    private get EffectiveValue(): number {
        return this.EffectivePrice.multiply(this.NumberOfToken);
    }

    public get DefaultDisplayPrice(): string {
        if (this.IsLiquidityPoolApplicable) return this.MarketValueInUserCurrencyValue;
        return this.EffectiveValue.subtract(this.getFees(this.EffectiveValue)).toCurrencyValue().toString();
    }

    public get ShowRewardDebt(): boolean {
        return this.IsAmountPaidInVirtualCredits && Application.getInstance().IsRewardDebtEnabled;
    }
}