import { DEFAULT_ID_AS_STRING } from './../../config/_const';
import { ONE, ZERO } from 'config/_const';
import IAssetPredictPrice from "model/AssetModel/IAssetPredictPrice";
import PriceHistoryInterface from 'model/AssetModel/PriceHistoryInterface';
import { ReduxRootState } from "model/Redux";
import ReduxActionInterface from "model/ReduxActionInterface";
import moment from 'moment';
import IAssetCurrentInformationData from "./IAssetCurrentInformationData";
import { UPDATE_ASSET_CURRENT_INFORMATION, UPDATE_ASSET_CURRENT_INFORMATION_LOADING, UPDATE_ASSET_CURRENT_INFORMATION_PRICE_HISTORY, UPDATE_ASSET_CURRENT_INFORMATION_PRICE_HISTORY_ERROR } from "./actionType";
import { UpdateAssetCurrentInformationPriceHistory } from './types';

const ROOT_VALUE_STATE: ReduxRootState<Record<string, IAssetCurrentInformationData>> = {
    loading: false,
    data: {}
};

export const assetsCurrentInformationReducer = (state: ReduxRootState<Record<string, IAssetCurrentInformationData>> = ROOT_VALUE_STATE, action: ReduxActionInterface<unknown>): ReduxRootState<Record<string, IAssetCurrentInformationData>> => {
    switch (action.type) {
        case UPDATE_ASSET_CURRENT_INFORMATION_LOADING:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_ASSET_CURRENT_INFORMATION:
            return handleUpdateAssetsCurrentInformation(state, action.payload as IAssetPredictPrice);
        case UPDATE_ASSET_CURRENT_INFORMATION_PRICE_HISTORY:
            return handleUpdatePriceHistoriesForAsset(state, action.payload as UpdateAssetCurrentInformationPriceHistory);
        case UPDATE_ASSET_CURRENT_INFORMATION_PRICE_HISTORY_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
};

const handleUpdatePriceHistoriesForAsset = (state: ReduxRootState<Record<string, IAssetCurrentInformationData>>, payload: UpdateAssetCurrentInformationPriceHistory): ReduxRootState<Record<string, IAssetCurrentInformationData>> => {
    return {
        ...state,
        data: {
            ...state.data,
            [payload.assetId]: {
                ...state.data[payload.assetId],
                priceHistory: payload.priceHistory
            }
        },
        loading: false,
    };
};

const handleUpdateAssetsCurrentInformation = (state: ReduxRootState<Record<string, IAssetCurrentInformationData>>, payload: IAssetPredictPrice): ReduxRootState<Record<string, IAssetCurrentInformationData>> => {
    const copyState: ReduxRootState<Record<string, IAssetCurrentInformationData>> = { ...state };
    const newAssetCurrentInformation: IAssetPredictPrice = payload;
    const assetId: string = newAssetCurrentInformation.assetId;
    const assetCurrentInformation: IAssetCurrentInformationData | undefined = copyState.data[assetId];
    const newCurrentValue: number = newAssetCurrentInformation.currentValue ?? assetCurrentInformation?.currentValue ?? ONE;
    const now: Date = new Date();
    const nowAsString: string = now.toISOString();
    const newPriceHistoryEntry: PriceHistoryInterface = {
        _id: DEFAULT_ID_AS_STRING,
        amount: ZERO,
        createdAt: nowAsString,
        updatedAt: nowAsString,
        price: newCurrentValue,
        time: now.getTime()
    };
    if (!assetCurrentInformation)
        return {
            ...copyState,
            data: {
                ...copyState.data,
                [assetId]: {
                    currentValue: newAssetCurrentInformation.currentValue ?? 1,
                    availableSupply: newAssetCurrentInformation.availableSupply ?? ZERO,
                    secondaryMarketSupply: newAssetCurrentInformation.secondaryMarketSupply ?? ZERO,
                    fetchedAt: now,
                    priceHistory: [newPriceHistoryEntry],
                    liquidityPoolApplicable: newAssetCurrentInformation.liquidityPoolApplicable,
                    initialSalePeriodIsDone: newAssetCurrentInformation.initialSalePeriodIsDone,
                    liquidityPoolSupply: newAssetCurrentInformation.liquidityPoolSupply,
                    liquidityPoolValue: newAssetCurrentInformation.liquidityPoolValue,
                    effectivePrice: newAssetCurrentInformation.effectivePrice,
                    mainCategory: newAssetCurrentInformation.mainCategory
                }
            },
            loading: false,
        };

    const priceHistory: PriceHistoryInterface[] = (assetCurrentInformation?.priceHistory ?? []);
    const lastPriceHistoryEntry: PriceHistoryInterface | undefined = priceHistory[priceHistory.length - 1];
    const seconds: number = 25;
    const nextRefreshDate: moment.Moment = moment(lastPriceHistoryEntry?.createdAt).add(seconds, 'seconds');
    const isValueChange: boolean = Boolean(!lastPriceHistoryEntry || lastPriceHistoryEntry.price !== newAssetCurrentInformation.currentValue);
    const shouldUpdatePriceHistory: boolean = moment().isSameOrAfter(nextRefreshDate) && isValueChange;
    if (shouldUpdatePriceHistory)
        priceHistory.push(newPriceHistoryEntry);
    return {
        ...copyState,
        data: {
            ...copyState.data,
            [assetId]: {
                currentValue: newAssetCurrentInformation.currentValue ?? assetCurrentInformation.currentValue,
                availableSupply: newAssetCurrentInformation.availableSupply ?? assetCurrentInformation.availableSupply,
                secondaryMarketSupply: newAssetCurrentInformation.secondaryMarketSupply ?? assetCurrentInformation.secondaryMarketSupply,
                fetchedAt: now,
                priceHistory: [...priceHistory],
                liquidityPoolApplicable: newAssetCurrentInformation.liquidityPoolApplicable,
                initialSalePeriodIsDone: newAssetCurrentInformation.initialSalePeriodIsDone,
                liquidityPoolSupply: newAssetCurrentInformation.liquidityPoolSupply,
                liquidityPoolValue: newAssetCurrentInformation.liquidityPoolValue,
                effectivePrice: newAssetCurrentInformation.effectivePrice ?? assetCurrentInformation.effectivePrice,
                mainCategory: newAssetCurrentInformation.mainCategory
            }
        },
        loading: false,
    };
};
