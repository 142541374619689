import i18n from '18n';
import { DeleteForever } from '@material-ui/icons';
import AdminAssetInformationFormRedux from 'components/medium/form/admin/admin-asset-form/AdminAssetInformationForm';
import ProfilBanner from 'components/medium/profil-banner/ProfilBanner';
import { IOSSwitch } from 'components/small/Button';
import CircularLoader from 'components/small/CircularLoader';
import GreenButton from 'components/small/buttons/green-button/GreenButton';
import RedButton from 'components/small/buttons/red-button/RedButton';
import LoginIcon from 'components/small/login-icon/LoginIcon';
import { FOOTBALL_PREFIX } from 'config/_const';
import { AssetCurveType } from 'config/_enums';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import AssetInterface from 'model/AssetModel/AssetInterface';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actionFetchAssetCategory } from 'service/asset-category/actions';
import {
    actionDeleteAsset,
    editAssets,
    getAssetBySlug,
    uploadAssetImg
} from 'service/assets/actions';
import { assetBySlugSelector } from 'service/assets/selectors';
import { loginPreview } from 'service/auth/actions';
import { managementModal } from 'service/modal/action';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import './AssetEditPage.scss';
import AssetEditPageProps from './AssetEditPageProps';

class AssetEditPage extends Component<AssetEditPageProps> {
    componentDidMount = () => {
        this.props.getAssetBySlug(this.props.match.params.slug);
        this.props.actionFetchAssetCategory();
    };

    onPostDeleteAsset = (asset: AssetInterface) => {
        this.props.managementModal(
            true,
            {
                display: true,
                disableBackDrop: false,
                showBackArrow: false,
                fullScreen: false,
                type: 'CONFIRM',
                propsCustom: {
                    message: i18n.t('message-delete-asset'),
                    onValidation: () => this.onDeleteAsset(asset._id),
                    onRefused: () => this.props.managementModal(false),
                    textCancelButton: i18n.t('button-cancel-delete-asset'),
                    textValidButton: i18n.t('button-delete-asset'),
                    showCancelButton: true,
                },
            },
        );
    };

    onDeleteAsset = (assetId: string) => {
        this.props.actionDeleteAsset(assetId);
        this.props.managementModal(false);
        this.props.history.goBack();
    };

    render = () => {
        const {
            assets: asset,
            assetsCategories } = this.props;

        if (!asset)
            return <CircularLoader loaderMessage={'Loading Asset'} loaderLocal={false} />;

        const assetVM: AssetViewModel = new AssetViewModel(asset!);

        return (
            <div className="asset-edit-page">
                {assetVM && !assetsCategories.loading && (
                    <div className="content">

                        <div className="banner-container">
                            <ProfilBanner
                                assetVM={assetVM}
                                instagram={asset.instagram}
                                snapchat={asset.snapchat}
                                facebook={asset.facebook}
                                twitter={asset.twitter}
                                tiktok={asset.tiktok}
                                youtubeVideo={asset.youtubeVideo}
                                editIcon={true}
                                logoUrl={`${asset.icon}?t=${new Date().getTime()}`}
                                assetName={asset.name}
                            // onIconEditChange={(file: any, field: string) => this.props.uploadAssetImg(file, this.props.match.params.slug, field)} 
                            />
                        </div>
                        <div className="action-button" >
                            <span>{i18n.t('button-switch')}</span>
                            <IOSSwitch
                                onChange={(e: any) => this.props.editAssets({ _id: asset._id, hidden: e.target.checked })} />
                        </div>
                        <div className="container-action-buttons">
                            <div className="action-button" >
                                <GreenButton
                                    disabled={false}
                                    receiveClickEvent={false}
                                    textButton={<span>Login  <LoginIcon /></span>} type="button"
                                    handleClick={() => this.props.loginPreview(asset._id, this.props.history, 'vip', false)} />
                            </div>
                            <div className="action-button">
                                <RedButton
                                    disabled={false}
                                    receiveClickEvent={false}
                                    textButton={<span>{i18n.t('button-delete-asset')}<DeleteForever style={{ verticalAlign: 'middle', fontSize: '20px', marginBottom: '4px' }} /></span>}
                                    type="button"
                                    handleClick={() => this.onPostDeleteAsset(asset)} />
                            </div>

                        </div>

                        <div className="admin-asset-form-container">
                            <AdminAssetInformationFormRedux
                                initialValues={{
                                    icon: asset.icon,
                                    name: asset.name,
                                    description: asset.description,
                                    facebook: asset.facebook,
                                    facebookFollowers: asset.facebookFollowers,
                                    instagram: asset.instagram,
                                    instagramFollowers: asset.instagramFollowers,
                                    snapchat: asset.snapchat,
                                    snapchatFollowers: asset.snapchatFollowers,
                                    tiktok: asset.tiktok,
                                    tiktokFollowers: asset.tiktokFollowers,
                                    youtube: asset.youtube,
                                    youtubeFollowers: asset.youtubeFollowers,
                                    youtubeVideo: asset.youtubeVideo,
                                    twitter: asset.twitter,
                                    twitterFollowers: asset.twitterFollowers,
                                    mainCategory: asset.mainCategory,
                                    subtitle: asset.subtitle || asset.mainCategory?.name,
                                    listOrder: asset.listOrder,
                                    availableForTournament: asset.availableForTournament,
                                    IRLScore: Math.floor(asset.IRLScore),
                                    yieldEnabled: asset.yieldEnabled,
                                    contractTerm: asset.contractTerm,
                                    instagramTag: asset.instagramTag || '',
                                    ...(asset.IRLScoreDetails && asset.IRLScoreDetails.length > 0 && { IRLScoreDetails: asset.IRLScoreDetails }),
                                    cardLevel: asset.cardLevel,
                                    sportCountry: asset.sportCountry,
                                    sportTeam: asset.sportTeam,
                                    sportPlayer: {
                                        ...asset.sportPlayer,
                                        ...(asset.sportPlayer?.id && { id: parseInt(JSON.stringify(asset.sportPlayer?.id ?? "").replace(JSON.stringify(FOOTBALL_PREFIX), ""), 10) }),
                                    },
                                    sportsScore: asset.sportsScore,
                                    isRetired: asset.sportPlayer?.isRetired || false,
                                    scoreBoosted: asset.scoreBoosted ?? false,
                                    marketConfiguration: {
                                        ...asset.marketConfiguration,
                                        secondaryMarket: {
                                            ...asset.marketConfiguration.secondaryMarket,
                                            isTokensInjectionEnabled: typeof asset.marketConfiguration.secondaryMarket.isTokensInjectionEnabled !== "undefined" ? asset.marketConfiguration.secondaryMarket.isTokensInjectionEnabled : true,
                                            isSellable: typeof asset.marketConfiguration.secondaryMarket.isSellable !== "undefined" ? asset.marketConfiguration.secondaryMarket.isSellable : true,
                                            isBuyable: typeof asset.marketConfiguration.secondaryMarket.isBuyable !== "undefined" ? asset.marketConfiguration.secondaryMarket.isBuyable : true,
                                        }
                                    },
                                    assetCurveConfiguration: {
                                        curveType: asset.assetCurveConfiguration?.curveType || AssetCurveType.WAVE,
                                        factors: asset.assetCurveConfiguration?.factors || {
                                            power: 0.55,
                                            modulationFactorParam: 0.05,
                                            cosFactor: 50,
                                            sinFactor: 100,
                                            plateauLength: 0,
                                            plateauHeightFactor: 0,
                                            endPrice: 30
                                        }
                                    }
                                }}
                                categories={assetsCategories.data && assetsCategories.data.length > 0 ? assetsCategories.data.map((cat: AssetCategoryInterface) => ({ label: cat.name, value: cat._id })) : []}
                                buttonText={'Update asset'}
                                onAssetUpdate={(form: Partial<AssetInterface>) => this.props.editAssets({ ...form, _id: asset._id })}
                                onUploadImg={(file: any, field: string) => this.props.uploadAssetImg(file, this.props.match.params.slug, field)}
                                editMode={true}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };
}

const mapStateToProps = (state: AssetEditPageProps, props: AssetEditPageProps) => ({
    assets: assetBySlugSelector(props.match.params.slug)(state),
    assetsCategories: state.assetsCategories,
});
export default connect(mapStateToProps, {
    getAssetBySlug,
    uploadAssetImg,
    editAssets,
    actionFetchAssetCategory,
    managementModal,
    actionDeleteAsset,
    loginPreview,
})(AssetEditPage);
