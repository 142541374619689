import { COLOR } from "config/_const";
import * as React from "react";

function ArrowUpIcon(props: React.SVGProps<SVGSVGElement>) {
    const color = props.color || COLOR.PRIMARY_VIOLET;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.289" height="12.889" viewBox="0 0 11.289 12.889">
            <path id="arrow-right-solid_1_" data-name="arrow-right-solid (1)" d="M11.56,5.658a.845.845,0,0,0,0-1.194L7.343.247A.844.844,0,1,0,6.149,1.441L8.93,4.219H.843a.843.843,0,1,0,0,1.687H8.927L6.152,8.684A.844.844,0,0,0,7.346,9.878l4.217-4.217Z" transform="translate(0.582 12.389) rotate(-90)" fill={color} stroke={color} strokeWidth="1"/>
      </svg>      
    );
}

export default ArrowUpIcon;
