import { CSSProperties } from 'react';
import IBorderRadius from '../../model/Css/IBorderRadius';
import Color from '../Shared/Color';

export default class LevelSubIconViewModel {
    public static readonly backgroundColor = {
        GREEN: '#53d081',
        PURPLE: '#7180E3',
    };
    private static readonly defaultBorderRadius: IBorderRadius = {
        size: 10,
        unit: 'pt',
    };
    private radius: IBorderRadius;
    private level: number;
    private backgroundColor: string;
    private isBoosted: boolean;

    constructor(level: number, isBoosted: boolean, backgroundColor?: string, radius: IBorderRadius = LevelSubIconViewModel.defaultBorderRadius) {
        this.radius = radius;
        this.level = level;
        this.backgroundColor = backgroundColor ?? Color.getColorByInputRatio(this.Level);
        this.isBoosted = isBoosted;
    }

    public generateSubIconCSS = (): CSSProperties => {
        const baseStyles: CSSProperties = {
            borderRadius: `${this.Radius.size}${this.Radius.unit}`,
            backgroundColor: this.BackgroundColor,
        };
        if (this.isBoosted) {
            baseStyles.boxShadow = `0px 3px 8px 1px ${this.ColorByInputLevel}`;
        }
    
        return baseStyles;
    }

    public get Radius(): IBorderRadius {
        return this.radius;
    }
    public get Level(): number {
        return this.level ?? 0;
    }
    public get BackgroundColor(): string {
        return this.backgroundColor;
    }

    public get ColorByInputLevel(): string {
        return Color.getColorByInputRatio(this.Level);
    }
}
