
import React from 'react';

const FightWhiteIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ color, width, height }) => {
    return (
        <svg id="CroosBlades" xmlns="http://www.w3.org/2000/svg" width={width ?? "10.909"} height={height ?? "12"} viewBox="0 0 10.909 12">
            <ellipse id="Ellipse_11" cx="5.455" cy="6" rx="5.455" ry="6" fill={color ?? "#dbdcdf"} opacity="0" />
            <path id="swords-solid" d="M.1.1A.3.3,0,0,1,.371.014L1.71.285a.912.912,0,0,1,.465.251l5.3,5.353L5.836,7.549.531,2.2a.912.912,0,0,1-.249-.47L.014.375A.31.31,0,0,1,.1.1ZM8.732,5.927l.3.307a.308.308,0,0,1,.063.341L8.576,7.763,9.644,8.841a.31.31,0,0,1,0,.433l-.456.46a.3.3,0,0,1-.429,0L7.691,8.657l-1.178.527a.3.3,0,0,1-.338-.063l-.3-.307a.31.31,0,0,1,0-.433L8.3,5.927A.3.3,0,0,1,8.732,5.927Zm-6.9.4.963-.972L4.44,7.012l-.963.972.393.4a.31.31,0,0,1,0,.433l-.3.307a.3.3,0,0,1-.338.063L2.05,8.657.983,9.734a.3.3,0,0,1-.429,0L.1,9.274a.31.31,0,0,1,0-.433L1.165,7.763.643,6.575a.307.307,0,0,1,.063-.341l.3-.307a.3.3,0,0,1,.429,0ZM9.211,2.2,6.947,4.483,5.3,2.823,7.564.536A.9.9,0,0,1,8.029.285L9.37.014A.307.307,0,0,1,9.646.1a.312.312,0,0,1,.084.278l-.27,1.35a.926.926,0,0,1-.249.47Z" transform="translate(0.731 1.018)" fill="#dbdcdf" />
        </svg>
    )
}

export default FightWhiteIcon;