import IUseAllAssets from 'hooks/asset/use-all-assets/IUseAllAssets';
import useAllAssets from 'hooks/asset/use-all-assets/useAllAssets';
import ITeamAsset from 'model/Team/ITeamAsset';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import IUseTeamList from './IUseTeamList';

const useTeamList = (): IUseTeamList => {
    const { getAssetsByIds }: IUseAllAssets = useAllAssets();
    const getAssetViewModelsFromTeamAssets = (teamAssets: ITeamAsset[]): AssetViewModel[] => {
        return getAssetsByIds(teamAssets.map((teamAsset: ITeamAsset) => teamAsset.asset));
    };

    return {
        getAssetViewModelsFromTeamAssets,
    };
};

export default useTeamList;
