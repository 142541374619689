import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import * as FirebaseNotificationsActionsTypes from './actionsType';
import AxiosConfig from '../../config/axiosConfig';
import { IHttpResponse } from '../../model/IHttpResponse';

// todo: remove ?
export const fetchFirebaseToken = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpResponse = await AxiosConfig.get('/users/firebase/token');

        if (response && response.data && response.data.status !== 200) {
            return dispatch({
                type: FirebaseNotificationsActionsTypes.FIREBASE_NOTIFICATIONS_ERROR,
            });
        }

        return dispatch({
            type: FirebaseNotificationsActionsTypes.FIREBASE_NOTIFICATIONS_SUCCESS,
            payload: {
                data: response.data.data,
                message: response.data.message,
            },
        });

    } catch (error) {

        return dispatch({
            type: FirebaseNotificationsActionsTypes.FIREBASE_NOTIFICATIONS_ERROR,
        });
    }
};

export const addFirebaseToken = (userId: string, firebaseToken: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {

        const response: IHttpResponse = await AxiosConfig.post(`/users/firebase/${userId}`, { firebaseToken });
        if (response && response.data && response.data.status !== 200) {
            return dispatch({
                type: FirebaseNotificationsActionsTypes.FIREBASE_NOTIFICATIONS_ERROR,
            });
        }

        return dispatch({
            type: FirebaseNotificationsActionsTypes.FIREBASE_NOTIFICATIONS_SUCCESS,
            payload: {
                data: [response.data.data],
                message: response.data.message,
            },
        });

    } catch (error) {
        return dispatch({
            type: FirebaseNotificationsActionsTypes.FIREBASE_NOTIFICATIONS_ERROR,
        });
    }
};
