import * as React from 'react';

function CreditCard() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.408" height="22.873" viewBox="0 0 29.408 22.873">
            <g id="credit-card-duotone" transform="translate(0 -32)">
                <path id="Tracé_3212" data-name="Tracé 3212" d="M29.408,132.9H0V128H29.408Z" transform="translate(0 -91.099)" fill="#15182b" />
                <path id="Tracé_3213" data-name="Tracé 3213" d="M26.141,32a3.269,3.269,0,0,1,3.268,3.268V36.9H0V35.268A3.267,3.267,0,0,1,3.268,32Zm3.268,19.605a3.271,3.271,0,0,1-3.268,3.268H3.268A3.269,3.269,0,0,1,0,51.605V41.8H29.408ZM5.718,48.338a.817.817,0,1,0,0,1.634H8.986a.817.817,0,1,0,0-1.634Zm6.535,1.634h6.535a.817.817,0,1,0,0-1.634H12.253a.817.817,0,0,0,0,1.634Z" fill="#15182b" opacity="0.4" />
            </g>
        </svg>

    );
}

export default CreditCard;
