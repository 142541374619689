import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import Facebook from '../../../../components/icons/Facebook';
import Instagram from '../../../../components/icons/Instagram';
import Snapchat from '../../../../components/icons/Snapchat';
import Tiktok from '../../../../components/icons/Tiktok';
import Twitter from '../../../../components/icons/Twitter';
import Youtube from '../../../../components/icons/Youtube';
import SocialBadge from '../../../../components/small/SocialBadge/SocialBadge';
import ISocialNetwork from '../../../../model/AssetModel/ISocialNetwork';

interface ISocialBadgesBannerProps {
    socialNetworks: ISocialNetwork[];
    isTopAsset: boolean;
    numberOfSocialBadges?: number;
}

const useStyles = () => {
    const background = '#f2f3f5';

    return makeStyles(() => ({
        container: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '90%',
            marginLeft: '6px',

            '& div:not(:last-child)': {
                marginRight: '8px',
            },
        },
        topAsset: {
            background,
            width: 'fit-content',
            flexWrap: 'inherit',
            alignItems: 'center',
            borderRadius: '10px',
            padding: '7px',
            marginLeft: '0px',
        },
    }));
};

const SocialBadgesBanner: React.FC<ISocialBadgesBannerProps> = ({ socialNetworks, isTopAsset, numberOfSocialBadges }: ISocialBadgesBannerProps) => {
    const classes = useStyles()();
    const widthOfScreen: number = window.screen.width;
    const icons: Record<string, React.ElementType> = {
        Instagram,
        Tiktok,
        Snapchat,
        Facebook,
        Youtube,
        Twitter,
    };

    const displayConditionSocialBadgesTopAsset: boolean = isTopAsset && socialNetworks.length > 0;
    const maxWidthMobileScreen: number = 768;
    const numberOfBadgesMaxWidthMobileScreen: number = 3;
    const mediumWidthMobileScreen: number = 420;
    const numberOfBadgesMediumWidthMobileScreen: number = 2;
    const minWidthMobileScreen: number = 330;
    const numberOfBadgesMinWidthMobileScreen: number = 1;

    let numberOfBadges: number | undefined = numberOfSocialBadges;
    if (isTopAsset && numberOfSocialBadges === undefined) {
        if (widthOfScreen < maxWidthMobileScreen) numberOfBadges = numberOfBadgesMaxWidthMobileScreen;
        if (widthOfScreen < mediumWidthMobileScreen) numberOfBadges = numberOfBadgesMediumWidthMobileScreen;
        if (widthOfScreen < minWidthMobileScreen) numberOfBadges = numberOfBadgesMinWidthMobileScreen;
    }

    return (
        <div className={clsx(classes.container, { [classes.topAsset]: displayConditionSocialBadgesTopAsset })} >
            {socialNetworks.slice(0, numberOfBadges).filter(socialNetwork => socialNetwork.followers !== 0).map((socialNetwork) => {
                return <SocialBadge key={socialNetwork.name} link={socialNetwork.url} icon={icons[socialNetwork.icon]} followers={socialNetwork.followers} isTopAsset={isTopAsset} />;
            })}

        </div >
    );
};

export default SocialBadgesBanner;
