import Application from 'Application';
import { ZERO } from 'config/_const';
import AssetInterface from 'model/AssetModel/AssetInterface';
import PriceHistoryInterface from 'model/AssetModel/PriceHistoryInterface';
import { RootState } from 'model/Redux';
import { createSelector } from 'reselect';
import AssetViewModel from '../../view-model/Assets/AssetViewModel';
import { assetByIdSelector } from '../assets/selectors';
import IAssetCurrentInformationData from './IAssetCurrentInformationData';
import { Selector } from 'react-redux';
import ReduxStoreInterface from 'model/ReduxStoreInterface';

export const selectorAssetInformationPriceHistory = createSelector<RootState, string, PriceHistoryInterface[], PriceHistoryInterface[]>(
    (state: RootState, assetId: string) => {
        return state.assetsCurrentInformation.data[assetId]?.priceHistory ?? []
    },
    (priceHistory: PriceHistoryInterface[]) => {
        return priceHistory
    },
);

export const selectorRemainingTokensPacks = createSelector(
    (state: RootState, assetId: string) => assetByIdSelector(state, assetId),
    (state: RootState, assetId: string) => state.assetsCurrentInformation.data[assetId],
    (asset: AssetInterface | undefined, assetCurrentInformation: IAssetCurrentInformationData | null) => {
        if (!assetCurrentInformation || !asset)
            return ZERO;
        const assetVM: AssetViewModel = new AssetViewModel(asset);
        const packsTotalSupply: number = Application.getInstance().PacksTotalSupply;
        const availableSupply: number = assetCurrentInformation.secondaryMarketSupply ?? ZERO;
        const stageSupply: number = AssetViewModel.getSupplyByStage(assetVM, availableSupply);
        const remainingTokens: number = packsTotalSupply.subtract(stageSupply);
        return remainingTokens.divide(Application.getInstance().PacksConfiguration.tokens);;
    },
);

export const isAssetInformationSelectorLoading: Selector<ReduxStoreInterface, boolean> = state => state.assetsCurrentInformation.loading;