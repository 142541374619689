import useUserLeague from 'hooks/use-user-league/useUserLeague';
import React from 'react';
import { Trans } from 'react-i18next';
import './UserLeague.scss';
import LeagueDetails from './localComponent/LeagueDetails/LeagueDetails';
import LeagueProgressMap from './localComponent/LeagueProgressMap/LeagueProgressMap';
import UpgradeLeagueProgress from './localComponent/UpgradeLeagueProgress/UpgradeLeagueProgress';

const UserLeagueInfo: React.FC = () => {
    const { userLeagueViewModel } = useUserLeague();

    return (
        <div className='user-league'>
            <section className="user-league-header">
                {userLeagueViewModel.LeagueIcon &&
                    <div className="league-img">
                        <div className="league-icon">
                            <userLeagueViewModel.LeagueIcon />
                        </div>
                    </div>
                }
                <div className="league-title">
                    {userLeagueViewModel.YourLeague}
                </div>
                <div className='league-token'>
                    <Trans i18nKey={userLeagueViewModel.IsUserInLastLeague ? "league.new.token" : userLeagueViewModel.UpgradeButtonName}
                        values={{
                            remainingToken: userLeagueViewModel.DiffForHigherLeague.remainingGeneralToken,
                            remainingLeagueToken: userLeagueViewModel.DiffForHigherLeague.remainingLeagueToken,
                            nextLeague: userLeagueViewModel.LeagueAttribution.HigherLeagueName,
                        }}
                    />
                </div>
            </section>
            <section className="user-league__map-container">
                <div className="league-progress-map">
                    <LeagueProgressMap userLeagueViewModel={userLeagueViewModel} />
                </div>
                <div className="upgrade-button">
                    <UpgradeLeagueProgress userLeagueViewModel={userLeagueViewModel} />
                </div>
            </section>
            <section className="user-league-details">
                <LeagueDetails userLeagueViewModel={userLeagueViewModel} />
            </section>
        </div>
    )
}

export default UserLeagueInfo