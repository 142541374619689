import React from 'react';
import './style.scss';
import i18n from '18n';

const NoNextTournament: React.FC = () => {
    return (
        <div className="no-next-tournament">
            <div className="next-tournament-information">
                <p>{i18n.t('tournament.information-league.next-tournament.no-next-tournament')}</p>
            </div>
        </div>
    );
};

export default React.memo(NoNextTournament);
