import { DESABLED_GA_EVENTS, G_TAGS_KNOWN_EVENTS } from "config/_const";
import TagManager from "react-gtm-module";
import Utils from "utils/Utils";
import IAssetTransactionViewModel from "view-model/AssetTransactionViewModel/IAssetTransactionViewModel";
import IHandleTournamentReward from "./IHandleTournamentReward";
import IGoogleTrackingFirstPurchase from "./IGoogleTrackingFirstPurchase";
export class TagManagerWrapper {
    static initialize(gtmId: string) {
        TagManager.initialize({
            gtmId,
        });
    }



    static sendEvent(eventName: string, eventData?: object, dataLayerName?: string) {
        if (process.env.NODE_ENV !== 'production' || DESABLED_GA_EVENTS)
            return;
        TagManager.dataLayer({});
        TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                ...eventData
            },
            dataLayerName
        });
    };

    static sendPurchaseEvent = (assetTransactionViewModel: IAssetTransactionViewModel, transactionIdPrefix: string) => {
        const tagManagerArgs = {
            ecommerce: {
                transaction_id: `${assetTransactionViewModel.Market};${transactionIdPrefix};${assetTransactionViewModel.AssetId};${Utils.uid()}`,
                value: assetTransactionViewModel.Amount,
                currency: assetTransactionViewModel.Currency,
                tax: assetTransactionViewModel.TotalFees,
                items: [{
                    item_id: assetTransactionViewModel.Asset.AssetId,
                    item_name: assetTransactionViewModel.Asset.AssetName,
                    currency: assetTransactionViewModel.Currency,
                    item_category: assetTransactionViewModel.Asset.MainCategoryId,
                    price: assetTransactionViewModel.CurrentValue,
                    quantity: assetTransactionViewModel.NumberOfToken
                }]
            },
            conversionValue: assetTransactionViewModel.Amount,
            currency: assetTransactionViewModel.Currency
        };
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.PURCHASE, tagManagerArgs);
    };

    static sendFirstPurchaseEvent = (firstPurchaseData: IGoogleTrackingFirstPurchase) => {
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.FIRST_PURCHASE, firstPurchaseData);
    }

    static handleTournamentReward(eventData: IHandleTournamentReward): void {
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.CLAIM_TOURNAMENT_REWARD, eventData)
    }
}