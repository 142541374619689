export const checkoutModeUnknownErrorMessage: string = 'DEVELOPER_MESSAGE : Checkout mode is set to NONE';
export const intentIsUndefinedErrorMessage: string = 'DEVELOPER_MESSAGE : intent is undefined for mode BUY_ASSET or CREDIT_ACCOUNT';
export const multipleBuyIntentIsUndefinedErrorMessage: string = 'DEVELOPER_MESSAGE : multipleBuyIntent is undefined for MULTIPLE_BUY_ASSET';
export const creditAccountSuccessCallbackIsMissingErrorMessage: string = 'DEVELOPER_MESSAGE : creditAccountSuccessCallback is not set for mode CREDIT_ACCOUNT';
export const buyAssetCallbackAreMissingErrorMessage: string = 'DEVELOPER_MESSAGE : buyAssetSuccessCallback or buyAssetInErrorCallback is not set for mode BUY_ASSET';
export const intentIsNotWellTypedErrorMessage: string = 'DEVELOPER_MESSAGE : Intent is not typed as IBuyAssetIntent for mode BUY_ASSET';
export const stripeIsNotLoadedErrorMessage: string = 'Unable to find stripe object or elements. Be sure stripe is loaded before calling this function.';
export const unknownCheckoutModeErrorMessage: string = 'DEVELOPER_MESSAGE : Unknown checkout mode';
export const multipleIntentNotFormattedWellErrorMessage: string = 'DEVELOPER_MESSAGE : Multiple buy intent is not formatted well. Missing primaryMarketData or secondaryMarketData';
export const multipleIntentArraysAreEmptyErrorMessage: string = 'DEVELOPER_MESSAGE : Multiple buy intent is not formatted well. PrimaryMarketData and SecondaryMarketData arrays are empty';
export const buyAssetIntentUndefinedErrorMessage: string = 'DEVELOPER_MESSAGE : Intent is undefined for BUY_ASSET';
export const stripeElementsOrInstanceNullErrorMessage: string = 'Unable to find stripe instance or elements instance';
export const stripeCardNotFoundErrorMessage: string = 'Unable to find card element from checkout form';
export const buyScoreintentOrCallbackFxnIsUndefinedErrorMessage: string = 'DEVELOPER_MESSAGE : buyScoreIntent is undefined or successCallback or errorCallback function is missing for BUY_BOOST_SCORE';