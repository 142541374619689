import { ISvgIconProps } from 'interfaces/SvgIcon.interface';
import * as React from 'react';


const CupDuotune: React.FC<ISvgIconProps> = ({ ...props }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" {...props} viewBox="0 0 20 20">
            <g id="icon" transform="translate(0 -728.5)">
                <g id="Rectangle_2014" data-name="Rectangle 2014" transform="translate(0 728.5)" fill="#8187d0" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="20" height="20" stroke="none" />
                    <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
                </g>
                <g id="User_rank_trophy" data-name="User rank trophy" transform="translate(-299.714 -128.135)">
                    <g id="trophy-duotone_1_" data-name="trophy-duotone (1)" transform="translate(300 858)">
                        <path id="Tracé_3768" data-name="Tracé 3768" d="M135.012,12.513a1.528,1.528,0,0,0-.536,1.134,1.439,1.439,0,0,0,1.464,1.463h.157a1.619,1.619,0,0,1,1.619,1.619.534.534,0,0,1-.509.54h-7.587a.515.515,0,0,1-.509-.54,1.619,1.619,0,0,1,1.619-1.619h.157a1.459,1.459,0,0,0,1.462-1.462,1.527,1.527,0,0,0-.536-1.134C128,9.164,128,3.238,128,3.238V1.079A1.084,1.084,0,0,1,129.085,0h8.624a1.084,1.084,0,0,1,1.084,1.079V3.238C138.763,3.238,138.763,9.164,135.012,12.513Z" transform="translate(-123.683)" fill="#8187d0" />
                        <path id="Tracé_3769" data-name="Tracé 3769" d="M6.534,72.53c-.132-.2-.253-.391-.368-.59a7.652,7.652,0,0,1-3.555-2.682,5.911,5.911,0,0,1-.979-3.639H4.344c-.023-.341-.026-.54-.026-.54l0-1.079H.921a.81.81,0,0,0-.788.62,7.447,7.447,0,0,0,1.118,5.515A10.361,10.361,0,0,0,7.576,74.1a2.2,2.2,0,0,1,.523.261A9.673,9.673,0,0,1,6.534,72.53ZM19.3,64.62a.812.812,0,0,0-.789-.62h-3.4l0,1.079s0,.2-.026.54H17.8a5.9,5.9,0,0,1-.979,3.636,7.647,7.647,0,0,1-3.555,2.682q-.172.3-.366.59a9.607,9.607,0,0,1-1.567,1.827,2.2,2.2,0,0,1,.523-.261,10.341,10.341,0,0,0,6.324-3.96A7.442,7.442,0,0,0,19.3,64.62Z" transform="translate(-0.001 -61.841)" fill="#8187d0" opacity="0.4" />
                    </g>
                    <path id="check-duotone" d="M7.117,96.162a.518.518,0,0,1,0,.733L2.963,101.05a.518.518,0,0,1-.733,0L.152,98.973a.519.519,0,1,1,.734-.733l1.7,1.709,3.8-3.785a.517.517,0,0,1,.733,0Z" transform="translate(306.443 764.586)" fill="#fff" stroke="#fff" stroke-width="1" />
                </g>
            </g>
        </svg>

    )
}

export default CupDuotune;