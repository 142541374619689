import ReduxActionInterface from '../../model/ReduxActionInterface';
import * as UserActionsTypes from './actionsType';
import ReduxRootState from '../../model/Redux/ReduxRootSate';
import Utils from '../../utils/Utils';
import UserInterface from '../../model/User/UserInterface'

const ROOT_VALUE_STATE: ReduxRootState = {
    data: {
        all: [],
        search: [],
        stats: {},
    },
    loading: false,
};

export const userReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    switch (action.type) {

        case UserActionsTypes.USER_LOADING:
            return {
                ...state,
                loading: true,
            };

        case UserActionsTypes.USER_ERROR:
            if (action.payload) {
                return {
                    ...state,
                    loading: false,
                    error: action.payload.message,
                };
            }

            return state;

        case UserActionsTypes.USER_SUCCESS:
            if (action.payload) {
                const stateData = state.data && state.data.all.length ? Utils.removedDuplicateMongoFile(state.data.all, action.payload.data) : [];
                return {
                    ...state,
                    data: {
                        ...state.data,
                        all: stateData.concat(action.payload.data),
                    },
                    loading: false,
                    error: null,
                };
            }

            return state;

        case UserActionsTypes.USER_SEARCH:
            if (action.payload && action.payload.data && action.payload.data.users.length) {

                const stateData = !action.payload.data.enablePagination ? action.payload.data.users : Utils.removedDuplicateMongoFile(state.data.search, action.payload.data.users).concat(action.payload.data.users);

                return {
                    ...state,
                    data: {
                        ...state.data,
                        search: stateData,
                    },
                    loading: false,
                    error: null,
                };
            }

            return state;

        case UserActionsTypes.USER_DELETED:
            if (action.payload) {

                return {
                    ...state,
                    data: {
                        ...state.data,
                        all: state.data.filter((user: any) => user._id !== action.payload.data),
                    },
                    loading: false,
                    error: null,
                };
            }
            return state;
        case UserActionsTypes.ACTUAL_USER_SUCCESS:
            if (action.payload) {
                return {
                    ...state,
                    actualUserIdentity: { ...state.actualUserIdentity, ...action.payload },
                    loading: false,
                    error: null,
                };
            }

            return state;

        case UserActionsTypes.USERS_STATS:
            if (action.payload) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        stats: action.payload,
                    },
                    loading: false,
                    error: null,
                };
            }
            return state;
        case UserActionsTypes.USER_DISABLED:
            return replaceUserData(action.payload.data, state);
        case UserActionsTypes.USER_REACTIVATED:
            return replaceUserData(action.payload.data, state);
        default: return state;
    }
};

const replaceUserData = (userData: UserInterface, state: ReduxRootState) => {
    let data = state.data.all;
    let search = state.data.search;
    const allDataIndex = data.findIndex((user: UserInterface) => user._id === userData._id.toString());
    if (allDataIndex !== -1)
        data[allDataIndex] = userData;
    const searchDataIndex = search.findIndex((user: UserInterface) => user._id.toString() === userData._id.toString());
    if (searchDataIndex !== -1)
        search[searchDataIndex] = userData;
    return {
        ...state,
        data: {
            ...state.data,
            all: data,
            search
        },
        loading: false,
        error: null,
    };
}
