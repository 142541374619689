import { AssetInterfaceWithTranslatedDescriptions } from 'model/AssetModel/AssetInterface';
import ReduxRootState from 'model/Redux/ReduxRootSate';
import ReduxActionInterface from 'model/ReduxActionInterface';
import { STATUS } from 'service/storeType';
import Utils from 'utils/Utils';
import * as ActionTypesAsset from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]> = {
    loading: false,
    data: [],
    price: 0,
    initialSellTotalPrice: 0,
    status: {
        getPredictedPriceStatus: STATUS.IDLE,
        getAssetsWithoutPage: STATUS.IDLE,
    },
    flags: {
        isFromUserAction: false,
    },
};

export const assetsReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface): ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]> => {
    let assetIndexFromStore: number = -1;

    switch (action.type) {
        case ActionTypesAsset.GET_TRANSLATED_DESCRIPTION_LOADING:
        case ActionTypesAsset.ASSETS_MARKET_CONFIGURATION_LOADING:
        case ActionTypesAsset.ASSETS_PRICE_HISTORIES_LOADING:
        case ActionTypesAsset.GET_ASSET_TOP_INVESTORS_LOADING:
        case ActionTypesAsset.ASSETS_LOADING:
        case ActionTypesAsset.CLOUD_CACHE_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ActionTypesAsset.GET_TRANSLATED_DESCRIPTION_ERROR:
        case ActionTypesAsset.ASSETS_MARKET_CONFIGURATION_ERROR:
        case ActionTypesAsset.ASSETS_PRICE_HISTORIES_ERROR:
        case ActionTypesAsset.GET_ASSET_TOP_INVESTORS_ERROR:
        case ActionTypesAsset.ASSETS_ERROR:
        case ActionTypesAsset.CLOUD_CACHE_LOADING_COMPLETE:
            return {
                ...state,
                loading: false,
            };

        case ActionTypesAsset.ASSETS_SUCCESS:
            return handleAssetsSuccess(state, action);

        case ActionTypesAsset.ASSETS_UPDATE:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
            };

        case ActionTypesAsset.GET_PRICE:
            if (action.payload.data) {
                const currentAssetId = action.payload.data._id;

                state.data.map((element: AssetInterfaceWithTranslatedDescriptions) => {
                    if (element._id === currentAssetId) {
                        const newElement = { ...element };
                        newElement.currentValue = action.payload.data.currentValue;
                        return newElement;
                    }
                });
            }
            return {
                ...state,
                loading: false,
            };

        case ActionTypesAsset.GET_PREDICTED_ASSET_PRICE_START: {
            const { isFromUserAction } = action.payload as { isFromUserAction: boolean };

            return {
                ...state,
                status: { ...state.status, getPredictedPriceStatus: STATUS.PENDING },
                flags: { isFromUserAction },
            };
        }

        case ActionTypesAsset.GET_PREDICTED_ASSET_PRICE_SUCCESS:
            return handleGetPredictedPriceSuccess(state, action);

        case ActionTypesAsset.UPDATE_ASSET_CURRENT_VALUE:
            assetIndexFromStore = state.data.findIndex((asset: AssetInterfaceWithTranslatedDescriptions) => asset._id === action.payload.assetId);
            if (assetIndexFromStore === -1)
                return state;
            const stateAsset = state.data[assetIndexFromStore];
            if (stateAsset.currentValue === action.payload.assetCurrentValue)
                return state;
            state.data[assetIndexFromStore] = {
                ...state.data[assetIndexFromStore],
                currentValue: action.payload.assetCurrentValue,
            };
            return {
                ...state,
                data: state.data,
                loading: false,
            };
        case ActionTypesAsset.DELETE_ASSET:
            return {
                ...state,
                data: state.data.filter((asset: AssetInterfaceWithTranslatedDescriptions) => asset._id !== action.payload.assetId),
                loading: false,
            };
        case ActionTypesAsset.UPDATE_ASSET_KEYS:
            assetIndexFromStore = state.data.findIndex((asset: AssetInterfaceWithTranslatedDescriptions) => asset._id === action.payload.assetId);

            if (assetIndexFromStore !== -1) {
                state.data[assetIndexFromStore] = {
                    ...state.data[assetIndexFromStore],
                    ...action.payload.data,
                };
            }

            return {
                ...state,
            };
        case ActionTypesAsset.ASSETS_MARKET_CONFIGURATION_SUCCESS:
            return handleMarketConfiguration(state, action);
        case ActionTypesAsset.GET_TOP_ASSETS_CURRENT_VALUES_SUCCESS:
            return handleTopAssetsCurrentValuesUpdate(state, action);
        case ActionTypesAsset.GET_TOP_ASSETS_SCORES_SUCCESS:
            return handleTopAssetsScoresUpdate(state, action);
        case ActionTypesAsset.INITIAL_SALE_DONE:
            return handleAssetInitialSaleDone(state, action);
        case ActionTypesAsset.ASSET_UPDATE:
            return handleAssetUpdate(state, action);
        case ActionTypesAsset.GET_TRANSLATED_DESCRIPTION_SUCCESS:
            return handleAssetDescriptionUpdate(state, action);
        default:
            return state;
    }
};

const handleAssetDescriptionUpdate = (state: ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]>, action: ReduxActionInterface): ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]> => {
    const assetIndex: number = state.data.findIndex((asset: AssetInterfaceWithTranslatedDescriptions) => asset._id === action.payload.assetId);
    if (assetIndex === -1)
        return state;
    let assetAtIndex: AssetInterfaceWithTranslatedDescriptions = state.data[assetIndex];
    if (!assetAtIndex.descriptions || assetAtIndex.descriptions.length === 0) {
        assetAtIndex.descriptions = [];
        assetAtIndex.descriptions.push(action.payload.description);
    } else {
        const indexOfDescription = assetAtIndex.descriptions.findIndex(obj => obj.lng === action.payload.description.lng);
        if (indexOfDescription > -1)
            assetAtIndex.descriptions[indexOfDescription] = action.payload.description;
        else
            assetAtIndex.descriptions.push(action.payload.description);
    }
    state.data[assetIndex] = assetAtIndex;
    return { ...state, loading: false };
}

const handleAssetUpdate = (state: ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]>, action: ReduxActionInterface): ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]> => {
    const updatedAsset: AssetInterfaceWithTranslatedDescriptions = action.payload.asset;
    const index: number = state.data.findIndex((asset: AssetInterfaceWithTranslatedDescriptions) => asset._id === updatedAsset._id);
    if (index === -1)
        return state;
    const assetAtIndex: AssetInterfaceWithTranslatedDescriptions = state.data[index];
    state.data[index] = {
        ...assetAtIndex,
        ...updatedAsset
    };
    return { ...state };
}

const handleTopAssetsCurrentValuesUpdate = (state: ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]>, action: ReduxActionInterface): ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]> => {
    if (action.payload.data.length === 0)
        return state;
    for (const assetInfo of action.payload.data) {
        const assetIndexFromStore: number = state.data.findIndex((asset: AssetInterfaceWithTranslatedDescriptions) => asset._id === assetInfo._id);
        if (assetIndexFromStore === -1)
            continue;
        const prevAssetState = state.data[assetIndexFromStore];
        state.data[assetIndexFromStore] = {
            ...state.data[assetIndexFromStore],
            'currentValue': assetInfo.currentValue ?? prevAssetState.currentValue,
            'secondaryMarketSupply': assetInfo.secondaryMarketSupply ?? prevAssetState.secondaryMarketSupply,
            'initialSaleDone': assetInfo.initialSaleDone ?? prevAssetState.initialSaleDone
        };
    }
    return {
        ...state,
        data: [...state.data],
    };
}

const handleTopAssetsScoresUpdate = (state: ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]>, action: ReduxActionInterface): ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]> => {
    if (action.payload.data.length === 0)
        return state;
    const tempState = { ...state };
    for (const assetInfo of action.payload.data) {
        const assetIndexFromStore: number = state.data.findIndex((asset: AssetInterfaceWithTranslatedDescriptions) => asset._id === assetInfo._id);
        if (assetIndexFromStore === -1)
            continue;
        const prevAssetState = tempState.data[assetIndexFromStore];
        tempState.data[assetIndexFromStore] = {
            ...tempState.data[assetIndexFromStore],
            'IRLScore': assetInfo.IRLScore ?? prevAssetState.IRLScore,
        };
    }
    return {
        ...state,
        data: tempState.data,
    };
}

const handleAssetInitialSaleDone = (state: ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]>, action: ReduxActionInterface): ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]> => {
    const newAssetData = state.data.map((asset) => {
        if (asset._id === action.payload.assetId) {
            return { ...asset, initialSaleDone: true, initialSaleSoldOutDate: action.payload.initialSaleSoldOutDate ?? asset.initialSaleSoldOutDate }
        }
        return asset;
    });

    return {
        ...state,
        data: newAssetData
    }
}

export const futureAssetsReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface): ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]> => {
    switch (action.type) {
        case ActionTypesAsset.FUTURE_ASSETS_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ActionTypesAsset.FUTURE_ASSETS_SUCCESS:
            const Data = state.data.length > 0 ? Utils.removedDuplicateMongoFile(state.data, action.payload.data) : [];
            return {
                ...state,
                data: Data.concat(action.payload.data),
                loading: false,
            };

        case ActionTypesAsset.FUTURE_ASSETS_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

const handleGetPredictedPriceSuccess = (state: ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]>, action: ReduxActionInterface): ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]> => {
    const assetIndexFromStore: number = state.data.findIndex((asset: AssetInterfaceWithTranslatedDescriptions) => asset._id === action.payload.assetId);
    let newAssetState: any = {};
    let tempStateData: AssetInterfaceWithTranslatedDescriptions[] = [...state.data];
    if (assetIndexFromStore > -1) {
        const assetFromStore: AssetInterfaceWithTranslatedDescriptions = state.data[assetIndexFromStore];
        newAssetState = {
            ...assetFromStore,
        };
        if (assetFromStore.availableSupply !== action.payload.availableSupply)
            newAssetState['availableSupply'] = action.payload.availableSupply;

        if (assetFromStore.initialSaleDone !== action.payload.privateSaleEnded) {
            newAssetState['initialSaleDone'] = action.payload.privateSaleEnded;
            newAssetState['initialSaleRealEndDate'] = action.payload.initialSaleRealEndDate;
            newAssetState['hoursBeforeSecondaryMarketStarts'] = action.payload.hoursBeforeSecondaryMarketStarts;
        }
        if (assetFromStore.feesRate !== action.payload.feesRate) {
            newAssetState["feesRate"] = action.payload.feesRate ?? assetFromStore.feesRate;
        }

        if (assetFromStore.feesFixedAmount !== action.payload.feesFixedAmount) {
            newAssetState["feesFixedAmount"] = action.payload.feesFixedAmount ?? assetFromStore.feesFixedAmount;
        }
        newAssetState["fetchedAt"] = new Date()

        tempStateData.splice(assetIndexFromStore, 1, newAssetState);
    }

    const newState: Partial<AssetInterfaceWithTranslatedDescriptions> = {};
    if (state.price !== parseFloat(action.payload.price))
        newState['price'] = parseFloat(action.payload.price);
    if (state.initialSellTotalPrice !== parseFloat(action.payload.initialSellTotalPrice))
        newState['initialSellTotalPrice'] = parseFloat(action.payload.initialSellTotalPrice);

    if (Object.keys(newState).length > 0 || Object.keys(newAssetState).length > 0)
        return {
            ...state,
            data: tempStateData,
            loading: false,
            ...newState,
            status: {
                ...state.status,
                getPredictedPriceStatus: STATUS.RESOLVED,
            },
            flags: { isFromUserAction: false },
        };

    return state;
};

function handleMarketConfiguration(state: ReduxRootState, action: ReduxActionInterface): ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]> {
    const defaultState = {
        ...state,
        data: state.data,
        loading: false,
        error: undefined,
    };
    if (!action.payload.marketConfiguration) return defaultState;
    const assetIndexFromStore = state.data.findIndex((asset: AssetInterfaceWithTranslatedDescriptions) => asset._id.toString() === action.payload.assetId);
    if (assetIndexFromStore === -1) return defaultState;
    state.data[assetIndexFromStore] = {
        ...state.data[assetIndexFromStore],
        marketConfiguration: action.payload.marketConfiguration,
    };
    return {
        ...state,
        data: state.data,
        loading: false,
        error: undefined,
    };
}

function handleAssetsSuccess(state: ReduxRootState, action: ReduxActionInterface): ReduxRootState<AssetInterfaceWithTranslatedDescriptions[]> {
    let stateData = state.data || [];
    const payloadData = action.payload.data || [];
    const excludedFields = ['currentValue', 'secondaryMarketSupply', 'initialSaleDone', 'IRLScore'];
    if (stateData.length === 0 || payloadData.length === 0)
        return {
            ...state,
            data: stateData.concat(payloadData),
            loading: false,
        };
    const updatedData = Utils.combineArrayWithUniqueId(stateData, payloadData);
    const updatedDataWithoutSecondaryMarketInfoAndScore = updatedData.map((asset: AssetInterfaceWithTranslatedDescriptions) => {
        const assetFromStore = stateData.find((assetFromStore: AssetInterfaceWithTranslatedDescriptions) => assetFromStore._id === asset._id);
        if (!assetFromStore)
            return asset;
        excludedFields.forEach((field: string) => {
            asset[field] = assetFromStore[field];
        });
        return asset;
    });
    return {
        ...state,
        data: updatedDataWithoutSecondaryMarketInfoAndScore,
        loading: false,
    };
}

