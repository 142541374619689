import Application from 'Application';
import { MINIMUM_REFUND_AMOUNT } from 'config/_const';
import useAccount from 'hooks/use-account/useAccount';
import React from 'react';
import WithdrawForm from '../form/withdraw-form/WithdrawForm';
import './WithdrawModal.scss';
import { Trans } from 'react-i18next';

const WithdrawModal: React.FC = () => {
    const { accountViewModel } = useAccount();
    return (
        <div className="withdraw-container">
            <div className="title">
                <Trans i18nKey='Myportfolio.withdraw' />
            </div>
            <WithdrawForm
                initialValues={{
                    amount: accountViewModel.IsOnboardingRunning && Application.getInstance().OnBoardingV2 ? accountViewModel.creditsAsNumber.toCurrencyValue().toString() : MINIMUM_REFUND_AMOUNT.toString(),
                    phone: (accountViewModel.account?.phone || "").replaceAll(" ", ""),
                    country: accountViewModel.account?.country,
                    currency: accountViewModel.Currency,
                }}
            />
        </div >
    );
};

export default React.memo(WithdrawModal);
