import Credits from 'components/icons/Credits'
import Gifts from 'components/icons/Gifts'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { BuyAssetCreditBreakdownProps } from './IBuyAssetCreditBreakdownProps'

const BuyAssetCreditBreakdown = ({ t: translate, buyAssetViewModel, selectedPaymentType }: BuyAssetCreditBreakdownProps) => {
    return (
        <div className='credit-info-wrapper'>
            {
                !buyAssetViewModel.ShowRewardDebt(selectedPaymentType)
                    ? <span>
                        {translate("buyAsset.available")} : {buyAssetViewModel.AvailableFieldFormatted}
                    </span>
                    : <>
                        <div className='info'>
                            <span>
                                {translate("gifts")}: {buyAssetViewModel.AmountToBePaidOnBuyFormatted.amountInGift}
                            </span>
                            <Gifts />
                        </div>
                        <div className='info'>
                            <span>
                                {translate("credits")}: {buyAssetViewModel.AmountToBePaidOnBuyFormatted.amountInCredit}
                            </span>
                            <Credits />
                        </div>
                    </>
            }
        </div>
    )
}

export default withTranslation()(BuyAssetCreditBreakdown)