import i18next from 'i18next';
import UserInterface from 'model/User/UserInterface';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { HTTP_STATUS_OK, VERIFICATION_ACTION, VERIFICATION_STATUS } from '../../config/_const';
import AxiosConfig from '../../config/axiosConfig';
import { USER_ENDPOINTS } from '../../config/endpoints';
import { IHttpResponse, IHttpStrongTypedResponse } from '../../model/IHttpResponse';
import { showNotification } from '../notification/action';
import * as UserActionType from './actionsType';

export const fetchAllUsers = (page: number, filter?: string, search?: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    let queries: string = '';
    if (filter) {
        queries += `filter=${filter}`;
    }

    if (search) {
        queries += `&search=${search}`;
    }

    try {
        const response: IHttpResponse = await AxiosConfig.get(`/users/all/${page}?${queries}`);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, i18next.t(response.data.message), 500);
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }

        return dispatch({
            type: UserActionType.USER_SUCCESS,
            payload: {
                data: response.data.data,
            },
        });

    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const searchUsers = (page: number, search?: string, enablePagination?: boolean): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    let queries: string = '';

    if (search) {
        queries += `search=${search}`;
    }

    try {

        const response: IHttpResponse = await AxiosConfig.get(`/users/all/${page}?${queries}`);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, i18next.t(response.data.message), 500);
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }

        return dispatch({
            type: UserActionType.USER_SEARCH,
            payload: {
                data: {
                    enablePagination,
                    users: response.data.data,
                },
            },
        });

    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const manageUsers = (status: boolean, userId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    try {

        const response: IHttpResponse = await AxiosConfig.post('/users/manageUser', { userId, status });
        showNotification(dispatch, response.data.message, response.data.status);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }

        return dispatch({
            type: UserActionType.USER_SUCCESS,
            payload: {
                data: [response.data.data],
                message: response.data.message,
            },
        });

    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const getUserDetailsById = (userId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({ type: UserActionType.USER_LOADING });

    try {

        const response: IHttpResponse = await AxiosConfig.get(`/users/${userId}`);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {

            showNotification(dispatch, response.data.message, response.data.status);

            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }

        return dispatch({
            type: UserActionType.USER_SUCCESS,
            payload: {
                data: [response.data.data],
            },
        });

    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const actionCreateInfluencerAccount = (body: Object): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    try {

        const response: IHttpResponse = await AxiosConfig.post('/users/influencer', body);

        showNotification(dispatch, response.data.message, response.data.status);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }

        return dispatch({
            type: UserActionType.USER_SUCCESS,
            payload: {
                data: [response.data.data],
            },
        });

    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const actionUpdateUserAsAdmin = (body: Object, userId: string): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {

    try {

        const response: IHttpResponse = await AxiosConfig.post('/users/updateAsAdmin', { userId, data: body });
        showNotification(dispatch, response.data.message, response.data.status);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }

        return dispatch({
            type: UserActionType.USER_SUCCESS,
            payload: {
                data: [response.data.data],
            },
        });

    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const increaseUserCreditsAsAdmin = (body: { credits: number, virtualCredits: number, userId: string }): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {
    try {

        const response: IHttpStrongTypedResponse<UserInterface> = await AxiosConfig.post('/users/creditUser', body);
        showNotification(dispatch, response.data.message, response.data.status);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }

        return dispatch({
            type: UserActionType.USER_SUCCESS,
            payload: {
                data: [response.data.data],
            },
        });

    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const sendCardToUserAsAdmin = (body: { amount: number, assetId: string, transactionSource: string, userId: string }): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<UserInterface> = await AxiosConfig.post(USER_ENDPOINTS.SEND_CARD_TO_USER, body);
        showNotification(dispatch, response.data.message, response.data.status);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }
        return dispatch({
            type: UserActionType.USER_SUCCESS,
        });

    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};


export const adminValidationUserAccount = (isConfirmed: string, userId: string, email: string, identityRefusalReasons?: string): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {
    dispatch({ type: UserActionType.USER_LOADING });

    try {
        const confirmationParam = isConfirmed === VERIFICATION_STATUS.CONFIRMED ? VERIFICATION_ACTION.VALIDATE : VERIFICATION_ACTION.REFUSE;
        const response = await AxiosConfig.post(`/users/validateAccount/${confirmationParam}`, {
            userId,
            email,
            data: {
                isConfirmed,
                identityRefusalReasons,
            },
        });

        showNotification(dispatch, response.data.message, response.data.status);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }

        return dispatch({
            type: UserActionType.USER_SUCCESS,
            payload: {
                data: [response.data.user],
            },
        });
    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const deleteUser = (userId: string): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {

    try {

        const response: IHttpResponse = await AxiosConfig.post('/users/delete', { userId });
        showNotification(dispatch, response.data.message, response.data.status);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }

        return dispatch({
            type: UserActionType.USER_DELETED,
            payload: {
                data: response.data.data,
            },
        });
    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const disableUser = (userId: string): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {
    try {
        const response: IHttpResponse = await AxiosConfig.post(USER_ENDPOINTS.DISABLE_USER_BASE_URL, { userId });
        showNotification(dispatch, response.data.message, response.data.status);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }
        return dispatch({
            type: UserActionType.USER_DISABLED,
            payload: {
                data: response.data.data,
            },
        });
    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const reactivateUser = (userId: string): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {
    try {
        const response: IHttpResponse = await AxiosConfig.post(USER_ENDPOINTS.REACTIVATE_USER_BASE_URL, { userId });
        showNotification(dispatch, response.data.message, response.data.status);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }
        return dispatch({
            type: UserActionType.USER_REACTIVATED,
            payload: {
                data: response.data.data,
            },
        });
    } catch (error) {
        showNotification(dispatch, i18next.t('contactus.snack.error'), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};

export const getSignedImage = (rectoImg?: string, versoImg?: string, fallback: boolean = false): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {
    dispatch({ type: UserActionType.USER_LOADING });

    const getImageName = (url: string) => {
        const splittedUrl = url.split('.amazonaws.com/');
        return splittedUrl[splittedUrl.length - 1];
    };

    try {
        const payload: any = {};
        if (rectoImg) {
            const getRectoImgResponse = await AxiosConfig.get(`/users/getSignedImage/${getImageName(rectoImg)}?fallback=${fallback}`);
            if (getRectoImgResponse.status !== HTTP_STATUS_OK)
                return dispatch({
                    type: UserActionType.USER_ERROR,
                });
            payload['rectoImg'] = getRectoImgResponse.data.image;
        }
        if (versoImg) {
            const getVersoImgResponse = await AxiosConfig.get(`/users/getSignedImage/${getImageName(versoImg)}?fallback=${fallback}`);
            if (getVersoImgResponse.status !== HTTP_STATUS_OK)
                return dispatch({
                    type: UserActionType.USER_ERROR,
                });
            payload['versoImg'] = getVersoImgResponse.data.image;
        }

        return dispatch({
            type: UserActionType.ACTUAL_USER_SUCCESS,
            payload,
        });

    } catch (error) {
        console.log('err signed url image error', error);
        return ({
            status: 500,
            error: error.stack,
        });
    }
};

export const getUsersStats = (): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {
    try {
        const response: IHttpResponse = await AxiosConfig.get('/users/admin/stats');
        showNotification(dispatch, response.data.message, response.data.status);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: UserActionType.USER_ERROR,
            });
        }
        return dispatch({
            type: UserActionType.USERS_STATS,
            payload: response.data.data,
        });
    } catch (error) {
        showNotification(dispatch, i18next.t(''), 500);
        return dispatch({ type: UserActionType.USER_ERROR });
    }
};
