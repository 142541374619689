import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import Application from 'Application';
import clsx from 'clsx';
import LocalLogin from 'components/medium/form/authentification/local-login/LocalLogin';
import ConditionalWrapper from 'components/small/conditionnal-wrapper/ConditionalWrapper';
import Divider from 'components/small/divider/Divider';
import HeaderRegister from 'components/small/header-register/HeaderRegister';
import LogoBlueTrendex from 'components/small/logo-blue-trendex/LogoBlueTrendex';
import SocialSignup from 'components/small/sign-up/SignUp';
import SignUpStep from 'components/small/sign-up/SignUpStep';
import StatPanel from 'components/small/stat-panel/StatPanel';
import { loginImage } from 'data/SignUpInImages/authentificationImages';
import { trendexLogoImage } from 'data/generalImages/generalImages.data';
import useConnect from 'hooks/connect/useConnect';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import BlogArticles from '../blog-articles/BlogArticles';
import PressArticles from '../press-articles/PressArticles';
import './Connect.scss';
import ConnectProps from './ConnectProps';
import SignupCountdown from 'components/small/signup-countdown/SignupCountdown';
import EuroCountdown from 'components/small/euro-countdown/EuroCountdown';

const Connect: React.FC<ConnectProps> = (props) => {
    const { connectViewModel, setMode, isModal, isNotModal, setIsVideoPlay } = useConnect({ forceSignup: props.forceSignup });
    return (
        <ConditionalWrapper
            condition={!(isModal)}
            wrapper={children => <div className="auth_page">{children}</div>}
        >
            <div className='auth-section'>
                {isNotModal && !Application.getInstance().OnBoardingV2 &&
                    <div className='back-chevron'>
                        <ChevronLeftRoundedIcon onClick={() => props.history.goBack()} fontSize='large' />
                    </div>
                }
                {connectViewModel.IsRegister &&
                    <HeaderRegister
                        videoId={connectViewModel.VideoId}
                        bannerImageVideo={connectViewModel.BannerImageVideo}
                        setIsVideoPlay={setIsVideoPlay}
                        isVideoPlay={connectViewModel.isVideoPlay}
                        title={connectViewModel.TitleRegister}
                        subtitle={connectViewModel.SubTitle4Shares}
                    />
                }
                <div className={clsx('auth_structure', { 'is-modal': isModal })}>
                    {props.explainationMessage &&
                        <div className="message"><p>{props.explainationMessage} !</p></div>
                    }
                    {connectViewModel.IsRegister && Application.getInstance().OnBoardingV2 &&
                        <SignupCountdown />
                    }
                    {connectViewModel.IsRegister && Application.getInstance().IsFootballEuroMode() &&
                        <div className='euro-countdown-container'>
                            <EuroCountdown />
                        </div>
                    }
                    {!props.explainationMessage && connectViewModel.IsRegister &&
                        <div className='steps-container-fullwidth'>
                            <div className='steps-container'>
                                <div className="container-social-buttons">
                                    <SocialSignup
                                        mode={connectViewModel.Mode}
                                        intentPurchase={props.intentPurchase}
                                        onSocialConnectSuccessCallback={props.onSocialConnectSuccessCallback}
                                        redirectTo={props.socialLoginRedirectUrl}
                                        onBeforeSocialLogin={props.onBeforeSocialLogin} />
                                </div>
                                <div className={clsx('separate_or', {
                                    'light-separate': !isModal,
                                })}>
                                    <span>
                                        <Trans i18nKey={'register.or'} />
                                    </span>
                                </div>
                                <div className="auth-form">
                                    <div className='steps-container'>
                                        <div className="register-container">
                                            {connectViewModel.ShowReCaptcha
                                                ?
                                                <GoogleReCaptchaProvider reCaptchaKey={Application.getInstance().ReCaptchaKey} >
                                                    <LocalLogin connectionViewModel={connectViewModel} />
                                                </GoogleReCaptchaProvider>
                                                :
                                                <LocalLogin connectionViewModel={connectViewModel} />
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="no-account">
                                    {connectViewModel.NoAccountTitle}
                                    <span className='no-account-span' onClick={() => setMode(connectViewModel.OtherMode)}>
                                        {connectViewModel.NoAccountText}
                                    </span>
                                </div>
                                <div className='width-signup-form'>
                                    {connectViewModel.RegisterStep.map((step, index) => {
                                        return (
                                            <div className='signup-step'>
                                                <div key={step.key}>
                                                    <SignUpStep stepImage={step.image} stepId={index + 1} stepTitle={step.key} currency={Application.getInstance().currencySymbol} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {Application.getInstance().OnBoardingV2 &&
                                    <SignupCountdown />
                                }
                            </div>
                        </div>
                    }
                    {!props.explainationMessage && connectViewModel.IsLogin &&
                        <div className="message login-top-section">
                            <img className='img-login' src={loginImage} alt="register" />
                            <h2><Trans i18nKey={'login-title'} /></h2>
                            <p><Trans i18nKey={'login-explainer-default'} /></p>
                        </div>
                    }
                    {connectViewModel.IsRegister &&
                        <div className="second-container-logo-blue">
                            <LogoBlueTrendex title={connectViewModel.TitleRegister} />
                        </div>
                    }
                    <div className="container-social-buttons">
                        <SocialSignup
                            mode={connectViewModel.Mode}
                            intentPurchase={props.intentPurchase}
                            onSocialConnectSuccessCallback={props.onSocialConnectSuccessCallback}
                            redirectTo={props.socialLoginRedirectUrl}
                            onBeforeSocialLogin={props.onBeforeSocialLogin} />
                    </div>
                    <div className={clsx('separate_or', {
                        'light-separate': !isModal,
                    })}>
                        <span>
                            <Trans i18nKey={'register.or'} />
                        </span>
                    </div>
                    <div className="auth-form width-signup-form">
                        <div className='steps-container'>
                            <div className="register-container">
                                {connectViewModel.ShowReCaptcha
                                    ?
                                    <GoogleReCaptchaProvider reCaptchaKey={Application.getInstance().ReCaptchaKey} >
                                        <LocalLogin connectionViewModel={connectViewModel} />
                                    </GoogleReCaptchaProvider>
                                    :
                                    <LocalLogin connectionViewModel={connectViewModel} />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="no-account">
                        {connectViewModel.NoAccountTitle}
                        <span className='no-account-span' onClick={() => setMode(connectViewModel.OtherMode)}>
                            {connectViewModel.NoAccountText}
                        </span>
                    </div>

                    {connectViewModel.IsRegister &&
                        <>
                            <BlogArticles language={connectViewModel.CurrentLanguage} />
                            <Divider background='#15182B26' size='80%' />
                            <PressArticles language={connectViewModel.CurrentLanguage} />
                            <div className='trendex-footer'>
                                <div className='trendex-its-already'>
                                    <img src={trendexLogoImage} alt='trendex_logo' />
                                    <span className='trendex-its-already'>{connectViewModel.StatAlready}</span>
                                </div>
                                <div className='stat-list'>
                                    <StatPanel statList={connectViewModel.StatListTrendex} />
                                </div>
                                <div className='partners-logo'>
                                    {Application.getInstance().PartnerList.map((partner) => {
                                        return (
                                            <div key={partner.name} className='partner-logo'>
                                                <img src={partner.img} alt={partner.name} className={partner.name} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    }
                </div>

            </div>
        </ConditionalWrapper >
    );
};


export default withRouter(React.memo(Connect));
