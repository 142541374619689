import { KeyboardArrowLeft } from '@material-ui/icons';
import clsx from 'clsx';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import useWalletRefund from 'hooks/use-wallet-refund/useWalletRefund';
import { useDevice } from 'hooks/useDevice';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import IBuyAssetWrapperProps from './IAssetTransactionWrapperProps.ts';
import "./asset-transaction-wrapper-dark.scss";
import "./asset-transaction-wrapper.scss";

const AssetTransactionWrapper: React.FC<IBuyAssetWrapperProps> = ({ onCancel, onConfirm, confirmText, isExiting, hideContinueButton, children, disableContinueButton, prePrivateSaleMessage, isTransactionTypeBuy, isTransactionTypeSell, isInjectedAsIframe, hideBackArrow }) => {
    const timeForEntering: number = 300;
    const [isEntering, setEntering] = useState<boolean>(true);
    const device = useDevice();
    const { handleWalletRefundClick } = useWalletRefund();

    useEffect(() => {
        window.scrollTo({ top: 0 });
        const enteringTimeout = setTimeout(() => {
            setEntering(false);
        }, timeForEntering);

        return () => clearTimeout(enteringTimeout);
    }, []);

    return (<div className={clsx(`buy-asset-slider`, {
        "slide-in": isEntering,
        "slide-out": isExiting,
        "stable": !(isExiting || isEntering),
        "is-iframe-landing": isInjectedAsIframe,
        "buy-asset-dark": isInjectedAsIframe
    })}>
        {!isInjectedAsIframe && !hideBackArrow && <div className="arrow-back" onClick={onCancel}>
            <KeyboardArrowLeft />
        </div>}
        <div className={clsx("buy-asset-wrapper",
            { "buy-asset-wrapper_ios": device?.operatingSystem === "ios" },
        )}
        >
            {children}

            <div className="container-button-continue">
                {!hideContinueButton && <PrimaryButton
                    type="button"
                    disabled={disableContinueButton}
                    textButton={confirmText}
                    receiveClickEvent={false}
                    handleClick={onConfirm}
                    className={clsx("buy-asset-continue-button",
                        { 'green-btn': isTransactionTypeBuy },
                        { 'red-btn': isTransactionTypeSell })}
                />
                }
                {prePrivateSaleMessage && (
                    <div className="prePrivateSale-warning">
                        {i18next.t(prePrivateSaleMessage)}
                        {prePrivateSaleMessage === "prePrivateSale.warning.credit-card" && (
                            <span onClick={handleWalletRefundClick} style={{ textDecoration: 'underline', textDecorationColor: '#ed213a' }}>
                                {i18next.t('click-here')}
                            </span>
                        )}
                    </div>
                )}
            </div>
        </div>
    </div>);
};

export default AssetTransactionWrapper;