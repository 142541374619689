import { OrderBy } from 'config/_enums';
import { isEmpty } from 'lodash';
import PredicateSortBuilder from 'model/Helper/PredicateSortBuilder';
import type { RootState } from 'model/Redux';
import ITournamentEvolution, { IEvolution } from 'model/Tournament/ITournamentEvolution';
import { createSelector, Selector } from 'reselect';
import { allAssetsSelector } from 'service/assets/selectors';
import type { Status } from 'service/storeType';
import { selectTournament } from 'service/tournament/selectors';
import type { Collection } from 'utils/types';
import Utils from 'utils/Utils';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import TournamentTopVIPViewModel from 'view-model/Tournament/TournamentTopVIPViewModel/TournamentTopVIPViewModel';
import TournamentViewModel from 'view-model/Tournament/TournamentViewModel/TournamentViewModel';
import { getAssetsEvolutionsSnapshotsFromDate } from './filter';
import { calculAssetScoreEvolution, extractAssetsEvolution } from './helper';
import type { IGetAssetsWithEvolutionByTournamentIdParamsSelector, ScoreHistoryDataAttribute, ScoreHistoryReducer } from './types';

const selectScoreHistory: Selector<RootState, ScoreHistoryReducer> = state => state.scoreHistory;
const selectScoreHistoryData: Selector<RootState, ScoreHistoryDataAttribute> = state => state.scoreHistory.data;

export const selectEvolutionsCollection = createSelector<RootState, ScoreHistoryDataAttribute, Collection<ITournamentEvolution>>(
    selectScoreHistoryData,
    (evolutions) => {
        if (evolutions === null)
            return {};
        return Utils.normalize(evolutions, 'assetId');
    });

export const selectGetEvolutionsStatus = createSelector<RootState, ScoreHistoryReducer, Status>(
    selectScoreHistory,
    state => state.status!.getEvolutions);

export const selectHasEvolutions = createSelector<RootState, ScoreHistoryReducer, boolean>(
    selectScoreHistory,
    state => state.data !== null && !isEmpty(state.data));

export const getAssetWithHighestEvolutionByTournamentSelector = createSelector(
    selectScoreHistoryData,
    (state: RootState, tournamentId: string) => selectTournament(state, tournamentId),
    allAssetsSelector,
    (allEvolutions: ScoreHistoryDataAttribute, tournament: TournamentViewModel | null, allAssets: AssetViewModel[]) => {
        if (!tournament || isEmpty(allEvolutions) || isEmpty(allAssets))
            return null;
        const evolutionsGroupedByAssetId: Collection<IEvolution[]> = {};
        allAssets.forEach((asset: AssetViewModel) => {
            evolutionsGroupedByAssetId[asset.AssetId] = getAssetsEvolutionsSnapshotsFromDate(asset.AssetId, allEvolutions, tournament.StartDate, tournament.EndDate);
        });
        if (isEmpty(evolutionsGroupedByAssetId))
            return null;

        const evolutionsSortedByHighestEvolution = Object.entries(evolutionsGroupedByAssetId)
            .map((evol) => {
                const assetId: string = evol[0];
                const assetSnapshotEvolutions: IEvolution[] = evol[1];
                const asset: AssetViewModel | undefined = allAssets.find((asset: AssetViewModel) => asset.AssetId === assetId);
                if (assetSnapshotEvolutions.length === 0 || !asset)
                    return {
                        assetId,
                        scoreEvolution: 0,
                    };
                return {
                    assetId,
                    scoreEvolution: calculAssetScoreEvolution(asset, assetSnapshotEvolutions),
                };
            })
            .sort((a, b) => new PredicateSortBuilder({ sortKey: 'scoreEvolution', sortSide: OrderBy.DESC }).build(a, b));
        const assetWithHighestEvolution: AssetViewModel | undefined = allAssets.find((asset: AssetViewModel) => asset.AssetId === evolutionsSortedByHighestEvolution[0].assetId);
        if (!assetWithHighestEvolution)
            return null;
        return new TournamentTopVIPViewModel({
            _id: assetWithHighestEvolution.AssetId,
            icon: assetWithHighestEvolution.AvatarPath,
            name: assetWithHighestEvolution.AssetName,
        });
    },
);

export const getAssetsWithEvolution = createSelector(
    selectEvolutionsCollection,
    allAssetsSelector,
    (_: RootState, params: IGetAssetsWithEvolutionByTournamentIdParamsSelector) => ({ ...params }),
    (evolutionCollection: Collection<ITournamentEvolution>, allAssets: AssetViewModel[], params: IGetAssetsWithEvolutionByTournamentIdParamsSelector) => {
        return extractAssetsEvolution(evolutionCollection, allAssets, params.assetIds);
    },
);
