import i18n from '18n';
import React from 'react';
import ITournamentInformationLeaguePopupProps from './ITournamentInformationLeaguePopupProps';
import './TournamentInformationLeaguePopup.scss';
import Divider from '../divider/Divider';
const TournamentInformationLeaguePopup: React.FC<ITournamentInformationLeaguePopupProps> = (props) => {
    return (
        <div className='tournament-information-league-popup'>
            <div className='informations'>
                <div className="content">
                    <div className='row'>
                        <div className="text"><b><span>{i18n.t("tournament.information-league.popup.cashprize")}</span></b></div>
                        <div className="end"><span>{props.tournamentInformationLeagueVM.getTotalCashprizeByLeague().toCurrency()}</span></div>
                    </div>
                    <Divider size='90%' />
                    <div className='row'>
                        <div className="text"><b><span>{i18n.t("tournament.information-league.popup.average")}</span></b></div>
                        <div className="end"><span>{props.tournamentInformationLeagueVM.getAveragePlayerCashprizeByLeague().toCurrency()}</span></div>
                    </div>
                </div>
            </div>
        </div>);
};



export default TournamentInformationLeaguePopup;
