import clsx from 'clsx';
import AssetTokenCard from 'components/small/asset-token/asset-token-card/AssetTokenCard';
import ScoreLabel from 'components/small/score-label/ScoreLabel';
import useAssetSlideView from 'hooks/asset/use-asset-slide-view/useAssetSlideView';
import { ICategoryTypeName } from 'interfaces/IAssetBgImages';
import AssetInterface from 'model/AssetModel/AssetInterface';
import React from 'react';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import AvatarViewModel from 'view-model/AvatarViewModel/AvatarViewModel';
import Color from 'view-model/Shared/Color';
import "./AssetsGridBox.scss";
import AssetsGridBoxProps from './AssetsGridBoxProps';
import i18n from '18n';

const AssetsGridBox: React.FC<AssetsGridBoxProps> = ({ asset, categories, renderIconOrLoaderOrTimer, canShowTokenAvailableCard }) => {
    const now = new Date().getTime();
    const initialSaleDateInTime = new Date(asset.initialSaleDate).getTime();
    const hasInitialSupply = asset.initialSupply;
    const { onAssetProfileClick } = useAssetSlideView();
    const assetBackgroundViewModel: AvatarViewModel = React.useMemo(() => new AvatarViewModel({ assetCategories: categories, asset: { avatarPath: asset.icon, mainCategoryId: asset.mainCategory, cardLevel: asset.cardLevel } }), [asset.cardLevel, asset.icon, asset.mainCategory, categories]);
    const isAssetInMarket: boolean = (hasInitialSupply > 0 && initialSaleDateInTime < now);
    const canDisplayTokenCard = canShowTokenAvailableCard && (isAssetInMarket && asset.availableSupply !== undefined);
    const assetViewModel = new AssetViewModel(asset as AssetInterface);

    const handleBoxClick = () => {
        onAssetProfileClick(assetViewModel);
        return;
    };

    const getAssetAvailableToken = React.useMemo<number>(() => {
        if (asset.initialSaleDone) return Math.max((asset.secondaryMarketSupply ?? 0), 0);
        return Math.max(asset.availableSupply ?? 0, 0);
    }, [asset.initialSaleDone, asset.secondaryMarketSupply, asset.availableSupply]);

    return (
        <React.Fragment >
            <div key={asset._id} className="asset-grid-box cursor-pointer" onClick={() => handleBoxClick()}>
                <div className='grid-wrapper' style={{ background: assetBackgroundViewModel.Color }}>
                    <img src={assetBackgroundViewModel.BackgroundImage} className="grid-asset-bg-img" alt={asset.icon + "-bg"} loading="lazy" />
                    <img src={asset.icon} className="grid-asset-avatar-img" alt={asset.icon} loading="lazy" />
                    <div className='overlay-filter'>
                        {canDisplayTokenCard &&
                            <div className='token-content'>
                                <AssetTokenCard availableToken={getAssetAvailableToken} />
                            </div>
                        }
                        <div className='asset-score-container'>
                            {assetViewModel.ShowTrendScore && <ScoreLabel
                                score={assetViewModel.getTrendScoreToDisplay(2)}
                                backgroundColor={Color.getColorByInputRatio(assetViewModel.IRLScore)}
                                canShowBoost={assetViewModel.ScoreBoosted}
                                tooltipText={assetViewModel.getScoreTooltipText(i18n.t('tooltip.trend.score'))}
                            />}
                            {assetViewModel.canShowSportScore(categories) &&
                                <ScoreLabel
                                    score={assetViewModel.getSportScoreToDisplay(2)}
                                    category={assetViewModel.getCategoryName(categories) as ICategoryTypeName}
                                    backgroundColor={Color.getColorByInputRatio(assetViewModel.SportScore || 0)}
                                    canShowBoost={assetViewModel.ScoreBoosted}
                                    tooltipText={assetViewModel.getScoreTooltipText(i18n.t('tooltip.sport.score'))}
                                />
                            }
                        </div>

                        <div className='grid-name'>
                            <span>{asset.name}</span>
                        </div>
                        {isAssetInMarket ?
                            <>
                                <div className='grid-currentValue'>{(asset.currentValue).toCurrency()}<span className='small-text_shareLabel'></span></div>
                                <div className={clsx('grid-totalPriceChange', {
                                    'greenTxt': assetViewModel.PriceEvolutions.isEvolutionPositive,
                                    'redTxt': !assetViewModel.PriceEvolutions.isEvolutionPositive,
                                })}>
                                    {assetViewModel.PriceEvolutions.evolutionPercentageWithIndicator}
                                </div>
                            </> :
                            <div className="asset-icon-loader-or-timer-wrapper">
                                {renderIconOrLoaderOrTimer?.(asset)}
                            </div>}
                    </div>

                </div>
            </div>
        </React.Fragment >
    );
};

export default React.memo(AssetsGridBox);
