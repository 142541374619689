
import React from 'react';
import { Checkbox, makeStyles, CheckboxProps } from '@material-ui/core';

interface TrendexCheckboxProps {
    black?: boolean;
    checkboxColor?: string;
}

type ComposedCheckboxProps = TrendexCheckboxProps & CheckboxProps;

export const TrendexCheckbox = (props: ComposedCheckboxProps) => {
    const useStyles = makeStyles({
        root: {
            color: props.checkboxColor ?? 'white',
        },
    });

    const classes = useStyles();

    return (
        <Checkbox
            className={props.black === true ? 'checkbox' : classes.root}
            value={props.value}
            color="default"
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
};
export const RedCheckbox = (props: CheckboxProps) => {
    const useStyles = makeStyles({
        root: {
            color: '#f14648',
        },
    });

    const classes = useStyles();

    return (
        <Checkbox
            className={classes.root}
            value={props.value}
            color="default"
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
};
