import React from 'react';
import IAssetAvatarProps from './IAssetAvatarProps';
import clsx from "clsx";
import "./asset-avatar.scss";
import useAvatar from 'hooks/use-avatar/useAvatar';

const AssetAvatar: React.FC<IAssetAvatarProps> = (props) => {
    const avatarViewModel = useAvatar(props.asset);
    return <div className={clsx("asset-avatar-container", props.className)}>
        {avatarViewModel.BackgroundImage && <img src={avatarViewModel.BackgroundImage} className="asset-bg-img" alt={"profile-avatar-bg"} loading="lazy" />}
        <img src={avatarViewModel.AvatarImage} className="asset-avatar-img" alt={"asset-avatar"} loading="lazy" />
    </div>
}

export default AssetAvatar;
