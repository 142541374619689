import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'model/Redux';
import { getEvolutions } from 'service/score-history/actions';
import { getAssetsWithEvolution } from 'service/score-history/selectors';
import AssetTournamentViewModel from 'view-model/Assets/AssetTournamentViewModel';
import React from 'react';
import IUseAssetsScoreEvolution from './IUseAssetsScoreEvolution';
import IUseAssetsScoreEvolutionParams from './IUseAssetsScoreEvolutionParams';

const useAssetsScoreEvolution = (params: IUseAssetsScoreEvolutionParams): IUseAssetsScoreEvolution => {
    const dispatch = useDispatch();
    const memoParams = React.useMemo(() => params, [params]);
    const assetsWithEvolution: AssetTournamentViewModel[] = useSelector((state: RootState) => getAssetsWithEvolution(state, { assetIds: memoParams.assetsIds }));

    React.useEffect(() => {
        if (memoParams.assetsIds.length === 0)
            return;
        dispatch(getEvolutions({
            assetsIds: params.assetsIds,
            startDate: params.startDate,
            endDate: params.endDate ?? new Date().toISOString(),
            onlyOncePerDay: params.onlyOncePerDay,
        }));
    }, [memoParams.assetsIds.length]);

    return {
        assetsWithEvolution,
    };
};

export default useAssetsScoreEvolution;
