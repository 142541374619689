import IFacebookPixelPurchaseData from "./IFacebookPixelPurchaseData";
import IPixelHandler from "../pixel/IPixelHandler";
import ReactPixel from 'react-facebook-pixel';
import Application from "Application";
import IMarketPixelHandler from "service/pixel/IMarketPixelHandler";

export default class PixelHandlerBuyOnPrimaryMarket implements IPixelHandler, IMarketPixelHandler {
    private trackOnStandardEvent: boolean;
    private trackOnTheFirstPayment: boolean;
    private _purchaseData: IFacebookPixelPurchaseData;
    private static customFacebookEventName: string = "Purchase_PrimaryMarket";
    private static standardFacebookEventName: string = "Purchase";
    private static isFirstPayment: string = 'First Purchase';

    constructor(trackOnStandardEvent: boolean, trackOnTheFirstPayment: boolean = false) {
        this._purchaseData = {
            currency: Application.getInstance().currency,
            value: 0,
            quantity: 0,
            assetName: 'FAKE',
            userEmail: 'FAKE',
            userId: 'FAKE'
        }
        this.trackOnTheFirstPayment = trackOnTheFirstPayment;
        this.trackOnStandardEvent = trackOnStandardEvent;
    }
    setPurchaseData(data: IFacebookPixelPurchaseData): void {
        this._purchaseData = data;
    }

    public get purchaseData() {
        return this._purchaseData;
    }

    public set purchaseData(data: IFacebookPixelPurchaseData) {
        this._purchaseData = data;
    }

    handle(): void {
        ReactPixel.trackCustom(PixelHandlerBuyOnPrimaryMarket.customFacebookEventName, this._purchaseData);
        if (this.trackOnStandardEvent)
            ReactPixel.track(PixelHandlerBuyOnPrimaryMarket.standardFacebookEventName, this._purchaseData);
        if(this.trackOnTheFirstPayment)
            ReactPixel.track(PixelHandlerBuyOnPrimaryMarket.isFirstPayment, this._purchaseData);
    }       
}