import React from 'react';
import { withTranslation } from 'react-i18next';
import BuyPacksButtonProps from './BuyPacksButtonProps';
import './style.scss';
import withBuyPacksButton from 'hoc/with-buy-packs-button/withBuyPacksButton';
import PackIcon from '../../icons/packs-icon/PackIcon';

const BuyPacksButton: React.FC<BuyPacksButtonProps> = ({ buyPacksButtonViewModel, onClick, translate }) => {
    return (
        <button className="buy-packs-button" onClick={() => onClick()}>
            <div className="title-container">
                <PackIcon color="#6F4A1E" width={20} height={20} />
                <p className="title">{translate(buyPacksButtonViewModel.TitleTranslation.key)}</p>
            </div>
            <p className="subtitle">{translate(buyPacksButtonViewModel.SubtitleTranslation.key, buyPacksButtonViewModel.SubtitleTranslation.values)}</p>
        </button>
    )
};

export default withTranslation()(withBuyPacksButton(BuyPacksButton));
