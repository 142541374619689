import useAccount from 'hooks/use-account/useAccount';
import useAssetTransactionSlides from 'hooks/use-asset-transaction-slideup/useAssetTransactionSlides';
import { useDispatch, useSelector } from 'react-redux';
import { SegmentTracking } from 'service/segment/SegmentTracking';
import { toggleModalState } from 'service/sliding-pane/action';
import { selectAssetModalState } from 'service/sliding-pane/selectors';
import IUseAssetSlideView from './IUseAssetSlideView';
import AssetViewModel from 'view-model/Assets/AssetViewModel';

const useAssetSlideView = (): IUseAssetSlideView => {
    const { closeAssetTransaction } = useAssetTransactionSlides();
    const { accountViewModel } = useAccount();
    const dispatch = useDispatch();
    const { isOpen, slugParam, isSlidingUp, overlaySlug } = useSelector(selectAssetModalState)

    const onAssetProfileClick = ({ AssetId, MainCategoryId, AssetName, AssetSlug, CurrentValueAsNumber }: AssetViewModel, callbackFxn?: () => void) => {
        SegmentTracking.sendProductClickedEvent({
            productId: AssetId,
            category: MainCategoryId,
            name: AssetName,
            price: CurrentValueAsNumber.toCurrencyValue(),
            url: AssetSlug,
            accountViewModel
        });
        if (!isOpen) {
            dispatch(toggleModalState({ isOpen: true, slugParam: AssetSlug, isSlidingUp: true }));
        }
        else if (slugParam) {
            dispatch(toggleModalState({ overlaySlug: AssetSlug, isSlidingUp: true }));
        }
        if (callbackFxn) callbackFxn();
    }

    const onSlideViewOpen = () => {
        if (overlaySlug) {
            dispatch(toggleModalState({ isSlidingUp: false, slugParam: overlaySlug, overlaySlug: '' }))
            return;
        }
        dispatch(toggleModalState({ isSlidingUp: false }))
    }

    const onSlideViewClose = () => {
        closeAssetTransaction();
        dispatch(toggleModalState({ isOpen: false }))
    }

    const onTransitionComplete = () => {
        dispatch(toggleModalState({ isOpen: false, slugParam: '', overlaySlug: '', isSlidingUp: false }));
    }

    return {
        activeSlug: slugParam,
        isSlideViewOpen: isOpen,
        overlaySlug,
        isSlidingUp,
        onAssetProfileClick,
        onSlideViewClose,
        onSlideViewOpen,
        onTransitionComplete
    };
};

export default useAssetSlideView;
