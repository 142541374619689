import React from 'react';
import IAssetDetailProps from './IAssetDetailProps';
import './assetDetail.scss';
import i18n from 'i18next';
import Utils from 'utils/Utils';
import TooltipHelper from 'components/small/top-bar/core/TopMenuContent/TooltipHelper/TooltipHelper';
import FlagCheckered from 'components/icons/assets/asset-details/FlagCheckered';
import CalenderStar from 'components/icons/assets/asset-details/CalenderStar';
import moment, { Moment } from 'moment';
import useYield from 'hooks/use-yield/useYield';

const AssetDetail: React.FC<IAssetDetailProps> = ({ asset }) => {
    const { shouldShowYield } = useYield();
    const getNextPayoutDate = (initialSaleDate: Moment): string => {
        let nextPayoutMonth;
        const increaseMonthNum = 1;
        const todayDate = moment().locale(Utils.getCurrentLanguage());
        const dayFromInitialSaleDate = initialSaleDate.date();
        if (todayDate.isSame(initialSaleDate, 'day')) {
            nextPayoutMonth = todayDate.add({ months: increaseMonthNum });
        } else {
            nextPayoutMonth = todayDate.date() <= dayFromInitialSaleDate
                ? todayDate
                : todayDate.add({ months: increaseMonthNum });
        }
        const endDayOfNextPayoutMonth = nextPayoutMonth.endOf("month").date();
        const nextPayoutDate = dayFromInitialSaleDate <= endDayOfNextPayoutMonth
            ? nextPayoutMonth.set({ date: dayFromInitialSaleDate })
            : nextPayoutMonth.set({ date: endDayOfNextPayoutMonth })

        return nextPayoutDate.format("ll");
    }

    const getPayoutDate = (): string => {
        const initialSaleDate = moment(asset.InitialSaleDate);
        const isInitialSaleDateValid = initialSaleDate.isValid()
        const initialSaleDateIsInFuture = initialSaleDate.isAfter(moment());
        if (isInitialSaleDateValid && initialSaleDateIsInFuture) {
            return i18n.t('assetProfile.detail.defaultPayoutDate');
        }
        return getNextPayoutDate(initialSaleDate);
    };

    const assetInformations = React.useMemo(() => ([
        { icon: <FlagCheckered />, label: i18n.t('assetProfile.detail.introductionPrice'), value: `${asset.InitialValue.toCurrency()}`, about: i18n.t('assetProfile.detail.introductionPrice.info') },
        ...(shouldShowYield?.(asset)) ? [{ icon: <CalenderStar />, label: i18n.t('assetProfile.detail.payoutDate'), value: getPayoutDate(), about: i18n.t('assetProfile.detail.payoutDate.info') }] : [],

    ]), [asset, getPayoutDate, shouldShowYield]);

    return (<div className="asset-detail">
        <div className="asset-information">
            {assetInformations.map((info, index) => <div key={"assetInfo" + index} className="asset-information_row">
                <span className="asset-information_row_label">{info.icon}{info.label} :</span>
                <span className="asset-information_row_value">{info.value}<TooltipHelper helperMessage={info.about} /></span>
            </div>)}
        </div>
    </div>);
};
export default React.memo(AssetDetail);
