import React from "react";
import { Redirect, Route } from "react-router-dom";

const UserAuthenticatedProtectedRoute: React.FC<any> = ({ component: Component, authorizedAccess, ...restOfProps }: any) => {
    return (
        <Route
            {...restOfProps}
            render={(props) =>
                authorizedAccess ? <Component {...props} /> : <Redirect to={'/'} />
            } />
    );
}

export default UserAuthenticatedProtectedRoute;