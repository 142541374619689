import { WEBSOCKET_DEMO_ENDPOINT, WEBSOCKET_DEV_ENDPOINT, WEBSOCKET_PRODUCTION_ENDPOINT } from "./_const";

export const getWebsocketEndpoint = () => {
    if (window.location.host.includes('localhost'))
        return WEBSOCKET_DEV_ENDPOINT;
    if (window.location.host.includes('integration-app.trendex.vip'))
        return WEBSOCKET_DEV_ENDPOINT
    if (window.location.host.includes('demo-app.trendex.vip'))
        return WEBSOCKET_DEMO_ENDPOINT;
    return WEBSOCKET_PRODUCTION_ENDPOINT
}
