import i18next from 'i18next';
import React from 'react';
import ITokenYieldInformationProps from './ITokenYieldInformationProps';
import useModalDisplay from 'hooks/use-modal-display/useModalDisplay';
import { Info } from '@material-ui/icons';
import Chart from 'components/icons/tournament/Chart';
import clsx from 'clsx';
import './TokenYieldInformation.scss';

const TokenYieldInformation: React.FC<ITokenYieldInformationProps> = ({ maxAPY, canShowYieldChartText }) => {
    const { showYieldModal } = useModalDisplay();

    return (
        <div
            className={clsx("info token-yield bonus", {
                "icon-as-banner": !canShowYieldChartText
            })}
            onClick={() => canShowYieldChartText && showYieldModal(maxAPY)}
        >
            <div className="icon">
                <Chart width={25} height={25} />
            </div>
            {canShowYieldChartText &&
                <div className="label no-margin">
                    {i18next.t('token-information.yield')}
                </div>
            }
            <div className="value">
                {parseFloat(maxAPY.toPercentage()) + '%'}
                {canShowYieldChartText &&
                    <Info className="info-icon" />
                }
            </div>
        </div>
    );
};

export default React.memo(TokenYieldInformation);
