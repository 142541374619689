import React from 'react';

const Trophy = (props: React.SVGProps<SVGSVGElement>) => {
    const DEFAULT_SIZE = '20px';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.938 18.611" height={DEFAULT_SIZE} width={DEFAULT_SIZE} {...props}>
            <path id="trophy-solid" d="M14.54,0H6.4A1.7,1.7,0,0,0,4.685,1.752c.007.193.015.385.025.574H.872A.87.87,0,0,0,0,3.2a9.741,9.741,0,0,0,2.853,7.3,11.658,11.658,0,0,0,5.02,2.755,1.848,1.848,0,0,1,1.432,1.658,1.379,1.379,0,0,1-1.378,1.378H6.979a1.163,1.163,0,0,0,0,2.326h6.979a1.163,1.163,0,1,0,0-2.326H13.01a1.379,1.379,0,0,1-1.378-1.378,1.84,1.84,0,0,1,1.432-1.658,11.648,11.648,0,0,0,5.024-2.755,9.753,9.753,0,0,0,2.85-7.3.87.87,0,0,0-.872-.872H16.227c.011-.189.018-.378.025-.574A1.7,1.7,0,0,0,14.54,0ZM1.778,4.071H4.845A17.716,17.716,0,0,0,6.732,11,9.029,9.029,0,0,1,4.071,9.244a7.728,7.728,0,0,1-2.29-5.173ZM16.87,9.244A9.029,9.029,0,0,1,14.209,11,17.716,17.716,0,0,0,16.1,4.071h3.068a7.761,7.761,0,0,1-2.29,5.173Z" />
        </svg>
    )
}

export default Trophy;
