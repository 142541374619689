import IBuySellingOrder from 'model/Order/IBuySellingOrder';
import ReduxRootState from 'model/Redux/ReduxRootSate';
import ReduxActionInterface from 'model/ReduxActionInterface';
import { BUY_SELLING_ORDERBOOK } from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<IBuySellingOrder> = {
    loading: false,
    data: {
        buyingOrderId: ''
    },
};

export const buySellingOrderReducer = (state: ReduxRootState<IBuySellingOrder> = ROOT_VALUE_STATE, action: ReduxActionInterface<IBuySellingOrder>) => {
    switch (action.type) {
        case BUY_SELLING_ORDERBOOK.LOADING:
            return {
                ...state,
                loading: true,
            };
        case BUY_SELLING_ORDERBOOK.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case BUY_SELLING_ORDERBOOK.SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case BUY_SELLING_ORDERBOOK.DROP_DATA:
            return {
                ...state,
                error: '',
                data: null
            };
        default:
            return state;
    }
};
