import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import * as ActionTypeWithDraw from './actionsType';
import AxiosConfig from '../../config/axiosConfig';
import { showNotification } from '../notification/action';
import { IHttpResponse, IHttpStrongTypedResponse } from '../../model/IHttpResponse';
import { FAKE_WITHDRAW_PREFIX, HTTP_STATUS_OK } from 'config/_const';
import { WITHDRAW_REQUESTS_ENDPOINTS } from 'config/endpoints';
import IWithdrawRequestData from 'model/Withdraw/IWithdrawRequestData';
import { createWithdrawalRequest } from 'service/user-withdrawal-requests/actions';
import IWithdrawalRequest from 'service/user-withdrawal-requests/IWithdrawalRequest';
import Utils from 'utils/Utils';

export const fetchAllWithdrawRequests = (page: number, userId?: string, status?: string, populate?: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    let queries = '';
    if (userId)
        queries += `userId=${userId}`;
    if (status)
        queries += `status=${status}`;
    if (populate)
        queries += `&with=${populate}`;
    try {
        const response: IHttpResponse = await AxiosConfig.get(`${WITHDRAW_REQUESTS_ENDPOINTS.ALL}/${page}?${queries}`);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: ActionTypeWithDraw.WITHDRAWS_ERROR,
            });
        }
        return dispatch({
            type: ActionTypeWithDraw.WITHDRAWS_SUCCESS,
            payload: {
                data: response.data.data,
                message: response.data.message,
            },
        });
    } catch (error) {
        return dispatch({
            type: ActionTypeWithDraw.WITHDRAWS_ERROR,
        });
    }
};

export const fetchWithdrawRequestsById = (withdrawRequetsId: string, populate?: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    let queries = '';
    if (populate)
        queries += `&with=${populate}`;
    try {
        const response: IHttpResponse = await AxiosConfig.get(`${WITHDRAW_REQUESTS_ENDPOINTS.FETCH}/${withdrawRequetsId}?${queries}`);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: ActionTypeWithDraw.WITHDRAWS_ERROR,
            });
        }
        return dispatch({
            type: ActionTypeWithDraw.WITHDRAWS_SUCCESS,
            payload: {
                data: response.data.data,
                message: response.data.message,
            },
        });
    } catch (error) {
        return dispatch({
            type: ActionTypeWithDraw.WITHDRAWS_ERROR,
        });
    }
};

export const createWithdrawRequest = (data: IWithdrawRequestData, onSuccessCallback?: () => void): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypeWithDraw.WITHDRAWS_LOADING,
        });
        const response: IHttpStrongTypedResponse<IWithdrawalRequest> = await AxiosConfig.post(`${WITHDRAW_REQUESTS_ENDPOINTS.CREATE}`, { ...data, amount: Number(data.amount).toDefaultCurrencyValue() });
        showNotification(dispatch, response.data.message, response.data.status);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: ActionTypeWithDraw.WITHDRAWS_ERROR,
            });
        if (onSuccessCallback) onSuccessCallback();

        const withdrawFromServer: IWithdrawalRequest = response.data.data;
        const withdraw: IWithdrawalRequest = {
            _id: withdrawFromServer?._id ?? `${FAKE_WITHDRAW_PREFIX}${Utils.uid()}`,
            createdAt: withdrawFromServer?.createdAt ?? new Date().toISOString(),
            creditsToWithdraw: parseFloat(data.amount),
            currency: data.currency ?? 'EUR',
            fees: withdrawFromServer?.fees ?? 2.5,
            feesRate: withdrawFromServer?.feesRate ?? 0.05,
            status: withdrawFromServer?.status ?? 'OPEN',
            type: withdrawFromServer?.type ?? 'SEPA',
            updatedAt: withdrawFromServer?.updatedAt ?? new Date().toISOString(),
            userId: withdrawFromServer?.userId ?? '-1',
            feesFixedAmount: withdrawFromServer?.feesFixedAmount ?? 2.5,
            IBAN: data.iban,

        };
        return dispatch(createWithdrawalRequest(withdraw));
    } catch (error) {
        console.log('error: ', error.stack);
        return dispatch({
            type: ActionTypeWithDraw.WITHDRAWS_ERROR,
        });
    }
};
