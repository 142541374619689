import i18n from '18n';
import TeamAssets from 'components/medium/team/team-list/TeamAssets/TeamAssets';
import CircularLoader from 'components/small/CircularLoader';
import GreenButtonWithGradient from 'components/small/buttons/green-button/GreenButtonWithGradient';
import { DEFAULT_ID_AS_STRING } from 'config/_const';
import IUseAllAssets from 'hooks/asset/use-all-assets/IUseAllAssets';
import useAllAssets from 'hooks/asset/use-all-assets/useAllAssets';
import useTournamentSubscriptions from 'hooks/tournament/use-tournament-subscriptions/useTournamentSubscriptions';
import useTournament from 'hooks/tournament/use-tournament/useTournament';
import IUseConfirmTeam from 'hooks/use-confirm-team/IUseConfirmTeam';
import useConfirmTeam from 'hooks/use-confirm-team/useConfirmTeam';
import ITeamAsset from 'model/Team/ITeamAsset';
import React from 'react';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import SubscribeConfirmationModal from '../../../medium/tournament/subscribe-confirmation-modal/SubscribeConfirmationModal';
import './AccordionPayment.scss';
import IConfirmTeamProps from './IConfirmTeamProps';
import './style.scss';

const ConfirmTeam: React.FC<IConfirmTeamProps> = (props) => {
    const { getAssetsByIds, getAssetById }: IUseAllAssets = useAllAssets();
    const getAssetViewModelsFromTeamAssets = (teamAssets: ITeamAsset[]): AssetViewModel[] => getAssetsByIds(teamAssets.map((teamAsset: ITeamAsset) => teamAsset.asset));
    const { nextTournament } = useTournament();
    useTournamentSubscriptions({ tournamentId: nextTournament?.Id ?? DEFAULT_ID_AS_STRING });

    const {
        showTournamentSubscription,
        teamLoading,
        teamIdFromServer,
        createOrUpdateTeamOnServer,
    }: IUseConfirmTeam = useConfirmTeam({ nextTournament, teamViewModel: props.teamViewModel, onSaveTeamCallback: props.onSaveTeamCallback });

    return (
        <div className="confirm-team-list">
            {!showTournamentSubscription &&
                <div>
                    <h3 className="bold black-color label-team">{i18n.t('my-team')}</h3>
                    <div className="team-list-container">
                        <TeamAssets
                            teamScoreOnTop={true}
                            embededInModal={true}
                            showGetButton={false}
                            getAssetViewModelsFromTeamAssets={getAssetViewModelsFromTeamAssets}
                            showRemoveIcon={false}
                            getAssetFromTeamAsset={getAssetById}
                            teamBuildingViewModel={props.teamViewModel} 
                            isCollapsed={false}
                            showTokenPerSlot={false}
                        />
                    </div>

                    <p className="no-margin information">{i18n.t('with-more-shares-win-more')}</p>

                    <div className="container-btn-save-team">
                        <GreenButtonWithGradient
                            className="button-confirm-team"
                            disabled={false}
                            handleClick={createOrUpdateTeamOnServer}
                            receiveClickEvent={false}
                            type="button"
                            textButton={i18n.t('confirm-team-btn.save')} />
                    </div>
                </div>}

            {teamLoading && <CircularLoader
                loaderMessage={i18n.t('trendexloading')}
                loaderLocal={false}
                showButton={false} />}

            {showTournamentSubscription && nextTournament && <SubscribeConfirmationModal teamId={teamIdFromServer} nextTournament={nextTournament} />}
        </div>
    );
};

export default React.memo(ConfirmTeam);
