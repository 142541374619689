import IWebflowLink from "interfaces/IWebflow";
import Utils from "utils/Utils";

export const WEBFLOW_PARAMS = {
    FIRSTNAME: "firstName",
    LASTNAME: "lastName",
    EMAIL: "email"
};
const WEBFLOW__BASE_URL: Record<string, { [key: string]: string }> = {
    INTEGRATION: {
        EN: 'https://en.benzema.club',
        FR: 'https://benzema.club',
        ES: 'https://es.benzema.club',
    },
    PROD: {
        EN: 'https://en.benzema.club',
        FR: 'https://benzema.club',
        ES: 'https://es.benzema.club',
    }
};
// const CHALLENGE_PATH: string = "/challenge";

const getCurrentLangage = (): string => Utils.getCurrentLanguage().toUpperCase();
const geWebflowBaseURLIntegration = (): string => (WEBFLOW__BASE_URL.INTEGRATION[getCurrentLangage()] ?? WEBFLOW__BASE_URL.INTEGRATION.EN);
const geWebflowBaseURLProd = (): string => (WEBFLOW__BASE_URL.PROD[getCurrentLangage()] ?? WEBFLOW__BASE_URL.PROD.EN);

const getIntegrationWebflow = (): IWebflowLink => {
    return {
        baseURL: geWebflowBaseURLIntegration(),
        challengeURL: geWebflowBaseURLIntegration(),
    }
};
const getProdWebflow = (): IWebflowLink => {
    return {
        baseURL: geWebflowBaseURLProd(),
        challengeURL: geWebflowBaseURLProd(),
    }
};

export const getWebflowEndPoint = (): IWebflowLink => {
    if (window.location.host.includes('localhost'))
        return getIntegrationWebflow();
    if (window.location.host.includes('integration-app.trendex.vip'))
        return getIntegrationWebflow();
    if (window.location.host.includes('demo-app.trendex.vip'))
        return getProdWebflow();
    return getProdWebflow();
}
