import React from 'react';
import { useSelector } from 'react-redux';
import { assetsCategoriesSelector } from 'service/asset-category/selector';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import { ReduxRootState } from 'model/Redux';
import AssetCategoryViewModel from 'view-model/Assets/AssetCategory';
import IUseAssetCategories from './IUseAssetCategories';


const useAssetsCategories = (): IUseAssetCategories => {
    const assetsCategories: ReduxRootState<AssetCategoryInterface[]> = useSelector(assetsCategoriesSelector);
    const assetCategoriesAsViewModel: AssetCategoryViewModel[] = React.useMemo(() => {
        return assetsCategories.data.map((category: AssetCategoryInterface) => new AssetCategoryViewModel(category));
    }, [assetsCategories]);

    return {
        assetsCategories: assetsCategories.data,
        assetCategoriesAsViewModel,
    };
};

export default useAssetsCategories;
