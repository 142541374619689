import academySignup from 'assets/images/Academy/Sign up.jpg'
import academyTeam from 'assets/images/Academy/Team.jpg'
import academyPurchaseShare from 'assets/images/Academy/Purchase of shares.jpg'

import Utils from "utils/Utils";
import { ApplicationSportVersionEnum } from 'config/_enums';
import { bannerVideoSignupBasketball, bannerVideoSignupFootball, bannerVideoStandard } from 'data/SignUpInImages/authentificationImages';

const CAROUSEL_VIDEOS: Record<string, Record<string, string>> = {
    LEAGUES: {
        EN: 'fkdqx-emMrc',
        FR: '6j6CYnp1quI',
        ES: 'IBR6VPvRBsY',
    },
    GLOBAL: {
        EN: 'h6KBpl-pCw4',
        FR: 'jduNUOV2Cqo',
        ES: '0XNhuvDeqAk',
    }
};

const ACADEMY_VIDEOS: Record<string, Record<string, string>> = {
    ACADEMY1_SIGNUP: {
        EN: 'LyJS1YF2nAU',
        FR: '4mYhZJ8iSjE',
        ES: 'LyJS1YF2nAU',
    },
    ACADEMY2_TEAM: {
        EN: 'mRkFX_ND7vE',
        FR: 'IkMl0cCq1C0',
        ES: '_3sXeT5tjdg',
    },
    ACADEMY3_PURCHASE_SHARE: {
        EN: 'ZNbgd0kY3Y8',
        FR: '6NynegXm31o',
        ES: 'XfhfNO16ET4',
    },
};

const PRIVATE_SELL: Record<string, Record<string, string>> = {
    PRIVATE_SELL_EXPLAINER: {
        EN: 'oULj3LspZA0',
        FR: '0TljzerdxBw',
        ES: 'SyYvKP0euUQ',
    }
};

export const SIGNUP_VERSIONS: Record<ApplicationSportVersionEnum, Record<string, { videoId: string, bannerImage: string }>> = {
    [ApplicationSportVersionEnum.STANDARD]: {
        EN: {
            videoId: 'hRzA_GWBhzk',
            bannerImage: bannerVideoStandard,
        },
        FR: {
            videoId: 'QtQQ2OAfTH8',
            bannerImage: bannerVideoStandard,
        },
    },
    [ApplicationSportVersionEnum.FOOTBALL]: {
        EN: {
            videoId: 'hRzA_GWBhzk',
            bannerImage: bannerVideoSignupFootball,
        },
        FR: {
            videoId: 'QtQQ2OAfTH8',
            bannerImage: bannerVideoSignupFootball,
        },
    },
    [ApplicationSportVersionEnum.BASKETBALL]: {
        EN: {
            videoId: 'okIIBlWwfaM',
            bannerImage: bannerVideoSignupBasketball,
        },
        FR: {
            videoId: 'ckAnDMwWx6E',
            bannerImage: bannerVideoSignupBasketball,
        },
    },
};

export const getCarouselIdVideoLeagues = () => CAROUSEL_VIDEOS.LEAGUES[Utils.getCurrentLanguage().toUpperCase()] ?? CAROUSEL_VIDEOS.LEAGUES.EN;
export const getCarouselIdVideoGlobal = () => CAROUSEL_VIDEOS.GLOBAL[Utils.getCurrentLanguage().toUpperCase()] ?? CAROUSEL_VIDEOS.GLOBAL.EN;

export const academyThumbnail1 = academySignup;
export const academyThumbnail2 = academyTeam;
export const academyThumbnail3 = academyPurchaseShare;
export const getAcademyVideoId1 = () => ACADEMY_VIDEOS.ACADEMY1_SIGNUP[Utils.getCurrentLanguage().toUpperCase()] ?? ACADEMY_VIDEOS.ACADEMY1_SIGNUP.EN;
export const getAcademyVideoId2 = () => ACADEMY_VIDEOS.ACADEMY2_TEAM[Utils.getCurrentLanguage().toUpperCase()] ?? ACADEMY_VIDEOS.ACADEMY2_TEAM.EN;
export const getAcademyVideoId3 = () => ACADEMY_VIDEOS.ACADEMY3_PURCHASE_SHARE[Utils.getCurrentLanguage().toUpperCase()] ?? ACADEMY_VIDEOS.ACADEMY3_PURCHASE_SHARE.EN;

export const getPrivateSellExplainer = () => PRIVATE_SELL.PRIVATE_SELL_EXPLAINER[Utils.getCurrentLanguage().toUpperCase()] ?? PRIVATE_SELL.PRIVATE_SELL_EXPLAINER.EN;