import { LOCAL_STORAGE_KNOWN_KEYS } from "config/_const";

export default class ReferralViewModel {
    private _referralId: string;

    constructor() {
        this._referralId = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_REFERRAL) ?? localStorage.getItem("referral") ?? '';
    }

    public get ReferralId(): string {
        return this._referralId;
    }

    public RemoveReferralId(): void {
        localStorage.removeItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_REFERRAL);
    }
}