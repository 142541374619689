import i18n from '18n';
import React from 'react';
import { Link } from 'react-router-dom';
import { URLS } from 'config/_const';
import IRewardModalProps from './IRewardModalProps';
import { rewardTournamentImg, tdxLogoN } from 'data/generalImages/generalImages.data';
import ModalImageContainer from 'components/small/modal-image-container/ModalImageContainer';
import TournamentModalCard from 'components/small/tournament-modal-card/TournamentModalCard';
import './style.scss';

const RewardModal: React.FC<IRewardModalProps> = ({ rewardModalViewModel, onValidation }: IRewardModalProps) => {
    return (
        <div className="container-reward-modal">
            <ModalImageContainer imgSrc={rewardTournamentImg} />
            <div className="rank-container">
                <div className="placement-number">
                    {rewardModalViewModel.Place}
                    <sup>{rewardModalViewModel.RanksSuffix}</sup>
                </div>
            </div>
            <h3>{i18n.t('titleModalRewardCongratulations')}</h3>

            <p>{i18n.t('titleModalRewardPrize')}</p>
            <TournamentModalCard
                iconSrc={tdxLogoN}
                rewardAmount={rewardModalViewModel.creditsRewardAsString()}
                rewardTextDescription={i18n.t('tournamentRewardRankDescription', { rank: rewardModalViewModel.Place, suffix: rewardModalViewModel.RanksSuffix })}
            />
            {rewardModalViewModel.ShouldShowHigherLeagueRewardDisclaimer && <div className='reward-disclaimer'>
                {i18n.t('reward.disclaimer', { upperLeague: rewardModalViewModel.HigherLeague, upperLeagueValue: rewardModalViewModel.HigherLeaguePotentialRewardAsCurrency })}
            </div>}
            <Link to={URLS.tournament}>
                <div className="check-results-btn">
                    <button className='primary-button' onClick={() => onValidation()}>
                        {i18n.t('seeResultsFromModalRewards')}
                    </button>
                </div>
            </Link>
        </div>
    );
};

export default RewardModal;
