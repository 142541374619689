import ReduxActionInterface from '../../model/ReduxActionInterface';

import * as SideMenuType from './actionsType';

import ReduxRootState from '../../model/Redux/ReduxRootSate';
import Utils from '../../utils/Utils';

const ROOT_VALUE_STATE: ReduxRootState = {
    data: {
        display: !Utils.isMobile(),
    },
    loading: true,
};

export const sideMenuReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {
        case SideMenuType.SIDE_MENU_MANAGE:
            return {
                ...state,
                data: {
                    display: !state.data.display,
                },
            };

        default: return state;
    }
};
