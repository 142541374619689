import { TEAM_AUTOMATIC_GENERATION_BEHAVIOR } from '../../config/_const';
import IAsset from '../../model/Team/generator/IAsset';
import { IInternalTeam } from '../../model/Team/ITeam';
import ITeamAsset from '../../model/Team/ITeamAsset';
import ITeamConfig from '../../model/Team/ITeamConfig';
import ITeamGenerator from '../../model/Team/ITeamGenerator';
import TeamAsset from '../../model/Team/TeamAsset';
import TrendexServiceAbstract from '../common/TrendexServiceAbstract';
import IGenerateTeam from './IGenerateTeam';

export default class TeamGenerationService extends TrendexServiceAbstract implements ITeamGenerator {
    private assets: IAsset[];
    private configuration: ITeamConfig;
    private team: IInternalTeam | undefined;
    constructor(configuration: ITeamConfig, assets: IAsset[], team: IInternalTeam | undefined) {
        super();
        this.configuration = configuration;
        this.assets = assets;
        this.team = team;
    }

    public get Configuration(): ITeamConfig {
        return this.configuration;
    }

    public get Team(): IInternalTeam | undefined {
        return this.team;
    }

    public get Assets(): IAsset[] {
        return [...this.assets].sort((assetA: IAsset, assetB: IAsset) => {
            if (assetA.fromPortfolio && !assetB.fromPortfolio)
                return -1;
            if (!assetA.fromPortfolio && assetB.fromPortfolio)
                return 1;
            if (assetA.fromPortfolio && assetB.fromPortfolio)
                return assetB.amountSharesOwned - assetA.amountSharesOwned;
            return assetA.numberOfSharesInSale > assetB.numberOfSharesInSale ? -1 : 1;
        });
    }

    private throwableBuildTeamAsset = (asset: IAsset) => {
        if (asset.fromPortfolio && !asset.amountSharesOwned)
            throw Error('Unable to build team because amountSharesOwned is not set');
        let numberOfShares: number = asset.amountSharesOwned ?? 0;
        return new TeamAsset(numberOfShares, asset._id);
    }

    private isTeamAutoGenerated = (localTeam: IInternalTeam): boolean => {
        return localTeam.autoGenerated;
    }

    private configurationIsOfTypeFilled = (): boolean => {
        return this.configuration.automaticGeneration.behavior === TEAM_AUTOMATIC_GENERATION_BEHAVIOR.FILL;
    }

    public generateTeam = (assetHandledCursor: number): IGenerateTeam => {
        try {
            let generatedTeamObject: { assetsTeam: ITeamAsset[], lastAssetHandledCursor: number } = { assetsTeam: [], lastAssetHandledCursor: assetHandledCursor };

            const existingEditingTeam: IInternalTeam | undefined = this.Team;
            if (!existingEditingTeam) {
                generatedTeamObject = this.createAssetsTeam(assetHandledCursor, this.configuration.maxNumberOfAssets);
                return { lastAssetHandledCursor: generatedTeamObject.lastAssetHandledCursor, assetsTeam: generatedTeamObject.assetsTeam };
            }

            const dataOfExistingTeam: IInternalTeam = existingEditingTeam;
            if (this.configurationIsOfTypeFilled() && this.configuration.maxNumberOfAssets > dataOfExistingTeam.teamAssets.length) {
                generatedTeamObject = this.createAssetsTeam(assetHandledCursor, this.configuration.maxNumberOfAssets - dataOfExistingTeam.teamAssets.length, dataOfExistingTeam.teamAssets);
                generatedTeamObject.lastAssetHandledCursor = this.getLastAssetHandledCursor(generatedTeamObject.assetsTeam);
                return { lastAssetHandledCursor: generatedTeamObject.lastAssetHandledCursor, assetsTeam: [...dataOfExistingTeam.teamAssets, ...generatedTeamObject.assetsTeam] };
            }

            let lastAssetHandledCursor: number = assetHandledCursor;
            if ((this.isTeamAutoGenerated(dataOfExistingTeam) || this.configurationIsOfTypeFilled()) && dataOfExistingTeam.teamAssets.length > 0) {
                lastAssetHandledCursor = this.getLastAssetHandledCursor(dataOfExistingTeam.teamAssets);
            }

            generatedTeamObject = this.createAssetsTeam(lastAssetHandledCursor, this.configuration.maxNumberOfAssets);
            return { lastAssetHandledCursor: -1, assetsTeam: generatedTeamObject.assetsTeam };
        } catch (exception) {
            console.log('Exception thrown from AutomaticTeamGenerationService::generateTeam', exception);
            return { lastAssetHandledCursor: assetHandledCursor, assetsTeam: [] };
        }
    }

    private createAssetsTeam = (lastAssetHandledCursor: number, numberOfAssets: number, assetsToExclude?: ITeamAsset[]): { assetsTeam: ITeamAsset[], lastAssetHandledCursor: number } => {
        const autoGeneratedTeamAssets: ITeamAsset[] = [];
        let assetsToHandle: IAsset[] = this.Assets;

        if (lastAssetHandledCursor > -1) {
            assetsToHandle = assetsToHandle.slice(lastAssetHandledCursor);
            if (assetsToHandle.length < numberOfAssets) {
                assetsToHandle = assetsToHandle.concat(this.Assets.slice(0, numberOfAssets - assetsToHandle.length));
            }
        }

        if (assetsToExclude !== undefined && assetsToExclude.length > 0) {
            for (const asset of assetsToExclude) {
                const index: number = assetsToHandle.findIndex(asst => asst._id.toString() === asset.asset.toString());
                if (index !== -1)
                    assetsToHandle.splice(index, 1);
            }
        }

        let newAssetHandledCursor: number = -1;
        for (const asset of assetsToHandle) {
            const teamAsset: ITeamAsset = this.throwableBuildTeamAsset(asset);
            autoGeneratedTeamAssets.push(teamAsset);
            if (autoGeneratedTeamAssets.length === numberOfAssets) {
                newAssetHandledCursor = assetsToHandle.indexOf(asset);
                break;
            }
        }

        return { assetsTeam: autoGeneratedTeamAssets, lastAssetHandledCursor: newAssetHandledCursor };
    }

    private getLastAssetHandledCursor = (lastAssetOfTeam: ITeamAsset[]): number => {
        let lastIndex: number = -1;
        for (const asset of lastAssetOfTeam) {
            const indexOfAsset: number = this.Assets.findIndex(assetToHandle => assetToHandle._id.toString() === asset.asset.toString());
            if (indexOfAsset > lastIndex || (lastIndex === this.Assets.length - 1 && indexOfAsset === 1))
                lastIndex = indexOfAsset;
        }
        return lastIndex === -1 ? lastIndex : lastIndex + 1;
    }

}
