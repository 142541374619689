import register from '../../assets/images/login-register/Image_register.png';
import login from '../../assets/images/login-register/Image-login.png';
import welcomeTdxLogo from '../../assets/images/login-register/welcomeTdxLogo.png';
import banner from '../../assets/images/login-register/Banner_Desktop-nogradient.png';
import cards_team from '../../assets/images/login-register/cards_team-min.png';
import purchaseOfSales from '../../assets/images/Academy/Purchase of shares.jpg';
import team from '../../assets/images/Academy/Team.jpg';
import tokenEvolution from '../../assets/images/Benzema-club/token_evolution.png';
import tournamentVisual from '../../assets/images/login-register/Tournament_Visual.png';
import logoBlueTrendex from '../../assets/images/login-register/logo-blue-trendex.png';
import bannerFootball from '../../assets/images/login-register/football-signup.webp';
import bannerBasketball from '../../assets/images/login-register/basketball-signup.webp';
import backFourTalentCards from '../../assets/images/login-register/cards-team-back.webp';
import frontFiveTalentCards from '../../assets/images/login-register/cards-team-front-5.webp'
import frontFourTalentCards from '../../assets/images/login-register/cards-team-front-4.webp'
import bannerStandard from '../../assets/images/login-register/foot-and-basket-signup.webp';
import logo from '../../assets/images/login-register/logo.webp'

export const logoBlueTdx = logoBlueTrendex;
export const logoTrendex = logo;
export const bannerVideoStandard = bannerStandard;
export const bannerVideoSignupFootball = bannerFootball;
export const bannerVideoSignupBasketball = bannerBasketball;
export const fourTalentCardsBack = backFourTalentCards;
export const FiveTalentCardsFront = frontFiveTalentCards
export const FourTalentCardsFront = frontFourTalentCards

export const OldRegisterImage = register;
export const loginImage = login;

export const bannerImage = banner;
export const cardsTeamImage = cards_team;
export const purchaseOfSalesImage = purchaseOfSales;
export const teamImage = team;
export const tokenEvolutionImage = tokenEvolution;
export const tournamentVisualImage = tournamentVisual;

export const welcomeImgLogo = welcomeTdxLogo