import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import TableHeader from '../../../model/Table/TableHeader';

interface GenericTableHeaderProps {
    numSelected: number;
    onRequestSort: any;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void | null;
    order: 'asc' | 'desc';
    orderBy: string | number | symbol;
    rowCount: number;
    headCells: any;
    showSelectAll: boolean;
}

const GenericTableHeader: React.FunctionComponent<GenericTableHeaderProps> = (props) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {props.showSelectAll &&
                    <TableCell padding="checkbox">

                    </TableCell>}
                {props.headCells.map((headCell: TableHeader<{}>) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        style={{ textAlign: 'left', background: '#343a6a', color: 'white', border: 'none' }}
                        sortDirection={orderBy === headCell.id ? order : false} >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)} >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default GenericTableHeader;
