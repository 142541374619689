import i18next from 'i18next';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SegmentTracking } from 'service/segment/SegmentTracking';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import { G_TAGS_KNOWN_EVENTS, HTTP_STATUS_OK } from '../../config/_const';
import AxiosConfig from '../../config/axiosConfig';
import { TEAM_ENDPOINTS } from '../../config/endpoints';
import ITeam, { IInternalTeam } from '../../model/Team/ITeam';
import { showNotification } from '../notification/action';
import { IHttpStrongTypedResponse } from './../../model/IHttpResponse';
import * as TeamsActionTypes from './actionTypes';

export const createTeam = (team: Required<Pick<IInternalTeam, 'owner' | 'teamAssets' | 'configuration'>> & Partial<IInternalTeam>, createTeamSuccessCallback: (teamId: string) => void): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TeamsActionTypes.TEAMS_LOADING });
        const response: IHttpStrongTypedResponse<ITeam> = await AxiosConfig.post(TEAM_ENDPOINTS.CREATE, team);
        if (response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, i18next.t(response.data.message), response.data.status);
            return dispatch({ type: TeamsActionTypes.TEAMS_ERROR });
        }
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.CREATE_TEAM, { teamId: response.data.data._id });
        SegmentTracking.sendCreateTeamEvent({ teamId: response.data.data._id });
        dispatch({ type: TeamsActionTypes.TEAMS_SUCCESS, payload: [response.data.data] });
        return createTeamSuccessCallback(response.data.data._id);
    } catch (exception) {
        dispatch({ type: TeamsActionTypes.TEAMS_ERROR });
    }
};

export const disableTeam = (id: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TeamsActionTypes.TEAMS_LOADING });
        const response: IHttpStrongTypedResponse<ITeam> = await AxiosConfig.post(TEAM_ENDPOINTS.DISABLE, { _id: id });
        if (response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, i18next.t(response.data.message), response.data.status);
            return dispatch({ type: TeamsActionTypes.TEAMS_ERROR });
        }
        return dispatch({ type: TeamsActionTypes.TEAMS_SUCCESS, payload: [response.data.data] });
    } catch (exception) {
        dispatch({ type: TeamsActionTypes.TEAMS_ERROR });
    }
};

export const updateTeam = (team: Required<ITeam>, updateTeamSuccessCallback: (teamId: string) => void): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TeamsActionTypes.TEAMS_LOADING });
        const response: IHttpStrongTypedResponse<ITeam> = await AxiosConfig.put(TEAM_ENDPOINTS.UPDATE, team);
        if (response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, i18next.t(response.data.message), response.data.status);
            return dispatch({ type: TeamsActionTypes.TEAMS_ERROR });
        }
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.UPDATE_TEAM, { teamId: response.data.data._id });
        SegmentTracking.sendUpdateTeamEvent({ teamId: response.data.data._id });
        dispatch({ type: TeamsActionTypes.TEAMS_SUCCESS, payload: [response.data.data] });
        return updateTeamSuccessCallback(response.data.data._id);
    } catch (exception) {
        dispatch({ type: TeamsActionTypes.TEAMS_ERROR });
    }
};

export const getActiveUserTeams = (userId: string, transparentLoading?: boolean): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        if (!transparentLoading) {
            dispatch({ type: TeamsActionTypes.TEAMS_LOADING });
        }
        const response: IHttpStrongTypedResponse<ITeam[]> = await AxiosConfig.get(`${TEAM_ENDPOINTS.GET_TEAM_BY_USER}/${userId}`);
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({ type: TeamsActionTypes.TEAMS_ERROR });
        return dispatch({ type: TeamsActionTypes.TEAMS_SUCCESS, payload: response.data.data });
    } catch (exception) {
        dispatch({ type: TeamsActionTypes.TEAMS_ERROR });
    }
};

export const getAllActiveTeams = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TeamsActionTypes.TEAMS_LOADING });
        const response: IHttpStrongTypedResponse<ITeam[]> = await AxiosConfig.get(TEAM_ENDPOINTS.ALL_ENABLED);
        if (response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({ type: TeamsActionTypes.TEAMS_ERROR });
        }
        return dispatch({ type: TeamsActionTypes.TEAMS_SUCCESS, payload: response.data.data });
    } catch (exception) {
        dispatch({ type: TeamsActionTypes.TEAMS_ERROR });
    }
};

export const updateTeamStatus = (teamId: string, status: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TeamsActionTypes.TEAMS_LOADING });
        return dispatch({
            type: TeamsActionTypes.TEAMS_UPDATE_STATUS,
            payload: {
                teamId,
                status,
            },
        });
    } catch (exception) {
        dispatch({ type: TeamsActionTypes.TEAMS_ERROR });
    }
};
