import Application from "Application";
import { DEFAULT_AVATAR, DEFAULT_ID_AS_STRING, DEFAULT_SCORE_BOOST, TEAM_STATUS, TRENDEX_ROLES, USER_METADATA, ZERO } from "config/_const";
import ITeam from "model/Team/ITeam";
import ITeamAsset from "model/Team/ITeamAsset";
import { IPersonalTournamentSubscription } from "model/Tournament/ITournamentSubscription";
import IPersonalTournament from "model/Tournament/PersonalTournament/IPersonalTournament";
import IUserPersonalTournamentConfiguration from "model/Tournament/PersonalTournament/IUserPersonalTournamentConfiguration";
import { ITournamentUser, TeamUserInterface } from "model/User/UserInterface";
import Utils from "utils/Utils";
import AccountViewModel from "view-model/AccountViewModel/AccountViewModel";
import IAssetIdsAndScores from "./IAssetIdsAndScores";
import UserPersonalTournamentLocalStorageHelper from "./UserPersonalTournamentLocalStorageHelper";

export default class UserPersonalTournamentHelper {
    private tournament: IPersonalTournament;
    private static tournamentConfiguration: IUserPersonalTournamentConfiguration = Application.getInstance().UserPersonalTournamentConfig;
    private static MAX_TEAM_SCORE: number = 100;
    private accountViewModel: AccountViewModel;
    private userTeam: ITeam;
    private assetIdsForGeneration: IAssetIdsAndScores[];
    private localStorageHelper: UserPersonalTournamentLocalStorageHelper;
    private readonly userPlace: number = UserPersonalTournamentHelper.tournamentConfiguration.place;

    constructor(accountViewModel: AccountViewModel, userTeam: ITeam, assetIds: IAssetIdsAndScores[], localStorageHelper: UserPersonalTournamentLocalStorageHelper, forceTournamentGeneration: boolean = false) {
        this.accountViewModel = accountViewModel;
        this.userTeam = userTeam;
        this.assetIdsForGeneration = assetIds;
        this.localStorageHelper = localStorageHelper;
        if (this.localStorageHelper.Tournament && this.localStorageHelper.Tournament.subscriptions.length > 0 && !forceTournamentGeneration)
            this.tournament = this.localStorageHelper.Tournament;
        else
            this.tournament = this.generateTournament();
    }

    public get Tournament(): IPersonalTournament {
        return this.tournament;
    }

    private get UserTeamScore(): number {
        return this.userTeam.teamAssets.reduce((acc: number, teamAsset: ITeamAsset) => {
            const asset: IAssetIdsAndScores | undefined = this.assetIdsForGeneration.find((as) => as.id === teamAsset.asset);
            const score: number = asset?.score ?? ZERO;
            return acc + score
        }, 0).divide(this.userTeam.teamAssets.length);
    }

    private get PersonalTournamentPrizepool(): number {
        return this.accountViewModel.PersonalTournament?.prizePool ?? ZERO
    }

    public generateTournament(save: boolean = true): IPersonalTournament {
        const teamScoreAverage: number = this.UserTeamScore;
        const tournament: IPersonalTournament = {
            config: UserPersonalTournamentHelper.tournamentConfiguration,
            tournamentState: this.accountViewModel.PersonalTournamentState,
            subscriptions: [{
                scoreBoost: DEFAULT_SCORE_BOOST,
                totalRecruitmentPointsAtSubscriptionTime: 0,
                place: Application.getInstance().UserPersonalTournamentConfig.place,
                userSubscription: true,
                teamScoreAverage,
                ...this.buildDefaultIdObject(),
                subscriptionDate: new Date().toString(),
                totalAssetsOwnedAtSubscriptionTime: 0,
                league: UserPersonalTournamentHelper.tournamentConfiguration.defaultLeague,
                user: this.accountViewModel.account,
                team: this.userTeam,
                tournament: this.accountViewModel.PersonalTournament!,
                cashprize: this.accountViewModel.PersonalTournament?.prizePool ?? ZERO
            }]
        };
        tournament.subscriptions = tournament.subscriptions.concat(this.generateSubscriptions());
        if (save)
            this.localStorageHelper.Tournament = tournament;
        return tournament;
    }

    private generateSubscriptions = (): IPersonalTournamentSubscription[] => {
        const toReturn = [];
        for (let i = 1; i <= this.userPlace; i++)
            toReturn.push(this.buildRandomSubscription(i));
        for (let j = this.userPlace + 1; j <= UserPersonalTournamentHelper.tournamentConfiguration.nbBots; j++)
            toReturn.push(this.buildRandomSubscription(j));
        return toReturn;
    }

    private buildRandomSubscription = (place: number): IPersonalTournamentSubscription => {
        const bot: ITournamentUser = this.buildRandomTournamentUser(place);
        const botTeam: ITeam = this.buildRandomTeam(place, bot);
        const teamScoreAverage: number = this.generateTeamScore(place);
        return {
            ...this.buildDefaultIdObject(),
            scoreBoost: DEFAULT_SCORE_BOOST,
            totalRecruitmentPointsAtSubscriptionTime: 0,
            place,
            userSubscription: false,
            cashprize: this.calculateBotCashprize(place),
            subscriptionDate: new Date().toString(),
            totalAssetsOwnedAtSubscriptionTime: 0,
            league: UserPersonalTournamentHelper.tournamentConfiguration.defaultLeague,
            user: bot,
            team: botTeam,
            teamScoreAverage,
            tournament: this.accountViewModel.PersonalTournament!,
        };
    }

    private buildRandomTeam = (place: number, bot: TeamUserInterface): ITeam => {
        const betterThanUser: boolean = this.isBotPlaceBetterThanUser(place);
        const computeNumberOfSharesByAsset = (numberOfSharesFromUser: number) => {
            const arbitraryNumber: number = (numberOfSharesFromUser - place);
            const numberOfSharesForLosers: number = arbitraryNumber < 1 ? 1 : arbitraryNumber;
            const numberOfSharesForWinners: number = (this.userPlace - place) + numberOfSharesFromUser;
            return !betterThanUser ? numberOfSharesForLosers : numberOfSharesForWinners;
        };

        const getRandomAssetFromTeamAsset = (teamAsset: ITeamAsset): string => {
            const currentAssetScore: IAssetIdsAndScores | undefined = this.assetIdsForGeneration.find(as => as.id === teamAsset.asset);
            const random: string = this.assetIdsForGeneration.random().id;
            if (!currentAssetScore)
                return random;
            const assetsAccordingToScore: IAssetIdsAndScores[] = this.assetIdsForGeneration.filter(as => !betterThanUser ? as.score <= currentAssetScore.score : as.score > currentAssetScore.score);
            if (!assetsAccordingToScore || assetsAccordingToScore.length === 0)
                return random;
            return assetsAccordingToScore.random().id;
        };

        return {
            ...this.buildDefaultIdObject(),
            label: `bot_nb_${place}`,
            autoGenerated: true,
            lastTournamentPosition: place,
            owner: bot,
            status: TEAM_STATUS.ENGAGED,
            enabled: true,
            createdAt: new Date().toString(),
            updatedAt: new Date().toString(),
            configuration: this.userTeam.configuration,
            teamAssets: this.userTeam.teamAssets.map(ta => {
                return {
                    ...this.buildDefaultIdObject(),
                    asset: getRandomAssetFromTeamAsset(ta),
                    numberOfShares: computeNumberOfSharesByAsset(ta.numberOfShares),
                    createdAt: new Date().toString(),
                    updatedAt: new Date().toString()
                }
            })
        };
    }

    private buildRandomTournamentUser = (place: number): ITournamentUser => {
        const pseudo = Utils.generateRandomPseudo();
        return {
            ...this.buildDefaultIdObject(),
            avatar: DEFAULT_AVATAR,
            country: Utils.getMetadataForFakeUser(USER_METADATA.COUNTRY),
            lastName: `Bot_LN_${place}`,
            firstName: `Bot_FN_${place}`,
            pseudo: pseudo,
            userPseudo: pseudo,
            type: TRENDEX_ROLES.USER
        };
    }

    private buildDefaultIdObject = () => {
        return {
            _id: DEFAULT_ID_AS_STRING,
        }
    }

    //#region Team Score calculation
    private calculateTeamScoreRatio = (): number => {
        return (this.UserTeamScore.safeDivideBy(UserPersonalTournamentHelper.tournamentConfiguration.nbBots));
    }

    private calculateTeamScoreFromPlace = (place: number): number => {
        const betterThanUser: boolean = this.isBotPlaceBetterThanUser(place);
        const teamScoreRatio: number = this.calculateTeamScoreRatio();
        const placeRatio: number = this.arbitraryCalculatePlaceRatio(place);
        const previousTeamScoreRatio: number = teamScoreRatio.multiply(placeRatio);
        return betterThanUser ? this.UserTeamScore.addition(previousTeamScoreRatio).addition(teamScoreRatio) : this.UserTeamScore.subtract(previousTeamScoreRatio).subtract(teamScoreRatio);
    }

    private generateTeamScore = (place: number): number => {
        const botTeamScore = this.calculateTeamScoreFromPlace(place);
        if (botTeamScore >= UserPersonalTournamentHelper.MAX_TEAM_SCORE)
            return UserPersonalTournamentHelper.MAX_TEAM_SCORE;
        if (botTeamScore < ZERO)
            return ZERO;
        return botTeamScore
    }
    //#endregion

    //#region Cashprize calculation
    private getCashprizeAverageBySubscriptions = (): number => {
        const totalSubscriptions: number = UserPersonalTournamentHelper.tournamentConfiguration.nbBots;
        return this.PersonalTournamentPrizepool.safeDivideBy(totalSubscriptions);
    }

    private calculateBotCashprizeByPlace = (place: number): number => {
        const betterThanUser: boolean = this.isBotPlaceBetterThanUser(place);
        const cashprizeRatio: number = this.getCashprizeAverageBySubscriptions();
        const placeRatio: number = this.arbitraryCalculatePlaceRatio(place);
        const previousRatioCalculated: number = cashprizeRatio.multiply(placeRatio);
        return betterThanUser ? this.PersonalTournamentPrizepool.addition(previousRatioCalculated).addition(cashprizeRatio) : this.PersonalTournamentPrizepool.subtract(previousRatioCalculated).subtract(cashprizeRatio);
    }

    private calculateBotCashprize = (place: number): number => {
        const botCashprize: number = this.calculateBotCashprizeByPlace(place);
        if (botCashprize < ZERO)
            return ZERO;
        return botCashprize;
    }
    //#endregion

    private isBotPlaceBetterThanUser(botPlace: number): boolean {
        return botPlace.isSmallerThan(this.userPlace);
    }

    private arbitraryCalculatePlaceRatio(place: number): number {
        const betterThanUser: boolean = this.isBotPlaceBetterThanUser(place);
        return betterThanUser ? this.userPlace.subtract(place) : place.subtract(this.userPlace);
    }
}