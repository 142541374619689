import React from 'react';

const EuroCupBlack = ({ height = 20, width = 25, fill = "#000" }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 21.594">
            <g id="JO_icon" transform="translate(-14707 -18196)">
                <path id="Tracé_4242" data-name="Tracé 4242" d="M9.028,21.593h0A11.075,11.075,0,0,1,3.277,17.7,11,11,0,0,1,12,0a11,11,0,0,1,8.757,17.658,11.083,11.083,0,0,1-5.675,3.9c-.077-.091-.165-.188-.258-.291A3.974,3.974,0,0,1,13.64,19.21a1.033,1.033,0,0,1-.01-.139,3.16,3.16,0,0,1,.866-1.659,12.245,12.245,0,0,0,1.323-2.051c.029-.059.057-.118.084-.176a11.177,11.177,0,0,0,.989-4.554,5.936,5.936,0,0,0-.275-1.955,1.908,1.908,0,0,0-.332-.573c-.024-.028-.053-.061-.086-.094.081-.283.742-2.774-.877-3.537a1.62,1.62,0,0,0-.681-.135,2.167,2.167,0,0,0-.875.177.851.851,0,0,1,.086-.3c.14-.2.55-.224.913-.249a2.807,2.807,0,0,0,.464-.055c.205-.054.4-.378.508-.867a1.477,1.477,0,0,0,.025-.878A16.479,16.479,0,0,0,12.533,2H12a20.362,20.362,0,0,0-3.762.184.4.4,0,0,0-.051.257c0,.452.212,1.386.6,1.486a2.813,2.813,0,0,0,.462.051c.363.023.775.049.916.244a.854.854,0,0,1,.088.3,2.183,2.183,0,0,0-.888-.183,1.62,1.62,0,0,0-.681.135A1.962,1.962,0,0,0,7.612,6.22a5.268,5.268,0,0,0,.2,1.835,1.907,1.907,0,0,0-.392.647,5.886,5.886,0,0,0-.262,1.905,11.1,11.1,0,0,0,1.1,4.775A12.228,12.228,0,0,0,9.6,17.428a3.159,3.159,0,0,1,.875,1.651,1.035,1.035,0,0,1-.009.139A3.97,3.97,0,0,1,9.3,21.286c-.1.108-.188.211-.267.307ZM8.607,7.508h0a2.433,2.433,0,0,1,.325-2.273,1.047,1.047,0,0,1,.641-.2.873.873,0,0,1,.742.332c0,.034,0,.068,0,.1,0,.893-.161,1.493-.431,1.607a4.629,4.629,0,0,1-.452.147,6.238,6.238,0,0,0-.824.285Zm6.8-.03h0a6.408,6.408,0,0,0-.8-.271,4.616,4.616,0,0,1-.453-.145C13.721,6.888,13.7,5.7,13.7,5.34a.9.9,0,0,1,.724-.306,1.042,1.042,0,0,1,.64.2A2.442,2.442,0,0,1,15.4,7.477Z" transform="translate(14706 18196)" fill={fill} />
            </g>
        </svg>


    );
};

export default EuroCupBlack;