import React from 'react';
import ChampionsIcon from 'components/icons/ChampionsIcon';
import Shares from 'components/icons/Shares';
import PackIcon from 'components/icons/packs-icon/PackIcon';
import './style.scss'
import PackVipCardProps from './PackVipCardProps';
import { withTranslation } from 'react-i18next';
import Application from 'Application';
import useIsMobile from 'hooks/useIsMobile';
import ShirtIcon from '../../icons/BenzemaClub/ShirtIcon';

const PackVipCard: React.FC<PackVipCardProps> = ({ t: translate, assetName }) => {
    const isMobile: boolean = useIsMobile();
    const iconSize: number = React.useMemo(() => isMobile ? 26 : 35, [isMobile]);

    return (
        <div className="pack-vip-card">
            <div className="header-container">
                <PackIcon color="#6F4A1E" width={50} height={50} />
                <h3 className="title">{translate("PACK VIP")}</h3>
            </div>

            <div className="content">
                <div className="item">
                    <Shares color="#6F4A1E" width={iconSize} height={iconSize} />
                    <p>{translate('buy.packs.card.bonus.tokens', { amount: Application.getInstance().PacksConfiguration.tokens })}</p>
                </div>
                <div className="item">
                    <ShirtIcon color="#6F4A1E" width={iconSize} height={iconSize} />
                    <p>{translate("buy.packs.card.bonus.jersey", { assetName })}</p>
                </div>
                <div className="item">
                    <ChampionsIcon color="#6F4A1E" width={iconSize} height={iconSize} />
                    <p>{translate("buy.packs.card.bonus.league")}</p>
                </div>
            </div>
        </div >
    )
}

export default withTranslation()(PackVipCard);