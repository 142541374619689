import MenuInterface from '../../model/Menu/MenuInterface';
import { VisibilityEnum } from '../../config/_const';
import Utils from 'utils/Utils';
import Application from 'Application';
const entriesToExclude = Application.getInstance().menuEntriesToExclude;

export const filterMenuByIsMobile = (menu: MenuInterface[], isMobile: boolean) => menu.filter(el => isMobile ? el.visibility.includes(VisibilityEnum.SIDE_MOBILE) : el.visibility.includes(VisibilityEnum.SIDE_DESKTOP));

export const filterMenuByBottom = (menu: MenuInterface[]) => menu.filter((el: MenuInterface) => el.visibility.includes(VisibilityEnum.BOTTOM));

export const filterMenuByRoleOrAdminSwitch = (menu: MenuInterface[], role: string, isBaseAdmin?: boolean) => menu.filter((el: MenuInterface) => {

    if (el.accessRole.includes(role)) {

        if (!el.isAdminToVip) {

            return el;
        }

        if (el.isAdminToVip && isBaseAdmin) {

            return el;
        }

        return false;
    }

    return false;
});

export const filterMenuByVisibility = (menu: MenuInterface[]) => menu.filter(el => el.visibility.includes(VisibilityEnum.HIDDEN));

export const filterMenuByBetaAppCondition = (menu: MenuInterface[]) => menu.filter((el: MenuInterface) => {
    if (el.onlyForBetaApp === true && !Utils.isBetaApp())
        return false;
    return true;
});

export const filterByApplicationRestrictions = (menu: MenuInterface[]) => menu.filter((el: MenuInterface) => {
    return !entriesToExclude.includes(el.name);
});
