import { INDICATORS, TOKEN_INFORMATION_CONFIG, TOKEN_INFORMATION_ITEMS } from 'config/_const';
import React from 'react';
import ITokenInformationProps from './ITokenInformationProps';
import './TokenInformation.scss';
import TokenAvailableInformation from './small/available/TokenAvailableInformation';
import TokenPriceInformation from './small/price/TokenPriceInformation';
import ChistmasBonus from 'components/small/bonus/ChristmasBonus';
import YieldInformation from './small/yield/yield-information/YieldInformation';
import clsx from 'clsx';
// import PublicSaleAvailableInformation from './small/public-sale/PublicSaleAvailableInformation';

const TokenInformation: React.FC<ITokenInformationProps> = ({ tokenPrice, asset, availableSupply, totalTokensInCirculation, displayItems }: ITokenInformationProps): JSX.Element => {
    console.log("🚀 ~ tokenPrice:", typeof tokenPrice)

    const benzemaSpecialPrice: number = 30;
    return (
        <div className={clsx("all-token-information-container bonus", { 'token-information-fun-cards': asset.IsFunCategory })}>
            {displayItems.includes(TOKEN_INFORMATION_ITEMS.PRICE) &&
                <TokenPriceInformation
                    userIconSize={TOKEN_INFORMATION_CONFIG.USER_ICON_SIZE}
                    currentValue={tokenPrice}
                    specialPrice={asset.enableCustomAssetProfil() && asset.IsAssetBenzema && asset.isEarlyBirdEnable() ? benzemaSpecialPrice : undefined}
                    asset={asset}
                />
            }
            {displayItems.includes(TOKEN_INFORMATION_ITEMS.YIELD) &&
                <YieldInformation
                    assetVM={asset}
                    canShowAsBanner={false}
                />
            }
            {/* {(displayItems.includes(TOKEN_INFORMATION_ITEMS.SUPPLY) && !asset.AvailableSupply) ?
                <TokenAvailableInformation
                    asset={asset}
                    tokensBuyable={availableSupply > 0 ? availableSupply : 0}
                    totalTokensInCirculation={totalTokensInCirculation}
                    remainingTokensToSell={asset.SecondaryMarketSupply + asset.AvailableSupply}
                    indicator={asset.enableCustomAssetProfil() ? INDICATORS.FIRE_ICON : INDICATORS.DEFAULT} />
                :
                <PublicSaleAvailableInformation
                    remainingTokensToSell={asset.AvailableSupply}
                    tokensBuyable={availableSupply}
                />
            } */}
            {displayItems.includes(TOKEN_INFORMATION_ITEMS.SUPPLY) &&
                <TokenAvailableInformation
                    asset={asset}
                    tokensBuyable={availableSupply > 0 ? availableSupply : 0}
                    totalTokensInCirculation={totalTokensInCirculation}
                    remainingTokensToSell={asset.SecondaryMarketSupply + asset.AvailableSupply}
                    indicator={asset.enableCustomAssetProfil() ? INDICATORS.FIRE_ICON : INDICATORS.DEFAULT} />

            }

            {asset.showChristmasBonus() &&
                <ChistmasBonus />
            }
        </div>
    );
};

export default React.memo(TokenInformation);
