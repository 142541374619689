import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import * as ModalActionsType from './actionsType';

export const managementModal = (stateModal: boolean, modalObject?: Object): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    const type: string = stateModal ? ModalActionsType.MODAL_DISPLAY : ModalActionsType.MODAL_DISMISS;

    return dispatch({
        type,
        payload: {
            modalObject: {
                closeByEscape: true,
                ...modalObject
            },
        },
    });
};
