import IBuyTournamentSubscriptionTIcketParams from "hooks/payment/IBuyTournamentSubscriptionTIcketParams";
import useBuyByCredits from "hooks/payment/useBuyByCredits";

const useTournamentTicketPurchase = () => {
    const { buyTournamentSubscriptionTicketWithCredits } = useBuyByCredits({ showErrorsOnSecondaryMarketPaymentError: false });
    return {
        purchaseTournamentSubscriptionTicketWithCredits: (params: IBuyTournamentSubscriptionTIcketParams) => buyTournamentSubscriptionTicketWithCredits(params),
    };
}

export default useTournamentTicketPurchase;