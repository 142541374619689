const crypto = require('crypto');

interface IIntercomUserData {
    name?: string,
    email?: string,
    created_at?: number,
}

interface IIntercomSettings {
    hide_default_launcher?: boolean
}

class IntercomService {
    private static instance: IntercomService;
    private apiBase: string = "https://api-iam.intercom.io";
    private apiId: string = 'iq3rg8xd';
    private identityVerificationHashKey: string = process.env.INTERCOM_IDENTITY_VERIFICATION_HASH_KEY ?? '';
    private hideDefaultLauncher: boolean = true;
    private intercomInstance = (window as any).Intercom;
    private userUpdated: boolean;

    private constructor() {
        this.userUpdated = false;
        IntercomService.instance = this;
    }

    public static getInstance() {
        if (!IntercomService.instance)
            IntercomService.instance = new IntercomService();
        return IntercomService.instance;
    }

    public boot() {
        this.intercomInstance('boot', {
            api_base: this.apiBase,
            app_id: this.apiId,
            hide_default_launcher: this.hideDefaultLauncher,
        });
    }

    public update(data?: IIntercomSettings & IIntercomUserData, updateUser: boolean = false) {
        if (!data) {
            this.intercomInstance('update');
            return;
        }

        this.intercomInstance('update', {
            ...data,
            user_hash: crypto.createHmac('sha256', this.identityVerificationHashKey).update(data.email).digest('hex')
        });
        if (updateUser)
            this.userUpdated = true;
    }

    public shutdown() {
        this.intercomInstance('shutdown');
        this.userUpdated = false;
    }

    public startTour(tourId: number) {
        this.intercomInstance('startTour', tourId);
    }

    public hide() {
        this.intercomInstance('hide');
    }

    public show() {
        this.intercomInstance('show');
    }

    public isUserUpdated() {
        return this.userUpdated;
    }
}

export default IntercomService;
