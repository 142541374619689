import { Capacitor } from '@capacitor/core';
import appConfig from 'application.json';
import { IListCardInformation } from 'components/small/list-card/ListCardProps';
import { ADMINISTRATOR_ID, BENZEMA_CAMPAIGN_DATE, CURRENCIES_ENUM, CURRENCIES_SYMBOL, FIRST_TOURNAMENT_WITH_LEAGUE_DATE, LANGUAGES, LOCAL_STORAGE_KNOWN_KEYS, NUMBER_OF_TOURNAMENT_BY_WEEK, USER_METADATA } from 'config/_const';
import { TournamentEntryType, TournamentLeagues } from 'config/_enums';
import dayjs from 'dayjs';
import ApplicationSportVersion from 'model/ApplicationSportVersion/ApplicationSportVersion';
import IGiftReward from 'model/Gift/IGiftReward';
import IPartnerList from 'model/PartnerList/IPartnerList';
import IRegisterStep from 'model/RegisterStep/IRegisterStep';

import IScoreBoosterConfiguration from 'model/AppConfiguration/ScoreBooster/IScoreBoosterConfiguration';
import { IRegisterArticle } from 'model/RegisterArticle/IRegisterArticle';
import ITournamentLeague from 'model/Tournament/ITournamentLeague';
import IUserPersonalTournamentConfiguration from 'model/Tournament/PersonalTournament/IUserPersonalTournamentConfiguration';
import moment from 'moment';
import IGeoIP from 'service/geoip/IGeoIP';
import Utils from 'utils/Utils';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';
import IGiftAtFirstPayment from './model/Gift/IGiftAtFirstPayment';
import UserInterface from './model/User/UserInterface';

interface IApplicationSpecificConfiguration {
    manifestPath: string;
    appTitle: string;
    appBrand: string;
    top50VideoBanner: string;
    top50VideoIdEn: string;
    top50VideoIdFr: string;
    instructionVideoIdFr: string;
    instructionVideoIdEn: string;
    menuLogo: string;
    darkMenuLogo: string;
    showSocialNetworks: boolean;
    menuEntriesToExclude: string[];
    hideCategoriesOnTop50: boolean;
    showLegalNotice: boolean;
    currency: CURRENCIES_ENUM;
    currencySymbol: string;
    tournamentBannerImage: string;
    showAppleLoginButton: boolean;
    showOrderbook: boolean;
    enableKYCAiPrise: boolean;
    showCrispChat: boolean;
    showWhatsAppChat: boolean;
    whatsAppLink: string;
    showGoogleLoginButton: boolean;
    showTopInvestorsList: boolean;
    topInvestorsLimit: number;
    tournamentsPerYear: number;
    averageSharePrice: number;
    defaultPrizePool: number;
    tournamentLeagues: ITournamentLeague[];
    showFacebookLoginButton: boolean;
    maxBuyPerUserRatio: number;
    enablePurchaseLimit: boolean;
    showGamblingOnNativeDevice: boolean;
    defaultCountry: string;
    defaultLanguage: string;
    defaultTimezone: string;
    defaultCurrency: string;
    defaultLocale: string;
    contryWithoutYield: string[];
    giftRewards: IGiftReward;
    skipPrimaryMarket: boolean;
    minutesFromSoldOutDateWhichUserCantSell: number;
    whitelist: IWhiteListConfiguration;
    christmasBonus: IChristmasBonus;
    packs: IPacksConfiguration;
    useNewLogoLoader: boolean;
    shouldAvoidPriceRangeRoundUpError: boolean;
    partnerList: IPartnerList[];
    statListTrendex: IListCardInformation[];
    registerSteps: Record<string, IRegisterStep[]>;
    registerBlogArticles: IRegisterArticle[];
    registerPressArticles: IRegisterArticle[];
    showTournamentResults: boolean;
    teamScoreBoost: IScoreBoosterConfiguration;
    priceEvolution24hr: { isEnabled: boolean; };
    isRewardDebtEnabled: boolean;
    showAchievementInProfile: boolean;
    assetScoreBoostedDays: number;
    tournamentReward: ITournamentReward;
    personalTournamentConfig: IUserPersonalTournamentConfiguration;
    onBoardingV2: boolean;
    tournamentLeagueLogo: string;
    footballEuro: IFootballEuroConfiguration;
    tournament: ITournamentTypeConfiguration
}

interface ITournamentCashprizeRepartitionConfiguration {
    multiplierForPodium: number;
    multiplierForPlayers: number;
    multiplierForFirst: number;
    multiplierForSecond: number;
    multiplierForThird: number;
    defaultCashprizeMultiplier: number;
}

interface ITournamentConfiguration {
    cashprizeRepartition: ITournamentCashprizeRepartitionConfiguration;
}
interface ITournamentTypeConfiguration {
    [TournamentEntryType.FREE]: ITournamentConfiguration;
    [TournamentEntryType.PAID]: ITournamentConfiguration;
}

interface IFootballEuroConfiguration {
    [key: string]: IFootballEuro;
}

interface IFootballGains {
    quarterFinal: number,
    semiFinal: number,
    final: number;
}

interface IFootballRules {
    normal: number,
    prolongations: number,
    penalties: number;
}

interface IFootballEuro {
    enabled: boolean;
    gains: IFootballGains;
    rules: IFootballRules;
}

interface ITournamentReward {
    giveAssets: IAttributeTokens;
}

interface IAttributeTokens {
    enabled: boolean;
    maxDifferentAssets: number;
    percentageEachLeagues: number;
    rewardPercentageToTransformIntoAssets: number;
}

interface IApplicationConfiguration {
    application: string;
    enabled: boolean;
    configuration: IApplicationSpecificConfiguration;
}

interface IApplicationConfigurations {
    configurations: IApplicationConfiguration[];
}

interface IWhiteListConfiguration {
    asset: string;
    enabled: boolean;
    daysBeforeLaunch: number;
    maxTokens: number;
    daysBeforeEarlyBird: number;
}

interface IChristmasBonus {
    assetIds: string[];
}

interface IPacksConfiguration {
    enabled: boolean;
    asset: string;
    tokens: number;
    numberOfPacksAvailable: number;
    endsInDaysBeforeLaunch: number;
}

export default class Application {
    private static instance: Application;
    private static defaultApplicationName: string = 'Trendex';
    private currentConfiguration: IApplicationSpecificConfiguration;
    private _internalCount: number;
    private isAndroidBrowser: boolean;
    private userAgent: string = navigator.userAgent.toLowerCase();
    private isCommingFromBrowserSnap: boolean = this.userAgent.includes('snapchat');
    private isAndroidDevice: boolean = this.userAgent.includes('android');
    private sportVersion: ApplicationSportVersion = ApplicationSportVersion.getInstance();

    private constructor() {
        const platform = Capacitor.getPlatform();
        this.isAndroidBrowser = platform === 'android'; // Error when trying to access const DEVICE_PLATFORM, ReferenceError: Cannot access 'DEVICE_PLATFORM' before initialization
        const applicationConfigurations: IApplicationConfigurations = JSON.parse(JSON.stringify(appConfig));
        const enabledConfiguration: IApplicationConfiguration | undefined = applicationConfigurations.configurations.find((conf: IApplicationConfiguration) => conf.enabled);
        if (!enabledConfiguration) {
            const trendexConfiguration: IApplicationConfiguration | undefined = applicationConfigurations.configurations.find((conf: IApplicationConfiguration) => conf.application === Application.defaultApplicationName);
            if (!trendexConfiguration)
                throw Error('Unable to configure application. No Trendex application definition found');
            this.currentConfiguration = trendexConfiguration.configuration;
        } else {
            this.currentConfiguration = enabledConfiguration.configuration;
        }
        Application.instance = this;
        this._internalCount = 0;
    }

    public static getInstance(): Application {
        return Application.instance ? Application.instance : new Application();
    }

    public getSortedTournamentLeagues(isAsc: boolean = true, cashprize?: number): LeagueViewModel[] {
        const tournamentLeagues = [...this.getLeagues(cashprize)];
        return tournamentLeagues.sort(
            (leagueA: LeagueViewModel, leagueB: LeagueViewModel) =>
                isAsc
                    ? leagueA.MinWalletShares - leagueB.MinWalletShares
                    : leagueB.MinWalletShares - leagueA.MinWalletShares
        );
    }

    public getLeagues(tournamentPrizePool?: number): LeagueViewModel[] {
        return this.currentConfiguration.tournamentLeagues.map((entry: ITournamentLeague) => new LeagueViewModel({ ...entry, prizePool: (tournamentPrizePool ?? this.defaultPrizePool).multiply(entry.cashprizeChunk) }));
    }

    public getLeagueFromName(leagueName: TournamentLeagues, tournamentPrizePool?: number): LeagueViewModel {
        const league = this.currentConfiguration.tournamentLeagues.find((entry: ITournamentLeague) => entry.name === leagueName) as ITournamentLeague;
        return new LeagueViewModel({
            ...league,
            prizePool: (tournamentPrizePool ?? this.defaultPrizePool).multiply(league.cashprizeChunk)
        });
    }

    public get manifestPath() {
        return this.currentConfiguration.manifestPath;
    }

    public get title() {
        return this.currentConfiguration.appTitle;
    }

    public get brand() {
        return this.currentConfiguration.appBrand;
    }

    public get top50VideoBanner() {
        return this.currentConfiguration.top50VideoBanner;
    }

    public get top50VideoIdEn() {
        return this.currentConfiguration.top50VideoIdEn;
    }

    public get top50VideoIdFr() {
        return this.currentConfiguration.top50VideoIdFr;
    }

    public get instructionVideoIdEn() {
        return this.currentConfiguration.instructionVideoIdEn;
    }

    public get instructionVideoIdFr() {
        return this.currentConfiguration.instructionVideoIdFr;
    }

    public get menuLogo() {
        return this.currentConfiguration.menuLogo;
    }

    public get darkMenuLogo() {
        return this.currentConfiguration.darkMenuLogo;
    }

    public get showSocialNetworks() {
        return this.currentConfiguration.showSocialNetworks;
    }

    public get menuEntriesToExclude() {
        return this.currentConfiguration.menuEntriesToExclude;
    }

    public get hideCategoriesOnTop50() {
        return this.currentConfiguration.hideCategoriesOnTop50;
    }

    public get showLegalNotice() {
        return this.currentConfiguration.showLegalNotice;
    }

    public get currency(): CURRENCIES_ENUM {
        const localAuth: string | null = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_AUTH);
        if (localAuth) {
            const localCurrency: CURRENCIES_ENUM | undefined = JSON.parse(localAuth).currency;
            if (localCurrency && localCurrency !== this.currentConfiguration.currency)
                return localCurrency;
            return this.currentConfiguration.currency;
        }
        return Utils.getMetadataForFakeUser(USER_METADATA.CURRENCY) as CURRENCIES_ENUM;
    }

    public get currencySymbol() {
        return CURRENCIES_SYMBOL[this.currency] || CURRENCIES_SYMBOL.EUR;
    }

    public get tournamentBannerImage() {
        return this.currentConfiguration.tournamentBannerImage;
    }

    public get showAppleLoginButton() {
        if (this.isAndroidBrowser || this.isAndroidDevice || (this.isAndroidDevice && this.isCommingFromBrowserSnap))
            return false;
        return this.currentConfiguration.showAppleLoginButton;
    }

    public get showGoogleLoginButton() {
        if (this.isAndroidDevice || this.isAndroidDevice && this.isCommingFromBrowserSnap)
            return false;
        return this.currentConfiguration.showGoogleLoginButton;
    }

    public get showCrispChat() {
        return this.currentConfiguration.showCrispChat;
    }

    public get showWhatsAppChat() {
        return this.currentConfiguration.showWhatsAppChat;
    }

    public get whatsAppLink() {
        return this.currentConfiguration.whatsAppLink;
    }

    public get showFacebookLoginButton() {
        if (this.isAndroidDevice && this.isCommingFromBrowserSnap)
            return false;
        return this.currentConfiguration.showFacebookLoginButton;
    }

    public get showTopInvestorsList() {
        return this.currentConfiguration.showTopInvestorsList;
    }

    public get tournamentsPerYear() {
        return this.currentConfiguration.tournamentsPerYear;
    }

    public get averageSharePrice() {
        return this.currentConfiguration.averageSharePrice;
    }

    public get defaultPrizePool() {
        return this.currentConfiguration.defaultPrizePool;
    }

    public get topInvestorsLimit() {
        return this.currentConfiguration.topInvestorsLimit;
    }

    public incrementInternalCount() {
        ++this._internalCount;
    }

    public get internalCount() {
        return this._internalCount;
    }

    public resetCount() {
        this._internalCount = 0;
    }

    public getTop50VideoId(): string {
        if (Utils.getCurrentLanguage() !== LANGUAGES.FR)
            return this.top50VideoIdEn;
        return this.top50VideoIdFr;
    }

    public getInstructionVideoId(): string {
        if (Utils.getCurrentLanguage() !== LANGUAGES.FR)
            return this.instructionVideoIdEn;
        return this.instructionVideoIdFr;
    }

    public get showOrderbook() {
        return this.currentConfiguration.showOrderbook;
    }

    public get enableKYCAiPrise() {
        return this.currentConfiguration.enableKYCAiPrise;
    }

    public get maxBuyPerUserRatio() {
        return this.currentConfiguration.maxBuyPerUserRatio;
    }

    public get enablePurchaseLimit() {
        return this.currentConfiguration.enablePurchaseLimit;
    }

    public get showGamblingFeature(): boolean {
        return !Capacitor.isNativePlatform() || this.currentConfiguration.showGamblingOnNativeDevice;
    }

    public isPurchaseLimitOnPrimaryMarketEnabled = (userId: string | undefined) => {
        if (!userId)
            return this.enablePurchaseLimit;
        return this.enablePurchaseLimit && (userId !== ADMINISTRATOR_ID);
    };

    public get defaultGeoIP(): IGeoIP {
        return {
            country: this.currentConfiguration.defaultCountry,
            language: this.currentConfiguration.defaultLanguage,
            timezone: this.currentConfiguration.defaultTimezone,
            currency: this.currentConfiguration.defaultCurrency,
            locale: this.currentConfiguration.defaultLocale,
        };
    }

    public get ContryWithoutYield(): string[] {
        return this.currentConfiguration.contryWithoutYield;
    }

    public get SkipPrimaryMarket(): boolean {
        return this.currentConfiguration.skipPrimaryMarket;
    }

    public get MinutesFromSoldOutDateWhichUserCantSell(): number {
        return this.currentConfiguration.minutesFromSoldOutDateWhichUserCantSell;
    }

    public get GiftRewardsAtFirstDeposit(): IGiftAtFirstPayment {
        return this.currentConfiguration.giftRewards.firstPayment;
    }

    public get WhiteListConfiguration(): IWhiteListConfiguration {
        return this.currentConfiguration.whitelist;
    }

    public get ChristmasBonusConfiguration(): IChristmasBonus {
        return this.currentConfiguration.christmasBonus;
    }

    public get PacksConfiguration(): IPacksConfiguration {
        return this.currentConfiguration.packs;
    }

    public get PacksTotalSupply(): number {
        return this.PacksConfiguration.tokens.multiply(this.PacksConfiguration.numberOfPacksAvailable);
    }

    public get UseNewLogoLoader(): boolean {
        return this.currentConfiguration.useNewLogoLoader;
    }

    public get IsRegisterDuringBenzemaCampaign(): boolean {
        const authFromLocalStorage: string | null = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_AUTH);
        if (!authFromLocalStorage)
            return true;
        const parsedAuth: UserInterface = JSON.parse(authFromLocalStorage);
        const createdAt: moment.Moment = moment(parsedAuth.createdAt);
        return createdAt.isSameOrAfter(BENZEMA_CAMPAIGN_DATE);
    }

    public get IsInjectedAsIframe(): boolean {
        return Boolean(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.LANDING_IFRAME) ?? '0'));
    }

    public get ReCaptchaKey(): string {
        return process.env.REACT_APP_RECAPTCHA_SITEKEY ?? '';
    }

    public get PartnerList(): IPartnerList[] {
        return this.currentConfiguration.partnerList;
    }

    public get StatListTrendex(): IListCardInformation[] {
        return this.currentConfiguration.statListTrendex;
    }

    public getRegisterStepBySport(sportType: string): IRegisterStep[] {
        return this.currentConfiguration.registerSteps[sportType];
    }

    public getRegisterBlogArticles(): IRegisterArticle[] {
        return this.currentConfiguration.registerBlogArticles;
    }

    public getRegisterPressArticles(): IRegisterArticle[] {
        return this.currentConfiguration.registerPressArticles;
    }

    public get ShouldAvoidPriceRangeRoundUpError(): boolean {
        return this.currentConfiguration.shouldAvoidPriceRangeRoundUpError;
    }

    public get ShowTournamentResults(): boolean {
        return this.currentConfiguration.showTournamentResults;
    }
    public get IsTeamScoreBoostConfigured(): boolean {
        return this.currentConfiguration.teamScoreBoost.isEnabled;
    }

    public get DefaultAdditionalScorePoints(): number {
        return this.currentConfiguration.teamScoreBoost.defaultPoint;
    }

    public get ScoreBoosterConfiguration(): IScoreBoosterConfiguration {
        return this.currentConfiguration.teamScoreBoost;
    }

    public get IsRandomizedScoreBoosterActivate(): boolean {
        return this.ScoreBoosterConfiguration.randomized.enabled;
    }

    public get MaxScoreBoosterPoints(): number {
        if (this.IsRandomizedScoreBoosterActivate)
            return this.ScoreBoosterConfiguration.randomized.points[1];
        return this.DefaultAdditionalScorePoints;
    }

    public get UserPersonalTournamentConfig(): IUserPersonalTournamentConfiguration {
        return this.currentConfiguration.personalTournamentConfig;
    }

    public get SportVersion(): ApplicationSportVersion {
        return this.sportVersion;
    }

    public get IsPriceEvolution24hrConfigured(): boolean {
        return this.currentConfiguration.priceEvolution24hr.isEnabled;
    }

    public getTotalCashprize(): number {
        const now = dayjs();
        const numberOfWeeks = now.diff(dayjs(FIRST_TOURNAMENT_WITH_LEAGUE_DATE), 'weeks');
        return numberOfWeeks * NUMBER_OF_TOURNAMENT_BY_WEEK * this.defaultPrizePool;
    }

    public get IsRewardDebtEnabled(): boolean {
        return this.currentConfiguration.isRewardDebtEnabled;
    }

    public get showAchievementInProfile(): boolean {
        return this.currentConfiguration.showAchievementInProfile;
    }

    public get AssetScoreBoostedDays(): number {
        return this.currentConfiguration.assetScoreBoostedDays;
    }

    public get TournamentRewardConfiguration(): ITournamentReward {
        return this.currentConfiguration.tournamentReward;
    }

    public get OnBoardingV2(): boolean {
        return this.currentConfiguration.onBoardingV2;
    }

    public get TournamentLeagueLogo(): string {
        return this.currentConfiguration.tournamentLeagueLogo;
    }

    public IsFootballEuroMode(year: number = new Date().getFullYear()): boolean {
        return this.currentConfiguration.footballEuro[year.toString()].enabled;
    }

    public FootballEuroGains(year: number = new Date().getFullYear()): IFootballGains {
        return this.currentConfiguration.footballEuro[year.toString()].gains;
    }

    public FootballEuroRules(year: number = new Date().getFullYear()): IFootballRules {
        return this.currentConfiguration.footballEuro[year.toString()].rules;
    }

    public getTournamentConfiguration(tournamentType: TournamentEntryType): ITournamentConfiguration {
        return this.currentConfiguration.tournament[tournamentType];
    }
}