import { SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import AffiliateBannerEn from "assets/images/Affiliate/en/affiliate-banner-en.png";
import AffiliateBannerEs from "assets/images/Affiliate/es/affiliate-banner-es.png";
import AffiliateBannerFr from "assets/images/Affiliate/fr/affiliate-banner-fr.png";
import BenzemaBannerEn from 'assets/images/banner-carousel/en/EN-BANZEMA_Landing_page.webp';
import CommunityEn from "assets/images/banner-carousel/en/discord-en.webp";
import WelcomeEn from "assets/images/banner-carousel/en/main-en.webp";
import { default as NewsEn, default as NewsEs } from "assets/images/banner-carousel/en/news-en.webp";
import LeaguesEn from "assets/images/banner-carousel/en/reward-en.webp";
import RudyEn from "assets/images/banner-carousel/en/rudy-en.webp";
import BenzemaBannerEs from 'assets/images/banner-carousel/es/ES-BANZEMA_Landing_page.webp';
import CommunityEs from "assets/images/banner-carousel/es/discord-es.webp";
import WelcomeEs from "assets/images/banner-carousel/es/main-es.webp";
import LeaguesEs from "assets/images/banner-carousel/es/reward-es.webp";
import RudyEs from "assets/images/banner-carousel/es/rudy-es.webp";
import BenzemaBannerFr from 'assets/images/banner-carousel/fr/Fr-BANZEMA_Landing_page.webp';
import CommunityFr from "assets/images/banner-carousel/fr/discord-fr.webp";
import WelcomeFr from "assets/images/banner-carousel/fr/main-fr.webp";
import NewsFr from "assets/images/banner-carousel/fr/news-fr.webp";
import LeaguesFr from "assets/images/banner-carousel/fr/reward-fr.webp";
import RudyFr from "assets/images/banner-carousel/fr/rudy-fr.webp";
import Slogan from "assets/images/banner-carousel/own-the-stars.webp";
import DefaultAvatar from 'assets/images/sidebar/profile-picture.jpg';
import IMenuItem from 'components/small/dropdown-menu/IMenuItem';
import IYieldConfigPerAsset from 'model/AbTesting/Yield/IYieldConfigPerAsset';
import { AssetCardLevelName, AssetCardLevelNamePlurial } from 'model/AssetModel/AssetCardLevelName';
import ICacheRequest from 'model/ICacheRequest';
import { IScoreBoost } from 'model/Tournament/ITournamentSubscription';
import { AssetCardLevel, AssetCardLevelPlurial, AssetCategory, AssetCurveType, ConnectionMode, OnboardingStep } from './_enums';

import GetStartedEn from 'assets/images/banner-carousel/en/get-started-en.png';
import GetStartedEs from 'assets/images/banner-carousel/es/get-started-es.png';
import GetStartedFr from 'assets/images/banner-carousel/fr/get-started-fr.png';

import FiveKEn from 'assets/images/banner-carousel/en/fivek-en.png';
import FiveKEs from 'assets/images/banner-carousel/es/fivek-es.png';
import FiveKFr from 'assets/images/banner-carousel/fr/fivek-fr.png';

import ReferralEn from "assets/images/banner-carousel/en/referral-en.png";
import ReferralEs from "assets/images/banner-carousel/es/referral-es.png";
import ReferralFr from "assets/images/banner-carousel/fr/referral-fr.png";

import KbAmbassadorEn from "assets/images/banner-carousel/en/kb-ambassador-en.png";
import KbAmbassadorEs from "assets/images/banner-carousel/es/kb-ambassador-es.png";
import KbAmbassadorFr from "assets/images/banner-carousel/fr/kb-ambassador-fr.png";

import MarcusSmartBannerEn from "assets/images/banner-carousel/en/marcus-smart-banner-min.png";
import MarcusSmartBannerEs from "assets/images/banner-carousel/es/marcus-smart-banner-min.png";
import MarcusSmartBannerFr from "assets/images/banner-carousel/fr/marcus-smart-banner-min.png";

import WembyBannerEn from "assets/images/banner-carousel/en/wemby-banner-min.png";
import WembyBannerEs from "assets/images/banner-carousel/es/wemby-banner-min.png";
import WembyBannerFr from "assets/images/banner-carousel/fr/wemby-banner-min.png";

import TrendexUsaEn from "assets/images/banner-carousel/en/trendex-usa-min.png";
import TrendexUsaEs from "assets/images/banner-carousel/es/trendex-usa-min.png";
import TrendexUsaFr from "assets/images/banner-carousel/fr/trendex-usa-min.png";

import BannerEuro2024En from "assets/images/banner-carousel/en/banner-euro-2024.webp";
import BannerEuro2024Es from "assets/images/banner-carousel/es/banner-euro-2024.webp";
import BannerEuro2024Fr from "assets/images/banner-carousel/fr/banner-euro-2024.webp";


/**
 * Differente type of an select
 */

export enum WRAPPER_SELECT_TYPE {
    NATIVE = 'NATIVE',
    MATERIAL = 'MATERIAL',
}

export enum TRENDEX_LANGUAGES {
    ENGLISH = 'en',
    FRENCH = 'fr',
    SPANISH = 'es',
}

export enum TRENDEX_ROLES {
    USER = 'USER',
    VIP = 'VIP',
    ADMIN = 'ADMIN',
}

export const TRENDEX_ROLES_ENUM: any = {
    USER: 0,
    VIP: 90,
    ADMIN: 999,
};

export enum VisibilityEnum {
    // ALL = 'ALL',
    HIDDEN = 'HIDDEN',
    SIDE_MOBILE = 'SIDE_MOBILE',
    SIDE_DESKTOP = 'SIDE_DESKTOP',
    BOTTOM = 'BOTTOM',
}

export enum VERIFICATION_STATUS {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    REFUSED = 'REFUSED',
}

export enum VERIFICATION_ACTION {
    VALIDATE = 'validate',
    REFUSE = 'refuse',
}

export const TRANSACTION_SOURCE = {
    REFERRAL: 'REFERRAL',
    PAYPAL: 'PAYPAL',
    STRIPE: 'STRIPE',
    BITPAY: 'BITPAY',
    MARKETPLACE: 'MARKETPLACE',
    INITIAL_SUPPLY: 'INITIAL_SUPPLY',
    WITHDRAW: 'WITHDRAW',
    FEE: 'FEE',
    REWARD: 'REWARD',
    PARTNERSHIP: 'PARTNERSHIP',
    YIELD: 'YIELD',
    SECONDARY_MARKET: 'SECONDARY_MARKET',
    STACKING: 'STACKING',
    END_OF_INITIAL_SALE: 'END_OF_INITIAL_SALE',
    REGISTER_WITH_REFERRAL: 'REGISTER_WITH_REFERRAL',
    REGISTER_WITHOUT_REFERRAL: 'REGISTER_WITHOUT_REFERRAL',
    GIFT_FIRST_STRIPE_PAYMENT: 'FIRST_STRIPE_PAYMENT',
    DISCORD_GIVEWAY: 'DISCORD_GIVEWAY',
    MANUAL_TOURNAMENT_ADMIN_REFUND: 'MANUAL_TOURNAMENT_ADMIN_REFUND',
    TWITTER_GIVEWAY: 'TWITTER_GIVEWAY',
    DELTA_BETWEEN_ORDER_PRICES: 'SECONDARY_MARKET_SLEEPAGE',
    NEGATIVE_DELTA_BETWEEN_ORDER_PRICES: 'NEGATIVE_SECONDARY_MARKET_SLEEPAGE',
    INITIAL_SUPPLY_TRENDEX_PART: 'INITIAL_SUPPLY_TRENDEX_PART',
    REGULATION_SECONDARY_MARKET: 'REGULATION_SECONDARY_MARKET',
    SECONDARY_MARKET_NEGATIVE_SPREAD_REGULATION: 'SECONDARY_MARKET_NEGATIVE_SPREAD_REGULATION',
    REFERRAL_BONUS: 'REFERRAL_BONUS',
    TRANSFERS_CREDITS: 'TRANSFERS_CREDITS',
    MISSION: 'MISSION',
    LIQUIDITY_POOL: 'LIQUIDITY_POOL',
    REGULATION_LIQUIDITY_POOL: 'REGULATION_LIQUIDITY_POOL',
    ADMIN_REGULATION: 'ADMIN_REGULATION'
};

export const TRANSACTION_SOURCE_LABEL: { [key: string]: string; } = {
    PAYPAL: 'Payment.paypal',
    WITHDRAW: 'transaction.withdraw',
    FEE: 'royalties',
    REWARD: 'transaction.tournament-reward',
    REGISTER: 'reward.register',
    YIELD: 'yield',
    REFERRAL: 'referral.source',
    PARTNERSHIP: "partnership"
};

export const ASSET_TRANSACTIONS_STATUS = {
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    ERROR: 'ERROR',
    PRICE_CHANGED: 'PRICE_CHANGED',
    ORDER_PENDING: 'PENDING_LIMIT_ORDER',
    ORDER_REMAINING: 'REMAINING_LIMIT_ORDER',
    ORDER_PICKED: 'PICKED_LIMIT_ORDER',
};

export const TRANSACTIONS_STATUS = {
    ACTIVE: 'ACTIVE',
    PENDING: 'PENDING',
    REFUSED: 'REFUSED',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
    OPEN: 'OPEN'
};
export const TRANSACTIONS_TYPE = {
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT',
};

export const ORDER_SIDE = {
    BUY: 'BUY',
    SELL: 'SELL',
};

export const VIP_MODE = localStorage.getItem('vipMode');

export const BETA_HOME_DEFAULT: Record<string, string> = {
    VIP_NO_ASSET_REQUEST: '/become-vip',
    VIP_ASSET_REQUEST: '/my-portfolio',
    USER: '/my-portfolio',
    ADMIN: '/',
};

export const HOME_DEFAULT: Record<string, string> = {
    VIP_NO_ASSET_REQUEST: '/become-vip',
    VIP_ASSET_REQUEST: '/',
    USER: '/',
    ADMIN: '/',
};

export const HOME_AFTER_SIGNUP: Record<string, string> = {
    VIP: '/become-vip?l=1',
    USER: '/',
};

export const MIN_PAYMENT_AMOUNT = 1;
export const MIN__BUY_CREDIT_PAYMENT_AMOUNT = 5;
export const MENU_MOBILE_BREAKPOINT = 1100;
export const DESKTOP_MODE_BREAKPOINT = 1200;

export const HTTP_STATUS_OK = 200;
export const TRENDEX_HTTP_STATUS_NOK = 400;
export const MINIMUM_NUMBER_OF_TOKEN_FOR_PRIVATE_SELL = 10000;
export const DEFAULT_LIQUIDITY_POOL_SUPPLY = 3000;
export const DEFAULT_LIQUDITIY_POOL_VALUE = 3000;
export const HTTP_STATUS_INTERNAL_ERROR = 500;

export const NOTIFICATION_STATUS_BLUE = 600;

export const HTTP_UNAUTHORIZED: number = 401;
export const AXIOS_STATUS_TEXT = {
    UNAUTHORIZED: 'Unauthorized',
};
export const SIGN_IN_WITH_APPLE_OPTIONS: SignInWithAppleOptions = {
    clientId: 'secret.trendex',
    redirectURI: 'we-use-another-service-for-web',
    scopes: 'email name',
    nonce: 'fds0fkpsokpokfdsitgjlmvbd77greijbvxk',
};

export const FACEBOOK_PERMISSIONS = ['email'];

export enum OauthProviderEnum {
    APPLE = 'APPLE',
    FACEBOOK = 'FACEBOOK',
    GOOGLE = 'GOOGLE',
}

export const DEFAULT_WALLET_TOTAL_VALUE_HISTORY = [{
    createdAt: new Date('10-08-2021'),
    value: 14,
},
{
    createdAt: new Date('11-08-2021'),
    value: 16,
},
{
    createdAt: new Date('12-08-2021'),
    value: 18,
},
{
    createdAt: new Date('13-08-2021'),
    value: 17,
},
{
    createdAt: new Date('14-08-2021'),
    value: 21,
}];

const GOOGLE_CANCEL_SIGN_IN_MESSAGE = 'The user canceled the sign-in flow.';
const APPLE_CANCEL_SIGN_IN_MESSAGE = 'The operation couldn’t be completed. (com.apple.AuthenticationServices.AuthorizationError error 1000.)';

export const CANCEL_SIGN_IN_MESSAGES = [GOOGLE_CANCEL_SIGN_IN_MESSAGE, APPLE_CANCEL_SIGN_IN_MESSAGE];

export const NUMBER_OF_HOURS_BEFORE_SORT_ASSET_BY_CURRENT_VALUE = 120;

export const PREDICT_PRICE_REQUEST_STATUS = {
    PENDING: 'pending',
    RESOLVED: 'resolved',
    REJECTED: 'rejected',
};

export const ORDER_CREATED_SUCCESSFULLY_STATUS = 'ORDER_SUCCESS';

// clé publique firebase
export const VAPID_KEY = 'BDKYRg2GM1-qnFvh7ukFi0JfulEYu80kvB7FHINPWSPNr3FafEXzTG080_ATcaqOiU-hiyGPNtDg6RIakeJYBGg';

export const GENERIC_TABLE_DEFAULT_ROW_PER_PAGE = 50;
export const GENERIC_TABLE_PAGINATION_IS_DISABLED_ROW_PER_PAGE = 1000000;

export const DIRECT_VIP_MODE_DURATION = 1200000; // 20 min in milliseconds

export const CREDIT_ORDER_TYPE = {
    LIMIT: 'LIMIT',
    MARKET: 'MARKET'
};

export const FILE_FORMAT_ALLOWED_FOR_IMAGE_UPLOAD = '.png,.jpg,.jpeg,.pdf';
export const CONNECTION_MODE: Record<ConnectionMode, ConnectionMode> = {
    [ConnectionMode.login]: ConnectionMode.login,
    [ConnectionMode.register]: ConnectionMode.register,
};

export const LOCAL_STORAGE_KNOWN_KEYS = {
    KNOWN_USER: 'KNOWN_USER',
    TDX_AUTH_STATE: 'tdx-auth-state',
    TDX_AUTH: 'tdx-auth',
    TDX_DEVICE_TOKEN: 'tdx-deviceToken',
    TDX_DIRECT_VIP_DATE: 'tdx-direct-vip-date',
    TDX_DIRECT_VIP_SLUG: 'tdx-direct-vip-slug',
    DEV_MODE: 'devMode',
    TDX_THEME: 'tdx-theme',
    TOKENS: 'tokens',
    LANG: 'lng',
    VIP_MODE: 'vipMode',
    CURRENT_MENU: 'current-menu',
    SECRET_CODE: 'secret-code',
    TDX_CREDITS_INTENT: 'tdx-credits-intent',
    TDX_REFERRAL: 'tdx-referral',
    TDX_SIGNUP_SOURCE_CHANNEL: 'tdx-signup-source-channel',
    TDX_SIGNUP_SOURCE_ID: 'tdx-signup-source-id',
    TDX_ANONYMOUS_GODSON_POPEDUP: 'tdx-godson-anonymous-popedup',
    TDX_GODSON_POPEDUP: 'tdx-godson-popedup',
    ABTESTING_REGISTER_CONNECTION_VARIANT: 'ab-testing-registered',
    ABTESTING_LOGIN_CONNECTION_VARIANT: 'ab-testing-login',
    ABTESTING_REGISTER_TRENDEX_APP_VARIANT: 'ab-testing-registered',
    ABTESTING_LOGIN_TRENDEX_APP_CONNECTION_VARIANT: 'ab-testing-login',
    ABTESTING_FIRST_VARIANT_DISPLAYED: 'ab-testing-first-variant-displayed',
    CONFIRM_TEAM_DISPLAYED: 'confirm-team-displayed',
    PORTFOLIO_ANONYMOUS_WANTS_TO_REFUND: 'portfolio-anonymous-wants-to-refund',
    BETA_APP: 'BETA-APP',
    REWARDS: 'REWARDS',
    TUTORIAL: 'TUTORIAL',
    GIFT_REGISTER: 'GIFT_REGISTER',
    SIGNUP_FROM_ORDERBOOK: 'SIGNUP_FROM_ORDERBOOK',
    INTENT_FROM_ORDERBOOK_BEFORE_SIGNUP: 'INTENT_FROM_ORDERBOOK_BEFORE_SIGNUP',
    ABTESTING_YIELD: 'ab-testing-yield',
    ASSET_WHITE_LIST: 'ASSET_WHITE_LIST',
    LAST_TOURNAMENT_TRANSACTION_ID: 'LAST_TOURNAMENT_TRANSACTION_ID',
    AVAILABLE_SOON_NOTIFICATION: 'AVAILABLE_SOON_NOTIFICATION',
    GIFT_REGISTER_CLAIMED: 'GIFT_REGISTER_CLAIMED',
    CURRENT_TRANSACTION_ASSET_ID: 'CURRENT_TRANSACTION_ASSET_ID',
    IS_LISTVIEW: "IS_LISTVIEW",
    IS_GRIDVIEW_IN_WALLET: "IS_GRIDVIEW_IN_WALLET",
    FOREX_RATES: "FOREX_RATES",
    FIRST_TIME_REGISTER_VIEW: "FIRST_TIME_REGISTER_VIEW",
    EMAIL_NOTIFICATION_ENABLED: "EMAIL_NOTIFICATION_ENABLED",
    SMS_NOTIFICATION_ENABLED: "SMS_NOTIFICATION_ENABLED",
    ALREADY_GET_LOGIN: "ALREADY_GET_LOGIN",
    EMAIL: "tdx-email",
    DIRECT_BUY: "tdx-direct-buy",
    FROM_LANDING: "FROM_LANDING",
    LANDING_IFRAME: 'iframe',
    BLOCKTALENTPAGE_IS_HIDDEN: 'blocktalentpage_is_hidden',
    TOURNAMENT_META_INFORMATION_HIDDEN: 'TOURNAMENT_META_INFORMATION_HIDDEN',
    SIGNUP_VERSION: 'v',
    FUNNEL_AI_PRISE_DONE: 'FUNNEL_AI_PRISE_DONE',
    USER_PERSONAL_TOURNAMENT_CREATION_CALLED: 'USER_PERSONAL_TOURNAMENT_CREATION_CALLED',
    USER_PERSONAL_TOURNAMENT: 'USER_PERSONAL_TOURNAMENT',
    FUN_ASSET_MODAL_SEEN: 'FUN_ASSET_MODAL_SEEN'
};

export const DEFAULT_SIGNUP_SOURCE_AND_ID = {
    SIGNUP_SOURCE_CHANNEL: 'DIRECT_URL',
    SIGNUP_SOURCE_ID: '1'
};

export const MOBILE_STORAGE_KEYS = {
    TDX_MOBILE_DEVICE_TOKEN_DATA: 'tdx-mobileDeviceTokenData',
};

export const LOCAL_STORAGE_KNOWN_VALUES = {
    PLAY_TO_EARN: 'P2E',
};

export const DISABLE_USER_BASE_URL = '/users/disableUser';
export const REACTIVATE_USER_BASE_URL = '/users/reactivateUser';
export const GET_USER_CREDITS = '/users/credits';
export const REFERRAL_TERMS_USER_BASE_URL = '/auth/acceptReferralTerms';
export const TERMS_USE_LINKS: { fr: string, en: string, es: string; } = {
    fr: "//tdx-legal.s3.eu-west-3.amazonaws.com/trendex-cguv-fr-20240819.pdf",
    en: "//tdx-legal.s3.eu-west-3.amazonaws.com/trendex-cguv-en-20240819.pdf",
    es: "//tdx-legal.s3.eu-west-3.amazonaws.com/trendex-cguv-es-20240819.pdf",
};
export const VIP_TERMS_USE_LINKS: { fr: string, en: string; } = {
    fr: "//tdx-legal.s3.eu-west-3.amazonaws.com/TRENDEX+-+Conditions+ge%CC%81ne%CC%81rales+d'utilisation+%26+de+vente+talents_2023+01+31.pdf",
    en: "//tdx-legal.s3.eu-west-3.amazonaws.com/TRENDEX+-+General+terms+and+conditions+of+sale+for+talents_2023+01+31.pdf",
};

export const LEGAL_NOTICE: { fr: string, en: string; } = {
    fr: '//tdx-legal.s3.eu-west-3.amazonaws.com/TRENDEX+-+Mentions+le%CC%81gales+2023+01+20.pdf',
    en: '//tdx-legal.s3.eu-west-3.amazonaws.com/TRENDEX+-+Legal+notice+2023+01+20.pdf',
};

export const LEGAL_RATES: { fr: string, en: string; } = {
    fr: '//tdx-legal.s3.eu-west-3.amazonaws.com/TRENDEX+-+Tarifs_2023+01+20.pdf',
    en: '//tdx-legal.s3.eu-west-3.amazonaws.com/TRENDEX+-+Rates_2023+01+20.pdf',
};
export const REFERRAL_TERMS_LINK = '//tdx-legal.s3.eu-west-3.amazonaws.com/TRENDEX+-+R%C3%A8glement+et+conditions+du+jeu+concours+du+10+au+13+juin+2022.pdf';

export const REFERRAL_DATA_LAST_DAYS = 30;

export const USER_SUBTYPES = {
    AGENT: 'AGENT',
    SUPERADMIN: 'SUPERADMIN',
    VIP_ADMIN: 'VIP_ADMIN',
};

export const AB_TESTING_EXPERIMENTS = {
    HOME: 'HomeExperiment',
};

export const REACT_AB_TEST_KNOWN_KEYS = {
    LOCAL_STORED_HOME_EXPERIMENT: `PUSHTELL-${AB_TESTING_EXPERIMENTS.HOME}`,
    TRENDEX_APP_VARIANT: 'TrendexApp',
    LOGIN_VARIANT: 'ForcedConnection',
};

export const MIXPANEL_KNOWN_KEYS = {
    TRACK: {
        CONNECTION_VARIANT_REGISTER: `${REACT_AB_TEST_KNOWN_KEYS.LOGIN_VARIANT}_Register`,
        CONNECTION_VARIANT_LOGIN: `${REACT_AB_TEST_KNOWN_KEYS.LOGIN_VARIANT}_Login`,
        CONNECTION_VARIANT_BUY_ON_PRIMARY_MARKET_NEW_USER: `${REACT_AB_TEST_KNOWN_KEYS.LOGIN_VARIANT}_primary_market_buy_new_user`,
        CONNECTION_VARIANT_BUY_ON_PRIMARY_MARKET_EXISTING_USER: `${REACT_AB_TEST_KNOWN_KEYS.LOGIN_VARIANT}_primary_market_buy_existing_user`,
        CONNECTION_VARIANT_BUY_ON_SECONDARY_MARKET_NEW_USER: `${REACT_AB_TEST_KNOWN_KEYS.LOGIN_VARIANT}_secondary_market_buy_new_user`,
        CONNECTION_VARIANT_BUY_ON_SECONDARY_MARKET_EXISTING_USER: `${REACT_AB_TEST_KNOWN_KEYS.LOGIN_VARIANT}_secondary_market_buy_existing_user`,
        TRENDEX_APP_VARIANT_REGISTER: `${REACT_AB_TEST_KNOWN_KEYS.TRENDEX_APP_VARIANT}_Register`,
        TRENDEX_APP_LOGIN: `${REACT_AB_TEST_KNOWN_KEYS.TRENDEX_APP_VARIANT}_Login`,
        TRENDEX_APP_BUY_ON_PRIMARY_MARKET_NEW_USER: `${REACT_AB_TEST_KNOWN_KEYS.TRENDEX_APP_VARIANT}_primary_market_buy_new_user`,
        TRENDEX_APP_BUY_ON_PRIMARY_MARKET_EXISTING_USER: `${REACT_AB_TEST_KNOWN_KEYS.TRENDEX_APP_VARIANT}_primary_market_buy_existing_user`,
        TRENDEX_APP_BUY_ON_SECONDARY_MARKET_NEW_USER: `${REACT_AB_TEST_KNOWN_KEYS.TRENDEX_APP_VARIANT}_secondary_market_buy_new_user`,
        TRENDEX_APP_BUY_ON_SECONDARY_MARKET_EXISTING_USER: `${REACT_AB_TEST_KNOWN_KEYS.TRENDEX_APP_VARIANT}_secondary_market_buy_existing_user`,
        USER_SESSION_STARTS: 'USER_SESSION_STARTS',
        USER_SESSION_ENDS: 'USER_SESSION_ENDS',
        CONNECTION_VARIANT_SESSION_STARTS: `${REACT_AB_TEST_KNOWN_KEYS.LOGIN_VARIANT}_UserSessionStarts`,
        TRENDEX_APP_SESSION_STARTS: `${REACT_AB_TEST_KNOWN_KEYS.TRENDEX_APP_VARIANT}_UserSessionStarts`,
    },
};

export const ID_FACES = {
    RECTO: 'RECTO',
    VERSO: 'VERSO',
};

export const ID_TYPES = {
    PASSPORT: 'PASSPORT',
    ID_CARD: 'ID_CARD',
};

export const G_TAGS_KNOWN_EVENTS = {
    ORDER_CONFIRMATION: 'Order_Confirmation',
    INSCRIPTION_CONFIRMATION: 'Inscription_Confirmation',
    ADD_TO_CART: 'add_to_cart',
    GET_SHARES: 'get_shares',
    DEPOSIT: 'deposit',
    LOGIN: 'login',
    PURCHASE: 'purchase',
    FIRST_PURCHASE: 'First Purchase',
    REFUND: 'refund',
    SIGNUP: 'signup',
    VIRTUAL_PAGE_VIEW: 'VirtualPageView',
    IDENTITY_VERIFICATION_REQUEST: 'identity_verification_request',//EMAIL VERIFICATION
    WITHDRAWAL_REQUEST: 'withdrawal_request',
    CREATE_TEAM: 'create_team',
    UPDATE_TEAM: 'update_team',
    TOURNAMENT_SUBSCRIBE: 'tournament_subscribe',
    CLAIM_TOURNAMENT_REWARD: 'claim_tournament_reward',
    REFERRAL_ZONE: 'referral_zone',
    COPYING_REFERRAL_LINK: 'copying_referral_link',
    REGISTER_PAGE: 'register_page',
};

export const DESABLED_GA_EVENTS = false;

export const SEGMENT_EVENTS = {
    PRODUCT_ADDED: 'Product Added',
    PRODUCT_CLICKED: 'Product Clicked',
    DEPOSIT: 'Deposit',
    LOGIN: 'login',
    ORDER_COMPLETED: 'Order Completed',
    SIGNUP: 'Signed Up',
    SIGNUP_NOT_COMPLETED: 'Signed Up Not Completed',
    VIRTUAL_PAGE_VIEW: 'VirtualPageView',
    IDENTITY_VERIFICATION_REQUEST: 'identity_verification_request',//EMAIL VERIFICATION
    WITHDRAWAL_REQUEST: 'withdrawal_request',
    CREATE_TEAM: 'create_team',
    UPDATE_TEAM: 'update_team',
    TOURNAMENT_SUBSCRIBE: 'tournament_subscribe',
    CLAIM_TOURNAMENT_REWARD: 'claim_tournament_reward',
    REFERRAL_ZONE: 'referral_zone',
    COPYING_REFERRAL_LINK: 'copying_referral_link',
    REGISTER_PAGE: 'register_page',
};

export const DATEPICKER = {
    FR: {
        DISPLAY_FORMAT: 'dd/MM/yyyy',
        SAVE_FORMAT: 'yyyy-MM-dd',
    },
    EN: {
        DISPLAY_FORMAT: 'MM/dd/yyyy',
        SAVE_FORMAT: 'yyyy-MM-dd',
    },
};

export const DISCORD_LINK = 'https://discord.gg/trendex';

export const GODFATHER_REFERRAL = {
    percentageDueByGodson: 10
};

export const BITPAY_URL_INTENT = {
    SEARCH_PARAM: 'redirectFrom',
    BITPAY_SUCCESS: 'bitpaySuccess',
    BITPAY_ERROR: 'bitpayError',
    REDIRECT_TO: 'redirectTo'
};

export const PEOPLE_COUNTER = {
    MIN_INTERVAL: 5,
    MAX_INTERVAL: 15,
    MIN_VISITOR: 1,
    MAX_VISITOR: 5,
    MORNING_HOUR: 7,
    MIDNIGHT_HOUR: 0,
    VARIATION_RATIO: 0.2,
    CONF: [
        {
            interval: [-30, -20],
            counterInterval: [30, 50],
            name: 'de 30min à 20min avant',
        },
        {
            interval: [-20, -15],
            counterInterval: [50, 70],
            name: 'de 20min à 15min avant',
        },
        {
            interval: [-15, -10],
            counterInterval: [70, 90],
            name: 'de 15min à 10min avant',
        },
        {
            interval: [-10, -6],
            counterInterval: [90, 110],
            name: 'de 10min à 6min avant',
        },
        {
            interval: [-6, -4],
            counterInterval: [110, 130],
            name: 'de 6min à 4min avant',
        },
        {
            interval: [-4, -2],
            counterInterval: [130, 150],
            name: 'de 4min à 2min avant',
        },
        {
            interval: [-2, -1],
            counterInterval: [150, 152, 154, 156, 158, 160, 162, 164, 166, 168, 170],
            name: '2min avant à 1min avant',
        },
        {
            interval: [-1, 0],
            counterInterval: [170, 172, 174, 176, 178, 180, 182, 184, 186, 188, 190, 192, 194, 196, 198, 200],
            name: '1min avant à la vente privée',
        },
        {
            interval: [0, 1],
            counterInterval: [200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 365, 370, 375, 380],
            name: 'de la vente privée à 1min après',
        },
        {
            interval: [1, 2],
            counterInterval: [380, 400, 420, 440, 460, 480, 500, 520, 540, 560, 580],
            name: 'de 1min à 2min après',
        },
        {
            interval: [2, 4],
            counterInterval: [580, 585, 590, 595, 600, 605, 610, 615, 620, 625, 630],
            name: 'de 2min à 4min après',
        },
        {
            interval: [4, 6],
            counterInterval: [620, 610, 600, 590, 580, 570],
            name: 'de 4min à 6min après',
        },
        {
            interval: [6, 8],
            counterInterval: [570, 560, 550, 540, 530, 520],
            name: 'de 6min à 8min après',
        },
        {
            interval: [8, 10],
            counterInterval: [520, 500, 480, 460, 440, 420],
            name: 'de 8min à 10min après',
        },
        {
            interval: [10, 12],
            counterInterval: [420, 400, 380, 360, 340, 320],
            name: 'de 10min à 12min après',
        },
        {
            interval: [12, 15],
            counterInterval: [320, 300, 280, 260, 240, 220],
            name: 'de 12min à 15min après',
        },
        {
            interval: [15, 20],
            counterInterval: [220, 200],
            name: 'de 15min à 20min après',
        },
        {
            interval: [20, 25],
            counterInterval: [200, 180],
            name: 'de 20min à 25min après',
        },
        {
            interval: [25, 30],
            counterInterval: [180, 160],
            name: 'de 25min à 30min après',
        },
        {
            interval: [30, 45],
            counterInterval: [160, 140],
            name: 'de 30min à 45min après',
        },
        {
            interval: [45, 60],
            counterInterval: [140, 120],
            name: 'de 45min à 1h après',
        },
        {
            interval: [60, 90],
            counterInterval: [120, 100],
            name: 'de 1h à 1h30 après',
        },
        {
            interval: [90, 120],
            counterInterval: [100, 80],
            name: 'de 1h30 à 2h après',
        },
        {
            interval: [120, 180],
            counterInterval: [80, 60],
            name: 'de 2h à 2h30 après',
        },
        {
            interval: [120, 180],
            counterInterval: [60, 50],
            name: 'de 2h30 à 3h après',
        },
        {
            interval: [180, 1440],
            counterInterval: [30, 50],
            name: 'de 3h à 1j après',
        },
    ],
};

export const GET_ENVORONMENT_BASE_URL = '/system/environment';

export const SECONDARY_MARKET_MATCHING_STRATEGIES = {
    NEEREST_FROM_CURRENT_VALUE: 'NEEREST_FROM_CURRENT_VALUE',
    FLOOR_PRICE: 'FLOOR_PRICE',
};

export const SECONDARY_MARKET_CURRENT_VALUE_COMPUTATION_METHODS = {
    MAX: 'MAX',
    AVG: 'AVG',
    MIN: 'MIN',
    ORDERS_PRICES_AVG: 'ORDERS_PRICES_AVG',
    ORDERBOOK_PONDERATED_AVG: 'ORDERBOOK_PONDERATED_AVG',
};
export const DEFAULT_AVATAR = DefaultAvatar;
export const URL_DEFAULT_AVATAR = "https://assetsimgupload.s3.us-east-2.amazonaws.com/profile-picture.jpg";
export const TEAM_STATUS = {
    ENGAGED: 'ENGAGED',
    DISENGAGED: 'DISENGAGED',
};

export const TEAM_DRAFT_ID = 'draft';
export const TEAM_INTERNAL_STATUS = {
    EDIT_IN_PROGRESS: 'EDIT_IN_PROGRESS',
    EDIT_DONE: 'EDIT_DONE',
};

export const TEAM_AUTOMATIC_GENERATION_BEHAVIOR = {
    FILL: 'FILL',
    REPLACE: 'REPLACE',
};

export const DEFAULT_ID_AS_STRING: string = '-1';
export const DEFAULT_ID_AS_NUMBER: number = -1;
export const MAX_BUY_AMOUNT: number = 1000;
export const DEFAULT_BUTTON_DEBOUNCE_IN_MS = 350;
export const TOURNAMENT_STATE = {
    OPENED: 'OPENED',
    CLOSED: 'CLOSED',
    PENDING: 'PENDING',
    NONE: 'NONE',
};

export const DEFAULT_NUMBER_OF_SHARES_FOR_TEAM: number = 1;

export enum CURRENCIES_ENUM {
    EUR = 'EUR',
    USD = 'USD',
    GBP = 'GBP',
    SAR = 'SAR'
};

export const MINIMUM_REFUND_AMOUNT: number = 50;
export const MAX_WITHDRAW_ONBOARDING_WITHOUT_KYC: number = 3;
export const HALF_WIDTH_ICON = {
    mobile: 20,
    desktop: 20,
};

export const REWARD_TRANSACTION_TYPE = {
    SENT: 'SENT',
    OPENED: 'OPENED',
    EXPIRED: 'EXPIRED',
};

export const TEAM_EDITOR_CONFIG = {
    CONFIG_SHOW_CATEGORIES: {
        MIN_NUMBER_OF_ASSETS: 8,
        MIN_NUMBER_OF_CATEGORIES: 1,
    },
};

export const SIGN_UP = "signup";
export const ASSET_PATH = "/asset";

export const URLS: Record<string, any> = {
    top50: '/top-50',
    tournament: '/tournament',
    portfolios: {
        mainPage: '/my-portfolio',
        openScoreBoosterCheckout: '/my-portfolio?openBoosterCheckout=true'
    },
    login: '/sign-in',
    profile: {
        mainPage: "/profile",
        editPage: "/profile?show=edit",
        identityModal: '/profile?show=edit&modal=open'
    },
    register: `/${SIGN_UP}`
};

export const TRENDEX_COOKIES = {
    JWT_TDX: {
        name: 'tdx',
        expiresAsDays: 180,
    },
    UNIQUE_USER_ID: {
        name: 'user-id',
    },

    TDX_COOKIE_CONSENT: 'tdx-cookie-consent',
};

export const TRENDEX_REQUEST_HEADERS = {
    AUTH_TOKEN: 'x-auth-token',
    UNIQUE_USER_ID: 'x-user-id'
};

export const CHECK_LIMIT_IN_SECONDS: number = 45;

export const ALL_CATEGORIES = 'all';

export const ASSET_DEFAULT_CATEGORY = {
    _id: 'all',
    banner: [],
    description: '',
    color: '',
    icon: 'TopTalents',
    name: 'Top Talents'
};

export const MENU_ITEMS_NAME = {
    TRADING: 'TRADING',
    MY_PROFILE: 'MY_PROFILE',
    MY_PERKS: 'MY_PERKS',
    WALLET: 'WALLET',
    TOURNAMENT: 'TOURNAMENT',
    MY_SALES: 'MY_SALES',
    AFFILIATION: 'AFFILIATION',
    ADVANTAGES: 'ADVANTAGES',
    FOLLOW_UP: 'FOLLOW_UP',
    HELP: 'HELP',
    BECOME_VIP: 'BECOME_VIP',
    ADMIN_VALIDATE_USER_ID: 'ADMIN_VALIDATE_USER_ID',
    ADMIN_ALL_WITHDRAW: 'ADMIN_ALL_WITHDRAW',
    ADMIN_ASSETS: 'ADMIN_ASSETS',
    ADMIN_INFLUENCER: 'ADMIN_INFLUENCER',
    ADMIN_ALL_TRANSACTIONS: 'ADMIN_ALL_TRANSACTIONS',
    ADMIN_ORDERS: 'ADMIN_ORDERS',
    ADMIN_FEES: 'ADMIN_FEES',
    ADMIN_STATS: 'ADMIN_STATS',
    ADMIN_TOURNAMENTS: 'ADMIN_TOURNAMENTS',
    VIP_ASSET: 'VIP_ASSET',
    HELP_DESK: "HELP_DESK"
};


export const LANGUAGES = { FR: 'fr', EN: 'en' };

export const PROD_ORIGIN = process.env.NODE_ENV === "production" ? 'app.trendex.vip' : 'integration-app.trendex.vip';

export const InternalCookieConsentVisibility = {
    SHOW: 'show',
    HIDDEN: 'hidden',
    BY_COOKIE_VALUE: 'byCookieValue',
};

export const DEVICE_PLATFORM = {
    ANDROID: 'android',
    IOS: 'ios',
    WEB: 'web',
};

export const COUNTRY = {
    DEFAULT: 'US',
    FRENCH: 'FR',
};


export const MY_PLAYERS_TAB_SECTION = {
    WITHDRAW: 'withdraws',
    TRANSACTION: 'transactions',
    ASSET_WALLET: 'wallet',
    PAYMENTS: 'payments',
};

export const YIELD_DEFAULT_CONFIG = {
    MIN: 0.022,
    MAX: 0.22,
};

export const DEFAULT_CONTRACT_TERM: number = 10;

export const HISTORY_CHART = {
    scoreScale: 1 / 3,
};

export const TOKEN_INFORMATION_CONFIG = {
    USER_ICON_SIZE: 18,
    SEEDLING_ICON_SIZE: 22,
    RATIO_TO_ACTIVATE_COLOR_DEGRADATION_OF_INDICATOR: 3,
};
export const HIDDEN_BOTTOM_MENU_PATHS = ['/profile', '/signup', '/login', '/become-vip', '/reset-password', '/sign-in', '/signup/vip', '/checkout/tournament/ticket'];

export const DISCORD_NAME: string = 'trendex';

export const TRANSACTION_VIEW_SIZE = 3;
export const PRIMARY_SIDE_MENU_COUNT = 3;

export const MAX_EARN_PER_INVITE = 5000;


export const CURRENCIES_SYMBOL: Record<CURRENCIES_ENUM, string> = {
    EUR: '€',
    USD: '$',
    GBP: '£',
    SAR: 'ر.س'
};

export const WITHDRAWS_TYPES = {
    SEPA: 'SEPA',
    NON_SEPA: 'NON_SEPA',
};

export const WITHDRAW_STATUS = {
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    OPEN: 'OPEN',
    USER_CANCELED: 'USER_CANCELED'
};

export const TOTAL_AMOUNT_OF_INCOME_PER_REFERRAL: number = 5000;

export const DEFAULT_TIMEZONE = 'Europe/Paris';

export const OPTIONS_FOR_TIME: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
};

export const OPTIONS_FOR_DATE: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
};

export const LANGUAGE_WITHOUT_REGIONAL_NAME: Record<string, string> = {
    'ar': 'ar-SA',
};

export const DEFAULT_CURRENCY: string = 'EUR';

export const USER_METADATA = {
    TIMEZONE: 'timezone',
    CURRENCY: 'currency',
    LANGUAGE: 'language',
    COUNTRY: 'country',
    LOCALE: 'locale',
};

export const ZERO: number = 0;
export const ONE: number = 1;
export const HUNDRED: number = 100;
export const HUNDRED_PERCENT: string = "100%";
export const ZERO_PERCENT: string = "0%";
export const EDIT: string = "edit";
export const OPEN: string = "open";

export const PRICE_SELL_STEPPER_FRACTION = 0.05;
export const ORDER_STATUS = {
    QUEUED: 'QUEUED',
    OPEN: 'OPEN',
    PICKED: 'PICKED',
    CANCELED: 'CANCELED',
    ERROR: 'ERROR',
};


export const WALLET_REFUND_OPTIONS: number[] = [20, 100, 150, 200];

export const YIELD_CONFIG = {
    TOP_ASSETS: {
        showYieldBubble: false,
    },
};

export const COLOR = {
    PRIMARY_VIOLET: '#6677F5',
    RED: 'rgb(255, 0, 0)',
    ORANGE: 'rgb(255, 165, 0)',
    LIMEGREEN: 'rgb(50, 205, 50)',
    WHITE: 'white',
    TRANSPARENT_WHITE: '#FFFFFF59',
    GREEN: "green"
};

export const MODAL_COLOR = {
    DARK: '#15182B',
    LIGHT: 'white',
    VERY_LIGHT_BLUE: '#F7F8FF',
    TRANSPARENT: 'transparent',
};

export const YIELD_CONFIG_PER_ASSET: IYieldConfigPerAsset[] = [
    {
        _id: '6437cbf1f5eaae0002c1355e',
        minYield: 0.022,
        maxYield: 6.25,
    },
    {
        _id: '6437d77af5eaae0002c8b645',
        minYield: 0.022,
        maxYield: 6.25,
    },
    {
        _id: '647e2a9320c292000735179a',
        minYield: 0.044,
        maxYield: 0.44,
    },
    {
        _id: '64798c61a8ecd1000783d1b8',
        minYield: 0.022 * 5,
        maxYield: 0.22 * 5
    },
    {
        _id: '64393b0887e7b800029aebf8',
        minYield: 0.022 * 5,
        maxYield: 0.22 * 5
    },
    {
        _id: '64b4fbda21b0720007800ef9',
        minYield: 0.022 * 69,
        maxYield: 0.22 * 69
    },
    {
        _id: '64be6d25888cf50007278c98',
        minYield: 0.022 * 2,
        maxYield: 0.22 * 2
    },
    {
        _id: '6439274487e7b8000291e9e0',
        minYield: 0.022 * 5,
        maxYield: 0.22 * 5
    },
    {
        _id: '64d0a7847beef600072d879c',
        minYield: 0.022 * 2,
        maxYield: 0.22 * 2
    },
    {
        _id: '64d392b98231ea00076bc126',
        minYield: 0.022 * 1.3,
        maxYield: 0.22 * 1.3
    },
    {
        _id: '64da2de35380bd000787a159',
        minYield: 0.022 * 1.3,
        maxYield: 0.22 * 1.3
    },
    {
        _id: '64ed036c8c6ba100084de687',
        minYield: 0.022 * 2,
        maxYield: 0.22 * 2
    },
    {
        _id: '64f04796c2f65c0013562285',
        minYield: 0.022 * 2,
        maxYield: 0.22 * 2
    },
    {
        _id: '65ccc88bd86503c918c624d1',
        minYield: 0.022 * 5,
        maxYield: 0.22 * 5
    },
];
export const MAX_ASSET_TRANSACTION_TOKENS = 1000;
export const NUMBER_OF_CHAR_BEFORE_VALID_ID_NUMBER = 5;
export const MINIMUM_WALLET_REFUND_AMOUNT = 5;
export const MAXIMUM_WALLET_REFUND_AMOUNT = 100000;
export const MAX_CURRENT_LENGTH: number = 9;

export const IRL_SCORE_DETAIL_NAME = {
    INSTAGRAM: 'INSTAGRAM',
};


export const TRANSACTION_PAGES_TRANSITION_TIME = 200;
export const ADMINISTRATOR_ID = process.env.NODE_ENV === 'production' ? '610bb2b4fb3a4f00040ae12c' : '614b37b9ec108c153b245eaa';

export enum VALUE_FORMAT_TYPE {
    CURRENCY = "CURRENCY",
    PERCENTAGE = "PERCENTAGE"
}

export const ACTIONS_RECAPTCHA = {
    REGISTER: 'REGISTER'
};

export const ENABLE_RECAPTCHA = false;
export const MINIMUM_EQUAL_VALUE_GRAPH_THRESHOLD: number = 5;
export const SCROLL_EFFECT_DESABLE: boolean = false;
export const HIDE_TRANSITION_OFFSET: number = 2;
export const SCROLL_TOP_LIMITED_TO_DISPLAY_BOTTOM_BAR: number = 100;
export const TOP_50_CAROUSEL_SLIDE_TRIGGER_TIME: number = 5000;
export const DEFAULT_CAROUSEL_SLIDE_TRANSITION_TIME: number = 400;
export const DEFAULT_CAROUSEL_SLOT_WIDTH_BY_PERCENTAGE: number = 100;
export const ASSET_SLIDE_UP_DURATION: number = 750;
export const ASSET_SLIDE_DOWN_DURATION: number = 500;

export const CACHING_REQUEST: ICacheRequest[] = [
];
export const DEFAULT_CACHE_ASSET: string = "defaultCacheAssets";
export const TOP_50_CAROUSEL_IMAGES: Record<string, any> = {
    EN: {
        SLOGAN: Slogan,
        RUDY: RudyEn,
        WELCOME: WelcomeEn,
        LEAGUES: LeaguesEn,
        COMMUNITY: CommunityEn,
        NEWS: NewsEn,
        REFERRAL: ReferralEn,
        BENZEMA: BenzemaBannerEn,
        GET_STARTED: GetStartedEn,
        FIVE_K: FiveKEn,
        KB_AMBASSADOR: KbAmbassadorEn,
        TRENDEX_USA: TrendexUsaEn,
        WEMBY_BANNER: WembyBannerEn,
        MARCUS_SMART_BANNER: MarcusSmartBannerEn,
        BANNER_EURO_2024: BannerEuro2024En
    },
    FR: {
        SLOGAN: Slogan,
        RUDY: RudyFr,
        WELCOME: WelcomeFr,
        LEAGUES: LeaguesFr,
        COMMUNITY: CommunityFr,
        NEWS: NewsFr,
        REFERRAL: ReferralFr,
        BENZEMA: BenzemaBannerFr,
        GET_STARTED: GetStartedFr,
        FIVE_K: FiveKFr,
        KB_AMBASSADOR: KbAmbassadorFr,
        TRENDEX_USA: TrendexUsaFr,
        WEMBY_BANNER: WembyBannerFr,
        MARCUS_SMART_BANNER: MarcusSmartBannerFr,
        BANNER_EURO_2024: BannerEuro2024Fr
    },
    ES: {
        SLOGAN: Slogan,
        RUDY: RudyEs,
        WELCOME: WelcomeEs,
        LEAGUES: LeaguesEs,
        COMMUNITY: CommunityEs,
        NEWS: NewsEs,
        REFERRAL: ReferralEs,
        BENZEMA: BenzemaBannerEs,
        GET_STARTED: GetStartedEs,
        FIVE_K: FiveKEs,
        KB_AMBASSADOR: KbAmbassadorEs,
        TRENDEX_USA: TrendexUsaEs,
        WEMBY_BANNER: WembyBannerEs,
        MARCUS_SMART_BANNER: MarcusSmartBannerEs,
        BANNER_EURO_2024: BannerEuro2024Es
    }
};

export const AFFILIATE_BANNER: Record<string, any> = {
    EN: AffiliateBannerEn,
    FR: AffiliateBannerFr,
    ES: AffiliateBannerEs,
};

export const KB_AMBASSADOR_URLS: Record<string, any> = {
    EN: "https://en.trendex.vip/post/karim-benzema-realise-son-premier-investissement-tech-en-rejoignant-trendex",
    FR: "https://www.trendex.vip/post/karim-benzema-realise-son-premier-investissement-tech-en-rejoignant-trendex",
    ES: "https://es.trendex.vip/post/karim-benzema-realise-son-premier-investissement-tech-en-rejoignant-trendex",
};

export const BLOG_ARTICLES_TRENDEX_USA: Record<string, any> = {
    EN: "https://en.trendex.vip/post/trendex-debarque-aux-usa",
    FR: "https://www.trendex.vip/post/trendex-debarque-aux-usa",
    ES: "https://es.trendex.vip/post/trendex-debarque-aux-usa",
};

export const BLOG_ARTICLES_MARCUS_SMART: Record<string, any> = {
    EN: "https://en.trendex.vip/post/marcus-smart-meilleur-defenseur-de-nba-debarque-sur-trendex",
    FR: "https://www.trendex.vip/post/marcus-smart-meilleur-defenseur-de-nba-debarque-sur-trendex",
    ES: "https://es.trendex.vip/post/marcus-smart-meilleur-defenseur-de-nba-debarque-sur-trendex",
};

export const BLOG_ARTICLES_WEMBY: Record<string, any> = {
    EN: "https://en.trendex.vip/post/le-prochain-wemby-est-sur-trendex",
    FR: "https://www.trendex.vip/post/le-prochain-wemby-est-sur-trendex",
    ES: "https://es.trendex.vip/post/le-prochain-wemby-est-sur-trendex",
};

export const WEBSOCKET_DEV_ENDPOINT = 'wss://8iszp8dg10.execute-api.eu-west-3.amazonaws.com/dev';
export const WEBSOCKET_DEMO_ENDPOINT = 'wss://0spcx4ou64.execute-api.eu-west-3.amazonaws.com/preprod';
export const WEBSOCKET_PRODUCTION_ENDPOINT = 'wss://cf3jomvyn7.execute-api.eu-west-3.amazonaws.com/production';

export const SECONDS_AFTER_INITIAL_SOLDOUT = 5;

export const MAX_SOCKET_RECONNECTION = 5;

export const EXPONENTIAL_BACKOFF_DELTA = 10000;

export const SOCKET_TIMEOUT_REASON = "Going away";

export const TOURNAMENT_TUTORIAL_MODAL: 'VIDEO' | 'STORIES' = "VIDEO";

export const DEFAULT_EXPONENTIAL_BACKOFF_MULTIPLIER_CHECK_TRANSACTIONS: number = 500;

export const DEFAULT_AMOUNT_REFUND_WALLET: number = 500;
export const AssetCategoryOrder: Record<string, number> = {
    [AssetCategory['Fun cards']]: 2,
    [AssetCategory.Football]: 3,
    [AssetCategory.Basketball]: 4,
    [AssetCategory.Handball]: 5,
    [AssetCategory.Fight]: 6,
    [AssetCategory.Rugby]: 7,
    [AssetCategory.Music]: 8,
    [AssetCategory.Tiktok]: 9,
    [AssetCategory.Cinema]: 10,
    [AssetCategory.Youtube]: 11,
    [AssetCategory['Euro 2024']]: 12,
    [AssetCategory.Instagram]: 13,
    [AssetCategory.Streamers]: 75,
};

export const SHOW_LOVE_BOX: boolean = true;

export const SECONDARY_MARKET_PREDICT_PRICE_METHODS = {
    CLASSIC: 'CLASSIC',
    AVERAGE_PONDERATED_PRICE_OF_ORDERBOOK: 'AVERAGE_PONDERATED_PRICE_OF_ORDERBOOK'
};

export const SORTER_TOP_50 = [
    { index: 0, value: 'default', label: 'top-50.sort-system.default', item_label: 'top-50.sort-system.menu-item.default' },
    { index: 1, value: 'name', label: 'top-50.sort-system.name', item_label: 'top-50.sort-system.menu-item.name' },
    { index: 2, value: 'price', label: 'top-50.sort-system.price', item_label: 'top-50.sort-system.menu-item.price' },
    { index: 3, value: 'yield', label: 'top-50.sort-system.yield', item_label: 'top-50.sort-system.menu-item.yield' },
    { index: 4, value: 'evolution', label: 'top-50.sort-system.evolution', item_label: 'top-50.sort-system.menu-item.evolution' },
    { index: 5, value: 'irlScore', label: 'top-50.sort-system.irlScore', item_label: 'top-50.sort-system.menu-item.irlScore' },
    { index: 6, value: 'sportScore', label: 'top-50.sort-system.sportScore', item_label: 'top-50.sort-system.menu-item.sportScore' },
    { index: 7, value: 'initialSaleDate', label: 'top-50.sort-system.initialSaleDate', item_label: 'top-50.sort-system.menu-item.initialSaleDate' },
    { index: 8, value: 'cardLevel', label: 'top-50.sort-system.cardLevel', item_label: 'top-50.sort-system.menu-item.cardLevel' },
    { index: 9, value: 'category', label: 'top-50.sort-system.category', item_label: 'top-50.sort-system.menu-item.category' },
];

export const DEFAULT_FILTER_TOP_50 = 'default';

export const ASSET_CARD_LEVEL_NAME: Record<number, AssetCardLevelName> = {
    [AssetCardLevel.Rookie]: "Rookie",
    [AssetCardLevel.Contender]: "Contender",
    [AssetCardLevel.Champion]: "Champion",
    [AssetCardLevel.Legend]: "Legend"
};

export const ASSET_CARD_LEVEL_NAME_PLURIAL: Record<number, AssetCardLevelNamePlurial> = {
    [AssetCardLevelPlurial.Rookies]: "Rookies",
    [AssetCardLevelPlurial.Contenders]: "Contenders",
    [AssetCardLevelPlurial.Champions]: "Champions",
    [AssetCardLevelPlurial.Legends]: "Legends"
};


/**
 * Value of card with respect to rookie card.
 * 
 * For example: 1 Contender card is equivalent to 2 Rookie card.
 */
export const ASSET_CARD_EQUIVALENT: Record<number, number> = {
    [AssetCardLevel.Rookie]: 1,
    [AssetCardLevel.Contender]: 2,
    [AssetCardLevel.Champion]: 5,
    [AssetCardLevel.Legend]: 25
};

export const PRE_PRIVATE_SALES_START_THRESHOLD: number = 5;

export const KEYCODE_ESC: number = 27;

export const TOP_ASSETS_LIMIT = 500;

export const SELL_SLIDER_CONFIG = {
    NB_STEPS_BETWEEN_MIN_DEFAULT: 6,
    NB_STEPS_BETWEEN_DEFAULT_MAX: 6,
    MIN_VALUE: 0,
    MAX_VALUE: 100,
    MIDDLE_VALUE: 50,
    MIN_DEVIATION_RATIO: 0.2,
    MAX_DEVIATION_RATIO: 0.2,
};

export const RESERVE_VALUE = 0.000000000000000001;
export const LP_BUY_FEE = 0.05;
export const LP_SELL_FEE = 0.05;
export const LP_SELL_FIXED_FEE = 0.05;
export const PRIMARY_MARKET_FEES_FIXED = 0.15;
export const PRIMARY_MARKET_FEES_RATE = 0.05;
export const VIP_CAN_SELL_OWN_TOKENS: string[] = [];

export const CLOUD_FRONT_CACHED_ROUTES_KEYS = {
    TOP_ASSETS_SCORES: 'top-assets-scores',
    TOP_ASSETS: 'top-assets',
    ASSET_DETAIL: 'asset-detail',
};

export const TOOLTIP_TOUCH_DELAY_TIME: number = 100;
export const CURRENT_FOOTBALL_SEASON_YEAR: number = 2023;
export const TOKEN_INFORMATION_ITEMS: Record<string, string> = {
    PRICE: 'PRICE',
    YIELD: 'YIELD',
    SUPPLY: 'SUPPLY'
};
export const ASSET_PROFILE_TOKEN_INFORMATION_DISPLAY_ITEMS = [TOKEN_INFORMATION_ITEMS.PRICE, TOKEN_INFORMATION_ITEMS.SUPPLY];

export enum ORDER_TYPE {
    MARKET = 'MARKET',
    LIQUIDITY_POOL = 'LIQUIDITY_POOL'
}

export const ASSET_TRANSACTION_DETAIL_ATTRIBUTES = {
    TOKENS: 'TOKENS',
    USER_VALUE: 'USER_VALUE'
};
export const LIMIT_ORDER_ACCEPTANCE_RANGE = 0.2;
export const ALLOW_ACCESS_WITH_INSUFFICIENT_BALANCE: boolean = true;
export const ALLOW_ANONYMOUS_PURCHASE: boolean = true;
export const REGEX_PATTERNS: Record<string, RegExp> = {
    EMAIL: /^[0-9=?A-Z_a-z|~+](\.?[-0-9=?A-Z_a-z|~+])*@[a-zA-Z0-9]{2}(-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-z])+$/,
    PASSWORD: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
    PHONE: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
    FRENCH_PHONE: /^(33|0)\d{9}$/,
    DISCORD: /^.{3,32}#[0-9]{4}$/i,
    NUMBER_AND_LETTER: /^(?=.*[a-zA-Z])(?=.*[0-9])/,
    MINIMUM_LENGTH_REGEX: /^.{8,}$/,
};

export const WHITELIST_STATUS = {
    NONE: 'NONE',
    INTENT: 'INTENT',
    CONFIRMED: 'CONFIRMED'
};

export enum ASSET_BUY_STAGE {
    PRE_BUY_PACK = 'PRE_BUY_PACK',
    WHITELIST_USER_BUY = 'WHITELIST_USER_BUY',
    NONE = 'NONE',
    MARKET = 'MARKET',
}

export const SUPER_TALENT = '6437cbf1f5eaae0002c1355e';
export const TRENDEX_STAT = {
    CONTRACTED_TALENTS: "200",
    SOLD_TRENDS: "1.000.000",
    REDISTRIBUTED_TO_PLAYERS: "300.000 €",
};

export const TRENDS_DATA = {
    VIP_PACK: 5000,
    WHITELISTED: 10000,
    PUBLIC_SUPPLY: 5000,
};
export const BUY_STAGE_TRANSITION_TOLERANCE_TIME: number = 3;
export const HIDE_DISCORD_BUTTON: boolean = false;
export const LIGHT_ASSET_PROFIL_ELLIGIBLE: string[] = [];
export const ASSETS_CATEGORIES: Record<string, string> = {
    [AssetCategory.Football]: '64219383c07ae31d115b0775',
    [AssetCategory.Basketball]: '64219383c07ae31d115b0774',
    [AssetCategory.Fight]: '64219383c07ae31d115b0777',
    [AssetCategory.Rugby]: '64219383c07ae31d115b0776',
    [AssetCategory.Music]: '60858bfa3ac68b6c185d78a9',
    [AssetCategory.Tiktok]: '633ea4cc9041bace1a678fe9',
    [AssetCategory.Cinema]: '617013b410c9781e9f1d2007',
    [AssetCategory.Instagram]: '6085883ce4c992ae6f74fe00',
    [AssetCategory.Youtube]: '60858bfa3ac68b6c185d78ad',
    [AssetCategory.Streamers]: '617a57e4b33b38253cd67795',
    [AssetCategory['Euro 2024']]: '667962346b086bc0615d5297',
    [AssetCategory['Fun cards']]: '667ecf7141dfc84eb4500c69'
};
export const SUPPORTED_LANGUAGES = ['en', 'fr', 'es'];

export enum TOURNAMENT_TASK_STATUS {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    WAITING = 'WAITING',
    PENDING = 'PENDING',
    RUNNING = 'RUNNING'
}

export enum WEBSOCKET_ACTIONS {
    PREDICT_PRICE = 'predictPrice',
    TOURNAMENT_SUBSCRIBE = 'tournamentSubscribe'
}

export enum TOURNAMENT_CATEGORY {
    DEFAULT = 'DEFAULT',
    FOOTBALL = 'FOOTBALL',
    BASKETBALL = 'BASKETBALL'
}

export const TOURNAMENT_CATEGORIES: IMenuItem[] = [
    {
        index: 1,
        value: TOURNAMENT_CATEGORY.DEFAULT,
        label: 'admin.tournaments.category.default'
    },
    {
        index: 2,
        value: TOURNAMENT_CATEGORY.FOOTBALL,
        label: 'admin.tournaments.category.soccer'
    },
    {
        index: 3,
        value: TOURNAMENT_CATEGORY.BASKETBALL,
        label: 'admin.tournaments.category.basketball'
    }
];

export enum TOURNAMENT_INFORMATION_STATE_KEY {
    CLOSED = "closed",
    OPENED = "opened",
    REGISTERED = "registered",
    NONREGISTERED = "notregistered",
}

export enum TOURNAMENT_INFORMATION_LEAGUE_NAME {
    ACTUAL = "actual",
    OTHER = "other"
}

export const BENZEMA_CAMPAIGN_DATE: string = new Date("2023-09-25").toISOString();
export const ASSET_BUY_PAGE_CONFIG = {
    PACK: 'PACK'
};

export const INDICATORS = {
    DEFAULT: 'DEFAULT',
    NONE: 'NONE',
    FIRE_ICON: 'FIRE_ICON',
};

export const AMOUNT_PRECISION_AFTER_DECIMAL = 2;
export const TERMS_DRAW_LINKS: { fr: string; } = {
    fr: "https://s3.eu-west-3.amazonaws.com/benzema.club/Re%CC%80glement_du_jeu_Benzema.pdf",
};

export const BENZEMA_BANNER_VIDEO: { fr: string, en: string, es: string; } = {
    fr: "6j6CYnp1quI",
    en: "fkdqx-emMrc",
    es: "IBR6VPvRBsY"
};

export const STRIPE_ACCOUNT_COUNTRY = 'FR';

export const SPORT_ASSET_CATEGORIES = [
    AssetCategory.Football.toLowerCase(),
    AssetCategory.Basketball.toLowerCase()
];
export const SPORT_ASSET_CATEGORIES_ID = [
    ASSETS_CATEGORIES[AssetCategory.Football],
    ASSETS_CATEGORIES[AssetCategory.Basketball]
];

export const TOURNAMENT_SUBJECT_TO_SPORT = [
    TOURNAMENT_CATEGORY.FOOTBALL,
    TOURNAMENT_CATEGORY.BASKETBALL
];

export const SCRAPABLE_SPORT_ASSET_CATEGORIES = [
    ASSETS_CATEGORIES[AssetCategory.Basketball],
    ASSETS_CATEGORIES[AssetCategory.Football],
];


export const PROFILE_SIZE_LIMIT_MB: number = 1;
export const PROFILE_SIZE_LIMIT_BYTES: number = PROFILE_SIZE_LIMIT_MB * 1024 * 1024; // 1MB
export const PROFILE_SIZE_LIMIT_STRING: string = PROFILE_SIZE_LIMIT_MB + "MB";

export const WITHDRAW_DATA_NAME = {
    AMOUNT: "amount",
    IBAN: "iban",
    ROUTING_NUMBER: "routingNumber",
    ACCOUNT_NUMBER: "accountNumber",
    BIC_OR_SWIFT: "bicOrSwift",
    BANK_NAME: "bankName",
    BANK_ADDRESS: "bankAddress",
};

export const WITHDRAW_TIME_PERIOD: string = "12h-72h";
export const ROUND_UP_ERROR_ACCEPTANCE: number = 0.01;
export const EmptyString: string = '';
export const NONE: string = 'none';
export const EmptyObject: object = {};
export const EmptyArray: Array<any> = [];

export const PERSONAL_TOURNAMENT_LABEL_UNSUBSCRIBED = 'unsubscribed';
export const PERSONAL_TOURNAMENT_LABEL_SUBSCRIBED = 'subscribed';
export const WorldRegions: string[] = [
    'europe',
    'north-america',
    'middle-east',
    'africa',
    'south-america',
    'asia'
];

export const MAX_TEAM_SCORE: number = 100;
export const TREND_SCORE_STATUS_PART = [
    {
        label: 'trendScore.status.low',
        value: 33.33,
    },
    {
        label: 'trendScore.status.mid',
        value: 66.66,
    },
    {
        label: 'trendScore.status.good',
        value: 100,
    },
];

export const PHONEPLACEHOLDER = "+33 6 12 34 56 78";

export const FOOTBALL_PREFIX: number = 99999;

export const DEFAULT_SCORE_BOOST: IScoreBoost = {
    isAcquired: false,
    points: 10
};
export const CATEGORY_CAN_SHOW_STATISTICS = [
    ASSETS_CATEGORIES[AssetCategory.Basketball]
];

export const SPORTS_STATISTICS_RATIO = {
    Basketball: {
        Points: 2.5,
        Rebounds: 3,
        Assists: 3.75,
        Steals: 7.5,
        Blocks: 7.5,
        Turnovers: -2.5
    },
    Football: {
        FoulsDrawn: 0,
        YellowCards: 0,
        DuelsWon: 0,
        DuelsTotal: 0,
        PassesKey: 0,
        PassesAccuracy: 0,
        ShotOn: 0,
        PenaltyScored: 0,
        GamesMinutes: 0,
    }
};

export const DAY_IN_MS: number = 24 * 60 * 60 * 1000;
export const NUMBER_OF_DAYS_WITH_SPECIAL_FILTER_TOP_50: number = 15;

export const ASSETS_SLUG = {
    KARIM_BENZEMA: 'karim-benzema',
    RUDY_GOBERT: 'rudy-gobert',
    RONALD_ARAUJO: 'ronald-araujo',
    JESSE_LINGUARD: 'jesse-lingard',
    RENATO_SANCHES: 'renato-sanches',
    MARCUS_SMART: 'marcus-smart',
    FRANCE_EURO_2024: 'france-(euro-2024)',
    ESPAGNE_EURO_2024: 'espagne-(euro-2024)',
    PAYS_BAS_EURO_2024: 'pays-bas-(euro-2024)',
    ANGLETERRE_EURO_2024: 'angleterre-(euro-2024)'
};

export const NUMBER_OF_TOURNAMENT_BY_WEEK = 2;

export const FIRST_TOURNAMENT_WITH_LEAGUE_DATE = new Date('2023-01-18');

export const TOURNAMENT_CATEGORY_LABEL: Record<string, string> = {
    DEFAULT: 'Trend',
    FOOTBALL: 'Football',
    BASKETBALL: 'Basketball'
};

export const MINIMUM_STRIPE_AMOUNT: number = 1;

export const EDIT_PROFILE_MANDATORY: Record<string, boolean> = {
    firstName: true,
    lastName: true,
    email: true,
    pseudo: false,
    country: false,
    phone: false,
    dateOfBirth: false,
    language: false,
    discord: false,
};

export const ASSETS_TO_HIDE_IN_TOP50: string[] = [
    "65fb08846a183e11ced70dab",
    "65c3dc2b2b166dbd2ec66a18",
    "6601cfc91f42a52376d22585",
    '6687c660171149a693348eaf', // Portugal
    '6687c791b13d45ba788b0c43', // Allemagne
    '6688190db13d45ba78166344', // Suisse
    '6688e68fd49174879c47f35c', // Turquie
    '6687be8db13d45ba78772edf', // France
    '6688e59ac421dbb66b4fc813', // Pays-Bas
    '6687c888b13d45ba788e1e57', // Espagne
    '668813b1b13d45ba780db264', // Angleterre
];

export const REQUIRED_SALES_AMOUNT_FOR_KYC = 100;
export const DEFAULT_ORDERBOOK_FEES = {
    sellFees: 0.05,
    sellFixedFees: 0.15,
};

export const NUMBER_OF_FREE_CARDS: number = 4;

export const NUMBER_OF_FREE_CARDS_ONBOARDING: number = NUMBER_OF_FREE_CARDS + 1;

export const DOMAIN_NAME: Record<string, string> = {
    EN: "https://en.trendex.vip",
    FR: "https://www.trendex.vip",
    ES: "https://es.trendex.vip",
};

export const SIGNUP_COUNTDOWN_HOURS: number = 32;
export const SIGNUP_COUNTDOWN_MINUTES: number = 24;
export const SIGNUP_COUNTDOWN_SECONDS: number = 24;

export const ONBOARDING_PREFIX_URL: string = 'onboarding';

export const onboardingEndpoints: Record<number, string> = {
    [OnboardingStep.CREATE_TEAM]: `/${ONBOARDING_PREFIX_URL}/team`,
    [OnboardingStep.SELL_HIS_FIRST_ASSET]: `/${ONBOARDING_PREFIX_URL}/sell`,
    [OnboardingStep.VIEW_LIGHT_WALLET]: `/${ONBOARDING_PREFIX_URL}/wallet`,
    [OnboardingStep.FIRST_WITHDRAW]: `/${ONBOARDING_PREFIX_URL}/withdraw`,
};

export enum LAUNCH_MODE {
    DEFAULT = 'DEFAULT',
    LIQUIDITY_POOL = 'LIQUIDITY_POOL',
}

export const LAUNCH_MODE_OPTIONS = [
    { label: "Default", value: LAUNCH_MODE.DEFAULT },
    { label: "Liquidity Pool Only", value: LAUNCH_MODE.LIQUIDITY_POOL }
];

export const FAKE_WITHDRAW_PREFIX: string = '00000';
export const SLIPPAGE: number = 10 / 100;
export const TOURNAMENT_ENTRY_TYPE = {
    FREE: "FREE",
    PAID: "PAID",
};

export const CURVE_TYPE_LIST: string[] = [
    AssetCurveType.DEFAULT,
    AssetCurveType.EXPONENTIAL,
    AssetCurveType.STEPWISE,
    AssetCurveType.WAVE,
    AssetCurveType.LINEAR
];