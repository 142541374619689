import React from 'react';
import ISelectionTabsProps from './ISelectionTabsProps';
import clsx from 'clsx';
import "./selection-tabs.scss";

const SelectionTabs: React.FC<ISelectionTabsProps> = ({ options, selectedValue, setSelectedValue }) => {
    return <div className="selection-tabs-wrapper">
        {options.map((option) => (
            <div
                className={clsx("selection-tab", { selected: option.value === selectedValue })}
                key={option.value}
                onClick={() => setSelectedValue(option.value)}

            >
                {option.label}
            </div>)
        )}
    </div>
}

export default SelectionTabs;
