import { ISvgIconProps } from 'interfaces/SvgIcon.interface';
import * as React from 'react';

const ShirtIcon: React.FC<ISvgIconProps> = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? "32"} height={props.height ?? "32"} {...props} viewBox="0 0 32 32">
        <g id="Groupe_5727" data-name="Groupe 5727" transform="translate(-1034 -965)">
            <rect id="Rectangle_2200" data-name="Rectangle 2200" width="32" height="32" transform="translate(1034 965)" fill={props.color ?? "#6677f5"} opacity="0" />
            <g id="football-jersey-svgrepo-com" transform="translate(1037.692 420.726)">
                <g id="Groupe_5647" data-name="Groupe 5647" transform="translate(0 548.393)">
                    <g id="Groupe_5646" data-name="Groupe 5646" transform="translate(0 0)">
                        <path id="Tracé_3970" data-name="Tracé 3970" d="M24.545,555.6l-3.971-5.709a.372.372,0,0,0-.229-.159L15.05,548.4a.586.586,0,0,0-.1-.011h-.4a.4.4,0,0,0-.346.2,2.221,2.221,0,0,1-1.9,1.127,2.252,2.252,0,0,1-1.972-1.127.4.4,0,0,0-.346-.2H9.66a.586.586,0,0,0-.1.011L4.27,549.733a.387.387,0,0,0-.229.159L.07,555.6a.4.4,0,0,0,.043.5l2.647,2.722a.4.4,0,0,0,.574,0l1.031-1.079v14.007a.4.4,0,0,0,.4.4H19.782a.406.406,0,0,0,.4-.4V557.7L21.3,558.77a.4.4,0,0,0,.558-.005l2.647-2.653A.4.4,0,0,0,24.545,555.6Zm-2.982,2.318-1.5-1.451a.39.39,0,0,0-.431-.08.4.4,0,0,0-.245.367v14.6H5.168v-14.6a.4.4,0,0,0-.686-.276l-1.435,1.494-2.132-2.19,3.695-5.316,5.1-1.281h.064a3.091,3.091,0,0,0,2.536,1.329,3.018,3.018,0,0,0,2.467-1.329H14.9l5.1,1.281,3.695,5.311Z" transform="translate(0 -548.393)" fill={props.color ?? "#6677f5"} stroke={props.color ?? "#6677f5"} stroke-linecap="square" stroke-width="0.5" />
                        <path id="Tracé_3971" data-name="Tracé 3971" d="M283.5,648.294h-1.786a.4.4,0,0,0-.4.4v1.728a.406.406,0,0,0,.043.175c.133.266.622,1.148,1.281,1.148.579,0,1.042-.8,1.217-1.148a.388.388,0,0,0,.043-.175v-1.728A.4.4,0,0,0,283.5,648.294Zm-.4,2.025a1.94,1.94,0,0,1-.473.622,1.623,1.623,0,0,1-.516-.622v-1.228h.989Z" transform="translate(-266.359 -642.983)" fill={props.color ?? "#6677f5"} stroke={props.color ?? "#6677f5"} stroke-linecap="square" stroke-width="0.5" />
                        <path id="Tracé_3993" data-name="Tracé 3993" d="M1.058,14.155H3.382l2.6-3.354a8.112,8.112,0,0,0,.791-1.212,3.82,3.82,0,0,0,.352-.961,4.474,4.474,0,0,0,.088-.879,2.981,2.981,0,0,0-.427-1.589A3.079,3.079,0,0,0,5.612,5.053a3.546,3.546,0,0,0-1.727-.408,3.512,3.512,0,0,0-1.721.415A3.068,3.068,0,0,0,.982,6.19,3.15,3.15,0,0,0,.555,7.836,3,3,0,0,0,.938,9.35a2.822,2.822,0,0,0,1.055,1.062,3.013,3.013,0,0,0,1.539.389h.151Zm2.827-4.95a1.368,1.368,0,0,1-.98-.383,1.333,1.333,0,0,1-.4-1.011A1.353,1.353,0,0,1,3.884,6.4,1.353,1.353,0,0,1,5.266,7.811a1.333,1.333,0,0,1-.4,1.011A1.368,1.368,0,0,1,3.884,9.205Z" transform="translate(8.424 5.881)" fill={props.color ?? "#6576f4"} />
                    </g>
                </g>
            </g>
        </g>
    </svg>

);

export default ShirtIcon;