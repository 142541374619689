import dayjs from "dayjs"
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Utils from './Utils';

dayjs.extend(utc);
dayjs.extend(timezone);

export const isBeforeNow = (date: Date | string): boolean => {
    return dayjs(date).isBefore(dayjs())
}

export const dateDifferenceInDays = (date1: Date | string, date2: Date | string) => {
    return dayjs(date1).diff(date2, "days");
}

export const dateDifferenceInSeconds = (date1: Date | string, date2: Date | string) => {
    return dayjs(date1).diff(date2, 'seconds');
}

export const subtractDay = (date: Date | string, days: number) => {
    return dayjs(date).subtract(days, 'days');
}
export const dayDifferenceInFrenchTime = (date: Date | string) => {
    const frenchDateMidnight = toFrenchTimeMidnight(date);
    const currentFrenchDateMidnight = toFrenchTimeMidnight(new Date())
    return dateDifferenceInDays(frenchDateMidnight, currentFrenchDateMidnight);
}

export const toFrenchTimeMidnight = (date: string | Date) => {
    return dayjs(date).tz("Europe/Paris").startOf('day').toDate()
}

export const getMonthName = (date: Date) => {
    return date.toLocaleString(Utils.getCurrentLanguage(), { month: 'long' });
}

export const getDayName = (date: Date) => {
    return date.toLocaleDateString(Utils.getCurrentLanguage(), { weekday: 'long' });
}

export const getFullDateWithoutYear = (date: Date) => {
    return date.toLocaleDateString(Utils.getCurrentLanguage(), { weekday: 'long', month: 'long', day: 'numeric' });
}

export const getDateToString = (date: Date, weekday?: "long" | "short" | "narrow" | undefined, month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined, day?: "numeric" | "2-digit" | undefined, year?: "numeric" | "2-digit" | undefined) => {
    return date.toLocaleDateString(Utils.getCurrentLanguage(), { weekday, month, day, year });
}