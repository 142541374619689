import React from 'react';

const Plus = () => {
    return (<svg id="circle-plus-duotone" data-name="circle-plus-duotone (1)" xmlns="http://www.w3.org/2000/svg" width="19.685" height="19.685" viewBox="0 0 19.685 19.685">
        <path id="Tracé_3180" data-name="Tracé 3180" d="M144.923,149.229a.923.923,0,1,1,0-1.845h2.461v-2.461a.923.923,0,0,1,1.845,0v2.461h2.461a.923.923,0,1,1,0,1.845h-2.461v2.461a.923.923,0,1,1-1.845,0v-2.461Z" transform="translate(-138.464 -138.464)" fill="#fff" />
        <path id="Tracé_3181" data-name="Tracé 3181" d="M0,9.842a9.842,9.842,0,1,1,9.842,9.842A9.842,9.842,0,0,1,0,9.842Zm9.842,4.306a.921.921,0,0,0,.923-.923V10.765h2.461a.923.923,0,1,0,0-1.845H10.765V6.459a.923.923,0,1,0-1.845,0V8.92H6.459a.923.923,0,1,0,0,1.845H8.92v2.461A.921.921,0,0,0,9.842,14.148Z" fill="#fff" opacity="0.4" />
    </svg>)
}

export default Plus;
