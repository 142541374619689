import Application from 'Application';
import AxiosConfig from 'config/axiosConfig';
import { IHttpStrongTypedResponse } from 'model/IHttpResponse';
import IGeoIP from './IGeoIP';

export const getGeoIP = async () => {
    const response: IHttpStrongTypedResponse<IGeoIP> = await AxiosConfig.get('/geoip');
    if (response && response?.data?.data) {
        return response.data.data;
    }
    return Application.getInstance().defaultGeoIP;
}; 