import i18n from "18n";
import { COLOR, HUNDRED, ZERO } from "config/_const";
import { TournamentLeagues } from "config/_enums";
import { TournamentLeagueIcon } from "data/TournamentImages/tournamentImages.data";
import LeagueAttribution from "model/League/LeagueAttribution";
import TournamentViewModel from "view-model/Tournament/TournamentViewModel/TournamentViewModel";

interface IUserLeagueViewModelProps {
    leagueAttribution: LeagueAttribution,
    previousTournament: TournamentViewModel | undefined;
}

class UserLeagueViewModel {
    private _leagueAttribution: LeagueAttribution;
    private _previousTournament: TournamentViewModel | undefined;

    constructor({ leagueAttribution, previousTournament }: IUserLeagueViewModelProps) {
        this._leagueAttribution = leagueAttribution;
        this._previousTournament = previousTournament;
    }

    private get PrizePool() {
        return this._previousTournament
            ? this._previousTournament.PrizePool
            : this.LeagueAttribution.App.defaultPrizePool;
    }

    public get LeagueAttribution(): LeagueAttribution {
        return this._leagueAttribution;
    }

    public get BtnColor(): string {
        return this.LeagueAttribution.League.BtnColor || COLOR.PRIMARY_VIOLET;
    }

    public get BtnColorSecondary(): string {
        return this.LeagueAttribution.League.BtnColorSecondary || COLOR.WHITE;
    }

    public get LeagueIcon(): React.FC | undefined {
        const UserLeagueIcon = TournamentLeagueIcon[this.LeagueAttribution.Name as TournamentLeagues];
        return UserLeagueIcon;
    }
    public get LeagueTeamScoreBoostPrice(): number {
        return this.LeagueAttribution.League.PriceForTeamScoreBoost;
    }

    public get LeagueTeamScoreBoostPriceFormatted(): string {
        return this.LeagueTeamScoreBoostPrice.toCurrency();
    }

    public get PricePoolofLeague(): number {
        return this.PrizePool * this.LeagueAttribution.League.CashprizeChunk;
    }

    public get PricePoolofLeagueFormatted(): string {
        return this.PricePoolofLeague.toCurrency();
    }

    public get AnnualPricePool(): number {
        return this.PricePoolofLeague * this.LeagueAttribution.App.tournamentsPerYear;
    }

    public get AnnualPricePoolFormatted(): string {
        return this.AnnualPricePool.toCurrency();
    }

    public get MaxSubscriptions(): number {
        return this.LeagueAttribution.League.MaxSubscriptions;
    }

    public get HigherLeagueMinShare(): number {
        return this.LeagueAttribution.HigherLeague.MinWalletShares || ZERO;
    }
    public get HigherLeagueToken(): number {
        return this.LeagueAttribution.HigherLeague.LeagueTokens || ZERO;
    }

    public get HigherLeagueIcon(): React.FC | undefined {
        const UserLeagueIcon = TournamentLeagueIcon[this.LeagueAttribution.HigherLeagueName as TournamentLeagues];
        return UserLeagueIcon;
    }

    public get IsLeagueTokenZeroInHigherLeague(): boolean {
        return this.LeagueAttribution.HigherLeague.LeagueTokens === ZERO;
    }

    private get OwnedLeagueTokenOfHighLeague(): number {
        return this.HigherLeagueToken < this.LeagueAttribution.OwnedLeagueTokensOfHigherLeague
            ? this.HigherLeagueToken
            : this.LeagueAttribution.OwnedLeagueTokensOfHigherLeague;
    }

    public get CurrentGeneralTokens() {
        if (this.IsLeagueTokenZeroInHigherLeague) return this.LeagueAttribution.UserTotalTokens;
        return (this.HigherLeagueToken < this.OwnedLeagueTokenOfHighLeague)
            ? this.LeagueAttribution.UserTotalTokens.subtract(this.HigherLeagueToken)
            : this.LeagueAttribution.UserTotalTokens.subtract(this.OwnedLeagueTokenOfHighLeague);
    }

    public get DiffForHigherLeague() {
        const remainingLeagueToken = this.IsLeagueTokenZeroInHigherLeague
            ? ZERO
            : Math.max(this.HigherLeagueToken.subtract(this.OwnedLeagueTokenOfHighLeague), 0);
        const remainingGeneralToken = Math.max(this.HigherLeagueMinShare.subtract(this.CurrentGeneralTokens), 0);
        return {
            remainingLeagueToken,
            remainingGeneralToken
        }
    }

    public get LowerLeagueMinShare(): number {
        return this.LeagueAttribution.LowerLeague.MinWalletShares;
    }

    public get IsUserInLastLeague(): boolean {
        return this.LeagueAttribution.IsUserInLastLeague;
    }

    public get IsGeneralTokenFullForHighLeague(): boolean {
        return this.CurrentGeneralTokens >= this.HigherLeagueMinShare;
    }

    public get ProgressPercentageFromCurrentToHigherLeague(): string {
        const currentGeneralTokensWithLimit = this.IsGeneralTokenFullForHighLeague ? this.HigherLeagueMinShare : this.CurrentGeneralTokens;
        const totalSpecialAndLeagueTokenForNextLeague = this.HigherLeagueMinShare + this.HigherLeagueToken;
        const totalUserSpecialAndLeagueTokenForNextLeague = currentGeneralTokensWithLimit + this.OwnedLeagueTokenOfHighLeague;
        const precentageToHigherLeague = (totalUserSpecialAndLeagueTokenForNextLeague.divide(totalSpecialAndLeagueTokenForNextLeague)).multiply(HUNDRED);
        return Math.floor(precentageToHigherLeague) + "%";
    }

    public get YourLeague(): string {
        return i18n.t('your.league', { leagueName: this.LeagueAttribution.Name });
    }

    public get UpgradeButtonName(): string {
        return this.IsLeagueTokenZeroInHigherLeague ? 'league.upgrade.without.league.text' : 'league.upgrade.text';
    }

    public get TotalGeneralTokenText(): string {
        return i18n.t('league.general.token');
    }

    public get TotalGeneralTokenValue(): string {
        return `${this.CurrentGeneralTokens} / ${this.HigherLeagueMinShare}`;
    }

    public get TotalLeagueTokenText(): string {
        return i18n.t('league.total.token', {
            highLeagueName: this.LeagueAttribution.HigherLeagueName,
        });
    }

    public get TotalLeagueTokenValue(): string {
        return `${this.OwnedLeagueTokenOfHighLeague} / ${this.HigherLeagueToken}`;
    }

}

export default UserLeagueViewModel;