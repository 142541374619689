import React from 'react';
import ITrendexSelectInputProps from './ITrendexSelectInputProps';
import './style.scss';

const TrendexBlueSelect: React.FC<React.PropsWithChildren<ITrendexSelectInputProps>> = ({ children, ...rest }: ITrendexSelectInputProps) => {
    return (
        <select {...rest} className='trendex-blue-select'>
            {React.Children.toArray(children).map((child: any) => {
                return child;
            })}
        </select>
    )
};

export default TrendexBlueSelect;