import AxiosConfig from 'config/axiosConfig';
import ISportStatistics from 'interfaces/score-chart/ISportStatistics';
import { IHttpStrongTypedResponse } from 'model/IHttpResponse';
import { ThunkAction } from 'redux-thunk';
import { GET_ASSET_STATISTICS_ERROR, GET_ASSET_STATISTICS_LOADING, GET_ASSET_STATISTICS_SUCCESS } from './actionType';
import { HTTP_STATUS_OK } from 'config/_const';
import { Action } from 'redux';

export const getAssetScoreStatistics = (assetSportPlayerId: number, assetCategoryName: string): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({ type: GET_ASSET_STATISTICS_LOADING });
    try {
        const response: IHttpStrongTypedResponse<ISportStatistics[]> = await AxiosConfig.post(`/sports/${assetCategoryName}/statistics/last5`,
            {
                playerId: assetSportPlayerId
            }
        );
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({ type: GET_ASSET_STATISTICS_ERROR, payload: { message: response.data.message } });
        return dispatch({
            type: GET_ASSET_STATISTICS_SUCCESS,
            payload: {
                data: response.data.data[0]
            }
        });
    } catch (exception: any) {
        return dispatch({ type: GET_ASSET_STATISTICS_ERROR, payload: { message: exception.message } });
    }
};