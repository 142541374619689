import { Icon } from '@material-ui/core';
import CategoriesIconsMap from 'components/icons/categories/CategoriesIconsMap';
import { AssetCategory } from 'config/_enums';
import withAssetSportStatistics from 'hoc/with-asset-sport-statistics/withAssetSportStatistics';
import useAssetsCategories from 'hooks/asset-categories/useAssetCategories';
import React from 'react';
import { Trans } from 'react-i18next';
import { compose } from 'redux';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import SportScoreChart from '../score-chart/sport/SportScoreChart';
import './AssetSportScore.scss';
import AssetSportScoreProps from './AssetSportScoreProps';

export const AssetSportScore: React.FC<AssetSportScoreProps> = ({ assetVM, statistics, assetScoreEvolutionVM }) => {
    const { assetsCategories } = useAssetsCategories();

    return (
        <div className='asset-trend-score'>
            <div className='score-header'>
                <div className='title'>
                    {assetVM.getCategoryName(assetsCategories) &&
                        <Icon className='category-icon'>
                            {CategoriesIconsMap[(assetVM.getCategoryName(assetsCategories) ?? AssetCategory.Instagram).capitalizeFirstLetter()]}
                        </Icon>
                    }
                    <div className='trend-title'><Trans i18nKey='asset-profile.sport-score.title' values={{ sport: assetVM.getCategoryNameCapitalizeFirstLetter(assetsCategories) }} /></div>
                </div>
                <div className='content' style={{ background: assetScoreEvolutionVM.BackgroundAccordingScore }}>
                    {assetVM.getCategoryName(assetsCategories) &&
                        <Icon style={{ fill: assetScoreEvolutionVM.BackgroundAccordingScore, fontSize: 50 }} className='icon-background'>
                            {CategoriesIconsMap[(assetVM.getCategoryName(assetsCategories) ?? AssetCategory.Instagram).capitalizeFirstLetter()]}
                        </Icon>
                    }
                    <span className='score' style={{ color: assetScoreEvolutionVM.ColorByTrendScore }}>{assetVM.getSportScoreToDisplay(2)}</span>
                    <div className='status'>
                        <div className="indicator" style={{ background: assetScoreEvolutionVM.ColorByTrendScore }} />
                        <span className='text'><Trans i18nKey={assetVM.getSportScoreStatus()} /></span>
                    </div>
                    <span className='description'><Trans i18nKey={'asset-profile.sport-score.description'} components={{ bold: <strong /> }} values={{ sport: assetVM.getCategoryNameCapitalizeFirstLetter(assetsCategories) }} /></span>
                </div>
            </div>
            {statistics && <SportScoreChart statistics={statistics} assetVM={assetVM} />}
        </div>
    );
};

export default compose<React.FC<{ assetVM: AssetViewModel; }>>(
    withAssetSportStatistics,
)(AssetSportScore);