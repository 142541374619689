import i18n from "18n";
import Application from "Application";
import clsx from "clsx";
import GreenButton from "components/small/buttons/green-button/GreenButton";
import { NUMBER_OF_FREE_CARDS, NUMBER_OF_FREE_CARDS_ONBOARDING } from 'config/_const';
import { hiddenAssetCard } from "data/generalImages/generalImages.data";
import i18next from "i18next";
import AssetCategoryInterface from "model/AssetModel/AssetCategoryInterface";
import ReduxStoreInterface from "model/ReduxStoreInterface";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AssetsGridBox from "../assets-grid-box/AssetsGridBox";
import './Welcome.scss';
import WelcomeProps from "./WelcomeProps";

export const Welcome: React.FC<WelcomeProps> = (props) => {
    const [isGiftCardOpen, setIsGiftCardOpen] = useState<boolean>(false);
    const assetCategories: AssetCategoryInterface[] = useSelector((state: ReduxStoreInterface) => state.assetsCategories.data);
    const nbTokens = !Application.getInstance().OnBoardingV2 ? NUMBER_OF_FREE_CARDS : NUMBER_OF_FREE_CARDS_ONBOARDING;

    return (
        <div className={clsx("welcome", { "onboarding-v2": Application.getInstance().OnBoardingV2 })}>
            <div className="title-container">
                <h3>{i18n.t("welcome-title")}</h3>
                <p>{i18n.t("register-welcome-discover", { nbTokens: nbTokens })}</p>
                {props.accountViewModel.Referral.usedReferralName && (
                    <p className="referral-name">
                        {" "}
                        {i18n.t("referral.modal.title")}{" "}
                        {props.accountViewModel.Referral.usedReferralName}
                    </p>
                )}
            </div>

            <div className="assets-container">
                <div className="asset-grid-wrapper">
                    {props.registerGiftViewModel.Assets.map((asset) => {
                        return (
                            <div key={asset._id} className="flipping-card">
                                <div
                                    className={clsx("card-front", {
                                        "card-front-rotate": isGiftCardOpen,
                                    })}
                                >
                                    <img className={clsx({ "hide-front-img": isGiftCardOpen })} src={hiddenAssetCard} alt="hidden-card" />
                                </div>
                                <div
                                    className={clsx("card-back", {
                                        "card-back-rotate": isGiftCardOpen,
                                    })}
                                >
                                    <AssetsGridBox asset={asset} categories={assetCategories} canShowTokenAvailableCard={false} />

                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {isGiftCardOpen
                ? <GreenButton
                    className={clsx("lets-go-btn", { show: isGiftCardOpen })}
                    textButton={i18n.t("Letsgo")}
                    receiveClickEvent={false}
                    type={!Application.getInstance().OnBoardingV2 ? "button" : "submit"}
                    disabled={false}
                    handleClick={props.onClaimReward}
                />
                : <GreenButton
                    className="open-btn"
                    receiveClickEvent={false}
                    type="submit"
                    textButton={i18next.t("register-welcome-revealBtn")}
                    disabled={false}
                    handleClick={() => setIsGiftCardOpen(true)}
                />
            }


        </div>
    );
};

export default Welcome;
