import * as React from 'react';

const StarHalfStroke = ({ height = "20px", width = "20px", ...props }: React.SVGProps<SVGSVGElement>) => {

  return (
    <svg height={height} width={width} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g id="Influence" transform="translate(0 -728.5)">
        <g id="Rectangle_2014" data-name="Rectangle 2014" transform="translate(0 728.5)" fill="#8187d0" stroke="#707070" strokeWidth="1" opacity="0">
          <rect width="20" height="20" stroke="none" />
          <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
        </g>
        <g id="star-half-duotone" transform="translate(-22.796 730.101)">
          <path id="Tracé_3607" data-name="Tracé 3607" d="M32.791,14.455l-4.224,2.257a1.062,1.062,0,0,1-1.114-.076,1.049,1.049,0,0,1-.422-1.031l.811-4.831L24.408,7.406a1.059,1.059,0,0,1-.26-1.081A1.048,1.048,0,0,1,25,5.614l4.731-.7L31.849.554a1.041,1.041,0,0,1,.913-.593Zm4.785,2.379a.6.6,0,0,1-.109,0Z" transform="translate(0 0)" fill="#8187d0" />
          <path id="Tracé_3608" data-name="Tracé 3608" d="M143.205,16.834a.563.563,0,0,1-.105,0Zm4.669-2.379V-.039h0a1.052,1.052,0,0,1,.949.593l2.119,4.361,4.735.7a1.054,1.054,0,0,1,.587,1.793l-3.434,3.368.811,4.831a1.056,1.056,0,0,1-1.536,1.107l-4.231-2.257Z" transform="translate(-115.084 0)" fill="#8187d0" opacity="0.4" />
        </g>
      </g>
    </svg>
  );
}

export default StarHalfStroke;
