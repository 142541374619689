import * as React from 'react';

const Copy = ({ height = "16px", width = "16px", color = "#15182B", fill="#6677f5", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg id="copy-duotone" height={height} width={width} {...props} color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.574 17.574">
            <path id="Tracé_3206" data-name="Tracé 3206" d="M6.59,12.082V4.393H1.648A1.648,1.648,0,0,0,0,6.041v9.885a1.648,1.648,0,0,0,1.648,1.648h6.59a1.648,1.648,0,0,0,1.648-1.648V14.279h-1.1A2.2,2.2,0,0,1,6.59,12.082ZM14.279,0V3.3h3.3Z" fill={fill} />
            <path id="Tracé_3207" data-name="Tracé 3207" d="M233.885,3.3v8.238a1.648,1.648,0,0,1-1.648,1.648h-6.59A1.648,1.648,0,0,1,224,11.533V1.648A1.648,1.648,0,0,1,225.648,0h4.943V3.3Z" transform="translate(-216.311)" fill={fill} opacity="0.4" />
        </svg>

    );
};

export default Copy;
