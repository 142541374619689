import React from 'react';
import './YieldMetaInformation.scss';
import Range from 'components/small/range/Range';
import IYieldMetaInformationProps from './IYieldMetaInformationProps';
import { withTranslation } from 'react-i18next';
import YIELD_INFORMATION_PARAGRAPHS from 'data/YieldInformation.data';

const YieldMetaInformation: React.FC<IYieldMetaInformationProps> = ({ maxAPY, t: translate }) => {
    return <div className='yield-information-container'>

        <section className='heading'>
            {translate('asset.yield')}
        </section>

        <section className='range'>
            <Range
                range={maxAPY}
                endLabel={translate('asset.yield.max')}
                startLabel={translate('asset.yield.min')}
                currentLabel={translate('asset.yield.current')}
            />
        </section>

        <section className='content'>
            {YIELD_INFORMATION_PARAGRAPHS.map((text, index) => (
                <p key={index}>
                    {translate(text, { maxYield: parseFloat(maxAPY.toPercentage()) + '%' })}
                </p>
            ))}
        </section>
    </div >;
};

export default withTranslation()(YieldMetaInformation);