import Application from 'Application';
import { TOURNAMENT_CATEGORY } from 'config/_const';
import { getTournamentBanner } from 'data/TournamentBanner.data';
import { academyThumbnail2, getAcademyVideoId2 } from 'data/YoutubeVideo/youtubeVideo.data';
import BannerVideoSlide from 'page/Top50/top50Carousel/slides/VideoSlide';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import TeamHeaderOnboardingProps from './TeamHeaderOnboardingProps';
import './style.scss';

const TeamHeaderOnboarding: React.FC<TeamHeaderOnboardingProps> = ({ user, t: translate }) => {
    return (
        <div className='team-header-onboarding'>
            <h5 className='title'><b>{translate('onboarding.team.header.discover')}</b></h5>

            <div className='logo-container'>
                <img src={Application.getInstance().TournamentLeagueLogo} alt="Logo HERE" className="img-fluid" />
            </div>

            <p className='title'>{translate('onboarding.team.header.title')}</p>

            <div className='notice-container'>
                <div className='flex'>
                    <p className='no-margin'><Trans i18nKey={"onboarding.team.header.notice.disclaimer"} components={[<b></b>]} /></p>
                    {/* <p className='no-margin'>{translate('genericaccept')}</p> */}
                </div>
            </div>

            <div className='how-does-it-work-container'>
                <h6 className='title'><b>{translate('onboarding.team.header.how.it.works')}</b></h6>

                <div className="img-container">
                    <BannerVideoSlide
                        key={""}
                        id={""}
                        isNotOnCarouselView={false}
                        setCarouselAutoPlay={() => { }}
                        videoId={getAcademyVideoId2()}
                        bannerImage={academyThumbnail2}
                        showText={false}
                        showCenterPlayBtn
                        showWatchNowPlayBtn={false}
                        showPlayIconInWatchNow={false}
                        isVideo={true} />
                </div>
            </div>

            <div className='cashprize-container' style={{ backgroundImage: `url(${getTournamentBanner(TOURNAMENT_CATEGORY.DEFAULT)})` }} >
                <div className='cashprize-information'>
                    <p>{translate('onboarding.team.header.tournament.earning')}</p>
                    <h2 className='prize-pool'>{user.PersonalTournamentPrizePoolAsCurrency}</h2>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(TeamHeaderOnboarding);