import React from 'react';
import clsx from 'clsx';
import IPaymentCheckboxProps from './PaymentCheckboxProps';
import "./payment-check.scss";

const PaymentCheckbox: React.FC<IPaymentCheckboxProps> = ({ isChecked, onCheck }) => {
    return (<div className={clsx("payment-checkbox-container", { "checked": isChecked })} onClick={onCheck}>
        {isChecked && <div className='fill-checked'></div>}
    </div>)
}

export default PaymentCheckbox;