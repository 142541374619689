import * as React from 'react';

const Referral = ({ height = "20px", width = "20px", color = "#15182B", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg height={height} width={width} {...props} color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g id="Referral" transform="translate(0 -728.5)">
                <g id="Rectangle_2014" data-name="Rectangle 2014" transform="translate(0 728.5)" fill="#8187d0" stroke="#707070" strokeWidth="1" opacity="0">
                    <rect width="20" height="20" stroke="none" />
                    <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
                </g>
                <g id="user-group-crown-duotone" transform="translate(1.815 731.952)">
                    <path id="Tracé_3222" data-name="Tracé 3222" d="M9,0,7.366.818,5.729,0,4.092.818,2.455,0V3.274H9ZM7.026,7.776H4.433A4.433,4.433,0,0,0,0,12.208a.886.886,0,0,0,.887.888h9.685a.886.886,0,0,0,.888-.888A4.433,4.433,0,0,0,7.026,7.776Z" fill="#8187d0" />
                    <path id="Tracé_3223" data-name="Tracé 3223" d="M107.459,34.865A2.864,2.864,0,0,0,104.594,32a2.788,2.788,0,0,0-1.228.291v2.574a4.053,4.053,0,0,1-.632,2.161,2.833,2.833,0,0,0,1.859.7A2.863,2.863,0,0,0,107.459,34.865Zm-1.66,4.5H103.91a5.181,5.181,0,0,1,1.912,4.023,1.69,1.69,0,0,1-.256.888h3.507a.829.829,0,0,0,.842-.842A4.092,4.092,0,0,0,105.8,39.366Zm-9.8-4.5a3.274,3.274,0,1,0,6.548,0v-.409H96Z" transform="translate(-93.545 -31.181)" fill="#8187d0" opacity="0.4" />
                </g>
            </g>
        </svg>

    );
};

export default Referral;
