import TournamentWidgetContent from 'components/medium/tournament/off/tournament-widget/tournament-widget-content/TournamentWidgetContent';
import withTournamentWidget from 'hoc/tournament/with-tournament-widget/withTournamentWidget';
import React from 'react';
import ITournamentWidgetProps from './ITournamentWidgetProps';
import TournamentWidgetCountdown from './tournament-widget-countdown/TournamentWidgetCountdown';

const TournamentWidget: React.FC<ITournamentWidgetProps> = ({ tournament }: ITournamentWidgetProps) => {
    return (
        <div className="tournament-off-header">
            <TournamentWidgetCountdown tournament={tournament} hideClock={true} />
            <TournamentWidgetContent tournament={tournament} />
        </div>
    );
};

export default withTournamentWidget(TournamentWidget);
