
import React from 'react';

const Sparkles = () => {
    return (<svg id="sparkles-duotone_1_" data-name="sparkles-duotone" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 23.158 23.158">
        <path id="Tracé_3910" data-name="Tracé 3910" d="M9.277,64.421a.722.722,0,0,0-1.312,0L5.581,69.581.421,71.961a.725.725,0,0,0,0,1.316l5.161,2.384,2.379,5.156a.722.722,0,0,0,1.312,0l2.384-5.161,5.161-2.384a.722.722,0,0,0,0-1.312l-5.156-2.379Z" transform="translate(0 -61.105)" fill="#fff" />
        <path id="Tracé_3911" data-name="Tracé 3911" d="M320.339,3.854l2.556-.959.959-2.556a.521.521,0,0,1,.977,0l.959,2.556,2.556.959a.521.521,0,0,1,0,.977l-2.556.959-.959,2.556a.521.521,0,0,1-.977,0l-.959-2.556-2.556-.959a.521.521,0,0,1,0-.977Zm0,14.474,2.556-.959.959-2.556a.521.521,0,0,1,.977,0l.959,2.556,2.556.959a.521.521,0,0,1,0,.977l-2.556.959-.959,2.556a.521.521,0,0,1-.977,0l-.959-2.556-2.556-.959a.521.521,0,0,1,0-.977Z" transform="translate(-305.526)" fill="#fff" />
    </svg>

    )
}

export default Sparkles;