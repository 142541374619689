import { Dialog, Theme } from '@material-ui/core';
import styled from '@material-ui/styles/styled';
import React from 'react';
import { StyledNativeDialogProps } from './types';

const StyledNativeDialog: React.ComponentType<StyledNativeDialogProps> = styled(Dialog)<Theme, StyledNativeDialogProps>(({ isNative }) => ({
    marginTop: isNative ? 'calc(env(safe-area-inset-top))' : 'initial',
    marginBottom: isNative ? 'calc(env(safe-area-inset-bottom))' : 'initial',
}));

export default React.memo(StyledNativeDialog);