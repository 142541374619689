import clsx from 'clsx';
import React from 'react';
import useStackedImageEffect from '../../../hooks/style/use-stacked-image-effect/useStackedImageEffect';
import IStackedImageEffectProps from './IStackedImageProps';
import './style.scss';

export const StackedImageEffect: React.FC<IStackedImageEffectProps> = (props) => {
    const stackedEffect = useStackedImageEffect({ imgPath: props.imgPath });
    return <div className="stacked-img-effect">
        <div className={clsx('', {
            [stackedEffect.stacked]: props.applyStackedEffect,
            [stackedEffect.classic]: !props.applyStackedEffect,
        })} />
    </div>;
};
