import React from 'react';
import PrimaryButton from '../../buttons/primary-button/PrimaryButton';
import PrivateSellConfirmProps from './PrivateSellConfirmProps';
import SrcConfirmed from '../../../../assets/images/confirmed.png';
import './PrivateSellConfirm.scss';
import i18next from 'i18next';
import clsx from 'clsx';

const PrivateSellConfirm: React.FC<PrivateSellConfirmProps> = (props) => {

    return (
        <div className={clsx('private-sell-confirm private-sell-confirm-light', {})}>
            <img src={SrcConfirmed} alt="Confirmed" />
            <h2>{i18next.t('OverviewPrivateSell.confirmation')}</h2>

            <div className="content">
                <p className="first-text">{i18next.t('date-title-ico')} <b>{props.date}</b></p>
                <p className="description-modal"> {i18next.t('total-supply-ico')} <b>{props.initialSupply.toLocaleString()}</b>{i18next.t('PrivateSellConfirm.recap', { date: props.date })}</p>

                <p className="text-informations">{i18next.t('PrivateSellConfirm.info')}</p>
            </div>

            <div className="buttons">
                <PrimaryButton
                    receiveClickEvent={false}
                    disabled={false}
                    textButton={i18next.t('confirm-ico')}
                    type="button"
                    handleClick={() => props.launchPrivateSell()}
                />
            </div>

            <div className="information">
                <p>{i18next.t('PrivateSellConfirm.advice')}</p>
                <p>Tel : 06 22 51 64 95</p>
            </div>
        </div>
    );
};

export default PrivateSellConfirm;
