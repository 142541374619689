import * as React from 'react';

const CheckMark: React.FC = (props: React.SVGProps<SVGSVGElement>) => {
    const color = props.color || "#8187cf";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.786" height="10.645" viewBox="0 0 13.786 10.645" {...props}>
            <path id="checkmark" d="M169.953,22.427l4.2,4.2,7.463-7.463" transform="translate(-168.893 -18.105)" fill="none" stroke={color} stroke-width="3" />
        </svg>

    );
}

export default CheckMark;
