import { YIELD_DEFAULT_CONFIG } from 'config/_const';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import IUseYield from './IUseYield';

const useYield = (): IUseYield => {
    const { accountViewModel }: IUseAccount = useAccount();

    const shouldShowYield = (asset: AssetViewModel): boolean => asset.YieldEnabled && (!accountViewModel.isAuthenticated || accountViewModel.shouldShowYield());
    const calculateMaxAPY = (tokenValue: number, maxYield: number = YIELD_DEFAULT_CONFIG.MAX): number => maxYield.safeDivideBy(tokenValue);

    return {
        shouldShowYield,
        calculateMaxAPY,
    };
};

export default useYield;
