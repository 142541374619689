import * as React from 'react';
import IClubCardProps from './ClubCardProps';
import './ClubCard.scss';

const ClubCard: React.FunctionComponent<IClubCardProps> = ({ cardInformationList }) => {
    return <>
        {cardInformationList.map(card => {
            if (!card.show)
                return;
            return <article key={card.id} className='club-card'>
                <div className='club-card__img'>
                    <img src={card.img} alt='benzema-club-img' />
                </div>
                <div className='club-card__heading'>
                    <div className='club-card__icon'>{card.icon}</div>
                    <div className='club-card__topic'>
                        <div className='club-card__title'>{card.label}</div>
                        <div className='club-card__subtitle'>{card.value}</div>
                    </div>
                </div>
            </article>
        }
        )}
    </>
};

export default ClubCard;
