import Application from 'Application';
import i18next from 'i18next';
import React from 'react';
import ITournamentWidgetContentProps from './ITournamentWidgetContentProps';
import './style.scss';
import { getTournamentBanner } from 'data/TournamentBanner.data';
import { TOURNAMENT_CATEGORY } from 'config/_const';

const TournamentWidgetContent: React.FC<ITournamentWidgetContentProps> = ({ tournament }: ITournamentWidgetContentProps) => {
    const { Category, PrizePool } = tournament;
    const bannerImage = getTournamentBanner(Category as TOURNAMENT_CATEGORY);

    return (
        <div className="tournament-widget-content">
            <div style={{ backgroundImage: `url(${bannerImage})` }} className='background' >
                {Application.getInstance().showGamblingFeature &&
                    <div className='tournament-prize'>
                        <p className='title'>{i18next.t('tournament.cashprize')}</p>
                        <p className="prize-pool">{PrizePool.toCurrency()}</p>
                    </div>
                }
            </div>
        </div>
    );
};

export default TournamentWidgetContent;
