import Application from 'Application';
import TournamentResults from 'components/medium/tournament/off/tournament-results/TournamentResults';
import { TournamentLeagues } from 'config/_enums';
import withNextTournament from 'hoc/tournament/with-next-tournament/withNextTournament';
import withPreviousTournament from 'hoc/tournament/with-previous-tournament/withPreviousTournament';
import TournamentHeader from 'page/Tournament/TournamentHeader';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import TournamentWidget from '../tournament-widget/TournamentWidget';
import './style.scss';
import TournamentOffSectionProps from "./TournamentOffSectionProps";
import { DEFAULT_ID_AS_STRING } from 'config/_const';

const TournamentOffSection: React.FC<TournamentOffSectionProps> = (props) => {
    return (
        <div className="container-content-tournament-off">
            {props.nextTournament.Id !== DEFAULT_ID_AS_STRING && <TournamentHeader tournament={props.nextTournament} />}
            {props.nextTournament.Id === DEFAULT_ID_AS_STRING && <TournamentWidget />}

            <div>
                <TournamentResults activeLeague={Application.getInstance().getLeagueFromName(props.match.params.league as TournamentLeagues, props.previousTournament.PrizePool)} tournament={props.previousTournament} key="top-portfolio" />
            </div>
        </div>
    );
};

export default compose<React.FC>(
    withRouter,
    withPreviousTournament,
    withNextTournament,
)(TournamentOffSection);
