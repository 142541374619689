import React from 'react';
import { Trans } from 'react-i18next';
import Utils from 'utils/Utils';
import { TERMS_USE_LINKS, TRENDEX_LANGUAGES } from '../../../config/_const';
import './CheckboxCGU.scss';
import CheckboxCGUProps from './CheckboxCGUProps';

const CheckboxCGU: React.FC<CheckboxCGUProps> = ({
    termsText = 'cgu.label.generic',
    fileLink = [TERMS_USE_LINKS[Utils.getCurrentLanguage() as 'fr' | 'en'] ?? TERMS_USE_LINKS[TRENDEX_LANGUAGES.ENGLISH]],
    additionnalLinks = [],
    isChecked,
    handleClick,
}) => {
    return (
        <div className="general-terms">
            <div className='terms-content' onClick={handleClick}>
                <input type="checkbox" className="cgu-checkbox vertical-align-center" checked={isChecked} />
                <label className="terms-label">
                    <Trans i18nKey={termsText}>
                        {fileLink.concat(additionnalLinks).map((link: string) => <a href={link} target='_blank' className='terms-link' />)}
                    </Trans>
                </label>
            </div>
        </div>
    );
};

export default CheckboxCGU;
