import { OrderSide } from "config/_enums";
import useAccount from "hooks/use-account/useAccount";
import { useEffect, } from "react";
import { useDispatch } from "react-redux";
import { getPredictedPrice } from "service/assets/actions";
import { secondaryMarketGetPredictedPrice } from "service/secondary-market/actions";
import AssetInterface from 'model/AssetModel/AssetInterface';
import AssetViewModel from "view-model/Assets/AssetViewModel";

const useAssetSellInitiator = (asset: AssetInterface, numberOfToken: number) => {
    const dispatch = useDispatch();
    const { accountViewModel } = useAccount();
    const { _id: assetId } = asset;
    const assetViewModel = new AssetViewModel(asset);

    useEffect(() => {
        if (!assetId || !assetViewModel) return;

        dispatch(secondaryMarketGetPredictedPrice(assetId, numberOfToken, OrderSide.SELL, accountViewModel.Id));

        if (assetViewModel.InitialSaleDone)
            return;
        dispatch(getPredictedPrice(assetId, numberOfToken, false, true));
    }, [accountViewModel.Id, assetId, numberOfToken])

}

export default useAssetSellInitiator;