import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import Utils from '../../utils/Utils';
import * as AssetTransactionLogActionType from './actionType';

const ROOT_VALUE_STATE: ReduxRootState = {
    loading: false,
    data: [],
};

export const assetTransactionsLogReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {

        case AssetTransactionLogActionType.ASSET_TRANSACTION_LOG_LOADING:
            return {
                ...state,
                loading: true,
            };

        case AssetTransactionLogActionType.ASSET_TRANSACTION_LOG_SUCCESS:

            const stateData = state.data.length > 0 ? Utils.removedDuplicateMongoFile(state.data, action.payload.assetTransactionsLog) : [];
            return {
                ...state,
                data: stateData.concat(action.payload.assetTransactionsLog),
                loading: false,
            };

        case AssetTransactionLogActionType.ASSET_TRANSACTION_LOG_ERROR:

            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
