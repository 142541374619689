import React from 'react';
import IEuro2024WinRulesProps from './IEuro2024WinRulesProps';
import './euro-2024-win-rules.scss';
import i18n from '18n';
import Application from 'Application';

const Euro2024WinRules: React.FC<IEuro2024WinRulesProps> = () => {
    return (
        <div className='euro-2024-win-rules'>
            <span className='no-margin win-title'>
                <b>{i18n.t('euro2024.tournament.disclaimer.win-rules.title')}</b>
            </span>
            <div className='grid'>
                <p>
                    {i18n.t('euro2024.tournament.disclaimer.win-rules.key.1')}
                </p>
                <p>
                    {i18n.t('euro2024.tournament.disclaimer.win-rules.value', { percentage: Application.getInstance().FootballEuroRules().normal })}
                </p>
                <p>
                    {i18n.t('euro2024.tournament.disclaimer.win-rules.key.2')}
                </p>
                <p>
                    {i18n.t('euro2024.tournament.disclaimer.win-rules.value', { percentage: Application.getInstance().FootballEuroRules().prolongations })}
                </p>
                <p>
                    {i18n.t('euro2024.tournament.disclaimer.win-rules.key.3')}
                </p>
                <p>
                    {i18n.t('euro2024.tournament.disclaimer.win-rules.value', { percentage: Application.getInstance().FootballEuroRules().penalties })}
                </p>
            </div>
        </div>
    );
};

export default Euro2024WinRules;