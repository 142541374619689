import MoneyEarned from 'components/icons/referral/MoneyEarned';
import Referral from 'components/icons/referral/Referral';
import Facebook from 'components/icons/social-media/Facebook';
import Telegram from 'components/icons/social-media/Telegram';
import Twitter from 'components/icons/social-media/Twitter';
import { G_TAGS_KNOWN_EVENTS, HIDE_DISCORD_BUTTON, MAX_EARN_PER_INVITE } from 'config/_const';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FacebookShareButton, TelegramShareButton, TwitterShareButton } from 'react-share';
import { compose } from 'redux';
import { RootState } from 'model/Redux';
import { getReferral } from 'service/affiliate/getters';
import { managementModal } from 'service/modal/action';
import { fetchReferralTransactions } from 'service/transactions/actions';
import { referralTransactionsSelector } from 'service/transactions/selectors';
import JoinDiscordBlock from '../join-discord-block/JoinDIscordBlock';
import ReferYourFriendsTitle from '../referYourFriends-title/ReferYourFriendsTitle';
import ReferenceYourFriends from '../referenceYourFriends/ReferenceYourFriends';
import { IRefferalInfo, IShareProfileProps } from './ShareProfileProps';
import "./ShareProfile.scss";
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';


const ShareProfile: React.FC<IShareProfileProps> = ({ url, text, referralTransactions }) => {
    const { accountViewModel }: IUseAccount = useAccount();
    const { earnedReferral } = getReferral(referralTransactions);
    const dispatch = useDispatch();
    TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.REFERRAL_ZONE, { userId: accountViewModel.Id })
    React.useEffect(() => {
        dispatch(fetchReferralTransactions());
    }, []);

    const referralInfo: IRefferalInfo[] = useMemo(() => ([
        { id: "R1", icon: <Referral />, label: i18next.t('Referral.shareProfile.friendReferred'), value: accountViewModel.referralUserList?.length },
        { id: "R2", icon: <MoneyEarned />, label: i18next.t('Referral.shareProfile.totalReferralEarn'), value: earnedReferral.toCurrency() },
    ]), [accountViewModel.earnedReferralInLast30Days, accountViewModel.referralUserList]);

    const ShareProfileReferral = (): JSX.Element => (
        <div className='referral-container'>
            <ReferYourFriendsTitle extraTitle={i18next.t('Referral.shareProfile.earnUpTo.title', { payment: MAX_EARN_PER_INVITE.toCurrency(0) })} />
            {accountViewModel.isAuthenticated &&
                <>
                    <ReferenceYourFriends greenButton={false} />
                    {accountViewModel.referralUserList?.length
                        ? <div className="referral-info-data">
                            {referralInfo.map((info) => <div key={info.id} className='refer-info'>
                                <span className="referInfo-label">{info.icon}{info.label} :</span>
                                <span className="referInfo-value">{info.value}</span>
                            </div>)}
                        </div>
                        : null}
                </>}
        </div>
    );

    return (
        <div className='share-profile-container'>
            <div className="share-profile-top-section">
                <h2 className='title'>{i18next.t('Referral.shareProfile.title')}</h2>
                <ShareProfileReferral />
                <div className="link-container">
                    <div className="link-social-network">
                        <span className="link-button-wrapper">
                            <FacebookShareButton className='share-button-size' url={url} quote={text}>
                                <Facebook />
                            </FacebookShareButton>
                        </span>
                        <h5>{i18next.t('asset-form-admin-facebook')}</h5>
                    </div>
                    <div className="link-social-network">
                        <span className="link-button-wrapper">
                            <TwitterShareButton className='share-button-size' url={url} title={text}>
                                <Twitter />
                            </TwitterShareButton>
                        </span>
                        <h5>{i18next.t('asset-form-admin-twitter')}</h5>
                    </div>
                    <div className="link-social-network">
                        <span className="link-button-wrapper">
                            <TelegramShareButton className='share-button-size' url={url} title={text}>
                                <Telegram />
                            </TelegramShareButton>
                        </span>
                        <h5>{i18next.t('social.media.telegram')}</h5>
                    </div>
                </div>
            </div>

            {!HIDE_DISCORD_BUTTON && <div className='share-profile-footer-section'>
                <JoinDiscordBlock />
            </div>}
        </div>
    )
};

const mapStateToProps = (state: RootState) => ({
    referralTransactions: referralTransactionsSelector(state),
});

export default compose(
    connect(mapStateToProps, {
        managementModal,
    }),
)(React.memo(ShareProfile));
