import { AssetCategory } from 'config/_enums';
import AssetCategoryInterface from '../../model/AssetModel/AssetCategoryInterface';

export default class AssetCategoryViewModel {
    private icon: string;
    private description: string;
    private name: string;
    private id: string;
    private banner: string[];

    constructor(assetCategory: AssetCategoryInterface) {
        this.icon = assetCategory.icon;
        this.description = assetCategory.description;
        this.name = assetCategory.name;
        this.id = assetCategory._id;
        this.banner = assetCategory.banner;
    }

    public get Id(): string {
        return this.id;
    }
    public set Id(value: string) {
        this.id = value;
    }
    public get Name(): string {
        return this.name;
    }
    public set Name(value: string) {
        this.name = value;
    }
    public get Description(): string {
        return this.description;
    }
    public set Description(value: string) {
        this.description = value;
    }
    public get Icon(): string {
        return this.icon;
    }
    public set Icon(value: string) {
        this.icon = value;
    }
    public get Banner(): string[] {
        return this.banner;
    }
    public set Banner(value: string[]) {
        this.banner = value;
    }
    public IsInstagramCategory(): boolean {
        return this.Name === AssetCategory.Instagram;
    }
}