
import Application from 'Application';
import clsx from 'clsx';
import Bitcoin from 'components/icons/Bitcoin';
import CreditCard from 'components/icons/CreditCard';
import PlusIcon from 'components/icons/Plus';
import Wallet from 'components/icons/wallet/Wallet';
import CircularLoader from 'components/small/CircularLoader';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import CreditCardWrapper from 'components/small/credit-card-wrapper/CreditCardWrapper';
import PaymentOptionCard from 'components/small/payment-option-card/PaymentOptionCard';
import CryptoPaymentCard from 'components/small/payment-option-card/crypto-payment-card/CryptoPaymentCard';
import SelectionTabs from 'components/small/selection-tabs/SelectionTabs';
import { BitPayInitiationPages, MarketEnum, PaymentMethodsEnum } from 'config/_enums';
import useAccount from 'hooks/use-account/useAccount';
import useCreditByBitpay from 'hooks/use-credit-by-bitpay/useCreditByBitpay';
import useSlidingPages from 'hooks/use-sliding-pages/useSlidingPages';
import { useDevice } from 'hooks/useDevice';
import i18next from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserCredits } from 'service/auth/actions';
import WalletRefundViewModel from 'view-model/WalletRefundViewModel/WalletRefundViewModel';
import i18n from '18n';
import { DEFAULT_AMOUNT_REFUND_WALLET, MIN__BUY_CREDIT_PAYMENT_AMOUNT } from 'config/_const';
import CheckoutModeEnum from '../stripe-elements/CheckoutModeEnum';
import "./wallet-refund.scss";

const WalletRefund: React.FC = () => {
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<number>(DEFAULT_AMOUNT_REFUND_WALLET);
    const [isCreditCardPaymentReady, setCreditCardPaymentReady] = useState<boolean>(true);
    const walletRefundViewModel = useMemo(() => new WalletRefundViewModel(value), [value]);
    const { accountViewModel } = useAccount();
    const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentMethodsEnum>(accountViewModel.StripeCustomerId?.safeIsNullOrEmpty() ?? false ? PaymentMethodsEnum.STRIPE : PaymentMethodsEnum.STRIPE_NEW_CARD);
    const { hideSlidingPage } = useSlidingPages();
    const { creditWithBitpay, transactionLoading } = useCreditByBitpay(BitPayInitiationPages.REFUND_WALLET);
    const [confirmText, setConfirmText] = useState("Myportfolio.confirm");
    const stripeConfirmationMethodRef = useRef<Function>(() => { });
    const onCreditSuccess = () => {
        dispatch(getUserCredits());
        hideSlidingPage();
    }

    const device = useDevice();

    const handleConfirmation = async () => {
        switch (selectedPaymentType) {
            case PaymentMethodsEnum.BITPAY:
                creditWithBitpay(value);
                break;
            case PaymentMethodsEnum.STRIPE:
            case PaymentMethodsEnum.STRIPE_NEW_CARD:
                stripeConfirmationMethodRef?.current();
                break;
        }
    }

    const isConfirmReady = () => {
        return walletRefundViewModel.IsReady && (isCreditCardPaymentReady || selectedPaymentType !== (PaymentMethodsEnum.STRIPE || PaymentMethodsEnum.STRIPE_NEW_CARD))
    }

    const renderStripeSubComponent = (): JSX.Element => {
        const newCardForm = selectedPaymentType === PaymentMethodsEnum.STRIPE_NEW_CARD;
        return <CreditCardWrapper
            fiatAmount={value}
            mode={CheckoutModeEnum.CREDIT_ACCOUNT}
            hasStripeCustomerId={accountViewModel.StripeCustomerId?.safeIsNullOrEmpty() ?? false}
            trackEventOnPixel={true}
            showLoader={true}
            intent={{ fiatAmount: value, concernedMarket: MarketEnum.NONE, displayCreditForm: false }}
            creditAccountSuccessCallback={onCreditSuccess}
            confirmationMethodRef={stripeConfirmationMethodRef}
            setCreditCardReady={setCreditCardPaymentReady}
            newCardForm={newCardForm}
        />
    }

    const renderBitpaySubComponent = (): JSX.Element => (
        <CryptoPaymentCard handleClick={handleConfirmation} />
    )

    const paymentOptions = [
        { id: 1, value: PaymentMethodsEnum.STRIPE, label: i18next.t("Payment.card"), info: <CreditCard />, isDisabled: !walletRefundViewModel.IsReady || !(accountViewModel.StripeCustomerId?.safeIsNullOrEmpty() ?? false), renderSubComponent: renderStripeSubComponent },
        { id: 2, value: PaymentMethodsEnum.STRIPE_NEW_CARD, label: i18next.t("Payment.newCard"), info: <CreditCard />, isDisabled: !walletRefundViewModel.IsReady, renderSubComponent: renderStripeSubComponent },
        { id: 3, value: PaymentMethodsEnum.BITPAY, label: i18next.t("payment.crypto"), info: <Bitcoin />, isDisabled: !walletRefundViewModel.IsReady, renderSubComponent: renderBitpaySubComponent }
    ];

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const inputValue = parseInt(event.target.value.trim(), 10);
        if (!inputValue || isNaN(inputValue))
            return setValue(0);
        setValue(walletRefundViewModel.getRefundAmountInRange(inputValue));
    }


    const setSelectedValue = (selectedValue: string | number) => {
        setValue(Number(selectedValue));
    }

    useEffect(() => {
        switch (selectedPaymentType) {
            case PaymentMethodsEnum.STRIPE:
            case PaymentMethodsEnum.STRIPE_NEW_CARD:
                setConfirmText("confirmTransaction.stripe");
                break;
            case PaymentMethodsEnum.BITPAY:
                setConfirmText("confirmTransaction.bitpay");
                break;
            default:
                setConfirmText("Myportfolio.confirm");
                break;
        }
    }, [selectedPaymentType])

    return <div className={clsx("wallet-action-container", { "wallet-action-ios": device?.operatingSystem === "ios" })}>
        <h1 className='wallet-action-title'>{walletRefundViewModel.Title}</h1>
        <div className="action-value-display" onClick={() => inputRef.current?.focus()}>
            <div className="action-value-notations">
                <PlusIcon />
                <Wallet />
                {Application.getInstance().currency}
            </div>
            <div className="action-value-fiat">
                <input
                    placeholder={i18n.t('short.credit.account.min', { amount: MIN__BUY_CREDIT_PAYMENT_AMOUNT.toCurrency(0) })}
                    ref={inputRef}
                    value={walletRefundViewModel.Value || ''}
                    inputMode='numeric'
                    className="value-input"
                    onChange={handleInputChange} />
                {walletRefundViewModel.Value > 0 && <span>{walletRefundViewModel.CurrencySymbol}</span>}
            </div>
        </div>
        <div className="fiat-selection-tabs">
            <SelectionTabs
                options={walletRefundViewModel.SelectionOptions}
                selectedValue={value}
                setSelectedValue={setSelectedValue} />
        </div>

        <div className="confirm-payment-type">
            {paymentOptions.map((option) =>
                <PaymentOptionCard
                    isSelected={selectedPaymentType === option.value}
                    handleClick={(value) => setSelectedPaymentType(value)}
                    label={option.label}
                    value={option.value}
                    info={option.info}
                    isDisabled={option.isDisabled}
                    key={option.id}
                    renderSubComponent={option.renderSubComponent} />
            )}
        </div>
        <PrimaryButton
            className='confirmation-button'
            handleClick={handleConfirmation}
            textButton={i18next.t(confirmText)}
            receiveClickEvent={false}
            type={"button"}
            disabled={!isConfirmReady()} />
        {transactionLoading && <CircularLoader loaderLocal={false} loaderMessage={i18next.t("genericloading")} />}
    </div>
}

export default WalletRefund;