import useYield from 'hooks/use-yield/useYield';
import React from 'react';

const withYield = (Component: any) => {
    return (props: any) => {
        const { calculateMaxAPY, shouldShowYield } = useYield();
        return <Component calculateMaxAPY={calculateMaxAPY} shouldShowYield={shouldShowYield} {...props} />;
    };
};
export default withYield;
