import { ApplicationSportVersionEnum } from 'config/_enums';
import IUserAbTesting from 'model/AbTesting/User/IUserAbTesting';
import { ReduxRootState } from 'model/Redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UserStoreData from 'service/auth/UserStoreData';
import { acceptCGUPayment, actionLoadMe, actionLoginUser, actionRegisterUser, finishOnboarding, getUserCredits, launchOnboarding, saveCookieConsentChoice, updateOnboardingStep, updateUserAbTesting } from 'service/auth/actions';
import { authSelector } from 'service/auth/selectors';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import { LOCAL_STORAGE_KNOWN_KEYS } from '../../config/_const';
import IUseAccount from './IUseAccount';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

const useAccount = (): IUseAccount => {
    const dispatch = useDispatch();
    const browserHistory = useHistory();
    const auth: ReduxRootState<UserStoreData> = useSelector(authSelector);
    const logInUser = (email: string, password: string) => {
        dispatch(actionLoginUser(email, password));
    };

    const registerUser = (email: string, password: string, lastName: string, firstName: string, token: string, signupVersion?: ApplicationSportVersionEnum, registerAsVip?: boolean) => {
        dispatch(actionRegisterUser(email, password, lastName, firstName, token, registerAsVip, browserHistory, undefined, undefined, signupVersion));
    };

    const acceptPaymentCGU = () => {
        dispatch(acceptCGUPayment());
    };

    const handleCookieConsent = (choice: boolean) => {
        if (!auth.data.isAuthenticated)
            return;
        dispatch(saveCookieConsentChoice(choice));
    };

    const updateAbTesting = (body: IUserAbTesting) => {
        if (!auth.data.isAuthenticated)
            return;
        dispatch(updateUserAbTesting(body));
    };

    const acceptSMSNotification = (smsNotificationEnabled: boolean): void => {
        return localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.SMS_NOTIFICATION_ENABLED, JSON.stringify(smsNotificationEnabled));
    }

    const updateUserOnboardingStep = (): ThunkAction<void, null, unknown, Action<string>> => {
        return updateOnboardingStep();
    };

    const handleLaunchOnboarding = (): ThunkAction<void, null, unknown, Action<string>> => {
        return launchOnboarding();
    }

    const refreshUser = () => {
        return actionLoadMe();
    }

    const handleFinishOnboarding = () => {
        return finishOnboarding();
    }

    const refreshUserCredits = () => {
        return getUserCredits();
    }

    return {
        logInUser,
        registerUser,
        acceptPaymentCGU,
        handleCookieConsent,
        updateAbTesting,
        acceptSMSNotification,
        updateOnboardingStep: updateUserOnboardingStep,
        handleLaunchOnboarding,
        refreshUser: refreshUser,
        handleFinishOnboarding,
        refreshUserCredits,
        accountViewModel: new AccountViewModel({
            isAuthenticated: auth.data.isAuthenticated,
            account: auth.data.account,
            loadingUploadPicture: auth.data.loadingUploadPicture,
            loadingUploadIdentity: auth.data.loadingUploadIdentity,
            emailVerification: auth.data.emailVerification,
            isAdminToVip: auth.data.isAdminToVip
        }),
        accountIsLoading: auth.loading,
    };
};

export default useAccount;
