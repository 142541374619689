import * as React from 'react';
import TxnAssetHeaderHalfScreenProps from './TxnAssetHeaderHalfScreenProps';
import './TxnAssetHeaderHalfScreen.scss';
import PackVipCard from 'components/small/pack-vip-card/PackVipCard';

const TxnAssetHeaderHalfScreen: React.FunctionComponent<TxnAssetHeaderHalfScreenProps> = ({ assetTransactionViewModel, renderDetailSubComponent }) => {
    const showPackVipCard = React.useMemo(() => {
        return assetTransactionViewModel.Asset.PackEnabled || assetTransactionViewModel.FromPack;
    }, [assetTransactionViewModel]);
    return (
        <div className="buy-asset-header desktop-view">
            <div className="buy-asset-header_title">
                <span className='buy-asset-header_title-label'>{assetTransactionViewModel.HeaderLabel} </span>
            </div>
            {showPackVipCard && <div className="pack-vip-container"><PackVipCard assetName={assetTransactionViewModel.Asset.AssetName} /></div>}
            {renderDetailSubComponent()}
        </div>
    )
};

export default React.memo(TxnAssetHeaderHalfScreen);
