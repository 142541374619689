import { TOURNAMENT_CATEGORY, TRENDEX_ROLES, ZERO } from 'config/_const';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import StackInterface from 'model/User/StackInterface';
import AccountViewModel from '../AccountViewModel/AccountViewModel';
import AssetViewModel from '../Assets/AssetViewModel';
import Utils from 'utils/Utils';

class AssetWalletViewModel {
    private static OWN_TOKENS_AMOUNT_TO_BE_PLAY: number = 5000;
    private id: string;
    private asset: AssetViewModel;
    private amount: number;
    private boughtAt: number;
    private amountBoughtInitialSale?: number;
    private stack: StackInterface[];
    private numberToSell: number;
    private tokensInTeam?: number;

    constructor(entry: AssetWalletInterface) {
        this.id = entry._id
        this.asset = new AssetViewModel(entry.asset);
        this.amount = entry.amount;
        this.boughtAt = entry.boughtAt;
        this.stack = entry.stack;
        this.numberToSell = entry.numberToSell;
        this.tokensInTeam = entry.tokensInTeam;
    }

    public get Id(): string {
        return this.id;
    }
    public get Asset(): AssetViewModel {
        return this.asset;
    }
    public get Amount(): number {
        return this.amount;
    }

    public get BoughtAt(): number {
        return this.boughtAt ?? 1;
    }
    public get AmountBoughtInitialSale(): number {
        return this.amountBoughtInitialSale ?? 0;
    }
    public get NumberToSell(): number {
        return this.numberToSell;
    }
    public get Earning(): number {
        return this.calculateEarning();
    }
    public get Stack(): StackInterface[] {
        return this.stack;
    }

    public get TokensInTeam(): number {
        return this.tokensInTeam ?? 0;
    }

    public get TokensAmountWithTeams(): number {
        return this.Amount.addition(this.TokensInTeam);
    }

    private shouldDisableForTouranment(tournamentCategory?: string): boolean {
        const assetCategoryName: string | undefined = Utils.getAssetCategoryNameFromId(this.Asset.MainCategoryId);
        const isNonDefaultTouranment = Boolean(tournamentCategory) && tournamentCategory !== TOURNAMENT_CATEGORY.DEFAULT;
        const hasGoodSportScore = this.asset.SportScore > ZERO;
        
        return (isNonDefaultTouranment &&
            (this.Asset.IsSportPlayerRetired || tournamentCategory !== assetCategoryName || !hasGoodSportScore)
        );
    };

    public isEnabledForTeam(user: AccountViewModel, tournamentCategory?: string): boolean {
        if (this.shouldDisableForTouranment(tournamentCategory))
            return false;
        if (!this.Asset.AvailableForTournament)
            return false;
        return user.UserType === TRENDEX_ROLES.USER ? true : this.isAvailableToPlay(user.Id);
    }

    private isAvailableToPlay(userId: string): boolean {
        return !this.Asset.belongsTo(userId) ? true : !this.isTokensAmountTooHighToBePlayed()
    }

    private isTokensAmountTooHighToBePlayed(): boolean {
        return this.Amount > AssetWalletViewModel.OWN_TOKENS_AMOUNT_TO_BE_PLAY;
    }

    public getTokensAmount(userId: string): number {
        if (!this.Asset.belongsTo(userId))
            return this.Amount;
        return !this.isTokensAmountTooHighToBePlayed() ? this.Amount : 0;
    }

    private calculateEarning(): number {
        return this.TokensAmountWithTeams * this.Asset.CurrentValueAsNumber - this.TokensAmountWithTeams * this.BoughtAt;
    }
}

export default AssetWalletViewModel;