import React from 'react';
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import AxiosConfig from '../../config/axiosConfig';
import * as Input from '../../components/small/input/ReduxFormInput';
import { managementNotification } from '../../service/notification/action';
import Application from 'Application';
import './ForgotPassword.scss';
import { checkValue } from '../../middleware/reduxFormValidator';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { isValidEmail } from 'utils/validator';
import { useHistory } from 'react-router-dom';
import Utils from 'utils/Utils';
import { connect } from 'react-redux';
import { ForgotPasswordProps } from './ForgetPasswordProps';

const INITIAL_STATE_FORM = {
  email: '',
};

const ForgotPassword: React.FC<InjectedFormProps<{}, ForgotPasswordProps> & ForgotPasswordProps> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const [email, setEmail] = React.useState<string>('');
  const [isEmailFocus, setIsEmailFocus] = React.useState<boolean>(false);

  const onChangeInputEmail = (e: any): void => {
    setEmail(Utils.getTrimValue(e.target.value));
  };

  const emailValidatorComponent = (value: string): JSX.Element | undefined =>
    !isValidEmail(value)
      ? <span key={"invalid-email" + value}>
        <FontAwesomeIcon className='icon' icon={faTimesCircle} />{i18next.t('register.mail.errorMessage')}
      </span>
      : undefined;

  const isEmailValid = (): boolean => email.length > 0 && isValidEmail(email);

  const handleFailure = (message: string): void => {
    dispatch(managementNotification(true, message, 400));
  };

  const handleSuccess = () => {
    dispatch(managementNotification(true, i18next.t('forgotPassword.success')));
    props.reset();
    history.push('/signup');
  };

  const submitForgotPassword = async (values: { [k: string]: string | number }) => {
    const currentLanguage = Utils.getCurrentLanguage();

    try {
      const response = await AxiosConfig.post('/auth/forgot-password', {
        email: values.email,
        clientLang: currentLanguage,
      });

      if (response.data.status !== 200) {
        handleFailure(response.data.message);
        throw new SubmissionError({
          _error: 'An error occurred while submitting contact form',
        });
      }

      handleSuccess();
      return response;
    } catch (error: any) {
      handleFailure(error.toString());
      throw new SubmissionError({
        _error: 'An error occurred while submitting contact form',
      });
    }
  };

  return (
    <div className="forgot_password">
      <section className='forgot-header'>
        <h3 className="forgot-header-title">{i18next.t('forgotPassword.title')}</h3>
        <p className='forgot-header-subtitle'> {i18next.t('forgotPassword.email.label', { brand: Application.getInstance().brand })}</p>
      </section>

      <form
        className="forgot_password_form"
        onSubmit={props.handleSubmit(submitForgotPassword)}
      >
        <div className="form_section">
          <div className="form-group">
            <Field
              name={'email'}
              placeholder={i18next.t('login.placeholder.email')}
              type="text"
              variant="standard"
              className="your-email-field"
              normalize={Utils.getTrimValue}
              validate={[
                checkValue,
                emailValidatorComponent,
              ]}
              onChange={onChangeInputEmail}
              onFocus={() => setIsEmailFocus(true)}
              component={Input.generateInputText}
            />
            {isEmailFocus
              ? isEmailValid() && <p className="valid-message"><FontAwesomeIcon className='icon' icon={faCheckCircle} />{i18next.t('register.mail.validMessage')}</p>
              : null
            }
          </div>

          <div className="form_btn">
            <PrimaryButton
              disabled={!props.valid || props.submitting}
              receiveClickEvent={false}
              type="submit"
              className={'search-email'}
              textButton={i18next.t('forgotPassword.submit')}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (_state: ForgotPasswordProps, ownProps: ForgotPasswordProps) => ({
  initialValues: ownProps.initialValues
    ? { ...ownProps.initialValues }
    : INITIAL_STATE_FORM,
});

const ForgotPasswordFormRedux = reduxForm<{}, ForgotPasswordProps>({
  form: 'forgotPassword',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
})(ForgotPassword);

export default connect(mapStateToProps)(ForgotPasswordFormRedux);
