import Utils from 'utils/Utils';
import { TRENDEX_ROLES, URL_DEFAULT_AVATAR, USER_METADATA, VERIFICATION_STATUS } from '../../config/_const';
import UserInterface from './UserInterface';
import { OnboardingStep } from 'config/_enums';

export const FakeUser: UserInterface = {
    _id: '-1',
    userCGUaccepted: false,
    facebook: {
        id: '-1',
        name: 'Fake',
        token: '-1',
        email: 'fake@gmail.com',
    },
    google: {
        id: '-1',
        name: 'Fake',
        token: '-1',
        email: 'fake@gmail.com',
    },
    apple: {
        id: '-1',
        name: 'Fake',
        token: '-1',
        email: 'fake@gmail.com',
    },
    twitter: {
        id: '-1',
        name: 'Fake',
        token: '-1',
        email: 'fake@gmail.com',
    },
    local: {
        email: 'fake@gmail.com',
    },
    password: '',
    identityRefusalReasons: '',
    email: 'fake@gmail.com',
    lastName: 'Fake',
    firstName: 'User',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    credits: 0,
    virtualCredits: 0,
    stripeCustomerId: '',
    avatar: URL_DEFAULT_AVATAR,
    dateOfBirth: '',
    country: Utils.getMetadataForFakeUser(USER_METADATA.COUNTRY),
    uploadIdentityDate: new Date().toISOString(),
    transactions: [],
    cardIdImgUrl: '',
    isConfirmed: VERIFICATION_STATUS.REFUSED,
    type: TRENDEX_ROLES.USER,
    assetTransactions: [],
    assetWallet: [],
    subscribedAssetsIds: [],
    validatedEmail: false,
    emailConfirmed: false,
    referral: {
        partner: false,
        subPartner: false,
        ownReferralId: '-1',
        referralTermsAccepted: false,
        enabled: true,
        rewardPerReferral: {
            enabled: true,
        }
    },
    totalEarningsHistory: [],
    totalCreditsHistory: [],
    currentWalletTotalValue: 0,
    totalEarningPercentage: 0,
    ownedAssets: [],
    disabled: false,
    frozenCredits: 0,
    isOwnedAssetPrivateSellEnded: false,
    identificationDocumentNumber: '',
    language: Utils.getMetadataForFakeUser(USER_METADATA.LANGUAGE),
    pseudo: '',
    userPseudo: '',
    cookieConsentAccepted: false,
    abTesting: {},
    timezone: Utils.getMetadataForFakeUser(USER_METADATA.TIMEZONE),
    currency: Utils.getMetadataForFakeUser(USER_METADATA.CURRENCY),
    locale: Utils.getMetadataForFakeUser(USER_METADATA.LOCALE),
    ip: '',
    totalSales: 0,
    onboardingStep: OnboardingStep.NO_ONBOARDING,
};
