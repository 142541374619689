import useAssetSportStatistics from "hooks/asset/use-asset-sport-statistics/useAssetSportStatistics";
import React from "react";
import IInjectedWithAssetSportStatistics from "./IInjectedWithAssetSportStatistics";
import IWithAssetSportStatisticsProps from "./IWithAssetSportStatisticsProps";
import AssetScoreEvolutionViewModel from "view-model/Assets/AssetScoreEvolutionViewModel";

const withAssetSportStatistics = <BaseProps,>(ChildComponent: React.ComponentType<BaseProps & IInjectedWithAssetSportStatistics>): React.FC<IWithAssetSportStatisticsProps<BaseProps>> => {
    const WrappedComponent: React.FC<IWithAssetSportStatisticsProps<BaseProps>> = (props: IWithAssetSportStatisticsProps<BaseProps> & BaseProps) => {
        const { fetchAssetSportStatistics, statistics } = useAssetSportStatistics(props.assetVM);

        React.useEffect(() => {
            fetchAssetSportStatistics();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return <ChildComponent statistics={statistics} assetScoreEvolutionVM={new AssetScoreEvolutionViewModel(props.assetVM.SportScore)} {...props as BaseProps} />
    }

    return React.memo<IWithAssetSportStatisticsProps<BaseProps>>(WrappedComponent);
}

export default withAssetSportStatistics;