import { Capacitor } from '@capacitor/core';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { GetResult, Storage } from '@capacitor/storage';
import moment from 'moment';
import { MOBILE_STORAGE_KEYS } from './config/_const';

const getShouldPersist = (value: string, tokenValue: string): boolean => {
    const { token, time } = JSON.parse(value);
    if (token !== tokenValue)
        return true;
    return moment().diff(moment(time), 'months') > 0;
};

export const initMobilePush = async (sendToken: (token: string) => void): Promise<void> => {
    const isPushNotificationsPluginAvailable: boolean = Capacitor.isPluginAvailable('PushNotifications');
    if (!isPushNotificationsPluginAvailable)
        return;

    PushNotifications.requestPermissions().then((result) => {
        if (result.receive !== 'granted')
            return;
        PushNotifications.register();
    });

    PushNotifications.addListener('registration',
        async (token: Token) => {
            const tokenValue: string = token.value;
            let shouldPersist: boolean = true;
            const { value }: GetResult = await Storage.get({ key: MOBILE_STORAGE_KEYS.TDX_MOBILE_DEVICE_TOKEN_DATA });
            if (value)
                shouldPersist = getShouldPersist(value, tokenValue);

            if (shouldPersist) {
                sendToken(tokenValue);
                await Storage.set({
                    key: MOBILE_STORAGE_KEYS.TDX_MOBILE_DEVICE_TOKEN_DATA,
                    value: JSON.stringify({
                        token: tokenValue,
                        time: moment().toDate(),
                    }),
                });
            }
        },
    );
};

