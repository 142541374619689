import { CircularProgress } from '@material-ui/core';
import React from 'react';
import ILoadingWrapperProps from './ILoadingWrapperProps';
import './style.scss';
import clsx from 'clsx';
const LoadingWrapper: React.FC<ILoadingWrapperProps> = (props) => {
    return props.waitingOperation()
        ? <div className={clsx('center',
            { 'loading-wrapper': !props.loadingComponent })}
        >
            {props.loadingComponent ? props.loadingComponent : <CircularProgress />}
        </div>
        : props.child;
};

export default React.memo(LoadingWrapper);
