import * as React from "react";

function TopTalents() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14.681'
      height='16.778'
      viewBox='0 0 14.681 16.778'
    >
      <path
        id='fire-regular_1_'
        data-name='fire-regular (1)'
        d='M4.08,1.679A19.651,19.651,0,0,1,5.918,3.641,25.742,25.742,0,0,1,9.175,0c3.22,2.986,5.505,6.882,5.505,9.228a7.444,7.444,0,0,1-7.34,7.55A7.444,7.444,0,0,1,0,9.228C0,7.484,1.7,3.883,4.08,1.679ZM7.34,15.2a5.884,5.884,0,0,0,5.767-5.977c0-1.487-1.45-4.365-3.936-7.016A22.525,22.525,0,0,0,7.193,4.568l-1.2,1.651L4.7,4.638q-.289-.35-.59-.688C2.592,5.833,1.573,8.205,1.573,9.228A5.884,5.884,0,0,0,7.34,15.2ZM4.407,7.966,6.091,9.883s2.634-3.362,2.83-3.592C10.316,7.96,11.01,8.933,11.01,10.04a3.53,3.53,0,0,1-3.752,3.592,3.867,3.867,0,0,1-2.216-.692,3.428,3.428,0,0,1-.958-4.4C4.181,8.356,4.289,8.163,4.407,7.966Z'
        fill='#fff'
      />
    </svg>
  );
}

export default TopTalents;
