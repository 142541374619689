import React from 'react';
import WhiteListDisclaimerProps from 'components/small/white-list-box-button/white-list-disclaimer/WhiteListDisclaimerProps';
import WhiteListDisclaimerViewModel from 'view-model/WhiteListDisclaimerViewModel/WhiteListDisclaimerViewModel';
import { getWebflowEndPoint, WEBFLOW_PARAMS } from 'config/externalEndPoints';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import useUserLeague from 'hooks/use-user-league/useUserLeague';
import IWithWhiteListDisclaimer from './IWithWhiteListDisclaimer';

const withWhiteListDisclaimer = (Component: React.FC<WhiteListDisclaimerProps>) => {
    const MemoComponent: React.FC<WhiteListDisclaimerProps> = React.memo(Component);

    const WrappedComponent: React.FC<IWithWhiteListDisclaimer> = ({ t: translate, assetVM, icon }) => {
        const { accountViewModel }: IUseAccount = useAccount();
        const { userLeagueViewModel } = useUserLeague();

        const openWorkFlowPage = () => {
            if (!accountViewModel.isAuthenticated) {
                const url = getWebflowEndPoint().challengeURL;
                window.open(url, '_blank');
            } else {
                if (accountViewModel.IsUserWhiteListed() || userLeagueViewModel.LeagueAttribution.isWhitelisted())
                    return;
                let WF_URL = getWebflowEndPoint().challengeURL + `?${WEBFLOW_PARAMS.EMAIL}=${accountViewModel.Email}`;
                if (accountViewModel.firstName) {
                    WF_URL += `&${WEBFLOW_PARAMS.FIRSTNAME}=${accountViewModel.firstName}`;
                }
                if (accountViewModel.LastName) {
                    WF_URL += `&${WEBFLOW_PARAMS.LASTNAME}=${accountViewModel.LastName}`;
                }

                window.open(WF_URL, '_blank');
            }
        };

        const whiteListDisclaimerViewModel: WhiteListDisclaimerViewModel = React.useMemo(() => new WhiteListDisclaimerViewModel(accountViewModel, userLeagueViewModel.LeagueAttribution, assetVM), [accountViewModel, userLeagueViewModel]);

        return <MemoComponent onClick={openWorkFlowPage} translate={translate} whiteListDisclaimerViewModel={whiteListDisclaimerViewModel} icon={icon} />

    }

    return React.memo(WrappedComponent);
}

export default withWhiteListDisclaimer;