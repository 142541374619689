import Application from "Application";
import WhatsAppLogo from 'components/icons/whatsapp/logo-whatsapp.svg';

class WhatsAppService {
    private whatsappButton: HTMLAnchorElement;
    private static isWhatsAppChatEnabled: boolean = Application.getInstance().showWhatsAppChat;

    constructor() {
        this.whatsappButton = document.createElement("a");
    }

    init(): void {
        this.whatsappButton.href = Application.getInstance().whatsAppLink;
        this.whatsappButton.target = '_blank';
        this.whatsappButton.className = Application.getInstance().showCrispChat ? "whatsapp-button crisp-button" : "whatsapp-button";;
        this.whatsappButton.id = "WhatsAppBtn";

        const whatsappIcon = document.createElement("img");
        whatsappIcon.src = WhatsAppLogo;
        whatsappIcon.alt = "WhatsAppLogo";

        this.whatsappButton.appendChild(whatsappIcon);

        if (WhatsAppService.isWhatsAppChatEnabled)
            document.body.appendChild(this.whatsappButton);
    }

    show(): void {
        this.whatsappButton.style.display = 'block';
    }

    hide(): void {
        this.whatsappButton.style.display = 'none';
    }
}

const whatsappService = new WhatsAppService();
export default whatsappService;