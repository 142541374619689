import { ZERO } from "config/_const";
import { IPersonalTournamentSubscription } from "model/Tournament/ITournamentSubscription";
import IPersonalTournament from "model/Tournament/PersonalTournament/IPersonalTournament";
import TournamentSubscriptionViewModel from "view-model/Tournament/TournamentSubscriptionsViewModel/TournamentSubscriptionViewModel";

export default class UserPersonalTournamentViewModel {
    private personalTournament: IPersonalTournament;
    constructor(input: IPersonalTournament) {
        this.personalTournament = input;
    }

    private get SortedSubscriptions(): IPersonalTournamentSubscription[] {
        return this.personalTournament.subscriptions.sort((subA, subB) => subA.place - subB.place);
    }

    public get Subscriptions(): TournamentSubscriptionViewModel[] {
        return this.SortedSubscriptions.map((subscription: IPersonalTournamentSubscription) => {
            return new TournamentSubscriptionViewModel({
                ...subscription,
                teamScoreAverage: subscription.teamScoreAverage ?? ZERO,
            })
        })
    }
}