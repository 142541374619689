import React from "react";
import ChipsScoreBoosterPriceProps from "./ChipsScoreBoosterPriceProps";
import './style.scss';

const ChipsScoreBoosterPrice: React.FC<ChipsScoreBoosterPriceProps> = ({ price }) => {
    return (
        <div className="chips-score-booster-price">
            <span className="score">
                {price}
            </span>
        </div>)
}

export default ChipsScoreBoosterPrice;