import Application from "Application";
import IPixelHandler from "service/pixel/IPixelHandler";
import IPurchaseData from "./IPixelHandlerSnapPurchase";
const SnapchatReactPixel = require('react-snapchat-pixel');

export default class PixelHandlerSnapPurchase implements IPixelHandler {
    private trackOnTheFirstPayment: boolean;
    private static customEventName: string = 'PURCHASE';
    private static isFirstPayment: string = 'SIGN_UP'; // Bypass Snapchat for the first payment event
    private _data: IPurchaseData;
    constructor(trackOnTheFirstPayment: boolean = false) {
        this._data = {
            currency: Application.getInstance().currency,
            price: 0,
            number_items: 0,
            item_category: 'FAKE', //bypass for the assetName
            user_email: 'FAKE'
        }
        this.trackOnTheFirstPayment = trackOnTheFirstPayment;
    }

    public set purchageData(data: IPurchaseData) {
        this._data = data;
    }

    handle(): void {
        SnapchatReactPixel.default.track(PixelHandlerSnapPurchase.customEventName, this._data);
        if (this.trackOnTheFirstPayment)
            SnapchatReactPixel.default.track(PixelHandlerSnapPurchase.isFirstPayment, this._data);
    }
}