import Application from "Application";

class CrispService {
    private static isCrispChatEnabled: boolean = Application.getInstance().showCrispChat;
    static init(): void {
        if (this.isCrispChatEnabled) {
            window.$crisp = {};
            window.CRISP_WEBSITE_ID = "2f7800cf-8c28-4c2e-8440-8bc6eeffa2b5";
            const script = document.createElement("script");
            script.src = "https://client.crisp.chat/l.js";
            script.async = true;
            document.getElementsByTagName("head")[0].appendChild(script);
        };
    }

    static show(): void {
        if (this.isCrispChatEnabled) {
            window.$crisp.do('chat:show');
        };
    }

    static hide(): void {
        if (this.isCrispChatEnabled) {
            window.$crisp.do('chat:hide');
        };
    }
}

export default CrispService;