import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import * as ScheduleOrderTypes from './actionsType';
import AxiosConfig from '../../config/axiosConfig';
import { showNotification } from '../notification/action';

export const createScheduleOrder = (body: any): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {

    dispatch({
        type: ScheduleOrderTypes.SCHEDULED_ORDER_LOADING,
    });
    try {

        const response = await AxiosConfig.post(`/scheduledOrder/create`, body);

        if (response && response.data && response.data.status !== 200) {

            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: ScheduleOrderTypes.SCHEDULED_ORDER_ERROR,
            });
        }

        return dispatch({
            type: ScheduleOrderTypes.SCHEDULED_ORDER_SUCCESS,
            payload: {
                data: response.data.data,
                message: response.data.message,
            },
        });

    } catch (error) {

        showNotification(dispatch, error.stack, 500);
        return dispatch({
            type: ScheduleOrderTypes.SCHEDULED_ORDER_ERROR,

        });
    }
};
