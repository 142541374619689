import { Selector, createSelector } from 'reselect';
import { RootState } from '../../model/Redux';
import TransactionInterface from '../../model/User/TransactionInterface';
import { TRANSACTION_SOURCE } from './../../config/_const';

export const transactionsDataSelector: Selector<RootState, TransactionInterface[]> = state => state.transactions.data;
export const referralTransactionsSelector = createSelector<RootState, TransactionInterface[], TransactionInterface[]>(
    transactionsDataSelector,
    (transactions: TransactionInterface[]) => {
        if (transactions.length === 0)
            return [];
        return transactions.filter((transaction: TransactionInterface) => transaction.source === TRANSACTION_SOURCE.REFERRAL);
    },
);

export const isFinancialTransactionsLoadingSelector = createSelector<RootState, TransactionInterface[], boolean, boolean>(
    transactionsDataSelector,
    (state: RootState) => state.transactions.loading,
    (financialTranctions: TransactionInterface[], financialTransactionsLoading: boolean) => {
        return financialTranctions.length === 0 && financialTransactionsLoading;
    }
);