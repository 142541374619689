import i18n from '18n';
import IButtonProps from 'components/small/buttons/IButtonProps';
import { TEAM_INTERNAL_STATUS, TOURNAMENT_CATEGORY } from 'config/_const';
import TeamViewModel from 'view-model/Team/TeamViewModel/TeamViewModel';
import ICTAType from './ICallToActionType';
import ITeamCTAViewModel from './ITeamCallToAactionViewModel';

export default class TeamCallToActionViewModel {
    private static readonly TEAM_VALIDATION_BUTTON: Record<string, ICTAType> = {
        GENERATE: {
            name: 'GENERATE',
            textButton: () => i18n.t('generate-team-not-connected'),
        },
        CONFIRM: {
            name: 'CONFIRM',
            textButton: () => i18n.t('confirm-team'),
        },
        ENGAGED: {
            name: 'ENGAGED',
            textButton: () => i18n.t('confirm-team-already-engaged'),
        },
        JOIN_TOURNAMENT: {
            name: 'JOIN_TOURNAMENT',
            textButton: (nextTournament: TOURNAMENT_CATEGORY) => {
                if (!(nextTournament === TOURNAMENT_CATEGORY.DEFAULT)) {
                    return i18n.t('team.join.tournament', { tournament: nextTournament?.toLocaleLowerCase() })
                }

                return i18n.t('team.join.tournament')
            },
        },
    };
    private isEngagedInTournament: boolean;
    private team: TeamViewModel | undefined;
    private functionsByCTAType: Record<string, () => void>;
    private checkDisableByCTAType: Record<string, () => boolean>;

    constructor(params: ITeamCTAViewModel) {
        this.isEngagedInTournament = params.isEngagedInTournament;
        this.team = params.team;
        this.functionsByCTAType = params.functionsByCTAType;
        this.checkDisableByCTAType = params.checkDisableByCTAType;
    }

    private displayGenerateTeamButton = (): boolean => !this.Team || this.Team.teamAssets.length === 0;
    private displayJoinTournamentButton = (): boolean => Boolean(this.Team && (this.Team.teamAssets.length ?? 0) === this.Team.configuration.maxNumberOfAssets && this.Team.InternalStatus === TEAM_INTERNAL_STATUS.EDIT_DONE && !this.IsEngagedInTournament);
    public displayResetTeamButton = (): boolean => Boolean(this.Team && this.Team.InternalStatus === TEAM_INTERNAL_STATUS.EDIT_DONE && (this.Team.teamAssets.length ?? 0) >= 1 && !this.IsEngagedInTournament);
    public canDisplayErrorForSaveAndJoinBtn = (): boolean => !this.IsEngagedInTournament && !this.displayGenerateTeamButton();

    private getCTAType = (): ICTAType => {
        if (this.IsEngagedInTournament)
            return TeamCallToActionViewModel.TEAM_VALIDATION_BUTTON.ENGAGED;
        if (this.displayGenerateTeamButton())
            return TeamCallToActionViewModel.TEAM_VALIDATION_BUTTON.GENERATE;
        if (this.displayJoinTournamentButton())
            return TeamCallToActionViewModel.TEAM_VALIDATION_BUTTON.JOIN_TOURNAMENT;
        return TeamCallToActionViewModel.TEAM_VALIDATION_BUTTON.CONFIRM;
    }

    public generateCTAProps = (nextTournament: TOURNAMENT_CATEGORY): IButtonProps => {
        const buttonType: ICTAType = this.getCTAType();
        return {
            type: 'button',
            disabled: this.checkDisableByCTAType[buttonType.name](),
            className: 'join-tournament',
            textButton: buttonType.textButton(nextTournament),
            handleClick: this.FunctionsByCTAType[buttonType.name],
            receiveClickEvent: false,
        };
    }

    public get IsEngagedInTournament(): boolean {
        return this.isEngagedInTournament;
    }

    public get Team(): TeamViewModel | undefined {
        return this.team;
    }
    public get FunctionsByCTAType(): Record<string, () => void> {
        return this.functionsByCTAType;
    }

}
