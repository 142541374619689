import { useMemo } from "react";
import IUseFirstDepositAchievement from "./IUseFirstDepositAchievement";
import FirstDepositAchievementViewModel from "view-model/FirstDepositAchievementViewModel/FirstDepositAchievementViewModel";
import TransactionViewModel from "view-model/TransactionViewModel/TransactionViewModel";

const useFirstDepositAchievement = (transaction : TransactionViewModel[]): IUseFirstDepositAchievement => {
    const firstDepositAchievementVM: FirstDepositAchievementViewModel = useMemo(() => new FirstDepositAchievementViewModel(transaction), [transaction]);
    return { firstDepositAchievementVM }
}

export default useFirstDepositAchievement;
