import i18next from 'i18next';
import React from 'react';
import PrimaryButton from '../buttons/primary-button/PrimaryButton';
import MailboxImg from "assets/images/modals/undraw_online_re_x00h-min.png";
import ModalImageContainer from '../modal-image-container/ModalImageContainer';
import './style.scss';

interface ModalProps {
    onValidation: () => void;
    onRefused: () => void;
}

const NotificationPermissionModal: React.FC<ModalProps> = ({ onValidation, onRefused }) => {

    return <div className={`notification-permission-modal`}>
        <ModalImageContainer imgSrc={MailboxImg} />
        <div className="confirm-message" style={{ lineHeight: '23px' }}>{i18next.t('notification.askPermission')}</div>
        <div className="buttons-container">
            <div>
                <button
                    type="button"
                    className={'cancel-button cursor-pointer'}
                    onClick={onRefused}
                >
                    {i18next.t('notification.permissionReject')}
                </button>
            </div>
            <div>
                <PrimaryButton
                    receiveClickEvent={false}
                    disabled={false}
                    type="button"
                    className={`primary-button confirm-button`}
                    handleClick={onValidation}
                    textButton={i18next.t('notification.permissionAccept')} />
            </div>
        </div>
    </div >;
};

export default NotificationPermissionModal;
