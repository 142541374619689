import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import './style.scss';
const TournamentOffSectionLoading: React.FC = () => {
    return (
        <div className="tournament-off-section-loading">
            <div className="flex content-list">
                <Skeleton animation="wave" variant="text" className="text" height={50} />
                <Skeleton animation="wave" variant="text" className="text" height={50} />
            </div>
            <Skeleton animation="wave" className="container-rect" variant="rect" height={200} />
            <div className="flex content-list">
                <Skeleton animation="wave" variant="circle" width={50} height={50} />
                <Skeleton animation="wave" variant="text" className="text" height={50} />
                <Skeleton animation="wave" variant="text" width={50} height={50} />
            </div>
            <div className="flex content-list">
                <Skeleton animation="wave" variant="circle" width={50} height={50} />
                <Skeleton animation="wave" variant="text" className="text" height={50} />
                <Skeleton animation="wave" variant="text" width={50} height={50} />
            </div>
            <div className="flex content-list">
                <Skeleton animation="wave" variant="circle" width={50} height={50} />
                <Skeleton animation="wave" variant="text" className="text" height={50} />
                <Skeleton animation="wave" variant="text" width={50} height={50} />
            </div>
            <div className="flex content-list">
                <Skeleton animation="wave" variant="circle" width={50} height={50} />
                <Skeleton animation="wave" variant="text" className="text" height={50} />
                <Skeleton animation="wave" variant="text" width={50} height={50} />
            </div>
            <div className="flex content-list">
                <Skeleton animation="wave" variant="circle" width={50} height={50} />
                <Skeleton animation="wave" variant="text" className="text" height={50} />
                <Skeleton animation="wave" variant="text" width={50} height={50} />
            </div>
        </div>
    );
};

export default TournamentOffSectionLoading;
