import React from 'react';
import clsx from 'clsx';
import { ValidateLabelOfLocalLoginFormProps } from './IValidateLabelOfLocalLoginFormProps';
import './ValidateLabelOfLocalLoginForm.scss';

const ValidationLabel: React.FC<ValidateLabelOfLocalLoginFormProps> = ({ validationResults, field }) => {
    const { error, success } = validationResults[field];
    return (
        <label className={clsx({
            'error': Boolean(error),
            'valid-message': Boolean(success),
            'form': true,
        })}>
            {error || success}
        </label>
    );
};

export default ValidationLabel;
