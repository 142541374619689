import i18n from "18n";
import Whatsapp from "components/icons/Whatsapp";
import React from "react";
import PrimaryButton from "../buttons/primary-button/PrimaryButton";
import IShareReferralButtonsProps from "./IShareReferralButtonsProps";
import Facebook from "components/icons/social-media/Facebook";
import Twitter from "components/icons/social-media/Twitter";
import Mail from "components/icons/Mail";
import './ShareReferralButtons.scss';

const ShareReferralButtons: React.FC <IShareReferralButtonsProps>= (props) => {

    const urlEncoded = encodeURIComponent(props.accountVM.getReferralLink())
    const messagePayload = encodeURIComponent(`${i18n.t("Aboutus.tab.about.referyourfriend.message", { link: props.accountVM.getReferralLink() })}`)
    const buttonSize = 40;

    return (
        <div className='share-referral-buttons'>
            <a target="_blank" href={`http://www.facebook.com/sharer.php?u=${urlEncoded}`} >
                <PrimaryButton
                    disabled={false}
                    receiveClickEvent={false}
                    textButton={<Facebook fill='#fff' height={buttonSize} width={buttonSize} />}
                    type="button"
                />
            </a>
            <a target="_blank" href={`https://twitter.com/intent/post?text=${messagePayload}`} >
                <PrimaryButton
                    disabled={false}
                    receiveClickEvent={false}
                    textButton={<Twitter fill='#fff' height={buttonSize} width={buttonSize} />}
                    type="button"
                />
            </a>
            <a target="_blank" href={`https://wa.me/?text=${messagePayload}`} >
                <PrimaryButton
                    disabled={false}
                    receiveClickEvent={false}
                    textButton={<Whatsapp fill={"#fff"} height={buttonSize} width={buttonSize} />}
                    type="button"
                />
            </a>
            <a href={`mailto:?subject=${i18n.t("Aboutus.tab.about.referyourfriend.mail.subject")}&body=${messagePayload}`}>
                <PrimaryButton
                    disabled={false}
                    receiveClickEvent={false}
                    textButton={<Mail fill={'#fff'} height={buttonSize} width={buttonSize} />}
                    type="button"
                />
            </a>
        </div>);
}
export default ShareReferralButtons;