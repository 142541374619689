import { ClickAwayListener, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import React from 'react';
import Utils from 'utils/Utils';
import ITooltipHelperProps from './IToolypHelperProps';
import './style.scss';

const TooltipHelper: React.FC<ITooltipHelperProps> = ({ helperMessage }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    return (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <Tooltip open={Utils.isMobile() ? isOpen : undefined} title={<p className="no-margin">{helperMessage}</p>}>
                <div className="tooltip-helper">
                    <Info onClick={() => setIsOpen(!isOpen)} className="icon" />
                </div>
            </Tooltip>
        </ClickAwayListener>
    );
};

export default React.memo(TooltipHelper);
