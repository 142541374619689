import { createSelector, Selector } from 'reselect';
import { RootState } from '../../model/Redux';
import NotificationReduxStore from './NotificationReduxStore';

export const notificationSelector: Selector<RootState, NotificationReduxStore> = state => state.notifications.data;

export const globalNotificationSelector = createSelector<RootState, NotificationReduxStore, NotificationReduxStore>(
    notificationSelector,
    (notification) => {
        return notification;
    },
);
