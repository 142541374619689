import React from 'react'
import UpgradeLeagueProgressProps from './UpgradeLeagueProgressProps'
import './UpgradeLeagueProgress.scss';
import TournamentProgressBar from 'components/medium/tournament-level-button/localComponent/TournamentProgressBar/TournamentProgressBar';

const UpgradeLeagueProgress: React.FC<UpgradeLeagueProgressProps> = (props) => {
    return (
        <div className='upgrade-league-progress-box' >
            <div className='upgrade__bar-section'>
                <TournamentProgressBar showLeagueIcon={false} />
            </div>
            <div className="upgrade__tokens-section">
                <div className='total-tokens'>
                    <div className='token_text'>
                        {props.userLeagueViewModel.TotalGeneralTokenText}
                    </div>
                    <div className='token_num'>
                        {props.userLeagueViewModel.TotalGeneralTokenValue}
                    </div>
                </div>
                {!props.userLeagueViewModel.IsLeagueTokenZeroInHigherLeague &&
                    <div className='total-tokens'>
                        <div className='token_text'>
                            {props.userLeagueViewModel.TotalLeagueTokenText}
                        </div>
                        <div className='token_num'>
                            {props.userLeagueViewModel.TotalLeagueTokenValue}

                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default UpgradeLeagueProgress;