import { ZERO } from 'config/_const';
import { PaymentMethodsEnum } from 'config/_enums';
import ICreditBreakDownVMBuilder from './ICreditBreakDownViewModel';

export default class CreditBreakDownViewModel {
    private _creditsAmount: number;
    private _virtualCreditsAmount: number;
    private _selectedPaymentType: PaymentMethodsEnum;
    private _totalAvailableAmount: number;
    private _amountToBePaid: number;

    constructor({ creditsAmount, virtualCreditsAmount, selectedPaymentType, amountToBePaid }: ICreditBreakDownVMBuilder) {
        this._creditsAmount = creditsAmount;
        this._virtualCreditsAmount = virtualCreditsAmount;
        this._selectedPaymentType = selectedPaymentType;
        this._amountToBePaid = amountToBePaid;
        this._totalAvailableAmount = this._creditsAmount.addition(this._virtualCreditsAmount);
    }

    public get TotalAvailableAmountFormatted() {
        return this._totalAvailableAmount.toCurrency(2);
    }

    private get CreditRepartition() {
        const hasAmountInVirtualCreditExceeded = this._virtualCreditsAmount.subtract(this._amountToBePaid) < ZERO;
        if (hasAmountInVirtualCreditExceeded) {
            return {
                credits: this._amountToBePaid.subtract(this._virtualCreditsAmount),
                giftAmount: this._virtualCreditsAmount,
            }
        }

        return {
            credits: ZERO,
            giftAmount: this._amountToBePaid,
        }
    }

    public get CreditRepartitionFormatted() {
        return {
            credits: this.CreditRepartition.credits.toCurrency(2),
            giftAmount: this.CreditRepartition.giftAmount.toCurrency(2),
        }
    }

    public get IsVirtualCreditsEmpty() {
        return this._virtualCreditsAmount <= ZERO;
    }

    public get IsNotCreditPayment() {
        return this._selectedPaymentType !== PaymentMethodsEnum.CREDITS
    }

    public get IsVirtualCredisEmptyOrSelectedPaymentNotCredit() {
        return this._selectedPaymentType !== PaymentMethodsEnum.CREDITS
    }
}

