import ReactPixel from 'react-facebook-pixel';
import Application from '../../Application';

class CustomFacebookCampaign {
    private static pixelId: string = '2258394914371227';
    private static events = {
        purchase: "Purchase",
        creditCardIntent: "InitiateCheckout"
    };

    static trackPurchase(value: number): void {
        ReactPixel.trackSingle(CustomFacebookCampaign.pixelId, CustomFacebookCampaign.events.purchase, {
            value,
            currency: Application.getInstance().currency,
        });
    }

    static trackCreditCardIntent(): void {
        ReactPixel.trackSingle(CustomFacebookCampaign.pixelId, CustomFacebookCampaign.events.creditCardIntent);
    }
}

export default CustomFacebookCampaign;