import React from 'react';
import IStripeCheckoutFormProps from './IStripeCheckoutFormProps';
import TrendexCheckoutForm from './trendexCheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import StripeConfig from '../../../config/stripe';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(StripeConfig.publicKey);

const StripeCheckoutForm: React.FC<IStripeCheckoutFormProps> = (props: IStripeCheckoutFormProps) => {
    return (
        <Elements stripe={stripePromise}>
            <TrendexCheckoutForm {...props} />
        </Elements>
    );
};

export default StripeCheckoutForm;
