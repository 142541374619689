import { ASSETS_CATEGORIES, DEFAULT_ID_AS_STRING, TRENDEX_ROLES, ZERO } from 'config/_const';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import AssetInterface from 'model/AssetModel/AssetInterface';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import { ReduxRootState, RootState } from 'model/Redux';
import ITeam from 'model/Team/ITeam';
import ITeamAssetWallet from 'model/Team/ITeamAssetsWallet';
import { Selector, createSelector } from 'reselect';
import { assetsCategoriesSelector } from 'service/asset-category/selector';
import { accountAsViewModelSelector, userIdSelector } from 'service/auth/selectors';
import { totalTokensInTeam } from 'service/teams/selectors';
import WalletStoreData from 'service/wallet/WalletStoreData';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import WalletViewModel from 'view-model/WalletViewModel/WalletViewModel';
import ITeamAsset from '../../model/Team/ITeamAsset';
import AssetWalletViewModel from '../../view-model/WalletViewModel/AssetWalletViewModel';
import { AssetCategory } from 'config/_enums';

export const walletLoadingSelector: Selector<RootState, boolean> = state => state.wallet.loading;
export const assetWalletSelector: Selector<RootState, AssetWalletInterface[]> = state => state.wallet.data.ownedAssets;
export const walletLoadedSelector: Selector<RootState, boolean> = state => state.wallet.data.loaded;
export const ownedAssetIdsSelector: Selector<RootState, string[]> = state => state.wallet.data.ownedAssets.map((wallet: AssetWalletInterface) => wallet.asset._id);
export const ownedAssetsSelector: Selector<RootState, AssetInterface[]> = state => state.wallet.data.ownedAssets.map((wallet: AssetWalletInterface) => wallet.asset);
export const walletSelector: Selector<RootState, WalletViewModel> = state => new WalletViewModel({ ownedAssets: state.wallet.data.ownedAssets, portfolioTotalValue: state.wallet.data.walletTotalValue }, state.wallet.loading, state.wallet.data.loaded);
export const walletTotalValueSelector: Selector<RootState, number> = state => state.wallet.data.walletTotalValue ?? 0;

export const getUserAssetsSelector = createSelector<RootState, string, AssetWalletInterface[], ITeam[], AssetWalletInterface[]>(
    userIdSelector,
    assetWalletSelector,
    (state: RootState) => state.teams.data,
    (userId: string, userAssets: AssetWalletInterface[], userTeams: ITeam[]) => {
        const userAssetsCopy: AssetWalletInterface[] = [...userAssets.filter((entry) => entry.asset.owner !== userId)];
        if (userTeams.length === 0 || !userAssets || userAssets.length === 0)
            return (userAssetsCopy ?? []).filter((entry) => entry && (entry.amount.addition(entry.frozenAmount) > ZERO));
        const teamAssets: ITeamAsset[] = userTeams.map((entry: ITeam) => entry.teamAssets).flat();
        if (teamAssets.length === 0)
            return userAssetsCopy.filter((entry) => entry && (entry.amount.addition(entry.frozenAmount) > ZERO));

        const assetsIdsInTeams: string[] = teamAssets.map((entry) => entry.asset);
        const userWalletWithoutAssetsInTeam: AssetWalletInterface[] = userAssetsCopy.filter((entry) => !assetsIdsInTeams.includes(entry.asset._id) && (entry.amount.addition(entry.frozenAmount) > ZERO));
        const assetMap: Record<string, AssetWalletInterface> = {};
        for (const walletEntry of userAssetsCopy)
            assetMap[walletEntry.asset?._id] = walletEntry;
        return teamAssets.map((teamAsset: ITeamAsset): AssetWalletInterface => {
            const asset: AssetWalletInterface | undefined = assetMap[teamAsset.asset];
            const tokensInTeam = asset?.tokensInTeam ?? 0;
            return {
                ...asset,
                tokensInTeam: tokensInTeam.addition(teamAsset.numberOfShares)
            }
        }).concat(userWalletWithoutAssetsInTeam).sort((walletEntryA: AssetWalletInterface, walletEntryB: AssetWalletInterface) => new Date(walletEntryA.createdAt).getTime() > new Date(walletEntryB.createdAt).getTime() ? 1 : -1);
    }
)

export const walletAndTeamAssetSelector = createSelector<RootState, AssetWalletInterface[], ITeam[], AssetWalletInterface[]>(
    getUserAssetsSelector,
    (state: RootState) => state.teams.data,
    (assetWallet: AssetWalletInterface[], teams: ITeam[]) => {
        if (teams.length === 0)
            return assetWallet;
        const filteredTeams: ITeam[] = teams.filter(team => team._id && team._id !== 'undefined' && team._id !== null && team._id !== 'draft');
        if (filteredTeams.length === 0)
            return assetWallet;
        const teamAssetArray: ITeamAssetWallet[] = [];
        for (const team of filteredTeams) {
            for (const teamAsset of team.teamAssets) {
                const teamAssetIndex: number = teamAssetArray.findIndex((entry: ITeamAssetWallet) => entry.asset === teamAsset.asset);
                if (teamAssetIndex > -1) {
                    teamAssetArray[teamAssetIndex].amount += teamAsset.numberOfShares;
                    continue;
                }
                teamAssetArray.push({
                    asset: teamAsset.asset,
                    amount: teamAsset.numberOfShares,
                });
            }
        }

        return assetWallet.map((assetWalletEntry: AssetWalletInterface) => {
            const assetFind: ITeamAssetWallet | undefined = teamAssetArray.find((entry: ITeamAssetWallet) => entry.asset === assetWalletEntry.asset._id);
            const copyEntry = { ...assetWalletEntry };
            if (assetFind)
                copyEntry.amount += assetFind.amount;
            return copyEntry;
        });
    },
);

export const selectorGetWalletAsViewModel = createSelector<RootState, AssetWalletInterface[], ReduxRootState<WalletStoreData>, WalletViewModel>(
    walletAndTeamAssetSelector,
    (state: RootState) => state.wallet,
    (assetWallet: AssetWalletInterface[], wallet: ReduxRootState<WalletStoreData>) => {
        return new WalletViewModel({ ownedAssets: assetWallet, portfolioTotalValue: wallet.data.walletTotalValue }, wallet.loading, wallet.data.loaded);
    }
);

export const isWalletLoadedSelector = createSelector(
    userIdSelector,
    walletLoadedSelector,
    (userId: string, walletLoaded: boolean) => {
        return userId !== DEFAULT_ID_AS_STRING ? walletLoaded : true;
    },
);

export const categoriesOfOwnedAssetsSelector = createSelector<RootState, AssetInterface[], ReduxRootState<AssetCategoryInterface[]>, AssetCategoryInterface[]>(
    ownedAssetsSelector,
    assetsCategoriesSelector,
    (ownedAssets: AssetInterface[], assetsCategories: ReduxRootState<AssetCategoryInterface[]>) => {
        const ownedAssetsCategoriesIds: string[] = ownedAssets.filter((asset: AssetInterface) => !asset.hidden).map((asset: AssetInterface) => asset.mainCategory).getUniqueValues();
        return assetsCategories.data.filter(category => ownedAssetsCategoriesIds.includes(category._id));
    },
);

export const selectPortfolioTotalValue = createSelector(
    walletTotalValueSelector,
    accountAsViewModelSelector,
    (walletTotalValue: number, account: AccountViewModel) => {
        if (account.UserType === TRENDEX_ROLES.VIP)
            return account.getUserTotalCredits();
        return walletTotalValue + account.getUserTotalCredits();
    },
);

export const totalWalletSharesSelector = createSelector<RootState, AssetWalletInterface[], AccountViewModel, number>(
    assetWalletSelector,
    accountAsViewModelSelector,
    (assetWallet: AssetWalletInterface[], account: AccountViewModel) => {
        return assetWallet
            .filter((assetWallet: AssetWalletInterface) => assetWallet.asset.mainCategory !== ASSETS_CATEGORIES[AssetCategory['Fun cards']])
            .map((assetWallet: AssetWalletInterface) => new AssetWalletViewModel(assetWallet).getTokensAmount(account.Id))
            .reduce((accumulator: number, tokensAmount: number) => accumulator + tokensAmount, 0)
    },
);

export const totalTokensAccountSelector = createSelector<RootState, number, number, number>(
    totalWalletSharesSelector,
    totalTokensInTeam,
    (tokensInWallet: number, tokensInTeam: number) => tokensInWallet + tokensInTeam,
);

