import React from 'react';

const TripleArrowsUp: React.FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? "8.614"} height={props.width ?? "13.747"} viewBox="0 0 8.614 13.747">
            <g id="Groupe_6259" data-name="Groupe 6259" transform="translate(-293.135 -13.089)">
                <path id="Tracé_4062" data-name="Tracé 4062" d="M294.549,28.234l2.893-2.893,2.893,2.893" transform="translate(0 -2.812)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" opacity="0.644" />
                <path id="Tracé_4063" data-name="Tracé 4063" d="M294.549,28.234l2.893-2.893,2.893,2.893" transform="translate(0 -6.825)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" opacity="0.615" />
                <path id="Tracé_4064" data-name="Tracé 4064" d="M294.549,28.234l2.893-2.893,2.893,2.893" transform="translate(0 -10.838)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" />
            </g>
        </svg>
    );
};

export default TripleArrowsUp;
