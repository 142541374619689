import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const TopMenuWidgetLoading: React.FC = () => {
    return (
        <div className="top-menu-widget-loading">
            <Skeleton variant="text" width={190} height={26} />
        </div>
    );
};

export default TopMenuWidgetLoading;
