import * as React from 'react';

const GiftSolid = ({ height = "20px", width = "20px", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg height={height} width={width} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.827 35.862">
            <path id="gifts-solid" d="M13.483,3.853l1.779-1.3a1.68,1.68,0,1,1,1.975,2.719L15.255,6.655H16.81a3.418,3.418,0,0,1,2.823,1.6,7.109,7.109,0,0,0-2.809,5.267,4.61,4.61,0,0,0-3.376,4.406v15.69a4.415,4.415,0,0,0,.6,2.241H3.362A3.362,3.362,0,0,1,0,32.494V10.08A3.362,3.362,0,0,1,3.362,6.718H4.917L2.933,5.276A1.681,1.681,0,1,1,4.911,2.557l1.921,1.4L6.25,2.207A1.681,1.681,0,0,1,7.312.08,1.71,1.71,0,0,1,9.442,1.143l.693,2.084.749-2.114a1.679,1.679,0,1,1,3.166,1.122ZM24.109,8.9a5.128,5.128,0,0,1,4.013,2.038l2.136,2.774,2.136-2.774a5.028,5.028,0,0,1,9.071,3,4.462,4.462,0,0,1-.287,1.681h.287a3.4,3.4,0,0,1,3.362,3.362v5.666H31.379V17.862H29.138v6.787H15.69V18.983a3.4,3.4,0,0,1,3.362-3.362h.287a4.462,4.462,0,0,1-.287-1.681A5.093,5.093,0,0,1,24.109,8.9Zm1.352,4.09a1.773,1.773,0,0,0-1.352-.728,1.736,1.736,0,0,0-1.7,1.681,1.638,1.638,0,0,0,1.681,1.681h3.439Zm10.948-.728a1.773,1.773,0,0,0-1.352.728l-2.073,2.634h3.439A1.638,1.638,0,0,0,38.1,13.94a1.736,1.736,0,0,0-1.7-1.681ZM15.69,32.494v-5.6H29.138v8.965H19.052A3.363,3.363,0,0,1,15.69,32.494Zm15.69,3.362V26.89H44.827v5.6a3.363,3.363,0,0,1-3.362,3.362Z" transform="translate(0 0.006)" fill="#6677f5" />
        </svg>

    );
};

export default GiftSolid;
