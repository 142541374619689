import ReduxActionInterface from '../../model/ReduxActionInterface';
import ReduxRootState from '../../model/Redux/ReduxRootSate';

import * as AssetCategoryActionType from './actionsType';
import Utils from '../../utils/Utils';

const ROOT_VALUE_STATE: ReduxRootState = {
    loading: false,
    data: [],
};

export const assetsCategoriesReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {
        case AssetCategoryActionType.ASSETS_CATEGORY_LOADING:
            return {
                ...state,
                loading: true,
            };

        case AssetCategoryActionType.ASSETS_CATEGORY_ERROR:

            return {
                ...state,
                loading: false,
            };

        case AssetCategoryActionType.ASSETS_CATEGORY_SUCCESS:

            const stateData = state.data.length > 0 ? Utils.removedDuplicateMongoFile(state.data, action.payload.assetCategories) : [];

            return {
                ...state,
                data: stateData.concat(action.payload.assetCategories),
                loading: false,
            };

        default: return state;
    }
};
