/* eslint-disable react-hooks/exhaustive-deps */
import i18n from '18n';
import { CreditCard } from '@material-ui/icons';
import PaymentCreditBreakDown from 'components/small/PaymentCreditBreakDown/PaymentCreditBreakDown';
import GreenButton from 'components/small/buttons/green-button/GreenButton';
import CreditCardWrapper from 'components/small/credit-card-wrapper/CreditCardWrapper';
import PaymentOptionCard from 'components/small/payment-option-card/PaymentOptionCard';
import { DEFAULT_ID_AS_STRING, MINIMUM_STRIPE_AMOUNT } from 'config/_const';
import { MarketEnum, PaymentMethodsEnum } from 'config/_enums';
import useTeamScoreBoostConfirmation from 'hooks/team-score-boost/use-team-score-boost-confirmation/useTeamScoreBoostConfirmation';
import useTournament from 'hooks/tournament/use-tournament/useTournament';
import useUserTournamentSubscription from 'hooks/tournament/use-user-tournament-subscription/useUserTournamentSubscription';
import useAccount from 'hooks/use-account/useAccount';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Translation from 'view-model/Achievement/Translation';
import { IPaymentOptions } from '../../asset-transaction/confirm-buy-asset/IPaymentOptions';
import CheckoutModeEnum from '../../stripe-elements/CheckoutModeEnum';
import './ScoreBoostCheckout.scss';

const ScoreBoostCheckout: React.FC = () => {
    const dispatch = useDispatch();
    const { accountViewModel } = useAccount();
    const { tournamentInProgress } = useTournament()
    const { currentUserSubscription, fetchUserTournamentSubscription } = useUserTournamentSubscription({ tournamentId: tournamentInProgress?.Id || DEFAULT_ID_AS_STRING });
    const userSubscriptionId: string | null = currentUserSubscription?.TournamentSubscriptionId ?? null;
    const stripeConfirmationMethodRef = useRef<Function>(() => { });
    const [isCreditCardPaymentReady, setCreditCardPaymentReady] = useState<boolean>(true);
    const { onBuyBoostSuccess, onBuyBoostError, handleCreditBuyBoost, scoreBoosterViewModel } = useTeamScoreBoostConfirmation();
    const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentMethodsEnum>(PaymentMethodsEnum.CREDITS);
    const isTotalCreditLowerThanBoostPrice: boolean = accountViewModel.TotalCreditsAsNumber < scoreBoosterViewModel.Price;

    React.useEffect(() => {
        dispatch(fetchUserTournamentSubscription());
    }, [dispatch, fetchUserTournamentSubscription]);

    useEffect(() => {
        if (isTotalCreditLowerThanBoostPrice)
            setSelectedPaymentType(PaymentMethodsEnum.STRIPE);
    }, [isTotalCreditLowerThanBoostPrice])

    const handleConfirmation = async () => {
        setCreditCardPaymentReady(false);
        switch (selectedPaymentType) {
            case PaymentMethodsEnum.CREDITS:
                handleCreditBuyBoost();
                break;
            case PaymentMethodsEnum.STRIPE:
            case PaymentMethodsEnum.STRIPE_NEW_CARD:
                stripeConfirmationMethodRef?.current();
                break;
        }
    }

    const renderStripeSubComponent = (): JSX.Element => {
        const isNewCardForm = selectedPaymentType === PaymentMethodsEnum.STRIPE_NEW_CARD;

        return <CreditCardWrapper
            fiatAmount={scoreBoosterViewModel.Price}
            mode={CheckoutModeEnum.BUY_BOOST_SCORE}
            hasStripeCustomerId={accountViewModel.StripeCustomerId?.safeIsNullOrEmpty() ?? false}
            trackEventOnPixel={true}
            showLoader={true}
            intent={{ fiatAmount: scoreBoosterViewModel.Price, concernedMarket: MarketEnum.NONE, displayCreditForm: false }}
            buyAssetSuccessCallback={(_transactionData, _isDone) => { return new Promise(() => { onBuyBoostSuccess() }) }}
            buyAssetInErrorCallback={(_transactionData, _isDone) => { return new Promise(() => { onBuyBoostError() }) }}
            confirmationMethodRef={stripeConfirmationMethodRef}
            setCreditCardReady={setCreditCardPaymentReady}
            newCardForm={isNewCardForm}
            buyScoreIntent={{
                subscribeId: userSubscriptionId || DEFAULT_ID_AS_STRING,
                fiatAmount: scoreBoosterViewModel.Price,
            }}
        />
    };

    let paymentOptions: IPaymentOptions[] = useMemo(() => [
        { id: 1, value: PaymentMethodsEnum.CREDITS, label: i18n.t("credits"), info: <PaymentCreditBreakDown creditsAmount={accountViewModel.creditsAsNumber} virtualCreditsAmount={accountViewModel.VirtualCreditsAsNumber} amountToBePaid={scoreBoosterViewModel.Price} selectedPaymentType={selectedPaymentType} />, isDisabled: isTotalCreditLowerThanBoostPrice, renderSubComponent: renderStripeSubComponent },
        { id: 2, value: PaymentMethodsEnum.STRIPE, label: i18n.t("Payment.card"), info: <CreditCard />, isDisabled: !Boolean(accountViewModel.StripeCustomerId) || scoreBoosterViewModel.IsPriceSmallerThanStripe, renderSubComponent: renderStripeSubComponent },
        { id: 3, value: PaymentMethodsEnum.STRIPE_NEW_CARD, label: i18n.t(accountViewModel.isAuthenticated ? "Payment.newCard" : 'anonymous.payment-option.new-card'), info: <CreditCard />, isDisabled: scoreBoosterViewModel.IsPriceSmallerThanStripe || false, renderSubComponent: renderStripeSubComponent },
    ], [accountViewModel.creditsAsNumber, accountViewModel.VirtualCreditsAsNumber, accountViewModel.StripeCustomerId, accountViewModel.isAuthenticated, scoreBoosterViewModel.Price, scoreBoosterViewModel.IsPriceSmallerThanStripe, selectedPaymentType, isTotalCreditLowerThanBoostPrice, renderStripeSubComponent]);

    const translation = React.useMemo(() => {
        const transKey: string = scoreBoosterViewModel.IsRandomizedActivated ? 'boost.score.randomized.checkout.price.title' : 'boost.score.checkout.price.title';
        const values: Object = scoreBoosterViewModel.IsRandomizedActivated ? { min: scoreBoosterViewModel.MinPoints, max: scoreBoosterViewModel.MaxPoints } : { scoreBoostPoint: scoreBoosterViewModel.BoostPoints };
        return { ...new Translation(transKey, values) };
    }, [scoreBoosterViewModel]);

    const shouldDisableTransaction: boolean = React.useMemo(() => {
        const isConfirmReady = (): boolean => {
            const isPaymentTypeStripe = [PaymentMethodsEnum.STRIPE, PaymentMethodsEnum.STRIPE_NEW_CARD].includes(selectedPaymentType);
            return (isCreditCardPaymentReady || !isPaymentTypeStripe);
        };

        return !isConfirmReady() || accountViewModel.TotalCreditsAsNumber < scoreBoosterViewModel.Price;
    }, [accountViewModel.TotalCreditsAsNumber, isCreditCardPaymentReady, scoreBoosterViewModel.Price, selectedPaymentType])

    return <section className='score-boost-checkout'>
        <h1 className='score-boost-checkout__title'>{i18n.t('boost.score.checkout.title')}</h1>
        <div className="score-boost-checkout__section payment">
            <div className='section__left'>
                <div className="left__title">{i18n.t(translation.key, translation.values)}</div>
                <div className="left__subTitle">{i18n.t('boost.score.checkout.price.subtitle')}</div>
            </div>
            <div className='section__right'>{scoreBoosterViewModel.PriceAsCurrency}</div>
        </div>
        <div className="score-boost-checkout__section credit">
            <div className='section__left'>{i18n.t("buyAsset.available")}</div>
            <div className='section__right'>{accountViewModel.TotalCreditsAsNumber.toCurrency(2)}</div>
        </div>

        <div className="confirm-payment-type">
            {paymentOptions.map((option) =>
                <>
                    <PaymentOptionCard
                        isSelected={selectedPaymentType === option.value}
                        handleClick={(value) => setSelectedPaymentType(value)}
                        label={option.label}
                        value={option.value}
                        info={option.info}
                        isDisabled={option.isDisabled}
                        key={option.id}
                        renderSubComponent={option.renderSubComponent}
                    />
                    {(scoreBoosterViewModel.IsPriceSmallerThanStripe && option.value === PaymentMethodsEnum.STRIPE_NEW_CARD) &&
                        <div className='warning-msg'>
                            {i18n.t('payment.error.credit-card.tooSmallAmount', { amount: MINIMUM_STRIPE_AMOUNT.toCurrency() })}
                        </div>
                    }
                </>
            )}

        </div>
        <GreenButton
            className='confirmation-boost-btn'
            handleClick={handleConfirmation}
            textButton={i18n.t('confirm')}
            receiveClickEvent={false}
            type={"button"}
            disabled={shouldDisableTransaction}
        />
    </section>;
};

export default ScoreBoostCheckout;
