import * as React from 'react';

function BadgeCheck({height='1em', width='1em', ...props}: React.SVGProps<SVGSVGElement>) {
    return (
        <svg className="icon_badge-check" height={height} width={width}
            {...props}
            style={{ fill: "currentColor", overflow: "hidden", padding: '1px' }}
            viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1024 512c0-75.4-47.4-139.8-114.2-164.8 29.4-64.8 17.6-143.8-35.8-197.2-53.4-53.4-132.4-65.2-197.2-35.8C651.8 47.4 587.4 0 512 0s-139.8 47.4-164.8 114.2c-64.8-29.4-144-17.6-197.2 35.8-53.4 53.4-65.2 132.4-35.8 197.2C47.4 372.2 0 436.6 0 512s47.4 139.8 114.2 164.8c-29.4 64.8-17.6 144 35.8 197.2 53.2 53.2 132.2 65.4 197.2 35.8 25 66.6 89.4 114.2 164.8 114.2s139.8-47.4 164.8-114.2c65.2 29.6 144 17.4 197.2-35.8 53.4-53.4 65.2-132.4 35.8-197.2 66.8-25 114.2-89.4 114.2-164.8z m-289.6-88.5L472.32 683.48c-8.62 8.56-22.56 8.5-31.1-0.12l-151.44-152.66c-8.56-8.62-8.5-22.56 0.12-31.12l52.06-51.64c8.62-8.56 22.56-8.5 31.12 0.12l84.3 84.98 194.4-192.84c8.62-8.56 22.56-8.5 31.1 0.12l51.64 52.06c8.56 8.64 8.52 22.58-0.12 31.12z" />
        </svg>
    );
}

export default BadgeCheck;
