import * as ActionsType from './actionsType';
import { STATUS } from 'service/storeType';
import type ReduxRootState from 'model/Redux/ReduxRootSate';
import type { ScoreHistoryDataAttribute } from './types';
import ReduxActionInterface from 'model/ReduxActionInterface';

const ROOT_VALUE_STATE: ReduxRootState<ScoreHistoryDataAttribute> = {
    loading: false,
    data: [],
    error: '',
    status: { getEvolutions: STATUS.IDLE },
};
export const scoreHistoryReducer = (state: ReduxRootState<ScoreHistoryDataAttribute> = ROOT_VALUE_STATE, action: ReduxActionInterface): ReduxRootState<ScoreHistoryDataAttribute> => {

    switch (action.type) {
        case ActionsType.GET_EVOLUTIONS.LOADING:
            return {
                ...state,
                status: {
                    ...state.status,
                    getEvolutions: STATUS.PENDING,
                },
            };

        case ActionsType.GET_EVOLUTIONS.ERROR:
            return {
                ...state,
                status: {
                    ...state.status,
                    getEvolutions: STATUS.REJECTED,
                },
            };
        case ActionsType.GET_EVOLUTIONS.SUCCESS:
            return {
                ...state,
                data: action.payload,
                status: {
                    ...state.status,
                    getEvolutions: STATUS.RESOLVED,
                },
            };
        default: return state;
    }
};
