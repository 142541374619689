import { DEFAULT_ID_AS_STRING } from 'config/_const';
import IBuySimulation from 'hooks/simulation/IBuySimulation';
import ISellSimulation from 'hooks/simulation/ISellSimulation';
import useAssetsWithOwnedAndTeamAssetsShares from 'hooks/wallet/useAssetsWithOwnedAndTeamAssetsShares';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import { ReduxRootState, RootState } from 'model/Redux';
import { useDispatch, useSelector } from 'react-redux';
import { userIdSelector } from 'service/auth/selectors';
import { simulateBuy as simulateBuyAction, simulateSell as simulateSellAction } from 'service/simulation/buy/wallet/action';
import WalletStoreData from 'service/wallet/WalletStoreData';
import { getUserWallet, updateUserAssetsAction } from 'service/wallet/actions';
import { getUserAssetsSelector, isWalletLoadedSelector } from 'service/wallet/selector';
import BuyAssetViewModel from 'view-model/BuyAssetViewModel/BuyAssetViewModel';
import SellAssetViewModel from 'view-model/SellAssetViewModel/SellAssetViewModel';
import INumberOfSharesInTeam from 'view-model/Team/TeamViewModel/ISharesLocalTeam';
import WalletViewModel from 'view-model/WalletViewModel/WalletViewModel';
import IUseWallet from './IUseWallet';
import IUseWalletProps from './IUseWalletProps';

const useWallet = (props: IUseWalletProps): IUseWallet & IBuySimulation & ISellSimulation => {
    const dispatch = useDispatch();
    const wallet: ReduxRootState<WalletStoreData> = useSelector((state: RootState) => state.wallet);
    const ownedAssets: AssetWalletInterface[] = props.considerTeamShares ? useAssetsWithOwnedAndTeamAssetsShares() : useSelector((state: RootState) => getUserAssetsSelector(state));
    const userId: string = useSelector(userIdSelector);
    const isWalletLoaded: boolean = useSelector(isWalletLoadedSelector);

    const refreshWallet = (transparentLoading: boolean = false): void => {
        if (userId === DEFAULT_ID_AS_STRING || wallet.loading)
            return;
        dispatch(getUserWallet(userId, transparentLoading));
    };

    const updateUserAssets = (assets: INumberOfSharesInTeam[]) => {
        dispatch(updateUserAssetsAction(assets))
    };

    const simulateBuy = (buyAssetViewModel: BuyAssetViewModel): void => {
        dispatch(simulateBuyAction(buyAssetViewModel))
    };

    const simulateSell = (sellAssetViewModel: SellAssetViewModel): void => {
        dispatch(simulateSellAction(sellAssetViewModel));
    };

    return {
        refreshWallet,
        updateUserAssets,
        walletViewModel: new WalletViewModel(
            {
                ownedAssets,
                portfolioTotalValue: wallet.data.walletTotalValue,
                totalEarnings: wallet.data.totalEarnings,
                profit: wallet.data.profit,
                profitPercentage: wallet.data.profitPercentage
            },
            wallet.loading,
            isWalletLoaded,
        ),
        walletIsLoading: wallet.loading,
        simulateBuy,
        simulateSell
    };
};

export default useWallet;
