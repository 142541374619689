import WalletRefund from "components/medium/wallet-refund/WalletRefund";
import useAccountFromCookies from "hooks/use-account--from-cookies/useAccountFromCookies";
import React from "react";
import {  useDispatch } from "react-redux";
import { actionConnectUser } from "service/auth/actions";
import { managementModal } from "service/modal/action";
import { openSlidingPage } from "service/sliding-pages/action";
import Utils from "utils/Utils";

const useWalletRefund = () => {
    const { isUserExist } = useAccountFromCookies();
    const dispatch = useDispatch();

    const openWalletRefundPage = () => dispatch(openSlidingPage(<WalletRefund />));

    const showSignup = () => {
        dispatch(managementModal(true, {
            type: 'SIGN_UP',
            display: true,
            disableBackDrop: false,
            showBackArrow: Utils.isMobile(),
            fullScreen: Utils.isMobile(),
            showCancelButton: false,
            propsCustom: {
                onPostLogin: (form: { email: string, password: string, firstName: string, lastName: string, mode: string, token: string }) => onPostLogin(form),
                intentPurchase: false,
            },
        }))
    }

    const handleWalletRefundClick = () => {
        if (!isUserExist)
            return showSignup();
        openWalletRefundPage();
    };

    const onPostLogin = (form: { email: string, password: string, firstName: string, lastName: string, mode: string, token: string }): void => {
        dispatch(actionConnectUser(form, openWalletRefundPage));
    };

    return { handleWalletRefundClick };
};

export default useWalletRefund;