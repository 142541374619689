import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import Utils from '../../../utils/Utils';
import './SocialBadgeDark.scss';

interface ISocialBadgeProps {
    link?: string;
    followers: number | null;
    icon: React.ElementType;
    className?: string;
    isTopAsset: boolean;
}

const useStyles = () => {
    const background = '#EAEBF3'
    const text = '#14172D'
    const fill = '#696969'

    return makeStyles(() => ({
        container: {
            background,
            display: 'inline-flex',
            alignItems: 'center',
            zIndex: 1,
            borderRadius: '18px',
            padding: '10px',
            marginTop: '6px',

            '&:disabled': {
                opacity: '0.5',
                pointerEvents: 'none',
            },

            '&:hover': {
                cursor: 'pointer',
            },

            '& svg': {
                fill: text,
            },

            '@media (max-width: 400px)': {
                padding: '7px',
                borderRadius: '18px',
            },
        },
        followers: {
            marginLeft: '4px',
            fontSize: '12px',
            color: text,
            '@media (max-width: 500px)': {
                fontSize: '10px',
            },
        },
        topAsset: {
            marginRight: '0px',
            display: 'inline-flex',
            fontWeight: 200,
            pointerEvents: 'none',
            alignItems: 'center',
        },
        topAssetIcon: {
            fill,
            height: '14px',
            width: '12px',
        },
    }));
};

const SocialBadge: React.FC<ISocialBadgeProps> = ({ link, followers, className, icon: Icon, isTopAsset }: ISocialBadgeProps) => {
    const classes = useStyles()();
    const shouldNotRender = (!Utils.isDefined(link) || Utils.isEmpty(link.trim())) && !Utils.isDefined(followers);

    const openSocialMedia = React.useCallback(() => {
        if (!Utils.isDefined(link) || Utils.isEmpty(link.trim()))
            return;

        window.open(link, '_blank');
    }, [link]);

    if (shouldNotRender)
        return null;

    return (
        <div onClick={openSocialMedia} className={clsx({ [classes.container]: !isTopAsset }, { [classes.topAsset]: isTopAsset })}>
            <Icon className={clsx(className, { [classes.topAssetIcon]: isTopAsset })} />
            {Utils.isDefined(followers) && <div className={classes.followers}>{Utils.toKorM(followers, 0)}</div>}
        </div>
    );
};

export default SocialBadge;
