import { useState, useEffect, useCallback } from 'react';

interface useDragScrollProps {
    selector: string;
    momentumVelocity?: number;
}

function useDragScroll({ momentumVelocity = 0.9, selector }: useDragScrollProps) {
    const [isSwipping, setIsSwipping] = useState(false);

    const init = useCallback(() => {

        // better approach https://valtism.com/src/use-drag-scroll.html
        // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#improving_scrolling_performance_with_passive_listeners
        // test if passive event api is available, this code
        let passiveEvent: boolean | object = false;
        try {
            const opts = Object.defineProperty({}, 'passive', {
                get() {
                    passiveEvent = true;
                },
            });
            window.addEventListener('test', () => { }, opts);
        } catch (e) { }

        passiveEvent = passiveEvent ? { capture: true, passive: true } : true;
        // const supportedWheelEvent: string = 'onwheel' in HTMLDivElement.prototype ? 'wheel' :
        //   document.onMousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';
        const element = document.querySelector(selector);
        let isDown = false;
        let startX: number = 0;
        let scrollLeft: number = 0;

        if (!element) {
            return null;
        }

        element.addEventListener('mousedown', (e: any) => {
            isDown = true;
            element.classList.add('active');
            startX = e.pageX - element.clientLeft;
            scrollLeft = element.scrollLeft;
            cancelMomentumTracking();
        });

        element.addEventListener('mouseleave', () => {
            isDown = false;
            element.classList.remove('active');
        });

        element.addEventListener('mouseup', () => {
            isDown = false;
            element.classList.remove('active');
            beginMomentumTracking();
            setTimeout(() => setIsSwipping(false), 0);
        });

        element.addEventListener('mousemove', (e: any) => {
            if (!isDown) return; // todo: isDown dont change (useCallback memoize)
            e.preventDefault();
            const x = e.pageX - element.clientLeft;
            const walk = (x - startX) * 3; //scroll-fast
            let prevScrollLeft = element.scrollLeft;
            element.scrollLeft = scrollLeft - walk;
            velX = element.scrollLeft - prevScrollLeft;
            if (element.scrollLeft - prevScrollLeft && !isSwipping) {
                setIsSwipping(true);
            }
        });

        // Momentum
        let velX = 0;
        let momentumID: any;

        element.addEventListener('wheel', (_e) => {
            cancelMomentumTracking();
        }, passiveEvent);

        function beginMomentumTracking() {
            cancelMomentumTracking();
            momentumID = requestAnimationFrame(momentumLoop);
        }

        function cancelMomentumTracking() {
            cancelAnimationFrame(momentumID);
        }

        function momentumLoop() {
            if (!element) {
                return;
            }

            velX *= momentumVelocity;

            if (Math.abs(velX) > 0.5) {
                momentumID = requestAnimationFrame(momentumLoop);
            }
        }
    }, [isSwipping, momentumVelocity]);

    useEffect(() => {
        init();
        // todo: remove event listener
        // return () => {
        //
        // }
    }, [init]);

    return {
        isSwipping,
    };
}

export default useDragScroll;
