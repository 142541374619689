import CircularLoader from "components/small/CircularLoader";
import SeeMoreContainer from "components/small/see-more-container/SeeMoreContainer";
import React from "react";
import Utils from "utils/Utils";
import './AssetDescription.scss';
import IAssetDescriptionProps from "./IAssetDescription";
import LineBreaks from "components/small/line-breaks/LineBreaks";
import { LANGUAGES } from "config/_const";

const AssetDescription: React.FC<IAssetDescriptionProps> = ({ assetWithTranslatedDescriptions, assetLoading }) => {
    let description = assetWithTranslatedDescriptions.description;
    const userLanguage = Utils.getCurrentLanguage();

    if (!description || userLanguage !== LANGUAGES.FR) {
        const descriptionInUserLanguage = assetWithTranslatedDescriptions.descriptions?.find(desc => desc.lng === userLanguage);
        if (!descriptionInUserLanguage)
            return <></>;
        description = descriptionInUserLanguage.value;
    }


    return (
        <div className='asset-description-container'>
            {!assetLoading ? (
                <SeeMoreContainer>
                    <LineBreaks str={description} />
                </SeeMoreContainer>
            ) : (
                <CircularLoader
                    loaderMessage=''
                    loaderLocal
                    localLoaderStyle={{ height: '15px', width: '15px' }}
                />
            )}
        </div>
    );
};

export default React.memo(AssetDescription);