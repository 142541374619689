/* eslint-disable react-hooks/exhaustive-deps */
import { TEAM_STATUS, URLS } from "config/_const";
import IUseDialog from "hooks/use-dialog/IUseDialog";
import useDialog from "hooks/use-dialog/useDialog";
import useSlidingPages from "hooks/use-sliding-pages/useSlidingPages";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateTeamStatus } from "service/teams/action";
import useUserTournamentSubscription from "../use-user-tournament-subscription/useUserTournamentSubscription";
import IUseTournamentRegistrationConfirmation from "./IUseTournamentRegistrationConfirmation";
import IUseTournamentRegistrationConfirmationProps from "./IUseTournamentRegistrationConfirmationProps";
import TournamentTicketCheckout from "components/medium/tournament/tournament-ticket/TournamentTicketCheckout/TournamentTicketCheckout";

const useTournamentRegistrationConfirmation = ({ userTeamId, nextTournament }: IUseTournamentRegistrationConfirmationProps): IUseTournamentRegistrationConfirmation => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { closeDialog }: IUseDialog = useDialog();
    const { subscribe: subscribeToTournament } = useUserTournamentSubscription({ tournamentId: nextTournament.Id, });
    const { displaySlidingPage } = useSlidingPages();

    const onSubscribedSuccessCallback = React.useCallback((): void => {
        closeDialog();
        dispatch(updateTeamStatus(userTeamId, TEAM_STATUS.ENGAGED));
        history.push(URLS.tournament);
    }, [dispatch, history, userTeamId]);

    const handleSubscribeToTournament = React.useCallback(() => {
        if (nextTournament.HasEntryFee) {
            closeDialog();
            displaySlidingPage(<TournamentTicketCheckout />)
            return;
        }

        subscribeToTournament({ team: userTeamId }, onSubscribedSuccessCallback);
    }, [nextTournament.HasEntryFee, onSubscribedSuccessCallback, userTeamId]);

    return {
        subscribe: () => handleSubscribeToTournament(),
    }
};

export default useTournamentRegistrationConfirmation;