/* eslint-disable react-hooks/exhaustive-deps */
import { DEFAULT_ID_AS_STRING, ORDER_SIDE, ORDER_TYPE } from 'config/_const';
import { STATE_SCOPE } from 'config/_enums';
import { IStoreOrder } from 'model/IOrder';
import IBuyFromBestSellingOrders from 'model/Order/IBuyFromBestSellingOrders';
import type IBuyData from 'model/Payment/IBuyData';
import IBuyScoreBoostPayload from 'model/Payment/IBuyScoreBoostPayload';
import IBuyShresFromBestSellingOrers from 'model/Payment/IBuySharesFromBestSellingOrders';
import type ICreateOrderParams from 'model/Payment/ICreateOrderParams';
import type ISecondaryMarketPaymentData from 'model/Payment/ISecondaryMarketPaymentData';
import type { ReduxRootState, RootState } from 'model/Redux';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buyOnSecondaryMarketV4 } from 'service/direct-buy-from-best-selling-orders/action';
import { buyFromSellingOrderSelector } from 'service/direct-buy-from-best-selling-orders/selector';
import { createOrder } from 'service/order/actions';
import { buyAsset, buyTeamScoreBoostCreditPayment, purchaseTournamentSubscriptionTicketWithCredits, secondaryMarketDirectBuy } from 'service/payment/actions';
import IBuyTournamentSubscriptionTIcketParams from './IBuyTournamentSubscriptionTIcketParams';
import type ICreditsPaymentState from './ICreditsPaymentState';
import type IUseBuyByCredits from './IUseBuyByCredits';
import type IUseBuyByCreditsProps from './IUseBuyByCreditsProps';

const useBuyByCredits = (props: IUseBuyByCreditsProps): IUseBuyByCredits => {
    const dispatch = useDispatch();
    const buyFromBestSellingOrdersState: ReduxRootState<IBuyFromBestSellingOrders> = useSelector(buyFromSellingOrderSelector);
    const paymentGlobalState = useSelector((state: RootState) => state.payment);
    const ordersGlobalState: ReduxRootState<IStoreOrder[]> = useSelector((state: RootState) => state.orders);
    const [paymentState, setPaymentState] = useState<ICreditsPaymentState>({ assetId: '', stateScope: STATE_SCOPE.NONE, isDirectBuy: false });

    useEffect(() => {
        if (paymentGlobalState.loading)
            return;
        handlePayment();
    }, [paymentGlobalState.loading]);

    useEffect(() => {
        if (ordersGlobalState.loading)
            return;
        handleCreateOrder();
    }, [ordersGlobalState.loading]);

    useEffect(() => {
        if (buyFromBestSellingOrdersState.loading)
            return;
        handleDirectBuyOrderFromBestSellingOrders();
    }, [buyFromBestSellingOrdersState.loading]);

    const handleCreateOrder = async () => {
        if (paymentState.stateScope !== STATE_SCOPE.ORDER)
            return;
        handleGlobalPaymentState(ordersGlobalState);
    };

    const handleDirectBuyOrderFromBestSellingOrders = async () => {
        if (paymentState.stateScope !== STATE_SCOPE.DIRECT_BUY_BEST_SELLING_ORDERS)
            return;
        handleGlobalPaymentState(buyFromBestSellingOrdersState);
    };

    const handlePayment = async () => {
        if (paymentState.stateScope !== STATE_SCOPE.PAYMENT)
            return;
        handleGlobalPaymentState(paymentGlobalState);
    };

    const handleGlobalPaymentState = async (globalState: ReduxRootState<IBuyFromBestSellingOrders> | ReduxRootState<IStoreOrder[]> | ReduxRootState<any> | null) => {
        if (!globalState)
            return;
        if (globalState.error) {
            if (paymentState.transactionErrorCallback)
                return paymentState.transactionErrorCallback(globalState);
            return;
        }
        if (paymentState.transactionSuccessCallback)
            return paymentState.transactionSuccessCallback(globalState);
    };

    const onBeforePayment = (input: Partial<ICreditsPaymentState>, stateScope: STATE_SCOPE) => {
        setPaymentState({
            stateScope,
            isDirectBuy: input.isDirectBuy ?? false,
            assetId: input.assetId ?? DEFAULT_ID_AS_STRING,
            transactionErrorCallback: input.transactionErrorCallback,
            transactionSuccessCallback: input.transactionSuccessCallback,
        });
    };

    const buyOnPrimaryMarket = (input: ICreditsPaymentState, paymentData: IBuyData, callBackAfterTxn?: () => void) => {
        onBeforePayment(input, STATE_SCOPE.PAYMENT);
        dispatch(buyAsset(paymentData.assetId, paymentData.amountOfShares, paymentData.isDirectBuy, undefined, callBackAfterTxn));
    };

    const buyOnSecondaryMarket = (input: ICreditsPaymentState, paymentData: ISecondaryMarketPaymentData) => {
        onBeforePayment(input, input.isDirectBuy ? STATE_SCOPE.PAYMENT : STATE_SCOPE.ORDER);
        if (input.isDirectBuy)
            return dispatch(secondaryMarketDirectBuy(paymentData.amountOfShares, paymentData.assetId, (paymentData.unitaryPrice / paymentData.amountOfShares)));
        const createOrderPayload: ICreateOrderParams = createCreateOrderParamsFromSecondaryMarketPaymentData(paymentData);
        return dispatch(createOrder(createOrderPayload, props.showErrorsOnSecondaryMarketPaymentError));
    };

    const createCreateOrderParamsFromSecondaryMarketPaymentData = (data: ISecondaryMarketPaymentData) => {
        return {
            assetId: data.assetId,
            type: ORDER_TYPE.MARKET,
            side: ORDER_SIDE.BUY,
            price: data.unitaryPrice,
            amount: data.amountOfShares,
            showSuccessMessage: false,
        };
    };

    const directBuyFromBestSellingOrders = (input: ICreditsPaymentState, paymentData: IBuyShresFromBestSellingOrers, callBackAfterTxn?: () => void) => {
        onBeforePayment(input, STATE_SCOPE.DIRECT_BUY_BEST_SELLING_ORDERS);
        return dispatch(buyOnSecondaryMarketV4(paymentData, callBackAfterTxn));
    };

    const buyForScoreBoost = (input: ICreditsPaymentState, boostData: IBuyScoreBoostPayload) => {
        onBeforePayment(input, STATE_SCOPE.PAYMENT);
        dispatch(buyTeamScoreBoostCreditPayment(boostData));
    }

    const buyTournamentSubscriptionTicketWithCredits = (args: IBuyTournamentSubscriptionTIcketParams) => {
        onBeforePayment({ transactionErrorCallback: args.transactionErrorCallback, transactionSuccessCallback: args.transactionSuccessCallback }, STATE_SCOPE.PAYMENT);
        return purchaseTournamentSubscriptionTicketWithCredits(args);
    }

    return {
        buyOnSecondaryMarket,
        buyOnPrimaryMarket,
        directBuyFromBestSellingOrders,
        isPaymentStateLoading: paymentGlobalState.loading,
        isCreateOrderStateLoading: ordersGlobalState.loading,
        buyForScoreBoost,
        buyTournamentSubscriptionTicketWithCredits,
    };
};
export default useBuyByCredits;
