import { DEFAULT_ID_AS_STRING, HTTP_STATUS_OK, TEAM_STATUS } from 'config/_const';
import usePersistentTeamManagement from 'hooks/team/use-persistent-team-management/usePersistentTeamManagement';
import IUseDialog from 'hooks/use-dialog/IUseDialog';
import useDialog from 'hooks/use-dialog/useDialog';
import useNotification from 'hooks/useNotification';
import useWallet from 'hooks/wallet/use-wallet/useWallet';
import i18next from 'i18next';
import { isEqual } from 'lodash';
import ITeam from 'model/Team/ITeam';
import ITeamConfig from 'model/Team/ITeamConfig';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { userIdSelector } from 'service/auth/selectors';
import { selectDefaultConfiguration } from 'service/team-configuration/selector';
import { activeUserTeamSelector } from 'service/teams/selectors';
import TeamViewModel from 'view-model/Team/TeamViewModel/TeamViewModel';
import IUseConfirmTeam from './IUseConfirmTeam';
import IUseConfirmTeamProps from './IUseConfirmTeamProps';
import useUserLeague from 'hooks/use-user-league/useUserLeague';
import { TournamentLeagues } from 'config/_enums';
import IUseAllAssets from 'hooks/asset/use-all-assets/IUseAllAssets';
import useAllAssets from 'hooks/asset/use-all-assets/useAllAssets';

const useConfirmTeam = (props: IUseConfirmTeamProps): IUseConfirmTeam => {
    const { closeDialog }: IUseDialog = useDialog();
    const { managementNotification } = useNotification();
    const { refreshWallet } = useWallet({ considerTeamShares: true });
    const userId: string = useSelector(userIdSelector);
    const [showTournamentSubscription, setShowTournamentSubscription] = useState<boolean>(false);
    const activeUserTeam: ITeam | undefined = useSelector(activeUserTeamSelector);
    const { userLeagueViewModel } = useUserLeague();
    const defaultConfiguration: ITeamConfig | undefined = useSelector(selectDefaultConfiguration(userLeagueViewModel.LeagueAttribution.Name as TournamentLeagues));
    const [teamIdFromServer, setTeamIdFromServer] = useState<string>(props.teamViewModel.id);
    const { getAssetViewModelById }: IUseAllAssets = useAllAssets();


    const updateLocalTeamCallback = (teamUpdated: ITeam) => {
        setTeamIdFromServer(teamUpdated._id)
        refreshUserInformations();
        managementNotification(true, i18next.t('team.save.succeeded'), HTTP_STATUS_OK);
        if (props.onSaveTeamCallback)
            props.onSaveTeamCallback(teamUpdated._id);
        if (!props.onSaveTeamCallback && props.teamViewModel.isComplete(getAssetViewModelById) && props.nextTournament && props.nextTournament.isSubscriptionOpen())
            return setShowTournamentSubscription(true);
        closeDialog();
    };

    const { createTeam, updateTeam, teamLoading } = usePersistentTeamManagement({ updateLocalTeamCallback, userId });

    const createOrUpdateTeamOnServer = (): Promise<any> => {
        const internalTeamViewModel: TeamViewModel = props.teamViewModel;
        internalTeamViewModel.teamAssets = props.teamViewModel.teamAssets;
        if (internalTeamViewModel.status !== TEAM_STATUS.DISENGAGED)
            internalTeamViewModel.status = TEAM_STATUS.DISENGAGED;
        if (defaultConfiguration && !isEqual(defaultConfiguration, internalTeamViewModel.teamConfigurationViewModel.Configuration))
            internalTeamViewModel.teamConfigurationViewModel.Configuration = defaultConfiguration;
        const localDbTeam: ITeam = internalTeamViewModel.asITeam();
        return internalTeamViewModel.id === DEFAULT_ID_AS_STRING ? createTeam(localDbTeam) : updateTeam(localDbTeam);
    };

    const refreshUserInformations = () => {
        refreshWallet(true);
    };

    return {
        activeUserTeam,
        showTournamentSubscription,
        teamLoading,
        teamIdFromServer,
        createOrUpdateTeamOnServer,
        closeDialog,
    };
};

export default useConfirmTeam;
