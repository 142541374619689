import React, { useState, useEffect } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { PaymentRequestOptions } from '@stripe/stripe-js';
import { IPaymentWalletCheckoutFormProps } from './IPaymentWalletCheckoutFormProps';
import { AMOUNT_PRECISION_AFTER_DECIMAL, DEFAULT_CURRENCY, STRIPE_ACCOUNT_COUNTRY } from 'config/_const';
import useTrendexCheckoutForm from 'hooks/use-trendex-checkout-form/useTrendexCheckoutForm';
import { MarketEnum } from 'config/_enums';
import CircularLoader from 'components/small/CircularLoader';
import i18next from 'i18next';

const EXPRESS_PAYMENT_LABEL: Record<string, string> = {
    applePay: 'Apple Pay',
    googlePay: 'Google Pay',
    link: 'Link'
}

const PaymentRequestButton: React.FC<IPaymentWalletCheckoutFormProps> = (props) => {
    const trendexCheckoutFormProps = { ...props };
    trendexCheckoutFormProps.walletEmail = '';
    trendexCheckoutFormProps.buyAssetSuccessCallback = (transactionData: any, done: boolean): Promise<any> => {
        setIsLoading(false);
        return props.buyAssetSuccessCallback!(transactionData, done);
    };

    trendexCheckoutFormProps.buyAssetInErrorCallback = (transactionData: any, done: boolean): Promise<any> => {
        setIsLoading(false);
        return props.buyAssetInErrorCallback!(transactionData, done);
    };

    const { handleBuyOnPrimaryMarket, handleBuyOnSecondaryMarket } = useTrendexCheckoutForm(trendexCheckoutFormProps);
    const [paymentRequest, setPaymentRequest] = useState<any>(null);
    const stripe = useStripe();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const setLoading = () => {
        setIsLoading(true);
    };

    useEffect(() => {
        if (!stripe) return;
        const amount = parseInt(props.intent?.fiatAmount.toFixed(AMOUNT_PRECISION_AFTER_DECIMAL)!).multiply(100) || 0;
        const paymentRequestOptions: PaymentRequestOptions = {
            country: STRIPE_ACCOUNT_COUNTRY,
            currency: DEFAULT_CURRENCY.toLowerCase(),
            total: {
                label: props.label,
                amount,
            },
            requestPayerName: true,
            requestPayerEmail: true
        };
        const pr = stripe.paymentRequest(paymentRequestOptions);

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
            if (result) {
                const value = Object.entries(result || {}).find(entry => entry[1])
                const name: string = EXPRESS_PAYMENT_LABEL[value?.[0] || 'applePay'];
                props.getExpressPaymentName(name);
                setPaymentRequest(pr);
            }
        }).catch((error) => {
            props.buyAssetInErrorCallback!({
                error
            }, true);
        });

        pr.on("paymentmethod", async ({ complete, paymentMethod, payerEmail }) => {
            trendexCheckoutFormProps.walletEmail = payerEmail;
            const paymentMethodId = paymentMethod.id;
            if (props.intent?.concernedMarket === MarketEnum.PRIMARY) {
                handleBuyOnPrimaryMarket(paymentMethodId);
            }
            if (props.intent?.concernedMarket === MarketEnum.SECONDARY) {
                handleBuyOnSecondaryMarket(paymentMethodId);
            }
            complete("success"); // Indicate success to wallet payment interface.
        });

        pr.on("cancel", () => {
            setIsLoading(false);
        });

    }, [stripe, props.intent?.fiatAmount]);

    return (
        <div className="stripe-element">
            {isLoading && <CircularLoader
                loaderMessage={i18next.t('trendexloading')}
                loaderLocal={false}
                showButton={false}
                doNotSplitMessage={true} />}
            {paymentRequest && <PaymentRequestButtonElement onClick={setLoading} options={{ paymentRequest }} />}

        </div>
    );
}

export default React.memo(PaymentRequestButton);
