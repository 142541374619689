import i18n from '18n';
import clsx from "clsx";
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import SimpleAssetCard from 'components/small/simple-asset-card/SimpleAssetCard';
import { URLS } from 'config/_const';
import IUseAssetCategories from 'hooks/asset-categories/IUseAssetCategories';
import useAssetsCategories from 'hooks/asset-categories/useAssetCategories';
import IAssetReward from 'interfaces/IAssetReward';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import hiddenAsset from '../../../../assets/images/Assets/hidden-Asset/hidden-Asset-card.png';
import IRewardCardsModalProps from './IRewardCardsModalProps';
import './style.scss';
import AssetViewModel from 'view-model/Assets/AssetViewModel';

const RewardCardsModal: React.FC<IRewardCardsModalProps> = ({ rewardModalViewModel, onValidation }: IRewardCardsModalProps) => {
    const [isGiftCardOpen, setIsGiftCardOpen] = useState<boolean>(false);
    const { assetsCategories }: IUseAssetCategories = useAssetsCategories();
    const userHasWinReward: boolean = rewardModalViewModel.HasWinAssets;

    return (
        <div className='reward-cards-modal'>
            <div className="container-reward-modal">
                <div className="rank-container">
                    <div className="placement-number">
                        {rewardModalViewModel.Place}
                        <sup>{rewardModalViewModel.RanksSuffix}</sup>
                    </div>
                </div>
                <h3> <Trans i18nKey={userHasWinReward ? 'titleModalRewardCongratulations' : 'tournament.reward.card.modal.loose.title'} /></h3>
                {userHasWinReward && <p><Trans i18nKey="titleModalRewardPrize" /> </p>}
                <p><Trans i18nKey={userHasWinReward ? "tournament.reward.card.modal.subtitle" : "tournament.reward.card.modal.loose.subtitle"} values={{ amount: rewardModalViewModel.TotalTokensReceive }} /></p>
            </div>
            {userHasWinReward &&
                <div className="assets-container">
                    <div className="asset-grid-wrapper">
                        {rewardModalViewModel.AssetsReward.map((assetReward: IAssetReward) => {
                            return (
                                <div key={assetReward.asset._id} className="flipping-card">
                                    <div
                                        className={clsx("card-front", {
                                            "card-front-rotate": isGiftCardOpen,
                                        })}>
                                        <img src={hiddenAsset} alt="hidden-card" />
                                    </div>
                                    <div
                                        className={clsx("card-back", {
                                            "card-back-rotate": isGiftCardOpen,
                                        })}>
                                        {isGiftCardOpen && <SimpleAssetCard asset={new AssetViewModel({ ...assetReward.asset, currentValue: assetReward.currentValue, availableSupply: assetReward.amount })} categories={assetsCategories} />}

                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
            {(isGiftCardOpen || !userHasWinReward)
                ? <Link to={URLS.tournament}><PrimaryButton
                    className={clsx("lets-go-btn", { show: isGiftCardOpen })}
                    textButton={i18n.t('genericOk')}
                    receiveClickEvent={false}
                    type="button"
                    disabled={false}
                    handleClick={() => onValidation()}
                /></Link>
                : <PrimaryButton
                    className="open-btn"
                    receiveClickEvent={false}
                    type="submit"
                    textButton={i18n.t('seeResultsFromModalRewards')}
                    disabled={false}
                    handleClick={() => setIsGiftCardOpen(true)} />
            }
        </div>
    );
};

export default RewardCardsModal;
