import { Capacitor } from '@capacitor/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initMobilePush } from '../../../firebase-mobile';
import ReduxStoreInterface from '../../../model/ReduxStoreInterface';
import { addFirebaseToken } from '../../../service/firebase-notifications/actions';

const Notifications: React.FC = () => {
    const dispatch = useDispatch();
    const userId: string = useSelector((state: ReduxStoreInterface) => state.auth.data.account._id);
    const isNative: boolean = Capacitor.isNativePlatform();

    useEffect(() => {
        if (isNative && userId)
            initMobilePush((token) => dispatch(addFirebaseToken(userId, token)));
    }, [userId, isNative]);

    return <></>
};

export default Notifications;
