import React from "react";
import "./modal-image-container.scss";

interface ModalProps {
    imgSrc: string;
    className?: string;
    maxWidth?: string;
    width?: string;
}

const ModalImageContainer: React.FC<ModalProps> = (props) => {
    const { maxWidth = '320px', width = '100%', imgSrc, className } = props;

    return (
        <div className="modal-image-container">
            <img src={imgSrc} className={className} style={{ maxWidth, width }} />
        </div>
    )
}

export default ModalImageContainer;