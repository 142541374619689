import Application from 'Application';
import { LOCAL_STORAGE_KNOWN_KEYS } from 'config/_const';
import { NotificationPermission, NotificationUserResponse, Status, TdxNotification } from 'config/_enums';
import i18next from 'i18next';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { actionConnectUser, actionPostSubscribeToAsset } from 'service/auth/actions';
import { authSelector } from 'service/auth/selectors';
import { managementModal } from 'service/modal/action';
import Utils from 'utils/Utils';
import CircularIndeterminate from '../CircularLoader';
import NotificationUiProps from './NotificationUiProps';

const NotificationUi: React.FC<NotificationUiProps> = ({ assetId, isDetail, actionConnectUser, child, onSocialConnectSuccessCallback }) => {
    const dispatch: Dispatch<any> = useDispatch();
    const { data: { isAuthenticated, postSubscriptionStatus, postSubscriptionAssetId, account: { subscribedAssetsIds } } } = useSelector(authSelector);
    const isSubscribed: boolean = Utils.isDefined(subscribedAssetsIds) && subscribedAssetsIds.includes(assetId);
    const theme = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_THEME);
    const assetIdFromLocalStorage: string | null = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.ASSET_WHITE_LIST);

    React.useEffect(() => {
        if (!isAuthenticated || !assetIdFromLocalStorage)
            return;
        if (assetId !== assetIdFromLocalStorage)
            return;
        localStorage.removeItem(LOCAL_STORAGE_KNOWN_KEYS.ASSET_WHITE_LIST);
        if (isSubscribed)
            return;
        handleNotification();
    }, [isAuthenticated, isSubscribed]);

    const handleClick = () => {
        if (isAuthenticated)
            return handleNotification();

        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.ASSET_WHITE_LIST, assetId);
        dispatch(managementModal(true, {
            type: 'SIGN_UP',
            display: true,
            disableBackDrop: false,
            showBackArrow: Utils.isMobile(),
            fullScreen: Utils.isMobile(),
            showCancelButton: false,
            propsCustom: {
                theme,
                onSocialConnectSuccessCallback,
                onPostLogin: (form: { email: string, password: string, firstName: string, lastName: string, mode: string, token: string }) => onPostLogin(form),
                intentPurchase: false,
                forceSignup: true,
            },
        }));
    };

    const onPostLogin = (form: { email: string, password: string, firstName: string, lastName: string, mode: string, token: string }): void => {
        actionConnectUser(form);
    };

    const handleNotification = () => {
        if (postSubscriptionStatus === Status.pending && postSubscriptionAssetId === assetId)
            return;
        if (('Notification' in window) && Notification.permission === NotificationPermission.DENIED && localStorage.getItem(TdxNotification.GUIDANCE_USER_RESPONSE) !== NotificationUserResponse.REFUSED)
            dispatch(managementModal(true, {
                display: true,
                disableBackDrop: false,
                showBackArrow: false,
                fullScreen: false,
                type: 'CONFIRM',
                propsCustom: {
                    showCancelButton: true,
                    showLinkButton: false,
                    onRefused: () => {
                        localStorage.setItem(TdxNotification.GUIDANCE_USER_RESPONSE, NotificationUserResponse.REFUSED);
                        dispatch(managementModal(false));
                    },
                    onValidation: async () => {
                        dispatch(managementModal(false));
                    },
                    message: i18next.t('notification.guidance', { brand: Application.getInstance().brand }),
                },
            }));

        return dispatch(actionPostSubscribeToAsset(assetId));
    };

    return (
        <>
            {isDetail && postSubscriptionAssetId === assetId ?
                <CircularIndeterminate
                    loaderLocal
                    loaderMessage={''}
                    localLoaderStyle={{ marginBottom: '8px', height: '40px', width: '40px' }}
                    localTextStyle={{ fontSize: Utils.isMobile() ? '11px' : '16px' }} />
                :
                <>
                    {child(isSubscribed, handleClick)}
                </>}
        </>
    );
};

const mapStateToProps = (_state: NotificationUiProps) => ({
});

export default compose(
    connect(mapStateToProps, {
        actionConnectUser,
    }),
)(NotificationUi);
