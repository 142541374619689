import React from 'react';
import "./style.scss"

const CircleAvatar = ({avatarSrc}:{avatarSrc:string}) => {

    return <img
        loading="lazy"
        className="bottom-profile-icon"
        src={avatarSrc} alt="Avatar" />;
};

export default CircleAvatar;
