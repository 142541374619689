import Utils from 'utils/Utils';
import { AssetCardLevel, AssetCategory } from 'config/_enums';
import AssetInterface from 'model/AssetModel/AssetInterface';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import AccountViewModel from '../AccountViewModel/AccountViewModel';
import AssetViewModel from '../Assets/AssetViewModel';
import INumberOfSharesInTeam from '../Team/TeamViewModel/ISharesLocalTeam';
import AssetWalletViewModel from './AssetWalletViewModel';
import IWalletViewModel from './IWalletViewModel';
import { GroupWalletByAssetLevel } from './types';
import { ASSETS_CATEGORIES, TRENDEX_ROLES } from 'config/_const';

export default class WalletViewModel {
    private _portfolioTotalValue: number;
    private _walletLoading: boolean;
    private _ownedAssets: AssetWalletInterface[];
    private _loaded: boolean;
    private _totalEarnings: number;
    private _profit: number;
    private _profitPercentage: number;

    constructor(wallet: IWalletViewModel, loading: boolean, loaded: boolean) {
        this._portfolioTotalValue = wallet.portfolioTotalValue;
        this._walletLoading = loading;
        this._ownedAssets = wallet.ownedAssets;
        this._loaded = loaded;
        this._totalEarnings = wallet.totalEarnings ?? 0;
        this._profit = wallet.profit ?? 0;
        this._profitPercentage = wallet.profitPercentage ?? 0;
    }

    public getAssetsViewModelsWithoutAssetsInTeam(totalSharesInTeam: INumberOfSharesInTeam[], hideAssetsIfAmountIsZero: boolean): AssetWalletInterface[] {
        return this.UserAssets
            .filter((wallet: AssetWalletInterface) => {
                if ((hideAssetsIfAmountIsZero && wallet.amount === 0) || wallet.asset.hidden)
                    return false;
                const sharesByAsset: INumberOfSharesInTeam | undefined = totalSharesInTeam.find((shares: INumberOfSharesInTeam) => shares.assetId === wallet.asset._id);
                if (sharesByAsset === undefined)
                    return true;
                return wallet.amount - sharesByAsset.amountOfShares > 0;
            })
    }

    public getAssetsFromWallet(): AssetViewModel[] {
        return this.UserAssets
            .map((assetWallet: AssetWalletInterface) => assetWallet.asset)
            .map((asset: AssetInterface) => new AssetViewModel(asset));
    }

    public getOwnedSharesByAssetId(assetId: string): number {
        const assetWallet: AssetWalletInterface | undefined = this.UserAssets.find((wallet: AssetWalletInterface) => {
            return wallet.asset._id === assetId;
        });
        return assetWallet ? assetWallet.amount : 0;
    }

    public getOwnedAssetsAvailableInTeam(user: AccountViewModel, tournamentCategory?: string): AssetWalletInterface[] {
        return this.UserAssets.filter((assetWallet: AssetWalletInterface) => this.isOwnedAssetAvailableForTeam(user, assetWallet.asset._id, tournamentCategory));
    }

    public getAssetBySlug(slug: string): AssetInterface | undefined {
        return this.UserAssets.find(walletAsset => walletAsset.asset.slug === slug)?.asset;
    }

    public getWalletAssetById(id: string): AssetWalletInterface | undefined {
        return this.UserAssets.find(walletAsset => walletAsset.asset._id === id);
    }

    public isOwnedAssetAvailableForTeam(user: AccountViewModel, assetId: string, tournamentCategory?: string): boolean {
        const assetWallet: AssetWalletInterface | undefined = this.getWalletAssetById(assetId);
        return !assetWallet ? false : new AssetWalletViewModel(assetWallet).isEnabledForTeam(user, tournamentCategory);
    }

    public get portfolioTotalValue(): number {
        return this._portfolioTotalValue || 0;
    }
    public set portfolioTotalValue(value: number) {
        this._portfolioTotalValue = value;
    }
    public get walletLoading(): boolean {
        return this._walletLoading;
    }
    public get loaded(): boolean {
        return this._loaded;
    }
    public set walletLoading(value: boolean) {
        this._walletLoading = value;
    }
    public get UserAssets(): AssetWalletInterface[] {
        return this._ownedAssets;
    }
    public get TotalEarnings(): number {
        return this._totalEarnings;
    }

    public get Profit(): number {
        return this._profit;
    }
    public get ProfitPercentage(): number {
        return this._profitPercentage;
    }
    public get EarningChangeSign(): string {
        return this.TotalEarnings > 0 ? "+" : ""
    }
    public get YieldInfo(): { totalYield: number, totalYieldTokens: number } {
        return this.UserAssets.reduce(({ totalYieldTokens, totalYield }, userAsset) => {
            if (!userAsset.asset.yieldEnabled)
                return { totalYieldTokens, totalYield }
            const maxYield = Utils.getYieldConfigPerAsset(userAsset.asset._id).maxYield;
            return { totalYieldTokens: totalYieldTokens + userAsset.amount, totalYield: totalYield + (maxYield * userAsset.amount) / userAsset.asset.currentValue }
        }, { totalYield: 0, totalYieldTokens: 0 });
    }

    public get AssetsEligibleToLeagueAttribution(): AssetWalletInterface[] {
        return this.UserAssets.filter((userAsset: AssetWalletInterface) => userAsset.asset.mainCategory !== ASSETS_CATEGORIES[AssetCategory['Fun cards']]);
    }

    public groupAssetsByLevelAndCalculateAmountForLeagueAttribution = (): GroupWalletByAssetLevel => {
        const enumValuesAsArray: number[] = Utils.valueOfNumberEnum(AssetCardLevel);
        return enumValuesAsArray.reduce((wallet: GroupWalletByAssetLevel, cardLevel: number) => {
            const assetsCurrentLevel: AssetWalletInterface[] = this.AssetsEligibleToLeagueAttribution.filter((walletEntry: AssetWalletInterface) => (typeof walletEntry.asset.cardLevel === 'undefined' ? AssetCardLevel.Rookie : walletEntry.asset.cardLevel) === cardLevel);
            wallet[cardLevel] = assetsCurrentLevel.reduce((accTemp: number, wallet: AssetWalletInterface) => {
                return accTemp + wallet.amount;
            }, 0);
            return wallet;
        }, {});
    }

    public selectPortfolioTotalValue(user: AccountViewModel): number {
        if (user.UserType === TRENDEX_ROLES.VIP)
            return user.getUserTotalCredits();
        return this._portfolioTotalValue + user.getUserTotalCredits();
    }

}