import { KeyboardArrowLeft } from '@material-ui/icons';
import i18next from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { FILE_FORMAT_ALLOWED_FOR_IMAGE_UPLOAD, ID_FACES, NUMBER_OF_CHAR_BEFORE_VALID_ID_NUMBER } from 'config/_const';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import IIdentityFormProps from './IIdentityFormProps';
import IdentityTypeViewModel from 'view-model/IdentityFormViewModel/IdentityFormViewModel';
import AddPhoto from 'components/icons/AddPhoto';


const IdentityForm: React.FC<IIdentityFormProps> = ({ countryOfUser, idType, versoImgUrl, rectoImgUrl, handleSubmit, onIdentityChange, goBack }) => {
    const [idInput, setIdInput] = useState("");
    const identityFormViewModel = useMemo(() => new IdentityTypeViewModel(idType, countryOfUser), [idType, countryOfUser])
    const idFacesMap = useMemo(() => ({ [ID_FACES.RECTO]: rectoImgUrl, [ID_FACES.VERSO]: versoImgUrl }), [rectoImgUrl, versoImgUrl]);
    const showConfirmButton = useMemo(() => {
        const isConfirmShown = !Boolean(identityFormViewModel.RequiredIdFaces.find(idFace => !idFacesMap[idFace]));
        return isConfirmShown;
    }, [identityFormViewModel.RequiredIdFaces, idFacesMap])

    const onIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setIdInput(inputValue.trim());
    }

    useEffect(() => {
        return goBack;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<React.Fragment>
        <div className="model_title clickable" onClick={goBack}>
            <h4> <KeyboardArrowLeft /> {i18next.t('Aboutus.tab.identity.backToDocumentType')}</h4>
        </div>

        <div className="sub-title">
            {identityFormViewModel.IdTitle}
        </div>

        <div className="container-identity-img">
            <img src={identityFormViewModel.SampleImagePath} alt="ids" />
        </div>

        <Form
            className="form-identity-upload"
            onSubmit={(e: any) => {
                handleSubmit(e, idInput)
            }}>
            <Form.Group>
                <Form.Label className="identity-number-label" htmlFor="idNumber">
                    {i18next.t('Identity.pleaseSetIdNumber')}
                </Form.Label>
                <Form.Control className='id-number-field' type="text" placeholder={i18next.t('Identity.pleaseSetIdNumber')} required={true} onChange={(e: any) => onIdChange(e)} />
            </Form.Group>
            {idInput.length >= NUMBER_OF_CHAR_BEFORE_VALID_ID_NUMBER &&
                <div className='upload-images'>
                    {identityFormViewModel.RequiredIdFaces.map(imageType => (
                        <Form.Group key={imageType}>
                            <div className="upload_file">
                                <Form.Label
                                    className="bootstrap-label-overide"
                                    htmlFor={identityFormViewModel.getFileInputId(imageType)}>

                                    <div className="label-identity-upload">
                                        <div className="identity-upload_label">
                                            <AddPhoto />
                                            {identityFormViewModel.getUploadLabelFromImageType(imageType)}
                                        </div>
                                        <div className="identity-upload_data">
                                            {idFacesMap[imageType] ?
                                                <React.Fragment>
                                                    <div className='file-attached'>
                                                        {i18next.t("Aboutus.tab.identity.attached")}
                                                    </div>
                                                    <div className="upload-picture">
                                                        {i18next.t("Aboutus.tab.identity.uploadAnother")}
                                                    </div>
                                                </React.Fragment> : <div className='upload-picture'>
                                                    {i18next.t("Aboutus.tab.identity.uploadPicture")}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Form.Label>
                                <Form.File
                                    hidden={true}
                                    id={identityFormViewModel.getFileInputId(imageType)}
                                    name={identityFormViewModel.getFileInputId(imageType)}
                                    onChange={(e: any) => onIdentityChange(e, imageType)}
                                    accept={FILE_FORMAT_ALLOWED_FOR_IMAGE_UPLOAD}
                                />
                            </div>
                        </Form.Group>))
                    }
                    {showConfirmButton &&
                        <Form.Group>
                            <PrimaryButton
                                className='submit-identity'
                                textButton={i18next.t("Aboutus.tab.about.sendMyDocuments")}
                                type={"submit"}
                                receiveClickEvent={true}
                                disabled={!showConfirmButton}
                            />
                        </Form.Group>

                    }
                </div>
            }


        </Form>


    </React.Fragment>)
}

export default IdentityForm;