import ListCard from "components/small/list-card/ListCard";
import * as React from "react";
import { IPriceDetailsProps } from "./PriceDetailsProps";
import withPriceDetails from "hoc/with-price-details/withPriceDetails";

const PriceDetails: React.FC<IPriceDetailsProps> = ({
  priceInformations
}) => {

  return (
    <div className="price-details">
      <ListCard informationsList={priceInformations} />
    </div>
  );
};

export default withPriceDetails(PriceDetails);
