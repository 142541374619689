import ITournamentEvolution, { IEvolution } from 'model/Tournament/ITournamentEvolution';
import moment from 'moment';
import { ScoreHistoryDataAttribute } from './types';

export const getAssetsEvolutionsSnapshotsFromDate = (assetId: string, evolutions: ScoreHistoryDataAttribute, start: string, end: string): IEvolution[] => {
    if (!evolutions || evolutions.length === 0)
        return [];
    const evolutionsCurrentAsset: ITournamentEvolution | undefined = evolutions.find((evolution: ITournamentEvolution) => evolution.assetId === assetId);
    if (!evolutionsCurrentAsset)
        return [];
    return filterEvolutionsFromDate(evolutionsCurrentAsset.evolution, start, end);
};

export const filterEvolutionsFromDate = (evolutions: IEvolution[], start: string, end: string): IEvolution[] => {
    return evolutions.filter((assetScoreSnapshot: IEvolution) => {
        const momentAssetScoreSnapshotDate: moment.Moment = moment(assetScoreSnapshot.date);
        return momentAssetScoreSnapshotDate.isSameOrAfter(start, 'dates') && momentAssetScoreSnapshotDate.isSameOrBefore(end, 'dates') && assetScoreSnapshot.score > -1;
    });
};
