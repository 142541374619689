import * as React from 'react';

const AddPhoto = ({ height = "40px", width = "40px", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg id="icon" xmlns="http://www.w3.org/2000/svg" height={height} width={width} {...props} viewBox="0 0 48 48">
            <g id="Rectangle_2014" data-name="Rectangle 2014" fill="#6677f5" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="48" height="48" stroke="none" />
                <rect x="0.5" y="0.5" width="47" height="47" fill="none" />
            </g>
            <path id="Soustraction_31" data-name="Soustraction 31" d="M21.18,21.178H3.025A3.029,3.029,0,0,1,0,18.151V9.266c.2.02.4.03.6.03a6.008,6.008,0,0,0,6-6c0-.125,0-.251-.012-.373L7.05,1.551A2.258,2.258,0,0,1,9.2,0h5.806a2.261,2.261,0,0,1,2.149,1.551l.492,1.475H21.18A3.027,3.027,0,0,1,24.2,6.05v12.1A3.028,3.028,0,0,1,21.18,21.178ZM12.1,7.564A4.538,4.538,0,1,0,16.64,12.1,4.543,4.543,0,0,0,12.1,7.564Z" transform="translate(11.898 13.411)" fill="#5f6273" />
            <path id="circle-xmark-solid" d="M5.286,10.572A5.286,5.286,0,1,0,0,5.286,5.286,5.286,0,0,0,5.286,10.572ZM3.614,3.614a.494.494,0,0,1,.7,0l.971.971.971-.971a.495.495,0,0,1,.7.7l-.971.971.971.971a.495.495,0,0,1-.7.7l-.971-.971-.971.971a.495.495,0,0,1-.7-.7l.971-.971-.971-.971A.494.494,0,0,1,3.614,3.614Z" transform="translate(4.8 16.524) rotate(-45)" fill="#5f6273" />
        </svg>

    );
};

export default AddPhoto;