import React from 'react';
import './SecondaryButton.scss';
import clsx from 'clsx';
import IButtonProps from '../IButtonProps';

const SecondaryButton: React.FC<IButtonProps> = (props) => {
    return (
        <button
            className={clsx(props.className, 'secondary-button-base secondary-button-light full', {})}
            type={props.type}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.handleClick ? props.receiveClickEvent ? props.handleClick(e) : props.handleClick() : undefined} >

            {props.textButton}
        </button>
    );
};

export default SecondaryButton;
