import i18n from '18n';
import BenzemaFAQ from 'components/medium/benzema-faq/BenzemaFAQ';
import ListCard from 'components/small/list-card/ListCard';
import StatPanel from 'components/small/stat-panel/StatPanel';
import { trendexLogoImage } from 'data/generalImages/generalImages.data';
import withBenzemaClub from 'hoc/with-benzema-club/withBenzemaClub';
import * as React from 'react';
import ClubCard from '../ClubCard/ClubCard';
import './BenzemaClubSection.scss';
import IBenzemaClubSection from './BenzemaClubSectionProps';
import BuyPacksButton from 'components/small/buy-packs-button/BuyPacksButton';
import BuySharesButton from '../../../../components/medium/buying/BuySharesButton';
import Application from 'Application';

const BenzemaClubSection: React.FC<IBenzemaClubSection> = (props) => {
    return (
        <section className='benzema-club'>
            <div className='benzema-club__articles'>
                <div className='benzema-club__articles-title'>
                    {i18n.t(props.assetViewModel.isCustomBenzemaProfilPageBeforePublicSales() ? 'benzema.club.join.title.early.bird' : 'benzema.club.join.title')}
                </div>
                <div className='benzema-club__article-list'>
                    <ClubCard cardInformationList={props.cardInformationList} />
                </div>
            </div>

            {props.withLightAssetProfil &&
                <>
                    {props.assetViewModel.IsWhiteListedPurchaseOpen && props.assetViewModel.isEarlyBirdEnable() && <div className="button-buy-container">
                        <BuySharesButton assetViewModel={props.assetViewModel} isSaleStartingInLessThan3Min={props.assetViewModel.isInPrePrivateSaleState()} />
                    </div>}
                    {props.assetViewModel.IsWhiteListedPurchaseOpen && !props.assetViewModel.isEarlyBirdEnable() && <div className="pack-button-container-asset-profil">
                        <BuyPacksButton assetViewModel={props.assetViewModel} />
                    </div>}
                    <div className="benzema-faq-container">
                        <BenzemaFAQ assetViewModel={props.assetViewModel} />
                    </div>
                </>
            }

            <div className='benzema-club__trends-detail'>
                <ListCard informationsList={props.trendDetailList} />
            </div>

            <div className='benzema-club__footer-detail'>
                {props.assetViewModel.isEarlyBirdEnable() && <div className="button-buy-container">
                    <BuySharesButton assetViewModel={props.assetViewModel} isSaleStartingInLessThan3Min={props.assetViewModel.isInPrePrivateSaleState()} />
                </div>}
                <div className='benzema-club__footer-title'>
                    <img src={trendexLogoImage} alt='trendex_logo' />
                    <span>{i18n.t('stat.already')}</span>
                </div>
                <StatPanel statList={Application.getInstance().StatListTrendex} />
            </div>
        </section >
    );
};

export default withBenzemaClub(BenzemaClubSection);
