import { isJwtTokenValid } from "service/token.service";

const useAccountFromCookies = () => {
    const isUserExist = isJwtTokenValid();

    return {
        isUserExist,
    }
}

export default useAccountFromCookies;
