import IPixelHandler from "../pixel/IPixelHandler";
import IDepositData from "./IDepositData";
import ReactPixel from 'react-facebook-pixel';
import Application from "Application";

export default class PixelHandlerDeposit implements IPixelHandler {
    private data: IDepositData;
    private trackOnTheFirstPayment: boolean;
    private static customFacebookEventName = 'deposit';
    private static isFirstPayment: string = 'First Purchase';

    constructor(trackOnTheFirstPayment: boolean) {
        this.data = {
            currency: Application.getInstance().currency,
            value: 0
        }
        this.trackOnTheFirstPayment = trackOnTheFirstPayment;
    }

    public get depositData() {
        return this.data;
    }

    public set depositData(data: IDepositData) {
        this.data = data;
    }

    handle(): void {
        ReactPixel.trackCustom(PixelHandlerDeposit.customFacebookEventName, this.data);
        if (this.trackOnTheFirstPayment)
            ReactPixel.trackCustom(PixelHandlerDeposit.isFirstPayment, this.data);
    }

}