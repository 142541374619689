import React from 'react';
import PrimaryButtonProps from './PrimaryButtonProps';
import './PrimaryButton.scss';

const PrimaryButton: React.FC<PrimaryButtonProps> = (props) => {
    return (
        <button
            style={props.style}
            className={`primary-button ${props.className}`}
            type={props.type}
            disabled={props.loading || props.disabled}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.handleClick ? props.receiveClickEvent ? props.handleClick(e) : props.handleClick() : undefined}
        >
            {props.textButton}
            {props.loading && <div className="loader loader-box" />}
            {props.children}
        </button>
    );
};

export default PrimaryButton;
