import * as React from 'react';

const Heart = ({ height = "28.19", width = "32.97", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg width={width} height={height} {...props} xmlns="http://www.w3.org/2000/svg">
            <defs>
                <radialGradient id="radial-gradient" cx="0.743" cy="0.13" r="0.708" gradientTransform="matrix(-1, 0, 0, 0.972, 1.486, 0.004)" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#f94179" />
                    <stop offset="1" stopColor="#6677f5" />
                </radialGradient>
                <filter id="heart-solid" x="0" y="0" width="77.965" height="73.191" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="7.5" result="blur" />
                    <feFlood floodColor="#e84787" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#heart-solid)">
                <path id="heart-solid-2" data-name="heart-solid" d="M3.065,58.778,14.7,69.64a2.617,2.617,0,0,0,3.567,0L29.9,58.778a9.632,9.632,0,0,0,3.065-7.05v-.373a9.2,9.2,0,0,0-15.71-6.509l-.773.773-.773-.773A9.2,9.2,0,0,0,0,51.355v.373A9.632,9.632,0,0,0,3.065,58.778Z" transform="translate(22.5 -22.65)" fill="url(#radial-gradient)" />
            </g>
        </svg>
    );
}

export default Heart;
