import TrophyYield from 'assets/images/trophy-yield-green.webp';
import clsx from 'clsx';
import CircleMinusSolid from 'components/icons/CicleMinusSolid';
import CirclePlusSolid from 'components/icons/CirclePlusSolid';
import Gifts from 'components/icons/Gifts';
import { PaymentMethodsEnum, TransactionType } from 'config/_enums';
import i18next from 'i18next';
import React from 'react';
import clockTimeGif from '../../../assets/images/clock-time.gif';
import validated from '../../../assets/images/validated.png';
import AssetAvatar from '../asset-avatar/AssetAvatar';
import './SuccessBuyAssetSharePage.scss';
import SuccessBuyAssetSharePageProps from './SuccessBuyAssetSharePageProps';
import { EmptyString } from 'config/_const';

const SuccessBuyAssetSharePage: React.FC<SuccessBuyAssetSharePageProps> = ({ totalPrice, asset, transactionType, orderExecuted, tokenQuantity, totalFeesFormatted, totalTransactionFormatted, isLiquidityEnabled, amountPaidInVirtualCredit, userYield, paymentMethod }) => {
    const isTransactionTypeSell = transactionType === TransactionType.SELL;
    const buySuccessMessageText = orderExecuted ? i18next.t('buyasset.confirm.orderExecuted.text', { name: asset.AssetName, totalPrice: totalPrice.toCurrency(), quantity: tokenQuantity }) : i18next.t('buyAsset.emailConfirmation', { name: asset.AssetName, totalPrice: totalPrice.toCurrency(), quantity: tokenQuantity });

    const getGiftAmountUsed = () => {
        if (!isTransactionTypeSell && amountPaidInVirtualCredit && paymentMethod === PaymentMethodsEnum.CREDITS) {
            return <div className='amount-breakdown'>
                <Gifts />
                <span> {i18next.t("asset.order.in.rewards", { amount: amountPaidInVirtualCredit.toCurrency() })}</span>
            </div>
        }
    }

    const getHeaderMsg: () => string = () => {
        if (isTransactionTypeSell) {
            return orderExecuted ? i18next.t("liquidityEnabled.sellAsset.confirmHeader", { name: asset.AssetName, totalPrice: totalTransactionFormatted }) : i18next.t("asset.order.placed", { asset: asset.AssetName, amount: totalTransactionFormatted })
        }

        return i18next.t("asset.order.executed");
    };

    const getContentMsg: () => string = () => {
        if (isTransactionTypeSell) {
            return isLiquidityEnabled
                ? i18next.t("liquidityEnabled.sellAsset.feeInfo", { feePrice: totalFeesFormatted })
                : i18next.t("sellAsset.feeInfo", { feePrice: totalFeesFormatted });
        }
        return buySuccessMessageText;
    };

    const getEmailConfirmMsg: () => string = () => isLiquidityEnabled
        ? EmptyString
        : i18next.t("sellAsset.emailConfirmation");

    return (
        <div className="success-buy-asset-share-page">
            <div className="header-image-wrapper">
                <AssetAvatar asset={{
                    avatarPath: asset.AvatarPath,
                    cardLevel: asset.CardLevel,
                    mainCategoryId: asset.MainCategoryId
                }} />
                {orderExecuted ? <img src={validated} alt="clock-time" className='bottom-validate-gif' /> : <img src={clockTimeGif} alt="clock-time" className='bottom-clock-gif' />}
                <div className={clsx("transaction-icon", { red: isTransactionTypeSell })}>
                    {transactionType === TransactionType.BUY ? <CirclePlusSolid /> : <CircleMinusSolid />}
                </div>
            </div>
            <div className="success-buy-asset-share-page-header">
                {getHeaderMsg()}
            </div>
            <div className="success-buy-asset-share-page-content">
                {getContentMsg()}
            </div>
            {getGiftAmountUsed()}
            {isTransactionTypeSell &&
                <div className="success-buy-asset-share-page-content">
                    {getEmailConfirmMsg()}
                </div>
            }
            {asset.YieldEnabled && userYield &&
                <div className="success-buy-asset-share-page-yield">
                    <img src={TrophyYield} />
                    {i18next.t('buyAsset.yield')}
                </div>
            }
        </div>
    );
};

export default SuccessBuyAssetSharePage;
