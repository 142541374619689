import Clock from "components/icons/Clock";
import Shares from "components/icons/Shares";
import AssetAvatar from "components/small/asset-avatar/AssetAvatar";
import CircularIndeterminate from 'components/small/CircularLoader';
import React from "react";
import Close from "components/icons/Close";
import WalletOrderViewModel from "view-model/WalletOrderViewModel/WalletOrderViewModel";
import AssetViewModel from "view-model/Assets/AssetViewModel";
import { OrderStatus } from "config/_enums";
import useOrderCard from "hooks/use-order-card/useOrderCard";
import IUseOrderCard from "hooks/use-order-card/IUseOrderCard";

const OrderCard: React.FC<{ orderViewModel: WalletOrderViewModel }> = ({ orderViewModel }) => {
    const { isCancellingOrder, internalCancelOrder, renderYieldWarning }: IUseOrderCard = useOrderCard({ orderViewModel });
    const assetViewModel: AssetViewModel = new AssetViewModel(orderViewModel.relatedAsset);
    return (
        <>
            <div className="wallet-order-date">
                <span>
                    {orderViewModel.formattedCreatedAtDate}
                </span>
                {orderViewModel.formattedCreatedAtTime}
            </div>
            <div className='wallet-order-body'>
                <div className="wallet-order-avatar">
                    {<AssetAvatar asset={{
                        avatarPath: assetViewModel.AvatarPath,
                        mainCategoryId: assetViewModel.MainCategoryId,
                        cardLevel: assetViewModel.CardLevel
                    }} />}
                    <div className="avatar-icon"><Shares /></div>
                </div>
                <div className="wallet-order-detail">
                    <div className='wallet-order-waiting d-flex'><Clock className='pr-1' />
                        {orderViewModel.orderStatus === OrderStatus.QUEUED
                            ? orderViewModel.orderDescription('queued')
                            : orderViewModel.orderDescription('waiting')
                        }
                    </div>
                    <span className="wallet-transaction-description">
                        {orderViewModel.orderDescription('description.text')}
                    </span>
                    {renderYieldWarning()}
                    <div className="wallet-order-summary">
                        <span>{orderViewModel.orderDescription('summary.tokens')}</span>
                        <span>{orderViewModel.orderDescription('summary.price')}</span>
                        <span>{orderViewModel.orderDescription('summary.total')}</span>
                    </div>
                </div>
            </div>
            {orderViewModel.orderStatus === OrderStatus.OPEN && (
                <div className="wallet-order-action">
                    <button className="wallet-order-cancel-button" onClick={internalCancelOrder}>
                        {isCancellingOrder ?
                            <CircularIndeterminate
                                loaderMessage=""
                                loaderLocal={true}
                                localLoaderStyle={{ height: '25px', width: '25px', margin: 0 }}
                            /> : <Close className="cancel-order-icon" />}
                        {orderViewModel.orderDescription('cancelOrder')}
                    </button>
                </div>
            )}
        </>
    );
}

export default OrderCard;