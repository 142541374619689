import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { VALUE_FORMAT_TYPE } from 'config/_const';
import React from 'react';
import { Trans } from 'react-i18next';
import IWalletItemProps from './IWalletItemProps';

const WalletItem: React.FC<IWalletItemProps> = (props) => {
    const Icon = props.icon;
    const renderFormattedValue = (value: number | string, formatType: VALUE_FORMAT_TYPE): JSX.Element => {
        switch (formatType) {
            case VALUE_FORMAT_TYPE.CURRENCY:
                return <>{value}</>;
            case VALUE_FORMAT_TYPE.PERCENTAGE:
                return <Trans
                    i18nKey={"wallet.percentagePerYear"}
                    components={[<span className='wallet-item_superscript' />]}
                    values={{
                        value: value
                    }} />;
            default:
                return <>{value}</>
        }
    }
    return <div className='wallet-item-container'>
        <div className="wallet-item_label">
            <span className="wallet-item-icon">
                <Icon />
            </span>
            {props.name}
            <Tooltip enterTouchDelay={100} title={<p>{props.tooltip}</p>}>
                <Info className='wallet-item-tooltip' />
            </Tooltip>
        </div>
        <div className="wallet-item_value">
            {renderFormattedValue(props.value, props.formatType)}
        </div>
    </div>
}

export default WalletItem;
