/* eslint-disable react-hooks/exhaustive-deps */
import { DEFAULT_ID_AS_STRING } from 'config/_const';
import { ReduxRootState, RootState } from 'model/Redux';
import ITeam from 'model/Team/ITeam';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTeam as createTeamAction, getActiveUserTeams as getUserTeamsAction, updateTeam as updateTeamAction } from 'service/teams/action';
import IUsePersistentTeamManagement from './IUsePersistentTeamManagement';
import IUseTeamManagementProps from './IUseTeamManagementProps';
const usePersistentTeamManagement = (props: IUseTeamManagementProps): IUsePersistentTeamManagement => {
    const dispatch = useDispatch();
    const teamState: ReduxRootState<ITeam[]> = useSelector((state: RootState) => state.teams);
    const [createdTeamIdFromServer, setCreatedTeamIdFromServer] = useState<string>(DEFAULT_ID_AS_STRING);
    const internalUserId = React.useMemo(() => props.userId, [props.userId]);

    const internalSetTeamId = (teamId: string) => setCreatedTeamIdFromServer(teamId);
    const internalSetUserTeams = async () => {
        if (!internalUserId)
            return;
        const teams: ITeam[] = teamState.data.filter((team: ITeam) => team.owner._id === internalUserId);
        if (!teams || teams.length === 0)
            return;
        if (props.getUserTeamsCallback)
            props.getUserTeamsCallback(teamState.data);
    };

    useEffect(() => {
        if (teamState.data.length === 0 || teamState.loading)
            return;
        internalSetUserTeams();
        if (createdTeamIdFromServer === DEFAULT_ID_AS_STRING)
            return;
        const teamUpdated: ITeam | undefined = teamState.data.find((storeTeam: ITeam) => storeTeam._id === createdTeamIdFromServer);
        if (!teamUpdated || !props.updateLocalTeamCallback)
            return;
        props.updateLocalTeamCallback(teamUpdated);
    }, [teamState, createdTeamIdFromServer, internalUserId]);

    useEffect(() => {
        if (!teamState.error)
            return;
        if (props.teamActionErrorCallback)
            return props.teamActionErrorCallback(teamState.error);
    }, [teamState.error]);

    const createTeam = async (team: ITeam): Promise<any> => {
        return dispatch(createTeamAction(team, internalSetTeamId));
    };

    const updateTeam = async (team: ITeam): Promise<any> => {
        return dispatch(updateTeamAction(team, internalSetTeamId));
    };

    const getUserTeams = async (transparentLoading: boolean = false): Promise<any> => {
        dispatch(getUserTeamsAction(internalUserId, transparentLoading));
    };

    return {
        createTeam,
        updateTeam,
        getUserTeams,
        userTeams: teamState.data,
        teamLoading: teamState.loading,
    };
};

export default usePersistentTeamManagement;
