import React from 'react';
import CircularIndeterminate from './CircularLoader';

const LoadingIndicatorWithDelay: React.FC<{ delay?: number }> = () => {
    const delay = 200; // 200ms
    const [showLoadingIndicator, setLoadingIndicatorVisibility] = React.useState(false);

    React.useEffect(() => {
        const timer = setTimeout(() => setLoadingIndicatorVisibility(true), delay);

        // this will clear Timeout when component unmont like in willComponentUnmount
        return () => {
            clearTimeout(timer);
        };
    });

    return showLoadingIndicator ? <CircularIndeterminate loaderMessage="" /> : null;
};

export default LoadingIndicatorWithDelay;
