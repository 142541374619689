import React from 'react';
import { withTranslation } from 'react-i18next';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';
import LeagueAccessProps, { ILeagueAccessWithExtraProps } from './LeagueAccessProps';
import './style.scss';
import withAccessLeague from 'hoc/withAccessLeague/withAcessLeague';
import ListCard from 'components/small/list-card/ListCard';
import { compose } from 'redux';

const LeagueAccess: React.FC<ILeagueAccessWithExtraProps > = ({ tournamentLeague, t: translate, showMaxSubscriptions, canShowTeamData, listOfAccessLeague }) => {
    const requireLeagueTokens: LeagueViewModel[] = tournamentLeague.getAccessConditions();
    const canShowRelationsContainer: Boolean = canShowTeamData || Boolean(requireLeagueTokens.length);

    return (
        <div className='league-relations'>
            <div className='flex title-container'>
                <p className='no-margin title'>{translate(canShowTeamData ? 'team.current.league.with.league.name' : 'team.current-league', { leagueName: tournamentLeague.Name })}</p>
                {!canShowTeamData &&
                    <p className='no-margin title number-tokens'>{translate(requireLeagueTokens.length > 0 ? 'league.require.total.tokens' : 'orderbook.shares', { amount: tournamentLeague.TotalTokens })}</p>
                }
            </div>

            {canShowRelationsContainer &&
                <ListCard className={canShowTeamData ? 'team-data' : ''} informationsList={listOfAccessLeague} />
            }
            {showMaxSubscriptions && <p className='no-margin tournament-places'>{translate('tournament.leagues.available.seats', { maxSubscriptions: tournamentLeague.MaxSubscriptions })}</p>}
        </div>
    )
}

export default compose<React.FC<LeagueAccessProps>>(withTranslation(), withAccessLeague,)(LeagueAccess);