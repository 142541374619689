import { ZERO } from 'config/_const';
import IFootballStatisticsGoal from 'interfaces/sports/football/statistics/IFootballStatisticsGoal';

export default class FootballStatisticsGoalViewModel {
    private total: number;
    private conceded: number;
    private assists: number;
    private saves: number;

    constructor(statistics: Partial<IFootballStatisticsGoal>) {
        this.total = statistics.total ?? ZERO;
        this.conceded = statistics.conceded ?? ZERO;
        this.assists = statistics.assists ?? ZERO;
        this.saves = statistics.saves ?? ZERO;
    }

    get Total(): number {
        return this.total;
    }

    get Conceded(): number {
        return this.conceded;
    }

    get Assists(): number {
        return this.assists;
    }

    get Saves(): number {
        return this.saves;
    }
}
