import i18n from '18n';
import clsx from 'clsx';
import Padlock from 'components/icons/Padlock';
import GreenButton from 'components/small/buttons/green-button/GreenButton';
import WhiteButtonWithBorder from 'components/small/buttons/white-button/WhiteButtonWithBorder';
import TeamEngagedWarningMessage from 'components/small/team/team-engaged-warning-message/TeamEngagedWarningMessage';
import { DEFAULT_ID_AS_STRING, TEAM_STATUS, TOURNAMENT_CATEGORY } from 'config/_const';
import IUseAllAssets from 'hooks/asset/use-all-assets/IUseAllAssets';
import useAllAssets from 'hooks/asset/use-all-assets/useAllAssets';
import useTournament from 'hooks/tournament/use-tournament/useTournament';
import useDialog from 'hooks/use-dialog/useDialog';
import i18next from 'i18next';
import React from 'react';
import { useDispatch } from 'react-redux';
import { managementModal } from 'service/modal/action';
import Utils from 'utils/Utils';
import TeamCallToActionViewModel from 'view-model/Team/TeamCallToAactionViewModel/TeamCallToActionViewModel';
import IUseAccount from '../use-account/IUseAccount';
import useAccount from '../use-account/useAccount';
import IUseTeamsOverview from './IUseTeamsOverview';
import IUseTeamsOverviewProps from './IUseTeamsOverviewProps';
import useIsDesktopMode from 'hooks/useIsDesktopMode';

const useTeamsOverview = (props: IUseTeamsOverviewProps): IUseTeamsOverview => {
    const dispatch = useDispatch();
    const { closeDialog } = useDialog();
    const { getAssetViewModelById }: IUseAllAssets = useAllAssets();
    const isEngagedInTournament = props.team.status === TEAM_STATUS.ENGAGED;
    const { nextTournament } = useTournament();
    const tournamentId: string = nextTournament?.Id ?? DEFAULT_ID_AS_STRING;
    const { accountViewModel }: IUseAccount = useAccount();
    const isDesktopMode = useIsDesktopMode();
    const isTeamIncomplete = (): boolean => !props.team.isComplete(getAssetViewModelById) ?? true;

    const displayTeamEngagedWarningMessage = (): void => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                message: <TeamEngagedWarningMessage />,
                showCancelButton: false,
                textValidButton: i18next.t('team.engaged.validation'),
                onValidation: closeDialog,
            },
        }));
    };

    const displayTournamentWillStartSoon = (): void => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                message: i18next.t('next-tournament-soon'),
                showCancelButton: false,
                textValidButton: i18next.t('team.engaged.validation'),
                onValidation: closeDialog,
            },
        }));
    };

    const displaySubscribeConfirmationModal = () => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: true,
            showBackArrow: Utils.isMobile(),
            fullScreen: Utils.isMobile(),
            showCancelButton: !Utils.isMobile(),
            type: 'SUBSCRIBE_CONFIRM_MODAL',
            propsCustom: {
                nextTournament,
                teamId: props.team.id,
            },
        }));
    };

    const internalSubscribeToTournament = () => {
        if (!props.team.isComplete(getAssetViewModelById)) return;
        if (nextTournament?.Category && !nextTournament.IsTournamentDefault) {
            const isExcludedTeamAssetsExist = props.isTeamIncompatibleWithTournament(nextTournament.Category)
            if (isExcludedTeamAssetsExist) return;
        }
        if (tournamentId === DEFAULT_ID_AS_STRING)
            return displayTournamentWillStartSoon();
        return displaySubscribeConfirmationModal();
    };

    const modalIncompleteTeam = (maxNumberOfAssets: number): void => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: true,
            fullScreen: Utils.isMobile(),
            type: 'INCOMPLETE_TEAM',
            propsCustom: {
                maxNumberOfAssets,
                onValidation: closeDialog,
            },
        }));
    };

    const renderSecondaryButton = (teamCTAViewModel: TeamCallToActionViewModel): JSX.Element | null => {
        if (teamCTAViewModel.displayResetTeamButton() && isDesktopMode)
            return <div className="button-generate-team teams-btn">
                <WhiteButtonWithBorder
                    className='reset-btn'
                    textButton={i18n.t('reset-team')}
                    receiveClickEvent={false}
                    type={'button'}
                    disabled={false}
                    handleClick={props.resetTeam}
                    debounceEvent={true}
                    preventDefaultEvent={false}
                />
            </div>;

        const teamAssetsNotEmpty: boolean = Boolean(props.team && props.team.teamAssets.length > 0);
        const maxNumberOfAssets: number = props.team.configuration.maxNumberOfAssets;
        const userHasEnoughAssetsInWallet: boolean = (props.walletViewModel.getOwnedAssetsAvailableInTeam(accountViewModel).length - (props.team?.teamAssets.length ?? 0)) > 0;
        const generateButtonText: string = props.team.id !== DEFAULT_ID_AS_STRING || teamAssetsNotEmpty ? i18n.t('generate.team.with.team') : i18n.t('generate-team-not-connected');

        if (userHasEnoughAssetsInWallet && teamAssetsNotEmpty && !isEngagedInTournament && isDesktopMode)
            return (
                <div className="button-generate-team teams-btn">
                    <WhiteButtonWithBorder
                        textButton={generateButtonText}
                        receiveClickEvent={false}
                        type={'button'}
                        disabled={props.walletViewModel.getOwnedAssetsAvailableInTeam(accountViewModel).length < maxNumberOfAssets}
                        handleClick={() => props.launchGeneration()}
                        debounceEvent={true}
                        preventDefaultEvent={false} />
                </div>
            );
        return null;
    };

    const renderButtonsAndWidget = (): JSX.Element => {
        const maxNumberOfAssets: number = props.team.configuration.maxNumberOfAssets;
        const teamCTAViewModel: TeamCallToActionViewModel = new TeamCallToActionViewModel({
            isEngagedInTournament,
            team: props.team,
            functionsByCTAType: {
                GENERATE: () => props.launchGeneration(),
                CONFIRM: () => props.team?.isComplete(getAssetViewModelById) ? props.displayConfirmTeamDialog() : modalIncompleteTeam(maxNumberOfAssets),
                ENGAGED: () => displayTeamEngagedWarningMessage(),
                JOIN_TOURNAMENT: () => internalSubscribeToTournament(),
            },
            checkDisableByCTAType: {
                GENERATE: () => false,
                CONFIRM: () => isTeamIncomplete(),
                ENGAGED: () => false,
                JOIN_TOURNAMENT: () => isTeamIncomplete(),
            }
        });

        const renderErrorMsg = (teamCTAViewModel: TeamCallToActionViewModel): JSX.Element => <>
            {(isTeamIncomplete() && teamCTAViewModel.canDisplayErrorForSaveAndJoinBtn()) &&
                <div className='error-team center'>
                    {i18n.t('team.invalid.slot')}
                </div>
            }
        </>;
        const teamAssetsNotEmpty: boolean = Boolean(props.team && props.team.teamAssets.length > 0);
        const displayCallToActionButton: boolean = teamAssetsNotEmpty || props.walletViewModel.getOwnedAssetsAvailableInTeam(accountViewModel).length >= maxNumberOfAssets;
        const tournamentButtonType = nextTournament?.Category ?? TOURNAMENT_CATEGORY.DEFAULT;

        return (<>
            <div className="flex buttons teams-schedule-btns-row">
                {renderSecondaryButton(teamCTAViewModel)}

                {displayCallToActionButton &&
                    <div className={clsx('button-confirm-team teams-btn', { 'is-engaged': isEngagedInTournament })}>
                        <GreenButton
                            {...teamCTAViewModel.generateCTAProps(tournamentButtonType as TOURNAMENT_CATEGORY)}
                            showShadow={true}
                            icon={isEngagedInTournament
                                ? <Padlock width={20} height={20} />
                                : undefined
                            }
                        />
                    </div>}
            </div>
            {renderErrorMsg(teamCTAViewModel)}
        </>);
    };

    return {
        renderButtonsAndWidget,
    };
};

export default useTeamsOverview;
