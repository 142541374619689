
import React from 'react';
import { Radio, withStyles } from '@material-ui/core';

export const TrendexRadio = withStyles({
    root: {
        color: '#fa5371',

    },
})(props => <Radio color="default" {...props} />);

