import ITopBarStoreData from 'model/ITopBarStoreData';
import ReduxRootState from 'model/Redux/ReduxRootSate';
import ReduxActionInterface from 'model/ReduxActionInterface';
import * as ThemeAction from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<ITopBarStoreData> = {
    loading: false,
    data: {
        color: 'black',
    },
};

export const topBarReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    if (action.type === ThemeAction.SWITCH_COLOR)
        return {
            ...state,
            data: {
                ...state.data,
                color: action.payload.color,
            },
        };
    return state;
};
