import i18n from '18n';
import { Button } from '@material-ui/core';
import { CONNECTION_MODE, URLS } from 'config/_const';
import React from 'react';
import { useHistory } from 'react-router-dom';
import "./tob-bar-actions.scss"

const TopBarActions: React.FC = () => {
    const history = useHistory();

    const handleActionClick = (mode: string) => {
        const modeSearchParam = new URLSearchParams();
        modeSearchParam.set("mode", mode);
        history.push({ pathname: URLS[mode.toLowerCase()], search: modeSearchParam.toString() })
    }

    return <div className="top-bar-actions customize-menu">
        <Button
            className='top-bar-btn login-btn'
            onClick={() => handleActionClick(CONNECTION_MODE.LOGIN)}>
            {i18n.t("login")}
        </Button>
        <Button
            className='top-bar-btn signup-btn'
            onClick={() => handleActionClick(CONNECTION_MODE.REGISTER)}>
            {i18n.t("signup")}
        </Button>
    </div>
}

export default TopBarActions;