import Application from 'Application';
import { DEFAULT_SCORE_BOOST } from 'config/_const';
import { TournamentLeagues, TournamentRewardKind } from 'config/_enums';
import { ITournamentTeam, WithInjectedTeamScoreAverage } from 'model/Team/ITeam';
import ITeamAsset from 'model/Team/ITeamAsset';
import { ITournamentBase } from 'model/Tournament/ITournament';
import { IScoreBoost, ITournamentSubscriptionViewModelParams } from 'model/Tournament/ITournamentSubscription';
import UserInterface from 'model/User/UserInterface';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';

export default class TournamentSubscriptionViewModel {
    private _id: string;
    private team: ITournamentTeam;
    private tournament: ITournamentBase;
    private subscriptionDate: string;
    private user: AccountViewModel;
    private teamScoreAverage: number;
    private totalAssetsOwnedAtSubscriptionTime: number;
    private cashprize: number;
    private league: TournamentLeagues;
    private scoreBoost: IScoreBoost;
    private kind: TournamentRewardKind;
    private totalRecruitmentPointsAtSubscriptionTime: number;
    private place: number;

    constructor(args: WithInjectedTeamScoreAverage<ITournamentSubscriptionViewModelParams>) {
        this._id = args._id;
        this.team = args.team;
        this.tournament = args.tournament;
        this.subscriptionDate = args.subscriptionDate;
        this.teamScoreAverage = args.teamScoreAverage;
        this.totalAssetsOwnedAtSubscriptionTime = args.totalAssetsOwnedAtSubscriptionTime;
        this.cashprize = args.cashprize ?? 0;
        this.user = new AccountViewModel({
            account: args.user as UserInterface,
            isAuthenticated: false,
        });
        this.league = args.league;
        this.scoreBoost = args.scoreBoost;
        this.kind = TournamentRewardKind.CREDITS_REWARD
        this.totalRecruitmentPointsAtSubscriptionTime = args.totalRecruitmentPointsAtSubscriptionTime;
        this.place = 0;
    }

    public get TournamentSubscriptionId(): string {
        return this._id;
    }

    public get Team(): ITournamentTeam {
        return this.team;
    }

    public get Tournament(): ITournamentBase {
        return this.tournament;
    }

    public get SubscriptionDate(): string {
        return this.subscriptionDate;
    }

    public get User(): AccountViewModel {
        return this.user;
    }

    public get TeamScoreAverage(): number {
        return this.teamScoreAverage;
    }

    public get TotalAssetsOwnedAtSubscriptionTime(): number {
        return this.totalAssetsOwnedAtSubscriptionTime;
    }

    public get Cashprize(): number {
        return this.cashprize;
    }

    public set Cashprize(value: number) {
        this.cashprize = value;
    }

    public get League(): TournamentLeagues {
        return this.league;
    }

    public get TokensInTeam(): number {
        if (this.TotalRecruitmentPointsAtSubscriptionTime)
            return this.TotalRecruitmentPointsAtSubscriptionTime;

        return this.Team.teamAssets.reduce((acc: number, teamAsset: ITeamAsset) => acc + teamAsset.numberOfShares, 0);
    }

    public get RateOfReturn(): number {
        return this.Cashprize * Application.getInstance().tournamentsPerYear;
    }

    public get TournamentCategory(): string {
        return this.tournament.category.toLowerCase();
    }

    public get ScoreBooster(): IScoreBoost {
        return Application.getInstance().IsTeamScoreBoostConfigured
            ? this.scoreBoost
            : DEFAULT_SCORE_BOOST;
    }

    public get IsScoreBoosted(): boolean {
        return this.ScoreBooster.isAcquired;
    }

    public get TotalRecruitmentPointsAtSubscriptionTime(): number {
        return this.totalRecruitmentPointsAtSubscriptionTime;
    }

    public get Kind(): TournamentRewardKind {
        return this.kind;
    }

    public set Kind(kind: TournamentRewardKind) {
        this.kind = kind;
    }

    public get Place(): number {
        return this.place;
    }

    public set Place(place: number) {
        this.place = place
    }

    public get BoostPrice(): number {
        const league: LeagueViewModel | undefined = Application.getInstance().getLeagues().find((tempsLeague) => tempsLeague.Name === this.League);
        if (!league)
            return 0;
        return league.PriceForTeamScoreBoost;
    }
}

