import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import useSlidingPages from 'hooks/use-sliding-pages/useSlidingPages';
import i18next from 'i18next';
import ForgotPasswordFormRedux from 'page/ForgotPassword/ForgotPassword';
import React from 'react';
import useAuthentificationForm from 'hooks/use-authentication-form/useAuthentificationForm';
import './LocalLogin.scss';
import LocalLoginProps from './LocalLoginProps';
import ValidateLabelOfLocalLoginForm from 'components/small/label/ValidateLabelOfLocalLoginForm';

const LocalLogin: React.FC<LocalLoginProps> = ({ connectionViewModel }) => {
    const { displaySlidingPage } = useSlidingPages();
    const {
        submitAuthentificationForm,
        verifyEmail,
        verifyPassword,
        handleCheckboxChange,
        btnIsDisabled,
        validationResults
    } = useAuthentificationForm({ connectionViewModel });

    return (
        <div className="loginregi_form">
            <form key={"LocalLogin"} onSubmit={(e) => submitAuthentificationForm(e, { email: e.currentTarget.email.value, password: e.currentTarget.password.value })}>
                <div className="fields-container">
                    <div className="field-container custinput_group m-0">
                        <input
                            name={'email'}
                            required
                            type="text"
                            className='register-input-field authentification-form'
                            onChange={({ target: { value } }) => verifyEmail(value)}
                            placeholder={i18next.t(`${connectionViewModel.Mode.toLowerCase()}.placeholder.email`)} />
                        {connectionViewModel.IsRegister && <ValidateLabelOfLocalLoginForm validationResults={validationResults} field="email" />}
                    </div>
                    <div className="field-container custinput_group">
                        <input
                            name={'password'}
                            required
                            type="password"
                            className='register-input-field authentification-form'
                            onChange={({ target: { value } }) => verifyPassword(value)}
                            placeholder={i18next.t(`${connectionViewModel.Mode.toLowerCase()}.placeholder.password`)} />
                        {connectionViewModel.IsRegister && <ValidateLabelOfLocalLoginForm validationResults={validationResults} field="password" />}
                        {connectionViewModel.IsLogin && (
                            <p onClick={() => displaySlidingPage(<ForgotPasswordFormRedux />)}
                                className="forgot-password">
                                <span className='hover'>{i18next.t('login.forgetpassword')}</span>
                            </p>
                        )}
                    </div>
                </div>
                {connectionViewModel.IsRegister && (
                    <div className="checkbox-container flex">
                        <label className='label-checkbox checkbox-send-email'>
                            <input
                                name={'checkbox'}
                                type="checkbox"
                                onChange={({ target: { checked } }) => handleCheckboxChange(checked)}
                                className='checkbox checkbox-send-email'
                            />
                            {i18next.t('email.notification.disclaimer')}
                        </label>
                    </div>
                )}
                <div className="container-send-login">
                    <PrimaryButton
                        style={{ fontSize: "15px", fontWeight: "bold", height: "50px", borderRadius: "11px" }}
                        receiveClickEvent={false}
                        disabled={btnIsDisabled()}
                        textButton={connectionViewModel.IsLogin ? i18next.t('login.btntext') : i18next.t('register.registerbtn')}
                        type="submit" />
                </div>
            </form>
        </div>
    );
};

export default LocalLogin;
