import React from 'react';
import IWalletCardProps from './IWalletCardProps';

const WalletCard: React.FC<IWalletCardProps> = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28">
            <g id="Groupe_4294" data-name="Groupe 4294" transform="translate(-39.724 -246)">
                <ellipse id="Ellipse_11" data-name="Ellipse 11" cx="13.5" cy="14" rx="13.5" ry="14" transform="translate(39.724 246)" opacity="0" />
                <rect id="Rectangle_1926" data-name="Rectangle 1926" width="16" height="19" rx="3" transform="translate(42.724 249)" />
                <path id="Soustraction_18" data-name="Soustraction 18" d="M-1936.864,808.959a3.019,3.019,0,0,1-.678-.078l-3.816-.881h1.08a5.006,5.006,0,0,0,5-5V790c0-.032,0-.064,0-.1l2.01.464a2.98,2.98,0,0,1,1.869,1.333,2.981,2.981,0,0,1,.379,2.265l-2.924,12.667A2.985,2.985,0,0,1-1936.864,808.959Zm-3.413-2.958h-8.27a2.989,2.989,0,0,1-.987-2.966l2.924-12.667a2.985,2.985,0,0,1,2.921-2.326,3.017,3.017,0,0,1,.677.077l5.681,1.312a3.025,3.025,0,0,1,.054.57v13A3,3,0,0,1-1940.277,806Z" transform="translate(1996 -538)" />
            </g>
        </svg>
    );
};

export default WalletCard;
