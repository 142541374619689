import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import { createSelector, Selector } from 'reselect';
import { TRANSACTION_SOURCE, TRANSACTIONS_STATUS } from 'config/_const';
import AssetInterface from 'model/AssetModel/AssetInterface';
import IWithdraw from 'model/IWithdraw';
import { RootState } from 'model/Redux';
import TransactionInterface, { InternalTransaction } from 'model/User/TransactionInterface';
import { assetWalletSelector } from 'service/wallet/selector';
import TransactionViewModel from 'view-model/TransactionViewModel/TransactionViewModel';
import { IAssetInAssetTransaction } from 'model/AssetTransactions/IAssetTransaction';

export interface IAllTransaction {
    type: ALL_TRANSACTIONS_TYPES;
    createdAt: 'string';
    fiat?: number;
    source?: string;
    status?: string;
    rejectionMessage?: string;
    creditsToWithdraw?: number;
    creditOrDebit?: string;
    currency: string;
    _id: string;
    asset: IAssetInAssetTransaction
}

export enum ALL_TRANSACTIONS_TYPES {
    PAYMENT = 'PAYMENT',
    WITHDRAW = 'WITHDRAW',
    FEE = 'FEE',
}

const keys: string[] = ['createdAt', 'fiat', 'source', 'status', 'rejectionMessage', 'creditsToWithdraw', 'type', 'currency', '_id', 'asset'];

// TRANSFORMERS
export const transformDataForAllTransactions = (transactions: any[], transactionsObjectKeys: string[], type: ALL_TRANSACTIONS_TYPES): any[] => transactions.map((el) => {
    const transactionTransformed = {
        type,
    };
    for (const [key, value] of Object.entries(el)) {
        if (!transactionsObjectKeys.includes(key))
            continue;
        if (key === 'type') {
            Object.defineProperty(transactionTransformed, 'creditOrDebit', {
                value,
                writable: false,
            });
        } else {
            Object.defineProperty(transactionTransformed, key, {
                value,
                writable: false,
            });
        }
    }
    return transactionTransformed;
});

// SORTERS
export const sortAllTransactionsByDecDate = (allTransactions: any[]) => allTransactions.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
export const transactionsDataSelector: Selector<RootState, TransactionInterface[]> = state => state.transactions.data;
export const withdrawsDataSelector: Selector<RootState, IWithdraw[]> = state => state.withdraws.data;

const getAssetIdFromExternalReference = (externalReference: string): string => externalReference?.split(';')[1] ?? '';

export const allTransactionsSelector = createSelector(
    transactionsDataSelector,
    withdrawsDataSelector,
    assetWalletSelector,
    (transactions: TransactionInterface[], withdraws: IWithdraw[], userWallet: AssetWalletInterface[]) => {
        const filteredTransactions: InternalTransaction[] = transactions
            .filter(transaction => transaction.status !== TRANSACTIONS_STATUS.PENDING)
            .map((transaction: TransactionInterface) => {
                if (transaction.source !== TRANSACTION_SOURCE.YIELD)
                    return new InternalTransaction(transaction);
                const assetId: string = getAssetIdFromExternalReference(transaction.externalReference ?? '');
                const assetWallet: AssetWalletInterface | undefined = userWallet.find((wallet) => wallet.asset._id === assetId);
                if (!assetWallet)
                    return new InternalTransaction(transaction);
                const asset: AssetInterface = assetWallet.asset;
                return new InternalTransaction({
                    ...transaction,
                    asset: {
                        icon: asset.icon,
                        name: asset.name,
                        id: asset._id,
                        mainCategory: asset.mainCategory,
                        cardLevel: asset.cardLevel
                    }
                })
            });

        const allTransactions = transformDataForAllTransactions(filteredTransactions, keys, ALL_TRANSACTIONS_TYPES.PAYMENT).concat(transformDataForAllTransactions(withdraws, keys, ALL_TRANSACTIONS_TYPES.WITHDRAW));
        return sortAllTransactionsByDecDate(allTransactions);
    },
);

export const transactionsAsViewModelSelector = createSelector(
    transactionsDataSelector,
    (transactions: TransactionInterface[]) => transactions.map((transaction: TransactionInterface) => new TransactionViewModel({
        id: transaction._id,
        paid: transaction.fiat,
        source: transaction.source,
        transactionDate: transaction.createdAt.toDate(),
        type: transaction.type,
        status: transaction.status,
        action: transaction.type,
    }))
)
