import LegendsCard from 'assets/images/cards-icons/legends.png';
import ChampionCard from 'assets/images/cards-icons/champions.png';
import ContendersCard from 'assets/images/cards-icons/contenders.png';
import { TournamentLeagues } from 'config/_enums';
import { EmptyString } from 'config/_const';

const LeagueCards: Record<TournamentLeagues, string> = {
    [TournamentLeagues.Legends]: LegendsCard,
    [TournamentLeagues.Champions]: ChampionCard,
    [TournamentLeagues.Contenders]: ContendersCard,
    [TournamentLeagues.Believers]: EmptyString,
    [TournamentLeagues.Managers]: EmptyString,
    [TournamentLeagues.Rookies]: EmptyString,
};

export default LeagueCards