import { TournamentProgress } from 'config/_enums';
import AxiosConfig from 'config/axiosConfig';
import { TOURNAMENT_ENDPOINTS } from 'config/endpoints';
import { HTTP_STATUS_OK } from 'config/_const';
import type { IHttpStrongTypedResponse } from 'model/IHttpResponse';
import type { RootState } from 'model/Redux';
import type ITournament from 'model/Tournament/ITournament';
import type { ITournamentCreationBody } from 'model/Tournament/ITournamentCreation';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import * as TournamentActionType from './actionsType';
import type { ResetStatusAction } from './types';

export const getNextTournament = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TournamentActionType.TOURNAMENT.LOADING });
        const getPrizePoolResponse: IHttpStrongTypedResponse<ITournament> = await AxiosConfig.get(TOURNAMENT_ENDPOINTS.NEXT_TOURNAMENT);
        if (getPrizePoolResponse.data.status !== HTTP_STATUS_OK)
            throw new Error(getPrizePoolResponse.data.message);
        return dispatch({
            type: TournamentActionType.TOURNAMENT.SUCCESS,
            payload: {
                data: [{ ...getPrizePoolResponse.data.data, tournamentProgress: TournamentProgress.NEXT }],
            },
        });
    } catch (error) {
        let message = 'Server.users.validateAccount.generic-error';
        if (error instanceof TypeError)
            message = error.message;
        return dispatch({
            type: TournamentActionType.TOURNAMENT.ERROR,
            payload: { message },
        });
    }
};

export const getCurrentTournament = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TournamentActionType.GET_CURRENT_TOURNAMENT.LOADING });
        const response: IHttpStrongTypedResponse<ITournament> = await AxiosConfig.get(TOURNAMENT_ENDPOINTS.GET_CURRENT_TOURNAMENT);
        if (response.data.status !== HTTP_STATUS_OK)
            throw new Error(response.data.message);
        return dispatch({
            type: TournamentActionType.GET_CURRENT_TOURNAMENT.SUCCESS,
            payload: {
                data: [{ ...response.data.data, tournamentProgress: TournamentProgress.IN_PROGRESS }],
            },
        });
    } catch (error) {
        let message = 'Server.tournament.generic-error';
        if (error instanceof TypeError)
            message = error.message;
        return dispatch({
            type: TournamentActionType.GET_CURRENT_TOURNAMENT.ERROR,
            payload: { message },
        });
    }
};

export const postTournament = (args: ITournamentCreationBody): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TournamentActionType.POST_TOURNAMENT.LOADING });
        const response: IHttpStrongTypedResponse<ITournament> = await AxiosConfig.post(TOURNAMENT_ENDPOINTS.POST_TOURNAMENT, args);
        if (response.data.status !== HTTP_STATUS_OK)
            throw new Error(response.data.message);
        return dispatch({
            type: TournamentActionType.POST_TOURNAMENT.SUCCESS,
            payload: {
                data: [response.data.data],
            },
        });
    } catch (error) {
        let message = 'Server.tournament.generic-error';
        if (error instanceof TypeError)
            message = error.message;
        return dispatch({
            type: TournamentActionType.POST_TOURNAMENT.ERROR,
            payload: { message },
        });
    }
};

export const resetStatus = (actionName: string) => (dispatch: ThunkDispatch<RootState, null, ResetStatusAction>) => {
    dispatch({
        type: TournamentActionType.RESET_STATUS,
        payload: { actionName },
    });
};

export const getPreviousTournament = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TournamentActionType.GET_PREVIOUS_TOURNAMENT.LOADING });
        const getPreviousTournamentResponse: IHttpStrongTypedResponse<ITournament> = await AxiosConfig.get(TOURNAMENT_ENDPOINTS.PREVIOUS_TOURNAMENT);
        if (getPreviousTournamentResponse.data.status !== HTTP_STATUS_OK)
            throw new Error(getPreviousTournamentResponse.data.message);
        return dispatch({
            type: TournamentActionType.GET_PREVIOUS_TOURNAMENT.SUCCESS,
            payload: {
                data: [{ ...getPreviousTournamentResponse.data.data, tournamentProgress: TournamentProgress.PREVIOUS }],
            },
        });
    } catch (error) {
        let message = 'Server.users.validateAccount.generic-error';
        if (error instanceof TypeError)
            message = error.message;
        return dispatch({
            type: TournamentActionType.GET_PREVIOUS_TOURNAMENT.ERROR,
            payload: { message },
        });
    }
};
