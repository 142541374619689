import { Button, ButtonClassKey } from '@material-ui/core';
import React, { MouseEvent } from 'react';
import { IOverrideButtonProps } from '../IButtonProps';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import './style.scss';
import HandleClick from './ClickHandler';
import clsx from 'clsx';

const WhiteButtonWithBorder: React.FC<IOverrideButtonProps> = (props) => {

    const classes: Partial<ClassNameMap<ButtonClassKey>> = {
        root: 'white-button-border',
    };

    return <Button
        classes={classes}
        className={clsx("classes",
            {[`${props.className}`]: Boolean(props.className)}
        )}
        onClick={(event: MouseEvent<HTMLButtonElement>) => HandleClick(event, props.debounceEvent, props.preventDefaultEvent, props.handleClick)}
        variant="contained"
        type={props.type}
        startIcon={props.startIcon}>{props.textButton}</Button>;
};

export default React.memo(WhiteButtonWithBorder);
