import { useSelector } from 'react-redux';
import AssetWalletInterface from '../../model/AssetModel/AssetWalletInterface';
import { walletAndTeamAssetSelector } from '../../service/wallet/selector';

const useAssetsWithOwnedAndTeamAssetsShares = (): AssetWalletInterface[] => {
    const assetsWithOwnedAndTeamAssetsShares: AssetWalletInterface[] = useSelector(walletAndTeamAssetSelector);
    return assetsWithOwnedAndTeamAssetsShares;
};

export default useAssetsWithOwnedAndTeamAssetsShares;
