import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import ITeam from '../../model/Team/ITeam';
import Utils from '../../utils/Utils';
import * as TeamsActionTypes from './actionTypes';

const ROOT_VALUE_STATE: ReduxRootState<ITeam[]> = {
    loading: false,
    data: [],
};

const onTeamSuccess = (state: ReduxRootState<ITeam[]>, payload: ITeam[]) => {
    if (state.data.isEqualTo(payload)) {
        return {
            ...state,
            loading: false,
        };
    }

    for (const team of payload) {
        if (typeof team.owner === 'string') {
            team.owner = { _id: team.owner };
        }
    }

    const stateData: ITeam[] = Utils.removedDuplicateMongoFile(state.data, payload);
    return {
        ...state,
        loading: false,
        data: stateData.concat(payload),
    };
};

interface IUpdateTeamStatusReducer {
    status: string;
    teamId: string;
}
const updateTeamStatus = (state: ReduxRootState<ITeam[]>, payload: IUpdateTeamStatusReducer): ReduxRootState<ITeam[]> => {
    const teamsFromStore: ITeam[] = [...state.data];
    const teamUpdateIndex: number = teamsFromStore.findIndex((team: ITeam) => team._id === payload.teamId);
    if (teamUpdateIndex === -1)
        return state;

    teamsFromStore[teamUpdateIndex].status = payload.status
    return {
        ...state,
        loading: false,
        data: teamsFromStore,
    }
}

export const teamsReducer = (state: ReduxRootState<ITeam[]> = ROOT_VALUE_STATE, action: ReduxActionInterface<any>): ReduxRootState<ITeam[]> => {
    switch (action.type) {
        case TeamsActionTypes.TEAMS_LOADING:
            return {
                ...state,
                loading: true,
            };

        case TeamsActionTypes.TEAMS_ERROR:
            return {
                ...state,
                loading: false,
            };

        case TeamsActionTypes.TEAMS_SUCCESS:
            return onTeamSuccess(state, action.payload);
        case TeamsActionTypes.TEAMS_UPDATE_STATUS:
            return updateTeamStatus(state, action.payload);
        case TeamsActionTypes.RESET_STATE:
            return ROOT_VALUE_STATE;
        default: return state;
    }
};
