export const AUTH_SUCCESS: string = 'AUTH_SUCCESS';
export const AUTH_FAILED: string = 'AUTH_FAILED';
export const AUTH_LOADING: string = 'AUTH_LOADING';
export const AUTH_LOGOUT: string = 'AUTH_LOGOUT';
export const CHANGE_ASSET: string = 'CHANGE_ASSET';
export const UPLOAD_PROFILE_PICTURE_LOADING: string = 'UPLOAD_PROFILE_PICTURE_LOADING';
export const UPLOAD_PROFILE_PICTURE_SUCCESS: string = 'UPLOAD_PROFILE_PICTURE_SUCCESS';
export const UPLOAD_PROFILE_PICTURE_FAILED: string = 'UPLOAD_PROFILE_PICTURE_FAILED';
export const UPLOAD_IDENTITY_LOADING: string = 'UPLOAD_IDENTITY_LOADING';
export const UPLOAD_IDENTITY_SUCCESS: string = 'UPLOAD_IDENTITY_SUCCESS';
export const UPLOAD_IDENTITY_FAILED: string = 'UPLOAD_IDENTITY_FAILED';
export const UPDATE_AUTH_KEY: string = 'UPDATE_AUTH_KEY';
export const SUBSCRIBE_TO_ASSET_START: string = 'SUBSCRIBE_TO_ASSET_START';
export const SUBSCRIBE_TO_ASSET_SUCCESS: string = 'SUBSCRIBE_TO_ASSET_SUCCESS';
export const SUBSCRIBE_TO_ASSET_FAILED: string = 'SUBSCRIBE_TO_ASSET_FAILED';
export const UPDATE_USER_CGU_LOADING: string = 'UPDATE_USER_CGU_LOADING';
export const UPDATE_USER_CGU_SUCCESS: string = 'UPDATE_USER_CGU_SUCCESS';
export const UPDATE_USER_CGU_FAILED: string = 'UPDATE_USER_CGU_FAILED';
export const GET_CREDITS_SUCCESS: string = 'GET_CREDITS_SUCCESS';
export const GET_CREDITS_ERROR: string = 'GET_CREDITS_ERROR';
export const UPDATE_USER_REFERRAL_TERMS_LOADING: string = 'UPDATE_USER_REFERRAL_TERMS_LOADING';
export const UPDATE_USER_REFERRAL_TERMS_SUCCESS: string = 'UPDATE_USER_REFERRAL_TERMS_SUCCESS';
export const UPDATE_USER_REFERRAL_TERMS_FAILED: string = 'UPDATE_USER_REFERRAL_TERMS_FAILED';
export const RESET_PASSWORD_LOADING: string = 'RESET_PASSWORD_LOADING';
export const RESET_PASSWORD_SUCCESS: string = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED: string = 'RESET_PASSWORD_FAILED';
export const ADD_ASSET_TO_ASSETWALLET: string = 'ADD_ASSET_TO_ASSETWALLET';
export const REWARD_SUCCESS: string = 'REWARD_SUCCESS';
export const REWARD_FAILED: string = 'REWARD_FAILED';
export const REWARD_LOADING: string = 'REWARD_LOADING';
export const HANDLE_CLAIMABLE_REWARD_LOADING: string = 'HANDLE_CLAIMABLE_REWARD_LOADING';
export const HANDLE_CLAIMABLE_REWARD_ERROR: string = 'HANDLE_CLAIMABLE_REWARD_ERROR';
export const HANDLE_CLAIMABLE_REWARD_SUCCESS: string = 'HANDLE_CLAIMABLE_REWARD_SUCCESS';
export const REWARD_REGISTER: string = 'REWARD_REGISTER';
export const UPDATE_USER_SUCCESS: string = 'UPDATE_USER_SUCCESS';
export const UPDATE_CREDITS_AFTER_BUY: string = 'UPDATE_CREDITS_AFTER_BUY';
export const WHITELIST_USER_LOADING: string = 'WHITELIST_USER_LOADING';
export const WHITELIST_USER_SUCCESS: string = 'WHITELIST_USER_SUCCESS';
export const WHITELIST_USER_ERROR: string = 'WHITELIST_USER_ERROR';
// #region User personal tournament
export const PERSONAL_TOURNAMENT_SUBSCRIBE_LOADING: string = 'PERSONAL_TOURNAMENT_SUBSCRIBE_LOADING';
export const PERSONAL_TOURNAMENT_SUBSCRIBE_SUCCESS: string = 'PERSONAL_TOURNAMENT_SUBSCRIBE_SUCCESS';
export const PERSONAL_TOURNAMENT_SUBSCRIBE_ERROR: string = 'PERSONAL_TOURNAMENT_SUBSCRIBE_ERROR';

export const PERSONAL_TOURNAMENT_CREATE_LOADING: string = 'PERSONAL_TOURNAMENT_CREATE_LOADING';
export const PERSONAL_TOURNAMENT_CREATE_SUCCESS: string = 'PERSONAL_TOURNAMENT_CREATE_SUCCESS';
export const PERSONAL_TOURNAMENT_CREATE_ERROR: string = 'PERSONAL_TOURNAMENT_CREATE_ERROR';

export const GET_PERSONAL_TOURNAMENT_LOADING: string = 'GET_PERSONAL_TOURNAMENT_LOADING';
export const GET_PERSONAL_TOURNAMENT_SUCCESS: string = 'GET_PERSONAL_TOURNAMENT_SUCCESS';
export const GET_PERSONAL_TOURNAMENT_ERROR: string = 'GET_PERSONAL_TOURNAMENT_ERROR';
// #endregion
export const UPDATE_VERIFICATION_IDENTITY: string = "UPDATE_VERIFICATION_IDENTITY";
export const UPDATE_ONBOARDING_STEP: string = "UPDATE_ONBOARDING_STEP";
export const FINISH_ONBOARDING_STEP: string = "FINISH_ONBOARDING_STEP";
export const LAUNCH_ONBOARDING: string = "LAUNCH_ONBOARDING";

export const POLLING_KYC_STATUS_SUCCESS: string = 'POLLING_KYC_STATUS_SUCCESS';
export const POLLING_KYC_STATUS_ERROR: string = 'POLLING_KYC_STATUS_ERROR';