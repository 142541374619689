import WrapperSelect from 'components/small/wrapper-select/WrapperSelect';
import { WRAPPER_SELECT_TYPE } from 'config/_const';
import * as React from 'react';
import useSportsPlayers from 'hooks/sports/use-sports-players/useSportsPlayers';
import ISelectSportsTeamsField from './ISelectSportsTeamField';

const SelectSportsTeamsField: React.FC<ISelectSportsTeamsField> = (fieldProps) => {
  const { fetchSportsPlayersByTeamId } = useSportsPlayers();

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const currentSelectedValue: number = parseInt(event.target.value);
    const isTargetSelected = Boolean(fieldProps.sportsTeamsList.find(team => team.id === currentSelectedValue));
    if (isTargetSelected) {
      fetchSportsPlayersByTeamId(currentSelectedValue);
    }

    fieldProps.input.onChange(event);
  }

  return (
    <WrapperSelect
      valueChange={handleSelectChange}
      propsReduxForm={fieldProps}
      customValue=""
      data={fieldProps.options}
      type={WRAPPER_SELECT_TYPE.NATIVE}
    />
  )
};

export default React.memo(SelectSportsTeamsField);
