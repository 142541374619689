import useAssetSlideView from "hooks/asset/use-asset-slide-view/useAssetSlideView";
import useAccount from "hooks/use-account/useAccount";
import IUseWallet from "hooks/wallet/use-wallet/IUseWallet";
import useWallet from "hooks/wallet/use-wallet/useWallet";
import i18n from '18n';
import useModalDisplay from "hooks/use-modal-display/useModalDisplay";
import { IOnSellAssetClick } from "./IUseAssetBuySellClick";
import AssetViewModel from "view-model/Assets/AssetViewModel";

const useAssetBuySellClick = () => {
    const { accountViewModel } = useAccount();
    const { onAssetProfileClick } = useAssetSlideView();
    const { walletViewModel }: IUseWallet = useWallet({ considerTeamShares: false });
    const { showShortMessageInModal, showToVerifyProfileInModal, showAssetSellInitiator, showEmailVerificationText, showAssetDisclaimer } = useModalDisplay();

    const onSellAssetClick: IOnSellAssetClick = (assetSlug, canOpenAssetProfile, openIdentityModal, callBackFxnAfterClick) => {
        const asset = walletViewModel.getAssetBySlug(assetSlug);
        if (!asset) return;
        const assetViewModel: AssetViewModel = new AssetViewModel(asset);
        if (assetViewModel.IsFunCategory) {
            canOpenAssetProfile
                ? onAssetProfileClick(assetViewModel, () => showAssetSellInitiator(asset))
                : showAssetSellInitiator(asset);
        } else {
            if (accountViewModel.IsOnboardingRunning) {
                const asset = walletViewModel.getAssetBySlug(assetSlug);
                if (!asset) return;
                showAssetSellInitiator(asset, accountViewModel.IsOnboardingRunning, !accountViewModel.IsOnboardingRunning);
                return;
            }
            if (accountViewModel.isIdentityPending) showShortMessageInModal(`${i18n.t('Myportfolio.message.pending')}`);
            else if (!accountViewModel.isEmailValidated) showToVerifyProfileInModal(`${i18n.t('Myportfolio.message.to.confirm.user')}`, `${i18n.t('emailverify.sendbtn')}`, () => showEmailVerificationText(i18n.t("email.verification.text")));
            else if (accountViewModel.canAskForKyc) {
                openIdentityModal();
                return;
            }
            else {
                if (asset.marketConfiguration.secondaryMarket.warningBeforeSell) {
                    const message: string = assetViewModel.IsFootballEuro ? i18n.t('euros2024.asset.before.sell.disclaimer') : '';
                    showAssetDisclaimer(message, () => showAssetSellInitiator(asset));
                } else {
                    canOpenAssetProfile
                        ? onAssetProfileClick(assetViewModel, () => showAssetSellInitiator(asset))
                        : showAssetSellInitiator(asset);
                }
            }
        }

        callBackFxnAfterClick?.();
    }

    return {
        onSellAssetClick
    }
}

export default useAssetBuySellClick;
