import React from "react";
import { Redirect, Route } from "react-router-dom";

function VipProtectedRoute({ component: Component, isUserVip, ...restOfProps }: any) {
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isUserVip ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default VipProtectedRoute;