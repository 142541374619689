import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import i18next from 'i18next';
import AdminAssetRequestFormProps from './AdminAssetRequestFormProps';
import * as Input from '../../../../small/input/ReduxFormInput';
import PrimaryButton from '../../../../small/buttons/primary-button/PrimaryButton';
import IAdminAssetRequestFormInitialValues from './IAdminAssetRequestFormInitialValues';
import './AdminAssetRequestForm.scss';

const INITIAL_STATE_FORM: IAdminAssetRequestFormInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
};

const AdminAssetRequestForm: React.FC<InjectedFormProps<{}, AdminAssetRequestFormProps> & AdminAssetRequestFormProps> = (props) => {

    return (
        <div className="asset-request-form">
            <form onSubmit={props.handleSubmit(props.onInformationsSubmit)}>

                <div className="field-container">
                    <label className="form_label">{i18next.t('Aboutus.tab.about.firstname')}</label>
                    <Field
                        name={'firstName'}
                        type="text"
                        component={Input.generateInputText} />
                </div>

                <div className="field-container">
                    <label className="form_label">{i18next.t('Aboutus.tab.about.lastname')}</label>
                    <Field
                        name={'lastName'}
                        type="text"
                        component={Input.generateInputText} />
                </div>

                <div className="field-container">
                    <label className="form_label">{i18next.t('Aboutus.tab.about.email')}</label>
                    <Field
                        name={'email'}
                        type="email"
                        component={Input.generateInputText} />
                </div>

                <div className="field-container">
                    <label className="form_label">{i18next.t('Aboutus.tab.about.address')}</label>
                    <Field
                        name={'address'}
                        type="address"
                        component={Input.generateInputText} />
                </div>

                <div className="button-submit">

                    <PrimaryButton
                        disabled={false}
                        receiveClickEvent={false}
                        type="submit"
                        handleClick={() => undefined}
                        textButton={props.buttonText}
                    />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (_state: AdminAssetRequestFormProps, ownProps: AdminAssetRequestFormProps) => ({
    initialValues: ownProps.initialValues ? { ...INITIAL_STATE_FORM, ...ownProps.initialValues } : INITIAL_STATE_FORM,
});

const AdminAssetRequestFormRedux = reduxForm<{}, AdminAssetRequestFormProps>({
    form: 'AdminUserInformationForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    enableReinitialize: true,
})(AdminAssetRequestForm);

export default connect(mapStateToProps)(AdminAssetRequestFormRedux);
