import hiddenAsset from '../../assets/images/Assets/hidden-Asset/hidden-Asset-card.png';
import academyThumbnail from 'assets/images/Academy/academy-thumbnail.png'
import rewardTournamentGolden from 'assets/images/reward/rewards_tournament_golden.png'
import logo_N from 'assets/images/Trendex-logo/logo _N_round.png'

export const hiddenAssetCard = hiddenAsset;

export const academyVideoImg = academyThumbnail;

export const tdxLogoN = logo_N;

export const rewardTournamentImg = rewardTournamentGolden;

export const trendexLogoImage = process.env.PUBLIC_URL + '/img/trendex-logo-black.png';