import { useDispatch, useSelector } from 'react-redux';
import AssetInterface from 'model/AssetModel/AssetInterface';
import { assetsDataSelector, getAssetsLoadingSelector } from 'service/assets/selectors';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import IUseAllAssets from './IUseAllAssets';
import { fetchTopAssets, fetchTopAssetsScores } from '../../../service/assets/actions';
import { useMemo } from 'react';
import React from 'react';

const useAllAssets = (): IUseAllAssets => {
    const dispatch = useDispatch();
    const allAssetsSelector: AssetInterface[] = useSelector(assetsDataSelector);
    const allAssets: AssetViewModel[] = useMemo(() => {
        return allAssetsSelector.map((asset: AssetInterface) => new AssetViewModel(asset))
    }, [allAssetsSelector]);
    const isAssetsLoading: boolean = useSelector(getAssetsLoadingSelector);

    const getAssetById = (assetId: string): AssetInterface | undefined => {
        const assetIndex: number = allAssets.findIndex((asset: AssetViewModel) => asset.AssetId === assetId);
        if (assetIndex === -1)
            return;
        return allAssets[assetIndex].Asset;
    };

    const getAssetViewModelById = (assetId: string): AssetViewModel | undefined => {
        const assetViewModel: AssetViewModel | undefined = allAssets.find((asset: AssetViewModel) => asset.AssetId === assetId);
        return assetViewModel;
    };

    const getAssetsByIds = (assetIds: string[]): AssetViewModel[] => {
        return allAssets.filter((asset: AssetViewModel) => assetIds.includes(asset.AssetId));
    };

    const fetchAllAssets = () => {
        if (allAssets.length === 0) {
            dispatch(fetchTopAssets());
            return
        }
        dispatch(fetchTopAssetsScores())
        return;
    };

    const getAllAssets = React.useCallback(() => {
        return fetchTopAssets();
    }, [])

    return {
        allAssets,
        isAssetsLoading,
        getAssetById,
        getAssetsByIds,
        fetchAllAssets,
        getAssetViewModelById,
        getAllAssets
    };
};

export default useAllAssets;
