import * as React from 'react';

const RightFromBracket = ({ height = "17.837px", width = "20.385px", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg height={height} width={width} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.385 17.837">
            <path id="right-from-bracket-solid" d="M6.37,34.548A1.274,1.274,0,1,0,6.37,32H3.822A3.823,3.823,0,0,0,0,35.822V46.015a3.823,3.823,0,0,0,3.822,3.822H6.37a1.274,1.274,0,1,0,0-2.548H3.822a1.273,1.273,0,0,1-1.274-1.274V35.822a1.273,1.273,0,0,1,1.274-1.274Zm13.716,7.063a.953.953,0,0,0,0-1.386l-5.733-5.415a.956.956,0,0,0-1.612.693V38.37h-5.1A1.273,1.273,0,0,0,6.37,39.644v2.548a1.273,1.273,0,0,0,1.274,1.274h5.1v2.867a.956.956,0,0,0,1.612.693Z" transform="translate(0 -32)" fill="#15182b" opacity="0.8" />
        </svg>
    );
};

export default RightFromBracket;