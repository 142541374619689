import i18n from '18n';
import animationData from 'assets/animation-lotties/lf20_vfvxsus7.json';
import LeagueDisclaimer from 'components/medium/league-disclaimer/LeagueDisclaimer';
import GreenButtonWithGradient from 'components/small/buttons/green-button/GreenButtonWithGradient';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import PaidTournamentDisclaimer from 'components/small/paid-tournament-disclaimer/PaidTournamentDisclaimer';
import useTournamentRegistrationConfirmation from 'hooks/tournament/use-tournament-registration-confirmation/useTournamentRegistrationConfirmation';
import IUseDialog from 'hooks/use-dialog/IUseDialog';
import useDialog from 'hooks/use-dialog/useDialog';
import React from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import { totalTokensAccountSelector } from 'service/wallet/selector';
import Utils from 'utils/Utils';
import ITournamentSubscriptionConfirmationModalProps from './ITournamentSubscriptionConfirmationModalProps';
import './style.scss';

const SubscribeConfirmationModal: React.FC<ITournamentSubscriptionConfirmationModalProps> = (props: ITournamentSubscriptionConfirmationModalProps): JSX.Element => {
    const { closeDialog }: IUseDialog = useDialog();
    const totalTokensOfUser: number = useSelector(totalTokensAccountSelector);
    const { subscribe } = useTournamentRegistrationConfirmation({ userTeamId: props.teamId, nextTournament: props.nextTournament });

    return <div className="confirm-team-subscribe-to-tournament">
        <div className="container-animation-ready">
            <Lottie
                options={{
                    loop: false,
                    autoplay: true,
                    animationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                    },
                }}
                height={'95%'}
                width={Utils.isMobile() ? '55%' : '30%'}
            />
        </div>
        <h2 className='small-title'>{i18n.t('congratulation.teamCompleted')}</h2>

        {props.nextTournament.HasEntryFee && <PaidTournamentDisclaimer />}
        {!props.nextTournament.HasEntryFee && <LeagueDisclaimer userWalletShares={totalTokensOfUser} tournament={props.nextTournament} />}

        <p className="confirm-team-text">
            {i18n.t('readyToParticipate')}
        </p>
        <div className="buttons-subscribe-tournament">
            <PrimaryButton type="button"
                disabled={false}
                textButton={i18n.t('subscribe.no')}
                handleClick={() => closeDialog()}
                receiveClickEvent={false} />
            <GreenButtonWithGradient
                type="button"
                disabled={false}
                textButton={i18n.t('subscribe.yes')}
                handleClick={subscribe}
                receiveClickEvent={false} />
        </div>
    </div>
};

export default SubscribeConfirmationModal;
