import ILiquidityPoolParams from "interfaces/liquidity-pool/ILiquidityPoolParams";
import IAssetCurveConfiguration from "model/AssetModel/IAssetCurveConfiguration";

abstract class LiquidityPool {
    private tokenBalance: number;
    private totalBalance: number;
    private curveBalance: number;
    private initialTokens: number;
    private assetCurveConfiguration: IAssetCurveConfiguration;

    constructor(liquidityPool: ILiquidityPoolParams) {
        this.tokenBalance = liquidityPool.liquidityPoolSupply;
        this.totalBalance = liquidityPool.liquidityPoolValue;
        this.initialTokens = liquidityPool.initialTokens;
        this.curveBalance = this.initialTokens - this.tokenBalance;
        this.assetCurveConfiguration = liquidityPool.assetCurveConfiguration;
    }

    abstract getBuyPrice(_tokens: number): number;
    abstract getSellPrice(_tokens: number): number;
    abstract getMaxTokenWithAvailableCredit(_credit: number): number;

    public get TokenBalance(): number {
        return this.tokenBalance;
    }

    public get TotalBalance(): number {
        return this.totalBalance;
    }

    public get CurveBalance(): number {
        return this.curveBalance;
    }

    public get InitialTokens(): number {
        return this.initialTokens;
    }

    public get AssetCurveConfiguration(): IAssetCurveConfiguration {
        return this.assetCurveConfiguration;
    }
}

export default LiquidityPool;