import i18n from '18n';
import bankImg from 'assets/images/wallet-transactions/bank_icon.png';
import { WITHDRAWS_TYPES, WITHDRAW_TIME_PERIOD } from 'config/_const';
import IWithdrawalRequest from 'service/user-withdrawal-requests/IWithdrawalRequest';
import PendingCardViewModel from 'view-model/PendingCardViewModel/PendingCardViewModel';

export default class WalletWithdrawalViewModel extends PendingCardViewModel {
    private _withdraw: IWithdrawalRequest;
    private _createdAt: Date;

    constructor(withdraw: IWithdrawalRequest) {
        super();
        this._withdraw = withdraw;
        this._createdAt = new Date(withdraw.createdAt);
    }

    public get Id(): string {
        return this._withdraw._id;
    }

    public get Type(): string {
        return this._withdraw.type;
    }

    public get Status(): string {
        return this._withdraw.status;
    }

    public get Currency(): string {
        return this._withdraw.currency;
    }

    public get CreditsToWithdraw(): number {
        return this._withdraw.creditsToWithdraw;
    }

    public get CreditsToWithdrawFormatted(): string {
        return this.CreditsToWithdraw.toCurrency();
    }

    public get Fees(): number {
        return this._withdraw.fees ?? 0;
    }

    public get FeesFormatted(): string {
        return this.Fees.toCurrency();
    }

    public get FeeRate(): number {
        return this._withdraw.feesRate;
    }

    public get DateInDDMMYYY(): string {
        return this._createdAt.formatDDMMYY();
    }

    public get TimeInHHMMSS(): string {
        return this._createdAt.toTimeString().split(' ')[0];
    }

    public get CardImage(): string {
        return bankImg;
    }

    public get CardTopic(): string {
        return i18n.t('pending.withdraw.topic');
    }
    public get CardMsg(): string {
        return i18n.t('pending.withdraw.message', { withdrawAmount: this.CreditsToWithdrawFormatted });
    }

    public get CardCancelText(): string | undefined {
        return i18n.t('pending.withdraw.cancel');
    }

    public get CardSummaryOne(): string {
        return i18n.t('pending.withdraw.type', { withdrawType: (this.Type === WITHDRAWS_TYPES.NON_SEPA ? 'NOSEPA' : this.Type) });
    }

    public get CardSummaryTwo(): string {
        return i18n.t('pending.withdraw.fee', { withdrawFee: this.FeesFormatted });
    }

    public get CardSummaryThree(): string {
        return i18n.t('pending.withdraw.period', { withdrawPeriod: WITHDRAW_TIME_PERIOD });
    }
}

