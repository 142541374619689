import ITopMenuWidgetProps from 'components/small/top-bar/core/TopMenuWidget/ITopMenuWidgetProps';
import TopMenuWidgetLoading from 'components/small/top-bar/core/TopMenuWidget/TopMenuWidgetLoading';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import IUseWallet from 'hooks/wallet/use-wallet/IUseWallet';
import useWallet from 'hooks/wallet/use-wallet/useWallet';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPortfolioTotalValue } from 'service/wallet/selector';
import IWithTopMenuWidgetProps from './IWithTopMenuWidgetProps';

const withTopMenuWidget = (Component: React.FC<ITopMenuWidgetProps>) => {
    const MemoComponent = React.memo(Component);

    const Wrapper: React.FC<IWithTopMenuWidgetProps> = ({ color }) => {
        const portfolioTotalValue: number = useSelector(selectPortfolioTotalValue);
        const { walletViewModel }: IUseWallet = useWallet({ considerTeamShares: false });
        const { accountViewModel }: IUseAccount = useAccount();

        if (walletViewModel.walletLoading)
            return <TopMenuWidgetLoading />;
        return <MemoComponent
            walletViewModel={walletViewModel}
            accountViewModel={accountViewModel}
            portfolioTotalValue={portfolioTotalValue}
            color={color} />;
    };

    return React.memo(Wrapper);
};

export default withTopMenuWidget;
