import { Divider } from '@material-ui/core'
import AssetSportScore from 'components/medium/asset-sport-score/AssetSportScore'
import { CATEGORY_CAN_SHOW_STATISTICS } from 'config/_const'
import React from 'react'
import './AssetEvolutions.scss'
import IAssetEvolutionsProps from './AssetEvolutionsProps'
import AssetTrendScoreEvolution from './AssetTrendScoreEvolution/AssetTrendScoreEvolution'

export const AssetEvolutions: React.FC<IAssetEvolutionsProps> = ({ asset }) => {
    return (
        <div className='asset-evolutions'>
            {CATEGORY_CAN_SHOW_STATISTICS.includes(asset.MainCategoryId) && asset.SportPlayer.id &&
                <>
                    <AssetSportScore assetVM={asset} />
                    <Divider />
                </>
            }
            {asset.ShowTrendScore && <AssetTrendScoreEvolution asset={asset} />}
            {asset.ShowTrendScore && <Divider />}
        </div>
    )
}

export default React.memo(AssetEvolutions)