import i18n from '18n';
import Application from "Application";
import ShareProfileReferral from "components/small/share-profile-referral/ShareProfileReferral";
import SuccessBuyAssetSharePage from "components/small/successBuyAssetSharePage/SuccessBuyAssetSharePage";
import { LOCAL_STORAGE_KNOWN_KEYS, ONE, SUPER_TALENT, TRENDEX_HTTP_STATUS_NOK } from "config/_const";
import { AssetTransactionType, BitPayInitiationPages, MarketEnum, PaymentMethodsEnum } from "config/_enums";
import useBuyByCredits from "hooks/payment/useBuyByCredits";
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import useAssetTransactionSlides from "hooks/use-asset-transaction-slideup/useAssetTransactionSlides";
import useCreditByBitpay from "hooks/use-credit-by-bitpay/useCreditByBitpay";
import useNotification from 'hooks/useNotification';
import useWallet from "hooks/wallet/use-wallet/useWallet";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dropDataOnGetBestSellingOrder } from "service/direct-buy-from-best-selling-orders/action";
import CustomFacebookCampaign from 'service/facebook-pixel/CustomFacebookCampaign';
import PixelHandlerBuyOnPrimaryMarket from 'service/facebook-pixel/PixelHandlerBuyOnPrimaryMarket';
import PixelHandlerBuyOnSecondaryMarket from 'service/facebook-pixel/PixelHandlerBuyOnSecondaryMarket';
import { managementModal } from "service/modal/action";
import { createSellOrder } from "service/order/actions";
import IMarketPixelHandler from 'service/pixel/IMarketPixelHandler';
import IPixelHandler from 'service/pixel/IPixelHandler';
import { SegmentTracking } from "service/segment/SegmentTracking";
import PixelHandlerSnapPurchase from 'service/snapchat-pixel/PixelHandlerSnapPurchase';
import CustomGoogleTagManager from 'service/tag-manager/CustomGoogleTagManager';
import { TagManagerWrapper } from "service/tag-manager/TagManagerWrapper";
import Utils from "utils/Utils";
import AccountViewModel from "view-model/AccountViewModel/AccountViewModel";
import IAssetTransactionViewModel from "view-model/AssetTransactionViewModel/IAssetTransactionViewModel";
import BuyAssetViewModel from "view-model/BuyAssetViewModel/BuyAssetViewModel";
import SellAssetViewModel from "view-model/SellAssetViewModel/SellAssetViewModel";

const useAssetTransactionConfirmation = (assetTransactionViewModel: IAssetTransactionViewModel, selectedPaymentType: PaymentMethodsEnum) => {
    const { closeAssetTransaction } = useAssetTransactionSlides();
    const { simulateBuy: simulateBuyInWallet, simulateSell: simulateSellInWallet } = useWallet({ considerTeamShares: false });
    const { buyOnPrimaryMarket, directBuyFromBestSellingOrders } = useBuyByCredits({ showErrorsOnSecondaryMarketPaymentError: false });
    const [isTransactionLoading, setTransactionLoading] = useState<boolean>(false);
    const { creditWithBitpay, transactionLoading: bitPayTransactionLoading } = useCreditByBitpay(BitPayInitiationPages.BUY_ASSET);
    const dispatch = useDispatch();
    const { accountViewModel, refreshUser, updateOnboardingStep, refreshUserCredits }: IUseAccount = useAccount();
    const { managementNotification } = useNotification();

    useEffect(() => {
        setTransactionLoading(bitPayTransactionLoading);
    }, [bitPayTransactionLoading]);

    const callBackAfterTxn = () => setTransactionLoading(false);

    const handleSell = () => {
        setTransactionLoading(true);
        const sellAssetViewModel = assetTransactionViewModel as SellAssetViewModel;
        return dispatch(createSellOrder({ ...sellAssetViewModel.CreateOrderPayload, onErrorCallBack: onError, onSuccessCallBack: onSellAssetSuccess }));
    };

    const handleBuy = () => {
        setTransactionLoading(true);
        const buyAssetViewModel = assetTransactionViewModel as BuyAssetViewModel;
        let market = buyAssetViewModel.Market;
        if (Application.getInstance().SkipPrimaryMarket)
            market = MarketEnum.SECONDARY;
        if (market === MarketEnum.PRIMARY) {
            buyOnPrimaryMarket({
                ...buyAssetViewModel.CreditPaymentState,
                transactionSuccessCallback: onSuccess,
                transactionErrorCallback: onError
            },
                buyAssetViewModel.BuyByCreditData,
                callBackAfterTxn,
            );
        } else {
            if (buyAssetViewModel.NumberOfToken.isSmallerThan(ONE)) {
                setTransactionLoading(false);
                managementNotification(true, i18n.t('sm.negative.amount.detected', { amount: buyAssetViewModel.NumberOfToken }), TRENDEX_HTTP_STATUS_NOK);
                return;
            };

            const creditsPaymentState = {
                ...buyAssetViewModel.CreditPaymentState,
                transactionSuccessCallback: onSuccess,
                transactionErrorCallback: onError
            };

            directBuyFromBestSellingOrders(creditsPaymentState,
                buyAssetViewModel.PaymentBestSellingOrderData,
                callBackAfterTxn,
            );
        }
    };

    const handleRefund = () => {
        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.CURRENT_TRANSACTION_ASSET_ID, assetTransactionViewModel.AssetId);
        creditWithBitpay(assetTransactionViewModel.Amount);
    };

    const onConfirmation = (transactionType: AssetTransactionType) => {
        switch (transactionType) {
            case AssetTransactionType.SELL:
                handleSell();
                break;
            case AssetTransactionType.BUY:
                handleBuy();
                break;
            case AssetTransactionType.REFUND:
                handleRefund();
                break;
        }
    };

    const handleFacebookPixelEventPurchase = (marketSide: number, assetTransactionViewModel: IAssetTransactionViewModel) => {
        let isFirstPaymentCB: boolean = accountViewModel.StripeCustomerId === '';
        const handlerType = marketSide === MarketEnum.SECONDARY ? PixelHandlerBuyOnSecondaryMarket : PixelHandlerBuyOnPrimaryMarket;
        const handler: IMarketPixelHandler & IPixelHandler = new handlerType(true, isFirstPaymentCB);
        handler.setPurchaseData({
            currency: assetTransactionViewModel.Currency,
            userEmail: accountViewModel.Email,
            userId: accountViewModel.Id,
            assetName: assetTransactionViewModel.AssetName,
            quantity: assetTransactionViewModel.NumberOfToken,
            value: assetTransactionViewModel.Amount,
        });
        handler.handle();
    };

    const handleSnapChatPixelEventPurchase = (assetTransactionViewModel: BuyAssetViewModel) => {
        const pixelHandlerSnapPurchase: PixelHandlerSnapPurchase = new PixelHandlerSnapPurchase(accountViewModel.StripeCustomerId === '');
        pixelHandlerSnapPurchase.purchageData = {
            currency: assetTransactionViewModel.Currency,
            user_email: accountViewModel.Email,
            item_category: assetTransactionViewModel.AssetName,
            number_items: assetTransactionViewModel.NumberOfToken,
            price: assetTransactionViewModel.Amount,
        };
        pixelHandlerSnapPurchase.handle();
    };

    const handleGA4PixelEvent = (assetTransactions: BuyAssetViewModel, account: AccountViewModel) => {
        if (accountViewModel.StripeCustomerId === '') {
            TagManagerWrapper.sendFirstPurchaseEvent({
                currency: assetTransactions.Currency,
                userEmail: account.Email,
                userId: account.Id,
                assetName: assetTransactions.AssetName,
                quantity: assetTransactions.NumberOfToken,
                value: assetTransactions.Amount,
            });
        }
        TagManagerWrapper.sendPurchaseEvent(assetTransactionViewModel, account.Id);
    };

    const handleSegmentPixelEventPurchase = (assetTransactionViewModel: BuyAssetViewModel) => {
        SegmentTracking.sendOrderCompletedEvent({
            checkoutId: Utils.uid(),
            orderId: `${assetTransactionViewModel.Market};${accountViewModel.Id};${assetTransactionViewModel.AssetId};${Utils.uid()}`,
            accountViewModel: accountViewModel,
            assetTransactionViewModel: assetTransactionViewModel,
        });
    };

    const onSellAssetSuccess = () => {
        dispatch(refreshUserCredits());
        onSuccess();
        if (accountViewModel.IsOnboardingRunning)
            dispatch(updateOnboardingStep());
        return;
    };

    const onSuccess = () => {
        if (assetTransactionViewModel instanceof BuyAssetViewModel) {
            handleGA4PixelEvent(assetTransactionViewModel, accountViewModel);
            CustomGoogleTagManager.sendPurchaseEventCustom(assetTransactionViewModel, accountViewModel.Id, accountViewModel.Email);
            handleSegmentPixelEventPurchase(assetTransactionViewModel);
            handleSnapChatPixelEventPurchase(assetTransactionViewModel);
            const buyAssetViewModel = assetTransactionViewModel as BuyAssetViewModel;
            handleFacebookPixelEventPurchase(buyAssetViewModel.Market, assetTransactionViewModel);
            if (assetTransactionViewModel.AssetId === SUPER_TALENT)
                CustomFacebookCampaign.trackPurchase(assetTransactionViewModel.Amount);

            if (!accountViewModel.StripeCustomerId)
                dispatch(refreshUser());
        }
        setTransactionLoading(false);
        let propsModal = {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'MESSAGE_MODAL',
            showCancelButton: true,
            fullWidth: false,
            propsCustom: {}
        };

        if (assetTransactionViewModel.TransactionType === AssetTransactionType.BUY && assetTransactionViewModel.Asset.enableCustomAssetProfil() && assetTransactionViewModel.Asset.IsAssetBenzema)
            propsModal = {
                ...propsModal,
                fullWidth: true,
                type: "BENZEMA_PACK",
                propsCustom: {
                    totalAmount: assetTransactionViewModel.Amount,
                    totalTokens: assetTransactionViewModel.NumberOfToken,
                    fromPack: assetTransactionViewModel.FromPack
                }
            };
        else {
            propsModal = {
                ...propsModal,
                propsCustom: {
                    customClass: "success-buy-asset-SharePage_customModal",
                    showCancelButton: false,
                    showLinkButton: false,
                    message: (
                        <>
                            <SuccessBuyAssetSharePage
                                asset={assetTransactionViewModel.Asset}
                                tokenQuantity={assetTransactionViewModel.NumberOfToken}
                                totalPrice={assetTransactionViewModel.Amount}
                                totalFeesFormatted={assetTransactionViewModel.TotalFeesFormatted}
                                totalTransactionFormatted={assetTransactionViewModel.TotalTransactionFormatted}
                                transactionType={assetTransactionViewModel.TransactionType}
                                orderExecuted={assetTransactionViewModel.IsImmediateSettlement}
                                userYield={accountViewModel.shouldShowYield()}
                                paymentMethod={selectedPaymentType}
                                amountPaidInVirtualCredit={assetTransactionViewModel.AmountPaidInVirtualCredit}
                                isLiquidityEnabled={assetTransactionViewModel.IsLiquidityPoolApplicable || false}
                            />
                            {!accountViewModel.IsOnboardingRunning &&
                                <ShareProfileReferral />
                            }
                        </>),
                },
            };
        }
        dispatch(managementModal(true, propsModal));
        if (assetTransactionViewModel instanceof BuyAssetViewModel)
            simulateBuyInWallet(assetTransactionViewModel);
        if (assetTransactionViewModel instanceof SellAssetViewModel)
            simulateSellInWallet(assetTransactionViewModel);
        if (Application.getInstance().IsInjectedAsIframe) {
            return;
        }
        closeAssetTransaction();
    };

    const onError = (_transactionData?: any) => {
        setTransactionLoading(false);
        managementNotification(true, _transactionData?.error ?? _transactionData?.intentStatus?.error, TRENDEX_HTTP_STATUS_NOK);
        const buyAssetViewModel = assetTransactionViewModel as BuyAssetViewModel;
        if (buyAssetViewModel.Market === MarketEnum.SECONDARY)
            dispatch(dropDataOnGetBestSellingOrder());
    };

    const hideTransactionError = () => {
        managementNotification(false);
    };

    return { onConfirmation, onError, onSuccess, hideTransactionError, isTransactionLoading };

};

export default useAssetTransactionConfirmation;
