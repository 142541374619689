
import React from 'react';
import './FabButtonCustom.scss';

const CustomFabButton: React.FunctionComponent<{handleClick: () => void}> = (props) => {
    return (
        <div onClick={() => props.handleClick()} className="hover fab-button">
            {props.children}
        </div>
    );
};

export default CustomFabButton;
