import { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import IMostSalesAssets from 'model/AssetModel/IMostSalesAssets';
import IAsset from 'model/Team/generator/IAsset';
import { IInternalTeam } from 'model/Team/ITeam';
import ITeamAsset from 'model/Team/ITeamAsset';
import ITeamConfig from 'model/Team/ITeamConfig';
import IGenerateTeam from 'service/team-generation/IGenerateTeam';
import TeamGenerationService from 'service/team-generation/TeamGenerationService';
import AssetViewModel from 'view-model/Assets/AssetViewModel';

interface IUseTeamGenerator {
    launchGeneration: (ownedAssets: AssetWalletInterface[], bestSellingAssets: IMostSalesAssets[], otherAssets: AssetViewModel[], configuration: ITeamConfig, team?: IInternalTeam) => void;
    instantTeamGeneration: (ownedAssets: AssetWalletInterface[], bestSellingAssets: IMostSalesAssets[], otherAssets: AssetViewModel[], configuration: ITeamConfig, team?: IInternalTeam) => IGenerateTeam;
}

interface IUseTeamGeneratorParams {
    callbackGenerateTeam?: (teamAssets: ITeamAsset[]) => void;
}

const useTeamGenerator = ({ callbackGenerateTeam }: IUseTeamGeneratorParams): IUseTeamGenerator => {
    const [teamGenerator, setTeamGenerator] = useState<TeamGenerationService>();
    const [teamGenerated, setTeamGenerated] = useState<boolean>(false);
    const [lastAssetHandledCursor, setLastAssetHandledCursor] = useState<number>(-1);
    const launchGeneration = (ownedAssets: AssetWalletInterface[], bestSellingAssets: IMostSalesAssets[], otherAssets: AssetViewModel[], configuration: ITeamConfig, team?: IInternalTeam): void => {
        if (teamGenerated)
            setTeamGenerated(() => false);
        const finalArray: IAsset[] = createArrayOfAssets(ownedAssets, bestSellingAssets, otherAssets);
        setTeamGenerator(new TeamGenerationService(configuration, finalArray, team));
    };

    const instantTeamGeneration = (ownedAssets: AssetWalletInterface[], bestSellingAssets: IMostSalesAssets[], otherAssets: AssetViewModel[], configuration: ITeamConfig, team?: IInternalTeam): IGenerateTeam => {
        if (teamGenerated)
            setTeamGenerated(() => false);
        const finalArray: IAsset[] = createArrayOfAssets(ownedAssets, bestSellingAssets, otherAssets);
        return new TeamGenerationService(configuration, finalArray, team).generateTeam(lastAssetHandledCursor);
    };

    const createArrayOfAssets = (ownedAssets: AssetWalletInterface[], bestSellingAssets: IMostSalesAssets[], otherAssets: AssetViewModel[]): IAsset[] => {
        const finalArray: IAsset[] = [];
        const idsArrayToExclude: string[] = [];

        for (const ownedAsset of ownedAssets.filter(asset => asset.amount > 0)) {
            finalArray.push({
                _id: ownedAsset.asset._id,
                currentValue: ownedAsset.asset.currentValue,
                initialSaleDone: ownedAsset.asset.initialSaleDone,
                fromPortfolio: true,
                amountSharesOwned: ownedAsset.amount,
                numberOfSharesInSale: 0,
            });
            idsArrayToExclude.push(ownedAsset.asset._id);
        }

        for (const asset of otherAssets) {
            if (idsArrayToExclude.includes(asset.AssetId))
                continue;
            const bestSellingAsset: IMostSalesAssets | undefined = bestSellingAssets.find(asst => asst._id.toString() === asset.AssetId.toString());
            finalArray.push({
                _id: asset.AssetId,
                currentValue: asset.CurrentValueAsNumber,
                initialSaleDone: asset.InitialSaleDone,
                fromPortfolio: false,
                amountSharesOwned: 0,
                numberOfSharesInSale: bestSellingAsset !== undefined ? bestSellingAsset.totalAmount : 0,
            });
        }
        return finalArray;
    };

    useEffect(() => {
        if (teamGenerator === undefined)
            return;
        const returnParams: IGenerateTeam = teamGenerator.generateTeam(lastAssetHandledCursor);
        batch(() => {
            if (callbackGenerateTeam)
                callbackGenerateTeam(returnParams.assetsTeam);
            setLastAssetHandledCursor(returnParams.lastAssetHandledCursor);
            setTeamGenerated(() => true);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamGenerator]);

    useEffect(() => {
        setTeamGenerated(() => false);
    }, [teamGenerated]);

    return { launchGeneration, instantTeamGeneration };
};

export default useTeamGenerator;
