import { RootState } from 'model/Redux';
import { Selector } from 'react-redux';
import IWithdrawalRequest from './IWithdrawalRequest';
import { isArray } from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';
import WalletWithdrawalViewModel from 'view-model/WalletWithdrawalViewModel/WalletWithdrawalViewModel';

export const userWithdrawalRequestsSelector: Selector<RootState, IWithdrawalRequest[]> = state => state.userWithdrawalRequests.data;
export const userWithdrawalRequestsLoadingSelector: Selector<RootState, boolean> = state => state.userWithdrawalRequests.loading;

export const selectUserWithdrawalRequests = createSelector(
    userWithdrawalRequestsSelector,
    (withdrawalRequests) => {
        if (!withdrawalRequests || withdrawalRequests.length === 0 || !isArray(withdrawalRequests)) return [];
        return withdrawalRequests
            .sort((a ,b)=>moment(b.createdAt).diff(a.createdAt))
            .map(withdrawalRequest => new WalletWithdrawalViewModel(withdrawalRequest));
    }
);