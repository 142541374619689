
export default abstract class PendingCardViewModel {
    abstract get DateInDDMMYYY(): string;
    abstract get TimeInHHMMSS(): string;
    abstract get CardTopic(): string;
    abstract get CardMsg(): string;
    abstract get CardSummaryOne(): string;
    abstract get CardSummaryTwo(): string;
    abstract get CardSummaryThree(): string;
    abstract get CardCancelText(): string | undefined;
}