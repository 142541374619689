import * as React from 'react';

const TickMark: React.FC = (props: React.SVGProps<SVGSVGElement>) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={props.className ?? "tick-mark-icon"} width={props.width ?? "10.018"} height={props.height ?? "6.645"} viewBox="0 0 10.018 6.645">
      <path id="Tracé_4059" data-name="Tracé 4059" d="M303.621,611.3l2.959,2.959,4.231-4.231" transform="translate(-302.207 -608.612)" fill="none" stroke="#53d081" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  )
}

export default TickMark;
