import useTournament from 'hooks/tournament/use-tournament/useTournament';
import i18n from 'i18next';
import UserInterface from 'model/User/UserInterface';
import React from 'react';
import { useSelector } from 'react-redux';
import { accountSelector } from 'service/auth/selectors';
import ITeamEngagedWarningMessageProps from './ITeamEngagedWarningMessageProps';
import './style.scss';

const TeamEngagedWarningMessage: React.FC<ITeamEngagedWarningMessageProps> = ({ teamEngagedMessage }: ITeamEngagedWarningMessageProps) => {
    return (
        <div className="team-engaged">
            <h2>{i18n.t('tournament.happening').toUpperCase()}</h2>
            <hr />
            <p className="text">{teamEngagedMessage}</p>
        </div>
    );
};

const withTeamEngagedWarning = (Component: React.FC<ITeamEngagedWarningMessageProps>) => {
    const MemoComponent = React.memo(Component);

    const Wrapper: React.FC = () => {
        const { tournamentInProgress, tournamentLoading } = useTournament();
        const user: UserInterface = useSelector(accountSelector);

        if (tournamentLoading)
            return <></>;

        const teamEngagedMessage: string = !tournamentInProgress ? i18n.t('simple-team.engaged.warning') : i18n.t('team.engaged.warning', {
            day: tournamentInProgress.EndDate.toDate().toLocaleDateString(user.language),
            hours: tournamentInProgress.EndDate.toDate().toLocaleTimeString(user.language, { hour: '2-digit', minute: '2-digit' }),
        });

        return <MemoComponent teamEngagedMessage={teamEngagedMessage} />;
    };

    return React.memo(Wrapper);
};

export default withTeamEngagedWarning(TeamEngagedWarningMessage);
