export const ASSETS_LOADING = 'ASSETS_LOADING';
export const ASSETS_ERROR = 'ASSETS_ERROR';
export const ASSETS_SUCCESS = 'ASSETS_SUCCESS';
export const ASSETS_UPDATE = 'ASSETS_UPDATE';
export const GET_PRICE = 'GET_PRICE';
export const GET_PREDICTED_ASSET_PRICE_START = 'GET_PREDICTED_ASSET_PRICE_START';
export const GET_PREDICTED_ASSET_PRICE_SUCCESS = 'GET_PREDICTED_ASSET_PRICE_SUCCESS';
export const GET_PREDICTED_ASSET_PRICE_FAILED = 'GET_PREDICTED_ASSET_PRICE_FAILED';
export const GET_PREDICTED_ASSET_PRICE = 'GET_PREDICTED_ASSET_PRICE';
export const DELETE_ASSET = 'DELETE_ASSET';
export const UPDATE_ASSET_KEYS = 'UPDATE_ASSET_KEYS';
export const FUTURE_ASSETS_ERROR = 'FUTURE_ASSETS_ERROR';
export const FUTURE_ASSETS_SUCCESS = 'FUTURE_ASSETS_SUCCESS';
export const FUTURE_ASSETS_LOADING = 'FUTURE_ASSETS_LOADING';
export const UPDATE_ASSET_CURRENT_VALUE = 'UPDATE_ASSET_CURRENT_VALUE';
export const GET_ASSET_TOP_INVESTORS_LOADING = 'GET_ASSET_TOP_INVESTORS_LOADING';
export const GET_ASSET_TOP_INVESTORS_SUCCESS = 'GET_ASSET_TOP_INVESTORS_SUCCESS';
export const GET_ASSET_TOP_INVESTORS_ERROR = 'GET_ASSET_TOP_INVESTORS_ERROR';
export const ASSETS_PRICE_HISTORIES_LOADING = 'ASSETS_PRICE_HISTORIES_LOADING';
export const ASSETS_PRICE_HISTORIES_ERROR = 'ASSETS_PRICE_HISTORIES_ERROR';
export const ASSETS_PRICE_HISTORIES_SUCCESS = 'ASSETS_PRICE_HISTORIES_SUCCESS';
export const ASSETS_MARKET_CONFIGURATION_LOADING = 'ASSETS_MARKET_CONFIGURATION_LOADING';
export const ASSETS_MARKET_CONFIGURATION_ERROR = 'ASSETS_MARKET_CONFIGURATION_ERROR';
export const ASSETS_MARKET_CONFIGURATION_SUCCESS = 'ASSETS_MARKET_CONFIGURATION_SUCCESS';
export const GET_TOP_ASSETS_CURRENT_VALUES_LOADING = 'GET_TOP_ASSETS_CURRENT_VALUES_LOADING';
export const GET_TOP_ASSETS_CURRENT_VALUES_ERROR = 'GET_TOP_ASSETS_CURRENT_VALUES_ERROR';
export const GET_TOP_ASSETS_CURRENT_VALUES_SUCCESS = 'GET_TOP_ASSETS_CURRENT_VALUES_SUCCESS';
export const GET_TOP_ASSETS_CURRENT_VALUES_UPDATE = 'GET_TOP_ASSETS_CURRENT_VALUES_UPDATE';
export const INITIAL_SALE_DONE = 'INITIAL_SALE_DONE';
export const GET_TOP_ASSETS_SCORES_LOADING: string = 'GET_TOP_ASSETS_SCORES_LOADING';
export const GET_TOP_ASSETS_SCORES_ERROR: string = 'GET_TOP_ASSETS_SCORES_ERROR';
export const GET_TOP_ASSETS_SCORES_SUCCESS: string = 'GET_TOP_ASSETS_SCORES_SUCCESS';
export const ASSET_UPDATE: string = 'ASSET_UPDATE';

export const CLOUD_CACHE_LOADING: string = 'CLOUD_CACHE_LOADING';
export const CLOUD_CACHE_LOADING_COMPLETE: string = 'CLOUD_CACHE_LOADING_COMPLETE';

export const GET_TRANSLATED_DESCRIPTION_LOADING: string = 'GET_TRANSLATED_DESCRIPTION_LOADING';
export const GET_TRANSLATED_DESCRIPTION_ERROR: string = 'GET_TRANSLATED_DESCRIPTION_ERROR';
export const GET_TRANSLATED_DESCRIPTION_SUCCESS: string = 'GET_TRANSLATED_DESCRIPTION_SUCCESS';