import { ChevronLeft } from '@material-ui/icons';
import { IOwnInformation } from 'components/small/about-overview/AboutOverviewProps';
import ProfilIcon from 'components/small/profil-icon/ProfilIcon';
import { DEFAULT_CURRENCY, TRENDEX_LANGUAGES } from 'config/_const';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import i18next from 'i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionUpdateUserInformation } from 'service/auth/actions';
import { selectorIsAdminToVip } from 'service/auth/selectors';
import UserInformationFormRedux from '../../medium/form/user-information-form/UserInformationForm';
import './UserProfileEdit.scss';
import { IUserProfileEdit } from './UserProfileEditProps';

const UserProfileEdit = (props: IUserProfileEdit) => {
    const dispatch = useDispatch();
    const { accountViewModel, acceptSMSNotification }: IUseAccount = useAccount();
    const isAdminToVip: boolean = useSelector(selectorIsAdminToVip);

    const onSuccessCallback = () => {
        props.handleIsThisMainProfilePage(true);
    }

    const updateOwnInformations = (formData: IOwnInformation) => {
        dispatch(actionUpdateUserInformation(formData, undefined, onSuccessCallback));
    }

    return (
        <div className='edit-userProfile-container'>
            <section className='confirm-section'>
                <span
                    className='cancel-btn hover'
                    onClick={() => props.handleIsThisMainProfilePage(true)}
                >
                    <ChevronLeft />{i18next.t('genericBack')}
                </span>
            </section>
            <h2>{i18next.t('EditProfile')}</h2>
            <ProfilIcon
                loading={accountViewModel.loadingUploadPicture}
                avatar={accountViewModel.avatar}
                level={12}
                editIcon={false}
                onIconEditChange={props.onIconEditChange}
                showEditText={true}
                isEditDisabled={!isAdminToVip && accountViewModel.isVipAccountConfirmed}
            />

            <section className="container-form-informations">
                <UserInformationFormRedux
                    isEmailValidated={accountViewModel.account.validatedEmail}
                    buttonText={'Aboutus.tab.about.updateinfo'}
                    initialValues={{
                        email: accountViewModel.account.email,
                        pseudo: accountViewModel.account.pseudo || '',
                        lastName: accountViewModel.account.lastName,
                        firstName: accountViewModel.account.firstName,
                        country: accountViewModel.account.country || '',
                        address: accountViewModel.account.address,
                        phone: accountViewModel.account.phone,
                        discord: accountViewModel.account.discord,
                        dateOfBirth: accountViewModel.account.dateOfBirth || new Date().toISOString(),
                        language: accountViewModel.account.language ?? TRENDEX_LANGUAGES.ENGLISH,
                        currency: accountViewModel.account.currency || DEFAULT_CURRENCY,
                    }}
                    onInformationsSubmit={updateOwnInformations}
                    managementNotification={props.managementNotification}
                    showSubmitButton={true}
                    handleIsThisMainProfilePage={props.handleIsThisMainProfilePage}
                    acceptSMSNotification={acceptSMSNotification} />
            </section>
        </div>
    )
}

export default React.memo(UserProfileEdit); 
