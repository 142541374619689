import * as React from "react";

const Handball: React.FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" width='19' height='19.033' x="0px" y="0px" viewBox="0 0 256 256" enable-background="new 0 0 256 256">
            <g>
                <g>
                    <g>
                        <path fill="#000000" d="M76.8,2.2C68.3,4.1,61.1,11,58.6,19.3C56.9,25.1,58,33.7,61,38.6c2.7,4.4,8.2,9,12.7,10.6c14,4.9,28.7-3,32.4-17.3C110.5,14.7,94.3-1.8,76.8,2.2z" /><path fill="#000000" d="M136.6,36.4c-10.5,2.9-17.5,11.8-17.5,22.5c0,6.9,2.5,12.5,7.7,17.3c13.5,12.4,35.6,4.4,38.4-13.8c1.8-11.6-5.7-22.9-17.2-26C144.5,35.5,140,35.5,136.6,36.4z" /><path fill="#000000" d="M56.5,50.2c-7.1,2.2-11.9,8.3-12.4,15.6c-0.2,2.7,0.2,5.3,2.5,15.3c3.7,16.1,6,20.8,13.4,27.6c3.3,3,9.9,6.6,26.8,14.4c9,4.2,12,10,8.6,16.7c-0.5,1-19.1,21-41.3,44.4c-22.2,23.4-40.9,43.3-41.5,44.3c-2.9,4.4-3.5,11.2-1.3,15.9c1.4,3.2,4.6,6.5,7.7,7.9c3.6,1.6,9,1.6,12.5,0c2.6-1.3,7.2-5.6,43-40.5c16-15.6,18.1-17.6,21.5-19.2c3.4-1.7,4-1.8,7.9-1.8c3.8,0,4.5,0.2,6.9,1.4c2.9,1.6,5.3,4.7,5.8,7.6c0.5,2.9,0,6.8-1.4,9.6c-0.8,1.6-4.6,6.4-9.5,11.8c-9.9,11.1-10.9,12.8-10.9,18.8c0,3.8,0.2,4.5,1.5,6.9c2.9,5.1,7.6,7.6,14.6,7.7c6.6,0,8.2-0.8,21.9-10.9c13.8-10.3,15.5-11.6,18.5-15.5c2.6-3.4,6.2-10,7.3-13.6c0.4-1.4,2.5-14.6,4.5-29.2c4-29.6,4.1-29.8,8.2-33.5c3-2.7,6-3.9,9.9-3.9c1.8,0,13.1,1.7,25.2,3.9c24.2,4.2,26,4.3,30.8,2.1c3.5-1.6,5.8-3.7,7.6-6.9c1.2-2.3,1.4-3.1,1.4-6.3c0-2.9-0.2-4.2-1.1-5.8c-1.7-3.2-4.3-5.7-7.2-7.2c-1.5-0.8-29.6-8.9-70.3-20.1C130.1,96.9,98.3,88,96.6,87.5c-3.3-1.1-8.8-4.7-11.6-7.7c-4-4.2-5.8-7.6-8.1-14.8c-1.4-4.3-2.8-7.8-3.7-9C69.4,50.7,62.5,48.3,56.5,50.2z" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default Handball;
