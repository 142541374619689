import AssetAvatar from 'components/small/asset-avatar/AssetAvatar';
import ScoreLabel from 'components/small/score-label/ScoreLabel';
import { ICategoryTypeName } from 'interfaces/IAssetBgImages';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Color from 'view-model/Shared/Color';
import IWalletListViewCellOnboardingProps from './IWalletListViewCellOnboardingProps';
import './style.scss';
import { ASSET_CARD_LEVEL_NAME, ASSET_CARD_LEVEL_NAME_PLURIAL } from 'config/_const';
import LeagueColorIcon from 'components/small/league-color-icon/LeagueColorIcon';
import Application from 'Application';
import { TournamentLeagues } from 'config/_enums';

const WalletListViewCellOnboarding: React.FC<IWalletListViewCellOnboardingProps> = ({ t: translate, asset, assetCategories }: IWalletListViewCellOnboardingProps) => {
    return (
        <div className='wallet-portfolio-list-item' key={asset.Id}>
            <div className="cell-container">
                <div className="icon-container">
                    <AssetAvatar
                        asset={{
                            avatarPath: asset.icon,
                            mainCategoryId: asset.mainCategory,
                            cardLevel: asset.CardLevel
                        }}
                        className="portfolio-item-avatar-img"
                    />
                </div>

                <div className="content-container">
                    <div className="header-container">
                        <div className="portfolio-item-introduction">
                            <p className="asset-name no-margin">{asset.Name}</p>
                            <p className="asset-category no-margin">{asset.getAssetCategoryName(assetCategories)}</p>
                            <div className='asset-pseudo-detail'>
                                <div className='asset-pseudo-detail-score'>
                                    <div className='asset-score-label-container'>
                                        <ScoreLabel
                                            score={asset.AssetAsVM.getTrendScoreToDisplay(2)}
                                            canShowBoost={asset.ScoreBoosted}
                                            backgroundColor={Color.getColorByInputRatio(asset.IRLScore)}
                                            tooltipText={asset.getScoreTooltipText(translate('tooltip.trend.score'))}
                                            showMaxScore={true}
                                        />
                                    </div>
                                    <span className='score-label'><Trans i18nKey="onboarding.card.trend-score" /></span>
                                </div>
                                {asset.canShowSportScore(assetCategories) &&
                                    <div className='asset-pseudo-detail-score'>
                                        <div className='asset-score-label-container'>
                                            <ScoreLabel
                                                score={asset.AssetAsVM.getSportScoreToDisplay(2)}
                                                category={asset.getAssetCategoryName(assetCategories) as ICategoryTypeName}
                                                canShowBoost={asset.ScoreBoosted}
                                                backgroundColor={Color.getColorByInputRatio(asset.sportsScore)}
                                                tooltipText={asset.getScoreTooltipText(translate('tooltip.sport.score'))}
                                                showMaxScore={true}
                                            />
                                        </div>
                                        <span className='score-label'><Trans i18nKey="onboarding.card.sport-score" values={{ sport: asset.getAssetCategoryName(assetCategories) }} /></span>
                                    </div>
                                }
                            </div>

                        </div>

                        <div className="amount-container">
                            <p className="current-value-wallet-item no-margin">{asset.getTotalHoldingValue().toCurrency()}</p>
                            <p className="number-of-trends no-margin">{translate('wallet.shares')}: {asset.Amount}</p>
                        </div>
                    </div>


                    <div className="portfolio-item-contents">
                        <span className=''><Trans i18nKey={"onboarding.card-level"} /> :</span>
                        <LeagueColorIcon assetLeague={Application.getInstance().getLeagueFromName(ASSET_CARD_LEVEL_NAME_PLURIAL[asset.CardLevel] as TournamentLeagues)} />
                        <span className='asset-league'>{ASSET_CARD_LEVEL_NAME[asset.CardLevel]}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(React.memo(WalletListViewCellOnboarding));
