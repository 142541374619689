import * as React from 'react';

const Clock = ({ height = "23px", width = "23px", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg id="clock-duotone" height={height} width={width} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.918 23.918">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#fd8b85" />
                    <stop offset="0.502" stopColor="#e66f81" />
                    <stop offset="1" stopColor="#ff3577" />
                </linearGradient>
            </defs>
            <path className='fa-primary' id="Tracé_3210" data-name="Tracé 3210" d="M234.242,97.121v5.755l3.985,2.653a1.082,1.082,0,0,1,.271,1.556,1.029,1.029,0,0,1-1.514.271l-4.485-2.99a1,1,0,0,1-.5-.892V97.121a1.121,1.121,0,1,1,2.242,0Z" transform="translate(-221.162 -91.515)" fill="url(#linear-gradient)" />
            <path className='fa-secondary' id="Tracé_3211" data-name="circle-box" d="M11.959,23.918A11.959,11.959,0,1,1,23.918,11.959,11.958,11.958,0,0,1,11.959,23.918ZM10.838,11.959a1,1,0,0,0,.5.892l4.485,2.99a1.029,1.029,0,0,0,1.514-.271,1.082,1.082,0,0,0-.271-1.556L13.08,11.361V5.606a1.128,1.128,0,0,0-1.163-1.121A1.146,1.146,0,0,0,10.8,5.606Z" fill="#6677f5" opacity="0.3" />
        </svg>


    );
};

export default Clock;