import { getLifecycle } from 'service/utils';

const REDUCER_NAME: string = 'TOURNAMENT';

export const TOURNAMENT = getLifecycle(REDUCER_NAME, 'TOURNAMENT');
export const TOURNAMENT_GET_USERS = getLifecycle(REDUCER_NAME, 'GET_USERS');
export const POST_TOURNAMENT = getLifecycle(REDUCER_NAME, 'POST_TOURNAMENT');
export const GET_CURRENT_TOURNAMENT = getLifecycle(REDUCER_NAME, 'GET_CURRENT_TOURNAMENT');
export const GET_PREVIOUS_TOURNAMENT = getLifecycle(REDUCER_NAME, 'GET_PREVIOUS_TOURNAMENT');
export const RESET_STATUS = `${REDUCER_NAME}_RESET_STATUS`;

