import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import * as SideMenuType from './actionsType';

export const managementSideMenu = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    return dispatch({
        type: SideMenuType.SIDE_MENU_MANAGE,
    });
};
