import IStripeCheckoutFormProps from 'components/medium/stripe-elements/IStripeCheckoutFormProps';
import StripeCheckoutForm from 'components/medium/stripe-elements/stripeCheckoutForm';
import React from 'react';
import "./credit-card-wrapper.scss";

const CreditCardWrapper: React.FC<IStripeCheckoutFormProps> = (props) => {
    return (<div className='credit-card-wrapper'>
        <StripeCheckoutForm {...props} />
    </div>
    )
}

export default CreditCardWrapper;