import useAssetsCategories from "hooks/asset-categories/useAssetCategories";
import ISportStatistics from "interfaces/score-chart/ISportStatistics";
import { RootState } from "model/Redux";
import { useDispatch, useSelector } from "react-redux";
import { getAssetScoreStatistics } from "service/asset-score-statistics/action";
import { selectScoreStatisticsSelector } from "service/asset-score-statistics/selector";
import AssetViewModel from "view-model/Assets/AssetViewModel";

export const useAssetSportStatistics = (assetVM: AssetViewModel) => {
    const dispatch = useDispatch();
    const { assetsCategories } = useAssetsCategories();
    const statistics: ISportStatistics = useSelector((state: RootState) => selectScoreStatisticsSelector(state, assetVM.SportPlayer.id));
    return {
        fetchAssetSportStatistics: () => dispatch(getAssetScoreStatistics(assetVM?.SportPlayer.id, assetVM.getCategoryName(assetsCategories))),
        statistics,
    }
}

export default useAssetSportStatistics;