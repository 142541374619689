import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { deleteUser, fetchAllUsers, searchUsers, disableUser, reactivateUser } from '../../../service/users/actions';
import UserInterface from '../../../model/User/UserInterface';
import { UserDataTable } from '../../../model/Table/TableHeader';
import Row from '../../../model/Table/Row';
import GenericTable from '../../../components/medium/generic-table/GenericTable';
import { UserHeadCell } from '../../../config/tableConfig';
import { Link } from 'react-router-dom';
import SecondaryButton from '../../../components/small/buttons/secondary-button/SecondaryButton';
import OverviewUsersPageProps from './OverviewUsersPageProps';
import OverviewUsersPageState from './OverviewUsersPageState';
import GreenButton from '../../../components/small/buttons/green-button/GreenButton';
import LoginIcon from '../../../components/small/login-icon/LoginIcon';
import { loginPreview } from '../../../service/auth/actions';
import RedButton from '../../../components/small/buttons/red-button/RedButton';
import './OverviewUsersPage.scss';
import i18next from 'i18next';
import moment from 'moment';
import PrimaryButton from '../../../components/small/buttons/primary-button/PrimaryButton';
import AxiosConfig from '../../../config/axiosConfig';
import { CSVLink } from 'react-csv';
import { IHttpStrongTypedResponse } from 'model/IHttpResponse';
class OverviewUsersPage extends React.Component<OverviewUsersPageProps, OverviewUsersPageState> {

    constructor(props: OverviewUsersPageProps) {
        super(props);

        this.state = {
            statusFilter: '',
            searchWord: '',
            searchTimeout: undefined,
            defaultPage: 0,
            dumpUserData: []
        };
    }

    private static PENDING: string = 'PENDING';
    private static CONFIRMED: string = 'CONFIRMED';
    private static REFUSED: string = 'REFUSED';

    private static STATUS_ARRAY: Array<string> = [OverviewUsersPage.PENDING, OverviewUsersPage.CONFIRMED, OverviewUsersPage.REFUSED]
    componentWillMount = async () => {
        this.props.fetchAllUsers(1, JSON.stringify({}));
        //TODO - store/action/reducer : Dirty (no time)
        if (this.state.dumpUserData.length > 0)
            return;
        if (this.props.auth.data.account._id !== '6331a8c9de4c77000386559f')
            return;
        const response: IHttpStrongTypedResponse<[]> = await AxiosConfig.post('/users/dump');
        this.setState({ dumpUserData: response.data.data });
    }

    colorByUserStatus = (status: string) => {
        switch (status) {
            case OverviewUsersPage.REFUSED:
                return '#ff6659';

            case OverviewUsersPage.CONFIRMED:
                return '#6abf69';

            default:
                return '#fbc02d';
        }
    }

    fetchUserByStatus = (status: string = OverviewUsersPage.PENDING, page?: number) => {
        this.setState({ statusFilter: status });

        this.props.fetchAllUsers(
            typeof page !== 'undefined' ? page : 1,
            JSON.stringify({ isConfirmed: status }));
    }

    fetchUsersByDisabled = () => {
        this.setState({ statusFilter: 'DISABLED' });
        this.props.fetchAllUsers(1, JSON.stringify({ disabled: true }));
    }

    fetchUsersByPagination = (page: number) => {
        let filter: { [key: string]: any } | undefined = undefined;

        if (this.state.searchWord) {
            return this.props.searchUsers(
                page + 1,
                this.state.searchWord,
                true,
            );
        }

        if (this.state.statusFilter) {
            filter = { isConfirmed: this.state.statusFilter };
        }

        this.props.fetchAllUsers(
            page + 1,
            JSON.stringify(filter),
            this.state.searchWord,
        );
    }

    onSearchUsers = (searchWord: string, page: number = 1) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }

        this.setState({
            searchWord,
            defaultPage: page - 1,
            searchTimeout: searchWord ? setTimeout(
                () => {
                    this.props.searchUsers(
                        page,
                        searchWord,
                        false,
                    );
                },
                1000,
            ) : undefined,
        });
    }

    tableUsersConfiguration = (users: UserInterface[]): Row<UserDataTable>[] => {
        if (!users || !users.length) {
            return [];
        }

        return users
            .sort((firstUser: UserInterface, secondUser: UserInterface) => new Date(firstUser.uploadIdentityDate).getTime() - new Date(secondUser.uploadIdentityDate).getTime())
            .filter((user: UserInterface) => !this.state.statusFilter || (OverviewUsersPage.STATUS_ARRAY.includes(this.state.statusFilter) ? user.isConfirmed === this.state.statusFilter : user.disabled === true))
            .map((user: UserInterface) => {
                /** generic data who need to send to generic table */
                const usersData: UserDataTable = {
                    pseudo: { data: user.pseudo || `${i18next.t('admin.undefined')}`, textCellColor: 'white' },
                    name: { data: `${user.firstName || i18next.t('admin.undefined')} ${user.lastName || i18next.t('admin.undefined')}`, textCellColor: 'white' },
                    createAt: { data: user.createdAt ? `${moment(user.createdAt).format('DD/MM/YYYY')}` : `${i18next.t('admin.undefined')}`, textCellColor: 'white' },
                    isConfirmed: { data: user.isConfirmed, textCellColor: this.colorByUserStatus(user.isConfirmed || OverviewUsersPage.PENDING) },
                    email: { data: user.email, textCellColor: 'white' },
                    connectTo: {
                        data: (
                            <GreenButton
                                disabled={false}
                                receiveClickEvent={false}
                                textButton={<span>Login  <LoginIcon /></span>}
                                type="button"
                                handleClick={() => this.props.loginPreview(false, this.props.history, 'standard', user._id)} />
                        ),
                    },
                    credits: {
                        data: `${user.credits ? user.credits.toFixed(2) : '0'} (${user.frozenCredits?.toFixed(2) || '0'})`, textCellColor: 'white'
                    },
                    deleteUser: {
                        data: (
                            <RedButton
                                disabled={false}
                                receiveClickEvent={false}
                                type="button"
                                textButton={i18next.t('common.delete')}
                                handleClick={() => this.props.deleteUser(user._id)} />
                        ),
                    },
                    disableUser: {
                        data: !user.disabled ? (
                            <RedButton
                                disabled={false}
                                receiveClickEvent={false}
                                type="button"
                                textButton={i18next.t('common.disable')}
                                handleClick={() => this.props.disableUser(user._id)} />
                        ) : <GreenButton
                            disabled={false}
                            receiveClickEvent={false}
                            textButton="Réactiver"
                            type="button"
                            handleClick={() => this.props.reactivateUser(user._id)} />,
                    },
                    viewProfile: {
                        data: (
                            <Link to={`/users/${user._id}`}>
                                <SecondaryButton
                                    disabled={false}
                                    receiveClickEvent={false}
                                    type={'button'}
                                    textButton={'See profil'}
                                    handleClick={() => undefined} />
                            </Link>
                        ),
                    },
                };
                return {
                    cells: usersData,
                    id: user._id,
                };
            });
    }

    render = () => {
        const {
            users,
        } = this.props;

        const filteredUsers: UserInterface[] = this.state.searchWord && users.data.search.length ? users.data.search : users.data.all || [];
        const usersRows: Row<UserDataTable>[] = this.tableUsersConfiguration(filteredUsers);

        return (
            <div className="transfer-page">

                {(!users || (users && !users.data.all.length)) && <h1>Aucune validations pour le moment</h1>}

                <div className="action-buttons">

                    {this.props.auth.data.account._id === '6331a8c9de4c77000386559f' && <div className="item">
                        <CSVLink data={this.state.dumpUserData} separator={';'}>
                            <PrimaryButton
                                receiveClickEvent={false}
                                disabled={false}
                                className="download-button"
                                textButton="DUMP"
                                type="button" />
                        </CSVLink>
                    </div>}

                    <div className="item">
                        <SecondaryButton
                            disabled={false}
                            receiveClickEvent={false}
                            textButton={'Confirmed user'}
                            handleClick={() => this.fetchUserByStatus(OverviewUsersPage.CONFIRMED)}
                            type={'button'}
                        />
                    </div>

                    <div className="item">
                        <SecondaryButton
                            disabled={false}
                            receiveClickEvent={false}
                            textButton={'Pending user'}
                            handleClick={() => this.fetchUserByStatus(OverviewUsersPage.PENDING)}
                            type={'button'}
                        />
                    </div>

                    <div className="item">
                        <SecondaryButton
                            disabled={false}
                            receiveClickEvent={false}
                            textButton={'Refused user'}
                            handleClick={() => this.fetchUserByStatus(OverviewUsersPage.REFUSED)}
                            type={'button'}
                        />
                    </div>

                    <div className="item">
                        <SecondaryButton
                            disabled={false}
                            receiveClickEvent={false}
                            textButton={'Utilisateurs bloqués'}
                            handleClick={() => this.fetchUsersByDisabled()}
                            type={'button'}
                        />
                    </div>
                    <div className="item">
                        <PrimaryButton
                            disabled={false}
                            receiveClickEvent={false}
                            textButton={'Réinitialiser les filtres'}
                            handleClick={() => this.setState({ statusFilter: '' })}
                            type={'button'}
                        />
                    </div>
                </div>

                <div className="search-container">
                    <input
                        type="text"
                        placeholder={i18next.t('index.search')}
                        value={this.state.searchWord}
                        onChange={(e: any) => this.onSearchUsers(e.target.value)} />
                </div>

                <div className="transfer-container card-style">
                    <GenericTable
                        defaultPage={this.state.defaultPage}
                        rows={usersRows}
                        loadMoreData={(page: number) => this.fetchUsersByPagination(page)}
                        headerTable={UserHeadCell}
                        enabledCheckbox={false}
                        enableCreateButton={false}
                        tableHeight={'100vh'} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: OverviewUsersPageProps) => ({
    users: state.users,
    auth: state.auth,
});

export default compose(
    connect(mapStateToProps, {
        fetchAllUsers,
        loginPreview,
        deleteUser,
        searchUsers,
        disableUser,
        reactivateUser
    }),
)(OverviewUsersPage);
