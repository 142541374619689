import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CreatePaymentMethodCardData, StripeCardCvcElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardNumberElementChangeEvent, StripeCardNumberElementOptions } from '@stripe/stripe-js';
import Application from 'Application';
import clsx from 'clsx';
import ICreditAccountIntent from 'components/medium/stripe-elements/ICreditAccountIntent';
import GreenButtonWithGradient from 'components/small/buttons/green-button/GreenButtonWithGradient';
import IBuyScoreBoostProps from 'hooks/payment/stripe/IBuyScoreBoostProps';
import i18next from 'i18next';
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assetViewModelByIdSelector } from 'service/assets/selectors';
import PixelHandlerDeposit from 'service/facebook-pixel/PixelHandlerDeposit';
import { SegmentTracking } from 'service/segment/SegmentTracking';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import Utils from 'utils/Utils';
import { isValidEmail } from 'utils/validator';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import CreditCardLoader from '../../components/loader/credit-card-loader/CreditCardLoader';
import CheckoutModeEnum from '../../components/medium/stripe-elements/CheckoutModeEnum';
import {
    buyAssetCallbackAreMissingErrorMessage,
    buyAssetIntentUndefinedErrorMessage,
    buyScoreintentOrCallbackFxnIsUndefinedErrorMessage,
    checkoutModeUnknownErrorMessage,
    creditAccountSuccessCallbackIsMissingErrorMessage,
    intentIsNotWellTypedErrorMessage,
    intentIsUndefinedErrorMessage,
    multipleBuyIntentIsUndefinedErrorMessage,
    multipleIntentArraysAreEmptyErrorMessage,
    multipleIntentNotFormattedWellErrorMessage,
    stripeCardNotFoundErrorMessage,
    stripeElementsOrInstanceNullErrorMessage,
    stripeIsNotLoadedErrorMessage,
    unknownCheckoutModeErrorMessage,
} from '../../components/medium/stripe-elements/ErrorMessages';
import IBuyAssetIntent from '../../components/medium/stripe-elements/IBuyAssetIntent';
import ICreatePaymentMethod from '../../components/medium/stripe-elements/ICreatePaymentMethod';
import ITrendexCheckoutFormProps from '../../components/medium/stripe-elements/ITrendexCheckoutFormProps';
import CircularLoader from '../../components/small/CircularLoader';
import SuccessPage from '../../components/small/SuccessPage/SuccessPage';
import CheckboxCGU from '../../components/small/cgu-checkbox/CheckboxCGU';
import LoadingWrapper from '../../components/small/loading-wrapper/LoadingWrapper';
import { DEFAULT_ID_AS_STRING, G_TAGS_KNOWN_EVENTS, LOCAL_STORAGE_KNOWN_KEYS, MIN_PAYMENT_AMOUNT, MIN__BUY_CREDIT_PAYMENT_AMOUNT, TERMS_DRAW_LINKS, TRENDEX_HTTP_STATUS_NOK, TRENDEX_LANGUAGES, USER_METADATA } from '../../config/_const';
import { MarketEnum } from '../../config/_enums';
import IBuyData from '../../model/Payment/IBuyData';
import ISecondaryMarketPaymentData from '../../model/Payment/ISecondaryMarketPaymentData';
import { getUserCredits } from '../../service/auth/actions';
import { managementModal } from '../../service/modal/action';
import IBuyOnPrimaryMarketProps from '..//payment/stripe/IBuyOnPrimaryMarketProps';
import useTrendexStripePayment from '../payment/stripe/useTrendexStripePayment';
import IUseAccount from '../use-account/IUseAccount';
import useAccount from '../use-account/useAccount';
import IUseTrendexCheckoutForm, { IStripeCardCompletion } from './IUseTrendexCheckoutForm';
import useNotification from 'hooks/useNotification';
import IBuyTournamentTicketIntent from 'components/medium/stripe-elements/IBuyTournamentTicketIntent';
import IInitiateTournamentTicketPurchase from 'hooks/payment/stripe/IInitiateTournamentTicketPurchase';
const useTrendexCheckoutForm = (props: ITrendexCheckoutFormProps): IUseTrendexCheckoutForm => {
    const { managementNotification } = useNotification();
    const accordionClasses = makeStyles(() => {
        return {
            root: {
                backgroundColor: '#f4f4f5',
                boxShadow: 'none',
                '&.MuiAccordion-root:before': {
                    display: 'none',
                },
            },
        };
    })();
    const [expandTrendexCheckoutForm, setExpandNewPaymentForm] = useState<boolean>(props.newCardForm);
    const cardPaymentMethod: string = 'card';
    const confirmCardStatusOk: string = 'succeeded';
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [allowUserToBuy, setAllowUserToBuy] = useState<boolean>(false);
    const [paymentRulesAccepted, setPaymentRulesAccepted] = useState<boolean>(false);
    const { accountViewModel, acceptPaymentCGU, accountIsLoading }: IUseAccount = useAccount();
    const paymentFormSubmitRef = useRef<HTMLButtonElement>(null);

    const displayPaymentIntentInErrorCallback = (errorReason?: string) => {
        paymentDone();
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                showCancelButton: false,
                showLinkButton: false,
                onValidation: () => {
                    dispatch(managementModal(false));
                },
                textValidButton: i18next.t('cardpayment.error-modal.validation'),
                message: i18next.t(errorReason ?? 'cardpayment.error-modal.error'),
            },
        }));
    };
    const stripeCardNumberStyle: StripeCardNumberElementOptions = {
        classes: {
            base: 'full-width',
        },
        showIcon: true
    };
    const { creditAccount, buyOnPrimaryMarket, buyOnSecondaryMarket, buyForScoreBoost, initiateTicketPurchaseIntentForTournament } = useTrendexStripePayment({
        polTransactions: true,
        callbackOnPaymentIntentError: displayPaymentIntentInErrorCallback,
        accountViewModel,
    });
    const [stripeCardCompletion, setStripeCardCompletion] = useState<IStripeCardCompletion>({ isNumberCompleted: false, isExpiryCompleted: false, isCvcCompleted: false });
    const isStripeCardCompleted = useMemo(() => {
        return Object.values(stripeCardCompletion).every(value => value === true);
    }, [stripeCardCompletion]);
    const [pendingTransaction, setPendingTransaction] = useState<boolean>(false);
    const [internalShowLoader, setInternalShowLoader] = useState<boolean>(false);
    const [creditAmount, setCreditAmount] = useState<number>(props.intent?.fiatAmount ?? MIN__BUY_CREDIT_PAYMENT_AMOUNT);
    const [creditAmountErrorMessage, setCreditAmountErrorMessage] = useState<boolean>(false);
    const [email, setEmail] = useState(localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.EMAIL) ?? '');
    const emailInputElementRef = useRef<HTMLInputElement>(null);
    const assetViewModel: AssetViewModel | undefined = useSelector((state: any) => assetViewModelByIdSelector(state, (props.intent as IBuyAssetIntent).assetId));

    useEffect(() => {
        props.setCreditCardReady?.((!expandTrendexCheckoutForm && props.hasStripeCustomerId) || (!accountViewModel.isAuthenticated ? (isStripeCardCompleted && paymentRulesAccepted && isValidEmail(email)) : isStripeCardCompleted));
    }, [expandTrendexCheckoutForm, props.hasStripeCustomerId, isStripeCardCompleted, paymentRulesAccepted, email, props.setCreditCardReady, accountViewModel.isAuthenticated]);

    useEffect(() => {
        if (accountViewModel.Id === DEFAULT_ID_AS_STRING)
            return;
        setAllowUserToBuy(accountViewModel.PaymentCGUAccepted);
    }, [accountViewModel.Id, accountViewModel.PaymentCGUAccepted]);

    const emailValidatorComponent = (): JSX.Element | undefined => {
        if (isValidEmail(email) || email.length === 0)
            return undefined;
        return (
            <span className="invalid-email">
                <FontAwesomeIcon className='icon' icon={faTimesCircle} /> {i18next.t('register.mail.errorMessage')}
            </span>
        );
    };

    const propsAreValid = (): boolean => {
        if (props.mode === CheckoutModeEnum.NONE) {
            managementNotification(true, checkoutModeUnknownErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return false;
        }
        if (props.mode === CheckoutModeEnum.BUY_ASSET && !props.intent) {
            managementNotification(true, intentIsUndefinedErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return false;
        }
        if (props.mode === CheckoutModeEnum.MULTIPLE_BUY_ASSET && !props.multipleBuyIntent) {
            managementNotification(true, multipleBuyIntentIsUndefinedErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return false;
        }
        if (props.mode === CheckoutModeEnum.CREDIT_ACCOUNT && !props.creditAccountSuccessCallback) {
            managementNotification(true, creditAccountSuccessCallbackIsMissingErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return false;
        }
        if (props.mode === CheckoutModeEnum.BUY_ASSET && (!props.buyAssetSuccessCallback || !props.buyAssetInErrorCallback)) {
            managementNotification(true, buyAssetCallbackAreMissingErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return false;
        }
        if (props.mode === CheckoutModeEnum.BUY_ASSET && !('assetId' in props.intent!)) {
            managementNotification(true, intentIsNotWellTypedErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return false;
        }
        if ((props.mode === CheckoutModeEnum.BUY_BOOST_SCORE && !props.buyScoreIntent) && (!props.buyAssetSuccessCallback && !props.buyAssetInErrorCallback)) {
            managementNotification(true, buyScoreintentOrCallbackFxnIsUndefinedErrorMessage, TRENDEX_HTTP_STATUS_NOK);

            return false;
        }
        return true;
    };


    const handleSubmit = async (event?: FormEvent<Element> | (React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined)) => {
        if (event)
            event.preventDefault();
        if (!propsAreValid())
            return;
        if (!stripe || !elements) {
            managementNotification(true, stripeIsNotLoadedErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return;
        }
        if (props.showLoader)
            showLoader();
        setPendingTransaction(true);
        if (!props.fiatAmount || props.fiatAmount === 0) {
            managementNotification(true, i18next.t('payment.error.selectAmount'), TRENDEX_HTTP_STATUS_NOK);
            return;
        }
        if (props.fiatAmount < MIN_PAYMENT_AMOUNT) {
            managementNotification(true, i18next.t('payment.error.tooSmallAmount', { amount: MIN_PAYMENT_AMOUNT.toCurrency() }), TRENDEX_HTTP_STATUS_NOK);
            return;
        }
        switch (props.mode) {
            case CheckoutModeEnum.CREDIT_ACCOUNT:
                return handleCreditAccount();
            case CheckoutModeEnum.BUY_ASSET:
                return handleBuyAsset();
            case CheckoutModeEnum.MULTIPLE_BUY_ASSET:
                return handleMultipleBuy();
            case CheckoutModeEnum.BUY_BOOST_SCORE:
                return handleBuyBoost();
            case CheckoutModeEnum.BUY_TOURNAMENT_TICKET:
                return handleBuyTournamentTicket();
            default:
                managementNotification(true, unknownCheckoutModeErrorMessage, TRENDEX_HTTP_STATUS_NOK);
                return;
        }
    };

    const handleExternalSubmit = () => {
        if (!expandTrendexCheckoutForm && props.hasStripeCustomerId) {
            handleSubmit();
            return;
        }

        !paymentFormSubmitRef?.current?.disabled && paymentFormSubmitRef?.current?.click();
        return;
    };

    if (props.confirmationMethodRef) {
        props.confirmationMethodRef.current = handleExternalSubmit;
    }

    const handleMultipleBuy = () => {
        if (!props.multipleBuyIntent || (props.multipleBuyIntent && !props.multipleBuyIntent.primaryMarketData && !props.multipleBuyIntent.secondaryMarketData)) {
            managementNotification(true, multipleIntentNotFormattedWellErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return;
        }
        const multipleBuyOnPrimaryMarketNotEmpty: boolean = !!props.multipleBuyIntent.primaryMarketData && props.multipleBuyIntent.primaryMarketData.length > 0;
        const multipleBuyOnSecondaryMarketNotEmpty: boolean = !!props.multipleBuyIntent.secondaryMarketData && props.multipleBuyIntent.secondaryMarketData.length > 0;
        if (multipleBuyOnPrimaryMarketNotEmpty) {
            return handleMultipleBuyOnPrimaryMarket(multipleBuyOnSecondaryMarketNotEmpty);
        }
        if (multipleBuyOnSecondaryMarketNotEmpty) {
            return handleMultipleBuyOnSecondaryMarket();
        }
        managementNotification(true, multipleIntentArraysAreEmptyErrorMessage, TRENDEX_HTTP_STATUS_NOK);
        return;
    };

    const handleMultipleBuyOnPrimaryMarket = async (chainOnSecondaryMarket: boolean, primaryMarketData?: IBuyData[]) => {
        if (!primaryMarketData && !props.multipleBuyIntent?.primaryMarketData) {
            managementNotification(true, multipleIntentArraysAreEmptyErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return displayPaymentIntentInErrorCallback();
        }
        const getPaymentMethodIdResponse = await getPaymentMethodId();
        if (getPaymentMethodIdResponse.errorThrown)
            return displayPaymentIntentInErrorCallback();
        const paymentData: IBuyData[] = primaryMarketData ?? props.multipleBuyIntent!.primaryMarketData!;
        if (paymentData.length === 0)
            return paymentDone();
        const firstEntry: IBuyData = paymentData[0];
        const buyAssetPayload: IBuyOnPrimaryMarketProps = {
            assetId: firstEntry.assetId,
            amountOfShares: firstEntry.amountOfShares,
            isDirectBuy: firstEntry.isDirectBuy,
            transactionNotInErrorCallback: async (transactionData: any) => {
                await props.buyAssetSuccessCallback!(transactionData, (paymentData.length === 1 && !chainOnSecondaryMarket));
                if (paymentData.length === 1) {
                    if (chainOnSecondaryMarket)
                        return handleMultipleBuyOnSecondaryMarket();
                    return paymentDone();
                }
                handleMultipleBuyOnPrimaryMarket(chainOnSecondaryMarket, paymentData.slice(1));
            },
            transactionInErrorCallback: async (transactionData: any) => {
                await props.buyAssetInErrorCallback!(transactionData, (!props.multipleBuyIntent!.continueOnError || (paymentData.length === 1 && !chainOnSecondaryMarket)));
                if (!props.multipleBuyIntent!.continueOnError)
                    return paymentDone();
                if (paymentData.length === 1) {
                    if (chainOnSecondaryMarket)
                        return handleMultipleBuyOnSecondaryMarket();
                    return paymentDone();
                }
                handleMultipleBuyOnPrimaryMarket(chainOnSecondaryMarket, paymentData.slice(1));
            },
            doWithStripeSecretAndPaymentMethod: internalConfirmCard,
            paymentMethodId: getPaymentMethodIdResponse.paymentMethodId,
        };
        buyOnPrimaryMarket(buyAssetPayload);
    };

    const handleMultipleBuyOnSecondaryMarket = async (secondaryMarketData?: ISecondaryMarketPaymentData[]) => {
        if (!secondaryMarketData && !props.multipleBuyIntent?.secondaryMarketData) {
            managementNotification(true, multipleIntentArraysAreEmptyErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return;
        }
        const getPaymentMethodIdResponse = await getPaymentMethodId();
        if (getPaymentMethodIdResponse.errorThrown)
            return paymentDone();
        const paymentData: ISecondaryMarketPaymentData[] = secondaryMarketData ?? props.multipleBuyIntent!.secondaryMarketData!;
        if (paymentData.length === 0)
            return paymentDone();
        const firstEntry: ISecondaryMarketPaymentData = paymentData[0];
        const priceComputed: number = Number(firstEntry.unitaryPrice);
        const totalAmountOfShares: number = Number(firstEntry.amountOfShares);
        const priceOfOrder: number = firstEntry.unitaryPrice ?? (priceComputed / totalAmountOfShares);
        buyOnSecondaryMarket({
            data: {
                totalAmount: priceComputed,
                amountOfShares: firstEntry.amountOfShares,
                assetId: firstEntry.assetId,
                priceOfOrder,
                paymentMethodId: getPaymentMethodIdResponse.paymentMethodId,
                isDirectBuy: firstEntry.isDirectBuy,
                isBuyingFromOrderbook: firstEntry.isBuyingOrderFromOrderbook,
                sellingOrderId: firstEntry.sellingOrderIdFromOrderbook,
                directBuyFromTheBestSellingOrders: firstEntry.directBuyFromBestSellingOrders
            },
            transactionNotInErrorCallback: async (transactionData: any) => {
                await props.buyAssetSuccessCallback!(transactionData, paymentData.length === 1);
                if (paymentData.length === 1)
                    return paymentDone();
                handleMultipleBuyOnSecondaryMarket(paymentData.slice(1));
            },
            transactionInErrorCallback: async (transactionData: any) => {
                await props.buyAssetInErrorCallback!(transactionData, (!props.multipleBuyIntent!.continueOnError || paymentData.length === 1));
                if (!props.multipleBuyIntent!.continueOnError)
                    return paymentDone();
                if (paymentData.length === 1)
                    return paymentDone();
                handleMultipleBuyOnSecondaryMarket(paymentData.slice(1));
            },
            doWithStripeSecretAndPaymentMethod: internalConfirmCard,
        });
    };

    const getPaymentMethodId = async (): Promise<{ paymentMethodId: string | undefined, errorThrown: boolean; }> => {
        let internalPaymentMethodId = undefined;
        if (!props.hasStripeCustomerId || expandTrendexCheckoutForm) {
            const { error, paymentMethodId } = await createStripePaymentMehod();
            if (error) {
                managementNotification(true, error, TRENDEX_HTTP_STATUS_NOK);
                return {
                    paymentMethodId: internalPaymentMethodId,
                    errorThrown: true,
                };
            }
            internalPaymentMethodId = paymentMethodId;
        }
        return {
            paymentMethodId: internalPaymentMethodId,
            errorThrown: false,
        };
    };

    const handleBuyAsset = async () => {
        if (!props.intent) {
            managementNotification(true, buyAssetIntentUndefinedErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return;
        }
        const getPaymentMethodIdResponse = await getPaymentMethodId();
        if (getPaymentMethodIdResponse.errorThrown) {
            setPendingTransaction(false);
            hideLoader();
            return;
        }
        if (props.intent.concernedMarket === MarketEnum.PRIMARY) {
            handleBuyOnPrimaryMarket(getPaymentMethodIdResponse.paymentMethodId);
        }
        if (props.intent.concernedMarket === MarketEnum.SECONDARY) {
            handleBuyOnSecondaryMarket(getPaymentMethodIdResponse.paymentMethodId);
        }
    };

    const handleBuyOnSecondaryMarket = (paymentMethodId?: string) => {
        const buyAssetIntent = props.intent as IBuyAssetIntent;
        const priceComputed: number = Number(buyAssetIntent.fiatAmount);
        const totalAmountOfShares: number = Number(buyAssetIntent.amountOfShares);
        const priceOfOrder: number = buyAssetIntent.unitaryPrice ?? (priceComputed / totalAmountOfShares);
        buyOnSecondaryMarket({
            data: {
                totalAmount: buyAssetIntent.fiatAmount,
                amountOfShares: buyAssetIntent.amountOfShares,
                assetId: buyAssetIntent.assetId,
                priceOfOrder,
                paymentMethodId,
                isDirectBuy: buyAssetIntent.isDirectBuy,
                isBuyingFromOrderbook: buyAssetIntent.buyFromOrderbook?.isBuyFromOrderbook ?? false,
                sellingOrderId: buyAssetIntent.buyFromOrderbook?.sellingOrderId,
                directBuyFromTheBestSellingOrders: buyAssetIntent.directBuyFromTheBestSellingOrders,
                email: email || props.walletEmail || '',
                type: buyAssetIntent.type,
                currency: Utils.getMetadataForFakeUser(USER_METADATA.CURRENCY)
            },
            transactionNotInErrorCallback: async (transactionData: any) => {
                paymentDone();
                await props.buyAssetSuccessCallback!(transactionData, true);
            },
            transactionInErrorCallback: async (transactionData: any) => {
                paymentDone();
                await props.buyAssetInErrorCallback!(transactionData, true);
            },
            doWithStripeSecretAndPaymentMethod: internalConfirmCard,
        });
    };

    const handleBuyOnPrimaryMarket = (paymentMethodId?: string) => {
        const buyAssetIntent: IBuyAssetIntent = props.intent as IBuyAssetIntent;
        const buyAssetPayload: IBuyOnPrimaryMarketProps = {
            assetId: buyAssetIntent.assetId,
            amountOfShares: buyAssetIntent.amountOfShares,
            isDirectBuy: buyAssetIntent.isDirectBuy,
            transactionNotInErrorCallback: async (transactionData: any) => {
                paymentDone();
                await props.buyAssetSuccessCallback!(transactionData, true);
            },
            transactionInErrorCallback: async (transactionData: any) => {
                paymentDone();
                await props.buyAssetInErrorCallback!(transactionData, true);
            },
            doWithStripeSecretAndPaymentMethod: internalConfirmCard,
            paymentMethodId,
            email,
            currency: Utils.getMetadataForFakeUser(USER_METADATA.CURRENCY)
        };
        buyOnPrimaryMarket(buyAssetPayload);
    };

    const handleCreditAccount = async () => {

        let payload: any = { fiatAmount: props.intent?.fiatAmount || creditAmount };
        if (!props.hasStripeCustomerId || expandTrendexCheckoutForm) {
            const { error, paymentMethodId } = await createStripePaymentMehod();
            if (error) {
                hideLoader();
                managementNotification(true, error, TRENDEX_HTTP_STATUS_NOK);
                return;
            }
            payload = { ...payload, paymentMethodId };
        }
        creditAccount({
            ...payload,
            doWithStripeSecretAndPaymentMethod: internalConfirmCard,
            transactionNotInErrorCallback: (transactionData: any) => {
                paymentDone();
                if (props.trackEventOnPixel) {
                    trackDepositOnPixel(transactionData);
                }
                dispatch(getUserCredits());
                dispatch(managementModal(true, {
                    display: true,
                    disableBackDrop: true,
                    showBackArrow: false,
                    fullScreen: false,
                    showCancelButton: false,
                    close: () => {
                        if (props.creditAccountSuccessCallback)
                            props.creditAccountSuccessCallback(transactionData);
                    },
                    type: 'CONFIRM',
                    propsCustom: {
                        showCancelButton: false,
                        showLinkButton: false,
                        onRefused: () => dispatch(managementModal(false)),
                        textValidButton: i18next.t('Becomevippage.submitSuccess.confirm'),
                        onValidation: () => {
                            dispatch(managementModal(false));
                            if (props.creditAccountSuccessCallback)
                                props.creditAccountSuccessCallback(transactionData);
                        },
                        message: <SuccessPage />,
                    },
                }));
            },
            transactionInErrorCallback: (_transactionData: any) => {
                displayPaymentIntentInErrorCallback();
            },
        });
    };

    const handleBuyBoost = async () => {
        if (!props.buyScoreIntent) {
            managementNotification(true, buyScoreintentOrCallbackFxnIsUndefinedErrorMessage, TRENDEX_HTTP_STATUS_NOK);
            return;
        }
        const getPaymentMethodIdResponse: { paymentMethodId: string | undefined, errorThrown: boolean; } = await getPaymentMethodId();
        if (getPaymentMethodIdResponse.errorThrown) {
            setPendingTransaction(false);
            hideLoader();
            return;
        }
        const buyScoreBoostPayload: IBuyScoreBoostProps = {
            data: {
                fiatAmount: props.buyScoreIntent.fiatAmount,
                tournamentSubscriptionId: props.buyScoreIntent.subscribeId,
                paymentMethodId: getPaymentMethodIdResponse.paymentMethodId,
                email: email || props.walletEmail || '',
                currency: accountViewModel.Currency || Utils.getMetadataForFakeUser(USER_METADATA.CURRENCY),
            },
            transactionNotInErrorCallback: async (transactionData: any) => {
                paymentDone();
                await props.buyAssetSuccessCallback!(transactionData, true);
            },
            transactionInErrorCallback: async (transactionData: any) => {
                paymentDone();
                await props.buyAssetInErrorCallback!(transactionData, true);
                displayPaymentIntentInErrorCallback();
            },
            doWithStripeSecretAndPaymentMethod: internalConfirmCard,
        };
        buyForScoreBoost(buyScoreBoostPayload);
    };

    const internalBuildTournamentTicketPurchasePayload = async (buyTicketIntent: IBuyTournamentTicketIntent): Promise<IInitiateTournamentTicketPurchase | undefined> => {
        const getPaymentMethodIdResponse: { paymentMethodId: string | undefined, errorThrown: boolean; } = await getPaymentMethodId();
        if (getPaymentMethodIdResponse.errorThrown) {
            setPendingTransaction(false);
            hideLoader();
            return;
        }
        return {
            data: {
                fiatAmount: buyTicketIntent.fiatAmount,
                paymentMethodId: getPaymentMethodIdResponse.paymentMethodId,
                subscription: {
                    ...buyTicketIntent.subscription,
                },
            },
            transactionNotInErrorCallback: async (transactionData: any) => {
                paymentDone();
                await props.buyAssetSuccessCallback!(transactionData, true);
            },
            transactionInErrorCallback: async (transactionData: any) => {
                paymentDone();
                await props.buyAssetInErrorCallback!(transactionData, true);
                displayPaymentIntentInErrorCallback();
            },
            doWithStripeSecretAndPaymentMethod: internalConfirmCard,
        };
    }

    const handleBuyTournamentTicket = async () => {
        const buyTicketIntent: IBuyTournamentTicketIntent | undefined = props.intent as IBuyTournamentTicketIntent | undefined;
        if (!buyTicketIntent)
            throw new Error('Buy ticket intent is missing to continue the purchase');

        const intentPurchasePayload: IInitiateTournamentTicketPurchase | undefined = await internalBuildTournamentTicketPurchasePayload(buyTicketIntent);
        if (!intentPurchasePayload)
            return;
        initiateTicketPurchaseIntentForTournament(intentPurchasePayload);
    }


    const createStripePaymentMehod = async (): Promise<ICreatePaymentMethod> => {
        if (!stripe || !elements) {
            return {
                error: stripeElementsOrInstanceNullErrorMessage,
            };
        }
        const card = elements.getElement(CardNumberElement);
        if (!card)
            return {
                error: stripeCardNotFoundErrorMessage,
            };
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            card,
            type: cardPaymentMethod,
        } as CreatePaymentMethodCardData);
        return {
            error: error?.message,
            paymentMethodId: paymentMethod?.id,
        };
    };

    const trackDepositOnPixel = (transactionData: any) => {
        const pixelHandlerDeposit: PixelHandlerDeposit = new PixelHandlerDeposit(accountViewModel.StripeCustomerId === '');
        pixelHandlerDeposit.depositData = {
            currency: Application.getInstance().currency,
            value: props.fiatAmount,
        };
        pixelHandlerDeposit.handle();
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.DEPOSIT, {
            value: transactionData.amount.toCurrencyValue(),
            currency: transactionData.currency,
        });
        SegmentTracking.sendDepositEvent({ transactionData, accountViewModel });
    };

    const internalConfirmCard = async (clientSecret: string, paymentMethod: string): Promise<boolean> => {
        if (!stripe)
            return false;
        const confirmCardResult = await stripe.confirmCardPayment(clientSecret, { payment_method: paymentMethod });
        if (confirmCardResult.error) {
            paymentDone();
            managementNotification(true, confirmCardResult.error.message, TRENDEX_HTTP_STATUS_NOK);
            return false;
        }
        return confirmCardResult.paymentIntent.status === confirmCardStatusOk;
    };

    const paymentDone = () => {
        setPendingTransaction(false);
        if (props.showLoader)
            hideLoader();
    };

    const showLoader = () => {
        setInternalShowLoader(true);
    };

    const hideLoader = () => {
        setInternalShowLoader(false);
    };


    const internalOnCreditAmountChanges = (event: any) => {
        if (isNaN(event.target.value)) return;
        const userInput: number = event.target.value;
        if (userInput < MIN__BUY_CREDIT_PAYMENT_AMOUNT)
            setCreditAmountErrorMessage(true);
        else
            setCreditAmountErrorMessage(false);
        setCreditAmount(userInput);
    };

    const getCreditInputComponent = (): JSX.Element => {
        return (<div>
            {(props.mode === CheckoutModeEnum.CREDIT_ACCOUNT && ((props.intent as ICreditAccountIntent).displayCreditForm)) &&
                <div className="amount-tokens">
                    <h2 style={{ marginTop: '10px' }}>{i18next.t('Credits.getYourCredits')}</h2>
                    <div className="credit-container">
                        <input className="buy-credits-input" placeholder={i18next.t('buy-credits-input', { currency: Application.getInstance().currencySymbol })} type="number" value={creditAmount} onChange={internalOnCreditAmountChanges} />
                        <span className="currency-logo">{Application.getInstance().currencySymbol}</span>
                    </div>
                </div>}
            {props.mode === CheckoutModeEnum.CREDIT_ACCOUNT && creditAmountErrorMessage && <span className="amount-error-message">{i18next.t('buycredits.minimal-amount-credit-to-buy', { amount: MIN__BUY_CREDIT_PAYMENT_AMOUNT.toCurrency() })}</span>}
        </div>);
    };

    const internalAcceptPaymentCGU = () => {
        setPaymentRulesAccepted(prev => !prev);
        if (accountViewModel.isAuthenticated)
            acceptPaymentCGU();
    };

    const handleStripeCardCompletion = (completion: Partial<IStripeCardCompletion>) => {
        setStripeCardCompletion(prev => ({ ...prev, ...completion }));
    };

    const renderPaymentForm = (hasStripeCustomerId: boolean): JSX.Element => {
        const isAnonymous: boolean = !accountViewModel.isAuthenticated;
        const initialForm: JSX.Element = <form id="payment-form" onSubmit={handleSubmit} className={clsx({ hidden: !isAnonymous && !props.newCardForm })} style={{ width: '100%' }}>
            <div className="payment-form-information">
                <div className={clsx('email-element', { hidden: !isAnonymous })}>
                    <label className={clsx({ hidden: !isAnonymous })}>{i18next.t('cardpayment.label.email')}</label>
                    <input
                        ref={emailInputElementRef}
                        id="name"
                        name="email"
                        type={!isAnonymous ? "hidden" : "email"}
                        placeholder="Email"
                        required
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    {emailValidatorComponent()}
                </div>

                <div className="stripe-element">
                    <div className="stripe-card-number">
                        <label>{i18next.t('cardpayment.label.card-number')}</label>
                        <CardNumberElement
                            className="custom-card-number-element"
                            options={stripeCardNumberStyle}
                            onChange={(e: StripeCardNumberElementChangeEvent) => handleStripeCardCompletion({ isNumberCompleted: e.complete })}
                        />
                    </div>
                    <div className='stripe-expiry-date-cvc-number'>
                        <div className="stripe-expiry-date">
                            <label>{i18next.t('cardpayment.label.expiry-date')}</label>
                            <CardExpiryElement
                                onChange={(e: StripeCardExpiryElementChangeEvent) => handleStripeCardCompletion({ isExpiryCompleted: e.complete })}
                            />
                        </div>
                        <div className="stripe-cvc-number">
                            <label>{i18next.t('cardpayment.label.security-code')}</label>
                            <CardCvcElement
                                onChange={(e: StripeCardCvcElementChangeEvent) => handleStripeCardCompletion({ isCvcCompleted: e.complete })}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <GreenButtonWithGradient
                type="submit"
                receiveClickEvent={false}
                disabled={!isStripeCardCompleted || pendingTransaction || creditAmountErrorMessage}
                textButton={i18next.t('confirm')}
                ref={paymentFormSubmitRef}
            />
            <div className="separator"></div>
        </form>;

        if (!hasStripeCustomerId)
            return initialForm;

        return <>
            <Accordion
                className={clsx("accordion-container", { hidden: !props.newCardForm })}
                classes={accordionClasses}
                expanded={expandTrendexCheckoutForm} >
                <AccordionSummary
                    classes={{
                        content: 'override-accordion-summary accordion',
                    }}
                    onClick={() => setExpandNewPaymentForm(true)}
                    expandIcon={''}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <p className="no-margin stripe-title accordion-title">{i18next.t('paymentForm.payWithAnotherCard')}</p>
                </AccordionSummary>
                <AccordionDetails>
                    {initialForm}
                </AccordionDetails>
            </Accordion>
        </>;
    };

    const getCheckoutForm = (): JSX.Element => {
        const isBuyAsset: boolean = props.mode === CheckoutModeEnum.BUY_ASSET && ('assetId' in props.intent!);
        return (
            <div className="trendex-checkout-form">
                <LoadingWrapper
                    waitingOperation={() => accountIsLoading}
                    child={<>
                        {allowUserToBuy && getCreditInputComponent()}
                        <div>
                            {internalShowLoader && !isBuyAsset && <CircularLoader
                                loaderMessage={i18next.t('trendexloading')}
                                loaderLocal={false}
                                showButton={false}
                                doNotSplitMessage={true} />}

                            {internalShowLoader && isBuyAsset && <CreditCardLoader showBuyExplainer={true} />}
                        </div>
                        {(allowUserToBuy || !accountViewModel.isAuthenticated) && (
                            renderPaymentForm(props.hasStripeCustomerId)
                        )}
                        {((!allowUserToBuy && accountViewModel.isAuthenticated && !accountViewModel.PaymentCGUAccepted) || !accountViewModel.isAuthenticated) &&
                            <CheckboxCGU isChecked={paymentRulesAccepted} handleClick={internalAcceptPaymentCGU} termsText={assetViewModel && assetViewModel.enableCustomAssetProfil() ? 'cgu.label.benzema' : undefined} additionnalLinks={[TERMS_DRAW_LINKS[Utils.getCurrentLanguage() as 'fr'] ?? TERMS_DRAW_LINKS[TRENDEX_LANGUAGES.FRENCH]]} />
                        }
                    </>} />
            </div>
        );
    };

    return {
        isStripeCardCompleted,
        pendingTransaction,
        internalShowLoader,
        handleSubmit,
        handleBuyOnPrimaryMarket,
        handleBuyOnSecondaryMarket,
        getCreditInputComponent,
        getCheckoutForm,
    };
};

export default useTrendexCheckoutForm;
