/* eslint-disable react-hooks/exhaustive-deps */
import {
    CardPayment as primaryMarketStripePayment,
    secondaryMarketStripePayment, getStatusAssetPayment as checkTransactionForPrimaryMarket,
    getTransactionPayment as checkTransactionForSecondaryMarket,
    CreditAccountByCard,
    unsafeDropPaymentData,
    boostTeamScoreStripePayment,
    actionInitiateTicketPurchaseIntentForTournament
} from '../../../service/payment/actions';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxRootState, RootState } from '../../../model/Redux';
import ITrendexStripePaymentProps from './ITrendexStripePaymentProps';
import IBuyWithStripeProps from './IBuyAssetProps';
import IBuyOnPrimaryMarketProps from './IBuyOnPrimaryMarketProps';
import IBuyOnSecondaryMarket from './IBuyOnSecondaryMarket';
import IUseTrendexStripePayment from './IUseTrendexStripePayment';
import { MarketEnum } from '../../../config/_enums';
import { managementModal } from '../../../service/modal/action';
import i18next from 'i18next';
import { DEFAULT_EXPONENTIAL_BACKOFF_MULTIPLIER_CHECK_TRANSACTIONS, HTTP_STATUS_INTERNAL_ERROR, HTTP_STATUS_OK, TRANSACTIONS_STATUS } from '../../../config/_const';
import IPaymentIntentData from './IPaymentIntentData';
import IPaymentState from './IPaymentState';
import ICreditAccount from './ICreditAccount';
import { isEmpty } from 'lodash';
import Application from 'Application';
import Utils from 'utils/Utils';
import i18n from '../../../18n';
import { managementNotification } from '../../../service/notification/action';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import IBuyScoreBoostProps from './IBuyScoreBoostProps';
import IInitiateTournamentTicketPurchase from './IInitiateTournamentTicketPurchase';

const useTrendexStripePayment = (props: ITrendexStripePaymentProps): IUseTrendexStripePayment => {
    const dispatch = useDispatch();
    const [anonymousData, setAnonymousData] = useState<{ user: AccountViewModel | undefined, token: string | undefined }>({ user: undefined, token: undefined });
    const [userId, setUserId] = useState<string>(props.accountViewModel.Id);
    const pendingPaymentRequestStatus: string = 'PENDING';
    const errorPaymentRequestStatus: string = 'ERROR';
    const paymentGlobalState: ReduxRootState = useSelector((state: RootState) => state.payment);
    const [transactionId, setTransactionId] = useState<string>();
    const [paymentIntentData, setPaymentIntentData] = useState<IPaymentIntentData>();
    const [paymentState, setPaymentState] = useState<IPaymentState>({
        market: MarketEnum.NONE,
        paymentSucceded: false
    });
    const isPaymentIntentInError = () => (!paymentGlobalState.data?.paymentIntent.data) || (paymentGlobalState.loading === false && paymentGlobalState.data.paymentIntent.data && paymentGlobalState.data.paymentIntent.status !== HTTP_STATUS_OK);
    const isCreditTransaction = () => paymentGlobalState.data.intentStatus && paymentGlobalState.data.transactionStatus;
    const isBuyAssetTransaction = () => paymentGlobalState.data.assetStatus;
    const timeBeforeLoginAnonymousUser: number = 3000;
    const [nbCallCheckTransactionStatus, setNbCallCheckTransactionStatus] = useState<number>(0);
    let timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (!anonymousData.user)
            return;
        setUserId(anonymousData.user.Id);
    }, [anonymousData]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current)
                clearTimeout(timeoutRef.current);
        }
    }, []);

    useEffect(() => {
        if (paymentGlobalState.loading)
            return;
        if (paymentGlobalState.error) {
            if (props.callbackOnPaymentIntentError)
                props.callbackOnPaymentIntentError(paymentGlobalState.error);
            dispatch(unsafeDropPaymentData());
            return;
        }
        if (!paymentGlobalState.data || Object.keys(paymentGlobalState.data).length === 0)
            return;
        if (paymentGlobalState.data.paymentIntent) {
            if (paymentGlobalState.data.paymentIntent.data.user && paymentGlobalState.data.paymentIntent.data.token && !props.accountViewModel.isAuthenticated)
                setAnonymousData({ user: new AccountViewModel({ account: paymentGlobalState.data.paymentIntent.data.user, isAuthenticated: true }), token: paymentGlobalState.data.paymentIntent.data.token });
            handlePayment();
        }
        if (isCreditTransaction() || isBuyAssetTransaction() && props.polTransactions)
            handleTransactions();
    }, [paymentGlobalState]);

    useEffect(() => {
        if (!paymentIntentData || !paymentIntentData.transactionId)
            return;
        setTransactionId(paymentIntentData.transactionId);
    }, [paymentIntentData]);

    useEffect(() => {
        if (!transactionId)
            return;
        internalLaunchGetTransactionStatusRequest(transactionId);
    }, [transactionId]);

    useEffect(() => {
        if (paymentState.paymentSucceded === true && nbCallCheckTransactionStatus > 0)
            return setNbCallCheckTransactionStatus(0);
    }, [paymentState])

    const handleTransactions = () => {
        const transactionStatus = paymentGlobalState.data.assetStatus ?? paymentGlobalState.data.intentStatus.status;
        if (![pendingPaymentRequestStatus, errorPaymentRequestStatus].includes(transactionStatus) || paymentGlobalState.data.transactionStatus === TRANSACTIONS_STATUS.SUCCESS) {
            setPaymentState((state: IPaymentState) => { return { ...state, paymentSucceded: true } });
            if (paymentState.transactionNotInErrorCallback) {
                paymentState.transactionNotInErrorCallback(paymentGlobalState.data);
                if (anonymousData.user && anonymousData.token)
                    setTimeout(() => {
                        dispatch({
                            type: 'AUTH_SUCCESS',
                            payload: {
                                token: anonymousData.token,
                                user: anonymousData.user,
                            },
                        });
                    }, timeBeforeLoginAnonymousUser);
            }
            return;
        }
        if (transactionStatus === errorPaymentRequestStatus) {
            setPaymentState((state: IPaymentState) => { return { ...state, paymentSucceded: true } });
            if (paymentState.transactionInErrorCallback)
                return paymentState.transactionInErrorCallback(paymentGlobalState.data);
            return;
        }
        timeoutRef.current = setTimeout(() => {
            internalLaunchGetTransactionStatusRequest(transactionId!);
        }, nbCallCheckTransactionStatus * DEFAULT_EXPONENTIAL_BACKOFF_MULTIPLIER_CHECK_TRANSACTIONS);
    }

    const internalLaunchGetTransactionStatusRequest = (internalTransactionId: string) => {
        setNbCallCheckTransactionStatus(nbCallCheckTransactionStatus + 1);
        switch (paymentState.market) {
            case MarketEnum.PRIMARY:
                dispatch(checkTransactionForPrimaryMarket(internalTransactionId, userId));
                break;
            case MarketEnum.SECONDARY:
                dispatch(checkTransactionForSecondaryMarket(internalTransactionId, userId));
                break;
            default:
                break;
        }
    }

    const handlePayment = async () => {
        if (isPaymentIntentInError()) {
            if (props.callbackOnPaymentIntentError) {
                props.callbackOnPaymentIntentError(paymentGlobalState.error);
                dispatch(unsafeDropPaymentData());
            }
            else
                return dispatch(managementModal(true, {
                    display: true,
                    disableBackDrop: false,
                    showBackArrow: false,
                    fullScreen: false,
                    type: 'CONFIRM',
                    propsCustom: {
                        showCancelButton: false,
                        showLinkButton: false,
                        onValidation: () => {
                            dispatch(managementModal(false))
                        },
                        textValidButton: i18next.t('cardpayment.error-modal.validation'),
                        message: i18next.t('cardpayment.error-modal.error'),
                    },
                }));
        }
        await checkForPaymentIntentStatus();
    }

    const checkForPaymentIntentStatus = async () => {
        if (paymentState.market === MarketEnum.NONE)
            return;

        const paymentIntent = paymentGlobalState.data.paymentIntent;
        if (!paymentIntent || !paymentIntent.data || !paymentIntent.data.paymentMethod)
            return;
        if (isEmpty(paymentIntent.data.paymentMethod.data) && props.callbackOnPaymentIntentError) {
            props.callbackOnPaymentIntentError(paymentGlobalState.error);
            return dispatch(unsafeDropPaymentData());
        }
        if (paymentState.doWithStripe) {
            const continueExecution = await paymentState.doWithStripe(paymentIntent.data.secretIntent, paymentIntent.data.paymentMethod.data[0].id);
            if (!continueExecution)
                return;
        }
        setPaymentIntentData(paymentIntent.data);
    }

    const beforePaymentHook = (input: IBuyWithStripeProps, market: MarketEnum) => {
        if (input.doBeforePayment)
            input.doBeforePayment();
        setPaymentState({
            market: market,
            callbackBeforeDefaultPaymentIntentErrorHandling: input.callbackBeforeDefaultPaymentIntentErrorHandling,
            doWithStripe: input.doWithStripeSecretAndPaymentMethod,
            transactionNotInErrorCallback: input.transactionNotInErrorCallback,
            transactionInErrorCallback: input.transactionInErrorCallback,
            paymentSucceded: false
        });
    }

    const buyOnPrimaryMarket = async (inputs: IBuyOnPrimaryMarketProps): Promise<void> => {
        beforePaymentHook(inputs, MarketEnum.PRIMARY);
        dispatch(primaryMarketStripePayment(inputs.assetId, inputs.amountOfShares, inputs.isDirectBuy, inputs.paymentMethodId, inputs.email, inputs.currency));
    }

    const buyOnSecondaryMarket = async (inputs: IBuyOnSecondaryMarket): Promise<void> => {
        beforePaymentHook(inputs, MarketEnum.SECONDARY);
        dispatch(secondaryMarketStripePayment(inputs.data));
    }

    const creditAccount = async (inputs: ICreditAccount): Promise<void> => {
        beforePaymentHook(inputs, MarketEnum.SECONDARY);
        const euroEquivalent: number | undefined = await Utils.getEuroEquivalent(Application.getInstance().currency, Number(inputs.fiatAmount));
        if (!euroEquivalent) {
            if (props.callbackOnPaymentIntentError)
                return props.callbackOnPaymentIntentError(i18n.t('forex.not.found'));
            managementNotification(true, i18n.t('forex.not.found'), HTTP_STATUS_INTERNAL_ERROR);
            return;
        }
        inputs.fiatAmount = euroEquivalent;
        dispatch(CreditAccountByCard(inputs.fiatAmount, inputs.paymentMethodId));
    }

    const buyForScoreBoost = async (inputs: IBuyScoreBoostProps): Promise<void> => {
        beforePaymentHook(inputs, MarketEnum.SECONDARY);
        dispatch(boostTeamScoreStripePayment(inputs.data));
    }

    const initiateTicketPurchaseIntentForTournament = async (inputs: IInitiateTournamentTicketPurchase): Promise<void> => {
        beforePaymentHook(inputs, MarketEnum.SECONDARY);
        dispatch(actionInitiateTicketPurchaseIntentForTournament(inputs.data));
    }

    return {
        paymentLoading: paymentGlobalState.loading,
        buyOnPrimaryMarket,
        buyOnSecondaryMarket,
        creditAccount,
        buyForScoreBoost,
        initiateTicketPurchaseIntentForTournament,
    }
};

export default useTrendexStripePayment;