import { G_TAGS_KNOWN_EVENTS } from "config/_const";
import TagManager from "react-gtm-module";
import Utils from "utils/Utils";
import IAssetTransactionViewModel from "view-model/AssetTransactionViewModel/IAssetTransactionViewModel";

class CustomGoogleTagManager {
    static readonly gtmId: string = "GTM-P57CD6SJ";
    private googleTagManager: any = { ...TagManager };
    constructor() {
        this.googleTagManager.initialize({
            gtmId: CustomGoogleTagManager.gtmId,
        });
    }

    public sendEvent(eventName: string, eventData?: object, dataLayerName?: string) {
        if (process.env.NODE_ENV !== 'production')
            return;
        this.googleTagManager.dataLayer({});
        this.googleTagManager.dataLayer({
            dataLayer: {
                event: eventName,
                ...eventData
            },
            dataLayerName
        });
    };
    public sendPurchaseEventCustom = (assetTransactionViewModel: IAssetTransactionViewModel, transactionIdPrefix: string, userEmail: string) => {
        const eventData = {
            userEmail,
            transactionId: `${transactionIdPrefix};${assetTransactionViewModel.AssetId};${Utils.uid()}`,
            assetName: assetTransactionViewModel.Asset.AssetName,
            purchaseAmount: assetTransactionViewModel.Amount,
            currency: assetTransactionViewModel.Currency,
            assetCategory: assetTransactionViewModel.Asset.MainCategoryId,
        };
        this.sendEvent(G_TAGS_KNOWN_EVENTS.PURCHASE, eventData);
    };
}

export default new CustomGoogleTagManager();