import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import { ITournamentResultReducer } from '../../model/Tournament/ITournamentResult';
import * as ActionTypesTournamentResult from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<ITournamentResultReducer> = {
    loading: false,
    data: {},
    error: '',
};

export const tournamentResultReducer = (state: ReduxRootState<ITournamentResultReducer> = ROOT_VALUE_STATE, action: ReduxActionInterface): ReduxRootState<ITournamentResultReducer> => {
    switch (action.type) {
        case ActionTypesTournamentResult.TOURNAMENT_RESULT_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ActionTypesTournamentResult.TOURNAMENT_RESULT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        case ActionTypesTournamentResult.TOURNAMENT_RESULT_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.tournamentId]: action.payload.tournamentResults,
                },
                loading: false,
            };
        default: return state;
    }
};
