import React from "react";
import ILineBreaksProps from "./ILineBreaksProps";

const LineBreaks: React.FC<ILineBreaksProps> = ({ str }) => {
    const linefeedBlock = (str || '').split('\n');
    if (!linefeedBlock)
        return <p>{str}</p>;
    return (
        <p className='asset-description-see-more'>
            {linefeedBlock.map((sentence: string, index: number) => <React.Fragment key={index}>{sentence}<br /></React.Fragment>)}
        </p>
    );
};

export default LineBreaks;