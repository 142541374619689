import React from "react";
import OnboardingLightWallet from "./OnboardingLightWallet"
import './OnboardingLightWallet.scss';
import OnboardingLightWalletButton from "../OnboardingLightWalletButton/OnboardingLightWalletButton";
import useAccount from "hooks/use-account/useAccount";
import IUseAccount from "hooks/use-account/IUseAccount";
import { useDispatch } from "react-redux";

const OnboardingLightWalletWrapper: React.FC = () => {
    const dispatch = useDispatch();
    const { updateOnboardingStep }: IUseAccount = useAccount();

    return (
        <div className="wallet-view-onboarding">
            <OnboardingLightWallet />
            <OnboardingLightWalletButton onLastStepClicked={() => dispatch(updateOnboardingStep())} />
        </div>
    )
}

export default OnboardingLightWalletWrapper;