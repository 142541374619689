import { ITournamentWinReducer } from 'model/Tournament/ITournamentWin';
import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import { RESET_STATE } from '../commonActionTypes';
import { WIN_RESULTS } from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<ITournamentWinReducer> = {
    loading: false,
    data: {},
    error: '',
};

export const tournamentWinsReducer = (state: ReduxRootState<ITournamentWinReducer> = ROOT_VALUE_STATE, action: ReduxActionInterface): ReduxRootState<ITournamentWinReducer> => {
    switch (action.type) {
        case WIN_RESULTS.LOADING:
            return {
                ...state,
                loading: true,
            };

        case WIN_RESULTS.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        case WIN_RESULTS.SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    allWins: action.payload.allWins.data,
                },
                loading: false,
            };
        case RESET_STATE:
            return ROOT_VALUE_STATE;
        default: return state;
    }
};
