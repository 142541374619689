import React from 'react'

const TournamentIcon = () => {
    return (<svg className="tournament_svg" xmlns="http://www.w3.org/2000/svg" width="26.205" height="25.645" viewBox="0 0 26.205 25.645">
        <g id="Leagues" transform="translate(0.275 0.345)">
            <path id="Tracé_229" data-name="Tracé 229" d="M0,0H24V24H0Z" transform="translate(0.828 0.517)" opacity="0" />
            <g id="Managers" transform="translate(0 0)">
                <path id="Soustraction_37" data-name="Soustraction 37" d="M13.181,24.941V19.652a7.9,7.9,0,0,0,1.335-.175v3.5a14.888,14.888,0,0,0,2.823-1.916,14.558,14.558,0,0,0,3.182-3.849,21.626,21.626,0,0,0,1.49-3.334C22.4,12.8,22.765,11.6,23.1,10.3c.588-2.295.934-4.319,1.088-5.321L14.516,1.818V4.645a7.9,7.9,0,0,0-1.335-.175V0L25.656,4.073l-.069.535c-.044.337-1.1,8.319-3.9,13.243a15.675,15.675,0,0,1-7.61,6.77l-.9.32Zm-.707,0h0l-.9-.32a15.678,15.678,0,0,1-7.61-6.77C1.169,12.927.113,4.946.069,4.608L0,4.073,12.475,0V4.468a7.9,7.9,0,0,0-1.335.172V1.818L1.471,4.975c.154,1,.5,3.026,1.088,5.321.333,1.3.7,2.5,1.086,3.58a21.624,21.624,0,0,0,1.49,3.334,14.585,14.585,0,0,0,3.186,3.858A14.739,14.739,0,0,0,11.14,22.98v-3.5a7.9,7.9,0,0,0,1.335.172Z" transform="translate(0 0)" fill="#15182b" stroke="#15182b" strokeWidth="0.5" />
                <g id="Rookies" transform="translate(7.547 3.125)">
                    <path id="Tracé_3792" data-name="Tracé 3792" d="M5060.487,4876.915l-2.513,2.623h1.151l-1.052,5.454-2.31-3.157h-1.813l-1.085,6.3,1.456,1.468,1.044-5.559,2.32,3.208h1.788l1.421-7.715h.975l-1.382-2.623m.145-1.03,2.245,4.262H5061.4l-1.421,7.714h-2.6l-1.673-2.313-1,5.307-2.493-2.513,1.225-7.116h2.634l1.668,2.281.649-3.36h-1.84Z" transform="translate(-5051.886 -4875.369)" />
                    <path id="Tracé_3792_-_Contour" data-name="Tracé 3792 - Contour" d="M5056.739,4869.107l2.675,5.081h-1.726l-1.421,7.714h-3.012l-1.351-1.866-.974,5.182-3.009-3.036,1.295-7.522h3.044l1.348,1.843.447-2.314h-2.183Zm-.145,1.03h0l-1.113,1.162,1.113-1.162Zm.806,2.834-.951-1.8-1.729,1.8h.809l-1.256,6.5-2.629-3.6h-1.4l-1.015,5.895.938.946,1.068-5.685,2.644,3.654h1.379l1.42-7.714Zm-2.608.607h0Zm3.615,0h-1.225l-1.421,7.714h-2.2l-2-2.759h0l2,2.759h2.2l1.421-7.714Zm-8.679,1.688h0l-1.155,6.708,1.974,1.991.615-3.271-.615,3.271-1.974-1.991Zm2.224,0h0l1.989,2.719h0Z" transform="translate(-5047.921 -4869.107)" stroke="#15182b" strokeLinecap="round" strokeWidth="0.5" />
                </g>
            </g>
        </g>
    </svg>
    )
}

export default TournamentIcon;