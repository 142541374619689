import { DISCORD_NAME } from 'config/_const';
import i18next from 'i18next';
import React from 'react'
import DiscordIcon from '../DiscordIcon/DiscordIcon';
import './JoinDiscordBlock.scss'


const JoinDiscordBlock: React.FC = () => {
    const openDiscord = () => window.open(`https://discord.gg/${DISCORD_NAME}`, '_blank')

    return (
        <div onClick={openDiscord} className='join-discord-block hover'>
            <div className='icon-circle'>
                <span className='circle'>
                    <DiscordIcon />
                </span>
            </div>
            <div className='text'>{i18next.t('btn-discord-title')}</div>
        </div>
    );
};

export default JoinDiscordBlock;