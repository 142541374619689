import ISportsFootballPlayers, { ISportsFootballPlayersInfo } from "interfaces/sports/ISportsPlayer";
import { ReduxRootState } from "model/Redux";
import ReduxStoreInterface from "model/ReduxStoreInterface";
import { Selector } from "react-redux";
import { createSelector } from "reselect";

export const sportsPlayersSelector: Selector<ReduxStoreInterface, ReduxRootState<ISportsFootballPlayers[]>> = state => state.sportsPlayers;
export const isSportsPlayersSelectorLoading: Selector<ReduxStoreInterface, boolean> = state => state.sportsPlayers.loading;
export const sportsPlayersSelectorWithInfo = createSelector<ReduxStoreInterface, ReduxRootState<ISportsFootballPlayers[]>, ISportsFootballPlayersInfo[] >(
    sportsPlayersSelector,
    (sportsPlayers) => {
        if (sportsPlayers.data.length === 0) return [];

        return sportsPlayers.data.map((footballPlayer: ISportsFootballPlayers) => {
            const {player } = footballPlayer;
            const { id, name, firstname, lastname } = player;
            return {
                id, name, firstname, lastname
            };
        })
    },
);