import { selectBottomBar } from './../../service/bottombar/selector';
import { useSelector, useDispatch } from "react-redux"
import { toggleBottomBar } from 'service/bottombar/action';

const useBottomBar = () => {
    const { isOpen } = useSelector(selectBottomBar);
    const dispatch = useDispatch();

    const hideBottomBar = () => {
        dispatch(toggleBottomBar({ isOpen: false }));
    }

    const showBottomBar = () => {
        dispatch(toggleBottomBar({ isOpen: true }));
    }


    return { isOpen, hideBottomBar, showBottomBar }
}

export default useBottomBar;