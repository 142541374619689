import i18n from '18n';
import benzemaJerseyImg from 'assets/images/Benzema-club/benzema-jersey.png';
import JeddahImg from 'assets/images/Benzema-club/jeddah.png';
import playTOurnamentImg from 'assets/images/Benzema-club/play_tournament.png';
import tokenChangeImg from 'assets/images/Benzema-club/token_evolution.png';
import ShirtIcon from 'components/icons/BenzemaClub/ShirtIcon';
import TournamentBadge from 'components/icons/BenzemaClub/TournamentBadge';
import Trends from 'components/icons/BenzemaClub/Trends';
import Team from 'components/icons/BenzemaClub/custom_profil/icons/team.webp';
import Fans from 'components/icons/BenzemaClub/custom_profil/fans.png';
import Zap from 'components/icons/BenzemaClub/custom_profil/icons/zap.png';
import BluePlane from 'components/icons/BenzemaClub/blue-plane/BluePlane';
import { IListCardInformation } from 'components/small/list-card/ListCardProps';
import { TRENDEX_STAT, TRENDS_DATA } from 'config/_const';
import { academyThumbnail2 } from 'data/YoutubeVideo/youtubeVideo.data';
import IBenzemaClubSection from 'page/AssetProfilPage/local-components/Benzema-club-section/BenzemaClubSectionProps';
import { ICardInformation } from 'page/AssetProfilPage/local-components/ClubCard/ClubCardProps';
import React from 'react';
import AssetViewModel from 'view-model/Assets/AssetViewModel';


const withBenzemaClub = (Component: React.FC<IBenzemaClubSection>) => {
    const MemoComponent = React.memo(Component);

    const Wrapper: React.FC<{ assetViewModel: AssetViewModel, withLightAssetProfil: boolean }> = ({ assetViewModel, withLightAssetProfil }) => {
        const i18nPrefix: string = assetViewModel.isCustomBenzemaProfilPageBeforePublicSales() ? 'benzema.early.bird.' : '';
        const cardInformationList: ICardInformation[] = [
            {
                id: "R1",
                show: !assetViewModel.isCustomBenzemaProfilPageBeforePublicSales(),
                label: i18n.t("club.title1.signed.jersey"),
                value: `${i18n.t("club.subtitle1")}`,
                img: benzemaJerseyImg,
                icon: <ShirtIcon />,
            },
            {
                id: "R2",
                show: assetViewModel.isCustomBenzemaProfilPageBeforePublicSales(),
                label: i18n.t("benzema.early.bird.club.team.title"),
                value: `${i18n.t("benzema.early.bird.club.team.subtitle")}`,
                img: academyThumbnail2,
                icon: <img src={Team} style={{ width: '30px' }} />,
            },
            {
                id: "R3",
                show: !assetViewModel.isCustomBenzemaProfilPageBeforePublicSales(),
                label: i18n.t(`${i18nPrefix}article.win.title.footsteps`),
                value: `${i18n.t(`${i18nPrefix}article.win.subtitle.footsteps`)}`,
                img: JeddahImg,
                icon: <BluePlane />,
            },
            {
                id: "R4",
                show: assetViewModel.isCustomBenzemaProfilPageBeforePublicSales(),
                label: i18n.t(`${i18nPrefix}article.win.title.footsteps`),
                value: `${i18n.t(`${i18nPrefix}article.win.subtitle.footsteps`)}`,
                img: Fans,
                icon: <img src={Zap} style={{ width: '30px' }} />,
            },
            {
                id: "R5",
                show: true,
                label: i18n.t("club.title2.tournament"),
                value: `${i18n.t(`${i18nPrefix}club.subtitle2`)}`,
                img: playTOurnamentImg,
                icon: <TournamentBadge />,
            },
            {
                id: "R6",
                show: true,
                label: i18n.t(`${i18nPrefix}club.title3.trend`),
                value: `${i18n.t(`${i18nPrefix}club.subtitle3`)}`,
                img: tokenChangeImg,
                icon: <Trends />,
            },

        ];
        const trendDetailList: IListCardInformation[] = [
            {
                id: "T1",
                show: true,
                label: i18n.t("trends.vip.pack"),
                value: TRENDS_DATA.VIP_PACK,
            },
            {
                id: "T2",
                show: true,
                label: i18n.t("trends.whitelist"),
                value: TRENDS_DATA.WHITELISTED,
            },
            {
                id: "T3",
                show: true,
                label: i18n.t("trends.public.supply"),
                value: TRENDS_DATA.PUBLIC_SUPPLY,
            }
        ];
        const statList = [
            {
                id: "C1",
                show: true,
                label: i18n.t("stat.contracted.talents"),
                value: TRENDEX_STAT.CONTRACTED_TALENTS,
            },
            {
                id: "C2",
                show: true,
                label: i18n.t("stat.trends.sold"),
                value: TRENDEX_STAT.SOLD_TRENDS,
            },
            {
                id: "C3",
                show: true,
                label: i18n.t("stat.redistributed.players"),
                value: TRENDEX_STAT.REDISTRIBUTED_TO_PLAYERS,
            }
        ];

        return <MemoComponent withLightAssetProfil={withLightAssetProfil} assetViewModel={assetViewModel} cardInformationList={cardInformationList} trendDetailList={trendDetailList} statList={statList} />
    }

    return React.memo(Wrapper);
}

export default withBenzemaClub;