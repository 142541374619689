import * as React from 'react';

const ChartWaterfall = ({ height = "20px", width = "20px", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg id="chart-waterfall-svg" height={height} width={width}  {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g id="Rectangle_2014" data-name="Rectangle 2014" fill="#8187d0" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="20" height="20" stroke="none" />
                <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
            </g>
            <g id="chart-waterfall-duotone" transform="translate(2 3.177)">
                <path id="Tracé_3197" data-name="Tracé 3197" d="M134.879,64a.982.982,0,0,1,.983.983v1.965a.983.983,0,0,1-1.965,0V64.983A.982.982,0,0,1,134.879,64Zm-1.965,4.913a.983.983,0,1,1-1.965,0V66.948a.983.983,0,1,1,1.965,0Zm-2.948,2.948a.983.983,0,1,1-1.965,0V69.9a.983.983,0,1,1,1.965,0Zm8.844,0a.983.983,0,0,1-1.965,0V64.983a.983.983,0,0,1,1.965,0Z" transform="translate(-124.069 -63.017)" fill="#8187d0" />
                <path id="Tracé_3198" data-name="Tracé 3198" d="M.983,32a.983.983,0,0,1,.983.983V43.3a.492.492,0,0,0,.491.491H14.74a.983.983,0,0,1,0,1.965H2.457A2.456,2.456,0,0,1,0,43.3V32.983A.983.983,0,0,1,.983,32Z" transform="translate(0 -32)" fill="#8187d0" opacity="0.4" />
            </g>
        </svg>

    );
}

export default ChartWaterfall;
