import IUseDialog from 'hooks/use-dialog/IUseDialog';
import useDialog from 'hooks/use-dialog/useDialog';
import React from 'react';
import { Link } from 'react-router-dom';
import GreenButtonWithGradient from 'components/small/buttons/green-button/GreenButtonWithGradient';
import SecondaryButton from 'components/small/buttons/secondary-button/SecondaryButton';
import './style.scss';
import i18n from '18n';
import Calendar from 'components/icons/Calendar';

const SessionExpired: React.FC = () => {
    const { closeDialog }: IUseDialog = useDialog();
    return (
        <div className="session-expired">
            <div className="content">
                <div className="icon-expired">
                    <Calendar />
                </div>

                <div className="title">
                    <h3>{i18n.t('expired.session')}</h3>
                </div>

                <div className="flex buttons">
                    <div className="close-button">
                        <SecondaryButton
                            textButton={i18n.t('later')}
                            disabled={false}
                            receiveClickEvent={false}
                            type='button'
                            handleClick={closeDialog} />
                    </div>

                    <div>
                        <Link to='/profile'>
                            <GreenButtonWithGradient
                                className="reconnect-button"
                                textButton={i18n.t('reconnect')}
                                disabled={false}
                                receiveClickEvent={false}
                                type='button'
                                handleClick={closeDialog} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SessionExpired;