import React from 'react';
import clsx from 'clsx';
import i18next from 'i18next';
import ModalProps from './ConfirmationModalProps';
import './style.scss';

const ConfirmationModal: React.FC<ModalProps> = ({
    onValidation,
    onRefused,
    message,
    cancelBtnText = i18next.t('confirmationModal.reject'),
    acceptBtnText = i18next.t('confirmationModal.accept'),
    hideCancelBtn,
    fullButtons
}) => {

    return (
        <div className={`confirmation-modal`}>
            <div className="confirmation-message">{message}</div>
            <div className="buttons-container">
                {!hideCancelBtn && <button
                    type="button"
                    className={clsx('reject-button cursor-pointer', { 'full': fullButtons })}
                    onClick={(e)=>{e.preventDefault();onRefused()}}
                >
                    {cancelBtnText}
                </button>}
                <button
                    type="button"
                    className={clsx('accept-button', { 'full': fullButtons })}
                    onClick={onValidation}
                >
                    {acceptBtnText}
                </button>
            </div>
        </div >
    );
};

export default ConfirmationModal;
