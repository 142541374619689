import React from 'react';
import "./crypto-payment-card.scss";
import ICryptoPaymentCardProps from './ICryptoPaymentCardProps';

const CryptoPaymentCard: React.FC<ICryptoPaymentCardProps> = (props) => (
    <div className="crypto-payment-card"  >
        <img src="https://test.bitpay.com/cdn/en_US/bp-btn-pay-currencies.svg"
            alt="BitPay, the easy way to pay with bitcoins." 
            onClick={props.handleClick} />
    </div>)

export default CryptoPaymentCard;
