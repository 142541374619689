import React from 'react';

function TournamentTrophy(props: React.SVGProps<SVGSVGElement>) {
    const DEFAULT_SIZE = '20px';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={DEFAULT_SIZE} height={DEFAULT_SIZE} viewBox="0 0 22.926 20.379" {...props}>
            <defs>
                <linearGradient id="linear-gradient-trophy" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#6677f5" />
                    <stop offset="1" stop-color="#f94179" />
                </linearGradient>
            </defs>
            <g id="Groupe_5147" data-name="Groupe 5147" transform="translate(-300 -858)">
                <g id="trophy-duotone_1_" data-name="trophy-duotone (1)" transform="translate(299.999 858)">
                    <path id="Tracé_3768" data-name="Tracé 3768" d="M136.275,14.767a1.8,1.8,0,0,0-.633,1.338,1.7,1.7,0,0,0,1.727,1.727h.185a1.91,1.91,0,0,1,1.91,1.911.63.63,0,0,1-.6.637h-8.953a.607.607,0,0,1-.6-.637,1.91,1.91,0,0,1,1.91-1.911h.185a1.722,1.722,0,0,0,1.726-1.725,1.8,1.8,0,0,0-.633-1.338C128,10.814,128,3.821,128,3.821V1.274A1.28,1.28,0,0,1,129.28,0h10.178a1.279,1.279,0,0,1,1.279,1.274V3.821C140.7,3.821,140.7,10.814,136.275,14.767Z" transform="translate(-122.904)" fill="url(#linear-gradient-trophy)" />
                    <path id="Tracé_3769" data-name="Tracé 3769" d="M7.711,74.066c-.155-.231-.3-.462-.434-.7a9.03,9.03,0,0,1-4.2-3.165,6.975,6.975,0,0,1-1.155-4.294h3.2c-.027-.4-.031-.637-.031-.637L5.1,64H1.086a.956.956,0,0,0-.929.732,8.788,8.788,0,0,0,1.32,6.509c1.491,2.222,4,3.782,7.463,4.673a2.593,2.593,0,0,1,.617.308A11.414,11.414,0,0,1,7.711,74.066Zm15.061-9.334A.958.958,0,0,0,21.841,64H17.829l0,1.274s0,.236-.03.637H21A6.96,6.96,0,0,1,19.846,70.2a9.024,9.024,0,0,1-4.2,3.165q-.2.353-.432.7a11.337,11.337,0,0,1-1.849,2.156,2.6,2.6,0,0,1,.617-.308c3.461-.889,5.974-2.45,7.463-4.673A8.782,8.782,0,0,0,22.772,64.732Z" transform="translate(0 -61.453)" opacity="0.4" fill="url(#linear-gradient-trophy)" />
                </g>
                <path id="check-duotone" d="M8.4,96.19a.611.611,0,0,1,0,.865l-4.9,4.9a.611.611,0,0,1-.865,0L.179,99.506a.612.612,0,1,1,.867-.865l2,2.016L7.533,96.19a.611.611,0,0,1,.865,0Z" transform="translate(307.603 765.053)" fill="#fff" stroke="#fff" strokeWidth="1" />
            </g>
        </svg>
    );
}

export default TournamentTrophy;
