import * as React from 'react';
import PendingCard from 'components/medium/pending-card/PendingCard';
import IPendingWithdrawalProps from './IPendingWithdrawalProps';
import usePendingWithdrawal from 'hooks/withdraw/use-pending-withdrawal/usePendingWithdrawal';

const PendingWithdrawal: React.FC<IPendingWithdrawalProps> = ({ walletWithdrawalViewModel }) => {
    const { renderCardImg, renderTopImgIcon, internalCancelWithdrawRequest, isCancelRequestLoading } = usePendingWithdrawal(walletWithdrawalViewModel);

    return <PendingCard
        pendingCardViewModel={walletWithdrawalViewModel}
        handleInternalCancel={internalCancelWithdrawRequest}
        isCancelRequestLoading={isCancelRequestLoading}
        renderCardImg={renderCardImg}
        renderTopImgIcon={renderTopImgIcon}
    />
};

export default React.memo(PendingWithdrawal);
