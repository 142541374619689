import React from 'react';
import './style.scss';
import i18n from '18n';

const LegendBanner: React.FC = () => {
    return (
        <div className="legend-container">
            <span className="legend-span">{i18n.t('asset.legend')}</span>
        </div>
    )
}

export default React.memo(LegendBanner);