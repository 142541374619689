import i18next from 'i18next';
import TableHeader, {
    AssetDataTable,
    AssetRequestTableHeader,
    AssetsCategoriesDataTable,
    OverviewAssetsHeader,
    TransactionsLogsTable,
    TWithdraws,
    UserDataTable
} from '../model/Table/TableHeader';

export const UserHeadCell: TableHeader<UserDataTable>[] = [

    { disablePadding: false, id: 'viewProfile', label: '', numeric: false },
    { disablePadding: false, id: 'pseudo', label: 'Pseudo', numeric: false },
    { disablePadding: false, id: 'createAt', label: 'Créé le', numeric: false },
    { disablePadding: false, id: 'name', label: 'Name', numeric: false },
    { disablePadding: false, id: 'isConfirmed', label: 'Confirmé', numeric: false },
    { disablePadding: false, id: 'email', label: 'Email', numeric: false },
    { disablePadding: false, id: 'credits', label: 'Crédits', numeric: false },
    { disablePadding: false, id: 'connectTo', label: 'Connexion', numeric: false },
    { disablePadding: false, id: 'deleteUser', label: 'Supprimer', numeric: false },
    { disablePadding: false, id: 'disableUser', label: 'Désactiver', numeric: false },
];

export const AssetHeadCell: TableHeader<AssetDataTable>[] = [

    { disablePadding: false, id: 'name', label: 'Name', numeric: false },
    { disablePadding: false, id: 'description', label: 'Description', numeric: false },
    { disablePadding: false, id: 'banner', label: 'Image', numeric: false },
];

export const AssetsCategoriesHeadCell: TableHeader<AssetsCategoriesDataTable>[] = [

    { disablePadding: false, id: 'icon', label: 'Icon', numeric: false, width: '50px' },
    { disablePadding: false, id: 'assetsInformation', label: 'Name', numeric: false, width: '150px' },
    { disablePadding: false, id: 'priceInformation', label: 'Price', numeric: false },
    { disablePadding: false, id: 'priceChart', label: 'Price', numeric: false },
    { disablePadding: false, id: 'sell', label: 'Sell', numeric: false },
    { disablePadding: false, id: 'buy', label: 'Buy', numeric: false },
];

export const WithdrawalRequestsHeadCell: TableHeader<TWithdraws>[] = [
    { disablePadding: false, id: 'show', label: '', numeric: false },
    { disablePadding: false, id: 'userId', label: 'Name', numeric: false },
    { disablePadding: false, id: 'creditsToWithdraw', label: 'Amount', numeric: false },
    { disablePadding: false, id: 'currency', label: 'Currency', numeric: false },
    { disablePadding: false, id: 'typeOfForm', label: 'type of Request', numeric: false },
    { disablePadding: false, id: 'statusUser', label: 'User Status', numeric: false },
    { disablePadding: false, id: 'status', label: 'Status', numeric: false },
    { disablePadding: false, id: 'email', label: 'Email', numeric: false },
    { disablePadding: false, id: 'phone', label: 'Phone', numeric: false },
    { disablePadding: false, id: 'createdDate', label: 'Created date', numeric: false },
];

export const AssetsCrudHeadCell: TableHeader<OverviewAssetsHeader>[] = [
    { disablePadding: false, id: 'action', label: 'Action', numeric: false },
    { disablePadding: false, id: 'name', label: 'Name', numeric: false },
    { disablePadding: false, id: 'slug', label: 'Slug', numeric: false },
    { disablePadding: false, id: 'currentValue', label: 'Current value', numeric: false },
    { disablePadding: false, id: 'availableSupply', label: 'Available supply', numeric: false },
    { disablePadding: false, id: 'totalPriceChange', label: 'Total price change', numeric: false },
    { disablePadding: false, id: 'lastValueIncrement', label: 'Last value increment', numeric: false },
    { disablePadding: false, id: 'login', label: 'Login', numeric: false },
];

export const TransactionsLogsHeadCell: TableHeader<TransactionsLogsTable>[] = [
    { disablePadding: false, id: 'user', label: i18next.t('TransactionsLogsHeadCell.user'), numeric: false },
    { disablePadding: false, id: 'amount', label: i18next.t('TransactionsLogsHeadCell.amount'), numeric: false },
    { disablePadding: false, id: 'assetName', label: i18next.t('TransactionsLogsHeadCell.assetName'), numeric: false },
    { disablePadding: false, id: 'type', label: i18next.t('TransactionsLogsHeadCell.type'), numeric: false },
    { disablePadding: false, id: 'otherUser', label: i18next.t('TransactionsLogsHeadCell.otherUser'), numeric: false },
];

export const AssetRequestTableHeaderCell: TableHeader<AssetRequestTableHeader>[] = [
    { disablePadding: false, id: 'profil', label: '', numeric: false },
    { disablePadding: false, id: 'createdAt', label: 'Création', numeric: false },
    { disablePadding: false, id: 'email', label: 'Email', numeric: false },
    { disablePadding: false, id: 'lastName', label: 'Last Name', numeric: false },
    { disablePadding: false, id: 'firstName', label: 'First Name', numeric: false },
    { disablePadding: false, id: 'country', label: 'Country', numeric: false },
    { disablePadding: false, id: 'vipMode', label: 'Vip mode', numeric: false },
    { disablePadding: false, id: 'type', label: 'Type', numeric: false },
];