import ITournamentTaskStatus from "model/ITournamentTaskStatus";
import ReduxRootState from "model/Redux/ReduxRootSate";
import ReduxActionInterface from "model/ReduxActionInterface";
import { UPDATE_TOURNAMENT_TASK_STATUS } from "./actionType";
import { TOURNAMENT_TASK_STATUS } from "config/_const";

const ROOT_VALUE_STATE: ReduxRootState<ITournamentTaskStatus> = {
    loading: false,
    data: {
        tournamentId: '',
        status: TOURNAMENT_TASK_STATUS.WAITING
    },
    error: '',
};

export const tournamentTaskStatusReducer = (state: ReduxRootState<ITournamentTaskStatus> = ROOT_VALUE_STATE, action: ReduxActionInterface<ITournamentTaskStatus>) => {
    switch (action.type) {
        case UPDATE_TOURNAMENT_TASK_STATUS: {
            return { ...state, data: action.payload }
        }
        default:
            return state
    }
}