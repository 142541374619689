import * as React from "react";

const FightIcon: React.FC = () => {
    return (
        <svg
            width='19'
            height='19.033'
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512">
            <path d="M0 96C0 43 43 0 96 0H288c53 0 96 43 96 96v32H352c-26.2 0-49.4 12.6-64 32H144c-21.2 0-34.3-5.3-41.8-9.7c-3.8-2.3-6.3-4.4-7.7-5.8c-.7-.7-1.1-1.2-1.3-1.5l-.1-.1 0 0c-5-7.1-14.7-9-22-4.2c-7.4 4.9-9.3 14.8-4.4 22.2L80 152c-13.3 8.9-13.3 8.9-13.3 8.9l0 0 0 0 .1 .1 .1 .2 .4 .5c.3 .4 .6 .8 1.1 1.4c.9 1.1 2 2.4 3.6 4c3.1 3.1 7.6 6.9 13.8 10.7C98.3 185.3 117.2 192 144 192H273.6c-1 5.2-1.6 10.5-1.6 16c0 37.3 25.5 68.6 60 77.5c8.6 2.2 17.3-2.9 19.5-11.5s-2.9-17.3-11.5-19.5c-20.7-5.3-36-24.1-36-46.5c0-26.5 21.5-48 48-48h32c35.3 0 64 28.7 64 64v24.2c0 25.5-10.1 49.9-28.1 67.9l-49.1 49.1c-12 12-18.7 28.3-18.7 45.3V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V359.6C12.4 342 0 316.4 0 288V96zM152.9 354.7c-7.4-4.9-17.3-2.9-22.2 4.4s-2.9 17.3 4.4 22.2l28 18.7-28 18.7c-7.4 4.9-9.3 14.8-4.4 22.2s14.8 9.3 22.2 4.4L192 419.2l39.1 26.1c7.4 4.9 17.3 2.9 22.2-4.4s2.9-17.3-4.4-22.2l-28-18.7 28-18.7c7.4-4.9 9.3-14.8 4.4-22.2s-14.8-9.3-22.2-4.4L192 380.8l-39.1-26.1z" />
        </svg>
    );
}

export default FightIcon;
