import React from 'react';
import IArticleProps from './IArticleProps';
import "./Article.scss";
import { Trans } from 'react-i18next';
import { DOMAIN_NAME } from 'config/_const';

const Article: React.FC<IArticleProps> = (props) => {

    const url = props.link.includes("https://") ? props.link : `${DOMAIN_NAME[props.language] ?? DOMAIN_NAME["EN"]}${props.link}`;

	return <a href={url} target="_blank">
		<div className={"article-component"}>
		<div className='article'>
			<div className='article-header'>
				<div className='source-logo'>
				    <img src={props.sourceLogo} />
				</div>
				<div className='article-source'>{props.source}</div>
			</div>
			<h4><Trans i18nKey={props.title} /></h4>
				<div className='article-footer'>
					<div className='article-date'>{props.date}</div>
				</div>
		</div>
		<div className={"thumbnail"}>
			<img src={props.thumbnail} />
		</div>
	</div></a>
}

export default Article;
