import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import Utils from '../../utils/Utils';
import * as AssetRequestActionType from './actionType';

const ROOT_VALUE_STATE: ReduxRootState = {
    loading: false,
    data: [],
};

export const assetRequestReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {

        case AssetRequestActionType.ASSET_REQUEST_LOADING:
            return {
                ...state,
                loading: true,
            };

        case AssetRequestActionType.ASSET_REQUEST_SUCCESS:

            const stateData = state.data.length > 0 ? Utils.removedDuplicateMongoFile(state.data, action.payload.assetRequest) : [];
            return {
                ...state,
                data: stateData.concat(action.payload.assetRequest),
                loading: false,
            };

        case AssetRequestActionType.ASSET_REQUEST_ERROR:

            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
