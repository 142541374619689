import { AddCircle } from '@material-ui/icons';
import React from 'react';
import i18n from '18n';
import WhiteButton from 'components/small/buttons/white-button/WhiteButton';
import IUnfilledSlotProps from './IUnfilledSlotProps';
import './style.scss';

const UnfilledSlot: React.FC<IUnfilledSlotProps> = (props) => {
    return (
        <div className="unfilled-slot">
            <div className="unfilled-slot">
                <div>
                    <img className="background" src={props.imgPath} alt="Background" />
                </div>
            </div>
            {props.showAddButton && <div className="button-get">
                <WhiteButton
                    startIcon={<AddCircle />}
                    disabled={false}
                    receiveClickEvent={false}
                    type="button"
                    textButton={i18n.t('TeamManagement.button.get')}
                    debounceEvent={true}
                    preventDefaultEvent={false} />
            </div>}
        </div>
    );
};

UnfilledSlot.defaultProps = ({
    imgPath: '/img/silver-rocket.png',
});

export default React.memo(UnfilledSlot);
