import Application from 'Application';
import YieldMetaInformation from 'components/medium/token-information/small/yield/YieldMetaInformation';
import UserLeagueInfo from 'components/medium/user-league/UserLeague';
import SuccessPage from 'components/small/SuccessPage/SuccessPage';
import TeamIncompatible from 'components/small/team-incompatible/TeamIncompatible';
import { HTTP_STATUS_OK, MODAL_COLOR } from 'config/_const';
import i18next from 'i18next';
import AssetInterface from 'model/AssetModel/AssetInterface';
import React from 'react';
import { useDispatch } from 'react-redux';
import { sendEmailVerification } from 'service/auth/actions';
import { managementModal } from 'service/modal/action';
import { managementNotification } from 'service/notification/action';

export const useModalDisplay = () => {
    const dispatch = useDispatch();

    const showAssetDisclaimer = (message: string, onClick: () => void) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            showCancelButton: true,
            type: 'CONFIRM',
            propsCustom: {
                className: "verify-identity_customModal",
                showCancelButton: false,
                onRefused: () => dispatch(managementModal(false)),
                onValidation: onClick,
                message: message,
            },
        }));
    }

    const showShortMessageInModal = (message: string) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            showCancelButton: true,
            type: 'MESSAGE_MODAL',
            propsCustom: {
                customClass: "show-short-msg_customModal",
                message:
                    <h6><b>{message}</b></h6>,
            },
        }));
    };
    const showToVerifyProfileInModal = (msg: string, btnText: string, callBackOnValidate?: () => void) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            showCancelButton: true,
            type: 'CONFIRM',
            propsCustom: {
                className: "verify-identity_customModal",
                showCancelButton: false,
                textValidButton: btnText,
                onRefused: () => dispatch(managementModal(false)),
                onValidation: () => {
                    if (callBackOnValidate) callBackOnValidate();
                    dispatch(sendEmailVerification());
                    dispatch(managementNotification(true, i18next.t('email.verification.sent'), HTTP_STATUS_OK));
                },
                message: <div>
                    <h6><b>{msg}</b></h6>
                </div>,
            },
        }));
    };

    const showTournamentModal = () => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            showCancelButton: true,
            type: 'MESSAGE_MODAL',
            propsCustom: {
                customClass: "user-tournament_customModal",
                message: <UserLeagueInfo />,

            },
        }));
    };

    const showYtVideoModal = (videoId: string, bannerImage?: string) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            showCancelButton: false,
            fullScreen: false,
            type: 'VIDEO_MODAL',
            propsCustom: {
                bannerImage: bannerImage,
                videoId: videoId
            },
        }));
    };

    const closeDialog = () => dispatch(managementModal(false));

    const showYieldModal = (maxAPY: number) => {
        dispatch(managementModal(true, {
            display: true,
            type: 'CONFIRM',
            fullScreen: false,
            showBackArrow: false,
            disableBackDrop: false,
            showCancelButton: false,
            propsCustom: {
                message: <YieldMetaInformation maxAPY={maxAPY} />,
                showCancelButton: false,
                onValidation: closeDialog,
                textValidButton: i18next.t('team.engaged.validation'),
            },
        }));
    };

    const showTeamIncompatibleModal = (message: string, iconName?: string) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            showCancelButton: false,
            type: 'CONFIRM',
            bgColor: MODAL_COLOR.DARK,
            textColor: MODAL_COLOR.LIGHT,
            propsCustom: {
                customClass: "team-incompatible_customModal",
                showCancelButton: false,
                onValidation: closeDialog,
                message: <TeamIncompatible msg={message} iconName={iconName} />,
            },
        }));
    };

    const showScoreBoosterModal = () => {
        return Application.getInstance().IsTeamScoreBoostConfigured &&
            dispatch(managementModal(true, {
                display: true,
                disableBackDrop: false,
                showBackArrow: false,
                fullScreen: false,
                showCancelButton: true,
                type: 'BUY_BOOST_SCORE',
                propsCustom: {
                    onValidation: closeDialog,
                },
            }));
    };

    const showBuyScoreBoostSuccessMessage = (message: string) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: true,
            showBackArrow: false,
            fullScreen: false,
            showCancelButton: true,
            type: 'MESSAGE_MODAL',
            propsCustom: {
                customClass: "success-buy-boost_customModal",
                message: <SuccessPage message={message} />,
            },
        }));
    };

    const showAssetSellInitiator = (asset: AssetInterface, disableBackDrop: boolean = false, closeByBodyClick: boolean = true) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: disableBackDrop,
            showBackArrow: false,
            fullScreen: true,
            showCancelButton: false,
            type: 'ASSET_SELL_INITIATOR',
            bgColor: MODAL_COLOR.TRANSPARENT,
            closeByBodyClick: closeByBodyClick,
            closeByEscape: false,
            propsCustom: {
                asset: asset,
                closeDialog: closeDialog
            },
        }));
    };

    const showAssetSellWarning = () => {
        dispatch(managementModal(true, {
            type: 'CONFIRM',
            fullScreen: false,
            showBackArrow: false,
            disableBackDrop: false,
            showCancelButton: false,
            propsCustom: {
                message: <p style={{ opacity: 0.6, fontSize: "13px" }}>{i18next.t("asset.sell.warning")}</p>,
                showCancelButton: false,
                onValidation: closeDialog,
                textValidButton: i18next.t('asset.sell.warning.confirm'),
            },
        }));
    };


    const showEmailVerificationText = (message: string) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            showCancelButton: true,
            type: 'MESSAGE_MODAL',
            propsCustom: {
                message: <p className='no-margin' style={{ opacity: 0.6, fontSize: "14px" }}>{message}</p>,

            },
        }));
    };

    const showOnboardingTournamentConfirmation = (props: any) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: true,
            showBackArrow: false,
            fullScreen: true,
            showCancelButton: false,
            type: 'TOURNAMENT_CONFIRMATION_MODAL_ON_BOARDING',
            closeByBodyClick: false,
            bgColor: MODAL_COLOR.TRANSPARENT,
            closeByEscape: false,
            propsCustom: {
                onButtonClick: () => {
                    closeDialog();
                    props.subscribe();
                    props.updateOnboardingStep();
                }
            },
        }));
    };

    return {
        showShortMessageInModal,
        showToVerifyProfileInModal,
        showTournamentModal,
        showYtVideoModal,
        showYieldModal,
        showTeamIncompatibleModal,
        showScoreBoosterModal,
        showBuyScoreBoostSuccessMessage,
        showAssetSellInitiator,
        showAssetSellWarning,
        showEmailVerificationText,
        showOnboardingTournamentConfirmation,
        showAssetDisclaimer
    };
};


export default useModalDisplay;