import { TOP_ASSETS_LIMIT } from 'config/_const';
import Level from 'model/Level';
import AssetInterface from '../../model/AssetModel/AssetInterface';
import ISocialNetwork from '../../model/AssetModel/ISocialNetwork';
import '../../utils/extensionMethods/string';


interface GetInitialPriceWithFeesParams {
    currentInitialSellStep: Level | undefined;
    currentValueAsset: number;
    coef: number | null;
    price: string;
    tokens: number;
    feesRate: string;
    feesFixedAmount: number;
}

export const getFirst70 = (assets: AssetInterface[]) => assets.slice(0, TOP_ASSETS_LIMIT);

export const getStepIdByTokens = (tokens: number, config: Level[]): number => {
    let accumulator = 0;
    let stepId = -1;
    for (let i = 0; i < config.length; i++) {
        const currentStep = config[i];
        accumulator += currentStep.tokens;
        if (accumulator > tokens) {
            stepId = currentStep.id;
            break;
        }
    }
    return stepId;
};

export const getCurrentStep = (config: Level[], tokens: number): Level | undefined => {
    const currentStepId = getStepIdByTokens(tokens, config);
    return config.find((step) => step.id === currentStepId);
};

export const getInitialSellPriceWithFees = (params: GetInitialPriceWithFeesParams): number => {
    const { price, coef, currentInitialSellStep, currentValueAsset, feesFixedAmount, feesRate, tokens } = params;

    if (!currentInitialSellStep) return 0;
    let _price = price ? parseFloat(price) : 0;

    if (!price && currentValueAsset) {
        _price = currentValueAsset * tokens;
        if (coef && currentInitialSellStep.coef) {
            _price = _price * coef;
        }
    }

    // Same method as the server side (cf: buyFromInitialSupply)
    if (feesRate) {
        const fees = Math.round(_price * parseFloat(feesRate) * 100.0) / 100.0;
        if (fees) {
            _price += fees;
        }
    }

    return _price + feesFixedAmount;
};

export const getIsAssetSubjectToCoef = (asset: AssetInterface, config: Level[]) => {
    if (asset.initialSaleDone) return false;
    const tokensSold: number = !asset ? 0 : asset.initialSupply - asset.availableSupply;
    const currentPrivateSellConfig: Level | undefined = getCurrentStep(config, tokensSold);
    return !currentPrivateSellConfig ? false : (currentPrivateSellConfig.coef && typeof asset.coef !== 'undefined');
};

export const getSocialNetworks = (asset: AssetInterface) => {
    const socialNetworkArray: ISocialNetwork[] = Object.keys(asset).filter((key: string) => /followers/.test(key.toLocaleLowerCase())).map((key: string) => {
        const lowerCasedKey = key.toLocaleLowerCase();
        const socialNetworkName = lowerCasedKey.substring(0, lowerCasedKey.indexOf('followers'));
        return {
            name: socialNetworkName,
            url: asset[socialNetworkName],
            followers: asset[key] ?? 0,
            icon: socialNetworkName.capitalizeFirstLetter(),
        };
    });
    return socialNetworkArray.sort((a: ISocialNetwork, b: ISocialNetwork) => a.followers > b.followers ? -1 : 1);
};
