import Fire from 'assets/images/fire-joypixels.gif';
import clsx from 'clsx';
import WalletCard from 'components/icons/wallet/card/WalletCard';
import { INDICATORS } from 'config/_const';
import useTokenAvailableInformationViewModel from 'hooks/use-token-available-information/useTokenAvailableInformation';
import i18next from 'i18next';
import React from 'react';
import Utils from 'utils/Utils';
import ITokenAvailableInformationProps from './ITokenAvailableInformationProps';
import { MarketEnum } from 'config/_enums';


const TokenAvailableInformation: React.FC<ITokenAvailableInformationProps> = ({ tokensBuyable, remainingTokensToSell, totalTokensInCirculation, indicator, asset }) => {
    const { tokenAvailableInformationViewModel } = useTokenAvailableInformationViewModel(tokensBuyable , remainingTokensToSell, totalTokensInCirculation, MarketEnum.SECONDARY);
    const label: string = i18next.t(asset.enableCustomAssetProfil() && asset.IsAssetBenzema && asset.isEarlyBirdEnable() ? 'benzema.custom-profil.token-information.token-available' : 'token-information.token-available')
    return <div className={clsx("info token-available bonus", { "border-transparent": asset.showChristmasBonus() })}>
        <div className="icon">
            <WalletCard className="tokens-icon" />
        </div>
        <div className={clsx("label", { "no-margin": label.length > 10 && Utils.isMobile() })}>
            {label}
        </div>
        <div className="value">
            {indicator === INDICATORS.FIRE_ICON && <img src={Fire} alt="loading..." className="fire-icon" />}
            {indicator === INDICATORS.DEFAULT && <div className="indicator" style={{ backgroundColor: tokenAvailableInformationViewModel.getColorIndicatorForAvailableToken() }}></div>}
            {tokensBuyable}
        </div>
    </div>;
};
export default React.memo(TokenAvailableInformation);
