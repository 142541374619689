import { ZERO } from 'config/_const';
import IFootballStatisticsDuel from 'interfaces/sports/football/statistics/IFootballStatisticsDuel';

export default class FootballStatisticsDuelViewModel {
    private total: number;
    private won: number;

    constructor(statistics: Partial<IFootballStatisticsDuel>) {
        this.total = statistics.total ?? ZERO;
        this.won = statistics.won ?? ZERO;
    }

    get Total(): number {
        return this.total;
    }

    get Won(): number {
        return this.won;
    }
}
