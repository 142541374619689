import { ALL_TRANSACTIONS_TYPES, IAllTransaction, allTransactionsSelector } from "components/medium/transactions/AllTransactionsSelector";
import { TransactionAction, TransactionType } from "config/_enums";
import useAccount from "hooks/use-account/useAccount";
import { IAssetTransaction } from "model/AssetTransactions/IAssetTransaction";
import ReduxStoreInterface from "model/ReduxStoreInterface";
import ITransactionInterface from "model/Transaction/ITransactionInterface";
import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { getAllAssetTransactions } from "service/asset-transactions/actions";
import { fetchAllTransactions } from "service/transactions/actions";
import { fetchAllWithdrawRequests } from "service/withdraws/actions";
import TransactionViewModel from "view-model/TransactionViewModel/TransactionViewModel";
import IUseTransaction from './IUseTransaction';
import { sortTransactionsByDate } from "./sorter";
import { isFinancialTransactionsLoadingSelector } from '../../service/transactions/selectors';
import { DEFAULT_ID_AS_STRING } from "config/_const";

const mapToModelAssetTransaction = (assetTransaction: IAssetTransaction): ITransactionInterface => {
    return {
        relatedAsset: assetTransaction.asset,
        paid: assetTransaction.paid,
        transactionDate: new Date(assetTransaction.createdAt),
        amountOfShares: assetTransaction.amount,
        type: assetTransaction.type,
        source: assetTransaction.source,
        id: assetTransaction.id,
        action: assetTransaction.type === TransactionType.SELL ? TransactionAction.DEBIT : TransactionAction.CREDIT,
        status: assetTransaction.status
    }
}

const mapToModelUserTransaction = (userTransaction: IAllTransaction): ITransactionInterface => {
    return {
        relatedAsset: userTransaction.asset,
        paid: userTransaction.fiat || userTransaction.creditsToWithdraw || 0,
        transactionDate: new Date(userTransaction.createdAt),
        id: userTransaction._id,
        type: userTransaction.source || userTransaction.type || "",
        source: userTransaction.source || "",
        action: userTransaction.creditOrDebit || "",
        status: userTransaction.status
    }
}

export const useTransaction = (): IUseTransaction => {
    const dispatch = useDispatch();
    const { accountViewModel } = useAccount();
    const [transactions, setTransactions] = useState<TransactionViewModel[]>([]);
    const assetTransactions: IAssetTransaction[] = useSelector((state: ReduxStoreInterface) => state.userAssetTransactions.data);
    const userTransactions: IAllTransaction[] = useSelector(allTransactionsSelector);
    const financialTransactionsLoading: boolean = useSelector(isFinancialTransactionsLoadingSelector);

    useEffect(() => {
        if (accountViewModel.Id === DEFAULT_ID_AS_STRING)
            return;
        batch(() => {
            dispatch(getAllAssetTransactions(accountViewModel.Id));
            dispatch(fetchAllTransactions(1));
            dispatch(fetchAllWithdrawRequests(1, accountViewModel.Id));
        });
    }, [accountViewModel.Id, dispatch])

    useEffect(() => {
        const mappedUserTransactions = userTransactions
            .filter(transaction => transaction.type !== ALL_TRANSACTIONS_TYPES.WITHDRAW)
            .map(mapToModelUserTransaction);
        const mappedAssetTransactions = assetTransactions.map(mapToModelAssetTransaction);
        const mergedTransactions = sortTransactionsByDate([...mappedAssetTransactions, ...mappedUserTransactions]).map(transaction => new TransactionViewModel(transaction));
        setTransactions(mergedTransactions);

    }, [assetTransactions, userTransactions])


    return { transactions, financialTransactionsLoading };
}

export default useTransaction;