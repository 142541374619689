import TournamentConfirmationModalOnBoarding from 'components/medium/tournament-confirmation-modal-on-boarding/TournamentConfirmationModalOnBoarding';
import React from 'react';
import './style.scss';

interface ITournamentConfirmationOnboardingProps {
    onButtonClick: () => void;
}

const TournamentConfirmationOnboarding: React.FC<ITournamentConfirmationOnboardingProps> = ({ onButtonClick }: ITournamentConfirmationOnboardingProps) => {
    return (
        <div className='tournament-confirmation-onboarding'>
            <TournamentConfirmationModalOnBoarding onButtonClick={onButtonClick} />
        </div>
    )
}

export default TournamentConfirmationOnboarding;