import Application from 'Application';
import { ZERO } from 'config/_const';
import { TournamentLeagues } from 'config/_enums';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';
import AssetWalletViewModel from 'view-model/WalletViewModel/AssetWalletViewModel';
import WalletViewModel from 'view-model/WalletViewModel/WalletViewModel';
import { GroupWalletByAssetLevel } from 'view-model/WalletViewModel/types';
import IDiffBetweenLeague, { IHighLowLeagueForUser } from './IDiffBetweenLeague';

export default class LeagueAttribution {
    readonly App: Application = Application.getInstance();
    readonly tournamentLeagues: LeagueViewModel[] = this.App.getLeagues();
    readonly firstLeague: LeagueViewModel = this.tournamentLeagues[this.tournamentLeagues.length - 1]
    readonly lastLeague: LeagueViewModel = this.tournamentLeagues[ZERO]
    private user: AccountViewModel;
    private userTotalTokens: number;
    private league: LeagueViewModel;
    private wallet: WalletViewModel;
    private walletGroupByAssetLevel: GroupWalletByAssetLevel;
    private diffBetweenLeague: IDiffBetweenLeague;
    private higherLeague: LeagueViewModel;
    private static whiteListedLeagues: TournamentLeagues[] = [TournamentLeagues.Contenders, TournamentLeagues.Champions];

    constructor(user: AccountViewModel, wallet: WalletViewModel) {
        this.wallet = wallet;
        this.walletGroupByAssetLevel = wallet.groupAssetsByLevelAndCalculateAmountForLeagueAttribution();
        this.user = user;
        this.userTotalTokens = this.calculateTotalTokens();
        this.league = this.getLeague();
        this.higherLeague = this.getHigherLeague();
        this.diffBetweenLeague = this.getDiffBetweenLeague();
    }

    public get CurrentLeagueTotalTokens(): number {
        return this.League.MinWalletShares.addition(this.League.LeagueTokens);
    }

    public get UserTotalTokens(): number {
        return this.userTotalTokens;
    }

    public get hasEnteredInLeague(): boolean {
        const hasUserMinTokenForFirstLeague: boolean = this.UserTotalTokens >= this.firstLeague.MinWalletShares;
        const hasUserMinFirstLeagueToken: boolean = this.walletGroupByAssetLevel[this.firstLeague.Level] >= this.firstLeague.LeagueTokens;
        return hasUserMinTokenForFirstLeague && hasUserMinFirstLeagueToken;
    }

    public get League(): LeagueViewModel {
        return this.league;
    }

    public get Name(): TournamentLeagues {
        return this.League.Name;
    }
    public get OwnedCurrentLeagueTokens(): number {
        return this.walletGroupByAssetLevel[this.League.Level];
    }

    public get DiffBetweenLeague(): IDiffBetweenLeague {
        return this.diffBetweenLeague;
    }

    public get HigherLeague(): LeagueViewModel {
        return this.higherLeague;
    }
    public get HigherLeagueName(): string {
        return this.HigherLeague.Name;
    }
    public get OwnedLeagueTokensOfHigherLeague(): number {
        return this.walletGroupByAssetLevel[this.HigherLeague.Level];
    }

    public get LowerLeague(): LeagueViewModel {
        return this.LeaguesOfUser.lowLeague || this.League;
    }
    public get LowerLeagueName(): string {
        return this.LowerLeague.Name;
    }

    private calculateTotalTokens = (): number => {
        return this.wallet.AssetsEligibleToLeagueAttribution.reduce((accumulator: number, assetWallet: AssetWalletInterface) => accumulator + new AssetWalletViewModel(assetWallet).getTokensAmount(this.user.Id), 0)
    }

    private getLeague = (): LeagueViewModel => {
        let currentLeague: LeagueViewModel = this.firstLeague;

        for (let i = this.tournamentLeagues.length - 1; i >= 0; i = i - 1) {
            const hasAccessToLeague = this.userHasAccessToLeague(this.tournamentLeagues[i]);
            if (!hasAccessToLeague)
                break;
            currentLeague = this.tournamentLeagues[i];
        }
        return currentLeague;
    }

    private userHasAccessToLeague = (tournamentLeague: LeagueViewModel): boolean => {
        if (this.userHasEnoughtTokens(tournamentLeague))
            return this.userHasEnoughtLeagueTokens(tournamentLeague)
        return false;
    }

    private userHasEnoughtTokens = (tournamentLeague: LeagueViewModel): boolean => {
        return this.UserTotalTokens >= (tournamentLeague.MinWalletShares + tournamentLeague.LeagueTokens);
    }

    private userHasEnoughtLeagueTokens = (tournamentLeague: LeagueViewModel): boolean => {
        return this.walletGroupByAssetLevel[tournamentLeague.Level] >= tournamentLeague.LeagueTokens;
    }

    private get LeaguesOfUser(): IHighLowLeagueForUser {
        const currentIndex: number = this.tournamentLeagues.findIndex((value: LeagueViewModel) => value.Name === this.League.Name);
        return {
            highLeague: this.hasEnteredInLeague ? this.tournamentLeagues[currentIndex - 1] : this.firstLeague,
            lowLeague: this.tournamentLeagues[currentIndex + 1],
        }
    }

    public getHigherLeague(): LeagueViewModel {
        return this.LeaguesOfUser.highLeague || this.League;
    }

    public getDiffBetweenLeague(): IDiffBetweenLeague {
        const higherLeague: LeagueViewModel | undefined = this.higherLeague;
        if (!higherLeague)
            return {
                leagueName: '',
                totalTokens: 0,
                tokensLeague: 0
            };
        const tokensLeague: number = Math.max(higherLeague.LeagueTokens.subtract(this.OwnedLeagueTokensOfHigherLeague), 0)
        return {
            tokensLeague,
            totalTokens: Math.max(higherLeague.MinWalletShares.addition(higherLeague.LeagueTokens).subtract(tokensLeague).subtract(this.UserTotalTokens), 0),
            leagueName: higherLeague.Name
        }
    }

    public isWhitelisted(): boolean {
        return LeagueAttribution.whiteListedLeagues.includes(this.Name);
    }

    public get IsUserInLastLeague(): boolean {
        return this.Name === this.lastLeague.Name;
    }

    public get UserHasLeagueTokens(): boolean {
        return this.OwnedCurrentLeagueTokens > 0;
    }

}