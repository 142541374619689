import { ISportsTeam, ISportsTeamNameInfo } from "interfaces/sports/ISportsTeam";
import { ReduxRootState } from "model/Redux";
import ReduxStoreInterface from "model/ReduxStoreInterface";
import { Selector } from "react-redux";
import { createSelector } from "reselect";

export const sportsTeamsSelector: Selector<ReduxStoreInterface, ReduxRootState<ISportsTeam[]>> = state => state.sportsTeams;
export const isSportsTeamsSelectorLoading: Selector<ReduxStoreInterface, boolean> = state => state.sportsTeams.loading;
export const sportsTeamsSelectorWithInfo = createSelector<ReduxStoreInterface, ReduxRootState<ISportsTeam[]>, ISportsTeamNameInfo[]>(
    sportsTeamsSelector,
    (sportsteams) => {
        if (sportsteams.data.length === 0) return [];
        return sportsteams.data.map((team: ISportsTeam) => {
            const { _id, id, name } = team;
            return { _id, id, name };
        })
    },
);