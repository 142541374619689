import { Capacitor } from '@capacitor/core';
import {
    BarChart,
    Gavel,
    Grade,
    GroupWorkRounded,
    Help,
    PhotoLibrary,
    Shop,
    SupervisorAccount,
    TransferWithinAStation
} from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import BadgeCheck from 'components/icons/BadgeCheck';
import EuroCup from 'components/icons/EuroCup';
import Home from 'components/icons/Home';
import TeamIcon from 'components/icons/TeamIcon';
import TournamentIcon from 'components/icons/tournament/TournamentIcon';
import DiscordIcon from 'components/small/DiscordIcon/DiscordIcon';
import i18next from 'i18next';
import Application from '../Application';
import MenuInterface from '../model/Menu/MenuInterface';
import { DISCORD_LINK, HIDE_DISCORD_BUTTON, MENU_ITEMS_NAME, TOTAL_AMOUNT_OF_INCOME_PER_REFERRAL, TRENDEX_ROLES_ENUM, USER_SUBTYPES, VisibilityEnum } from './_const';

const extraFields = Application.getInstance().IsFootballEuroMode() ? {
    icon: EuroCup,
    text: i18next.t('euro.2024.title')
} : undefined;

export const MENU_ITEMS: MenuInterface[] = [
    {
        title: Capacitor.isNativePlatform() ? i18next.t('Menu.trading.native') : i18next.t('Menu.trading'),
        titleTranslateKey: Capacitor.isNativePlatform() ? 'Menu.trading.native' : 'Menu.trading',
        url: '/top-50',
        Icon: Home,
        accessRole: [TRENDEX_ROLES_ENUM.USER, TRENDEX_ROLES_ENUM.VIP, TRENDEX_ROLES_ENUM.ADMIN],
        visibility: [VisibilityEnum.BOTTOM, VisibilityEnum.SIDE_DESKTOP],
        sideOrder: {
            USER: 1,
            VIP: 1,
        },
        bottomOrder: {
            USER: 1,
            VIP: 1,
        },
        guestAccess: true,
        name: MENU_ITEMS_NAME.TRADING,
        disableForOnboarding: true
    },
    {
        title: i18next.t('Menu.myProfile'),
        titleTranslateKey: 'Menu.myProfile',
        url: '/profile',
        Icon: AccountCircleIcon,
        accessRole: [TRENDEX_ROLES_ENUM.USER, TRENDEX_ROLES_ENUM.VIP, TRENDEX_ROLES_ENUM.ADMIN],
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.BOTTOM],
        sideOrder: {
            USER: 3,
        },
        bottomOrder: {
            USER: 3
        },
        guestAccess: true,
        name: MENU_ITEMS_NAME.MY_PROFILE,
        disableForOnboarding: true
    },
    {
        title: i18next.t('tournament'),
        titleTranslateKey: 'tournament',
        url: '/tournament',
        Icon: TournamentIcon,
        accessRole: [TRENDEX_ROLES_ENUM.USER, TRENDEX_ROLES_ENUM.VIP, TRENDEX_ROLES_ENUM.ADMIN],
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.BOTTOM],
        sideOrder: {
            USER: 2,
            VIP: 3,
        },
        bottomOrder: {
            USER: 2,
            VIP: 2,
        },
        guestAccess: true,
        name: MENU_ITEMS_NAME.WALLET,
        disableForOnboarding: true,
        extraFields: extraFields
    },
    {
        title: i18next.t('Menu.myTeam'),
        titleTranslateKey: 'Menu.myTeam',
        url: '/my-portfolio',
        Icon: TeamIcon,
        accessRole: [TRENDEX_ROLES_ENUM.USER, TRENDEX_ROLES_ENUM.VIP, TRENDEX_ROLES_ENUM.ADMIN],
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.BOTTOM],
        sideOrder: {
            USER: 5,
        },
        bottomOrder: {
            USER: 1,
        },
        guestAccess: true,
        onlyForBetaApp: false,
        name: MENU_ITEMS_NAME.TOURNAMENT,
        disableForOnboarding: true
    },
    {
        title: i18next.t('Menu.mySales'),
        titleTranslateKey: 'Menu.mySales',
        url: '/my-sales',
        Icon: Shop,
        accessRole: [TRENDEX_ROLES_ENUM.VIP],
        isAdminToVip: true,
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.BOTTOM],
        sideOrder: {
            VIP: 2,
        },
        bottomOrder:
        {
            // USER: 1,
            VIP: 2,
        },
        name: MENU_ITEMS_NAME.MY_SALES,
        disableForOnboarding: false
    },
    {
        title: `${i18next.t('AffiliateProgram', { amount: TOTAL_AMOUNT_OF_INCOME_PER_REFERRAL.toCurrency(0) })}`,
        titleTranslateKey: 'AffiliateProgram',
        url: '/affiliate',
        Icon: GroupWorkRounded,
        accessRole: [TRENDEX_ROLES_ENUM.VIP, TRENDEX_ROLES_ENUM.USER],
        visibility: Application.getInstance().showGamblingFeature ? [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE] : [],
        guestAccess: false,
        sideOrder: {
            USER: 4
        },
        name: MENU_ITEMS_NAME.AFFILIATION,
        disableForOnboarding: true
    },
    {
        title: i18next.t('Menu.myAdvantages'),
        titleTranslateKey: 'Menu.myAdvantages',
        url: '/my-advantages',
        Icon: Grade,
        accessRole: [TRENDEX_ROLES_ENUM.VIP],
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE],
        sideOrder: {
            VIP: 4,
        },
        name: MENU_ITEMS_NAME.ADVANTAGES,
        disableForOnboarding: false
    },
    {
        title: i18next.t('Menu.followUp'),
        titleTranslateKey: 'Menu.followUp',
        url: '/follow-up',
        Icon: BarChart,
        isAdminToVip: true,
        accessRole: [TRENDEX_ROLES_ENUM.VIP],
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE],
        sideOrder: {
            VIP: 4,
        },
        name: MENU_ITEMS_NAME.FOLLOW_UP,
        disableForOnboarding: false
    },
    {
        title: i18next.t('Menu.becomeVip'),
        titleTranslateKey: 'Menu.becomeVip',
        url: '/become-vip',
        Icon: BadgeCheck,
        accessRole: [TRENDEX_ROLES_ENUM.USER],
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE],
        sideOrder: {
            VIP: 7,
            USER: 5,
        },
        guestAccess: true,
        name: MENU_ITEMS_NAME.BECOME_VIP,
        disableForOnboarding: true
    },
    {
        title: i18next.t('Menu.helpDesk'),
        titleTranslateKey: 'Menu.helpDesk',
        url: 'https://trendex.crisp.help',
        isExternalLink: true,
        Icon: Help,
        accessRole: [TRENDEX_ROLES_ENUM.VIP, TRENDEX_ROLES_ENUM.USER],
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE],
        sideOrder: {
            VIP: 8,
            USER: 6,
        },
        guestAccess: true,
        name: MENU_ITEMS_NAME.HELP_DESK,
        disableForOnboarding: false
    },
    {
        title: i18next.t('btn-discord-title'),
        titleTranslateKey: 'btn-discord-title',
        url: DISCORD_LINK,
        isExternalLink: true,
        Icon: DiscordIcon,
        accessRole: [TRENDEX_ROLES_ENUM.VIP, TRENDEX_ROLES_ENUM.USER],
        visibility: HIDE_DISCORD_BUTTON ? [] : [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE],
        sideOrder: {
            VIP: 9,
            USER: 7,
        },
        guestAccess: true,
        name: MENU_ITEMS_NAME.HELP_DESK,
        disableForOnboarding: false
    },

    // ADMIN //
    {
        title: i18next.t('admin.validateuserid'),
        titleTranslateKey: 'admin.validateuserid',
        url: '/users',
        Icon: SupervisorAccount,
        accessRole: [TRENDEX_ROLES_ENUM.ADMIN],
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE],
        name: MENU_ITEMS_NAME.ADMIN_VALIDATE_USER_ID,
        disableForOnboarding: false
    },
    {
        title: i18next.t('admin.allwithdrawalrequests'),
        titleTranslateKey: 'admin.allwithdrawalrequests',
        url: '/withdraw-requests',
        Icon: TransferWithinAStation,
        accessRole: [TRENDEX_ROLES_ENUM.ADMIN],
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE],
        name: MENU_ITEMS_NAME.ADMIN_ALL_WITHDRAW,
        disableForOnboarding: false
    },
    {
        title: i18next.t('admin.crudassets'),
        titleTranslateKey: 'admin.crudassets',
        url: '/assets',
        Icon: PhotoLibrary,
        accessRole: [TRENDEX_ROLES_ENUM.ADMIN],
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE],
        name: MENU_ITEMS_NAME.ADMIN_ASSETS,
        disableForOnboarding: false
    },
    {
        title: i18next.t('admin.influencer'),
        titleTranslateKey: 'admin.influencer',
        url: '/influencer',
        Icon: Gavel,
        accessRole: [TRENDEX_ROLES_ENUM.ADMIN],
        userSubType: USER_SUBTYPES.VIP_ADMIN,
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE],
        name: MENU_ITEMS_NAME.ADMIN_INFLUENCER,
        disableForOnboarding: false
    },
    {
        title: i18next.t('admin.tournaments'),
        titleTranslateKey: 'admin.tournaments',
        url: '/admin-tournament',
        Icon: EmojiEventsIcon,
        accessRole: [TRENDEX_ROLES_ENUM.ADMIN],
        visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE],
        name: MENU_ITEMS_NAME.ADMIN_TOURNAMENTS,
        disableForOnboarding: false
    },
];
