import React from 'react';
import ProfilIconProps from './ProfilIconProps';
import './ProfilIcon.scss';
import { Edit } from '@material-ui/icons';
import MultiColorsLoader from '../multi-colors-loader/MultiColorsLoader';
import i18n from '18n';
import { DEFAULT_AVATAR, PROFILE_SIZE_LIMIT_BYTES, PROFILE_SIZE_LIMIT_STRING, TRENDEX_HTTP_STATUS_NOK, ZERO } from 'config/_const';
import { useDispatch } from 'react-redux';
import { showNotification } from 'service/notification/action';
import i18next from 'i18next';

const ProfilIcon: React.FC<ProfilIconProps> = (props) => {
    const dispatch = useDispatch();
    const iconSrc = props.avatar;

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target?.files || !e.target.files[0])
            return;

        const fileSizeBytes = e.target.files[0].size || ZERO;
        if (fileSizeBytes > PROFILE_SIZE_LIMIT_BYTES)
            return showNotification(dispatch, i18next.t('warning.to.limit.size', { size: PROFILE_SIZE_LIMIT_STRING }), TRENDEX_HTTP_STATUS_NOK);
        if (props.onIconEditChange) {
            props.onIconEditChange(e.target.files[0]);
            e.target.files = null;
        }
    }

    return (
        <div className="profil_section">
            <div className="profile_img">
                <img className="img-fluid" src={iconSrc || DEFAULT_AVATAR} alt="profile-img" />
                {props.editIcon && (
                    <span>
                        <label htmlFor="file" style={{ marginTop: '10px' }} className="pencil">
                            <Edit />
                        </label>

                        <input
                            onChange={(e: any) => {
                                if (props.onIconEditChange) {

                                    props.onIconEditChange(e.target.files[0]);
                                }
                            }}
                            type="file"
                            name="file"
                            accept="image/*"
                            id="file"
                            hidden
                            height={0}
                        />
                    </span>
                )}
                {props.showLevel && <span className="level">12</span>}
                {props.leagueIcon && <span className='league-icon'>{props.leagueIcon}</span>}
                {props.loading && <MultiColorsLoader />}
            </div>
            {props.showEditText &&
                <h6 className='change-profile'>
                    <label className={props.isEditDisabled ? 'disabled-cursor' : 'hover'} htmlFor="file">
                        {i18n.t('Profile.changeProfilePhoto')}
                    </label>
                    <input
                        onChange={onFileChange}
                        type="file"
                        name="file"
                        accept="image/*"
                        id="file"
                        hidden
                        height={0}
                        disabled={props.isEditDisabled}
                    />
                </h6>
            }
        </div>
    );
};

export default ProfilIcon;
