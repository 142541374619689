import WithdrawModal from "components/medium/withdraw-modal/WithdrawModal";
import React from "react";
import './WithdrawOnboarding.scss';

const WithdrawOnboarding: React.FC = () => {

    return (
        <div className="withdraw-onboarding-container">
            <WithdrawModal />
        </div>
    )
}

export default WithdrawOnboarding;