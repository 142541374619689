import React from 'react';
import { TournamentLeagues } from 'config/_enums';

import Rookies from 'components/icons/tournament/Rookies';
import Managers from 'components/icons/tournament/Managers';
import Believers from 'components/icons/tournament/Believers';
import Contenders from 'components/icons/tournament/Contenders';
import Champions from 'components/icons/tournament/Champions';
import Legends from 'components/icons/tournament/Legends';

import RookieSvg from 'components/icons/tournament/Rookies.svg';
import ManagersSvg from 'components/icons/tournament/Managers.svg';
import BelieversSvg from 'components/icons/tournament/Believers.svg';
import ContendersSvg from 'components/icons/tournament/Contenders.svg';
import ChampionsSvg from 'components/icons/tournament/Champions.svg';
import LegendsSvg from 'components/icons/tournament/Legends.svg';
import { ISvgIconProps } from 'interfaces/SvgIcon.interface';

export const TournamentLeagueIcon: Record<TournamentLeagues, React.FC<ISvgIconProps>> = {
    [TournamentLeagues.Rookies]: (args: ISvgIconProps) => <Rookies {...args} />,
    [TournamentLeagues.Managers]: (args: ISvgIconProps) => <Managers {...args} />,
    [TournamentLeagues.Believers]: (args: ISvgIconProps) => <Believers {...args} />,
    [TournamentLeagues.Contenders]: (args: ISvgIconProps) => <Contenders {...args} />,
    [TournamentLeagues.Champions]: (args: ISvgIconProps) => <Champions {...args} />,
    [TournamentLeagues.Legends]: (args: ISvgIconProps) => <Legends {...args} />,
}

export const TournamentLeagueSvg: Record<TournamentLeagues, string> = {
    [TournamentLeagues.Rookies]: RookieSvg,
    [TournamentLeagues.Managers]: ManagersSvg,
    [TournamentLeagues.Believers]: BelieversSvg,
    [TournamentLeagues.Contenders]: ContendersSvg,
    [TournamentLeagues.Champions]: ChampionsSvg,
    [TournamentLeagues.Legends]: LegendsSvg,
}