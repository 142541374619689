import * as React from 'react';
import ITeamIncompatibleProps from './TeamIncompatibleProps';
import './TeamIncompatible.scss';
import CategoriesIconsMap from 'components/icons/categories/CategoriesIconsMap';
import { Icon } from '@material-ui/core';

const TeamIncompatible: React.FC<ITeamIncompatibleProps> = ({ iconName, msg }) => {
    const icon = iconName && CategoriesIconsMap[(iconName.toLowerCase()).capitalizeFirstLetter()];
    
    return <section className='team-incompatible'>
        {(iconName && icon) &&
            <div className='team-incompatible__icon'>
                <Icon className='category-icon'>
                    {icon}
                </Icon>
            </div>
        }
        <div className='team-incompatible__msg'>{msg}</div>
    </section>
};

export default React.memo(TeamIncompatible);
