import i18n from '18n';
import Application from 'Application';
import { InternalCookieConsentVisibility, TRENDEX_COOKIES } from 'config/_const';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import Cookies from 'js-cookie';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import './style.scss';

const InternalCookieConsent: React.FC = () => {
    const { handleCookieConsent, getCookieConsentVisibility } = useCookieConsent();

    return <CookieConsent
        enableDeclineButton={true}
        overlay={true}
        visible={getCookieConsentVisibility()}
        onAccept={() => handleCookieConsent(true)}
        onDecline={() => handleCookieConsent(false)}
        location="bottom"
        buttonText={i18n.t('cookie.consent.accept')}
        cookieName={TRENDEX_COOKIES.TDX_COOKIE_CONSENT}
        disableStyles={true}
        containerClasses="cookie-consent"
        buttonWrapperClasses="cookie-consent-buttons"
        buttonClasses="button-cookie-consent accept-button"
        declineButtonClasses="button-cookie-consent decline-button"
        buttonStyle={{ background: '#55BC7C', color: 'white', fontSize: '13px' }}
        declineButtonText={i18n.t('cookie.consent.refuse')}
        expires={150} >
        <h5 className="title-consent">{i18n.t('cookie.consent.title')}</h5>
        <p className="consent-description">{i18n.t('cookie.consent.message', { applicationName: Application.getInstance().title })}</p>
    </CookieConsent>;
};

const useCookieConsent = () => {
    const { accountViewModel, handleCookieConsent }: IUseAccount = useAccount();

    const getCookieConsentVisibility = (): string => {
        return accountViewModel.isAuthenticated && typeof accountViewModel.CookieConsentAccepted === 'undefined' && !Cookies.get(TRENDEX_COOKIES.TDX_COOKIE_CONSENT) ? InternalCookieConsentVisibility.SHOW : onAnonymousUser();
    };

    const onAnonymousUser = (): string => {
        return !accountViewModel.isAuthenticated ? InternalCookieConsentVisibility.BY_COOKIE_VALUE : InternalCookieConsentVisibility.HIDDEN;
    };

    React.useEffect(() => {
        const cookieConsent: string | undefined = Cookies.get(TRENDEX_COOKIES.TDX_COOKIE_CONSENT);
        if (cookieConsent && typeof accountViewModel.CookieConsentAccepted === 'undefined') {
            handleCookieConsent(JSON.parse(cookieConsent));
        }
    }, [accountViewModel.isAuthenticated]);

    return {
        handleCookieConsent,
        getCookieConsentVisibility,
    };
};

export default React.memo(InternalCookieConsent);
