import { PREDICT_PRICE_REQUEST_STATUS } from '../../config/_const';
import ISecondaryMarketStoreData from '../../model/AssetModel/ISecondaryMarketStoreData';
import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import * as SecondaryMarketActionsType from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<ISecondaryMarketStoreData> = {
    loading: false,
    data: {
        predictPriceData: {
            secondaryMarketPredictPriceData: {
                price: 0,
                feesRate: '',
                availableSupply: 0,
                assetCurrentValue: 0,
                feesFixedAmount: 0,
                maxPriceDeviation: 0,
                minPriceDeviation: 0,
                minimumPrice: 0,
                computationMethod: ''
            },
            side: '',
            assetId: '',
        },
    },
};

export const secondaryMarketReducer = (state: ReduxRootState<ISecondaryMarketStoreData> = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    switch (action.type) {
        case SecondaryMarketActionsType.SECONDARY_MARKET_GET_PREDICTED_ASSET_PRICE_START: {
            return {
                ...state,
                status: { ...state.status, secondaryMarketPredictPriceStatus: PREDICT_PRICE_REQUEST_STATUS.PENDING },
            };
        }
        case SecondaryMarketActionsType.SECONDARY_MARKET_GET_PREDICTED_ASSET_PRICE_SUCCESS: {
            if (!action.payload || !action.payload.secondaryMarketPredictPriceData)
                return state;
            return {
                ...state,
                data: {
                    ...state.data,
                    predictPriceData: {
                        secondaryMarketPredictPriceData: action.payload.secondaryMarketPredictPriceData,
                        side: action.payload.side,
                        assetId: action.payload.assetId,
                    },
                },
                loading: false,
                status: {
                    ...state.status,
                    secondaryMarketPredictPriceStatus: PREDICT_PRICE_REQUEST_STATUS.RESOLVED,
                },
            };
        }

        case SecondaryMarketActionsType.SECONDARY_MARKET_GET_PREDICTED_ASSET_PRICE_FAILED: {
            return {
                ...state, status: { ...state.status, secondaryMarketPredictPriceStatus: PREDICT_PRICE_REQUEST_STATUS.REJECTED }
            }
        }

        case SecondaryMarketActionsType.SECONDARY_MARKET_GET_MOST_SALES_ASSETS_START: {
            return {
                ...state,
                loading: true,
            };
        }
        case SecondaryMarketActionsType.DROP_PREDICT_PRICE_DATA:
            return ROOT_VALUE_STATE;
        default:
            return state;
    }
};
