import React from 'react';
import i18next from 'i18next';
import { HTTP_STATUS_OK } from 'config/_const';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import MailboxImg from "assets/images/modals/undraw_mailbox_re_dvds-min.png";
import './verify-email-modal.scss';
import ModalImageContainer from '../modal-image-container/ModalImageContainer';

interface VerifyEmailModalProps {
    onValidation: () => void;
}

const VerifyEmailModal: React.FC<VerifyEmailModalProps> = (props) => {
    const { accountViewModel }: IUseAccount = useAccount();
    const { onValidation } = props;

    return (
        <div className='verify-email-modal'>
            <ModalImageContainer imgSrc={MailboxImg} />
            <div className="model_title">
                <h4>{i18next.t('emailvarifypop.title')}</h4>
            </div>

            <div className="model_container">

                {
                    accountViewModel.emailVerification && accountViewModel.emailVerification === HTTP_STATUS_OK &&
                    <>
                        <p>
                            {i18next.t('Aboutus.tab.email.checkyouremail')}
                        </p>
                        <div className="success-checkmark">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                            </div>
                        </div>

                        <div className="model_btns">
                            <button onClick={onValidation} className="primary-button verify_btn">{i18next.t('emailvarifypop.resendbtn')}</button>
                        </div>
                    </>
                }

                {
                    !(accountViewModel.emailVerification && accountViewModel.emailVerification === HTTP_STATUS_OK) &&
                    <>
                        <p>
                            {i18next.t('Aboutus.tab.email.pleaseclick')}
                        </p>
                        <div className="model_btns">
                            <button onClick={onValidation} className="primary-button verify_btn">{i18next.t('emailvarifypop.sendbtn')}</button>
                        </div>
                    </>
                }

            </div>
        </div>
    )
};

export default VerifyEmailModal;
