import TeamEditor from 'components/medium/team/team-editor/TeamEditor'
import Divider from 'components/small/divider/Divider'
import useAllAssets from 'hooks/asset/use-all-assets/useAllAssets'
import IUseAccount from 'hooks/use-account/IUseAccount'
import useAccount from 'hooks/use-account/useAccount'
import React from 'react'
import TeamHeaderOnboarding from './TeamHeaderOnboarding/TeamHeaderOnboarding'
import './style.scss'

const TeamOnboarding: React.FC = () => {
    const { accountViewModel: user }: IUseAccount = useAccount();
    const { fetchAllAssets } = useAllAssets();

    /** @todo At remove but don't have the choice in this case
     * Because team editor need all assets
     */
    React.useEffect(() => {
        fetchAllAssets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='team-onboarding'>
            <div className='team-header-onboarding-container'>
                <TeamHeaderOnboarding user={user} />
            </div>

            <div className="container-divider">
                <Divider />
            </div>

            <div className='team-editor-container'>
                <TeamEditor hideAssetsIfAmountIsZero={true} />
            </div>
        </div>
    )
}

export default TeamOnboarding;
