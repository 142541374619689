import PoundCurrencyImg from 'assets/images/currency/BritishPound.png';
import EuroCurrencyImg from 'assets/images/currency/Eur Flag.png';
import SARCurrencyImg from 'assets/images/currency/SaudiArabia.png';
import USDCurrencyImg from 'assets/images/currency/USD.png';
import Application from '../Application';

const CURRENCIES_IMG: Record<string, string> = {
    EUR: EuroCurrencyImg,
    USD: USDCurrencyImg,
    GBP: PoundCurrencyImg,
    SAR: SARCurrencyImg,
}

export const getCurrencyImage = () => CURRENCIES_IMG[Application.getInstance().currency] ?? CURRENCIES_IMG.EUR;
