/* eslint-disable react-hooks/exhaustive-deps */
import CircularLoader from 'components/small/CircularLoader';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import * as Input from 'components/small/input/ReduxFormInput';
import WrapperSelect from 'components/small/wrapper-select/WrapperSelect';
import { ASSETS_CATEGORIES, ASSET_CARD_LEVEL_NAME, CURRENT_FOOTBALL_SEASON_YEAR, CURVE_TYPE_LIST, LOCAL_STORAGE_KNOWN_KEYS, NONE, SCRAPABLE_SPORT_ASSET_CATEGORIES, SECONDARY_MARKET_CURRENT_VALUE_COMPUTATION_METHODS, SECONDARY_MARKET_MATCHING_STRATEGIES, SPORT_ASSET_CATEGORIES_ID, WRAPPER_SELECT_TYPE, ZERO } from 'config/_const';
import { AssetCategory, AssetCurveType } from 'config/_enums';
import useSportsCountries from 'hooks/sports/use-sports-countries/useSportsCountries';
import useSportsPlayers from 'hooks/sports/use-sports-players/useSportsPlayers';
import useSportsTeams from 'hooks/sports/use-sports-teams/useSportsTeams';
import i18next from 'i18next';
import { ISportsCountries } from 'interfaces/sports/ISportsCountries';
import { ISportsFootballPlayersInfo } from 'interfaces/sports/ISportsPlayer';
import { ISportsTeamNameInfo } from 'interfaces/sports/ISportsTeam';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, FieldArray, InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import { assetOrderSelector } from 'service/assets/selectors';
import './AdminAssetInformationForm.scss';
import { INITIAL_STATE_FORM, defaultCountry, defaultSportPlayer, defaultSportTeam } from './AdminAssetInformationFormInitialData';
import AdminAssetInformationFormProps from './AdminAssetInformationFormProps';
import { IAdminAssetFormData } from './IAdminAssetFormInitialValues';
import SelectCategoryField from './local-components/selectCategoryField/SelectCategoryField';
import SelectGeneralField from './local-components/selectGeneralField/SelectGeneralField';
import SelectSportsCountriesField from './local-components/selectSportsCountriesField/SelectSportsCountriesField';
import SelectSportsPlayersField from './local-components/selectSportsPlayersField/SelectSportsPlayersField';
import SelectSportsTeamsField from './local-components/selectSportsTeamsField/SelectSportsTeamsField';

const renderIRLScoreDetailField: React.FC<any> = (args: any) => {
    return (
        <ul>
            {args.fields.map((member: any, index: number) => (
                <div key={index} className="influence-container">
                    <li key={index}>
                        <div className="flex">
                            <div className="field-container item">
                                <Field
                                    disabled={true}
                                    name={`${member}.name`}
                                    type="text"
                                    component={Input.generateInputText} />
                            </div>
                        </div>

                        <h5>Hype Auditor</h5>
                        <div className="flex">
                            <div className="field-container item">
                                <label className="form_label">Followers engagés</label>
                                <Field
                                    name={`${member}.engagedFollowers`}
                                    type="number"
                                    component={Input.generateInputText} />
                            </div>
                            <div className="field-container item">
                                <label className="form_label">Followers engagés sur les jours</label>
                                <Field
                                    name={`${member}.engagedFollowersOverDays`}
                                    type="number"
                                    component={Input.generateInputText} />

                            </div>
                        </div>

                        <div className="flex">
                            <div className="field-container item">
                                <label className="form_label">Nombre de followers</label>
                                <Field
                                    name={`${member}.followers`}
                                    type="number"
                                    component={Input.generateInputText} />
                            </div>

                            <div className="field-container item">
                                <label className="form_label">Nombre de publications</label>
                                <Field
                                    name={`${member}.mediaCount`}
                                    type="number"
                                    component={Input.generateInputText} />
                            </div>
                        </div>

                        <h5>A la main</h5>
                        <div className="flex">
                            <div className="field-container item">
                                <label className="form_label">Progressive Score</label>
                                <Field
                                    name={`${member}.progressiveScore`}
                                    type="number"
                                    component={Input.generateInputText} />
                            </div>

                            <div className="field-container item">
                                <label className="form_label">Relative Score</label>
                                <Field
                                    name={`${member}.relativeScore`}
                                    type="number"
                                    component={Input.generateInputText} />
                            </div>
                        </div>
                    </li>
                </div>
            ))}
        </ul>
    );
};
const generateMatchingAndPredictPriceStrategyMethodsSelect = (fieldProps: any) => {
    return (
        <WrapperSelect
            valueChange={fieldProps.input.onChange}
            propsReduxForm={fieldProps}
            customValue=""
            data={[{ value: SECONDARY_MARKET_MATCHING_STRATEGIES.NEEREST_FROM_CURRENT_VALUE, label: SECONDARY_MARKET_MATCHING_STRATEGIES.NEEREST_FROM_CURRENT_VALUE }, { value: SECONDARY_MARKET_MATCHING_STRATEGIES.FLOOR_PRICE, label: SECONDARY_MARKET_MATCHING_STRATEGIES.FLOOR_PRICE }]}
            type={WRAPPER_SELECT_TYPE.NATIVE} />
    );
};

const generateCurrentValueComputationnMethodsSelect = (fieldProps: any) => {
    return (
        <WrapperSelect
            valueChange={fieldProps.input.onChange}
            propsReduxForm={fieldProps}
            customValue=""
            data={[
                { value: SECONDARY_MARKET_CURRENT_VALUE_COMPUTATION_METHODS.AVG, label: `PRICE_HISTORY_${SECONDARY_MARKET_CURRENT_VALUE_COMPUTATION_METHODS.AVG}` },
                { value: SECONDARY_MARKET_CURRENT_VALUE_COMPUTATION_METHODS.MAX, label: `PRICE_HISTORY_${SECONDARY_MARKET_CURRENT_VALUE_COMPUTATION_METHODS.MAX}` },
                { value: SECONDARY_MARKET_CURRENT_VALUE_COMPUTATION_METHODS.ORDERS_PRICES_AVG, label: SECONDARY_MARKET_CURRENT_VALUE_COMPUTATION_METHODS.ORDERS_PRICES_AVG },
            ]}
            type={WRAPPER_SELECT_TYPE.NATIVE} />
    );
};

const AdminAssetInformationForm: React.FC<InjectedFormProps<{}, AdminAssetInformationFormProps> & AdminAssetInformationFormProps> = (props) => {
    const [isSportCategorySelected, setIsSportCategorySelected] = useState<boolean>(SPORT_ASSET_CATEGORIES_ID.includes(props.initialValues.mainCategory));
    const darkTheme: boolean = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_THEME) === 'dark';
    const assetOrder = useSelector(assetOrderSelector);

    const { countriesList, isSportsCountryFetchLoading, fetchSportsCountries } = useSportsCountries();
    const { sportsTeamsList, isSportsTeamsFetchLoading, fetchSportsTeamsByCountry } = useSportsTeams();
    const { footballPlayersList, isSportsPlayersFetchLoading, fetchSportsPlayersByTeamId } = useSportsPlayers();
    const required = (value: any) => (value || typeof value === 'number' ? undefined : 'Required');

    useEffect(() => {
        if (props.initialValues.mainCategory === ASSETS_CATEGORIES[AssetCategory.Football]) fetchSportsCountries();
        if (props.initialValues.sportCountry) fetchSportsTeamsByCountry(props.initialValues.sportCountry);
        if (props.initialValues.sportTeam?.id) fetchSportsPlayersByTeamId(props.initialValues.sportTeam.id);
    }, []);


    useEffect(() => {
        if (props.formValues.sportCountry === NONE) {
            props.change('sportTeam', defaultSportTeam);
            props.change('sportPlayer', defaultSportPlayer);
        }
        else if (props.formValues.sportTeam?.id === '0') {
            props.change('sportPlayer', defaultSportPlayer);
        }
    }, [props.formValues.sportCountry, props.formValues.sportTeam]);

    const handleAssetFormData = (formData: Partial<IAdminAssetFormData>) => {
        const { sportCountry, sportTeam, sportPlayer } = formData;
        const { isRetired, ...formDataWithoutIsRetired } = formData;
        //QUICK FIX
        if (!sportCountry || !sportTeam)
            return props.onAssetUpdate({
                ...formDataWithoutIsRetired,
                sportPlayer: sportPlayer ? { ...sportPlayer, isRetired } : { isRetired },
                sportTeam: {
                    id: 0,
                    name: ''
                }
            });

        const country = sportCountry === NONE ? '' : sportCountry;

        const selectedteamInfo = sportsTeamsList.find((team: ISportsTeamNameInfo) => team.id === parseInt(sportTeam.id));
        if (!sportPlayer)
            return props.onAssetUpdate({
                ...formDataWithoutIsRetired,
                sportCountry: country,
                sportTeam: selectedteamInfo,
                sportPlayer: { isRetired },
            });
        const selectedPlayerInfo: ISportsFootballPlayersInfo | undefined = footballPlayersList.find((footballPlayer: ISportsFootballPlayersInfo) => footballPlayer.id === parseInt(sportPlayer.id));
        return props.onAssetUpdate({
            ...formDataWithoutIsRetired,
            sportCountry: country,
            sportTeam: selectedteamInfo || {
                id: 0,
                name: ''
            },
            sportPlayer: {
                isRetired,
                ...(selectedPlayerInfo && { ...selectedPlayerInfo }),
                ...sportPlayer,
            },
        });



    };
    const curveTypeData = CURVE_TYPE_LIST.map(curveType => ({
        label: curveType,
        value: curveType
    }));

    return (
        <div className="asset-information-form">
            <form onSubmit={props.handleSubmit(handleAssetFormData)} className="">

                <div className="container">
                    <h3>Personnal Information</h3>
                    <hr />
                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-name')}</label>
                            <Field
                                name={'name'}
                                type="text"
                                component={Input.generateInputText} />
                        </div>

                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-main-category')}</label>
                            <Field
                                name={'mainCategory'}
                                validate={[required]}
                                type="select"
                                options={props.categories}
                                component={SelectCategoryField}
                                setStateChange={setIsSportCategorySelected}
                            />
                        </div>

                        {isSportCategorySelected && <>
                            {isSportsCountryFetchLoading &&
                                <CircularLoader
                                    loaderMessage=''
                                    loaderLocal
                                    localLoaderStyle={{ marginBottom: '8px', height: '22px', width: '22px' }}
                                />
                            }
                            {!isSportsCountryFetchLoading &&
                                <div className='sports-container'>
                                    <div className="field-container item">
                                        <label className="form_label">{i18next.t('asset-form-admin-sports-country')}</label>
                                        <Field
                                            name={'sportCountry'}
                                            type="select"
                                            options={[defaultCountry, ...countriesList.data].map((country: ISportsCountries) => ({ label: country.name, value: country.name }))}
                                            component={SelectSportsCountriesField}
                                        />
                                    </div>
                                    <div className="flex">
                                        <div className="field-container item">
                                            <label className="form_label">{i18next.t('asset-form-admin-team')}</label>
                                            {isSportsTeamsFetchLoading
                                                ? <CircularLoader
                                                    loaderMessage=''
                                                    loaderLocal
                                                    localLoaderStyle={{ marginBottom: '8px', height: '30px', width: '30px' }}
                                                />
                                                : <Field
                                                    name={'sportTeam.id'}
                                                    type="select"
                                                    options={[defaultSportTeam, ...sportsTeamsList].map((team: ISportsTeamNameInfo) => ({ label: team.name, value: team.id }))}
                                                    component={SelectSportsTeamsField}
                                                    sportsTeamsList={sportsTeamsList}
                                                />
                                            }
                                        </div>
                                    </div>

                                    <div className="flex">
                                        {parseInt(props.formValues.sportTeam?.id ?? 0) !== ZERO && <div className="field-container item">
                                            <label className="form_label">{i18next.t('asset-form-admin-player', { footballSeason: CURRENT_FOOTBALL_SEASON_YEAR })}</label>
                                            {isSportsPlayersFetchLoading
                                                ? <CircularLoader
                                                    loaderMessage=''
                                                    loaderLocal
                                                    localLoaderStyle={{ marginBottom: '8px', height: '30px', width: '30px' }}
                                                />
                                                : <Field
                                                    name={'sportPlayer.id'}
                                                    type="select"
                                                    options={[defaultSportPlayer, ...footballPlayersList].map((footballPlayer: ISportsFootballPlayersInfo) => ({ label: footballPlayer.name, value: footballPlayer.id }))}
                                                    component={SelectSportsPlayersField}
                                                />
                                            }
                                        </div>}
                                    </div>
                                </div>

                            }
                        </>
                        }


                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-subtitle')}</label>
                            <Field
                                name={'subtitle'}
                                type="text"
                                component={Input.generateInputText} />
                        </div>

                    </div>
                    <div className="flex">
                        <div className="field-container item flex">
                            <label className="form_label">{i18next.t('asset-form-admin-score-boosted')}</label>
                            <Field
                                name={'scoreBoosted'}
                                type="checkbox"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateCheckbox}
                            />
                            {isSportCategorySelected &&
                                <>
                                    <label className="form_label">{i18next.t('asset-form-admin-retired')}</label>
                                    <Field
                                        name={'isRetired'}
                                        type="checkbox"
                                        custom={{
                                            black: !darkTheme,
                                        }}
                                        component={Input.generateCheckbox}
                                    />
                                </>

                            }
                        </div>
                    </div>

                    {SCRAPABLE_SPORT_ASSET_CATEGORIES.includes(props.formValues.mainCategory) &&
                        <div className='externalDataSource-fields'>
                            <h3>External data source</h3>
                            <hr />
                            <label className="form_label">{i18next.t('asset-form-admin-externalDataSource.id')}</label>
                            <Field
                                name={'sportPlayer.id'}
                                type="number"
                                component={Input.generateInputText}
                            />
                            <label className="form_label">{i18next.t('asset-form-admin-externalDataSource.url')}</label>
                            <Field
                                name={'sportPlayer.scrapeUrl'}
                                type="url"
                                component={Input.generateInputText}
                            />
                            <hr />
                        </div>
                    }



                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-card-level')}</label>
                            <Field
                                name={'cardLevel'}
                                type="select"
                                options={Object.entries(ASSET_CARD_LEVEL_NAME).map((entry) => ({ label: entry[1], value: entry[0] }))}
                                component={SelectGeneralField} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-description')}</label>
                            <Field
                                name={'description'}
                                type="text"
                                component={Input.generateTextArea} />
                        </div>
                    </div>

                    {assetOrder && assetOrder.length > 0 && <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-order')}</label>
                            <Field
                                name={'listOrder'}
                                type="select"
                                options={assetOrder}
                                component={SelectGeneralField} />
                        </div>
                    </div>}
                </div>

                <div className="container">
                    <h3>Social Media</h3>
                    <hr />
                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-facebook')}</label>
                            <Field
                                name={'facebook'}
                                type="text"
                                component={Input.generateInputText} />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-facebook')} followers</label>
                            <Field
                                name={'facebookFollowers'}
                                type="number"
                                component={Input.generateInputText} />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-twitter')}</label>
                            <Field
                                name={'twitter'}
                                type="text"
                                component={Input.generateInputText} />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-twitter')} followers</label>
                            <Field
                                name={'twitterFollowers'}
                                type="number"
                                component={Input.generateInputText} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-youtube')}</label>
                            <Field
                                name={'youtube'}
                                type="text"
                                component={Input.generateInputText} />
                        </div>

                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-youtube')} followers</label>
                            <Field
                                name={'youtubeFollowers'}
                                type="number"
                                component={Input.generateInputText} />
                        </div>

                    </div>

                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-youtube-video')}</label>
                            <Field
                                name={'youtubeVideo'}
                                type="text"
                                component={Input.generateInputText} />
                        </div>

                    </div>

                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-tiktok')}</label>
                            <Field
                                name={'tiktok'}
                                type="text"
                                component={Input.generateInputText} />
                        </div>

                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-tiktok')} followers</label>
                            <Field
                                name={'tiktokFollowers'}
                                type="number"
                                component={Input.generateInputText} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-instagram')} link</label>
                            <Field
                                name={'instagram'}
                                type="text"
                                component={Input.generateInputText} />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-instagram')} followers</label>
                            <Field
                                name={'instagramFollowers'}
                                type="number"
                                component={Input.generateInputText} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-instagram')} tag</label>
                            <Field
                                name={'instagramTag'}
                                type="text"
                                component={Input.generateInputText} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-snapchat')}</label>
                            <Field
                                name={'snapchat'}
                                type="text"
                                component={Input.generateInputText} />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">{i18next.t('asset-form-admin-snapchat')} followers</label>
                            <Field
                                name={'snapchatFollowers'}
                                type="number"
                                component={Input.generateInputText} />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <h3>Gestion de l'achat/vente</h3>
                    <hr />
                    <div className="flex">
                        <div className="field-container item">
                            <Field
                                label="Activer/Désactiver la vente"
                                name={'marketConfiguration.secondaryMarket.isSellable'}
                                type="checkbox"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateCheckbox}
                            />
                        </div>

                        <div className="field-container item">
                            <Field
                                label="Activer/Désactiver l'achat"
                                name={'marketConfiguration.secondaryMarket.isBuyable'}
                                type="checkbox"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateCheckbox}
                            />
                        </div>

                        <div className="field-container item">
                            <div>
                                <Field
                                    label="Activer/Désactiver l'injection de tokens via la routine"
                                    name={'marketConfiguration.secondaryMarket.isTokensInjectionEnabled'}
                                    type="checkbox"
                                    custom={{
                                        black: !darkTheme,
                                    }}
                                    component={Input.generateCheckbox} />
                            </div>
                        </div>
                    </div>

                    <div className='flex'>
                        <div className="field-container item">
                            <div>
                                <Field
                                    label="Affiché un disclaimer avant la vente"
                                    name={'marketConfiguration.secondaryMarket.warningBeforeSell'}
                                    type="checkbox"
                                    custom={{
                                        black: !darkTheme,
                                    }}
                                    component={Input.generateCheckbox} />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <h3>{i18next.t('admin.asset.title.market')}</h3>
                    <hr />
                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">Ratio en variation haute</label>
                            <Field
                                name={'marketConfiguration.variation.maxVariationRatio'}
                                type="number"
                                component={Input.generateInputText}
                                custom={{
                                    InputProps: {
                                        inputProps: {
                                            max: 1, min: 0, step: 0.01,
                                        },
                                    },
                                }} />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Ratio en variation basse</label>
                            <Field
                                name={'marketConfiguration.variation.minVariationRatio'}
                                type="number"
                                component={Input.generateInputText}
                                custom={{
                                    InputProps: {
                                        inputProps: {
                                            max: 1, min: 0, step: 0.01,
                                        },
                                    },
                                }} />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Proba +/- (0=baisse. 1=hausse)</label>
                            <Field
                                name={'marketConfiguration.variation.probabilityOfGettingPlusOrMinus'}
                                type="number"
                                component={Input.generateInputText}
                                custom={{
                                    InputProps: {
                                        inputProps: {
                                            max: 1, min: 0, step: 0.1,
                                        },
                                    },
                                }}
                            />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Variation activée</label>
                            <Field
                                name={'marketConfiguration.variation.enabled'}
                                type="checkbox"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateCheckbox}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">Méthode de calcul (Predict + Match) MS</label>
                            <Field
                                name={'marketConfiguration.secondaryMarket.orderMatchCalculationMethod'}
                                type="select"
                                component={generateMatchingAndPredictPriceStrategyMethodsSelect} />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Méthode de calcul CurrentValue (MS)</label>
                            <Field
                                name={'marketConfiguration.secondaryMarket.currentPriceCalculation.method'}
                                type="select"
                                component={generateCurrentValueComputationnMethodsSelect} />
                        </div>

                        <div className="field-container item">
                            <label className="form_label">Low supply threshold</label>
                            <Field
                                name={'marketConfiguration.secondaryMarket.lowSupplyThreshold'}
                                type="number"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateInputText}
                            />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Activer la tolérence aux ordres de vente</label>
                            <Field
                                name={'marketConfiguration.secondaryMarket.enableSellOrderTolerance'}
                                type="checkbox"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateCheckbox}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">Orderbook fees rate</label>
                            <Field
                                name={'marketConfiguration.secondaryMarket.orderBookFees.sellFees'}
                                type="number"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateInputText}
                            />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Orderbook fixed fees</label>
                            <Field
                                name={'marketConfiguration.secondaryMarket.orderBookFees.sellFixedFees'}
                                type="number"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateInputText}
                            />
                        </div>
                        <div className="field-container item">
                            <label className="form_label"> Lp fees rate</label>
                            <Field
                                name={'marketConfiguration.secondaryMarket.liquidityPoolFees.sellFees'}
                                type="number"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateInputText}
                            />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Lp fixed fees</label>
                            <Field
                                name={'marketConfiguration.secondaryMarket.liquidityPoolFees.sellFixedFees'}
                                type="number"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateInputText}
                            />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Parallel secondary market</label>
                            <Field
                                name={'marketConfiguration.secondaryMarket.isParallelSecondaryMarket'}
                                type="checkbox"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateCheckbox}
                            />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <h3>{i18next.t('admin.asset.title.progress.behavior')}</h3>
                    <hr />
                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">Minutes depuis début VP pour progression auto</label>
                            <Field
                                name={'marketConfiguration.privateSell.progressBarConfiguration.showProgressFromPrivateSellStartInMinutes'}
                                type="number"
                                component={Input.generateInputText}
                                custom={{
                                    InputProps: {
                                        inputProps: {
                                            max: 10000, min: 0, step: 1,
                                        },
                                    },
                                }} />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Progression min interval (seconds)</label>
                            <Field
                                name={'marketConfiguration.privateSell.progressBarConfiguration.minRangeForProgression'}
                                type="number"
                                component={Input.generateInputText}
                                custom={{
                                    InputProps: {
                                        inputProps: {
                                            max: 10000, min: 1, step: 1,
                                        },
                                    },
                                }} />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Progression max interval (seconds)</label>
                            <Field
                                name={'marketConfiguration.privateSell.progressBarConfiguration.maxRangeForProgression'}
                                type="number"
                                component={Input.generateInputText}
                                custom={{
                                    InputProps: {
                                        inputProps: {
                                            max: 10000, min: 1, step: 1,
                                        },
                                    },
                                }} />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">Largeur min à ajouter</label>
                            <Field
                                name={'marketConfiguration.privateSell.progressBarConfiguration.minimumProgressWidthToAdd'}
                                type="number"
                                component={Input.generateInputText}
                                custom={{
                                    InputProps: {
                                        inputProps: {
                                            max: 10000, min: 1, step: 0.1,
                                        },
                                    },
                                }}
                            />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Largeur de base pour calcul progression</label>
                            <Field
                                name={'marketConfiguration.privateSell.progressBarConfiguration.defaultWidthForProgressCalculation'}
                                type="number"
                                component={Input.generateInputText}
                                custom={{
                                    InputProps: {
                                        inputProps: {
                                            max: 10000, min: 1, step: 0.1,
                                        },
                                    },
                                }}
                            />
                        </div>
                        <div className="field-container item">
                            <label className="form_label">Largeur minimum pour calcul de progression</label>
                            <Field
                                name={'marketConfiguration.privateSell.progressBarConfiguration.fakeWidthMagicNumberForProgression'}
                                type="number"
                                component={Input.generateInputText}
                                custom={{
                                    InputProps: {
                                        inputProps: {
                                            max: 10000, min: 1, step: 0.1,
                                        },
                                    },
                                }} />
                        </div>


                    </div>
                </div>
                <div className="container">
                    <h3>{i18next.t('admin.asset.title.primary.market.behavior')}</h3>
                    <hr />
                    <div className="flex">
                        <div className="field-container item">
                            <label className="form_label">Pourcentage des recettes pour le vip</label>
                            <Field
                                name={'marketConfiguration.privateSell.vipPartAtEachBuyRatio'}
                                type="number"
                                component={Input.generateInputText}
                                custom={{
                                    InputProps: {
                                        inputProps: {
                                            max: 1, min: 0, step: 0.01
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <h3>{i18next.t('admin.asset.title.available.tournament')}</h3>
                    <hr />
                    <div className="field-container item">
                        <Field
                            label={i18next.t('disable-asset')}
                            type="checkbox"
                            custom={{
                                black: !darkTheme,
                            }}
                            component={Input.generateCheckbox}
                            name="availableForTournament" />
                    </div>
                </div>

                <div className="container">
                    <h3>{i18next.t('admin.asset.title.performance')}</h3>
                    <hr />

                    <div className="field-container item">
                        <Field
                            label="Activer le rendement pour ce talent (yield)"
                            type="checkbox"
                            custom={{
                                black: !darkTheme,
                            }}
                            component={Input.generateCheckbox}
                            name="yieldEnabled" />
                    </div>
                </div>

                <div className="container">
                    <h3>{i18next.t('admin.asset.title.influece.variation')}</h3>
                    <hr />
                    <div className="flex">
                        <div className="field-container item">
                            <Field
                                label={i18next.t('irl-score')}
                                type="number"
                                name={'IRLScore'}
                                component={Input.generateInputText}
                                custom={{
                                    InputProps: {
                                        inputProps: {
                                            max: 100, min: 0, step: 1,
                                        },
                                    },
                                }} />
                        </div>
                    </div>

                    <div className="field-container item">
                        <FieldArray
                            component={renderIRLScoreDetailField}
                            name="IRLScoreDetails" />
                    </div>

                </div>

                <div className="container">
                    <div className="field-container item">
                        <h3>Score Sportif</h3>
                        <hr />
                        <Field
                            name='sportsScore'
                            type="number"
                            component={Input.generateInputText} />
                    </div>
                </div>

                <div className="container">
                    <h3>{i18next.t('admin-asset-information.contract.title')}</h3>
                    <hr />
                    <div className="field-container item">
                        <Field
                            label={i18next.t('admin-asset-information.contract.contractTerm')}
                            type="number"
                            custom={{
                                black: !darkTheme,
                                InputProps: {
                                    inputProps: {
                                        min: 0, step: 1,
                                    },
                                },
                            }}
                            component={Input.generateInputText}
                            name="contractTerm" />
                    </div>
                </div>

                <div className="container">
                    <h3>Secondary Market Config</h3>
                    <hr />
                    <h5 style={{ textAlign: 'center' }}>Restriction</h5>
                    <div className="flex">
                        <div className="field-container item">
                            <Field
                                label="Activer la restriction d'achat au marché secondaire"
                                type="checkbox"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateCheckbox}
                                name="marketConfiguration.secondaryMarket.restriction.enabled" />
                        </div>
                        <div className="field-container item">
                            <Field
                                label="Tokens max par user pendant la restriction"
                                type="number"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateInputText}
                                name="marketConfiguration.secondaryMarket.restriction.maxTokensPerUser" />
                        </div>
                        <div className="field-container item">
                            <Field
                                label="Temps de la restriction (en minutes)"
                                type="number"
                                custom={{
                                    black: !darkTheme,
                                }}
                                component={Input.generateInputText}
                                name="marketConfiguration.secondaryMarket.restriction.timeRestrictionInMinutes" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <h3>LP Curve Configuration</h3>
                    <hr />
                    <h5 style={{ textAlign: 'center' }}>Curve Type</h5>
                    <div className="flex">

                        <Field
                            disabled={props.editMode}
                            name={'assetCurveConfiguration.curveType'}
                            type="select"
                            options={curveTypeData}
                            component={SelectGeneralField}
                        />

                        {
                            props.formValues.assetCurveConfiguration?.curveType !== AssetCurveType.DEFAULT &&
                            <>
                                <div className="field-container item">
                                    <label className="form_label">End price</label>
                                    <Field
                                        disabled={props.editMode}
                                        name={'assetCurveConfiguration.factors.endPrice'}
                                        type="number"
                                        custom={{
                                            black: !darkTheme,
                                        }}
                                        component={Input.generateInputText}
                                    />
                                </div>
                                {
                                    props.formValues.assetCurveConfiguration?.curveType !== AssetCurveType.LINEAR &&
                                    <div className="field-container item">
                                        <label className="form_label">Power</label>
                                        <Field
                                            disabled={props.editMode}
                                            name={'assetCurveConfiguration.factors.power'}
                                            type="number"
                                            custom={{
                                                black: !darkTheme,
                                            }}
                                            component={Input.generateInputText}
                                        />
                                    </div>
                                }
                            </>
                        }
                        {
                            props.formValues.assetCurveConfiguration?.curveType === AssetCurveType.STEPWISE &&
                            <>
                                <div className="field-container item">
                                    <label className="form_label">Plateau Length</label>
                                    <Field
                                        disabled={props.editMode}
                                        name={'assetCurveConfiguration.factors.plateauLength'}
                                        type="number"
                                        custom={{
                                            black: !darkTheme,
                                        }}
                                        component={Input.generateInputText}
                                    />
                                </div>
                                <div className="field-container item">
                                    <label className="form_label">Plateau Height Factor</label>
                                    <Field
                                        disabled={props.editMode}
                                        name={'assetCurveConfiguration.factors.plateauHeightFactor'}
                                        type="number"
                                        custom={{
                                            black: !darkTheme,
                                        }}
                                        component={Input.generateInputText}
                                    />
                                </div>
                            </>
                        }
                        {
                            props.formValues.assetCurveConfiguration?.curveType === AssetCurveType.WAVE &&
                            <>
                                <div className="field-container item">
                                    <label className="form_label">Modulation Factor</label>
                                    <Field
                                        disabled={props.editMode}
                                        name={'assetCurveConfiguration.factors.modulationFactorParam'}
                                        type="number"
                                        custom={{
                                            black: !darkTheme,
                                        }}
                                        component={Input.generateInputText}
                                    />
                                </div>
                                <div className="field-container item">
                                    <label className="form_label">Cosinus Factor</label>
                                    <Field
                                        disabled={props.editMode}
                                        name={'assetCurveConfiguration.factors.cosFactor'}
                                        type="number"
                                        custom={{
                                            black: !darkTheme,
                                        }}
                                        component={Input.generateInputText}
                                    />
                                </div>
                                <div className="field-container item">
                                    <label className="form_label">Sinus Factor</label>
                                    <Field
                                        disabled={props.editMode}
                                        name={'assetCurveConfiguration.factors.sinFactor'}
                                        type="number"
                                        custom={{
                                            black: !darkTheme,
                                        }}
                                        component={Input.generateInputText}
                                    />
                                </div>
                            </>
                        }

                    </div>
                </div>

                <div className="button-submit">
                    <PrimaryButton
                        disabled={props.invalid || props.pristine || props.submitting}
                        receiveClickEvent={false}
                        type="submit"
                        textButton={i18next.t(props.buttonText)} />
                </div>
            </form >
        </div >
    );
};

const mapStateToProps = (state: AdminAssetInformationFormProps, ownProps: AdminAssetInformationFormProps) => {
    const initialValues = ownProps.initialValues ? { ...INITIAL_STATE_FORM, ...ownProps.initialValues } : INITIAL_STATE_FORM;
    const formValues = getFormValues('AdminAssetInformationForm')(state) || INITIAL_STATE_FORM;

    return {
        initialValues,
        formValues,
    };
};

const AdminAssetInformationFormRedux = reduxForm<{}, AdminAssetInformationFormProps>({
    form: 'AdminAssetInformationForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    enableReinitialize: true,
})(AdminAssetInformationForm);

export default React.memo(connect(mapStateToProps)(AdminAssetInformationFormRedux));
