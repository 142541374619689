import clsx from 'clsx';
import Copy from 'components/icons/Copy';
import CircularLoader from 'components/small/CircularLoader';
import { G_TAGS_KNOWN_EVENTS, HTTP_STATUS_OK } from 'config/_const';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { acceptReferralTerms } from 'service/auth/actions';
import { accountSelector } from 'service/auth/selectors';
import { managementNotification } from 'service/notification/action';
import { SegmentTracking } from 'service/segment/SegmentTracking';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import Application from '../../../Application';
import './ReferenceYourFriends.scss';
import ReferenceYourFriendsProps from './ReferenceYourFriendsProps';

const ReferenceYourFriends: React.FC<ReferenceYourFriendsProps> = (props) => {
    const greenColor: string = '#53D081';
    const { referral } = useSelector(accountSelector);
    const dispatch = useDispatch();
    const { ownReferralId } = referral;
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);

    useEffect(() => {
        props.auth.loading ? setShowLoader(true) : setShowLoader(false);
    }, [props.auth.loading]);

    const getReferralLink = () => {
        const currentPath = window.location.href;
        const referralToken = ownReferralId || '...';
        if (currentPath.includes("/asset/")) return `${currentPath}?referral=${referralToken}`;
        return `${window.BASE_URL}profile?mode=REGISTER&referral=${referralToken}`;
    }

    return (
        <div className="affiliation_coupon">
            {ownReferralId &&
                <>
                    {Application.getInstance().showGamblingFeature &&
                        <>
                            <div className="coupon_btns">
                                <div className={'affiliation-container'}>
                                    <p className="affiliated-link no-margin">{getReferralLink()}</p>
                                    <CopyToClipboard
                                        onCopy={() => {
                                            TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.COPYING_REFERRAL_LINK, {
                                                referralLink: getReferralLink(),
                                            });
                                            SegmentTracking.sendCopyingReferralLinkEvent({ referralLink: getReferralLink(), userEmail: props.auth.data.account.email, userId: props.auth.data.account.id });
                                            setIsLinkCopied(true)
                                            dispatch(managementNotification(true, i18next.t('Aboutus.tab.about.copytoclipboard'), HTTP_STATUS_OK));
                                        }}
                                        text={getReferralLink()}>
                                        <div className='affiliation-copy-container'>
                                            <p className={clsx('msg-text no-margin', { 'green-text': isLinkCopied })}>
                                                {isLinkCopied ? i18next.t('text.copied') : i18next.t('text.copy')}
                                            </p>
                                            <div className={clsx('affiliation-copy', { 'green-bg': isLinkCopied })}>
                                                <Copy className='hover' fill={isLinkCopied ? greenColor : undefined} />
                                            </div>
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </>}
                    {showLoader &&
                        <CircularLoader
                            loaderMessage={''}
                            loaderLocal={false}
                            doNotSplitMessage={true} />
                    }
                </>}
        </div >
    );
};

const mapStateToProps = (state: ReferenceYourFriendsProps) => ({
    auth: state.auth,
});

export default compose(
    connect(mapStateToProps, {
        acceptReferralTerms,
    }),
)(ReferenceYourFriends);
