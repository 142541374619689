import React from 'react';
import clsx from 'clsx';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IRotateCircularBtnProps from './RotateCircularBtnProps';
import './RotateCircularBtn.scss';

const RotateCircularBtn: React.FunctionComponent<IRotateCircularBtnProps> = ({ canRotate180deg, onTrigger }) => {
  return (
    <div
      className={clsx("rotate-circular-btn hover", { "rotate": canRotate180deg })}
      onClick={onTrigger}
    >
      <KeyboardArrowUpIcon />
    </div>
  );
};

export default RotateCircularBtn;
