
import type { RootState } from 'model/Redux';
import { createSelector, Selector } from 'reselect';

export const bottomBarSelector: Selector<RootState, { isOpen: boolean }> = state => state.bottomBar.data;

export const selectBottomBar = createSelector(
    bottomBarSelector,
    (state) => {
        return state;
    },
);