import * as React from "react";

const RugbyIcon: React.FC = () => {
    return (
        <svg
            width='19'
            height='19.033'
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path d="M208.9 37.1C274.5 12.8 357.6 6.5 461.5 23.8c13 2.2 23.4 12.1 26.1 25.1c17.5 84.8 18.6 211.3-41.6 309.5c-30.6 50-76.8 92-142.9 116.5c-65.6 24.3-148.7 30.7-252.6 13.3c-13-2.2-23.4-12.1-26.1-25.1C6.9 378.3 5.8 251.8 66 153.6c30.6-50 76.8-92 142.9-116.5zM379.3 155.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L304 185.4l-20.7-20.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L281.4 208 256 233.4l-20.7-20.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L233.4 256 208 281.4l-20.7-20.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L185.4 304l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L208 326.6l20.7 20.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L230.6 304 256 278.6l20.7 20.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L278.6 256 304 230.6l20.7 20.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L326.6 208l52.7-52.7z" />
        </svg>
    );
}

export default RugbyIcon;
