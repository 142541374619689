import i18n from '18n'
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons'
import Application from 'Application'
import classNames from 'classnames'
import Crown from 'components/icons/Crown'
import RightFromBracket from 'components/icons/RightFromBracket'
import DiscordIcon from 'components/small/DiscordIcon/DiscordIcon'
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton'
import Divider from 'components/small/divider/Divider'
import IdentityVerificationPending from 'components/small/identity-verification-pending/IdentityVerificationPending'
import ProfilIcon from 'components/small/profil-icon/ProfilIcon'
import { AFFILIATE_BANNER, DISCORD_LINK, HIDE_DISCORD_BUTTON, TRENDEX_ROLES } from 'config/_const'
import IUseAccount from 'hooks/use-account/IUseAccount'
import useAccount from 'hooks/use-account/useAccount'
import IUseUserIdentityVerification from 'hooks/use-user-identity-verification/IUseUserIdentityVerification'
import useUserIdentityVerification from 'hooks/use-user-identity-verification/useUserIdentityVerification'
import useUserLeague from 'hooks/use-user-league/useUserLeague'
import useIsDesktopMode from 'hooks/useIsDesktopMode'
import React from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { sendEmailVerification } from 'service/auth/actions'
import { managementModal } from 'service/modal/action'
import Utils from 'utils/Utils'
import FirstDepositAchievement from '../../small/first-deposit-achievement/FirstDepositAchievement'
import TrendexAcademy from '../trendex-academy/TrendexAcademy'
import LeagueProgressMap from '../user-league/localComponent/LeagueProgressMap/LeagueProgressMap'
import './UserProfile.scss'
import { IRectangleCard, IUserProfile } from './UserProfileProps'

export const UserProfile: React.FC<IUserProfile> = (props: IUserProfile) => {
    const dispatch = useDispatch();
    const { userLeagueViewModel } = useUserLeague();
    const { accountViewModel }: IUseAccount = useAccount();
    const isEmailValidated = accountViewModel.account.validatedEmail;
    const isIdentityVerificationPending = accountViewModel.isIdentityPending;
    const isIdentityVerificationConfirmed = accountViewModel.isIdentityConfirmed;
    const { launchIdentityVerification }: IUseUserIdentityVerification = useUserIdentityVerification({ shouldLaunchPolling: Application.getInstance().OnBoardingV2 && accountViewModel.IsOnboardingRunning });
    const affiliateBanner = AFFILIATE_BANNER[Utils.getCurrentLanguage().toUpperCase()] ?? AFFILIATE_BANNER.EN;
    const isDesktop = useIsDesktopMode();

    const RectangleCard = (cardProps: IRectangleCard): JSX.Element => <>
        <div className={`rectangle-card hover ${cardProps.className}`}>
            <span className='icon'>{cardProps.icon}</span>
            <div className='title'>{cardProps.title}</div>
        </div>
    </>;

    const leagueIcon = userLeagueViewModel.LeagueIcon && <userLeagueViewModel.LeagueIcon />;

    const onVerifyButtonClicked = () => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            showCancelButton: true,
            fullScreen: false,
            type: 'VERIFY_EMAIL',
            propsCustom: {
                onValidation: () => {
                    dispatch(sendEmailVerification());
                }
            },
        }))
    }

    return (
        <div className='user-profile-container'>
            <section className="user-profile-top-section">
                <ProfilIcon
                    level={0}
                    editIcon={false}
                    showEditText={false}
                    leagueIcon={leagueIcon}
                    avatar={accountViewModel.avatar}
                />
                <div className='user-profile-details'>
                    <h2 className='profile-name'>
                        {accountViewModel.pseudo || (accountViewModel.firstName + " " + (accountViewModel.LastName ?? null))}
                    </h2>
                    <div className='edit-myProfile'>
                        <span onClick={() => props.handleIsThisMainProfilePage(false)} className='hover'>{i18n.t('Profile.modifyProfile')}</span>
                    </div>
                </div>
            </section>

            {
                (accountViewModel.identityConfirmStatus && isIdentityVerificationPending) &&
                <IdentityVerificationPending />
            }

            {!(isEmailValidated && (isIdentityVerificationConfirmed || isIdentityVerificationPending)) &&
                <section className='user-profile-verification'>
                    <span className='message'>{i18n.t('user.verification.message')}</span>

                    <div className='step' >
                        <span className={classNames('content', { disabled: isEmailValidated })} onClick={onVerifyButtonClicked}> {isEmailValidated ? <CheckCircle style={{ width: '14px', height: "14px" }} /> : <RadioButtonUnchecked style={{ width: '14px', height: "14px" }} />}
                            <span className='text'>{i18n.t('Aboutus.tab.about.emailnotvarified')}</span>
                        </span>
                    </div>
                    <div className='step'>
                        <span className={classNames('content', { disabled: !isEmailValidated || (isIdentityVerificationConfirmed || isIdentityVerificationPending) })} onClick={launchIdentityVerification}>
                            {(isIdentityVerificationConfirmed || isIdentityVerificationPending)
                                ? <CheckCircle style={{ width: '14px', height: "14px" }} />
                                : <RadioButtonUnchecked style={{ width: '14px', height: "14px" }} />
                            }
                            <span className='text'> {i18n.t('user.verify.identity')}</span>
                        </span>
                    </div>
                </section>
            }

            {userLeagueViewModel.LeagueAttribution.UserHasLeagueTokens &&
                <section className='user-league'>
                    <div className="title">
                        <Trans
                            i18nKey="user.own.trends"
                            values={{
                                league: userLeagueViewModel.LeagueAttribution.Name,
                                tokens: userLeagueViewModel.LeagueAttribution.OwnedCurrentLeagueTokens,
                                trend: userLeagueViewModel.LeagueAttribution.UserTotalTokens
                            }}
                        />
                    </div>
                    <div className="progress-map">
                        <LeagueProgressMap userLeagueViewModel={userLeagueViewModel} />
                    </div>
                </section>
            }

            {!isDesktop &&
                <TrendexAcademy />
            }
            {Application.getInstance().showGamblingFeature &&
                <>
                    {Application.getInstance().showAchievementInProfile &&
                        <section className='achievements-section'>
                            <h2 className='achievement-topic'>{i18n.t('Achievements')}</h2>
                            <FirstDepositAchievement />
                        </section>
                    }
                    <section className='invite-banner-section'>
                        <div className='banner-title-img-container'>
                            <img className='banner-img' src={affiliateBanner} alt="banner" />
                        </div>
                        <div className='invite-button'>
                            <Link to={'/affiliate'}>
                                <PrimaryButton
                                    disabled={false}
                                    receiveClickEvent={false}
                                    type="submit"
                                    textButton={i18n.t('invite')} />
                            </Link>
                        </div>
                    </section>
                </>
            }
            <section className='rectangle-cards-section'>
                {!HIDE_DISCORD_BUTTON && <a href={DISCORD_LINK} target="_blank" rel="noopener noreferrer">
                    <RectangleCard icon={<DiscordIcon />} title={i18n.t('btn-discord-title')} />
                </a>}
                {accountViewModel.UserType !== TRENDEX_ROLES.VIP &&
                    <Link to="/become-vip">
                        <RectangleCard icon={<Crown />} title={i18n.t('text.becomeTalent')} />
                    </Link>
                }
                <Divider background='#D2D4E0' size='97%' />
                <Link to={'/'}>
                    <div onClick={() => props.onLogoutUser()}>
                        <RectangleCard className="logout-btn" icon={<RightFromBracket />} title={i18n.t('logout')} />
                    </div>
                </Link>
            </section>
        </div>
    )
};


export default UserProfile;