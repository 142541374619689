import { ZERO } from 'config/_const';
import IFootballStatisticsShot from 'interfaces/sports/football/statistics/IFootballStatisticsShot'; // Importation de l'interface

export default class FootballStatisticsShotViewModel {
    private total: number;
    private on: number;

    constructor(statistics: Partial<IFootballStatisticsShot>) {
        this.total = statistics.total ?? ZERO;
        this.on = statistics.on ?? ZERO;
    }

    get Total(): number {
        return this.total;
    }

    get On(): number {
        return this.on;
    }
}
