import * as React from 'react';

function ArrowLeftRight() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.523" height="23.523" viewBox="0 0 23.523 23.523">
            <path id="arrow-right-arrow-left-solid_" data-name="arrow-right-arrow-left-solid " d="M49.869,12.725a1.471,1.471,0,1,1-2.081-2.081l2.636-2.632H33.4a1.47,1.47,0,0,1,0-2.94H50.425L47.793,2.436A1.471,1.471,0,0,1,49.873.356L55.018,5.5a1.472,1.472,0,0,1,0,2.081l-5.144,5.144ZM37.5,23.013l-5.144-5.144a1.472,1.472,0,0,1,0-2.081L37.5,10.644a1.471,1.471,0,0,1,2.081,2.081l-2.632,2.636H53.975a1.47,1.47,0,1,1,0,2.94H36.949l2.636,2.636A1.471,1.471,0,1,1,37.5,23.018Z" transform="translate(-31.925 0.075)" fill="#fff" />
        </svg>

    );
}

export default ArrowLeftRight;
