import * as React from 'react';
import TokenYieldInformation from '../token-yield-information/TokenYieldInformation';
import { TOKEN_INFORMATION_CONFIG } from 'config/_const';
import Utils from 'utils/Utils';
import TokenYieldInformationBanner from '../token-yield-information-banner/TokenYieldInformationBanner';
import IYieldInformationProps from './IYieldInformation';
import withYieldInformation from 'hoc/with-yield-information/withYieldInformation';

const YieldInformation: React.FC<IYieldInformationProps> = ({ assetVM, canShowAsBanner, calculateMaxAPY }) => {
    const getYieldConfig = Utils.getYieldConfigPerAsset(assetVM.AssetId);

    return <>
        {canShowAsBanner
            ? <TokenYieldInformationBanner
                maxAPY={calculateMaxAPY?.(assetVM.CurrentValueAsNumber, getYieldConfig.maxYield) || 0}
                seedlingIconSize={TOKEN_INFORMATION_CONFIG.SEEDLING_ICON_SIZE}
                minYield={getYieldConfig.minYield}
                maxYield={getYieldConfig.maxYield}
                canShowYieldChartText={false}
            />
            : <TokenYieldInformation
                maxAPY={calculateMaxAPY?.(assetVM.CurrentValueAsNumber, getYieldConfig.maxYield) || 0}
                seedlingIconSize={TOKEN_INFORMATION_CONFIG.SEEDLING_ICON_SIZE}
                minYield={getYieldConfig.minYield}
                maxYield={getYieldConfig.maxYield}
                canShowYieldChartText={true}
            />
        }
    </>;
};

export default withYieldInformation(YieldInformation);
