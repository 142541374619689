import IWithdrawFormInitialValues from "components/medium/form/withdraw-form/IWithdrawFormInitialValues";
import { COUNTRY, MAX_WITHDRAW_ONBOARDING_WITHOUT_KYC, MINIMUM_REFUND_AMOUNT, WITHDRAWS_TYPES } from "config/_const";
import IWithdrawFeeStoreData from "service/withdraw-fee/IWithdrawFeeStoreData";
import { isValidPhoneNumber } from "utils/validator";
import IWithrawViewModel from "./IWIthdrawViewModel";
const IBAN = require('iban');

export default class WithdrawViewModel {
    private _withdrawData: IWithdrawFormInitialValues;
    private _zoneType: string;
    private _showAmountErrorAndFees: { amountError: boolean, fees: boolean; };
    private _withdrawFeeData: IWithdrawFeeStoreData;
    private _withdrawAmountInEuros: number;

    constructor(withdrawObject: IWithrawViewModel) {
        this._withdrawData = withdrawObject.withdrawData;
        this._zoneType = withdrawObject.withdrawData.zoneType;
        this._showAmountErrorAndFees = withdrawObject.showAmountErrorAndFees;
        this._withdrawFeeData = withdrawObject.withdrawFeeData;
        this._withdrawAmountInEuros = Number(this._withdrawData.amount).toDefaultCurrencyValue();
    };

    public get WithdrawData(): IWithdrawFormInitialValues {
        return this._withdrawData;
    };

    public get ZoneType(): string {
        return this._zoneType;
    };

    public get IsSepaForm(): boolean {
        return this.ZoneType === WITHDRAWS_TYPES.SEPA;
    };

    public get ShowAmountErrorAndFees(): { amountError: boolean, fees: boolean; } {
        return this._showAmountErrorAndFees;
    };

    public get WithdrawFeeData(): IWithdrawFeeStoreData {
        return this._withdrawFeeData;
    };

    public get FeesAmount(): number {
        return this.computeFees(this._withdrawAmountInEuros);
    };

    private computeFees(amount: number): number {
        return amount.multiply(this._withdrawFeeData.feesRate).addition(this._withdrawFeeData.feesFixedAmount);
    }

    public get PriceWithFees(): number {
        return this.IsSepaForm ? this._withdrawAmountInEuros : Number(this._withdrawAmountInEuros) + this.FeesAmount;
    };

    public get Receivable(): number {
        return this.IsSepaForm ? this._withdrawAmountInEuros.subtract(this.FeesAmount) : Number(this._withdrawAmountInEuros);
    }

    public isAmountNotEnough(value: number): boolean {
        return value < MINIMUM_REFUND_AMOUNT.toCurrencyValue();
    }

    public isIBANValid(): boolean {
        return IBAN.isValid(this._withdrawData.iban);
    }

    public required = (value: any) => (value || typeof value === 'number' ? undefined : 'Required');


    private getNetAmountFromTotalAmount = (value: number) => {
        return (value.subtract(Number(this.WithdrawFeeData.feesFixedAmount))).safeDivideBy(1 + Number(this.WithdrawFeeData.feesRate));
    };
    public getMaxWithdrawAmount = (userCredits: number) => {
        return this.IsSepaForm ? userCredits.toCurrencyValue() : this.getNetAmountFromTotalAmount(userCredits).toCurrencyValue();
    };

    public requiresKYCForWithdrawal = () => {
        return parseFloat(this.WithdrawData.amount) > MAX_WITHDRAW_ONBOARDING_WITHOUT_KYC || this.WithdrawData.iban.slice(0, 2) !== COUNTRY.FRENCH;
    };


    public get Disabled(): boolean {
        if (!isValidPhoneNumber(this.WithdrawData.phone) || !this.WithdrawData.lastName || !this.WithdrawData.firstName)
            return true;
        if (this.IsSepaForm) {
            if (!this.isIBANValid())
                return true;
            else return false;
        }
        if (this.WithdrawData.routingNumber.length === 0 || this.WithdrawData.bankAddress.length === 0 || this.WithdrawData.bankName.length === 0 || this.WithdrawData.bicOrSwift.length === 0)
            return true;
        return false;
    }

};