import { useEffect, useState } from 'react';
import { DESKTOP_MODE_BREAKPOINT } from '../config/_const';
import useWindowSize from './useWindowSize';

function useIsDesktopMode() {
    const [isDesktopMode, setIsDesktopMode] = useState(false);
    const [width] = useWindowSize();

    useEffect(() => {
        if (width >= DESKTOP_MODE_BREAKPOINT)
            return setIsDesktopMode(true);
        return setIsDesktopMode(false);
    }, [width]);

    return isDesktopMode;
}

export default useIsDesktopMode;
