import i18n from "18n";
import { ID_FACES, ID_TYPES } from "config/_const";

export default class IdentityTypeViewModel {
    private _idType: string;
    private _countryOfUser: string;
    constructor(idType: string, countryOfUser: string) {
        this._idType = idType;
        this._countryOfUser = countryOfUser
    }

    public get IdType(): string {
        return this._idType
    }

    public get RequiredIdFaces(): string[] {
        switch (this._idType) {
            case ID_TYPES.ID_CARD:
                return [ID_FACES.RECTO, ID_FACES.VERSO];
            case ID_TYPES.PASSPORT:
                return [ID_FACES.RECTO];
            default:
                return [ID_FACES.RECTO];
        }
    }

    public get IdTitle(): string {
        if (this._idType === ID_TYPES.PASSPORT) {
            return i18n.t("Identity.type.passport");
        }

        return i18n.t("Identity.type.cardId");
    }

    public getFileInputId(imageType: string): string {
        if (imageType === ID_FACES.VERSO) {
            return "card-identity-outside"
        }

        return "card-identity-inside"
    }

    public get SampleImagePath(): string {
        switch (this.IdType) {
            case ID_TYPES.ID_CARD:
                return `/img/valid-identity/${this._countryOfUser}/IDCard/idCard_example.webp`;
            case ID_TYPES.PASSPORT:
                return `/img/valid-identity/${this._countryOfUser}/Passport/passport_example.webp`;
            default:
                return `/img/valid-identity/${this._countryOfUser}/IDCard/idCard_example.webp`;
        }
    }

    public getUploadLabelFromImageType(imgType: string): string {
        switch (imgType) {
            case ID_FACES.RECTO:
                return i18n.t("Aboutus.tab.identity.uploadinside");
            case ID_FACES.VERSO:
                return i18n.t("Aboutus.tab.identity.uploadoutside");
            default:
                return i18n.t("Aboutus.tab.identity.uploadinside");
        }

    }
}