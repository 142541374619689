import LoadingIndicatorWithDelay from 'components/small/LoadingIndicatorWithDelay';
import Notification from 'components/small/notification/Notification';
import { G_TAGS_KNOWN_EVENTS, HOME_AFTER_SIGNUP, LOCAL_STORAGE_KNOWN_KEYS, TRENDEX_COOKIES } from 'config/_const';
import { ApplicationSportVersionEnum } from 'config/_enums';
import Cookies from 'js-cookie';
import IServerQuery from 'model/shared/IServerQuery';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { actionLoadMe, actionLogoutUser } from 'service/auth/actions';
import { managementModal } from 'service/modal/action';
import { managementNotification } from 'service/notification/action';
import { managementSideMenu } from 'service/side-menu/action';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import { setJwtToken } from 'service/token.service';
import Utils from 'utils/Utils';
import 'utils/extensionMethods/array';
import 'utils/extensionMethods/date';
import 'utils/extensionMethods/number';
import 'utils/extensionMethods/storage';
import '../../src/assets/css/bootstrap.min.css';
import '../../src/assets/scss/custom_style.scss';
import '../../src/assets/scss/custom_responsive.scss';
import '../../src/assets/scss/light_style.scss';
import '../../src/assets/scss/light_responsive.scss';

import AppContainer from './AppContainer/AppContainer';
import RootContainerProps from './RootContainerProps';

const publicRoutes = ['/reset-password', '/forgot-password'];

class RootContainer extends React.Component<RootContainerProps> {
    componentWillMount = () => {
        if (!publicRoutes.includes(this.props.location.pathname))
            this.onPostSocialConnect();
    };

    onPostSocialConnect = () => {
        const search: URLSearchParams = new URLSearchParams(window.location.search);
        const JWTToken: string | null = search.get('authToken');
        const uniqueUserId: string | null = search.get('uniqueUserId');
        const errorAuth: string | null = search.get('error');
        const email: string | null = search.get('email');
        const directBuy: string | null = search.get('directBuy');
        const fromLanding: string | null = search.get('fromLanding');
        const isLandingIframe: string | null = search.get('iframe');
        const signupVersion: string | null = search.get('v');
        const referral: string | null = search.get('referral');
        if (referral)
            localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_REFERRAL, referral);
        if (isLandingIframe)
            localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.LANDING_IFRAME, isLandingIframe);
        else localStorage.removeItem(LOCAL_STORAGE_KNOWN_KEYS.LANDING_IFRAME);
        if (fromLanding)
            localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.FROM_LANDING, fromLanding);
        if (email)
            localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.EMAIL, email);
        if (directBuy)
            localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.DIRECT_BUY, directBuy);

        if (errorAuth)
            return this.props.managementNotification(true, errorAuth);

        if (JWTToken) {
            TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.LOGIN);
            setJwtToken(JWTToken);
        }

        if (uniqueUserId) {
            Cookies.set(TRENDEX_COOKIES.UNIQUE_USER_ID.name, uniqueUserId);
        }

        if (signupVersion && Object.values(ApplicationSportVersionEnum).includes(signupVersion as ApplicationSportVersionEnum))
            localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.SIGNUP_VERSION, signupVersion as ApplicationSportVersionEnum);

        this.props.actionLoadMe();
    };

    render = () => {
        const returnQuery: IServerQuery = Utils.convertURLQueryToObject(window.location.search);

        if (returnQuery.authToken) {
            if (Utils.getIsVIPInLocalStorage())
                return <Redirect to={HOME_AFTER_SIGNUP.VIP} />;
            return <Redirect to={{
                pathname: `${window.location.pathname}`,
                state: { ...returnQuery },
            }} />;
        }

        if (returnQuery.directVip) {
            localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_DIRECT_VIP_DATE, new Date().getTime().toString());
            localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_DIRECT_VIP_SLUG, returnQuery.directVip);
        }

        return (

            <React.Suspense fallback={<LoadingIndicatorWithDelay />}>

                {/* Content application who change between the router */}
                <AppContainer />

                {/* Global snackbar for the react app */}
                <Notification />

            </React.Suspense>
        );
    };

}

const mapStateToProps = (state: RootContainerProps) => ({
    notifications: state.notifications,
});

export default compose(
    connect(mapStateToProps, {
        managementNotification,
        managementSideMenu,
        actionLoadMe,
        actionLogoutUser,
        managementModal,
    }),
)(withRouter(RootContainer));
