import { DEFAULT_ID_AS_STRING } from '../../../config/_const';
import ITeamConfig from '../../../model/Team/ITeamConfig';
import { TeamConfigMock } from '../../../model/Team/MockTeam';

export default class TeamConfigurationViewModel {
    private loading: boolean;
    private configuration: ITeamConfig;

    constructor(loading: boolean, configuration: ITeamConfig | undefined) {
        this.loading = loading;
        this.configuration = this.loadConfiguration(configuration);
    }

    private loadConfiguration(configuration: ITeamConfig | undefined): ITeamConfig {
        return configuration ?? TeamConfigMock;
    }

    public get isEmptyConfiguration() {
        return this.Loading && this.Configuration._id === DEFAULT_ID_AS_STRING;
    }

    public get Loading(): boolean {
        return this.loading;
    }

    public set Loading(value: boolean) {
        this.loading = value;
    }

    public get Configuration(): ITeamConfig {
        return this.configuration;
    }

    public set Configuration(config: ITeamConfig) {
        this.configuration = this.loadConfiguration(config);
    }

    public get MaxNumOfAssetTeam(): number {
        return this.Configuration.maxNumberOfAssets;
    }

    public get MinSharesByAssset(): number {
        return this.Configuration.minimumSharesByAsset;
    }
}
