import React from 'react';
import SuccessPageProps from './SuccessPageProps';
import { compose } from 'redux';
import { connect } from 'react-redux';
import './SuccessPage.scss';
import i18next from 'i18next';
import { G_TAGS_KNOWN_EVENTS } from 'config/_const';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';

class SuccessPage extends React.Component<SuccessPageProps, {}> {

    constructor(props: SuccessPageProps) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.VIRTUAL_PAGE_VIEW, {
            virtualPageURL: '/post-buy',
            virtualPageTitle: 'Payment successful'
        }, 'PageDataLayer');
    }

    render = () => {
        const title = this.props.title || i18next.t('initPayment.awesome');
        const message = this.props.message || i18next.t('initPayment.awesomeDescription');

        return (
            <div className="main-container-success">
                <div className="check-container-success">
                    <div className="check-background-success">
                        <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className="check-shadow-success"></div><br></br>
                </div>
                <div className="container-text-success">
                    <h3 className="title-text-success">{title}</h3>
                    <p className="message-text-success">{message}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (_state: SuccessPageProps) => ({

});

export default compose(
    connect(mapStateToProps, {

    }),
)(SuccessPage);
