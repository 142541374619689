import i18n from "18n";
import Application from "Application";
import { BITPAY_URL_INTENT, MAXIMUM_WALLET_REFUND_AMOUNT, MINIMUM_WALLET_REFUND_AMOUNT, WALLET_REFUND_OPTIONS } from "config/_const";

export default class WalletRefundViewModel {
    private value: number;
    private fiatAsCurrency: string;
    private currencySymbol: string;

    constructor(value: number) {
        this.value = value;
        this.fiatAsCurrency = this.value.toCurrency(0);
        this.currencySymbol = this.fiatAsCurrency.replace(/[\d\,]/g, '').trim();
    }

    public get Value(): number {
        return this.value;
    }
    public get FiatAsCurrency(): string {
        return this.fiatAsCurrency.replace(/\s/g, '');
    }
    public get CurrencySymbol(): string {
        return this.currencySymbol;
    }
    public get Title(): string {
        return i18n.t("wallet.addMoney");
    }
    public get IsReady() {
        return this.value >= MINIMUM_WALLET_REFUND_AMOUNT && this.value <= MAXIMUM_WALLET_REFUND_AMOUNT;
    }
    public get SelectionOptions(): { label: string, value: number }[] {
        return WALLET_REFUND_OPTIONS.map(option => ({ label: option.asCurrency(), value: option }))
    }

    public getValueFromInputFiat(fiat: string): number {
        const replacePattern = new RegExp(`[${Application.getInstance().currencySymbol},]`, 'g');
        const value = fiat.replace(replacePattern, "");
        return Number(value.replace(/\s/g, ''));
    }

    private appendQuerySign(url: string): string {
        if (url.includes('?'))
            return `${url}&`
        return `${url}?`
    }

    public getBitPayRedirectUrl(url: string): string {
        const queryAppendedUrl = this.appendQuerySign(url);
        return `${queryAppendedUrl}${BITPAY_URL_INTENT.SEARCH_PARAM}=${BITPAY_URL_INTENT.BITPAY_SUCCESS}`;
    }

    public getBitPayCloseUrl(url: string): string {
        const queryAppendedUrl = this.appendQuerySign(url);
        return `${queryAppendedUrl}${BITPAY_URL_INTENT.SEARCH_PARAM}=${BITPAY_URL_INTENT.BITPAY_ERROR}`;
    }

    public getRefundAmountInRange(inputAmount: number): number {
        return MAXIMUM_WALLET_REFUND_AMOUNT < inputAmount ? MAXIMUM_WALLET_REFUND_AMOUNT : inputAmount;
    }
}
