import useSlidingPages from 'hooks/use-sliding-pages/useSlidingPages';
import React, { useEffect } from 'react';
import Slide from '@mui/material/Slide';
import "./sliding-page-wrapper.scss";
import { KeyboardArrowDown, KeyboardArrowLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { HistoryActions } from 'config/_enums';

const SlidingPages: React.FC = () => {
    const { isOpen, hideSlidingPage, Component, verticalSlide } = useSlidingPages();
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen((_location, action) => {
            if (action === HistoryActions.REPLACE) return;
            hideSlidingPage()
        });
        return unlisten;
    }, [history])

    useEffect(() => {
        if (isOpen) {
            window.scrollTo({ top: 0 });
            document.getElementById("mainbody")?.classList.add("disable-scroll");
        }
        else {
            document.getElementById("mainbody")?.classList.remove("disable-scroll");
        }
        return () => document.getElementById("mainbody")?.classList.remove("disable-scroll");
    }, [isOpen])

    return (
        <Slide in={isOpen} direction={verticalSlide? 'up': 'left'} mountOnEnter unmountOnExit>
            <div className="sliding-page-container">
                <div className="arrow-back" onClick={hideSlidingPage}>
                    {verticalSlide ? <KeyboardArrowDown/>:<KeyboardArrowLeft />}
                </div>
                {Component}
            </div>
        </Slide>)
};

export default SlidingPages;