import React from 'react';
import Stories from 'react-insta-stories';
import { useDispatch } from 'react-redux';
import { managementModal } from 'service/modal/action';
import './tutorial.scss';
import Utils from 'utils/Utils';
import GreenButtonWithGradient from 'components/small/buttons/green-button/GreenButtonWithGradient';
import { LOCAL_STORAGE_KNOWN_KEYS } from 'config/_const';
import i18next from 'i18next';
import stepOneFr from '../../../../src/assets/images/tuto-slider/fr/tuto-1.jpg';
import stepTwoFr from '../../../../src/assets/images/tuto-slider/fr/tuto-2.jpg';
import stepThreeFr from '../../../../src/assets/images/tuto-slider/fr/tuto-3.jpg';
import stepOneEn from '../../../../src/assets/images/tuto-slider/en/tuto-1.jpg';
import stepTwoEn from '../../../../src/assets/images/tuto-slider/en/tuto-2.jpg';
import stepThreeEn from '../../../../src/assets/images/tuto-slider/en/tuto-3.jpg';

let sliderStepOne: string;
let sliderStepTwo: string;
let sliderStepThree: string;

const languageOfUser: string = Utils.getCurrentLanguage();
switch (languageOfUser) {
    case 'en':
        sliderStepOne = stepOneEn;
        sliderStepTwo = stepTwoEn;
        sliderStepThree = stepThreeEn;
        break;
    case 'fr':
    default:
        sliderStepOne = stepOneFr;
        sliderStepTwo = stepTwoFr;
        sliderStepThree = stepThreeFr;
        break;
}

const componentImg = (srcImage: string, displayBtn: boolean = false) => {
    const dispatch = useDispatch();
    return (
        <>
            <img src={srcImage} className="img-step-3" alt="stepThree" />
            <div className="btn-tutorial">
                {displayBtn &&
                    <GreenButtonWithGradient
                        receiveClickEvent={false}
                        handleClick={() => {
                            localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.TUTORIAL, 'true');
                            dispatch(managementModal(false));
                        }}
                        disabled={false}
                        textButton={i18next.t('modal.tutorial.confirm')}
                        type="button"
                    />
                }
            </div>
        </>
    );
};

const stories = [
    {
        content: () => {
            return componentImg(sliderStepOne);
        },
    },
    {
        content: () => {
            return componentImg(sliderStepTwo);
        },
    },
    {
        content: () => {
            return componentImg(sliderStepThree, true);
        },
    },
];

const Tutorial = () => {
    const dispatch = useDispatch();
    return (
        <div className="tutorial">
            <Stories
                stories={stories}
                defaultInterval={7000}
                loop={false}
                onAllStoriesEnd={() => {
                    localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.TUTORIAL, 'true');
                    dispatch(managementModal(false));
                }}
                width={'100%'}
                height={Utils.isMobile() ? '100vh' : '84vh'}
            />
        </div>
    );
};
export default Tutorial;
