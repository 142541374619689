import { makeStyles, Theme } from '@material-ui/core';
import IUseStackedEffectProps from './IUseStackedImageEffectProps';

const useStackedImageEffect = makeStyles<Theme, IUseStackedEffectProps>(() => {
    return {
        stacked: {
            backgroundImage: ({ imgPath }) => `linear-gradient(#ffffffa6, #2020208a),  url("${imgPath}")`,
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            borderRadius: '16px',
            boxShadow: ' 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24)',
            '&:before, &:after': {
                backgroundImage: ({ imgPath }) => `linear-gradient(#ffffffa6, #2020208a),  url("${imgPath}")`,
                height: '100%',
                width: '100%',
                borderRadius: '16px',
                backgroundSize: 'cover',
                boxShadow: '0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24)',
                position: 'absolute',
                content: '""',
            },
            '&:before': {
                left: '-8px',
                top: '8px',
                zIndex: -1,
            },
            '&:after': {
                left: '-16px',
                top: '16px',
                zIndex: -2,
            },
        },
        classic: {
            backgroundImage: ({ imgPath }) => `linear-gradient(#ffffffa6, #2020208a),  url("${imgPath}")`,
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            borderRadius: '16px',
        },
    };
});

export default useStackedImageEffect;