import Credits from 'components/icons/Credits'
import Gifts from 'components/icons/Gifts'
import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import { SellRewardDebtInfoProps } from './SellRewardDebtInfoProps'
import "./SellRewardDebtInfo.scss"

const SellRewardDebtInfo = ({ t: translate, sellViewModel }: SellRewardDebtInfoProps) => {
    return (
        <div className='asset-reward-debt-info'>
            <p className="info-header">
                <Trans i18nKey="reward.debt.sell.header" values={{ amount: sellViewModel.UserInputAmount.toCurrency(), asset: sellViewModel.AssetName }} components={{ span: <span /> }} />
            </p>

            <p className="amount-detail">
                <Credits />
                <span>
                    {sellViewModel.AmountToBeReceivedInSell.amountInCredit}
                </span>
                {translate("reward.debt.sell.credit")}
            </p>
            <p className="amount-detail">
                <Gifts />
                <span>
                    {sellViewModel.AmountToBeReceivedInSell.amountInVirtualCredit}
                </span>
                {translate("reward.debt.sell.gift")}
            </p>
        </div>
    )
}

export default withTranslation()(SellRewardDebtInfo)