import i18next from 'i18next';
import React from 'react';
import Countdown from 'react-countdown';
import './Timer.scss';
import TimerProps from './TimerProps';
import Clock from 'components/icons/Clock';

const Timer: React.FC<TimerProps> = (props) => {
    const renderer = React.useCallback((timer: { days: number, hours: number, minutes: number, seconds: number, completed: boolean }) => {
        return (
            <ul className="timer-container" style={props.containerStyle}>
                {props.canShowIcon
                    ? <li className="chart" style={props.chartStyle}>
                        <Clock />
                    </li>
                    : null}
                <li className="chart" style={props.chartStyle}>
                    <div className="time">
                        <span style={props.numberStyle} className="time__number">{timer.days}</span>
                        <span style={props.textStyle} className="time__text">{i18next.t('day.inSingleLetter')}</span>
                    </div>
                </li>
                <li className="chart" style={props.chartStyle}>
                    <div className="time">
                        <span style={props.numberStyle} className="time__number">{timer.hours}</span>
                        <span style={props.textStyle} className="time__text">{i18next.t('hour.inSingleLetter')}</span>
                    </div>
                </li>
                <li className="chart" style={props.chartStyle}>
                    <div className="time">
                        <span style={props.numberStyle} className="time__number">{timer.minutes}</span>
                        <span style={props.textStyle} className="time__text">{i18next.t('minute.inSingleLetter')}</span>
                    </div>
                </li>
                <li className="chart" style={props.chartStyle}>
                    <div className="time">
                        <span style={props.numberStyle} className="time__number">{timer.seconds}</span>
                        <span style={props.textStyle} className="time__text">{i18next.t('second.inSingleLetter')}</span>
                    </div>
                </li>
            </ul>
        );
    }, [props]);

    return (
        <div className={`timer ${props.className}`} style={props.rootStyle}>
            {props.title ? <h2 className="title-countdown" style={props.titleStyle}>{props.title}</h2> : null}
            <Countdown
                key={props.key}
                onComplete={props.onTimeEnd}
                precision={3}
                date={new Date(props.date)}
                renderer={renderer}
                onTick={props.onTick}
            />
        </div>
    );
};

export default Timer;
