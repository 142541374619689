import { TREND_SCORE_STATUS_PART, ZERO } from "config/_const";
import Color from "view-model/Shared/Color";

export default class AssetScoreEvolutionViewModel {
    private score: number;

    constructor(score: number) {
        this.score = score;
    }

    public get TrendScoreStatus(): string {
        for (let i = ZERO; i < TREND_SCORE_STATUS_PART.length; i++) {
            if (this.score <= TREND_SCORE_STATUS_PART[i].value)
                return TREND_SCORE_STATUS_PART[i].label;
        }
        return TREND_SCORE_STATUS_PART[ZERO].label;
    }

    public get ColorByTrendScore(): string {
        return Color.getColorByInputRatio(this.score);
    }

    public get BackgroundAccordingScore(): string {
        return Color.getColorByInputRatio(this.score, 0.12);
    }

    public get Score(): number {
        return this.score;
    }

    public get RoundedScore(): string {
        return this.score.toFixed(ZERO);
    }
}