import i18n from '18n'
import ChartWaterfall from 'components/icons/assets/asset-details/ChartWaterfall'
import CalendarDays from 'components/icons/assets/asset-trendScore/CalenderDays'
import HeartHalfStroke from 'components/icons/assets/asset-trendScore/HeartHalfStroke'
import InstagramDuoTone from 'components/icons/assets/asset-trendScore/Instagram'
import StarHalfStroke from 'components/icons/assets/asset-trendScore/StarHalfStroke'
import ListCard from 'components/small/list-card/ListCard'
import { IRL_SCORE_DETAIL_NAME, ZERO } from 'config/_const'
import IRLScoreDetail from 'model/IRLScoreDetail'
import React from 'react'
import Utils from 'utils/Utils'
import IScoreDetailsProps from './ScoreDetailsProps'

export const ScoreDetails: React.FC<IScoreDetailsProps> = ({ asset }) => {
    const TOTAL_SCORE_NUM: number = 50;
    const SLASH: string = "/";

    const getInstagramFollowers = (instagramScoreDetail: IRLScoreDetail | undefined): number => {
        return instagramScoreDetail?.followers ?? 0
    }

    const instagramScoreDetail: IRLScoreDetail | undefined = asset.IRLScoreDetails?.find((entry: IRLScoreDetail) => entry.name === IRL_SCORE_DETAIL_NAME.INSTAGRAM);
    const instagramFollowers: number = getInstagramFollowers(instagramScoreDetail);
    const instagramFollowersFallback: number = instagramFollowers * 0.01;
    const trendScoreInformations = [
        { id: "R1", show: true, icon: <InstagramDuoTone />, label: i18n.t('asset.followers'), value: Utils.toKorM(instagramFollowers, ZERO) },
        { id: "R2", show: true, icon: <HeartHalfStroke />, label: i18n.t('asset.followersEngaged'), value: Utils.toKorM(instagramScoreDetail?.engagedFollowers ?? instagramFollowersFallback, ZERO) },
        { id: "R3", show: true, icon: <StarHalfStroke />, label: i18n.t('asset.scoreInfuence'), value: `${instagramScoreDetail?.relativeScore.toFixed() ?? 0}${SLASH}${TOTAL_SCORE_NUM}` },
        { id: "R4", show: true, icon: <CalendarDays />, label: i18n.t('asset.followersEngagedLast30day'), value: Utils.toKorM(instagramScoreDetail?.engagedFollowersOverDays ?? instagramScoreDetail?.engagedFollowers ?? instagramFollowersFallback, ZERO) },
        { id: "R5", show: true, icon: <ChartWaterfall />, label: i18n.t('assetProfile.history.scoreEvolution'), value: `${instagramScoreDetail?.progressiveScore.toFixed(2) ?? 0}${SLASH}${TOTAL_SCORE_NUM}` },
    ];

    return (
        <div className='score-details'>
            <ListCard informationsList={trendScoreInformations} />
        </div>
    )
}


export default React.memo(ScoreDetails)