import { ONBOARDING_PREFIX_URL } from "./_const";

const ASSETS: string = 'assets';
const TEAM: string = 'team';
const TEAM_CONFIG: string = 'teamConfiguration';
const CREDITS: string = 'credit';
const PAYMENT: string = 'payment';
const USERS: string = 'users';
const AUTH: string = 'auth';
const SYSTEM: string = 'system';
const ORDERS: string = 'orders';
const TOURNAMENT: string = 'tournament';
const TOURNAMENT_SUBSCRIPTION: string = 'tournamentSubscription';
const TOURNAMENT_RESULT: string = 'tournamentResult';
const SCORE_HISTORY: string = 'scoreHistory';
const REWARDS: string = 'reward';
const TOURNAMENT_WIN: string = 'tournamentWin';
const WITHDRAWS: string = 'withdraw-requests';
const TRANSACTIONS: string = 'transactions';
const BUY: string = 'buy';
const CLOUDFRONT: string = 'cloud-front';
const SPORTS: string = 'sports';
const COUNTRIES: string = 'countries';
const TEAMS: string = 'teams';
const PLAYERS: string = 'players';

export const TEAMS_PARAMS = {
    COUNTRY: 'country',
};
export const PLAYERS_PARAMS = {
    TEAM_ID: "team.id",
    LEAGUE_SEASON: "league.season",
};

export const ORDER_ENDPOINTS = {
    GET_ORDER_BY_ID_BASE_URL: `/${ORDERS}/`,
    GET_ORDERBOOK_BY_ASSET: `/${ORDERS}/orderbook/`,
    BUY_SELLING_ORDER: `/${ORDERS}/buySellingOrder`,
    BUY_FROM_BEST_SELLING_ORDERS: `/${ORDERS}/buySharesFromBestSellingOrders`,
    BUY_SECONDARY_MARKET_V3: `${BUY}/secondaryMarket/asset`,
    MARKET_SERVICE_BUY: `secondaryMarket/buy`,
    MARKET_SERVICE_CREATE_LIMIT_ORDER: `order/create`,
    MARKET_SERVICE_GET_ASSET_INFO: `secondaryMarket/infos`
};

export const SYSTEM_ENDPOINTS = {
    GET_ENVORONMENT_BASE_URL: `/${SYSTEM}/environment`,
};

export const AUTH_ENDPOINTS = {
    REFERRAL_TERMS_USER_BASE_URL: `/${AUTH}/acceptReferralTerms`,
    RESET_PASSWORD_BASE_URL: `/${AUTH}/reset-password/`,
    SOCIAL_NETWORKS: {// DO NOT REMOVE BASE /api
        GOOGLE: `/api/${AUTH}/google`,
        APPLE: `/api/${AUTH}/apple`,
        FACEBOOK: `/api/${AUTH}/facebook`,
    },
    LOAD_ME: `/${AUTH}/me`,
    ACCEPT_COOKIE_CONSENT: `/${AUTH}/acceptCookieConsent`,
    DECLINE_COOKIE_CONSENT: `/${AUTH}/declineCookieConsent`,
    CLAIM_REWARD_AT_REGISTER: `/${AUTH}/claimRewardAtRegister`,
    UPDATE_ONBOARDING_STEP: `/${AUTH}/${ONBOARDING_PREFIX_URL}/step`,
    POLLING_KYC_STATUS: `/${AUTH}/pollingKycStatus`,
};

export const USER_ENDPOINTS = {
    GET_ALL_ASSET_TRANSACTIONS_BASE_URL: `/${USERS}/assetTransactions/all/`,
    DISABLE_USER_BASE_URL: `/${USERS}/disableUser`,
    REACTIVATE_USER_BASE_URL: `/${USERS}/reactivateUser`,
    GET_USER_CREDITS: `/${USERS}/credits`,
    GET_USER_WALLET: `/${USERS}/wallet`,
    UPDATE_AB_TESTING: `/${USERS}/updateAbTesting`,
    SEND_CARD_TO_USER: `/${USERS}/sendCardToUser`
};

export const WHITELIST_ENDPOINTS = {
    CONFIRM: '/whitelist/confirm'
};

export const SCORE_HISTORY_ENDPOINTS = {
    GET_EVOLUTION: `/${SCORE_HISTORY}/evolution`,
};
export const TOURNAMENT_ENDPOINTS = {
    NEXT_TOURNAMENT: `/${TOURNAMENT}/nextTournament`,
    POST_TOURNAMENT: TOURNAMENT,
    GET_CURRENT_TOURNAMENT: `${TOURNAMENT}/in-progress`,
    PREVIOUS_TOURNAMENT: `/${TOURNAMENT}/previousTournament`,
};

export const USER_TOURNAMENT_ENDPOINTS = {
    SUBSCRIBE: `/${TOURNAMENT}/subscribe/userTournament`,
    GET: `/${TOURNAMENT}/userTournament`,
    CREATE: `/${TOURNAMENT}/create/userTournament`
};

export const TOURNAMENT_SUBSCRIPTION_ENDPOINTS = {
    GET_SUBSCRIPTIONS: `/${TOURNAMENT_SUBSCRIPTION}/subscriptions`,
    GET_USER_SUBSCRIPTIONS: `/${TOURNAMENT_SUBSCRIPTION}/subscriptions/user`,
    SUBSCRIBE: `/${TOURNAMENT_SUBSCRIPTION}/subscribe`,
    GET_USER_SUBSCRIPTION_PER_TOURNAMENT: `/${TOURNAMENT_SUBSCRIPTION}/user-subscription`,
};

export const TOURNAMENT_RESULT_ENDPOINTS = {
    GET_RESULTS: `/${TOURNAMENT_RESULT}`,
};

export const TEAM_ENDPOINTS = {
    CREATE: `/${TEAM}/create`,
    DISABLE: `/${TEAM}/disable`,
    UPDATE: `/${TEAM}/update`,
    GET_TEAM_BY_USER: `/${TEAM}/getTeamByUser`,
    ALL_ENABLED: `/${TEAM}/allEnabled`,
};

export const TEAM_CONFIGURATION_ENDPOINTS = {
    CREATE: `/${TEAM_CONFIG}/create`,
    DELETE: `/${TEAM_CONFIG}/delete`,
    UPADATE: `/${TEAM_CONFIG}/update`,
    GET: `/${TEAM_CONFIG}`,
};

export const ASSET_ENDPOINTS = {
    PRIMARY_MARKET_DIRECT_BUY: `/${ASSETS}/directBuy`,
    SECONDARY_MARKET_PREDICT_PRICE_BASE_URL: `/${ASSETS}/predict/price/`,
    PRIMARY_MARKET_CREDIT_PAYMENT_BASE_URL: `/${BUY}/primaryMarket/asset`,
    GET_ASSET_TOP_INVESTORS_BASE_URL: `/${ASSETS}/topInvestors`,
    FETCH_ASSET_MARKET_CONFIGURATION_BASE_URL: `/${ASSETS}/marketConfiguration`,
    MOST_SALES_ASSETS_BASE_URL: `/${ASSETS}/getAssetIdsMostSalesSecondaryMarket`,
    PREDICT_PRICE_TEAM_GENERATION: `/${ASSETS}/predict/buy/prices`,
    PRIMARY_MARKET_PREDICT_PRICE: `/${ASSETS}/predict/price`,
    GET_TOP_ASSETS: `/${ASSETS}/fetchTopAssets/`,
    GET_TOP_ASSETS_CURRENT_VALUES: `/${ASSETS}/fetchTopAssetsCurrentValues`,
    GET_PRICE_HISTORY_BY_ASSET_SLUG: `/${ASSETS}/priceHistory`,
    GET_TOP_ASSETS_SCORES: `/${ASSETS}/fetchTopAssetsScores`,
    MANAGE_ASSET: `/${ASSETS}/manage`,
    GET_TRANSLATED_DESCRIPTION: `/${ASSETS}/description`,
};

export const CREDITS_ENDPOINTS = {
    SECONDARY_MARKET_BUY_SHARES_WITH_STRIPE_BASE_URL: `/${CREDITS}/one-off-payment`,
    SECONDARY_MARKET_DIRECT_BUY: `/${CREDITS}/directBuy`,
    SECONDARY_MARKET_PAYMENT_STATUS_BASE_URL: `/${CREDITS}/payment-status/`,
    SECONDARY_MARKET_PAYPAL_PAYMENT_BASE_URL: `/${CREDITS}/paypal`,
    CREDIT_ACCOUNT_WITH_BITPAY_BASE_URL: `/${CREDITS}/bitpay`,
    BOOST_SCORE_BUY_WITH_CREDIT_BASE_URL: `/${CREDITS}/boostBuy`,
    BUY_TOURNAMENT_TICKET: `/${CREDITS}/buy/tournamentTicket`,
};

export const PAYMENT_ENDPOINTS = {
    PRIMARY_MARKET_PAYPAL_PAYMENT_BASE_URL: `/${PAYMENT}/paypal`,
    PRIMARY_MARKET_PAYPAL_PAYMENT_SUCCESS_BASE_URL: `/${PAYMENT}/paypal/success`,
    PRIMARY_MARKET_CRYPTO_PAYMENT_BASE_URL: `/${PAYMENT}/crypto-payment`,
    PRIMARY_MARKET_PAYMENT_STATUS_BASE_URL: `/${PAYMENT}/payment-asset-status/`,
    PRIMARY_MARKET_STRIPE_PAYMENT_BASE_URL: `/${PAYMENT}/one-off-payment`,
};

export const REWARDS_ENDPOINTS = {
    REWARD_LAST_TOURNAMENT_BY_USER_ID: `/${REWARDS}/getClaimableRewardAndResultFromPreviousTournamentByUser/`,
    USER_PERSONAL_TOURNAMENT_REWARD: `/${REWARDS}/getClaimableRewardForPersonalTournament`,
    HANDLE_CLAIMABLE_REWARD: `/${REWARDS}/handleReward`,
    REWARDS_BY_TOURNAMENT_ID: `/${REWARDS}/getRewardsByTournamentId`,
};

export const TOURNAMENT_WIN_ENDPOINTS = {
    ALL_WINS: `/${TOURNAMENT_WIN}/allWins`,
};

export const WITHDRAW_REQUESTS_ENDPOINTS = {
    FETCH: `/${WITHDRAWS}`,
    ALL: `/${WITHDRAWS}/all`,
    CREATE: `/${WITHDRAWS}/create`,
    FEES: `/${WITHDRAWS}/fees`,
};

export const TRANSACTIONS_ENDPOINT = {
    REFERRAL: `/${TRANSACTIONS}/referral`,
    ALL_TRANSACTIONS: `/users/transactions`
};

export const CLOUD_FRONT_CACHE_ENDPOINT = {
    CLEAR_CACHE: `/${CLOUDFRONT}/invalidate-cache`
};

export const SPORTS_ENDPOINT = {
    GET_COUNTRIES: `/${SPORTS}/${COUNTRIES}`,
    GET_TEAMS: `/${SPORTS}/${TEAMS}`,
    GET_PLAYERS: `/${SPORTS}/${PLAYERS}`,
};

export const USER_WITHDRAWAL_REQUESTS_ENDPOINTS = {
    GET_OPEN: `/${WITHDRAWS}/user/open`,
    CANCEL: `/${WITHDRAWS}/user/cancel`
};
