import IPixelHandler from "service/pixel/IPixelHandler";
const SnapchatReactPixel = require('react-snapchat-pixel');
const trackSignUpEventSnap = false;
interface ISignupData {
    user_email: string;
};

export default class PixelHandlerSignup implements IPixelHandler {
    private static customEventName: string = 'SIGN_UP';
    private _data: ISignupData;
    constructor() {
        this._data = {
            user_email: ''
        }
    }

    public set data(data: ISignupData) {
        this._data = data;
    }

    handle(): void {
        if (!trackSignUpEventSnap)
            return;
        SnapchatReactPixel.default.track(PixelHandlerSignup.customEventName, this._data);
    }
}