import i18n from '18n';
import { marketServiceUrl } from 'config/marketServiceConfig';
import IOrder from 'model/IOrder';
import ICreateLimitOrder from 'model/Payment/ICreateLimitOrder';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { HTTP_STATUS_OK, HTTP_UNAUTHORIZED, ONE } from '../../config/_const';
import { OrderSide } from '../../config/_enums';
import AxiosConfig from '../../config/axiosConfig';
import { ORDER_ENDPOINTS } from '../../config/endpoints';
import { IActionGetOrdersArguments } from '../../model/IActionGetOrdersArguments';
import { IHttpResponse, IHttpStrongTypedResponse } from '../../model/IHttpResponse';
import ICreateOrderParams from '../../model/Payment/ICreateOrderParams';
import { getUserCredits } from '../auth/actions';
import { showNotification } from '../notification/action';
import { getUserWallet } from '../wallet/actions';
import * as OrderActionType from './actionsType';
import { getOrderFilter } from './getters';

export const actionGetOrders = ({
    pageIndex,
    own,
    sort,
    search,
    populate,
    type,
    side,
    status,
    assetId,
    filter,
    concat = true,
    count,
}: IActionGetOrdersArguments): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({
        type: OrderActionType.ORDER_LOADING,
    });

    let queryRequest: string = '';

    if (own) {
        queryRequest += `own=${JSON.stringify(own)}`;
    }

    if (sort) {
        queryRequest += `sort=${sort}`;
    }
    // https://hello.trendex.vip/api/auth/apple/callback
    if (search) {
        queryRequest += `&search=${search}`;
    }

    if (populate) {
        queryRequest += `&with=${populate}`;
    }

    if (type) {
        queryRequest += `&type=${type}`;
    }

    if (side) {
        queryRequest += `&side=${side}`;
    }

    if (status) {
        queryRequest += `&status=${status}`;
    }

    if (assetId) {
        queryRequest += `&assetId=${assetId}`;
    }

    if (filter) {
        queryRequest += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (count) {
        queryRequest += `&count=${count}`;
    }

    try {
        const response: IHttpResponse = await AxiosConfig.get(`/orders/all/${pageIndex}?${queryRequest}`);
        if (response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({ type: OrderActionType.ORDER_ERROR });
        }

        return dispatch({
            type: OrderActionType.ORDER_SUCCESS,
            payload: {
                concat,
                orders: response.data.data,
            },
        });

    } catch (exception) {
        const exceptionAsError: Error = exception as Error;
        const errorMessage: string = exceptionAsError.stack || exceptionAsError.message;
        return dispatch({
            type: OrderActionType.ORDER_ERROR,
            payload: { error: errorMessage },
        });
    }
};

export const createOrder = (params: ICreateOrderParams, showError: boolean = true): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<IOrder> = await AxiosConfig.post('/orders/create', { assetId: params.assetId, type: params.type, side: params.side, price: params.price, amount: params.amount, minimumPrice: params.minimumPrice });
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            params.onErrorCallBack?.({});
            if (showError)
                showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: OrderActionType.ORDER_ERROR,
                payload: {
                    error: response.data.message,
                },
            });
        }

        params.onSuccessCallBack?.();
        if (params.showSuccessMessage)
            showNotification(dispatch, response.data.message, response.data.status);

        dispatch(getUserOrders());

        return dispatch({
            type: OrderActionType.ORDER_SUCCESS,
            payload: {
                orders: [{
                    ...response.data.data,
                    newOrder: true
                }],
                message: response.data.message,
            },
        });

    } catch (error) {
        params.onErrorCallBack?.({});
        return dispatch({
            type: OrderActionType.ORDER_ERROR,
        });
    }
};

export const createSellOrder = (params: ICreateOrderParams, showError: boolean = true): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<IOrder> = await AxiosConfig.post(`${marketServiceUrl}/sell`, { assetId: params.assetId, price: params.price, amount: params.amount, userId: params.userId, type: params.type });
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            params.onErrorCallBack?.({});
            if (showError)
                showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: OrderActionType.ORDER_ERROR,
                payload: {
                    error: response.data.message,
                },
            });
        }

        params.onSuccessCallBack?.();
        if (params.showSuccessMessage)
            showNotification(dispatch, response.data.message, response.data.status);

        dispatch(getUserOrders());

        return dispatch({
            type: OrderActionType.ORDER_SUCCESS,
            payload: {
                orders: [{
                    ...response.data.data,
                    newOrder: true
                }],
                message: response.data.message,
            },
        });

    } catch (error: any) {
        const errorMessage: string = error?.response?.data?.message || 'Common.an-error-occurred';
        showNotification(dispatch, errorMessage, HTTP_UNAUTHORIZED);
        params.onErrorCallBack?.({ error: errorMessage });
        return dispatch({
            type: OrderActionType.ORDER_ERROR,
        });
    }
};

// TODO - remove unused params (_ prefix)
export const cancelOrder = (orderId: string, _assetId: string, userId: string, _side: OrderSide, _isRecap?: boolean): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {

    try {
        dispatch({
            type: OrderActionType.ORDER_LOADING,
        });
        if (!orderId || orderId.length === 0) {
            return dispatch({
                type: OrderActionType.ORDER_ERROR,
            });
        }
        const cancelOrderResponse = await AxiosConfig.post(`${marketServiceUrl}/order/cancel`, { userId, orderId });
        if (cancelOrderResponse.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, cancelOrderResponse.data.message, cancelOrderResponse.data.status);
            return dispatch({
                type: OrderActionType.ORDER_ERROR,
            });
        }

        dispatch(
            getUserWallet(userId, true),
        );
        dispatch(getUserCredits());
        dispatch(getUserOrders());
    } catch (error) {
        return dispatch({
            type: OrderActionType.ORDER_ERROR,
        });
    }
};

export const getUserOrders = (): ThunkAction<void, null, unknown, Action<string>> => async dispatch => dispatch(actionGetOrders({
    pageIndex: 1,
    count: 500,
    own: true,
    populate: 'asset',
    filter: getOrderFilter(),
    concat: false,
}));

export const startPollingUserOrders = (interval: number): ThunkAction<void, null, unknown, Action<string>> => async dispatch => dispatch({
    type: OrderActionType.START_POLLING_ORDERS,
    payload: {
        interval,
        fetch: () => dispatch(getUserOrders()),
    },
});

export const stopPollingOrders = () => (dispatch: Dispatch): Action => dispatch({
    type: OrderActionType.STOP_POLLING_ORDERS,
});

export const createLimitOrder = (inputs: ICreateLimitOrder, callBackAfterTxn: () => void, onErrorCallBack?: () => void): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        if (inputs.amount.isSmallerThan(ONE)) {
            onErrorCallBack?.();
            return dispatch({
                type: OrderActionType.ORDER_ERROR,
                payload: i18n.t('sm.negative.amount.detected', { amount: inputs.amount }),
            });
        }
        dispatch({ type: OrderActionType.ORDER_LOADING });
        const response: IHttpStrongTypedResponse<IOrder> = await AxiosConfig.post(`${marketServiceUrl}/${ORDER_ENDPOINTS.MARKET_SERVICE_CREATE_LIMIT_ORDER}`, inputs);
        callBackAfterTxn();
        if (response.data.status !== HTTP_STATUS_OK) {
            onErrorCallBack?.();
            return dispatch({
                type: OrderActionType.ORDER_ERROR,
                payload: i18n.t(response.data.message),
            });
        }

        return dispatch({
            type: OrderActionType.ORDER_SUCCESS,
            payload: { orders: [{ ...response.data.data, new: true }] },
        });
    } catch (error: any) {
        onErrorCallBack?.();
        const errorMessage: string = error?.response?.data?.message || 'Common.an-error-occurred';
        return dispatch({
            type: OrderActionType.ORDER_ERROR,
            payload: errorMessage,
        });
    }
}

