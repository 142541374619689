/**
 * Convert multi-level Array in csv string
 * @param {Array} rows
 */
export const makeCSV = (rows: any[][]) => {
    const csv = rows
        .map((row: any[]) =>
            row
                .map((col: string | [] | number) =>
                    typeof col !== "string" ? col : `"${col.replace(/"/g, '""')}"`
                )
                .join(",")
        )
        .join("\n");
    return csv;
};

/**
 * Download File obtained as response
 * @param {String} filedata
 * @param {String} contentType
 * @param {String} fileName
 */
export const blobDownload = (
    fileName: string,
    filedata: any,
    contentType: string = "text/csv"
) => {
    const DOWNLOAD = "download";
    const url = window.URL.createObjectURL(
        new Blob([filedata], { type: contentType })
    );
    const link: any = document.createElement("a");

    link.href = url;
    link.setAttribute(DOWNLOAD, fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};
