import RegisterGiftModel, { AssetRewardRegister } from 'model/RegisterGiftModel/RegisterGiftModel';

export default class RegisterGiftViewModel {
    private assets: AssetRewardRegister[];
    private nbTokensPerAsset: number;

    constructor(params: RegisterGiftModel) {
        this.assets = params.assets;
        this.nbTokensPerAsset = params.nbTokensPerAsset;
    }

    public get Assets(): AssetRewardRegister[] {
        return this.assets;
    }

    public get NbTokensPerAsset(): number {
        return this.nbTokensPerAsset;
    }
}