import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '18n';
import { getUserOrders } from 'service/order/actions';
import { isAuthenticatedSelector } from 'service/auth/selectors';
import { TRANSACTION_VIEW_SIZE } from 'config/_const';
import OrderCard from './OrderCard';
import Divider from 'components/small/divider/Divider';
import "./wallet-orders.scss";
import { selectOpenAndQueuedOrders } from 'service/order/selectors';
import WalletOrderViewModel from 'view-model/WalletOrderViewModel/WalletOrderViewModel';

const UserOpenOrders: React.FC = () => {
    const [viewSize, setViewSize] = useState(TRANSACTION_VIEW_SIZE);
    const dispatch = useDispatch();
    const orders: WalletOrderViewModel[] = useSelector(selectOpenAndQueuedOrders);
    const isAuthenticated: boolean = useSelector(isAuthenticatedSelector);
    const hasOrders: boolean = orders.length > 0;
    const getOrders = () => dispatch(getUserOrders());

    useEffect(() => {
        if (isAuthenticated) {
            getOrders();
        }
    }, [isAuthenticated]);

    return (hasOrders ?
        <div className='wallet-orders-container'>
            <div className='section-title'>{i18n.t("wallet.order.section.title")}</div>
            {orders.slice(0, viewSize).map(order => <div className='wallet-order-card' key={order.id}>
                <OrderCard orderViewModel={order} />
            </div>)}
            {viewSize < orders.length && <span className="see-more" onClick={() => setViewSize(orders.length)}>{i18n.t("wallet.order.seeMore")}</span>}
            <Divider background='#FFFFFF59' size="calc(100% - 20px)" />
        </div>
        : <></>
    );
};

export default UserOpenOrders;
