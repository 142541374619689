import React from 'react';
import './style.scss';
import ITournamentWidgetProps from '../ITournamentWidgetProps';
import useTournamentWidgetContent from 'hooks/use-tournament-widget-content/useTournamentWidgetContent';

const TournamentWidgetCountdown = ({ tournament }: ITournamentWidgetProps, hideClock: boolean = false) => {
    const { renderCountdown } = useTournamentWidgetContent({ tournament, hideClock });

    return (
        <div className="countdown-content">
            {renderCountdown(hideClock)}
        </div>
    );
};

export default TournamentWidgetCountdown;
