import PriceHistoryGraph from "components/medium/price-history-graph/PriceHistoryGraph";
import IUsePriceHistory from "hooks/use-price-history/IUsePriceHistory";
import usePriceHistory from "hooks/use-price-history/usePriceHistory";
import React, { useEffect } from "react";
import IAssetPriceEvolutionProps from "./IAssetPriceEvolutionProps";

const AssetPriceEvolution: React.FC<IAssetPriceEvolutionProps> = ({ asset }) => {
    const { priceHistory, fetchPriceHistory }: IUsePriceHistory = usePriceHistory(asset.AssetSlug);

    useEffect(() => {
        fetchPriceHistory();
    }, [asset.InitialSaleDone, fetchPriceHistory]);


    if (Boolean(!priceHistory || !priceHistory.length))
        return <></>

    return <PriceHistoryGraph asset={asset} priceHistory={priceHistory} />;
}

export default React.memo(AssetPriceEvolution);