import i18n from '18n';
import { Icon } from '@material-ui/core';
import Application from 'Application';
import clsx from 'clsx';
import CategoriesIconsMap from 'components/icons/categories/CategoriesIconsMap';
import { TOURNAMENT_CATEGORY } from 'config/_const';
import { getTournamentBanner } from 'data/TournamentBanner.data';
import dayjs from 'dayjs';
import i18next from 'i18next';
import React from 'react';
import Countdown, { CountdownTimeDelta } from 'react-countdown';
import { Trans } from 'react-i18next';
import BannerOverlay from './BannerOverlay/BannerOverlay';
import './Header.scss';
import ITournamentHeaderProps from './ITournamentHeaderProps';

const TournamentHeader: React.FC<ITournamentHeaderProps> = ({ tournament }: ITournamentHeaderProps) => {
    const { Category, PrizePool } = tournament;
    const bannerImage = getTournamentBanner(Category as TOURNAMENT_CATEGORY);
    const isTournamentOn: boolean = tournament.isOpen();
    const countdownDate = isTournamentOn ? tournament.EndDate : tournament.SubscriptionEndDate;

    const tournamentCustomRenderer = (args: CountdownTimeDelta): JSX.Element => {
        if (args.completed) {
            window.location.href = '/tournament';
            const now = dayjs();
            return <p className="no-margin small-countdown">{now < dayjs(tournament.EndDate).subtract(30, 'seconds') ? i18n.t('tournament-about-to-start') : i18n.t('tournament-about-to-end')}</p>;
        }
        const { hours, minutes, seconds, days } = args;
        if (days === 0 && hours === 0 && minutes < 1)
            return <p className="no-margin"><Trans
                i18nKey={"tournament-result-come-no-left"}
                components={{ span: <span className="hide" /> }}
                values={{ seconds }}
            /></p>;
        if (days === 0)
            return <p className="no-margin">
                <Trans
                    i18nKey={"tournament.on.remaining-time-no-days-no-left"}
                    components={{ span: <span className="hide" /> }}
                    values={{ hours, minutes, seconds }}
                />
            </p>;
        return <p className="no-margin">
            <Trans
                i18nKey={"tournament.on.remaining-time-no-left"}
                components={{ span: <span className="hide" /> }}
                values={{ days, hours, minutes, seconds }}
            />
        </p>;
    };

    return (
        <div className={clsx("tournament-header", { "tournament-on-header": isTournamentOn })}>
            <div className="trendex-tournament-logo" style={{ backgroundImage: `url(${bannerImage})` }}>
                {isTournamentOn && <BannerOverlay />}
                {Application.getInstance().showGamblingFeature &&
                    <div className='overlay'>
                        <div className='tournament-prize'>
                            <p className='title'>{i18next.t('tournament.cashprize')}</p>
                            <p className="prize-pool">{PrizePool.toCurrency()}</p>
                        </div>
                        <div className='tournament-type flexbox'>
                            <div className='center-bloc'>
                                <Icon className='category-icon'>
                                    {CategoriesIconsMap[(Category ?? TOURNAMENT_CATEGORY.DEFAULT).toLowerCase().capitalizeFirstLetter()]}
                                </Icon>
                                <span className='title'>
                                    <Trans i18nKey={"tournament.title"} values={{ category: i18n.t(`tournament.category.${Category.toLowerCase()}`) }} />
                                </span>
                            </div>
                        </div>
                        <div className='countdown-container flexbox column'>
                            <span className='end-in'>
                                {
                                    isTournamentOn ?
                                        <Trans i18nKey={"tournament.endin"} />
                                        :
                                        <Trans i18nKey={"tournament.startingsoon"} />
                                }
                            </span>
                            <span className='countdown'>
                                <Countdown
                                    precision={0}
                                    date={countdownDate}
                                    renderer={tournamentCustomRenderer} />
                            </span>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default TournamentHeader;
