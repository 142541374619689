import i18n from "18n";
import Shares from 'components/icons/Shares';
import TickMark from 'components/icons/tick-cross-mark/TickMark';
import { COLOR } from 'config/_const';
import IUseTeamConfiguration from 'hooks/team/use-team-configuration/IUseTeamConfiguration';
import useTeamConfiguration from 'hooks/team/use-team-configuration/useTeamConfiguration';
import * as React from 'react';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';
import CircleIcon from '@mui/icons-material/Circle';
import CrossMark from 'components/icons/tick-cross-mark/CrossMark';
import { IListCardInformation } from 'components/small/list-card/ListCardProps';
import { IWithAccessLeagueProps } from "./IWithAccessLeagueProps";
import LeagueAccessProps from "components/small/league-header/league-access/LeagueAccessProps";

const withAccessLeague = (ChildComponent: React.FC<IWithAccessLeagueProps>) => {
    const MemoComponent = React.memo(ChildComponent);

    const WrapperComponent: React.FC<LeagueAccessProps> = (props) => {
        const { tournamentLeague, canShowTeamData, isTeamCompleted } = props;
        const accessLeagueTokens: LeagueViewModel[] = tournamentLeague.getAccessConditions();
        const { teamConfigViewModel }: IUseTeamConfiguration = useTeamConfiguration({ leagueType: tournamentLeague.Name });

        const listOfAccessLeague: IListCardInformation[] = React.useMemo(() => {
            const formattedAccessLeagues: IListCardInformation[] = accessLeagueTokens.map((league: LeagueViewModel) => {
                return {
                    id: `${league.Index}`,
                    show: true,
                    icon: <img src={league.CardIconLink} alt={league.Name} />,
                    label: i18n.t(canShowTeamData ? 'orderbook.shares.team.own' : 'orderbook.shares', { amount: league.LeagueTokens, name: league.Name }),
                    value: canShowTeamData ? <TickMark /> : <></>,
                }
            }
            );
            const formattedTeamTotal: IListCardInformation[] = [{
                id: "R1",
                show: true,
                icon: <Shares color={COLOR.PRIMARY_VIOLET} />,
                label: i18n.t('team.total.card.requirment', { totalLeagueToken: tournamentLeague.TotalTokens }),
                value: <TickMark />,
            }];
            const formattedTeamPoint: IListCardInformation[] = [{
                id: "Rlast",
                show: true,
                icon: <CircleIcon className='dot-circle' />,
                label: i18n.t('team.minimum.recruitment', { minimumSharePerSlot: teamConfigViewModel.Configuration.minimumSharesByAsset }),
                value: isTeamCompleted ? <TickMark /> : <CrossMark />,
            }];

            return canShowTeamData
                ? [...formattedTeamTotal, ...formattedAccessLeagues, ...formattedTeamPoint]
                : [...formattedAccessLeagues]
            }, [accessLeagueTokens, tournamentLeague.TotalTokens, teamConfigViewModel.Configuration.minimumSharesByAsset, isTeamCompleted, canShowTeamData]);


        return <MemoComponent {...props} listOfAccessLeague={listOfAccessLeague} />
    }

    return React.memo(WrapperComponent);
};

export default (withAccessLeague);
