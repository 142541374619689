import WrapperSelect from 'components/small/wrapper-select/WrapperSelect';
import { WRAPPER_SELECT_TYPE } from 'config/_const';
import { ISelectFieldProps } from 'interfaces/ISelectFieldProps';
import React from 'react';

const SelectGeneralField: React.FC<ISelectFieldProps> = (fieldProps) => {

    return (
        <WrapperSelect
            valueChange={fieldProps.input.onChange}
            propsReduxForm={fieldProps}
            customValue=""
            disabled={fieldProps.disabled}
            data={fieldProps.options}
            type={WRAPPER_SELECT_TYPE.NATIVE} />
    );
};

export default React.memo(SelectGeneralField);
