import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { TOGGLE_ASSET_TRANSACTION_SLIDEUP } from './actionType';
import { AssetTransactionType } from 'config/_enums';
import { ORDER_TYPE } from 'config/_const';

export const openAssetTransactionSlides = (assetId: string, transactionType: AssetTransactionType, canShowHalfScreen?: boolean, fromPack?: boolean, orderType?: ORDER_TYPE): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    return dispatch({
        type: TOGGLE_ASSET_TRANSACTION_SLIDEUP,
        payload: { isOpen: true, assetId, transactionType, canShowHalfScreen, fromPack, orderType }
    });
};

export const closeAssetTransactionSlides = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    return dispatch({
        type: TOGGLE_ASSET_TRANSACTION_SLIDEUP,
        payload: { isOpen: false }
    });
};
