import { useDispatch } from 'react-redux';
import { actionConnectUser } from 'service/auth/actions';
import { managementModal } from 'service/modal/action';
import useIsMobile from 'hooks/useIsMobile';

const useSignupModal = (postSigninParams: URLSearchParams, postSigninCallBack = () => { }) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();

    const onPostLogin = (form: any) => {
        const onSuccessCallback = () => postSigninCallBack();
        dispatch(actionConnectUser(form, onSuccessCallback));
    }


    const getWindowLocation = (): string => {
        return `${window.location.origin}${window.location.pathname}`
    }

    const getRedirectToUrl = () => {
        return `${getWindowLocation()}?${postSigninParams.toString}`;
    }

    const displaySignup = () => {
        dispatch(managementModal(true, {
            type: 'SIGN_UP',
            display: true,
            disableBackDrop: false,
            showBackArrow: isMobile,
            fullScreen: isMobile,
            showCancelButton: !isMobile,
            propsCustom: {
                onPostLogin: onPostLogin,
                socialLoginRedirectUrl: getRedirectToUrl(),
            },
        }));
    }

    return { displaySignup }
}


export default useSignupModal;
