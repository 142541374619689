import { MouseEvent } from 'react';
import { debounce } from 'lodash';
import { DEFAULT_BUTTON_DEBOUNCE_IN_MS } from '../../../../config/_const';

const debounceInputChange = debounce((callback) => {
    callback();
}, DEFAULT_BUTTON_DEBOUNCE_IN_MS);

const HandleClick = (event: MouseEvent<HTMLButtonElement>, debouce: boolean, preventDefault: boolean, callback?: () => void) => {
    if (preventDefault)
        event.preventDefault();
    if (!callback)
        return;
    if (debouce === true)
        debounceInputChange(callback);
    else
        callback();
};

export default HandleClick;