import { Store, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
import { initializeAxiosInterceptor } from '../config/axiosConfig';
import { withdrawsAcceptRefuseReducer } from './WithdrawsAcceptRefuse/reducer';
import { affiliateReducer } from './affiliate/reducer';
import { appConfigReducer } from './app-config/reducer';
import { assetsCategoriesReducer } from './asset-category/reducer';
import { assetsCurrentInformationReducer } from './asset-current-information/reducer';
import { assetRequestReducer } from './asset-request/reducer';
import { assetScoreStatisticsReducer } from './asset-score-statistics/reducer';
import { assetTransactionSlidesReducer } from './asset-transaction-slideup/reducer';
import { assetTransactionsLogReducer } from './asset-transactions-log/reducer';
import { getAllAssetTransactionsReducer } from './asset-transactions/reducer';
import { assetsConfigReducer } from './assets-config/reducer';
import { watchFetchTopAssets } from './assets/middleware';
import { assetsReducer, futureAssetsReducer } from './assets/reducer';
import { authLoadMeReducer } from './auth-loadMe/reducer';
import { checkForTournamentRewardModal, watchUserGiftRegisterClaims, watchUserSignupSource, watcherUserRegister } from './auth/middleware';
import { authReducer } from './auth/reducer';
import { bottombarReducer } from './bottombar/reducer';
import { buySellingOrderReducer } from './buy-selling-order/reducer';
import { buyFromBestSellingOrderReducer } from './direct-buy-from-best-selling-orders/reducer';
import { feesReducer } from './fees/reducer';
import { modalReducer } from './modal/reducer';
import { notificationReducer } from './notification/reducer';
import { orderReducer } from './order/reducer';
import { paymentReducer } from './payment/reducer';
import { scoreHistoryReducer } from './score-history/reducer';
import { secondaryMarketReducer } from './secondary-market/reducer';
import { sideMenuReducer } from './side-menu/reducer';
import slidingPagesReducer from './sliding-pages/reducer';
import { assetModalViewReducer } from './sliding-pane/reducer';
import { sportsCountriesReducer } from './sports-countries/reducers';
import { sportsPlayersReducer } from './sports-players/reducers';
import { sportsTeamsReducer } from './sports-teams/reducer';
import { systemReducer } from './system/reducer';
import { teamConfigurationReducer } from './team-configuration/reducer';
import { teamsReducer } from './teams/reducer';
import { topBarReducer } from './top-bar/reducer';
import { tournamentTaskStatusReducer } from './tournament-task-state/reducer';
import { tournamentReducer } from './tournament/reducer';
import { tournamentResultReducer } from './tournamentResult/reducer';
import { tournamentRewardReducer } from './tournamentReward/reducer';
import { tournamentSubscriptionReducer } from './tournamentSubscription/reducer';
import { tournamentWinsReducer } from './tournamentWins/reducer';
import { transactionReducer } from './transactions/reducer';
import { userPerksReducer } from './user-perks/reducer';
import { userTournamentReducer } from './user-tournament/reducer';
import { userWithdrawalRequestsReducer } from './user-withdrawal-requests/reducer';
import { userReducer } from './users/reducer';
import { walletReducer } from './wallet/reducer';
import { withdrawFeeReducer } from './withdraw-fee/reducer';
import { withdrawsReducer } from './withdraws/reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    notifications: notificationReducer,
    modal: modalReducer,
    payment: paymentReducer,
    sideMenu: sideMenuReducer,
    users: userReducer,
    assets: assetsReducer,
    futureAssets: futureAssetsReducer,
    wallet: walletReducer,
    orders: orderReducer,
    assetsCategories: assetsCategoriesReducer,
    assetsConfig: assetsConfigReducer,
    affiliate: affiliateReducer,
    assetRequest: assetRequestReducer,
    withdraws: withdrawsReducer,
    withdrawsAcceptRefuse: withdrawsAcceptRefuseReducer,
    transactions: transactionReducer,
    assetTransactionsLog: assetTransactionsLogReducer,
    userPerks: userPerksReducer,
    appConfig: appConfigReducer,
    secondaryMarket: secondaryMarketReducer,
    userAssetTransactions: getAllAssetTransactionsReducer,
    fees: feesReducer,
    system: systemReducer,
    teams: teamsReducer,
    teamConfiguration: teamConfigurationReducer,
    tournament: tournamentReducer,
    tournamentSubscription: tournamentSubscriptionReducer,
    tournamentResult: tournamentResultReducer,
    tournamentReward: tournamentRewardReducer,
    tournamentWins: tournamentWinsReducer,
    topBar: topBarReducer,
    assetModalView: assetModalViewReducer,
    form: formReducer,
    scoreHistory: scoreHistoryReducer,
    buySellingOrder: buySellingOrderReducer,
    assetScoreStatistics: assetScoreStatisticsReducer,
    assetTransactionSlides: assetTransactionSlidesReducer,
    bottomBar: bottombarReducer,
    buyFromBestSellingOrder: buyFromBestSellingOrderReducer,
    withdrawFee: withdrawFeeReducer,
    slidingPages: slidingPagesReducer,
    userTournament: userTournamentReducer,
    authLoadMe: authLoadMeReducer,
    assetsCurrentInformation: assetsCurrentInformationReducer,
    sportsTeams: sportsTeamsReducer,
    sportsPlayers: sportsPlayersReducer,
    sportsCountries: sportsCountriesReducer,
    tournamentTaskStatus: tournamentTaskStatusReducer,
    userWithdrawalRequests: userWithdrawalRequestsReducer
});

const configureStore = () => {
    const middleware = [thunk, watchUserSignupSource, checkForTournamentRewardModal, watcherUserRegister, watchUserGiftRegisterClaims, watchFetchTopAssets];
    return createStore(
        enableBatching(rootReducer),
        compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production'
                ? window.__REDUX_DEVTOOLS_EXTENSION__()
                : (f: any) => f,
        ),
    );
};

const store: Store = configureStore();
initializeAxiosInterceptor(store);
export default store;
