import clsx from 'clsx';
import { ZERO } from 'config/_const';
import IUseAssetsScoreEvolution from 'hooks/evolution/use-assets-score-evolution/IUseAssetsScoreEvolution';
import useAssetsScoreEvolution from 'hooks/evolution/use-assets-score-evolution/useAssetsScoreEvolution';
import { IAssetWithAmount } from 'model/AssetModel/AssetInterface';
import React, { useMemo } from 'react';
import WalletAssetListViewModel from 'view-model/WalletAssetViewModel/WalletAssetListViewModel';
import IWalletListViewProps from './IWalletListViewProps';
import WalletListViewCell from './wallet-list-view-cell/WalletListViewCell';

const WalletListView: React.FC<IWalletListViewProps> = ({ assets, assetCategories, onboardingMode, showOnboardingTooltip, renderPortfolioActions }) => {
    const { assetsWithEvolution }: IUseAssetsScoreEvolution = useAssetsScoreEvolution({
        assetsIds: assets.map(asset => asset._id),
        onlyOncePerDay: true,
        startDate: new Date().subtractDates({ days: 2 }).toISOString(),
    });
    const walletAssets: WalletAssetListViewModel[] = useMemo(() => assets.map((asset: IAssetWithAmount) => new WalletAssetListViewModel(asset, assetsWithEvolution.find(assetEvolution => assetEvolution.AssetId === asset._id)?.SnapshotsIRLScoreEvolution)), [assets, assetsWithEvolution]);
    return (
        <div className={clsx('wallet-portfolio-list', { 'onboarding-mode': onboardingMode })}>
            {walletAssets.map((asset: WalletAssetListViewModel, index: number) => <WalletListViewCell key={asset.Id} asset={asset} assetCategories={assetCategories} renderPortfolioActions={renderPortfolioActions} showOnboardingTooltip={showOnboardingTooltip && index === ZERO} />)}
        </div>
    );
}

export default WalletListView;
