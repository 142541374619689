import ShareProfile from 'components/small/share-profile/ShareProfile';
import React from 'react';

export const getShareModalObject = (url: string, text: string) => {

  return {
    display: true,
    disableBackDrop: false,
    showBackArrow: false,
    fullScreen: false,
    showCancelButton: true,
    type: 'MESSAGE_MODAL',
    propsCustom: {
      isShare: true,
      message: <ShareProfile url={url} text={text} />,
    },
  };
};
