import * as React from 'react';
import './ErrorTxnMsg.scss';
import WalletCard from 'components/icons/wallet/card/WalletCard';

const ErrorTxnMsg: React.FC<{ msg: string }> = ({msg}) =>
    <div className='input-tokens-caution input-tokens-error'>
        <p className="no-margin">
            <span><WalletCard /></span>
            {msg}
        </p>
    </div>;

export default React.memo(ErrorTxnMsg);
