import moment from 'moment';
import { REFERRAL_DATA_LAST_DAYS } from '../../config/_const';
import TransactionInterface from '../../model/User/TransactionInterface';
import UserInterface from '../../model/User/UserInterface';

export const getReferral = (referralTransactions: TransactionInterface[]) => {
    const toReturn = {
        earnedReferral: 0,
        earnedReferralLast30days: 0
    };
    if (referralTransactions.length === 0)
        return toReturn;
    return referralTransactions.reduce((obj: { earnedReferral: number, earnedReferralLast30days: number }, referralTransaction: TransactionInterface) => {
        return {
            earnedReferral: obj.earnedReferral + referralTransaction.fiat,
            earnedReferralLast30days: obj.earnedReferral + (moment(referralTransaction.createdAt).isAfter(moment().subtract(REFERRAL_DATA_LAST_DAYS, 'days')) ? referralTransaction.fiat : 0)
        }
    }, toReturn);
};

export const getReferralDataByGodson = (referralTransactions: TransactionInterface[], godson: UserInterface) => {
    const referralTransactionsByGodson = referralTransactions.filter(transaction => transaction.externalReference === godson._id);
    return {
        percentage: referralTransactionsByGodson.reduce((acc: number, entry: TransactionInterface) => acc + entry.fiat, 0),
    };
};
