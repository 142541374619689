import Application from 'Application';
import { ORDER_TYPE, TRANSACTION_PAGES_TRANSITION_TIME, ZERO } from 'config/_const';
import { AssetTransactionType, DefaultNumberOfTokens } from 'config/_enums';
import withAssetTransaction from 'hoc/with-asset-transaction/withAssetTransaction';
import useAssetOfTransactionSlideup from 'hooks/use-asset-of-transaction-slideup/useAssetOfTransactionSlideup';
import useAssetTransactionSlides from 'hooks/use-asset-transaction-slideup/useAssetTransactionSlides';
import useBottomBar from 'hooks/use-bottom-bar/useBottomBar';
import IUseCredits from 'hooks/use-credits/IUseCredits';
import useCredits from 'hooks/use-credits/useCredits';
import IUseWallet from 'hooks/wallet/use-wallet/IUseWallet';
import useWallet from 'hooks/wallet/use-wallet/useWallet';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import SecondaryMarketPredictPriceWrapperState from 'model/Resale/SecondaryMarketPredictPriceWrapperState';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorAccountViewModel } from 'service/auth/selectors';
import { secondaryMarketPredictPriceSelector } from 'service/secondary-market/selector';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import BuyAssetViewModel from 'view-model/BuyAssetViewModel/BuyAssetViewModel';
import SellAssetViewModel from 'view-model/SellAssetViewModel/SellAssetViewModel';
import IAssetTransactionProps from './IAssetTransactionProps';
import AssetTransactionDetail from './asset-transaction-detail/AssetTransactionDetail';
import IBuyAssetViewModelBuilder from 'view-model/BuyAssetViewModel/IBuyAssetViewModelBuilder';
import ISellAssetViewModelBuilder from 'view-model/SellAssetViewModel/ISellAssetViewModelBuilder';

const AssetTransaction: React.FC<IAssetTransactionProps> = ({ asset, transactionType, assetCurrentInformation, fromPack, orderType }) => {
    const { hideBottomBar, showBottomBar } = useBottomBar();
    const { closeAssetTransaction } = useAssetTransactionSlides();
    const { fetchPredictedPrice } = useAssetOfTransactionSlideup();
    const predictPriceData: SecondaryMarketPredictPriceWrapperState = useSelector(secondaryMarketPredictPriceSelector);
    const { walletViewModel }: IUseWallet = useWallet({ considerTeamShares: false });
    const [isExiting, setIsExiting] = useState<boolean>(false);
    const [numberOfToken, setNumberOfToken] = useState<number>(transactionType === AssetTransactionType.BUY ? 0 : DefaultNumberOfTokens.SELL);
    const [userInputAmount, setUserInputAmount] = useState<string>(ZERO.toFixed(2));
    const userAmount = useMemo(() => Number(userInputAmount).toDefaultCurrencyValue(), [userInputAmount]);
    const { totalCredits, credits, virtualCredits }: IUseCredits = useCredits();
    const [isSecondaryMarketRestrictionEnabled, setIsSecondaryMarketRestrictionEnabled] = React.useState<boolean>(new AssetViewModel(asset).isSecondaryMarketRestrictionEnabled);
    const walletAsset: AssetWalletInterface | undefined = useMemo(() => walletViewModel.getWalletAssetById(asset._id), [walletViewModel, asset]);
    const accountViewModel: AccountViewModel = useSelector(selectorAccountViewModel);
    const currency: string = accountViewModel.Currency ?? Application.getInstance().currency;
    const assetTransactionViewModel = useMemo(() => {
        if (transactionType === AssetTransactionType.BUY) {
            const buyAssetViewModelBuilder: IBuyAssetViewModelBuilder = {
                asset,
                numberOfShares: numberOfToken,
                availableCredits: totalCredits,
                credits,
                virtualCredits,
                assetCurrentInformation,
                walletAsset,
                userId: accountViewModel.Id,
                currency,
                userAmount,
                fromPack,
            };
            return new BuyAssetViewModel(buyAssetViewModelBuilder);
        }
        const sellAssetViewModelBuilder: ISellAssetViewModelBuilder = {
            asset,
            assetWallet: walletAsset,
            numberOfToken,
            userAmount,
            predictPriceData,
            assetCurrentInformation,
            userId: accountViewModel.Id,
            currency,
            orderType: orderType || ORDER_TYPE.MARKET
        };
        return new SellAssetViewModel(sellAssetViewModelBuilder);
    }, [transactionType, asset, walletAsset, numberOfToken, userAmount, predictPriceData, assetCurrentInformation, accountViewModel.Id, currency, orderType, totalCredits, credits, virtualCredits, fromPack]);

    useEffect(() => {
        if (assetTransactionViewModel.Asset.isSecondaryMarketRestrictionEnabled === isSecondaryMarketRestrictionEnabled)
            return;
        setIsSecondaryMarketRestrictionEnabled(assetTransactionViewModel.Asset.isSecondaryMarketRestrictionEnabled);
    }, [assetTransactionViewModel.Asset.isSecondaryMarketRestrictionEnabled, isSecondaryMarketRestrictionEnabled]);


    useEffect(() => {
        if (!assetTransactionViewModel.IsImmediateSettlement)
            return;
        setUserInputAmount(assetTransactionViewModel.Amount.toCurrencyValue().toString());
    }, [assetTransactionViewModel.MarketValue, assetTransactionViewModel.IsImmediateSettlement, assetTransactionViewModel.UserInputAmount, assetTransactionViewModel.Amount]);

    useEffect(() => {
        setUserInputAmount(assetTransactionViewModel.MarketValueInUserCurrencyValue);
    }, [assetTransactionViewModel.MarketValueInUserCurrencyValue]);

    const closeBuyAsset = () => {
        setIsExiting(true);
        setTimeout(() => {
            setIsExiting(false);
            closeAssetTransaction();
        }, TRANSACTION_PAGES_TRANSITION_TIME);
    };

    useEffect(() => {
        fetchPredictedPrice(numberOfToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfToken]);


    useEffect(() => {
        window.parent.postMessage('purchaseModuleOpen', '*');
        if (transactionType === AssetTransactionType.BUY)
            setNumberOfToken(assetTransactionViewModel.DefaultAllowedTransaction);

        hideBottomBar();
        return () => showBottomBar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionType]);

    useEffect(() => {
        if (numberOfToken > assetTransactionViewModel.MaxAllowedTransaction)
            setNumberOfToken(assetTransactionViewModel.MaxAllowedTransaction);
        if (!assetTransactionViewModel.IsImmediateSettlement)
            setUserInputAmount(assetTransactionViewModel.MarketValueInUserCurrencyValue);
    }, [assetTransactionViewModel.IsImmediateSettlement, assetTransactionViewModel.MarketValueInUserCurrencyValue, assetTransactionViewModel.MaxAllowedTransaction, numberOfToken]);

    return (
        <React.Fragment>
            <AssetTransactionDetail
                assetTransactionViewModel={assetTransactionViewModel}
                closeBuyAsset={closeBuyAsset}
                isExiting={isExiting}
                setNumberOfToken={setNumberOfToken}
                setIsSecondaryMarketRestrictionEnabled={setIsSecondaryMarketRestrictionEnabled}
                isSecondaryMarketRestrictionEnabled={isSecondaryMarketRestrictionEnabled}
                totalCredits={totalCredits}
                renderDetailSubComponent={() => <></>}
                setUserInputAmount={setUserInputAmount}
                userInputAmount={userInputAmount}
                hideBackArrow={accountViewModel.IsOnboardingRunning}
            />
        </React.Fragment>
    );
};

export default withAssetTransaction(AssetTransaction);