import * as React from 'react';

const HeartHalfStroke = ({ height = "20px", width = "20px", ...props }: React.SVGProps<SVGSVGElement>) => {

  return (
    <svg height={height} width={width} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.56 15.016">
      <g id="heart-duotone" transform="translate(-3632.22 39.508)">
        <g id="heart-half-duotone" transform="translate(3632.221 -40.954)">
          <path id="Tracé_3605" data-name="Tracé 3605" d="M8.78,16.462a1.392,1.392,0,0,1-.95-.374L1.632,10.3A5.135,5.135,0,0,1,0,6.547v-.2A4.9,4.9,0,0,1,8.368,2.881l.412.411Z" fill="#8187d0" />
          <path id="Tracé_3606" data-name="Tracé 3606" d="M8.78,16.462V3.292l.381-.411a4.989,4.989,0,0,1,4.3-1.369,4.9,4.9,0,0,1,4.095,4.836v.2A5.13,5.13,0,0,1,15.927,10.3l-6.2,5.786a1.392,1.392,0,0,1-.95.374Z" fill="#8187d0" opacity="0.4" />
        </g>
        <path id="heart-duotone-2" data-name="heart-duotone" d="M0,47.254v-.2a4.9,4.9,0,0,1,8.368-3.467L8.78,44l.381-.411a4.989,4.989,0,0,1,4.3-1.369,4.9,4.9,0,0,1,4.095,4.836v.2a5.13,5.13,0,0,1-1.632,3.755l-6.2,5.786a1.394,1.394,0,0,1-1.9,0l-6.2-5.786A5.135,5.135,0,0,1,0,47.254Z" transform="translate(3632.22 -81.66)" fill="#8187d0" opacity="0.4" />
      </g>
    </svg>
  );
}

export default HeartHalfStroke;
