import React from 'react';
import Application from 'Application';
import Article from 'components/small/article/Article';
import IBlogArticlesProps from './IBlogArticlesProps';
import './BlogArticles.scss';
import { Trans } from 'react-i18next';

const BlogArticles: React.FC<IBlogArticlesProps> = (props) => {
	return (<div className="blog-articles-component">
        <h2>
            <Trans i18nKey="blog.articles" />
        </h2>
        {
		Application.getInstance().getRegisterBlogArticles().map((article) => {
			return (
					<Article title={article.title} thumbnail={article.image} link={article.link} language={props.language} date={article.date} source={article.source} sourceLogo={article.sourceLogo} key={article.title}/>
			);
			})
		}
		</div>)
}

export default BlogArticles;
