import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import AxiosConfig from '../../config/axiosConfig';
import { REWARDS_ENDPOINTS } from '../../config/endpoints';
import { HTTP_STATUS_OK } from '../../config/_const';
import { IHttpStrongTypedResponse } from '../../model/IHttpResponse';
import * as RewardByTournamentIdActionType from './actionsType';
import ITournamentReward from 'model/Tournament/ITournamentReward';

export const getRewardByTournamentId = (tournamentId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: RewardByTournamentIdActionType.REWARD_LAST_TOURNAMENT_LOADING });
        const getRewardsResponse: IHttpStrongTypedResponse<ITournamentReward[]> = await AxiosConfig.get(`${REWARDS_ENDPOINTS.REWARDS_BY_TOURNAMENT_ID}/${tournamentId}`);
        const responseHttpCode: number = getRewardsResponse.data.status;
        if (responseHttpCode !== HTTP_STATUS_OK)
            throw new Error(getRewardsResponse.data.message);

        return dispatch({
            type: RewardByTournamentIdActionType.REWARD_LAST_TOURNAMENT_SUCCESS,
            payload: {
                tournamentId,
                rewards: getRewardsResponse.data.data,
            },
        });
    } catch (error) {
        let message = 'Server.users.validateAccount.generic-error';
        if (error instanceof TypeError)
            message = error.message;
        return dispatch({
            type: RewardByTournamentIdActionType.REWARD_LAST_TOURNAMENT_ERROR,
            payload: {
                message,
            },
        });
    }
};
