import React from "react";
import './ScoreBoosterValuesAdded.scss';
import IScoreBoosterValuesAddedProps from "./IScoreBoosterValuesAddedProps";
import { Trans } from "react-i18next";

const ScoreBoosterValueAdded: React.FC<IScoreBoosterValuesAddedProps> = () => {
    return (
        <div className="boost-detail-container flex-center">
            <i className="fa fa-check"></i>
            <span className="score">
                <Trans i18nKey='booster.added' />
            </span>
        </div>)
}

export default ScoreBoosterValueAdded;