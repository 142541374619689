import { useDispatch, useSelector } from "react-redux";
import { getAllSportsTeamsByCountry } from 'service/sports-teams/actions';
import { isSportsTeamsSelectorLoading, sportsTeamsSelectorWithInfo } from 'service/sports-teams/selectors';

const useSportsTeams = () => {
    const dispatch = useDispatch();
    const sportsTeamsList = useSelector(sportsTeamsSelectorWithInfo);
    const isSportsTeamsFetchLoading = useSelector(isSportsTeamsSelectorLoading);
    const fetchSportsTeamsByCountry = (country: string) => dispatch(getAllSportsTeamsByCountry(country));

    return {
        sportsTeamsList,
        isSportsTeamsFetchLoading,
        fetchSportsTeamsByCountry,
    }
}
export default useSportsTeams;