import i18n from '18n';
import { Icon, Tooltip } from '@material-ui/core';
import Application from 'Application';
import Droplets from 'assets/images/fun-assets/droplets.webp';
import FunCardsUpLeft from 'assets/images/fun-assets/fun-card-badge.webp';
import clsx from 'clsx';
import ArrowUpFromBracket from 'components/icons/ArrowUpFromBracket';
import TrendScoreIcon from 'components/icons/TrendScoreIcon';
import TripleArrowsUp from 'components/icons/arrows/TripleArrowsUp';
import { ASSET_PROFILE_TOKEN_INFORMATION_DISPLAY_ITEMS, LAUNCH_MODE } from 'config/_const';
import { ICategoryTypeName } from 'interfaces/IAssetBgImages';
import { deburr } from 'lodash';
import { default as React, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import IAssetCurrentInformationData from 'service/asset-current-information/IAssetCurrentInformationData';
import { managementModal } from 'service/modal/action';
import Utils from 'utils/Utils';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import AvatarViewModel from 'view-model/AvatarViewModel/AvatarViewModel';
import Color from 'view-model/Shared/Color';
import CategoriesIconsMap from '../../icons/categories/CategoriesIconsMap';
import LegendBanner from '../../small/legend-banner/LegendBanner';
import TokenInformation from '../token-information/TokenInformation';
import './ProfilBanner.scss';
import ProfilBannerProps from './ProfilBannerProps';

const ProfilBanner: React.FC<ProfilBannerProps> = (props) => {
    const dispatch = useDispatch();
    const assetViewModel: AssetViewModel = props.assetVM;
    const authUserReferralId: string | null = props.auth.data.isAuthenticated ? props.auth.data.account.referral.ownReferralId : null;
    const isInitialSaleDateBeforeToday: boolean = assetViewModel.InitialSaleDate ? new Date(assetViewModel.InitialSaleDate).getTime() < new Date().getTime() : false;
    const isCurrentAssetInformationApplicable = (currentInfo: IAssetCurrentInformationData): boolean => {
        return Boolean(currentInfo);
    };

    const assetInfo = useMemo<{ availableSupply: number, price: number; }>(() => {
        if (!assetViewModel)
            return { availableSupply: 0, price: 1 };
        const assetCurrentInfo = props.assetsCurrentInformation.data[assetViewModel.AssetId] && Utils.removeUndefinedAndNullFromObject(props.assetsCurrentInformation.data[assetViewModel.AssetId]);
        const defaultAssetInfo = { currentValue: assetViewModel.CurrentValue, secondaryMarketSupply: assetViewModel.SecondaryMarketSupply, availableSupply: assetViewModel.AvailableSupply };
        const selectedAssetInfo = isCurrentAssetInformationApplicable(assetCurrentInfo) ? { ...defaultAssetInfo, ...assetCurrentInfo } : defaultAssetInfo;

        if (assetViewModel.LaunchMode === LAUNCH_MODE.LIQUIDITY_POOL)
            return { availableSupply: assetCurrentInfo?.liquidityPoolSupply - 1, price: selectedAssetInfo.currentValue };
        if (assetViewModel.ForcedSecondaryMarket)
            return { availableSupply: AssetViewModel.getSupplyByStage(assetViewModel, selectedAssetInfo.secondaryMarketSupply) ?? 0, price: selectedAssetInfo.currentValue };
        if (Application.getInstance().SkipPrimaryMarket)
            return { availableSupply: selectedAssetInfo?.secondaryMarketSupply, price: selectedAssetInfo.currentValue };
        if (!assetViewModel.InitialSaleDone)
            return { availableSupply: selectedAssetInfo?.availableSupply, price: selectedAssetInfo.currentValue };
        if (assetViewModel.InitialSaleDone)
            return { availableSupply: selectedAssetInfo.secondaryMarketSupply ?? 0, price: selectedAssetInfo.currentValue };
        return { availableSupply: 0, price: selectedAssetInfo.currentValue };
    }, [assetViewModel, props.assetsCurrentInformation.data]);

    const assetBackgroundViewModel: AvatarViewModel = React.useMemo(() => new AvatarViewModel({ assetCategories: props.assetsCategories.data, asset: { avatarPath: assetViewModel.AvatarPath ?? "", mainCategoryId: assetViewModel.MainCategoryId, cardLevel: assetViewModel.CardLevel } }), [assetViewModel.AvatarPath, assetViewModel.CardLevel, assetViewModel.MainCategoryId, props.assetsCategories.data]);
    const getAssetCategoryName = (): ICategoryTypeName => {
        const [assetCategory] = Utils.getAssetCategory(props.assetsCategories.data, assetViewModel.Asset);
        const categoryName: string = assetCategory?.name.toLowerCase();
        const categoryNameWithoutAccent: ICategoryTypeName = deburr(categoryName,) as ICategoryTypeName;
        return categoryNameWithoutAccent;
    };

    return (
        <div className={clsx("asset-profil-header", { 'gradient-fun-cards': assetViewModel?.IsFunCategory })}>
            <div className='asset-profil-header-share'><ArrowUpFromBracket className='hover' onClick={() => {
                const hasInitialSupply = assetViewModel.InitialSupply;
                const shareMessage = (hasInitialSupply && isInitialSaleDateBeforeToday)
                    ? i18n.t('Share.assetPossibleToBet', { name: assetViewModel.AssetName })
                    : i18n.t('Share.assetResaleAvailableSoon', { name: assetViewModel.AssetName });
                if (props.actionShare !== undefined) {
                    props.actionShare(
                        shareMessage,
                        shareMessage,
                        Utils.referralLinkToAsset(assetViewModel.AssetSlug, authUserReferralId),
                        shareMessage,
                    );
                }
            }} />
            </div>
            <div className="asset-profil-header-img-container" >
                <div style={{ borderColor: assetBackgroundViewModel.Color }} className={clsx('asset-profil-header-img-block', { 'asset-fun': assetViewModel?.IsFunCategory })}>
                    {assetViewModel?.VideoIconProfile && <video playsInline={true} width="100%" height="100%" autoPlay={true} loop={true}
                        poster={assetViewModel.AvatarPath}
                        unselectable='off'
                        muted={true}
                        style={{ borderRadius: '15px', objectFit: 'cover' }}>
                        <source
                            src={assetViewModel.VideoIconProfile}
                            type="video/mp4" />
                    </video>}
                    {!assetViewModel?.VideoIconProfile && <img src={assetBackgroundViewModel.BackgroundImage} className={clsx("asset-profil-banner-bg-category", { 'bg-category-fun': assetViewModel?.IsFunCategory })} alt={assetViewModel?.AvatarPath + "-bg"} loading="lazy" />}
                    {!assetViewModel?.VideoIconProfile && <img src={assetViewModel.AvatarPath} className={clsx("asset-profil-banner-bg-avatar", { 'bg-avatar-fun': assetViewModel?.IsFunCategory })} alt={assetViewModel?.AvatarPath || ""} loading="lazy" />}
                    {assetViewModel?.IsFunCategory &&
                        <>
                            <img src={FunCardsUpLeft} className='fun-cards-up-left' alt="icon fun cards" />
                            <img src={Droplets} className='droplets' alt="icon droplets cards" />
                        </>
                    }
                </div>
            </div>
            <div className={clsx('asset-profil-banner-name', { 'banner-name-fun': assetViewModel.IsFunCategory })}>{assetViewModel.AssetName}</div>
            <div className={clsx('asset-profil-banner-category-nameIcon', { 'fun-cards-margin': assetViewModel.IsFunCategory })}>
                <div className="flex container-profil-banner">
                    <Icon className={clsx('category-icon', { 'fun-cards-icon': assetViewModel?.IsFunCategory })}>
                        {CategoriesIconsMap[getAssetCategoryName().capitalizeFirstLetter()]}
                    </Icon>
                    <span className="category-name item">{getAssetCategoryName() ?? ''}</span>
                    {assetViewModel?.IsFunCategory && <span onClick={() => {
                        dispatch(managementModal(true, {
                            display: true,
                            disableBackDrop: true,
                            showBackArrow: false,
                            fullScreen: false,
                            type: 'FUN_ASSET_MODAL',
                            propsCustom: {
                                onValidation: () => dispatch(managementModal(false))
                            },
                        }));
                    }} className="learn-more-fun-assets">{i18n.t('Myportfolio.message.explanation-dropdown')}</span>}
                    {assetViewModel?.isLegend() && !assetViewModel.IsFunCategory && <LegendBanner />}
                </div>
            </div>

            {
                assetViewModel?.ShowTrendScore && <div className="asset-profil-score">
                    <div className="asset-profil-score__item">
                        <TrendScoreIcon
                            height={19}
                            width={19}
                            className="item"
                        />
                        <span className="label asset-profil-score__item">
                            {i18n.t('trend-score')}:
                        </span>
                    </div>
                    <Tooltip
                        arrow
                        enterTouchDelay={100}
                        title={<p style={{ fontSize: '13px' }} className='no-margin'>{assetViewModel?.getScoreTooltipText(i18n.t('tooltip.trend.score'))}</p>}
                    >
                        <div className='asset-profil-score__item asset-profil-banner-IRL-score' style={{ background: `${Color.getColorByInputRatio(assetViewModel?.IRLScore ?? 0)}` }}>
                            {assetViewModel?.getTrendScoreToDisplay(2)}
                            {assetViewModel?.ScoreBoosted &&
                                <span className="asset-profil-score__boosted">
                                    <TripleArrowsUp />
                                </span>
                            }
                        </div>

                    </Tooltip>

                </div>
            }

            {
                assetViewModel?.canShowSportScore(props.assetsCategories.data) &&
                <div className="asset-profil-score border-top padding-top">
                    <div className="asset-profil-score__item">
                        <Icon className='category-icon'>
                            {CategoriesIconsMap[getAssetCategoryName().capitalizeFirstLetter()]}
                        </Icon>
                        <span className="asset-profil-score__item label">
                            {i18n.t('sport-score', { sport: getAssetCategoryName().capitalizeFirstLetter() })} :
                        </span>
                    </div>
                    <Tooltip
                        arrow
                        enterTouchDelay={100}
                        title={<p style={{ fontSize: '13px' }} className='no-margin'>{assetViewModel?.getScoreTooltipText(i18n.t('tooltip.sport.score'))}</p>}
                    >
                        <div className='asset-profil-score__item asset-profil-banner-IRL-score' style={{ background: `${Color.getColorByInputRatio(assetViewModel?.SportScore ?? 0)}` }}>
                            {assetViewModel?.getSportScoreToDisplay(2)}
                            {assetViewModel?.ScoreBoosted &&
                                <span className="asset-profil-score__boosted">
                                    <TripleArrowsUp />
                                </span>
                            }
                        </div>
                    </Tooltip>

                </div>
            }
            {
                assetViewModel &&
                <TokenInformation
                    asset={assetViewModel}
                    availableSupply={assetInfo.availableSupply}
                    totalTokensInCirculation={assetViewModel.InitialSupply}
                    tokenPrice={Number(assetInfo.price)}
                    displayItems={ASSET_PROFILE_TOKEN_INFORMATION_DISPLAY_ITEMS}
                />
            }
        </div >
    );
};

const mapStateToProps = (state: ProfilBannerProps) => ({
    auth: state.auth,
    assetsCategories: state.assetsCategories,
    assetsCurrentInformation: state.assetsCurrentInformation
});

export default connect(mapStateToProps)(ProfilBanner);
