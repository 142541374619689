import AssetViewModel from 'view-model/Assets/AssetViewModel';
import { AssetTransactionType, OrderSide } from "config/_enums";
import useAllAssets from "hooks/asset/use-all-assets/useAllAssets";
import useAccount from "hooks/use-account/useAccount";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assetTransactionSlidesSelector } from "service/asset-transaction-slideup/selector";
import { getAssetsById, getPredictedPrice } from "service/assets/actions";
import { secondaryMarketGetPredictedPrice } from "service/secondary-market/actions";
import { LAUNCH_MODE } from 'config/_const';

const useAssetOfTransactionSlideup = () => {
    const dispatch = useDispatch();
    const { assetId, transactionType, fromPack, orderType } = useSelector(assetTransactionSlidesSelector);
    const { allAssets } = useAllAssets();
    const asset = useMemo(() => allAssets.find(asset => asset.AssetId === assetId)?.Asset, [allAssets, assetId]);
    const { accountViewModel } = useAccount();

    useEffect(() => {
        if (assetId && !asset) {
            dispatch(getAssetsById(assetId))
        }
    }, [assetId, asset, dispatch])

    const fetchPredictedPrice = (numberOfToken: number) => {
        if (!assetId || !asset) return;
        const assetViewModel = new AssetViewModel(asset);
        if (transactionType === AssetTransactionType.SELL) {
            dispatch(secondaryMarketGetPredictedPrice(assetId, numberOfToken, OrderSide.SELL, accountViewModel.Id));
            return;
        }

        if (assetViewModel.InitialSaleDone || assetViewModel.LaunchMode === LAUNCH_MODE.LIQUIDITY_POOL)
            return;
        dispatch(getPredictedPrice(assetId, numberOfToken, false, true));
    }


    return {
        asset,
        transactionType,
        fromPack,
        orderType,
        fetchPredictedPrice
    }
}

export default useAssetOfTransactionSlideup;