import type { RootState } from 'model/Redux';
import { createSelector, Selector } from 'reselect';
import moment from 'moment';
import { isArray } from 'lodash';
import { OrderStatus } from 'config/_enums';
import { IUserOrder } from './../../model/IOrder';
import WalletOrderViewModel from 'view-model/WalletOrderViewModel/WalletOrderViewModel';

export const ordersSelector: Selector<RootState, IUserOrder[]> = (state: RootState) => state.orders.data;

export const selectOpenOrders = createSelector(
    ordersSelector,
    (orders) => {
        if (!orders || orders.length === 0 || !isArray(orders)) return [];
        return orders
            .filter(order => order.status == OrderStatus.OPEN)
            .sort((a ,b)=>moment(b.createdAt).diff(a.createdAt))
            .map(order => new WalletOrderViewModel(order));
    }
);
export const selectOpenAndQueuedOrders = createSelector(
    ordersSelector,
    (orders) => {
        if (!orders || orders.length === 0 || !isArray(orders)) return [];
        return orders
            .filter(order => order.status === OrderStatus.OPEN || order.status === OrderStatus.QUEUED)
            .sort((a ,b)=>moment(b.createdAt).diff(a.createdAt))
            .map(order => new WalletOrderViewModel(order));
    }
);
