import { ZERO } from 'config/_const';
import IFootballStatisticsSubstitute from 'interfaces/sports/football/statistics/IFootballStatisticsSubstitute';

export default class FootballStatisticsSubstituteViewModel {
    private in: number;
    private out: number;
    private bench: number;

    constructor(statistics: Partial<IFootballStatisticsSubstitute>) {
        this.in = statistics.in ?? ZERO;
        this.out = statistics.out ?? ZERO;
        this.bench = statistics.bench ?? ZERO;
    }

    get In(): number {
        return this.in;
    }

    get Out(): number {
        return this.out;
    }

    get Bench(): number {
        return this.bench;
    }
}
