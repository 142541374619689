import * as React from 'react';
import BuySharesButton from '../buying/BuySharesButton';
import './AssetBuySellSection.scss';
import useAccount from 'hooks/use-account/useAccount';
import useWallet from 'hooks/wallet/use-wallet/useWallet';
import IUseWallet from 'hooks/wallet/use-wallet/IUseWallet';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import SellShareButton from '../asset-sell/SellShareButton';
import IAssetBuySellSection from './AssetBuySellSectionProps';
import useAssetInitialSalePeriod from 'hooks/use-assetCurrentInfos/useAssetInitialSalePeriod';
import CircularLoader from 'components/small/CircularLoader';

const AssetBuySellSection: React.FC<IAssetBuySellSection> = ({ assetViewModel, isSaleStartingInLessThan3Min }) => {
    const { accountViewModel } = useAccount();
    const { walletViewModel }: IUseWallet = useWallet({ considerTeamShares: false });
    const walletAsset: AssetWalletInterface | undefined = React.useMemo(() =>
        walletViewModel.getWalletAssetById(assetViewModel.AssetId),
        [assetViewModel.AssetId, walletViewModel]);
    const { withinFakePrimaryMarket, isAssetCurrentInformationLoading } = useAssetInitialSalePeriod({
        assetId: assetViewModel.AssetId,
        initialSaleEndDate: String(assetViewModel.InitialSaleRealEndDate)
    });
    const isAfterFakePrimaryMarket = accountViewModel.IsUserAdmin ? true : !withinFakePrimaryMarket
    const isSellBtnClickable = Boolean(walletAsset?.amount) &&
        (assetViewModel.Owner !== accountViewModel.Id || accountViewModel.CanVipSellOwnTokens);
    const canShowSellBtn = (accountViewModel.isAuthenticated && assetViewModel.InitialSaleDone) && isAfterFakePrimaryMarket;

    return <section className='asset-buySell-section'>
        <BuySharesButton assetViewModel={assetViewModel} isSaleStartingInLessThan3Min={isSaleStartingInLessThan3Min} />
        {canShowSellBtn && (
            isAssetCurrentInformationLoading
                ? <CircularLoader
                    loaderMessage=''
                    loaderLocal
                    localLoaderStyle={{ height: '15px', width: '15px' }}
                />
                : <SellShareButton assetViewModel={assetViewModel} isSellBtnClickable={isSellBtnClickable && isAfterFakePrimaryMarket} />
        )}
    </section>;
};

export default React.memo(AssetBuySellSection);
