import { LAUNCH_MODE, SECONDS_AFTER_INITIAL_SOLDOUT } from 'config/_const';
import { useSocketContext } from 'context/WebSocket/socketContext';
import IAssetPredictPrice from 'model/AssetModel/IAssetPredictPrice';
import moment from 'moment';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { cancelSecondaryMarketPredictPrice, getAssetInfoFromMarketService, updateAssetCurrentInformation } from 'service/asset-current-information/actions';
import { cancelGetPredictPriceRequest, getPredictedPrice, updateAsset, updateInitialSaleDone } from 'service/assets/actions';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import { IUseSocketContext } from '../../context/WebSocket/types';
import IUseAssetPredictPrice from './IUseAssetPredictPrice';
import IUseAssetPredictPriceProps from "./IUseAssetPredictPriceProps";

const useAssetPredictPrice = ({ asset }: IUseAssetPredictPriceProps): IUseAssetPredictPrice => {
    const dispatch = useDispatch();
    const IS_FALLBACK_POLLING_ACTIVATE: boolean = false;
    const SCHEDULED_TIMEOUT_IN_MS: number = 1500;
    const { socketContext, subscribeToAssetId }: IUseSocketContext = useSocketContext();
    const assetViewModel: AssetViewModel = useMemo(() => new AssetViewModel(asset), [asset]);
    const predictPriceTimeout: MutableRefObject<NodeJS.Timeout | undefined> = useRef<NodeJS.Timeout>();
    const maxMinutesStartPolling: number = 90;
    const canStartPredictPricePolling: boolean = useMemo(() => {
        const initialSaleDateWithOneHours: moment.Moment = moment(assetViewModel.InitialSaleDate).add(maxMinutesStartPolling, 'minutes');
        const nowIsBetweenInitialSaleDateAndOneHours: boolean = moment().isBetween(moment(moment(assetViewModel.InitialSaleDate)), initialSaleDateWithOneHours);
        return nowIsBetweenInitialSaleDateAndOneHours;
    }, [assetViewModel]);

    useEffect(() => {
        if (canStartPredictPricePolling)
            scheduledPredictPrice();
        return () => stopPredictPriceTimeout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetViewModel]);

    const stopPredictPriceTimeout = (): void => {
        if (!predictPriceTimeout.current)
            return;
        clearTimeout(predictPriceTimeout.current);
    }

    const scheduledPredictPrice = useCallback((): void => {
        if (!IS_FALLBACK_POLLING_ACTIVATE || !canStartPredictPricePolling)
            return;
        stopPredictPriceTimeout();
        predictPriceTimeout.current = setTimeout(() => {
            fetchPredictPrice(assetViewModel, scheduledPredictPrice, scheduledPredictPrice);
        }, SCHEDULED_TIMEOUT_IN_MS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const cancelPredictPriceRequest = (reason?: string): void => {
        if (!assetViewModel.InitialSaleDone) {
            dispatch(cancelGetPredictPriceRequest(reason));
            return;
        }
        dispatch(cancelSecondaryMarketPredictPrice(reason));
        return;
    }

    const predictPriceListener = useCallback((event: MessageEvent) => {
        cancelPredictPriceRequest('Predict price cancel by WS event');
        scheduledPredictPrice();
        const predictPriceData: IAssetPredictPrice = JSON.parse(event.data);
        if (!(predictPriceData && predictPriceData.assetId))
            return;

        if (Number(predictPriceData.currentValue))
            dispatch(updateAssetCurrentInformation({ ...predictPriceData }))
        else
            console.error(`Current value received as zero, ${predictPriceData}`);
        if (typeof predictPriceData.availableSupply === 'number')
            dispatch(updateAsset(predictPriceData.assetId, { availableSupply: predictPriceData.availableSupply }));
        if (predictPriceData.initialSaleSoldOutDate) {
            const privateSellEndDate: Date = new Date(predictPriceData.initialSaleSoldOutDate);
            if (moment(privateSellEndDate).isBefore(moment()))
                setTimeout(() => dispatch(updateInitialSaleDone(predictPriceData.assetId, privateSellEndDate.toISOString())), SECONDS_AFTER_INITIAL_SOLDOUT * 1000);
        }

        if (assetViewModel.InitialSaleDateHasPassed && !assetViewModel.InitialSaleDone && predictPriceData.secondaryMarketSupply)
            setTimeout(() => dispatch(updateInitialSaleDone(predictPriceData.assetId)), SECONDS_AFTER_INITIAL_SOLDOUT * 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchPredictPrice = useCallback((predictAsset: AssetViewModel, callbackOnSuccess?: () => void, callbackOnCancel?: () => void): void => {
        const forcedSM: boolean = predictAsset.IsWhiteListedPurchaseOpen || predictAsset.PackEnabled;
        if (!predictAsset.InitialSaleDone && !forcedSM && predictAsset.LaunchMode === LAUNCH_MODE.DEFAULT) {
            dispatch(getPredictedPrice(predictAsset.AssetId, 1, false, true, callbackOnSuccess, callbackOnCancel));
            return
        }
        dispatch(getAssetInfoFromMarketService(assetViewModel.AssetId, callbackOnSuccess, callbackOnCancel));
        return
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const { applicationSocket, isSocketOpen } = socketContext
        if (!applicationSocket || !isSocketOpen)
            return;
        subscribeToAssetId(assetViewModel.AssetId);
        fetchPredictPrice(assetViewModel);
        applicationSocket.addEventListener('message', predictPriceListener);
        return () => applicationSocket.removeEventListener("message", predictPriceListener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetViewModel.AssetId, socketContext.applicationSocket, socketContext.isSocketOpen]);

    return {
        scheduledPredictPrice,
    }
}

export default useAssetPredictPrice;