import i18next from 'i18next';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { TRENDEX_ROLES } from '../../../../../config/_const';
import { COUNTRIES_LIST } from '../../../../../data/countries';
import PrimaryButton from '../../../../small/buttons/primary-button/PrimaryButton';
import * as Input from '../../../../small/input/ReduxFormInput';
import { generateCheckbox } from "../../../../small/input/ReduxFormInput";
import './AdminUserInformationForm.scss';
import AdminUserInformationFormProps from './AdminUserInformationFormProps';
import IAdminUserFormInitialValues from './IAdminUserFormInitialValues';

const INITIAL_STATE_FORM: IAdminUserFormInitialValues = {
    firstName: '',
    lastName: '',
    pseudo: '',
    email: '',
    country: '',
    type: '',
    identificationDocumentNumber: '',
    stripeCustomerId: '',
    referral: {
        ownReferralId: '',
        usedReferralId: '',
        partner: false,
        subPartner: false,
        referralTermsAccepted: false,
        enabled: true,
        rewardPerReferral: {
            enabled: true,
        }
    },
    dateOfBirth: '',
    validatedEmail: false
};

const AdminUserInformationForm: React.FC<InjectedFormProps<{}, AdminUserInformationFormProps> & AdminUserInformationFormProps> = (props) => {

    const generateSelect = (fieldProps: any) => {

        let data: {
            label: string | number,
            value: string | number,
        }[] = [];

        if (fieldProps.input.name === 'country') {

            data = COUNTRIES_LIST.map((country: { code: string, name: string }) => {

                return {
                    label: country.name,
                    value: country.code,
                };
            });
        }

        if (fieldProps.input.name === 'type') {
            data = Object.entries(TRENDEX_ROLES).map((role: string[]) => {

                return {
                    label: role[0],
                    value: role[1],
                };
            });
        }

        return (
            <Form.Control {...fieldProps.input} as="select" className="cust_dropdown">
                {data.map((country: { label: string | number, value: string | number }) => {

                    return (
                        <option selected={country.value === props.initialValues.country} value={country.value}>{country.label}</option>
                    );
                })}
            </Form.Control>
        );
    };

    return (
        <div className="user-information-form">
            <form onSubmit={props.handleSubmit(props.onInformationsSubmit)} className="row">

                <div className="field-container custinput_group col-md-4">
                    <label className="form_label">{i18next.t('admin.userDetail.identificationDocumentNumber')}</label>
                    <Field
                        name={'identificationDocumentNumber'}
                        type="text"
                        component={Input.generateInputText} />
                </div>
                <div className="field-container custinput_group col-md-4">
                    <label className="form_label">{i18next.t('Aboutus.tab.about.firstname')}</label>
                    <Field
                        name={'firstName'}
                        type="text"
                        component={Input.generateInputText} />
                </div>
                <div className="field-container custinput_group col-md-4">
                    <label className="form_label">{i18next.t('Aboutus.tab.about.lastname')}</label>
                    <Field
                        name={'lastName'}
                        type="text"
                        component={Input.generateInputText} />
                </div>
                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">{i18next.t('Aboutus.tab.about.pseudo')}</label>
                    <Field
                        name={'pseudo'}
                        type="text"
                        component={Input.generateInputText} />
                </div>
                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">{i18next.t('Aboutus.tab.about.email')}</label>
                    <Field
                        name={'email'}
                        type="email"
                        component={Input.generateInputText} />
                </div>
                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">{i18next.t('Aboutus.tab.about.country')} :</label>
                    <Field
                        name={'country'}
                        type="select"
                        component={generateSelect} />

                </div>

                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">{i18next.t('admin.userDetail.stripeId')} :</label>
                    <Field
                        name={'stripeCustomerId'}
                        type="text"
                        component={Input.generateInputText} />

                </div>

                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">{i18next.t('admin.userDetail.referralId')} :</label>
                    <Field
                        name={'referral.ownReferralId'}
                        type="text"
                        component={Input.generateInputText} />

                </div>

                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">{i18next.t('admin.userDetail.usedReferralId')} :</label>
                    <Field
                        name={'referral.usedReferralId'}
                        type="text"
                        component={Input.generateInputText} />

                </div>
                <div className="field-container custinput_group col-md-3">
                    <label className="form_label">{i18next.t('admin.userDetail.partner')} :</label>
                    <Field
                        name={'referral.partner'}
                        type="checkbox"
                        component={generateCheckbox} />
                </div>

                <div className="field-container custinput_group col-md-3">
                    <label className="form_label">{i18next.t('referral.reward.per.referral.enabled')} :</label>
                    <Field
                        name={'referral.rewardPerReferral.enabled'}
                        type="checkbox"
                        component={generateCheckbox} />
                </div>
                <div className="field-container custinput_group col-md-3">
                    <label className="form_label">{i18next.t('admin.userDetail.email.activated')} :</label>
                    <Field
                        name={'validatedEmail'}
                        type="checkbox"
                        component={generateCheckbox} />
                </div>
                <div className="field-container custinput_group col-md-3">
                    <label className="form_label">{i18next.t('admin.userDetail.dateOfBirth')} :</label>
                    <Field
                        name={'dateOfBirth'}
                        type="text"
                        component={Input.generateInputText} />
                </div>
                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">{i18next.t('admin.userDetail.typeAccount')} :</label>
                    <Field
                        name={'type'}
                        type="text"
                        component={generateSelect} />

                </div>

                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">{i18next.t('Aboutus.tab.about.address')} :</label>
                    <Field
                        name={'address'}
                        type="text"
                        component={Input.generateInputText} />

                </div>

                <div className="field-container custinput_group col-md-6">
                    <label className="form_label">{i18next.t('Aboutus.tab.about.phone')} :</label>
                    <Field
                        name={'phone'}
                        type="text"
                        component={Input.generateInputText} />

                </div>

                <div className="button-submit col-md-6">
                    <PrimaryButton
                        disabled={false}
                        receiveClickEvent={false}
                        type="submit"
                        textButton={i18next.t(props.buttonText)} />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (_state: AdminUserInformationFormProps, ownProps: AdminUserInformationFormProps) => ({
    initialValues: ownProps.initialValues ? ownProps.initialValues : INITIAL_STATE_FORM,
});

const AdminUserInformationFormRedux = reduxForm<{}, AdminUserInformationFormProps>({
    form: 'AdminUserInformationForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    enableReinitialize: true,
})(AdminUserInformationForm);

export default connect(mapStateToProps)(AdminUserInformationFormRedux);
