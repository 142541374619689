
import i18next from "i18next";
import { useEffect, useRef } from 'react';
import { batch, useDispatch } from "react-redux";
import { pollingKycStatus, updateVerificationIdentity } from 'service/auth/actions';
import CrispService from 'service/crisp/CrispService';
import { managementModal } from 'service/modal/action';
import { showNotification } from 'service/notification/action';
import WhatsAppService from 'service/whatsapp/WhatsAppService';
import Utils from 'utils/Utils';
import { LOCAL_STORAGE_KNOWN_KEYS, NOTIFICATION_STATUS_BLUE, VERIFICATION_STATUS } from './../../config/_const';
import IUseAIPriseKYC from './IUseAIPriseKYC';
import IUseAiPriseKYCProps from "./IUseAiPriseKYCProps";

const useAiPriseKYC = ({ callbackFunction }: IUseAiPriseKYCProps): IUseAIPriseKYC => {
	const verifyFrameRef = useRef<HTMLElement>(null);
	const dispatch = useDispatch();
	const mode = Utils.isProdEnvironment() ? "PRODUCTION" : "SANDBOX";

	
	useEffect(() => {
		const verifyFrame = verifyFrameRef.current;
		if (!verifyFrame)
			return;
		
		const setAiPriseKYCSuccessful = (): void => {
			dispatch(updateVerificationIdentity(VERIFICATION_STATUS.PENDING));
			localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.FUNNEL_AI_PRISE_DONE, 'true');
		}
	
		const successfullHandler = async (): Promise<void> => {
			showNotification(dispatch, i18next.t('notification.message.aiPrise'), NOTIFICATION_STATUS_BLUE);
			console.log("successHandler");
			batch(() => {
				dispatch(pollingKycStatus);
				setAiPriseKYCSuccessful();
			});
			callbackFunction();
		};
	
		const continueHandler = (): void => {
			dispatch(managementModal(false));
			CrispService.show();
			WhatsAppService.show();
		};

		verifyFrame.addEventListener("aiprise:successful", successfullHandler);
		verifyFrame.addEventListener("aiprise:continue", continueHandler);

		return () => {
			verifyFrame.removeEventListener("aiprise:successful", successfullHandler);
			verifyFrame.removeEventListener("aiprise:continue", continueHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { verifyFrameRef, mode };
};

export default useAiPriseKYC;
