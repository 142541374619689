import ReduxActionInterface from '../../model/ReduxActionInterface';

import ReduxRootState from '../../model/Redux/ReduxRootSate';
import TransactionInterface from '../../model/User/TransactionInterface';
import Utils from '../../utils/Utils';
import { RESET_STATE } from '../commonActionTypes';
import * as TransactionActionsTypes from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<TransactionInterface[]> = {
    data: [],
    loading: false,
};

export const transactionReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    switch (action.type) {

        case TransactionActionsTypes.TRANSACTION_LOADING:
            return {
                ...state,
                loading: true,
            };

        case TransactionActionsTypes.TRANSACTION_ERROR:
            if (action.payload) {
                return {
                    ...state,
                    loading: false,
                    error: action.payload.message,
                };
            }

            return state;

        case TransactionActionsTypes.TRANSACTION_SUCCESS:
            if (action.payload) {

                const stateData = state.data && state.data.length ? Utils.removedDuplicateMongoFile(state.data, action.payload.data) : [];
                return {
                    ...state,
                    data: stateData.concat(action.payload.data),
                    loading: false,
                    error: null,
                };
            }
            return state;
        case RESET_STATE:
            return ROOT_VALUE_STATE;
        default: return state;
    }
};
