import i18n from '18n';
import clsx from 'clsx';
import Reset from 'components/icons/Reset';
import Sparkles from 'components/icons/Sparkles';
import AssetsSelection from 'components/medium/assets-selection/AssetsSelection';
import FilterTabs from 'components/medium/filter-tabs/FilterTabs';
import TeamsOverview from 'components/medium/team/teams-overview/TeamsOverview';
import Divider from 'components/small/divider/Divider';
import OnboardingButton from 'components/small/onboarding-button/OnboardingButton';
import RotateCircularBtn from 'components/small/rotate-circular-btn/RotateCircularBtn';
import { DEFAULT_ID_AS_STRING, TEAM_EDITOR_CONFIG, TEAM_INTERNAL_STATUS, TEAM_STATUS } from 'config/_const';
import withNextTournament from 'hoc/tournament/with-next-tournament/withNextTournament';
import withTournamentInProgress from 'hoc/tournament/with-tournament-in-progress/withTournamentInProgress';
import withAccount from 'hoc/with-account/withAccount';
import useAssetSlideView from 'hooks/asset/use-asset-slide-view/useAssetSlideView';
import IUseTeamEditor from 'hooks/team/use-team-editor/IUseTeamEditor';
import useTeamEditor from 'hooks/team/use-team-editor/useTeamEditor';
import useUserTournamentSubscription from 'hooks/tournament/use-user-tournament-subscription/useUserTournamentSubscription';
import IUseFilterTabs from 'hooks/use-filter-tabs/IUseFilterTabs';
import useFilterTabs from 'hooks/use-filter-tabs/useFilterTabs';
import useModalDisplay from 'hooks/use-modal-display/useModalDisplay';
import useIsDesktopMode from 'hooks/useIsDesktopMode';
import IUseWallet from 'hooks/wallet/use-wallet/IUseWallet';
import useWallet from 'hooks/wallet/use-wallet/useWallet';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import { ReduxRootState } from 'model/Redux';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { assetsCategoriesSelector } from 'service/asset-category/selector';
import ITeamEditorProps, { IBaseTeamEditorProps } from './ITeamEditorProps';
import './style.scss';

const TeamEditor: React.FC<ITeamEditorProps> = ({ accountViewModel, nextTournament, tournamentInProgress, hideAssetsIfAmountIsZero, updateOnboardingStep }) => {
    const dispatch = useDispatch();
    const { walletViewModel }: IUseWallet = useWallet({ considerTeamShares: true });
    const onboardingMode: boolean = Boolean(accountViewModel.IsOnboardingRunning);
    const {
        teamBuildingViewModel,
        deleteAssetFromEditingTeam,
        onPickAssetIntent,
        displayConfirmTeam,
        resetTeam,
        replaceEditingTeam,
        isTeamIncompatibleWithTournament,
        internalLaunchTeamGeneration,
        createTeam,
        updateTeam }: IUseTeamEditor = useTeamEditor({ walletViewModel: walletViewModel, accountViewModel: accountViewModel, autoGenerateTeam: onboardingMode, nextTournament: nextTournament });
    const walletWithoutSharesInTeams: AssetWalletInterface[] = walletViewModel.getAssetsViewModelsWithoutAssetsInTeam(teamBuildingViewModel.SharesInLocalTeams, hideAssetsIfAmountIsZero);
    const { currentTab, ref, handleTabsChange, transformStringToIconName, renderTab, resetTab }: IUseFilterTabs = useFilterTabs();
    const allCategoriesSelector: ReduxRootState<AssetCategoryInterface[]> = useSelector(assetsCategoriesSelector);
    const ownedAssetsCategoriesIds: string[] = walletWithoutSharesInTeams.map((assetWallet: AssetWalletInterface) => assetWallet.asset.mainCategory).getUniqueValues();
    const categoriesOfOwnedAssets = allCategoriesSelector.data.filter(category => ownedAssetsCategoriesIds.includes(category._id));
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const isDesktopMode = useIsDesktopMode();
    const isEngagedInTournament = teamBuildingViewModel.status === TEAM_STATUS.ENGAGED;
    const displayResetTeamButton = (): boolean => Boolean(teamBuildingViewModel && teamBuildingViewModel.InternalStatus === TEAM_INTERNAL_STATUS.EDIT_DONE && (teamBuildingViewModel.teamAssets.length ?? 0) >= 1 && !isEngagedInTournament);
    const userHasEnoughAssetsInWallet: boolean = (walletViewModel.getOwnedAssetsAvailableInTeam(accountViewModel).length - (teamBuildingViewModel?.teamAssets.length ?? 0)) > 0;
    const teamAssetsNotEmpty: boolean = Boolean(teamBuildingViewModel && teamBuildingViewModel.teamAssets.length > 0);
    const { isSlideViewOpen } = useAssetSlideView();
    const [showOnboardingButton, setShowOnboardingButton] = React.useState<boolean>(true);
    const { showOnboardingTournamentConfirmation } = useModalDisplay();
    const { subscribe } = useUserTournamentSubscription({ tournamentId: DEFAULT_ID_AS_STRING });
    const onboardingSaveTeam = React.useCallback(() => {
        if (teamBuildingViewModel.id === DEFAULT_ID_AS_STRING)
            createTeam(teamBuildingViewModel.asITeam());
        else
            updateTeam(teamBuildingViewModel.asITeam());
        showOnboardingTournamentConfirmation({ updateOnboardingStep: () => dispatch(updateOnboardingStep()), subscribe: () => subscribe({ team: teamBuildingViewModel.id }) });
        setShowOnboardingButton(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamBuildingViewModel]);

    const triggerCollapse = () => {
        setIsCollapsed(!isCollapsed);
        if (!isCollapsed)
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
    };

    const renderPickButtons = (): JSX.Element | null => {
        if (displayResetTeamButton()) {
            return <div className='pick_button' onClick={resetTeam}>
                <Reset />
                <span>
                    {i18n.t('reset-team')}
                </span>
            </div>;
        }

        if (userHasEnoughAssetsInWallet && teamAssetsNotEmpty && !isEngagedInTournament) {
            return <div className={clsx("pick_button")} onClick={internalLaunchTeamGeneration}>
                <Sparkles />
                <span>
                    {i18n.t('generate.team.with.team')}
                </span>
            </div>;
        }

        return null;
    };

    useEffect(() => {
        if (categoriesOfOwnedAssets.find(category => category._id === currentTab))
            return;
        resetTab();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoriesOfOwnedAssets]);

    return (
        <React.StrictMode>
            {!onboardingMode && !isDesktopMode && !isSlideViewOpen && renderPickButtons()}
            <div className={clsx("teams-management", { 'collapse-on': isCollapsed })}>
                <div className={clsx("container-team-management", { 'team-sticky': isCollapsed })}>
                    <TeamsOverview
                        onboardingMode={onboardingMode}
                        resetTeam={resetTeam}
                        launchGeneration={() => internalLaunchTeamGeneration()}
                        deleteAssetFromEditingTeam={deleteAssetFromEditingTeam}
                        teamBuildingViewModel={teamBuildingViewModel}
                        walletViewModel={walletViewModel}
                        resetEditingTeam={replaceEditingTeam}
                        disableGenerationButton={true}
                        displayConfirmTeamDialog={() => displayConfirmTeam()}
                        isTeamIncompatibleWithTournament={isTeamIncompatibleWithTournament}
                        isCollapsed={isCollapsed}
                    />
                    <div className="container-divider">
                        <Divider />
                    </div>
                </div>

                {walletWithoutSharesInTeams.length > 0 && <div className="container-assets">
                    <h4 className="black-color label-compose-team">{i18n.t('Myportfolio.myplayers')}</h4>
                    <h6 className="sublabel-compose-team">{i18n.t('team.owned.card.number', { totalOwnedCard: walletWithoutSharesInTeams.length })}</h6>
                    {walletWithoutSharesInTeams.length > TEAM_EDITOR_CONFIG.CONFIG_SHOW_CATEGORIES.MIN_NUMBER_OF_ASSETS && categoriesOfOwnedAssets.length > TEAM_EDITOR_CONFIG.CONFIG_SHOW_CATEGORIES.MIN_NUMBER_OF_CATEGORIES &&
                        <div className="assets-filter">
                            <FilterTabs
                                categories={categoriesOfOwnedAssets}
                                starIconText={'asset.category.all'}
                                currentTab={currentTab}
                                tabRef={ref}
                                handleTabsChange={handleTabsChange}
                                transformStringToIconName={transformStringToIconName}
                                renderTab={renderTab} />
                        </div>}
                    <div className="container-owned-assets">
                        <AssetsSelection
                            nextTournament={nextTournament}
                            tournamentInProgress={tournamentInProgress}
                            categories={allCategoriesSelector.data}
                            onSlotSelected={onPickAssetIntent}
                            currentTab={currentTab}
                            hideAssetsIfAmountIsZero={hideAssetsIfAmountIsZero}
                            teamBuildingViewModel={teamBuildingViewModel}
                            walletViewModel={walletViewModel} />
                    </div>
                </div>}
                {!onboardingMode && <div className="collapse-btn">
                    <RotateCircularBtn canRotate180deg={isCollapsed} onTrigger={triggerCollapse} />
                </div>}

                {onboardingMode && showOnboardingButton && <OnboardingButton handleClick={onboardingSaveTeam} type='button' disabled={!teamBuildingViewModel.IsReady} receiveClickEvent={false} textButton={i18n.t("next")} />}
            </div>
        </React.StrictMode>
    );
};

export default compose<React.FC<IBaseTeamEditorProps>>(
    withAccount,
    withNextTournament,
    withTournamentInProgress

)(TeamEditor);