import Application from 'Application';
import { ZERO } from 'config/_const';
import IUseDialog from 'hooks/use-dialog/IUseDialog';
import useDialog from 'hooks/use-dialog/useDialog';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { pollingKycStatus } from 'service/auth/actions';
import CrispService from 'service/crisp/CrispService';
import { managementModal } from 'service/modal/action';
import WhatsAppService from 'service/whatsapp/WhatsAppService';
import Utils from 'utils/Utils';
import IUseUserIdentityVerification from './IUseUserIdentityVerification';

interface IUseUserIdentityVerificationParams {
    shouldLaunchPolling?: boolean;
}

const useUserIdentityVerification = ({ shouldLaunchPolling }: IUseUserIdentityVerificationParams): IUseUserIdentityVerification => {
    const dispatch = useDispatch();
    const [pollingRetryCount, setPollingRetryCount] = useState(ZERO);
    const [startPolling, setStartPolling] = useState<boolean>(false);
    const { displaySimpleConfirmDialog, closeDialog, openIdentityVerificationDialog }: IUseDialog = useDialog();
    const { t: translate } = useTranslation();
    const maxPollingRetry = 3;
    const pollingTimeoutDelay = 4000;
    const [kycPolling, setKycPolling] = useState<NodeJS.Timeout | undefined>();

    const showAiPriseKYCModal = (): void => {
        CrispService.hide();
        WhatsAppService.hide();
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: true,
            showCancelButton: true,
            showBackArrow: false,
            fullScreen: Utils.isMobile(),
            type: 'AI_PRISE_KYC',
            propsCustom: {
                callbackFunction: launchPolling
            },
        }));
    }

    const launchPolling = () => {
        if (!shouldLaunchPolling)
            return
        setStartPolling((prev: boolean) => !prev);
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: true,
            showBackArrow: false,
            showCancelButton: false,
            fullScreen: false,
            type: 'LOADING_MODAL',
        }));
    }

    const openVerificationIdentityDialog = () => {
        if (Application.getInstance().enableKYCAiPrise)
            return showAiPriseKYCModal;
        return openIdentityVerificationDialog;
    }

    useEffect(() => {
        if (!startPolling || pollingRetryCount === maxPollingRetry)
            return;

        setKycPolling(setTimeout(() => {
            dispatch(pollingKycStatus());
            setPollingRetryCount((prev: number) => prev + 1);
        }, pollingTimeoutDelay));
        return () => {
            stopKYCPolling();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollingRetryCount, startPolling]);

    const launchIdentityVerification = () => {
        displaySimpleConfirmDialog({
            showCancelButton: true,
            textValidButton: translate("identity.pre.launch.kyc.validation"),
            textCancelButton: translate('withdraw.request.close'),
            onValidation: openVerificationIdentityDialog(),
            message: translate('identity.pre.launch.kyc'),
            onRefused: closeDialog
        });
    }

    const clearKycPolling = useCallback(() => {
        if (!kycPolling)
            return;
        clearTimeout(kycPolling);
    }, [kycPolling]);

    const stopKYCPolling = useCallback(() => {
        clearKycPolling();
    }, [clearKycPolling])

    return {
        launchIdentityVerification,
        pollingRetryCount,
        maxPollingRetry,
        isPollingStarted: startPolling,
        stopKYCPolling,
    }
}

export default useUserIdentityVerification