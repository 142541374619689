import React from 'react';
import i18n from '18n';
import WalletWithdrawalViewModel from 'view-model/WalletWithdrawalViewModel/WalletWithdrawalViewModel';
import PendingWithdrawal from './pending-withdrawal/PendingWithdrawal';
import './WalletPendingWithdraws.scss';
import Divider from 'components/small/divider/Divider';
import { COLOR, TRANSACTION_VIEW_SIZE } from 'config/_const';
import Skeleton from '@material-ui/lab/Skeleton';
import useUserWithdrawalRequests from 'hooks/user-withdrawal-requests/useUserWithdrawalRequests';

const WalletPendingWithdraws: React.FC = () => {
  const [viewSize, setViewSize] = React.useState(TRANSACTION_VIEW_SIZE);
  const { withdrawalRequests, loading: isWithdrawRequestLoading } = useUserWithdrawalRequests({ getAllAtComponentLoading: true });
  const withdrawRequestLength = withdrawalRequests.length;
  const hasWithdrawRequested = Boolean(withdrawRequestLength);

  return <>
    {
      isWithdrawRequestLoading
        ? <div className='wallet-pending-withdraw__loading-skeleton'>
          <Skeleton variant="text" width={"100%"} height={"100%"} />
        </div>
        : (hasWithdrawRequested &&
          <section className='wallet-pending-withdraws'>
            <h6 className='wallet-pending-withdraws__heading'>{i18n.t('pending.withdraw')}</h6>
            {withdrawalRequests.slice(0, viewSize).map((walletWithdrawalViewModel: WalletWithdrawalViewModel) =>
              <PendingWithdrawal key={walletWithdrawalViewModel.Id} walletWithdrawalViewModel={walletWithdrawalViewModel} />
            )}
            {viewSize < withdrawRequestLength &&
              <span className="see-more" onClick={() => setViewSize(withdrawRequestLength)}>
                {i18n.t("wallet.order.seeMore")}
              </span>
            }
            <Divider background={COLOR.TRANSPARENT_WHITE} size="calc(100% - 20px)" />
          </section>
        )
    }
  </>
};

export default WalletPendingWithdraws;