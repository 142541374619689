import i18n from "18n";
import { HTTP_STATUS_OK, ONE } from "config/_const";
import AxiosConfig from "config/axiosConfig";
import { ORDER_ENDPOINTS } from "config/endpoints";
import { marketServiceUrl } from "config/marketServiceConfig";
import { IHttpStrongTypedResponse } from "model/IHttpResponse";
import IBuyFromBestSellingOrders from "model/Order/IBuyFromBestSellingOrders";
import IBuyShresFromBestSellingOrers from "model/Payment/IBuySharesFromBestSellingOrders";
import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import { UPDATE_CREDITS_AFTER_BUY } from "service/auth/actionsType";
import { getUserOrders } from "service/order/actions";
import { IBuySecondaryMarketV3 } from './../../model/Order/IBuySecondaryMarketV3';
import { BUY_FROM_BEST_SELLING_ORDERBOOK } from './actionType';
import { IBuyOnSecondaryMarketV4Error } from "./types";

export const buySharesFromBestSellingOrders = (inputs: IBuyShresFromBestSellingOrers): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: BUY_FROM_BEST_SELLING_ORDERBOOK.LOADING });
        const response: IHttpStrongTypedResponse<IBuyFromBestSellingOrders> = await AxiosConfig.post(ORDER_ENDPOINTS.BUY_FROM_BEST_SELLING_ORDERS, inputs);
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR,
                payload: i18n.t(response.data.message),
            });
        dispatch(getUserOrders());
        return dispatch({
            type: BUY_FROM_BEST_SELLING_ORDERBOOK.SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        console.error('error from buySellingOrder : ', error);
        return dispatch({
            type: BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR,
            payload: error,
        });
    }
};

export const buyOnSecondaryMarketV3 = (inputs: IBuyShresFromBestSellingOrers, callBackAfterTxn?: () => void): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        if (inputs.numberOfShares.isSmallerThan(ONE))
            return dispatch({
                type: BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR,
                payload: i18n.t('sm.negative.amount.detected', { amount: inputs.numberOfShares }),
            });

        dispatch({ type: BUY_FROM_BEST_SELLING_ORDERBOOK.LOADING });
        const response: IHttpStrongTypedResponse<IBuySecondaryMarketV3> = await AxiosConfig.post(ORDER_ENDPOINTS.BUY_SECONDARY_MARKET_V3, inputs);
        callBackAfterTxn?.();
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR,
                payload: i18n.t(response.data.message),
            });
        return dispatch({
            type: BUY_FROM_BEST_SELLING_ORDERBOOK.SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        console.error('error from buySellingOrder : ', error);
        callBackAfterTxn?.();
        return dispatch({
            type: BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR,
            payload: error,
        });
    }
};


export const buyOnSecondaryMarketV4 = (inputs: IBuyShresFromBestSellingOrers, callBackAfterTxn?: () => void): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        if (inputs.numberOfShares.isSmallerThan(ONE))
            return dispatch({
                type: BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR,
                payload: i18n.t('sm.negative.amount.detected', { amount: inputs.numberOfShares }),
            });

        dispatch({ type: BUY_FROM_BEST_SELLING_ORDERBOOK.LOADING });
        const response: IHttpStrongTypedResponse<IBuySecondaryMarketV3, IBuyOnSecondaryMarketV4Error> = await AxiosConfig.post(`${marketServiceUrl}/${ORDER_ENDPOINTS.MARKET_SERVICE_BUY}`, inputs);
        callBackAfterTxn?.();
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR,
                payload: i18n.t(response.data.error.error || 'Common.an-error-occurred'),
            });
        dispatch({
            type: UPDATE_CREDITS_AFTER_BUY,
            payload: {
                userCredits: response.data.data.userCredits,
                userVirtualCredits: response.data.data.userVirtualCredits
            }
        });
        return dispatch({
            type: BUY_FROM_BEST_SELLING_ORDERBOOK.SUCCESS,
            payload: response.data.data,
        });
    } catch (error: any) {
        const errorMessage: string = error?.response?.data?.message || 'Common.an-error-occurred';
        return dispatch({
            type: BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR,
            payload: errorMessage,
        });
    }
}

export const createLimitOrder = (inputs: IBuyShresFromBestSellingOrers, callBackAfterTxn?: () => void): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        if (inputs.numberOfShares.isSmallerThan(ONE))
            return dispatch({
                type: BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR,
                payload: i18n.t('sm.negative.amount.detected', { amount: inputs.numberOfShares }),
            });

        dispatch({ type: BUY_FROM_BEST_SELLING_ORDERBOOK.LOADING });
        const response: IHttpStrongTypedResponse<IBuySecondaryMarketV3> = await AxiosConfig.post(`${marketServiceUrl}/${ORDER_ENDPOINTS.MARKET_SERVICE_CREATE_LIMIT_ORDER}`, inputs);
        callBackAfterTxn?.();
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR,
                payload: i18n.t(response.data.message),
            });
        dispatch({
            type: UPDATE_CREDITS_AFTER_BUY,
            payload: {
                userCredits: response.data.data.userCredits,
                userVirtualCredits: response.data.data.userVirtualCredits
            }
        });
        return dispatch({
            type: BUY_FROM_BEST_SELLING_ORDERBOOK.SUCCESS,
            payload: response.data.data,
        });
    } catch (error: any) {
        console.error('error from buySellingOrder : ', error);
        const errorMessage: string = error?.response?.data?.message || 'Common.an-error-occurred';
        return dispatch({
            type: BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR,
            payload: errorMessage,
        });
    }
}



export const dropDataOnGetBestSellingOrder = (): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    return dispatch({ type: BUY_FROM_BEST_SELLING_ORDERBOOK.DROP_DATA });
};