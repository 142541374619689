import i18n from '18n';
import { Remove } from '@material-ui/icons';
import RedButton from 'components/small/buttons/red-button/RedButton';
import useAssetBuySellClick from 'hooks/use-asset-buy-sell-click/useAssetBuySellClick';
import IUseUserIdentityVerification from 'hooks/use-user-identity-verification/IUseUserIdentityVerification';
import useUserIdentityVerification from 'hooks/use-user-identity-verification/useUserIdentityVerification';
import React from 'react';
import './SellShareButton.scss';
import ISellShareButtonProps from './SellShareButtonProps';
import clsx from 'clsx';

const SellShareButton: React.FC<ISellShareButtonProps> = ({ assetViewModel, isSellBtnClickable }) => {
    const { launchIdentityVerification }: IUseUserIdentityVerification = useUserIdentityVerification({});
    const { onSellAssetClick } = useAssetBuySellClick();
    const canOpenAssetProfile = false;


    return <>
        <RedButton
            className={clsx('sell-share-button', { 'fun': assetViewModel.IsFunCategory || assetViewModel.IsFootballEuro })}
            disabled={!isSellBtnClickable}
            receiveClickEvent={false}
            textButton={
                <span className='sell-button-label'>
                    <Remove /> {i18n.t('sellAsset.sellShares')}
                </span>}
            type="button"
            handleClick={() => onSellAssetClick(assetViewModel.AssetSlug, canOpenAssetProfile, launchIdentityVerification)}
        />
    </>
};

export default React.memo(SellShareButton);
