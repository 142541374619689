const EU_COUNTRIES_LANGUAGE_MAP = {
    "Albania": "sq-AL",
    "Andorra": "ca-AD",
    "Austria": "de-AT",
    "Belarus": "be-BY",
    "Belgium": "nl-BE",
    "Bosnia and Herzegovina": "bs-BA",
    "Bulgaria": "bg-BG",
    "Croatia": "hr-HR",
    "Cyprus": "el-CY",
    "Czech Republic": "cs-CZ",
    "Denmark": "da-DK",
    "Estonia": "et-EE",
    "Finland": "fi-FI",
    "France": "fr-FR",
    "Germany": "de-DE",
    "Greece": "el-GR",
    "Hungary": "hu-HU",
    "Iceland": "is-IS",
    "Ireland": "en-IE",
    "Italy": "it-IT",
    "Kosovo": "sq-XK",
    "Latvia": "lv-LV",
    "Liechtenstein": "de-LI",
    "Lithuania": "lt-LT",
    "Luxembourg": "lb-LU",
    "Malta": "mt-MT",
    "Moldova": "ro-MD",
    "Monaco": "fr-MC",
    "Montenegro": "sr-ME",
    "Netherlands": "nl-NL",
    "North Macedonia": "mk-MK",
    "Norway": "nb-NO",
    "Poland": "pl-PL",
    "Portugal": "pt-PT",
    "Romania": "ro-RO",
    "Russia": "ru-RU",
    "San Marino": "it-SM",
    "Serbia": "sr-RS",
    "Slovakia": "sk-SK",
    "Slovenia": "sl-SI",
    "Spain": "es-ES",
    "Sweden": "sv-SE",
    "Switzerland": "de-CH",
    "Ukraine": "uk-UA",
    "United Kingdom": "en-GB",
    "Vatican City": "la-VA"
};

export { EU_COUNTRIES_LANGUAGE_MAP };