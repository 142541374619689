import ITranslation from './ITranslation';

export default class Translation implements ITranslation {
    key: string;
    values: Object;

    constructor(key: string, values: any) {
        this.key = key;
        this.values = values;
    }
}