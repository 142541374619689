import IAssetProfileModal from 'model/IAssetProfileModal';
import ReduxRootState from 'model/Redux/ReduxRootSate';
import ReduxActionInterface from 'model/ReduxActionInterface';
import * as AssetViewModal from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<IAssetProfileModal> = {
    loading: false,
    data: {
        isOpen: false,
        slugParam: '',
        isSlidingUp: false,
        overlaySlug: '',
    }
};

export const assetModalViewReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    if (action.type === AssetViewModal.SET_SLIDE_UP_MODAL_STATE)
        return {
            ...state,
            data: {
                ...state.data,
                isOpen: action.payload.isOpen ?? state.data.isOpen,
                slugParam: action.payload.slugParam ?? state.data.slugParam,
                isSlidingUp: action.payload.isSlidingUp ?? state.data.isSlidingUp,
                overlaySlug: action.payload.overlaySlug ?? state.data.overlaySlug,
            },
        };
    return state;
};
