import { useSelector } from 'react-redux';
import { userIsAuthenticated } from 'service/auth/selectors';
import AssetCategoryViewModel from 'view-model/Assets/AssetCategory';
import { AssetCategory } from '../config/_enums';
import useAssetsCategories from 'hooks/asset-categories/useAssetCategories';
import useWallet from './wallet/use-wallet/useWallet';

interface IUseShouldHideInstagramAssets {
    shouldHide: boolean;
    category: AssetCategoryViewModel | undefined;
}
const useShouldHideInstagramAssets = (): IUseShouldHideInstagramAssets => {
    useWallet({ considerTeamShares: false });
    const isAuthenticated: boolean = useSelector(userIsAuthenticated);
    const { assetCategoriesAsViewModel } = useAssetsCategories();
    const hiddenCategory: AssetCategoryViewModel | undefined = assetCategoriesAsViewModel.find(({ Name }) => Name === AssetCategory.Instagram);

    if (!isAuthenticated)
        return {
            shouldHide: true,
            category: hiddenCategory,
        };

    return {
        shouldHide: false,
        category: hiddenCategory,
    };
};

export default useShouldHideInstagramAssets;
