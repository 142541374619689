import CheckMark from 'components/icons/CheckMark';
import React from 'react';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import useUserLeague from 'hooks/use-user-league/useUserLeague';
import BuyPacksButton from '../buy-packs-button/BuyPacksButton';
import WhiteListDisclaimer from '../white-list-box-button/white-list-disclaimer/WhiteListDisclaimer';
import AssetProfilActionButtonsProps from './AssetProfilActionButtonsProps';
import './style.scss';
import { withTranslation } from 'react-i18next';

const AssetProfilActionButtons: React.FC<AssetProfilActionButtonsProps> = ({ assetVM, t: translate }) => {
    const { accountViewModel }: IUseAccount = useAccount();
    const { userLeagueViewModel } = useUserLeague();

    const userIsWhitelisted: boolean = React.useMemo(() => {
        return accountViewModel.IsUserWhiteListed() || userLeagueViewModel.LeagueAttribution.isWhitelisted();
    }, [accountViewModel, userLeagueViewModel]);
    return (
        <div className="asset-profil-actions-button">
            {assetVM.showPack() && <div className="buy-pack-container">
                {!assetVM.isEarlyBirdEnable() && !userIsWhitelisted && <div className="disclaimer">
                    <h6 className="title">{translate('pre.sales.buy.pack.title')}</h6>
                    <p className="no-margin info">{translate('pre.sales.buy.pack.subtitle', { hours: assetVM.EarlyBirdDate.get('hours') })}</p>
                </div>}
                <BuyPacksButton assetViewModel={assetVM} /></div>}

            {assetVM.isWhiteListed() && !assetVM.IsWhiteListedPurchaseOpen && !assetVM.PreSaleIsFinished && <div className="white-list-container"> <WhiteListDisclaimer assetVM={assetVM} icon={<CheckMark />} /></div>}
        </div>
    )
};

export default withTranslation()(React.memo(AssetProfilActionButtons));
