import React from 'react';
import ISignupCountdownProps from './ISignupCountdownProps';
import './SignupCountdown.scss';
import { NUMBER_OF_FREE_CARDS, NUMBER_OF_FREE_CARDS_ONBOARDING, SIGNUP_COUNTDOWN_HOURS, SIGNUP_COUNTDOWN_MINUTES, SIGNUP_COUNTDOWN_SECONDS } from 'config/_const';
import { Trans } from 'react-i18next';
import dayjs from 'dayjs';
import Application from 'Application';
import CountdownWidget from '../countdown-widget/CountdownWidget';


const SignupCountdown: React.FC<ISignupCountdownProps> = () => {
    const now = dayjs();
    const countdownTime = Application.getInstance().IsFootballEuroMode() ? dayjs('2024-07-14T23:00:00').utcOffset(2) : now.add(SIGNUP_COUNTDOWN_HOURS, 'hours').add(SIGNUP_COUNTDOWN_MINUTES, 'minutes').add(SIGNUP_COUNTDOWN_SECONDS, 'seconds');
    const nbTokens = !Application.getInstance().OnBoardingV2 ? NUMBER_OF_FREE_CARDS : NUMBER_OF_FREE_CARDS_ONBOARDING;

    return (
        <div className="countdown-component">
            <span className='title-countdown-component'><Trans i18nKey={"onboarding.welcome.bonus.title"} values={{ nbTokens: nbTokens }} components={{ gold: <span className='gold' /> }} /></span>
            <div className='countdown-wrapper'>
                <CountdownWidget date={countdownTime.toDate().toString()} titleTranslationKey='onboarding.welcome.bonus.description' daysInHours={Boolean(!Application.getInstance().IsFootballEuroMode())} />
            </div>
        </div>
    );
};

export default SignupCountdown;
