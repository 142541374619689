import { ZERO } from 'config/_const';
import IFootballStatisticsDribble from 'interfaces/sports/football/statistics/IFootballStatisticsDribble';

export default class FootballStatisticsDribbleViewModel {
    private attempts: number;
    private success: number;
    private past: number;

    constructor(statistics: Partial<IFootballStatisticsDribble>) {
        this.attempts = statistics.attempts ?? ZERO;
        this.success = statistics.success ?? ZERO;
        this.past = statistics.past ?? ZERO;
    }

    get Attempts(): number {
        return this.attempts;
    }

    get Success(): number {
        return this.success;
    }

    get Past(): number {
        return this.past;
    }
}
