import React from 'react';
import './GreenButton.scss';
import GreenButtonProps from './GrennButtonProps';
import clsx from 'clsx';

const GreenButton: React.FC<GreenButtonProps> = (props) => {
    return (
        <button
            className={clsx('green-button',
                {
                    [`${props.className}`]: Boolean(props.className),
                    'btn-shadow': props.showShadow
                }
            )}
            type={props.type}
            disabled={props.disabled}
            onClick={(e: any) => props.handleClick ? props.receiveClickEvent ? props.handleClick(e) : props.handleClick() : undefined}
        >
            {props.icon &&
                <span className='green-button__icon'>{props.icon}</span>
            }
            {props.textButton}
            <span className="subtitle">{props.subtitle}</span>
        </button>
    );
};

export default GreenButton;
