import i18n from '18n';
import { AssetCardLevel } from 'config/_enums';
import { ICategoryTypeName } from 'interfaces/IAssetBgImages';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import AssetInterface, { IAssetWithAmount } from 'model/AssetModel/AssetInterface';
import { IEvolution } from 'model/Tournament/ITournamentEvolution';
import Utils from 'utils/Utils';
import Color from 'view-model/Shared/Color';
import IWalletInformationMap from './IWalletInformationMap';
import { SPORT_ASSET_CATEGORIES } from 'config/_const';
import Application from 'Application';
import AssetViewModel from 'view-model/Assets/AssetViewModel';

export default class WalletAssetListViewModel {
    private asset: AssetInterface;
    private amount: number;
    private profit?: number;
    private marketPrice: number;
    private irlScore: number;
    private totalPriceChange: number;
    private name: string;
    public mainCategory: string;
    private slug: string;
    private yieldEnabled: boolean;
    public icon: string;
    private id: string;
    private currentValue: number;
    private IRLScoreEvolution?: IEvolution[];
    private scoreEvolution: number;
    private yieldAmountPerYear: number;
    private yieldTotalAmountEarned: number;
    private cardLevel: AssetCardLevel;
    private initialSaleRealEndDate: string;
    public sportsScore: number;
    private tokensInTeam: number;
    private isYieldEnabled: boolean;

    constructor(asset: IAssetWithAmount, assetScoreEvolution?: IEvolution[]) {
        this.asset = asset;
        this.amount = asset.amount ?? 0;
        this.profit = asset.profit;
        this.mainCategory = asset.mainCategory;
        this.irlScore = asset.IRLScore;
        this.totalPriceChange = asset.totalPriceChange;
        this.name = asset.name;
        this.marketPrice = asset.marketPrice;
        this.slug = asset.slug;
        this.yieldEnabled = asset.yieldEnabled;
        this.icon = asset.icon;
        this.id = asset.id;
        this.currentValue = asset.currentValue;
        this.IRLScoreEvolution = assetScoreEvolution;
        this.scoreEvolution = this.computeScoreEvolution();
        this.yieldAmountPerYear = asset.yieldAmountPerYear ?? 0;
        this.yieldTotalAmountEarned = asset.yieldTotalAmountEarned ?? 0;
        this.cardLevel = asset.cardLevel;
        this.initialSaleRealEndDate = asset.initialSaleRealEndDate;
        this.sportsScore = asset.sportsScore;
        this.tokensInTeam = asset.tokensInTeam ?? 0;
        this.isYieldEnabled = asset.isYieldEnabled ?? false;
    }

    public get InitialSaleRealEndDate(): string {
        return this.initialSaleRealEndDate;
    }

    public get Asset() {
        return this.asset;
    }

    public get Amount(): number {
        return this.amount.addition(this.tokensInTeam);
    }

    public get AmountWithoutTokensInTeam(): number {
        return this.amount;
    }

    public get Profit() {
        return this.profit;
    }

    public get MarketPrice() {
        return this.marketPrice;
    }

    public get MainCategoryId() {
        return this.mainCategory;
    }

    public get IRLScore() {
        return this.irlScore;
    }

    public get CurrentValue() {
        return this.currentValue;
    }

    public get TotalPriceChange() {
        return this.totalPriceChange;
    }

    public get Name() {
        return this.name;
    }

    public get Slug() {
        return this.slug;
    }

    public get YieldEnabled() {
        return this.yieldEnabled;
    }

    public get AvatarPath() {
        return this.icon;
    }

    public get Id() {
        return this.id;
    }

    public get YieldAmountPerYear(): number {
        return this.yieldAmountPerYear;
    }
    public get YieldTotalAmountEarned(): number {
        return this.yieldTotalAmountEarned;
    }

    public getAssetCategoryName(assetCategories: AssetCategoryInterface[]) {
        const [assetCategory] = Utils.getAssetCategory(assetCategories, { mainCategory: this.mainCategory });
        const categoryName: ICategoryTypeName = assetCategory?.name.toLowerCase() as ICategoryTypeName;
        return categoryName;
    }

    public getAssetScoreBackgroundColor() {
        const RGBvalue: string = Color.getColorByInputRatio(this.IRLScore || 0)
        return RGBvalue;
    }

    public getTotalHoldingValue(): number {
        return this.CurrentValue * (this.Amount || 0);
    }

    public getAssetInformationMap(): IWalletInformationMap[] {
        var color = 'grey-text';
        if (this.Profit)
            color = this.Profit > 0 ? 'green-text' : (this.Profit < 0 ? 'red-text' : 'grey-text');
        const informationMap = [
            { label: "wallet.currentValue", value: this.CurrentValue.toCurrency(), order: 1 },
            { label: "wallet.profits", value: `${this.Profit?.toCurrency(undefined, undefined, undefined, undefined, 'exceptZero')}`, order: 2, color },
            ...this.YieldEnabled ?
                [
                    { label: "wallet.year.yield", value: this.YieldAmountPerYear.toCurrency(2), order: 3, sup: i18n.t("value.for.year") },
                ] : [],
        ];
        informationMap.sort((a, b) => a.order - b.order);
        return informationMap;

    }

    private computeScoreEvolution(): number {
        if (!this.IRLScoreEvolution) return 0;
        const todayEvolution = this.IRLScoreEvolution?.find(evolution => new Date(evolution.date).getDate() === new Date().getDate());
        const prevDayEvolution = this.IRLScoreEvolution?.find(evolution => new Date(evolution.date).getDate() === new Date().subtractDates({ days: 1 }).getDate());

        if (todayEvolution && todayEvolution.score !== -1) {
            return todayEvolution.score - (prevDayEvolution?.score || this.IRLScore);
        }
        const prevDay2Evolution = this.IRLScoreEvolution?.find(evolution => new Date(evolution.date).getDate() === new Date().subtractDates({ days: 2 }).getDate());
        if (prevDayEvolution && prevDay2Evolution) {
            return prevDayEvolution.score - prevDay2Evolution.score;
        }
        return 0;
    }
    public get ScoreEvolution() {
        return this.scoreEvolution;
    }
    public get ScoreEvolutionChangeSign() {
        return this.ScoreEvolution > 0 ? "+" : "";
    }
    public get CardLevel(): AssetCardLevel {
        return this.cardLevel;
    }

    public get isAssetInTeam(): boolean {
        return this.Asset.tokensInTeam > 0;
    }

    public get formattedFootballScore(): number | string {
        return this.sportsScore ? Math.floor(this.sportsScore) : '__';
    }

    public get IsSportPlayerRetired(): boolean {
        return this.asset.sportPlayer?.isRetired || false;
    }

    public canShowSportScore(assetCategories: AssetCategoryInterface[]): boolean {
        if (this.IsSportPlayerRetired || this.sportsScore <= 0)
            return false;
        const categoryName = this.getAssetCategoryName(assetCategories);
        return SPORT_ASSET_CATEGORIES.includes(categoryName);
    }

    public get IsYieldEnabled(): boolean {
        return this.isYieldEnabled;
    }

    public get ScoreBoosted(): boolean {
        if (!this.asset.initialSaleDate) {
            return false;
        }

        return this.asset.scoreBoosted && Utils.isDateBeforeDelay(this.asset.initialSaleDate, Application.getInstance().AssetScoreBoostedDays);
    }

    public getScoreTooltipText(tooltipText: string): string {
        return this.asset.scoreBoosted ? i18n.t('tooltip.score.boosted') : tooltipText
    }

    public get AssetAsVM(): AssetViewModel {
        return new AssetViewModel(this.Asset);
    }
}
