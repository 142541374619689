import Application from 'Application';
import NoTournamentResults from 'components/medium/tournament/off/no-tournament-results/NoTournamentResults';
import TournamentOffSectionLoading from 'components/medium/tournament/off/tournament-off-section/tournament-off-section-loading/TournamentOffSectionLoading';
import IUseTournamentResults from 'hooks/tournament/use-tournament-results/IUseTournamentResults';
import useTournamentResults from 'hooks/tournament/use-tournament-results/useTournamentResults';
import IUseTournamentRewards from 'hooks/tournament/use-tournament-rewards/IUseTournamentRewards';
import useTournamentRewards from 'hooks/tournament/use-tournament-rewards/useTournamentRewards';
import React, { useEffect } from 'react';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';
import TournamentViewModel from 'view-model/Tournament/TournamentViewModel/TournamentViewModel';
import IWithTournamentResultsParams from './IWithTournamentResultsParams';
import { useDispatch } from 'react-redux';
import { DEFAULT_ID_AS_STRING } from 'config/_const';

const withTournamentResults = (Component: React.FC<IWithTournamentResultsParams>) => {
    const MemoComponent = React.memo(Component);

    const Wrapper: React.FC<{ tournament: TournamentViewModel, activeLeague: LeagueViewModel }> = ({ tournament, activeLeague }) => {
        const dispatch = useDispatch();
        const {
            tournamentResults,
            tournamentResultsLoading,
            fetchTournamentResults
        }: IUseTournamentResults = useTournamentResults({ tournamentId: tournament.Id });
        const { fetchCurrentTournamentRewards, tournamentRewards, tournamentRewardsLoading }: IUseTournamentRewards = useTournamentRewards({ tournamentId: tournament.Id });

        useEffect(() => {
            if (tournament.Id === DEFAULT_ID_AS_STRING)
                return;
            if (tournamentResults.length === 0)
                dispatch(fetchTournamentResults(tournament.Id));
        }, [dispatch, fetchTournamentResults, tournament.Id, tournamentResults.length])

        useEffect(() => {
            if (tournament.Id === DEFAULT_ID_AS_STRING || tournamentRewardsLoading)
                return;
            if (tournamentRewards.length === 0 && tournamentResults.length !== 0)
                dispatch(fetchCurrentTournamentRewards());
        }, [dispatch, fetchCurrentTournamentRewards, tournament.Id, tournamentResults.length, tournamentRewards, tournamentRewards.length, tournamentRewardsLoading]);

        const tournamentResultsFiltered = tournamentResults.filter((tournamentResult) => tournamentResult.League === activeLeague.Name);
        if (tournamentResultsLoading || !tournament)
            return <TournamentOffSectionLoading />;
        if ((!tournamentResultsLoading && tournamentResultsFiltered.length === 0) || !Application.getInstance().ShowTournamentResults)
            return <NoTournamentResults />;
        return <MemoComponent activeLeague={activeLeague} tournamentResults={tournamentResultsFiltered} tournament={tournament} />
    };

    return React.memo(Wrapper);
};

export default withTournamentResults;
