import Application from 'Application';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import Instructions from 'components/small/instructions/Instructions';
import NotificationUi from 'components/small/notification-ui/NotificationUi';
import ReloadAccountBlock from 'components/small/reload-account-block/ReloadAccountBlock';
import { ASSET_BUY_PAGE_CONFIG, ASSET_BUY_STAGE, LOCAL_STORAGE_KNOWN_KEYS, PRE_PRIVATE_SALES_START_THRESHOLD } from 'config/_const';
import { AssetTransactionType } from 'config/_enums';
import useFirstDepositAchievement from 'hooks/first-deposit-achievement/useFirstDepositAchievement';
import useAccount from 'hooks/use-account/useAccount';
import IUseAssetPredictPrice from 'hooks/use-asset-predict-price/IUseAssetPredictPrice';
import useAssetPredictPrice from 'hooks/use-asset-predict-price/useAssetPredictPrice';
import useAssetTransactionSlides from 'hooks/use-asset-transaction-slideup/useAssetTransactionSlides';
import IUseTransaction from 'hooks/use-transactions/IUseTransaction';
import useTransaction from 'hooks/use-transactions/useTransaction';
import useUserLeague from 'hooks/use-user-league/useUserLeague';
import i18next from 'i18next';
import AssetInterface from 'model/AssetModel/AssetInterface';
import React, { ReactElement, useEffect, useState } from 'react';
import { CountdownTimeDelta } from 'react-countdown/dist/utils';
import { Trans } from 'react-i18next';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import AssetBuySellSection from '../asset-buySell-section/AssetBuySellSection';
import BenzemaTimer from '../profil-banner/benzema-timer/BenzemaTimer';
import Timer from '../timer/Timer';
import './AssetMarketPlace.scss';
import type { AssetMarketPlaceProps } from './AssetMarketPlaceProps';

const PrivateSell: React.FC<AssetMarketPlaceProps> = (props) => {
    const canDisplayInstruction: boolean = false;
    const assetViewModel: AssetViewModel = React.useMemo(() => new AssetViewModel(props.asset), [props.asset]);
    const { accountViewModel } = useAccount();
    const { scheduledPredictPrice }: IUseAssetPredictPrice = useAssetPredictPrice({ asset: props.asset, transactionType: AssetTransactionType.BUY });
    const [isSaleStartingInLessThan3Min, setIsSaleStartingInLessThan3Min] = useState<boolean>(assetViewModel.isInPrePrivateSaleState());
    const ThreeMinutesInMs: number = PRE_PRIVATE_SALES_START_THRESHOLD * 60 * 1000;
    const { openAssetTransaction } = useAssetTransactionSlides();
    const { userLeagueViewModel } = useUserLeague();
    const isUserWhiteListed: boolean = accountViewModel.IsUserWhiteListed() || userLeagueViewModel.LeagueAttribution.isWhitelisted();
    const withCustomProfil: boolean = React.useMemo(() => assetViewModel.enableCustomAssetProfil(), [assetViewModel]);
    const { transactions }: IUseTransaction = useTransaction();
    const { firstDepositAchievementVM } = useFirstDepositAchievement(transactions);

    const isPrimaryMarketWillStart: boolean = React.useMemo(() => {
        return Boolean(props.asset && !props.privateSellIsOpen && !assetViewModel.InitialSaleDone && assetViewModel.InitialSaleDate);
    }, [props.asset, props.privateSellIsOpen, assetViewModel.InitialSaleDone, assetViewModel.InitialSaleDate]);

    const shouldDisplayPurchaseButton: boolean = React.useMemo(() => {
        return props.isBuyable || props.privateSellIsOpen || isSaleStartingInLessThan3Min || (props.preSaleIsOpen && isUserWhiteListed) || assetViewModel.isEarlyBirdEnable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isBuyable, props.privateSellIsOpen, props.preSaleIsOpen, isSaleStartingInLessThan3Min, isUserWhiteListed]);

    const shouldDisplayBottomButtonForBenzema: boolean = React.useMemo(() => {
        if (!assetViewModel.IsAssetBenzema || assetViewModel.InitialSaleDateHasPassed)
            return true;
        return assetViewModel.enableCustomAssetProfil();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetViewModel, props.privateSellIsOpen, props.preSaleIsOpen]);

    const shouldDisplayTopButtonForBenzema: boolean = React.useMemo(() => {
        if (!assetViewModel.IsAssetBenzema)
            return false;
        return !assetViewModel.enableCustomAssetProfil();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetViewModel, props.privateSellIsOpen, props.preSaleIsOpen]);

    const shouldDisplayBottomBuyButton: boolean = shouldDisplayPurchaseButton && shouldDisplayBottomButtonForBenzema;
    const shouldDisplayTopBuyButton: boolean = shouldDisplayPurchaseButton && shouldDisplayTopButtonForBenzema;

    useEffect(() => {
        const directBuy: string | null = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.DIRECT_BUY);
        if (!directBuy)
            return;
        localStorage.removeItem(LOCAL_STORAGE_KNOWN_KEYS.DIRECT_BUY)
        const shouldShowPack: boolean = (directBuy === ASSET_BUY_PAGE_CONFIG.PACK && assetViewModel.showPack()) || assetViewModel.BuyStage === ASSET_BUY_STAGE.PRE_BUY_PACK;
        return openAssetTransaction(assetViewModel.AssetId, AssetTransactionType.BUY, true, shouldShowPack);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let reloadNextStageTimeout: ReturnType<typeof setTimeout>;
        if ((assetViewModel.BuyStage === ASSET_BUY_STAGE.PRE_BUY_PACK || assetViewModel.BuyStage === ASSET_BUY_STAGE.WHITELIST_USER_BUY)
            && assetViewModel.NextTransitionBuyStageInSeconds) {
            reloadNextStageTimeout = setTimeout(() => setTimeout(() => window.location.reload()), assetViewModel.NextTransitionBuyStageInSeconds.multiply(1000));
        }

        return () => {
            if (reloadNextStageTimeout) clearTimeout(reloadNextStageTimeout);
        }
    }, [assetViewModel])

    const onPrivateSellTimerEnd = (): void => {
        props.onPrivateSellTimerEnd();
        setIsSaleStartingInLessThan3Min(false);
        scheduledPredictPrice();
    };

    const onPrivateSellTick = (timeDelta: CountdownTimeDelta): void => {
        if (timeDelta.total > ThreeMinutesInMs) {
            if (isSaleStartingInLessThan3Min)
                setIsSaleStartingInLessThan3Min(false)
            return;
        }
        if (!isSaleStartingInLessThan3Min)
            setIsSaleStartingInLessThan3Min(true);
    };

    const generateInfoSentence = (): ReactElement => {
        const asset: AssetInterface = props.asset;
        const currentValue: number = props.asset.currentValue;
        if (accountViewModel.TotalCreditsAsNumber === 0 || accountViewModel.TotalCreditsAsNumber < currentValue)
            return <>
                {Application.getInstance().showGamblingFeature && shouldDisplayTopBuyButton &&
                    <>
                        <AssetBuySellSection assetViewModel={assetViewModel} isSaleStartingInLessThan3Min={isSaleStartingInLessThan3Min} />

                    </>
                }</>;
        const tokensPrice: number[] = [currentValue, currentValue + 1, currentValue + 2];
        return <>
            {Application.getInstance().showGamblingFeature && shouldDisplayTopBuyButton &&
                <>
                    <AssetBuySellSection assetViewModel={assetViewModel} isSaleStartingInLessThan3Min={isSaleStartingInLessThan3Min} />
                    <div>
                        <h2>Test</h2>
                    </div>
                </>
            }
            <p>
                {i18next.t('asset.available.soon.info.sentence.start')}
                {tokensPrice.map((value: number, index: number) => {
                    const maxTokens: number = accountViewModel.getNumberOfTokensComparedToTotalCredits(value, asset.availableSupply);
                    if (maxTokens === 0)
                        return null;
                    return <b>{i18next.t(`asset.available.soon.info.sentence.bold.${index + 1}`, { nbTokens: maxTokens, price: value.toCurrency() })}</b>
                })}
                {i18next.t('asset.available.soon.info.sentence.end')}
            </p>
        </>
    };

    return (
        <>
            {!withCustomProfil && isPrimaryMarketWillStart && (
                <div className='private-sell-container'>
                    <div className="countdown-ico-not-open">
                        <div className="countdown-top-section">
                            {assetViewModel.IsAssetBenzema && <BenzemaTimer setIsSaleStartingInLessThan3Min={setIsSaleStartingInLessThan3Min} assetVM={assetViewModel} setPrivateSellIsOpen={onPrivateSellTimerEnd} setPreSaleIsOpen={props.setPreSaleIsOpen} />}
                            {!assetViewModel.IsAssetBenzema &&
                                <>
                                    <h5 className='countdown-top-section__title'>{i18next.t('Generictable.availableSoon')}</h5>
                                    <Timer date={assetViewModel.InitialSaleDate} onTimeEnd={onPrivateSellTimerEnd} onTick={onPrivateSellTick} />
                                </>}
                            <p className='countdown-top-section__subtitle'>{i18next.t('Assetprofilepage.topUp.detail.msg')}</p>
                            {generateInfoSentence()}
                            <div className='private-sell__btn-section'>
                                <div className='notificationUi-block auto-margin'>
                                    {isSaleStartingInLessThan3Min
                                        ? <ReloadAccountBlock />
                                        : <NotificationUi
                                            assetId={assetViewModel.AssetId}
                                            child={props.renderNotificationUIButton}
                                        />
                                    }
                                </div>
                            </div>
                            {!assetViewModel.IsFunCategory && props.getYtBanner()}
                        </div>
                    </div>
                </div>
            )}

            {withCustomProfil && isPrimaryMarketWillStart && <div style={{ display: "none" }}> <Timer date={assetViewModel.InitialSaleDate} onTimeEnd={onPrivateSellTimerEnd} onTick={onPrivateSellTick} /></div>}
            {canDisplayInstruction && (
                <div className={assetViewModel.Description && 'instruction-wrapper'}>
                    <Instructions buttonText={i18next.t('instructions.cta.why-bet')} managementModal={props.managementModal} />
                </div>
            )}
            {Application.getInstance().showGamblingFeature && shouldDisplayBottomBuyButton &&
                <AssetBuySellSection assetViewModel={assetViewModel} isSaleStartingInLessThan3Min={isSaleStartingInLessThan3Min} />
            }
            {!firstDepositAchievementVM.FirstDepositCompleted && !assetViewModel.IsFunCategory &&
                <PrimaryButton
                    className='first-deposit-button'
                    type={'button'}
                    textButton={<Trans
                        i18nKey={firstDepositAchievementVM.Achievement.Subtitle.key}
                        components={{ bold: <strong /> }}
                        values={firstDepositAchievementVM.Achievement.Subtitle.values} />} receiveClickEvent={false} disabled={false}                >
                </PrimaryButton>
            }
        </>
    );
};


export default React.memo(PrivateSell);
