import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import AxiosConfig from '../../config/axiosConfig';
import { TOURNAMENT_RESULT_ENDPOINTS } from '../../config/endpoints';
import { HTTP_STATUS_OK } from '../../config/_const';
import { IHttpStrongTypedResponse } from '../../model/IHttpResponse';
import * as TournamentResultActionType from './actionsType';
import ITournamentResult from '../../model/Tournament/ITournamentResult';

export const getTournamentResult = (tournamentId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TournamentResultActionType.TOURNAMENT_RESULT_LOADING });
        const getSubscriptionsResponse: IHttpStrongTypedResponse<ITournamentResult[]> = await AxiosConfig.get(`${TOURNAMENT_RESULT_ENDPOINTS.GET_RESULTS}/${tournamentId}`);
        const responseHttpCode: number = getSubscriptionsResponse.data.status;
        if (responseHttpCode !== HTTP_STATUS_OK)
            throw new Error(getSubscriptionsResponse.data.message);
        return dispatch({
            type: TournamentResultActionType.TOURNAMENT_RESULT_SUCCESS,
            payload: {
                tournamentResults: getSubscriptionsResponse.data.data,
                tournamentId,
            },
        });
    } catch (error) {
        let message = 'Server.users.validateAccount.generic-error';
        if (error instanceof TypeError)
            message = error.message;
        return dispatch({
            type: TournamentResultActionType.TOURNAMENT_RESULT_ERROR,
            payload: {
                message,
            },
        });
    }
};
