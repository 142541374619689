import { RootState } from "model/Redux";
import { useDispatch, useSelector } from "react-redux";
import { closeSlidingPage, openSlidingPage } from "service/sliding-pages/action";

const useSlidingPages = () => {
    const dispatch = useDispatch();
    const { display, Component, verticalSlide } = useSelector((state: RootState) => state.slidingPages)

    const displaySlidingPage = (Component: JSX.Element, verticalSlide: boolean = false) => {
        dispatch(openSlidingPage(Component, verticalSlide));
    }

    const hideSlidingPage = () => {
        dispatch(closeSlidingPage());
    }

    return { isOpen: display, Component, displaySlidingPage, hideSlidingPage, verticalSlide }
}

export default useSlidingPages;