import TrendScoreIcon from "components/icons/TrendScoreIcon";
import ScoreDetails from "components/medium/asset-score-evolution/ScoreDetails/ScoreDetails";
import withAssetScoreEvolution from "hoc/with-asset-score-evolution/withAssetScoreEvolution";
import React from "react";
import { Trans } from "react-i18next";
import IAssetTrendScoreEvolutionProps from "./IAssetTrendScoreEvolutionProps";
import ScoreHistoryGraph from "components/medium/score-history-graph/ScoreHistoryGraph";

const AssetTrendScoreEvolution: React.FC<IAssetTrendScoreEvolutionProps> = ({ asset, assetScoreEvolutionVM }) => {
    return (
        <div className='asset-trend-score'>
            <div className='score-header'>
                <div className='title'>
                    <TrendScoreIcon height={17} width={20} fill="#15182B" />
                    <div className='trend-title'><Trans i18nKey='asset-profil.trend-score.title' /></div>
                </div>
                <div className='content' style={{ background: assetScoreEvolutionVM.BackgroundAccordingScore }}>
                    <TrendScoreIcon height={120} width={120} fill={assetScoreEvolutionVM.BackgroundAccordingScore} className='icon-background' />
                    <span style={{ color: assetScoreEvolutionVM.ColorByTrendScore }} className='score'>{asset.getTrendScoreToDisplay(2)}</span>
                    <div className='status'>
                        <div className="indicator" style={{ background: assetScoreEvolutionVM.ColorByTrendScore }}></div>
                        <span className='text'><Trans i18nKey={assetScoreEvolutionVM.TrendScoreStatus} /></span>
                    </div>
                    <span className='description'><Trans i18nKey='asset-profil.trend-score.description' /></span>
                </div>
                <label className='graph-title'><Trans i18nKey='asset-profil.trend-score.graphTitle' /></label>
            </div>
            <ScoreHistoryGraph asset={asset} />
            <ScoreDetails asset={asset} />
        </div>
    );
};

export default withAssetScoreEvolution(AssetTrendScoreEvolution);