import i18n from "18n";
import { ONBOARDING_PREFIX_URL, onboardingEndpoints } from "config/_const";
import React from "react";
import { useHistory, Link } from "react-router-dom";
import AccountViewModel from "view-model/AccountViewModel/AccountViewModel";
import OnboardingButton from "../onboarding-button/OnboardingButton";

const ResumeOnboardingButton: React.FC<{ auth: AccountViewModel; }> = ({ auth }) => {
    const { listen } = useHistory();
    const [showOnboardingResumeButton, setShowOnboardingResumeButton] = React.useState<boolean>(false);

    React.useEffect(() => {
        const unlisten = listen((location, _action) => {
            if (location.pathname.includes(ONBOARDING_PREFIX_URL)) {
                setShowOnboardingResumeButton(false);
                return;
            }
            setShowOnboardingResumeButton(true);
        });
        return unlisten;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!showOnboardingResumeButton)
        return <></>;
    return (
        <Link to={onboardingEndpoints[auth.OnboardingStep]}>
            <OnboardingButton type={'button'} textButton={i18n.t('onboarding.backtoonboarding')} receiveClickEvent={false} disabled={false} />
        </ Link>
    );
};

export default ResumeOnboardingButton;