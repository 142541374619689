import i18n from '18n';
import Application from 'Application';
import clsx from 'clsx';
import React from 'react';
import { ReactSVG } from 'react-svg';
import './LeagueHeader.scss';
import type LeagueHeaderProps from './LeagueHeaderProps';
import LeagueAccess from './league-access/LeagueAccess';
import Cup from 'components/icons/tournament/league/Cup';

const LeagueHeader: React.FC<LeagueHeaderProps> = ({ league }) => {
    return (
        <div key={league.Name} className={clsx('tournament-league-card')}>

            <div className="tournament-information" style={{ backgroundSize: 'cover', backgroundImage: `url(${league.TournamentLeagueBannerLink})` }}>
                <ReactSVG className="leagueIcon" src={league.IconLink} />
                <p className="title">{league.Name}</p>
                <div className="league-name-background" data-content={`${league.Name}`}></div>
            </div>

            <div className='league-access-container'>
                <LeagueAccess canShowTeamData={false} showMaxSubscriptions={true} tournamentLeague={league} isTeamCompleted={false} />
            </div>

            {Application.getInstance().showGamblingFeature && <div key={league.Name + '-sub'} className={clsx('tournament-league-card-sub')}>
                <div className='vertical-center'><Cup /><p className='annual-rewards purple'>{i18n.t('tournament.leagues.league-prize')} {i18n.t('tournament.information-league.reward.content', { reward: league.PrizePoolToCurrency })}</p></div>
            </div>}
        </div>
    );
};

export default LeagueHeader;
