import { COLOR, TOKEN_INFORMATION_CONFIG } from 'config/_const';
import { MarketEnum } from 'config/_enums';
import Color from 'view-model/Shared/Color';

const LOW_TOKEN_VAL = 500;
const AVERAGE_TOKEN_VAL = 1500;

export default class TokenAvailableInformationViewModel {
    private tokensBuyable: number;
    private maxTokensForAnimation: number;
    private remainingTokensToSell: number;
    private totalTokensInCirculation: number;
    private market: MarketEnum;

    constructor(tokensBuyable: number, remainingTokensToSell: number, totalTokensInCirculation: number, market: MarketEnum = 1) {
        this.tokensBuyable = tokensBuyable;
        this.remainingTokensToSell = remainingTokensToSell;
        this.totalTokensInCirculation = totalTokensInCirculation;
        this.maxTokensForAnimation = Math.ceil(totalTokensInCirculation / TOKEN_INFORMATION_CONFIG.RATIO_TO_ACTIVATE_COLOR_DEGRADATION_OF_INDICATOR);
        this.market = market;
    }

    public getColorOfIndicator(): string {
        const tokenIndicatorColor: number = this.tokensBuyable < this.maxTokensForAnimation ? (this.tokensBuyable * 100) / this.maxTokensForAnimation : 100;
        return Color.getColorByInputRatio(tokenIndicatorColor);
    }

    public getColorIndicatorForAvailableToken(opacity: number = 1): string {
        if(this.market === MarketEnum.PRIMARY)
            return this.getColorIndicatorForAvailableTokenPrimaryMarket(opacity);
        return this.getColorIndicatorForAvailableTokenSecondaryMarket(opacity);
    }

    private getColorIndicatorForAvailableTokenPrimaryMarket(opacity: number): string {
        if (this.remainingTokensToSell / this.totalTokensInCirculation < 1/3) return Color.rgbToRgba(COLOR.RED, opacity);
        else if (this.remainingTokensToSell / this.totalTokensInCirculation < 2/3) return Color.rgbToRgba(COLOR.ORANGE, opacity);
        return Color.rgbToRgba(COLOR.LIMEGREEN, opacity);
    }

    private getColorIndicatorForAvailableTokenSecondaryMarket(opacity: number): string {
        if (this.tokensBuyable < LOW_TOKEN_VAL) return Color.rgbToRgba(COLOR.RED, opacity);
        else if (this.tokensBuyable < AVERAGE_TOKEN_VAL) return Color.rgbToRgba(COLOR.ORANGE, opacity);
        return Color.rgbToRgba(COLOR.LIMEGREEN, opacity);
    }
}
