import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Download } from '@mui/icons-material';
import GenericTable from 'components/medium/generic-table/GenericTable';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import SecondaryButton from 'components/small/buttons/secondary-button/SecondaryButton';
import { WITHDRAW_STATUS } from 'config/_const';
import { WithdrawalRequestsHeadCell } from 'config/tableConfig';
import i18next from 'i18next';
import IWithdraw from 'model/IWithdraw';
import Row from 'model/Table/Row';
import { TWithdraws } from 'model/Table/TableHeader';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { fetchAllWithdrawRequests } from 'service/withdraws/actions';
import WithdrawalRequestPageState from './WithdrawRequestPageState';
import './WithdrawRequestsPage.scss';
import WithdrawalRequestPageProps from './WithdrawRequestsPageProps';
import { generateDownload } from './local-withdrawRequest-utils/generateDownloadCsv';

class WithdrawRequestsPage extends React.Component<WithdrawalRequestPageProps, WithdrawalRequestPageState> {

    constructor(props: WithdrawalRequestPageProps) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    componentWillMount = () => {
        this.props.fetchAllWithdrawRequests(1, undefined, WITHDRAW_STATUS.OPEN, 'userId');
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    openModal = () => {
        this.setState({ showModal: true });
    }

    colorByWithdrawStatus = (status: string) => {
        switch (status) {
            case 'REFUSED':
                return '#ff6659';
            case 'CONFIRMED':
                return '#6abf69';
            default:
                return '#fbc02d';
        }
    }

    fetchWithdrawByPagination = (page: number) => {
        this.props.fetchAllWithdrawRequests(page + 1, undefined, 'OPEN', 'userId');
    }

    render = () => {
        const {
            withdraws,
        } = this.props;
        const FILENAME = "withdraw-request";

        const withdrawsRows: Row<TWithdraws>[] = [];
        const withdrawsDescSort: IWithdraw[] = withdraws.data.sort((a: IWithdraw, b: IWithdraw) => new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime() ? 0 : -1);

        for (const withdraw of withdrawsDescSort) {
            const rowWithdraw: TWithdraws = {
                creditsToWithdraw: { data: withdraw.creditsToWithdraw, textCellColor: 'white' },
                userId: { data: `${withdraw.userId && withdraw.userId.lastName ? withdraw.userId.lastName : 'N/A'} ${withdraw.userId && withdraw.userId.firstName ? withdraw.userId.firstName : 'N/A'}`, textCellColor: 'white' },
                statusUser: { data: `${withdraw.userId ? withdraw.userId.isConfirmed : 'N/A'}`, textCellColor: 'white' },
                status: { data: withdraw.status, textCellColor: this.colorByWithdrawStatus(withdraw.status) },
                show: {
                    data: (
                        <Link to={`/withdraw-requests/${withdraw._id}`}>
                            <SecondaryButton
                                disabled={false}
                                receiveClickEvent={false}
                                textButton={'Show'}
                                type="button"
                                handleClick={() => undefined} />
                        </Link>
                    ),
                },
                currency: { data: withdraw.currency, textCellColor: 'white' },
                typeOfForm: { data: withdraw.type, textCellColor: 'white' },
                email: { data: `${withdraw.userId ? withdraw.userId.email : 'N/A'}`, textCellColor: 'white' },
                phone: { data: `${withdraw.userId ? withdraw.userId.phone : 'N/A'}`, textCellColor: 'white' },
                createdDate: { data: withdraw?.createdAt ? moment(withdraw.createdAt).format("YYYY-MM-DD") : 'N/A', textCellColor: "white" },
            };

            withdrawsRows.push({
                cells: rowWithdraw,
                id: withdraw._id,
            });
        }
        const handleDownloadCSV = () => {
            if (WithdrawalRequestsHeadCell.length && withdrawsRows.length)
                generateDownload(FILENAME, WithdrawalRequestsHeadCell, withdrawsRows)
        }

        return (
            <div className="transfer-page">
                {(withdraws && withdraws.data.length) &&
                    <div className='download-row'>
                        <PrimaryButton
                            disabled={false}
                            receiveClickEvent={false}
                            handleClick={() => handleDownloadCSV()}
                            type="button"
                            className={'download-btn'}
                            textButton={<div className='text-btn'>
                                <Download className='download-icon' />
                                {i18next.t('superAdminFeesDownload.download')}
                            </div>
                            }
                        />
                    </div>
                }
                {((withdraws && !withdraws.data.length)) && (
                    <h1>No withdraws for the moment</h1>
                )}

                <div className="transfer-container card-style">
                    <GenericTable
                        loadMoreData={(page: number) => this.fetchWithdrawByPagination(page)}
                        rows={withdrawsRows}
                        headerTable={WithdrawalRequestsHeadCell}
                        enabledCheckbox={false}
                        enableCreateButton={false}
                        tableHeight={'100vh'} />
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state: WithdrawalRequestPageProps) => ({
    withdraws: state.withdraws,
});

export default compose(
    connect(mapStateToProps, {
        fetchAllWithdrawRequests,
    }),
)(WithdrawRequestsPage);
