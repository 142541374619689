import { HTTP_STATUS_INTERNAL_ERROR, HTTP_STATUS_OK } from "config/_const";
import AxiosConfig from "config/axiosConfig";
import { SPORTS_ENDPOINT, TEAMS_PARAMS } from "config/endpoints";
import { showNotification } from "service/notification/action";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { sportsDataApiBaseUrl } from "config/sportsDataConfig";
import { SPORTS_TEAMS_ACTION } from "./actionTypes";
import i18next from "i18next";

export const getAllSportsTeamsByCountry = (country:string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: SPORTS_TEAMS_ACTION.LOADING });
        const route = `${sportsDataApiBaseUrl}${SPORTS_ENDPOINT.GET_TEAMS}?${TEAMS_PARAMS.COUNTRY}=${country}`;
        const response = await AxiosConfig.get(route);
        if (!response) {
            showNotification(dispatch, 'Sports : Server is offline, please retry later', HTTP_STATUS_INTERNAL_ERROR);
            return dispatch({
                type: SPORTS_TEAMS_ACTION.ERROR,
            });
        }
        if (response.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data, response.status);
            return dispatch({
                type: SPORTS_TEAMS_ACTION.ERROR,
            });
        }
        dispatch({
            type: SPORTS_TEAMS_ACTION.SUCCESS,
            payload: response.data
        });
    } catch (error) {
        console.log('DataApi error: ', error);
        showNotification(dispatch, 'Sports :'+i18next.t('ERRORS.GENERIC'), HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: SPORTS_TEAMS_ACTION.ERROR,
        });
    }
};

