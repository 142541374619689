import { ReduxRootState, RootState } from 'model/Redux';
import ITournamentWin, { ITournamentWinReducer } from 'model/Tournament/ITournamentWin';
import { Selector, createSelector } from 'reselect';

export const tournamentWinsSelector: Selector<RootState, ReduxRootState<ITournamentWinReducer>> = (state: RootState) => state.tournamentWins;

export const tournamentWinsByUserIdSelector = createSelector(
    tournamentWinsSelector,
    (_: RootState, userId: string) => userId,
    (selector: ReduxRootState<ITournamentWinReducer>, userId: string) => {
        if (selector.data.allWins === undefined || selector.data.allWins.length === 0)
            return undefined;
        return selector.data.allWins.filter((win: ITournamentWin) => win._id === userId)[0];
    },
);
