import { withTranslation, WithTranslation } from "react-i18next";
import React from 'react';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import Success from 'assets/images/modal-tournament-confirmation/success.gif';
import './TournamentConfirmationModalOnBoarding.scss';

interface ITournamentConfirmationModalOnBoardingProps extends WithTranslation {
    onButtonClick: () => void;
}

const TournamentConfirmationModalOnBoarding: React.FC<ITournamentConfirmationModalOnBoardingProps> = ({ t: translate, onButtonClick }: ITournamentConfirmationModalOnBoardingProps) => {
    return (
        <div className='container-modal-tournament-confirmation'>
            <div className='container-top-on-the-left-img'><img src={Success} alt='Congratulations' /></div>
            <h3>{translate('onboarding.tournament.confirmation.modal.title')}</h3>
            <p className='first-sentence'>{translate('onboarding.tournament.confirmation.modal.first-sentence')}</p>
            <p className='second-sentence'>{translate('onboarding.tournament.confirmation.modal.second-sentence')}</p>
            <div className='container-notice'>
                <p className='notice-sentence'><span>{translate('onboarding.tournament.confirmation.modal.notice-sentence-1')}</span>{translate('onboarding.tournament.confirmation.modal.notice-sentence-2')}</p>
            </div>
            <PrimaryButton
                textButton={translate('next')}
                type={'button'}
                receiveClickEvent={false}
                handleClick={onButtonClick}
                disabled={false}
                className={'tournament-confirmation-modal-on-boarding-btn'} />
        </div>
    )
};

export default withTranslation()(TournamentConfirmationModalOnBoarding);
