import * as React from 'react';
import { withTranslation } from 'react-i18next';
import './FunAssetModal.scss';
import IFunAssetModalProps from './IFunAssetModalProps';
import FunAssetLogo from 'assets/images/fun-assets/fun-cards-logo.webp';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import { LOCAL_STORAGE_KNOWN_KEYS } from 'config/_const';

const FunAssetModal: React.FC<IFunAssetModalProps> = ({ t: translate, onValidation }) => {

    const [isChecked, setIsChecked] = React.useState<boolean>(localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.FUN_ASSET_MODAL_SEEN) === 'true');

    const handleValidation = () => {
        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.FUN_ASSET_MODAL_SEEN, 'true');
        onValidation();
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };

    return <div className='fun-asset-modal'>
        <div className="container-image-fun-cards">
            <img src={FunAssetLogo} className="fun-cards-logo" alt="trendex fun cards" />
        </div>
        <p className="title-modal">
            {translate('fun.title.modal.terms.of.use')}
        </p>
        <div className="container-description">
            <p>
                {translate('fun.description.terms.of.use.1')}
            </p>
            <p>
                {translate('fun.description.terms.of.use.2')}
            </p>
        </div>
        <div className="container-terms-of-use">
            <p>
                {translate('fun.rules.terms.of.use')}
            </p>
            <div className="checkbox-container">
                <label className='label-checkbox'>
                    <input
                        name={'checkbox'}
                        type="checkbox"
                        className='checkbox'
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <span>{translate('fun.checkbox.terms.of.use')}</span>
                </label>
            </div>
        </div>
        <PrimaryButton
            className='confirm-button-fun-assets'
            handleClick={() => handleValidation()}
            textButton={translate('fun.btn.terms.of.use')}
            receiveClickEvent={false}
            type={'button'}
            disabled={!isChecked} />
    </div>
};

export default withTranslation()(FunAssetModal);
