import React, { CSSProperties } from 'react';
import './CircularLoader.scss';
import './LoaderLocal.scss';
import Utils from '../../utils/Utils';
import PrimaryButton from './buttons/primary-button/PrimaryButton';
import WhiteLogo from '../../assets/images/white-trendex-new-loader.gif';
import Application from 'Application';
import clsx from 'clsx';

interface Props {
    loaderMessage: string;
    loaderLocal?: boolean;
    localLoaderStyle?: CSSProperties;
    localTextStyle?: CSSProperties;
    buttonText?: string;
    showButton?: boolean;
    buttonAction?: any;
    doNotSplitMessage?: boolean;
    localContainer?: boolean;
}

const CircularIndeterminate: React.FC<Props> = (props) => {

    let textLoader = props.loaderMessage;
    let afterTextLoader = '';
    if (textLoader.length > 15 && !props.doNotSplitMessage) {
        afterTextLoader = textLoader.substr(17);
        textLoader = textLoader.substr(0, 16);
    }

    return (
        <>
            {props.loaderLocal &&
                <div className="local-loader-container">
                    <div className="loader" style={props.localLoaderStyle}></div>
                    {!Utils.isEmpty(textLoader) && (<span style={props.localTextStyle}>{textLoader}</span>)}

                    {!Utils.isEmpty(afterTextLoader) && (
                        <>
                            <br></br>
                            <br></br>
                            {afterTextLoader}
                        </>
                    )}
                </div>
            }
            {!props.loaderLocal &&
                <div id="container-loader" className={clsx({ 'container-absolute': props.localContainer })}>
                    <div className="global-loader-inner-container">
                        {Application.getInstance().UseNewLogoLoader ? (
                            <div className='new-loader'>
                                <img src={WhiteLogo} />
                            </div>
                        ) : (
                            <div className="container-loader-rocket">
                                <div className="body">
                                    <span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                    <div className="base">
                                        <span></span>
                                        <div className="face"></div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <p className="loader-text">
                            {textLoader}
                            {afterTextLoader}
                        </p>
                        {
                            props.showButton && <div style={{ width: 'max-content' }}>
                                <PrimaryButton
                                    receiveClickEvent={false}
                                    disabled={false}
                                    type="button"
                                    handleClick={props.buttonAction}
                                    textButton={props.buttonText ?? ''} />
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default React.memo(CircularIndeterminate);
