import { Icon, Tab } from '@material-ui/core';
import { ALL_CATEGORIES, AssetCategoryOrder } from 'config/_const';
import useDragScroll from 'hooks/useDragScroll';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import React, { useRef, useState } from 'react';
import CategoriesIconsMap from '../../components/icons/categories/CategoriesIconsMap';
import IUseFilterTabs from './IUseFilterTabs';

const useFilterTabs = (): IUseFilterTabs => {
    const [currentTab, setCurrentTab] = useState(ALL_CATEGORIES);
    const { isSwipping } = useDragScroll({ selector: '.top-filter .MuiTabs-flexContainer', momentumVelocity: 0.9 });
    const ref = useRef(null);

    const handleTabsChange = (_event: React.ChangeEvent<Record<string, unknown>>, selectedCategory: string): void => {
        if (!isSwipping)
            setCurrentTab(selectedCategory);
    };

    const transformStringToIconName = (categoryName: string) => {
        if (CategoriesIconsMap[categoryName])
            return CategoriesIconsMap[categoryName];

        const splitByCase: string[] = categoryName.split(/(?=[A-Z])/);
        let iconName: string = '';
        for (let i = 0; i < splitByCase.length; i++) {
            const next: string = splitByCase[i].charAt(0).toLowerCase() + splitByCase[i].slice(1);
            iconName += next;
            if (i < splitByCase.length - 1) {
                iconName += '_';
            }
        }
        return <Icon className="icon-small"> {iconName} </Icon>;
    };

    const renderTab = (category: AssetCategoryInterface) => {
        const Label = (
            <React.Fragment >
                <Icon>
                    {transformStringToIconName(category.icon ? category.icon.capitalizeFirstLetter() : category.name)}
                </Icon>
                {category.name}
            </React.Fragment>
        );
        return <Tab
            style={{ order: AssetCategoryOrder[category.name] }}
            value={category._id}
            key={category._id}
            classes={{
                root: 'asset-tab',
                selected: 'asset-tab-selected',
            }}
            label={Label} />;
    };

    const resetTab = () => {
        setCurrentTab(ALL_CATEGORIES);
    };

    return { currentTab, ref, handleTabsChange, transformStringToIconName, renderTab, resetTab };
};

export default useFilterTabs;
