import Application from 'Application';
import React from 'react';
import { withTranslation } from 'react-i18next';
import BuyPacksButton from '../buy-packs-button/BuyPacksButton';
import BuyPacksButtonDisclaimerProps from './BuyPacksButtonDisclaimerProps';
import './style.scss';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import useUserLeague from 'hooks/use-user-league/useUserLeague';

const BuyPacksButtonDisclaimer: React.FC<BuyPacksButtonDisclaimerProps> = ({ t: translate, assetViewModel }) => {
    const { accountViewModel }: IUseAccount = useAccount();
    const { userLeagueViewModel } = useUserLeague();

    const userIsWhitelisted: boolean = React.useMemo(() => {
        return accountViewModel.IsUserWhiteListed() || userLeagueViewModel.LeagueAttribution.isWhitelisted();
    }, [accountViewModel, userLeagueViewModel]);

    return (
        <div className="buy-packs-button-disclaimer">
            <div className="disclaimer">
                {!assetViewModel.isCustomBenzemaProfilPageBeforePublicSales() &&
                    <>
                        <h6 className="title">{translate('buy.pack.disclaimer.title', { numberOfPacksAvailable: Application.getInstance().PacksConfiguration.numberOfPacksAvailable })}</h6>
                        <ul>
                            <li>{translate('buy.pack.disclaimer.offer1', { amountOfTokens: Application.getInstance().PacksConfiguration.tokens })}</li>
                            <li>{translate('buy.pack.disclaimer.offer2')}</li>
                            <li>{translate('buy.pack.disclaimer.offer3')}</li>
                        </ul>
                    </>
                }
                {assetViewModel.isCustomBenzemaProfilPageBeforePublicSales() &&
                    <>
                        <h6 className="title">{translate('benzema.custom-profil.buy-packs.disclaimer.title')}</h6>
                        <ul>
                            <li>{translate('benzema.custom-profil.buy-packs.disclaimer.list.3')}</li>
                            <li>{translate('benzema.custom-profil.buy-packs.disclaimer.list.1')}</li>
                            <li>{translate('benzema.custom-profil.buy-packs.disclaimer.list.2')}</li>
                        </ul>
                    </>
                }
            </div>

            {!userIsWhitelisted && <div className="buy-pack-container">
                {!assetViewModel.isEarlyBirdEnable() && <div className="disclaimer-not-whitelist">
                    <h6 className="title">{translate('pre.sales.buy.pack.title')}</h6>
                    <p className="no-margin info">{translate('pre.sales.buy.pack.subtitle', { hours: assetViewModel.EarlyBirdDate.get('hours') })}</p>
                </div>}
            </div>}

            <BuyPacksButton assetViewModel={assetViewModel} />
        </div>
    )
};

export default withTranslation()(React.memo(BuyPacksButtonDisclaimer));
