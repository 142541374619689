import React from "react";
import "./HeaderRegister.scss";
import { Trans } from "react-i18next";
import LogoBlueTrendex from "../logo-blue-trendex/LogoBlueTrendex";
import BannerVideoSlide from "page/Top50/top50Carousel/slides/VideoSlide";
import { FiveTalentCardsFront } from "data/SignUpInImages/authentificationImages";
import { FourTalentCardsFront } from "data/SignUpInImages/authentificationImages";
import IHeaderRegisterProps from "./IHeaderRegisterProps";
import { NUMBER_OF_FREE_CARDS, NUMBER_OF_FREE_CARDS_ONBOARDING } from 'config/_const';
import Application from "Application";

export const HeaderRegister = ({ videoId, bannerImageVideo, setIsVideoPlay, isVideoPlay, title, subtitle }: IHeaderRegisterProps): JSX.Element => {
    const nbTokens = !Application.getInstance().OnBoardingV2 ? NUMBER_OF_FREE_CARDS : NUMBER_OF_FREE_CARDS_ONBOARDING;
    
    return (
        <div className="container-header-register">
            <div className="container-logo-blue">
                <LogoBlueTrendex title={title} />
            </div>
            <div className="container-video-signup">
                <BannerVideoSlide
                    id="123131"
                    isVideo
                    setCarouselAutoPlay={() => false}
                    videoId={videoId}
                    bannerImage={bannerImageVideo}
                    showText={false}
                    isNotOnCarouselView={false}
                    showCenterPlayBtn={true}
                    showWatchNowPlayBtn={false}
                    showPlayIconInWatchNow={true}
                    onVideoStart={setIsVideoPlay}
                />
                {!isVideoPlay &&
                    <div className="container-img-cards-talents">
                        <img src={Application.getInstance().OnBoardingV2 ? FiveTalentCardsFront : FourTalentCardsFront} className='cards-team-signup' />
                        <div className="steps-container-message">
                            <h2>
                                <Trans
                                    i18nKey={subtitle}
                                    values={{ nbTokens: nbTokens }}
                                    components={{ b: <strong /> }}
                                />
                            </h2>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default HeaderRegister;
