import { isEmpty } from 'lodash';
import { RootState } from 'model/Redux';
import ITeamAsset from 'model/Team/ITeamAsset';
import ITournamentEvolution from 'model/Tournament/ITournamentEvolution';
import ITournamentResult, { ITournamentResultReducer } from 'model/Tournament/ITournamentResult';
import { createSelector, Selector } from 'reselect';
import { allAssetsSelector } from 'service/assets/selectors';
import { userIdSelector } from 'service/auth/selectors';
import { extractAssetsEvolution } from 'service/score-history/helper';
import { selectEvolutionsCollection } from 'service/score-history/selectors';
import { extractTournamentId } from 'service/tournament/selectors';
import { tournamentRewardsSelector } from 'service/tournamentReward/selector';
import { Collection } from 'utils/types';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import TournamentResultViewModel from 'view-model/Tournament/TournamentResultViewModel/TournamentResultViewModel';
import TournamentRewardViewModel from 'view-model/Tournament/TournamentRewardViewModel/TournamentRewardViewModel';
import { sortResultsByPlace } from './sorters';

const tournamentResultsDataSelector: Selector<RootState, ITournamentResultReducer> = (state: RootState) => state.tournamentResult.data;
const tournamentResultsDataLoadingSelector: Selector<RootState, boolean> = (state: RootState) => state.tournamentResult.loading;
const tournamentResultsByTournamentId = (state: RootState, tournamentId: string) => state.tournamentResult.data[tournamentId];

export const getTournamentResultsEvolutionsByAssetIdsSelector = createSelector(
    selectEvolutionsCollection,
    allAssetsSelector,
    (state: RootState, tournamentId: string) => getAssetIdsFromResultsByTournamentIdSelector(state, tournamentId),
    (evolutionCollection: Collection<ITournamentEvolution>, allAssets: AssetViewModel[], assetIds: string[]) => {
        return extractAssetsEvolution(evolutionCollection, allAssets, assetIds);
    },
);

export const tournamentResultsSelector = createSelector(
    extractTournamentId,
    tournamentResultsDataSelector,
    (state: RootState, tournamentId: string, _: ITournamentResultReducer) => tournamentRewardsSelector(state, tournamentId),
    (tournamentId: string, tournamentResults: ITournamentResultReducer, tournamentRewards: TournamentRewardViewModel[]) => {
        if (!tournamentResults[tournamentId] || tournamentResults[tournamentId].length === 0)
            return [];

        return sortResultsByPlace(tournamentResults[tournamentId])
            .map((tournamentResult: ITournamentResult) => {
                return new TournamentResultViewModel({
                    result: tournamentResult,
                    reward: tournamentRewards.find((reward: TournamentRewardViewModel) => reward && reward.User.toString() === tournamentResult.user._id) ?? new TournamentRewardViewModel({})
                });
            });
    },
);

export const tournamentResultsLoadingSelector = createSelector<RootState, boolean, boolean>(
    [tournamentResultsDataLoadingSelector],
    (loading: boolean) => {
        return loading;
    },
);

export const getAssetIdsFromResultsByTournamentIdSelector = createSelector(
    tournamentResultsByTournamentId,
    (results: ITournamentResult[]) => {
        if (isEmpty(results))
            return [];
        const assetIdsIntoTournament: string[] = results.reduce((acc: string[], result: ITournamentResult) => acc.concat(result.team.teamAssets.map((teamAsset: ITeamAsset) => teamAsset.asset)), []);
        return Array.from(new Set(assetIdsIntoTournament));
    },
);

export const getUserResultByTournamentIdAndUserIdSelector = createSelector(
    tournamentResultsByTournamentId,
    userIdSelector,
    (results: ITournamentResult[], userId: string) => {
        return isEmpty(results) ? undefined : results.find(res => res.user._id.toString() === userId);
    },
);