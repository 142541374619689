import { DeviceInfo } from '@capacitor/device';
import * as Sentry from "@sentry/react";
import Application from 'Application';
import { SocketContextProvider } from 'context/WebSocket/socketContext';
import { useDevice } from 'hooks/useDevice';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { FallbackProps, withErrorBoundary } from "react-error-boundary";
import FacebookReactPixel from 'react-facebook-pixel';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setDevice } from 'service/app-config/actions';
import CrispService from 'service/crisp/CrispService';
import IntercomService from 'service/intercom/IntercomService';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import IAppProps from './IAppProps';
import GlobalError from './components/error/global-error/GlobalError';
import { LOCAL_STORAGE_KNOWN_KEYS, LOCAL_STORAGE_KNOWN_VALUES } from './config/_const';
import RootContainer from './container/RootContainer';
import store from './service/store';
import Utils from './utils/Utils';
import WhatsAppService from 'service/whatsapp/WhatsAppService';

const isProductionEnvironment = Utils.isProdEnvironment();
const pixelFB = isProductionEnvironment ? '721970586528351' : '1433533454225820';
const SnapchatReactPixel = require('react-snapchat-pixel');
TagManagerWrapper.initialize('GTM-TCJ2CWM');
FacebookReactPixel.init(pixelFB, undefined, { debug: !isProductionEnvironment, autoConfig: true });
SnapchatReactPixel.default.init('4727296b-8b5b-47ca-83fc-b6aff98267f3', null, {
    debug: !isProductionEnvironment,
});


CrispService.init();
WhatsAppService.init();

if (isProductionEnvironment)
    IntercomService.getInstance().boot();

mixpanel.init('52ce422d5e4151550ceaea5d718f9a2b', { debug: true });

Sentry.init({
    dsn: "https://34574e07295e488090e1dd03488c9de2@o4505188461314048.ingest.sentry.io/4505188468916224",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.5 : 1.0,
    replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 100,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    allowUrls: ['http://localhost:3000', 'https://app.trendex.vip'],
});

(() => {
    if (window.location.href.includes('/beta-app'))
        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.BETA_APP, LOCAL_STORAGE_KNOWN_VALUES.PLAY_TO_EARN);
})();

const App: React.FC<IAppProps> = ({ device }: { device: DeviceInfo | null }) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        const start = async (): Promise<void> => {
            const appTitle = String(Application.getInstance().title);
            const manifestDir = String(Application.getInstance().manifestPath);
            Utils.setAttributes({ appTitle, directory: manifestDir });
            await Utils.getForexRates();
        };
        start();
    }, []);

    React.useEffect(() => {
        if (device) {
            dispatch(setDevice(device));
        }
    }, [device, dispatch]);

    return (
        <BrowserRouter>
            <RootContainer />
        </BrowserRouter>
    );
};

const withProviders = (Component: React.FC<{ device: DeviceInfo | null }>) => {
    const MemoizedApplication = React.memo(Component);
    const Wrapper: React.FC = () => {
        const operatingDevice = useDevice();

        return (
            <Provider store={store}>
                <SocketContextProvider>
                    <MemoizedApplication device={operatingDevice} />
                </SocketContextProvider>
            </Provider>
        );
    };

    return React.memo(Wrapper);
};

export default withProviders(withErrorBoundary(App, {
    FallbackComponent: (_: FallbackProps) => <GlobalError />,
    onError: (error: Error) => Sentry.captureException(error)
}));
