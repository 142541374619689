import React from 'react';
import CircularIndeterminate from '../CircularLoader';
import LoadingModalProps from './LoadingModalProps';
import i18next from 'i18next';

const LoadingModal: React.FunctionComponent<LoadingModalProps> = (props: LoadingModalProps) => {
    return (
        <div>
            {props.message !== '' && <h3 style={{ marginBottom: '25%' }}>{props.message}</h3>}
            <CircularIndeterminate
                loaderLocal={props.loaderLocal || false}
                loaderMessage={i18next.t('genericloading')}
            />
        </div>
    );
};

LoadingModal.defaultProps = ({
    message: '',
});

export default LoadingModal;
