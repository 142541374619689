
import React from "react";
import './PendingCard.scss';
import IPendingCardProps from "./PendingCardProps";
import CircularLoader from "components/small/CircularLoader";
import Close from "components/icons/Close";
import Clock from "components/icons/Clock";

const PendingCard: React.FC<IPendingCardProps> = ({ pendingCardViewModel, handleInternalCancel, isCancelRequestLoading, renderTopImgIcon, renderCardImg }) =>
    <article className="pending-card">
        <div className="pending-card__date">
            <span>
                {pendingCardViewModel.DateInDDMMYYY}
            </span>
            {pendingCardViewModel.TimeInHHMMSS}
        </div>
        <div className='pending-card__body'>
            <div className="pending_card__avatar">
                {renderCardImg()}
                <div className="avatar-icon">{renderTopImgIcon()}</div>
            </div>
            <div className="pending-card__detail">
                <div className='detail-topic d-flex'>
                    <Clock className='pr-1' />
                    {pendingCardViewModel.CardTopic}
                </div>
                <span className="detail-description">
                    {pendingCardViewModel.CardMsg}
                </span>
                <div className='pending-card__summary'>
                    <span>{pendingCardViewModel.CardSummaryOne}</span>
                    <span>{pendingCardViewModel.CardSummaryTwo}</span>
                    <span>{pendingCardViewModel.CardSummaryThree}</span>
                </div >
            </div>
        </div>
        {Boolean(pendingCardViewModel.CardCancelText) && (
            <div className="pending-card__action">
                <button className="pending-card__cancel-button" onClick={handleInternalCancel}>
                    {isCancelRequestLoading ?
                        <CircularLoader
                            loaderMessage=""
                            loaderLocal={true}
                            localLoaderStyle={{ height: '25px', width: '25px', margin: 0 }}
                        /> : <Close className="cancel-order-icon" />}
                    {pendingCardViewModel.CardCancelText}
                </button>
            </div>
        )}
    </article>;
export default PendingCard;