import Credits from 'components/icons/Credits'
import Gifts from 'components/icons/Gifts'
import React from 'react'
import { withTranslation } from 'react-i18next'
import IPaymentCreditGiftBreakDown from './IPaymentCreditBreakDown'
import ICreditBreakDownVMBuilder from 'view-model/CreditBreakDownViewModel/ICreditBreakDownViewModel'
import CreditBreakDownViewModel from 'view-model/CreditBreakDownViewModel/CreditBreakDownVIewModel'

const PaymentCreditBreakDown = ({ t: translate, creditsAmount, virtualCreditsAmount, selectedPaymentType, amountToBePaid }: IPaymentCreditGiftBreakDown) => {
    const creditBreakDownVMBuilder: ICreditBreakDownVMBuilder = {
        creditsAmount: creditsAmount,
        virtualCreditsAmount: virtualCreditsAmount,
        selectedPaymentType: selectedPaymentType,
        amountToBePaid: amountToBePaid,
    }
    const creditBreakDownVM = new CreditBreakDownViewModel(creditBreakDownVMBuilder)

    return (
        <div className='payment-credit-gift-breakdown credit-info-wrapper'>
            {
                (creditBreakDownVM.IsVirtualCredisEmptyOrSelectedPaymentNotCredit)
                    ? <span>
                        {translate("buyAsset.available")} : {creditBreakDownVM.TotalAvailableAmountFormatted}
                    </span>
                    : <>
                        <div className='info'>
                            <span>
                                {translate("gifts")}: {creditBreakDownVM.CreditRepartitionFormatted.giftAmount}
                            </span>
                            <Gifts />
                        </div>
                        <div className='info'>
                            <span>
                                {translate("credits")}: {creditBreakDownVM.CreditRepartitionFormatted.credits}
                            </span>
                            <Credits />
                        </div>
                    </>
            }
        </div>
    )
}

export default withTranslation()(React.memo(PaymentCreditBreakDown));