import * as React from 'react';
import { withTranslation } from 'react-i18next';
import './ScoreBoosterModal.scss';
import IScoreBoosterModalProps from './IScoreBoosterModalProps';
import ScoreBoosterDetailBlock from 'components/small/score-booster/score-booster-detail-block/ScoreBoosterDetailBlock';
import Application from 'Application';

const ScoreBoosterModal: React.FC<IScoreBoosterModalProps> = ({ t: translate, onValidation }) => {
    return <div className='score-booster-modal'>
        <h3 className='score-booster__title'>
            {translate('score.booster-title')}
        </h3>
        <p className='score-booster__msg'>
            {translate('score.booster-msg', { boostPoint: Application.getInstance().MaxScoreBoosterPoints })}
        </p>
        <div className='score-booster__area'>
            <h3 className='score-booster__area-title'>
                {translate('score.booster-btn-title')}
            </h3>
            <div className='score-booster__area-btn'>
                <ScoreBoosterDetailBlock canShowTitle={false} closeBoosterModal={onValidation} />
            </div>
        </div>
    </div>;
};

export default withTranslation()(ScoreBoosterModal);
