import React from 'react';
import ShirtIcon from '../../icons/BenzemaClub/ShirtIcon';
import Divider from '../divider/Divider';
import BenzemaConfirmBuyProps from './BenzemaConfirmBuyProps';
import BenzemaHeader from '../../../assets/images/benzema-modal.png';
import TravelImg from '../../../assets/images/travel.png';
import LeaguesImg from '../../../assets/images/leagues.png';
import './style.scss';
import PrimaryButton from '../buttons/primary-button/PrimaryButton';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IUseDialog from 'hooks/use-dialog/IUseDialog';
import useDialog from 'hooks/use-dialog/useDialog';
import Application from '../../../Application';

const BenzemaConfirmBuy: React.FC<BenzemaConfirmBuyProps> = ({ totalAmount, totalTokens, t: translate, fromPack }) => {
    const { closeDialog }: IUseDialog = useDialog();
    return (
        <div className="benzema-confirm-buy">

            <div className="img-container">
                <img src={BenzemaHeader} />
            </div>
            <h3 className="title">{translate('pack.modal.title')}</h3>
            <p className="disclaimer">{translate(fromPack ? 'benzema.modal.pack.disclaimer1' : 'pack.modal.info1', { tokens: totalTokens, amount: totalAmount.toCurrency() })}</p>
            {fromPack && <p className="disclaimer">{translate('benzema.modal.pack.disclaimer2')}</p>}
            <p className="disclaimer">{translate('pack.modal.info2')}</p>

            <div className="container-cases">
                <div className="flex case">
                    <div className="icon-container">
                        <ShirtIcon color="#606373" />
                    </div>
                    <p className="no-margin offer">{translate('pack.modal.offer1')}</p>
                </div>

                <Divider size="86" background='#60637342' />

                <div className="flex case">
                    <div className="icon-container">
                        <img src={TravelImg} />
                    </div>
                    <p className="no-margin offer">{translate('pack.modal.offer2')}</p>
                </div>

                <Divider size="86" background='#60637342' />

                <div className="flex case">
                    <div className="icon-container">
                        <img src={LeaguesImg} />
                    </div>
                    <p className="no-margin offer">{translate('pack.modal.offer3')}</p>
                </div>
            </div>

            <div className="button-container">
                {!Application.getInstance().IsInjectedAsIframe && <Link to="/tournament" onClick={closeDialog}>
                    <PrimaryButton className="custom-button" disabled={false} receiveClickEvent={false} textButton={translate('pack.modal.button.text')} type="button" />
                </Link>}

                {Application.getInstance().IsInjectedAsIframe &&
                    <PrimaryButton handleClick={() => {
                        window.parent.postMessage('closeIframe', '*');
                        closeDialog();
                    }} className="custom-button" disabled={false} receiveClickEvent={false} textButton={translate('iframe.close')} type="button" />
                }
            </div>
        </div>
    )
}

export default withTranslation()(BenzemaConfirmBuy);
