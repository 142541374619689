import React from 'react';

const Locked = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="23.169" height="16.037" viewBox="0 0 23.169 16.037">
        <g id="locked" transform="translate(-30.318 -676.477)">
            <path id="lock-solid" d="M6.588,3.121v1.04H3.121V3.121a1.734,1.734,0,0,1,3.468,0Zm1.387,1.04V3.121a3.121,3.121,0,1,0-6.242,0v1.04H1.387A1.388,1.388,0,0,0,0,5.548V9.709A1.388,1.388,0,0,0,1.387,11.1H8.322A1.388,1.388,0,0,0,9.709,9.709V5.548A1.388,1.388,0,0,0,8.322,4.161Z" transform="translate(43.777 679.904)" fill="#fff" />
            <path id="coin-solid" d="M6.976,71.848C3.123,71.848,0,70.09,0,67.924S3.123,64,6.976,64s6.976,1.758,6.976,3.924S10.829,71.848,6.976,71.848ZM2.36,69.019a3.817,3.817,0,0,0,1.21.73,9.472,9.472,0,0,0,3.406.572,9.472,9.472,0,0,0,3.406-.572,3.817,3.817,0,0,0,1.21-.73,1.708,1.708,0,0,0,0-2.627,3.817,3.817,0,0,0-1.21-.73,9.472,9.472,0,0,0-3.406-.572,9.472,9.472,0,0,0-3.406.572,3.817,3.817,0,0,0-1.21.73,1.708,1.708,0,0,0,0,2.627Zm.256-1.313c0-.962,1.951-1.744,4.36-1.744s4.36.782,4.36,1.744S9.384,69.45,6.976,69.45,2.616,68.668,2.616,67.706ZM0,70.161a5.717,5.717,0,0,0,1.308,1.114v1.736A2.593,2.593,0,0,1,0,70.976ZM2.18,73.51V71.747a10.38,10.38,0,0,0,2.616.79v1.752a10.018,10.018,0,0,1-2.616-.782Zm3.488.894V72.657a13.112,13.112,0,0,0,2.616,0V74.4c-.422.041-.861.06-1.308.06S6.09,74.442,5.668,74.4Zm3.488-.112V72.54a10.492,10.492,0,0,0,2.616-.79v1.763a10.018,10.018,0,0,1-2.616.782Zm3.488-1.281V71.275a5.586,5.586,0,0,0,1.308-1.114v.815a2.585,2.585,0,0,1-1.308,2.033Z" transform="translate(-1.682 628.027) rotate(-30)" fill="#fff" />
        </g>
    </svg>
    )
}

export default Locked;
