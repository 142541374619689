import React from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import { IRangeProps } from './IRangeProps';
import "./range.scss"


const Range: React.FC<IRangeProps> = ({ startLabel, endLabel, currentLabel, range }) => {
    return <div className="common-range-container">
        <div className="range-bar" style={{ width: parseFloat(range.toPercentage()) + '%' }} />

        <div className="range-price-container">
            <div className="price-container label-start">
                <p className="no-margin">{startLabel}</p>
                <ArrowDropDown className='arrow-drop' />
            </div>

            <div className="price-container label-center">
                <p className="no-margin">{currentLabel}</p>
                <ArrowDropDown className='arrow-drop' />
            </div>

            <div className="price-container label-end">
                <p className="no-margin">{endLabel}</p>
                <ArrowDropDown className='arrow-drop' />
            </div>
        </div>
    </div>
}

export default Range;