import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import * as BOTTOMBAR_ACTIONS from './actionsType';

export const toggleBottomBar = ({ isOpen }: { isOpen: boolean }): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    return dispatch({
        type: BOTTOMBAR_ACTIONS.TOGGLE_BOTTOM_BAR,
        payload: { isOpen }
    });
};
