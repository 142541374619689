import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import * as ActionTypesFees from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState = {
  loading: false,
  data: [],
};

export const feesReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
  switch (action.type) {
    case ActionTypesFees.FEES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ActionTypesFees.FEES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ActionTypesFees.FEES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
