import { RootState } from 'model/Redux';
import ITournamentReward, { ITournamentRewardReducer } from 'model/Tournament/ITournamentReward';
import { createSelector } from 'reselect';
import { extractTournamentId } from 'service/tournament/selectors';
import TournamentRewardViewModel from 'view-model/Tournament/TournamentRewardViewModel/TournamentRewardViewModel';

export const tournamentRewardsDataSelector = (state: RootState) => state.tournamentReward.data;

export const tournamentRewardsSelector = createSelector(
    tournamentRewardsDataSelector,
    extractTournamentId,
    (tournamentRewards: ITournamentRewardReducer, tournamentId: string) => {
        if (!tournamentRewards[tournamentId] || tournamentRewards[tournamentId].length === 0)
            return [];

        return tournamentRewards[tournamentId]
            .map((tournamentReward: ITournamentReward) => {
                return new TournamentRewardViewModel({
                    ...tournamentReward,
                });
            });
    },
);
