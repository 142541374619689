import CircularIndeterminate from 'components/small/CircularLoader';
import { HistoryActions } from 'config/_enums';
import useAssetOfTransactionSlideup from 'hooks/use-asset-of-transaction-slideup/useAssetOfTransactionSlideup';
import useAssetTransactionSlides from 'hooks/use-asset-transaction-slideup/useAssetTransactionSlides';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AssetTransaction from '../AssetTransaction';
import "./asset-transaction-slides.scss";

const AssetTransactionSlides = () => {
    const { isOpen, closeAssetTransaction } = useAssetTransactionSlides();
    const { asset, transactionType, fromPack, orderType } = useAssetOfTransactionSlideup();
    
    const history = useHistory();
    useEffect(() => {
        const unlisten = history.listen((_location, action) => {
            if (action === HistoryActions.REPLACE) return;
            closeAssetTransaction()
        });
        return unlisten;
    }, [])

    if (!isOpen) return <></>
    return (asset && transactionType ?
        <AssetTransaction fromPack={fromPack} asset={asset} transactionType={transactionType} orderType={orderType} />
        : <CircularIndeterminate
            loaderMessage={i18next.t('genericloading')}
            loaderLocal={false} />)
}

export default AssetTransactionSlides;