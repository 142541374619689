import React from 'react';
import ScoreChartBarProps from './IScoreChartBarProps';
import './ScoreChartBar.scss';
import { ZERO } from 'config/_const';
import { Trans } from 'react-i18next';

const ScoreChartBar: React.FC<ScoreChartBarProps> = (props) => {
    return (
        <div onClick={props.onChartbarClick} className='score-chart-bar'>
            {props.score > ZERO ?
                <>
                    <div className='label-score-chart-bar clickable' style={{ backgroundColor: props.color }}>
                        <span className='value'>{props.score}</span>
                    </div>
                    <div className='background-score-chart-bar clickable' style={{ background: `linear-gradient(0deg, rgba(83, 208, 129, 0) 0%, ${props.color} 100%)`, height: `${props.score * 2}px` }} >

                    </div>
                </>
                :
                <div className='didnotplayed-score-chart-bar'>
                    <span>
                        <Trans
                            i18nKey="chart.score.sport.didnotplayed"
                        />
                    </span>
                </div>
            }
            <span className='date'>{props.date}</span>
        </div>
    );
};

export default ScoreChartBar;
