import React from 'react';
import Color from 'view-model/Shared/Color';
import './ScoreChart.scss';
import IScoreChartProps from './IScoreChartProps';
import ScoreChartViewModel from 'view-model/ScoreChartViewModel/ScoreChartViewModel';
import ScoreChartBar from 'components/small/score-chart/ScoreChartBar';
import { Trans } from 'react-i18next';

const ScoreChart: React.FC<IScoreChartProps> = (props) => {
    return (
        <div className="mixed-chart-score">
            <div className='score-chart'>
                <div className='chart-header-container'>
                    <h2 className='uppercase'>
                        {props.title}
                    </h2>
                    <p className='no-margin'><Trans i18nKey={'sport.score.statistics.event'} components={{ bold: <strong /> }} /></p>
                </div>
                <div className='score-chart-container'>
                    {props.scoreChartVM.map((value: ScoreChartViewModel, index: number) =>
                        <ScoreChartBar key={index} onChartbarClick={() => value.HasStatistics && props.onChartbarClick(value.buildScoreChartDetail())} score={value.Score} color={Color.getColorByInputRatio(value.Score)} date={value.DateToDisplayInGraphs} />
                    )}
                </div>
            </div>
        </div>
    )
};

export default ScoreChart;