import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './18n.js';
import App from './App';

declare global {
    interface Window {
        API_BASE_URL: string;
        PAYMENT_BASE_URL: string;
        NODE_ENV: string;
        BASE_URL: string;
        __REDUX_DEVTOOLS_EXTENSION__: any;
        BASE_FOLDER_BUILD: string;
        STRIPE_KEY: string;
        IS_BUILD: boolean;
        IS_CORDOVA: boolean;
        $crisp: any;
        CRISP_WEBSITE_ID: string;
    }
}

window.BASE_URL = window.location.protocol + '//' + window.location.hostname + '/'

if (window.location.href.includes('file://')) {
    window.IS_CORDOVA = true;
}

ReactDOM.render(
    <App />,
    document.getElementById('root'),
);
