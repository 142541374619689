import React from 'react';
import { ZERO } from 'config/_const';
import clsx from 'clsx';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import WalletCard from 'components/icons/wallet/card/WalletCard';
import { AssetTransactionType } from 'config/_enums';
import i18n from '18n';
import TxnAssetBodyProps from './TxnAssetBodyProps';
import './TxnAssetBody.scss';
import TxnTokenDetail from './txn-token-detail/TxnTokenDetail';

const TxnAssetBody: React.FC<TxnAssetBodyProps> = (
    { assetTransactionViewModel,
        setNumberOfToken,
        isTransactionInMobileView,
        isTransactionTypeBuy,
        isTransactionTypeSell,
        isSecondaryMarketRestrictionEnabled,
        setIsSecondaryMarketRestrictionEnabled,
        isPrePrivateSale,
        setIsPrePrivateSale,
        onCancel,
        setUserInputAmount,
        activeTransactionDetailAttribute,
        setActiveTransactionDetailAttribute,
        userInputAmount
    }
) => {
    const isSecondaryMarketBuyLimitApplied: boolean = (assetTransactionViewModel.IsSecondaryMarket && isTransactionTypeBuy) && isSecondaryMarketRestrictionEnabled;

    const secondaryMarketLimitBuyTimer = (args: CountdownRenderProps): JSX.Element => {
        const { hours, minutes, days } = args;
        if (days === ZERO && hours === ZERO && minutes === ZERO)
            return <span className="no-margin">{i18n.t('timer.without.days.hours.minutes', { seconds: args.formatted.seconds })}</span>;
        if (days === ZERO && hours === ZERO)
            return <span className="no-margin">{i18n.t('timer.without.days.hours', { minutes: args.formatted.minutes, seconds: args.formatted.seconds })}</span>;
        if (days === ZERO)
            return <span className="no-margin"><span className="no-margin">{i18n.t('timer.without.days', { hours: args.formatted.hours, minutes: args.formatted.minutes, seconds: args.formatted.seconds })}</span>;</span>;
        return <span className="no-margin">{i18n.t('timer.full', { days: args.formatted.days, hours: args.formatted.hours, minutes: args.formatted.minutes, seconds: args.formatted.seconds })}</span>;
    }

    const disableSecondaryMarketBuyLimit = (): void => {
        if (!isSecondaryMarketRestrictionEnabled)
            return;
        setIsSecondaryMarketRestrictionEnabled(false);
    }

    return (
        <div className={clsx("txn-asset-body", { "slider-gap": assetTransactionViewModel.TransactionType === AssetTransactionType.SELL })}>
            <TxnTokenDetail
                assetTransactionViewModel={assetTransactionViewModel}
                setNumberOfToken={setNumberOfToken}
                isTransactionInMobileView={isTransactionInMobileView}
                isTransactionTypeBuy={isTransactionTypeBuy}
                isTransactionTypeSell={isTransactionTypeSell}
                isPrePrivateSale={isPrePrivateSale}
                setIsPrePrivateSale={setIsPrePrivateSale}
                onCancel={onCancel}
                setUserInputAmount={setUserInputAmount}
                activeTransactionDetailAttribute={activeTransactionDetailAttribute}
                setActiveTransactionDetailAttribute={setActiveTransactionDetailAttribute}
                userInputAmount={userInputAmount}
            />
            {isSecondaryMarketBuyLimitApplied &&
                <div className='input-tokens-caution'>
                    <p className="no-margin">
                        <WalletCard />
                        {i18n.t('assetProfilPage.maxBuyPerUserSecondary', { tokens: assetTransactionViewModel.Asset.TokensLimitOnSecondaryMarketStart })}&nbsp;
                        <Countdown
                            onComplete={() => disableSecondaryMarketBuyLimit()}
                            precision={0}
                            renderer={secondaryMarketLimitBuyTimer}
                            date={assetTransactionViewModel.Asset.SecondaryMarketPurchaseRestrictionEndDate} />
                    </p>
                    <p className="no-margin">{i18n.t('sm.restriction.buy.own.tokens', { userToken: assetTransactionViewModel.HoldingAssetCount })}</p>
                </div>
            }
        </div>
    )
};

export default React.memo(TxnAssetBody);
