import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
const TournamentWidgetContentLoading: React.FC = () => {
    return (
        <div style={{ borderRadius: '9px' }}>
            <Skeleton animation="wave" className="container-rect" variant="rect" height={295} />
        </div>
    );
};

export default TournamentWidgetContentLoading;
