import { DEFAULT_ID_AS_STRING } from 'config/_const';
import { RootState } from 'model/Redux';
import { ILightTournamentSubscription } from 'model/Tournament/ITournamentSubscription';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptions, subscribeToTournamentAction } from 'service/tournamentSubscription/actions';
import { subscriptionsByTournamentIdSelector, tournamentSubscriptionLoadingSelector } from 'service/tournamentSubscription/selectors';
import TournamentSubscriptionViewModel from 'view-model/Tournament/TournamentSubscriptionsViewModel/TournamentSubscriptionViewModel';
import IUseTournamentSubscriptions from './IUseTournamentSubscriptions';
import IUseTournamentSubscriptionsParams from './IUseTournamentSubscriptionsParams';

const useTournamentSubscriptions = ({ tournamentId }: IUseTournamentSubscriptionsParams): IUseTournamentSubscriptions => {
    const dispatch = useDispatch();
    const tournamentUsersSubscriptions: TournamentSubscriptionViewModel[] = useSelector((state: RootState) => subscriptionsByTournamentIdSelector(state, tournamentId));
    const memoizedTournamentId: string = React.useMemo(() => tournamentId, [tournamentId]);
    const subscriptionsLoading: boolean = useSelector(tournamentSubscriptionLoadingSelector);

    React.useEffect(() => {
        fetchTournamentSubscription();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoizedTournamentId, dispatch]);

    const subscribeToTournament = (body: ILightTournamentSubscription, onSuccessCallback?: () => void): void => {
        dispatch(subscribeToTournamentAction({ ...body, tournament: memoizedTournamentId }, onSuccessCallback));
    };

    const fetchTournamentSubscription = React.useCallback(() => {
        if (memoizedTournamentId === DEFAULT_ID_AS_STRING)
            return;
        dispatch(getSubscriptions(memoizedTournamentId));
    }, [dispatch, memoizedTournamentId]);

    return {
        subscribeToTournament,
        fetchTournamentSubscription,
        subscriptionsLoading,
        tournamentSubscriptions: tournamentUsersSubscriptions,
    };
};

export default useTournamentSubscriptions;
