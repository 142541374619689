import React from 'react';
import { Trans } from 'react-i18next';
import './style.scss';

const PaidTournamentDisclaimer: React.FC = () => {
    return (
        <ul className="paid-tournament-disclaimer">
            <li>
                <Trans
                    i18nKey="tournament.paid.join"
                    components={{ bold: <strong /> }} />
            </li>
        </ul>
    );
};

export default PaidTournamentDisclaimer;
