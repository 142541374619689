import { Selector } from 'reselect';
import { RootState, ReduxRootState } from '../../model/Redux';
import { AppConfigState } from './reducer';

export const appConfigSelector: Selector<RootState, ReduxRootState> = state => state.appConfig;
export const deviceSelector: Selector<RootState, AppConfigState['data']['device']> = state => state.appConfig.data.device;
export const selectIsNativeDevice: Selector<RootState, boolean> = (state) => {
    if (!state.appConfig.data.device) return false;
    return state.appConfig.data.device.platform === 'ios' || state.appConfig.data.device.platform === 'android';
};
export const selectIsIOS: Selector<RootState, boolean> = (state) => {
    if (!state.appConfig.data.device) return false;
    return state.appConfig.data.device.platform === 'ios';
};
