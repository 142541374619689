import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { HTTP_STATUS_OK, HTTP_STATUS_INTERNAL_ERROR } from 'config/_const';
import AxiosConfig from 'config/axiosConfig';
import { CLOUD_FRONT_CACHE_ENDPOINT } from 'config/endpoints';
import { managementNotification } from 'service/notification/action';
import { IHttpStrongTypedResponse } from 'model/IHttpResponse';

export const createCloudCacheInvalidation = (keys: string[]): ThunkAction<Promise<any>, any, any, Action<String>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse = await AxiosConfig.post(`${CLOUD_FRONT_CACHE_ENDPOINT.CLEAR_CACHE}`, { keys });
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch(managementNotification(true, response.data.message, HTTP_STATUS_INTERNAL_ERROR));
        return dispatch(managementNotification(true, 'Cache invalidation created.', HTTP_STATUS_OK));
    } catch (exception) {
        return dispatch(managementNotification(true, (exception as Error).message, HTTP_STATUS_INTERNAL_ERROR));
    }
};