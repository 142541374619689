import React from 'react';
import Application from 'Application';
import Article from 'components/small/article/Article';
import IPressArticlesProps from './IPressArticlesProps';
import './PressArticles.scss';
import { Trans } from 'react-i18next';

const PressArticles: React.FC<IPressArticlesProps> = (props) => {
	return <div className="press-articles-component">
        <h2>
            <Trans i18nKey="press.articles" />
        </h2>
        {
		Application.getInstance().getRegisterPressArticles().map((article) => {
			return (
					<Article title={article.title} thumbnail={article.image} link={article.link} language={props.language} date={article.date} source={article.source} sourceLogo={article.sourceLogo} key={article.title}/>
			);
			})
		}
		</div>
}

export default PressArticles;
