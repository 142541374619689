import ReduxActionInterface from '../../model/ReduxActionInterface';
import * as AssetTransactionSlidupActions from './actionType';
import ReduxRootState from '../../model/Redux/ReduxRootSate';
import { IAssetTransactionSlides } from './types';

const ROOT_VALUE_STATE: ReduxRootState = {
    data: { isOpen: false },
    loading: false
};


export const assetTransactionSlidesReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface<IAssetTransactionSlides>) => {
    switch (action.type) {
        case AssetTransactionSlidupActions.TOGGLE_ASSET_TRANSACTION_SLIDEUP:
            return { ...state, data: action.payload }
        default:
            return state;
    }
}