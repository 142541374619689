import { ZERO } from 'config/_const';
import useUserTournamentSubscription from 'hooks/tournament/use-user-tournament-subscription/useUserTournamentSubscription';
import useUserLeague from 'hooks/use-user-league/useUserLeague';
import React from 'react';
import ScoreBoosterViewModel from 'view-model/ScoreBoosterViewModel/ScoreBoosterViewModel';
import IUseScoreBoosterParams from './IUseScoreBooster';

const useScoreBooster = ({ runningTournamentId }: IUseScoreBoosterParams) => {
    const { userLeagueViewModel } = useUserLeague();
    const { currentUserSubscription } = useUserTournamentSubscription({ tournamentId: runningTournamentId });
    const scoreBoosterVM: ScoreBoosterViewModel = React.useMemo(() => new ScoreBoosterViewModel({
        winningPoints: currentUserSubscription?.ScoreBooster.points || 0,
        isBoostAcquired: currentUserSubscription?.IsScoreBoosted || false,
        avgTeamScore: currentUserSubscription?.TeamScoreAverage ?? ZERO,
        priceForBoost: currentUserSubscription?.BoostPrice ?? userLeagueViewModel.LeagueTeamScoreBoostPrice,
    }), [currentUserSubscription, userLeagueViewModel.LeagueTeamScoreBoostPrice]);

    return {
        scoreBoosterVM
    };
};

export default useScoreBooster;
