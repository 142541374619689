import { EmptyObject, SPORTS_STATISTICS_RATIO, ZERO } from "config/_const";
import IDetailedStatistics from "interfaces/score-chart/IDetailedStatistics";
import FootballStatisticsCardViewModel from "view-model/FootballStatistics/FootballStatisticsCardViewModel";
import FootballStatisticsDribbleViewModel from "view-model/FootballStatistics/FootballStatisticsDribbleViewModel";
import FootballStatisticsDuelViewModel from "view-model/FootballStatistics/FootballStatisticsDuelViewModel";
import FootballStatisticsFoulViewModel from "view-model/FootballStatistics/FootballStatisticsFoulViewModel";
import FootballStatisticsGameViewModel from "view-model/FootballStatistics/FootballStatisticsGameViewModel";
import FootballStatisticsGoalViewModel from "view-model/FootballStatistics/FootballStatisticsGoalViewModel";
import FootballStatisticsPassViewModel from "view-model/FootballStatistics/FootballStatisticsPassViewModel";
import FootballStatisticsPenaltyViewModel from "view-model/FootballStatistics/FootballStatisticsPenaltyViewModel";
import FootballStatisticsShotViewModel from "view-model/FootballStatistics/FootballStatisticsShotViewModel";
import FootballStatisticsSubstituteViewModel from "view-model/FootballStatistics/FootballStatisticsSubstituteViewModel";
import FootballStatisticsTackleViewModel from "view-model/FootballStatistics/FootballStatisticsTackleViewModel";
import IFootballScoreViewModel from "./IFootballScoreViewModel";

export class FootballScoreViewModel {
    private games: FootballStatisticsGameViewModel;
    private substitutes: FootballStatisticsSubstituteViewModel;
    private shots: FootballStatisticsShotViewModel;
    private goals: FootballStatisticsGoalViewModel;
    private passes: FootballStatisticsPassViewModel;
    private tackles: FootballStatisticsTackleViewModel;
    private duels: FootballStatisticsDuelViewModel;
    private dribbles: FootballStatisticsDribbleViewModel;
    private fouls: FootballStatisticsFoulViewModel;
    private cards: FootballStatisticsCardViewModel;
    private penalty: FootballStatisticsPenaltyViewModel;
    private static footballRatios = SPORTS_STATISTICS_RATIO.Football;

    public constructor(footballScore: IFootballScoreViewModel) {
        const statistics = footballScore?.statistics ?? EmptyObject;
        this.games = new FootballStatisticsGameViewModel(statistics.games ?? EmptyObject);
        this.substitutes = new FootballStatisticsSubstituteViewModel(statistics.substitutes ?? EmptyObject);
        this.shots = new FootballStatisticsShotViewModel(statistics.shots ?? EmptyObject);
        this.goals = new FootballStatisticsGoalViewModel(statistics.goals ?? EmptyObject);
        this.passes = new FootballStatisticsPassViewModel(statistics.passes ?? EmptyObject);
        this.tackles = new FootballStatisticsTackleViewModel(statistics.tackles ?? EmptyObject);
        this.duels = new FootballStatisticsDuelViewModel(statistics.duels ?? EmptyObject);
        this.dribbles = new FootballStatisticsDribbleViewModel(statistics.dribbles ?? EmptyObject);
        this.fouls = new FootballStatisticsFoulViewModel(statistics.fouls ?? EmptyObject);
        this.cards = new FootballStatisticsCardViewModel(statistics.cards ?? EmptyObject);
        this.penalty = new FootballStatisticsPenaltyViewModel(statistics.penalty ?? EmptyObject);
    }

    public buildDetailedStatistics(): IDetailedStatistics[] {
        return Object.entries(FootballScoreViewModel.footballRatios).map((a: any) => {
            const key: keyof FootballScoreViewModel = a[0] as keyof FootballScoreViewModel;
            const value: number = a[1];
            return {
                label: `sport.score.statistic.football.${key.toLowerCase()}`,
                amount: `${this[key]}`,
                points: `${value !== ZERO ? parseFloat(`${this[key]}`) : ZERO}`,
            }
        });
    };

    public get Games(): FootballStatisticsGameViewModel {
        return this.games;
    }

    public get Substitutes(): FootballStatisticsSubstituteViewModel {
        return this.substitutes;
    }

    public get Shots(): FootballStatisticsShotViewModel {
        return this.shots;
    }

    public get Goals(): FootballStatisticsGoalViewModel {
        return this.goals;
    }

    public get Passes(): FootballStatisticsPassViewModel {
        return this.passes;
    }

    public get Tackles(): FootballStatisticsTackleViewModel {
        return this.tackles;
    }

    public get Duels(): FootballStatisticsDuelViewModel {
        return this.duels;
    }

    public get Dribbles(): FootballStatisticsDribbleViewModel {
        return this.dribbles;
    }

    public get Fouls(): FootballStatisticsFoulViewModel {
        return this.fouls;
    }

    public get Cards(): FootballStatisticsCardViewModel {
        return this.cards;
    }

    public get Penalty(): FootballStatisticsPenaltyViewModel {
        return this.penalty;
    }

    public get FoulsDrawn(): number {
        return this.fouls.Drawn;
    }

    public get YellowCards(): number {
        return this.cards.Yellow;
    }

    public get DuelsWon(): number {
        return this.duels.Won;
    }

    public get DuelsTotal(): number {
        return this.duels.Total;
    }

    public get PassesKey(): number {
        return this.passes.Key;
    }

    public get PassesAccuracy(): number {
        return this.passes.Accuracy;
    }

    public get ShotOn(): number {
        return this.shots.On;
    }

    public get PenaltyScored(): number {
        return this.penalty.Scored;
    }

    public get GamesMinutes(): number {
        return this.games.Minutes;
    }
}
