import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { HTTP_STATUS_OK } from '../../config/_const';
import AxiosConfig from '../../config/axiosConfig';
import { USER_ENDPOINTS } from '../../config/endpoints';
import { IHttpStrongTypedResponse } from '../../model/IHttpResponse';
import { GET_ASSET_TRANSACTIONS_ERROR, GET_ASSET_TRANSACTIONS_LOADING, GET_ASSET_TRANSACTIONS_SUCCESS } from './actionsType';
import { IGetAllAssetTransactionResponse } from './types';



export const getAllAssetTransactions = (userId: string): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({ type: GET_ASSET_TRANSACTIONS_LOADING });
    try {
        const getAssetTransactionsResponse: IHttpStrongTypedResponse<IGetAllAssetTransactionResponse[]> = await AxiosConfig.get(`${USER_ENDPOINTS.GET_ALL_ASSET_TRANSACTIONS_BASE_URL}${userId}`);
        if (getAssetTransactionsResponse.data.status !== HTTP_STATUS_OK)
            return dispatch({ type: GET_ASSET_TRANSACTIONS_ERROR, payload: { message: getAssetTransactionsResponse.data.message } });
        return dispatch({
            type: GET_ASSET_TRANSACTIONS_SUCCESS,
            payload: {
                data: getAssetTransactionsResponse.data.data
            }
        });
    } catch (exception) {
        return dispatch({ type: GET_ASSET_TRANSACTIONS_ERROR, payload: { message: exception.message } });
    }
};