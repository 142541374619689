import React from 'react';
import ITrendexCheckoutFormProps from './ITrendexCheckoutFormProps';
import useTrendexCheckoutForm from '../../../hooks/use-trendex-checkout-form/useTrendexCheckoutForm';
import './trendexCheckoutForm.scss';
/**
 * DO NOT USE THIS FORM AS STANDALONE COMPONENT - IT NEEDS TO BE WRAPPER INTO Stripe Elements
 */
const TrendexCheckoutForm: React.FC<ITrendexCheckoutFormProps> = (props: ITrendexCheckoutFormProps) => {
    const { getCheckoutForm } = useTrendexCheckoutForm(props);
    return getCheckoutForm();
};

export default TrendexCheckoutForm;
