import React, { useEffect } from "react";
import i18next from 'i18next';
import { useLocation } from "react-router-dom";
import AssetProfilPage from "page/AssetProfilPage/AssetProfilPage";
import useAssetSlideView from "hooks/asset/use-asset-slide-view/useAssetSlideView";
import "./asset-slide-up-view.scss"
import { useDispatch, useSelector } from "react-redux";
import { assetBySlugSelector, assetsLoadingSelector } from "service/assets/selectors"
import { getAssetBySlug } from "service/assets/actions";
import CircularIndeterminate from 'components/small/CircularLoader';
import useSlidingPages from "hooks/use-sliding-pages/useSlidingPages";
import useAssetTransactionSlides from "hooks/use-asset-transaction-slideup/useAssetTransactionSlides";

const AssetSlideUpView: React.FC = () => {
    const location = useLocation();
    const {
        activeSlug,
        overlaySlug,
        isSlideViewOpen,
        isSlidingUp,
        onTransitionComplete,
        onSlideViewOpen,
        onSlideViewClose
    } = useAssetSlideView()

    const nodeRef = React.useRef<HTMLDivElement>(null);
    const overlayRef = React.useRef<HTMLDivElement>(null);

    const asset = useSelector(assetBySlugSelector(activeSlug));
    const assetLoading = useSelector(assetsLoadingSelector);
    const isAssetLoading = (activeSlug && !asset) || (overlaySlug && assetLoading);
    const { isOpen: isSlidingPageOpen, hideSlidingPage } = useSlidingPages();
    const { isOpen: isTransactionSliderOpen, closeAssetTransaction, canShowHalfScreen } = useAssetTransactionSlides()
    const dispatch = useDispatch();

    useEffect(() => {
        if (isSlideViewOpen) {
            onSlideViewClose()
        }
    }, [location.pathname])

    useEffect(() => {
        if (!asset && activeSlug) dispatch(getAssetBySlug(activeSlug));
        if (isSlidingPageOpen) hideSlidingPage();
        if (isTransactionSliderOpen && !canShowHalfScreen) closeAssetTransaction();
        window.history.replaceState(null, '', activeSlug ? `/asset/${activeSlug}` : location.pathname);
    }, [activeSlug])

    return (
        <React.Fragment>
            {isAssetLoading && <CircularIndeterminate
                loaderMessage={i18next.t('genericloading')}
                loaderLocal={false} />
            }
            {asset &&
                <AssetProfilPage
                    key={activeSlug}
                    innerRef={nodeRef}
                    slug={activeSlug}
                    location={location}
                    isSlidingUp={Boolean(overlaySlug) || isSlidingUp}
                    isSlideViewOpen={isSlideViewOpen}
                    onTransitionComplete={onTransitionComplete}
                    onSlideViewOpen={onSlideViewOpen}
                />
            }
            {overlaySlug &&
                <AssetProfilPage
                    key={overlaySlug}
                    innerRef={overlayRef}
                    slug={overlaySlug}
                    location={location}
                    isSlidingUp={isSlidingUp}
                    isSlideViewOpen={isSlideViewOpen}
                    onTransitionComplete={onTransitionComplete}
                    onSlideViewOpen={onSlideViewOpen}
                />
            }
        </React.Fragment>
    )
}

export default AssetSlideUpView 