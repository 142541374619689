import * as Sentry from "@sentry/react";
import {
    CREDIT_ORDER_TYPE,
    HTTP_STATUS_INTERNAL_ERROR,
    HTTP_STATUS_OK,
    ORDER_SIDE,
} from 'config/_const';
import AxiosConfig, { initializeJwtHeaderAndUinqueUserId } from 'config/axiosConfig';
import { ASSET_ENDPOINTS, CREDITS_ENDPOINTS, PAYMENT_ENDPOINTS } from 'config/endpoints';
import i18next from 'i18next';
import { IHttpResponse, IHttpStrongTypedResponse } from 'model/IHttpResponse';
import IBuyAsset from 'model/Payment/IBuyAsset';
import IBuyScoreBoostPayload from "model/Payment/IBuyScoreBoostPayload";
import IScoreBoostPayment from "model/Payment/IScoreBoostPayment";
import SecondaryMarketDirectBuyResponse from 'model/Payment/ISecondaryMarketDirectBuyResponse';
import { ISecondaryMarketStripePaymentResponse } from 'model/Payment/ISecondaryMarketStripePaymentResponse';
import SecondaryMarketPaymentInterface from 'model/Payment/SecondaryMarketPaymentInterface';
import { IBuyTicketParameters, ITournamentSubscription } from "model/Tournament/ITournamentSubscription";
import UserInterface from 'model/User/UserInterface';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { UPDATE_CREDITS_AFTER_BUY } from 'service/auth/actionsType';
import { showNotification } from 'service/notification/action';
import { dispatchSuccessSubscriptionToTournament } from "service/tournamentSubscription/actions";
import SignupSourcesViewModel from "view-model/SignupSources/SignupSourcesViewModel";
import * as PaymentActionType from './actionsType';
import IBuyTournamentTicketParams from "model/Payment/IBuyTournamentTicketParams";
import { batch } from "react-redux";

const TRANSACTION_INTENT_TYPES = {
    ORDER: 'ORDER',
    BOOST_TEAM_SCORE: 'BOOST_TEAM_SCORE',
    BUY_TOURNAMENT_TICKET: 'BUY_TOURNAMENT_TICKET'
};

interface IOneOffPayment {
    assetId?: string;
    tokens?: number;
    amount?: number;
    paymentMethod?: string;
    isDirectBuy?: boolean;
    email?: string;
    currency?: string;
    sourceChannel?: string;
    sourceId?: string;
}

/**
 *   _____  _      ______           _____ ______   _____  ______          _____  
    |  __ \| |    |  ____|   /\    / ____|  ____| |  __ \|  ____|   /\   |  __ \ 
    | |__) | |    | |__     /  \  | (___ | |__    | |__) | |__     /  \  | |  | |
    |  ___/| |    |  __|   / /\ \  \___ \|  __|   |  _  /|  __|   / /\ \ | |  | |
    | |    | |____| |____ / ____ \ ____) | |____  | | \ \| |____ / ____ \| |__| |
    |_|    |______|______/_/    \_\_____/|______| |_|  \_\______/_/    \_\_____/    
                                                                      
 * 
 * 
 * GENERAL :
 *  Actions descriptions :
 *      All payment routes starting by /credit -> /!\ secondary market OR refound of credits. The diference between them is the body passes. /!\
 *      All payment routes tstarting by /payment -> primary market
 *   /!\ WATCHOUT - PLEASE USE ACTION TO DELETE PAYMENT STATE BEFORE CALLING YOUR METHOD /!\
 */

/**
 * Will initiate a payment with paypal ONLY for primary market.
 * In case of success amount will be credited to user credits
 * Need to use /credit/payment-status/ :transactionId/:userId to get the status of transactions
 * @param tokens amount of shares the user wants to buy
 * @param assetId asset id
 * @returns dispatch -> see reducer associated
 */
export const paypalPayment = (tokens: number, assetId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: PaymentActionType.DROP_PAYMENT_DATA });
        dispatch({ type: PaymentActionType.PAYMENT_LOADING });

        const response: IHttpResponse = await AxiosConfig.post(PAYMENT_ENDPOINTS.PRIMARY_MARKET_PAYPAL_PAYMENT_BASE_URL, {
            tokens,
            assetId
        });

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: PaymentActionType.PAYMENT_ERROR,
                payload: response.data.message,
            });
        }

        dispatch({
            type: PaymentActionType.PAYMENT_SUCCESS,
            payload: { data: response.data.data },
        });

        return window.location.href = response.data.data.redirectUrl;
    } catch (error) {
        return dispatch({ type: PaymentActionType.PAYMENT_ERROR });
    }
};

export const paypalPaymentSuccess = (tokens: number, assetId: string, paymentId: string, payerId: any, token: string, transactionId: string, oldTokenPrice: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    try {
        dispatch({ type: PaymentActionType.PAYMENT_LOADING });

        const response: IHttpResponse = await AxiosConfig.get(`${PAYMENT_ENDPOINTS.PRIMARY_MARKET_PAYPAL_PAYMENT_SUCCESS_BASE_URL}?tokens=${tokens}&assetId=${assetId}&paymentId=${paymentId}&PayerID=${payerId}&token=${token}&transactionId=${transactionId}&oldTokenPrice=${oldTokenPrice}`);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: PaymentActionType.PAYMENT_ERROR,
                payload: response.data.message,
            });
        }

        dispatch({
            type: PaymentActionType.PAYMENT_SUCCESS,
            payload: { data: response.data },
        });

    } catch (error) {
        dispatch({ type: PaymentActionType.PAYMENT_ERROR });
        return window.location.href = ('/error');
    }
};

/**
 * Will initiate a crypto payment ONLY for primary market. In case of succes, amount will be credited on user account
 * @param tokens amount of shares the user wants to buy
 * @param assetId asset id
 * @returns dispatch -> see reducer associated
 */
export const cryptoPayment = (tokens: number, assetId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({ type: PaymentActionType.DROP_PAYMENT_DATA });
    dispatch({
        type: PaymentActionType.PAYMENT_LOADING,
    });

    try {
        const response: IHttpResponse = await AxiosConfig.post(PAYMENT_ENDPOINTS.PRIMARY_MARKET_CRYPTO_PAYMENT_BASE_URL, { tokens, assetId });
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: PaymentActionType.PAYMENT_ERROR,
                payload: response.data.message,
            });
        }
        return dispatch({
            type: PaymentActionType.PAYMENT_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        return dispatch({
            type: PaymentActionType.PAYMENT_ERROR,
        });
    }
};

/**
 * Method used to buy shares from primary ONLY and with CREDITS_ITEMS
 * This method is synchronous, then, no need to call transactions-status
 * @param assetId concerned asset
 * @param amount of shares the user wants to buy
 * @param isDirectBuy boolean to tell if we want to buy shares directly
 * @returns dispatch -> see reducer associated
 */
export const buyAsset = (assetId: string, amount: number, isDirectBuy: boolean, priceComputed?: number, callBackAfterTxn?: () => void): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: PaymentActionType.DROP_PAYMENT_DATA });
        dispatch({ type: PaymentActionType.PAYMENT_LOADING });

        const response: IHttpStrongTypedResponse<IBuyAsset> = await AxiosConfig.post(ASSET_ENDPOINTS.PRIMARY_MARKET_CREDIT_PAYMENT_BASE_URL, { assetId, amount, isDirectBuy, priceComputed });
        callBackAfterTxn?.();
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, i18next.t(response.data.message), response.data.status);
            return dispatch({
                type: PaymentActionType.PAYMENT_ERROR,
                payload: {
                    status: response.data.status,
                    message: response.data.message,
                },
            });
        }
        dispatch({
            type: UPDATE_CREDITS_AFTER_BUY,
            payload: {
                userCredits: response.data.data.userCredits,
                userVirtualCredits: response.data.data.userVirtualCredits
            }
        });
        return dispatch({
            type: PaymentActionType.PAYMENT_SUCCESS,
            payload: {
                data: response.data.data,
                status: response.data.status,
                message: response.data.message,
            },
        });
    } catch (error) {
        const exceptionAsError: Error = error as Error;
        const errorMessage = exceptionAsError.message || exceptionAsError.stack;
        callBackAfterTxn?.();
        return dispatch({
            type: PaymentActionType.PAYMENT_ERROR,
            payload: {
                status: HTTP_STATUS_INTERNAL_ERROR,
                message: errorMessage,
            },
        });
    }
};

/**
 * Method used to check transaction status for payment initiated with STRIPE or PAYPAL
 * @param transactionId id of transaction created
 * @param userId user logged in
 * @returns dispatch -> see reducer associated
 */
export const getStatusAssetPayment = (transactionId: string, userId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({
        type: PaymentActionType.PAYMENT_ASSET_STATUS_LOADING,
    });
    try {
        const response: IHttpResponse = await AxiosConfig.get(`${PAYMENT_ENDPOINTS.PRIMARY_MARKET_PAYMENT_STATUS_BASE_URL}${transactionId}/${userId}`);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: PaymentActionType.PAYMENT_ASSET_STATUS_ERROR,
                payload: response.data,
            });
        }
        return dispatch({
            type: PaymentActionType.PAYMENT_ASSET_STATUS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        console.log('error from getStatusAssetPayment: ', error);
        return dispatch({
            type: PaymentActionType.PAYMENT_ASSET_STATUS_ERROR,
        });
    }
};

/**
 * Method used to initiate a payment with Stripe. Used ONLY for primary market
 * In case of success amount will be credited on user account.
 * @param assetId asset the user wants to buy shares
 * @param tokens amount of shares the user wants to buy
 * @param paymentMethodId Stripe payment method, usefull only if the user has not stripe customer id yet
 * @returns dispatch -> see reducer associated
 */
export const CardPayment = (assetId: string, tokens: number, isDirectBuy: boolean, paymentMethodId?: string, email?: string, currency?: string): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    return oneOffPayment({
        assetId: assetId,
        tokens: tokens,
        paymentMethod: paymentMethodId,
        isDirectBuy: isDirectBuy,
        email: email,
        currency: currency
    }, dispatch);
}

export const CreditAccountByCard = (fiatAmount: number, paymentMethodId?: string): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    return oneOffPayment({
        amount: fiatAmount,
        paymentMethod: paymentMethodId,
    }, dispatch, true);
};

const oneOffPayment = async (properties: IOneOffPayment, dispatch: ThunkDispatch<null, unknown, Action<string>>, isCredit = false) => {
    dispatch({ type: PaymentActionType.DROP_PAYMENT_DATA });
    dispatch({
        type: PaymentActionType.PAYMENT_INTENT_LOADING,
    });
    try {
        const signupSourceViewModel = new SignupSourcesViewModel();
        properties = {
            ...properties,
            sourceChannel: signupSourceViewModel.SignupSourceChannel,
            sourceId: signupSourceViewModel.SignupSourceId
        }
        const response: IHttpResponse = await AxiosConfig.post(isCredit ? CREDITS_ENDPOINTS.SECONDARY_MARKET_BUY_SHARES_WITH_STRIPE_BASE_URL : PAYMENT_ENDPOINTS.PRIMARY_MARKET_STRIPE_PAYMENT_BASE_URL, properties);
        if (response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: PaymentActionType.PAYMENT_INTENT_ERROR,
                payload: response.data,
            });
        }
        if (response.data.data.user && response.data.data.token && properties.email) {
            const user: UserInterface = response.data.data.user;
            initializeJwtHeaderAndUinqueUserId(response.data.data.token, user._id)
            Sentry.setUser({ id: user._id, email: user.email, username: user.pseudo });
        }
        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_ERROR,
        });
    }
}

/**
 * Method used to get the status of a transaction for SECONDARY market ONLY
 * @param transactionId transaction id
 * @param userId user logged in
 * @returns dispatch -> see reducer associated
 */
export const getTransactionPayment = (transactionId: string, userId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({
        type: PaymentActionType.PAYMENT_TRANSACTION_LOADING,
    });
    try {
        const response: IHttpResponse = await AxiosConfig.get(`${CREDITS_ENDPOINTS.SECONDARY_MARKET_PAYMENT_STATUS_BASE_URL}${transactionId}/${userId}`);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: PaymentActionType.PAYMENT_TRANSACTION_STATUS_ERROR,
                payload: response.data,
            });
        }
        return dispatch({
            type: PaymentActionType.PAYMENT_TRANSACTION_STATUS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        return dispatch({
            type: PaymentActionType.PAYMENT_TRANSACTION_STATUS_ERROR,
        });
    }
};

/**
 * Method user to initiate a payment via paypal on secondary market ONLY
 * In case of success, amount will be credited on user account and then, used to initiate an order on the amount of asset requested
 * @param amount the price of share returned by (preditPrice)
 * @param tokens amount of shares the user wants to buy
 * @param assetId asset concerned
 * @returns dispatch -> see reducer associated
 */
export const paypalCreditsPayment = (amount: number, tokens: number | undefined, assetId: string | undefined): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: PaymentActionType.DROP_PAYMENT_DATA });
        dispatch({ type: PaymentActionType.PAYMENT_LOADING });
        const data: any = {
            amount,
            intent: {
                type: TRANSACTION_INTENT_TYPES.ORDER,
                order: {
                    price: amount,
                    side: ORDER_SIDE.BUY,
                    type: CREDIT_ORDER_TYPE.LIMIT,
                    amount: tokens,
                    asset: assetId,
                },
            },
        };
        const response: IHttpResponse = await AxiosConfig.post(CREDITS_ENDPOINTS.SECONDARY_MARKET_PAYPAL_PAYMENT_BASE_URL, data);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: PaymentActionType.PAYMENT_ERROR,
                payload: response.data.message,
            });
        }
        dispatch({
            type: PaymentActionType.PAYMENT_SUCCESS,
            payload: { data: response.data.data },
        });
        return window.location.href = response.data.data.redirectUrl;
    } catch (error) {
        dispatch({ type: PaymentActionType.PAYMENT_ERROR });
    }
};

/**
 * Method used to buy shares on secondary market with stripe
 * In case of success, amount will be credited on user account and then, used to initiate an order on the amount of asset requested
 * @param totalAmount Total amount of payment
 * @param amountOfShares Amount of shares the user wants to buy
 * @param assetId asset id the user wants to buy shares
 * @param priceOfOrder price of order (predictprice)
 * @param paymentMethodId usefull only if the user hasn't stripe customer yet
 * @returns dispatch -> see reducer associated
 */
export const secondaryMarketStripePayment = (data: SecondaryMarketPaymentInterface): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({ type: PaymentActionType.DROP_PAYMENT_DATA });
    dispatch({ type: PaymentActionType.PAYMENT_LOADING });
    const sigupSourceViewModel = new SignupSourcesViewModel();
    try {
        const payload = {
            sourceChannel: sigupSourceViewModel.SignupSourceChannel,
            sourceId: sigupSourceViewModel.SignupSourceId,
            amount: data.totalAmount,
            paymentMethod: data.paymentMethodId,
            intent: {
                type: TRANSACTION_INTENT_TYPES.ORDER,
                sellingOrderIdFromOrderbook: data.sellingOrderId,
                order: {
                    asset: data.assetId,
                    side: ORDER_SIDE.BUY,
                    type: data.type ?? CREDIT_ORDER_TYPE.MARKET,
                    price: data.priceOfOrder,
                    amount: data.amountOfShares,
                    isDirectBuy: data.isDirectBuy,
                    fromOrderbook: data.isBuyingFromOrderbook,
                    directBuyFromTheBestSellingOrders: data.directBuyFromTheBestSellingOrders
                }
            },
            email: data.email,
            currency: data.currency
        };
        const response: IHttpStrongTypedResponse<ISecondaryMarketStripePaymentResponse> = await AxiosConfig.post(`${CREDITS_ENDPOINTS.SECONDARY_MARKET_BUY_SHARES_WITH_STRIPE_BASE_URL}`, payload);
        if (response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: PaymentActionType.PAYMENT_INTENT_ERROR,
                payload: response.data,
            });
        }
        if (response.data.data.user && response.data.data.token) {
            const user: UserInterface = response.data.data.user;
            initializeJwtHeaderAndUinqueUserId(response.data.data.token, user._id)
            Sentry.setUser({ id: user._id, email: user.email, username: user.pseudo });
        }
        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        console.log('error: ', error);
        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_ERROR,
            payload: error,
        });
    }
};

export const creditAccountWithBitpay = (amount: number, redirectUrl: string, closeUrl: string, onSuccessCallback?: (_responseData: any) => void, onErrorCallback?: () => void): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({ type: PaymentActionType.DROP_PAYMENT_DATA });
    try {
        const payload = {
            amount: amount,
            redirectUrl: redirectUrl,
            closeUrl: closeUrl
        };
        const response: IHttpResponse = await AxiosConfig.post(CREDITS_ENDPOINTS.CREDIT_ACCOUNT_WITH_BITPAY_BASE_URL, payload);
        if (response.data.status !== HTTP_STATUS_OK) {
            onErrorCallback?.();
            return dispatch({
                type: PaymentActionType.PAYMENT_INTENT_ERROR,
                payload: response.data,
            });
        }
        onSuccessCallback?.({ ...response.data.data, amount });
        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_SUCCESS,
            payload: response.data
        });
    } catch (exception) {
        onErrorCallback?.();

        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_ERROR,
            payload: exception,
        });
    }
};

/**
 * Method used to buy team score boost while tournament is running with stripe
 * In case of success amount will be credited on user account.
 * @param tournamentSubscriptionId tournamnetSubscription id of user
 * @param fiatAmount fiatAmount paid to buy boost
 * @param paymentMethodId usefull only if the user hasn't stripe customer yet
 * @returns dispatch -> see reducer associated
 */
export const boostTeamScoreStripePayment = (data: IScoreBoostPayment): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({ type: PaymentActionType.DROP_PAYMENT_DATA });
    dispatch({ type: PaymentActionType.PAYMENT_INTENT_LOADING });
    const sigupSourceViewModel = new SignupSourcesViewModel();
    try {
        const payload = {
            sourceChannel: sigupSourceViewModel.SignupSourceChannel,
            sourceId: sigupSourceViewModel.SignupSourceId,
            paymentMethod: data.paymentMethodId,
            amount: data.fiatAmount,
            intent: {
                type: TRANSACTION_INTENT_TYPES.BOOST_TEAM_SCORE,
                boost: {
                    tournamentSubscriptionId: data.tournamentSubscriptionId,
                }
            },
            email: data.email,
            currency: data.currency
        };
        const response: IHttpStrongTypedResponse<ISecondaryMarketStripePaymentResponse> = await AxiosConfig.post(`${CREDITS_ENDPOINTS.SECONDARY_MARKET_BUY_SHARES_WITH_STRIPE_BASE_URL}`, payload);
        if (response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: PaymentActionType.PAYMENT_INTENT_ERROR,
                payload: response.data,
            });
        }
        if (response.data.data.user && response.data.data.token) {
            const user: UserInterface = response.data.data.user;
            initializeJwtHeaderAndUinqueUserId(response.data.data.token, user._id)
            Sentry.setUser({ id: user._id, email: user.email, username: user.pseudo });
        }
        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        console.log('error: ', error);
        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_ERROR,
            payload: error,
        });
    }
};

/**
 * Method used to buy team score boost while tournament is running with creditAccount
 * In case of success amount will be credited on user account.
 * @param tournamentSubscriptionId tournamnetSubscription id of user
 * @param amount amount paid to buy boost
 * @returns dispatch -> see reducer associated
 */
export const buyTeamScoreBoostCreditPayment = (payload: IBuyScoreBoostPayload): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({ type: PaymentActionType.DROP_PAYMENT_DATA });
    dispatch({ type: PaymentActionType.PAYMENT_LOADING });
    try {
        const response: IHttpStrongTypedResponse<ISecondaryMarketStripePaymentResponse> = await AxiosConfig.post(`${CREDITS_ENDPOINTS.BOOST_SCORE_BUY_WITH_CREDIT_BASE_URL}`, payload);
        if (response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: PaymentActionType.PAYMENT_ERROR,
                payload: response.data,
            });
        }
        return dispatch({
            type: PaymentActionType.PAYMENT_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        console.log('error: ', error);
        return dispatch({
            type: PaymentActionType.PAYMENT_ERROR,
            payload: error,
        });
    }
};

/**
 * /!\ Will drop all payment data. to use in conscience
 * @returns void
 */
export const unsafeDropPaymentData = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    return dispatch({
        type: PaymentActionType.DROP_PAYMENT_DATA,
    });
}

export const secondaryMarketDirectBuy = (amount: number, assetId: string, price: number): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: PaymentActionType.PAYMENT_LOADING });
        const response: IHttpStrongTypedResponse<SecondaryMarketDirectBuyResponse> = await AxiosConfig.post(CREDITS_ENDPOINTS.SECONDARY_MARKET_DIRECT_BUY, { amount, assetId, price });
        if (response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: PaymentActionType.PAYMENT_INTENT_ERROR,
                payload: response.data,
            });
        }
        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        console.log('error: ', error);
        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_ERROR,
            payload: error,
        });
    }
};

export const purchaseTournamentSubscriptionTicketWithCredits = (payload: IBuyTicketParameters): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: PaymentActionType.DROP_PAYMENT_DATA });
        dispatch({ type: PaymentActionType.PAYMENT_LOADING });
        const response: IHttpStrongTypedResponse<ITournamentSubscription[]> = await AxiosConfig.post(`${CREDITS_ENDPOINTS.BUY_TOURNAMENT_TICKET}`, payload);
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: PaymentActionType.PAYMENT_ERROR,
                payload: response.data,
            });
        dispatch(dispatchSuccessSubscriptionToTournament(response, { team: payload.subscription.team, tournament: payload.subscription.tournament, subscriptionDate: payload.subscription.subscriptionDate }))
        return dispatch({
            type: PaymentActionType.PAYMENT_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        return dispatch({
            type: PaymentActionType.PAYMENT_ERROR,
            payload: error,
        });
    }
};

export const actionInitiateTicketPurchaseIntentForTournament = (data: IBuyTournamentTicketParams): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        batch(() => {
            dispatch({ type: PaymentActionType.DROP_PAYMENT_DATA });
            dispatch({ type: PaymentActionType.PAYMENT_INTENT_LOADING });
        });

        const signupSourceViewModel: SignupSourcesViewModel = new SignupSourcesViewModel();
        const payload = {
            sourceChannel: signupSourceViewModel.SignupSourceChannel,
            sourceId: signupSourceViewModel.SignupSourceId,
            paymentMethod: data.paymentMethodId,
            amount: data.fiatAmount,
            intent: {
                type: TRANSACTION_INTENT_TYPES.BUY_TOURNAMENT_TICKET,
                subscription: {
                    ...data.subscription
                }
            },
        };

        const response: IHttpStrongTypedResponse<ISecondaryMarketStripePaymentResponse> = await AxiosConfig.post(`${CREDITS_ENDPOINTS.SECONDARY_MARKET_BUY_SHARES_WITH_STRIPE_BASE_URL}`, payload);
        if (response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: PaymentActionType.PAYMENT_INTENT_ERROR,
                payload: response.data,
            });

        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        return dispatch({
            type: PaymentActionType.PAYMENT_INTENT_ERROR,
            payload: error,
        });
    }
};
