import { EmptyString, ZERO } from 'config/_const';
import IFootballStatisticsGame from 'interfaces/sports/football/statistics/IFootballStatisticsGame';

export default class FootballStatisticsGameViewModel {
    private appearences: number;
    private lineups: number;
    private minutes: number;
    private number: number;
    private position: string;
    private rating: number;
    private captain: boolean;

    constructor(statistics: Partial<IFootballStatisticsGame>) {
        this.appearences = statistics.appearences ?? ZERO;
        this.lineups = statistics.lineups ?? ZERO;
        this.minutes = statistics.minutes ?? ZERO;
        this.number = statistics.number ?? ZERO;
        this.position = statistics.position ?? EmptyString;
        this.rating = statistics.rating ?? ZERO;
        this.captain = statistics.captain ?? false;
    }

    get Appearences(): number {
        return this.appearences;
    }

    get Lineups(): number {
        return this.lineups;
    }

    get Minutes(): number {
        return this.minutes;
    }

    get Number(): number {
        return this.number;
    }

    get Position(): string {
        return this.position;
    }

    get Rating(): number {
        return this.rating;
    }

    get Captain(): boolean {
        return this.captain;
    }
}
