import { Capacitor } from '@capacitor/core';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import * as ModalActionsType from '../modal/actionsType';
import { getShareModalObject } from './modalObject';
import { Share } from '@capacitor/share';

export const actionShare = (title: string, text: string, url: string, dialogTitle: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
  const isNative = Capacitor.isNativePlatform();
  const dispatchData = () => dispatch({
      type: ModalActionsType.MODAL_DISPLAY,
      payload: {
        modalObject: getShareModalObject(url, text),
      },
    });


  try {
    // return data useful for stats
    if (isNative) {
      await Share.share({
        title,
        text,
        url,
        dialogTitle,
      });
    } return dispatchData();
  } catch (e) {
    if (e.message === 'Share API not available in this browser') {
      return dispatchData();
    }

    return dispatch({
      type: ModalActionsType.MODAL_DISMISS,
    });
  }
};
