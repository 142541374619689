import ReduxActionInterface from "model/ReduxActionInterface";
import { ReduxRootState } from "model/Redux";
import { SPORTS_COUNTRIES_ACTION } from "./actionTypes";
import { ISportsCountries } from "interfaces/sports/ISportsCountries";
import Utils from "utils/Utils";

const ROOT_VALUE_STATE: ReduxRootState<ISportsCountries[]> = {
    loading: false,
    data: [],
};

export const sportsCountriesReducer = (state = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    switch (action.type) {
        case SPORTS_COUNTRIES_ACTION.LOADING:
            return {
                ...state,
                loading: true,
            };
        case SPORTS_COUNTRIES_ACTION.SUCCESS:
            return handleSortsportsCountries(state, action);

        case SPORTS_COUNTRIES_ACTION.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    };

    function handleSortsportsCountries(state: ReduxRootState<ISportsCountries[]>, action: ReduxActionInterface): ReduxRootState<ISportsCountries[]> {
        const data = action.payload.data;
        if (data.length === 0) {
            return {
                ...state,
                loading: false,
                data,
            };
        }
        const countries: ISportsCountries[] = Utils.ensureArray(data);
        const sortedCountries = countries.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            return (nameA < nameB) ? -1 : 1;
        });

        return {
            ...state,
            loading: false,
            data: sortedCountries,
        };
    }
};