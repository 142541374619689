import { AssetCurveType } from 'config/_enums';
import DefaultLiquidityPool from './DefaultLiquidityPool';
import ExponentialLiquidityPool from './ExponentialLiquidityPool';
import StepwiseLiquidityPool from './StepwiseLiquidityPool';
import WaveLiquidityPool from './WaveLiquidityPool';
import LinearLiquidityPool from './LinearLiquidityPool';

class LiquidityPoolFactory {
    private static instance: LiquidityPoolFactory;
    private readonly liquidityPoolService: Record<string, any> = {
        [AssetCurveType.DEFAULT]: DefaultLiquidityPool,
        [AssetCurveType.EXPONENTIAL]: ExponentialLiquidityPool,
        [AssetCurveType.STEPWISE]: StepwiseLiquidityPool,
        [AssetCurveType.WAVE]: WaveLiquidityPool,
        [AssetCurveType.LINEAR]: LinearLiquidityPool,
    };

    public static getInstance(): LiquidityPoolFactory {
        if (!LiquidityPoolFactory.instance) {
            LiquidityPoolFactory.instance = new LiquidityPoolFactory();
        }
        return LiquidityPoolFactory.instance;
    }

    public getLiquidityPoolByType<ClassType>(curveType: string): ClassType {
        return this.liquidityPoolService[curveType];
    }
}

export default LiquidityPoolFactory.getInstance();