export default [
    {
        question: 'benzema.faq.title1',
        answer: 'benzema.faq.answer1',
    },
    {
        question: 'benzema.faq.title2',
        answer: 'benzema.faq.answer2',
    },
    {
        question: 'benzema.faq.title3',
        answer: 'benzema.faq.answer3',
    },
    {
        question: 'benzema.faq.title4',
        answer: 'benzema.faq.answer4',
    },
    {
        question: 'benzema.faq.title5',
        answer: 'benzema.faq.answer5',
    },
    {
        question: 'benzema.faq.title6',
        answer: 'benzema.faq.answer6',
    },
    {
        question: 'benzema.faq.title7',
        answer: 'benzema.faq.answer7',
    },
    {
        question: 'benzema.faq.title8',
        answer: 'benzema.faq.answer8',
    },
    {
        question: 'benzema.faq.title9',
        answer: 'benzema.faq.answer9',
    },
    {
        question: 'benzema.faq.title10',
        answer: 'benzema.faq.answer10',
    },
    {
        question: 'benzema.faq.title11',
        answer: 'benzema.faq.answer11',
    },
];
