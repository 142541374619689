import { DateRangeOutlined, Flag } from '@material-ui/icons';
import AdminAssetInformationFormRedux from 'components/medium/form/admin/admin-asset-form/AdminAssetInformationForm';
import { INITIAL_STATE_FORM } from 'components/medium/form/admin/admin-asset-form/AdminAssetInformationFormInitialData';
import AdminAssetRequestForm from 'components/medium/form/admin/admin-asset-request-form/AdminAssetRequestForm';
import GreenButton from 'components/small/buttons/green-button/GreenButton';
import SecondaryButton from 'components/small/buttons/secondary-button/SecondaryButton';
import { DEFAULT_CONTRACT_TERM } from 'config/_const';
import { AssetCardLevel, AssetCurveType } from 'config/_enums';
import i18next from 'i18next';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import AssetInterface from 'model/AssetModel/AssetInterface';
import IAssetMarketConfiguration from 'model/AssetModel/AssetMarketConfiguration';
import AssetRequestInterface from 'model/AssetRequestInterface';
import UserInterface from 'model/User/UserInterface';
import React from 'react';
import { connect } from 'react-redux';
import { actionFetchAssetCategory } from 'service/asset-category/actions';
import {
    actionFetchAssetRequestById,
    actionUpdateAssetRequestById,
    actionUpdateStatusRequestAndCreateVipAsset,
} from 'service/asset-request/action';
import { getAllAssets } from 'service/assets/actions';
import { actionCreateInfluencerAccount, actionUpdateUserAsAdmin } from 'service/users/actions';
import Utils from 'utils/Utils';
import './AssetRequestProfil.scss';
import AssetRequestProfilProps from './AssetRequestProfilProps';
import AssetRequestProfilState from './AssetRequestProfilState';

class AssetRequestProfil extends React.Component<AssetRequestProfilProps, AssetRequestProfilState> {

    constructor(props: AssetRequestProfilProps) {

        super(props);

        this.state = {
            assetCategory: '',
        };
    }

    componentWillMount = () => {
        this.props.actionFetchAssetRequestById(this.props.match.params.assetRequestId);
        this.props.actionFetchAssetCategory();
        this.fetchActualVipAssets();
    };

    componentDidUpdate = (prevProps: AssetRequestProfilProps) => {
        const assetRequestEndLoading: boolean = !!(prevProps.assetRequest.loading && !this.props.assetRequest.loading);
        if (assetRequestEndLoading) {
            this.fetchActualVipAssets();
        }
    };

    fetchActualVipAssets = () => {

        const actualAssetRequest: AssetRequestInterface | undefined = this.props.assetRequest.data.find((request: AssetRequestInterface) => request && request._id === this.props.match.params.assetRequestId);
        const vipAccount: UserInterface | undefined = this.props.users.data.all?.find((user: UserInterface) => user && actualAssetRequest && Utils.findEmail(user) === actualAssetRequest.email);
        if (vipAccount) {
            const ownedAssets: string[] = vipAccount.ownedAssets.map((asset: AssetInterface) => asset._id);
            const assetsNotLoad: boolean = !!(this.props.assets.data.length < vipAccount.ownedAssets.length);
            if (ownedAssets.length > 0 && assetsNotLoad) {
                this.props.getAllAssets(1, undefined, encodeURIComponent(JSON.stringify({
                    _id: ownedAssets,
                })));
            }
        }
    };

    findActualAssetRequest = (assetRequest: AssetRequestInterface[], keySearch: string, valueSearch: string) => {
        return assetRequest.find((asset: AssetRequestInterface) => asset && keySearch && valueSearch && asset[keySearch] === valueSearch);
    };

    updateStatusAndCreateVipAsset = (assetRequestBody: Object, assetBody: Partial<AssetInterface>, userId: string, assetRequestId: string) => {
        this.props.actionUpdateStatusRequestAndCreateVipAsset(assetBody, userId, assetRequestBody, assetRequestId);
    };

    onInfluencerAccountCreate = (form: any, influencerAccount: UserInterface | undefined) => {

        if (influencerAccount && influencerAccount.type === 'VIP') {
            this.props.actionUpdateUserAsAdmin(form, influencerAccount._id);
        } else {
            this.props.actionCreateInfluencerAccount(form);
        }
    };

    getInitialMarketConfiguration() {
        return {
            ...(INITIAL_STATE_FORM.marketConfiguration as IAssetMarketConfiguration),
            secondaryMarket: {
                ...(INITIAL_STATE_FORM.marketConfiguration as IAssetMarketConfiguration).secondaryMarket,
                isParallelSecondaryMarket: true
            }
        };
    }

    render() {

        const {
            assetRequest,
            users,
            assetsCategories,
        } = this.props;
        const actualAssetRequest: AssetRequestInterface | undefined = assetRequest.data.find((request: AssetRequestInterface) => request && request._id === this.props.match.params.assetRequestId);
        const influencerAccount: UserInterface | undefined = users.data.all?.find((user: UserInterface) => user && actualAssetRequest && user._id === actualAssetRequest.userId);

        return (
            <div className="asset-request-profil">
                {actualAssetRequest && (
                    <div className="content">
                        <div className="identity-container">
                            <div className="content-card request-information">

                                <AdminAssetRequestForm
                                    onInformationsSubmit={(form: any) => this.props.actionUpdateAssetRequestById(actualAssetRequest._id, { ...form })}
                                    initialValues={{
                                        email: actualAssetRequest.email,
                                        firstName: actualAssetRequest.firstName,
                                        lastName: actualAssetRequest.lastName,
                                        address: actualAssetRequest.address,
                                    }}
                                    buttonText={'Update request'}
                                />
                                <div className="items">
                                    <Flag />
                                    <p className="no-margin">Country: {actualAssetRequest.country}</p>
                                </div>

                                <div className="items">
                                    <DateRangeOutlined />
                                    <p className="no-margin">Request created the {Utils.formatDateToCompleteText(new Date(actualAssetRequest.createdAt))}</p>
                                </div>
                            </div>

                            <div className="content-card container-verify-identity">

                                {actualAssetRequest.isIdentityConfirmed === 'CONFIRMED' && <h3 className="title-confirmed">Identity is Confirmed</h3>}
                                {actualAssetRequest.isIdentityConfirmed === 'REFUSED' && (
                                    <>
                                        <h3 className="title-refused">Identity is refused</h3>
                                        <p>You can change the status of the person’s identity at any time</p>
                                    </>
                                )}

                                {actualAssetRequest.isIdentityConfirmed !== 'CONFIRMED' && (
                                    <>
                                        {actualAssetRequest.isIdentityConfirmed !== 'CONFIRMED' && actualAssetRequest.isIdentityConfirmed !== 'REFUSED' && (
                                            <h3>{i18next.t('overview-asset-request-profil-title-verify-account')}</h3>
                                        )}

                                        <div className="status-request">

                                            <div className="accept-button">
                                                <GreenButton
                                                    disabled={false}
                                                    receiveClickEvent={false}
                                                    type="button"
                                                    handleClick={() => this.props.actionUpdateAssetRequestById(actualAssetRequest._id, { isIdentityConfirmed: 'CONFIRMED', status: 'CONFIRMED' })}
                                                    textButton={'Accept identity'} />
                                            </div>

                                            <div>
                                                <SecondaryButton
                                                    disabled={false}
                                                    receiveClickEvent={false}
                                                    type="button"
                                                    handleClick={() => this.props.actionUpdateAssetRequestById(actualAssetRequest._id, { isIdentityConfirmed: 'REFUSED', status: 'REFUSED' })}
                                                    textButton={'Refused identity'} />
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>

                        <div className="content-card">
                            {!influencerAccount && <h3>You need to create influencer account before validate the asset</h3>}
                            {influencerAccount && influencerAccount.ownedAssets.length === 0 && (
                                <div>
                                    <h2 style={{ textAlign: 'center' }}>Confirm Asset Creation</h2>
                                    <AdminAssetInformationFormRedux
                                        initialValues={{
                                            icon: actualAssetRequest.icon || '',
                                            name: `${actualAssetRequest.firstName} ${actualAssetRequest.lastName}`,
                                            description: '',
                                            facebook: actualAssetRequest.facebookLink || '',
                                            facebookFollowers: actualAssetRequest.facebookFollowers || 0,
                                            tiktok: actualAssetRequest.tiktokLink || '',
                                            tiktokFollowers: actualAssetRequest.tiktokFollowers || 0,
                                            youtube: actualAssetRequest.youtube || '',
                                            twitter: actualAssetRequest.twitterLink || '',
                                            twitterFollowers: actualAssetRequest.twitterFollowers || 0,
                                            instagram: actualAssetRequest.instagramLink || '',
                                            instagramFollowers: actualAssetRequest.instagramFollowers || 0,
                                            snapchat: actualAssetRequest.snapchatLink || '',
                                            snapchatFollowers: actualAssetRequest.snapchatFollowers || 0,
                                            subtitle: '',
                                            contractTerm: actualAssetRequest.contractTerm || DEFAULT_CONTRACT_TERM,
                                            IRLScoreDetails: undefined,
                                            cardLevel: AssetCardLevel.Rookie,
                                            isRetired: false,
                                            marketConfiguration: this.getInitialMarketConfiguration(),
                                            scoreBoosted: false,
                                            assetCurveConfiguration: {
                                                curveType: AssetCurveType.DEFAULT,
                                                factors: {
                                                    power: 0,
                                                    modulationFactorParam: 0,
                                                    cosFactor: 0,
                                                    sinFactor: 0,
                                                    plateauLength: 0,
                                                    plateauHeightFactor: 0,
                                                    endPrice: 0
                                                }
                                            }
                                        }
                                        }
                                        categories={assetsCategories.data && assetsCategories.data.length > 0 ? assetsCategories.data.map((cat: AssetCategoryInterface) => {
                                            return { label: cat.name, value: cat._id };
                                        }) : []}
                                        buttonText={'Create asset'}
                                        onAssetUpdate={(form: any) => this.updateStatusAndCreateVipAsset(
                                            { status: 'CONFIRMED' },
                                            { ...form },
                                            influencerAccount._id,
                                            actualAssetRequest._id,
                                        )}
                                        editMode={false} />
                                </div>)}

                            {influencerAccount && influencerAccount.ownedAssets.length > 0 && (
                                <h3>Influencer Asset Created</h3>
                            )}
                        </div>

                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: AssetRequestProfilProps) => ({
    assetRequest: state.assetRequest,
    users: state.users,
    assets: state.assets,
    assetsCategories: state.assetsCategories,
});

export default connect(mapStateToProps, {
    actionFetchAssetRequestById,
    actionUpdateAssetRequestById,
    actionCreateInfluencerAccount,
    actionUpdateUserAsAdmin,
    getAllAssets,
    actionFetchAssetCategory,
    actionUpdateStatusRequestAndCreateVipAsset,
})(AssetRequestProfil);
