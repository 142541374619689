import { DEFAULT_TIMEZONE, LANGUAGES, OPTIONS_FOR_DATE, OPTIONS_FOR_TIME } from 'config/_const';
import Utils from 'utils/Utils';

declare global {
    interface String {
        capitalizeFirstLetter(): string;
        safeIsNullOrEmpty(): boolean;
        toDate(): Date;
        getCurrencySymbol(): string;
        toTimezoneFormatForCountdown(): Date;
        toTimeStringFormat(timezone: string): string;
        toDateString(): string;
        toDateAndTimeString(transformDateToUserTimezone: boolean, showTimezone: boolean): string;
        getExtractedNumber(): number;
        getRemovedWhiteSpace(): string;
    }
}

String.prototype.capitalizeFirstLetter = function (): string {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.safeIsNullOrEmpty = function (): boolean {
    if (typeof this === 'undefined' || this === null)
        return true;
    return this !== '';
};

String.prototype.toDate = function (): Date {
    if (this === '')
        return new Date();
    return new Date(String(this));
};

String.prototype.getCurrencySymbol = function (): string {
    return (0).toLocaleString(Utils.getCurrentLanguage(), { style: 'currency', currency: this.toString(), minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim();
};

String.prototype.toTimezoneFormatForCountdown = function (): Date {
    return new Date(new Date(this.toString()).toLocaleString(LANGUAGES.EN, { timeZone: DEFAULT_TIMEZONE }));
};

String.prototype.toTimeStringFormat = function (timezone: string): any {
    return new Date(this.toString()).toLocaleTimeString(Utils.getCurrentLanguage(), { ...OPTIONS_FOR_TIME, timeZone: timezone });
};

String.prototype.toDateString = function (): string {
    return new Date(this.toString()).toLocaleDateString(Utils.getCurrentLanguage(), OPTIONS_FOR_DATE);
};

String.prototype.toDateAndTimeString = function (transformDateToUserTimezone: boolean, showTimezone: boolean): string {
    const timezone: string | undefined = transformDateToUserTimezone ? Utils.getUserTimezone() : undefined;
    return new Date(this.toTimezoneFormatForCountdown()).toLocaleDateString(Utils.getCurrentLanguage(), { ...OPTIONS_FOR_DATE, ...OPTIONS_FOR_TIME, timeZoneName: showTimezone ? 'short' : undefined, timeZone: timezone ?? undefined });
};

String.prototype.getExtractedNumber = function (): number {
    const extraNumber = this.replace(/\D/g, "");
    return Number(extraNumber);
};

String.prototype.getRemovedWhiteSpace = function (): string {
    return this.replace(/\s/g, '');
};


export { };
