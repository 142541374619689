import { ASSETS_CATEGORIES } from "config/_const";
import { BasketballScoreViewModel } from "view-model/ScoreViewModel/BasketballScoreViewModel";
import { FootballScoreViewModel } from "view-model/ScoreViewModel/FootballScoreViewModel";

class FactoryScoreViewModel {
    private static instance: FactoryScoreViewModel;
    private readonly sportServices: Record<string, any> = {
        [ASSETS_CATEGORIES.Football]: FootballScoreViewModel,
        [ASSETS_CATEGORIES.Basketball]: BasketballScoreViewModel,
    }

    public static getInstance(): FactoryScoreViewModel {
        if (!FactoryScoreViewModel.instance) {
            FactoryScoreViewModel.instance = new FactoryScoreViewModel();
        }
        return FactoryScoreViewModel.instance;
    }

    public getServiceBySportCategory<ClassType>(sportCategory: string): ClassType {
        return this.sportServices[sportCategory];
    }
}

export default FactoryScoreViewModel.getInstance();