import { WithdrawalRequestsHeadCell } from "../../../../config/tableConfig";
import {
    blobDownload,
    makeCSV,
} from "../../../../utils/download-csv/download-csv.utils";
import HeadCell from "model/Table/TableHeader";
import { TWithdraws } from "model/Table/TableHeader";
import Row from "../../../../model/Table/Row";
import { ZERO } from "config/_const";

type looseType = string | number | symbol;

const generateTableData = (
    headerTable: HeadCell<any>[],
    rows: Row<TWithdraws>[]
) => {
    const generateCellInRow = (cell: any) => {
        return cell && typeof cell.data !== "undefined" ? cell.data : null;
    };
    const generateTable = (row: Row<Object>) => {
        return headerTable.map(
            (column: HeadCell<Object>) =>
                row.cells[column.id] && generateCellInRow(row.cells[column.id])
        );
    };

    const tabelData = rows.map((row: Row<Object>) => generateTable(row));
    return tabelData;
};

export const generateDownload = (
    fileName: string,
    headerTable: HeadCell<any>[],
    rows: Row<TWithdraws>[]
) => {
    const headerDataWithoutShowId = headerTable.filter((row) => {
        if (row.id !== WithdrawalRequestsHeadCell[ZERO].id) return row.label;
    });
    const headerData = headerDataWithoutShowId.map((row) => row.id);
    const tableData = generateTableData(headerDataWithoutShowId, rows);
    const combineData: [looseType[], ...any[][]] = [headerData, ...tableData];
    const convertedCsvData = makeCSV(combineData);
    blobDownload(fileName, convertedCsvData);
};
