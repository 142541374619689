import dayjs from 'dayjs';
import React from 'react';
import { Trans } from 'react-i18next';
import CountdownWidget from '../countdown-widget/CountdownWidget';
import './EuroCountdown.scss';
import IEuroCountdownProps from './IEuroCountdownProps';
import EuroCup from 'components/icons/EuroCup';


const EuroCountdown: React.FC<IEuroCountdownProps> = () => {
    const countdownTime = dayjs('2024-07-14T23:00:00').utcOffset(2);

    return (
        <div className="euro-countdown-component">
            <EuroCup height={40} width={40} />
            <span className='title-euro-countdown-component'><Trans i18nKey={"euro.2024.title"} /></span>
            <div className='euro-countdown-wrapper'>
                <CountdownWidget date={countdownTime.toDate().toString()} titleTranslationKey='onboarding.welcome.bonus.description' />
            </div>
        </div>
    );
};

export default EuroCountdown;
