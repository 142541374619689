import React from 'react';
import './BottomTabBarButton.scss';
import { NavLink } from 'react-router-dom';
import BottomTabBarButtonProps from './BottomTabBarButtonProps';
import BottomBarProfileIcon from "../../small/bottom-bar-profile-icon/BottomBarProfileIcon";
import useAccount from 'hooks/use-account/useAccount';
import IUseAccount from 'hooks/use-account/IUseAccount';
import { MENU_ITEMS_NAME } from 'config/_const';
import i18n from '18n';

const BottomTabBarButton: React.FC<BottomTabBarButtonProps> = ({ url, Icon, titleTranslateKey, name, extraFields }) => {
    const { accountViewModel }: IUseAccount = useAccount();
    const { avatar: avatarSrc, isAuthenticated, isThereUserAvatar } = accountViewModel;
    const showProfileIcon = isAuthenticated && name === MENU_ITEMS_NAME.MY_PROFILE && isThereUserAvatar;
    return (
        <NavLink
            to={url}
        >
            <div>
                {showProfileIcon ? <BottomBarProfileIcon avatarSrc={avatarSrc} /> : <Icon />}
                <p>{i18n.t(titleTranslateKey)}</p>
            </div>
            {extraFields &&
                <div className='extrafield'>
                    <extraFields.icon />
                    <span className="extra-field">{extraFields.text}</span>
                </div>
            }

        </NavLink>
    );
};

export default BottomTabBarButton;
