import LeagueAttribution from 'model/League/LeagueAttribution';
import { RootState } from 'model/Redux';
import { createSelector } from 'reselect';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import WalletViewModel from 'view-model/WalletViewModel/WalletViewModel';
import { accountAsViewModelSelector } from '../auth/selectors';
import { selectorGetWalletAsViewModel } from '../wallet/selector';

export const leagueAttributionSelector = createSelector<RootState, AccountViewModel, WalletViewModel, LeagueAttribution>(
    accountAsViewModelSelector,
    selectorGetWalletAsViewModel,
    (auth: AccountViewModel, wallet: WalletViewModel) => {
        return new LeagueAttribution(auth, wallet);
    },
);

export const userLeagueIsWhiteListedSelector = createSelector(
    leagueAttributionSelector,
    (leagueAttribution: LeagueAttribution) => leagueAttribution.isWhitelisted(),
)