import AssetSportScoreDetails from 'components/medium/asset-sport-score-details-modal/AssetSportScoreDetails';
import AssetSellInitiator from 'components/medium/asset-transaction/asset-sell-initiator/AssetSellInitiator';
import Connect from 'components/medium/connect/Connect';
import AdminAssetInformationForm from 'components/medium/form/admin/admin-asset-form/AdminAssetInformationForm';
import FunAssetModal from 'components/medium/modal-fun-asset/FunAssetModal';
import ModalIncompleteTeam from 'components/medium/modal-incomplete-team/ModalIncompleteTeam';
import TournamentConfirmationOnboarding from 'components/medium/onboarding/TournamentConfirmationOnboarding.tsx/TournamentConfirmationOnboarding';
import PostSignUpView from 'components/medium/post-signup-view/PostSignUpView';
import RegisterGiftView from 'components/medium/register-gift-view/RegisterGiftView';
import RewardCardsModal from 'components/medium/reward-modal/cards/RewardCardsModal';
import RewardModal from 'components/medium/reward-modal/rewardModal';
import ScoreBoosterModal from 'components/medium/score-booster/score-boost-modal/ScoreBoosterModal';
import StripeCheckoutForm from 'components/medium/stripe-elements/stripeCheckoutForm';
import SubscribeConfirmationModal from 'components/medium/tournament/subscribe-confirmation-modal/SubscribeConfirmationModal';
import BuyTournamentSubscriptionConfirmation from 'components/medium/tournament/tournament-ticket/BuyTournamentTicketConfirmation/BuyTournamentTicketConfirmation';
import BuyTournamentSubscriptionError from 'components/medium/tournament/tournament-ticket/BuyTournamentTicketError/BuyTournamentTicketError';
import Tutorial from 'components/medium/tutorial-tournament/Tutorial';
import ConfirmSale from 'components/medium/vip/confirm-sale/ConfirmSale';
import WithdrawModal from 'components/medium/withdraw-modal/WithdrawModal';
import AiPriseKYC from 'components/small/AiPriseKYC/AiPriseKYC';
import BenzemaConfirmBuy from 'components/small/benzema-confirm-buy/BenzemaConfirmBuy';
import Confirm from 'components/small/confirm/Confirm';
import ConfirmationModal from 'components/small/confirmation-modal/ConfirmationModal';
import KycResultDialog from 'components/small/kyc-result-dialog/KycResultDialog';
import LoadingModal from 'components/small/loading-modal/LoadingModal';
import MessageModal from 'components/small/message-modal/MessageModal';
import NotificationPermissionModal from 'components/small/notification-permission-modal/NotificationPermissionModal';
import PrivateSellConfirm from 'components/small/private-sell/private-sell-confirm/PrivateSellConfirm';
import SessionExpired from 'components/small/session-expired/SessionExpired';
import ConfirmTeam from 'components/small/team/confirm-team/ConfirmTeam';
import TournamentInformationLeaguePopup from 'components/small/tournament-information-league-popup/TournamentInformationLeaguePopup';
import ValidIdentity from 'components/small/valid-identity/ValidIdentity';
import VerifyEmailModal from 'components/small/verify-email-modal/VerifyEmailModal';
import VideoModal from 'components/small/video-modal/VideoModal';

const FACTORY_MODAL: { [key: string]: React.ElementType; } = {
    CONFIRM: Confirm,
    MESSAGE_MODAL: MessageModal,
    LOADING_MODAL: LoadingModal,
    ASSET_FORM: AdminAssetInformationForm,
    PRIVATE_SELL_CONFIRM: PrivateSellConfirm,
    CONFIRM_SALE: ConfirmSale,
    SIGN_UP: Connect,
    WITHDRAW_MODAL: WithdrawModal,
    CONFIRM_TEAM: ConfirmTeam,
    PAYMENT_FORM: StripeCheckoutForm,
    TOURNAMENT_TUTO: Tutorial,
    REWARD_MODAL: RewardModal,
    GIFT_REGISTER: RegisterGiftView,
    POST_SIGNUP: PostSignUpView,
    INCOMPLETE_TEAM: ModalIncompleteTeam,
    SUBSCRIBE_CONFIRM_MODAL: SubscribeConfirmationModal,
    VERIFY_EMAIL: VerifyEmailModal,
    SESSION_EXPIRED: SessionExpired,
    NOTIFICATION_PERMISSION_MODAL: NotificationPermissionModal,
    CONFIRMATION_MODAL: ConfirmationModal,
    VIDEO_MODAL: VideoModal,
    BUY_BOOST_SCORE: ScoreBoosterModal,
    ASSET_SPORT_SCORE_DETAILS: AssetSportScoreDetails,
    AI_PRISE_KYC: AiPriseKYC,
    BENZEMA_PACK: BenzemaConfirmBuy,
    TOURNAMENT_INFORMATION_LEAGUE_POPUP: TournamentInformationLeaguePopup,
    IDENTITY_VERIFICATION: ValidIdentity,
    REWARD_MODAL_CARDS: RewardCardsModal,
    TOURNAMENT_CONFIRMATION_MODAL_ON_BOARDING: TournamentConfirmationOnboarding,
    ASSET_SELL_INITIATOR: AssetSellInitiator,
    KYC_RESULT_DIALOG: KycResultDialog,
    BUY_TOURNAMENT_SUBSCRIPTION_CONFIRMATION: BuyTournamentSubscriptionConfirmation,
    FUN_ASSET_MODAL: FunAssetModal,
    BUY_TOURNAMENT_SUBSCRIPTION_ERROR: BuyTournamentSubscriptionError
};

export default FACTORY_MODAL;