import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import * as BOTTOMBAR_ACTIONS from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState = {
    loading: false,
    data: { isOpen: true }
};

export const bottombarReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {
        case BOTTOMBAR_ACTIONS.TOGGLE_BOTTOM_BAR:
            return {
                ...state,
                data: action.payload
            };

        default: return state;
    }
};
