import i18n from '18n';
import Application from 'Application';
import { TRENDEX_HTTP_STATUS_NOK } from 'config/_const';
import { TournamentProgress } from 'config/_enums';
import ITeam from 'model/Team/ITeam';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allAssetsSelector } from 'service/assets/selectors';
import { getUserPersonalTournament, subscribeToUserPersonalTournament } from 'service/auth/actions';
import { selectorAccountViewModel } from 'service/auth/selectors';
import { managementNotification } from 'service/notification/action';
import { activeUserTeamSelector } from 'service/teams/selectors';
import IAssetIdsAndScores from 'service/user-personal-tournament/IAssetIdsAndScores';
import UserPersonalTournamentHelper from 'service/user-personal-tournament/UserPersonalTournamentHelper';
import UserPersonalTournamentLocalStorageHelper from 'service/user-personal-tournament/UserPersonalTournamentLocalStorageHelper';
import PersonalTournamentSubscriptionStatusEnum from 'types/PersonalTournamentSubscriptionStatusEnum';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import TournamentViewModel from 'view-model/Tournament/TournamentViewModel/TournamentViewModel';
import UserPersonalTournamentViewModel from 'view-model/UserPersonalTournamentViewModel/UserPersonalTournamentViewModel';
import IUsePersonalTournament from './IUsePersonalTournament';

const usePersonalTournament = (): IUsePersonalTournament => {
    const dispatch = useDispatch();
    const accountViewModel: AccountViewModel = useSelector(selectorAccountViewModel);
    const personalTournament: TournamentViewModel = React.useMemo(() => new TournamentViewModel({ ...accountViewModel.PersonalTournament, tournamentProgress: TournamentProgress.PERSONAL_TOURNAMENT }), [accountViewModel]);
    const userTeam: ITeam | undefined = useSelector(activeUserTeamSelector);
    const assets: AssetViewModel[] = useSelector(allAssetsSelector);
    const userPersonalTournamentLocalStorageHelper: UserPersonalTournamentLocalStorageHelper = new UserPersonalTournamentLocalStorageHelper();

    const [userPersonalTournamentViewModel, setUserPersonalTournamentViewModel] = React.useState<UserPersonalTournamentViewModel>(new UserPersonalTournamentViewModel(userPersonalTournamentLocalStorageHelper.Tournament));
    const generateFakeTournament = React.useCallback((userPersonalTournamentLocalStorageHelper: UserPersonalTournamentLocalStorageHelper, accountViewModel: AccountViewModel, assetsIdsAndScore: IAssetIdsAndScores[], userTeam: ITeam, forceTournamentGeneration: boolean = false) => {
        if (!userTeam || assetsIdsAndScore.length === 0)
            return;
        const userPersonalTournamentHelper: UserPersonalTournamentHelper = new UserPersonalTournamentHelper(accountViewModel, userTeam, assetsIdsAndScore, userPersonalTournamentLocalStorageHelper, forceTournamentGeneration);
        setUserPersonalTournamentViewModel(new UserPersonalTournamentViewModel(userPersonalTournamentHelper.Tournament));
    }, []);

    useEffect(() => {
        if (!accountViewModel.AlreadyRegisteredForTournament || !userTeam || assets.length === 0 || userPersonalTournamentLocalStorageHelper.Tournament.subscriptions.length > 0)
            return;
        const assetsIdsAndScore: IAssetIdsAndScores[] = assets.map((asset: AssetViewModel) => ({ id: asset.AssetId, score: asset.IRLScore }))
        generateFakeTournament(userPersonalTournamentLocalStorageHelper, accountViewModel, assetsIdsAndScore, userTeam);
    }, [accountViewModel, assets, generateFakeTournament, userPersonalTournamentLocalStorageHelper, userTeam]);

    const subscribe = (onSubscribedSuccessCallback?: () => void) => {
        if (accountViewModel.PersonalTournamentSubscriptionState !== PersonalTournamentSubscriptionStatusEnum.NOT_SUBSCRIBED_YET) {
            dispatch(managementNotification(true, i18n.t('tournament.subscribe.unavailable'), TRENDEX_HTTP_STATUS_NOK));
            return;
        }
        dispatch(subscribeToUserPersonalTournament(accountViewModel, onSubscribedSuccessCallback));
    }

    return {
        isPersonalTournamentInProgress: accountViewModel.IsPersonalTournamentInProgress,
        userPersonalTournamentViewModel,
        isUserPersonalTournamentAvailable: accountViewModel.IsPersonalTournamentAvailable,
        personalTournament,
        isPersonalTournamentSubscriptionOpen: Application.getInstance().UserPersonalTournamentConfig.enabled && accountViewModel.PersonalTournamentSubscriptionsOpen,
        subscribe,
        fetchUserTournament: () => dispatch(getUserPersonalTournament()),
    };
}

export default usePersonalTournament;