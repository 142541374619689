import React from "react";
import { PanelProps } from "./PanelProps";
import { PanelState } from "./PanelState";
import "./Panel.scss";
class Panel extends React.Component<PanelProps, PanelState> {

    HTMLDivElement: HTMLDivElement | 0 = 0;

    constructor(props: PanelProps) {
        super(props);
        this.state = {
            height: 0
        };
    }

    componentDidMount() {
        const timer = window.setTimeout(() => {
            if (!this.HTMLDivElement) {
                return;
            }
            this.setState({
                height: this.HTMLDivElement.scrollHeight
            });
        }, 333);
        return () => clearTimeout(timer);
    }

    render() {
        const { questions, answer, activeTab, index, activateTab } = this.props;
        const { height } = this.state;
        const isActive = activeTab === index;
        const innerStyle = {
            height: `${isActive ? height : 0}px`
        }

        return (
            <div className='panel'
                role='tabpanel'
                aria-expanded={isActive}>
                <button className='panel__label'
                    role='tab'
                    onClick={activateTab}>
                    {questions}
                </button>
                <div className='panel__inner'
                    ref={(thisDiv: HTMLDivElement) => { this.HTMLDivElement = thisDiv }}
                    style={innerStyle}
                    aria-hidden={!isActive}>
                    <p id='panel__content'>
                        {answer}
                    </p>
                </div>
            </div>
        );
    }
}

export default Panel;