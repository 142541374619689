import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { OverviewAssetsHeader } from 'model/Table/TableHeader';
import Row from 'model/Table/Row';
import GenericTable from 'components/medium/generic-table/GenericTable';
import GreenButton from 'components/small/buttons/green-button/GreenButton';
import LoginIcon from 'components/small/login-icon/LoginIcon';
import { AssetsCrudHeadCell } from 'config/tableConfig';
import './OverviewAssets.scss';
import { createAsset, getAllAssets } from 'service/assets/actions';
import OverviewAssetsProps from './OverviewAssetsProps';
import AssetInterface from 'model/AssetModel/AssetInterface';
import { Link } from 'react-router-dom';
import SecondaryButton from 'components/small/buttons/secondary-button/SecondaryButton';
import CustomFabButton from 'components/small/fab-button/FabButtonCustom';
import { Add } from '@material-ui/icons';
import { managementModal } from 'service/modal/action';
import { actionFetchAssetCategory } from 'service/asset-category/actions';
import { loginPreview } from 'service/auth/actions';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import slugify from 'slugify';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import { createCloudCacheInvalidation } from 'service/cloudfront-cache-invalidation/actions';
import { CLOUD_FRONT_CACHED_ROUTES_KEYS } from 'config/_const';

class OverviewAssets extends React.Component<OverviewAssetsProps> {
    constructor(props: OverviewAssetsProps) {
        super(props);
    }

    componentWillMount = () => {
        this.props.getAllAssets(1);
        this.props.actionFetchAssetCategory();
    }

    fetchAssetsByPagination = (page: number) => {
        const filter: { [key: string]: any } = {};
        this.props.getAllAssets(
            page + 1,
            JSON.stringify(filter));
    }

    render = () => {
        const {
            assets,
            assetsCategories,
            loginPreview,
            history,
        } = this.props;

        const assetsRows: Row<OverviewAssetsHeader>[] = [];

        if (assets.data.length > 0) {
            for (const asset of assets.data as AssetInterface[]) {
                const row: OverviewAssetsHeader = {
                    name: { data: asset.name, textCellColor: 'white' },
                    slug: { data: asset.slug, textCellColor: 'white' },
                    availableSupply: { data: asset.availableSupply, textCellColor: 'white' },
                    totalPriceChange: { data: asset.totalPriceChange, textCellColor: 'white' },
                    lastValueIncrement: { data: asset.lastValueIncrement, textCellColor: 'white' },
                    currentValue: { data: asset.currentValue, textCellColor: 'white' },
                    action: {
                        data: (
                            <Link to={`/assets/${asset.slug}`}>
                                <SecondaryButton
                                    disabled={false}
                                    receiveClickEvent={false}
                                    textButton={'Manage'}
                                    handleClick={() => undefined}
                                    type="button" />
                            </Link>
                        ),
                    },
                    login: {
                        data: (
                            <GreenButton
                                disabled={false}
                                receiveClickEvent={false}
                                type="button"
                                textButton={<LoginIcon />}
                                handleClick={() => loginPreview(asset._id, history, "vip", false)} />
                        ),
                    },
                };

                assetsRows.push({
                    id: asset._id,
                    cells: row,
                });
            }

        }
        return (
            <div className="overview-assets">
                <div className="action-buttons">
                    <PrimaryButton
                        type='button'
                        disabled={assets.loading}
                        receiveClickEvent={false}
                        textButton={'Clear assets cache'}
                        handleClick={() => this.props.createCloudCacheInvalidation([CLOUD_FRONT_CACHED_ROUTES_KEYS.TOP_ASSETS, CLOUD_FRONT_CACHED_ROUTES_KEYS.ASSET_DETAIL])}
                        loading={assets.loading} />
                </div>
                <div className="card-style">
                    <GenericTable
                        loadMoreData={(page: number) => this.fetchAssetsByPagination(page)}
                        rows={assetsRows}
                        headerTable={AssetsCrudHeadCell}
                        enabledCheckbox={false}
                        enableCreateButton={false}
                        tableHeight={'100vh'} />
                </div>

                <CustomFabButton handleClick={() => this.props.managementModal(
                    true,
                    {
                        display: true,
                        disableBackDrop: false,
                        showBackArrow: false,
                        fullScreen: true,
                        type: 'ASSET_FORM',
                        propsCustom: {
                            editMode: false,
                            buttonText: 'Add Asset',
                            categories: (
                                assetsCategories.data && assetsCategories.data.length > 0 ? assetsCategories.data.map((cat: AssetCategoryInterface) => {
                                    return { label: cat.name, value: cat._id };
                                }) : []),
                            onAssetUpdate: (form: any) => {
                                this.props.createAsset(form);
                                this.props.managementModal(false);
                                this.props.history.push(`/assets/${slugify(form.name, { remove: /'/ }).toLowerCase()}`);
                            },
                        },
                    },
                )}>
                    <Add />
                </CustomFabButton>
            </div>
        );
    }
}

const mapStateToProps = (state: OverviewAssetsProps) => ({
    assets: state.assets,
    assetsCategories: state.assetsCategories,
});

export default compose(
    connect(mapStateToProps, {
        getAllAssets,
        managementModal,
        actionFetchAssetCategory,
        createAsset,
        loginPreview,
        createCloudCacheInvalidation,
    }),
)(OverviewAssets);
