import { createSelector, Selector } from 'reselect';
import { userIdSelector } from 'service/auth/selectors';
import { ReduxRootState, RootState } from '../../model/Redux';
import ITeam from '../../model/Team/ITeam';

export const teamsSelector: Selector<RootState, ReduxRootState<ITeam[]>> = state => state.teams;
export const teamsLoadingSelector: Selector<RootState, boolean> = state => state.teams.loading;


// TODO - /!\ TO REWORK FOR MULTIPLE TEAM HANDLING /!\
export const activeUserTeamSelector = createSelector<RootState, ReduxRootState<ITeam[]>, string, ITeam | undefined>(
    teamsSelector,
    userIdSelector,
    (teams, userId) => {
        return teams.data.find(team => team.owner._id.toString() === userId && team.enabled);
    },
);

export const totalTokensInTeam = createSelector<RootState, ITeam | undefined, number>(
    activeUserTeamSelector,
    (team: ITeam | undefined) => {
        if (!team || team.teamAssets.length === 0)
            return 0;
        return team.teamAssets.map(teamAsset => teamAsset.numberOfShares).reduce((a, b) => a + b, 0);
    },
);

export const isTeamsLoadingSelector = createSelector<ReduxRootState<ITeam[]>, boolean, boolean>(
    teamsLoadingSelector,
    (loading: boolean) => loading,
);
