import { ZERO } from 'config/_const';
import IFootballStatisticsTackle from 'interfaces/sports/football/statistics/IFootballStatisticsTackle';

export default class FootballStatisticsTackleViewModel {
    private total: number;
    private blocks: number;
    private interceptions: number;

    constructor(statistics: Partial<IFootballStatisticsTackle>) {
        this.total = statistics.blocks ?? ZERO;
        this.blocks = statistics.blocks ?? ZERO;
        this.interceptions = statistics.interceptions ?? ZERO;
    }

    get Total(): number {
        return this.total;
    }

    get Blocks(): number {
        return this.blocks;
    }

    get Interceptions(): number {
        return this.interceptions;
    }
}
