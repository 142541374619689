import BuyPacksButtonProps from 'components/small/buy-packs-button/BuyPacksButtonProps';
import { AssetTransactionType } from 'config/_enums';
import { getWebflowEndPoint } from 'config/externalEndPoints';
import IUseAccount from 'hooks/use-account/IUseAccount';
import useAccount from 'hooks/use-account/useAccount';
import useAssetTransactionSlides from 'hooks/use-asset-transaction-slideup/useAssetTransactionSlides';
import { RootState } from 'model/Redux';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectorRemainingTokensPacks } from 'service/asset-current-information/selectors';
import { userLeagueIsWhiteListedSelector } from 'service/league/selectors';
import BuyPacksButtonViewModel from 'view-model/BuyPacksButtonViewModel/BuyPacksButtonViewModel';
import IWithBuyPackButton from './IWithBuyPackButton';

const withBuyPacksButton = (Component: React.FC<BuyPacksButtonProps>): React.FC<IWithBuyPackButton> => {
    const MemoComponent: React.FC<BuyPacksButtonProps> = React.memo(Component);

    const Wrapper: React.FC<IWithBuyPackButton> = ({ assetViewModel, t: translate }) => {
        const { openAssetTransaction } = useAssetTransactionSlides();
        const packsSold: number = useSelector((state: RootState) => selectorRemainingTokensPacks(state, assetViewModel.AssetId));
        const userLeagueIsWhiteListed = useSelector(userLeagueIsWhiteListedSelector);
        const { accountViewModel }: IUseAccount = useAccount();

        const buyPacksButtonViewModel: BuyPacksButtonViewModel = React.useMemo(() => {
            return new BuyPacksButtonViewModel({ packsSold, userLeagueIsWhiteListed, customBenzemaProfilBeforePublicSale: assetViewModel.isCustomBenzemaProfilPageBeforePublicSales() });
        }, [packsSold, userLeagueIsWhiteListed]);

        const getQueries = (): string => {
            if (!accountViewModel.isAuthenticated)
                return ''
            return `email=${accountViewModel.Email}&firstName=${accountViewModel.firstName}&lastName=${accountViewModel.LastName}`;
        }

        const onClick = (): void => {
            if (buyPacksButtonViewModel.TotalPacksSold) {
                if (accountViewModel.IsUserWhiteListed() || userLeagueIsWhiteListed)
                    return;
                window.open(`${getWebflowEndPoint()}?${getQueries()}`, '_blank');
                return;
            }
            return openAssetTransaction(assetViewModel.AssetId, AssetTransactionType.BUY, true, true);
        };

        return <MemoComponent translate={translate} onClick={onClick} buyPacksButtonViewModel={buyPacksButtonViewModel} />
    }

    return React.memo(Wrapper);
};

export default withBuyPacksButton;