import { TOURNAMENT_WIN_ENDPOINTS } from 'config/endpoints';
import AxiosConfig from 'config/axiosConfig';
import { HTTP_STATUS_OK } from 'config/_const';
import { IHttpStrongTypedResponse } from 'model/IHttpResponse';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { WIN_RESULTS } from './actionsType';
import ITournamentWin from 'model/Tournament/ITournamentWin';

export const getAllTournamentWins = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: WIN_RESULTS.LOADING });
        const getAllWinsResponse: IHttpStrongTypedResponse<ITournamentWin[]> = await AxiosConfig.get(`${TOURNAMENT_WIN_ENDPOINTS.ALL_WINS}`);
        if (getAllWinsResponse.data.status !== HTTP_STATUS_OK)
            throw new Error(getAllWinsResponse.data.message);
        return dispatch({
            type: WIN_RESULTS.SUCCESS,
            payload: {
                allWins: getAllWinsResponse.data,
            },
        });
    } catch (error) {
        let message = 'Server.generic-error';
        if (error instanceof TypeError)
            message = error.message;
        return dispatch({
            type: WIN_RESULTS.ERROR,
            payload: {
                message,
            },
        });
    }
};
