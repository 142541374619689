import React from 'react';
import './GreyButton.scss';
import IButtonProps from '../IButtonProps';

const GreyButton: React.FC<IButtonProps> = (props) => {
    return (
        <button
            className='grey-button'
            type={props.type}
            onClick={(e: any) => props.handleClick ? props.receiveClickEvent ? props.handleClick(e) : props.handleClick() : undefined} >
            {props.textButton}
        </button>
    );
};

export default GreyButton;
