import React from 'react'

const PackIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ color, width, height }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 32} height={height ?? 32} viewBox="0 0 32 32">
            <g id="Groupe_5698" data-name="Groupe 5698" transform="translate(-1034 -965)">
                <rect id="Rectangle_2200" data-name="Rectangle 2200" width={width ?? 32} height={height ?? 32} transform="translate(1034 965)" fill={color ?? "#15182b"} opacity="0" />
                <g id="vip-card-icon" transform="translate(1039 965.004)">
                    <path id="Tracé_3968" data-name="Tracé 3968" d="M3.591,6.622H7.4L4.583.971A.674.674,0,0,1,5.789.37l3.117,6.25h3.156L15.179.37a.674.674,0,1,1,1.206.6L13.567,6.622h3.927a3.6,3.6,0,0,1,3.591,3.591V28.405a3.579,3.579,0,0,1-1.055,2.534l-.005.005A3.576,3.576,0,0,1,17.494,32H3.591A3.6,3.6,0,0,1,0,28.405V10.213A3.6,3.6,0,0,1,3.591,6.622Zm.8,9.364a.433.433,0,1,1,0-.865H9.054a1.59,1.59,0,0,0,0,.865ZM10.544,14.6a.956.956,0,1,1-.956.956.955.955,0,0,1,.956-.956Zm1.487.523H16.7a.433.433,0,1,1,0,.865H12.031a1.576,1.576,0,0,0,.06-.432,1.482,1.482,0,0,0-.06-.432ZM4.388,28.314a.433.433,0,1,1,0-.865H9.054a1.59,1.59,0,0,0,0,.865Zm6.156-1.388a.956.956,0,1,1-.956.956.955.955,0,0,1,.956-.956Zm1.487.523H16.7a.433.433,0,1,1,0,.865H12.031a1.576,1.576,0,0,0,.06-.432,1.482,1.482,0,0,0-.06-.432Zm.779-9.419h1.932a4.587,4.587,0,0,1,1.2.122,1.375,1.375,0,0,1,.633.354,1.232,1.232,0,0,1,.286.562,5.07,5.07,0,0,1,.076,1.021v.641a2.621,2.621,0,0,1-.146,1.026,1,1,0,0,1-.534.5,2.546,2.546,0,0,1-1.016.172h-.513V25.4H12.812V18.03Zm1.919,1.26v1.878c.055,0,.1.005.141.005a.442.442,0,0,0,.365-.13.957.957,0,0,0,.1-.539V19.9a.715.715,0,0,0-.117-.492.728.728,0,0,0-.49-.115Zm-3.016-1.26V25.4H9.794V18.03Zm-2.578,0L8.164,25.4H5.26L4.148,18.03H6.172q.344,3.051.5,5.159.152-2.133.315-3.784l.128-1.375Zm1.573-8.7.682-1.365h-7.8a2.249,2.249,0,0,0-2.242,2.242V28.405a2.249,2.249,0,0,0,2.242,2.242h13.9a2.243,2.243,0,0,0,1.583-.656l0,0a2.232,2.232,0,0,0,.656-1.583V10.213a2.249,2.249,0,0,0-2.242-2.242h-4.6l-.982,1.966a1.969,1.969,0,1,1-1.427-.612,1.594,1.594,0,0,1,.224.01Z" transform="translate(0 0)" fill={color ?? "#15182b"} />
                </g>
            </g>
        </svg>
    )
};

export default PackIcon
