import WrapperSelect from 'components/small/wrapper-select/WrapperSelect';
import { ASSETS_CATEGORIES, WRAPPER_SELECT_TYPE } from 'config/_const';
import { AssetCategory } from 'config/_enums';
import useSportsCountries from 'hooks/sports/use-sports-countries/useSportsCountries';
import { ISelectCategoryFieldProps } from 'interfaces/ISelectFieldProps';
import React from 'react';

const SelectCategoryField: React.FC<ISelectCategoryFieldProps> = (fieldProps) => {
    const { fetchSportsCountries } = useSportsCountries();

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const currentSelectedValue: string = event.target.value;

        const isSelectedTargetFootball = ASSETS_CATEGORIES[AssetCategory.Football] === currentSelectedValue;
        if (isSelectedTargetFootball) {
            fetchSportsCountries();
            if (fieldProps.setStateChange) fieldProps.setStateChange(true)
        } else {
            if(fieldProps.setStateChange) fieldProps.setStateChange(false)
        }

        fieldProps.input.onChange(event);
    }

    return (
        <WrapperSelect
            valueChange={handleSelectChange}
            propsReduxForm={fieldProps}
            customValue=""
            data={fieldProps.options}
            type={WRAPPER_SELECT_TYPE.NATIVE} />
    )
};

export default React.memo(SelectCategoryField);
