import ReduxActionInterface from '../../model/ReduxActionInterface';
import ReduxRootState from '../../model/Redux/ReduxRootSate';

import * as ActionTypeWithDraw from './actionsType';
import Utils from '../../utils/Utils';

const ROOT_VALUE_STATE: ReduxRootState = {
    loading: false,
    data: [],
};

export const withdrawsReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {
        case ActionTypeWithDraw.WITHDRAWS_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ActionTypeWithDraw.WITHDRAWS_ERROR:

            return {
                ...state,
                loading: false,
            };

        case ActionTypeWithDraw.WITHDRAWS_SUCCESS:
            let dataToInsert = action.payload.data;
            if (!dataToInsert.length) {
                dataToInsert = [dataToInsert];
            }
            const stateData = state.data && state.data.length ? Utils.removedDuplicateMongoFile(state.data, dataToInsert) : [];
            return {
                ...state,
                data: stateData.concat(action.payload.data),
                loading: false,
            };

        default: return state;
    }
};
