import Application from 'Application';
import { TEAM_STATUS, TOURNAMENT_CATEGORY } from 'config/_const';
import { TournamentEntryType, TournamentRewardKind } from 'config/_enums';
import { Dictionary, groupBy, isEmpty } from 'lodash';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import type { ReduxRootState, RootState } from 'model/Redux';
import ITeam from 'model/Team/ITeam';
import ITeamAsset from 'model/Team/ITeamAsset';
import ITournamentLeagueAndSubscription from 'model/Tournament/ITournamentLeagueAndSubscription';
import type { ITournamentSubscription, ITournamentSubscriptionsReducer } from 'model/Tournament/ITournamentSubscription';
import { Selector, createSelector } from 'reselect';
import { assetsCategoriesSelector } from 'service/asset-category/selector';
import { allAssetsSelector } from 'service/assets/selectors';
import { userIdSelector } from 'service/auth/selectors';
import { Status } from 'service/storeType';
import { calculateTeamScoreAverage } from 'service/teams/helper';
import { activeUserTeamSelector } from 'service/teams/selectors';
import { extractTournamentId, tournamentByIdSelector } from 'service/tournament/selectors';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';
import TournamentSubscriptionViewModel from 'view-model/Tournament/TournamentSubscriptionsViewModel/TournamentSubscriptionViewModel';
import TournamentViewModel from 'view-model/Tournament/TournamentViewModel/TournamentViewModel';
import { tournamentSubscriptionsSorter } from './sorter';

const tournamentSubscriptionSelector: Selector<RootState, ReduxRootState<ITournamentSubscriptionsReducer>> = (state: RootState) => state.tournamentSubscription;
const tournamentSubscriptionDataSelector: Selector<RootState, ITournamentSubscriptionsReducer> = (state: RootState) => state.tournamentSubscription['data'];
const tournamentSubscriptionLoading: Selector<RootState, boolean> = state => state.tournamentSubscription['loading'];
const tournamentSubscriptionsByTournamentId = (state: RootState, tournamentId: string): ITournamentSubscription[] => state.tournamentSubscription.data[tournamentId] ?? [];

const internalGetUserSubscription = (subscriptions: TournamentSubscriptionViewModel[], tournament: TournamentViewModel): ITournamentLeagueAndSubscription[] => {
    const tournamentLeagues: LeagueViewModel[] = Application.getInstance().getLeagues(tournament.PrizePool);
    const sortedTournamentLeagues: LeagueViewModel[] = tournamentLeagues.sort((leagueA: LeagueViewModel, leagueB: LeagueViewModel) => leagueA.MinWalletShares > leagueB.MinWalletShares ? -1 : 1);
    const tournamentSubscriptionsGroupByLeague: Dictionary<TournamentSubscriptionViewModel[]> = groupBy(subscriptions, (t: TournamentSubscriptionViewModel) => t.League);
    const sortedLeaguesAndSubscriptions: ITournamentLeagueAndSubscription[] = sortedTournamentLeagues.map((league: LeagueViewModel) => {
        return { league, subscriptions: tournamentSubscriptionsGroupByLeague[league.Name] ?? [] };
    });

    const tournamentConfiguration = Application.getInstance().getTournamentConfiguration(tournament?.EntryType ?? TournamentEntryType.FREE);
    const multiplierForPodium: number = tournamentConfiguration.cashprizeRepartition.multiplierForPodium;
    const multiplierForPlayers: number = tournamentConfiguration.cashprizeRepartition.multiplierForPlayers;
    const multiplierForFirst: number = tournamentConfiguration.cashprizeRepartition.multiplierForFirst;
    const multiplierForSecond: number = tournamentConfiguration.cashprizeRepartition.multiplierForSecond;
    const multiplierForThird: number = tournamentConfiguration.cashprizeRepartition.multiplierForThird
    const defaultCashprizeMultiplier: number = tournamentConfiguration.cashprizeRepartition.defaultCashprizeMultiplier

    for (const tournamentSubscription of sortedLeaguesAndSubscriptions) {
        if (tournamentSubscription.subscriptions.length === 0)
            continue;

        const tournamentRewardConfiguration = Application.getInstance().TournamentRewardConfiguration;
        const assetsRewardChunk = Math.floor(tournamentSubscription.subscriptions.length.multiply(tournamentRewardConfiguration.giveAssets.percentageEachLeagues));
        const startIndex: number = tournamentSubscription.subscriptions.length.subtract(assetsRewardChunk);
        const podiumBonus: number = tournamentSubscription.league.PrizePool * multiplierForPodium;
        const allPlayersPrize: number = tournamentSubscription.league.PrizePool * multiplierForPlayers;
        const cashPrizePerPerson: number = allPlayersPrize / tournamentSubscription.subscriptions.length;

        for (let i = tournamentSubscription.subscriptions.length - 1; i >= 0; i--) {
            const multiplier: number = defaultCashprizeMultiplier - (i > 0 ? (i / (tournamentSubscription.subscriptions.length - 1)) : 0);
            let cashprize: number = cashPrizePerPerson * multiplier;
            tournamentSubscription.subscriptions[i].Cashprize = cashprize;
        }

        tournamentSubscription.subscriptions = tournamentSubscription.subscriptions.sort((a, b) => b.Cashprize - a.Cashprize);

        if (tournamentSubscription.subscriptions[0].Cashprize / (tournamentSubscription.subscriptions[tournamentSubscription.subscriptions.length - 1].Cashprize) > tournamentSubscription.league.MaxMultiplierBetweenFirstAndLast) {
            let divider = tournamentSubscription.subscriptions[0].Cashprize / (tournamentSubscription.subscriptions[tournamentSubscription.subscriptions.length - 1].Cashprize * tournamentSubscription.league.MaxMultiplierBetweenFirstAndLast);
            for (let i = 0; i < tournamentSubscription.subscriptions.length; i++)
                tournamentSubscription.subscriptions[i].Cashprize = tournamentSubscription.subscriptions[i].Cashprize / (divider - (divider - 1) * (i / (tournamentSubscription.subscriptions.length - 1)));
        }


        let totalCashPrizeGiven = tournamentSubscription.subscriptions.reduce((acc, cur) => acc + cur.Cashprize, 0);
        const difference = allPlayersPrize / totalCashPrizeGiven;
        for (let i = 0; i < tournamentSubscription.subscriptions.length; i++) {
            tournamentSubscription.subscriptions[i].Cashprize = tournamentSubscription.subscriptions[i].Cashprize * difference;
            if (i >= startIndex && Application.getInstance().TournamentRewardConfiguration.giveAssets.enabled)
                tournamentSubscription.subscriptions[i].Kind = TournamentRewardKind.SHARES_REWARD;
        }
        totalCashPrizeGiven = tournamentSubscription.subscriptions.reduce((acc, cur) => acc + cur.Cashprize, 0)
        if (totalCashPrizeGiven > allPlayersPrize) {
            const divider = totalCashPrizeGiven / allPlayersPrize;
            for (let i = tournamentSubscription.subscriptions.length - 1; i >= 0; i--) {
                tournamentSubscription.subscriptions[i].Cashprize = tournamentSubscription.subscriptions[i].Cashprize / divider;
            }
        }

        for (let i = 0; i < tournamentSubscription.subscriptions.length; i++) {
            switch (i) {
                case 0:
                    tournamentSubscription.subscriptions[i].Cashprize += podiumBonus * multiplierForFirst;
                    break;
                case 1:
                    tournamentSubscription.subscriptions[i].Cashprize += podiumBonus * multiplierForSecond;
                    break;
                case 2:
                    tournamentSubscription.subscriptions[i].Cashprize += podiumBonus * multiplierForThird;
                    break;
            }
        }
    };

    return sortedLeaguesAndSubscriptions;
}

export const selectAssetIdsOfTeams = createSelector(
    tournamentSubscriptionDataSelector,
    extractTournamentId,
    (subscriptions: ITournamentSubscriptionsReducer, tournamentId: string) => {
        const assetIds: string[] = [];

        subscriptions[tournamentId].forEach((subscription) => {
            if (!subscription.user || !subscription.team) return null;
            const ids = subscription.team.teamAssets.map(a => a.asset);
            assetIds.push(...ids);
        });
        return assetIds;
    },
);

export const selectTournamentSubscriptionsStatus = createSelector<RootState, ReduxRootState<ITournamentSubscriptionsReducer>, Status>(tournamentSubscriptionSelector, (state: ReduxRootState<ITournamentSubscriptionsReducer>) => state.status!.getSubscriptions);
export const selectTournamentSubscriptionsLength = createSelector(
    tournamentSubscriptionDataSelector,
    extractTournamentId,
    (data: ITournamentSubscriptionsReducer, tournamentId: string) => data[tournamentId]?.length ?? 0);



export const subscriptionsByTournamentIdSelector = createSelector(
    tournamentSubscriptionDataSelector,
    extractTournamentId,
    allAssetsSelector,
    assetsCategoriesSelector,
    (state, tournamentId: string) => tournamentByIdSelector(state, tournamentId),
    (subscriptions: ITournamentSubscriptionsReducer, tournamentId: string, allAssets: AssetViewModel[], assetCategories: ReduxRootState<AssetCategoryInterface[]>, nextTournament: TournamentViewModel | undefined) => {
        const subscriptionsByTournamentId: ITournamentSubscription[] | undefined = subscriptions[tournamentId];
        if (!subscriptionsByTournamentId || subscriptionsByTournamentId.length === 0)
            return [];

        const tournamentType: TOURNAMENT_CATEGORY = nextTournament?.Category as TOURNAMENT_CATEGORY ?? TOURNAMENT_CATEGORY.DEFAULT;
        const subscriptionsAsViewModel: TournamentSubscriptionViewModel[] = subscriptionsByTournamentId.map((subscription: ITournamentSubscription) => {
            return new TournamentSubscriptionViewModel({
                ...subscription,
                league: subscription.league,
                teamScoreAverage: calculateTeamScoreAverage(subscription.team.teamAssets, allAssets, assetCategories.data, tournamentType as TOURNAMENT_CATEGORY, subscription.scoreBoost),
            });
        });
        return tournamentSubscriptionsSorter(subscriptionsAsViewModel);
    },
);

export const tournamentSubscriptionLoadingSelector = createSelector<RootState, boolean, boolean>(
    tournamentSubscriptionLoading,
    (loading: boolean) => {
        return loading;
    },
);

export const isUserSubscribeToTournamentSelector = createSelector(
    tournamentSubscriptionsByTournamentId,
    userIdSelector,
    (subscriptions: ITournamentSubscription[], userId: string) => {
        return isEmpty(subscriptions) ? false : Boolean(subscriptions.find((subscription: ITournamentSubscription) => subscription.user._id === userId));
    },
);

export const getAssetIdsFromSubscriptionsByTournamentIdSelector = createSelector(
    tournamentSubscriptionsByTournamentId,
    (subscriptions: ITournamentSubscription[]) => {
        if (isEmpty(subscriptions))
            return [];
        const assetIdsIntoTournament: string[] = subscriptions.reduce((acc: string[], susbcription: ITournamentSubscription) => acc.concat(susbcription.team.teamAssets.map((teamAsset: ITeamAsset) => teamAsset.asset)), []);
        return Array.from(new Set(assetIdsIntoTournament));
    },
);

export const userCanJoinTournamentSelector = createSelector(
    isUserSubscribeToTournamentSelector,
    activeUserTeamSelector,
    tournamentByIdSelector,
    (userAlreadyJoinTournament: boolean, team: ITeam | undefined, tournament: TournamentViewModel | undefined) => {
        return Boolean(tournament && team && tournament.isSubscriptionOpen() && !userAlreadyJoinTournament && team.status !== TEAM_STATUS.ENGAGED);
    },
);

export const getCurrentUserSubscriptionSelector = createSelector(
    subscriptionsByTournamentIdSelector,
    extractTournamentId,
    tournamentByIdSelector,
    userIdSelector,
    (subscriptions: TournamentSubscriptionViewModel[], tournamentId: string, tournament: TournamentViewModel | undefined, userId: string) => {
        if (subscriptions.length === 0 || !tournament)
            return undefined;
        const leagues: ITournamentLeagueAndSubscription[] = internalGetUserSubscription(subscriptions, tournament);
        let userSubscription: TournamentSubscriptionViewModel | undefined;
        for (const league of leagues) {
            for (let i = 0; i < league.subscriptions.length; i++) {
                const currentSubscription: TournamentSubscriptionViewModel = league.subscriptions[i];
                if (currentSubscription.User.Id === userId && currentSubscription.Tournament._id === tournamentId) {
                    userSubscription = league.subscriptions[i];
                    userSubscription.Place = i + 1;
                    break
                }
            }
        }
        return userSubscription;
    }
);

export const getNumberOfSubscriptionsByTournamentIdSelector = createSelector(
    tournamentSubscriptionsByTournamentId,
    (subscriptions: ITournamentSubscription[]) => {
        return subscriptions.length
    },
);
