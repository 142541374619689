import * as React from 'react';
import './TradeDetailBox.scss';
import clsx from 'clsx';
import { ITradeDetailBoxProps } from './TradeDetailBoxProps';

const TradeDetailBox: React.FC<ITradeDetailBoxProps> = ({ imgComponent, title, subText, valueContainer, showBorder }) =>
    <div className={clsx(
        'trade-detail-box',
        { 'highlight-border': showBorder })}
    >
        <div className='trade-detail__info'>
            <div className='trade-detail_img'>
                {imgComponent}
            </div>
            <div className="trade-detail_label">
                <div className="title">
                    {title}
                </div>
                {subText &&
                    <div className='sub-text'>
                        {subText}
                    </div>
                }
            </div>
        </div>
        <div className='trade-detail-value'>
            {valueContainer}
        </div>

    </div>;

export default React.memo(TradeDetailBox);
