import useUserLeague from "hooks/use-user-league/useUserLeague";
import useTeamConfiguration from "../use-team-configuration/useTeamConfiguration";
import { TournamentLeagues } from "config/_enums";
import IUseTeamConfiguration from "../use-team-configuration/IUseTeamConfiguration";
import IUseTeamConfigRespectToUserLeague from "./IUseTeamConfigRespectToUserLeague";

const UseTeamConfigRespectToUserLeague = (): IUseTeamConfigRespectToUserLeague => {
  const { userLeagueViewModel } = useUserLeague();
  const { teamConfigViewModel }: IUseTeamConfiguration = useTeamConfiguration({ leagueType: userLeagueViewModel.LeagueAttribution.Name as TournamentLeagues });
  const minSharesPerSlot = teamConfigViewModel.MinSharesByAssset;

  return {
    teamConfigViewModel,
    minSharesPerSlot,
  };
};

export default UseTeamConfigRespectToUserLeague;
