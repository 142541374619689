import { KEYCODE_ESC } from 'config/_const';
import FACTORY_MODAL from 'config/factory';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalReduxStore from 'service/modal/ModalReduxStore';
import { managementModal } from 'service/modal/action';
import { globalModalSelector } from '../../service/modal/selectors';
import IUseModal from './IUseModal';

const useModal = (): IUseModal => {
    const dispatch = useDispatch();
    const modalStore: ModalReduxStore = useSelector(globalModalSelector);
    const close = () => dispatch(managementModal(false));
    const child: React.ElementType = useMemo(() => FACTORY_MODAL[modalStore.type], [modalStore.type]);

    useEffect(() => {
        if (!modalStore.closeByEscape)
            return;
        document.addEventListener('keydown', closeByKey);
        return () => document.removeEventListener('keydown', closeByKey);
    }, [modalStore.closeByEscape]);

    const closeByKey = (event: KeyboardEvent): void => {
        if (event.keyCode !== KEYCODE_ESC)
            return;
        close();
        return;
    };

    return {
        child,
        modal: modalStore,
        close,
    };
};

export default useModal;