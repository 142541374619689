import Application from "Application";
import { BITPAY_URL_INTENT, G_TAGS_KNOWN_EVENTS, LOCAL_STORAGE_KNOWN_KEYS } from "config/_const";
import { BitPayInitiationPages } from "config/_enums";
import i18next from "i18next";
import IPaymentByBitpay from "model/Payment/IPaymentByBitpay";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { managementModal } from "service/modal/action";
import { creditAccountWithBitpay } from "service/payment/actions";
import { TagManagerWrapper } from "service/tag-manager/TagManagerWrapper";

const useCreditByBitpay = (initiationPage: BitPayInitiationPages) => {
    const dispatch = useDispatch();
    const [transactionLoading, setTransactionLoading] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();

    const showBitpayErrorModal = () => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                showCancelButton: false,
                showLinkButton: false,
                onRefused: () => dispatch(managementModal(false)),
                textValidButton: i18next.t('Becomevippage.submitSuccess.confirm'),
                onValidation: () => {
                    dispatch(managementModal(false));
                    removeSearchParams();
                },
                message: i18next.t('payment.bitpay.error', { brand: Application.getInstance().brand }),
            },
        }));
    }


    useEffect(() => {
        const url = new URLSearchParams(window.location.search);
        const redirectFrom = url.get(BITPAY_URL_INTENT.SEARCH_PARAM);
        if (redirectFrom) {
            if (redirectFrom === BITPAY_URL_INTENT.BITPAY_SUCCESS) {
                dispatch(managementModal(true, {
                    display: true,
                    disableBackDrop: false,
                    showBackArrow: false,
                    fullScreen: false,
                    type: 'CONFIRM',
                    propsCustom: {
                        showCancelButton: false,
                        showLinkButton: false,
                        onRefused: () => dispatch(managementModal(false)),
                        textValidButton: i18next.t('Becomevippage.submitSuccess.confirm'),
                        onValidation: () => {
                            dispatch(managementModal(false));
                            removeSearchParams();
                        },
                        message: i18next.t('payment.bitpay.success', { amount: localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_CREDITS_INTENT) }),
                    },
                }));
            } else if (redirectFrom === BITPAY_URL_INTENT.BITPAY_ERROR) {
                showBitpayErrorModal();
            }
            url.delete(BITPAY_URL_INTENT.SEARCH_PARAM);
            return;
        }
    }, [dispatch])
    const appendQuerySign = (url: string): string => {
        if (url.includes('?')) {
            return `${url}&`
        }
        return `${url}?`
    }

    const removeSearchParams = () => {
        const urlSearchParams = new URLSearchParams(location.search);
        urlSearchParams.delete(BITPAY_URL_INTENT.SEARCH_PARAM);
        urlSearchParams.delete(BITPAY_URL_INTENT.REDIRECT_TO);
        history.replace({ search: urlSearchParams.toString() })
    }
    const getWindowLocation = (): string => {
        return `${window.location.origin}${window.location.pathname}`
    }

    const getBitPayRedirectUrl = (): string => {
        const queryAppendedUrl = appendQuerySign(getWindowLocation());
        return `${queryAppendedUrl}${BITPAY_URL_INTENT.SEARCH_PARAM}=${BITPAY_URL_INTENT.BITPAY_SUCCESS}&${BITPAY_URL_INTENT.REDIRECT_TO}=${initiationPage}`;
    }

    const getBitPayCloseUrl = (): string => {
        const queryAppendedUrl = appendQuerySign(getWindowLocation());
        return `${queryAppendedUrl}${BITPAY_URL_INTENT.SEARCH_PARAM}=${BITPAY_URL_INTENT.BITPAY_ERROR}&${BITPAY_URL_INTENT.REDIRECT_TO}=${initiationPage}`;
    }

    const onSuccessCallback = (paymentData: IPaymentByBitpay) => {
        setTransactionLoading(false);
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.REFUND, {
            name: 'buy-bitpay',
            conversionValue: paymentData.amount,
            currency: Application.getInstance().currency.toLowerCase(),
        });
        return window.open(paymentData.redirectUrl, '_blank')?.focus();
    }

    const onErrorCallback = () => {
        setTransactionLoading(false);
        showBitpayErrorModal();
    }

    const creditWithBitpay = (amount: number) => {
        setTransactionLoading(true);
        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_CREDITS_INTENT, JSON.stringify(amount));
        dispatch(
            creditAccountWithBitpay(
                amount,
                getBitPayRedirectUrl(),
                getBitPayCloseUrl(),
                onSuccessCallback,
                onErrorCallback
            )
        );
    }

    return { creditWithBitpay, transactionLoading }

}

export default useCreditByBitpay;