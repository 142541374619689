import * as React from 'react';

const CalendarDays = ({ height = "20px", width = "20px", ...props }: React.SVGProps<SVGSVGElement>) => {

  return (
    <svg height={height} width={width} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g id="Engagement" transform="translate(0 -728.5)">
        <g id="Rectangle_2014" data-name="Rectangle 2014" transform="translate(0 728.5)" fill="#8187d0" stroke="#707070" strokeWidth="1" opacity="0">
          <rect width="20" height="20" stroke="none" />
          <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
        </g>
        <g id="calendar-days-duotone" transform="translate(2.024 729.384)">
          <path id="Tracé_3609" data-name="Tracé 3609" d="M5.7,2.279h4.558V1.139a1.139,1.139,0,1,1,2.279,0V2.279h1.709a1.709,1.709,0,0,1,1.709,1.709V6.837H0V3.988A1.709,1.709,0,0,1,1.709,2.279H3.418V1.139a1.139,1.139,0,0,1,2.279,0Z" fill="#8187d0" />
          <path id="Tracé_3610" data-name="Tracé 3610" d="M0,192H15.953v9.685a1.71,1.71,0,0,1-1.709,1.709H1.709A1.709,1.709,0,0,1,0,201.685Zm2.279,3.988a.57.57,0,0,0,.57.57H3.988a.571.571,0,0,0,.57-.57v-1.139a.571.571,0,0,0-.57-.57H2.849a.57.57,0,0,0-.57.57Zm5.128-1.709a.571.571,0,0,0-.57.57v1.139a.571.571,0,0,0,.57.57H8.546a.571.571,0,0,0,.57-.57v-1.139a.571.571,0,0,0-.57-.57Zm3.988,1.709a.571.571,0,0,0,.57.57H13.1a.571.571,0,0,0,.57-.57v-1.139a.571.571,0,0,0-.57-.57H11.964a.571.571,0,0,0-.57.57Zm-8.546,2.849a.57.57,0,0,0-.57.57v1.139a.57.57,0,0,0,.57.57H3.988a.571.571,0,0,0,.57-.57v-1.139a.571.571,0,0,0-.57-.57Zm3.988,1.709a.571.571,0,0,0,.57.57H8.546a.571.571,0,0,0,.57-.57v-1.139a.571.571,0,0,0-.57-.57H7.407a.571.571,0,0,0-.57.57Zm5.128-1.709a.571.571,0,0,0-.57.57v1.139a.571.571,0,0,0,.57.57H13.1a.571.571,0,0,0,.57-.57v-1.139a.571.571,0,0,0-.57-.57Z" transform="translate(0 -185.163)" fill="#8187d0" opacity="0.4" />
        </g>
      </g>
    </svg>
  );
}

export default CalendarDays;
