import AssetInterface from 'model/AssetModel/AssetInterface';
import BaseTournamentUserViewModel, { BaseConstructor } from '../BaseTournamentUserViewModel/BaseTournamentUserViewModel';

// extends this class options here
interface Constructor extends BaseConstructor {
    icon: AssetInterface['icon'];
    name: AssetInterface['name'];
}

export default class TournamentTopVIPViewModel extends BaseTournamentUserViewModel {
    private _icon: AssetInterface['icon'];
    private _name: AssetInterface['name'];

    constructor(asset: Constructor) {
        super(asset);
        this._icon = asset.icon;
        this._name = asset.name;
    }

    public get Icon() {
        return this._icon;
    }

    public get Name() {
        return this._name;
    }
}
