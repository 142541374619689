import useAccount from 'hooks/use-account/useAccount';
import React from 'react';


const withAdminView = <BaseProps,>(Component: React.ComponentType<BaseProps>): React.MemoExoticComponent<React.FC<BaseProps>> => {

    const Wrapper: React.FC<BaseProps> = (args: BaseProps) => {
        const { accountViewModel } = useAccount();
        const isUserAdmin: boolean = accountViewModel.IsUserAdmin;

        if (isUserAdmin) return null;
        return <Component {...args as BaseProps} />
    }

    return React.memo<React.FC<BaseProps>>(Wrapper);
}

export default withAdminView;