import { Capacitor } from '@capacitor/core';
import Application from 'Application';
import Notifications from 'components/hook-containers/notifications/Notifications';
import AssetTransactionSlides from 'components/medium/asset-transaction/asset-transaction-slides-stack/AssetTransactionSlides';
import Modal from 'components/medium/modal/Modal';
import RightSection from 'components/medium/right-section/RightSection';
import SideMenu from 'components/medium/side-menu/SideMenu';
import SlidingPages from 'components/medium/sliding-pages/SlidingPages';
import AssetSlideUpView from 'components/small/asset-slide-up-view/AssetSlideUpView';
import BottomTabBar from 'components/small/bottom-tab-bar/BottomTabBar';
import Footer from 'components/small/footer/Footer';
import InternalCookieConsent from 'components/small/internal-cookie-consent/InternalCookieConsent';
import ResumeOnboardingButton from 'components/small/resume-onboarding-button/ResumeOnboardingButton';
import ThemeProviderWrapper from 'components/small/theme-provider/ThemeProvider';
import TopBar from 'components/small/top-bar/TopBar';
import { LOCAL_STORAGE_KNOWN_KEYS, TRENDEX_ROLES, onboardingEndpoints } from 'config/_const';
import { AssetTransactionType, BitPayInitiationPages, NotificationPermission, NotificationUserResponse, RedirectInitiationPage, TdxNotification, URL_INTENT } from 'config/_enums';
import { MENU_ITEMS } from 'config/menu';
import MenuInterface from 'model/Menu/MenuInterface';
import moment from 'moment/moment';
import Top50SkeletonLoading from 'page/Top50/top50SkeletonLoading/Top50SkeletonLoading';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, BrowserRouter as Router } from 'react-router-dom';
import { compose } from 'redux';
import AppRouter from 'router/app.router';
import { actionFetchAssetCategory } from 'service/asset-category/actions';
import { openAssetTransactionSlides } from 'service/asset-transaction-slideup/action';
import { uploadProfileImage } from 'service/auth/actions';
import { selectorAccountViewModel } from 'service/auth/selectors';
import { addFirebaseToken, fetchFirebaseToken } from 'service/firebase-notifications/actions';
import { managementModal } from 'service/modal/action';
import { managementSideMenu } from 'service/side-menu/action';
import { openSlidingPage } from 'service/sliding-pages/action';
import Utils from 'utils/Utils';
import '../../../src/assets/css/bootstrap.min.css';
import { getToken } from '../../firebase';
import AppContainerProps from './AppContainerProps';
import AppContainerState from './AppContainerState';

const WalletRefund = React.lazy(() => import("components/medium/wallet-refund/WalletRefund"));

class AppContainer extends React.Component<AppContainerProps, AppContainerState> {
    private sideMenuDivRef: React.RefObject<HTMLDivElement>;

    constructor(props: AppContainerProps) {
        super(props);
        this.state = {
            activeItem: MENU_ITEMS[0],
        };
        this.sideMenuDivRef = React.createRef();
    }

    getCanAskPermission = () => {
        const tdxIsRefused = localStorage.getItem(TdxNotification.USER_RESPONSE) === NotificationUserResponse.REFUSED;
        const refusedCount = localStorage.getItem(TdxNotification.REFUSED_COUNT);
        const refusedDate = localStorage.getItem(TdxNotification.REFUSED_DATE);
        const diffInWeeks = moment(refusedDate).diff(moment(), 'weeks');
        return Notification.permission === NotificationPermission.DEFAULT || !tdxIsRefused || (tdxIsRefused && diffInWeeks === parseInt(refusedCount || '1', 10));
    };

    componentWillMount = async () => {
        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_THEME, 'light');
        const enableDarkTheme: boolean = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_THEME) === 'dark';
        this.activeLightTheme(enableDarkTheme);
        this.props.actionFetchAssetCategory();

        if (!this.props.auth.isAuthenticated)
            return;
        if (!('Notification' in window))
            return;
        if (this.getCanAskPermission()) {
            if (Notification.permission !== NotificationPermission.GRANTED) {
                try {
                    await this.askForPermissionAndGetToken();
                } catch (e) {
                    console.error('[FIREBASE] ', (e as Error).stack);
                }
            }
        }
        if (Notification.permission === NotificationPermission.GRANTED) {
            try {
                await getToken((token: string) => this.props.addFirebaseToken(this.props.auth.Id, token));
            } catch (e) {
                console.error('[FIREBASE] ', (e as Error).stack);
            }
        }
    };

    async componentDidMount(): Promise<void> {
        this.switchPageStateFromUrlParams();
    }

    switchPageStateFromUrlParams(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const redirectTo = urlParams.get(URL_INTENT.REDIRECT_TO);

        switch (redirectTo) {
            case BitPayInitiationPages.REFUND_WALLET:
                this.props.openSlidingPage(<WalletRefund />);
                break;
            case BitPayInitiationPages.BUY_ASSET:
                const currentTransactionId = localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.CURRENT_TRANSACTION_ASSET_ID);
                currentTransactionId && this.props.openAssetTransactionSlides(currentTransactionId, AssetTransactionType.BUY);
                break;
            case RedirectInitiationPage.BUY_ASSET:
                const assetId = urlParams.get("assetId");
                assetId && this.props.openAssetTransactionSlides(assetId, AssetTransactionType.BUY);
        }
    }

    askForPermissionAndGetToken = async () => {
        if (Notification.permission === NotificationPermission.DENIED || localStorage.getItem(TdxNotification.USER_RESPONSE) === NotificationUserResponse.REFUSED) return;

        this.props.managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            showCancelButton: true,
            fullScreen: false,
            type: 'NOTIFICATION_PERMISSION_MODAL',
            propsCustom: {
                onRefused: () => {
                    localStorage.setItem(TdxNotification.USER_RESPONSE, NotificationUserResponse.REFUSED);
                    const refusedCount = localStorage.getItem(TdxNotification.REFUSED_COUNT);
                    localStorage.setItem(TdxNotification.REFUSED_COUNT, refusedCount ? (parseInt(refusedCount, 10) + 1).toString() : '1');
                    if (!localStorage.getItem(TdxNotification.REFUSED_DATE)) {
                        localStorage.setItem(TdxNotification.REFUSED_DATE, moment().format());
                    }
                    this.props.managementModal(false);
                },
                onValidation: async () => {
                    localStorage.setItem(TdxNotification.USER_RESPONSE, NotificationUserResponse.ACCEPTED);
                    this.props.managementModal(false);
                    await window.Notification.requestPermission();
                    await getToken((token: string) => this.props.addFirebaseToken(this.props.auth.Id, token));
                },
            },
        });
    };

    addSideMenuActiveClassMenu = () => {
        document.getElementById('sidemenusec')?.classList.add('active');
        document.getElementById('close_back')?.classList.add('active');
    };

    removeSideMenuActiveClass = () => {
        document.getElementById('sidemenusec')?.classList.remove('active');
        document.getElementById('close_back')?.classList.remove('active');
    };

    onMenuItemsSelected = (item: MenuInterface) => {
        if (Utils.isMobile()) {
            this.props.managementSideMenu();
        }

        this.setState({
            activeItem: item,
        });
    };

    activeLightTheme = (state: boolean) => {
        return !state ? document.getElementById('mainbody')?.classList.add('light_demo') : document.getElementById('mainbody')?.classList.remove('light_demo');
    };

    render = () => {
        const {
            sideMenu,
            auth,
            appConfig: { data: { device } } } = this.props;
        const isIOS = device?.platform === 'ios';

        if (auth.IsDisabled && !auth.IsAdminToVip) {
            document.write('<div style="background-color:blue;width:100vw;height:100vh;"></div>');
            return <></>;
        }

        const onboardingMode: boolean = auth.IsOnboardingRunning && Application.getInstance().OnBoardingV2;
        const redirectTo = onboardingMode ? onboardingEndpoints[auth.OnboardingStep] : window.location.pathname;
        const resumeOnboarding: boolean = onboardingMode;

        return (
            <Router>
                {isIOS && <div className="app-navbar-notch" />}
                <AssetSlideUpView />
                <Modal />
                <ThemeProviderWrapper>
                    <div className={'main-app' + (this.props.auth.UserType === TRENDEX_ROLES.ADMIN ? ' admin-mode' : '')} onClick={() => sideMenu.data.display && Utils.isMobile() ? this.props.managementSideMenu() : undefined}>
                        <div className="mainframe_section">
                            <div ref={this.sideMenuDivRef} id="sidemenusec" className="sidemenu_section">
                                <SideMenu
                                    sideMenuDivRef={this.sideMenuDivRef}
                                    removeActiveClass={this.removeSideMenuActiveClass}
                                    uploadProfileImg={this.props.uploadProfileImage}
                                    onItemSelected={this.onMenuItemsSelected}
                                    items={MENU_ITEMS} />
                            </div>

                            {!onboardingMode && <BottomTabBar
                                isAdminToVip={auth.IsAdminToVip}
                                isAuthenticated={auth.isAuthenticated}
                                accountType={auth.UserType} />
                            }

                            <div className="header_section">
                                <TopBar addSideMenuActiveClassMenu={this.addSideMenuActiveClassMenu} />
                            </div>

                            {resumeOnboarding &&
                                <ResumeOnboardingButton auth={auth} />
                            }

                            <React.Suspense fallback={<Top50SkeletonLoading />}>
                                <div className="main_section">
                                    <div className="router contact_pages_e">

                                        <AppRouter />
                                        {onboardingMode && <Redirect to={redirectTo} />}
                                    </div>
                                    {!Capacitor.isNativePlatform() && !Application.getInstance().IsInjectedAsIframe && !onboardingMode && <InternalCookieConsent />}
                                    {!(Application.getInstance().OnBoardingV2 && auth.IsOnboardingRunning) &&
                                        <div className="footer_sec">
                                            <Footer />
                                        </div>
                                    }
                                    <AssetTransactionSlides />
                                    <SlidingPages
                                    />
                                </div>

                                <div id="close_back" className="close_background" onClick={() => this.removeSideMenuActiveClass()} ></div>

                            </React.Suspense>

                            <RightSection />

                        </div>
                    </div>
                </ThemeProviderWrapper>
                <Notifications />
            </Router>

        );
    };
}

const mapStateToProps = (state: AppContainerProps) => ({
    auth: selectorAccountViewModel(state),
    sideMenu: state.sideMenu,
    appConfig: state.appConfig,
});

export default compose(
    connect(mapStateToProps, {
        managementSideMenu,
        managementModal,
        actionFetchAssetCategory,
        uploadProfileImage,
        addFirebaseToken,
        fetchFirebaseToken,
        openSlidingPage,
        openAssetTransactionSlides
    }),
)(AppContainer);
