import Application from 'Application';
import { MINIMUM_STRIPE_AMOUNT } from 'config/_const';
import { IScoreBoosterVMBuilder } from 'hooks/score-booster/IUseScoreBooster';
import IRandomizedScoreBooster from 'model/AppConfiguration/ScoreBooster/IRandomizedScoreBoosterConfiguration';
import IScoreBoosterConfiguration from 'model/AppConfiguration/ScoreBooster/IScoreBoosterConfiguration';

export default class ScoreBoosterViewModel {
    private static configuration: IScoreBoosterConfiguration = Application.getInstance().ScoreBoosterConfiguration;
    private static randomized: IRandomizedScoreBooster = ScoreBoosterViewModel.configuration.randomized;
    private currentScore: number;
    private isBoostAcquired: boolean;
    private simulateBoostedScore: number;
    private amount: number;
    private price: number;
    private winningPoints: number;

    constructor(scoreBoosterVMBuilder: IScoreBoosterVMBuilder) {
        this.currentScore = scoreBoosterVMBuilder.avgTeamScore;
        this.isBoostAcquired = scoreBoosterVMBuilder.isBoostAcquired;
        this.simulateBoostedScore = scoreBoosterVMBuilder.avgTeamScore.addition(ScoreBoosterViewModel.configuration.defaultPoint);
        this.amount = scoreBoosterVMBuilder.priceForBoost;
        this.price = scoreBoosterVMBuilder.priceForBoost;
        this.winningPoints = scoreBoosterVMBuilder.winningPoints;
    }

    public get BoostPoints() {
        return ScoreBoosterViewModel.configuration.defaultPoint;
    }

    public get CurrentScore() {
        return Math.floor(this.currentScore);
    }

    public get IsBoostAcquired() {
        return this.isBoostAcquired;
    }

    public get SimulateBoostedScore() {
        return Math.floor(this.simulateBoostedScore);
    }

    public get Amount() {
        return this.amount;
    }

    public get MinPoints(): number {
        return ScoreBoosterViewModel.randomized.points[0];
    }

    public get MaxPoints(): number {
        return ScoreBoosterViewModel.randomized.points[1];
    }
    public get IsRandomizedActivated(): boolean {
        return ScoreBoosterViewModel.randomized.enabled;
    }

    public get IsRerollActivated(): boolean {
        return ScoreBoosterViewModel.randomized.enabled && ScoreBoosterViewModel.configuration.canRerollBoost;
    }

    public getContentColor() {
        return this.isBoostAcquired ? "#868895" : "#FFFFFF";
    }

    public get Price(): number {
        return this.price;
    }

    public get PriceAsCurrency(): string {
        return this.price.toCurrency();
    }

    public get IsPriceSmallerThanStripe(): boolean {
        return this.Price.isSmallerThan(MINIMUM_STRIPE_AMOUNT);
    }

    public get WinningPoints(): number {
        return this.winningPoints;
    }
}
