import React from 'react';

const Wallet: React.FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.523" height="23.207" viewBox="0 0 26.523 23.207" id="wallet-icon-dark" fill={props.color ?? "#15182b"} {...props}>
            <path id="wallet-solid" d="M23.207,36.973H4.144a.829.829,0,1,1,0-1.658H24.865v-.829A2.487,2.487,0,0,0,22.378,32H3.315A3.315,3.315,0,0,0,0,35.315V51.892a3.315,3.315,0,0,0,3.315,3.315H23.207a3.315,3.315,0,0,0,3.315-3.315v-11.6A3.318,3.318,0,0,0,23.207,36.973ZM21.55,47.748a1.658,1.658,0,1,1,1.658-1.658A1.661,1.661,0,0,1,21.55,47.748Z" transform="translate(0 -32)" />
        </svg>
    );
};

export default Wallet;
