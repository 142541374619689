import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import GenericTable from '../../../components/medium/generic-table/GenericTable';
import SecondaryButton from '../../../components/small/buttons/secondary-button/SecondaryButton';
import { AssetRequestTableHeaderCell } from '../../../config/tableConfig';
import AssetRequestInterface from '../../../model/AssetRequestInterface';
import Row from '../../../model/Table/Row';
import { AssetRequestTableHeader } from '../../../model/Table/TableHeader';
import { actionFetchAssetRequest } from '../../../service/asset-request/action';
import Utils from '../../../utils/Utils';
import OverviewAssetRequestProps from './OverviewAssetRequestProps';
import OverviewAssetRequestPropsState from './OverviewAssetRequestState';

import './OverviewAssetRequest.scss';

class OverviewAssetRequest extends React.Component<OverviewAssetRequestProps, OverviewAssetRequestPropsState> {

    constructor(props: OverviewAssetRequestProps) {

        super(props);

        this.state = {
            pageIndex: 1,
            filterAsset: 'PENDING',
            filterByVipMode: false,
        };
    }

    componentWillMount = () => {

        /**
         * Launch pending request
         */
        this.fetchAssetRequestByStatut('PENDING');
    }

    colorByRequestStatus = (status: string) => {

        switch (status) {
            case 'REFUSED':
                return '#ff6659';

            case 'CONFIRMED':
                return '#6abf69';

            default:
                return '#fbc02d';
        }
    }

    fetchAssetRequestByStatut = (status: string) => {

        this.props.actionFetchAssetRequest({ status });
        this.setState({
            filterAsset: status,
        });
    }

    filterByVipMode = () => {
        this.setState({
            filterByVipMode: !this.state.filterByVipMode,
        });
    }

    formatAssetRequestToInjectTable = (assetRequest: AssetRequestInterface[]) => {

        const assetRequestRows: Row<AssetRequestTableHeader>[] = [];
        const sortAssetRequest: AssetRequestInterface[] = assetRequest.sort((a: AssetRequestInterface, b: AssetRequestInterface) => new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? 1 : -1);

        for (const request of sortAssetRequest) {

            const cells: AssetRequestTableHeader = {
                createdAt: { data: Utils.formatDateToFilter(new Date(request.createdAt)), textCellColor: 'white' },
                lastName: { data: request.lastName, textCellColor: 'white' },
                firstName: { data: request.firstName, textCellColor: 'white' },
                country: { data: request.country, textCellColor: 'white' },
                email: { data: request.email, textCellColor: 'white' },
                type: { data: request.status, textCellColor: this.colorByRequestStatus(request.status) },
                profil: {
                    data: <Link to={`/asset-request/${request._id}`}>
                        <SecondaryButton
                            disabled={false}
                            receiveClickEvent={false}
                            textButton={'Profil'}
                            type="button" />
                    </Link>
                },
                vipMode: { data: request.vipMode ? String(request.vipMode) : 'false', textCellColor: request.vipMode ? '#6abf69' : 'white' },
            };

            assetRequestRows.push({
                cells,
                id: request._id,
            });
        }

        return assetRequestRows;
    }

    render() {
        const {
            assetRequest,
        } = this.props;

        let assetRequestFilter: AssetRequestInterface[] = assetRequest.data || [];

        if (this.state.filterAsset) {

            assetRequestFilter = assetRequestFilter.filter((assetRequest: AssetRequestInterface) => assetRequest && assetRequest.status === this.state.filterAsset).filter((assetRequest: AssetRequestInterface) => assetRequest && this.state.filterByVipMode ? assetRequest.vipMode === this.state.filterByVipMode : assetRequest);
        }

        const assetRequestRows: Row<AssetRequestTableHeader>[] = this.formatAssetRequestToInjectTable(assetRequestFilter);

        return (
            <div className="overview-influencer">

                <div className="action-buttons">
                    <div className="item">
                        <SecondaryButton
                            disabled={false}
                            receiveClickEvent={false}
                            textButton={'Confirmed request'}
                            handleClick={() => this.fetchAssetRequestByStatut('CONFIRMED')}
                            type={'button'}
                        />
                    </div>

                    <div className="item">
                        <SecondaryButton
                            disabled={false}
                            receiveClickEvent={false}
                            textButton={'Pending request'}
                            handleClick={() => this.fetchAssetRequestByStatut('PENDING')}
                            type={'button'} />
                    </div>

                    <div className="item">
                        <SecondaryButton
                            disabled={false}
                            receiveClickEvent={false}
                            textButton={'Refused request'}
                            handleClick={() => this.fetchAssetRequestByStatut('REFUSED')}
                            type={'button'} />
                    </div>

                    <div className="item">
                        <button
                            className={`secondary-button ${this.state.filterByVipMode && 'active-button'}`}

                            onClick={() => this.filterByVipMode()}
                        >
                            Vip mode
                        </button>
                    </div>
                </div>

                {<div className="container-overview-influencer">
                    <GenericTable
                        paginationIsDisabled={true}
                        headerTable={AssetRequestTableHeaderCell}
                        showTableHeader={true}
                        rows={assetRequestRows}
                        enabledCheckbox={false}
                        enableCreateButton={false}
                        tableHeight={'100vh'}
                    />
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state: OverviewAssetRequestProps) => ({
    assetRequest: state.assetRequest,
});

export default connect(mapStateToProps, {
    actionFetchAssetRequest,
})(OverviewAssetRequest);
