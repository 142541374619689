import IUserAbTesting from 'model/AbTesting/User/IUserAbTesting';
import IUserAbTestingYield from 'model/AbTesting/User/IUserAbTestingYield';
import { RootState } from 'model/Redux';
import ReduxRootState from 'model/Redux/ReduxRootSate';
import IMetadata from 'model/User/IMetadata';
import UserInterface from 'model/User/UserInterface';
import { createSelector, Selector } from 'reselect';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import { Status } from '../../config/_enums';
import UserStoreData from './UserStoreData';

export const authSelector: Selector<RootState, ReduxRootState<UserStoreData>> = (state) => {
    return state.auth;
};
export const accountSelector: Selector<RootState, UserInterface> = state => state.auth.data.account;
export const accountDataSelector: Selector<RootState, UserStoreData> = state => state.auth.data;
export const userIdSelector: Selector<RootState, string> = state => state.auth.data.account._id;
export const isAuthenticatedSelector: Selector<RootState, boolean> = state => state.auth.data.isAuthenticated;
export const loadingUploadPictureSelector: Selector<RootState, boolean> = state => state.auth.data.loadingUploadPicture;
export const accountAsViewModelSelector: Selector<RootState, AccountViewModel> = state => new AccountViewModel({ account: state.auth.data.account, isAuthenticated: state.auth.data.isAuthenticated });

export const userIsAuthenticated = createSelector<RootState, boolean, boolean>(
    isAuthenticatedSelector,
    (isAuthenticated: boolean) => isAuthenticated,
);

export const abTestingSelector = createSelector<RootState, UserInterface, IUserAbTesting | undefined>(
    accountSelector,
    (account: UserInterface) => account.abTesting,
);

export const yieldAbTestingSelector = createSelector<RootState, IUserAbTesting | undefined, IUserAbTestingYield | undefined>(
    abTestingSelector,
    (abTesting: IUserAbTesting | undefined) => {
        if (!abTesting)
            return undefined;
        return abTesting.yield;
    },
);

export const metadataSelector = createSelector<RootState, UserInterface, IMetadata>(
    accountSelector,
    (account: UserInterface) => {
        return {
            locale: account.locale,
            currency: account.currency,
            timezone: account.timezone,
            language: account.language,
            country: account.country,
        };
    },
);

export const currencySelector = createSelector<RootState, IMetadata, string>(
    metadataSelector,
    (metadata: IMetadata) => metadata.currency,
);

export const creditSelector = createSelector<RootState, UserInterface, number>(
    accountSelector,
    (account: UserInterface) => account.credits,
);

export const postSubscriptionStatusSelector = createSelector<RootState, ReduxRootState<UserStoreData>, Status>(
    authSelector,
    (auth: ReduxRootState<UserStoreData>) => auth.data.postSubscriptionStatus
);

export const postSubscriptionAssetIdSelector = createSelector<RootState, ReduxRootState<UserStoreData>, string>(
    authSelector,
    (auth: ReduxRootState<UserStoreData>) => auth.data.postSubscriptionAssetId ?? ''
);

export const selectorAccountViewModel = createSelector<RootState, UserStoreData, AccountViewModel>(
    accountDataSelector,
    (auth: UserStoreData) => new AccountViewModel({ account: auth.account, isAuthenticated: auth.isAuthenticated, isAdminToVip: auth.isAdminToVip })
);

export const selectorConnectedUserId = createSelector<RootState, string, string>(
    userIdSelector,
    (userId: string) => userId
);

export const selectorIsAdminToVip = createSelector<RootState, UserStoreData, boolean>(
    accountDataSelector,
    (data: UserStoreData) => data.isAdminToVip
);
