import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import GenericStepperProps from './GenericStepperProps';
import IGenericStepper from './IGenericStepper';
import IconStepper from './IconStepper/IconStepper';
import clsx from 'clsx';
import './style.scss';
import { StepIconProps } from '@material-ui/core';

const GenericStepper: React.FC<GenericStepperProps> = (props) => {

    return (
        <div className={clsx('stepper-generic stepper-generic-light', {})}>
            <Stepper
                connector={<div></div>}
                activeStep={props.currentStep}
                orientation={props.orientation}
                classes={{
                    root: 'stepper-root',
                }}>

                {props.stepsContent.map((step: IGenericStepper, numberStep: number) => {
                    const labelClasses = numberStep === props.currentStep ? 'active-label' : 'label';

                    return (

                        <Step expanded={!!(props.currentStep > numberStep)} key={step.label}>

                            <StepLabel
                                classes={{ root: 'label-stepper' }}
                                StepIconComponent={(fallbackProps: StepIconProps) => <IconStepper {...fallbackProps} />}>
                                <h3 className={`shared-label-style ${labelClasses}`}>{step.label}</h3>
                            </StepLabel>

                            <StepContent classes={{
                                root: 'content-stepper',
                            }}>

                                <div >
                                    {step.element}
                                </div>

                            </StepContent>

                        </Step>

                    );
                })}
            </Stepper>
        </div>
    );
};

GenericStepper.defaultProps = ({
    orientation: 'horizontal',
});

export default GenericStepper;
