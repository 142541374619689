import ITrendexService from "./ITrendexService";

export default abstract class TrendexServiceAbstract implements ITrendexService {
    toDebugString = (): string => {
        let string = '';
        Object.keys(this).forEach((key: string) => {
            const val = (this as any)[key];
            if (typeof val !== "function") {
                if (typeof val === 'object')
                    string += `\n\r DEBUG - Property name : ${key}, Value : ${JSON.stringify(val)}`;
                else
                    string += `\n\rDEBUG - Property name : ${key}, Value : ${val}`;
            }
        });

        return string;
    }
}