import { useDispatch, useSelector } from 'react-redux';
import ITeamConfig from 'model/Team/ITeamConfig';
import { getTeamConfiguration } from 'service/team-configuration/action';
import { selectDefaultConfiguration, teamConfigurationLoadingSelector } from 'service/team-configuration/selector';
import TeamConfigurationViewModel from 'view-model/Team/TeamConfigViewModel/TeamConfigViewModel';
import IUseTeamConfiguration from './IUseTeamConfiguration';
import IUseTeamConfigurationProps from './IUseTeamConfigurationProps';

const useTeamConfiguration = ({ leagueType }: IUseTeamConfigurationProps): IUseTeamConfiguration => {
    const dispatch = useDispatch();
    const teamConfigurationLoading: boolean = useSelector(teamConfigurationLoadingSelector);
    const teamConfiguration: ITeamConfig | undefined = useSelector(selectDefaultConfiguration(leagueType));

    const getConfiguration = () => {
        if (teamConfigurationLoading || teamConfiguration)
            return;
        dispatch(getTeamConfiguration(leagueType));
    };

    const dispatchTeamConfiguration = () => dispatch(getTeamConfiguration(leagueType));

    return {
        dispatchTeamConfiguration,
        getConfiguration,
        teamConfigViewModel: new TeamConfigurationViewModel(teamConfigurationLoading, teamConfiguration),
    };
};

export default useTeamConfiguration;

