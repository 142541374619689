import i18n from '18n';
import React from 'react';
import './style.scss';
import CreditCardLoaderProps from './CreditCardLoaderProps';

const CreditCardLoader: React.FC<CreditCardLoaderProps> = ({ showBuyExplainer }) => {
    return (
        <div className="credit-card-loader">
            <div className="overlay" />
            <div className="content">
                <div className="img-container">
                    <img src="https://media4.giphy.com/media/MUlrJBZ1Kpwh1VUzlf/giphy.gif?cid=6c09b952cbb12b0e87ecb3e7a17bedd0f21e7ebaaf3a89cb&rid=giphy.gif&ct=s" alt="Credit Card Loader" />
                </div>

                <h6 className="message">{i18n.t('loader.credit.card')}</h6>
                {showBuyExplainer && <h6 className="message">{i18n.t('loader.credit.card.buy.explainer')}</h6>}
            </div>
        </div>
    )
};

export default CreditCardLoader;
