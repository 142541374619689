import MoneyEarned from 'components/icons/referral/MoneyEarned';
import Referral from 'components/icons/referral/Referral';
import useAccount from 'hooks/use-account/useAccount';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IUseAccount from '../../../hooks/use-account/IUseAccount';
import TransactionInterface from '../../../model/User/TransactionInterface';
import { getReferral } from '../../../service/affiliate/getters';
import { fetchReferralTransactions } from '../../../service/transactions/actions';
import { referralTransactionsSelector } from '../../../service/transactions/selectors';
import ReferYourFriendsTitle from '../referYourFriends-title/ReferYourFriendsTitle';
import ReferenceYourFriends from '../referenceYourFriends/ReferenceYourFriends';
import "./share-profile-referral.scss";
import ShareReferralButtons from '../share-referral-buttons/ShareReferralButtons';

const ShareProfileReferral: React.FC = () => {
    const { accountViewModel }: IUseAccount = useAccount();
    const referralTransactions: TransactionInterface[] = useSelector(referralTransactionsSelector);
    const { earnedReferral } = getReferral(referralTransactions);
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchReferralTransactions());
    }, []);
    const referralInfo = useMemo(() => ([
        { id: "R1", icon: <Referral />, label: i18next.t('Referral.shareProfile.friendReferred'), value: accountViewModel.referralUserList?.length },
        { id: "R2", icon: <MoneyEarned />, label: i18next.t('Referral.shareProfile.totalReferralEarn'), value: earnedReferral.toCurrency() },
    ]), [earnedReferral, accountViewModel.referralUserList]);

    return (
        <div className='share-profile-referral-container'>
            <ReferYourFriendsTitle />
            {accountViewModel.isAuthenticated &&
                <>
                    <ReferenceYourFriends greenButton={false} />
                    {accountViewModel.referralUserList?.length
                        ? <div className="referral-info-data">
                            {referralInfo.map((info) => <div key={info.id} className='refer-info'>
                                <span className="referInfo-label">{info.icon}{info.label} :</span>
                                <span className="referInfo-value">{info.value}</span>
                            </div>)}
                        </div>
                        : null}
                </>}
            <ShareReferralButtons accountVM={accountViewModel} />
        </div>
    )
};

export default ShareProfileReferral;