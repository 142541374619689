import { AssetCardLevel } from "config/_enums";
import { IAssetCardLevel } from "./IAssetCardLevel";

const ASSET_CARD_LEVEL: IAssetCardLevel = {
    [AssetCardLevel.Rookie]: {
        name: "Rookie",
        textColor: "#8187D0"
    },
    [AssetCardLevel.Contender]: {
        name: "Contender",
        textColor: "#A3804A",
    },
    [AssetCardLevel.Champion]: {
        name: "Champion",
        textColor: "#848383",
    },
    [AssetCardLevel.Legend]: {
        name: "Legend",
        textColor: "#EBB627"
    }
}

export default ASSET_CARD_LEVEL;