import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import useNotification from 'hooks/useNotification';
import i18next from 'i18next';
import React from 'react';
import { NavLink } from 'react-router-dom';
import NotificationProps from './NotificationProps';

const Alert: React.FC<AlertProps> = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

const Notification: React.FC<NotificationProps> = () => {
    const anchorOrigin: SnackbarOrigin = {
        vertical: 'top',
        horizontal: 'center'
    };
    const defaultAnchorOrigin: SnackbarOrigin = {
        vertical: 'bottom',
        horizontal: 'center'
    };

    const { notification, managementNotification } = useNotification();

    if (!notification.display || notification.message === '')
        return <></>;
    const type = notification.status === 200 ? 'success' : ((notification.status !== 200 && notification.status < 500 ? 'warning' : notification.status === 500 ? 'error' : 'info'));
    return (
        <Snackbar open={true} autoHideDuration={6000} anchorOrigin={notification.verticalTop ? anchorOrigin : defaultAnchorOrigin} onClose={() => managementNotification(false)}>
            <Alert onClose={() => managementNotification(false)} severity={type}>
                <NavLink style={{ color: 'white' }} to={`${notification.link}` ?? '#'}>
                    {i18next.t(notification.message)}
                </NavLink>
            </Alert>
        </Snackbar>
    );
};

export default Notification;
