import ReduxActionInterface from '../../model/ReduxActionInterface';
import * as TokenActionsTypes from './actionsType';
import ReduxRootState from '../../model/Redux/ReduxRootSate';
import Utils from '../../utils/Utils';

const ROOT_VALUE_STATE: ReduxRootState = {
    data: [],
    loading: false
};

export const affiliateReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    switch (action.type) {

        case TokenActionsTypes.AFFILIATE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case TokenActionsTypes.AFFILIATE_ERROR:
            if (action.payload) {
                return {
                    ...state,
                    loading: false,
                    error: action.payload.message,
                };
            }

            return state;

        case TokenActionsTypes.AFFILIATE_SUCCESS:
            if (action.payload) {

                const stateData = state.data && state.data.length ? Utils.removedDuplicateMongoFile(state.data, action.payload.data) : [];
                return {
                    ...state,
                    data: stateData.concat(action.payload.data),
                    loading: false,
                    error: null,
                };
            }

            return state;

        case TokenActionsTypes.AFFILIATE_REFERRALS_SUCCESS:
            if (action.payload) {

                // const stateData = state.data && state.data.length ? Utils.removedDuplicateMongoFile(state.data, action.payload.data) : [];
                return {
                    ...state,
                    data: {
                        referrals: action.payload.data
                    },
                    loading: false,
                    error: null,
                };
            }

            return state;

        case TokenActionsTypes.AFFILIATE_REFERRAL_USERS_SUCCESS:
            if (action.payload) {

                const referrals = state.data && state.data.referrals ? state.data.referrals : null;

                return {
                    ...state,
                    data: {
                        referrals: referrals,
                        referralUsers: (action.payload.data && action.payload.data.referrals ? action.payload.data.referrals : [])
                    },
                    loading: false,
                    error: null,
                };
            }

            return state;

        default: return state;
    }
};
