import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import SlidingPageAction from './actionType';

export const openSlidingPage = (Component: JSX.Element, verticalSlide: boolean = false): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    return dispatch({
        type: SlidingPageAction.OPEN_SLIDING_PAGE,
        payload: { Component, verticalSlide },
    });
};

export const closeSlidingPage = (): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    return dispatch({
        type: SlidingPageAction.DISMISS_SLIDING_PAGE,
        payload: {},
    });
};
