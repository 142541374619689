import React from 'react';
import IConfirmSellAssetProps from './IConfirmSellAssetProps';
import "./confirm-sell-asset-detail.scss";
import clsx from 'clsx';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import SellRewardDebtInfo from 'components/small/sell-reward-debt/SellRewardDebtInfo';

const ConfirmSellAssetDetail: React.FC<IConfirmSellAssetProps> = ({ sellAssetViewModel }) => {
    return <div className='sell-asset-detail-wrapper'>
        {
            sellAssetViewModel.ShowRewardDebt &&
            <SellRewardDebtInfo sellViewModel={sellAssetViewModel} />
        }
        <div className='sell-asset-detail-container'>
            <div className="sell-asset-detail_label">
                {sellAssetViewModel.ProfitLabel}
            </div>
            <div className="sell-asset-detail_value">
                {sellAssetViewModel.ChangeSign} {sellAssetViewModel.ProfitFormatted}
                <span className={clsx("asset-value-change", sellAssetViewModel.ChangeColor)}>
                    {sellAssetViewModel.isProfit ? <ArrowDropUp /> : <ArrowDropDown />}
                    {sellAssetViewModel.UnitChange} ({sellAssetViewModel.ChangePercent} %)
                </span>
            </div>
        </div>
    </div>
}

export default ConfirmSellAssetDetail;
