import React from 'react';

const Yield = (props: React.SVGProps<SVGSVGElement>) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" {...props}>
        <g id="Yield" transform="translate(-39.305 -245.532)">
            <ellipse id="Ellipse_11" data-name="Ellipse 11" cx="11.5" cy="12" rx="11.5" ry="12" transform="translate(39.305 245.532)" fill="#fff" opacity="0" />
            <path id="seedling-solid" d="M20.811,33.3a9.1,9.1,0,0,1-7.893,9.023,10.37,10.37,0,0,0-2.654-5.662A9.1,9.1,0,0,1,18.209,32h1.3A1.3,1.3,0,0,1,20.811,33.3ZM0,35.9a1.3,1.3,0,0,1,1.3-1.3H2.6a9.105,9.105,0,0,1,9.1,9.1v5.2a1.3,1.3,0,0,1-2.6,0v-3.9A9.105,9.105,0,0,1,0,35.9Z" transform="translate(40.399 216.427)" fill="#fff" />
        </g>
    </svg>
    )
}

export default Yield;
