import i18n from '18n';
import { IPriceDetailsProps } from 'components/medium/asset-transaction/asset-transaction-detail/price-details/PriceDetailsProps';
import React from 'react';
import IWithPriceDetails from './IWithPriceDetails';
import { AssetTransactionType } from 'config/_enums';
import { EmptyString, LAUNCH_MODE, SLIPPAGE } from 'config/_const';
import Utils from 'utils/Utils';

const withPriceDetails = (Component: React.FC<IPriceDetailsProps>) => {
    const MemoComponent = React.memo(Component);

    const Wrapper: React.FC<IWithPriceDetails> = ({ assetTransactionViewModel }) => {
        const priceInformations = React.useMemo(() => {
            const isBuyTransaction: boolean = assetTransactionViewModel.TransactionType === AssetTransactionType.BUY;
            const slippageText = assetTransactionViewModel.Asset.LaunchMode === LAUNCH_MODE.LIQUIDITY_POOL ? `(± ${Utils.fractionToPercentage(SLIPPAGE)} ${i18n.t("tolerated")})` : EmptyString;
            return assetTransactionViewModel.IsLiquidityPoolApplicable
                ? [
                    {
                        id: "R11",
                        show: true,
                        label: i18n.t("buyAsset.available"),
                        value: isBuyTransaction && assetTransactionViewModel.Asset.IsFunCategory ? i18n.t('fun.fees.value', { credits: assetTransactionViewModel.AvailableFieldFormatted }) : assetTransactionViewModel.AvailableFieldFormatted,
                    },
                    {
                        id: "R12",
                        show: true,
                        label: i18n.t("sellPerToken"),
                        value: `${assetTransactionViewModel.UnitPriceFromPoolFormatted} ${slippageText}`,
                    }
                ]
                : [
                    {
                        id: "R21",
                        show: true,
                        label: i18n.t("buyAsset.available"),
                        value: assetTransactionViewModel.AvailableFieldFormatted,
                    }
                ];
        }, [assetTransactionViewModel]);

        if (priceInformations.length)
            return < MemoComponent assetTransactionViewModel={assetTransactionViewModel} priceInformations={priceInformations} />;

        return <></>;
    };

    return React.memo(Wrapper);

};

export default withPriceDetails;