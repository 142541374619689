import Application from 'Application';
import CircularLoader from 'components/small/CircularLoader';
import { ASSET_TRANSACTION_DETAIL_ATTRIBUTES, SUPER_TALENT } from 'config/_const';
import { AssetTransactionType, PaymentMethodsEnum } from 'config/_enums';
import useAssetTransactionConfirmation from 'hooks/use-asset-transaction-confirmation/useAssetTransactionConfirmation';
import useAssetTransactionSlides from 'hooks/use-asset-transaction-slideup/useAssetTransactionSlides';
import useIsDesktopMode from 'hooks/useIsDesktopMode';
import i18next from 'i18next';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import CustomFacebookCampaign from 'service/facebook-pixel/CustomFacebookCampaign';
import PixelHandlerInitiateCheckout from 'service/facebook-pixel/PixelHandlerInitiateCheckout';
import { managementModal } from 'service/modal/action';
import BuyAssetViewModel from 'view-model/BuyAssetViewModel/BuyAssetViewModel';
import SellAssetViewModel from 'view-model/SellAssetViewModel/SellAssetViewModel';
import AssetTransactionWrapperHalfScreen from '../asset-transaction-wrapper-halfScreen/AssetTranasactionWrapperHalfScreen';
import AssetTransactionWrapper from '../asset-transaction-wrapper/AssetTransactionWrapper';
import ConfirmBuyAsset from '../confirm-buy-asset/ConfirmBuyAsset';
import ConfirmSellAssetDetail from '../confirm-sell-asset-detail/ConfirmSellAssetDetail';
import TxnAssetBody from '../local-components/txn-asset-body/TxnAssetBody';
import TxnAssetHeaderHalfScreen from '../local-components/txn-asset-header-halfScreen/TxnAssetHeaderHalfScreen';
import TxnAssetHeader from '../local-components/txn-asset-header/TxnAssetHeader';
import IAssetTransactionDetailProps from './IAssetTransactionDetailProps';
import "./asset-transaction-detail.scss";

const AssetTransactionDetail: React.FC<IAssetTransactionDetailProps> = (props) => {
    const { canShowHalfScreen } = useAssetTransactionSlides();
    const isDesktop: boolean = useIsDesktopMode();
    const isTransactionInMobileView = React.useMemo(() => !(canShowHalfScreen && isDesktop), [canShowHalfScreen, isDesktop]);
    const isTransactionTypeSell: boolean = React.useMemo(() => props.assetTransactionViewModel.TransactionType === AssetTransactionType.SELL, [props.assetTransactionViewModel.TransactionType]);
    const isTransactionTypeBuy: boolean = React.useMemo(() => props.assetTransactionViewModel.TransactionType === AssetTransactionType.BUY, [props.assetTransactionViewModel.TransactionType]);
    const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentMethodsEnum>(PaymentMethodsEnum.CREDITS);
    const dispatch = useDispatch();
    const {
        onConfirmation,
        onError,
        onSuccess,
        isTransactionLoading
    } = useAssetTransactionConfirmation(props.assetTransactionViewModel, selectedPaymentType);
    const creditCardConfirmationMethodRef = useRef<Function>(() => { });
    const [transactionType, setTransactionType] = React.useState<AssetTransactionType>(props.assetTransactionViewModel.TransactionType);
    const [activeTransactionDetailAttribute, setActiveTransactionDetailAttribute] = React.useState(ASSET_TRANSACTION_DETAIL_ATTRIBUTES.TOKENS);
    const [isCreditCardPaymentReady, setCreditCardPaymentReady] = useState<boolean>(true);
    const [confirmText, setConfirmText] = useState("Myportfolio.confirm");
    const [selectedPaymentTypeHasChanged, setSelectedPaymentTypeHasChanged] = useState<boolean>(false);
    const [prePrivateSaleMessage, setPrePrivateSaleTresholdMessage] = useState<string>("");
    const [isPrePrivateSale, setIsPrePrivateSale] = useState<boolean>(props.assetTransactionViewModel.Asset.isInPrePrivateSaleState());
    const isInjectedAsIframe: boolean = Application.getInstance().IsInjectedAsIframe;

    const handleConfirmation = () => {
        if (props.assetTransactionViewModel.TransactionType === AssetTransactionType.BUY && [PaymentMethodsEnum.STRIPE, PaymentMethodsEnum.STRIPE_NEW_CARD].includes(selectedPaymentType)) {
            if (props.assetTransactionViewModel.AssetId === SUPER_TALENT)
                CustomFacebookCampaign.trackCreditCardIntent();
            if (!selectedPaymentTypeHasChanged || selectedPaymentType === PaymentMethodsEnum.STRIPE_NEW_CARD)
                creditCardConfirmationMethodRef.current();
            else
                dispatch(managementModal(true, {
                    display: true,
                    disableBackDrop: true,
                    showBackArrow: false,
                    showCancelButton: true,
                    fullScreen: false,
                    type: 'CONFIRM',
                    propsCustom: {
                        className: "credit-card-confirm_customModal",
                        showCancelButton: true,
                        showLinkButton: false,
                        onRefused: () => dispatch(managementModal(false)),
                        onValidation: () => { dispatch(managementModal(false)); creditCardConfirmationMethodRef.current(); },
                        textValidButton: i18next.t('confirmBuyAsset.confirmModal.button.valid'),
                        textCancelButton: i18next.t('confirmBuyAsset.confirmModal.button.cancel'),
                        message: (
                            <>
                                <h2>{i18next.t('confirmBuyAsset.confirmModal.title')}</h2>
                                <Trans
                                    i18nKey={'confirmBuyAsset.confirmModal.text'}
                                    components={{ b: <b /> }}
                                    values={{ amount: props.assetTransactionViewModel.AmountFormatted, asset: props.assetTransactionViewModel.AssetName, token: props.assetTransactionViewModel.NumberOfToken }}
                                />
                            </>
                        ),
                    },
                }));
            return;
        }
        const pixelHandlerInitiateCheckout: PixelHandlerInitiateCheckout = new PixelHandlerInitiateCheckout();
        pixelHandlerInitiateCheckout.initiateCheckoutData = {
            assetId: props.assetTransactionViewModel.Asset.AssetId,
            currency: props.assetTransactionViewModel.Currency,
            value: props.assetTransactionViewModel.UserInputAmount,
            quantity: props.assetTransactionViewModel.NumberOfToken,
            status: true
        };
        pixelHandlerInitiateCheckout.handle();
        onConfirmation(transactionType);
    };



    React.useEffect(() => {
        if (props.assetTransactionViewModel.TransactionType !== AssetTransactionType.BUY) {
            setConfirmText("initPayment.sellshares");
            return;
        }
        switch (selectedPaymentType) {
            case PaymentMethodsEnum.CREDITS:
                batch(() => {
                    setConfirmText("initPayment.buyshares");
                    setPrePrivateSaleTresholdMessage("prePrivateSale.warning.credits");
                });
                break;
            case PaymentMethodsEnum.STRIPE:
            case PaymentMethodsEnum.STRIPE_NEW_CARD:
                batch(() => {
                    setConfirmText("confirmTransaction.stripe");
                    setPrePrivateSaleTresholdMessage("prePrivateSale.warning.credit-card");
                });
                break;
            case PaymentMethodsEnum.BITPAY:
                batch(() => {
                    setConfirmText("confirmTransaction.bitpay");
                    setPrePrivateSaleTresholdMessage("prePrivateSale.warning.bitpay");
                });
                break;
            default:
                batch(() => {
                    setConfirmText("Myportfolio.confirm");
                    setPrePrivateSaleTresholdMessage("");
                });
                break;
        }
    }, [props.assetTransactionViewModel.TransactionType, selectedPaymentType]);

    const shouldDisableTransaction = React.useCallback((): boolean => {
        if (!props.assetTransactionViewModel.IsReady) return true;
        const isConfirmReady = (): boolean => {
            const isPaymentTypeStripe = [PaymentMethodsEnum.STRIPE, PaymentMethodsEnum.STRIPE_NEW_CARD].includes(selectedPaymentType);
            return (isCreditCardPaymentReady || !isPaymentTypeStripe);
        };

        if (isTransactionTypeSell)
            if (props.assetTransactionViewModel.IsUserAmountHighWarning || props.assetTransactionViewModel.IsUserAmountLowWarning)
                return true;

        if (props.assetTransactionViewModel.IsSuperAdmin)
            return false;
        if (!props.assetTransactionViewModel.Asset.InitialSaleDateHasPassed)
            return true;
        return !isConfirmReady() || (isPrePrivateSale && selectedPaymentType !== PaymentMethodsEnum.BITPAY);
    }, [isCreditCardPaymentReady, isPrePrivateSale, isTransactionTypeSell, props.assetTransactionViewModel.Asset.InitialSaleDateHasPassed, props.assetTransactionViewModel.IsSuperAdmin, props.assetTransactionViewModel.IsUserAmountHighWarning, props.assetTransactionViewModel.IsUserAmountLowWarning, selectedPaymentType, props.assetTransactionViewModel.IsReady]);

    const isTransactionDisabled: boolean = shouldDisableTransaction();

    const renderTxnAssetBody = () => (
        <>
            <TxnAssetBody
                assetTransactionViewModel={props.assetTransactionViewModel}
                setNumberOfToken={props.setNumberOfToken}
                isTransactionInMobileView={isTransactionInMobileView}
                isTransactionTypeBuy={isTransactionTypeBuy}
                isTransactionTypeSell={isTransactionTypeSell}
                isSecondaryMarketRestrictionEnabled={props.isSecondaryMarketRestrictionEnabled}
                setIsSecondaryMarketRestrictionEnabled={props.setIsSecondaryMarketRestrictionEnabled}
                isPrePrivateSale={isPrePrivateSale}
                setIsPrePrivateSale={setIsPrePrivateSale}
                onCancel={props.closeBuyAsset}
                setUserInputAmount={props.setUserInputAmount}
                activeTransactionDetailAttribute={activeTransactionDetailAttribute}
                setActiveTransactionDetailAttribute={setActiveTransactionDetailAttribute}
                userInputAmount={props.userInputAmount}
            />
            {isTransactionTypeBuy &&
                <ConfirmBuyAsset
                    buyAssetViewModel={props.assetTransactionViewModel as BuyAssetViewModel}
                    onSuccess={onSuccess}
                    onError={onError}
                    selectedPaymentType={selectedPaymentType}
                    setSelectedPaymentType={setSelectedPaymentType}
                    setTransactionType={setTransactionType}
                    creditCardConfirmationMethodRef={creditCardConfirmationMethodRef}
                    setCreditCardPaymentReady={setCreditCardPaymentReady}
                    setSelectedPaymentTypeHasChanged={setSelectedPaymentTypeHasChanged}
                    isTransactionDisabled={isTransactionDisabled}
                    handleClick={handleConfirmation}
                />
            }
            {isTransactionTypeSell &&
                <ConfirmSellAssetDetail
                    sellAssetViewModel={props.assetTransactionViewModel as SellAssetViewModel}
                />
            }
        </>
    );

    return (
        <>
            {isTransactionInMobileView
                ? <AssetTransactionWrapper
                    onCancel={props.closeBuyAsset}
                    onConfirm={handleConfirmation}
                    isExiting={props.isExiting}
                    confirmText={i18next.t(!isInjectedAsIframe || !props.assetTransactionViewModel.Asset.IsAssetBenzema ? confirmText : 'benzema.buy-asset.confirm-modal.button')}
                    hideContinueButton={selectedPaymentType === PaymentMethodsEnum.OTHER}
                    disableContinueButton={isTransactionDisabled}
                    isTransactionTypeBuy={isTransactionTypeBuy}
                    isTransactionTypeSell={isTransactionTypeSell}
                    prePrivateSaleMessage={isPrePrivateSale ? prePrivateSaleMessage : undefined}
                    isInjectedAsIframe={isInjectedAsIframe}
                    hideBackArrow={props.hideBackArrow}
                >
                    <TxnAssetHeader
                        assetTransactionViewModel={props.assetTransactionViewModel}
                        renderDetailSubComponent={props.renderDetailSubComponent}
                    />
                    {renderTxnAssetBody()}

                </AssetTransactionWrapper>
                : <AssetTransactionWrapperHalfScreen
                    onCancel={props.closeBuyAsset}
                    onConfirm={handleConfirmation}
                    isExiting={props.isExiting}
                    confirmText={i18next.t(confirmText)}
                    hideContinueButton={selectedPaymentType === PaymentMethodsEnum.OTHER}
                    disableContinueButton={isTransactionDisabled}
                    isTransactionTypeBuy={isTransactionTypeBuy}
                    isTransactionTypeSell={isTransactionTypeSell}
                    prePrivateSaleTresholdMessage={isPrePrivateSale ? prePrivateSaleMessage : undefined}
                    hideBackArrow={props.hideBackArrow}
                >
                    <TxnAssetHeaderHalfScreen assetTransactionViewModel={props.assetTransactionViewModel} renderDetailSubComponent={props.renderDetailSubComponent} />
                    {renderTxnAssetBody()}
                </AssetTransactionWrapperHalfScreen>
            }
            {isTransactionLoading && <CircularLoader loaderMessage={i18next.t('trendexloading')} doNotSplitMessage={true} />}
        </>);
};

export default React.memo(AssetTransactionDetail);