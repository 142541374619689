import { Avatar } from '@material-ui/core';
import clsx from 'clsx';
import TournamentTrophy from 'components/icons/TournamentTrophy';
import WalletCard from 'components/icons/wallet/card/WalletCard';
import LevelSubIcon from 'components/small/avatar/level-sub-icon/LevelSubIcon';
import { TournamentRewardKind } from 'config/_enums';
import useModalDisplay from 'hooks/use-modal-display/useModalDisplay';
import { RootState } from 'model/Redux';
import ITournamentWin from 'model/Tournament/ITournamentWin';
import React from 'react';
import { useSelector } from 'react-redux';
import { tournamentWinsByUserIdSelector } from 'service/tournamentWins/selector';
import LevelSubIconViewModel from 'view-model/CircleAvatarViewModel/LevelSubIconViewModel';
import FightSwordIcon from '../../../assets/images/fight-sword.webp';
import IconEtoile from '../../../assets/images/icon-winner.webp';
import BronzeIcon from '../../../assets/images/league/bronze.webp';
import OrIcon from '../../../assets/images/league/or.webp';
import SilverIcon from '../../../assets/images/league/silver.webp';
import ITournamentUserCard from './ITournamentUserCard';
import './style.scss';

const TournamentUserCard: React.FC<ITournamentUserCard> = ({ isUserAlreadyJoinedTournament, kind, userId, isResultOfConnectedUser, userAvatar, place, teamScore, username, numberOfTokensInTeam, totalAssetsOwnedAtSubscriptionTime, cashprize, isTournamentResultCard = false, isTeamScoreBoosted }: ITournamentUserCard) => {
    const tournamentWinsByUserId: ITournamentWin | undefined = useSelector((state: RootState) => tournamentWinsByUserIdSelector(state, userId));
    const numberOfStars: number = tournamentWinsByUserId === undefined ? 0 : tournamentWinsByUserId.numberOfWins;
    const medals: { [key: number]: string } = {
        1: OrIcon,
        2: SilverIcon,
        3: BronzeIcon,
    };
    const getMedal = () => medals[place] || '';
    const { showScoreBoosterModal } = useModalDisplay();
    const doSubscribedUserHasBoosted = (isTeamScoreBoosted && isUserAlreadyJoinedTournament);
    const SHOW_MODAL_BOOST = false;

    const openScoreBoosterModal = (e: React.MouseEvent) => {
        if (!doSubscribedUserHasBoosted)
            return
        if (!SHOW_MODAL_BOOST)
            return;
        e.stopPropagation();
        showScoreBoosterModal();
    }

    return (
        <div className={clsx('tournament-user-card', { highlight: isResultOfConnectedUser })} onClick={openScoreBoosterModal}>

            <div className="tournament-user-card__avatar">
                <div className="tournament-user-card__avatar--rank">
                    {place}
                </div>
                <div className="tournament-user-card__avatar--profile">
                    <Avatar alt="MOCK" src={userAvatar} />
                    {isTournamentResultCard && place < 4 &&
                        <img alt="Medal" className="medal" src={getMedal()} />
                    }
                    {tournamentWinsByUserId && numberOfStars > 0 &&
                        <div className={clsx(`tournaments-won stars-${numberOfStars}`, { 'tournament-in-progress': isTournamentResultCard })}>
                            {Array.from({ length: numberOfStars }).map((_, index) => (
                                <img alt="StarIcon" src={IconEtoile} key={index} />
                            ))}
                        </div>
                    }
                </div>
            </div>

            <div className="tournament-user-card__info">
                <div className="tournament-user-card__info--pseudo">
                    <LevelSubIcon
                        subIconViewModel={new LevelSubIconViewModel(teamScore, isTeamScoreBoosted)}
                        showExplainer={true}
                        textExplainer={'score.text.explainer'}
                        placementExplainer={'top'}
                        canShowBoostIcon={isTeamScoreBoosted}
                        canShowPointer={true}
                    />
                    <span>
                        {username}
                    </span>
                </div>
                <div className="tokens">
                    <span><img alt='FightSwordIcon' src={FightSwordIcon} />{numberOfTokensInTeam}</span>
                    {totalAssetsOwnedAtSubscriptionTime !== undefined &&
                        <span><WalletCard className="tokens-icon" />{totalAssetsOwnedAtSubscriptionTime}</span>
                    }
                </div>
            </div>

            <div className="tournament-user-card__score">
                <div className='cashprize-container'>
                    {isTournamentResultCard && <TournamentTrophy />}
                    {kind === TournamentRewardKind.SHARES_REWARD && <p className={clsx('no-margin', {
                        "result-color": isTournamentResultCard
                    })}>≈</p>}
                    <p className={clsx('no-margin cashprize', { 'result-card': isTournamentResultCard })}>
                        {cashprize}
                    </p>
                    {kind === TournamentRewardKind.SHARES_REWARD && <WalletCard className={clsx('cards-icon', {
                        "cards-icon-result": isTournamentResultCard
                    })} />}
                </div>
            </div>
        </div>
    );
};

export default React.memo(TournamentUserCard);
