import { ArrowDropDown, ArrowDropUpRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { EmptyString, MAX_CURRENT_LENGTH, TRENDEX_ROLES, ZERO } from 'config/_const';
import { TournamentLeagues } from 'config/_enums';
import { TournamentLeagueIcon } from 'data/TournamentImages/tournamentImages.data';
import useUserLeague from 'hooks/use-user-league/useUserLeague';
import { ISvgIconProps } from 'interfaces/SvgIcon.interface';
import React from 'react';
import { Trans } from 'react-i18next';
import WalletItem from '../wallet-item/WalletItem';
import IWalletOverviewProps from './IWalletOverviewProps';

const WalletOverview: React.FC<IWalletOverviewProps> = ({ walletViewModel, accountViewModel }) => {
    const getChangeIcon = () => {
        if (!walletViewModel.Profit) return EmptyString;
        return walletViewModel.Profit > ZERO ? <ArrowDropUpRounded className="color-green" /> : <ArrowDropDown className="color-red" />;
    };
    const { userLeagueViewModel } = useUserLeague();
    const SvgIcon: React.FC<ISvgIconProps> = TournamentLeagueIcon[userLeagueViewModel.LeagueAttribution.Name as TournamentLeagues]

    return (<div className={clsx("wallet-overview", { 'onboarding-mode': accountViewModel.IsOnboardingRunning })}>
        {accountViewModel.UserType !== TRENDEX_ROLES.VIP &&
            <div className="wallet-summary">
                <div className="wallet-summary_info">
                    <div className='wallet-summary_value'>
                        <h3 className="wallet-summary_value_header"><Trans i18nKey='totalValue' /></h3>
                        <div
                            className={clsx('wallet-summary_value_content',
                                {
                                    'color-red': walletViewModel.Profit < ZERO,
                                    'font-small': walletViewModel.selectPortfolioTotalValue(accountViewModel).toCurrency().length >= MAX_CURRENT_LENGTH,
                                })}>
                            {walletViewModel.selectPortfolioTotalValue(accountViewModel).toCurrency()} {getChangeIcon()}
                        </div>
                        {!accountViewModel.IsOnboardingRunning &&
                            <div className={clsx('wallet-summary_value_change', { 'color-red': walletViewModel.Profit < ZERO })}>
                                {walletViewModel.EarningChangeSign} {walletViewModel.Profit.toCurrency()} ({walletViewModel.ProfitPercentage.toPercentage()})
                            </div>
                        }
                    </div>

                    <div className='user-level'>
                        <SvgIcon className='icon' />
                        <div className='info'>
                            <p className='title'><Trans i18nKey='user.league.level' /></p>
                            <p className='name'>{userLeagueViewModel.LeagueAttribution.Name}</p>
                        </div>

                    </div>
                </div>
            </div>
        }

        {!accountViewModel.IsOnboardingRunning &&
            <div className="wallet-items-list">
                {accountViewModel.getWalletOverview(walletViewModel).map((item, index) => {
                    if (!item.canDisplay) return null;
                    return <WalletItem key={index} {...item} />
                })}
            </div>
        }
    </div >);
};

export default WalletOverview;
