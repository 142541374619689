import React from "react";
import "./LogoBlueTrendex.scss";
import logoTrendex from "assets/images/login-register/logo-blue-trendex.png";
import { Trans } from "react-i18next";
import ILogoBlueTrendexProps from "./ILogoBlueTrendexProps";

export const LogoBlueTrendex = ({ title }: ILogoBlueTrendexProps): JSX.Element => {
    return (
        <div className="container-logo-blue-trendex">
            <img src={logoTrendex} className="logo-blue-trendex" alt="trendex-blue-logo" />
            <p className="text">
                <Trans
                    i18nKey={title}
                    components={{ span: <span className="blue" /> }}
                />
            </p>
        </div>
    );
};

export default LogoBlueTrendex;
