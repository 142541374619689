import { ACTIONS_RECAPTCHA, CONNECTION_MODE, ENABLE_RECAPTCHA, LOCAL_STORAGE_KNOWN_KEYS } from "config/_const";
import IUseAccount from "hooks/use-account/IUseAccount";
import useAccount from "hooks/use-account/useAccount";
import IUserAuthenticationForm from "model/Form/IUserAuthenticationForm";
import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import IUseAuthenticationFormProps from "./IUseAuthenticationFormProps";
import { isValidEmail, isValidPassword } from "utils/validator";
import i18next from "i18next";
import { FieldValidationResult } from "./IFieldValidateResult";
import { IUseAuthentificationForm } from "./IUseAuthentificationForm";

const useAuthentificationForm = ({ connectionViewModel }: IUseAuthenticationFormProps): IUseAuthentificationForm => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { logInUser, registerUser }: IUseAccount = useAccount();
    const [validationResults, setValidationResults] = useState<FieldValidationResult>({
        email: { error: '', success: '' },
        password: { error: '', success: '' }
    });

    const btnIsDisabled = (): boolean => {
        return (
            validationResults.email.error !== '' ||
            validationResults.password.error !== ''
        );
    }

    const verifyField = (
        fieldName: string,
        value: string,
        isValid: (value: string) => boolean,
        errorMessageKey: string,
        successMessageKey: string
    ): void => {
        const error = value.trim() === '' ? '' : isValid(value) ? '' : i18next.t(errorMessageKey);
        const success = isValid(value) ? i18next.t(successMessageKey) : '';
        setValidationResults((prevResults) => ({
            ...prevResults,
            [fieldName]: { error, success },
        }));
    };

    const verifyEmail = (email: string): void => {
        verifyField('email', email, isValidEmail, 'register.mail.errorMessage', 'register.mail.validMessage');
    };

    const verifyPassword = (password: string): void => {
        verifyField('password', password, isValidPassword, 'register.password.errorMessage', 'register.password.validMessage');
    };

    const handleCheckboxChange = (isChecked: boolean): void => {
        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.EMAIL_NOTIFICATION_ENABLED, JSON.stringify(isChecked));
    };

    const submitAuthentificationForm = React.useCallback(async (event: React.FormEvent<HTMLFormElement>, form: IUserAuthenticationForm) => {
        event.preventDefault();
        if (connectionViewModel.Mode === CONNECTION_MODE.LOGIN)
            return logInUser(form.email, form.password);
        if (!ENABLE_RECAPTCHA)
            return registerUser(form.email, form.password, '', '', '', connectionViewModel.ApplicationSportVersionEnum, connectionViewModel.IsRegisterAsVip);
        if (!executeRecaptcha)
            return;
        const token: string = await executeRecaptcha(ACTIONS_RECAPTCHA.REGISTER);
        if (token)
            return registerUser(form.email, form.password, '', '', token, connectionViewModel.ApplicationSportVersionEnum, connectionViewModel.IsRegisterAsVip);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRecaptcha, connectionViewModel.Mode]);


    return {
        submitAuthentificationForm,
        verifyEmail,
        verifyPassword,
        handleCheckboxChange,
        btnIsDisabled,
        validationResults
    }
};

export default useAuthentificationForm;