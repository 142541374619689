import IAssetTransactionProps from 'components/medium/asset-transaction/IAssetTransactionProps';
import CircularLoader from 'components/small/CircularLoader';
import { RootState } from 'model/Redux';
import React from 'react';
import { useSelector } from 'react-redux';
import IAssetCurrentInformationData from 'service/asset-current-information/IAssetCurrentInformationData';
import IWithAssetTransactionParams from './IWithAssetTransactionParams';
import useAssetPredictPrice from 'hooks/use-asset-predict-price/useAssetPredictPrice';

const withAssetTransaction = (Component: React.FC<IAssetTransactionProps>) => {
    const MemoComponent: React.FC<IAssetTransactionProps> = React.memo(Component);

    const Wrapper: React.FC<IWithAssetTransactionParams> = ({ asset, transactionType, fromPack, orderType }) => {
        useAssetPredictPrice({ asset: asset, transactionType });

        const assetCurrentInformation: IAssetCurrentInformationData = useSelector((state: RootState) => state.assetsCurrentInformation.data[asset._id]);
        const assetCurrentInformationIsEmpty: boolean = Boolean(!assetCurrentInformation || (typeof assetCurrentInformation.availableSupply === 'undefined' && typeof assetCurrentInformation.secondaryMarketSupply === 'undefined'))
        if (assetCurrentInformationIsEmpty)
            return <CircularLoader loaderMessage='' />;

        return <MemoComponent fromPack={fromPack} assetCurrentInformation={assetCurrentInformation} asset={asset} transactionType={transactionType} orderType={orderType} />
    }

    return React.memo(Wrapper);
}

export default withAssetTransaction;