import React from 'react';
import MonitoringPrivateSellProps from './MonitoringPrivateSellProps';
import './MonitoringPrivateSell.scss';
import Divider from '../../../../small/divider/Divider';
import Utils from '../../../../../utils/Utils';
import i18next from 'i18next';

const MonitoringPrivateSell: React.FC<MonitoringPrivateSellProps> = (props) => {

    React.useEffect(
        () => {
            const intervalID = setInterval(
                () => {

                    props.onRefresh && props.onRefresh();
                },
                5000,
            );

            return () => {
                clearInterval(intervalID);
            };
        },
        [props],
    );

    return (
        <div className="monitoring-private-sell">
            <h5 className="no-margin"><b>{props.privateSellFinish ? i18next.t('MonitoringPrivateSell.summary') : i18next.t('MonitoringPrivateSell.summary')}</b></h5>

            <Divider />

            <h5>{i18next.t('MonitoringPrivateSell.open')}{Utils.formatDateToCompleteText(new Date(props.startDate))}</h5>

            <div className="sell-total">
                <h3 className="no-margin">{i18next.t('MonitoringPrivateSell.total')}</h3>
                <h2 className="no-margin">{props.totalPaid.toCurrency()}</h2>
            </div>

            <div className="sell-total">
                <h3 className="no-margin">{i18next.t('MonitoringPrivateSell.sold')}</h3>
                <h2 className="no-margin">{props.totalTokensSell}</h2>
            </div>
            <div className="sell-total">
                <h3 className="no-margin">{i18next.t('MonitoringPrivateSell.rest')}</h3>
                <h2 className="no-margin">{props.remainingTokens}</h2>
            </div>

            <h5>{props.privateSellFinish ? i18next.t('MonitoringPrivateSell.finished') : i18next.t('MonitoringPrivateSell.limitDate')} {Utils.formatDateToCompleteText(new Date(props.endDate))}</h5>

            <p>{i18next.t('MonitoringPrivateSell.ifNotSold')}</p>
        </div>
    );

};

export default MonitoringPrivateSell;
