import Application from 'Application';
import i18next from 'i18next';
import IAssetReward from 'interfaces/IAssetReward';
import LeagueAttribution from 'model/League/LeagueAttribution';
import IClaimableUserReward from 'model/Tournament/Rewards/IClaimableUserReward';

export default class RewardModalViewModel {
    private place: number;
    private creditsReward: number;
    private league?: LeagueAttribution;
    private higherLeaguePotentialReward: number;
    private totalTokensReceive: number;
    private assetsReward: IAssetReward[];
    private hasWinAssets: boolean;

    private Ranks = [
        'gold',
        'silver',
        'bronze',
    ];

    private Prefix = [
        i18next.t('top1'),
        i18next.t('top2'),
        i18next.t('top3'),
    ];

    constructor(args: IClaimableUserReward, league?: LeagueAttribution) {
        const defaultPlace = args.place ?? -1;
        this.place = args.fromUserPersonalTournament ? Application.getInstance().UserPersonalTournamentConfig.place : defaultPlace;
        this.creditsReward = args.virtualCreditsAmount ?? 0;
        this.league = league;
        this.higherLeaguePotentialReward = args.higherLeaguePotentialReward ?? 0;
        this.totalTokensReceive = args.totalTokensReceive ?? 0;
        this.assetsReward = args.assetsReward ?? [];
        this.hasWinAssets = (args.assetsReward || []).length > 0;
    }

    public get Place(): number {
        return this.place;
    }

    public get CreditsReward(): number {
        return this.creditsReward;
    }

    public get RankImage(): string {
        if (!this.IsTop3)
            return '';
        return `/img/tournament/${this.Ranks[this.place - 1]}-min.png`;
    }

    public get IsTop3(): boolean {
        return this.place > 0 && this.place <= 3;
    }

    public get isTop10(): boolean {
        return this.place > 0 && this.place <= 10;
    }

    public get ClassNameColor(): string {
        if (!this.IsTop3)
            return '';
        return this.Ranks[this.place - 1];
    }

    public get RanksSuffix(): string {
        if (!this.IsTop3)
            return i18next.t('otherTop');
        return this.Prefix[this.place - 1];
    }

    public creditsRewardAsString(): string {
        return this.CreditsReward.toCurrency();
    }

    public get HigherLeague() {
        return this.league?.HigherLeagueName
    }

    public get ShouldShowHigherLeagueRewardDisclaimer() {
        return Boolean(this.higherLeaguePotentialReward && this.higherLeaguePotentialReward > this.creditsReward);
    }

    public get HigherLeaguePotentialRewardAsCurrency() {
        return this.higherLeaguePotentialReward.toCurrency()
    }

    public get TotalTokensReceive() {
        return this.totalTokensReceive;
    }

    public get AssetsReward() {
        return this.assetsReward;
    }

    public get HasWinAssets(): boolean {
        return this.hasWinAssets;
    }
}
