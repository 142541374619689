import clsx from 'clsx';
import { HIDDEN_BOTTOM_MENU_PATHS, HIDE_TRANSITION_OFFSET, SCROLL_EFFECT_DESABLE, SCROLL_TOP_LIMITED_TO_DISPLAY_BOTTOM_BAR, TRENDEX_ROLES_ENUM } from 'config/_const';
import { MENU_ITEMS } from 'config/menu';
import useBottomBar from 'hooks/use-bottom-bar/useBottomBar';
import useIsMobile from 'hooks/useIsMobile';
import MenuInterface from 'model/Menu/MenuInterface';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectIsIOS } from 'service/app-config/selectors';
import { filterByApplicationRestrictions, filterMenuByBetaAppCondition, filterMenuByBottom, filterMenuByRoleOrAdminSwitch } from 'service/menu/filters';
import BottomTabBarButton from '../bottom-tab-bar-button/BottomTabBarButton';
import './BottomTabBar.scss';
import type BottomTabBarProps from './BottomTabBarProps';

const BottomTabBar: React.FC<BottomTabBarProps> = (props) => {
    const menuByBottom = filterMenuByBottom(MENU_ITEMS);
    const menuByBetaApp = filterMenuByBetaAppCondition(menuByBottom);
    const menuByRole = filterMenuByRoleOrAdminSwitch(menuByBetaApp, TRENDEX_ROLES_ENUM[props.accountType || 'USER'], props.isAdminToVip);
    const applicationSpecific = filterByApplicationRestrictions(menuByRole);
    const menu: MenuInterface[] = applicationSpecific.sort((a: MenuInterface, b: MenuInterface) => (a?.bottomOrder?.[props.accountType || 'USER'] || 0) - (b?.bottomOrder?.[props.accountType || 'USER'] || 0));
    const isIOS = useSelector(selectIsIOS);
    const { pathname: currentPath } = useLocation();
    const { isOpen } = useBottomBar();
    const [isScrollingDown, setScrollingDown] = useState<boolean>(false)
    const scrollYRef = useRef<number>(0);
    const isMobile = useIsMobile();

    const handleScroll = useCallback((): void => {
        if (SCROLL_EFFECT_DESABLE)
            return;
        if (scrollYRef.current > window.scrollY + HIDE_TRANSITION_OFFSET || scrollYRef.current < SCROLL_TOP_LIMITED_TO_DISPLAY_BOTTOM_BAR) {
            setScrollingDown(false);
        }
        if (scrollYRef.current < window.scrollY - HIDE_TRANSITION_OFFSET) {
            setScrollingDown(true);
        }
        scrollYRef.current = window.scrollY;
    }, []);

    useEffect(() => {
        if (isMobile) {
            window.addEventListener('scroll', handleScroll);
        }
        if (!isMobile) {
            window.removeEventListener('scroll', handleScroll);
        }
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll, isMobile]);

    const isBottomTabBarHidden = () => HIDDEN_BOTTOM_MENU_PATHS.includes(currentPath) || !isOpen || /\/asset\//.test(currentPath);
    return (
        <div className={clsx('bottom-tab-bar', { iOS: isIOS, hide: isBottomTabBarHidden(), fade: isScrollingDown })}>
            {menu.map((el: MenuInterface) => {
                if (!props.isAuthenticated && !el.guestAccess)
                    return false;

                return (
                    <BottomTabBarButton key={el.url} {...el} />
                );
            })}
        </div >
    );
};

export default BottomTabBar;
