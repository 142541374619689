import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { setup } from 'axios-cache-adapter';
import Cookies from 'js-cookie';
import localforage from 'localforage';
import { RootState } from 'model/Redux';
import { batch } from 'react-redux';
import { Store } from 'redux';
import { AUTH_LOGOUT } from 'service/auth/actionsType';
import { RESET_STATE } from 'service/commonActionTypes';
import { MODAL_DISPLAY } from 'service/modal/actionsType';
import Utils from 'utils/Utils';
import { AXIOS_STATUS_TEXT, CACHING_REQUEST, DEFAULT_CACHE_ASSET, HTTP_UNAUTHORIZED, TRENDEX_COOKIES, TRENDEX_REQUEST_HEADERS } from './_const';
import ICacheRequest from '../model/ICacheRequest';
import { setJwtToken } from 'service/token.service';

const integrationAsDevelopmentServer: boolean = JSON.parse(process.env.INTEGRATION_AS_DEFAULT_SERVER ?? 'false');
export const loadProxyUrl = (): string => {
    if (window.location.host.includes('localhost'))
        return !integrationAsDevelopmentServer ? 'http://localhost:8000' : 'https://integration-api.trendex.vip';
    if (window.location.host.includes('192.168.43.94'))
        return 'http://192.168.43.94:8000';
    if (window.location.host.includes('192.168.6.108'))  // ordi Andres
        return 'http://192.168.6.108:8000';
    if (window.location.host.includes('demo-app.trendex.vip'))  // pré-prod
        return 'https://demo-api.trendex.vip';
    if (window.location.host.includes('integration-app.trendex.vip'))  // pré-prod
        return 'https://integration-api.trendex.vip';
    if (window.location.host.includes('suprematic.io'))  // pré-prod
        return 'https://suprematic.herokuapp.com';
    return 'https://hello.trendex.vip';
};

export const forageStore = localforage.createInstance({
    driver: [localforage.LOCALSTORAGE],
    name: 'tdx-cache',
});

const AxiosConfig = setup({
    baseURL: `${loadProxyUrl()}/api/`,
    headers: {
        'Content-Type': 'application/json',
    },
    proxy: {
        host: `${loadProxyUrl()}`,
        port: 8000,
    },
    cache: {
        maxAge: 1000,
        exclude: {
            filter: (config: AxiosRequestConfig) => !CACHING_REQUEST.find((requestConfig: ICacheRequest) => config.url && config.url.includes(requestConfig.endpoint))
        },
        key: (req: AxiosRequestConfig) => CACHING_REQUEST.find((requestConfig: ICacheRequest) => req.url && req.url.includes(requestConfig.endpoint))?.storeKey || (req.url || DEFAULT_CACHE_ASSET),
        store: forageStore,
    }
});

export const initializeJwtHeaderAndUinqueUserId = (token: string, uniqueUserId: string | undefined) => {
    initializeJwtHeader(token)

    if (uniqueUserId) {
        Cookies.set(TRENDEX_COOKIES.UNIQUE_USER_ID.name, uniqueUserId);
    }

    AxiosConfig.defaults.headers.common[TRENDEX_REQUEST_HEADERS.UNIQUE_USER_ID] = uniqueUserId;
};

export const initializeJwtHeader = (token: string) => {
    setJwtToken(token)
    AxiosConfig.defaults.headers.common[TRENDEX_REQUEST_HEADERS.AUTH_TOKEN] = token;
};

export const clearAxiosJWTAndUniqueUserIdHeader = () => {
    delete AxiosConfig.defaults.headers.common[TRENDEX_REQUEST_HEADERS.AUTH_TOKEN];
    delete AxiosConfig.defaults.headers.common[TRENDEX_REQUEST_HEADERS.UNIQUE_USER_ID];
}

export const getSource = () => {
    return axios.CancelToken.source();
};

export const getCancelationToken = () => {
    return getSource().token;
};

export const isJwtExpired = (error: AxiosError): boolean => {
    if (!error.response)
        return false;
    const { status, statusText } = error.response;
    return error.isAxiosError && status === HTTP_UNAUTHORIZED && statusText === AXIOS_STATUS_TEXT.UNAUTHORIZED;
};

const handleRequestCache = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const overideConfig: AxiosRequestConfig = { ...config };
    const cachingCustomConfig: ICacheRequest | undefined = CACHING_REQUEST.find((requestConfig: ICacheRequest) => overideConfig.url!.includes(requestConfig.endpoint));
    if (!cachingCustomConfig || !cachingCustomConfig.enabled)
        return overideConfig;
    overideConfig.cache = {
        ...overideConfig.cache,
        maxAge: cachingCustomConfig.msMaxAge
    };
    return overideConfig;
}

export const initializeAxiosInterceptor = (store: Store) => {
    AxiosConfig.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            if (!config.url)
                return config;
            return handleRequestCache(config);
        },
        (error: AxiosError) => {
            return Promise.reject(error);
        }
    );
    AxiosConfig.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => {
            const state: RootState = store.getState();
            const userIsConnected: boolean = state.auth.data.isAuthenticated;
            if (!isJwtExpired(error) || !userIsConnected)
                return Promise.reject(error);
            Utils.clearUserData();
            batch(() => {
                store.dispatch({ type: AUTH_LOGOUT });
                store.dispatch({ type: RESET_STATE });
                store.dispatch({
                    type: MODAL_DISPLAY,
                    payload: {
                        modalObject: {
                            display: true,
                            disableBackDrop: true,
                            showBackArrow: false,
                            fullScreen: Utils.isMobile(),
                            type: 'SESSION_EXPIRED',
                            propsCustom: {},
                        },
                    },
                });
            });
            return Promise.reject(error);
        },
    );
};

export default AxiosConfig;
