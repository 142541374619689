import { OPTIONS_FOR_DATE, OPTIONS_FOR_TIME } from 'config/_const';
import Utils from 'utils/Utils';
import moment, { DurationInputObject, MomentInputObject } from 'moment';
const momentTZ = require('moment-timezone');

declare global {
    interface Date {
        addTimes(durationInput: DurationInputObject): Date;
        toTimezoneFormat(timezone: string): Date;
        toTimeStringFormat(timezone: string): string;
        toDateString(): string;
        toDateAndTimeString(timezone: string): string;
        formatDDMMYY(): string;
        getTimeInSeconds(): number;
        subtractDates(dateInput: MomentInputObject): Date;
        getDifference(dateInput: Date): number;
    }
}

Date.prototype.addTimes = function (durationInput: DurationInputObject): Date {
    return moment(this).add(durationInput).toDate();
};

Date.prototype.toTimezoneFormat = function (timezone: string): Date {
    return momentTZ(this).tz(timezone).format();
};

Date.prototype.toTimeStringFormat = function (timezone: string): any {
    return new Date(this).toLocaleTimeString(Utils.getCurrentLanguage(), { ...OPTIONS_FOR_TIME, timeZone: timezone });
};

Date.prototype.toDateString = function (): string {
    return new Date(this).toLocaleDateString(Utils.getCurrentLanguage(), OPTIONS_FOR_DATE);
};

Date.prototype.toDateAndTimeString = function (timezone: string): string {
    return this.toLocaleDateString(Utils.getCurrentLanguage(), { ...OPTIONS_FOR_DATE, ...OPTIONS_FOR_TIME, timeZone: timezone });
};

Date.prototype.formatDDMMYY = function (): string {
    return moment(this).format("DD/MM/YY");
}

Date.prototype.getTimeInSeconds = function (): number {
    return Math.floor(this.getTime() / 1000);
}

Date.prototype.subtractDates = function (dateInput: MomentInputObject): Date {
    return moment(this).subtract(dateInput).toDate();
}

Date.prototype.getDifference = function (dateInput: Date): number {
    return moment(this).diff(dateInput)
}
