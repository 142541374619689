import { useDispatch, useSelector } from "react-redux";
import { getAllSportsCountries } from "service/sports-countries/actions";
import { isSportsCountriesSelectorLoading, sportsCountriesSelector } from "service/sports-countries/selectors";

const useSportsCountries = () => {
    const dispatch = useDispatch();
    const countriesList = useSelector(sportsCountriesSelector);
    const isSportsCountryFetchLoading = useSelector(isSportsCountriesSelectorLoading);
    const fetchSportsCountries = () => dispatch(getAllSportsCountries());

    return {
        countriesList,
        isSportsCountryFetchLoading,
        fetchSportsCountries
    }
}
export default useSportsCountries;