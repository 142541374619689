import React from 'react';
import clsx from 'clsx';
import './IconStepper.scss';
import IconStepperProps from './IconStepperProps';

const IconStepper = (props: IconStepperProps) => {
    return (
        <div>
            <p className={clsx('icon-stepper icon-stepper-light', {})}>{props.icon}</p>
        </div>
    );
};

export default IconStepper;
