import * as React from 'react';

const Chart: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    const color = props.color || "#000";
    const DEFAULT_SIZE = '20px';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={props.height ?? DEFAULT_SIZE} width={props.width ?? DEFAULT_SIZE}  {...props}>
            <path d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z" fill={color}/>
        </svg>
    );
}

export default Chart;
