import Cookies from 'js-cookie';
import { TRENDEX_COOKIES } from 'config/_const';
import jwtDecode from 'jwt-decode';
import { IToken } from 'interfaces/token.interface';


/**
 * Set JWT token to storage(cookies).
 *
 * @param {string} JWTToken
 * @return {void}
 */
export const setJwtToken = (JWTToken: string) => {
  Cookies.set(TRENDEX_COOKIES.JWT_TDX.name, JWTToken, {
    expires: TRENDEX_COOKIES.JWT_TDX.expiresAsDays,
  });
};


/**
 * Get JWT token from storage(cookies).
 *
 */
export const getJwtToken: () => string | undefined = () => {
  return Cookies.get(TRENDEX_COOKIES.JWT_TDX.name);
};

/**
 * Get Decoded JWT-token
 *
 * @param {string} token
 * @return {IToken | null}
 */
export function getDecodedToken(token: string): IToken | null {
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
}

/**
 * Checks JWT-token is valid if token can be decoded and has expired date greater than today
 *
 * @return {boolean}
 */
export const isJwtTokenValid: () => boolean = () => {
  const JWTToken = getJwtToken();
  if (!JWTToken) return false;

  const decodedJWT = getDecodedToken(JWTToken);
  if (decodedJWT) {
    return (decodedJWT.exp * 1000 > Date.now()) ? true : false
  }

  return false;
}

