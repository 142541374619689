import React from 'react';
import './RedButton.scss';
import RedButtonProps from './RedButtonProps';
import clsx from 'clsx';

const RedButton: React.FC<RedButtonProps> = (props) => {
    return (
        <button
            className={clsx("red-button",
                {
                    [`${props.className}`]: Boolean(props.className),
                    'btn-shadow' : props.showShadow
                }
            )}
            type={props.type}
            disabled={props.disabled}
            onClick={(e: any) => props.handleClick ? props.receiveClickEvent ? props.handleClick(e) : props.handleClick() : undefined} >
            {props.textButton}
        </button>
    );
};

export default RedButton;
