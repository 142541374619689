import React from 'react';
import GenericTableProps from './GenericTableProps';
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    LabelDisplayedRowsArgs,
} from '@material-ui/core';
import GenericTableHeader from '../generic-table-header/GenericTableHeader';
import GenericTableState from './GenericTableState';
import Utils from '../../../utils/Utils';
import Row from '../../../model/Table/Row';
import HeadCell from '../../../model/Table/TableHeader';

import './GenericTable.scss';
import {
    GENERIC_TABLE_DEFAULT_ROW_PER_PAGE,
    GENERIC_TABLE_PAGINATION_IS_DISABLED_ROW_PER_PAGE
} from '../../../config/_const';

class GenericTable<T extends Object> extends React.Component<GenericTableProps<T>, GenericTableState> {
    constructor(props: GenericTableProps<any>) {
        super(props);

        this.state = {
            enablePadding: false,
            teamsSelected: [],
            order: 'asc',
            orderBy: this.props.headerTable[0].id,
            headCells: [],
            page: this.detectDefaultPage(),
            rowsPerPage: this.props.paginationIsDisabled ? GENERIC_TABLE_PAGINATION_IS_DISABLED_ROW_PER_PAGE : GENERIC_TABLE_DEFAULT_ROW_PER_PAGE,
        };
    }

    componentDidUpdate = (oldProps: GenericTableProps<any>) => {
        if (this.props.defaultPage === oldProps.defaultPage) {
            return;
        }

        this.setState({
            page: this.detectDefaultPage(),
        });
    }

    detectDefaultPage = (): number => {
        return this.props.defaultPage || 0;
    }

    /**
     * Function who create cell
     * Take on parameters the data at display
     * @param data
    */
    generateCellForRow = (cell: any) => {
        const styleContainer: any = {
            width: cell.width ? cell.width : 'auto',
            background: cell.background,
            color: cell.textCellColor ? cell.textCellColor : 'black',
            border: 'none',
        };
        return <TableCell
            align="left"
            classes={{
                root: 'table-cell-hover',
            }}
            component="div"
            style={styleContainer}
            id={Utils.generateUniqueId()}
            key={Utils.generateUniqueId()}
            scope="row"
            onClick={() => cell.handleCellClicked ? cell.handleCellClicked() : null}
            padding="default">
            <div className="cell-container">
                {cell && typeof cell.data !== 'undefined' ? cell.data : null}
            </div>

        </TableCell>;
    }

    /**
     * Genenerate table by type table
     */
    generateTable = (row: Row<T>) => {
        const isItemSelected: boolean | undefined = this.isSelected(row.id);
        return (
            <TableRow
                // hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                style={{ background: row.color || '#353471', color: 'white' }}
                key={row.id}
                selected={isItemSelected} >
                {/* Generate cell of the line */}

                {this.props.headerTable.map((column: HeadCell<T>) => {
                    return (
                        row.cells[column.id] && this.generateCellForRow(row.cells[column.id])
                    );
                })}
            </TableRow>
        );
    }

    /** Function who sort the data
     *
    */
    handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof any) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property,
        });
    }

    /**
     * Function who enable or disable the padding of the table
     */
    updateBooleanPadding = () => this.setState({ enablePadding: !this.state.enablePadding });

    stableSort(array: Row<T>[], comparator: (a: any, b: any) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el: any) => el[0]);
    }

    descendingComparator(a: any, b: any, orderBy: keyof any) {
        const firstDataCompare: string | number = a.cells[orderBy].data;
        const secondDataCompare: string | number = b.cells[orderBy].data;

        if (a.cells[orderBy].originalDate && b.cells[orderBy].originalDate) {
            return (new Date(a.cells[orderBy].originalDate).valueOf() - new Date(b.cells[orderBy].originalDate).valueOf());
        }

        if (a.cells[orderBy].originalElo && b.cells[orderBy].originalElo) {
            if (a.cells[orderBy].originalElo > b.cells[orderBy].originalElo) {
                return 1;
            }
            return -1;

        }

        if (secondDataCompare < firstDataCompare) {
            return -1;
        }
        if (secondDataCompare > firstDataCompare) {
            return 1;
        }
        return 0;
    }

    getComparator<Key extends keyof any>(order: 'asc' | 'desc', orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === 'desc'
            ? (a, b) => this.descendingComparator(a, b, orderBy)
            : (a, b) => -this.descendingComparator(a, b, orderBy);
    }

    handleChangePage = (_event: any, newPage: number) => {
        if (newPage > this.state.page && this.props.loadMoreData) {
            this.props.loadMoreData(newPage);
        }

        this.setState({ page: newPage });
    }

    /**
     * Function who check if the line is teamsSelected
     * Take on parameters the name of line
     * @param name
     */
    isSelected = (name: string) => this.props.currentSelected ? this.props.currentSelected.indexOf(name) !== -1 : undefined;

    render = () => {

        return (
            <div className="generic-table">
                <TableContainer style={{ position: 'relative', height: this.props.tableHeight }}>
                    <Table
                        stickyHeader
                        aria-labelledby="tableTitle"
                        size={this.state.enablePadding ? 'small' : 'medium'}
                        aria-label="sticky table">
                        <GenericTableHeader
                            showSelectAll={this.props.enabledCheckbox}
                            numSelected={this.props.currentSelected ? this.props.currentSelected.length : 0}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            onSelectAllClick={this.props.selectedAllLines ? this.props.selectedAllLines : console.log}
                            onRequestSort={this.handleRequestSort}
                            rowCount={this.props.rows.length}
                            headCells={this.props.headerTable} />

                        <TableBody>
                            {this.stableSort(this.props.rows, this.getComparator(this.state.order, this.state.orderBy))
                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((row: Row<T>) => this.generateTable(row))}
                        </TableBody>
                        {this.props.children}
                    </Table>
                </TableContainer>
                {!this.props.paginationIsDisabled && (
                  <TablePagination
                    rowsPerPageOptions={[50]}
                    component="div"
                    count={-1}
                    style={{ color: 'gray' }}
                    labelDisplayedRows={(data: LabelDisplayedRowsArgs) => `${data.from} of ${data.to}`}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage} />
                )}
            </div>
        );
    }
}

export default (GenericTable);
