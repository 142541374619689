import i18n from '18n';
import { CreditCard } from '@material-ui/icons';
import Application from 'Application';
import PaymentCreditBreakDown from 'components/small/PaymentCreditBreakDown/PaymentCreditBreakDown';
import GreenButton from 'components/small/buttons/green-button/GreenButton';
import CreditCardWrapper from 'components/small/credit-card-wrapper/CreditCardWrapper';
import PaymentOptionCard from 'components/small/payment-option-card/PaymentOptionCard';
import { DEFAULT_ID_AS_STRING, MINIMUM_STRIPE_AMOUNT, ONE } from 'config/_const';
import { MarketEnum, PaymentMethodsEnum, TournamentLeagues } from 'config/_enums';
import withNextTournament from 'hoc/tournament/with-next-tournament/withNextTournament';
import withAccount from 'hoc/with-account/withAccount';
import withUserLeagueAttribution from 'hoc/with-user-league-attribution/withUserLeagueAttribution';
import IUseUserTournamentSubscription from 'hooks/tournament/use-user-tournament-subscription/IUseUserTournamentSubscription';
import useUserTournamentSubscription from 'hooks/tournament/use-user-tournament-subscription/useUserTournamentSubscription';
import useTournamentTicketCheckout from 'hooks/use-tournament-ticket-checkout/useTournamentTicketCheckout';
import ITeam from 'model/Team/ITeam';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { activeUserTeamSelector } from 'service/teams/selectors';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';
import { IPaymentOptions } from '../../../asset-transaction/confirm-buy-asset/IPaymentOptions';
import CheckoutModeEnum from '../../../stripe-elements/CheckoutModeEnum';
import TournamentTicketCheckoutHeader from '../TournamentTicketCheckoutHeader/TournamentTicketCheckoutHeader';
import ITournamentTicketCheckoutProps from './ITournamentTicketCheckoutProps';
import './style.scss';

const TournamentTicketCheckout: React.FC<ITournamentTicketCheckoutProps> = ({ accountViewModel, nextTournament, userLeagueAttribution }) => {
    const dispatch = useDispatch();
    const userTeam: ITeam | undefined = useSelector(activeUserTeamSelector);
    const defaultSelectedLeague: LeagueViewModel = React.useMemo<LeagueViewModel>(() => Application.getInstance().getLeagueFromName(TournamentLeagues.Legends), []);
    const { league, stripeTicketRef, setSelectedPaymentType, isDiscountElligible, selectedPaymentType, onBuyTournamentTicketError, onBuyTournamentTicketSuccess, arbitraryBuyTicket, handleLeagueChange, isBuyLoading, leagueEntryPrice, leagueEntryPriceWithoutDiscount } = useTournamentTicketCheckout({
        teamId: userTeam?._id ?? DEFAULT_ID_AS_STRING,
        defaultLeague: defaultSelectedLeague,
        tournament: nextTournament,
        defaultPaymentMethod: accountViewModel.hasEnoughtCredits(nextTournament.getLeaguePriceEntry(defaultSelectedLeague.Name)) ? PaymentMethodsEnum.CREDITS : PaymentMethodsEnum.STRIPE,
        userLeagueAttribution,
    });
    const [isCreditCardPaymentReady, setCreditCardPaymentReady] = React.useState<boolean>(true);
    const hasEnoughtCredits: boolean = React.useMemo(() => accountViewModel.hasEnoughtCredits(nextTournament.getLeaguePriceEntry(league.Name)), [accountViewModel, league.Name, nextTournament]);
    const { fetchUserTournamentSubscription, currentUserSubscription }: IUseUserTournamentSubscription = useUserTournamentSubscription({ tournamentId: nextTournament.Id });

    React.useEffect(() => {
        if (!currentUserSubscription && !isBuyLoading)
            dispatch(fetchUserTournamentSubscription());
    }, [currentUserSubscription, dispatch, fetchUserTournamentSubscription, isBuyLoading]);

    const isConfirmationButtonEnabled = React.useCallback(() => {
        if (leagueEntryPrice.isSmallerThan(ONE) && !hasEnoughtCredits)
            return false;
        const isPaymentTypeStripe: boolean = [PaymentMethodsEnum.STRIPE, PaymentMethodsEnum.STRIPE_NEW_CARD].includes(selectedPaymentType);
        return (isCreditCardPaymentReady || !isPaymentTypeStripe);
    }, [hasEnoughtCredits, isCreditCardPaymentReady, leagueEntryPrice, selectedPaymentType]);

    const paymentOptions: IPaymentOptions[] = React.useMemo(() => {
        const renderStripeSubComponent = (): JSX.Element => {
            const isNewCardForm = selectedPaymentType === PaymentMethodsEnum.STRIPE_NEW_CARD;

            return <CreditCardWrapper
                fiatAmount={leagueEntryPrice}
                mode={CheckoutModeEnum.BUY_TOURNAMENT_TICKET}
                hasStripeCustomerId={accountViewModel.StripeCustomerId?.safeIsNullOrEmpty() ?? false}
                trackEventOnPixel={true}
                showLoader={true}
                intent={{
                    fiatAmount: leagueEntryPrice,
                    subscription: {
                        team: userTeam?._id ?? DEFAULT_ID_AS_STRING,
                        tournament: nextTournament.Id,
                        league: league.Name,
                        subscriptionDate: new Date().toISOString(),
                    },
                    concernedMarket: MarketEnum.NONE,
                    displayCreditForm: false
                }}
                buyAssetSuccessCallback={(_transactionData, _isDone) => { return new Promise(() => { onBuyTournamentTicketSuccess() }) }}
                buyAssetInErrorCallback={(_transactionData, _isDone) => { return new Promise(() => { onBuyTournamentTicketError() }) }}
                confirmationMethodRef={stripeTicketRef}
                setCreditCardReady={setCreditCardPaymentReady}
                newCardForm={isNewCardForm} />
        };

        return [
            { id: 1, value: PaymentMethodsEnum.CREDITS, label: i18n.t("credits"), info: <PaymentCreditBreakDown creditsAmount={accountViewModel.creditsAsNumber} virtualCreditsAmount={accountViewModel.VirtualCreditsAsNumber} amountToBePaid={leagueEntryPrice} selectedPaymentType={selectedPaymentType} />, isDisabled: !hasEnoughtCredits, renderSubComponent: renderStripeSubComponent },
            { id: 2, value: PaymentMethodsEnum.STRIPE, label: i18n.t("Payment.card"), info: <CreditCard />, isDisabled: leagueEntryPrice.isSmallerThan(1) || false, renderSubComponent: renderStripeSubComponent },
            { id: 3, value: PaymentMethodsEnum.STRIPE_NEW_CARD, label: i18n.t(accountViewModel.isAuthenticated ? "Payment.newCard" : 'anonymous.payment-option.new-card'), info: <CreditCard />, isDisabled: leagueEntryPrice.isSmallerThan(ONE) || false, renderSubComponent: renderStripeSubComponent },
        ]
    }, [accountViewModel.creditsAsNumber, accountViewModel.VirtualCreditsAsNumber, accountViewModel.isAuthenticated, accountViewModel.StripeCustomerId, leagueEntryPrice, selectedPaymentType, hasEnoughtCredits, userTeam, nextTournament.Id, league.Name, stripeTicketRef, onBuyTournamentTicketSuccess, onBuyTournamentTicketError]);

    return (
        <div className='tournament-ticket-checkout'>
            <div className='header-container'>
                <TournamentTicketCheckoutHeader totalTokens={userLeagueAttribution.UserTotalTokens} leagueEntryPriceWithoutDiscount={leagueEntryPriceWithoutDiscount} isDiscountElligible={isDiscountElligible} leagueEntryPrice={leagueEntryPrice} prizePool={nextTournament.PrizePool} onLeagueChange={handleLeagueChange} league={league} />
            </div>

            <div className="confirm-payment-type">
                {paymentOptions.map((option) =>
                    <>
                        <PaymentOptionCard
                            isSelected={selectedPaymentType === option.value}
                            handleClick={setSelectedPaymentType}
                            label={option.label}
                            value={option.value}
                            info={option.info}
                            isDisabled={option.isDisabled}
                            key={option.id}
                            renderSubComponent={option.renderSubComponent}
                        />
                        {(leagueEntryPrice.isSmallerThan(ONE) && option.value === PaymentMethodsEnum.STRIPE_NEW_CARD) &&
                            <div className='warning-msg'>
                                {i18n.t('payment.error.credit-card.tooSmallAmount', { amount: MINIMUM_STRIPE_AMOUNT.toCurrency() })}
                            </div>
                        }
                    </>
                )}

            </div>

            <div className='buy-ticket-button'>
                <GreenButton
                    className='confirmation-boost-btn'
                    handleClick={arbitraryBuyTicket}
                    textButton={i18n.t(Boolean(currentUserSubscription) ? 'wallet.tournament.next.already-subscribe' : 'tournament.paid.buy')}
                    receiveClickEvent={false}
                    type={"button"}
                    disabled={!isConfirmationButtonEnabled() || isBuyLoading || !userTeam || Boolean(currentUserSubscription)} />
            </div>
        </div>
    );
};

export default compose<React.FC>(
    withAccount,
    withNextTournament,
    withUserLeagueAttribution,
)(TournamentTicketCheckout);
