import React from 'react';

function Podium(props: React.SVGProps<SVGSVGElement>) {
    const DEFAULT_SIZE = '20px';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={DEFAULT_SIZE} height={DEFAULT_SIZE} viewBox="0 0 63 63" {...props}>
            <defs>
                <linearGradient id="linear-gradient-podium" x1="0.066" y1="-0.631" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#fcc73c" />
                    <stop offset="1" stopColor="#f09e36" />
                </linearGradient>
            </defs>
            <g id="icon_results" data-name="icon results" transform="translate(-178 -530)">
                <rect id="Rectangle_2082" data-name="Rectangle 2082" width="63" height="63" transform="translate(178 530)" fill="#fff" opacity="0" />
                <g id="ranking-star-duotone" transform="translate(181.86 539.895)">
                    <path id="Tracé_3771" data-name="Tracé 3771" d="M19.348,258.764A2.761,2.761,0,0,1,22.112,256H33.169a2.761,2.761,0,0,1,2.764,2.764v16.584a2.761,2.761,0,0,1-2.764,2.764H22.112a2.761,2.761,0,0,1-2.764-2.764ZM0,264.292a2.763,2.763,0,0,1,2.764-2.764H13.82a2.761,2.761,0,0,1,2.764,2.764v11.056a2.761,2.761,0,0,1-2.764,2.764H2.764A2.763,2.763,0,0,1,0,275.348Zm52.517,2.764a2.761,2.761,0,0,1,2.764,2.764v5.528a2.761,2.761,0,0,1-2.764,2.764H41.461a2.761,2.761,0,0,1-2.764-2.764V269.82a2.761,2.761,0,0,1,2.764-2.764Z" transform="translate(0 -235.256)" fill="url(#linear-gradient-podium)" />
                    <g id="Groupe_5112" data-name="Groupe 5112" transform="translate(18.227)">
                        <g id="trophy-duotone_1_" data-name="trophy-duotone (1)" transform="translate(0)">
                            <path id="Tracé_3768" data-name="Tracé 3768" d="M134.8,12.126a1.48,1.48,0,0,0-.52,1.1,1.4,1.4,0,0,0,1.419,1.418h.152a1.569,1.569,0,0,1,1.569,1.569.518.518,0,0,1-.494.523h-7.352a.5.5,0,0,1-.494-.523,1.569,1.569,0,0,1,1.569-1.569h.152a1.414,1.414,0,0,0,1.417-1.417,1.479,1.479,0,0,0-.52-1.1C128,8.881,128,3.138,128,3.138V1.046A1.051,1.051,0,0,1,129.051,0h8.358a1.051,1.051,0,0,1,1.05,1.046V3.138C138.43,3.138,138.43,8.881,134.8,12.126Z" transform="translate(-123.816)" fill="url(#linear-gradient-podium)" />
                            <path id="Tracé_3769" data-name="Tracé 3769" d="M6.332,72.266c-.127-.19-.245-.379-.356-.572a7.415,7.415,0,0,1-3.445-2.6,5.728,5.728,0,0,1-.949-3.526H4.21c-.022-.33-.025-.523-.025-.523l0-1.046H.892a.785.785,0,0,0-.763.6,7.216,7.216,0,0,0,1.084,5.345,10.04,10.04,0,0,0,6.128,3.837,2.129,2.129,0,0,1,.507.253A9.373,9.373,0,0,1,6.332,72.266ZM18.7,64.6a.786.786,0,0,0-.765-.6H14.641l0,1.046s0,.194-.025.523h2.628a5.716,5.716,0,0,1-.949,3.523,7.41,7.41,0,0,1-3.445,2.6q-.167.29-.355.572a9.311,9.311,0,0,1-1.518,1.77,2.136,2.136,0,0,1,.507-.253,10.021,10.021,0,0,0,6.128-3.837A7.211,7.211,0,0,0,18.7,64.6Z" transform="translate(-0.001 -61.908)" opacity="0.4" fill="url(#linear-gradient-podium)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
}

export default Podium;
