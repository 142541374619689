import { ISvgIconProps } from 'interfaces/SvgIcon.interface';
import * as React from 'react';


const UserGroup: React.FC<ISvgIconProps> = ({ ...props }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" {...props} viewBox="0 0 20 20">
            <g id="icon" transform="translate(0 -728.5)">
                <g id="Rectangle_2014" data-name="Rectangle 2014" transform="translate(0 728.5)" fill="#8187d0" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="20" height="20" stroke="none" />
                    <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
                </g>
                <g id="users-duotone" transform="translate(0.625 731)">
                    <path id="Tracé_3806" data-name="Tracé 3806" d="M133.622,118.094a3.047,3.047,0,1,0-3.021-3.047A3.025,3.025,0,0,0,133.622,118.094Zm1.465.938h-2.924A4.04,4.04,0,0,0,128,122.937a.807.807,0,0,0,.832.782h9.586a.807.807,0,0,0,.832-.782A4.04,4.04,0,0,0,135.087,119.031Z" transform="translate(-124.25 -108.719)" fill="#8187d0" />
                    <path id="Tracé_3807" data-name="Tracé 3807" d="M3.75,4.688A2.344,2.344,0,1,0,1.406,2.344,2.344,2.344,0,0,0,3.75,4.688Zm11.25,0a2.344,2.344,0,1,0-2.344-2.344A2.344,2.344,0,0,0,15,4.688Zm1.169.938H14.357a2.443,2.443,0,0,0-1.051.241,4.014,4.014,0,0,1,.047.462,3.95,3.95,0,0,1-.971,2.578h5.851a.534.534,0,0,0,.517-.548A2.66,2.66,0,0,0,16.169,5.625ZM5.435,5.862a2.117,2.117,0,0,0-1.043-.237H2.58A2.661,2.661,0,0,0,0,8.358a.533.533,0,0,0,.516.548H6.361a3.95,3.95,0,0,1-.971-2.578A3.742,3.742,0,0,1,5.435,5.862Z" fill="#8187d0" opacity="0.4" />
                </g>
            </g>
        </svg>
    )
}

export default UserGroup;