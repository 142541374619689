import React from 'react';
import ReactSlidingPane from 'react-sliding-pane';
import ICommonSlidingPaneProps from './ICommonSlidingPaneProps';
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./common-sliding-pane.scss"

const CommonSlidingPane: React.FC<ICommonSlidingPaneProps> = (props) => {
    return (
        <ReactSlidingPane
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            className={props.className}
            overlayClassName={props.overlayClassName}
            hideHeader={props.hideHeader}
            title={props.title}
            subtitle={props.subtitle}
        >
            {props.children}
        </ReactSlidingPane>
    )
}

export default CommonSlidingPane;