import { ASSETS_CATEGORIES } from 'config/_const';
import { AssetCardLevel } from 'config/_enums';
import assetsBgImagesPath from 'data/AssetsBgImages/assetsBgImages';
import { ICategoryTypeName } from 'interfaces/IAssetBgImages';
import { deburr } from 'lodash';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import { IAssetAvatar } from 'model/AssetModel/AssetInterface';
import Utils from 'utils/Utils';

interface IAvatarViewModelParam {
    assetCategories: AssetCategoryInterface[];
    asset: IAssetAvatar;
}
export default class AvatarViewModel {
    private assetCategories: AssetCategoryInterface[];
    private asset: IAssetAvatar;
    private currentAssetCategoryName?: ICategoryTypeName;
    private colors: Record<AssetCardLevel, string> = {
        [AssetCardLevel.Rookie]: 'transparent',
        [AssetCardLevel.Contender]: 'linear-gradient(180deg, #BE8635, #765019, #BF985E, #BF985E, #765019, #8D5E1B)',
        [AssetCardLevel.Champion]: 'linear-gradient(180deg, #B9B9B9, #6A6A6A, #D2D2D2, #C4C4C4, #6A6A6A, #959595)',
        [AssetCardLevel.Legend]: 'linear-gradient(180deg, #FFD667, #CB913E, #FFD667, #CB913E, #FFD667)'
    };

    constructor({ assetCategories, asset }: IAvatarViewModelParam) {
        this.asset = asset;
        this.assetCategories = assetCategories;
        this.currentAssetCategoryName = this.computeAssetCategoryName();
    }

    public get BackgroundImage(): string {
        if (!this.currentAssetCategoryName || !assetsBgImagesPath[this.currentAssetCategoryName])
            return assetsBgImagesPath.cinema[AssetCardLevel.Rookie];
        if (typeof this.asset.cardLevel === 'undefined')
            return assetsBgImagesPath[this.currentAssetCategoryName][AssetCardLevel.Rookie];
        return assetsBgImagesPath[this.currentAssetCategoryName][this.asset.cardLevel] ?? assetsBgImagesPath.cinema[AssetCardLevel.Rookie];
    }

    private computeAssetCategoryName() {
        const mainCategory = this.asset.mainCategoryId;
        const [assetCategory] = Utils.getAssetCategory(this.assetCategories, { ...this.asset, mainCategory: mainCategory });
        if (!assetCategory) return;
        const categoryName: string = assetCategory.name.toLowerCase();
        const categoryNameWithoutAccent: ICategoryTypeName = deburr(categoryName,) as ICategoryTypeName;
        return categoryNameWithoutAccent;
    }

    public get AvatarImage() {
        return this.asset.avatarPath;
    }

    public get Color(): string {
        if (this.asset.mainCategoryId === ASSETS_CATEGORIES['Fun cards'])
            return this.colors[AssetCardLevel.Rookie];
        return this.colors[this.asset.cardLevel] ?? this.colors[AssetCardLevel.Rookie];
    }
}
