import * as React from 'react';

function Home(props: React.SVGProps<SVGSVGElement>) {
    const DEFAULT_SIZE = '24px';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={DEFAULT_SIZE} height={DEFAULT_SIZE} viewBox="0 0 24 24" {...props}>
            <g id="icon_home" transform="translate(-1298 -109)">
                <path id="Tracé_409" data-name="Tracé 409" d="M0,0H24V24H0Z" transform="translate(1298 109)" fill="#15182B" opacity="0" />
                <path id="Soustraction_21" data-name="Soustraction 21" d="M-1285-90h-10a3,3,0,0,1-3-3v-13a3,3,0,0,1,3-3h10a3,3,0,0,1,3,3v13A3,3,0,0,1-1285-90Zm-2.018-5.771a.069.069,0,0,1,.018,0,.074.074,0,0,0,.019,0h.257a.646.646,0,0,0,.645-.644v-.262a1.228,1.228,0,0,0,.008-.131l-.011-2.581h.516a.524.524,0,0,0,.516-.518.438.438,0,0,0-.177-.387l-4.117-3.62a.545.545,0,0,0-.34-.112.465.465,0,0,0-.355.129l-4.135,3.6a.524.524,0,0,0-.162.387.513.513,0,0,0,.518.518h.517v1.122c0,.014,0,.031,0,.045v1.806a.646.646,0,0,0,.645.644h.258l.038,0h.044l.049,0h.9a.646.646,0,0,0,.645-.644v-1.419a.517.517,0,0,1,.516-.517h1.032a.517.517,0,0,1,.516.517v1.419a.646.646,0,0,0,.645.644h.911a.112.112,0,0,0,.024,0,.114.114,0,0,1,.024,0,.042.042,0,0,1,.021.005A.025.025,0,0,1-1287.018-95.771Z" transform="translate(2596.833 219.021)" fill="#15182B" />
                <path id="Soustraction_19" data-name="Soustraction 19" d="M12.747,20.918a3.014,3.014,0,0,1-.677-.078l-3.816-.881H9.335a5.006,5.006,0,0,0,5-5v-13c0-.112,0-.226-.011-.338l2.021.707a3,3,0,0,1,2.248,3.6L15.667,18.592A2.985,2.985,0,0,1,12.747,20.918ZM9.335,17.96H2.606a68.262,68.262,0,0,1,7.634-4.012l0-.014C10.1,12.665,8.888,1.5,9.968.293a.28.28,0,0,1,.21-.107.274.274,0,0,1,.062.007l1.883.658a2.986,2.986,0,0,1,.211,1.107v13A3,3,0,0,1,9.335,17.96Z" transform="translate(1302.498 111.061)" fill="#15182B" />
            </g>
        </svg>

    );
}

export default Home;
