import React from 'react';
import './InstantLiquidityChip.scss';
import instantBuyFlat from 'assets/images/Instant-Buy/InstantBuy-Flat.png'
import instantBuyRound from 'assets/images/Instant-Buy/InstantBuy-round.png'
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import clsx from 'clsx';

interface IInstantLiquidityChipProps extends WithTranslation {
  isListView: boolean;
  isOnlyLiquidityPool: boolean;
}

const InstantLiquidityChip: React.FC<IInstantLiquidityChipProps> = ({ isListView, isOnlyLiquidityPool, t: translate }) => {

  return (
    <div className="instant-liquidity-chip">

      {isListView
        ?
        <>
          <div className='instant-liquidity__img'>
            <img src={instantBuyFlat} alt='instant-buy' />
          </div>
          <p className={clsx("instant-liquidity__title", {
            'launch-mode-lp': isOnlyLiquidityPool
          })}>
            {translate(isOnlyLiquidityPool ? 'liquidity.only.instantBuySell' : 'liquidityEnabled.instantBuySell')}
            <Tooltip
              enterTouchDelay={100}
              title={
                <p style={{ fontSize: '13px' }} className='no-margin'>
                  {translate("liquidityEnabled.instantBuySell.info")}
                </p>
              }
            >
              <Info className='info-icon' />
            </Tooltip>
          </p>
          <div className='instant-liquidity__img'>
            <img src={instantBuyFlat} alt='instant-buy' />
          </div>
        </>
        :
        <div className='instant-liquidity__gridView'>
          <img src={instantBuyRound} alt='instant-buy' />
        </div>
      }

    </div>
  );
}


export default React.memo(withTranslation()(InstantLiquidityChip));