export const PAYMENT_LOADING = "PAYMENT_LOADING";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";

export const PAYMENT_ASSET_STATUS_LOADING = "PAYMENT_ASSET_STATUS_LOADING";
export const PAYMENT_ASSET_STATUS_ERROR = "PAYMENT_ASSET_STATUS_ERROR";
export const PAYMENT_ASSET_STATUS_SUCCESS = "PAYMENT_ASSET_STATUS_SUCCESS";
export const PAYMENT_TRANSACTION_STATUS_SUCCESS = "PAYMENT_TRANSACTION_STATUS_SUCCESS";
export const PAYMENT_TRANSACTION_STATUS_ERROR = "PAYMENT_TRANSACTION_STATUS_ERROR";
export const PAYMENT_TRANSACTION_LOADING = "PAYMENT_TRANSACTION_LOADING";

export const PAYMENT_INTENT_LOADING = "PAYMENT_INTENT_LOADING";
export const PAYMENT_INTENT_ERROR = "PAYMENT_INTENT_ERROR";
export const PAYMENT_INTENT_SUCCESS = "PAYMENT_INTENT_SUCCESS";

export const DROP_PAYMENT_DATA = "DROP_PAYMENT_DATA";