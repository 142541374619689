import React, { useEffect, useMemo } from 'react';
import "./AssetSellInitiator.scss";
import useAssetsCategories from 'hooks/asset-categories/useAssetCategories';
import AvatarViewModel from 'view-model/AvatarViewModel/AvatarViewModel';
import { withTranslation } from 'react-i18next';
import IAssetSellInitiatorProps from './IAssetInitiatorProps';
import useAssetTransactionSlides from 'hooks/use-asset-transaction-slideup/useAssetTransactionSlides';
import { AssetTransactionType } from 'config/_enums';
import ArrowRight from 'assets/images/arrow-right.png'
import CrispService from 'service/crisp/CrispService';
import InstantBuyFlat from 'assets/images/Instant-Buy/InstantBuy-Flat.png'
import { LAUNCH_MODE, ORDER_TYPE } from 'config/_const';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import useAssetPredictPrice from 'hooks/use-asset-predict-price/useAssetPredictPrice';
import { useSelector } from 'react-redux';
import CircularLoader from 'components/small/CircularLoader';
import useAssetCurrentMarketInfo from 'hooks/use-asset-current-market-info/useAssetCurrentMarketInfo';
import SellAssetViewModel from 'view-model/SellAssetViewModel/SellAssetViewModel';
import useWallet from 'hooks/wallet/use-wallet/useWallet';
import IUseWallet from 'hooks/wallet/use-wallet/IUseWallet';
import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import SecondaryMarketPredictPriceWrapperState from 'model/Resale/SecondaryMarketPredictPriceWrapperState';
import { secondaryMarketPredictPriceSelector } from 'service/secondary-market/selector';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import { selectorAccountViewModel } from 'service/auth/selectors';
import Application from 'Application';
import useAssetSellInitiator from 'hooks/asset-sell-initiator/useAssetSellInitiator';
import WhatsAppService from 'service/whatsapp/WhatsAppService';

const AssetSellInitiator = ({ t: translate, asset, closeDialog }: IAssetSellInitiatorProps) => {
    const assetViewModel = new AssetViewModel(asset);
    const { assetsCategories } = useAssetsCategories();
    const assetBackgroundViewModel: AvatarViewModel = asset && new AvatarViewModel({ assetCategories: assetsCategories, asset: { avatarPath: assetViewModel?.AvatarPath, mainCategoryId: assetViewModel?.MainCategoryId, cardLevel: assetViewModel.CardLevel } });
    const { openAssetTransaction } = useAssetTransactionSlides();
    const { walletViewModel }: IUseWallet = useWallet({ considerTeamShares: false });
    const openTransactionSlide = (orderType: ORDER_TYPE) => openAssetTransaction(assetViewModel.AssetId, AssetTransactionType.SELL, true, false, orderType);
    const walletAsset: AssetWalletInterface | undefined = useMemo(() => walletViewModel.getWalletAssetById(asset._id), [walletViewModel, asset]);
    const predictPriceData: SecondaryMarketPredictPriceWrapperState = useSelector(secondaryMarketPredictPriceSelector);
    const accountViewModel: AccountViewModel = useSelector(selectorAccountViewModel);
    const currency: string = accountViewModel.Currency ?? Application.getInstance().currency;
    useAssetPredictPrice({ asset: asset, transactionType: AssetTransactionType.SELL });
    const { assetCurrentMarketInfo, isAssetCurrentMarketInfoEmpty } = useAssetCurrentMarketInfo({ assetId: asset._id });
    useAssetSellInitiator(asset, 1);

    useEffect(() => {
        WhatsAppService.hide();
        CrispService.hide();
        return () => {
            CrispService.show();
            WhatsAppService.show();
        };
    }, []);

    if (isAssetCurrentMarketInfoEmpty)
        return <CircularLoader loaderMessage='' />;

    const sellViewModel = new SellAssetViewModel({
        asset,
        assetWallet: walletAsset,
        numberOfToken: 1,
        userAmount: 1,
        predictPriceData,
        assetCurrentInformation: assetCurrentMarketInfo,
        userId: accountViewModel.Id,
        currency,
        orderType: ORDER_TYPE.MARKET
    });

    const renderOrderbookEnabledContainer = () => {
        return <div className='asset-market-sell' onClick={() => {
            closeDialog();
            openTransactionSlide(ORDER_TYPE.MARKET)
        }}>
            <div className='asset-market-sell-info-wrapper'>
                <div className="avatar" style={{ background: assetBackgroundViewModel.Color }}>
                    <img className="avatar-bg" src={assetBackgroundViewModel.BackgroundImage} alt="bg" loading="lazy" />
                    <img className='avatar-icon' src={assetViewModel.AvatarPath} alt="avatar" loading="lazy" />
                    <div className="overlay-filter" />
                </div>
                <div className='asset-market-sell-info'>
                    <span>{translate("asset.sell.recommended")}</span>
                    <div className='title'>
                        {translate("asset.sell.market")}
                    </div>
                    <p className='info'>
                        {translate("asset.sell.market.detail")}
                    </p>

                    <hr />

                    <div className='price-wrapper'>
                        <div className='title'>
                            {translate("asset.sell.market.price")}
                        </div>

                        <div className='price'>
                            <span className='current-value'> {sellViewModel.EffectivePriceAsCurrency}</span>
                            <span className='price-deviation'>&plusmn;{sellViewModel.PriceDeviationInPercent}</span>
                        </div>
                    </div>
                </div>

            </div>

            <div className='asset-market-sell-button'>
                <img className='button-icon' src={ArrowRight} alt='icon' />
            </div>
        </div >
    }

    const renderOrderbookDisabledContainer = () => {
        return <div className="asset-market-sell-disable">
            <div className='title'>
                {translate("asset.sell.market.disabled.title")}
            </div>
        </div>
    }

    const renderLiquidityPoolEnabledContainer = () => {
        return <div className='asset-liquidity-pool-sell' onClick={() => {
            closeDialog();
            openTransactionSlide(ORDER_TYPE.LIQUIDITY_POOL)
        }}>
            <div className='asset-liquidity-pool-info'>
                <div className='title'>
                    {translate("asset.sell.liquidity.pool")}
                    <span className='instant-sell-icon'>
                        <img src={InstantBuyFlat} alt='instant-sell' />
                    </span>
                </div>
                <p className='info'>
                    {translate("asset.sell.liquidity.pool.detail")}
                </p>

                <hr />

                <div className='price-wrapper'>
                    <div className='title'>
                        {translate("asset.sell.liquidity.pool.price")}
                    </div>
                    <div className='price'>
                        {sellViewModel.LiquidityMarketValue?.toCurrency(2) ?? Number(0).toCurrency(2, undefined, 2)}
                    </div>
                </div>
            </div>

            <div className='asset-liquidity-pool-sell-button'>
                <img className='button-icon' src={ArrowRight} alt='arrow-right' />
            </div>
        </div>
    }

    const renderLiquidityPoolDisabledContainer = () => {
        return <div className="asset-liquidity-sell-disable">
            <div className='title'>
                {translate("asset.sell.liquidity.pool.disabled.title")}
            </div>
            {!assetViewModel.LiquidityPoolEnabled &&
                <div className='info'>
                    <p>
                        {translate("asset.sell.liquidity.pool.disabled.paragraph.one")}
                        <span className='instant-sell-icon'>
                            <img src={InstantBuyFlat} alt='instant-sell' />
                        </span>
                        <br />
                        {translate("asset.sell.liquidity.pool.disabled.paragraph.two")}
                    </p>
                </div>
            }
        </div>
    }

    return (
        <div className='asset-sell-wrapper' onClick={(event) => event.stopPropagation()}>

            {assetViewModel.LaunchMode === LAUNCH_MODE.DEFAULT ?
                renderOrderbookEnabledContainer() : renderOrderbookDisabledContainer()
            }

            {assetViewModel.LiquidityPoolEnabled && !accountViewModel.IsOnboardingRunning ?
                renderLiquidityPoolEnabledContainer() : renderLiquidityPoolDisabledContainer()
            }
        </div >
    )
}

export default withTranslation()(AssetSellInitiator);