import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import Timer from 'components/medium/timer/Timer';
import BenzemaTimerProps from './BenzemaTimerProps';
import { batch } from 'react-redux';
import { ASSET_BUY_STAGE } from 'config/_const';
import './style.scss';
import clsx from 'clsx';

const BenzemaTimer: React.FC<BenzemaTimerProps> = ({ setPreSaleIsOpen, setPrivateSellIsOpen, assetVM, t: translate, setIsSaleStartingInLessThan3Min }) => {
    const titles: Record<string, string> = useMemo(() => {
        return {
            NONE: "presale.timer.end",
            WHITELIST_USER_BUY: assetVM.isEarlyBirdEnable() ? "early-bird.timer.start" : "presale.timer.start",
            PRE_BUY_PACK: "presale.timer.not.start",
        };
    }, [assetVM]);
    const [date, setDate] = React.useState<Date>(assetVM.NextStageDate);
    const [title, setTitle] = React.useState<string>(titles[assetVM.BuyStage]);

    const onTimerComplete = () => {
        setTimeout(() => {
            batch(() => {
                setDate(assetVM.NextStageDate);
                setTitle(titles[assetVM.BuyStage]);
            })
            if (assetVM.BuyStage === ASSET_BUY_STAGE.PRE_BUY_PACK) {
                setPreSaleIsOpen(true);
                return;
            }
            if (assetVM.BuyStage === ASSET_BUY_STAGE.NONE) {
                setPreSaleIsOpen(false);
                setIsSaleStartingInLessThan3Min && setIsSaleStartingInLessThan3Min(true);
                return;
            }
            setPrivateSellIsOpen(true);
        }, 1500)
    }

    return (
        <div className="benzema-timer">
            <p className={clsx("pre-sales-title", { blue: assetVM.isEarlyBirdEnable() })}>{translate(title)}</p>
            <Timer key={`benzema-${assetVM.BuyStage.toLowerCase()}`} date={date.toISOString()} onTimeEnd={onTimerComplete} />
        </div>
    )
}

export default withTranslation()(React.memo(BenzemaTimer));
