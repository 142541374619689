import i18n from '18n';
import Application from 'Application';
import Credits from 'components/icons/Credits';
import Gifts from 'components/icons/Gifts';
import Locked from 'components/icons/Locked';
import Shares from 'components/icons/Shares';
import { COUNTRY, DEFAULT_AVATAR, DEFAULT_ID_AS_STRING, LOCAL_STORAGE_KNOWN_KEYS, PERSONAL_TOURNAMENT_LABEL_UNSUBSCRIBED, PROD_ORIGIN, REQUIRED_SALES_AMOUNT_FOR_KYC, TRANSACTION_SOURCE, TRENDEX_ROLES, VALUE_FORMAT_TYPE, VERIFICATION_STATUS, VIP_CAN_SELL_OWN_TOKENS, WHITELIST_STATUS, ZERO } from 'config/_const';
import { OnboardingStep } from 'config/_enums';
import dayjs from 'dayjs';
import IUserAbTesting from 'model/AbTesting/User/IUserAbTesting';
import AssetInterface from 'model/AssetModel/AssetInterface';
import ITournament from 'model/Tournament/ITournament';
import IReferral from 'model/User/IReferral';
import SocialInterface from 'model/User/SocialInterface';
import TransactionInterface from 'model/User/TransactionInterface';
import UserInterface, { IUserWhitelist } from 'model/User/UserInterface';
import ValueHistoryInterface from 'model/User/ValueHistoryInterface';
import { getReferral } from 'service/affiliate/getters';
import PersonaTournamentStateEnum from 'types/PersonalTournamentStateEnum';
import PersonalTournamentSubscriptionStatusEnum from 'types/PersonalTournamentSubscriptionStatusEnum';
import WalletViewModel from 'view-model/WalletViewModel/WalletViewModel';
import IAccountViewModel from './IAccountViewModel';
const isEuMember = require('is-eu-member').isEuMember;

export default class AccountViewModel {
    private _id: string;
    private _firstName: string;
    private _lastName: string;
    private _avatar: string;
    private _isAuthenticated: boolean;
    private _isEmailValidated: boolean;
    private _identityConfirmStatus: VERIFICATION_STATUS;
    private _loadingUploadPicture?: boolean;
    private _loadingUploadIdentity?: boolean;
    private _emailVerification?: string | number;
    private _credits: number;
    private _virtualCredits: number;
    private _subscribedAssetsIds: string[];
    private _pseudo: string;
    private _stripeCustomerId: string;
    private _userCGUaccepted: boolean;
    private totalCredits: number;
    private cookieConsentAccepted: boolean | undefined;
    private frozenCredits: number;
    private userType: string;
    private _account: UserInterface;
    private _referralUserList: UserInterface[] | undefined;
    private totalEarningHistory: ValueHistoryInterface[];
    private last24HEarningDifference: number;
    private _ownReferralId: string;
    private _language: string;
    private _abTesting: IUserAbTesting;
    private referral: IReferral;
    private _currency: string;
    private country: string;
    private email: string;
    private LOWEST_DISPLAY_VALUE: number;
    private whitelist?: IUserWhitelist;
    private signUpDate: string;
    private totalSales: number;
    private personalTournament?: ITournament;
    private onboardingStep: OnboardingStep;
    private disabled: boolean;
    private isAdminToVip: boolean;
    private ownedAssets: AssetInterface[];
    private subType: string;
    private google: SocialInterface;
    private apple: SocialInterface;

    constructor(user: IAccountViewModel) {
        this._isAuthenticated = user.isAuthenticated;
        this._loadingUploadPicture = user.loadingUploadPicture;
        this._loadingUploadIdentity = user.loadingUploadIdentity;
        this._emailVerification = user.emailVerification;
        this._firstName = user.account.firstName;
        this._avatar = user.account.avatar;
        this._credits = user.account.credits ?? 0;
        this._virtualCredits = user.account.virtualCredits ?? 0;
        this.totalCredits = this._credits + this._virtualCredits;
        this._id = user.account._id;
        this._isEmailValidated = user.account.validatedEmail;
        this._identityConfirmStatus = user.account.isConfirmed as VERIFICATION_STATUS;
        this._subscribedAssetsIds = user.account.subscribedAssetsIds;
        this._pseudo = user.account.pseudo;
        this._stripeCustomerId = user.account.stripeCustomerId;
        this._userCGUaccepted = user.account.userCGUaccepted;
        this._lastName = user.account.lastName;
        this.cookieConsentAccepted = user.account.cookieConsentAccepted;
        this.frozenCredits = user.account.frozenCredits ?? 0;
        this.userType = user.account.type ?? TRENDEX_ROLES.USER;
        this._account = user.account;
        this._referralUserList = user.account.referral?.referrals ?? [];
        this.totalEarningHistory = user.account.totalEarningsHistory ?? [];
        this.last24HEarningDifference = Array.isArray(this.totalEarningHistory) ? this.compute24HEarningDifference() : 0;
        this._ownReferralId = user.account.referral?.ownReferralId ?? "";
        this._language = user.account.language;
        this._abTesting = user.account.abTesting;
        this.referral = user.account.referral;
        this._currency = user.account.currency;
        this.country = user.account.country || COUNTRY.FRENCH;
        this.email = user.account.email;
        this.LOWEST_DISPLAY_VALUE = 0.01;
        this.whitelist = user.account.whitelist;
        this.signUpDate = user.account.createdAt;
        this.totalSales = user.account.totalSales;
        this.personalTournament = user.account.personalTournament;
        this.onboardingStep = user.account.onboardingStep !== OnboardingStep.NO_ONBOARDING ? user.account.onboardingStep : OnboardingStep.FINISH;
        this.disabled = user.account.disabled;
        this.isAdminToVip = user.isAdminToVip || false;
        this.ownedAssets = user.account.ownedAssets || [];
        this.subType = user.account.subType || '';
        this.google = user.account.google;
        this.apple = user.account.apple;
    }

    public IsUserWhiteListed(): boolean {
        return this.IsUserWhiteListStatusConfirmed || this.IsUserWhiteListStatusIntent;
    }

    public get IsUserWhiteListStatusIntent(): boolean {
        return this.whitelist?.status === WHITELIST_STATUS.INTENT;
    }
    public get IsUserWhiteListStatusConfirmed(): boolean {
        return this.whitelist?.status === WHITELIST_STATUS.CONFIRMED;
    }
    public get IsUserWhiteListStatusNone(): boolean {
        return this.whitelist?.status === WHITELIST_STATUS.NONE;
    }

    public get PaymentCGUAccepted(): boolean {
        return this._userCGUaccepted;
    }

    public get StripeCustomerId(): string {
        return this._stripeCustomerId ?? '';
    }

    public get firstName(): string {
        return this._firstName;
    }

    public get pseudo(): string {
        return this._pseudo;
    }

    public set firstName(firstName: string) {
        this._firstName = firstName;
    }

    public get LastName(): string {
        return this._lastName;
    }

    public get avatar(): string {
        return this._avatar ?? DEFAULT_AVATAR;
    }

    public get isThereUserAvatar(): boolean {
        return this._avatar ? true : false;
    }

    public set avatar(value: string) {
        this._avatar = value;
    }

    public get creditsAsNumber(): number {
        return this._credits ?? 0;
    }

    public get Credits() {
        return this._credits.toFixed(2);
    }

    public get VirtualCredits() {
        return this._virtualCredits.toFixed(2);
    }

    public get VirtualCreditsAsNumber() {
        return this._virtualCredits;
    }

    public get TotalCredits() {
        return this.totalCredits.toFixed(2);
    }

    public get TotalCreditsAsNumber() {
        return this.totalCredits;
    }

    public set credits(credits: number) {
        this._credits = credits;
    }

    public get Id(): string {
        return this._id ?? DEFAULT_ID_AS_STRING;
    }

    public get isAuthenticated(): boolean {
        return this._isAuthenticated;
    }
    public get isEmailValidated(): boolean {
        return this._isEmailValidated;
    }

    public get identityConfirmStatus(): VERIFICATION_STATUS {
        if (!this._identityConfirmStatus && localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.FUNNEL_AI_PRISE_DONE))
            return VERIFICATION_STATUS.PENDING;
        return this._identityConfirmStatus;
    }

    public get isIdentityConfirmed(): boolean {
        return (this.identityConfirmStatus === VERIFICATION_STATUS.CONFIRMED);
    }
    public get isIdentityPending(): boolean {
        return (this.identityConfirmStatus === VERIFICATION_STATUS.PENDING);
    }
    public get isIdentityRefused(): boolean {
        return (this.identityConfirmStatus === VERIFICATION_STATUS.REFUSED);
    }

    public get loadingUploadPicture(): boolean | undefined {
        return this._loadingUploadPicture;
    }

    public get loadingUploadIdentity(): boolean | undefined {
        return this._loadingUploadIdentity;
    }

    public get emailVerification(): string | number | undefined {
        return this._emailVerification;
    }

    public get subscribedAssetsIds(): string[] {
        return this._subscribedAssetsIds ?? [];
    }

    public set subscribedAssetsIds(value: string[]) {
        this._subscribedAssetsIds = value;
    }

    public get CookieConsentAccepted(): boolean | undefined {
        return this.cookieConsentAccepted;
    }

    public get FrozenCredits(): number {
        return this.frozenCredits;
    }
    public get UserType(): string {
        return this.userType;
    }

    public get IsUserAdmin(): boolean {
        return this.userType === TRENDEX_ROLES.ADMIN;
    }

    public get IsUserVip(): boolean {
        return this.UserType === TRENDEX_ROLES.VIP;
    }

    public get TotalEarningHistory(): ValueHistoryInterface[] {
        return this.totalEarningHistory;
    }

    public get Country(): string {
        return this.country;
    }

    public get account() {
        return this._account;
    }

    public get transactions(): TransactionInterface[] {
        return this._account.transactions ?? [];
    }

    public get referralUserList(): UserInterface[] | undefined {
        return this._referralUserList;
    }

    public get earnedReferralInLast30Days(): number {
        const { earnedReferralLast30days } = getReferral(this.transactions.filter((transaction: TransactionInterface) => transaction && transaction.source === TRANSACTION_SOURCE.REFERRAL));
        return earnedReferralLast30days;
    }

    public get OwnReferralId(): string {
        return this._ownReferralId;
    }

    public get Language(): string {
        return this._language;
    }

    public get Referral(): IReferral {
        return this.referral;
    }

    public set Currency(currency: string) {
        this._currency = currency;
    }

    public get Currency() {
        return this._currency;
    }

    public get AbTesting(): IUserAbTesting {
        return this._abTesting;
    }

    public get Email(): string {
        return this.email;
    }

    public get SignUpDate(): string {
        return this.signUpDate;
    }

    public get TotalSales(): number {
        return this.totalSales;
    }

    public assetAlreadyFollowed(assetId: string): boolean {
        return this.subscribedAssetsIds.includes(assetId);
    }

    public creditsAsString(): string {
        return this._credits.toFixed(2);
    }

    public username(): string {
        return this.pseudo;
    }

    public getUserTotalCredits(): number {
        return this.totalCredits + this.FrozenCredits;
    }

    public getLast24HEarningDifference(): number {
        return this.last24HEarningDifference;
    }

    private compute24HEarningDifference(): number {
        const totalEarningByDescending = [...this.totalEarningHistory].reverse();
        const todayEarning = totalEarningByDescending.find((earning) => this.checkToday(earning.createdAt));
        const last24HEarning = totalEarningByDescending.find(earning => this.checkBeforeLast24H(earning.createdAt));
        return todayEarning && last24HEarning ? todayEarning.value - last24HEarning.value : 0;
    }

    private checkBeforeLast24H(date: string): boolean {
        return (new Date().getTimeInSeconds() - new Date(date).getTimeInSeconds()) >= 86400;
    }

    private checkToday(date: string): boolean {
        return (new Date().getTimeInSeconds() - new Date(date).getTimeInSeconds()) < 86400;
    }

    private get CanDisplayGift(): boolean {
        return !(this.VirtualCreditsAsNumber < this.LOWEST_DISPLAY_VALUE || this.VirtualCreditsAsNumber === ZERO);
    }
    private get CanDisplayReserved(): boolean {
        return !(this.FrozenCredits < this.LOWEST_DISPLAY_VALUE || this.FrozenCredits === ZERO);
    }
    private get CanDisplayWalletShares(): boolean {
        const isVip = this.UserType === TRENDEX_ROLES.VIP
        return !isVip;
    }

    public getWalletOverview(wallet: WalletViewModel) {
        return ([
            { name: i18n.t('wallet.shares'), icon: Shares, value: this.getFormattedValue(wallet.portfolioTotalValue), tooltip: i18n.t('wallet.tooltip.shares'), formatType: VALUE_FORMAT_TYPE.CURRENCY, canDisplay: this.CanDisplayWalletShares },
            { name: i18n.t('credits'), icon: Credits, value: this.getFormattedValue(this.creditsAsNumber), tooltip: i18n.t('wallet.tooltip.credits'), formatType: VALUE_FORMAT_TYPE.CURRENCY, canDisplay: true },
            { name: i18n.t('gifts'), icon: Gifts, value: this.getFormattedValue(this.VirtualCreditsAsNumber), tooltip: i18n.t('wallet.tooltip.gifts'), formatType: VALUE_FORMAT_TYPE.CURRENCY, canDisplay: this.CanDisplayGift },
            { name: i18n.t('locked'), icon: Locked, value: this.getFormattedValue(this.FrozenCredits), tooltip: i18n.t('wallet.tooltip.locked'), formatType: VALUE_FORMAT_TYPE.CURRENCY, canDisplay: this.CanDisplayReserved },
        ]);
    }

    private getFormattedValue(value: number, formatType: VALUE_FORMAT_TYPE = VALUE_FORMAT_TYPE.CURRENCY): number | string {
        if (formatType === VALUE_FORMAT_TYPE.PERCENTAGE) {
            return value;
        }

        return value?.toCurrency(2) ?? Number(0).toCurrency(2, undefined, 2);
    }

    public shouldShowYield(): boolean {
        return !Application.getInstance().ContryWithoutYield.includes(this.Country);
    }

    public getChangeSign() {
        return this.getLast24HEarningDifference() > 0 ? '+' : '';
    }

    public getNumberOfTokensComparedToTotalCredits(assetCurrentValue: number, assetSupply: number): number {
        if (assetCurrentValue === 0 || assetSupply === 0 || this.TotalCreditsAsNumber < assetCurrentValue)
            return 0;
        return Math.min(Math.floor(this.TotalCreditsAsNumber.divide(assetCurrentValue)), assetSupply);
    }

    public get CanVipSellOwnTokens(): boolean {
        return this.IsUserVip && VIP_CAN_SELL_OWN_TOKENS.includes(this.Id.toString());
    }

    public get isVipAccountConfirmed(): boolean {
        return (this.IsUserVip && this.isIdentityConfirmed);
    }

    public getReferralLink(): string {
        return `https://${PROD_ORIGIN}/profile?mode=REGISTER&referral=${this.Referral.ownReferralId}`
    }

    public get canAskForKyc(): boolean {
        return (!this.isIdentityConfirmed && this.TotalSales >= REQUIRED_SALES_AMOUNT_FOR_KYC);
    }

    // #region Personal Tournament
    public get PersonalTournament(): ITournament | undefined {
        return this.personalTournament;
    }

    public get HasPersonalTournament(): boolean {
        return Boolean(Object.keys(this.personalTournament || {}).length > 0);
    }

    public get PersonalTournamentSubscriptionsOpen(): boolean {
        if (!this.HasPersonalTournament)
            return false;
        const now = dayjs();
        return dayjs(this.personalTournament!.subscriptionEndDate).isAfter(now);
    }

    public get PersonalTournamentState(): PersonaTournamentStateEnum {
        if (!this.HasPersonalTournament)
            return PersonaTournamentStateEnum.NONE;
        if (this.PersonalTournamentSubscriptionsOpen)
            return PersonaTournamentStateEnum.OPEN;
        return dayjs(this.personalTournament!.subscriptionEndDate).isBefore(dayjs()) ? PersonaTournamentStateEnum.FINISHED : PersonaTournamentStateEnum.IN_PROGRESS;
    }

    public get PersonalTournamentSubscriptionState(): PersonalTournamentSubscriptionStatusEnum {
        if (!this.HasPersonalTournament || !this.personalTournament!.label)
            return PersonalTournamentSubscriptionStatusEnum.NONE;
        return this.personalTournament!.label === PERSONAL_TOURNAMENT_LABEL_UNSUBSCRIBED ? PersonalTournamentSubscriptionStatusEnum.NOT_SUBSCRIBED_YET : PersonalTournamentSubscriptionStatusEnum.SUBSCRIBED;
    }

    public get AlreadyRegisteredForTournament(): boolean {
        return this.PersonalTournamentSubscriptionState === PersonalTournamentSubscriptionStatusEnum.SUBSCRIBED;
    }

    public get IsPersonalTournamentInProgress(): boolean {
        if (!this.HasPersonalTournament)
            return false;
        const now = dayjs();
        return (dayjs(this.personalTournament!.subscriptionEndDate).isSame(now) || dayjs(this.personalTournament!.subscriptionEndDate).isBefore(now)) && dayjs(this.personalTournament!.endDate).isAfter(now);
    }

    private get IsPersonalTournamentFinish(): boolean {
        const now: dayjs.Dayjs = dayjs();
        return dayjs(this.personalTournament!.endDate).isBefore(now);
    }

    public get IsPersonalTournamentAvailable(): boolean {
        const personalStartedButUserNotSubscribe: boolean = this.IsPersonalTournamentInProgress && !this.AlreadyRegisteredForTournament;
        if (!Application.getInstance().UserPersonalTournamentConfig.enabled || !this.HasPersonalTournament || personalStartedButUserNotSubscribe)
            return false;
        return !this.IsPersonalTournamentFinish;
    }

    public get PersonalTournamentPrizePoolAsCurrency(): string {
        return (this.personalTournament?.prizePool || ZERO).toCurrency()
    }

    // #endregion

    public get OnboardingStep(): number {
        return this.onboardingStep;
    }

    public get IsOnboardingRunning(): boolean {
        return Application.getInstance().OnBoardingV2 && this.OnboardingStep > OnboardingStep.NO_ONBOARDING && this.OnboardingStep < OnboardingStep.FINISH;
    }

    public get IsDisabled(): boolean {
        return this.disabled;
    }

    public get IsAdminToVip(): boolean {
        return this.isAdminToVip;
    }

    public get OwnedAssets(): AssetInterface[] {
        return this.ownedAssets;
    }

    public get SubType(): string {
        return this.subType;
    }

    public get HasGoogleSSO(): boolean {
        return Boolean(this.google?.email && this.google?.id !== DEFAULT_ID_AS_STRING);
    }

    public get HasAppleSSO(): boolean {
        return Boolean(this.apple?.email && this.apple?.id !== DEFAULT_ID_AS_STRING);
    }

    public get HasSSO(): boolean {
        return this.HasAppleSSO || this.HasGoogleSSO || false;
    }

    public get IsFrenchUserBySSO(): boolean {
        return this.Country === COUNTRY.FRENCH && this.HasSSO;
    }

    public get IsEuMember(): boolean {
        return isEuMember(this.Country);
    }

    public get RequireKycForFirstWithdrawal(): boolean {
        return Boolean(!this.IsFrenchUserBySSO && !this.isIdentityConfirmed);
    }

    public get IntendToBeVip(): boolean {
        return localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.VIP_MODE) === TRENDEX_ROLES.VIP;
    }

    public hasEnoughtCredits(amount: number): boolean {
        return this.TotalCreditsAsNumber >= amount;
    }
};
