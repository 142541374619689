import Timer from 'components/medium/timer/Timer';
import ProgressPrivateSell from 'components/medium/vip/progress-private-sell/ProgressPrivateSell';
import AssetInterface from 'model/AssetModel/AssetInterface';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import {
    actionFetchOwnAssets,
    actionGetPrivateSellStatus,
    actionLaunchPrivateSell,
} from 'service/assets/actions';
import { managementModal } from 'service/modal/action';
import {
    createScheduleOrder,
} from 'service/scheduled-order/actions';
import Utils from 'utils/Utils';
import OverviewPrivateSellProps from './OverviewPrivateSellProps';
import OverviewPrivateSellState from './OverviewPrivateSellState';

import GenericStepper from 'components/medium/GenericStepper/GenericStepper';
import ScheduledOrder from 'components/medium/vip/scheduled-order/ScheduledOrder';
import PrivateSellConfirm from 'components/small/private-sell/private-sell-confirm/PrivateSellConfirm';
import { DEFAULT_LIQUDITIY_POOL_VALUE, DEFAULT_LIQUIDITY_POOL_SUPPLY, LAUNCH_MODE, MINIMUM_NUMBER_OF_TOKEN_FOR_PRIVATE_SELL } from 'config/_const';
import i18next from 'i18next';
import { managementNotification } from 'service/notification/action';
import './OverviewPrivateSell.scss';

class OverviewPrivateSell extends React.Component<OverviewPrivateSellProps, OverviewPrivateSellState> {

    constructor(props: OverviewPrivateSellProps) {
        super(props);
        this.state = {
            activeStep: 0,
            skipped: [],
            initialSaleDate: '',
            initialSupply: MINIMUM_NUMBER_OF_TOKEN_FOR_PRIVATE_SELL,
            liquidityPoolEnabled: false,
            liquidityPoolInitialSupply: DEFAULT_LIQUIDITY_POOL_SUPPLY,
            liquidityPoolInitialValue: DEFAULT_LIQUDITIY_POOL_VALUE,
            chunkProgressiveMarket: true,
            launchMode: LAUNCH_MODE.DEFAULT,
        };
    }

    componentWillMount = () => {
        if (this.props.auth.data.isAuthenticated) {
            this.props.actionFetchOwnAssets();
            if (this.props.auth.data.actualAsset) {
                this.props.actionGetPrivateSellStatus(this.props.auth.data.actualAsset._id);
            }
        }
    }

    componentDidUpdate = (prevProps: OverviewPrivateSellProps) => {

        const oldActualAsset: AssetInterface | undefined = prevProps.assets.data.find((asset: AssetInterface) => asset && prevProps.auth.data.actualAsset && asset._id === prevProps.auth.data.actualAsset._id);
        const newActualAsset: AssetInterface | undefined = this.props.assets.data.find((asset: AssetInterface) => asset && prevProps.auth.data.actualAsset && asset._id === prevProps.auth.data.actualAsset._id);

        if (oldActualAsset && newActualAsset) {

            const initalSaleDateDefined: boolean = !!(!oldActualAsset.initialSaleDate && newActualAsset.initialSaleDate);

            if (initalSaleDateDefined) {

                this.props.managementModal(
                    true,
                    {
                        display: true,
                        disableBackDrop: false,
                        showBackArrow: false,
                        fullScreen: false,
                        type: 'CONFIRM_SALE',
                        propsCustom: {
                            onValidation: () => this.props.managementModal(false),
                        },
                    },
                );
            }
        }
    }

    nextStep = () => {
        this.setState({
            activeStep: this.state.activeStep + 1,
        });
    }

    onBeforeLaunchInitialSale = (body: any) => {
        if (body.initialSaleDate) {
            this.setState({
                initialSaleDate: body.initialSaleDate,
                initialSupply: body.initialSupply,
                liquidityPoolEnabled: body.liquidityPoolEnabled,
                liquidityPoolInitialSupply: Number(body.liquidityPoolInitialSupply),
                liquidityPoolInitialValue: Number(body.liquidityPoolInitialValue),
                chunkProgressiveMarket: body.chunkProgressiveMarket,
                launchMode: body.launchMode
            });
        }

        if (moment(new Date(body.initialSaleDate)).isBefore(new Date())) {

            return this.props.managementNotification(true, i18next.t('OverviewPrivateSell.passed-initialsaleDate'), 400);
        }

        this.nextStep();

    }

    createConfiguration = () => {
        return [
            {
                label: i18next.t('OverviewAdvantage.sell-tokens'),
                element: (
                    <ScheduledOrder
                        totalSupply={this.props.auth.data.actualAsset.totalSupply}
                        initialSupply={this.props.auth.data.actualAsset.initialSupply}
                        minAssets={MINIMUM_NUMBER_OF_TOKEN_FOR_PRIVATE_SELL}
                        chunkProgressiveMarket={this.state.chunkProgressiveMarket}
                        createScheduledOrder={(form: any) => this.onBeforeLaunchInitialSale(form)}
                    />
                ),
            },
            {
                label: i18next.t('OverviewAdvantage.start-private-sell'),
                element: (
                    <PrivateSellConfirm
                        date={Utils.formatDateToCompleteText(new Date(this.state.initialSaleDate))}
                        initialSupply={this.state.initialSupply}
                        launchPrivateSell={() => {
                            console.log(this.state)
                            this.props.actionLaunchPrivateSell({
                                assetId: this.props.auth.data.actualAsset._id,
                                initialSaleDate: this.state.initialSaleDate,
                                initialSupply: this.state.initialSupply,
                                liquidityPoolEnabled: this.state.liquidityPoolEnabled,
                                liquidityPoolInitialSupply: this.state.liquidityPoolInitialSupply,
                                liquidityPoolInitialValue: this.state.liquidityPoolInitialValue,
                                chunkProgressiveMarket: this.state.chunkProgressiveMarket,
                                launchMode: this.state.launchMode
                            }
                            );
                        }} />
                ),
            },
        ];

    }

    render = () => {
        const {
            auth,
            assets,
        } = this.props;

        const actualAsset: AssetInterface = assets.data.find((asset: AssetInterface) => asset && auth.data.actualAsset && asset._id === auth.data.actualAsset._id);
        const hasEnded = actualAsset?.initialSaleDone;

        const { activeStep } = this.state;

        const initialSaleDate = actualAsset && actualAsset.initialSaleDate ? new Date(actualAsset.initialSaleDate) : null;

        return (
            <div className="overview-private-sell">
                {
                    !initialSaleDate && !hasEnded && actualAsset ? (
                        <div className="stepper-launch-private-sell">
                            <GenericStepper
                                currentStep={activeStep}
                                stepsContent={this.createConfiguration()}
                                orientation="vertical" />
                        </div>
                    ) :
                        initialSaleDate && moment(new Date(initialSaleDate)).isAfter(moment(new Date())) ? (
                            <Timer
                                title={i18next.t('OverviewPrivateSell.yourPrivateSaleWillBeginIn')}
                                onTimeEnd={() => this.props.actionGetPrivateSellStatus(actualAsset._id)}
                                date={actualAsset.initialSaleDate} />
                        ) : initialSaleDate && actualAsset.initialSaleDone ? (
                            <ProgressPrivateSell
                                totalPaid={actualAsset.totalPaid || 0}
                                privateSellFinish={true}
                                totalSupply={actualAsset.totalSupply}
                                startDate={initialSaleDate.toISOString()}
                                endDate={actualAsset.initialSaleRealEndDate}
                                totalTokensSell={Math.abs(actualAsset.initialSupply - actualAsset.availableSupply)}
                                remainingTokens={actualAsset.availableSupply}
                            />
                        ) :
                            initialSaleDate &&
                            <ProgressPrivateSell
                                privateSellFinish={false}
                                totalPaid={actualAsset.totalPaid || 0}
                                totalSupply={actualAsset.totalSupply}
                                onRefresh={() => this.props.actionGetPrivateSellStatus(actualAsset._id)}
                                startDate={initialSaleDate.toISOString()}
                                endDate={actualAsset.initialSaleRealEndDate}
                                totalTokensSell={Math.abs(actualAsset.initialSupply - actualAsset.availableSupply)}
                                remainingTokens={actualAsset.availableSupply}
                            />
                }

            </div>
        );
    }
}

const mapStateToProps = (state: OverviewPrivateSellProps) => ({
    auth: state.auth,
    assets: state.assets,
});

export default connect(
    mapStateToProps, {
    managementModal,
    actionLaunchPrivateSell,
    actionFetchOwnAssets,
    actionGetPrivateSellStatus,
    managementNotification,
    createScheduleOrder,
})(OverviewPrivateSell);
