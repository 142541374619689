import React from 'react';
import './style.scss';
import GreenButtonWithGradient from 'components/small/buttons/green-button/GreenButtonWithGradient';
import srcExampleIncompleteTeam from 'assets/images/incomplete-team-example.jpg';
import srcExampleIncompleteTeamMobile from 'assets/images/incomplete-team-example-mobile.jpg';
import IModalIncompleteTeamProps from './IModalIncompleteTeamProps';
import Utils from 'utils/Utils';
import i18next from 'i18next';
const ModalIncompleteTeam = ({ maxNumberOfAssets, onValidation }: IModalIncompleteTeamProps) => {
    return (
        <div className='container-incomplete-team'>
            <p className='title-incomplete-team'>{i18next.t('incomplete-team.title')}</p>
            <div className="list-incomplete-team">
                <p>✔️ {i18next.t('incomplete-team.under-title-step-1')} {maxNumberOfAssets} {i18next.t('incomplete-team.under-title-step-2')}</p>
            </div>
            <div className="container-img-incomplete-team">
                <div className="example">
                    <span>{i18next.t('incomplete-team.exemple')}</span>
                </div>
                <img className='img-incomplete-team' src={Utils.isMobile() ? srcExampleIncompleteTeamMobile : srcExampleIncompleteTeam} alt="Example incomplete team" />
            </div>
            <div className="container-btn-modal-gift">
                <GreenButtonWithGradient
                    className="uppercase sheen-effect"
                    receiveClickEvent={false}
                    disabled={false}
                    type="button"
                    handleClick={() => onValidation()}
                    textButton={i18next.t('incomplete-team.btn')} />
            </div>
        </div>
    );
};
export default ModalIncompleteTeam;
