import React from 'react';
import WrapperSelectProps from './WrapperSelectProps';
import { WRAPPER_SELECT_TYPE } from '../../../config/_const';
import Utils from '../../../utils/Utils';
import { makeStyles, Theme, createStyles, FormControl, InputLabel, Select, MenuItem, ListItemText } from '@material-ui/core';
import { TrendexCheckbox } from '../CustomCheckbox';
import SelectDataInterface from '../../../model/Input/SelectDataInterface';
import i18next from 'i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: 0,
            marginBottom: '2%',
            width: '100%',
            color: 'white',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

const WrapperSelect: React.FunctionComponent<WrapperSelectProps> = (props: WrapperSelectProps) => {
    if (props.type === WRAPPER_SELECT_TYPE.NATIVE) {
        return (
            <NativeSelect
                className={props.className}
                showDefaultStyle={props.showDefaultStyle}
                propsReduxForm={props.propsReduxForm}
                data={props.data}
                disabled={props.disabled}
                value={props.value}
                valueChange={props.valueChange} />);
    } if (props.type === WRAPPER_SELECT_TYPE.MATERIAL) {
        return (
            <MaterialSelect
                customValue={props.customValue}
                customLabel={props.customLabel}
                selectProps={props.selectProps}
                propsReduxForm={props.propsReduxForm}
                data={props.data}
                value={props.value}
                multiple={props.multiple}
                valueChange={props.valueChange} />
        );
    }

    return <p>Aucun type de select</p>;
};

const MaterialSelect: React.FunctionComponent<WrapperSelectProps> = (props: WrapperSelectProps) => {
    const classes = useStyles();
    let currentValue: any = -1;
    let label: string = 'Aucun label défini';

    if (props.propsReduxForm) {

        currentValue = props.value ?? props.propsReduxForm.input.value;
        label = props.propsReduxForm.label;
    } else if (typeof props.customValue !== 'undefined') {

        currentValue = props.customValue;

        if (props.customLabel) {

            label = props.customLabel;
        }
    }

    return (
        <FormControl className={classes.formControl} variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
            <Select
                fullWidth={true}
                inputProps={
                    props.selectProps
                }
                native={false}
                style={{ color: 'white', background: '#343B6A' }}
                multiple={props.multiple ? props.multiple : false}
                name={props.propsReduxForm ? props.propsReduxForm.input.name : ''}
                value={currentValue}
                renderValue={(selected: unknown) => {
                    return props.data
                        .filter((data: SelectDataInterface) => {
                            if (Array.isArray(selected)) {

                                if ((selected as string[]).indexOf(`${data.value}`) !== -1) {
                                    return data;
                                }
                            } else {
                                if (selected === data.value) {
                                    return data;
                                }
                            }
                        })
                        .map((data: SelectDataInterface) => data.label)
                        .join(', ');

                }}
                id={Utils.generateUniqueId()}
                onChange={props.valueChange}
                label={label}>

                {props.data.map((type: SelectDataInterface) => {
                    const isSelected = props.multiple && !!(currentValue.find((data: any) => type.value === data));
                    return (
                        <MenuItem
                            disabled={type.disabled}
                            style={{ color: 'black' }}
                            key={Utils.generateUniqueId()}
                            value={type.value}>
                            {props.multiple && <TrendexCheckbox checked={isSelected} />}
                            <ListItemText primary={type.label} />
                        </MenuItem>
                    );
                })};
            </Select>
        </FormControl>

    );
};

const NativeSelect: React.FunctionComponent<WrapperSelectProps> = (props: WrapperSelectProps) => {
    const style: React.CSSProperties = {
        height: '56px',
        borderRadius: '6px',
        width: '100%',
        padding: '13.5px 14px',
        background: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
    };
    return (
        <select
            style={(props.showDefaultStyle === false) ? undefined : style}
            className={props.className}
            onChange={props.valueChange}
            value={props.value ?? props.propsReduxForm.input.value}
            disabled={props.disabled ?? false}>
            <option disabled={true} key={Utils.generateUniqueId()} value={-1}>{i18next.t('select.option')}</option>
            {
                props.data.map((data: any) => (
                    <option
                        key={Utils.generateUniqueId()}
                        data-key={data.dataKey ? data.dataKey : null}
                        value={data.value}>
                        {data.label}
                    </option>
                ))
            }
        </select>
    );
};

export default WrapperSelect;
