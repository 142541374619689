import MoneyEarned from 'components/icons/referral/MoneyEarned';
import Referral from 'components/icons/referral/Referral';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import KnowMoreMessage from 'components/small/know-more-message/KnowMoreMessage';
import ReferYourFriendsTitle from 'components/small/referYourFriends-title/ReferYourFriendsTitle';
import ReferenceYourFriends from 'components/small/referenceYourFriends/ReferenceYourFriends';
import i18next from 'i18next';
import { RootState } from 'model/Redux';
import UserInterface from 'model/User/UserInterface';
import React, { useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { getReferral, getReferralDataByGodson } from 'service/affiliate/getters';
import { managementModal } from 'service/modal/action';
import { fetchReferralTransactions } from 'service/transactions/actions';
import { referralTransactionsSelector } from 'service/transactions/selectors';

import ShareReferralButtons from 'components/small/share-referral-buttons/ShareReferralButtons';
import { AFFILIATE_BANNER, DEFAULT_AVATAR, G_TAGS_KNOWN_EVENTS } from 'config/_const';
import { selectorAccountViewModel } from 'service/auth/selectors';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import Utils from 'utils/Utils';
import './Affiliate.scss';
import AffiliatePageProps from './AffiliatePageProps';

const AffiliatePage: React.FC<AffiliatePageProps> = (props) => {
    const dispatch = useDispatch();
    const { auth, referralTransactions } = props;

    React.useEffect(() => {
        dispatch(fetchReferralTransactions());
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.REFERRAL_ZONE, { userId: auth.account._id })
    }, [auth.account._id, dispatch]);
    const { earnedReferral } = getReferral(referralTransactions);
    const referralUsers = auth.Referral.referrals;
    const showMoreInfo = () => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            showCancelButton: true,
            type: 'CONFIRM',
            propsCustom: {
                className: "knowMoreMessage-confirm-button",
                showCancelButton: false,
                showLinkButton: false,
                onRefused: () => dispatch(managementModal(false)),
                textValidButton: i18next.t('Becomevippage.submitSuccess.confirm'),
                onValidation: () => dispatch(managementModal(false)),
                message: <KnowMoreMessage />,
            },
        }));
    }

    const referralInfoList = useMemo(() => ([
        { id: "R1", bgColor: "blue", icon: <Referral />, label: i18next.t('Referral.shareProfile.friendReferred'), value: referralUsers?.length ?? 0 },
        { id: "R2", bgColor: "orange", icon: <MoneyEarned />, label: i18next.t('Referral.shareProfile.totalReferralEarn'), value: earnedReferral.toCurrency() },
    ]), [earnedReferral, referralUsers]);
    const affiliateBanner = AFFILIATE_BANNER[Utils.getCurrentLanguage().toUpperCase()] ?? AFFILIATE_BANNER.EN;
    return (
        <div className="affiliate-overview-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="main_container">
                            <section className="affiliate-top-section">
                                <img className='affiliate-banner' src={affiliateBanner} alt="affiliate-banner" />
                                <div className='affiliate-share-section' >
                                    <ReferYourFriendsTitle />
                                    <ReferenceYourFriends greenButton={false} />
                                    <ShareReferralButtons accountVM={auth}/>
                                </div>
                            </section>

                            <div className="knowMoreButtonContainer">
                                <PrimaryButton
                                    disabled={false}
                                    receiveClickEvent={false}
                                    textButton={i18next.t('initPayment.tooltipLabel')}
                                    type="button"
                                    handleClick={() => showMoreInfo()} />
                            </div>
                            <section className="referral-section">
                                {referralInfoList.map(info => <div key={info.id} className={`referral-card ${info.bgColor}`}>
                                    <div className="referral-card-icon">{info.icon}</div>
                                    <div className="referral-card-info">
                                        <h4 className="title">{info.label} :</h4>
                                        <span className="value">{info.value}</span>
                                    </div>
                                </div>)}
                            </section>
                            {referralUsers && referralUsers.length &&
                                <section className='referral-friend-section'>
                                    <h2 className='referral-friend-title'>{i18next.t('Affiliate.yourpartners')}</h2>
                                    {referralUsers.map((user: UserInterface) => {
                                        const { percentage } = getReferralDataByGodson(props.referralTransactions, user);
                                        return (
                                            <div key={user._id} className='friend-card'>
                                                <img className='friend-avatar' src={user.avatar || DEFAULT_AVATAR} alt="friend-avatart" />
                                                <div className="friend-details">
                                                    <h5 className='friend-name'>{user.pseudo || ''}</h5>
                                                    <div className='friend-amount-block'>
                                                        <div>{i18next.t('Amount-received')}  : {percentage.toCurrency()}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </section>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state: RootState) => ({
    auth: selectorAccountViewModel(state),
    referralTransactions: referralTransactionsSelector(state),
});

export default compose(
    connect(mapStateToProps, {
    }),
)(React.memo(AffiliatePage));
