import * as React from 'react';
import { Trans } from 'react-i18next';
import './FirstPurchaseBannerFunCards.scss';
import IFirstPurchaseBannerFunCardsProps from './IFirstPurchaseBannerFunCardsProps';
import GoldBag from 'assets/images/fun-assets/gold_bag.webp';

const FirstPurchaseBannerFunCards: React.FC<IFirstPurchaseBannerFunCardsProps> = ({ firstDepositAchievementVM }) => {


    return <div className='first-purchase-banner-fun-cards'>
        <img src={GoldBag} alt="gold bag icon" />
        <div className="content">
            <p className="title-content-banner-first-purchase-fun-cards"><Trans
                i18nKey={firstDepositAchievementVM.Achievement.Subtitle.key}
                components={{ bold: <strong /> }}
                values={firstDepositAchievementVM.Achievement.Subtitle.values} /></p>
        </div>
        <img src={GoldBag} alt="gold bag icon" />
    </div>
};

export default FirstPurchaseBannerFunCards;
