import BasketballTournamentBanner from 'assets/images/tournament/banner/basketball-resized.jpg';
import DefaultTournamentBanner from 'assets/images/tournament/banner/default-resized.jpg';
import FootballTournamentBanner from 'assets/images/tournament/banner/football-resized.jpg';
import NoTournamentBanner from 'assets/images/tournament/banner/no-tournament.png';

import { TOURNAMENT_CATEGORY } from 'config/_const';

const TOURNAMENT_BANNER: Record<string, string> = {
    FOOTBALL: FootballTournamentBanner,
    BASKETBALL: BasketballTournamentBanner,
    DEFAULT: DefaultTournamentBanner,
    NOTOURNAMENT: NoTournamentBanner,
}

export const getTournamentBanner = (category: TOURNAMENT_CATEGORY) => TOURNAMENT_BANNER[category];