import { Instagram } from '@material-ui/icons';
import React from 'react';
import Youtube from '../Youtube';
import Athlete from './Athlete';
import BasketballIcon from './BasketballIcon';
import FightIcon from './FightIcon';
import FootballIcon from './FootballIcon';
import Music from './Music';
import RugbyIcon from './RugbyIcon';
import TopTalents from './TopTalents';
import Tiktok from '../Tiktok';
import Tv from '../Tv';
import TrendScoreIcon from '../TrendScoreIcon';
import Handball from './Handball';
import EuroCupBlack from '../EuroCupBlack';
import FunCardsIcon from '../FunCards';

const CategoriesIconsMap: Record<string, JSX.Element> = {
    Youtube: <Youtube style={{ verticalAlign: 'initial', marginTop: '-2px' }} />,
    SportsSoccer: <Athlete />,
    Instagram: <Instagram style={{ verticalAlign: 'initial', marginTop: '-2px' }} />,
    Music: <Music />,
    TopTalents: <TopTalents />,
    Fight: <FightIcon />,
    Basketball: <BasketballIcon />,
    Football: <FootballIcon />,
    Rugby: <RugbyIcon />,
    Tiktok: <Tiktok />,
    Cinema: <Tv />,
    Default: <TrendScoreIcon />,
    Handball: <Handball />,
    "Euro 2024": <EuroCupBlack />,
    "Fun cards": <FunCardsIcon />,
};

export default CategoriesIconsMap; 