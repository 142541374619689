import clsx from 'clsx';
import Shares from 'components/icons/Shares';
import TrendScoreIcon from 'components/icons/TrendScoreIcon';
import FootballIcon from 'components/icons/categories/FootballIcon';
import ScoreLabel from 'components/small/score-label/ScoreLabel';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import Color from 'view-model/Shared/Color';
import './OnboardingLightWalletButton.scss';
import useIsMobile from 'hooks/useIsMobile';

interface IOnboardingLightWalletButtonProps {
    onLastStepClicked: () => void;
}

const OnboardingLightWalletButton: React.FC<IOnboardingLightWalletButtonProps> = ({ onLastStepClicked }) => {
    const isMobile = useIsMobile();
    const [currentSlide, setCurrentSlide] = useState<number>(1);
    const footballScore = 85;
    const trendScore = 90;

    const Score = ({ score, category, type, IconComponent }: { score: number, category?: string, type: string, IconComponent: React.FC<React.SVGProps<SVGSVGElement>> }) => (
        <div className='score'>
            <div className='score-title'>
                <ScoreLabel
                    score={score}
                    category={category}
                    canShowBoost={false}
                    backgroundColor={Color.getColorByInputRatio(score)}
                />
                <Trans i18nKey={`onboarding.light-wallet.slide.2.score.${type}.title`} />
            </div>
            <Trans i18nKey={`onboarding.light-wallet.slide.2.score.${type}.text`} />
            <IconComponent className='icon' />
        </div>
    );

    return (
        <div className={clsx("onboarding-lightwallet-button-container", { 'desktop': !isMobile })}>
            <div className='onboarding-lightwallet-button-carousel'>
                <div className={clsx('onboarding-lightwallet-button-carousel-content slide-1', { 'active': currentSlide === 1 })}>
                    <Shares width="34" height="36" />
                    <span className='title'><Trans i18nKey="onboarding.light-wallet.slide.1.title" /></span>
                    <span className='subtitle'><Trans i18nKey="onboarding.light-wallet.slide.1.subtitle" /></span>
                    <button className='next-btn' onClick={() => setCurrentSlide(2)}><Trans i18nKey="onboarding.light-wallet.slide.1.button" /></button>
                </div>
                <div className={clsx('onboarding-lightwallet-button-carousel-content slide-2', { 'active': currentSlide === 2 })}>
                    <Score score={footballScore} category={"football"} type="football" IconComponent={FootballIcon} />
                    <Score score={trendScore} type="trend" IconComponent={TrendScoreIcon} />
                    <span className='title'><Trans i18nKey="onboarding.light-wallet.slide.2.title" /></span>
                    <span className='subtitle'><Trans i18nKey="onboarding.light-wallet.slide.2.subtitle" components={[<br></br>]} /></span>
                    <button className='next-btn' onClick={onLastStepClicked}><Trans i18nKey="onboarding.light-wallet.slide.2.button" /></button>
                </div>
            </div>
        </div>
    )
}

export default OnboardingLightWalletButton;