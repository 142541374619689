import ReduxRootState from 'model/Redux/ReduxRootSate';
import ReduxActionInterface from 'model/ReduxActionInterface';
import { STATUS } from 'service/storeType';
import Utils from 'utils/Utils';
import * as ActionTypesTournament from './actionsType';
import { IInternalTournament } from './types';

const ROOT_VALUE_STATE: ReduxRootState<IInternalTournament[]> = {
    loading: false,
    data: [],
    error: '',
    status: {
        postTournament: STATUS.IDLE,
        getCurrentTournament: STATUS.IDLE,
        getPreviousTournament: STATUS.IDLE,
    },
};

// TODO : since we have a little codebase on this reducer, we could type all the actions.
// I can do it later. (@mathias)
export const tournamentReducer = (state: ReduxRootState<IInternalTournament[]> = ROOT_VALUE_STATE, action: ReduxActionInterface): ReduxRootState<IInternalTournament[]> => {
    switch (action.type) {
        case ActionTypesTournament.TOURNAMENT.LOADING:
            return {
                ...state,
                loading: true,
            };

        case ActionTypesTournament.TOURNAMENT.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        case ActionTypesTournament.TOURNAMENT.SUCCESS:
            const stateData: IInternalTournament[] = state.data.length ? Utils.removedDuplicateMongoFile(state.data, action.payload.data) : [];
            return {
                ...state,
                data: stateData.concat(action.payload.data),
                loading: false,
            };
        case ActionTypesTournament.POST_TOURNAMENT.LOADING:
            return {
                ...state,
                status: {
                    ...state.status,
                    postTournament: STATUS.PENDING,
                },
            };

        case ActionTypesTournament.POST_TOURNAMENT.ERROR:
            return {
                ...state,
                error: action.payload.message,
                status: {
                    ...state.status,
                    postTournament: STATUS.REJECTED,
                },
            };
        case ActionTypesTournament.POST_TOURNAMENT.SUCCESS:
            return {
                ...state,
                data: state.data.concat(action.payload.data),
                status: {
                    ...state.status,
                    postTournament: STATUS.RESOLVED,
                },
            };

        case ActionTypesTournament.GET_CURRENT_TOURNAMENT.LOADING:
            return {
                ...state,
                status: {
                    ...state.status,
                    getCurrentTournament: STATUS.PENDING,
                },
            };

        case ActionTypesTournament.GET_CURRENT_TOURNAMENT.ERROR:
            return {
                ...state,
                error: action.payload.message,
                data: state.data,
                status: {
                    ...state.status,
                    getCurrentTournament: STATUS.REJECTED,
                },
            };
        case ActionTypesTournament.GET_CURRENT_TOURNAMENT.SUCCESS:
            return {
                ...state,
                data: state.data.removedDuplicateMongoFile(action.payload.data).concat(action.payload.data),
                status: {
                    ...state.status,
                    getCurrentTournament: STATUS.RESOLVED,
                },
            };
        case ActionTypesTournament.GET_PREVIOUS_TOURNAMENT.LOADING:
            return {
                ...state,
                status: {
                    ...state.status,
                    getPreviousTournament: STATUS.PENDING,
                },
            };

        case ActionTypesTournament.GET_PREVIOUS_TOURNAMENT.ERROR:
            return {
                ...state,
                error: action.payload.message,
                data: state.data,
                status: {
                    ...state.status,
                    getPreviousTournament: STATUS.REJECTED,
                },
            };
        case ActionTypesTournament.GET_PREVIOUS_TOURNAMENT.SUCCESS:
            return {
                ...state,
                data: state.data.removedDuplicateMongoFile(action.payload.data).concat(action.payload.data),
                status: {
                    ...state.status,
                    getPreviousTournament: STATUS.RESOLVED,
                },
            };
        case ActionTypesTournament.RESET_STATUS:
            return {
                ...state,
                status: {
                    ...state.status,
                    [action.payload.actionName]: STATUS.IDLE,
                },
            };
        default: return state;
    }
};
