import { Tooltip } from '@material-ui/core';
import i18next from 'i18next';
import React from 'react';
import ILevelSubIconProps from './ILevelSubIconProps';
import './style.scss';
import clsx from 'clsx';
import TripleArrowUpGif from 'assets/gif/arrow/TripleArrowUp.gif'
import LevelSubIconContains from './level-sub-icon-contains/LevelSubIconContains';

const LevelSubIcon: React.FC<ILevelSubIconProps> = (props) => {
    let style = props.style || {};

    return (
        <div 
            style={{ ...props.subIconViewModel.generateSubIconCSS(), ...style }}
            className={clsx("sub-icon", 
                { 
                    "score-boosted": props.canShowBoostIcon,
                    "hover": props.canShowPointer,
                },
            )}
        >
            {props.textExplainer && props.showExplainer && props.placementExplainer ?
                <Tooltip title={<span style={{  fontSize: '14px ' }}>{i18next.t(props.textExplainer)}</span>} placement={props.placementExplainer} arrow >
                    <div className='sub-icon__container'>
                            <p className="libelle">{props.subIconViewModel.Level}</p>
                            {props.canShowBoostIcon && <span className='upgrade-arrow'><img src={TripleArrowUpGif} alt="up-arrow" className='arrowUp-gif' /></span>}
                        </div>                
                </Tooltip>
                : <LevelSubIconContains levelValue={props.subIconViewModel.Level} showBoostIcon={props.canShowBoostIcon}/>
            }
        </div>
    );
};

export default LevelSubIcon;
