import { ZERO } from 'config/_const';
import IFootballStatisticsPenalty from 'interfaces/sports/football/statistics/IFootballStatisticsPenalty';

export default class FootballStatisticsPenaltyViewModel {
    private won: number;
    private commited: number;
    private scored: number;
    private missed: number;
    private saved: number;

    constructor(statistics: Partial<IFootballStatisticsPenalty>) {
        this.won = statistics.won ?? ZERO;
        this.commited = statistics.commited ?? ZERO;
        this.scored = statistics.scored ?? ZERO;
        this.missed = statistics.missed ?? ZERO;
        this.saved = statistics.saved ?? ZERO;
    }

    get Won(): number {
        return this.won;
    }

    get Commited(): number {
        return this.commited;
    }

    get Scored(): number {
        return this.scored;
    }

    get Missed(): number {
        return this.missed;
    }

    get Saved(): number {
        return this.saved;
    }
}
