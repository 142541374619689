import * as React from 'react';

const FlagCheckered = ({ height = "20px", width = "20px", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg id="flag-checkered-svg" height={height} width={width} {...props} data-name="Introduction price" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g id="Rectangle_2014" data-name="Rectangle 2014" fill="#8187d0" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="20" height="20" stroke="none" />
                <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
            </g>
            <g id="flag-checkered-duotone" transform="translate(3 3.161)">
                <path id="Tracé_3199" data-name="Tracé 3199" d="M43.035,3.289V.721a4.973,4.973,0,0,1-3.1-.188V2.881a10.038,10.038,0,0,0,2.658.441C42.725,3.3,42.875,3.308,43.035,3.289Zm3.1,1.584V2.436a13.367,13.367,0,0,1-3.1.854V5.726A13.371,13.371,0,0,0,46.136,4.873Zm-.551,5.119a.871.871,0,0,0,.551-.8V7.309a13.367,13.367,0,0,1-3.1.854v2.452A8.25,8.25,0,0,0,45.585,9.992ZM33.733,5.759V3.322a8.733,8.733,0,0,1,3.1-.859V.044a10.463,10.463,0,0,0-3.1.852V.886a.886.886,0,0,0-1.772,0V13.732a.446.446,0,0,0,.443.443h.887a.445.445,0,0,0,.443-.443V8.195a8.733,8.733,0,0,1,3.1-.859V4.9A8.743,8.743,0,0,0,33.733,5.759Zm6.2-.443V2.879a9.133,9.133,0,0,0-3.1-.417V4.9A9.191,9.191,0,0,1,39.935,5.316Zm2.658.418a10.04,10.04,0,0,1-2.658-.441V7.729a10.038,10.038,0,0,0,2.658.441,4.007,4.007,0,0,0,.443-.031V5.7A1.639,1.639,0,0,1,42.592,5.734ZM36.834,7.312v2.44a9.161,9.161,0,0,1,3.1.413V7.752A9.782,9.782,0,0,0,36.834,7.312Z" transform="translate(-31.96)" fill="#8187d0" />
                <path id="Tracé_3200" data-name="Tracé 3200" d="M96,10.653A8.687,8.687,0,0,1,99.1,9.8V7.333a9.4,9.4,0,0,0-3.1.883Zm0-7.309V5.78a8.733,8.733,0,0,1,3.1-.859V2.484A8.725,8.725,0,0,0,96,3.344ZM99.1,4.9V7.333a9.136,9.136,0,0,1,3.1.417V5.314A10.5,10.5,0,0,0,99.1,4.9Zm3.1-2V.554A6.912,6.912,0,0,0,99.1.066V2.484A9.171,9.171,0,0,1,102.2,2.9Zm0,4.873v2.436a9.131,9.131,0,0,0,3.1.425V8.183a4,4,0,0,1-.443.031A11.006,11.006,0,0,1,102.2,7.773Zm2.658-4.455a10.04,10.04,0,0,1-2.658-.441V5.314a10.038,10.038,0,0,0,2.658.441,4.007,4.007,0,0,0,.443-.031V3.287A1.639,1.639,0,0,1,104.859,3.319ZM108.4,7.33V4.894a13.367,13.367,0,0,1-3.1.854V8.184A13.371,13.371,0,0,0,108.4,7.33Zm0-6.458a.972.972,0,0,0-1.472-.733,9.012,9.012,0,0,1-1.629.6V3.312a13.386,13.386,0,0,0,3.1-.854Z" transform="translate(-94.227 -0.021)" fill="#8187d0" opacity="0.4" />
            </g>
        </svg>
    );
}

export default FlagCheckered;
