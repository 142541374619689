import i18n from '18n';
import Application from 'Application';
import { IListCardInformation } from 'components/small/list-card/ListCardProps';
import { CONNECTION_MODE, LOCAL_STORAGE_KNOWN_KEYS } from 'config/_const';
import { ApplicationSportVersionEnum, ConnectionMode } from 'config/_enums';
import { SIGNUP_VERSIONS } from 'data/YoutubeVideo/youtubeVideo.data';
import i18next from 'i18next';
import IConnectViewModelParams from 'model/Connect/IConnectViewModelParams';
import IRegisterStep from 'model/RegisterStep/IRegisterStep';
import Utils from 'utils/Utils';

export default class ConnectViewModel {
    private mode: ConnectionMode;
    private showReCaptcha: boolean;
    private landingPageVersion: ApplicationSportVersionEnum;
    private startVideo: boolean;
    private currentLanguage: string;

    constructor(connect: IConnectViewModelParams) {
        this.mode = connect.mode;
        this.showReCaptcha = connect.showReCaptcha;
        this.landingPageVersion = connect.landingPageVersion || this.getSignupPageVersion();
        this.startVideo = connect.startVideo;
        this.currentLanguage = Utils.getCurrentLanguage().toUpperCase();
    }

    private getSignupPageVersion(): ApplicationSportVersionEnum {
        return Application.getInstance().SportVersion.Version;
    }

    private getSignupVideoInfo(version: ApplicationSportVersionEnum): { videoId: string, bannerImage: string } {
        const versionInfo: Record<string, { videoId: string, bannerImage: string }> = SIGNUP_VERSIONS[version];
        const selectedVersion = versionInfo[this.currentLanguage] ?? versionInfo["EN"];
        return {
            videoId: selectedVersion.videoId,
            bannerImage: selectedVersion.bannerImage,
        };
    };

    public get ApplicationSportVersionEnum(): ApplicationSportVersionEnum {
        return this.getSignupPageVersion();
    }

    public get isVideoPlay(): boolean {
        return this.startVideo;
    }

    public get VideoId(): string {
        return this.getSignupVideoInfo(this.landingPageVersion).videoId;
    }

    public get BannerImageVideo(): string {
        return this.getSignupVideoInfo(this.landingPageVersion).bannerImage;
    }

    public get Mode(): ConnectionMode {
        return this.mode;
    }

    public get OtherMode(): ConnectionMode {
        return this.mode === CONNECTION_MODE.REGISTER ? CONNECTION_MODE.LOGIN : CONNECTION_MODE.REGISTER;
    }

    public get TitleRegister(): string {
        switch (this.landingPageVersion) {
            case ApplicationSportVersionEnum.FOOTBALL:
                return 'title.logo.blue.signup.foot';
            case ApplicationSportVersionEnum.BASKETBALL:
                return 'title.logo.blue.signup.basket';
            default:
                return 'title.logo.blue.signup';
        }
    }

    public get SubTitle4Shares(): string {
        switch (this.landingPageVersion) {
            case ApplicationSportVersionEnum.FOOTBALL:
                return 'signup.subtitle.foot';
            case ApplicationSportVersionEnum.BASKETBALL:
                return 'signup.subtitle.basket';
            default:
                return 'signup.subtitle';
        }
    }

    public get NoAccountTitle(): string {
        return this.mode === CONNECTION_MODE.REGISTER ? i18n.t('register.alreadyregister') : i18n.t('login.donthaveaccount');
    }

    public get NoAccountText(): string {
        return this.mode === CONNECTION_MODE.REGISTER ? i18n.t('login.text') : i18n.t('register.text');
    }

    public get StatAlready(): string {
        return i18next.t('stat.already');
    }


    public get StatListTrendex(): IListCardInformation[] {
        return Application.getInstance().StatListTrendex;
    }

    public get RegisterStep(): IRegisterStep[] {
        return Application.getInstance().getRegisterStepBySport(this.landingPageVersion);
    }

    public get CrispUrl(): string {
        return i18n.t('crisp.url');
    }

    public get ShowReCaptcha(): boolean {
        return this.showReCaptcha;
    }

    public get IsRegister(): boolean {
        return this.Mode === CONNECTION_MODE.REGISTER;
    }

    public get IsLogin(): boolean {
        return this.Mode === CONNECTION_MODE.LOGIN;
    }

    public get IsRegisterAsVip(): boolean {
        return Boolean(localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.VIP_MODE));
    }

    public get CurrentLanguage(): string {
        return this.currentLanguage;
    }
}
