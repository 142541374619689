import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import * as AssetScoreStatisticsActions from './actionType';
import { IAssetScoreStatistics, ISportStatisticsStore } from './types';

const ROOT_VALUE_STATE: ReduxRootState<ISportStatisticsStore> = {
    loading: false,
    data: {},
    error: '',
};

export const assetScoreStatisticsReducer = (state: ReduxRootState<ISportStatisticsStore> = ROOT_VALUE_STATE, action: ReduxActionInterface<IAssetScoreStatistics>) => {
    switch (action.type) {
        case AssetScoreStatisticsActions.GET_ASSET_STATISTICS_SUCCESS:
            if (!action.payload.data || !action.payload.data._id)
                return {
                    ...state,
                    loading: false,
                }
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    [action.payload.data._id]: action.payload.data,
                }
            };
        case AssetScoreStatisticsActions.GET_ASSET_STATISTICS_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}