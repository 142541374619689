import type { RootState } from 'model/Redux';
import { createSelector, Selector } from 'reselect';

export const topbarColorSelector: Selector<RootState, string> = state => state.topBar.data.color;

export const selectTopBarColor = createSelector(
    topbarColorSelector,
    (color: string) => {
        return color;
    },
);