import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import './ChristmasBonus.scss';

const ChristmasBonus: React.FC<WithTranslation> = ({t: translate}) => {
    return (
        <div className="christmas-bonus">
            <div>🎄</div>
            <div className='sentence-christmas-bonus'><span className='title-christmas-bonus'>{translate('custom-profil.chirstmas-bonus.title')}</span> {translate('custom-profil.chirstmas-bonus.content.1')} <span className='gift-christmas-bonus'>{translate('custom-profil.chirstmas-bonus.bonus')}</span> {translate('custom-profil.chirstmas-bonus.content.2')}</div>
            <div>🎄</div>
        </div>
    );
};

export default withTranslation()(ChristmasBonus);
