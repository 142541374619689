import IAchievementParams from './IAchievementParams';
import Translation from './Translation';

export default class Achievement {
    private title: Translation;
    private subtitle: Translation;
    private redirectLink: string;

    constructor({ redirectLink, subtitle, title }: IAchievementParams) {
        this.title = title;
        this.subtitle = subtitle;
        this.redirectLink = redirectLink;
    }

    public get Title(): Translation {
        return this.title;
    }

    public get Subtitle(): Translation {
        return this.subtitle;
    }

    public get RedirectLink(): string {
        return this.redirectLink;
    }
} 