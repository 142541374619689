import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRANSACTIONS_ENDPOINT } from './../../config/endpoints';

import { HTTP_STATUS_OK } from '../../config/_const';
import AxiosConfig from '../../config/axiosConfig';
import { IHttpResponse, IHttpStrongTypedResponse } from '../../model/IHttpResponse';
import TransactionInterface from '../../model/User/TransactionInterface';
import * as TransactionActionType from './actionsType';

export const fetchAllTransactions = (pageIndex: number): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TransactionActionType.TRANSACTION_LOADING });
        const response: IHttpResponse = await AxiosConfig.get(`${TRANSACTIONS_ENDPOINT.ALL_TRANSACTIONS}/${pageIndex}`);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK)
            return dispatch({ type: TransactionActionType.TRANSACTION_ERROR });
        return dispatch({
            type: TransactionActionType.TRANSACTION_SUCCESS,
            payload: {
                data: response.data.data.transactions,
                message: response.data.message,
            },
        });
    } catch (error) {
        console.log('fetchAllTransactions: ', error);
        return dispatch({
            type: TransactionActionType.TRANSACTION_ERROR,
        });
    }
};

export const fetchReferralTransactions = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TransactionActionType.TRANSACTION_LOADING });
        const response: IHttpStrongTypedResponse<TransactionInterface[]> = await AxiosConfig.get(TRANSACTIONS_ENDPOINT.REFERRAL);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK)
            return dispatch({ type: TransactionActionType.TRANSACTION_ERROR });

        return dispatch({
            type: TransactionActionType.TRANSACTION_SUCCESS,
            payload: {
                data: response.data.data,
                message: response.data.message,
            },
        });

    } catch (error) {
        console.log('fetchReferralTransactions: ', error);
        return dispatch({ type: TransactionActionType.TRANSACTION_ERROR });
    }
};