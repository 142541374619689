import ReduxActionInterface from '../../model/ReduxActionInterface';
import ReduxRootState from '../../model/Redux/ReduxRootSate';

import * as UserPerksActionType from './actionsType';
import { RESET_STATE } from '../commonActionTypes';

const ROOT_VALUE_STATE: ReduxRootState = {
    loading: false,
    data: [],

};

export const userPerksReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {
        case UserPerksActionType.USERPERKS_LOADING:
            return {
                ...state,
                loading: true,
            };

        case UserPerksActionType.USERPERKS_ERROR:

            return {
                ...state,
                loading: false,
            };

        case UserPerksActionType.USERPERKS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
            };
        case RESET_STATE:
            return ROOT_VALUE_STATE;
        default: return state;
    }
};
