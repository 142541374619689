import i18n from "18n";
import * as Sentry from "@sentry/react";
import Application from "Application";
import { ADMINISTRATOR_ID, LAUNCH_MODE, LIMIT_ORDER_ACCEPTANCE_RANGE, MAX_ASSET_TRANSACTION_TOKENS, ONE, ORDER_TYPE, PRIMARY_MARKET_FEES_FIXED, PRIMARY_MARKET_FEES_RATE, TOKEN_INFORMATION_ITEMS, ZERO } from "config/_const";
import { AssetCurveType, AssetTransactionType, DefaultNumberOfTokens, MarketEnum, PaymentMethodsEnum, STATE_SCOPE } from "config/_enums";
import ICreditsPaymentState from "hooks/payment/ICreditsPaymentState";
import ILiquidityPool from "interfaces/liquidity-pool/ILiquidityPool";
import AssetWalletInterface from "model/AssetModel/AssetWalletInterface";
import IBuyData from "model/Payment/IBuyData";
import IBuyShresFromBestSellingOrers from "model/Payment/IBuySharesFromBestSellingOrders";
import IAssetCurrentInformationData from "service/asset-current-information/IAssetCurrentInformationData";
import Utils from "utils/Utils";
import LiquidityPoolFactory from "utils/liquidity-pool/LiquidityPoolFactory";
import IAssetTransactionViewModel from "view-model/AssetTransactionViewModel/IAssetTransactionViewModel";
import AssetViewModel from "view-model/Assets/AssetViewModel";
import IBuyAssetViewModelBuilder from "view-model/BuyAssetViewModel/IBuyAssetViewModelBuilder";
import { IArgsOfTokenObject } from "./interface";

export default class BuyAssetViewModel implements IAssetTransactionViewModel {
    private asset: AssetViewModel;
    private numberOfShares: number;
    private currency: string;
    private _availableCredits: number;
    private assetCurrentInformation: IAssetCurrentInformationData;
    private _holdingAsset?: AssetWalletInterface;
    private isSuperAdmin: boolean;
    private userId: string;
    private initialSalePeriodIsDone: boolean;
    private orderType: string;
    private userPrice: number;
    private userInputAmount: number;
    private fromPack: boolean;
    private credits: number;
    private virtualCredits: number;
    private liquidityPool: ILiquidityPool;

    constructor({ asset, numberOfShares, availableCredits, assetCurrentInformation, walletAsset, userId, currency, userAmount, fromPack, credits, virtualCredits }: IBuyAssetViewModelBuilder) {
        this.asset = new AssetViewModel(asset);
        this.numberOfShares = numberOfShares ?? 1;
        this._availableCredits = this.asset.IsBuyableOnlyWithCredits ? credits : availableCredits;
        this.fromPack = fromPack ?? false;
        if (!assetCurrentInformation || typeof assetCurrentInformation.availableSupply === 'undefined' && this.asset.IsIcoOpen) {
            Sentry.captureException(new Error('BuyAssetViewModel::assetCurrentInformation or availableSupply is undefined (Not present on redux)'), {
                extra: {
                    assetId: this.Asset.AssetId,
                    isIcoOpen: this.Asset.IsIcoOpen,
                    availableSupply: assetCurrentInformation?.availableSupply ?? 'N/A',
                    secondaryMarketSupply: assetCurrentInformation?.secondaryMarketSupply ?? 'N/A',
                    currentValue: assetCurrentInformation?.currentValue ?? 'N/A',
                }
            });
        }
        this.assetCurrentInformation = assetCurrentInformation && Utils.removeUndefinedAndNullFromObject(assetCurrentInformation);
        this._holdingAsset = walletAsset;
        this.isSuperAdmin = userId === ADMINISTRATOR_ID;
        this.currency = currency;
        this.userId = userId;
        this.initialSalePeriodIsDone = assetCurrentInformation.initialSalePeriodIsDone;
        this.orderType = this.IsLiquidityPoolApplicable ? ORDER_TYPE.LIQUIDITY_POOL : ORDER_TYPE.MARKET;
        this.userInputAmount = userAmount;
        this.userPrice = this.getUserPriceFromUserAmount(userAmount);
        this.credits = credits;
        this.virtualCredits = virtualCredits;
        const DynamicLiquidityPool: any = LiquidityPoolFactory.getLiquidityPoolByType<any>(asset?.assetCurveConfiguration.curveType ?? AssetCurveType.DEFAULT);
        this.liquidityPool = new DynamicLiquidityPool({ liquidityPoolValue: assetCurrentInformation.liquidityPoolValue, liquidityPoolSupply: assetCurrentInformation.liquidityPoolSupply, initialTokens: asset.liquidityPoolInitialSupply, assetCurveConfiguration: asset.assetCurveConfiguration });
    }
    FeesCautionMessage?: string | undefined;
    IsPoolApplicableAndEqualSupply?: boolean | undefined;
    IsPoolOutOfStock?: boolean | undefined;

    public get IsLiquidityPoolApplicable() {
        return this.Asset.MarketConfiguration.secondaryMarket.launchMode === LAUNCH_MODE.LIQUIDITY_POOL;
    }
    private getUserPriceFromUserAmount(amount: number) {
        return amount.divide(this.numberOfShares || 1);
    }

    public get InitialSalePeriodIsDone() {
        return this.initialSalePeriodIsDone;
    }

    public get IsSuperAdmin(): boolean {
        return this.isSuperAdmin;
    }

    public get TransactionType(): AssetTransactionType {
        return AssetTransactionType.BUY;
    }

    public get Asset(): AssetViewModel {
        return this.asset;
    }

    public get IsMarketOpen() {
        return this.asset.InitialSaleDone;
    }

    private get WithinFakePrimaryMarket() {
        const skipPrimaryMarketEnabled = Application.getInstance().SkipPrimaryMarket;
        return !skipPrimaryMarketEnabled || this.IsLiquidityPoolApplicable ? false : !this.initialSalePeriodIsDone;
    }

    public get IsIcoOpen() {
        if (!this.asset)
            return false;
        if (this.asset.IsIcoOpen || this.Asset.isInPrePrivateSaleState())
            return true;
        return this.WithinFakePrimaryMarket ? false : !this.asset.InitialSaleDone;
    }

    public get Market() {
        if (!this.asset.InitialSaleDateHasPassed && (this.asset.PackEnabled || this.Asset.IsWhiteListedPurchaseOpen))
            return MarketEnum.SECONDARY;
        return this.asset.InitialSaleDone ? MarketEnum.SECONDARY : MarketEnum.PRIMARY;
    }

    public get TotalPrice() {
        return this.IsPriceSelectorEnabled && !this.IsLiquidityPoolApplicable ? this.numberOfShares.multiply(this.userPrice) : this.getMarketValueWithoutFees();
    }

    private getMarketValueWithoutFees() {
        if (this.IsLiquidityPoolApplicable) return this.liquidityPool.getBuyPrice(this.numberOfShares);
        return this.numberOfShares.multiply(this.CurrentValue);
    }

    public get MarketValue() {
        const currentMarketValue = this.getMarketValueWithoutFees();
        return currentMarketValue.addition(this.getFees(currentMarketValue));
    }

    public get TotalPriceFormatted() {
        return this.TotalPrice.toCurrency();
    }

    public get TotalTransactionFormatted() {
        return this.Amount.toCurrency();
    }

    public get NumberOfToken() {
        return this.numberOfShares;
    }

    public get Currency() {
        return this.currency;
    }

    public get TotalFees() {
        return this.getFees(this.TotalPrice);
    }

    private getFees(value: number) {
        if (!this.isFeesEligible())
            return ZERO;
        if (!this.numberOfShares) return ZERO;
        if (this.IsLiquidityPoolApplicable) {
            if (!this.Asset.IsFunCategory)
                return ZERO;
            return (value * (this.Asset.LiquidityPoolBuyFeesRatio || 0));
        }
        if (this.WithinFakePrimaryMarket)
            return (value * PRIMARY_MARKET_FEES_RATE) + PRIMARY_MARKET_FEES_FIXED;
        return this.IsIcoOpen ? (this.asset.FeesRate || 0) * value + (this.asset.FeesFixedAmount || 0) : 0;
    }

    public get TotalFeesFormatted() {
        return this.TotalFees.toCurrency();
    }

    public get Amount() {
        return this.numberOfShares ? (this.TotalPrice + this.TotalFees) : 0;
    }

    public get AmountWithoutFees() {
        return this.numberOfShares ? this.TotalPrice : 0;
    }

    public get AmountFormatted() {
        return this.Amount.toCurrency();
    }

    public get CreditPaymentState(): ICreditsPaymentState {
        return { assetId: this.asset.AssetId, isDirectBuy: this.Market === MarketEnum.PRIMARY, stateScope: STATE_SCOPE.PAYMENT };
    }

    public get BuyByCreditData(): IBuyData {
        return { market: this.Market, amountOfShares: this.numberOfShares, isDirectBuy: this.Market === MarketEnum.PRIMARY, assetId: this.asset.AssetId };
    }

    public get AssetId(): string {
        return this.asset.AssetId;
    }

    public get HeaderLabel(): string {
        return i18n.t(this.asset.enableCustomAssetProfil() ? "benzema.buyAsset.tokens.get" : "buyAsset.tokens.get", { name: this.asset.AssetName });
    }

    public get TotalLabel(): string {
        return i18n.t("buyAsset.tokens.value");
    }

    public get AvailableFieldFormatted(): string {
        return this._availableCredits.toCurrency();
    }

    public get AvailableCreditsText(): string {
        return i18n.t("available.credit.value", { credits: this.AvailableFieldFormatted });
    }

    public get AvailableSupply(): number {
        if (this.IsLiquidityPoolApplicable) return this.assetCurrentInformation.liquidityPoolSupply;
        const supply = this.SelectedAssetInfo.secondaryMarketSupply.capMinToZero();
        const supplyByStage = AssetViewModel.getSupplyByStage(this.Asset, supply);
        return supplyByStage;
    }

    public get AdjustedAvailableSupply(): number {
        return this.AvailableSupply;
    }

    public get AssetTransactionSign(): string {
        return "+";
    }

    public get CurrentValue(): number {
        return Number(this.SelectedAssetInfo.currentValue.toFixed(2));
    }

    public get IsReadyForConfirmScreen(): boolean {
        if (this.IsUserAmountLowWarning || this.IsUserAmountHighWarning || !this.IsReady) return false;

        if (Boolean(this.numberOfShares) && !this.numberOfShares.isSmallerThan(ONE)) return this.IsSupplySufficient;

        return false;
    }

    public get IsReady(): boolean {
        return Boolean(this.numberOfShares) && !this.numberOfShares.isSmallerThan(ONE) && (this.numberOfShares <= this.AvailableSupply);
    }

    public get IsSupplyExceeded(): boolean {
        return this.AvailableSupply <= this.numberOfShares;
    }

    public get TransactionErrorMessage(): string {
        return i18n.t("buyAsset.failedToBuy");
    }

    public get AvailableCredits(): number {
        return this._availableCredits;
    }

    public get IsLowAvailableCredits(): boolean {
        return this.Amount > this._availableCredits;
    }

    public get AssetName(): string {
        return this.asset.AssetName;
    }

    public get HoldingAssetCount(): number {
        const assetWallet: AssetWalletInterface | undefined = this._holdingAsset;
        if (!assetWallet)
            return 0;
        return assetWallet.amount.addition(assetWallet.frozenAmount) ?? 0;
    }

    public get PaymentBestSellingOrderData(): IBuyShresFromBestSellingOrers {
        return {
            assetId: this.asset.AssetId,
            numberOfShares: this.numberOfShares,
            unitaryPrice: this.Asset.LaunchMode === LAUNCH_MODE.LIQUIDITY_POOL ? this.UnitPriceFromPool : this.CurrentValue,
            userId: this.userId,
            type: this.OrderType
        };
    }

    public get MaxAllowedPurchase() {
        if (Application.getInstance().SkipPrimaryMarket && this.Asset.TokensLimitOnSecondaryMarketStart)
            return this.Asset.TokensLimitOnSecondaryMarketStart;
        if (this.isSuperAdmin)
            return this.AvailableSupply;
        if (this.IsSecondaryMarket || !Application.getInstance().enablePurchaseLimit)
            return MAX_ASSET_TRANSACTION_TOKENS;
        return this.getInitialSupplyRatio();
    }

    public get MaxAllowedTransaction(): number {
        if (this.isSuperAdmin)
            return this.AvailableSupply;
        if (this.Asset.PackEnabled || this.FromPack)
            return Application.getInstance().PacksConfiguration.tokens;
        const fakePrimaryMarket = Application.getInstance().SkipPrimaryMarket && this.Asset.TokensLimitOnSecondaryMarketStart;
        if ((this.IsSecondaryMarket || !Application.getInstance().enablePurchaseLimit) || fakePrimaryMarket) {
            if ((this.IsSecondaryMarket && this.Asset.isSecondaryMarketRestrictionEnabled) || (fakePrimaryMarket && this.Asset.isSecondaryMarketRestrictionEnabled)) {
                const limitBuyByUser: number = this.Asset.TokensLimitOnSecondaryMarketStart.subtract(this.HoldingAssetCount);
                return Math.max(limitBuyByUser, 0);
            }
            return MAX_ASSET_TRANSACTION_TOKENS;
        }
        const maxAvailable: number = this.getInitialSupplyRatio().subtract(this.HoldingAssetCount);
        return Math.max(maxAvailable, 0);
    }

    public get IsSecondaryMarket(): boolean {
        return this.Market === MarketEnum.SECONDARY;
    }

    public get IsMaxAllowedTransactionWarning(): boolean {
        if (Application.getInstance().SkipPrimaryMarket)
            return false;
        return this.NumberOfToken === this.MaxAllowedTransaction && this.Market === MarketEnum.PRIMARY && !this.IsSupplyExceeded;
    }

    public get MaxAllowedCautionMessage(): string {
        return `${i18n.t("assetProfilPage.maxBuyPerUser", { tokens: this.MaxAllowedPurchase })} ${this.HoldingAssetCount ? i18n.t("assetProfilPage.maxBuyPerUser.tokensOwned", { tokens: this.HoldingAssetCount }) : ""}`;
    }

    public get MaxAllowedBuyToken(): number {
        return this.MaxAllowedTransaction < this.AvailableSupply ? this.MaxAllowedTransaction : this.AvailableSupply - 1;
    }

    public get MaxBuyTokenWithAvailableCredit(): number {
        if (this.isSuperAdmin)
            return this.AvailableSupply;
        const args: IArgsOfTokenObject = {
            pricePerToken: this.UnitaryPrice,
            feesRatePerToken: !this.WithinFakePrimaryMarket ? (this.asset.FeesRate || ZERO) : PRIMARY_MARKET_FEES_RATE,
            extraFee: !this.WithinFakePrimaryMarket ? (this.asset.FeesFixedAmount || ZERO) : PRIMARY_MARKET_FEES_FIXED,
            availableToken: this.MaxAllowedBuyToken,
            availableCredit: this._availableCredits
        };
        // TODO : review this method which doesn't work well (probably because of fees)
        return (this.MaxAllowedBuyToken > ZERO) ? Utils.getMaxTokenWithAvailableCredit(args) : ZERO;
    }
    private getInitialSupplyRatio(): number {
        return Math.floor(Application.getInstance().maxBuyPerUserRatio * this.Asset.InitialSupply);
    }
    public get IsMaxBuyTokenFromCreditAndInputTokenSame(): boolean {
        return (this.numberOfShares > ZERO)
            ? this.MaxBuyTokenWithAvailableCredit === this.numberOfShares
            : false;
    }

    private get SelectedAssetInfo(): Omit<IAssetCurrentInformationData, "fetchedAt" | "liquidityPoolSupply" | "liquidityPoolValue"> {
        const defaultAssetInformation: Omit<IAssetCurrentInformationData, "fetchedAt" | "liquidityPoolSupply" | "liquidityPoolValue"> = {
            availableSupply: this.asset.AvailableSupply,
            secondaryMarketSupply: this.asset.SecondaryMarketSupply,
            currentValue: this.asset.CurrentValueAsNumber,
            priceHistory: [],
            liquidityPoolApplicable: false,
            initialSalePeriodIsDone: false,
            mainCategory: this.asset.MainCategoryId
        };
        return !this.isCurrentAssetInformationApplicable() ? defaultAssetInformation : {
            ...defaultAssetInformation,
            ...this.assetCurrentInformation,
            availableSupply: this.assetCurrentInformation.availableSupply ?? this.asset.AvailableSupply,
        };
    }

    private isCurrentAssetInformationApplicable(): boolean {
        return Boolean(this.assetCurrentInformation);
    }

    public get BtnContinueText(): string {
        return i18n.t('initPayment.buyshares');
    }

    public get FeeIncludedText(): string {
        return i18n.t('fees.included', { feesAmount: this.TotalFeesFormatted });
    }

    public get isAssetOwnerSelf(): boolean {
        return this.userId === this.AssetId;
    }

    private get IsSupplySufficient(): boolean {
        return this.AvailableSupply >= this.NumberOfToken;
    }

    public get UnitaryPrice(): number {
        if (this.Asset.LaunchMode === LAUNCH_MODE.LIQUIDITY_POOL)
            return this.UnitPriceFromPool;
        return Number(this.TotalPrice.divide(this.numberOfShares).toFixed(3));
    }

    public get UnitPriceFromPoolFormatted(): string {
        return this.Amount.divide(this.numberOfShares).toCurrency();
    }

    private get UnitPriceFromPool(): number {
        return this.AmountWithoutFees.safeDivideBy(this.numberOfShares);
    }

    public get TokenInformationHeaderDisplayItems(): string[] {
        return [TOKEN_INFORMATION_ITEMS.PRICE, TOKEN_INFORMATION_ITEMS.SUPPLY];
    }

    public get OrderType() {
        return this.orderType;
    }

    public get IsPriceSelectorEnabled(): boolean {
        return !this.IsImmediateSettlement;
    }

    public get IsImmediateSettlement(): boolean {
        return this.orderType === ORDER_TYPE.MARKET;
    }

    public get UserInputAmount(): number {
        return this.userInputAmount;
    }

    public get IsUserAmountEditable(): boolean {
        return !this.IsImmediateSettlement;
    }

    public get DisplayAmount(): number {
        return this.IsUserAmountEditable ? this.UserInputAmount : this.Amount;
    }

    public get EligiblePriceRange(): { max: number, min: number; } {
        const valueWithoutFees = this.getMarketValueWithoutFees();
        const minAcceptedValue = valueWithoutFees.subtract(valueWithoutFees.multiply(LIMIT_ORDER_ACCEPTANCE_RANGE));
        const minAcceptedValueWithFee = minAcceptedValue.addition(this.getFees(minAcceptedValue));
        const maxValueWithFees = valueWithoutFees.addition(this.getFees(valueWithoutFees));
        return { max: maxValueWithFees, min: minAcceptedValueWithFee };
    }

    public get EligiblePriceRangeInCurrencyValue() {
        return {
            max: Number.isFinite(this.EligiblePriceRange.max) ? this.EligiblePriceRange.max.toCurrencyValue() : this.EligiblePriceRange.max,
            min: Number.isFinite(this.EligiblePriceRange.min) ? this.EligiblePriceRange.min.toCurrencyValue() : this.EligiblePriceRange.min
        };
    }

    public get IsUserAmountLowWarning(): boolean {
        return this.IsPriceSelectorEnabled && this.userInputAmount < this.EligiblePriceRange.min;
    }

    public get IsUserAmountHighWarning(): boolean {
        return this.IsPriceSelectorEnabled && this.userInputAmount > this.EligiblePriceRange.max;
    }

    public get MarketValueInUserCurrencyValue(): string {
        return this.MarketValue.toCurrencyValue().toString();
    }

    private isFeesEligible(): boolean {
        return (!this.Asset.PackEnabled && !this.Asset.IsWhiteListedPurchaseOpen) || this.Asset.IsFunCategory;
    }

    public get FromPack(): boolean {
        return this.fromPack;
    }

    public get DefaultAllowedTransaction(): number {
        const tokensInPack = Application.getInstance().PacksConfiguration.tokens;
        if (this.FromPack && this.AvailableSupply >= tokensInPack)
            return tokensInPack;
        return this.AvailableSupply > DefaultNumberOfTokens.BUY ? DefaultNumberOfTokens.BUY : this.AdjustedAvailableSupply;
    }

    public get EligiblePriceRangeAsCurrency() {
        return {
            max: this.EligiblePriceRangeInCurrencyValue.max.asCurrency(),
            min: this.EligiblePriceRangeInCurrencyValue.min.asCurrency()
        };
    }

    public get NumberOfTokenToString(): string {
        return this.numberOfShares.toString();
    }

    public get Credits(): number {
        return this.credits;
    }

    public get VirtualCredits(): number {
        return this.virtualCredits;
    }

    public get IsVirtualCreditEmpty(): boolean {
        return this.virtualCredits <= ZERO;
    }

    private AmountToBePaidOnBuy(): { amountInCredit: number, amountInGift: number; } {
        if (this.asset.IsBuyableOnlyWithCredits)
            return {
                amountInCredit: this.UserInputAmount,
                amountInGift: 0
            };
        const hasAmountInGiftExceeded = this.VirtualCredits.subtract(this.UserInputAmount) < ZERO;

        if (hasAmountInGiftExceeded) {
            return {
                amountInCredit: this.UserInputAmount.subtract(this.virtualCredits),
                amountInGift: this.VirtualCredits
            };
        }

        return {
            amountInCredit: Number(0),
            amountInGift: this.UserInputAmount
        };
    }

    public get AmountToBePaidOnBuyFormatted(): { amountInCredit: string, amountInGift: string; } {
        const hasAmountInGiftExceeded = this.VirtualCredits.subtract(this.UserInputAmount) < ZERO;

        if (hasAmountInGiftExceeded) {
            return {
                amountInCredit: this.UserInputAmount.subtract(this.virtualCredits).toCurrency(2),
                amountInGift: this.VirtualCredits.toCurrency(2)
            };
        }

        return {
            amountInCredit: Number(0).toCurrency(2),
            amountInGift: this.UserInputAmount.toCurrency(2)
        };
    }

    public get AmountPaidInVirtualCredit() {
        return this.AmountToBePaidOnBuy().amountInGift;
    }

    public get DefaultDisplayPrice(): string {
        return this.MarketValueInUserCurrencyValue;
    }

    public ShowRewardDebt(paymentType: PaymentMethodsEnum): boolean {
        return !this.IsVirtualCreditEmpty && paymentType === PaymentMethodsEnum.CREDITS && Application.getInstance().IsRewardDebtEnabled;
    }

}