import TokenInformation from 'components/medium/token-information/TokenInformation';
import * as React from 'react';
import './TxnAssetHeader.scss';
import TxnAssetHeaderProps from './TxnAssetHeaderProps';
import PackVipCard from 'components/small/pack-vip-card/PackVipCard';



const TxnAssetHeader: React.FC<TxnAssetHeaderProps> = ({ assetTransactionViewModel, renderDetailSubComponent }) => {
    const showPackVipCard = React.useMemo(() => {
        return assetTransactionViewModel.Asset.PackEnabled || assetTransactionViewModel.FromPack;
    }, [assetTransactionViewModel]);

    return <div className="txn-buy-asset-header mobile-view">
        <div className="transaction-buy-asset-header_title">
            <TokenInformation
                asset={assetTransactionViewModel.Asset}
                availableSupply={assetTransactionViewModel.AvailableSupply}
                tokenPrice={assetTransactionViewModel.CurrentValue}
                totalTokensInCirculation={assetTransactionViewModel.Asset.InitialSupply}
                displayItems={assetTransactionViewModel.TokenInformationHeaderDisplayItems}
            />
        </div>
        {showPackVipCard && <div className="pack-vip-container"><PackVipCard assetName={assetTransactionViewModel.Asset.AssetName} /></div>}
        {renderDetailSubComponent()}
    </div>
};

export default TxnAssetHeader;
