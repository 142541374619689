import React from 'react'
import './RightSection.scss'
import TrendexAcademy from '../trendex-academy/TrendexAcademy';
import withAdminView from 'hoc/with-admin-view/withAdminView';

const RightSection: React.FC = () => {
    return (
        <section className="right_section">
            <div className="right_section-container">
                <TrendexAcademy />
            </div>
        </section>
    )
}

export default withAdminView(RightSection);