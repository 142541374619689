import React, { MouseEvent } from 'react';
import { IOverrideButtonProps } from '../IButtonProps';
import './LinkButton.scss';
import HandleClick from 'components/small/buttons/white-button/ClickHandler';
import clsx from 'clsx';

const LinkButton: React.FC<IOverrideButtonProps> = (props) => {

    return <button
        className={clsx("link-button",
            {[`${props.className}`]: Boolean(props.className)}
        )}
        onClick={(event: MouseEvent<HTMLButtonElement>) => HandleClick(event, props.debounceEvent, props.preventDefaultEvent, props.handleClick)}
    >
        {props.textButton}
    </button>;
};

export default React.memo(LinkButton);
