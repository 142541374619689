import { IStoreOrder } from 'model/IOrder';
import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import Utils from '../../utils/Utils';
import * as ActionTypesAsset from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<IStoreOrder[]> = {
    loading: false,
    data: [],
    pollingId: null,
    polling: false,
};

export const orderReducer = (state: ReduxRootState = ROOT_VALUE_STATE, action: ReduxActionInterface) => {

    switch (action.type) {
        case ActionTypesAsset.ORDER_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ActionTypesAsset.ORDER_ERROR:

            return {
                ...state,
                error: action.payload?.error ?? 'Error',
                loading: false,
            };

        case ActionTypesAsset.ORDER_SUCCESS:
            const stateData = state.data.length > 0 ? Utils.removedDuplicateMongoFile(state.data, action.payload.orders) : [];
            return {
                ...state,
                data: action.payload.concat ? stateData.concat(action.payload.orders) : action.payload.orders,
                loading: false,
            };
        case ActionTypesAsset.ORDER_RESET:
            return {
                ...state,
                data: [],
                loading: false,
            };
        case ActionTypesAsset.START_POLLING_ORDERS:
            if (state.pollingId) {
                clearInterval(state.pollingId);
            }
            return {
                ...state,
                polling: true,
                pollingId: setInterval(action.payload.fetch, action.payload.interval),
            };
        case ActionTypesAsset.STOP_POLLING_ORDERS:
            if (state.pollingId) {
                clearInterval(state.pollingId);
            }
            return {
                ...state,
                polling: false,
                pollingId: null,
            };
        default: return state;
    }
};
