import AssetWalletInterface from 'model/AssetModel/AssetWalletInterface';
import React from 'react';
import { groupAssetsByLevel } from 'service/wallet/helper';
import { GroupAssetWalletByLevel } from 'service/wallet/types';
import IInjectedAssetsSelection from './IInjectedAssetsSelection';
import IWithAssetsSelectionProps from './IWithAssetsSelectionProps';
import { DEFAULT_ID_AS_STRING, TOURNAMENT_CATEGORY } from 'config/_const';
import TournamentViewModel from 'view-model/Tournament/TournamentViewModel/TournamentViewModel';

declare type IWithAssetsSelectionParams<BaseProps,> = IWithAssetsSelectionProps & BaseProps

const withAssetsSelection = <BaseProps,>(Component: React.FC<BaseProps & IInjectedAssetsSelection>) => {
    const MemoComponent = React.memo(Component);

    const Wrapper: React.FC<IWithAssetsSelectionParams<BaseProps>> = (props: IWithAssetsSelectionParams<BaseProps>) => {
        const { teamBuildingViewModel, walletViewModel, currentTab, hideAssetsIfAmountIsZero, nextTournament, tournamentInProgress }: IWithAssetsSelectionParams<BaseProps> = props;
        const currentTournament: TournamentViewModel = React.useMemo(() => {
            if (tournamentInProgress.Id !== DEFAULT_ID_AS_STRING)
                return tournamentInProgress;
            return nextTournament;
        }, [nextTournament, tournamentInProgress]);

        const userAssets: GroupAssetWalletByLevel = React.useMemo(() => {
            const walletWithoutSharesInTeams: AssetWalletInterface[] = walletViewModel.getAssetsViewModelsWithoutAssetsInTeam(teamBuildingViewModel.SharesInLocalTeams, hideAssetsIfAmountIsZero);
            const sortedByScore: AssetWalletInterface[] = walletWithoutSharesInTeams.sort((entryA: AssetWalletInterface, entryB: AssetWalletInterface) => {
                const currentTournamentCategory: string = currentTournament.Category;
                if (currentTournamentCategory !== TOURNAMENT_CATEGORY.DEFAULT)
                    return entryA.asset.sportsScore > entryB.asset.sportsScore ? -1 : 1
                return entryA.asset.IRLScore > entryB.asset.IRLScore ? -1 : 1
            });
            const groupedAssets: GroupAssetWalletByLevel = groupAssetsByLevel(sortedByScore.filter((assetWallet: AssetWalletInterface) => currentTab === 'all' ? true : assetWallet.asset.mainCategory === currentTab));
            return groupedAssets;
        }, [currentTab, currentTournament.Category, hideAssetsIfAmountIsZero, teamBuildingViewModel.SharesInLocalTeams, walletViewModel]);

        return <MemoComponent {...props as BaseProps} userAssets={userAssets} />;
    };

    return React.memo(Wrapper);
};

export default withAssetsSelection;
