import ListCard from "components/small/list-card/ListCard";
import withUserLeagueAttribution from "hoc/with-user-league-attribution/withUserLeagueAttribution";
import React from "react";
import { compose } from "redux";
import './TeamAssetScoreDetails.scss';
import TeamAssetScoreDetailsExtraProps from "./TeamAssetScoreDetailsExtraProps";
import ITeamAssetScoreDetailsProps from "./TeamAssetScoreDetailsProps";
import withTeamAssetScoreDetails from "hoc/with-team-asset-score-details/withTeamAssetScoreDetails";

const TeamAssetScoreDetails: React.FC<ITeamAssetScoreDetailsProps> = ({ teamStatistics, filteredStatistic, isCollapsed }) => {

  return (
    <div className="team-asset-score-details">
      {isCollapsed
        ? <ListCard informationsList={filteredStatistic} />
        : <ListCard informationsList={teamStatistics} />
      }
    </div>
  );
};

export default compose<React.FC<TeamAssetScoreDetailsExtraProps>>(
  withUserLeagueAttribution,
  withTeamAssetScoreDetails,
)(TeamAssetScoreDetails);
