import * as React from 'react';
import useFirstDepositAchievement from 'hooks/first-deposit-achievement/useFirstDepositAchievement';
import IUseTransaction from 'hooks/use-transactions/IUseTransaction';
import useTransaction from 'hooks/use-transactions/useTransaction';
import FirstPurchaseBannerFunCards from './FirstPurchaseBannerFunCards';

const WrapperFirstPurchaseBannerFunCards: React.FC = () => {
    const { transactions, financialTransactionsLoading }: IUseTransaction = useTransaction();
    const { firstDepositAchievementVM } = useFirstDepositAchievement(transactions);

    if (financialTransactionsLoading || firstDepositAchievementVM.FirstDepositCompleted)
        return null;

    return <FirstPurchaseBannerFunCards firstDepositAchievementVM={firstDepositAchievementVM} />
};

export default WrapperFirstPurchaseBannerFunCards;
