import React, { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import clsx from "clsx";
import "./see-more.scss";

type Props = {
    seeMoreText?: string,
}

const SeeMoreContainer: React.FC<Props> = ({ children, seeMoreText }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [isOverflowing, setOverflowing] = useState<boolean>(true)

    useEffect(() => {
        const overflowElement = ref.current;
        if (overflowElement) {
            setOverflowing(overflowElement.scrollHeight > overflowElement.offsetHeight);
        }
    }, [window.innerWidth])

    return <>
        <div ref={ref} className={clsx({ 'clamp': isOverflowing })}>
            {children}
        </div>
        {isOverflowing &&
            <div className={'see-more'}
                onClick={() => setOverflowing(!isOverflowing)}>
                {seeMoreText || i18next.t('instructions.cta.see-more')}
            </div>
        }
    </>
}

export default SeeMoreContainer;
