import { FAKE_WITHDRAW_PREFIX, HTTP_STATUS_OK } from 'config/_const';
import AxiosConfig from 'config/axiosConfig';
import { USER_WITHDRAWAL_REQUESTS_ENDPOINTS } from 'config/endpoints';
import { IHttpStrongTypedResponse } from 'model/IHttpResponse';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { showNotification } from 'service/notification/action';
import { GET_CREDITS_SUCCESS } from '../auth/actionsType';
import ICancelWithdrawalRequestResponse from './ICancelWithdrawalRequestResponse';
import IWithdrawalRequest from './IWithdrawalRequest';
import { CANCEL, GET_OPEN } from './actionsType';
import i18n from '18n';

export const getUserWithdrawalRequests = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({
            type: GET_OPEN.LOADING
        });
        const getWithdrawalRequestsResponse: IHttpStrongTypedResponse<IWithdrawalRequest[]> = await AxiosConfig.get(USER_WITHDRAWAL_REQUESTS_ENDPOINTS.GET_OPEN);
        let dispatchData = getWithdrawalRequestsResponse.data.data;
        if (getWithdrawalRequestsResponse.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: GET_OPEN.ERROR,
                payload: {
                    message: getWithdrawalRequestsResponse.data.message
                }
            });
        return dispatch({
            type: GET_OPEN.SUCCESS,
            payload: {
                data: dispatchData
            }
        });
    } catch (e) {
        return dispatch({
            type: GET_OPEN.ERROR,
            payload: {
                message: `Technical error occurs while getting withdrawal requests ${JSON.stringify((e as Error).stack)}`
            }
        });
    }
};

export const cancelUserWithdrawalRequests = (withdrawalRequestId: string, callBackFxn?: () => void): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        if (withdrawalRequestId.startsWith(FAKE_WITHDRAW_PREFIX))
            return dispatch({
                type: CANCEL.SUCCESS,
                payload: {
                    data: {
                        _id: withdrawalRequestId
                    }
                }
            });
        const cancelWithdrawalRequestResponse: IHttpStrongTypedResponse<ICancelWithdrawalRequestResponse> = await AxiosConfig.post(USER_WITHDRAWAL_REQUESTS_ENDPOINTS.CANCEL, {
            withdrawRequestId: withdrawalRequestId
        });
        if (cancelWithdrawalRequestResponse.data.status !== HTTP_STATUS_OK) {
            callBackFxn?.();
            showNotification(dispatch, cancelWithdrawalRequestResponse.data.message, cancelWithdrawalRequestResponse.data.status);
            return dispatch({
                type: GET_OPEN.ERROR,
                payload: {
                    message: cancelWithdrawalRequestResponse.data.message
                }
            });
        }

        dispatch({
            type: GET_CREDITS_SUCCESS,
            payload: cancelWithdrawalRequestResponse.data.data.user
        });

        return dispatch({
            type: CANCEL.SUCCESS,
            payload: {
                data: cancelWithdrawalRequestResponse.data.data.withdraw
            }
        });
    } catch (e) {
        return dispatch({
            type: GET_OPEN.ERROR,
        });
    }
};

export const createWithdrawalRequest = (data: IWithdrawalRequest): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({
            type: GET_OPEN.LOADING,
        });
        showNotification(dispatch, i18n.t('server.withdrawRequestCreated'), 200);
        return dispatch({
            type: GET_OPEN.SUCCESS,
            payload: {
                data: [data]
            }
        });
    } catch (e) {
        return dispatch({
            type: GET_OPEN.ERROR,
            payload: {
                message: `Technical error occurs while creating withdrawal request ${JSON.stringify((e as Error).stack)}`
            }
        });
    }
};