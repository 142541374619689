import { IGetAllAssetTransactionResponse } from './types';
import { IAssetTransaction } from '../../model/AssetTransactions/IAssetTransaction';
import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import { RESET_STATE } from '../commonActionTypes';
import { GET_ASSET_TRANSACTIONS_ERROR, GET_ASSET_TRANSACTIONS_LOADING, GET_ASSET_TRANSACTIONS_SUCCESS } from './actionsType';
import AssetTransactionAdapter from './adapter/AssetTransactionAdapter';

const ROOT_STATE: ReduxRootState<IAssetTransaction[]> = {
    loading: false,
    data: [],
};

export const getAllAssetTransactionsReducer = (state: ReduxRootState<IAssetTransaction[]> = ROOT_STATE, action: ReduxActionInterface) => {
    switch (action.type) {
        case GET_ASSET_TRANSACTIONS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case GET_ASSET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: mapAssetTransaction(action.payload.data)
            };
        case GET_ASSET_TRANSACTIONS_ERROR:
            return {
                ...state,
                error: action.payload.message,
                loading: false,
            };
        case RESET_STATE:
            return ROOT_STATE;
        default: return state;
    }
};

const mapAssetTransaction = (assetTransactions: IGetAllAssetTransactionResponse[]) => {
    if (assetTransactions.length === 0)
        return [];
    return assetTransactions.map((entry: IGetAllAssetTransactionResponse) => new AssetTransactionAdapter(entry));
};