import Application from 'Application';
import TeamOnboarding from 'components/medium/onboarding/TeamOnboarding/TeamOnboarding';
import WithdrawOnboarding from 'components/medium/onboarding/WIthdrawOnboarding/WithdrawOnboarding';
import WalletOnboarding from 'components/medium/onboarding/WalletOnboarding/WalletOnboarding';
import OnboardingLightWalletWrapper from 'components/medium/onboarding/light-wallet/OnboardingLightWallet/OnboardingLightWalletWrapper';
import TournamentOffSection from 'components/medium/tournament/off/tournament-off-section/TournamentOffSection';
import WalletRefund from 'components/medium/wallet-refund/WalletRefund';
import AboutOverview from 'components/small/about-overview/AboutOverview';
import { ONBOARDING_PREFIX_URL } from 'config/_const';
import IInjectedAccount from 'hoc/with-account/IInjectedAccount';
import withAccount from 'hoc/with-account/withAccount';
import AssetRequestProfil from 'page/Admin/OverviewAssetRequest/AssetRequestProfil/AssetRequestProfil';
import OverviewAssetRequest from 'page/Admin/OverviewAssetRequest/OverviewAssetRequest';
import AssetEditPage from 'page/Admin/OverviewAssets/AssetEditPage/AssetEditPage';
import OverviewAssets from 'page/Admin/OverviewAssets/OverviewAssets';
import OverviewUsersPage from 'page/Admin/OverviewUsersPage/OverviewUsersPage';
import OverviewUsersProfilPage from 'page/Admin/OverviewUsersPage/OverviewUsersProfilPage/OverviewUsersProfilPage';
import WithdrawRequestProfil from 'page/Admin/WithdrawRequests/WithdrawRequestProfil/WithdrawRequestProfil';
import WithdrawRequestsPage from 'page/Admin/WithdrawRequests/WithdrawRequestsPage';
import Affiliate from 'page/AffiliatePage/Affiliate';
import ResetPassword from 'page/ResetPassword/ResetPassword';
import OverviewPrivateSell from 'page/Vip/OverviewPrivateSell/OverviewPrivateSell';
import OverviewSales from 'page/Vip/OverviewSales/OverviewSales';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import AdminProtectedRoute from './AdminProtectedRoute';
import ProtectedRoute from './UserAuthenticatedProtectedRoute';
import VipProtectedRoute from './VipProtectedRoute';
import CircularLoader from 'components/small/CircularLoader';
import i18n from '18n';

const Tournament = React.lazy(() => import('page/Tournament/Tournament'));
const AdminTournament = React.lazy(() => import('page/Admin/Tournament/Tournament'));
const Portfolio = React.lazy(() => import('page/Portfolio/v2/Portfolio'));
const AssetProfilPage = React.lazy(() => import('page/AssetProfilPage/AssetProfilPage'));
const RulesPage = React.lazy(() => import('page/RulesPage/RulesPage'));
const BecomeVIP = React.lazy(() => import('page/BecomeVIP/BecomeVIP'));
const TopAssets = React.lazy(() => import('page/Top50/TopAssets'));
const ForgotPassword = React.lazy(() => import('page/ForgotPassword/ForgotPassword'));
const AuthentificationPage = React.lazy(() => import('page/AuthentificationPage/AuthentificationPage'));
const TournamentLeagueSubscriptionsDetails = React.lazy(() => import('components/medium/TournamentLeagueSubscriptionsDetails/TournamentLeagueSubscriptionsDetails'));

interface AppRouterProps extends IInjectedAccount {
}

const AppRouter: React.FC<AppRouterProps> = ({ accountViewModel: user, accountIsLoading }: AppRouterProps) => {
    if (accountIsLoading)
        return <CircularLoader loaderMessage={i18n.t('genericloading')} />;
    return (
        <Switch>
            <Route exact path={'/'} component={TopAssets} />
            <Route key="portfolio" exact path={'/my-portfolio'} component={Portfolio} />
            <Route exact path={'/top-50'} component={TopAssets} />
            <Route key="visit-profil" path={'/asset/:slug/:mode?'} component={AssetProfilPage} />
            <Route exact key="profile" path={'/profile'} component={AboutOverview} />
            <Route exact key="buy-credits" path={'/buy-credits'} component={WalletRefund} />
            <Route exact key="rules" path={'/rules'} component={RulesPage} />
            <Route exact key="become-vip" path={'/become-vip'} component={BecomeVIP} />
            <Route exact key="forgot-password" path={'/forgot-password'} component={ForgotPassword} />
            <Route exact key="signup" path={'/signup/:vipMode?'} component={AuthentificationPage} />
            <Route exact key="sign-in" path={'/sign-in/:vipMode?'} component={AuthentificationPage} />
            <Route exact key="login" path={'/login'} component={AuthentificationPage} />
            <Route exact key="tournament" path={'/tournament'} component={Tournament} />
            <Route exact key='tournament-results' path='/tournament/results/:league' component={TournamentOffSection} />
            <Route exact key='tournament-subscriptions' path='/tournament/subscriptions/:league' component={TournamentLeagueSubscriptionsDetails} />
            <Route exact key="reset-password" path={'/reset-password'} component={ResetPassword} />
            <ProtectedRoute exact key="affiliate" path={'/affiliate'} component={Affiliate} authorizedAccess={user.isAuthenticated && Application.getInstance().showGamblingFeature} />
            <ProtectedRoute authorizedAccess={user.isAuthenticated && user.IsOnboardingRunning} exact key="onboarding-sell" path={`/${ONBOARDING_PREFIX_URL}/sell`} component={WalletOnboarding} />
            <ProtectedRoute authorizedAccess={user.isAuthenticated && user.IsOnboardingRunning} exact key="onboarding-sell/asset" path={`/${ONBOARDING_PREFIX_URL}/sell/asset/:slug`} component={AssetProfilPage} />
            <ProtectedRoute authorizedAccess={user.isAuthenticated && user.IsOnboardingRunning} exact key="onboarding-wallet" path={`/${ONBOARDING_PREFIX_URL}/wallet`} component={OnboardingLightWalletWrapper} />
            <ProtectedRoute authorizedAccess={user.isAuthenticated && user.IsOnboardingRunning} exact key="onboarding-team" path={`/${ONBOARDING_PREFIX_URL}/team`} component={TeamOnboarding} />
            <ProtectedRoute authorizedAccess={user.isAuthenticated && user.IsOnboardingRunning} exact key="onboarding-withdraw" path={`/${ONBOARDING_PREFIX_URL}/withdraw`} component={WithdrawOnboarding} />
            <AdminProtectedRoute key='influencer' exact path={'/influencer'} component={OverviewAssetRequest} isUserAdmin={user.IsUserAdmin} />
            <AdminProtectedRoute key='asset-request' exact path={'/asset-request/:assetRequestId'} component={AssetRequestProfil} isUserAdmin={user.IsUserAdmin} />
            <AdminProtectedRoute key='users' exact path={'/users'} component={OverviewUsersPage} isUserAdmin={user.IsUserAdmin} />
            <AdminProtectedRoute key='users-id' exact path={'/users/:userId'} component={OverviewUsersProfilPage} isUserAdmin={user.IsUserAdmin} />
            <AdminProtectedRoute key='all-assets' exact path={'/assets'} component={OverviewAssets} isUserAdmin={user.IsUserAdmin} />
            <AdminProtectedRoute key='assets-with-slug' exact path={'/assets/:slug'} component={AssetEditPage} isUserAdmin={user.IsUserAdmin} />
            <AdminProtectedRoute key='withdraw-request' exact path={'/withdraw-requests'} component={WithdrawRequestsPage} isUserAdmin={user.IsUserAdmin} />
            <AdminProtectedRoute key='withdraw-request-with-id' exact path={'/withdraw-requests/:withdrawRequetsId'} component={WithdrawRequestProfil} isUserAdmin={user.IsUserAdmin} />
            <AdminProtectedRoute key='admin-tournament' exact path={'/admin-tournament'} component={AdminTournament} isUserAdmin={user.IsUserAdmin} />
            <VipProtectedRoute exact path={'/my-sales'} component={OverviewPrivateSell} isUserVip={user.IsUserVip} />
            <VipProtectedRoute exact path={'/follow-up'} component={OverviewSales} isUserVip={user.IsUserVip} />
        </Switch>
    )
}

export default compose<React.FC>(
    withAccount
)(AppRouter);
