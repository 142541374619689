import { OrderBy } from '../../config/_enums';
import IOrderByConfiguration from '../shared/IOrderByConfiguration';

export default class PredicateSortBuilder<T extends Record<keyof T, any>>  {
    private sortSide: OrderBy;
    private sortKey: keyof T;

    constructor(orderByConfiguration: IOrderByConfiguration<T>) {
        this.sortSide = orderByConfiguration.sortSide;
        this.sortKey = orderByConfiguration.sortKey;
    }

    public build = (aElememt: T, bElement: T): number => {
        const aInput = aElememt[this.SortKey];
        const bInput = bElement[this.SortKey];

        switch (typeof aInput) {
            case 'number': return this.numbersCompare(aInput, bInput);
            case 'string': return this.stringsCompare(aInput, bInput);
            default: throw new Error('[ERROR] PredicateSortBuilder::build -> type used is not supported by the build method');
        }
    }

    private numbersCompare(firstInput: number, secondInput: number) {
        return this.SortSide === OrderBy.ASC ? firstInput - secondInput : secondInput - firstInput;
    }

    private stringsCompare(firstInput: string, secondInput: string) {
        return this.SortSide === OrderBy.ASC ? firstInput.localeCompare(secondInput) : secondInput.localeCompare(firstInput);
    }

    public get SortSide(): OrderBy {
        return this.sortSide;
    }

    public get SortKey(): keyof T {
        return this.sortKey;
    }
}
