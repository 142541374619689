import React from 'react'
import { IAchievementCard } from './AchievementCardProps';
import './AchievementCard.scss'

const AchievementCard = (props: IAchievementCard) => {

    return (
        <div className={`achievement-card ${props.bgColor}`}>
            <h4 className='title'>{props.title}</h4>
            <h5 className='subtitle'>{props.subtitle}</h5>
            {props.bottomComponent
                ? <div className='bottom-component'>{props.bottomComponent}</div>
                : null
            }
        </div>
    )
}

export default AchievementCard;