import ModalReduxStore from './ModalReduxStore';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import * as ModalActionsType from './actionsType';

const ROOT_VALUE_STATE: ModalReduxStore = {
    display: false,
    fullScreen: false,
    showBackArrow: false,
    disableBackDrop: false,
    showModalAtBottom: false,
    showCancelButton: false,
    type: '',
    propsCustom: {},
};

export const modalReducer = (state: ModalReduxStore = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    switch (action.type) {
        case ModalActionsType.MODAL_DISPLAY:
            return {
                ...state,
                ...action.payload.modalObject,
                display: true,
            };

        case ModalActionsType.MODAL_DISMISS:
            return {
                display: false,
            };

        default: return state;
    }
};
