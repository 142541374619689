import React from 'react';
import DividerProps from './DividerProps';
import './Divider.scss';

const Divider: React.FC<DividerProps> = (props) => {
    return (
        <div className="divider" style={{
            background: props.background,
            width: props.size,
            transform: `rotate(${props.orientation}deg)`,
        }}>
        </div>
    );
};

Divider.defaultProps = {
    size: '100%',
    background: 'rgb(21 24 43 / 15%)',
};

export default Divider;
