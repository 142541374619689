import React from 'react';
import CancelButtonProps from "./CancelButtonProps"
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import './CancelButton.scss'

const CancelButton: React.FC<CancelButtonProps> = (props) => {
    return (
        <div className={clsx('cancel-button',
            { "align-right": props.isPositionRight })}
            onClick={props.close}
        >
            <Close className='cross-icon' />
        </div>
    )
};

export default React.memo(CancelButton);