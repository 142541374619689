import * as React from 'react';

const ProofOfIdentity = ({ height = "23px", width = "23px", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg id="passport-duotone" xmlns="http://www.w3.org/2000/svg" height={height} width={width} {...props} viewBox="0 0 27.881 31.864">
            <path id="Tracé_3776" data-name="Tracé 3776" d="M16.5,6.572a13.993,13.993,0,0,1,.884,4.381h2.435A5.984,5.984,0,0,0,16.5,6.572ZM8.066,12.945a5.963,5.963,0,0,0,3.314,4.38,14.26,14.26,0,0,1-.874-4.38ZM23.9,0H3.983A3.983,3.983,0,0,0,0,3.983v23.9a3.983,3.983,0,0,0,3.983,3.983H23.9a4,4,0,0,0,3.983-3.983V3.983A4,4,0,0,0,23.9,0ZM20.911,25.889H6.97a1,1,0,1,1,0-1.991h13.94a1,1,0,1,1,0,1.991Zm-6.97-5.974a7.966,7.966,0,1,1,7.966-7.966A7.961,7.961,0,0,1,13.94,19.915ZM8.066,10.953H10.5a14.134,14.134,0,0,1,.879-4.38A5.991,5.991,0,0,0,8.066,10.953ZM16.5,17.326a5.965,5.965,0,0,0,3.314-4.38H17.377A13.859,13.859,0,0,1,16.5,17.326Zm-2.558.523c.485-.467,1.294-2.147,1.456-4.9H12.478C12.652,15.7,13.461,17.382,13.94,17.849Zm-1.456-6.9H15.4c-.173-2.751-.976-4.431-1.461-4.9C13.461,6.522,12.646,8.2,12.484,10.953Z" fill="#868895" />
            <path id="Tracé_3777" data-name="Tracé 3777" d="M103.966,64a7.966,7.966,0,1,0,7.966,7.966A7.965,7.965,0,0,0,103.966,64Zm5.875,6.97h-2.435a14.134,14.134,0,0,0-.879-4.38A5.976,5.976,0,0,1,109.841,70.97Zm-11.75,1.991h2.435a14.134,14.134,0,0,0,.879,4.38A5.98,5.98,0,0,1,98.091,72.962Zm2.44-1.991h-2.44a5.963,5.963,0,0,1,3.314-4.38A14.26,14.26,0,0,0,100.531,70.97Zm3.435,6.9c-.479-.467-1.288-2.147-1.462-4.9h2.915C105.26,75.719,104.451,77.4,103.966,77.866Zm-1.456-6.9c.162-2.751.977-4.431,1.456-4.9.485.47,1.288,2.15,1.462,4.9Zm4.014,6.373a13.993,13.993,0,0,0,.884-4.381h2.435A5.973,5.973,0,0,1,106.524,77.343Z" transform="translate(-90.026 -60.017)" fill="#868895" opacity="0.4" />
        </svg>

    );
};

export default ProofOfIdentity;