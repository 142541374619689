import ReduxActionInterface from '../../model/ReduxActionInterface';
import ReduxRootState from '../../model/Redux/ReduxRootSate';
import * as TeamsConfigurationActionTypes from './actionTypes';
import Utils from '../../utils/Utils';
import ITeamConfig from '../../model/Team/ITeamConfig';

const ROOT_VALUE_STATE: ReduxRootState<ITeamConfig[]> = {
    loading: false,
    data: [],
};

interface TeamActionPayload {
    teamConfigs: ITeamConfig[];
    teamConfigId: string;
}

const onTeamConfigurationSuccess = (state: ReduxRootState<ITeamConfig[]>, payload: ITeamConfig[]) => {
    if (state.data.isEqualTo(payload)) {
        return {
            ...state,
            loading: false,
        };
    }
    const stateData: ITeamConfig[] = Utils.removedDuplicateMongoFile(state.data, payload);
    return {
        ...state,
        loading: false,
        data: stateData.concat(payload),
    };
};

export const teamConfigurationReducer = (state: ReduxRootState<ITeamConfig[]> = ROOT_VALUE_STATE, action: ReduxActionInterface<TeamActionPayload>) => {
    switch (action.type) {
        case TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_LOADING:
            return {
                ...state,
                loading: true,
            };

        case TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_ERROR:
            return {
                ...state,
                loading: false,
            };

        case TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_SUCCESS:
            return onTeamConfigurationSuccess(state, action.payload.teamConfigs);

        case TeamsConfigurationActionTypes.TEAMS_CONFIGURATION_DELETED:
            return {
                ...state,
                loading: false,
                data: state.data.filter((teamConfig: ITeamConfig) => teamConfig._id !== action.payload.teamConfigId),
            };

        default: return state;
    }
};
