import useSlidingPages from 'hooks/use-sliding-pages/useSlidingPages';
import { AssetTransactionType } from "config/_enums";
import { useDispatch, useSelector } from "react-redux";
import { closeAssetTransactionSlides, openAssetTransactionSlides } from "service/asset-transaction-slideup/action";
import { assetTransactionSlidesSelector } from "service/asset-transaction-slideup/selector";
import { ORDER_TYPE, TRANSACTION_PAGES_TRANSITION_TIME } from 'config/_const';

const useAssetTransactionSlides = () => {
    const dispatch = useDispatch();
    const { isOpen, canShowHalfScreen } = useSelector(assetTransactionSlidesSelector);
    const { hideSlidingPage, isOpen: isSlidingPageOpen } = useSlidingPages();

    const openAssetTransaction = (assetId: string, transactionType: AssetTransactionType, canShowHalfScreen?: boolean, fromPack?: boolean, orderType?: ORDER_TYPE) => {
        if (isSlidingPageOpen) {
            hideSlidingPage()
        }
        if (isOpen) {
            closeAssetTransaction();
            setTimeout(() => dispatch(openAssetTransactionSlides(assetId, transactionType, canShowHalfScreen, fromPack, orderType)), TRANSACTION_PAGES_TRANSITION_TIME);
            return;
        }
        dispatch(openAssetTransactionSlides(assetId, transactionType, canShowHalfScreen, fromPack, orderType));
    }


    const closeAssetTransaction = () => dispatch(closeAssetTransactionSlides());

    return {
        isOpen,
        canShowHalfScreen,
        openAssetTransaction,
        closeAssetTransaction,
    }
}

export default useAssetTransactionSlides;