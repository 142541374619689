import IUseTournament from 'hooks/tournament/use-tournament/IUseTournament';
import useTournament from 'hooks/tournament/use-tournament/useTournament';
import React from 'react';
import IInjectedPreviousTournament from './IInjectedPreviousTournament';
import TournamentViewModel from 'view-model/Tournament/TournamentViewModel/TournamentViewModel';

const withPreviousTournament = <ExtraProps,>(ChildComponent: React.ComponentType<ExtraProps & IInjectedPreviousTournament>): React.MemoExoticComponent<React.FC<ExtraProps>> => {
    const ParentComponent: React.FC<ExtraProps> = (args: ExtraProps) => {
        const { previousTournament, fetchPreviousTournament }: IUseTournament = useTournament();
        const memoizedPreviousTournament: TournamentViewModel = React.useMemo(() => {
            return previousTournament ?? new TournamentViewModel({});
        }, [previousTournament]);

        React.useEffect(() => {
            fetchPreviousTournament();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return <ChildComponent  {...args as ExtraProps} previousTournament={memoizedPreviousTournament} />
    }

    return React.memo<React.FC<ExtraProps>>(ParentComponent);
};

export default withPreviousTournament;