import i18n from '18n';
import clsx from 'clsx';
import { ALLOW_ANONYMOUS_PURCHASE, ASSETS_CATEGORIES, G_TAGS_KNOWN_EVENTS } from 'config/_const';
import { AssetTransactionType, RedirectInitiationPage, URL_INTENT } from 'config/_enums';
import useAccountFromCookies from 'hooks/use-account--from-cookies/useAccountFromCookies';
import useAccount from 'hooks/use-account/useAccount';
import useAssetTransactionSlides from 'hooks/use-asset-transaction-slideup/useAssetTransactionSlides';
import useSignupModal from 'hooks/use-signup-modal/useSignupModal';
import React, { useCallback } from 'react';
import { SegmentTracking } from 'service/segment/SegmentTracking';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import IBuySharesButtonProps from './IBuySharesButtonProps';
import "./buy-button.scss";
import GreenButton from 'components/small/buttons/green-button/GreenButton';
import { Add } from '@material-ui/icons';
import PixelHandlerAddToCart from 'service/facebook-pixel/PixelHandlerAddToCart';

const BuySharesButton: React.FC<IBuySharesButtonProps> = (props) => {
    const { assetViewModel, isSaleStartingInLessThan3Min = false } = props;
    const canDisplayHalfScreenModal = true;
    const { openAssetTransaction } = useAssetTransactionSlides();
    const { accountViewModel } = useAccount();
    const { isUserExist } = useAccountFromCookies();


    const postSigninCallBack = useCallback(() => {
        openAssetTransaction(assetViewModel.AssetId, AssetTransactionType.BUY);
    }, [assetViewModel.AssetId]);

    const getQueryParamsOnRedirect = useCallback(() => {
        const urlParams = new URLSearchParams();
        urlParams.append(URL_INTENT.REDIRECT_TO, RedirectInitiationPage.BUY_ASSET);
        urlParams.append("assetId", assetViewModel.AssetId);
        return urlParams;
    }, [assetViewModel.AssetId]);

    const { displaySignup } = useSignupModal(getQueryParamsOnRedirect(), postSigninCallBack);

    const handleBuyClick = () => {
        if (!ALLOW_ANONYMOUS_PURCHASE && !isUserExist) {
            displaySignup();
            return;
        }
        SegmentTracking.sendProductAddedEvent({ asset: assetViewModel.Asset, accountViewModel });
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.GET_SHARES, {
            name: assetViewModel.AssetSlug,
            conversionValue: assetViewModel.CurrentValue,
            currency: accountViewModel.Currency,
        });
        const pixelHandlerAddToCart: PixelHandlerAddToCart = new PixelHandlerAddToCart();
        pixelHandlerAddToCart.addToCartData = {
            name: assetViewModel.AssetSlug,
            conversionValue: assetViewModel.CurrentValue,
            currency: accountViewModel.Currency,
            status: true,
        };
        pixelHandlerAddToCart.handle();
        assetViewModel.Asset && openAssetTransaction(assetViewModel.AssetId, AssetTransactionType.BUY, canDisplayHalfScreenModal);
    };

    const textButton: string = React.useMemo(() => {
        if (assetViewModel.MainCategoryId === ASSETS_CATEGORIES['Euro 2024'])
            return "buyAsset.buyShares.euro";
        if (isSaleStartingInLessThan3Min)
            return "buyAsset.pre-private-sale.start";
        if (assetViewModel.isEarlyBirdEnable() && assetViewModel.IsAssetBenzema)
            return assetViewModel.enableCustomAssetProfil() ? "benzema.custom-profil.buy" : "early.bird.text.buy";
        return "buyAsset.buyShares";
    }, [isSaleStartingInLessThan3Min, assetViewModel]);

    return (
        <GreenButton
            className={clsx('resume-cart', 'buy-shares-button', {
                "custom-button-early-bird": assetViewModel.isEarlyBirdEnable(),
                "euro": assetViewModel.MainCategoryId === ASSETS_CATEGORIES['Euro 2024'],
                "fun": assetViewModel.IsFunCategory
            })}
            handleClick={handleBuyClick}
            textButton={<span className='buy-button-label'>{!assetViewModel.isEarlyBirdEnable() && <Add />} {i18n.t(textButton)}</span>}
            receiveClickEvent={false}
            disabled={false}
            subtitle={assetViewModel.IsAssetBenzema && assetViewModel.isEarlyBirdEnable() && !assetViewModel.enableCustomAssetProfil() ? i18n.t('early.bird.subtitle.buy') : ""}
            type="button"
            showShadow />);
};

export default React.memo(BuySharesButton);
