import ReactPixel from 'react-facebook-pixel';
import IPixelHandler from "../pixel/IPixelHandler";
import IAddToCartData from './IAddToCartData';

export default class PixelHandlerAddToCart implements IPixelHandler {
    private data: IAddToCartData;
    private static customFacebookEventName: string = 'AddToCart';

    constructor(){
        this.data = {
            name: '',
            conversionValue: '',
            currency: '',
            status: false
        };
    }

    public get addToCartData() {
        return this.data;
    }

    public set addToCartData(data: IAddToCartData) {
        this.data = data;
    }

    handle(): void {
        ReactPixel.track(PixelHandlerAddToCart.customFacebookEventName, this.data);
    }

}