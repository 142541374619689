import React from "react";
import { Tooltip } from "@material-ui/core";
import ITooltipProps from "./ITooltipProps";

const withTooltip = <P extends object>(
    WrappedComponent: React.ComponentType<P>,
) => {
    const WithTooltip: React.FC<P & ITooltipProps> = (props) => {
        const { tooltipText, ...rest } = props;
        if (!tooltipText) return <WrappedComponent {...rest as P} />;
        return (
            <Tooltip
                arrow
                enterTouchDelay={100}
                title={<p style={{ fontSize: '13px' }} className='no-margin'>{tooltipText}</p>}
            >
                <div className="wrapper">
                    <WrappedComponent {...rest as P} />
                </div>
            </Tooltip>
        );
    };

    return React.memo(WithTooltip);
};

export default withTooltip;