import Wallet from "components/icons/wallet/Wallet";
import React from "react";
import './ReloadAccountBlock.scss';
import i18next from "i18next";
import useWalletRefund from "hooks/use-wallet-refund/useWalletRefund";

const ReloadAccountBlock: React.FC = () => {
    const { handleWalletRefundClick } = useWalletRefund();

    return (
        <div className="reload-account-block" onClick={handleWalletRefundClick}>
            <span className="reload-account-block__icon"><Wallet style={{ fill: "#53D081", width: '18px', margin: '0 10px' }} /></span>
            <div className="reload-account-block__content">
                {i18next.t('Timer.loadMyAccount')}
            </div>
        </div>
    );
};

export default React.memo(ReloadAccountBlock);