import i18n from '18n';
import Clock from 'components/icons/Clock';
import ProofOfIdentity from 'components/icons/ProofOfIdentity';
import React from 'react';
import "./identity-verification-pending.scss";

const IdentityVerificationPending: React.FC = () => {
    return (<div className='identity-pending'>
        <div className="identity-pending_icons">
            <Clock className='clock-icon' />
            <ProofOfIdentity className='proof-of-identity-icon' />
        </div>
        <div className="identity-pending_content">
            <div className="identity-pending_title">
                {i18n.t("proofOfIdentity")}
            </div>
            <div className="identity-pending_text">
                {i18n.t("identity.documentUnderReview")}
            </div>
        </div>
    </div>)
}

export default IdentityVerificationPending