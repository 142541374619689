import { TransactionSource, TransactionType } from 'config/_enums';
import { IAssetInAssetTransaction, IAssetTransaction } from 'model/AssetTransactions/IAssetTransaction';
import { IGetAllAssetTransactionResponse } from '../types';

export default class AssetTransactionAdapter implements IAssetTransaction {
    public id: string;
    public createdAt: string;
    public type: TransactionType;
    public amount: number;
    public status: string;
    public asset: IAssetInAssetTransaction;
    public paid: number;
    public currency: string;
    public source: TransactionSource;

    constructor(assetTransaction: IGetAllAssetTransactionResponse) {
        this.id = assetTransaction.id;
        this.createdAt = assetTransaction.createdAt;
        this.type = assetTransaction.type;
        this.amount = assetTransaction.amount;
        this.status = assetTransaction.status;
        this.asset = {
            cardLevel: assetTransaction.asset.cardLevel,
            icon: assetTransaction.asset.icon,
            id: assetTransaction.asset.id,
            mainCategory: assetTransaction.asset.category,
            name: assetTransaction.asset.name
        }
        this.paid = assetTransaction.paid;
        this.currency = assetTransaction.currency;
        this.source = assetTransaction.source;
    }
}