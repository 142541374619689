
const getStripePublicKey = () => {
    if (window.location.host === 'app.trendex.vip') {
        return 'pk_live_51JAxAZDFZrZwSRLLo4JEapRish9gEOfIUYMrQvZJEjVowpkgWzr5bd2y0I4mDRPENRkVePgapUWzTSf9if1uesrA00yRZNI5gN';
    }
    if (window.location.host === 'app.suprematic.io') {
        return 'pk_live_XpXeQ9qGPvMXmqzWD9qL4wgx00IaApwxiY';
    }
    return 'pk_test_51KdtHQE42UsJeMxlzNFSILa9qLKLcjzGKLadxcUFwdTkY3m4ZaJ7eCRuCsdWAC6uIi7E6q07EQ1UkjAo9EhROEjK00cZkHe4ek';
};

const StripeConfig = {
    publicKey: getStripePublicKey(),
};

export default StripeConfig;