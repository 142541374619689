import { createSelector, Selector } from 'reselect';
import { RootState } from '../../model/Redux';
import ModalReduxStore from './ModalReduxStore';

export const modalSelector: Selector<RootState, ModalReduxStore> = state => state.modal;

export const isSignupModalSelector = createSelector<RootState, ModalReduxStore, boolean>(
    modalSelector,
    (modal) => {
        return modal.type === 'SIGN_UP';
    },
);

export const isRewardModalSelector = createSelector<RootState, ModalReduxStore, boolean>(
    modalSelector,
    (modal) => {
        return modal.type === 'REWARD_MODAL';
    },
);

export const isConfirmModalSelector = createSelector<RootState, ModalReduxStore, boolean>(
    modalSelector,
    (modal) => {
        return modal.type === 'CONFIRM';
    },
);

export const isModalOpen = createSelector<RootState, ModalReduxStore, boolean>(
    modalSelector,
    (modal) => {
        return modal.display;
    },
);

export const globalModalSelector = createSelector<RootState, ModalReduxStore, ModalReduxStore>(
    modalSelector,
    (modal) => {
        return modal;
    },
);
