import { DEFAULT_ID_AS_STRING, PERSONAL_TOURNAMENT_LABEL_SUBSCRIBED, VERIFICATION_STATUS } from 'config/_const';
import { Status, TournamentRewardKind } from '../../config/_enums';
import ReduxRootState from '../../model/Redux/ReduxRootSate';
import ReduxActionInterface from '../../model/ReduxActionInterface';
import IGetCredits from '../../model/User/IGetCredits';
import { FakeUser } from '../../model/User/MockUser';
import UserInterface from '../../model/User/UserInterface';
import { RESET_STATE } from '../commonActionTypes';
import { REWARD_TRANSACTION_TYPE, WHITELIST_STATUS } from './../../config/_const';
import UserStoreData from './UserStoreData';
import * as ActionTypesAuth from './actionsType';

const ROOT_VALUE_STATE: ReduxRootState<UserStoreData> = {
    loading: false,
    data: {
        loadingUploadPicture: false,
        loadingUploadIdentity: false,
        isAuthenticated: false,
        account: FakeUser,
        actualAsset: null,
        postSubscriptionStatus: Status.idle,
        postSubscriptionAssetId: '',
        isAdminToVip: false,
        emailVerification: '',
        reward: {
            user: DEFAULT_ID_AS_STRING,
            handled: false,
            rewardId: DEFAULT_ID_AS_STRING,
            tournamentId: DEFAULT_ID_AS_STRING,
            place: undefined,
            virtualCreditsAmount: undefined,
            totalTokensReceive: 0,
            assetsReward: [],
            kind: TournamentRewardKind.CREDITS_REWARD,
            fromUserPersonalTournament: false
        },
        rewardTransaction: {
            reward: DEFAULT_ID_AS_STRING,
            user: DEFAULT_ID_AS_STRING,
            type: REWARD_TRANSACTION_TYPE.EXPIRED,
            handled: false,
            tournament: DEFAULT_ID_AS_STRING
        },
        giftRegister: {
            enabled: false,
            assets: [],
            nbTokensPerAsset: 0
        },
    },
};

export const authReducer = (state: ReduxRootState<UserStoreData> = ROOT_VALUE_STATE, action: ReduxActionInterface): ReduxRootState<UserStoreData> => {

    switch (action.type) {
        case ActionTypesAuth.PERSONAL_TOURNAMENT_SUBSCRIBE_LOADING:
        case ActionTypesAuth.PERSONAL_TOURNAMENT_CREATE_LOADING:
        case ActionTypesAuth.GET_PERSONAL_TOURNAMENT_LOADING:
        case ActionTypesAuth.AUTH_LOADING:
        case ActionTypesAuth.RESET_PASSWORD_LOADING:
        case ActionTypesAuth.UPDATE_USER_CGU_LOADING:
        case ActionTypesAuth.UPDATE_USER_REFERRAL_TERMS_LOADING:
            return { ...state, loading: true };

        case ActionTypesAuth.AUTH_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    account: action.payload.user,
                    emailVerification: action.payload.email,
                    isAuthenticated: true,
                    actualAsset: action.payload.user.ownedAssets && action.payload.user.ownedAssets[0],
                    isAdminToVip: action.payload.isAdminToVip,
                    giftRegister: {
                        ...state.data.giftRegister,
                        ...action.payload.giftRegister
                    },
                },
                loading: false,
            };

        case ActionTypesAuth.AUTH_LOGOUT:
            return {
                ...state,
                data: {
                    ...state.data,
                    account: FakeUser,
                    isAuthenticated: false,
                },
                loading: false,
            };

        case ActionTypesAuth.CHANGE_ASSET:
            return {
                ...state,
                data: {
                    ...state.data,
                    actualAsset: action.payload,
                },
            };

        case ActionTypesAuth.UPLOAD_PROFILE_PICTURE_LOADING:
            return {
                ...state,
                data: {
                    ...state.data,
                    loadingUploadPicture: true,
                },
            };

        case ActionTypesAuth.UPDATE_AUTH_KEY:

            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    account: {
                        ...state.data.account,
                        ...action.payload.user,
                    },
                },
            };

        case ActionTypesAuth.UPLOAD_PROFILE_PICTURE_SUCCESS:
            const account = JSON.parse(JSON.stringify(state.data.account));

            account.avatar = action.payload.avatar;

            return {
                ...state,
                data: {
                    ...state.data,
                    account,
                    loadingUploadPicture: false,
                },
            };

        case ActionTypesAuth.UPLOAD_PROFILE_PICTURE_FAILED:
            return {
                ...state,
                data: {
                    ...state.data,
                    loadingUploadPicture: false,
                },
            };

        case ActionTypesAuth.UPLOAD_IDENTITY_LOADING:
            return {
                ...state,
                data: {
                    ...state.data,
                    loadingUploadIdentity: true,
                },
            };

        case ActionTypesAuth.UPLOAD_IDENTITY_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    account: action.payload.user,
                    emailVerification: action.payload.email,
                    isAuthenticated: true,
                    actualAsset: action.payload.user.ownedAssets && action.payload.user.ownedAssets[0],
                    loadingUploadIdentity: false,
                },
            };

        case ActionTypesAuth.UPLOAD_IDENTITY_FAILED:
            return {
                ...state,
                data: {
                    ...state.data,
                    loadingUploadIdentity: false,
                },
            };

        case ActionTypesAuth.SUBSCRIBE_TO_ASSET_START: {
            return {
                ...state,
                data: {
                    ...state.data,
                    postSubscriptionStatus: Status.pending,
                    postSubscriptionAssetId: action.payload,
                },
            };
        }

        case ActionTypesAuth.SUBSCRIBE_TO_ASSET_SUCCESS: {
            const subscribedAssetsIds = action.payload as string[];
            return {
                ...state,
                data: {
                    ...state.data,
                    account: {
                        ...state.data.account,
                        subscribedAssetsIds,
                    },
                    postSubscriptionStatus: Status.resolved,
                    postSubscriptionAssetId: '',
                },
            };
        }

        case ActionTypesAuth.SUBSCRIBE_TO_ASSET_FAILED: {
            return {
                ...state,
                data: {
                    ...state.data,
                    postSubscriptionStatus: Status.rejected,
                },
            };
        }
        case ActionTypesAuth.GET_PERSONAL_TOURNAMENT_ERROR:
        case ActionTypesAuth.PERSONAL_TOURNAMENT_CREATE_ERROR:
        case ActionTypesAuth.PERSONAL_TOURNAMENT_SUBSCRIBE_ERROR:
        case ActionTypesAuth.HANDLE_CLAIMABLE_REWARD_ERROR:
        case ActionTypesAuth.REWARD_FAILED:
        case ActionTypesAuth.RESET_PASSWORD_SUCCESS:
        case ActionTypesAuth.RESET_PASSWORD_FAILED:
        case ActionTypesAuth.GET_CREDITS_ERROR:
        case ActionTypesAuth.AUTH_FAILED:
        case ActionTypesAuth.UPDATE_USER_CGU_FAILED:
        case ActionTypesAuth.UPDATE_USER_REFERRAL_TERMS_FAILED:
        case ActionTypesAuth.POLLING_KYC_STATUS_ERROR:
            return {
                ...state,
                loading: false,
            };
        case ActionTypesAuth.UPDATE_USER_CGU_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    account: action.payload.user,
                },
                loading: false,
            };
        case ActionTypesAuth.GET_CREDITS_SUCCESS:
            return replaceUserCredits(action.payload, state);
        case ActionTypesAuth.UPDATE_CREDITS_AFTER_BUY:
            return replaceUserCreditsAfterBuy(action.payload, state);
        case RESET_STATE:
            return ROOT_VALUE_STATE;
        case ActionTypesAuth.UPDATE_USER_REFERRAL_TERMS_SUCCESS:
            return replaceUserReferralTermsAccepted(action.payload.data, state);
        case ActionTypesAuth.ADD_ASSET_TO_ASSETWALLET:
            return addAssetSharesToAssetWallet(action.payload, state);
        case ActionTypesAuth.REWARD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    reward: {
                        ...state.data.reward,
                        ...action.payload.data,
                    }
                },
            };
        case ActionTypesAuth.HANDLE_CLAIMABLE_REWARD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    rewardTransaction: action.payload,
                },
            };

        case ActionTypesAuth.REWARD_REGISTER:
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    giftRegister: {
                        ...state.data.giftRegister,
                        assets: action.payload.assets,
                        nbTokensPerAsset: action.payload.nbTokensPerAsset
                    }
                },
            };
        case ActionTypesAuth.WHITELIST_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    account: {
                        ...state.data.account,
                        whitelist: {
                            status: WHITELIST_STATUS.CONFIRMED
                        }
                    }
                }
            };
        case ActionTypesAuth.WHITELIST_USER_LOADING:
            return {
                ...state,
                loading: true
            };
        case ActionTypesAuth.WHITELIST_USER_ERROR:
            return {
                ...state,
                loading: false
            };
        case ActionTypesAuth.UPDATE_VERIFICATION_IDENTITY:
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    account: {
                        ...state.data.account,
                        isConfirmed: action.payload.isConfirmed
                    }
                }
            }
        case ActionTypesAuth.GET_PERSONAL_TOURNAMENT_SUCCESS:
        case ActionTypesAuth.PERSONAL_TOURNAMENT_CREATE_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    account: {
                        ...state.data.account,
                        personalTournament: action.payload
                    }
                },
                loading: false
            };
        case ActionTypesAuth.PERSONAL_TOURNAMENT_SUBSCRIBE_SUCCESS:
            if (!state.data.account.personalTournament)
                return {
                    ...state, loading: false
                };
            return {
                ...state,
                data: {
                    ...state.data,
                    account: {
                        ...state.data.account,
                        personalTournament: {
                            ...state.data.account.personalTournament,
                            label: PERSONAL_TOURNAMENT_LABEL_SUBSCRIBED
                        }
                    }
                },
                loading: false
            };

        case ActionTypesAuth.LAUNCH_ONBOARDING:
        case ActionTypesAuth.UPDATE_ONBOARDING_STEP:
        case ActionTypesAuth.FINISH_ONBOARDING_STEP:
            return internalUpdateOnboardingStep(state, action.payload.onboardingStep)
        case ActionTypesAuth.POLLING_KYC_STATUS_SUCCESS:
            return internalUpdateKycStatus(state, action.payload);
        default: return state;
    }
};

const internalUpdateKycStatus = (state: ReduxRootState<UserStoreData>, kycStatus: VERIFICATION_STATUS): ReduxRootState<UserStoreData> => {
    return {
        ...state,
        loading: false,
        data: {
            ...state.data,
            account: {
                ...state.data.account,
                isConfirmed: kycStatus
            }
        }
    }
}

const internalUpdateOnboardingStep = (state: ReduxRootState<UserStoreData>, onboardingStep: number) => {
    return {
        ...state,
        loading: false,
        data: {
            ...state.data,
            account: {
                ...state.data.account,
                onboardingStep: onboardingStep
            }
        }
    }
}

const replaceUserCreditsAfterBuy = (data: {
    userCredits: number,
    userVirtualCredits: number
}, state: ReduxRootState) => {
    if (isNaN(data.userCredits) || isNaN(data.userVirtualCredits))
        return state;
    return {
        ...state,
        data: {
            ...state.data,
            account: {
                ...state.data.account,
                credits: data.userCredits,
                virtualCredits: data.userVirtualCredits,
            },
        },
        loading: false,
        error: '',
    };
}

const replaceUserCredits = (data: IGetCredits, state: ReduxRootState) => {
    return {
        ...state,
        data: {
            ...state.data,
            account: {
                ...state.data.account,
                credits: data.credits,
                frozenCredits: data.frozenCredits,
                virtualCredits: data.virtualCredits,
            },
        },
        loading: false,
        error: '',
    };
};

const replaceUserReferralTermsAccepted = (datas: UserInterface, state: ReduxRootState) => {
    const account = state.data.account;
    account.referral.referralTermsAccepted = datas.referral.referralTermsAccepted;
    return {
        ...state,
        data: {
            ...state.data,
            account,
        },
        loading: false,
        error: '',
    };
};

const addAssetSharesToAssetWallet = (payload: any, state: ReduxRootState) => {
    const account = state.data.account;
    let assetWallet = account.assetWallet;
    if (assetWallet.findIndex((assetWalletEntry: any) => assetWalletEntry.asset.toString() === payload.assetId) === -1) {
        assetWallet.push({
            asset: payload.assetId,
            amount: payload.amountOfShares,
        });
    } else {
        assetWallet = assetWallet.map((assetWalletEntry: any) => {
            if (assetWalletEntry.asset.toString() !== payload.assetId) return assetWalletEntry;
            assetWalletEntry.amount += payload.amountOfShares;
            return assetWalletEntry;
        });
    }
    account.assetWallet = assetWallet;
    return {
        ...state,
        data: {
            ...state.data,
            account,
        },
        loading: false,
        error: '',
    };
};
