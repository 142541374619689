import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import i18next from 'i18next';
import FormScheduledOrderProps from './FormScheduledOrderProps';
import * as Input from '../../../../small/input/ReduxFormInput';
import PrimaryButton from '../../../../small/buttons/primary-button/PrimaryButton';
import './FormScheduledOrder.scss';
import InitialValueFormScheduledOrderInterface from './InitialValueFormScheduledOrderInterface';
import Utils from '../../../../../utils/Utils';
import clsx from 'clsx';
import { LAUNCH_MODE, LAUNCH_MODE_OPTIONS, WRAPPER_SELECT_TYPE } from 'config/_const';
import WrapperSelect from 'components/small/wrapper-select/WrapperSelect';
import { ISelectFieldProps } from 'interfaces/ISelectFieldProps';
const INITIAL_STATE_FORM: InitialValueFormScheduledOrderInterface = {
    initialSaleDate: `${Utils.formatDatenNextDayToFilter(new Date())}T${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}`,
    initialSupply: 0,
    chunkProgressiveMarket: true,
    launchMode: LAUNCH_MODE.DEFAULT
};

const FormScheduledOrder: React.FC<InjectedFormProps<{}, FormScheduledOrderProps> & FormScheduledOrderProps> = (props) => {
    return (
        <div className={clsx('form-private-sell-information-form form-private-sell-information-form-light', {})}>
            <h4>{i18next.t('MySales.FormScheduledOrder.form.title')}</h4>

            <form onSubmit={props.handleSubmit(props.createScheduledOrder)}>

                <div className="field-container">
                    <label className="form_label">{i18next.t('MySales.FormScheduledOrder.form.input1')}</label>
                    <Field
                        name={'initialSaleDate'}
                        type="datetime-local"
                        component={Input.generateInputText} />
                </div>

                <div className="field-container">
                    <label className="form_label under-text">{i18next.t('MySales.FormScheduledOrder.form.input2')}</label>
                    <Field
                        name={'initialSupply'}
                        type="text"
                        defaultValue={props.minAssets}
                        // normalize={(value: number) => Utils.priceBetweenTwoNumbers(props.minAssets, props.totalSupply, value)}
                        component={Input.generateInputText} />
                </div>
                <div className="field-container mt-3">
                    <div className='field-container item flex mt-1'>
                        <label className='form_label' htmlFor='liquidityPoolEnabled'>{i18next.t("MySales.FormScheduledOrder.form.input4")}</label>
                        <Field
                            name="liquidityPoolEnabled"
                            type={"checkbox"}
                            custom={{ black: true }}
                            component={Input.generateCheckbox}
                            id="liquidityPoolEnabled"
                        />
                    </div>
                    <div className='field-container item flex mt-1'>
                        <label className='form_label' htmlFor='chunkProgressiveMarket'>{i18next.t("MySales.FormScheduledOrder.form.input7")}</label>
                        <Field
                            name="chunkProgressiveMarket"
                            type={"checkbox"}
                            custom={{ black: true }}
                            component={Input.generateCheckbox}
                            id="chunkProgressiveMarket"
                        />
                    </div>

                </div>
                <div className="field-container mt-2">
                    <label className='form_label' htmlFor='liquidityPoolInitialSupply'>{i18next.t("MySales.FormScheduledOrder.form.input5")}</label>
                    <Field
                        name="liquidityPoolInitialSupply"
                        type={"text"}
                        component={Input.generateInputText}
                        id="liquidityPoolInitialSupply"
                        disabled={!props.formValues.liquidityPoolEnabled}
                    />
                </div>
                <div className="field-container mt-2">
                    <label className='form_label' htmlFor='liquidityPoolInitialValue'>{i18next.t("MySales.FormScheduledOrder.form.input6")}</label>
                    <Field
                        name="liquidityPoolInitialValue"
                        type={"text"}
                        component={Input.generateInputText}
                        id="liquidityPoolInitialValue"
                        disabled={!props.formValues.liquidityPoolEnabled}
                    />
                </div>

                <div className="field-container item">
                            <label className="form_label"> Launch Mode </label>
                            <Field
                                name={'launchMode'}
                                type="select"
                                component={(args: ISelectFieldProps) => <WrapperSelect
                                    valueChange={args.input.onChange}
                                    propsReduxForm={props}
                                    value={props.formValues.launchMode}
                                    customValue=""
                                    data={LAUNCH_MODE_OPTIONS}
                                    type={WRAPPER_SELECT_TYPE.NATIVE} />} />
                        </div>


                <p className="form_label under-text">{i18next.t('MySales.FormScheduledOrder.form.paragraph')}</p>

                <div className="button-submit">
                    <PrimaryButton
                        receiveClickEvent={false}
                        disabled={false}
                        type="submit"
                        textButton={i18next.t(props.buttonText)} />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: FormScheduledOrderProps, ownProps: FormScheduledOrderProps) => {

    const formValues = getFormValues('FormStartPrivateSell')(state) || INITIAL_STATE_FORM;
    const initialValues = ownProps.initialValues ? { ...INITIAL_STATE_FORM, ...ownProps.initialValues } : { ...formValues };

    return {
        initialValues,
        formValues,
    };
};

const FormScheduledOrderRedux = reduxForm<{}, FormScheduledOrderProps>({
    form: 'FormStartPrivateSell',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    enableReinitialize: true,
})(FormScheduledOrder);

export default connect(mapStateToProps)(FormScheduledOrderRedux);
