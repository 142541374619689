import { ITournamentTeam } from 'model/Team/ITeam';
import ITeamAsset from 'model/Team/ITeamAsset';
import UserInterface from 'model/User/UserInterface';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import TournamentRewardViewModel from '../TournamentRewardViewModel/TournamentRewardViewModel';
import ITournamentResultViewModelParams from './ITournamentResultViewModelParams';

export default class TournamentResultViewModel {
    private user: AccountViewModel;
    private _id: string;
    private team: ITournamentTeam;
    private tournament: string;
    private place: number;
    private score: number;
    private league: string;
    private totalAssetsOwnedAtSubscriptionTime: number;
    private totalTokensInTeam: number;
    private reward: TournamentRewardViewModel;
    private totalRecruitmentPointsAtSubscriptionTime: number;

    constructor({ result, reward }: ITournamentResultViewModelParams) {
        this.user = new AccountViewModel({ isAuthenticated: true, account: { ...result.user, userPseudo: result.user.pseudo } as UserInterface });
        this._id = result._id;
        this.team = result.team;
        this.tournament = result.tournament;
        this.place = result.place;
        this.score = result.score;
        this.league = result.league;
        this.totalAssetsOwnedAtSubscriptionTime = result.totalAssetsOwnedAtSubscriptionTime;
        this.totalTokensInTeam = result.totalTokensInTeam;
        this.reward = reward;
        this.totalRecruitmentPointsAtSubscriptionTime = result.totalRecruitmentPointsAtSubscriptionTime;
    }

    public get User(): AccountViewModel {
        return this.user;
    }

    public get TotalRecruitmentPointsAtSubscriptionTime(): number {
        return this.totalRecruitmentPointsAtSubscriptionTime;
    }

    public get Id(): string {
        return this._id;
    }

    public get TournamentId(): string {
        return this.tournament;
    }

    public get Team(): ITournamentTeam {
        return this.team;
    }

    public get League(): string {
        return this.league;
    }

    public get Place(): number {
        return this.place;
    }

    public get Score(): number {
        return this.score;
    }

    public get TotalAssetsOwnedAtSubscriptionTime(): number {
        return this.totalAssetsOwnedAtSubscriptionTime;
    }

    public get TokensInTeam(): number {
        if (this.TotalRecruitmentPointsAtSubscriptionTime)
            return this.TotalRecruitmentPointsAtSubscriptionTime;
        return this.totalTokensInTeam !== undefined ? this.totalTokensInTeam : this.Team.teamAssets.reduce((acc: number, teamAsset: ITeamAsset) => acc + teamAsset.numberOfShares, 0);
    }

    public get Reward(): TournamentRewardViewModel {
        return this.reward;
    }
}
