import { DEFAULT_NUMBER_OF_SHARES_FOR_TEAM, TEAM_AUTOMATIC_GENERATION_BEHAVIOR } from './../../config/_const';
import ITeam from './ITeam';
import ITeamConfig from './ITeamConfig';

export const AssetCategoryMock = {
    _id: '-1',
    name: 'Guy Morissette',
    description: 'non',
    icon: '',
    color: 'Sed voluptatem doloremque ea aut beatae ut omnis.',
    banner: [],
};

export const TeamThemeMock = {
    _id: '-1',
    assetCategory: AssetCategoryMock,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
};

export const TeamConfigMock: ITeamConfig = {
    _id: '-1',
    maxNumberOfAssets: 4,
    minimumSharesByAsset: DEFAULT_NUMBER_OF_SHARES_FOR_TEAM,
    theme: TeamThemeMock,
    defaultFiatAmount: 8088,
    type: 'Officiis ut dolores necessitatibus maiores natus quaerat est.Et unde quis a eum.Ipsum iure nesciunt consequatur.Sunt enim ducimus quae et omnis officia.Doloribus mollitia illum voluptate pariatur officiis placeat adipisci.\n \rRerum neque beatae explicabo fuga.Ratione itaque ea fuga aut possimus.Recusandae qui eius consequatur nulla corrupti.Facilis fuga ab voluptatibus accusamus.Maxime ad et et aut.\n \rBlanditiis sed alias facilis ut hic.Error qui nobis et laudantium.Minima qui consectetur quae.Qui sit aut quia facilis omnis impedit hic laboriosam eaque.Esse veritatis impedit deleniti et aliquam.',
    userType: ' Voluptatibus sit dolorem nemo illo dolor.Aut illo eligendi.Qui ut ducimus nemo sed saepe omnis minus.Necessitatibus et eligendi aut libero facilis quas.Accusantium beatae perferendis.Et rem ea tempora optio.',
    topSell: true,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    automaticGeneration: {
        behavior: TEAM_AUTOMATIC_GENERATION_BEHAVIOR.REPLACE,
    },
};

export const TeamAssetMock = {
    _id: '-1',
    asset: 'Assumenda unde fugit itaque saepe voluptas culpa qui quidem maiores.',
    numberOfShares: 5426,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
};

export const TeamMock: ITeam = {
    _id: '-1',
    label: 'Totam qui ut est aut reiciendis illo soluta incidunt.Quia veniam laborum quia magni eaque.Mollitia ea dolore qui vel assumenda qui quod.Sit expedita minus odit.Sapiente adipisci fuga possimus dignissimos harum quod non placeat eum.Recusandae fuga sit est.\n \rDeleniti similique laudantium deleniti fugiat facilis et.Omnis adipisci consequuntur delectus non voluptatem.Ut natus molestias dolores ea.Numquam natus et est.Totam voluptatem sunt in laborum quia.Est necessitatibus consequuntur quasi quia et sit.\n \rIn voluptate delectus iusto.Fuga est sapiente.Commodi unde labore est totam numquam.',
    teamAssets: [TeamAssetMock],
    autoGenerated: true,
    lastTournamentPosition: 52532,
    owner: {
        _id: '-1',
    },
    status: 'excepturi repellendus autem',
    configuration: TeamConfigMock,
    enabled: true,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
};
