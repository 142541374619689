import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import * as AssetCategoryActionType from './actionsType';
import AxiosConfig from '../../config/axiosConfig';
import { IHttpResponse } from '../../model/IHttpResponse';

export const actionFetchAssetCategory = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    dispatch({
        type: AssetCategoryActionType.ASSETS_CATEGORY_LOADING,
    });
    try {
        const response: IHttpResponse = await AxiosConfig.get('/asset-categories/all');

        if (response && response.data && response.data.status !== 200) {

            return dispatch({
                type: AssetCategoryActionType.ASSETS_CATEGORY_ERROR,
            });
        }

        return dispatch({
            type: AssetCategoryActionType.ASSETS_CATEGORY_SUCCESS,
            payload: {
                assetCategories: response.data.data,
                message: response.data.message,
            },
        });

    } catch (error) {

        return dispatch({
            type: AssetCategoryActionType.ASSETS_CATEGORY_ERROR,
        });
    }
};
