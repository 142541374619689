import { KeyboardArrowLeft } from '@material-ui/icons';
import React from 'react';
import useIsMobile from '../../../hooks/useIsMobile';
import './DynamicArrowBack.scss';
import { DynamicArrowBackProps } from './DynamicArrowBackProps';

const DynamicArrowBack: React.FC<DynamicArrowBackProps> = (props) => {
    const isMobile = useIsMobile();
    if (!isMobile)
        return null;
    return (
        <div className="back-arrow-modal" onClick={props.close}>
            <KeyboardArrowLeft className="icon" />
        </div>
    );
};

export default DynamicArrowBack;
