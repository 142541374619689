import { Divider } from '@material-ui/core';
import clsx from 'clsx';
import { ZERO } from "config/_const";
import IDetailedStatistics from 'interfaces/score-chart/IDetailedStatistics';
import React from "react";
import { Trans } from "react-i18next";
import AssetScoreEvolutionViewModel from 'view-model/Assets/AssetScoreEvolutionViewModel';
import './AssetSportScoreDetails.scss';
import IAssetSportScoreDetailsProps from './IAssetSportScoreDetailsProps';

const AssetSportScoreDetails = ({ statistics }: IAssetSportScoreDetailsProps) => {
    const assetScoreEvolutionVM: AssetScoreEvolutionViewModel = statistics.assetScoreEvolutionVM;
    return (
        <div className="sport-score-detail">
            <div className="sport-score-detail-header">
                <Trans
                    i18nKey="asset-profile.sport-score.details.date"
                    values={{ date: statistics.date }}
                />
            </div>
            <div className="sport-score-detail-content">
                <div className="sport-score-detail-content-status" style={{ backgroundColor: assetScoreEvolutionVM.BackgroundAccordingScore }}>
                    <span className="sport-score-detail-content-status-score" style={{ color: assetScoreEvolutionVM.ColorByTrendScore }}>{assetScoreEvolutionVM.RoundedScore}</span>
                    <div>
                        <span className="sport-score-detail-content-status-text"><div className="indicator" style={{ backgroundColor: assetScoreEvolutionVM.ColorByTrendScore }} /><Trans i18nKey={assetScoreEvolutionVM.TrendScoreStatus} /></span>
                    </div>
                </div>

                {statistics.data.map((stat: IDetailedStatistics) => {
                    return (
                        <div key={stat.label} className="sport-score-detail-content-stat">
                            <span className="sport-score-detail-content-stat-title"><Trans i18nKey={stat.label} />:</span>
                            <span className="sport-score-detail-content-stat-value">{stat.amount}</span>
                            {!stat.detailedCalculation ?
                                <span className={clsx("sport-score-detail-content-stat-points", { 'grey': Number(stat.points) === ZERO, 'red': Number(stat.points) < ZERO })}>
                                    <Trans
                                        i18nKey={Number(stat.points) !== ZERO ? 'asset-profile.sport-score.details.points' : '-'}
                                        values={{ score: stat.points }}
                                    />
                                </span>
                                :
                                <>
                                    <span className={clsx("sport-score-detail-content-stat-points")}>
                                        {stat.points}
                                    </span>
                                    <Divider />
                                    <span className={"sport-score-detail-content-stat-multiplier"}>
                                        <Trans
                                            i18nKey={stat.detailedCalculation}
                                        />
                                    </span>
                                </>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default AssetSportScoreDetails;