import IBuyFromBestSellingOrders from "model/Order/IBuyFromBestSellingOrders";
import ReduxRootState from 'model/Redux/ReduxRootSate';
import ReduxActionInterface from "model/ReduxActionInterface";
import { BUY_FROM_BEST_SELLING_ORDERBOOK } from "./actionType";

const ROOT_VALUE_STATE: ReduxRootState<IBuyFromBestSellingOrders> = {
    loading: false,
    data: {
        buyingOrderId: ''
    }
};
export const buyFromBestSellingOrderReducer = (state: ReduxRootState<IBuyFromBestSellingOrders> = ROOT_VALUE_STATE, action: ReduxActionInterface<IBuyFromBestSellingOrders>) => {
    switch (action.type) {
        case BUY_FROM_BEST_SELLING_ORDERBOOK.LOADING:
            return {
                ...state,
                loading: true,
            };
        case BUY_FROM_BEST_SELLING_ORDERBOOK.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case BUY_FROM_BEST_SELLING_ORDERBOOK.SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case BUY_FROM_BEST_SELLING_ORDERBOOK.DROP_DATA:
            return ROOT_VALUE_STATE;
        default:
            return state;
    }
};
