import UserInterface from 'model/User/UserInterface';

// so it's end to end typed the same way
export type Id = UserInterface['_id'];

export interface BaseConstructor {
    _id: Id;
}

export default class BaseTournamentViewModel {
    private _id: Id;

    constructor(user: BaseConstructor) {
        this._id = user._id;
    }

    public get Id(): Id {
        return this._id;
    }

}
