import i18next from 'i18next';
import { isEmpty } from 'lodash';
import compose from 'lodash/fp/compose';
import type AssetInterface from 'model/AssetModel/AssetInterface';
import type { RootState } from 'model/Redux';
import type ReduxRootState from 'model/Redux/ReduxRootSate';
import type ReduxStoreInterface from 'model/ReduxStoreInterface';
import moment, { Moment } from 'moment';
import { createSelector, Selector } from 'reselect';
import type { Collection } from 'utils/types';
import Utils from 'utils/Utils';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import TournamentTopVIPViewModel from 'view-model/Tournament/TournamentTopVIPViewModel/TournamentTopVIPViewModel';
import { allAssetsExceptFilter } from './filter';
import { getFirst70 } from './getters';
import { sortByActiveSale, sortByDate, sortByListOrder, sortByPlannedSale, sortByTotalPriceChange } from './sorters';

const ENABLED_OLD_SORT_ON_ASSET_LIST = false;

export const assetsSelector: Selector<ReduxStoreInterface, ReduxRootState> = state => state.assets;
export const assetsDataSelector: Selector<RootState, AssetInterface[]> = state => state.assets.data;
export const assetsLoadingSelector: Selector<RootState, boolean> = state => state.assets.loading;

export const topAssetsSelector = createSelector<ReduxStoreInterface, ReduxRootState, AssetInterface[]>(
    assetsSelector,
    (assets: ReduxRootState<AssetInterface[]>) => {
        if (assets.data.length === 0)
            return [];

        if (ENABLED_OLD_SORT_ON_ASSET_LIST)
            return compose(
                sortByListOrder,
                sortByDate,
                sortByActiveSale,
                sortByPlannedSale,
                getFirst70,
                sortByTotalPriceChange,
            )(assets.data);

        const topAssets: AssetInterface[] = assets.data;
        const listOrderAssets: AssetInterface[] = topAssets.filter((asset: AssetInterface) => typeof asset.listOrder === 'number').sort((a: AssetInterface, b: AssetInterface) => (a.listOrder || 0) - (b.listOrder || 0));
        const noListOrderAssets: AssetInterface[] = topAssets.filter((asset: AssetInterface) => typeof asset.listOrder !== 'number');
        const now: Moment = moment();
        return listOrderAssets.concat(noListOrderAssets).sort((asset: AssetInterface) => {
            if (asset.initialSaleDate && asset.initialSaleRealEndDate && moment(asset.initialSaleDate).isSameOrBefore(now) && moment(asset.initialSaleRealEndDate).isSameOrAfter(now))
                return -1;
            return 0;
        }).sort((asset: AssetInterface) => {
            if (asset.initialSaleDate && moment(asset.initialSaleDate).isAfter(now))
                return -1;
            return 0;
        }).sort((assetA: AssetInterface, assetB: AssetInterface) => {
            if (!assetA.initialSaleDate) {
                return 1;
            }
            if (!assetB.initialSaleDate) {
                return -1;
            }
            if (assetA.initialSaleDate && assetB.initialSaleDate && moment(assetA.initialSaleDate).isAfter(now) && moment(assetB.initialSaleDate).isAfter(now))
                return moment(assetA.initialSaleDate).diff(assetB.initialSaleDate);
            return 0;
        });
    },
);

export const assetOrderSelector = createSelector<ReduxStoreInterface, ReduxRootState, any>(
    assetsSelector,
    (assets) => {
        if (assets.data.length === 0) return [];

        const assetOrdersSelection = assets.data.map((_el: AssetInterface, index: number) => {
            return {
                label: index,
                value: index,
            };
        });

        assetOrdersSelection.push({
            label: i18next.t('asset-form-admin-no-order'),
            value: null,
        });

        return assetOrdersSelection.map((assetOrder: any, index: number) => {
            const assetsFiltered: AssetInterface[] = assets.data.filter((asset: AssetInterface) => asset.listOrder === index);
            if (assetsFiltered.length === 0)
                return assetOrder;
            return {
                label: `${index}: ${assetsFiltered[0].name}`,
                value: index,
                disabled: true,
            };
        });
    },
);

export const allAssetsExceptSelector = (assetsIds: string[]) => {
    return createSelector<RootState, AssetInterface[], AssetViewModel[]>(
        assetsDataSelector,
        (assets: AssetInterface[]) => {
            return allAssetsExceptFilter(assets, assetsIds).map((asset: AssetInterface) => new AssetViewModel(asset));
        },
    );
};

export const assetByIdSelector = createSelector(
    (_: RootState, assetId: string) => assetId,
    assetsDataSelector,
    (assetId: string, assets: AssetInterface[]) => {
        return assets.find((asset: AssetInterface) => asset && asset._id === assetId);
    },
);

export const assetBySlugSelector = (slug: string) => {
    return createSelector<RootState, AssetInterface[], AssetInterface | undefined>(
        assetsDataSelector,
        (assets: AssetInterface[]) => {
            return assets.find((asset: AssetInterface) => asset && asset.slug === slug);
        },
    );
};

export const allAssetsSelector = createSelector<RootState, AssetInterface[], AssetViewModel[]>(
    assetsDataSelector,
    (assets: AssetInterface[]) => {
        return assets.map((asset: AssetInterface) => new AssetViewModel(asset));
    },
);

export const assetWithBestIRLScoreSelector = createSelector<RootState, AssetInterface[], TournamentTopVIPViewModel | null>(
    assetsDataSelector,
    (assets: AssetInterface[]) => {

        const sorted = assets.sort((a: AssetInterface, b: AssetInterface) => {
            if (a.IRLScore > b.IRLScore) return -1;
            if (a.IRLScore < b.IRLScore) return 1;
            return 0;
        });

        if (isEmpty(sorted))
            return null;

        const currentWinner = sorted[0];
        return new TournamentTopVIPViewModel({
            _id: currentWinner._id,
            icon: currentWinner.icon,
            name: currentWinner.name,
        });
    },
);

export const selectAssetsWithoutPageCollection = createSelector<RootState, AssetInterface[], Collection<AssetInterface>>(
    assetsDataSelector,
    (assets: AssetInterface[]) => {
        return Utils.normalize(assets, '_id');
    },
);

export const getAssetsLoadingSelector = createSelector<RootState, boolean, boolean>(
    assetsLoadingSelector,
    (loading: boolean) => loading,
);

export const getInitialSaleDateOfAsset = createSelector(
    assetsDataSelector,
    (_: RootState, assetId: string) => assetId,
    (assets: AssetInterface[], assetId: string) => {
        const asset = assets.find((asset: AssetInterface) => asset._id === assetId);
        return asset?.initialSaleDate ?? ''
    }
);

export const getAssetIdBySlugSelector = createSelector(
    assetsDataSelector,
    (_: RootState, assetSlug: string) => assetSlug,
    (assets: AssetInterface[], assetSlug: string) => {
        const asset: AssetInterface | undefined = assets.find((asset: AssetInterface) => asset.slug === assetSlug);
        return asset?._id ?? ''
    }
);

export const assetViewModelByIdSelector = createSelector(
    (_: RootState, assetId: string) => assetId,
    assetsDataSelector,
    (assetId: string, assets: AssetInterface[]) => {
        const asset = assets.find((asset: AssetInterface) => asset && asset._id === assetId);
        if (!asset)
            return undefined;
        return new AssetViewModel(asset);
    },
);

export const assetIdsAndScoresSelector = createSelector(assetsDataSelector, (assets: AssetInterface[]) => {
    return assets.map(asset => {
        return {
            id: asset._id,
            score: asset.IRLScore
        }
    });
})