import { RGBColor, IColorPercent } from './IColorPercent';

export default class Color {
    public static getColorByInputRatio = (input: number, opacity: number = 1): string => {
        const levelPercent: number = parseFloat(((input ?? 0) / 100).toFixed(1));
        if (levelPercent < 0.1)
            return `rgb(${[255, 0, 0, opacity].join(', ')})`;

        const percentColors: IColorPercent[] = [
            { percent: 0.0, color: { r: 255, g: 0, b: 0 } },
            { percent: 0.5, color: { r: 205, g: 205, b: 0 } },
            { percent: 1.0, color: { r: 0, g: 200, b: 55 } },
        ];

        const index: number = percentColors.findIndex((color: IColorPercent) => levelPercent <= color.percent);
        const lower: IColorPercent = percentColors[index > 0 ? index - 1 : index];
        const upper: IColorPercent = percentColors[index];
        const range: number = upper.percent - lower.percent;
        const rangePct: number = (levelPercent - lower.percent) / range;
        const pctLower: number = 1 - rangePct;
        const pctUpper: number = rangePct;
        const color: RGBColor = {
            r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
            g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
            b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
        };
        return `rgb(${[color.r, color.g, color.b, opacity].join(', ')})`;
    }

    public static rgbToRgba = (rgb: string, opacity: number = 1): string => {
        var result = rgb.replace(')', `, ${opacity})`).replace('rgb', 'rgba');
        return result;
    }
}
