import { useMemo } from "react";
import TokenAvailableInformationViewModel from "view-model/Assets/TokenAvailableInformationViewModel";
import IUseTokenAvailableInformation from "./IUseTokenAvailableInformation";
import { MarketEnum } from "config/_enums";

const useTokenAvailableInformationViewModel = (tokensAvailable: number, totalTokensAvailable: number, totalTokensInCirculation: number, market: MarketEnum): IUseTokenAvailableInformation => {
    return {
        tokenAvailableInformationViewModel: useMemo(() => new TokenAvailableInformationViewModel(tokensAvailable, totalTokensAvailable, totalTokensInCirculation, market), [tokensAvailable, totalTokensAvailable, totalTokensInCirculation, market]),
    };
};

export default useTokenAvailableInformationViewModel;
