import {
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    TextField,
    TextareaAutosize,
} from '@material-ui/core';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { TrendexCheckbox } from '../CustomCheckbox';
import ReduxFormInputProps, { CheckboxReduxFormInputProps } from './ReduxFormInputProps';
import { COUNTRY, EmptyString, WorldRegions } from 'config/_const';
import { TrendexRadio } from '../CustomRadioButton';
import { phoneValidator } from "middleware/reduxFormValidator";
import i18next from 'i18next';


/** component who render an simple input text
 * form already connect to the redux form
 */
export const generateInputDateTime = (props: ReduxFormInputProps) => {

    return (
        <input
            disabled={props.disabled}
            style={{ width: '100%', ...props.additionnalStyle }}
            label={props.label}
            variant="outlined"
            type="datetime-local"
            placeholder={props.label}
            error={props.meta.touched && props.meta.invalid && props.meta.error}
            helperText={props.meta.touched && props.meta.error}
            {...props.input}
            {...props.custom} />
    );
};

/** component who render an simple input text
 * form already connect to the redux form
 */
export const generateInputText = (props: ReduxFormInputProps) => {

    return (
        <TextField
            inputProps={{ className: props.className, id: props.id }}
            disabled={props.disabled}
            style={{ ...props.additionnalStyle }}
            label={props.label}
            variant={props.variant ?? "outlined"}
            type={props.type}
            placeholder={props.placeholder}
            error={props.meta.touched && props.meta.invalid && props.meta.error}
            helperText={props.meta.touched && props.meta.error}
            {...props.input}
            {...props.custom} />
    );
};

/** function render an material text area
 * input is already connected to the redux form
 */
export const generateTextArea = (props: ReduxFormInputProps) => {

    return (
        <TextareaAutosize
            style={{
                width: '100%',
                borderRadius: '5px',
                padding: '15px',
                ...props.additionnalStyle,
            }}
            label={props.label}
            placeholder={props.placeholder}
            error={props.meta.touched && props.meta.invalid && props.meta.error}
            helperText={props.meta.touched && props.meta.error}
            {...props.input}
            {...props.custom} />
    );
};

/** function who generate radio groups
 * input is already connected to the redux form
 */
export const generateRadio = (props: ReduxFormInputProps, data: any) => {
    return (
        <FormControl>
            <FormLabel>{props.label}</FormLabel>
            <RadioGroup
                {...props.rest}
                onChange={props.input.onChange}
                {...props.input}
                value={props.input.value}>
                <div>
                    {data.map((data: any) =>
                        <FormControlLabel
                            key={data.label}
                            value={`${data.value}`}
                            control={<TrendexRadio />}
                            label={data.label} />)}
                </div>
            </RadioGroup>
        </FormControl>
    );
};

export const generateCheckbox = (props: CheckboxReduxFormInputProps) => {
    return (
        <div>
            <FormControl>

                <div>
                    <FormControlLabel
                        classes={{
                            label: props.className ?? ""
                        }}
                        onChange={props.input.onChange}
                        key={props.label}
                        value={`${props.input.value}`}
                        control={<TrendexCheckbox checkboxColor={props.checkboxColor} black={props.custom?.black} />}
                        label={props.label}
                        checked={props.input.checked}
                    />
                </div>

            </FormControl>

            {props.meta.touched && props.meta.invalid && props.meta.error && (
                <p className="form-control-error-message">{props.meta.error}</p>
            )}
        </div>
    );
};


interface ICountry {
    countryCode: string,
    dialCode: string,
    format: string,
    iso2: string,
    name: string,
    priority: number,
    regions: string[];
}

export const phoneFieldValidation = () => Boolean(document.querySelector(".invalid-number")) ? i18next.t('UserInformationForm.phone-bad-format') : EmptyString;

export const generatePhoneInput = (props: any) => {
    return <PhoneInput
        {...props.input}
        enableAreaCodes={false}
        placeholder={props?.placeholder}
        country={props.country ?? COUNTRY.FRENCH.toLowerCase()}
        countryCodeEditable={true}
        regions={WorldRegions}
        isValid={((value: string, country: ICountry) => {
            value = value.slice(country?.dialCode?.length ?? EmptyString);
            return phoneValidator(value, props.mandatory ?? false);
        })}
    />;
};
