import { MiddlewareAPI, AnyAction, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ASSETS_SUCCESS } from "./actionsType";
import { fetchTopAssetsCurrentValues, fetchTopAssetsScores } from "./actions";


export const watchFetchTopAssets = (storeAPI: MiddlewareAPI<ThunkDispatch<void, null, any>>) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type !== ASSETS_SUCCESS)
        return next(action);

    if (action.payload.data.length === 0)
        return next(action);

    storeAPI.dispatch(fetchTopAssetsCurrentValues())
    storeAPI.dispatch(fetchTopAssetsScores())
    return next(action);
};
