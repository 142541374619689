import i18n from "18n";
import { TRENDEX_HTTP_STATUS_NOK } from "config/_const";
import useAccount from "hooks/use-account/useAccount";
import IUsePersonalTournament from "hooks/use-personal-tournament/IUsePersonalTournament";
import usePersonalTournament from "hooks/use-personal-tournament/usePersonalTournament";
import { RootState } from "model/Redux";
import { ILightTournamentSubscription } from "model/Tournament/ITournamentSubscription";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { managementNotification } from "service/notification/action";
import { getUserSubscriptionPerTournament } from 'service/tournamentSubscription/actions';
import { getCurrentUserSubscriptionSelector, isUserSubscribeToTournamentSelector, userCanJoinTournamentSelector } from "service/tournamentSubscription/selectors";
import IUseTournamentSubscriptions from "../use-tournament-subscriptions/IUseTournamentSubscriptions";
import IUseTournamentSubscriptionsParams from '../use-tournament-subscriptions/IUseTournamentSubscriptionsParams';
import useTournamentSubscriptions from "../use-tournament-subscriptions/useTournamentSubscriptions";
import IUseUserTournamentSubscription from './IUseUserTournamentSubscription';

const useUserTournamentSubscription = (params: IUseTournamentSubscriptionsParams): IUseUserTournamentSubscription => {
    const dispatch = useDispatch();
    const tournamentId: string = React.useMemo(() => params.tournamentId, [params.tournamentId]);
    const currentUserSubscription = useSelector((state: RootState) => getCurrentUserSubscriptionSelector(state, tournamentId));
    const { isPersonalTournamentSubscriptionOpen, isUserPersonalTournamentAvailable, subscribe: subscribeToPersonalTournament }: IUsePersonalTournament = usePersonalTournament();
    const { subscribeToTournament, subscriptionsLoading }: IUseTournamentSubscriptions = useTournamentSubscriptions({ tournamentId: params.tournamentId });
    const userCanJoinTournament: boolean = useSelector((state: RootState) => userCanJoinTournamentSelector(state, params.tournamentId));
    const userAlreadyJoinTournament: boolean = useSelector((state: RootState) => isUserSubscribeToTournamentSelector(state, tournamentId));
    const { accountViewModel } = useAccount();

    const userAlreadyRegisteredForTournament: boolean = React.useMemo((): boolean => {
        if (isUserPersonalTournamentAvailable)
            return accountViewModel.AlreadyRegisteredForTournament;
        return userAlreadyJoinTournament;
    }, [accountViewModel.AlreadyRegisteredForTournament, isUserPersonalTournamentAvailable, userAlreadyJoinTournament]);

    const subscribe = (args: ILightTournamentSubscription, onSuccessCallback?: () => void) => {
        if (isPersonalTournamentSubscriptionOpen) {
            subscribeToPersonalTournament(onSuccessCallback);
            return;
        }

        if (!userCanJoinTournament) {
            dispatch(managementNotification(true, i18n.t('tournament.subscribe.unavailable'), TRENDEX_HTTP_STATUS_NOK));
            return;
        }
        subscribeToTournament(args, onSuccessCallback);
        return;
    }

    const fetchUserTournamentSubscription = React.useCallback(() => {
        return getUserSubscriptionPerTournament(tournamentId);
    }, [tournamentId]);

    return {
        fetchUserTournamentSubscription,
        currentUserSubscription,
        subscriptionsLoading,
        userAlreadyRegisteredForTournament,
        subscribe,
    };
};

export default useUserTournamentSubscription;