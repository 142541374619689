import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import clsx from 'clsx';
import { MINIMUM_EQUAL_VALUE_GRAPH_THRESHOLD } from 'config/_const';
import i18next from 'i18next';
import PriceHistoryInterface from 'model/AssetModel/PriceHistoryInterface';
import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { Trans } from 'react-i18next';
import { batch } from 'react-redux';
import Utils from 'utils/Utils';
import { IPriceDateRangeHistory, IPriceHistoryGraphDataPoints } from './IPriceHistoryGraph';
import IPriceGraphHistoryProps from './IPriceHistoryGraphProps';
import "./price-history-graph.scss";

export const DATE_RANGE_OPTIONS = [
    { label_TranslateKey: 'assetProfile.buySharesForm.dateRange.day', dateRange: 86400000, shouldShowEqualValues: true },
    { label_TranslateKey: 'assetProfile.buySharesForm.dateRange.week', dateRange: 604800000, shouldShowEqualValues: true },
    { label_TranslateKey: 'assetProfile.buySharesForm.dateRange.month', dateRange: 2628000000, shouldShowEqualValues: true },
    { label_TranslateKey: 'assetProfile.buySharesForm.dateRange.year', dateRange: 31536000000, shouldShowEqualValues: false },
    { label_TranslateKey: 'assetProfile.buySharesForm.dateRange.all', dateRange: Number.POSITIVE_INFINITY, shouldShowEqualValues: false },
];

const ADD_ZERO_PRICE_ENTRY_TO_GRAPH = true;

const PriceHistoryGraph: React.FC<IPriceGraphHistoryProps> = ({ asset, priceHistory }) => {
    const [selectedDateRange, setSelectedDateRange] = useState<number>(Number.POSITIVE_INFINITY);
    const [assetPriceHistory, setAssetPriceHistory] = useState<PriceHistoryInterface[]>([]);

    const assetProfilePriceHistoryOptions = (categories: string[]) => ({
        colors: ['#6677F5'],
        chart: {
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: categories,
            labels: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false,
        },
        grid: {
            show: false
        },
        stroke: {
            width: 4,
            color: 'green',
            curve: 'smooth'
        },
        fill: {
            opacity: 0.1,
            color: 'green',
            gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 0.2,
                gradientToColors: undefined,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0.1,
                stops: [0, 90],
                colorStops: []
            }
        },
        dataLabels: { enabled: false },
        style: { padding: 0, margin: 0 },
        tooltip: {
            enabled: true,
            x: { show: false },
            y: {
                formatter: (val: string) => {
                    return Number(val).toCurrency();
                },
                title: {
                    formatter: () => ""
                }
            },
            marker: { show: false },
        }
    });

    useEffect(() => {
        if (assetPriceHistory.length >= priceHistory.length)
            return;
        setAssetPriceHistory(priceHistory);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceHistory]);

    const addDefaultPriceHistory = (priceHistory: IPriceHistoryGraphDataPoints[], currentDate: Date, dateRange: number): IPriceHistoryGraphDataPoints[] => {
        if (dateRange > (currentDate.getTime() - new Date(asset.InitialSaleDate).getTime())) {
            const zeroPriceEntry: IPriceHistoryGraphDataPoints = { price: 0, frenchFormattedDate: new Date(asset.InitialSaleDate).formatDDMMYY() };
            const initalListingPrice: IPriceHistoryGraphDataPoints = { price: asset.InitialValue, frenchFormattedDate: (new Date(asset.InitialSaleDate).formatDDMMYY()) };
            priceHistory.unshift(initalListingPrice);
            if (ADD_ZERO_PRICE_ENTRY_TO_GRAPH)
                priceHistory.unshift(zeroPriceEntry);
        }
        if (priceHistory.length === 1)
            return [...priceHistory, { price: asset.CurrentValueAsNumber, frenchFormattedDate: new Date().formatDDMMYY() }];
        return priceHistory;
    };

    const priceDateRangeHistory: IPriceDateRangeHistory[] = useMemo(() => {
        if (!assetPriceHistory.length)
            return [];
        const currentDate = new Date();
        let finalPriceDateRangeHistory: IPriceDateRangeHistory[] = [];
        let goForwardFlag = true;
        for (let option of DATE_RANGE_OPTIONS) {
            if (goForwardFlag) {
                const priceHistoryOnRange: PriceHistoryInterface[] = assetPriceHistory.filter((ph: PriceHistoryInterface) => option.dateRange > (currentDate.getTime() - ph.time));
                if (priceHistoryOnRange.length <= 0)
                    continue;
                setSelectedDateRange(option.dateRange);
                goForwardFlag = !(assetPriceHistory.length === priceHistoryOnRange.length);
                const shouldShowEqualValues = option.shouldShowEqualValues || priceHistoryOnRange.length <= MINIMUM_EQUAL_VALUE_GRAPH_THRESHOLD;
                const priceHistoryFormatted = Utils.formatPriceHistoryByDay({ ...asset.Asset, priceHistory: priceHistoryOnRange }, !shouldShowEqualValues);
                const priceHistoryFormattedWithDefault = addDefaultPriceHistory(priceHistoryFormatted, currentDate, option.dateRange);
                const priceList = priceHistoryFormattedWithDefault.map(priceHistory => priceHistory.price.toFixed(2));
                const dateList = priceHistoryFormattedWithDefault.map(priceHistory => priceHistory.frenchFormattedDate);
                finalPriceDateRangeHistory.push({ ...option, priceHistory: priceList, dateHistory: dateList });
            }
        }

        return finalPriceDateRangeHistory;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetPriceHistory]);

    const selectedChartConfig = useMemo(() => {
        const selectedPriceDateRangeHistory = priceDateRangeHistory.find(dateRangeHistory => dateRangeHistory.dateRange === selectedDateRange);
        return { priceHistory: selectedPriceDateRangeHistory?.priceHistory ?? [], chartOptions: assetProfilePriceHistoryOptions(selectedPriceDateRangeHistory?.dateHistory ?? []) };
    }, [selectedDateRange, priceDateRangeHistory]);

    const getPriceChangeIcon = (): JSX.Element => {
        return asset.TotalPriceChange >= 0 ? <React.Fragment> <ArrowDropUp /> + </React.Fragment> : <React.Fragment><ArrowDropDown /></React.Fragment>;
    };

    const getPriceChange = () => {
        return `${asset.getEvolutionTotalPriceChangeInPercentage().toFixed(2)}%`;
    };

    const selectAndResetGraph = (selectedRange: number) => {
        batch(() => {
            setSelectedDateRange(selectedRange);
        });
    };

    return (<div className="mixed-chart">
        {selectedChartConfig && <React.Fragment>
            <div className="mixed-chart_header">
                <span className="mixed-chart_title"><Trans i18nKey='assetProfile.buySharesForm.priceEvolution' /></span>
                <span className={clsx('mixed-chart_price_change', { 'fill-red': asset.TotalPriceChange < 0 })}>
                    {getPriceChangeIcon()} {getPriceChange()}
                </span>
            </div>
            <Chart
                options={selectedChartConfig.chartOptions}
                series={[{ data: selectedChartConfig.priceHistory, name: i18next.t('price') }]}
                type="area"
                height={200}
            />
            <div className="mixed-chart_range">
                {priceDateRangeHistory.map((option) => (
                    <span
                        onClick={() => selectAndResetGraph(option.dateRange)}
                        className={clsx({
                            active: option.dateRange === selectedDateRange,
                        })}
                        key={option.dateRange}
                    >
                        <Trans i18nKey={option.label_TranslateKey} />
                    </span>
                ))}
            </div>
        </React.Fragment>}
    </div>);
};

export default React.memo(PriceHistoryGraph);