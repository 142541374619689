import React from 'react'
import { BuyRewardDebtInfoProps } from './BuyRewardDebtInfoProps'
import { withTranslation } from 'react-i18next'
import "./BuyRewardDebtInfo.scss"

const BuyRewardDebtInfo = ({ t: translate }: BuyRewardDebtInfoProps) => {
    return (
        <div className='asset-reward-debt-info'>
            {translate("reward.debt.buy")}
        </div>
    )
}

export default withTranslation()(BuyRewardDebtInfo)