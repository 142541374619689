import { HTTP_STATUS_INTERNAL_ERROR } from 'config/_const';
import i18next from 'i18next';
import { ReduxRootState } from 'model/Redux';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionAcceptRefusedWithdraw } from 'service/WithdrawsAcceptRefuse/actions';
import { IAcceptRefusedWithDrawRequest } from 'service/WithdrawsAcceptRefuse/types';
import { managementModal } from 'service/modal/action';
import { managementNotification } from 'service/notification/action';
import IWithdrawsStoreData from 'service/withdraws/IWithdrawsStoreData';
import { fetchWithdrawRequestsById } from 'service/withdraws/actions';
import { withdrawsSelector } from 'service/withdraws/selector';
import IUseWithdrawRequestProfil from './IUseWithdrawRequestProfil';
import IUseWithdrawnRequestProfilProps from './IUseWithdrawRequestProfilProps';

const useWithdrawRequestProfil = (props: IUseWithdrawnRequestProfilProps): IUseWithdrawRequestProfil => {
    const dispatch = useDispatch();
    const withdraw: ReduxRootState<IWithdrawsStoreData[]> = useSelector(withdrawsSelector);
    const withdrawData: IWithdrawsStoreData | undefined = withdraw.data.find((withdraw: IWithdrawsStoreData) => withdraw && withdraw._id === props.withdrawRequestId);
    const withdrawLoading: boolean = withdraw.loading;
    const [rejectionMessage, setRejectionMessage] = useState<string>('');
    const [refusedMode, setRefusedMode] = useState<boolean>(false);

    const internalBuildWithdrawRequestPayload = (type: string, withdrawData: IWithdrawsStoreData): IAcceptRefusedWithDrawRequest => {
        return {
            withdrawRequetsId: props.withdrawRequestId,
            type,
            rejectionMessage,
            userId: withdrawData.userId._id
        }
    }

    const onRejectWithdraw = () => {
        if (!withdrawData)
            return dispatch(managementNotification(true, 'No withdraw request inside the store', HTTP_STATUS_INTERNAL_ERROR));
        dispatch(actionAcceptRefusedWithdraw(internalBuildWithdrawRequestPayload('REJECT', withdrawData), 'userId'));
    };

    const onAcceptWithdraw = () => {
        if (!withdrawData)
            return dispatch(managementNotification(true, 'No withdraw request inside the store', HTTP_STATUS_INTERNAL_ERROR));
        dispatch(actionAcceptRefusedWithdraw(internalBuildWithdrawRequestPayload('ACCEPT', withdrawData), 'userId'));
    };

    const onFieldClick = (value: string) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                showCancelButton: false,
                showLinkButton: false,
                onRefused: () => dispatch(managementModal(false)),
                textValidButton: i18next.t('withdraw.request.close'),
                onValidation: () => dispatch(managementModal(false)),
                message: <input type="text" value={value} style={{ width: '100%' }} />,
            },
        }));
    };

    useEffect(() => {
        dispatch(fetchWithdrawRequestsById(props.withdrawRequestId, 'userId'));
    }, [dispatch, props.withdrawRequestId]);

    return { withdrawData, withdrawLoading, onRejectWithdraw, onAcceptWithdraw, onFieldClick, rejectionMessage, setRejectionMessage, refusedMode, setRefusedMode };
};

export default useWithdrawRequestProfil;
