import i18n from '18n';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React from 'react';
import './BannerOverlay.scss';

const TournamentRemainingTime: React.FC = () => {
    return (
        <div className="overlay-container display-flex vertical-align">
            <p className="text display-flex vertical-align no-margin">
                <FiberManualRecordIcon/>
                <span>
                    {i18n.t('now-playing')}
                </span>
            </p>
        </div>
    );
};

export default TournamentRemainingTime;
