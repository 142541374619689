import { DeviceInfo as CapacitorDevice, Device } from '@capacitor/device';
import { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';

interface DeviceInfo extends CapacitorDevice {
  isNativeDevice: boolean;
}

export const useDevice = (): DeviceInfo | null => {
  const [device, setDevice] = useState<DeviceInfo | null>(null);

  const updateDevice = async () => {
    const deviceInfo = await Device.getInfo();
    setDevice({
      ...deviceInfo,
      isNativeDevice: Capacitor.isNativePlatform(),
    });
  };

  useEffect(() => {
    updateDevice();
  }, []);

  return device;
};
