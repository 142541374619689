import React from 'react';
import useUserWithdrawalRequests from "hooks/user-withdrawal-requests/useUserWithdrawalRequests";
import IUsePendingWithdrawal from "./IUsePendingWithdrawal";
import WalletWithdrawalViewModel from "view-model/WalletWithdrawalViewModel/WalletWithdrawalViewModel";
import Error from 'components/icons/Error';
import { WITHDRAW_STATUS } from "config/_const";
import clockTimeGif from "assets/images/clock-time.gif";

const usePendingWithdrawal = (withdrawalViewModel: WalletWithdrawalViewModel): IUsePendingWithdrawal => {
    const [isCancelRequestLoading, setIsCancelRequestLoading] = React.useState<boolean>(false);
    const { cancelWithdrawalRequest } = useUserWithdrawalRequests({ getAllAtComponentLoading: false })

    const callBackToStopLoading = () => setIsCancelRequestLoading(false);

    const internalCancelWithdrawRequest = () => {
        if (isCancelRequestLoading)
            return;

        setIsCancelRequestLoading(true);
        cancelWithdrawalRequest(withdrawalViewModel.Id, callBackToStopLoading);
    };

    const renderCardImg = () => <img src={withdrawalViewModel.CardImage} alt="card-img" />

    const renderTopImgIcon = () => withdrawalViewModel.Status === WITHDRAW_STATUS.OPEN
        ? <img src={clockTimeGif} alt="clock-time" className='clock-gif' />
        : <Error />;

    return {
        internalCancelWithdrawRequest,
        isCancelRequestLoading,
        renderCardImg,
        renderTopImgIcon
    };
};

export default usePendingWithdrawal;