import React, { Fragment } from 'react'
import TooltipHelper from '../top-bar/core/TopMenuContent/TooltipHelper/TooltipHelper';
import ListCardProps, { IListCardInformation } from './ListCardProps';
import './ListCard.scss'
import clsx from 'clsx';

const ListCard: React.FC<ListCardProps> = (props: ListCardProps) => {
    return (
        <div className={clsx('list-card',
            { [props.className as string]: Boolean(props.className) }
        )}>
            {props.informationsList.map((info: IListCardInformation) =>
                info.show && !info.hidden &&
                <Fragment key={info.id}>
                    <div className={clsx('information_row', {
                        "justify-center": !Boolean(info.value),
                        faded: info.faded,
                    })}>
                        {info.label && <div className={clsx('information_row_icon-label', {
                            "justify-center": !Boolean(info.value)
                        })}   >
                            {info.icon &&
                                <div className="information_row_icon">
                                    {info.icon}
                                </div>}
                            <div className={clsx('information_row_label', {
                                "justify-center": !Boolean(info.value)
                            })}>
                                {info.label}
                            </div>
                        </div>}
                        {info.value &&
                            <div className="information_row_value">
                                {info.value}
                                {info.about && <TooltipHelper helperMessage={info.about} />}
                            </div>}
                    </div>
                </Fragment>
            )}
        </div>
    );
}

export default React.memo(ListCard);