import { useSelector } from "react-redux";
import IAssetCurrentInformationData from "service/asset-current-information/IAssetCurrentInformationData";
import { RootState } from 'model/Redux';


interface IUseAssetCurrentMarketInfoParam {
    assetId: string;
};

interface IUseAssetCurrentMarketInfo {
    assetCurrentMarketInfo: IAssetCurrentInformationData;
    isAssetCurrentMarketInfoEmpty: boolean;
}

const useAssetCurrentMarketInfo = ({ assetId }: IUseAssetCurrentMarketInfoParam): IUseAssetCurrentMarketInfo => {
    const assetCurrentMarketInfo: IAssetCurrentInformationData = useSelector((state: RootState) => state.assetsCurrentInformation.data[assetId]);
    const isAssetCurrentMarketInfoEmpty: boolean = Boolean(!assetCurrentMarketInfo || (typeof assetCurrentMarketInfo.availableSupply === 'undefined' && typeof assetCurrentMarketInfo.secondaryMarketSupply === 'undefined'))


    return {
        assetCurrentMarketInfo,
        isAssetCurrentMarketInfoEmpty
    }
};

export default useAssetCurrentMarketInfo;