import i18n from '18n';
import { Close, KeyboardArrowDown } from '@material-ui/icons';
import clsx from 'clsx';
import RightFromBracket from 'components/icons/RightFromBracket';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { actionLogoutUser } from 'service/auth/actions';
import Utils from 'utils/Utils';
import Application from 'Application';
import { LEGAL_NOTICE, LEGAL_RATES, MENU_ITEMS_NAME, PRIMARY_SIDE_MENU_COUNT, TERMS_USE_LINKS, TOTAL_AMOUNT_OF_INCOME_PER_REFERRAL, TRENDEX_LANGUAGES, TRENDEX_ROLES, TRENDEX_ROLES_ENUM, VisibilityEnum, ZERO } from '../../../config/_const';
import { MENU_ITEMS } from 'config/menu';
import useIsMobile from 'hooks/useIsMobile';
import MenuInterface from 'model/Menu/MenuInterface';
import { filterByApplicationRestrictions, filterMenuByBetaAppCondition, filterMenuByIsMobile, filterMenuByRoleOrAdminSwitch, filterMenuByVisibility } from 'service/menu/filters';
import CrownIcon from 'components/small/crown-icon/CrownIcon';
import './SideMenu.scss';
import SideMenuProps, { SideBaseMenuProps } from './SideMenuProps';
import { compose } from 'redux';
import withAccount from 'hoc/with-account/withAccount';


const SideMenu: React.FC<SideMenuProps> = (props) => {
    const location = useLocation();
    const isMobile = useIsMobile();
    const dispatch = useDispatch();

    const [menu, setMenu] = useState(MENU_ITEMS);
    const [expandLegalLinks, setExpandLegalLinks] = useState<boolean>(false);

    useEffect(() => {
        props.removeActiveClass();
    }, [location, props]);

    /**
     * Used for scrolling the mainBody to top when url is changed
     */
    useEffect(() => {
        window.scroll({ top: ZERO });
    }, [location.pathname]);


    useEffect(() => {
        const menuByRole = filterMenuByRoleOrAdminSwitch(MENU_ITEMS, TRENDEX_ROLES_ENUM[props.accountViewModel.UserType], props.accountViewModel.IsAdminToVip);
        const menuByIsMobile = filterMenuByIsMobile(menuByRole, isMobile);
        const menuByBetaApp = filterMenuByBetaAppCondition(menuByIsMobile);
        const applicationSpecific = filterByApplicationRestrictions(menuByBetaApp);
        const hiddenElements = filterMenuByVisibility(applicationSpecific);
        const filteredMenu = applicationSpecific.filter(element => !hiddenElements.includes(element));

        const sortedMenu = props.accountViewModel.UserType !== TRENDEX_ROLES.ADMIN ? filteredMenu.sort((a, b) => (a?.sideOrder?.[props.accountViewModel.UserType] || 0) - (b?.sideOrder?.[props.accountViewModel.UserType] || 0)) : filteredMenu;
        const subTypedMenuItems = sortedMenu.filter(item => {
            if (!item.userSubType) return true;
            if (item.userSubType === props.accountViewModel.SubType) return true;
            return false;
        });

        setMenu(subTypedMenuItems);
        if (props.accountViewModel.UserType === TRENDEX_ROLES.VIP && props.accountViewModel.OwnedAssets.length > 0) {
            menu.push({
                title: i18next.t('Menu.myAsset'),
                titleTranslateKey: 'Menu.myAsset',
                url: `/asset/${props.accountViewModel.OwnedAssets[0].slug}`,
                Icon: CrownIcon,
                accessRole: [TRENDEX_ROLES_ENUM.VIP, TRENDEX_ROLES_ENUM.USER],
                visibility: [VisibilityEnum.SIDE_DESKTOP, VisibilityEnum.SIDE_MOBILE],
                sideOrder: {
                    VIP: 7,
                },
                guestAccess: false,
                name: MENU_ITEMS_NAME.VIP_ASSET,
            });
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isMobile, props.accountViewModel.UserType, props.accountViewModel.IsAdminToVip, props.accountViewModel.OwnedAssets, props.accountViewModel.SubType],
    );

    useEffect(() => {
        if (!expandLegalLinks) return;
        const selectedDivElement = props.sideMenuDivRef?.current;
        if (selectedDivElement) selectedDivElement.scrollTo({
            top: selectedDivElement?.scrollHeight || 0,
            behavior: "auto"
        });
    }, [expandLegalLinks, props.sideMenuDivRef]);

    return (
        <div className="side_menulinks">
            <div className="sidemenu_content">
                <div className="profile_logosec">
                    {!props.accountViewModel.IsOnboardingRunning ?
                        <Link to={'/top-50'}>
                            <div className="sidemenu_logo">
                                <img id="img-logo" src={Application.getInstance().darkMenuLogo} alt="Logo HERE" className="img-fluid" />
                            </div>
                        </Link>
                        :
                        <div className="sidemenu_logo">
                            <img id="img-logo" src={Application.getInstance().darkMenuLogo} alt="Logo HERE" className="img-fluid" />
                        </div>
                    }

                    <div onClick={() => props.removeActiveClass()} className="close_menusec">
                        <Close />
                    </div>
                </div>

                <div className="sidemenu_links_sec">
                    <div className="sidemenu_widget">
                        {menu.map((item: MenuInterface, key: number) => {
                            const Icon: React.ElementType = item.Icon;

                            if (!props.accountViewModel.isAuthenticated && !item.guestAccess)
                                return false;

                            return (
                                <NavLink
                                    key={key}
                                    to={item.isExternalLink ? { pathname: `${item.url}` } : item.url}
                                    target={item.isExternalLink ? "_blank" : "_self"}
                                    className={clsx({
                                        'link-gold': item.name === MENU_ITEMS_NAME.AFFILIATION,
                                        "primary-menu-item": !isMobile && key < PRIMARY_SIDE_MENU_COUNT,
                                        "primary-menu-item_end": key === PRIMARY_SIDE_MENU_COUNT - 1,
                                        "disabled": item.disableForOnboarding && Application.getInstance().OnBoardingV2 && props.accountViewModel.IsOnboardingRunning,
                                    })}
                                >
                                    {Icon && <Icon />} {item.name === MENU_ITEMS_NAME.AFFILIATION ?
                                        <span className='link-gold-text'>
                                            <Trans
                                                i18nKey={item.titleTranslateKey}
                                                components={[<br key="br" />]}
                                                values={{
                                                    amount: TOTAL_AMOUNT_OF_INCOME_PER_REFERRAL.toCurrency(0)
                                                }} />
                                        </span>
                                        : i18n.t(item.titleTranslateKey)
                                    }
                                    {item.extraFields &&
                                        <div className='extrafield'>
                                            <item.extraFields.icon />
                                            <span className="extra-field">{item.extraFields.text}</span>
                                        </div>
                                    }

                                </NavLink>
                            );
                        })}
                    </div>
                    {props.accountViewModel.isAuthenticated && <div className="container-side-menu-deconnexion">
                        <Link to="/"><button className="btn-logout" onClick={() => dispatch(actionLogoutUser())}><RightFromBracket />{i18next.t('logout')}</button></Link>
                    </div>}

                </div>
            </div>

            {Application.getInstance().showLegalNotice &&
                <div className="legal_links sidemenu_footer" >
                    <div className="legal_links-title" onClick={() => setExpandLegalLinks(prev => !prev)}>
                        {i18n.t("sidemenu.termsAndConditions")}
                        <KeyboardArrowDown className={clsx({ "rotate": expandLegalLinks })} />
                    </div>
                    {expandLegalLinks &&
                        <div className={clsx("legal_links-container", { show: expandLegalLinks })}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={TERMS_USE_LINKS[Utils.getCurrentLanguage() as 'fr' | 'en'] ?? TERMS_USE_LINKS[TRENDEX_LANGUAGES.ENGLISH]} >
                                {i18next.t('Footer.termsofuse')}
                            </a>
                            <br />
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={'https://tdx-legal.s3.eu-west-3.amazonaws.com/Privacy+Policy+Trendex.pdf'} >
                                {i18next.t('Footer.privacypolicy')}
                            </a>
                            <br />
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={LEGAL_NOTICE[Utils.getCurrentLanguage() as 'fr' | 'en'] ?? LEGAL_NOTICE[TRENDEX_LANGUAGES.ENGLISH]} >
                                {i18next.t('Footer.legalnotice')}
                            </a>
                            <br />
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={LEGAL_RATES[Utils.getCurrentLanguage() as 'fr' | 'en'] ?? LEGAL_RATES[TRENDEX_LANGUAGES.ENGLISH]} >
                                {i18next.t('Footer.tarif')}
                            </a>
                        </div>
                    }
                </div>}
        </div>
    );
};

export default compose<React.FC<SideBaseMenuProps>>(
    withAccount
)(SideMenu);
