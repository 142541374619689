import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import * as ActionTypeAcceptRefuseWithDraw from './actionsType';
import AxiosConfig from '../../config/axiosConfig';
import { showNotification } from '../notification/action';
import { IHttpResponse } from '../../model/IHttpResponse';
import { IAcceptRefusedWithDrawRequest } from './types';

export const actionAcceptRefusedWithdraw = ({ type, userId, withdrawRequetsId, rejectionMessage }: IAcceptRefusedWithDrawRequest, populate?: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({
        type: ActionTypeAcceptRefuseWithDraw.ACCEPT_REFUSE_WITHDRAWS_LOADING,
    });
    let queries = '';

    if (populate) {

        queries += `&with=${populate}`;
    }

    try {

        let url = 'reject';
        let body = {
            _id: withdrawRequetsId,
            rejectionMessage: '',
            userId,
        };

        if (type === 'ACCEPT') {

            url = 'accept';
        } else if (type === 'REJECT') {

            body = {
                ...body,
                rejectionMessage: rejectionMessage || '',
            };
        }

        const response: IHttpResponse = await AxiosConfig.post(`/withdraw-requests/${url}?${queries}`, body);

        if (response && response.data && response.data.status !== 200) {

            showNotification(dispatch, response.data.message, response.data.status);

            return dispatch({
                type: ActionTypeAcceptRefuseWithDraw.ACCEPT_REFUSE_WITHDRAWS_ERROR,
            });
        }
        if (response && response.data && response.data.status === 200) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: ActionTypeAcceptRefuseWithDraw.ACCEPT_REFUSE_WITHDRAWS_SUCCESS,
                payload: {
                    status: response.data
                },
            });
        }

    } catch (error) {
        console.log('error: ', error);

        return dispatch({
            type: ActionTypeAcceptRefuseWithDraw.ACCEPT_REFUSE_WITHDRAWS_ERROR,
        });
    }
};
