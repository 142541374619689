import Application from 'Application';
import clsx from 'clsx';
import { HUNDRED_PERCENT, ZERO_PERCENT } from 'config/_const';
import { TournamentLeagues } from 'config/_enums';
import { TournamentLeagueIcon } from 'data/TournamentImages/tournamentImages.data';
import React from 'react';
import LeagueViewModel from 'view-model/League/LeagueViewModel/LeagueViewModel';
import './LeagueProgressMap.scss';
import LeagueProgressMapProps from './LeagueProgressMapProps';

const LeagueProgressMap: React.FC<LeagueProgressMapProps> = (props) => {
    const tournamentLeagueInAsc: LeagueViewModel[] = Application.getInstance().getSortedTournamentLeagues();
    const indexOfCurrentLeague: number = tournamentLeagueInAsc.findIndex(league => league.Name === props.userLeagueViewModel.LeagueAttribution.Name);
    const lastIndexOfTournamentLeague: number = (tournamentLeagueInAsc.length - 1);

    return (
        <div className="league-progress-map">
            {tournamentLeagueInAsc.map((league, index) => {
                const LeagueIcon = TournamentLeagueIcon[league.Name as TournamentLeagues];
                const getShadedPercentage = () => {
                    if (index < indexOfCurrentLeague) return HUNDRED_PERCENT;
                    else if (index === indexOfCurrentLeague) return props.userLeagueViewModel.ProgressPercentageFromCurrentToHigherLeague;
                    return ZERO_PERCENT;
                }

                return <>
                    <div className={clsx('individual-league',
                        { 'locked-league': index > indexOfCurrentLeague }
                    )}>
                        <div className="league-icon-size">
                            <LeagueIcon />
                        </div>
                        {
                            (index !== lastIndexOfTournamentLeague) &&
                            <div className='progress-bar-line'>
                                <div className="progress-bar-line__shaded" style={{ width: getShadedPercentage() }}>
                                </div>
                            </div>
                        }
                    </div>

                </>
            })
            }

        </div>
    )
}

export default LeagueProgressMap;