import ExplanationImg from "assets/images/Affiliate/Explaination popup_illu2.png";
import PopUpImg from "assets/images/Affiliate/Pop up explaination_illu1.png";
import i18next from 'i18next';
import React from 'react';
import { GODFATHER_REFERRAL } from 'config/_const';
import './KnowMoreMessage.scss';

const KnowMoreMessage: React.FC = () => {
    return (
        <div className="know-more-message">
            <img src={PopUpImg} alt="explanation-image" className="explainer-img1" />
            <h5>{i18next.t('Affiliate.explaination.title1')},</h5>
            <p>{i18next.t('Affiliate.explaination.text1', { percent: GODFATHER_REFERRAL.percentageDueByGodson })}</p>
            <img src={ExplanationImg} alt="explanation-image" className="explainer-img2" />
            <h5>{i18next.t('Affiliate.explaination.title2')},</h5>
            <p>{i18next.t('Affiliate.explaination.text2')}</p>
        </div>);
};

export default KnowMoreMessage;
