import Application from '../../Application';
import Translation from '../Achievement/Translation';
import IBuyPacksButtonViewModelParams from './IBuyPacksButtonViewModelParams';

export default class BuyPacksButtonViewModel {
    static readonly TOTAL_PACKS: number = Application.getInstance().PacksConfiguration.numberOfPacksAvailable;
    static readonly TOKENS_IN_PACK: number = Application.getInstance().PacksConfiguration.tokens;

    private packsSold: number;
    private totalPacksSold: boolean;
    private userLeagueIsWhiteListed: boolean;
    private customBenzemaProfilBeforePublicSale: boolean;

    constructor(args: IBuyPacksButtonViewModelParams) {
        this.packsSold = args.packsSold;
        this.totalPacksSold = args.packsSold >= BuyPacksButtonViewModel.TOTAL_PACKS;
        this.userLeagueIsWhiteListed = args.userLeagueIsWhiteListed;
        this.customBenzemaProfilBeforePublicSale = args.customBenzemaProfilBeforePublicSale;
    }

    public get PacksSold(): number {
        return this.packsSold || 33; //dirty default for launch @todo remove later
    }

    public get TotalPacksSold(): boolean {
        return this.totalPacksSold;
    }

    public get UserLeagueIsWhiteListed(): boolean {
        return this.userLeagueIsWhiteListed;
    }

    public get TitleTranslation(): Translation {
        if (this.TotalPacksSold)
            return new Translation("buy.packs.button.title.complete", {});
        return new Translation("buy.packs.button.title", {});
    }

    public get SubtitleTranslation(): Translation {
        if (this.TotalPacksSold)
            return new Translation(this.UserLeagueIsWhiteListed ? "buy.packs.button.subtitle.complete.whitelisted" : "buy.packs.button.subtitle.complete", {});
        return new Translation(!this.customBenzemaProfilBeforePublicSale ? "buy.packs.button.subtitle" : "benzema.custom-profil.buy.packs.button.subtitle", { packsLeft: 52 /*BuyPacksButtonViewModel.TOTAL_PACKS - this.PacksSold_*/, amountInPack: BuyPacksButtonViewModel.TOKENS_IN_PACK })
    }
}