import clsx from 'clsx';
import PlusIcon from 'components/icons/Plus';
import Shares from 'components/icons/Shares';
import FilledSlot from 'components/small/team/filled-slot/FilledSlot';
import withAccount from 'hoc/with-account/withAccount';
import withAssetsSelection from 'hoc/with-assets-selection/withAssetsSelection';
import { ICategoryTypeName } from 'interfaces/IAssetBgImages';
import React from 'react';
import { compose } from 'redux';
import { showAssetScore } from 'service/teams/helper';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import AvatarViewModel from 'view-model/AvatarViewModel/AvatarViewModel';
import IAssetsSelectionProps, { IAssetsSelectionPropsBase } from './IAssetsSelectionProps';
import './style.scss';

const AssetsSelection: React.FC<IAssetsSelectionProps> = ({ userAssets, categories, walletViewModel, teamBuildingViewModel, onSlotSelected, accountViewModel, nextTournament, tournamentInProgress }) => {
    const { showTrendScore, showSportsScore } = showAssetScore(nextTournament, tournamentInProgress);

    return (
        <div className="owned-assets">
            {userAssets.map((entry) => {
                return (
                    <div key={entry.levelName} className="container-box">
                        <div className="level-name-container">
                            <h3 className="level-name">
                                {entry.levelName}: <span className='level-name__total-token'>{entry.amount}<Shares /></span>
                            </h3>
                            <span className="level-total-card">({entry.countAsset})</span>
                        </div>
                        <div className="assets-box">{entry.assets.map((assetViewModel: AssetViewModel) => {
                            const numberOfSharesToPutInTeamAsset: number = teamBuildingViewModel.getNumberOfSharesToPutInTeamAsset(walletViewModel.UserAssets, assetViewModel.AssetId);
                            const assetAvailableForTournament: boolean = walletViewModel.isOwnedAssetAvailableForTeam(accountViewModel, assetViewModel.AssetId, nextTournament?.Category ?? tournamentInProgress?.Category);
                            const avatarBackground: AvatarViewModel = new AvatarViewModel({ asset: { avatarPath: assetViewModel.AvatarPath, mainCategoryId: assetViewModel.MainCategoryId, cardLevel: assetViewModel.CardLevel }, assetCategories: categories })
                            const category: ICategoryTypeName = assetViewModel.getCategoryName(categories) as ICategoryTypeName;

                            return <div className={clsx('item', {
                                opacity: !assetAvailableForTournament,
                            })} key={assetViewModel.AssetId}>
                                <FilledSlot
                                    hasFocus={false}
                                    applyStackedEffect={false}
                                    assetViewModel={assetViewModel}
                                    numberOfShares={teamBuildingViewModel.getNumberOfRemainingSharesByAsset(assetViewModel.AssetId, walletViewModel.getOwnedSharesByAssetId(assetViewModel.AssetId))}
                                    onSlotSelected={() => onSlotSelected(assetViewModel, numberOfSharesToPutInTeamAsset)}
                                    backgroundImgPath={avatarBackground.BackgroundImage}
                                    borderColor={avatarBackground.Color}
                                    showGetButton={assetAvailableForTournament}
                                    showRemoveIcon={false}
                                    textButton="TeamManagement.button.select"
                                    iconButton={<PlusIcon />}
                                    category={category}
                                    canShowSportScore={assetViewModel.canShowSportScore(categories) && showSportsScore && assetAvailableForTournament}
                                    showTokenPerSlot={false}
                                    canShowTrendScore={showTrendScore && assetViewModel.ShowTrendScore}
                                />
                            </div>;
                        })}</div>
                    </div>
                )
            })}
        </div>
    );
};

export default compose<React.FC<IAssetsSelectionPropsBase>>(
    withAssetsSelection,
    withAccount,
)(AssetsSelection);
