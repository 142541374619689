import { ZERO } from "config/_const";
import ITeamAsset from "model/Team/ITeamAsset";
import TournamentSubscriptionViewModel from "view-model/Tournament/TournamentSubscriptionsViewModel/TournamentSubscriptionViewModel";

export const sortTournamentSubscriptionsByTeamScore = (subscriptions: TournamentSubscriptionViewModel[]): TournamentSubscriptionViewModel[] => {
    if (subscriptions.length === 0)
        return [];

    return subscriptions.sort((subscriptionA: TournamentSubscriptionViewModel, subscriptionB: TournamentSubscriptionViewModel) => {
        const magicRatio: number = 100000;
        const subscriptionTeamScoreA: number = subscriptionA.TeamScoreAverage.multiply(magicRatio);
        const subscriptionTeamScoreB: number = subscriptionB.TeamScoreAverage.multiply(magicRatio);
        return subscriptionTeamScoreA > subscriptionTeamScoreB ? -1 : 1;
    });
}

export const tournamentSubscriptionsSorter = (subscriptions: TournamentSubscriptionViewModel[]): TournamentSubscriptionViewModel[] => {
    if (subscriptions.length === 0)
        return [];
    const magicRatio: number = 100000;
    const subscriptionSortedByTeamScore: TournamentSubscriptionViewModel[] = sortTournamentSubscriptionsByTeamScore(subscriptions);
    return subscriptionSortedByTeamScore.sort((subscriptionA: TournamentSubscriptionViewModel, subscriptionB: TournamentSubscriptionViewModel) => {
        const subscriptionTeamScoreA: number = subscriptionA.TeamScoreAverage.multiply(magicRatio);
        const subscriptionTeamScoreB: number = subscriptionB.TeamScoreAverage.multiply(magicRatio);
        if (subscriptionTeamScoreA !== subscriptionTeamScoreB)
            return ZERO;
        if (subscriptionA.TotalRecruitmentPointsAtSubscriptionTime && subscriptionB.TotalRecruitmentPointsAtSubscriptionTime)
            if (subscriptionA.TotalRecruitmentPointsAtSubscriptionTime !== subscriptionB.TotalRecruitmentPointsAtSubscriptionTime)
                return subscriptionA.TotalRecruitmentPointsAtSubscriptionTime > subscriptionB.TotalRecruitmentPointsAtSubscriptionTime ? -1 : 1;

        const numberOfSharesForEntryA = subscriptionA.Team.teamAssets.map((teamAsset: ITeamAsset) => teamAsset.numberOfShares).reduce((accumulator: number, b) => accumulator.addition(b), ZERO);
        const numberOfSharesForEntryB = subscriptionB.Team.teamAssets.map((teamAsset: ITeamAsset) => teamAsset.numberOfShares).reduce((accumulator: number, b) => accumulator.addition(b), ZERO);
        return numberOfSharesForEntryA > numberOfSharesForEntryB ? -1 : 1;
    });
}