import ReduxActionInterface from "model/ReduxActionInterface";
import SlidingPageAction from "./actionType";
import ISlidingPageStore from "model/SlidingPages/ISlidingPageStore";

const ROOT_VALUE_STATE: ISlidingPageStore = {
    display: false,
    verticalSlide: false,
}

const slidingPagesReducer = (state: ISlidingPageStore = ROOT_VALUE_STATE, action: ReduxActionInterface): ISlidingPageStore => {
    switch (action.type) {
        case SlidingPageAction.OPEN_SLIDING_PAGE:
            return {
                ...state,
                ...action.payload,
                display: true,
            }
        case SlidingPageAction.DISMISS_SLIDING_PAGE:
            return{ ...ROOT_VALUE_STATE, verticalSlide: state.verticalSlide};
        default: return state;
    }
}

export default slidingPagesReducer;