import { ZERO } from 'config/_const';
import IFootballStatisticsPass from 'interfaces/sports/football/statistics/IFootballStatisticsPass';

export default class FootballStatisticsPassViewModel {
    private total: number;
    private key: number;
    private accuracy: number;

    constructor(statistics: Partial<IFootballStatisticsPass>) {
        this.total = statistics.total ?? ZERO;
        this.key = statistics.key ?? ZERO;
        this.accuracy = statistics.accuracy ?? ZERO;
    }

    get Total(): number {
        return this.total;
    }

    get Key(): number {
        return this.key;
    }

    get Accuracy(): number {
        return this.accuracy;
    }
}
