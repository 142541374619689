import PrimaryButton from "components/small/buttons/primary-button/PrimaryButton";
import CountdownWidget from "components/small/countdown-widget/CountdownWidget";
import ScoreLabel from "components/small/score-label/ScoreLabel";
import UnfilledSlot from "components/small/team/unfilled-slot/UnfilledSlot";
import { TOURNAMENT_CATEGORY } from "config/_const";
import useAssetsCategories from "hooks/asset-categories/useAssetCategories";
import IUseTournament from "hooks/tournament/use-tournament/IUseTournament";
import useTournament from "hooks/tournament/use-tournament/useTournament";
import useUserTournamentSubscription from "hooks/tournament/use-user-tournament-subscription/useUserTournamentSubscription";
import AssetInterface from "model/AssetModel/AssetInterface";
import ITeamAsset from "model/Team/ITeamAsset";
import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import AssetViewModel from "view-model/Assets/AssetViewModel";
import AvatarViewModel from "view-model/AvatarViewModel/AvatarViewModel";
import TournamentViewModel from "view-model/Tournament/TournamentViewModel/TournamentViewModel";
import IWalletTeamViewProps from "./IWalletTeamViewProps";

const WalletTeamView: React.FC<IWalletTeamViewProps> = ({ onWalletClose, currentTournament, teamBuildingViewModel, walletViewModel }: IWalletTeamViewProps) => {
    const { assetsCategories } = useAssetsCategories();
    const { nextTournament, tournamentInProgress }: IUseTournament = useTournament();
    const { userAlreadyRegisteredForTournament, currentUserSubscription } = useUserTournamentSubscription({ tournamentId: currentTournament.Id });

    const TournamentInfo = ({ nextTournament }: { nextTournament: TournamentViewModel }) => (
        <>
            <CountdownWidget date={nextTournament.SubscriptionEndDate} titleTranslationKey="tournament-results.next-tournament-soon" />
            <div className='team-widget_tournament_subscription'>
                {!userAlreadyRegisteredForTournament ? (
                    <Link to='/tournament'><PrimaryButton textButton={<Trans i18nKey='wallet.tournament.next.subscribe' />} receiveClickEvent={false} type={'button'} disabled={false} handleClick={() => onWalletClose()} /></Link>
                ) : (
                    <Trans i18nKey='wallet.tournament.next.already-subscribe' />
                )}
            </div>
        </>
    )

    if (teamBuildingViewModel.teamAssets.length !== teamBuildingViewModel.MaxNumberOfAssetsInTeam)
        return null;
    return (
        <div className='team-widget'>
            <div className='team-widget_header'>
                <div className='team-widget_header_title'><Trans i18nKey="wallet.team.pick" /></div>
                <div className='team-widget_header_score'>
                    <Trans i18nKey='score' />:
                    <ScoreLabel score={teamBuildingViewModel.computeTeamsScores(walletViewModel.getAssetsFromWallet(), assetsCategories, currentTournament?.Category as TOURNAMENT_CATEGORY, currentUserSubscription)} canShowBoost={false} />
                </div>
            </div>
            <div className='team-widget_body'>
                <div className='team-widget_body_assets-list'>
                    {teamBuildingViewModel.teamAssets.map((teamAsset: ITeamAsset) => {
                        const asset: AssetInterface | undefined = walletViewModel.getWalletAssetById(teamAsset.asset)?.asset;
                        if (!asset)
                            return (
                                <div key={teamAsset.asset} className="team-members">
                                    <UnfilledSlot showAddButton={false} />
                                </div>
                            )
                        const assetViewModel: AssetViewModel = new AssetViewModel(asset);
                        const avatarBackground: AvatarViewModel = new AvatarViewModel({ asset: { avatarPath: assetViewModel.AvatarPath, mainCategoryId: assetViewModel.MainCategoryId, cardLevel: assetViewModel.CardLevel }, assetCategories: assetsCategories })
                        return (
                            <div className='team-asset' key={teamAsset._id} style={{ background: avatarBackground.Color }}>
                                <img src={avatarBackground.BackgroundImage} alt='background' className="background" />
                                <img src={avatarBackground.AvatarImage} alt='avatar' />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='team-widget_tournament'>
                {nextTournament ? (
                    <TournamentInfo nextTournament={nextTournament} />
                ) : tournamentInProgress ? (
                    <CountdownWidget date={tournamentInProgress.EndDate} daysInHours={true} titleTranslationKey="tournament-ends-in" />
                ) : (
                    <label className="title">
                        <Trans i18nKey="next-tournament-soon" />
                    </label>
                )}
            </div>
        </div>
    )
}

export default React.memo(WalletTeamView);