import i18n from '18n';
import { HTTP_STATUS_OK } from 'config/_const';
import AxiosConfig from 'config/axiosConfig';
import { TOURNAMENT_SUBSCRIPTION_ENDPOINTS } from 'config/endpoints';
import type { IHttpStrongTypedResponse } from 'model/IHttpResponse';
import type { ITournamentSubscription, ITournamentSubscriptionActionParams } from 'model/Tournament/ITournamentSubscription';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SegmentTracking } from 'service/segment/SegmentTracking';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import { G_TAGS_KNOWN_EVENTS, HTTP_STATUS_INTERNAL_ERROR } from './../../config/_const';
import { showNotification } from './../notification/action';
import * as TournamentSubscriptionActionType from './actionsType';

export const getSubscriptions = (tournamentId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TournamentSubscriptionActionType.GET_SUBSCRIPTIONS.LOADING });
        const getSubscriptionsResponse: IHttpStrongTypedResponse<ITournamentSubscription[]> = await AxiosConfig.get(`${TOURNAMENT_SUBSCRIPTION_ENDPOINTS.GET_SUBSCRIPTIONS}/${tournamentId}`);
        if (getSubscriptionsResponse.data.status !== HTTP_STATUS_OK)
            throw new Error(getSubscriptionsResponse.data.message);
        return dispatch({
            type: TournamentSubscriptionActionType.GET_SUBSCRIPTIONS.SUCCESS,
            payload: {
                tournamentId,
                subscriptions: getSubscriptionsResponse.data.data,
            },
        });
    } catch (error) {
        let message = 'Server.tournamentSubscription.get.generic-error';
        if (error instanceof TypeError)
            message = error.message;
        return dispatch({
            type: TournamentSubscriptionActionType.GET_SUBSCRIPTIONS.ERROR,
            payload: {
                message,
            },
        });
    }
};

export const dispatchSuccessSubscriptionToTournament = (tournamentSubscriptionResponse: IHttpStrongTypedResponse<ITournamentSubscription[]>, subscription: ITournamentSubscriptionActionParams, onSuccessCallback?: () => void): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.TOURNAMENT_SUBSCRIBE, { tournamentId: subscription.tournament, teamId: subscription.team });
    SegmentTracking.sendTournamentSubscriptionEvent({ subscription });
    onSuccessCallback?.();
    return dispatch({
        type: TournamentSubscriptionActionType.SUBSCRIBE.SUCCESS,
        payload: {
            subscription: tournamentSubscriptionResponse.data.data,
        },
    });
}

export const subscribeToTournamentAction = (subscription: ITournamentSubscriptionActionParams, onSuccessCallback?: () => void): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: TournamentSubscriptionActionType.SUBSCRIBE.LOADING });
        const tournamentSubscriptionResponse: IHttpStrongTypedResponse<ITournamentSubscription[]> = await AxiosConfig.post(`${TOURNAMENT_SUBSCRIPTION_ENDPOINTS.SUBSCRIBE}`, { subscription });
        if (tournamentSubscriptionResponse.data.status !== HTTP_STATUS_OK)
            throw new Error(tournamentSubscriptionResponse.data.message);
        showNotification(dispatch, i18n.t('tournament.subscribe.tournament'), tournamentSubscriptionResponse.data.status);
        return dispatch(dispatchSuccessSubscriptionToTournament(tournamentSubscriptionResponse, subscription, onSuccessCallback));
    } catch (error) {
        const message = error instanceof Error ? error.message : 'Server.users.validateAccount.generic-error';
        showNotification(dispatch, i18n.t(message), HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: TournamentSubscriptionActionType.SUBSCRIBE.ERROR,
            payload: {
                message,
            },
        });
    }
};

export const getUserSubscriptionPerTournament = (tournamentId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const queryRequest: string = `tournamentId=${tournamentId}`;
        dispatch({ type: TournamentSubscriptionActionType.GET_SUBSCRIPTIONS.LOADING });
        const getUserSubscriptionPerTournamentResponse: IHttpStrongTypedResponse<ITournamentSubscription> = await AxiosConfig.get(`${TOURNAMENT_SUBSCRIPTION_ENDPOINTS.GET_USER_SUBSCRIPTION_PER_TOURNAMENT}?${queryRequest}`);
        if (getUserSubscriptionPerTournamentResponse.data.status !== HTTP_STATUS_OK)
            throw new Error(getUserSubscriptionPerTournamentResponse.data.message);
        return dispatch({
            type: TournamentSubscriptionActionType.GET_USER_SUBSCRIPTION.SUCCESS,
            payload: {
                tournamentId,
                subscription: getUserSubscriptionPerTournamentResponse.data.data,
            },
        });
    } catch (error) {
        let message = 'Server.tournamentSubscription.get.generic-error';
        if (error instanceof TypeError)
            message = error.message;
        return dispatch({
            type: TournamentSubscriptionActionType.GET_SUBSCRIPTIONS.ERROR,
            payload: {
                message,
            },
        });
    }
};
