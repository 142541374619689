import Podium from 'components/icons/Podium';
import LeagueHeader from 'components/small/league-header/LeagueHeader';
import withTournamentResults from 'hoc/with-tournament-results/withTournamentResults';
import IUseTournamentOff from 'hooks/tournament/use-tournament-off/IUseTournamentOff';
import useTournamentOff from 'hooks/tournament/use-tournament-off/useTournamentOff';
import TournamentUserCard from 'page/Tournament/TournamentUserCard/TournamentUserCard';
import React from 'react';
import { Trans } from 'react-i18next';
import TournamentResultViewModel from 'view-model/Tournament/TournamentResultViewModel/TournamentResultViewModel';
import ITournamentResultsProps from './ITournamentResultsProps';
import './style.scss';
import useUserTournamentSubscription from 'hooks/tournament/use-user-tournament-subscription/useUserTournamentSubscription';

const TournamentResults: React.FC<ITournamentResultsProps> = ({ tournamentResults, activeLeague, tournament }: ITournamentResultsProps) => {
    const { tournamentResultOfUser, defaultPodiumSize, userId, tournamentResultsWithNoLeague }: IUseTournamentOff = useTournamentOff({ tournamentResults, tournamentPrizePool: tournament.PrizePool, activeLeague });
    const { userAlreadyRegisteredForTournament } = useUserTournamentSubscription({ tournamentId: tournament.Id, });

    return (
        <div className="top-portfolio">
            <div className="libelle-tournament-results">
                <Podium width={defaultPodiumSize} height={defaultPodiumSize} />
                <Trans i18nKey='tournament-results.label' values={{
                    category: tournament?.getTournamentCategory()
                }} />
                {!tournamentResultsWithNoLeague && tournamentResultOfUser &&
                    <TournamentUserCard
                        isUserAlreadyJoinedTournament={userAlreadyRegisteredForTournament}
                        key={tournamentResultOfUser.User.Id}
                        kind={tournamentResultOfUser.Reward.Kind}
                        userId={tournamentResultOfUser.User.Id}
                        isResultOfConnectedUser={tournamentResultOfUser.User.Id === userId}
                        userAvatar={tournamentResultOfUser.User.avatar}
                        place={tournamentResultOfUser.Place}
                        teamScore={tournamentResultOfUser.Score.countDecimals() > 2 ? tournamentResultOfUser.Score.roundNumber() : tournamentResultOfUser.Score}
                        username={tournamentResultOfUser.User.username()}
                        numberOfTokensInTeam={tournamentResultOfUser.TokensInTeam}
                        totalAssetsOwnedAtSubscriptionTime={tournamentResultOfUser.TotalAssetsOwnedAtSubscriptionTime}
                        cashprize={tournamentResultOfUser.Reward.FormattedFiatAmount}
                        isTournamentResultCard={true}
                        isTeamScoreBoosted={false}
                    />
                }
            </div>

            <LeagueHeader league={activeLeague} />

            {tournamentResults.map((tournamentResult: TournamentResultViewModel) => {
                return (<TournamentUserCard
                    isUserAlreadyJoinedTournament={userAlreadyRegisteredForTournament}
                    key={tournamentResult.User.Id}
                    kind={tournamentResult.Reward.Kind}
                    userId={tournamentResult.User.Id}
                    numberOfTokensInTeam={tournamentResult.TokensInTeam}
                    isResultOfConnectedUser={false}
                    userAvatar={tournamentResult.User.avatar}
                    place={tournamentResult.Place}
                    teamScore={tournamentResult.Score.countDecimals() > 2 ? tournamentResult.Score.roundNumber() : tournamentResult.Score}
                    username={tournamentResult.User.username()}
                    totalAssetsOwnedAtSubscriptionTime={tournamentResult.TotalAssetsOwnedAtSubscriptionTime}
                    cashprize={tournamentResult.Reward.FormattedFiatAmount}
                    isTournamentResultCard={true}
                    isTeamScoreBoosted={false}
                />);
            })}
        </div>
    );
};

export default withTournamentResults(TournamentResults);
