import i18n from "18n";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInitialSaleDateOfAsset } from "service/assets/selectors";
import { accountAsViewModelSelector } from "service/auth/selectors";
import { managementModal } from "service/modal/action";
import { cancelOrder } from "service/order/actions";
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import IUseOrderCard from "./IUseOrderCard";
import IUseOrderCardProps from "./IUseOrderCardProps";

const useOrderCard = ({ orderViewModel }: IUseOrderCardProps): IUseOrderCard => {
    const dispatch = useDispatch();
    const account: AccountViewModel = useSelector(accountAsViewModelSelector);
    const [isCancellingOrder, setCancellingOrder] = useState<boolean>(false)
    const initialSaleDate: string = useSelector(state => getInitialSaleDateOfAsset(state, orderViewModel.relatedAsset?._id ?? ''));

    const isRewardNextDay = (): boolean => {
        if (!initialSaleDate)
            return false;
        const momentInitialDate: moment.Moment = moment(initialSaleDate).startOf('day');
        const momentNow: moment.Moment = moment().startOf('day');
        const numberOfDaysInCurrentMonth: number = momentNow.daysInMonth();
        const numberOfDaysInMonthFromAsset: number = momentInitialDate.daysInMonth();
        const currentDayToInteger: number = parseInt(momentNow.format('DD'));
        const initialSaleDateDayToInteger: number = parseInt(momentInitialDate.format('DD'));
        const isRewardNextDay: boolean = currentDayToInteger === initialSaleDateDayToInteger - 1;
        if (numberOfDaysInCurrentMonth === numberOfDaysInMonthFromAsset || numberOfDaysInCurrentMonth > numberOfDaysInMonthFromAsset)
            return isRewardNextDay;
        const nowIsTheLastDayOfMonth = currentDayToInteger === numberOfDaysInCurrentMonth;
        if (numberOfDaysInMonthFromAsset > numberOfDaysInCurrentMonth)
            return initialSaleDateDayToInteger > numberOfDaysInCurrentMonth ? nowIsTheLastDayOfMonth : isRewardNextDay
        return false;
    }

    const internalCancelOrder = () => {
        if (isCancellingOrder)
            return;
        return orderViewModel.isSellOrder ? dispatchCancelOrder() : dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            showCancelButton: true,
            fullScreen: false,
            type: 'CONFIRMATION_MODAL',
            propsCustom: {
                hideCancelBtn: false,
                fullButtons: false,
                onRefused: () => dispatch(managementModal(false)),
                onValidation: () => {
                    dispatch(managementModal(false));
                    dispatchCancelOrder();
                },
                message: i18n.t('secondaryMarketCancelBuyingOrderConfirmMessage'),
            },
        }));
    };

    const dispatchCancelOrder = (_callback: Function | null = null): void => {
        dispatch(cancelOrder(orderViewModel.id, orderViewModel.relatedAsset._id, account.Id, orderViewModel.side, false));
        setCancellingOrder(true);
    };

    const renderYieldWarning = (): JSX.Element | undefined => {
        if (!isRewardNextDay() || !orderViewModel.relatedAsset.yieldEnabled || !account.shouldShowYield())
            return;
        return (
            <span className="wallet-transaction-yield">
                {i18n.t('order.yield.warning')}
            </span>
        );
    };

    return { isCancellingOrder, internalCancelOrder, renderYieldWarning };
};

export default useOrderCard;