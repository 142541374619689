import LeagueAttribution from 'model/League/LeagueAttribution';
import { getDateToString } from 'utils/date';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import Translation from 'view-model/Achievement/Translation';
import AssetViewModel from 'view-model/Assets/AssetViewModel';

export default class WhiteListDisclaimerViewModel {
    private account: AccountViewModel;
    private userLeague: LeagueAttribution;
    private asset: AssetViewModel;

    constructor(account: AccountViewModel, userLeague: LeagueAttribution, asset: AssetViewModel) {
        this.account = account;
        this.userLeague = userLeague;
        this.asset = asset;
    }

    public get isUserWhiteList(): boolean {
        return this.account.IsUserWhiteListed() || this.userLeague.isWhitelisted();
    }

    public get Title(): Translation {
        if ((!this.account.isAuthenticated || this.account.IsUserWhiteListStatusNone) && !this.userLeague.isWhitelisted())
            return new Translation('white.list.title', {});
        return new Translation('confirm.white.list.title', {});
    }

    public get Description(): Translation {
        const whiteListDate: Date = this.asset.WhiteListDate.toDate();
        if ((!this.account.isAuthenticated || this.account.IsUserWhiteListStatusNone) && !this.userLeague.isWhitelisted())
            return new Translation('white.list.description', { date: getDateToString(whiteListDate, 'long', 'long', 'numeric', undefined), hours: whiteListDate.getHours() });
        return new Translation('confirm.white.list.description', { date: getDateToString(whiteListDate, 'long', 'long', 'numeric', undefined), hours: whiteListDate.getHours() });
    }
}