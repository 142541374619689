import React from "react";
import clsx from "clsx"
import i18next from "i18next";
import YouTube from "react-youtube";
import Application from "Application";
import IVideoSlideProps from "./IVideoSlideProps";
import WatchNowPlayBtn from "components/small/watch-now-play-btn/WatchNowPlayBtn";
import './VideoSlide.scss'
import CirclePlayBtn from "components/icons/CirclePlayBtn";
import useVideoSlide from "./useVideoSlide";

const BannerVideoSlide: React.FC<IVideoSlideProps> = (props) => {
    const { visible, onPlayBtnClicked, onVideoStateChange, onPlayerReady } = useVideoSlide(props);

    return (
        <div className={clsx("banner-slide", { 'video-visible': visible })} key={props.id}>
            <div className="overlay-banner hover" onClick={onPlayBtnClicked} style={{ backgroundImage: `url(${props.bannerImage})` }} >
                {props.showText &&
                    <div className="info">
                        <div className="text">
                            <h4>{i18next.t('topAssets.getStarted', { brand: Application.getInstance().brand })}</h4>
                            <p>{i18next.t('topAssets.share')}</p>
                        </div>
                    </div>
                }
                {props.showCenterPlayBtn &&
                    <div className="circle-play-button">
                        <CirclePlayBtn/>
                    </div>
                }
                {props.showWatchNowPlayBtn &&
                    <div className="play-video-button">
                        <WatchNowPlayBtn showPlayIcon={props.showPlayIconInWatchNow} />
                    </div>
                }
            </div>
            <YouTube containerClassName="youtube-video"
                videoId={props.videoId}
                onStateChange={onVideoStateChange}
                onReady={onPlayerReady}
                opts={{
                    height: '100%', width: '100%',
                    playerVars: {
                        playsinline: 0
                    }
                }}
            />
        </div >
    )
}

export default BannerVideoSlide;