import { TournamentProgress } from 'config/_enums';
import IUsePersonalTournament from 'hooks/use-personal-tournament/IUsePersonalTournament';
import usePersonalTournament from 'hooks/use-personal-tournament/usePersonalTournament';
import { ReduxRootState, RootState } from 'model/Redux';
import { ITournamentWinReducer } from 'model/Tournament/ITournamentWin';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentTournament, getNextTournament, getPreviousTournament } from 'service/tournament/actions';
import { loadingTournamentSelector, nextTournamentSelector, tournamentByProgressSelector } from 'service/tournament/selectors';
import { getAllTournamentWins } from 'service/tournamentWins/actions';
import { tournamentWinsSelector } from 'service/tournamentWins/selector';
import TournamentViewModel from 'view-model/Tournament/TournamentViewModel/TournamentViewModel';
import IUseTournament from './IUseTournament';

const useTournament = (): IUseTournament => {
    const dispatch = useDispatch();
    const tournamentLoading: boolean = useSelector(loadingTournamentSelector);
    const previousTournament: TournamentViewModel | undefined = useSelector((state: RootState) => tournamentByProgressSelector(state, TournamentProgress.PREVIOUS));
    const tournamentInProgressFromStore: TournamentViewModel | undefined = useSelector((state: RootState) => tournamentByProgressSelector(state, TournamentProgress.IN_PROGRESS));
    const nextTournamentFromStore: TournamentViewModel | undefined = useSelector(nextTournamentSelector);
    const tournamentWins: ReduxRootState<ITournamentWinReducer> = useSelector(tournamentWinsSelector);
    const { personalTournament, isUserPersonalTournamentAvailable, isPersonalTournamentInProgress, fetchUserTournament }: IUsePersonalTournament = usePersonalTournament();
    const nextTournament: TournamentViewModel | undefined = React.useMemo((): TournamentViewModel | undefined => {
        if (!isUserPersonalTournamentAvailable)
            return nextTournamentFromStore;
        return !isPersonalTournamentInProgress ? personalTournament : undefined;
    }, [isPersonalTournamentInProgress, personalTournament, nextTournamentFromStore, isUserPersonalTournamentAvailable]);

    const tournamentInProgress: TournamentViewModel | undefined = React.useMemo((): TournamentViewModel | undefined => {
        if (!isUserPersonalTournamentAvailable)
            return tournamentInProgressFromStore;
        return isPersonalTournamentInProgress ? personalTournament : undefined;
    }, [isPersonalTournamentInProgress, isUserPersonalTournamentAvailable, personalTournament, tournamentInProgressFromStore]);

    const fetchPreviousTournament = () => {
        if (tournamentLoading || previousTournament)
            return;
        dispatch(getPreviousTournament());
    };

    const fetchTournamentInProgress = () => {
        if (tournamentInProgressFromStore || tournamentLoading)
            return;
        dispatch(getCurrentTournament());
    };

    const fetchNextTournament = () => {
        if (nextTournamentFromStore || tournamentLoading)
            return;
        dispatch(getNextTournament());
    };

    const fetchTournamentWins = () => {
        if (tournamentWins.loading)
            return;
        dispatch(getAllTournamentWins());
    };

    return {
        previousTournament,
        tournamentInProgress,
        tournamentLoading,
        nextTournament,
        fetchPreviousTournament,
        fetchTournamentInProgress,
        fetchNextTournament,
        fetchTournamentWins,
        fetchUserTournament,
    };
};

export default useTournament;
