import { TEAM_DRAFT_ID } from 'config/_const';
import ITeamAsset from './ITeamAsset';

export default class TeamAsset implements ITeamAsset {
    public _id: string;
    public asset: string;
    public numberOfShares: number;
    public createdAt: string;
    public updatedAt: string;

    constructor(numberOfShares: number, assetId: string) {
        this._id = TEAM_DRAFT_ID;
        this.asset = assetId;
        this.numberOfShares = numberOfShares;
        this.createdAt = new Date().toISOString();
        this.updatedAt = new Date().toISOString();
    }
}
