import i18n from '18n';
import { Tooltip } from '@material-ui/core';
import Application from 'Application';
import clsx from 'clsx';
import ArrowUpIcon from 'components/icons/ArrowUp';
import PriceDetails from 'components/medium/asset-transaction/asset-transaction-detail/price-details/PriceDetails';
import Timer from 'components/medium/timer/Timer';
import AssetAvatar from 'components/small/asset-avatar/AssetAvatar';
import SecondaryBtn from 'components/small/buttons/secondary-btn/SecondaryBtn';
import TradeDetailBox from 'components/small/trade-detail-box/TradeDetailBox';
import { ASSET_TRANSACTION_DETAIL_ATTRIBUTES, TOOLTIP_TOUCH_DELAY_TIME, TRANSACTION_PAGES_TRANSITION_TIME, ZERO } from 'config/_const';
import { AssetTransactionType } from 'config/_enums';
import { getCurrencyImage } from 'data/currencyImage.data';
import useAccount from 'hooks/use-account/useAccount';
import useAssetBuySellClick from 'hooks/use-asset-buy-sell-click/useAssetBuySellClick';
import useAssetTransactionSlides from 'hooks/use-asset-transaction-slideup/useAssetTransactionSlides';
import IUseUserIdentityVerification from 'hooks/use-user-identity-verification/IUseUserIdentityVerification';
import useUserIdentityVerification from 'hooks/use-user-identity-verification/useUserIdentityVerification';
import i18next from 'i18next';
import React from 'react';
import SellAssetViewModel from 'view-model/SellAssetViewModel/SellAssetViewModel';
import ErrorTxnMsg from '../../error-txn-msg/ErrorTxnMsg';
import TxnTokenDetailProps from './TxnTokenDetailProps';

const TxnTokenDetail: React.FC<TxnTokenDetailProps> = (
    { assetTransactionViewModel,
        setNumberOfToken,
        isTransactionTypeBuy,
        isTransactionTypeSell,
        isPrePrivateSale,
        setIsPrePrivateSale,
        onCancel,
        setUserInputAmount,
        activeTransactionDetailAttribute,
        setActiveTransactionDetailAttribute,
        userInputAmount,
    }
) => {
    const isBuyableTokenFromCreditIsZero: boolean = assetTransactionViewModel.MaxBuyTokenWithAvailableCredit === ZERO;
    const { accountViewModel } = useAccount();
    const { openAssetTransaction } = useAssetTransactionSlides();
    const { onSellAssetClick } = useAssetBuySellClick();
    const { launchIdentityVerification }: IUseUserIdentityVerification = useUserIdentityVerification({});
    const revertCurrentTransacationType: AssetTransactionType = (assetTransactionViewModel.TransactionType === AssetTransactionType.BUY) ? AssetTransactionType.SELL : AssetTransactionType.BUY;
    const isInjectedAsIframe: boolean = Application.getInstance().IsInjectedAsIframe;
    const shouldShowMaxButton: boolean = React.useMemo(() => !isInjectedAsIframe && isTransactionTypeBuy && !assetTransactionViewModel.Asset.PackEnabled, [assetTransactionViewModel.Asset.PackEnabled, isInjectedAsIframe, isTransactionTypeBuy]);
    const nonZeroPrefixedRegex: RegExp = /^0(?=[1-9])/;

    const handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue: number = parseInt(event.target.value);
        if (isNaN(inputValue) || inputValue < ZERO)
            return setNumberOfToken(ZERO);
        setNumberOfToken(inputValue);
    };

    const handleUserAmountInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue: string = event.target.value.replace(/,/g, '.');
        const inputValueAsNumber = Number(inputValue);
        if (isNaN(inputValueAsNumber))
            return;
        setUserInputAmount(inputValue.replace(nonZeroPrefixedRegex, ""));
    };

    const updateMaxTokenInput = () => setNumberOfToken(assetTransactionViewModel.MaxBuyTokenWithAvailableCredit);

    const canSwitchTxnPage = (): boolean => {
        if (accountViewModel.IsOnboardingRunning)
            return false;
        if (isTransactionTypeSell)
            return true;

        const isAssetExistInUserWallet = assetTransactionViewModel.Asset.InitialSaleDone && Boolean(assetTransactionViewModel.HoldingAssetCount);
        const canSwitchToSellPage = ((accountViewModel.isIdentityConfirmed && isAssetExistInUserWallet) && (!assetTransactionViewModel.isAssetOwnerSelf || accountViewModel.CanVipSellOwnTokens)) || accountViewModel.IsUserAdmin;
        return canSwitchToSellPage;
    };

    const getOpenTransactionSlide = () => openAssetTransaction(
        assetTransactionViewModel.Asset.AssetId,
        revertCurrentTransacationType,
        true
    );

    const openTransactionSlide = () => {
        if (!canSwitchTxnPage())
            return;

        onCancel();
        setTimeout(() => {
            if (isTransactionTypeBuy)
                onSellAssetClick(assetTransactionViewModel.Asset.AssetSlug, false, launchIdentityVerification)
            else
                getOpenTransactionSlide();
        }, TRANSACTION_PAGES_TRANSITION_TIME);
    };

    const getTooltipMsg = () => canSwitchTxnPage()
        ? revertCurrentTransacationType
        : '';

    return <>
        <div className='token-section'>
            <div className="token-input-row">
                <TradeDetailBox
                    showBorder={activeTransactionDetailAttribute === ASSET_TRANSACTION_DETAIL_ATTRIBUTES.TOKENS}
                    imgComponent={
                        <AssetAvatar asset={{ avatarPath: assetTransactionViewModel.Asset.AvatarPath, cardLevel: assetTransactionViewModel.Asset.CardLevel, mainCategoryId: assetTransactionViewModel.Asset.MainCategoryId }} />
                    }
                    title={assetTransactionViewModel.Asset.AssetName}
                    subText={assetTransactionViewModel.Asset.isLegend() ? i18n.t(assetTransactionViewModel.Asset.IsAssetBenzema && assetTransactionViewModel.Asset.enableCustomAssetProfil() ? 'benzema.limited.edition.light-profil' : 'limited.edition') : undefined}
                    valueContainer={
                        <div className={clsx('flex', {
                            'flex-with-button': true
                        })} onClick={() => setActiveTransactionDetailAttribute(ASSET_TRANSACTION_DETAIL_ATTRIBUTES.TOKENS)}>
                            {isInjectedAsIframe &&
                                <SecondaryBtn
                                    disabled={assetTransactionViewModel.NumberOfToken === 0}
                                    receiveClickEvent={false}
                                    className={clsx("button-increase-decrease")}
                                    textButton={'-'}
                                    type="button"
                                    handleClick={() => setNumberOfToken(assetTransactionViewModel.NumberOfToken - 1)} />}
                            <div className='input-field-tokens-value'>
                                <input
                                    autoFocus={!Application.getInstance().OnBoardingV2 && !accountViewModel.IsOnboardingRunning}
                                    key="input-token-value"
                                    disabled={(assetTransactionViewModel.Asset.PackEnabled || assetTransactionViewModel.FromPack) || (Application.getInstance().OnBoardingV2 && accountViewModel.IsOnboardingRunning)}
                                    name="token-input"
                                    min={assetTransactionViewModel.EligiblePriceRange.min}
                                    className={clsx('token-input-field', {
                                        'input-iframe-benzema': isInjectedAsIframe
                                    })}
                                    type='tel'
                                    inputMode='numeric'
                                    value={assetTransactionViewModel.NumberOfTokenToString || ZERO}
                                    onChange={handleTokenChange}
                                    readOnly={assetTransactionViewModel.Asset.PackEnabled || assetTransactionViewModel.FromPack}
                                />
                            </div>

                            {isInjectedAsIframe &&
                                <SecondaryBtn
                                    disabled={false}
                                    receiveClickEvent={false}
                                    className={clsx("button-increase-decrease", {
                                        "max-on": assetTransactionViewModel.IsMaxBuyTokenFromCreditAndInputTokenSame
                                    })}
                                    textButton={'+'}
                                    type="button"
                                    handleClick={() => setNumberOfToken(assetTransactionViewModel.NumberOfToken + 1)} />}
                            {shouldShowMaxButton &&
                                <SecondaryBtn
                                    disabled={assetTransactionViewModel.IsMaxBuyTokenFromCreditAndInputTokenSame || isBuyableTokenFromCreditIsZero}
                                    receiveClickEvent={false}
                                    className={clsx("max-btn", {
                                        "max-on": assetTransactionViewModel.IsMaxBuyTokenFromCreditAndInputTokenSame
                                    })}
                                    textButton={i18n.t("max")}
                                    type="button"
                                    handleClick={() => updateMaxTokenInput()} />
                            }
                        </div>
                    }
                />
                <Tooltip placement='right' enterTouchDelay={TOOLTIP_TOUCH_DELAY_TIME} title={getTooltipMsg()}>
                    <div className={clsx('arrow-circle',
                        { 'hover': canSwitchTxnPage() })}
                        onClick={openTransactionSlide}
                    >
                        <span className={clsx('up-arrow',
                            { 'revert-arrow': isTransactionTypeSell }
                        )}>
                            <ArrowUpIcon />
                        </span>
                    </div>
                </Tooltip>
            </div>
            <TradeDetailBox
                imgComponent={
                    <img src={getCurrencyImage()} alt='currency-img' />
                }
                title={Application.getInstance().currency}
                showBorder={activeTransactionDetailAttribute === ASSET_TRANSACTION_DETAIL_ATTRIBUTES.USER_VALUE}
                valueContainer={
                    <div className='trade-detail' onClick={() => setActiveTransactionDetailAttribute(ASSET_TRANSACTION_DETAIL_ATTRIBUTES.USER_VALUE)}>
                        {assetTransactionViewModel.IsPriceSelectorEnabled ?
                            <div className="trade-detail_market-value">{i18n.t('sellAsset.marketPrice')}: {assetTransactionViewModel.DefaultDisplayPrice}</div> :
                            <></>}
                        <div className='input-field-tokens-value'>
                            <input
                                key="input-user-value"
                                disabled={Boolean(!assetTransactionViewModel.NumberOfToken)}
                                name="user-value-input"
                                min={ZERO}
                                inputMode='decimal'
                                className='token-input-field'
                                value={userInputAmount || ZERO}
                                onChange={handleUserAmountInput}
                                readOnly={!assetTransactionViewModel.IsUserAmountEditable}
                            />
                        </div>
                        <div className='trade-detail__fee'>{assetTransactionViewModel.FeeIncludedText}</div>
                        <div className='container-caution-tokens-detail'>
                            {assetTransactionViewModel.IsUserAmountLowWarning &&
                                <ErrorTxnMsg msg={i18n.t("assetTransaction.error.low.userAmount", { amount: assetTransactionViewModel.EligiblePriceRangeAsCurrency.min })} />
                            }
                            {assetTransactionViewModel.IsUserAmountHighWarning &&
                                <ErrorTxnMsg msg={i18n.t("assetTransaction.error.high.userAmount", { amount: assetTransactionViewModel.EligiblePriceRangeAsCurrency.max })} />
                            }
                        </div>
                    </div>
                }
            />
            {(isTransactionTypeSell && Boolean(assetTransactionViewModel.NumberOfToken)) &&
                <div className='fee-caution'>{(assetTransactionViewModel as SellAssetViewModel).FeesCautionMessage}</div>
            }
            <div className='detail-section'>
                <PriceDetails assetTransactionViewModel={assetTransactionViewModel} />
            </div>
            {isPrePrivateSale &&
                <div className='confirm-transaction-total_timer'>
                    {i18next.t("prePrivateSale.warning.timer")}
                    <Timer
                        onTimeEnd={() => setIsPrePrivateSale(false)}
                        date={assetTransactionViewModel.Asset.InitialSaleDate}
                        canShowIcon={false}
                        numberStyle={{ fontSize: '18px', color: 'red' }}
                        textStyle={{ fontSize: '18px', color: 'red' }}
                        containerStyle={{ background: 'linear-gradient(90deg, #ed213a, #93291e)', WebkitBackgroundClip: 'text', WebkitTextFillColor: '#ed213a' }}
                    />
                </div>
            }
        </div>

        {assetTransactionViewModel.IsSupplyExceeded &&
            <ErrorTxnMsg msg={i18n.t("assetTransaction.limitExceeded")} />
        }
        {assetTransactionViewModel.IsMaxAllowedTransactionWarning &&
            <ErrorTxnMsg msg={assetTransactionViewModel.MaxAllowedCautionMessage} />
        }
        {assetTransactionViewModel.IsPoolApplicableAndEqualSupply &&
            <ErrorTxnMsg msg={i18n.t("liquidityEnabled.buy.error.not.all.tokens")} />
        }
        {assetTransactionViewModel.IsPoolOutOfStock && !assetTransactionViewModel.Asset.IsFunCategory &&
            <ErrorTxnMsg msg={i18n.t("liquidityEnabled.sell.error.empty.pool")} />
        }
    </>;
};

export default React.memo(TxnTokenDetail);
