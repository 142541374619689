import * as React from 'react';

const Crown = ({ height = "17.04px", width = "21.909px", color = "#15182B", ...props }: React.SVGProps<SVGSVGElement>) => {

    return (
        <svg height={height} width={width} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.909 17.04">
            <path id="crown-solid" d="M11.753,34.815a1.521,1.521,0,1,0-1.6,0L7.976,39.174a1.218,1.218,0,0,1-1.849.407L2.739,36.869a1.521,1.521,0,1,0-1.217.609h.027l1.738,9.562a2.435,2.435,0,0,0,2.4,2H16.226a2.438,2.438,0,0,0,2.4-2l1.738-9.562h.027a1.521,1.521,0,1,0-1.217-.609l-3.389,2.712a1.218,1.218,0,0,1-1.849-.407Z" transform="translate(0 -32)" fill="#15182b" opacity="0.8" />
        </svg>
    );
};

export default Crown;