/* eslint-disable react-hooks/exhaustive-deps */
import i18n from '18n';
import { DEFAULT_ID_AS_STRING, LOCAL_STORAGE_KNOWN_KEYS, TEAM_INTERNAL_STATUS, TRENDEX_HTTP_STATUS_NOK, ZERO } from 'config/_const';
import { TournamentLeagues } from 'config/_enums';
import IUseAllAssets from 'hooks/asset/use-all-assets/IUseAllAssets';
import useAllAssets from 'hooks/asset/use-all-assets/useAllAssets';
import IUsePersistentTeamManagement from 'hooks/team/use-persistent-team-management/IUsePersistentTeamManagement';
import usePersistentTeamManagement from 'hooks/team/use-persistent-team-management/usePersistentTeamManagement';
import IUseDialog from 'hooks/use-dialog/IUseDialog';
import useDialog from 'hooks/use-dialog/useDialog';
import useModalDisplay from 'hooks/use-modal-display/useModalDisplay';
import useTeamGenerator from 'hooks/use-team-generator/useTeamGenerator';
import useUserLeague from 'hooks/use-user-league/useUserLeague';
import IUseWallet from 'hooks/wallet/use-wallet/IUseWallet';
import useWallet from 'hooks/wallet/use-wallet/useWallet';
import i18next from 'i18next';
import AssetCategoryInterface from 'model/AssetModel/AssetCategoryInterface';
import AssetInterface from 'model/AssetModel/AssetInterface';
import ReduxStoreInterface from 'model/ReduxStoreInterface';
import ITeam from 'model/Team/ITeam';
import ITeamAsset from 'model/Team/ITeamAsset';
import InternalTeam from 'model/Team/InternalTeam';
import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { managementModal } from 'service/modal/action';
import { managementNotification, showNotification } from 'service/notification/action';
import { categoriesOfOwnedAssetsSelector } from 'service/wallet/selector';
import AssetViewModel from 'view-model/Assets/AssetViewModel';
import INumberOfSharesInTeam from 'view-model/Team/TeamViewModel/ISharesLocalTeam';
import TeamViewModel from 'view-model/Team/TeamViewModel/TeamViewModel';
import IUseTeamConfiguration from '../use-team-configuration/IUseTeamConfiguration';
import useTeamConfiguration from '../use-team-configuration/useTeamConfiguration';
import IUseTeamEditor from './IUseTeamEditor';
import IUseTeamEditorProps, { IExcludedTeamAssets } from './IUseTeamEditorProps';

const useTeamEditor = ({ walletViewModel, accountViewModel, autoGenerateTeam, nextTournament }: IUseTeamEditorProps): IUseTeamEditor => {
    const dispatch = useDispatch();
    const { updateUserAssets, refreshWallet }: IUseWallet = useWallet({ considerTeamShares: false });
    const { userLeagueViewModel } = useUserLeague();
    const { teamConfigViewModel, dispatchTeamConfiguration }: IUseTeamConfiguration = useTeamConfiguration({ leagueType: userLeagueViewModel.LeagueAttribution.Name as TournamentLeagues });
    const [teamBuildingViewModel, setTeamBuildingViewModel] = useState<TeamViewModel>(new TeamViewModel(teamConfigViewModel, undefined, true));
    const onTeamCreatedCallback = (_teamUpdated: ITeam) => {
        refreshWallet();
        return
    }
    const { getUserTeams, teamLoading, userTeams, updateTeam, createTeam }: IUsePersistentTeamManagement = usePersistentTeamManagement({ userId: accountViewModel.Id, updateLocalTeamCallback: onTeamCreatedCallback });
    const { getAssetById }: IUseAllAssets = useAllAssets();
    const { displaySimpleConfirmDialog, closeDialog, displayConfirmTeamDialog }: IUseDialog = useDialog();
    const categoriesOfOwnedAssets: AssetCategoryInterface[] = useSelector(categoriesOfOwnedAssetsSelector);
    const assetCategories: AssetCategoryInterface[] = useSelector((state: ReduxStoreInterface) => state.assetsCategories.data);
    const { showTeamIncompatibleModal } = useModalDisplay();

    const onGenerationEnd = (teamAssets: ITeamAsset[]) => {
        teamBuildingViewModel.teamAssets = teamAssets;
        teamBuildingViewModel.InternalStatus = TEAM_INTERNAL_STATUS.EDIT_IN_PROGRESS;
        teamBuildingViewModel.autoGenerated = true;
        return setTeamBuildingViewModel(new TeamViewModel(teamConfigViewModel, teamBuildingViewModel.asInternalTeam()));
    };

    const { launchGeneration, instantTeamGeneration } = useTeamGenerator({ callbackGenerateTeam: onGenerationEnd });

    //#region "TeamCrud"
    const pickAsset = (assetId: string, defaultShares: number): void => {
        const teamAsset: ITeamAsset | undefined = teamBuildingViewModel.getTeamAssetByAssetId(assetId);
        if (teamBuildingViewModel.teamAssets.length >= teamBuildingViewModel.configuration.maxNumberOfAssets && !teamAsset) {
            dispatch(managementNotification(true, i18n.t('error-team-full'), TRENDEX_HTTP_STATUS_NOK));
            return;
        }
        teamBuildingViewModel.addAsset(assetId, defaultShares);
        return setTeamBuildingViewModel(new TeamViewModel(teamConfigViewModel, teamBuildingViewModel.asInternalTeam()));
    };

    const deleteAssetFromEditingTeam = (assetId: string): void => {
        teamBuildingViewModel.teamAssets = teamBuildingViewModel.teamAssets.filter((teamAsset: ITeamAsset) => teamAsset.asset !== assetId);
        teamBuildingViewModel.InternalStatus = TEAM_INTERNAL_STATUS.EDIT_IN_PROGRESS;
        return setTeamBuildingViewModel(new TeamViewModel(teamConfigViewModel, teamBuildingViewModel.asInternalTeam()));
    };

    const deleteMultipleAssetFromLocalTeam = (assetIds: string[]): void => {
        teamBuildingViewModel.teamAssets = teamBuildingViewModel.teamAssets.filter((teamAsset: ITeamAsset) => !assetIds.includes(teamAsset.asset));
        teamBuildingViewModel.InternalStatus = TEAM_INTERNAL_STATUS.EDIT_IN_PROGRESS;
        return setTeamBuildingViewModel(new TeamViewModel(teamConfigViewModel, teamBuildingViewModel.asInternalTeam()));
    };

    const onPickAssetIntent = (asset: AssetViewModel, shares: number) => {
        if (!asset.AvailableForTournament)
            return displaySimpleConfirmDialog({ message: i18n.t('asset-unavailable'), onValidation: closeDialog, showCancelButton: false });
        return pickAsset(asset.AssetId, shares);
    };

    const replaceEditingTeam = async (): Promise<void> => {
        const teamFromServer: ITeam | undefined = userTeams.find((usrTeam: ITeam) => usrTeam._id === teamBuildingViewModel.id);
        if (teamBuildingViewModel.id !== DEFAULT_ID_AS_STRING && !teamFromServer) {
            const errorMessage: string = `No team found in store for id ${teamBuildingViewModel.id}`;
            dispatch(showNotification(dispatch, i18n.t('error.from.localDb.replace', { errorDetail: errorMessage }), TRENDEX_HTTP_STATUS_NOK, false));
            return;
        }

        if (!teamFromServer)
            return;
        teamBuildingViewModel.teamAssets = teamFromServer.teamAssets;
        teamBuildingViewModel.InternalStatus = TEAM_INTERNAL_STATUS.EDIT_DONE;
        return setTeamBuildingViewModel(new TeamViewModel(teamConfigViewModel, teamBuildingViewModel.asInternalTeam()));
    };

    const internalLaunchTeamGeneration = () => {
        launchGeneration(walletViewModel.getOwnedAssetsAvailableInTeam(accountViewModel, nextTournament?.Category), [], [], teamBuildingViewModel.configuration, teamBuildingViewModel.asInternalTeam());
    };

    const resetTeam = () => {
        if (teamBuildingViewModel.id === DEFAULT_ID_AS_STRING)
            return displayErrorFromTeam();

        const previousTeamAssetsFromTeam: INumberOfSharesInTeam[] = teamBuildingViewModel.teamAssets.map((entry: ITeamAsset) => ({ assetId: entry.asset, amountOfShares: entry.numberOfShares }));
        const teamBuildingUpdated: TeamViewModel = teamBuildingViewModel.flushTeamAssets();
        batch(() => {
            updateUserAssets(previousTeamAssetsFromTeam);
            updateTeam(teamBuildingUpdated.asITeam());
            return setTeamBuildingViewModel(new TeamViewModel(teamConfigViewModel, teamBuildingUpdated.asInternalTeam()));
        });
    };
    //#endregion "TeamCrud"

    const displayErrorFromTeam = (): void => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            fullScreen: false,
            type: 'CONFIRM',
            propsCustom: {
                message: i18next.t('error.from.localDb.team'),
                showCancelButton: false,
                textValidButton: i18next.t('team.engaged.validation'),
                onValidation: closeDialog,
            },
        }));
    };

    const onConfirmDeleteUnavailableAsset = (unavailableAssetIds: string[]) => {
        const teamAssetsUnavailable: INumberOfSharesInTeam[] = teamBuildingViewModel.teamAssets
            .filter((entry: ITeamAsset) => unavailableAssetIds.includes(entry.asset))
            .map((entry: ITeamAsset) => ({ assetId: entry.asset, amountOfShares: entry.numberOfShares }));

        batch(() => {
            updateUserAssets(teamAssetsUnavailable);
            deleteMultipleAssetFromLocalTeam(unavailableAssetIds);
            closeDialog();
        });
    };

    const getExcludedTeamAssetsByTournamentCategory = (tournamentCategoryName: string): IExcludedTeamAssets => {
        let excludedTeamAssets: IExcludedTeamAssets = {
            nonSportAssets: [],
            retiredAssets: []
        };
        teamBuildingViewModel.teamAssets.forEach(teamAsset => {
            const asset: AssetInterface | undefined = walletViewModel.getWalletAssetById(teamAsset.asset)?.asset;
            if (!asset)
                return;
            const assetViewModel: AssetViewModel = new AssetViewModel(asset);
            const assetCategoryName = assetViewModel.getCategoryName(assetCategories);
            if (tournamentCategoryName.toLowerCase() !== assetCategoryName.toLowerCase())
                excludedTeamAssets.nonSportAssets.push(assetViewModel.AssetName);
            if (asset.sportPlayer?.isRetired)
                excludedTeamAssets.retiredAssets.push(assetViewModel.AssetName);
        });

        return excludedTeamAssets;
    };

    const canTriggerIncompatibleModal = (transText: string, excludeTeamAssets: string[], nextTournamentCategory: string) => {
        if (!excludeTeamAssets.length) return false;
        const excludedTeamAssetsNameListWithQuote = excludeTeamAssets.map(name => `"${name}"`);
        showTeamIncompatibleModal(
            i18n.t(transText, {
                replaceAsset: `${excludedTeamAssetsNameListWithQuote.join(", ")}`,
                tournamentCategory: nextTournamentCategory.toLowerCase()
            }),
            nextTournamentCategory,
        );
        return true;
    };

    const isTeamIncompatibleWithTournament = (nextTournamentCategory: string): boolean => {
        const excludedTeamAssetsNameList = getExcludedTeamAssetsByTournamentCategory(nextTournamentCategory);
        return (
            canTriggerIncompatibleModal('team.incompatible', excludedTeamAssetsNameList.nonSportAssets, nextTournamentCategory) ||
            canTriggerIncompatibleModal('team.incompatible.with.retiredPlayer', excludedTeamAssetsNameList.retiredAssets, nextTournamentCategory)
        );
    };

    const displayConfirmTeam = () => {
        const unavailableAssetIds: string[] = teamBuildingViewModel.getUnavailableTeamAssetIds(walletViewModel, accountViewModel);
        if (unavailableAssetIds.length > ZERO)
            return displaySimpleConfirmDialog({
                message: i18n.t('multiple-asset-unavailable'),
                onValidation: () => onConfirmDeleteUnavailableAsset(unavailableAssetIds),
                onRefused: closeDialog,
            });
        if (nextTournament?.Category && !nextTournament.IsTournamentDefault) {
            const isTeamIncompatible = isTeamIncompatibleWithTournament(nextTournament.Category.toLowerCase());
            if (isTeamIncompatible) return;
        }

        return internalDisplayConfirmTeam();
    };

    const internalDisplayConfirmTeam = () => displayConfirmTeamDialog({
        teamViewModel: teamBuildingViewModel,
    });

    const userHasNoTeamYet = (): boolean => {
        return !teamLoading && userTeams.length === ZERO && !teamConfigViewModel.Loading;
    };

    const internalCreateEmptyTeam = (): void => {
        const teamGeneration = instantTeamGeneration(walletViewModel.getOwnedAssetsAvailableInTeam(accountViewModel, nextTournament?.Category), [], [], teamBuildingViewModel.configuration, teamBuildingViewModel.asInternalTeam());
        const internalTeam: InternalTeam = new InternalTeam({ userId: accountViewModel.Id, configuration: teamConfigViewModel.Configuration, team: { teamAssets: autoGenerateTeam ? teamGeneration.assetsTeam : [], autoGenerated: autoGenerateTeam } });
        return setTeamBuildingViewModel(new TeamViewModel(teamConfigViewModel, internalTeam));
    };

    //#region "Team Effect"
    useEffect(() => {
        if (accountViewModel.Id === DEFAULT_ID_AS_STRING || userTeams.length > ZERO)
            return;
        getUserTeams();
    }, []);

    useEffect(() => {
        if (teamConfigViewModel.Configuration.type !== userLeagueViewModel.LeagueAttribution.Name.toUpperCase())
            dispatchTeamConfiguration();
    }, [userLeagueViewModel.LeagueAttribution.Name]);

    useEffect(() => {
        if (!localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.CONFIRM_TEAM_DISPLAYED))
            return;
        if (teamBuildingViewModel.Loading)
            return;
        localStorage.removeItem(LOCAL_STORAGE_KNOWN_KEYS.CONFIRM_TEAM_DISPLAYED);
        displayConfirmTeam();
    }, [teamBuildingViewModel]);

    useEffect(() => {
        if (teamLoading || teamConfigViewModel.Loading || teamConfigViewModel.Configuration._id === DEFAULT_ID_AS_STRING)
            return;
        if (userHasNoTeamYet())
            return internalCreateEmptyTeam();
        if (userTeams.length === ZERO)
            return;
        setTeamBuildingViewModel(new TeamViewModel(teamConfigViewModel, new InternalTeam({ team: userTeams[0], userId: accountViewModel.Id, configuration: teamConfigViewModel.Configuration })));
    }, [teamLoading, userTeams, teamConfigViewModel.Loading]);

    useEffect(() => {
        if (accountViewModel.Id !== DEFAULT_ID_AS_STRING)
            return;
        return internalCreateEmptyTeam();
    }, [accountViewModel.Id]);
    //#endregion 'Team Effect'

    return {
        categoriesOfOwnedAssets,
        teamConfigViewModel,
        teamBuildingViewModel,
        userTeams,
        onPickAssetIntent,
        displayConfirmTeam,
        internalDisplayConfirmTeam,
        internalLaunchTeamGeneration,
        replaceEditingTeam,
        resetTeam,
        deleteAssetFromEditingTeam,
        getAssetFromTeamAsset: getAssetById,
        isTeamIncompatibleWithTournament,
        createTeam,
        updateTeam
    };
};

export default useTeamEditor;
