import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ChevronLeft, ExpandMore, Menu } from '@material-ui/icons';
import useIsMobile from 'hooks/useIsMobile';
import { ILocationState } from 'model/RouteParamsProps';
import TopBarNavButtonProps from './TopBarNavButtonProps';
import { selectAssetModalState } from 'service/sliding-pane/selectors';
import useAssetSlideView from 'hooks/asset/use-asset-slide-view/useAssetSlideView';
import './style.scss';

const TopBarNavButton: React.FC<TopBarNavButtonProps> = ({ openMenu, color }) => {
    const isMobile = useIsMobile();
    const history = useHistory();
    const location = useLocation<ILocationState>();
    const isGoBack = /^\/asset\/|^\/become-vip\/|^\/tournament\/results\/|^\/tournament\/subscriptions/.test(history.location.pathname) && isMobile;
    const { isOpen: isAssetSlideViewOpen, isSlidingUp } = useSelector(selectAssetModalState);
    const showBackButton = isAssetSlideViewOpen && isSlidingUp === false;
    const { onSlideViewClose } = useAssetSlideView();

    const handleClick = () => {
        if (isAssetSlideViewOpen) {
            return onSlideViewClose();
        }

        if (isGoBack) {
            if (!location.key || (location.state && location.state.provider))
                return history.replace('/top-50');
            return history.goBack();
        }

        openMenu();
    };

    const renderTopLeftIcon = () => {
        const className = 'menu-icon';
        const style = { color };
        if (showBackButton) {
            return <ExpandMore className={className} style={style} />
        }

        if (isGoBack) {
            return <ChevronLeft className={className} style={style} />
        }

        return <Menu className={className} style={style} />;
    }

    return (
        <div className={clsx("hover top-bar-nav-button", { 'back-button-only': showBackButton })} onClick={handleClick}>
            {renderTopLeftIcon()}
        </div>
    );
};

export default TopBarNavButton;
