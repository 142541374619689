import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import * as AuthLoadMeActionType from './actionTypes';


export const actionIsAuthLoadMeLoading = (state:boolean): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    return dispatch({
        type: state ? AuthLoadMeActionType.LOADME_LOADING : AuthLoadMeActionType.LOADME_NOT_LOADING,
        data: {}
    });
};
