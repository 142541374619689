import { ISvgIconProps } from 'interfaces/SvgIcon.interface';
import * as React from 'react';

const Managers: React.FC<ISvgIconProps> = (props) => {
    const defaultColor1 = "url(#linear-gradient-managers)";
    const defaultColor2 = "url(#linear-gradient-managers-2)";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} width={props.width} height={props.height} viewBox="0 0 245 411.811">
            <defs>
                <linearGradient id="linear-gradient-managers" x1="0.052" y1="0.852" x2="1.038" y2="0.103" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#e7b4ba" />
                    <stop offset="0.502" stop-color="#e66f81" />
                    <stop offset="1" stop-color="#d0547d" />
                </linearGradient>
                <linearGradient id="linear-gradient-managers-2" x1="0.5" y1="0" x2="0.5" y2="1" href="#linear-gradient-managers" />
            </defs>
            <g id="Managers" transform="translate(-6603.5 -2601)">
                <g id="Rookies" transform="translate(178 75)">
                    <path id="Ellipse_281" data-name="Ellipse 281" d="M118,9A109,109,0,1,0,227,118,109.123,109.123,0,0,0,118,9m0-9A118,118,0,1,1,0,118,118,118,0,0,1,118,0Z" transform="translate(6430 2602)" fill={props.color || defaultColor1} />
                    <path id="Ellipse_281_-_Contour" data-name="Ellipse 281 - Contour" d="M118-4.5A122.5,122.5,0,1,1-4.5,118,122.639,122.639,0,0,1,118-4.5Zm0,227A104.5,104.5,0,1,0,13.5,118,104.618,104.618,0,0,0,118,222.5Zm0,9A113.629,113.629,0,0,1,4.5,118a113.5,113.5,0,0,0,227,0A113.629,113.629,0,0,1,118,231.5Z" transform="translate(6430 2602)" fill={props.color || defaultColor1} />
                    <path id="Tracé_3792" data-name="Tracé 3792" d="M5161.086,4889.438l-33.068,34.52h15.153l-13.851,71.756-30.392-41.546h-23.862l-14.272,82.916,19.159,19.323,13.746-73.138,30.532,42.191h23.516l18.7-101.5h12.818l-18.176-34.52m1.9-13.553,29.525,56.073h-19.411l-18.7,101.5h-34.263l-22.02-30.429L5085,5072.859l-32.79-33.071,16.115-93.621h34.663l21.955,30.012,8.536-44.222h-24.2Z" transform="translate(1427.971 -2255.922)" fill={props.color || defaultColor1} />
                    <path id="Tracé_3792_-_Contour" data-name="Tracé 3792 - Contour" d="M5163.944,4869.107l35.2,66.85h-22.707l-18.7,101.5H5118.1l-17.764-24.548-12.813,68.175-39.605-39.945,17.036-98.974h40.063l17.736,24.246,5.879-30.456h-28.729Zm-1.905,13.554h0l-14.643,15.286,14.643-15.286Zm10.6,37.3-12.5-23.743-22.744,23.743h10.628l-16.509,85.522-34.61-47.312h-18.462l-13.351,77.563,12.343,12.449,14.057-74.792,34.788,48.072h18.143l18.7-101.5Zm-34.31,8h0Zm47.564,0h-16.115l-18.7,101.5h-28.89l-26.276-36.31h0l26.276,36.31h28.89l18.7-101.5Zm-114.192,22.21h0l-15.193,88.269,25.975,26.2,8.091-43.053-8.091,43.052-25.974-26.2Zm29.262,0h0l26.173,35.779h0Z" transform="translate(1427.971 -2255.922)" fill={props.color || defaultColor1} />
                </g>
                <g id="Groupe_5142" data-name="Groupe 5142" transform="translate(0 -1)">
                    <path id="Polygone_75" data-name="Polygone 75" d="M33.5,0,44.388,20.327,67,24.446,51.116,41.127,54.2,64,33.5,53.982,12.8,64l3.088-22.873L0,24.446l22.612-4.119Z" transform="translate(6692.5 2602)" fill={props.color || defaultColor2} />
                </g>
                <path id="Soustraction_36" data-name="Soustraction 36" d="M136,353.811h0c-.033-.016-3.394-1.582-8.256-4.374a148.253,148.253,0,0,1-18.164-12.272A88.8,88.8,0,0,1,91.415,318.27c-5.479-8.029-8.256-16.185-8.256-24.24V261.353a135.308,135.308,0,0,0,25.484,7.895,137.332,137.332,0,0,0,54.713,0,135.311,135.311,0,0,0,25.485-7.895V294.03c0,8.056-2.778,16.211-8.256,24.24a88.8,88.8,0,0,1-18.164,18.894,146.667,146.667,0,0,1-18.164,12.272c-4.869,2.8-8.223,4.359-8.256,4.374Zm65.16-46V255.4A136.666,136.666,0,0,0,254,203.661v44.368c0,8.056-2.778,16.212-8.256,24.241a88.794,88.794,0,0,1-18.164,18.894,146.653,146.653,0,0,1-18.164,12.272c-4.856,2.789-8.118,4.31-8.255,4.373Zm-130.32,0h0v0c-.136-.062-3.4-1.583-8.254-4.373a148.232,148.232,0,0,1-18.164-12.272,88.793,88.793,0,0,1-18.164-18.894C20.777,264.241,18,256.085,18,248.029V203.661A136.665,136.665,0,0,0,70.84,255.4Z" transform="translate(6590 2659)" fill={props.color || defaultColor2} />
            </g>
        </svg>

    );
}

export default Managers;
