import FactoryScoreViewModel from "components/medium/score-chart-facto/FactoryScoreViewModel";
import { EmptyString } from "config/_const";
import ISportChartDetail from "interfaces/score-chart/IScoreChartConfig";
import ISportStatistics from "interfaces/score-chart/ISportStatistics";
import { useDispatch } from "react-redux";
import { managementModal } from "service/modal/action";
import ScoreChartViewModel from "view-model/ScoreChartViewModel/ScoreChartViewModel";

const useSportScoreChart = (sportStatistics: ISportStatistics, mainCategoryId: string): any => {
    const dispatch = useDispatch();
    const DynamicSportClass: any = FactoryScoreViewModel.getServiceBySportCategory<any>(mainCategoryId);
    const scores: ScoreChartViewModel[] = sportStatistics && sportStatistics.statistics ? sportStatistics.statistics.map((statistic: any) => new ScoreChartViewModel({ _id: sportStatistics._id, score: statistic.score, date: statistic.date ?? EmptyString, service: new DynamicSportClass({ player_id: sportStatistics._id, statistics: statistic }) })) : [];

    const onChartbarClick = (statistics: ISportChartDetail) => {
        dispatch(managementModal(true, {
            display: true,
            disableBackDrop: false,
            showBackArrow: false,
            showCancelButton: true,
            fullScreen: false,
            type: 'ASSET_SPORT_SCORE_DETAILS',
            propsCustom: {
                statistics
            },
        }));
    }

    return {
        scores,
        onChartbarClick
    }
}

export default useSportScoreChart;