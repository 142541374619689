import React from 'react';
import { TransactionsLogsHeadCell } from '../../../../config/tableConfig';
import AssetTransactionInterface from '../../../../model/AssetModel/AssetTransactionInterface';
import Row from '../../../../model/Table/Row';
import { TransactionsLogsTable } from '../../../../model/Table/TableHeader';
import GenericTable from '../../generic-table/GenericTable';
import LogTableProps from './LogTableProps';

const LogTable: React.FC<LogTableProps> = (props) => {

    const generateRows = (assetTransactionsLog: AssetTransactionInterface[]) => {
        const rows: Row<TransactionsLogsTable>[] = [];

        for (const log of assetTransactionsLog) {

            const cells: TransactionsLogsTable = {
                amount: { data: log.amount, textCellColor: 'white' },
                type: { data: log.type, textCellColor: 'white' },
                assetName: { data: log.asset ? log.asset.name : 'No Name', textCellColor: 'white' },
                otherUser: { data: log.otherUser ? `${log.otherUser.firstName} ${log.otherUser.lastName}` : 'N/A', textCellColor: 'white' },
                user: { data: log.userId ? `${log.userId.firstName} ${log.userId.lastName}` : 'N/A', textCellColor: 'white' },
            };

            rows.push({
                cells,
                id: log._id,
            });
        }

        return rows;
    };

    return (
        <div className="log-table">
            <GenericTable
                rows={generateRows(props.logs)}
                loadMoreData={(page: number) => props.loadMoreData && props.loadMoreData(page)}
                headerTable={TransactionsLogsHeadCell}
                enabledCheckbox={false}
                enableCreateButton={false}
                tableHeight={props.height || '100vh'} />
        </div>
    );
};

LogTable.defaultProps = ({
    height: '100vh',
});

export default LogTable;
