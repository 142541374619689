import * as React from "react";

function Athlete() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='19.033'
      viewBox='0 0 19 19.033'
    >
      <path
        id='futbol-solid'
        d='M6.584,8.5l1.145,3.4h3.587l1.1-3.4-2.9-2.1ZM9.483,0A9.517,9.517,0,1,0,19,9.55,9.509,9.509,0,0,0,9.483,0Zm6,13.416-3.175-.048L11.377,16.4a6.947,6.947,0,0,1-3.723,0l-.933-3.031-3.175.048A7.085,7.085,0,0,1,2.4,9.873L4.993,8.045l-1.03-3A7.161,7.161,0,0,1,6.976,2.854l2.541,1.9,2.539-1.9a7.162,7.162,0,0,1,3.013,2.187l-1.028,3L16.635,9.87A7.047,7.047,0,0,1,15.487,13.416Z'
        transform='translate(0)'
        fill='#15182b'
      />
    </svg>
  );
}

export default Athlete;
