import React from 'react';
import PrimaryButton from '../buttons/primary-button/PrimaryButton';
import { IOnboardingButtonProps } from './IOnboardingButtonProps';
import './OnboardingButton.scss';


const OnboardingButton: React.FC<IOnboardingButtonProps> = (props) => {
    return (
        <div className='onboarding-button-component'>
            <div className='button-container-onboarding'>
                <PrimaryButton
                    handleClick={props.handleClick}
                    textButton={props.textButton}
                    className="onboarding-button"
                    receiveClickEvent={false}
                    type={'button'}
                    disabled={props.disabled} />
            </div>
        </div>
    );
};

export default OnboardingButton;
