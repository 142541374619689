import { EXPONENTIAL_BACKOFF_DELTA, MAX_SOCKET_RECONNECTION, SOCKET_TIMEOUT_REASON, WEBSOCKET_ACTIONS } from 'config/_const';
import React, { useCallback, useEffect } from 'react';
import Utils from 'utils/Utils';
import WebsocketActionType from './actionType';
import { ActionTypes, ContextDispatch, ISocketContext, IUseSocketContext, ProviderProps } from './types';
import { getWebsocketEndpoint } from 'config/websocketEndpoint';


const initialState: ISocketContext = {
    applicationSocket: null,
    isConnecting: false,
    isSocketOpen: false,
    rooms: {},
};
const StateContext = React.createContext<ISocketContext>(initialState);
const DispatchContext = React.createContext<ContextDispatch | undefined>(undefined);

function reducer(context: ISocketContext, action: ActionTypes): ISocketContext {
    switch (action.type) {
        case WebsocketActionType.INITIATE_SOCKET:
            return {
                applicationSocket: new WebSocket(getWebsocketEndpoint()),
                isConnecting: true,
                isSocketOpen: false,
                rooms: {},
            };
        case WebsocketActionType.CLOSE_SOCKET:
            context.applicationSocket?.close();
            return { ...context, applicationSocket: null, isConnecting: false }
        case WebsocketActionType.SOCKET_OPENED:
            return { ...context, isSocketOpen: true, isConnecting: false }
        case WebsocketActionType.SUBSCRIBE_ROOM:
            if (!action.payload) return context;
            return { ...context, rooms: { ...context.rooms, ...action.payload } }
        default: {
            return context;
        }
    }
}

const Provider = ({ children }: ProviderProps): JSX.Element => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const [connectionRetries, setConnectionRetries] = React.useState(0);
    const socketOpenListener = useCallback(() => {
        setConnectionRetries(0);
        dispatch({ type: WebsocketActionType.SOCKET_OPENED })
    }, [])

    const socketCloseListener = useCallback((event: CloseEvent) => {
        if (event.reason === SOCKET_TIMEOUT_REASON) {
            dispatch({ type: WebsocketActionType.INITIATE_SOCKET });
            return;
        }

        if (event.wasClean)
            return;
        if (connectionRetries >= MAX_SOCKET_RECONNECTION) {
            return;
        }
        setConnectionRetries(prev => prev + 1);
        setTimeout(() => dispatch({ type: WebsocketActionType.INITIATE_SOCKET }), EXPONENTIAL_BACKOFF_DELTA * connectionRetries);
        return;
    }, [connectionRetries]);

    useEffect(() => {
        state.applicationSocket?.addEventListener("open", socketOpenListener);
        return () => state.applicationSocket?.removeEventListener("open", socketOpenListener)
    }, [state.applicationSocket, socketOpenListener]);

    useEffect(() => {
        state.applicationSocket?.addEventListener("close", socketCloseListener);
        return () => state.applicationSocket?.removeEventListener("close", socketCloseListener);
    }, [state.applicationSocket, socketCloseListener])

    return (
        <StateContext.Provider value={state} >
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

function useSocketContext(): IUseSocketContext {
    const socketContext = React.useContext(StateContext);
    const socketDispatchContext = React.useContext(DispatchContext);

    useEffect(() => {
        if (socketContext.isConnecting)
            return;
        if (!socketContext.applicationSocket && !socketContext?.isSocketOpen) {
            socketDispatchContext?.({ type: WebsocketActionType.INITIATE_SOCKET });
            return;
        }
    }, [socketContext, socketDispatchContext]);


    const subscribeToAssetId = useCallback((assetId: string) => {
        if (socketContext?.rooms.assetId !== assetId && socketContext?.isSocketOpen && socketContext?.applicationSocket) {
            const socketPayload = JSON.stringify({ assetId, action: WEBSOCKET_ACTIONS.PREDICT_PRICE });
            socketContext.applicationSocket.send(socketPayload);
            socketDispatchContext?.({ type: WebsocketActionType.SUBSCRIBE_ROOM, payload: { assetId: assetId } })
        }
    }, [socketContext, socketDispatchContext])

    const subscribeToTournament = useCallback(() => {
        if (socketContext?.isSocketOpen && socketContext?.applicationSocket) {
            const socketPayload = JSON.stringify({ action: WEBSOCKET_ACTIONS.TOURNAMENT_SUBSCRIBE });
            socketContext.applicationSocket.send(socketPayload);
        }
    }, [socketContext])

    if (!Utils.isDefined(socketContext) || !Utils.isDefined(socketDispatchContext)) {
        throw new Error('socketContext must be used within its own Provider');
    }

    return { socketContext, socketDispatch: socketDispatchContext, subscribeToAssetId, subscribeToTournament };
}

export { Provider as SocketContextProvider, useSocketContext };
