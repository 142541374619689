import ReduxActionInterface from "model/ReduxActionInterface";
import { ReduxRootState } from "model/Redux";
import { SPORTS_PLAYERS_ACTION } from "./actionTypes";
import ISportsFootballPlayers from "interfaces/sports/ISportsPlayer";

const ROOT_VALUE_STATE: ReduxRootState<ISportsFootballPlayers[]> = {
    loading: false,
    data: [],
};

export const sportsPlayersReducer = (state = ROOT_VALUE_STATE, action: ReduxActionInterface) => {
    switch (action.type) {
        case SPORTS_PLAYERS_ACTION.LOADING:
            return {
                ...state,
                loading: true,
            };
        case SPORTS_PLAYERS_ACTION.SUCCESS:
             return  handleSortSportsPlayers(state, action)
   
        case SPORTS_PLAYERS_ACTION.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    };

    function handleSortSportsPlayers(state: ReduxRootState<ISportsFootballPlayers[]>, action: ReduxActionInterface): ReduxRootState<ISportsFootballPlayers[]> {
        const data = action.payload.data;
        if (data.length === 0) {
            return {
                ...state,
                loading: false,
                data,
            };
        }
        const players: ISportsFootballPlayers[] = data;
        const sortTeams = players.sort((a, b) => {
            const nameA = a.player.name.toLowerCase();
            const nameB = b.player.name.toLowerCase();
            return (nameA < nameB) ? -1 : 1;
        });

        return {
            ...state,
            loading: false,
            data: sortTeams,
        };
    };
};