import React from 'react';
import i18n from '18n';
import './style.scss';
import ITeamAssetsScoreProps from './ITeamAssetsScoreProps';

const TeamAssetsScore: React.FC<ITeamAssetsScoreProps> = ({ teamScore }) => {
    return (
        <p className="team-asset-score no-margin">
            {i18n.t('team.performanceScore')}
            <span className="score">
                {teamScore > 0 ? teamScore : '-'}
            </span>
        </p>
    );
};

export default React.memo(TeamAssetsScore);
