import UserProfileEdit from 'components/medium/user-profile-edit/UserProfileEdit';
import UserProfile from 'components/medium/user-profile/UserProfile';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../18n';
import { EDIT, HTTP_STATUS_OK, OPEN } from '../../../config/_const';
import UserInterface from '../../../model/User/UserInterface';
import {
    actionLoadMe,
    actionLoginUser,
    actionLogoutUser,
    actionRegisterUser,
    magicLink,
    sendEmailVerification,
    sendIdentity,
    uploadProfileImage,
    verifyAccount,
} from '../../../service/auth/actions';
import { managementNotification } from '../../../service/notification/action';
import Connect from '../../medium/connect/Connect';
import CircularLoader from '../CircularLoader';
import './AboutOverview.scss';
import AboutOverviewProps from './AboutOverviewProps';
import AboutOverviewState from './AboutOverviewState';

class AboutOverview extends React.Component<AboutOverviewProps, AboutOverviewState>  {

    constructor(props: AboutOverviewProps) {
        super(props);

        this.state = {
            isThisMainProfilePage: true,
            canTriggerIdentifyModal: false,
        };
    }


    clearQueryOnComponentLoad = () => {
        // Remove it so that it doesn't happen twice
        this.props.history.replace({ ...this.props.location, search: '' });
    }

    componentWillMount = () => {
        const url = new URLSearchParams(window.location.search);
        const token: string | null = url.get('token');
        this.triggerComponentThroughURLqueryParameter();

        if (!token)
            return;

        if (!this.props.auth?.data.isAuthenticated) {
            return this.props.magicLink(token, this.props.history).then((_) => {
                this.props.verifyAccount(token).then((_unused) => {
                    this.props.managementNotification(true, i18n.t('Aboutus.tab.about.emailvarified'), HTTP_STATUS_OK);
                });
                this.clearQueryOnComponentLoad();
            });
        }
        this.props.verifyAccount(token).then((_) => {
            this.props.managementNotification(true, i18n.t('Aboutus.tab.about.emailvarified'), HTTP_STATUS_OK);
        });
        this.clearQueryOnComponentLoad();
    }

    componentDidUpdate = (prevProps: AboutOverviewProps) => {
        const validatedEmail: UserInterface = this.props.auth.data.account.validatedEmail;
        const prevValidatedEmail: UserInterface = prevProps.auth.data.account.validatedEmail;
        if (prevProps.auth.data.isAuthenticated && this.props.auth.data.isAuthenticated && prevValidatedEmail !== validatedEmail) {
            this.props.actionLoadMe();
        }

        if (this.props.location.search !== prevProps.location.search) {
            this.triggerComponentThroughURLqueryParameter()
        }
    }

    triggerComponentThroughURLqueryParameter() {
        const url = new URLSearchParams(window.location.search);
        const showEdit: string | null = url.get('show');
        const openModal: string | null = url.get('modal');

        if (showEdit === EDIT) {
            this.setState({ isThisMainProfilePage: false })
            if (openModal === OPEN) this.setState({ canTriggerIdentifyModal: true })
        }
    };

    onLogOut = () => {
        this.props.actionLogoutUser();
    }

    handleIsThisMainProfilePage = (boolValue: boolean) => {
        if (boolValue) this.clearQueryOnComponentLoad();
        this.setState({ isThisMainProfilePage: boolValue })
        window.scrollTo({ top: 0 })
    }

    render = () => {

        const {
            auth,
            authLoadMe
        } = this.props;

        if (authLoadMe.loading) {

            return <CircularLoader
                loaderLocal={false}
                loaderMessage=""
            />
        } else if (!auth.data.isAuthenticated) {

            return (
                <div className="auth-form-container">
                    <Connect
                        forceSignup={false} />
                </div>
            );
        }

        return (
            <div className="about-overview">
                {this.state.isThisMainProfilePage
                    ? <UserProfile
                        handleIsThisMainProfilePage={this.handleIsThisMainProfilePage}
                        onLogoutUser={() => this.onLogOut()}
                    />
                    : <UserProfileEdit
                        handleIsThisMainProfilePage={this.handleIsThisMainProfilePage}
                        canTriggerIdentifyModal={this.state.canTriggerIdentifyModal}
                        onIconEditChange={this.props.uploadProfileImage}
                        managementNotification={this.props.managementNotification}
                        sendEmailVerification={this.props.sendEmailVerification}
                    />
                }

                {auth.data.loadingUploadIdentity && <CircularLoader loaderLocal={false} loaderMessage={i18n.t("genericloading")} />}
            </div>
        );
    }
}

const mapStateToProps = (state: AboutOverviewProps) => ({
    auth: state.auth,
    authLoadMe: state.authLoadMe,
});

export default connect(mapStateToProps, {
    sendEmailVerification,
    sendIdentity,
    verifyAccount,
    managementNotification,
    uploadProfileImage,
    actionRegisterUser,
    actionLoginUser,
    actionLogoutUser,
    actionLoadMe,
    magicLink,
})(AboutOverview);
