import * as React from 'react';
import TripleArrowUpGif from 'assets/gif/arrow/TripleArrowUp.gif'
import ILevelSubIconContains from './ILevelSubIconContains';

const LevelSubIconContains: React.FC<ILevelSubIconContains> = ({ levelValue, showBoostIcon }) =>
    <div className='sub-icon__container'>
        <p className="libelle">{levelValue}</p>
        {showBoostIcon && <span className='upgrade-arrow'><img src={TripleArrowUpGif} alt="up-arrow" className='arrowUp-gif' /></span>}
    </div>

export default LevelSubIconContains;
