import Error from 'components/icons/Error';
import RedButton from 'components/small/buttons/red-button/RedButton';
import React from 'react';
import { withTranslation } from "react-i18next";
import IBuyTournamentTicketErrorProps from './IBuyTournamentTicketErrorProps';
import './style.scss';

const BuyTournamentTicketError: React.FC<IBuyTournamentTicketErrorProps> = ({ t: translate, onButtonClick, league }: IBuyTournamentTicketErrorProps) => {
    return (
        <div className='container-modal-buy-ticket-error'>
            <div className='container-top-on-the-left-img'><Error /></div>
            <h3>{translate('tournament.ticket.error.title', { leagueName: league.Name })}</h3>
            <p className='first-sentence'>{translate('tournament.ticket.error.message', { leagueName: league.Name })}</p>
            <RedButton
                textButton={translate('retry')}
                type={'button'}
                receiveClickEvent={false}
                handleClick={onButtonClick}
                disabled={false}
                className={'tournament-confirmation-modal-on-boarding-btn'} />
        </div>
    )
};

export default withTranslation()(BuyTournamentTicketError);