import ILiquidityPoolParams from "interfaces/liquidity-pool/ILiquidityPoolParams";
import LiquidityPool from './liquidityPool';
import { ZERO } from "config/_const";


class LinearLiquidityPool extends LiquidityPool {
    private initialPrice: number;
    private growthRate: number;

    constructor(liquidityPool: ILiquidityPoolParams) {
        super(liquidityPool);
        this.initialPrice = 1;
        const endPrice = this.AssetCurveConfiguration.factors.endPrice;
        this.growthRate = (endPrice - this.initialPrice) / this.InitialTokens;
    }

    private curve(x: number): number {
        if (x < 0)
            return this.initialPrice;
        if (x > this.InitialTokens)
            return this.AssetCurveConfiguration.factors.endPrice;
        return this.initialPrice + this.growthRate * x;
    }

    private integrate(start: number, xout: number) {
        let sum = ZERO;
        for (let i = start; i < xout; i++) {
            sum += this.curve(i);
        }
        return sum;
    }

    getBuyPrice(xout: number) {
        if (xout === ZERO)
            return this.curve(xout);
        return this.integrate(this.CurveBalance, this.CurveBalance + xout);
    }

    getSellPrice(xin: number) {
        if (xin === ZERO)
            return this.curve(xin);
        return this.integrate(this.CurveBalance - xin, this.CurveBalance);
    }

    getMaxTokenWithAvailableCredit(credits: number): number {
        let totalCost = ZERO;
        let tokensBought = ZERO;

        for (let x = ZERO; x < this.InitialTokens; x++) {
            let price = this.curve(x);
            if (totalCost + price > credits) {
                break;
            }
            totalCost += price;
            tokensBought++;
        }

        return tokensBought;
    }
}

export default LinearLiquidityPool;