import i18next from 'i18next';
import { checkDiscord, isValidEmail, isValidPassword, isValidPhoneNumber } from '../utils/validator';

export const emailValidator = (value: string) => {
    return !isValidEmail(value) ? i18next.t('register.mail.errorMessage') : undefined;
};

export const passwordValidator = (value: string) => {
    return !isValidPassword(value) ? i18next.t('register.password.errorMessage') : undefined;
};

export const checkValue = (value: string) => {
    return value ? undefined : i18next.t('login.validatemsg');
};

export const phoneValidator = (phone: string, mandatory: boolean = true) => {
    if (!phone)
        return !mandatory;
    return isValidPhoneNumber(phone);
};


export const discordValidator = (usernameAndTag: string) => {
    if (!usernameAndTag) return undefined;
    return checkDiscord(usernameAndTag) ? undefined : i18next.t('UserInformationForm.discord-bad-format');
};
