import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in/dist/esm/definitions';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import * as Sentry from "@sentry/react";
import Application from 'Application';
import {
    CANCEL_SIGN_IN_MESSAGES, CONNECTION_MODE, FACEBOOK_PERMISSIONS,
    G_TAGS_KNOWN_EVENTS,
    HOME_AFTER_SIGNUP, HTTP_STATUS_INTERNAL_ERROR, HTTP_STATUS_OK, LOCAL_STORAGE_KNOWN_KEYS, MIXPANEL_KNOWN_KEYS,
    OauthProviderEnum,
    SIGN_IN_WITH_APPLE_OPTIONS,
    TRENDEX_COOKIES,
    TRENDEX_HTTP_STATUS_NOK,
    TRENDEX_ROLES,
    VERIFICATION_STATUS
} from 'config/_const';
import { ApplicationSportVersionEnum, FormMode, OnboardingStep } from 'config/_enums';
import AxiosConfig, { clearAxiosJWTAndUniqueUserIdHeader, initializeJwtHeaderAndUinqueUserId } from 'config/axiosConfig';
import {
    AUTH_ENDPOINTS,
    REWARDS_ENDPOINTS,
    USER_ENDPOINTS,
    USER_TOURNAMENT_ENDPOINTS,
    WHITELIST_ENDPOINTS
} from 'config/endpoints';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import mixpanel from 'mixpanel-browser';
import IUserAbTesting from 'model/AbTesting/User/IUserAbTesting';
import AssetInterface from 'model/AssetModel/AssetInterface';
import { IHttpResponse, IHttpStrongTypedResponse } from 'model/IHttpResponse';
import { RootState } from 'model/Redux';
import IRewardTransaction from 'model/RewardTransactions/IRewardTransaction';
import ITournament from 'model/Tournament/ITournament';
import IClaimableUserReward from 'model/Tournament/Rewards/IClaimableUserReward';
import IGetCredits from 'model/User/IGetCredits';
import UserInterface, { IClaimRewardRewardAtRegisterResponse, IUserCookieConsentResponse } from 'model/User/UserInterface';
import moment from 'moment';
import { batch } from 'react-redux';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { actionIsAuthLoadMeLoading } from 'service/auth-loadMe/action';
import { RESET_STATE } from 'service/commonActionTypes';
import IntercomService from 'service/intercom/IntercomService';
import { managementModal } from 'service/modal/action';
import { showNotification } from 'service/notification/action';
import { SegmentTracking } from 'service/segment/SegmentTracking';
import { TagManagerWrapper } from 'service/tag-manager/TagManagerWrapper';
import { getUserWallet } from 'service/wallet/actions';
import Utils from 'utils/Utils';
import AccountViewModel from 'view-model/AccountViewModel/AccountViewModel';
import ReferralViewModel from 'view-model/Referrals/ReferralViewModel';
import { managementNotification } from '../../service/notification/action';
import * as AuthActionsType from './actionsType';

export const whitelistUser = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: AuthActionsType.WHITELIST_USER_LOADING });
        const whitelistUserResponse: IHttpStrongTypedResponse = await AxiosConfig.post(WHITELIST_ENDPOINTS.CONFIRM);
        if (whitelistUserResponse.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: AuthActionsType.WHITELIST_USER_ERROR
            });
        return dispatch({
            type: AuthActionsType.WHITELIST_USER_SUCCESS
        });
    } catch (exception) {
        return dispatch({
            type: AuthActionsType.WHITELIST_USER_ERROR,
            message: 'Error while whitelisting curent user'
        });
    }
};

export const actionRegisterUser = (email: string, password: string, lastName: string, firstName: string, token: string, isUserVip?: boolean, history?: any, signupSourceChannel?: string, signupSourceId?: string, signupVersion?: ApplicationSportVersionEnum): ThunkAction<Promise<void>, null, unknown, Action<string>> => async (dispatch) => {
    dispatch(managementModal(true, {
        type: 'LOADING_MODAL',
        message: '',
        showCancelButton: false,
    }));
    let response = null;
    const singupAsVip: boolean = !!(isUserVip);
    const currentLanguage = Utils.getCurrentLanguage();
    const referralViewModel = new ReferralViewModel();
    try {
        dispatch({
            type: AuthActionsType.AUTH_LOADING,
        });
        response = await AxiosConfig.post('/auth/register', { email, password, lastName, firstName, isUserVip: singupAsVip, token, clientLang: currentLanguage, signupSourceChannel, signupSourceId, referralId: referralViewModel.ReferralId, v: signupVersion });
        referralViewModel.RemoveReferralId();
    } catch (error) {
        showNotification(dispatch, 'Wrong email or password', HTTP_STATUS_INTERNAL_ERROR);
        dispatch(managementModal(false));
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }

    if (!response) {

        showNotification(dispatch, 'Server is offline, please retry later', HTTP_STATUS_INTERNAL_ERROR);
        dispatch(managementModal(false));
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }

    if (response.data.status !== HTTP_STATUS_OK) {

        showNotification(dispatch, response.data.message, response.data.status);
        dispatch(managementModal(false));
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }
    initializeJwtHeaderAndUinqueUserId(response.data.token, response.data.uniqueUserId);
    const user: UserInterface = response.data.user;
    Sentry.setUser({ id: user._id, email: user.email, username: user.pseudo });

    dispatch(managementModal(false));
    dispatch({
        type: AuthActionsType.AUTH_SUCCESS,
        payload: {
            token: response.data.token,
            user: user,
            giftRegister: response.data.giftRegister,
        },
    });

    if (history) {
        if (Utils.getIsVIPInLocalStorage())
            return history.push(HOME_AFTER_SIGNUP.VIP);
        return history.push(HOME_AFTER_SIGNUP.USER);
    }
};

export const actionConnectUser = (form: { email: string, password: string, firstName: string, lastName: string, mode: string, token: string; }, onSuccessCallback?: () => void): ThunkAction<Promise<void>, null, unknown, Action<string>> => async (dispatch) => {

    dispatch(managementModal(false));

    if (form.mode === FormMode.REGISTER) {
        return dispatch(actionRegisterUser(form.email, form.password, form.lastName, form.firstName, form.token));
    }
    return dispatch(actionLoginUser(form.email, form.password, undefined, undefined, onSuccessCallback));
};

export const actionLoginUser = (email: string, password: string, history?: any, redirect?: string, onSuccessCallback?: () => void): ThunkAction<Promise<void>, null, unknown, Action<string>> => async (dispatch) => {
    dispatch(managementModal(true, {
        type: 'LOADING_MODAL',
        showCancelButton: false,
        message: '',
    }));
    TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.LOGIN);

    let response = null;

    try {
        dispatch({ type: AuthActionsType.AUTH_LOADING });
        response = await AxiosConfig.post('/auth/login', { email, password });
    } catch (error) {

        showNotification(dispatch, i18next.t('notification.wrongemailpass'), HTTP_STATUS_INTERNAL_ERROR);
        dispatch(managementModal(false));
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }

    dispatch(managementModal(false));

    if (!response) {
        showNotification(dispatch, 'Une erreur est survenue lors de votre connexion', HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }

    if (response.status !== HTTP_STATUS_OK) {
        showNotification(dispatch, response.data.message, response.status);
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }

    initializeJwtHeaderAndUinqueUserId(response.data.token, response.data.uniqueUserId);
    const user: UserInterface = response.data.user;
    Sentry.setUser({ id: user._id, email: user.email, username: user.pseudo });
    dispatch({
        type: AuthActionsType.AUTH_SUCCESS,
        payload: {
            token: response.data.token,
            user: user,
        },
    });

    if (history)
        return history.replace(redirect ? redirect : Utils.getHome(user));

    if (onSuccessCallback) onSuccessCallback();
};

export const actionLoadMe = (transparentLoading?: boolean): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        if (!transparentLoading)
            dispatch({ type: AuthActionsType.AUTH_LOADING });

        const cookieJwt: string | undefined = Cookies.get(TRENDEX_COOKIES.JWT_TDX.name);
        const cookieUniqueUserId: string | undefined = Cookies.get(TRENDEX_COOKIES.UNIQUE_USER_ID.name);

        if (cookieJwt) {
            const decodedJWT = Utils.parseJwt(cookieJwt);
            if (decodedJWT.exp < new Date().getTime().safeDivideBy(1000))
                return dispatch(actionLogoutUser());
            initializeJwtHeaderAndUinqueUserId(cookieJwt, cookieUniqueUserId);
        } else {
            return dispatch({ type: AuthActionsType.AUTH_FAILED });
        }

        dispatch(actionIsAuthLoadMeLoading(true));
        const response = await AxiosConfig.get(AUTH_ENDPOINTS.LOAD_ME);
        if (response && !response.data.user) {
            dispatch(actionIsAuthLoadMeLoading(false));
            return dispatch({ type: AuthActionsType.AUTH_FAILED });
        }

        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_AUTH_STATE, 'true');
        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_AUTH, JSON.stringify(response.data.user));
        if (!localStorage.getItem(LOCAL_STORAGE_KNOWN_KEYS.KNOWN_USER)) {
            const cypher = Utils.unsafeCipher('AMotherFuckingSecretSaltOfHellFromAjaccio');
            localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.KNOWN_USER, cypher(response.data.user._id.toString()));
        }
        const user: UserInterface = response.data.user;
        Sentry.setUser({ id: user._id, email: user.email, username: user.pseudo });

        const intercom = IntercomService.getInstance();
        if (!intercom.isUserUpdated())
            intercom.update({
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                created_at: moment(user.createdAt).unix(),
            }, true);

        return batch(() => {
            dispatch({
                type: AuthActionsType.AUTH_SUCCESS,
                payload: {
                    user: response.data.user,
                    isAdminToVip: response.data.isAdminToVip || false,
                    giftRegister: response.data.giftRegister,
                },
            });
            dispatch(getUserWallet(user._id));
            dispatch(actionIsAuthLoadMeLoading(false));
        });

    } catch (error) {
        dispatch(actionIsAuthLoadMeLoading(false));
        return dispatch({ type: AuthActionsType.AUTH_FAILED });
    }
};

const resetUserDataOnLogout = () => {
    Utils.clearUserData();
    clearAxiosJWTAndUniqueUserIdHeader();
};

export const actionLogoutUser = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response = await AxiosConfig.get('/auth/logout');

        if (response.status === HTTP_STATUS_OK) {
            if (response.data.preview) {
                initializeJwtHeaderAndUinqueUserId(response.data.token, response.data.uniqueUserId);
                const user: UserInterface = response.data.user;
                Sentry.setUser({ id: user._id, email: user.email, username: user.pseudo });
                dispatch({
                    type: AuthActionsType.AUTH_SUCCESS,
                    payload: {
                        token: response.data.token,
                        user: user,
                    },
                });
            } else {
                IntercomService.getInstance().shutdown();
                Sentry.setUser(null);
                resetUserDataOnLogout();
                mixpanel.track(MIXPANEL_KNOWN_KEYS.TRACK.USER_SESSION_ENDS);

                batch(() => {
                    dispatch({ type: AuthActionsType.AUTH_LOGOUT });
                    dispatch({ type: RESET_STATE });
                });
            }
        } else {
            showNotification(dispatch, response.data.message, response.status);
            dispatch({ type: AuthActionsType.AUTH_FAILED });
            return dispatch({ type: RESET_STATE });
        }
    } catch (error) {
        resetUserDataOnLogout();
        dispatch({ type: AuthActionsType.AUTH_LOGOUT });
    }
};

export const uploadProfileImage = (file: any): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    try {
        const form = new FormData();

        form.append('file', file);

        dispatch({ type: AuthActionsType.UPLOAD_PROFILE_PICTURE_LOADING });

        const response: IHttpResponse = await AxiosConfig.post('/users/uploadImage', form);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            (response.data.status === TRENDEX_HTTP_STATUS_NOK
                ? showNotification(dispatch, i18next.t('Server.couldnot.upload-profile'), response.data.status)
                : showNotification(dispatch, response.data.message, response.data.status)
            );
            return dispatch({
                type: AuthActionsType.UPLOAD_PROFILE_PICTURE_FAILED,
            });
        }

        showNotification(dispatch, i18next.t('Server.assets.upload.uploadS3ImgAsset.success'), response.data.status);
        return dispatch({
            type: AuthActionsType.UPLOAD_PROFILE_PICTURE_SUCCESS,
            payload: {
                avatar: response.data.data.image,
            },
        });
    } catch (error: any) {
        const errorMsg = error?.response?.data?.message;
        showNotification(dispatch, errorMsg || (error as Error).message, HTTP_STATUS_INTERNAL_ERROR);

        return dispatch({
            type: AuthActionsType.UPLOAD_PROFILE_PICTURE_FAILED,
        });

    }
};

export const sendIdentity = (rectoImage: any, versoImage: any, identificationDocumentNumber: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    try {
        const form = new FormData();

        form.append('rectoImage', rectoImage);
        form.append('versoImage', versoImage);
        form.append('identificationDocumentNumber', identificationDocumentNumber);

        dispatch({ type: AuthActionsType.UPLOAD_IDENTITY_LOADING });

        const response = await AxiosConfig.post('/users/sendIdentity', form);

        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            dispatch(managementNotification(true, i18next.t(response.data.message), 500));

            return dispatch({
                type: AuthActionsType.UPLOAD_IDENTITY_FAILED,
            });
        }

        return dispatch({
            type: AuthActionsType.UPLOAD_IDENTITY_SUCCESS,
            payload: {
                user: response.data.user,
            },
        });

    } catch (error) {

        showNotification(dispatch, i18next.t('ERRORS.GENERIC'), HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: AuthActionsType.UPLOAD_IDENTITY_FAILED,
        });
    }
};

export const sendEmailVerification = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response = await AxiosConfig.post('/users/sendEmailVerification');

        if (response && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AuthActionsType.AUTH_FAILED,
            });
        }

        return dispatch({
            type: AuthActionsType.AUTH_SUCCESS,
            payload: {
                email: response.data.status,
                user: response.data.user,
            },
        });

    } catch (error) {

        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }
};

export const verifyAccount = (token: string): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {

    try {
        const response = await AxiosConfig.get(`/users/verifyAccount/${token}`);

        if (response && response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AuthActionsType.AUTH_FAILED,
            });
        }
        TagManagerWrapper.sendEvent(G_TAGS_KNOWN_EVENTS.IDENTITY_VERIFICATION_REQUEST, {
            userId: response.data.user._id,
        });
        SegmentTracking.sendIdentityVerificationRequestEvent({ user: response.data.user });
        return dispatch({
            type: AuthActionsType.AUTH_SUCCESS,
            payload: {
                user: response.data.user,
            },
        });

    } catch (error) {

        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }
};

export const actionUpdateAsAdmin = (_id: string, email: string, lastName: string, firstName: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    let response = null;

    try {

        response = await AxiosConfig.post('/users/updateAsAdmin', { _id, email, lastName, firstName });

    } catch (error) {

        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }

    if (!response) {

        showNotification(dispatch, i18next.t('notification.updatingdataerror'), HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }

    if (response.data.status !== HTTP_STATUS_OK) {

        showNotification(dispatch, i18next.t('notification.unauthorize'), response.data.status);
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }
};

export const actionUpdateUserInformation = (nextUserData: Object & { language?: string; }, disableNotification?: Boolean, onSuccessCallback?: () => void): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    dispatch({
        type: AuthActionsType.AUTH_LOADING,
    });

    try {
        const response: IHttpResponse = await AxiosConfig.put('/users/updateInformations', nextUserData);

        if (response.data.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, response.data.message, HTTP_STATUS_INTERNAL_ERROR);
            return dispatch({
                type: AuthActionsType.AUTH_FAILED,
            });
        }

        localStorage.setItem(LOCAL_STORAGE_KNOWN_KEYS.TDX_AUTH, JSON.stringify(response.data.data));

        if (nextUserData.language) {
            Utils.changeCurrentLanuage(nextUserData.language);
        }

        dispatch({
            type: AuthActionsType.UPDATE_AUTH_KEY,
            payload: {
                user: response.data.data,
            },
        });
        if (onSuccessCallback) onSuccessCallback();
        if (!disableNotification) {
            showNotification(dispatch, response.data.message, response.data.status);
            return dispatch({
                type: AuthActionsType.AUTH_SUCCESS,
                payload: {
                    user: response.data.data,
                },
            });
        }

    } catch (error) {
        console.log(error);
        showNotification(dispatch, i18next.t('Common.an-error-occurred'), HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }
};

export const actionChangeAsset = (asset: AssetInterface): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    try {

        return dispatch({
            type: AuthActionsType.CHANGE_ASSET,
            payload: asset,
        });

    } catch (error) {

        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }
};

export const loginPreview = (assetId: string, history: any, mode: string, userId: string): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {

    if (!userId && !assetId) {
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }

    try {

        const response = await AxiosConfig.post(`auth/preview/login/${mode}`, { assetId, userId });

        if (response.data.status === HTTP_STATUS_OK) {
            initializeJwtHeaderAndUinqueUserId(response.data.token, response.data.uniqueUserId);

            dispatch({
                type: AuthActionsType.AUTH_SUCCESS,
                payload: {
                    token: response.data.token,
                    user: response.data.user,
                    isAdminToVip: !!(response.data.user.type === TRENDEX_ROLES.VIP),
                },
            });

            return history.replace('/');
        }
        showNotification(dispatch, response.data.message, response.data.status);

    } catch (error) {

        showNotification(dispatch, (error as Error).message, HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }
};

export const actionPostSubscribeToAsset = (assetId: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    dispatch({
        type: AuthActionsType.SUBSCRIBE_TO_ASSET_START,
        payload: assetId,
    });

    const response = await AxiosConfig.post(`/assets/subscribe/${assetId}`);

    if (response.status !== HTTP_STATUS_OK) {
        return dispatch({
            type: AuthActionsType.SUBSCRIBE_TO_ASSET_FAILED,
            message: response.data.data.message,
        });
    }

    const { subscribedAssetsIds } = response.data.data.data;
    return dispatch({
        type: AuthActionsType.SUBSCRIBE_TO_ASSET_SUCCESS,
        payload: subscribedAssetsIds,
    });
};

export const magicLink = (token: string, history: any): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response = await AxiosConfig.post('auth/magiclink', { token });
        if (response.data.status === HTTP_STATUS_OK) {
            initializeJwtHeaderAndUinqueUserId(response.data.token, response.data.uniqueUserId);
            const user: UserInterface = response.data.user;
            Sentry.setUser({ id: user._id, email: user.email, username: user.pseudo });
            dispatch({
                type: AuthActionsType.AUTH_SUCCESS,
                payload: {
                    token: response.data.token,
                    user: user,
                },
            });
            return history.replace('/');
        }
        showNotification(dispatch, response.data.message, response.data.status);
    } catch (error) {
        showNotification(dispatch, (error as Error).message, HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }
};

export const acceptCGUPayment = (): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: AuthActionsType.UPDATE_USER_CGU_LOADING });
        const response: IHttpResponse = await AxiosConfig.post('/userCGUaccepted');
        if (response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AuthActionsType.AUTH_FAILED,
            });
        }
        return dispatch({
            type: AuthActionsType.UPDATE_USER_CGU_SUCCESS,
            payload: {
                user: response.data.data,
            },
        });

    } catch (error) {
        showNotification(dispatch, (error as Error).message, HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: AuthActionsType.UPLOAD_PROFILE_PICTURE_FAILED,
        });
    }
};

export const actionTokenSocialConnect = (provider: OauthProviderEnum, history: any, successCallback?: (mode: string, socialProvider: OauthProviderEnum) => void): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    let access_token: string | null = null;
    let response: any;
    const socialData: any = {};

    try {
        switch (provider) {
            case OauthProviderEnum.FACEBOOK:
                const responseFacebook = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
                if (responseFacebook.accessToken) {
                    access_token = responseFacebook.accessToken.token;
                }
                break;
            case OauthProviderEnum.GOOGLE:
                const responseGoogle = await GoogleAuth.signIn();
                access_token = responseGoogle.authentication.accessToken;
                break;
            case OauthProviderEnum.APPLE:
                const responseApple: SignInWithAppleResponse = await SignInWithApple.authorize(SIGN_IN_WITH_APPLE_OPTIONS);
                if (responseApple.response) {
                    access_token = responseApple.response.identityToken;
                    if (responseApple.response.email) {
                        socialData.email = responseApple.response.email;
                    }
                }
                break;
        }
        if (!access_token) {
            showNotification(dispatch, i18next.t('ActionTokenSocialConnect.error'), HTTP_STATUS_INTERNAL_ERROR);
            return dispatch({
                type: AuthActionsType.AUTH_FAILED,
            });
        }

        response = await AxiosConfig.get(`auth/${provider.toLowerCase()}/token?access_token=${access_token}`);
        console.log('response', response);

        if ((response.data.status !== HTTP_STATUS_OK) || (!response.data.token)) {
            showNotification(dispatch, i18next.t('ActionTokenSocialConnect.error'), HTTP_STATUS_INTERNAL_ERROR);
            return dispatch({
                type: AuthActionsType.AUTH_FAILED,
            });
        }

        initializeJwtHeaderAndUinqueUserId(response.data.token, response.data.uniqueUserId);
        const user: UserInterface = response.data.user;
        Sentry.setUser({ id: user._id, email: user.email, username: user.pseudo });
        dispatch({
            type: AuthActionsType.AUTH_SUCCESS,
            payload: {
                token: response.data.token,
                user: user,
                giftRegister: response.data.giftRegister,
            },
        });

        if (typeof successCallback !== 'undefined') {
            successCallback(moment().diff(moment(response.data.user.createdAt), 'seconds') < 30 ? CONNECTION_MODE.REGISTER : CONNECTION_MODE.LOGIN, provider);
        }
        return history.replace(Utils.getHome(user));

    } catch (e) {
        if (e && (!isEmpty(e) && !CANCEL_SIGN_IN_MESSAGES.includes((e as Error).message))) {
            showNotification(dispatch, i18next.t('ActionTokenSocialConnect.error'), HTTP_STATUS_INTERNAL_ERROR);
        }
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }
};
export const actionRegisterSignupSource = (signupSourceChannel: string, signupSourceId: string): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpResponse = await AxiosConfig.post('/auth/signupSource', { signupSourceChannel, signupSourceId });
        if (response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AuthActionsType.AUTH_FAILED,
            });
        }
        return dispatch(actionLoadMe());
    } catch (error) {
        console.log('error: ', error);
        return dispatch({
            type: AuthActionsType.AUTH_FAILED,
        });
    }
};

export const getUserCredits = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<IGetCredits> = await AxiosConfig.post(USER_ENDPOINTS.GET_USER_CREDITS);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK)
            return dispatch({
                type: AuthActionsType.GET_CREDITS_ERROR,
            });
        return dispatch({
            type: AuthActionsType.GET_CREDITS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        return dispatch({ type: AuthActionsType.GET_CREDITS_ERROR });
    }
};
export const acceptReferralTerms = (): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: AuthActionsType.UPDATE_USER_REFERRAL_TERMS_LOADING });
        const response: IHttpStrongTypedResponse<UserInterface> = await AxiosConfig.post(AUTH_ENDPOINTS.REFERRAL_TERMS_USER_BASE_URL);
        showNotification(dispatch, i18next.t(response.data.message), response.data.status);
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) return dispatch({ type: AuthActionsType.UPDATE_USER_REFERRAL_TERMS_FAILED });
        return dispatch({
            type: AuthActionsType.UPDATE_USER_REFERRAL_TERMS_SUCCESS,
            payload: {
                data: response.data.data,
            },
        });
    } catch (error) {
        return dispatch({ type: AuthActionsType.UPDATE_USER_REFERRAL_TERMS_FAILED });
    }
};

export const resetPassword = (token: string, newPassword: string, email: string, history: any, redirectAfterLogin: string, callback?: Function): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: AuthActionsType.RESET_PASSWORD_LOADING });
        const response: IHttpStrongTypedResponse<UserInterface> = await AxiosConfig.post(`${AUTH_ENDPOINTS.RESET_PASSWORD_BASE_URL}${token}`, { password: newPassword });
        if (response && response.data && response.data.status !== HTTP_STATUS_OK) {
            const message: string = i18next.t(response.data.message);
            showNotification(dispatch, message, response.data.status);
            return dispatch({
                type: AuthActionsType.RESET_PASSWORD_FAILED,
                payload: message,
            });
        }
        dispatch(actionLoginUser(email, newPassword, history, redirectAfterLogin));
        if (callback)
            callback();
        return dispatch({
            type: AuthActionsType.RESET_PASSWORD_SUCCESS,
            payload: response.data.data,
        });
    } catch (exception) {
        const message: string = i18next.t('Becomevippage.submitFailed');
        showNotification(dispatch, message, HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: AuthActionsType.RESET_PASSWORD_FAILED,
            payload: message,
        });
    }
};

export const getClaimableRewardAndResultForPersonalTournament = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<IClaimableUserReward> = await AxiosConfig.get(REWARDS_ENDPOINTS.USER_PERSONAL_TOURNAMENT_REWARD);
        return internalHandleClaimableRewardResponse(response, dispatch);
    } catch (exception) {
        const message: string = i18next.t('getClaimableRewardAndResultFromPreviousTournamentByUser.Failed');
        showNotification(dispatch, message, HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: AuthActionsType.RESET_PASSWORD_FAILED,
            payload: message,
        });
    }
};

export const getClaimableRewardAndResultFromPreviousTournamentByUser = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<IClaimableUserReward> = await AxiosConfig.get(`${REWARDS_ENDPOINTS.REWARD_LAST_TOURNAMENT_BY_USER_ID}`);
        if (response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AuthActionsType.REWARD_FAILED,
                payload: i18next.t(response.data.message),
            });
        }
        return dispatch({
            type: AuthActionsType.REWARD_SUCCESS,
            payload: {
                data: response.data.data ?? {},
            },
        });
    } catch (exception) {
        const message: string = i18next.t('getClaimableRewardAndResultFromPreviousTournamentByUser.Failed');
        showNotification(dispatch, message, HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: AuthActionsType.RESET_PASSWORD_FAILED,
            payload: message,
        });
    }
};

const internalHandleClaimableRewardResponse = (response: IHttpStrongTypedResponse<IClaimableUserReward>,
    dispatch: ThunkDispatch<null, unknown, Action<string>>) => {
    if (response.data.status !== HTTP_STATUS_OK) {
        return dispatch({
            type: AuthActionsType.REWARD_FAILED,
            payload: i18next.t(response.data.message),
        });
    }

    return dispatch({
        type: AuthActionsType.REWARD_SUCCESS,
        payload: {
            data: response.data.data ?? {},
        },
    });
};

export const handleReward = (rewardId: string): ThunkAction<Promise<any>, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const response: IHttpStrongTypedResponse<IRewardTransaction> = await AxiosConfig.post(`${REWARDS_ENDPOINTS.HANDLE_CLAIMABLE_REWARD}`, { rewardId });
        if (response.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AuthActionsType.HANDLE_CLAIMABLE_REWARD_ERROR,
                payload: i18next.t(response.data.message),
            });
        }
        return dispatch({
            type: AuthActionsType.HANDLE_CLAIMABLE_REWARD_SUCCESS,
            payload: {
                data: response.data.data,
            },
        });
    } catch (exception) {
        const message: string = i18next.t('handleReward.Failed');
        showNotification(dispatch, message, HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({
            type: AuthActionsType.HANDLE_CLAIMABLE_REWARD_ERROR,
            payload: message,
        });
    }
};

export const addAssetSharesToAssetWallet = (assetId: string, amountOfShares: number): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    dispatch({
        type: AuthActionsType.ADD_ASSET_TO_ASSETWALLET, payload: {
            amountOfShares,
            assetId,
        },
    });
};

export const saveCookieConsentChoice = (cookieConsentAccepted: boolean): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const cookieConsentResponse: IHttpStrongTypedResponse<IUserCookieConsentResponse> = await AxiosConfig.post(cookieConsentAccepted ? AUTH_ENDPOINTS.ACCEPT_COOKIE_CONSENT : AUTH_ENDPOINTS.DECLINE_COOKIE_CONSENT);
        if (cookieConsentResponse.data.status !== HTTP_STATUS_OK)
            throw new Error(cookieConsentResponse.data.message);
        return dispatch({
            type: AuthActionsType.UPDATE_AUTH_KEY,
            payload: {
                user: cookieConsentResponse.data.data,
            },
        });
    } catch (exception) {
        let message = 'notification.updatingdataerror';
        if (exception instanceof TypeError)
            message = exception.message;
        return showNotification(dispatch, message, HTTP_STATUS_INTERNAL_ERROR);
    }
};

export const updateUserAbTesting = (abTesting: IUserAbTesting): ThunkAction<void, null, unknown, Action<String>> => async (dispatch) => {
    try {
        const updateAbTestingResponse: IHttpStrongTypedResponse = await AxiosConfig.post(USER_ENDPOINTS.UPDATE_AB_TESTING, { abTesting });
        if (updateAbTestingResponse && updateAbTestingResponse.data && updateAbTestingResponse.data.status !== HTTP_STATUS_OK) {
            return dispatch({
                type: AuthActionsType.AUTH_FAILED,
            });
        }
        return dispatch({
            type: AuthActionsType.UPDATE_AUTH_KEY,
            payload: { user: updateAbTestingResponse.data.data },
        });
    } catch (error) {
        return dispatch({ type: AuthActionsType.AUTH_FAILED });
    }
};

export const claimRewardAtRegister = (referralId: string | null): ThunkAction<void, null, unknown, Action<string>> => async (dispatch: Function) => {
    try {
        const claimRewardAtRegisterReponse: IHttpStrongTypedResponse<IClaimRewardRewardAtRegisterResponse> = await AxiosConfig.post(AUTH_ENDPOINTS.CLAIM_REWARD_AT_REGISTER, { usedReferralId: referralId });
        if (claimRewardAtRegisterReponse && claimRewardAtRegisterReponse.data && claimRewardAtRegisterReponse.data.status !== HTTP_STATUS_OK)
            return dispatch({ type: AuthActionsType.AUTH_FAILED });
        return dispatch({
            type: AuthActionsType.REWARD_REGISTER,
            payload: { ...claimRewardAtRegisterReponse.data.data },
        });
    } catch (error) {
        return dispatch({ type: AuthActionsType.AUTH_FAILED });
    }
};

export const createUserPersonalTournament = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({
            type: AuthActionsType.PERSONAL_TOURNAMENT_CREATE_LOADING
        });
        const createTournamentResponse: IHttpStrongTypedResponse<ITournament> = await AxiosConfig.post(USER_TOURNAMENT_ENDPOINTS.CREATE);
        if (createTournamentResponse.data?.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, i18next.t('userPersonalTournament.create.failed'), HTTP_STATUS_INTERNAL_ERROR);
            return dispatch({ type: AuthActionsType.PERSONAL_TOURNAMENT_CREATE_ERROR });
        }
        return dispatch({
            type: AuthActionsType.PERSONAL_TOURNAMENT_CREATE_SUCCESS,
            payload: createTournamentResponse.data.data
        });
    } catch (exception) {
        return dispatch({ type: AuthActionsType.PERSONAL_TOURNAMENT_CREATE_ERROR });
    }
};

export const subscribeToUserPersonalTournament = (accountViewModel: AccountViewModel, onSubscribedSuccessCallback?: () => void): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({
            type: AuthActionsType.PERSONAL_TOURNAMENT_SUBSCRIBE_LOADING
        });
        const subscribeToTournamentResponse: IHttpStrongTypedResponse = await AxiosConfig.post(USER_TOURNAMENT_ENDPOINTS.SUBSCRIBE);
        console.log(subscribeToTournamentResponse.data);
        if (subscribeToTournamentResponse.data?.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, i18next.t('userPersonalTournament.subscribe.failed'), HTTP_STATUS_INTERNAL_ERROR);
            return dispatch({ type: AuthActionsType.PERSONAL_TOURNAMENT_SUBSCRIBE_ERROR });
        }
        if (!accountViewModel.HasPersonalTournament && Application.getInstance().UserPersonalTournamentConfig.enabled)
            return getUserPersonalTournament();
        if (onSubscribedSuccessCallback)
            onSubscribedSuccessCallback();
        return dispatch({
            type: AuthActionsType.PERSONAL_TOURNAMENT_SUBSCRIBE_SUCCESS,
            payload: {
                subscribed: true
            }
        });
    } catch (exception) {
        return dispatch({ type: AuthActionsType.PERSONAL_TOURNAMENT_SUBSCRIBE_ERROR });
    }
};

export const getUserPersonalTournament = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch) => {
    try {
        const getTournamentResponse: IHttpStrongTypedResponse<{ personalTournament: ITournament; }> = await AxiosConfig.get(USER_TOURNAMENT_ENDPOINTS.GET);
        if (getTournamentResponse.data?.status !== HTTP_STATUS_OK) {
            showNotification(dispatch, i18next.t('userPersonalTournament.get.failed'), HTTP_STATUS_INTERNAL_ERROR);
            return dispatch({ type: AuthActionsType.GET_PERSONAL_TOURNAMENT_ERROR });
        }
        return dispatch({
            type: AuthActionsType.GET_PERSONAL_TOURNAMENT_SUCCESS,
            payload: getTournamentResponse.data.data.personalTournament
        });
    } catch (exception) {
        return dispatch({ type: AuthActionsType.GET_PERSONAL_TOURNAMENT_ERROR });
    }
};

export const updateVerificationIdentity = (status: VERIFICATION_STATUS): ThunkAction<void, null, unknown, Action<string>> => async (dispatch: Function) => {
    return dispatch({
        type: AuthActionsType.UPDATE_VERIFICATION_IDENTITY,
        payload: { isConfirmed: status },
    });
};

export const launchOnboarding = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch: Function) => {
    const { data: { status, message } }: IHttpStrongTypedResponse = await AxiosConfig.put(AUTH_ENDPOINTS.UPDATE_ONBOARDING_STEP, { onboardingStep: OnboardingStep.VIEW_LIGHT_WALLET });
    if (status !== HTTP_STATUS_OK) {
        showNotification(dispatch, message, HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({ type: AuthActionsType.AUTH_FAILED })
    }
    return dispatch({
        type: AuthActionsType.LAUNCH_ONBOARDING,
        payload: { onboardingStep: OnboardingStep.VIEW_LIGHT_WALLET },
    });
};

export const finishOnboarding = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch: Function) => {
    const { data: { status, message } }: IHttpStrongTypedResponse = await AxiosConfig.put(AUTH_ENDPOINTS.UPDATE_ONBOARDING_STEP, { onboardingStep: OnboardingStep.FINISH });
    if (status !== HTTP_STATUS_OK) {
        showNotification(dispatch, message, HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({ type: AuthActionsType.AUTH_FAILED })
    }
    return dispatch({
        type: AuthActionsType.FINISH_ONBOARDING_STEP,
        payload: { onboardingStep: OnboardingStep.FINISH },
    });
};

export const updateOnboardingStep = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch: Function, getState: any) => {
    const state: RootState = getState();
    const auth: UserInterface = state.auth.data.account;
    const onboardingStep: number = auth.onboardingStep + 1;
    const { data: { status, message } }: IHttpStrongTypedResponse = await AxiosConfig.put(AUTH_ENDPOINTS.UPDATE_ONBOARDING_STEP, { onboardingStep });
    if (status !== HTTP_STATUS_OK) {
        showNotification(dispatch, message, HTTP_STATUS_INTERNAL_ERROR);
        return dispatch({ type: AuthActionsType.AUTH_FAILED })
    }
    return dispatch({
        type: AuthActionsType.UPDATE_ONBOARDING_STEP,
        payload: {
            onboardingStep: onboardingStep
        },
    });
};

export const pollingKycStatus = (): ThunkAction<void, null, unknown, Action<string>> => async (dispatch: Function) => {
    const response = await AxiosConfig.get(AUTH_ENDPOINTS.POLLING_KYC_STATUS);
    if (response.data.status !== HTTP_STATUS_OK)
        return dispatch({
            type: AuthActionsType.POLLING_KYC_STATUS_ERROR,
        });

    return dispatch({
        type: AuthActionsType.POLLING_KYC_STATUS_SUCCESS,
        payload: response.data.data,
    });
}

