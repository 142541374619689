import ReactPixel from 'react-facebook-pixel';
import IPixelHandler from "../pixel/IPixelHandler";
import IRegisterData from './IRegisterData';

export default class PixelHandlerRegisterUser implements IPixelHandler {
    private data: IRegisterData;
    private static customFacebookEventName: string = 'CompleteRegistration';

    constructor(){
        this.data = {
            userEmail: '',
            status: false
        };
    }

    public get registerData() {
        return this.data;
    }

    public set registerData(data: IRegisterData) {
        this.data = data;
    }

    handle(): void {
        ReactPixel.track(PixelHandlerRegisterUser.customFacebookEventName, this.data);
    }

}