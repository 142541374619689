import React from 'react';
import { withTranslation } from 'react-i18next';
import Panel from 'components/small/panel/Panel';
import BenzemaFAQProps from './BenzemaFAQProps';
import './style.scss';
import benzemaFAQData from 'data/benzemaFAQData';

const BenzemaFAQ: React.FC<BenzemaFAQProps> = ({ t: translate, assetViewModel }) => {
    const [activeTabForGeneral, setActiveTabForGeneral] = React.useState<number>(-1);
    const activateTabGeneral = (index: number) => setActiveTabForGeneral(activeTabForGeneral === index ? -1 : index);
    const i18nPrefix: string = assetViewModel.isCustomBenzemaProfilPageBeforePublicSales() ? 'benzema.early.bird.' : '';

    return (
        <div className="benzema-faq">
            {benzemaFAQData.map(({ question, answer }, index: number) => {
                return (
                    <Panel
                        key={question}
                        activeTab={activeTabForGeneral}
                        index={index}
                        questions={translate(`${i18nPrefix}${question}`)}
                        answer={translate(`${i18nPrefix}${answer}`)}
                        activateTab={() => activateTabGeneral(index)} />
                )
            })}
        </div>
    )
};

export default withTranslation()(React.memo(BenzemaFAQ));
