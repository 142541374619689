import React from "react";
import './ScoreBoosterDetailBlock.scss';
import ScoreBoosterDetail from "../score-booster-detail/ScoreBoosterDetail";
import Divider from "../../divider/Divider";
import useScoreBooster from "hooks/score-booster/useScoreBooster";
import IScoreBoosterDetailBlockProps from "./IScoreBoosterDetailBlockProps";
import { Trans } from "react-i18next";
import useTournament from "hooks/tournament/use-tournament/useTournament";
import { DEFAULT_ID_AS_STRING } from "config/_const";


const ScoreBoosterDetailBlock: React.FC<IScoreBoosterDetailBlockProps> = (props) => {
    const { tournamentInProgress } = useTournament();
    const { scoreBoosterVM } = useScoreBooster({ runningTournamentId: tournamentInProgress?.Id || DEFAULT_ID_AS_STRING })

    return (
        <div className="score-booster-detail-block">
            {props.canShowTitle &&
                <>
                    <Divider size="98%" />
                    <h2>
                        <Trans i18nKey='booster.title' />
                    </h2>
                </>
            }
            <ScoreBoosterDetail scoreBoosterVM={scoreBoosterVM} closeBoosterModal={props.closeBoosterModal} />
        </div>)
}

export default ScoreBoosterDetailBlock;