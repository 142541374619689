import clsx from 'clsx';
import { HISTORY_CHART, MINIMUM_EQUAL_VALUE_GRAPH_THRESHOLD, ZERO } from 'config/_const';
import IUseAssetsScoreEvolution from 'hooks/evolution/use-assets-score-evolution/IUseAssetsScoreEvolution';
import useAssetsScoreEvolution from 'hooks/evolution/use-assets-score-evolution/useAssetsScoreEvolution';
import i18next from 'i18next';
import moment from 'moment';
import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';
import Utils from 'utils/Utils';
import { IScoreDateRangeHistory } from './IScoreHistoryGraph';
import './ScoreHistoryGraph.scss';
import IScoreGraphHistoryProps from './ScoreHistoryGraphProps';


const SCORE_DATE_RANGE_OPTIONS = [
    { label_TranslateKey: 'assetProfile.buySharesForm.dateRange.day', dateRange: 86400000, shouldShowEqualValue: true },
    { label_TranslateKey: 'assetProfile.buySharesForm.dateRange.week', dateRange: 604800000, shouldShowEqualValue: true },
    { label_TranslateKey: 'assetProfile.buySharesForm.dateRange.month', dateRange: 2628000000, shouldShowEqualValue: true },
    { label_TranslateKey: 'assetProfile.buySharesForm.dateRange.year', dateRange: 31536000000, shouldShowEqualValue: false },
    { label_TranslateKey: 'assetProfile.buySharesForm.dateRange.all', dateRange: Number.POSITIVE_INFINITY, shouldShowEqualValue: false },
];

const ScoreHistoryGraph: React.FC<IScoreGraphHistoryProps> = (props) => {
    const CHART_HEIGHT: number = 100;
    const oneYear = 1;
    const currentDate = new Date();
    const totalYearOfAssetCreated = moment().diff(moment(props.asset.CreatedAt), "years");
    const [selectedDateRange, setSelectedDateRange] = React.useState<number>(Number.POSITIVE_INFINITY);

    const assetProfileScoreHistoryOptions = (categories: string[], scalesY: number[], canShowLegend?: boolean) => ({
        colors: ['#53D081'],
        chart: {
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: categories,
            labels: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false,
        },
        grid: {
            show: false
        },
        stroke: {
            width: 3,
            color: 'green',
            curve: 'smooth'
        },
        fill: {
            opacity: 0.5,
            color: 'green',
            type: "gradient",
            gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 1,
                gradientToColors: undefined,
                inverseColors: false,
                opacityFrom: 0.7,
                opacityTo: 0.7,
                stops: [0, 70, 100],
                colorStops: []
            }
        },
        dataLabels: { enabled: false },
        style: { padding: 0, margin: 0 },
        tooltip: {
            enabled: true,
            x: { show: false },
            y: {
                formatter: (val: string, { seriesIndex }: { seriesIndex: number }) => {
                    const valInNum = (parseFloat(val) < ZERO) ? ZERO : parseFloat(val);
                    return Math.round(valInNum / (scalesY[seriesIndex] || 1));
                },
                title: {
                    formatter: () => ""
                }
            },
            marker: { show: false },
        },
        legend: {
            show: canShowLegend ?? true,
            position: "top",
            horizontalAlign: "left",
            showForSingleSeries: true,
            fontSize: "16px",
            labels: {
                useSeriesColors: true,
            },
            markers: {
                offsetX: "-10px"
            },
            onItemClick: { toggleDataSeries: false }
        }
    });

    const { assetsWithEvolution }: IUseAssetsScoreEvolution = useAssetsScoreEvolution({
        assetsIds: [props.asset.AssetId],
        onlyOncePerDay: true,
        startDate: (totalYearOfAssetCreated >= oneYear)
            ? currentDate.subtractDates({ years: oneYear }).toISOString()
            : props.asset.CreatedAt,
    });
    const irlScoreEvolution = React.useMemo(() => assetsWithEvolution.find(assetEvolution => assetEvolution.AssetId === props.asset.AssetId)?.SnapshotsIRLScoreEvolution || [], [assetsWithEvolution, props.asset]);

    const scoreDateRangeHistory: IScoreDateRangeHistory[] = React.useMemo(() => {
        if (!irlScoreEvolution?.length) return [];
        let finalScoreDateRangeHistory: IScoreDateRangeHistory[] = [];
        let goForwardFlag = true;
        const currentDate = new Date();
        SCORE_DATE_RANGE_OPTIONS.forEach((option) => {
            if (goForwardFlag) {
                const scoreHistoryOnRange = irlScoreEvolution.filter((scoreHistory) => {
                    return option.dateRange > (currentDate.getTime() - new Date(scoreHistory.date).getTime());
                }).map((scoreHistory => ({ ...scoreHistory, date: new Date(scoreHistory.date).toISOString() })));
                if (scoreHistoryOnRange.length) {
                    setSelectedDateRange(option.dateRange);
                    goForwardFlag = !(irlScoreEvolution.length === scoreHistoryOnRange.length);
                    const shouldShowEqualValues = option.shouldShowEqualValue || scoreHistoryOnRange.length <= MINIMUM_EQUAL_VALUE_GRAPH_THRESHOLD;
                    const scoreHistoryFormatted = Utils.formatIrlScoreHistoryByDay(scoreHistoryOnRange, !shouldShowEqualValues);
                    const scoreHistoryFormattedWithDefault = scoreHistoryFormatted.length === 1 ? [...scoreHistoryFormatted, { score: props.asset.getTrendScoreToDisplay(2), frenchFormattedDate: new Date().formatDDMMYY() }] : scoreHistoryFormatted;
                    const scoreList = scoreHistoryFormattedWithDefault.map(scoreHistory => scoreHistory.score);
                    const dateList = scoreHistoryFormattedWithDefault.map(scoreHistory => scoreHistory.frenchFormattedDate);
                    finalScoreDateRangeHistory.push({ ...option, scoreHistory: Utils.scaleArray(scoreList, HISTORY_CHART.scoreScale), dateHistory: dateList });
                }
            }
        });

        return finalScoreDateRangeHistory;
    }, [irlScoreEvolution, props.asset]);

    const selectedChartConfig = React.useMemo(() => {
        const selectedDateRangeHistory = scoreDateRangeHistory.find(dateRangeHistory => dateRangeHistory.dateRange === selectedDateRange);
        return { scoreHistory: selectedDateRangeHistory?.scoreHistory ?? [], chartOptions: assetProfileScoreHistoryOptions(selectedDateRangeHistory?.dateHistory ?? [], [HISTORY_CHART.scoreScale], false) };
    }, [selectedDateRange, scoreDateRangeHistory]);


    return (
        <div className="mixed-chart-score">
            {selectedChartConfig && <Fragment>
                <Chart
                    options={selectedChartConfig.chartOptions}
                    series={[{ data: selectedChartConfig.scoreHistory, name: i18next.t('assetProfile.history.scoreEvolution') }]}
                    type="area"
                    height={CHART_HEIGHT}
                />
                <div className="mixed-chart_range">
                    {scoreDateRangeHistory.map((option, indexNum) => <Fragment key={indexNum}>
                        <span
                            onClick={() => setSelectedDateRange(option.dateRange)}
                            className={clsx({
                                active: option.dateRange === selectedDateRange,
                            })}
                        >
                            {i18next.t(option.label_TranslateKey)}
                        </span>
                    </Fragment>)}
                </div>
            </Fragment>}
        </div>
    );
};

export default ScoreHistoryGraph;