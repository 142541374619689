import { useDispatch, useSelector } from "react-redux";
import IUseAssetInitialSalePeriod from "./IUseAssetInitialSalePeriod";
import IAssetCurrentInformationData from "service/asset-current-information/IAssetCurrentInformationData";
import { RootState } from 'model/Redux';
import { useEffect, useState } from "react";
import { getAssetInfoFromMarketService } from 'service/asset-current-information/actions';
import Application from "Application";
import dayjs from 'dayjs';
import { isAssetInformationSelectorLoading } from "service/asset-current-information/selectors";

interface IUseAssetInitialSalePeriodInput {
    assetId: string;
    initialSaleEndDate: string;
};

const useAssetInitialSalePeriod = (params: IUseAssetInitialSalePeriodInput): IUseAssetInitialSalePeriod => {
    const dispatch = useDispatch();
    const assetCurrentInformationFromStore: IAssetCurrentInformationData = useSelector((state: RootState) => state.assetsCurrentInformation.data[params.assetId]);
    const [assetCurrentInformation, setAssetCurrentInformation] = useState<IAssetCurrentInformationData>();
    const [internalIsInitialSalePeriodIsDone, setInternalIsInitialSalePeriodIsDone] = useState<boolean>(true);
    const dayjsSaleEndDate = dayjs(params.initialSaleEndDate);
    const assetSubjectToInitialSalePeriod: boolean = !params.initialSaleEndDate || !Application.getInstance().SkipPrimaryMarket ? false : dayjs().isBefore(dayjsSaleEndDate.add(Application.getInstance().MinutesFromSoldOutDateWhichUserCantSell, 'minutes'));
    const isAssetCurrentInformationLoading: boolean = useSelector(isAssetInformationSelectorLoading);

    useEffect(() => {
        if (!assetSubjectToInitialSalePeriod)
            return setInternalIsInitialSalePeriodIsDone(true);
        dispatch(getAssetInfoFromMarketService(params.assetId));
    }, []);

    useEffect(() => {
        if (!assetCurrentInformationFromStore)
            return;
        setAssetCurrentInformation(assetCurrentInformationFromStore);
    }, [assetCurrentInformationFromStore]);

    useEffect(() => {
        if (!assetCurrentInformation)
            return;
        setInternalIsInitialSalePeriodIsDone(assetCurrentInformation.initialSalePeriodIsDone);
    }, [assetCurrentInformation]);

    const reloadData = () => {
        dispatch(getAssetInfoFromMarketService(params.assetId));
    };

    return {
        initialSalePeriodIsDone: internalIsInitialSalePeriodIsDone,
        withinFakePrimaryMarket: !Application.getInstance().SkipPrimaryMarket ? false : !internalIsInitialSalePeriodIsDone,
        reload: reloadData,
        isAssetCurrentInformationLoading,
    }
};

export default useAssetInitialSalePeriod;