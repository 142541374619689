import LeagueAttribution from "model/League/LeagueAttribution";
import React from "react";
import { useSelector } from "react-redux";
import { leagueAttributionSelector } from "service/league/selectors";
import IInjectedLeagueAttribution from "./IInjectedLeagueAttribution";


const withUserLeagueAttribution = <BaseProps,>(ChildComponent: React.ComponentType<BaseProps & IInjectedLeagueAttribution>): React.MemoExoticComponent<React.FC<BaseProps>> => {
  const WrappedComponent: React.FC<BaseProps> = (props: BaseProps) => {
    const leagueAttribution: LeagueAttribution = useSelector(leagueAttributionSelector);

    return (
      <ChildComponent {...props as BaseProps} userLeagueAttribution={leagueAttribution} />
    )
  }

  return React.memo<React.FC<BaseProps>>(WrappedComponent);
}

export default withUserLeagueAttribution;