
import type { RootState } from 'model/Redux';
import { Selector, createSelector } from 'reselect';
import { ISportStatisticsStore } from './types';
import ISportStatistics from 'interfaces/score-chart/ISportStatistics';

export const assetScoreStatisticsSelector: Selector<RootState, ISportStatisticsStore> = state => state.assetScoreStatistics.data;

export const selectScoreStatisticsSelector = createSelector(
    [assetScoreStatisticsSelector, (_: RootState, assetId: number) => assetId],
    (data, assetId: number) => {
        return data[assetId] as ISportStatistics;
    }
);
