import React, { useEffect, useState } from 'react';
import IBuyAssetWrapperHalfScreenProps from './IAssetTransactionWrapperHalfScreenProps';
import clsx from 'clsx';
import { KeyboardArrowDown } from '@material-ui/icons';
import PrimaryButton from 'components/small/buttons/primary-button/PrimaryButton';
import "./AssetTransactionWrapperHalfScreen.scss";
import useWalletRefund from 'hooks/use-wallet-refund/useWalletRefund';
import i18next from 'i18next';

const AssetTransactionWrapperHalfScreen: React.FC<IBuyAssetWrapperHalfScreenProps> = ({ onCancel, onConfirm, confirmText, isExiting, children, hideContinueButton, disableContinueButton, prePrivateSaleTresholdMessage, isTransactionTypeBuy, isTransactionTypeSell, hideBackArrow }) => {
    const timeForEntering: number = 300;
    const scrollUpValue: number = 75;
    const [isEntering, setEntering] = useState<boolean>(true);
    const { handleWalletRefundClick } = useWalletRefund();

    //makes assetProfilPage's asset price,token block visible by scrolling up
    const makeAssetProfilePageScrollUp = (): void => {
        const assetProfilePageElement = document.getElementById("assetProfilePage");
        assetProfilePageElement?.scrollTo({ top: scrollUpValue });
        window.scrollTo({ top: scrollUpValue });
    };

    useEffect(() => {
        makeAssetProfilePageScrollUp();
        const enteringTimeout = setTimeout(() => {
            setEntering(false);
        }, timeForEntering);

        return () => clearTimeout(enteringTimeout);
    }, []);

    return (<div className={clsx('buy-asset-slider-halfScreen', { "halfScreen_slide-in": isEntering, "halfScreen_slide-out": isExiting })}>
        {!hideBackArrow &&
            <div className="arrow-back" onClick={onCancel}>
                <KeyboardArrowDown />
            </div>
        }
        <div className="buy-asset-wrapper">
            {children}
            <div className="container-button-continue">
                {!hideContinueButton && <PrimaryButton
                    type="button"
                    disabled={disableContinueButton}
                    textButton={confirmText}
                    receiveClickEvent={false}
                    handleClick={onConfirm}
                    className={clsx("buy-asset-continue-button",
                        { 'green-btn': isTransactionTypeBuy },
                        { 'red-btn': isTransactionTypeSell })}
                />
                }
                {prePrivateSaleTresholdMessage && (
                    <div className="prePrivateSale-warning">
                        {i18next.t(prePrivateSaleTresholdMessage)}
                        {prePrivateSaleTresholdMessage === "prePrivateSale.warning.credit-card" && (
                            <span onClick={handleWalletRefundClick} style={{ textDecoration: 'underline', textDecorationColor: '#ed213a' }}>
                                {i18next.t('click-here')}
                            </span>
                        )}
                    </div>
                )}
            </div>
        </div>
    </div>);
};

export default AssetTransactionWrapperHalfScreen;