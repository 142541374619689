import Application from "Application";
import { TEAM_STATUS, ZERO } from "config/_const";
import { PaymentMethodsEnum, TournamentLeagues } from "config/_enums";
import IUseDialog from "hooks/use-dialog/IUseDialog";
import useDialog from "hooks/use-dialog/useDialog";
import useTournamentTicketPurchase from "hooks/use-tournament-ticket-purchase/useTournamentTicketPurchase";
import { debounce } from "lodash";
import { IBuyTicketParameters } from "model/Tournament/ITournamentSubscription";
import React from "react";
import { batch, useDispatch } from "react-redux";
import { updateTeamStatus } from "service/teams/action";
import LeagueViewModel from "view-model/League/LeagueViewModel/LeagueViewModel";
import IUseTournamentTicketCheckoutParams from "./IUseTournamentTicketCheckoutParams";

const useTournamentTicketCheckout = ({ defaultLeague, tournament, defaultPaymentMethod, teamId, userLeagueAttribution }: IUseTournamentTicketCheckoutParams) => {
    const dispatch = useDispatch();
    const [selectedPaymentType, setSelectedPaymentType] = React.useState<PaymentMethodsEnum>(defaultPaymentMethod);
    const [league, setLeague] = React.useState<LeagueViewModel>(defaultLeague);
    const [leagueEntryPrice, setLeagueEntryPrice] = React.useState<number>(ZERO);
    const [leagueEntryPriceWithoutDiscount, setLeagueEntryPriceWithoutDiscount] = React.useState<number>(ZERO);
    const { purchaseTournamentSubscriptionTicketWithCredits } = useTournamentTicketPurchase();
    const stripeTicketRef = React.useRef<Function>(() => { });
    const { showBuyTicketConfirmation, showBuyTicketError }: IUseDialog = useDialog();
    const [isBuyLoading, setIsBuyLoading] = React.useState<boolean>(false);
    const isDiscountElligible: boolean = React.useMemo(() => userLeagueAttribution.League.Index >= league.Index, [league.Index, userLeagueAttribution.League.Index]);

    const getLeagueEntryPriceByLeagueWithDiscount = React.useCallback((league: LeagueViewModel, ticketPrice) => {
        const discountAmount: number = userLeagueAttribution.League.Index >= league.Index ? league.Discount : ZERO;
        const ticketPriceWithDiscount: number = ticketPrice - (ticketPrice * discountAmount);
        return ticketPriceWithDiscount;
    }, [userLeagueAttribution.League.Index]);

    React.useEffect(() => {
        setSelectedPaymentType(defaultPaymentMethod);
    }, [defaultPaymentMethod]);

    React.useEffect(() => {
        batch(() => {
            setLeague(defaultLeague);
            const ticketPrice: number = tournament.getLeaguePriceEntry(defaultLeague.Name);
            setLeagueEntryPriceWithoutDiscount(ticketPrice);
            setLeagueEntryPrice(getLeagueEntryPriceByLeagueWithDiscount(defaultLeague, ticketPrice));
        })
    }, [getLeagueEntryPriceByLeagueWithDiscount, defaultLeague, tournament]);

    const onBuyTournamentTicketSuccess = React.useCallback(() => {
        setIsBuyLoading((prev: boolean) => !prev);
        dispatch(showBuyTicketConfirmation({ league }));
        dispatch(updateTeamStatus(teamId, TEAM_STATUS.ENGAGED))
    }, [dispatch, league, showBuyTicketConfirmation, teamId]);

    const onBuyTournamentTicketError = React.useCallback(() => {
        setIsBuyLoading((prev: boolean) => !prev);
        dispatch(showBuyTicketError({ league }));
    }, [dispatch, league, showBuyTicketError])

    const handleBuyTournamentSubscriptionTicket = React.useCallback(() => {
        setIsBuyLoading((prev: boolean) => !prev);
        const params: IBuyTicketParameters = {
            subscription: {
                team: teamId,
                tournament: tournament.Id,
                league: league.Name,
                subscriptionDate: new Date().toISOString(),
            }
        };
        dispatch(purchaseTournamentSubscriptionTicketWithCredits({ ...params, transactionErrorCallback: onBuyTournamentTicketError, transactionSuccessCallback: onBuyTournamentTicketSuccess }));
    }, [purchaseTournamentSubscriptionTicketWithCredits, dispatch, league.Name, onBuyTournamentTicketError, onBuyTournamentTicketSuccess, teamId, tournament.Id]);

    const arbitraryBuyTicket = React.useCallback(() => {
        switch (selectedPaymentType) {
            case PaymentMethodsEnum.CREDITS:
                handleBuyTournamentSubscriptionTicket();
                break;
            case PaymentMethodsEnum.STRIPE:
            case PaymentMethodsEnum.STRIPE_NEW_CARD:
                stripeTicketRef?.current();
                break;
        }
    }, [handleBuyTournamentSubscriptionTicket, selectedPaymentType]);

    const handleLeagueChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const leagueName: TournamentLeagues = event.target.value as TournamentLeagues;
        const league = Application.getInstance().getLeagueFromName(leagueName);

        batch(() => {
            setLeague(league);
            const ticketPrice: number = tournament.getLeaguePriceEntry(league.Name);
            setLeagueEntryPriceWithoutDiscount(ticketPrice);
            setLeagueEntryPrice(getLeagueEntryPriceByLeagueWithDiscount(league, ticketPrice));
        });
    }

    return {
        league,
        selectedPaymentType,
        stripeTicketRef,
        arbitraryBuyTicket: debounce(arbitraryBuyTicket, 600),
        isBuyLoading,
        leagueEntryPrice,
        leagueEntryPriceWithoutDiscount,
        isDiscountElligible,
        setSelectedPaymentType,
        handleLeagueChange,
        onBuyTournamentTicketSuccess,
        onBuyTournamentTicketError,
    }
}

export default useTournamentTicketCheckout;