import { useDispatch, useSelector } from "react-redux";
import { getAllSportsPlayersWithTeamId } from "service/sports-players/actions";
import { isSportsPlayersSelectorLoading, sportsPlayersSelectorWithInfo } from "service/sports-players/selectors";

const useSportsPlayers = () => {
    const dispatch = useDispatch();
    const isSportsPlayersFetchLoading = useSelector(isSportsPlayersSelectorLoading);
    const footballPlayersList = useSelector(sportsPlayersSelectorWithInfo);
    const fetchSportsPlayersByTeamId = (teamId: number) => { 
        dispatch(getAllSportsPlayersWithTeamId(teamId));
    }

    return {
        footballPlayersList,
        isSportsPlayersFetchLoading,
        fetchSportsPlayersByTeamId
    }
}
export default useSportsPlayers;